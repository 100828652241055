import { useFormik } from "formik";
import { Grid, InputLabel, SelectChangeEvent, TextField } from "@mui/material";
import React, { ChangeEvent, FormEvent, ReactElement, useEffect, useState } from "react";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import accountTypes from "src/components/payment/ACHForm/accountTypes";
import { inputError } from "src/utils/showInputError/showInputError";
import achFormValidation from "./AchFormValidation";
import PaymentOptions from "../PaymentOptions/PaymentOptions";
import { accountNumbersMatch, showAccountNumberHelperText } from "./helpers/AchFormHelpers";
import { maskAccountNumber } from "src/components/payment/ACHForm/ACHForm";
import { useAppDispatch } from "../../../../../../store/hooks";
import {
  setAccountName as setACHSliceAccountName,
  setAccountNumber as setACHSliceAccountNumber,
  setAccountType as setACHSliceAccountType,
  setRoutingNumber as setACHSliceRoutingNumber,
  setEnableAutoPay
} from "src/store/reducers/achSlice/achSlice";

export interface AchFormValues {
  routingNumber: string;
  bankName: string;
  accountNumber: string;
  accountNumberConfirm: string;
  accountName: string;
  checkNumber: string;
  accountType: string;
  isAutoBilling: boolean;
  isSaveAsPreferredMethod: boolean;
}
interface ACHFormProps {
  handleSubmit: (values: AchFormValues) => void;
  actionButtons?: ReactElement;
  setFormik?: Function;
}

const ACHForm: React.FC<ACHFormProps> = ({ handleSubmit, actionButtons, setFormik }): ReactElement => {
  const dispatch = useAppDispatch();
  const [accountNumber, setAccountNumber] = useState("");
  const [maskedAccountNumber, setMaskedAccountNumber] = useState("");
  const [accountNumberConfirm, setAccountNumberConfirm] = useState("");
  const [maskedAccountNumberConfirm, setMaskedAccountNumberConfirm] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountName, setAccountName] = useState("");

  const formik = useFormik({
    initialValues: {
      routingNumber: "",
      bankName: "",
      accountNumber: maskedAccountNumber,
      accountNumberConfirm: maskedAccountNumberConfirm,
      accountName: "",
      checkNumber: "",
      accountType: "1",
      isAutoBilling: false,
      isSaveAsPreferredMethod: false
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: achFormValidation,
    onSubmit: (formValues) => {
      const values = {
        ...formValues,
        accountNumber: accountNumber
      };
      handleSubmit(values);
    }
  });

  const { touched, values, errors, setFieldValue, handleChange } = formik;

  useEffect(() => {
    if (setFormik) {
      setFormik(formik);
    }
  }, []);

  const updateForm = (fieldName: string, fieldValue?: string | boolean): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleAccountNameOnChange = (fieldValue: string) => {
    updateForm("accountName", fieldValue);
    setAccountName(fieldValue);
  };

  const handleAccountNameOnBlur = () => {
    dispatch(setACHSliceAccountName(accountName));
  };

  const handleAccountNumberOnChange = (fieldValue: string) => {
    if (fieldValue.match(/^[0-9]*$/)) {
      updateForm("accountNumber", fieldValue);
      setAccountNumber(fieldValue);
    }
  };

  const handleAccountNumberOnFocus = () => {
    updateForm("accountNumber", accountNumber);
    setMaskedAccountNumber(accountNumber);
  };

  const handleAccountNumberOnBlur = () => {
    const tempMaskedCvv = maskAccountNumber(accountNumber);
    setMaskedAccountNumber(tempMaskedCvv);
    updateForm("accountNumber", tempMaskedCvv);
  };

  const handleConfirmAccountNumberOnChange = (fieldValue: string) => {
    if (fieldValue.match(/^[0-9]*$/)) {
      updateForm("accountNumberConfirm", fieldValue);
      setAccountNumberConfirm(fieldValue);
    }
  };

  const handleConfirmAccountNumberOnFocus = () => {
    updateForm("accountNumberConfirm", accountNumberConfirm);
    setAccountNumberConfirm(accountNumberConfirm);
  };

  const handleConfirmAccountNumberOnBlur = () => {
    const tempMaskedCvv = maskAccountNumber(accountNumberConfirm);
    setMaskedAccountNumberConfirm(tempMaskedCvv);
    updateForm("accountNumberConfirm", tempMaskedCvv);
    dispatch(setACHSliceAccountNumber(accountNumberConfirm));
  };

  const handleRoutingNumberOnChange = (fieldValue: string) => {
    updateForm("routingNumber", fieldValue);
    setRoutingNumber(fieldValue);
  };

  const handleRoutingNumberOnBlur = () => {
    dispatch(setACHSliceRoutingNumber(routingNumber));
  };

  const handleAccountTypeOnChange = (e: SelectChangeEvent<any>) => {
    updateForm("accountType", e.target.value);
    dispatch(setACHSliceAccountType(e.target.value));
  };

  const handleAutoBillChange = (fieldValue: boolean) => {
    updateForm("isAutoBilling", fieldValue);
    dispatch(setEnableAutoPay(fieldValue));
  };

  return (
    <Grid
      container
      direction={"column"}
      spacing={2}
      component={"form"}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        formik.handleSubmit();
      }}
    >
      <Grid item container spacing={2}>
        <Grid item xs={6} lg={3}>
          <PMSSelect
            id={"account-type"}
            data-testid={"account-type"}
            name={"accountType"}
            value={values.accountType}
            changeHandler={handleAccountTypeOnChange}
            label={"Account Type"}
          >
            <option disabled value={""}>
              Account Type
            </option>
            {accountTypes.map((accType) => (
              <option key={accType.value} value={accType.value}>
                {accType.label}
              </option>
            ))}
          </PMSSelect>
        </Grid>

        <Grid item xs={12} lg={4}
          order={{ xs: 1, lg: 1 }}>
          <InputLabel htmlFor={"routing-number"}>Routing Number</InputLabel>
          <TextField
            fullWidth
            placeholder={"Routing Number"}
            id={"routing-number"}
            inputProps={{
              "data-testid": "routing-number",
              maxLength: 9
            }}
            name={"routingNumber"}
            value={values.routingNumber}
            onBlur={() => handleRoutingNumberOnBlur()}
            onChange={(e:ChangeEvent<HTMLInputElement>) => handleRoutingNumberOnChange(e.target.value)}
            error={inputError("routingNumber", touched, errors).error}
            helperText={inputError("routingNumber", touched, errors).helperText}
          />
        </Grid>
        <Grid item xs={12} lg={5}
          order={{ xs: 2, lg: 2 }}>
          <InputLabel htmlFor={"bank-name"}>Bank Name</InputLabel>
          <TextField
            fullWidth
            placeholder={"Bank Name"}
            id={"bank-name"}
            inputProps={{ "data-testid": "bank-name" }}
            name={"bankName"}
            value={values.bankName}
            onChange={handleChange}
            error={inputError("bankName", touched, errors).error}
            helperText={inputError("bankName", touched, errors).helperText}
          />
        </Grid>
        <Grid item xs={12} lg={7}
          order={{ xs: 3, lg: 3 }}>
          <InputLabel htmlFor={"account-number"}>Account Number</InputLabel>
          <TextField
            fullWidth
            placeholder={"Account Number"}
            id={"account-number"}
            inputProps={{ "data-testid": "account-number" }}
            name={"accountNumber"}
            value={values.accountNumber}
            onChange={(e) => handleAccountNumberOnChange(e.target.value)}
            onBlur={() => handleAccountNumberOnBlur()}
            onFocus={() => handleAccountNumberOnFocus()}
            error={
              accountNumbersMatch(touched, values.accountNumberConfirm, values.accountNumber).error ||
              inputError("accountNumber", touched, errors).error
            }
            helperText={
              showAccountNumberHelperText(touched, values.accountNumberConfirm, values.accountNumber) ||
              inputError("accountNumber", touched, errors).helperText
            }
          />
        </Grid>
        <Grid item xs={12} lg={5}
          order={{ xs: 5, lg: 4 }}>
          <InputLabel htmlFor={"account-name"}>Name on Account</InputLabel>
          <TextField
            fullWidth
            placeholder={"Name on Account"}
            id={"account-name"}
            inputProps={{ "data-testid": "account-name" }}
            name={"accountName"}
            value={values.accountName}
            onBlur={() => handleAccountNameOnBlur()}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleAccountNameOnChange(e.target.value)}
            error={inputError("accountName", touched, errors).error}
            helperText={inputError("accountName", touched, errors).helperText}
          />
        </Grid>
        <Grid item xs={12} lg={7}
          order={{ xs: 4, lg: 5 }}>
          <InputLabel htmlFor={"account-number-confirm"}>Confirm Account Number</InputLabel>
          <TextField
            fullWidth
            placeholder={"Confirm Account Number"}
            id={"account-number-confirm"}
            inputProps={{ "data-testid": "account-number-confirm" }}
            name={"accountNumberConfirm"}
            value={values.accountNumberConfirm}
            onChange={(e) => handleConfirmAccountNumberOnChange(e.target.value)}
            onFocus={() => handleConfirmAccountNumberOnFocus()}
            onBlur={() => handleConfirmAccountNumberOnBlur()}
            error={
              accountNumbersMatch(touched, values.accountNumberConfirm, values.accountNumber).error ||
              inputError("accountNumberConfirm", touched, errors).error
            }
            helperText={
              showAccountNumberHelperText(touched, values.accountNumberConfirm, values.accountNumber) ||
              inputError("accountNumberConfirm", touched, errors).helperText
            }
          />
        </Grid>
        <Grid item xs={12} lg={5}
          order={{ xs: 6, lg: 6 }}>
          <InputLabel htmlFor={"check-number"}>Check Number</InputLabel>
          <TextField
            fullWidth
            placeholder={"Check Number"}
            id={"check-number"}
            type={"number"}
            inputProps={{ "data-testid": "check-number" }}
            name={"checkNumber"}
            value={values.checkNumber}
            onChange={handleChange}
            error={inputError("checkNumber", touched, errors).error}
            helperText={inputError("checkNumber", touched, errors).helperText}
          />
        </Grid>
      </Grid>
      <Grid m={1}>
        <PaymentOptions
          isAutoBillingEnabled={values.isAutoBilling}
          isSaveAsPreferredMethod={values.isSaveAsPreferredMethod}
          handleAutoBillingChange={() => handleAutoBillChange(!values.isAutoBilling)}
          handlePreferredMethodChange={() => setFieldValue("isSaveAsPreferredMethod", !values.isSaveAsPreferredMethod)}
        />
      </Grid>
      <Grid>{actionButtons ?? null}</Grid>
    </Grid>
  );
};

export default ACHForm;
