import { CircularProgress, Grid } from "@mui/material";
import React, { ReactElement } from "react";
import { Box } from "@mui/system";
import EmptyTable from "../../../../../components/ui/PMSTable/EmptyTable/EmptyTable";
import PMSTable from "../../../../../components/ui/PMSTable/PMSTable";
import SelectedLedgerTableColumns from "./SelectedLedgerTableColumns/SelectedLedgerTableColumns";
import {
  selectSelectedLedgers
} from "../../../../../store/reducers/makeAPaymentSlice/makeAPaymentSlice";
import { selectSelectedOccupant } from "../../../../../store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { useAppSelector } from "../../../../../store/hooks";
import useStyles from "./ReviewAndApplyPaymentTable.styles";

const ReviewAndApplyPaymentTable: React.FC = (): ReactElement => {
  const selectedOccupant = useAppSelector(selectSelectedOccupant);
  const selectedLedgers = useAppSelector(selectSelectedLedgers);
  const { classes } = useStyles();

  const emptyTable = () => (
    <EmptyTable
      collectionNotFound={`data`}
      value={"data"}
    />
  );

  return (
    <Box className={classes.table}>
      {selectedLedgers.length
        ? <PMSTable
            data-test-id={"occupant-ledgers-table"}
            columns={SelectedLedgerTableColumns(selectedOccupant)}
            rows={selectedLedgers}
            emptyTableDisplay={emptyTable}
            hideFooter
      />
        : <Grid p={2} container item
            xs={12}
            justifyContent={"center"}>
          <CircularProgress/>
        </Grid>
        }
    </Box>
  );
};

export default ReviewAndApplyPaymentTable;
