import {
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography
} from "@mui/material";
import { CheckCircleOutline, Close } from "@mui/icons-material";
import React, { ChangeEvent, FC, FormEvent } from "react";
import {
  resetAutomaticPaymentsSlice,
  selectAutomaticPaymentSlice,
  selectConfirmAutomaticPaymentModalOpen,
  selectEnrollAutopayLoading,
  setConfirmAutomaticPaymentsModalOpened
} from "src/store/reducers/automaticPaymentsSlice/automaticPaymentsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";

import { LoadingButton } from "@mui/lab";
import { enrollAutoPay } from "src/store/thunks/occupant/enrollAutoPay/enrollAutoPay";
import { getOccupant } from "src/store/thunks/occupant/getOne/getOccupant";
import { setSelectedLedger } from "src/store/reducers/ledgersSlice/ledgersSlice";
import { useParams } from "react-router";
import useStyles from "./ConfirmAutomaticPaymentsModal.styles";

interface ConfirmAutomaticPaymentsModalProps { }

const ConfirmAutomaticPaymentsModal: FC<ConfirmAutomaticPaymentsModalProps> = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const modalOpen = useAppSelector(selectConfirmAutomaticPaymentModalOpen);
  const params = useParams<{ id?: string }>();
  const enrollLoading = useAppSelector(selectEnrollAutopayLoading);

  const handleCloseModal = async() => {
    await dispatch(setConfirmAutomaticPaymentsModalOpened(false));
    await dispatch(setSelectedLedger(null));
  };

  const handleSubmit = () => {
    dispatch(enrollAutoPay()).then(response => {
      dispatch(resetAutomaticPaymentsSlice());
      dispatch(setConfirmAutomaticPaymentsModalOpened(false));
      dispatch(setSelectedLedger(null));

      if (!response.type.includes("rejected")) {
        dispatch(getOccupant(params.id!));
      }
    });
  };

  return (
    <Modal onClose={handleCloseModal} open={modalOpen} className={classes.modal}>
      <Grid
        p={4}
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        justifyContent={"center"}
        className={classes.container}
      >
        <Grid item xs={11}>
          <Typography textAlign={"center"} variant={"h5"}>Confirm to Enable Autobill</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={handleCloseModal}>
            <Close />
          </IconButton>
        </Grid>
        <Grid mb={1} item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography textAlign={"center"}>Confirm to enable Autobill on this unit?</Typography>
        </Grid>
        <Grid
          container
          xs={12}
          mt={2}
          justifyContent={"center"}
          gap={2}
          >
          <Button onClick={handleCloseModal} color={"error"} variant={"text"}
            sx={{ marginRight: 5 }}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSubmit}
            variant={"contained"}
            loading={enrollLoading}
            className={classes.button}
          >
            Submit Autobill Update
          </LoadingButton>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ConfirmAutomaticPaymentsModal;
