import { RootState } from "../../../rootStore";
import { UpdateEmergencyContactPayload } from "./UpdateEmergencyContactPayload";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const updateEmergencyContact = createAsyncThunk(
  "updateEmergencyContact",
  (occupantId: string | number, { dispatch, getState, rejectWithValue }) => {
    if (!occupantId) {
      const errorMessage = "Occupant ID not found";

      dispatch(showSnackbar({
        message: errorMessage,
        variant: "error"
      }));

      rejectWithValue(errorMessage);
      return;
    }

    const state = getState() as RootState;

    const payload: UpdateEmergencyContactPayload = {
      id: state.emergencyContactInformation.id,
      first_name: state.emergencyContactInformation.first_name,
      middle_name: state.emergencyContactInformation.middle_name ?? "",
      last_name: state.emergencyContactInformation.last_name,
      email: state.emergencyContactInformation.email,
      relationship: state.emergencyContactInformation.relationship,
      address: state.emergencyContactInformation.address,
      address_two: state.emergencyContactInformation.address_two ?? "",
      city: state.emergencyContactInformation.city,
      region: state.emergencyContactInformation.region,
      postal_code: state.emergencyContactInformation.postal_code,
      country: state.emergencyContactInformation.country,
      phone_primary: state.emergencyContactInformation.phone_primary ?? "",
      phone_alternate: state.emergencyContactInformation.phone_alternate ?? ""
    };

    return axios.put(`/api/v1/occupants/${occupantId}/contacts/${payload.id}`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: `Emergency Contact updated`,
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = `There was an error updating Emergency Contact ${payload.id}`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
