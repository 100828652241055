import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DocumentSliceState } from "./DocumentSliceState";
import { Facility } from "src/models/Facility";
import { RootState } from "src/store/rootStore";
import { getByIdDocumentTemplate } from "src/store/thunks/documentTemplates/getById/getByIdDocumentTemplate";
import { getDocumentTemplateListForNewProductType } from
  "../../thunks/documentTemplates/getDocumentTemplateListForNewpProductType/getDocumentTemplateListForNewProductType";
import { string } from "prop-types";

export const initialState: DocumentSliceState = {
  id: 0,
  documentName: "",
  documentType: 0,
  documentCategory: 0,
  is_disabled: false,
  created_at: "",
  rollback_allowed: true,
  attachedFacility: [],
  documentContent: "",
  loading: false,
  list: [],
  fetchListError: "",
  documentVersion: "",
  modalOpened: false,
  rollbackModalOpened: false
};

export const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    setDocumentName: (state, action: PayloadAction<string>) => {
      state.documentName = action.payload;
    },
    setDocumentType: (state, action: PayloadAction<number>) => {
      state.documentType = action.payload;
    },
    setDocumentCategory: (state, action: PayloadAction<number>) => {
      state.documentCategory = action.payload;
    },
    setAttachedFacilities: (state, action: PayloadAction<[number]>) => {
      state.attachedFacility = action.payload;
    },
    setDocumentContent: (state, action: PayloadAction<string>) => {
      state.documentContent = action.payload;
    },
    setDocumentLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setDocumentModalOpened: (state) => {
      state.modalOpened = !state.modalOpened;
    },
    setDocumentRollbackModalOpened: (state) => {
      state.rollbackModalOpened = !state.rollbackModalOpened;
    },
    resetDocumentSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getDocumentTemplateListForNewProductType.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getDocumentTemplateListForNewProductType.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload.data;
      })
      .addCase(getDocumentTemplateListForNewProductType.rejected, (state, action) => {
        state.loading = false;
        state.fetchListError = action.payload as string;
      })
      .addCase(getByIdDocumentTemplate.pending, (state) => {
        state.loading = true;
      })
      .addCase(getByIdDocumentTemplate.fulfilled, (state, action) => {
        const currentFacilities = action.payload.facilities.map((facility: Facility) => facility.id);

        state.documentName = action.payload.name;
        state.documentCategory = action.payload.category;
        state.documentType = action.payload.type;
        state.attachedFacility = currentFacilities;
        state.documentVersion = action.payload.version;
        state.created_at = action.payload.created_at;
        state.documentContent = action.payload.content;
        state.rollback_allowed = action.payload.rollback_allowed;
        state.is_disabled = action.payload.is_disabled;
        state.loading = false;
      });
  }

});

export const selectDocumentName = (state: RootState) => state.document.documentName;
export const selectDocumentType = (state: RootState) => state.document.documentType;
export const selectDocumentList = (state: RootState) => state.document.list; ;
export const selectDocumentCategory = (state: RootState) => state.document.documentCategory;
export const selectAttachedFacilities = (state: RootState) => state.document.attachedFacility;
export const selectDocumentContent = (state: RootState) => state.document.documentContent;
export const selectDocumentVersion = (state: RootState) => state.document.documentVersion;
export const selectDocumentLoading = (state: RootState) => state.document.loading;
export const selectDocumentModalOpened = (state: RootState) => state.document.modalOpened;
export const selectIsDisabled = (state: RootState) => state.document.is_disabled;
export const selectRollbackAllowed = (state: RootState) => state.document.rollback_allowed;
export const selectRollbackModalOpened = (state: RootState) => state.document.rollbackModalOpened;
export const selectDocumentCreatedAt = (state: RootState) => state.document.created_at;

export const {
  setAttachedFacilities,
  setDocumentCategory,
  setDocumentContent,
  setDocumentLoading,
  setDocumentName,
  setDocumentType,
  setDocumentModalOpened,
  setDocumentRollbackModalOpened,
  resetDocumentSlice
} = documentSlice.actions;

export default documentSlice.reducer;
