/* eslint-disable max-len */
import { Button, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { selectMoveOutLedger, selectSelectedLedgerDatePaidThru, selectSelectedLedgerProductTypeName, selectSelectedLedgerUnitNumber, selectSelectedLedgerUnitSize, selectSelectedLegerQuotedRate } from "src/store/reducers/moveOutSlice/moveOutSlice";
import {
  selectQuotedRate,
  selectSelectedProductTypeName,
  selectSelectedUnitNumber,
  selectSelectedUnitSize
} from "../../../../store/reducers/selectedUnitSlice/selectedUnitSlice";
import {
  selectSelectedOccupant,
  selectSelectedOccupantLastNameFirst
} from "../../../../store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { setLedgersModalOpen, setSelectedLedger } from "src/store/reducers/ledgersSlice/ledgersSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { ArticleOutlined } from "@mui/icons-material";
import { DealDetailsProps } from "./DealDetailsProps";
import { formatDate } from "../../../../utils/__dateAndTimeUtils__/formatDate/formatDate";
import formatPhone from "src/utils/formatPhone/formatPhone";
import moment from "moment";
import { selectDealLoading } from "src/store/reducers/dealSlice/dealSlice";
import { selectSelectedPromotion } from "../../../../store/reducers/selectedPromotionSlice/selectedPromotionSlice";
import useStyles from "./DealDetails.styles";

const DealDetails: React.FC<DealDetailsProps> = ({ isMoveOut }): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const promotion = useAppSelector(selectSelectedPromotion);
  const occupant = useAppSelector(selectSelectedOccupant);
  const occupantName = useAppSelector(selectSelectedOccupantLastNameFirst);
  const unitNumber = useAppSelector(isMoveOut ? selectSelectedLedgerUnitNumber : selectSelectedUnitNumber);
  const unitSize = useAppSelector(isMoveOut ? selectSelectedLedgerUnitSize : selectSelectedUnitSize);
  const productTypeName = useAppSelector(isMoveOut ? selectSelectedLedgerProductTypeName : selectSelectedProductTypeName);
  const dealLoading = useAppSelector(selectDealLoading);
  const quotedRate = useAppSelector(selectQuotedRate);
  const showPromoApplied = (promotion && (promotion.applied_month > 1));
  const paidThruDate = useAppSelector(selectSelectedLedgerDatePaidThru);
  const monthlyRate = useAppSelector(selectSelectedLegerQuotedRate);
  const promotionAppliedCaption = showPromoApplied
    ? (
      <Typography variant={"caption"} gutterBottom>
        *Promotion begins on {formatDate(promotion?.applied_date || "")}
      </Typography>
      )
    : "";

  const ledger = useAppSelector(selectMoveOutLedger);

  const handleSelectLedger = async() => {
    await dispatch(setSelectedLedger(ledger));
    dispatch(setLedgersModalOpen(true));
  };

  if (dealLoading) {
    return (
      <Grid
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        py={5}
      >
        <CircularProgress size={50} data-testid={"deal-loading"} aria-label={"deal-loading"} />
      </Grid>
    );
  }
  return (
    <>
      <Grid
        container
        justifyContent={"space-between"}
        p={2}
        spacing={1}
      >
        <Grid item xs={6} lg={"auto"}>
          <Grid container direction={"column"}>
            <Typography variant={"h5"} className={classes.bold} component={"span"}>{occupantName}</Typography>
            <Typography variant={"h6"} component={"span"}>{occupant?.email}</Typography>
            <Typography variant={"h6"} component={"span"}>{formatPhone(occupant?.phone_primary, true)}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} lg={"auto"}>
          <Grid container direction={"column"}>
            <Typography variant={"h6"} component={"span"} className={classes.bold}>Selected Unit</Typography>
            <Typography variant={"h6"} component={"span"}>Unit {unitNumber}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} lg={"auto"}>
          <Grid container direction={"column"}>
            <Typography variant={"h6"} component={"span"} className={classes.bold}>Unit Size</Typography>
            <Typography variant={"h6"} component={"span"}>{unitSize}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} lg={"auto"}>
          <Grid container direction={"column"}>
            <Typography variant={"h6"} component={"span"} className={classes.bold}>Product Type</Typography>
            <Typography variant={"h6"} component={"span"}>{productTypeName}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} lg={"auto"}>
          <Grid container direction={"column"}>
            <Typography variant={"h6"} component={"span"} className={classes.bold}>Rent</Typography>
            <Typography variant={"h6"} component={"span"}>${isMoveOut ? monthlyRate : quotedRate}</Typography>
          </Grid>
        </Grid>
        {!isMoveOut && (
        <Grid
          item
          xs={6}
          lg={"auto"}
        >
          <Grid container direction={"column"}>
            <Typography variant={"h6"} component={"span"} className={classes.bold}>
              Applied Promotion{showPromoApplied ? "*" : ""}
            </Typography>
            <Typography variant={"h6"} component={"span"} className={classes.promotion}>
              {promotion?.name || "N/A"}
            </Typography>
            {promotionAppliedCaption}
          </Grid>
        </Grid>
        )}
        {isMoveOut && (
          <>
            <Grid item xs={6} lg={"auto"}>
              <Grid container direction={"column"}>
                <Typography variant={"h6"} component={"span"} className={classes.bold}>
                  Paid Thru Date
                </Typography>
                <Typography variant={"h6"} component={"span"} className={classes.paidDate}>
                  {formatDate(moment(paidThruDate))}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              xs={6}
              lg={"auto"}
        >
              <Grid
                container
                className={classes.actionButton}
                direction={"row"}
                alignItems={"center"}
                component={Button}
                onClick={handleSelectLedger}
          >
                <ArticleOutlined />
                <Typography variant={"caption"}>Ledger</Typography>
              </Grid>
            </Grid>

          </>
        )}

      </Grid>
      <Divider className={classes.divider} />
    </>
  );
};

export default DealDetails;
