import { axiosInstance as axios } from "../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";

export const getUnitCharges = createAsyncThunk(
  "getUnitCharges",
  (
    { occupantId, ledgerId, prepaidPeriods = 0 }: { occupantId: string; ledgerId: string; prepaidPeriods?: number },
    { dispatch, getState, rejectWithValue }
  ) => {
    const params = {
      params: {
        prepaid_periods: prepaidPeriods
      }
    };
    return axios
      .get(`/api/v1/occupants/${occupantId}/ledgers/${ledgerId}/payment-cost`, params)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = `There was an error fetching this unit's charges.`;

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  }
);
