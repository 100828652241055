import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DocumentsSliceState } from "./DocumentsSliceState";
import { HttpProblem } from "src/models/HttpProblem";
import { RootState } from "../../rootStore";
import documentTemplateNullOption from "src/utils/autocompleteNullOptions/documentTemplateNullOptionAutocomplete";
import { downloadDocumentTemplate } from "src/store/thunks/documentTemplates/download/downloadDocumentTemplate";
import { getAllDocumentTemplates } from "src/store/thunks/documentTemplates/getAll/getAllDocumentTemplates";
import {
  getAllDocumentTemplatesAllFacilities
} from "src/store/thunks/documentTemplates/getAll/getAllDocumentTemplatesAllFacilities";
import {
  getAllPaginatedDocumentTemplates
} from "src/store/thunks/documentTemplates/getAll/getAllPaginatedDocumentTemplates";
import {
  getDocumentTemplateChangelog
} from "src/store/thunks/documentTemplates/changelog/getDocumentTemplateChangelog";
import {
  getDynamicInclusionKeywords
} from "src/store/thunks/documentTemplates/getDynamicInclusionKeywords/getDynamicInclusionKeywords";
import moment from "moment";

export const initialState: DocumentsSliceState = {
  documentTemplates: [],
  documentTemplatesError: "",
  pdfUrl: "",
  selectedDocumentTemplate: null,
  selectedDocumentTemplateChangelog: [],
  documentTemplatesLoading: false,
  keywordsLoading: false,
  keywordsError: "",
  keywords: [],
  documentTemplateChangelogLoading: false,
  documentTemplateSearchValue: "",
  documentCurrentTabView: 0,
  showAllFacilities: true,
  showDeleteDocumentModal: false,
  deleteDocumentLoading: false
};

export const documentTemplatesSlice = createSlice({
  name: "documentTemplates",
  initialState,
  reducers: {
    setSelectedDocumentTemplate: (state, action) => {
      state.selectedDocumentTemplate = action.payload;
    },
    setDocumentTemplateChangelogLoading: (state) => {
      state.documentTemplateChangelogLoading = !state.documentTemplateChangelogLoading;
    },
    setDocumentTemplateSearchValue: (state, action) => {
      state.documentTemplateSearchValue = action.payload as string;
    },
    setDocumentCurrentTabView: (state, action) => {
      state.documentCurrentTabView = action.payload;
    },
    setShowAllFacilities: (state, action) => {
      state.showAllFacilities = action.payload;
    },
    resetDynamicInclusionKeywords: (state) => {
      state.keywords = initialState.keywords;
    },
    setShowDeleteDocumentModal: (state, action) => {
      state.showDeleteDocumentModal = action.payload;
    },
    setSelectedDocumentPDFUrl: (state, action) => {
      state.pdfUrl = action.payload;
    },
    resetDocumentsSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDynamicInclusionKeywords.pending, (state) => {
        state.keywordsLoading = true;
      })
      .addCase(
        getDynamicInclusionKeywords.fulfilled,
        (
          state,
          action: PayloadAction<
            {
              [key: string]: { [key: string]: string };
            }[]
          >
        ) => {
          state.keywords = action.payload;
          state.keywordsLoading = false;
        }
      )
      .addCase(getDynamicInclusionKeywords.rejected, (state, action) => {
        state.keywords = [];
        state.keywordsError = action.payload as string;
      })
      .addCase(getAllPaginatedDocumentTemplates.pending, (state) => {
        state.documentTemplatesLoading = true;
      })
      .addCase(getAllPaginatedDocumentTemplates.fulfilled, (state, action) => {
        state.documentTemplates = action.payload.data;
        state.documentTemplatesLoading = false;
      })
      .addCase(getDocumentTemplateChangelog.pending, (state) => {
        state.documentTemplateChangelogLoading = true;
      })
      .addCase(getDocumentTemplateChangelog.fulfilled, (state, action) => {
        const formattedChangelog = action.payload.map((template: any) => {
          return {
            ...template,
            created_at: moment(template.created_at).format("MM/DD/YYYY LT")
          };
        });
        state.selectedDocumentTemplateChangelog = formattedChangelog;
        state.documentTemplateChangelogLoading = false;
      })
      .addCase(getAllDocumentTemplates.pending, (state) => {
        state.documentTemplatesLoading = true;
      })
      .addCase(getAllDocumentTemplates.fulfilled, (state, action) => {
        state.documentTemplates = action.payload.data;
      })
      .addCase(getAllDocumentTemplatesAllFacilities.pending, (state) => {
        state.documentTemplatesLoading = true;
      })
      .addCase(getAllDocumentTemplatesAllFacilities.fulfilled, (state, action) => {
        state.documentTemplatesLoading = false;
        state.documentTemplates = action.payload.data;
      })
      .addCase(getAllDocumentTemplatesAllFacilities.rejected, (state, action) => {
        state.documentTemplatesLoading = false;
        state.documentTemplates = initialState.documentTemplates;
        state.documentTemplatesError = action.payload as HttpProblem;
      })
      .addCase(downloadDocumentTemplate.pending, (state, action) => {
        state.documentTemplatesLoading = true;
      })
      .addCase(downloadDocumentTemplate.fulfilled, (state, action) => {
        state.documentTemplatesLoading = false;
      })
      .addCase(downloadDocumentTemplate.rejected, (state, action) => {
        state.documentTemplatesLoading = false;
      });
  }
});

export const selectDocumentTemplates = (state: RootState) => state.documents.documentTemplates;
export const selectDocumentTemplatesForAutomationsAutocomplete = (state: RootState) => [
  documentTemplateNullOption,
  ...state.documents.documentTemplates
];
export const selectDynamicInclusionKeywords = (state: RootState) => state.documents.keywords;
export const selectDynamicInclusionKeywordsLoading = (state: RootState) => state.documents.keywordsLoading;
export const selectSelectedDocumentPDFUrl = (state: RootState) => state.documents.pdfUrl;
export const selectDynamicInclusionKeywordsError = (state: RootState) => state.documents.keywordsError;
export const selectDocumentTemplateLoading = (state: RootState) => state.documents.documentTemplatesLoading;
export const selectSelectedDocumentTemplate = (state: RootState) => state.documents.selectedDocumentTemplate;
export const selectSelectedDocumentTemplateChangelog = (state: RootState) =>
  state.documents.selectedDocumentTemplateChangelog;
export const selectDocumentTemplateChangelogLoading = (state: RootState) =>
  state.documents.documentTemplateChangelogLoading;
export const selectDocumentTemplateSearchValue = (state: RootState) => state.documents.documentTemplateSearchValue;
export const selectDocumentCurrentTabView = (state: RootState) => state.documents.documentCurrentTabView;
export const selectShowAllFacilitiesInDocumentLibrary = (state: RootState) => state.documents.showAllFacilities;
export const selectShowDeleteDocumentModal = (state: RootState) => state.documents.showDeleteDocumentModal;
export const selectDeleteDocumentLoading = (state: RootState) => state.documents.deleteDocumentLoading;

export const {
  setSelectedDocumentTemplate,
  resetDynamicInclusionKeywords,
  setDocumentTemplateChangelogLoading,
  setDocumentTemplateSearchValue,
  setDocumentCurrentTabView,
  setShowAllFacilities,
  setShowDeleteDocumentModal,
  setSelectedDocumentPDFUrl,
  resetDocumentsSlice
} = documentTemplatesSlice.actions;

export default documentTemplatesSlice.reducer;
