import AddNewSettingValues from "src/models/AddNewSettingValues";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";
import { RootState } from "src/store/rootStore";

export const createChartOfAccountsSetting = createAsyncThunk("createChartOfAccountsSetting",
  (payload : AddNewSettingValues, { dispatch, getState, rejectWithValue }) => {
    let errorMessage;
    const store = getState() as RootState;
    const facilityId = store.selectedAdminFacility.selectedAdminFacility?.id;

    if (!facilityId) {
      errorMessage = "No Facility Id provided";

      dispatch(showSnackbar({
        message: errorMessage,
        variant: "error"
      }));

      return rejectWithValue(errorMessage);
    }

    return axios.post(`/api/v1/facilities/${facilityId}/chart-accounts`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Setting added successfully",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;

        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = `There was an error creating this setting`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
