import React, { ReactElement } from "react";
import CashForm from "../CashForm/CashForm";
import CreditCardForm from "../CreditCardForm/CreditCardForm";
import { selectPaymentType } from "src/store/reducers/paymentSlice/paymentSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { useNavigate } from "react-router";
import { selectPurchaseLoading, setPurchaseLoading } from "src/store/reducers/pointOfSalesSlice/pointOfSalesSlice";
import { resetSelectedOccupantSlice } from "src/store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { purchaseMerchandise } from "src/store/thunks/merchandise/purchaseMerchandise/purchaseMerchandise";

const PaymentForm = () => {
  const paymentType = useAppSelector(selectPaymentType);
  const formId = "point-of-sales-payment-form";
  const dispatch = useAppDispatch();
  const purchaseLoading = useAppSelector(selectPurchaseLoading);

  const navigate = useNavigate();

  const onReviewAndPaySubmit = () => {
    dispatch(setPurchaseLoading(true));
    dispatch(purchaseMerchandise()).then((resp) => {
      if (!resp.type.includes("rejected")) {
        navigate("/merchandise/point-of-sales/merchandise-summary");
      }
      dispatch(setPurchaseLoading(true));
    });
  };

  const forms: {[index: number]: ReactElement} = {
    1: <CashForm formId={formId} onSubmit={onReviewAndPaySubmit} loading={purchaseLoading} />,
    2: <></>,
    3: <CreditCardForm formId={formId} onSubmit={onReviewAndPaySubmit} loading={purchaseLoading}/>,
    4: <></>
  };

  return forms[paymentType];
};

export default PaymentForm;
