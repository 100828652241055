import "./FabPulsatingButton.css";
import React, { useEffect, useState } from "react";
import {
  selectDealNotification,
  setSelectedDealNotification
} from "src/store/reducers/dealNotificationSlice/dealNotificationSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Deal } from "src/models/Deal";
import DealChips from "./components/DealChips";
import { ReactComponent as FabIcon } from "src/assets/icons/FAB.svg";
import { SoftphoneDisplayMode } from "src/store/reducers/appSoftphoneSlice/appSoftphoneSliceState";
import { Timeout } from "react-number-format/types/types";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { setIsDisplayed } from "src/store/reducers/appSoftphoneSlice/appSoftphoneSlice";
import { useGetDealNotificationsQuery } from "src/api/endpoints/deals";
import useSound from "src/hooks/useSound";
import useStyles from "./FabPulsatingButton.styles";

const FabPulsatingButton = () => {
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const { data: deals } = useGetDealNotificationsQuery(
    { facilityId: selectedFacility?.id },
    { pollingInterval: 60000, skip: !selectedFacility, refetchOnMountOrArgChange: true }
  );
  const [previousDeals, setPreviousDeals] = useState<Deal[] | undefined>(deals);
  const { lastInteractionTime, startedFollowUp } = useAppSelector(selectDealNotification);
  const [timer, setTimer] = useState<Timeout|undefined>();
  const { play } = useSound("/notification.mp3");

  const dispatch = useAppDispatch();

  const [pulse, setPulse] = useState(true);
  const [displayAlerts, setDisplayAlerts] = useState(false);
  const { classes } = useStyles({ pulse });

  //play sound notification if new deal comes in
  useEffect(() => {
    const hasNewDeal = deals?.some((deal: Deal) => !previousDeals?.find((pd) => pd.id === deal.id));
    if (hasNewDeal && previousDeals && previousDeals?.length > 0) {
      play();
    }
    setPreviousDeals(deals);
  }, [deals]);

  // timer module for follow up notification modal.
  useEffect(() => {
    const setupTimer = () => {
      const newTimer = setTimeout(() => {
        if (deals) {
          const dealsWithNoFollowUp = deals.filter(
            (deal) => !startedFollowUp.some((followUpId) => followUpId === deal.id)
          );

          const lastDeal = dealsWithNoFollowUp.pop();

          if (lastDeal) {
            dispatch(setSelectedDealNotification(lastDeal));
            dispatch(setIsDisplayed(SoftphoneDisplayMode.DisplayFollowUp));
          }
        }
      }, 300000);

      setTimer((prevTimer) => {
        if (prevTimer) {
          clearInterval(prevTimer);
        }
        return newTimer;
      });
    };

    setupTimer();
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [lastInteractionTime, deals, startedFollowUp, dispatch]);

  const showDealNotifications = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setPulse(false);
    setDisplayAlerts(true);
  };

  const hideDealNotifications = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setPulse(true);

    setDisplayAlerts(false);
  };

  return deals && deals.length > 0
    ? (
      <div
        className={classes.pulse}
        onMouseEnter={(e) => showDealNotifications(e)}
        onMouseLeave={(e) => hideDealNotifications(e)}
      >
        {displayAlerts
          ? (
            <DealChips deals={deals} pulse={pulse} />
            )
          : (
            <>
              <span className={classes.pulseSpan} />
              <span />
              <span />
              <span />
              <FabIcon />
            </>
            )}
      </div>
      )
    : null;
};

export default FabPulsatingButton;
