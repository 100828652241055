import axios from "axios";

export const identityInstance = axios.create({
  baseURL: window.config.identityApiUri,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest"
  }
});

identityInstance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("bearerToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

identityInstance.interceptors.response.use(
  (resp) => {
    return resp;
  },
  (err) => {
    if (err.response.status === 401) {
      sessionStorage.removeItem("bearerToken");
      sessionStorage.removeItem("refreshToken");
      window.location.assign(window.config.identityFrontend);
    }

    return Promise.reject(err);
  }
);
