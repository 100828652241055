import { GridSortModel } from "@mui/x-data-grid";
import { useState } from "react";

export interface PMSPaginationValues {
  page: number;
  pageSize: number;
  sortModel: GridSortModel;
}

export interface PMSPagination {
  values: PMSPaginationValues;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleTotalChange: (total: number) => void;
  handleSortModelChange: (sortModel: GridSortModel) => void;
}

const usePagination = (): PMSPagination => {
  const [pagination, setPagination] = useState({ page: 0, pageSize: 5, sortModel: <GridSortModel>[] });

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, page: 0, pageSize: newPageSize }));
  };

  const handleTotalChange = (total: number) => {
    setPagination((prev) => ({ ...prev, total: total }));
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    setPagination((prev) => ({ ...prev, sortModel: sortModel }));
  };

  return { values: pagination, handlePageChange, handlePageSizeChange, handleTotalChange, handleSortModelChange };
};

export default usePagination;
