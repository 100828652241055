import { MerchandiseValues } from "../../../../models/formikInputValues/MerchandiseValues";
import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";
import { PurchaseMerchandisePayload } from "./PurchaseMerchandisePayload";
import { PurchasedMerchandiseItem } from "src/models/MerchandiseItem";
import { PaymentMethod } from "src/enums/PaymentMethod";

export const purchaseMerchandise = createAsyncThunk(
  "purchaseMerchandise",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const facilityId = state.selectedFacility.selectedFacility.id;
    const selectedMerchItemIds = state.pointOfSales.merchLineItemIds;
    const merchandise = state.merchandiseItems.merchandiseItems;
    const selectedMerchItems = merchandise.filter(merchItems => selectedMerchItemIds.includes(merchItems.id));
    const purchasedMerchItemsArray: PurchasedMerchandiseItem[] = selectedMerchItems.map((merchItem) => ({
      id: merchItem.id,
      quantity: merchItem.quantity_for_cart
    }));
    const occupantId = state.selectedOccupant.selectedOccupant?.id;

    const amountsReceived = [
      state.reviewAndPayCash.cashReceived
    ];

    const paymentAmount = amountsReceived[state.payment.paymentType - 1] ?? state.moveOutCost.final_balance;

    const payload: PurchaseMerchandisePayload = {
      occupant_id: occupantId,
      merchandise: purchasedMerchItemsArray,
      payment_amount: parseFloat(paymentAmount),
      payment_method: state.payment.paymentType
    };

    if (payload.payment_method === PaymentMethod.creditCard || payload.payment_method === PaymentMethod.ach) {
      payload.billing = {
        first_name: "",
        last_name: "",
        enable_autobill: false
      };

      if (payload.payment_method === PaymentMethod.creditCard) {
        payload.billing.first_name = state.reviewAndPayBillingAddress.firstName;
        payload.billing.last_name = state.reviewAndPayBillingAddress.lastName;
        payload.billing.card_number = state.reviewAndPayCC.creditCardNumber;
        payload.billing.exp_month = state.reviewAndPayCC.expDate.substring(0, 2);
        payload.billing.exp_year = state.reviewAndPayCC.expDate.substring(2, 4);
        payload.billing.cvv = state.reviewAndPayCC.cvv;
        payload.payment_amount = state.pointOfSales.finalBalance;
        payload.billing.credit_card_type = state.reviewAndPayCC.creditCardType;
      }
    }

    return axios.post(`/api/v1/facilities/${facilityId}/merchandise/purchase`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Merchandise created successfully",
          variant: "success"
        }));
        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = `There was an error creating creating the purchase`;

        if (err?.response?.status === 400) {
          errorMessage += `: ${err.response.data.detail}`;
        }

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
