import { makeStyles } from "../../makeStyles";

const useStyles = makeStyles({ label: "Manage Webhooks" })((theme) => ({
  cellTitle: {
    color: theme.palette.primary.contrastText,
    fontStyle: "italic"
  },
  description: {
    margin: `0 ${theme.spacing(2)}`
  },
  button: {
    textTransform: "uppercase",
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.common.white
  }
}));

export default useStyles;
