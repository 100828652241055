import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Tooltip, Typography } from "@mui/material";
import { MerchandiseCategory } from "src/models/MerchandiseCategory";
import ProcessMerchQuantityIncrementor
  from "../../../ChooseRetailMerch/ProcessMerchQuantityIncrementor/ProcessMerchQuantityIncrementor";
import React from "react";

const ReviewAndPayQuantityTableColumns = (merchandiseCategories: MerchandiseCategory[]): GridColDef[] => {
  const showMerchandiseCategory = (id: number) => merchandiseCategories.length
    ? merchandiseCategories.find(merchCategory => merchCategory.id === id)?.name
    : "N/A";

  return [
    {
      field: "name",
      renderHeader: () => <Tooltip title={"Product"}><Typography>Product</Typography></Tooltip>,
      flex: 1
    },
    {
      field: "sku",
      renderHeader: () => <Tooltip title={"SKU"}><Typography>SKU</Typography></Tooltip>,
      flex: 1,
      valueGetter: ({ row }) => "0001"
    },
    {
      field: "merchandise_category_id",
      renderHeader: () => <Tooltip title={"Category"}><Typography>Category</Typography></Tooltip>,
      flex: 1,
      valueGetter: ({ row }) => showMerchandiseCategory(row.merchandise_category_id)
    },
    {
      getApplyQuickFilterFn: undefined,
      field: "quantity_for_cart",
      renderHeader: () => <Tooltip title={"Quantity"}><Typography>Quantity</Typography></Tooltip>,
      flex: 1,
      minWidth: 180,
      type: "number",
      sortComparator: (a: string, b: string) =>
        Number(a) - Number(b),
      renderCell: ({ row, value }: GridRenderCellParams) => <ProcessMerchQuantityIncrementor row={row} value={value} />
    },
    {
      field: "price",
      maxWidth: 100,
      renderHeader: () => <Tooltip title={"Price"}><Typography>Price</Typography></Tooltip>,
      type: "number",
      flex: 1,
      sortComparator: (a: string, b: string) =>
        Number(a.slice(1)) - Number(b.slice(1)),
      valueGetter: ({ row }) => `$${parseFloat(row.price).toFixed(2)}`
    }
  ];
};

export default ReviewAndPayQuantityTableColumns;
