import React, { ReactElement } from "react";
import { selectECInformationLoading, setEmergencyContact }
  from "../../../../store/reducers/emergencyContactInformationSlice/emergencyContactInformationSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import ECIcons from "../../../../components/stepper/EmergencyContactIcons/ECIcons";
import EmergencyContact from "../../../../components/stepper/EmergencyContact/EmergencyContact";
import { EmergencyContactValues } from "../../../../models/formikInputValues/occupant/EmergencyContactValues";
import PMSAdvisory from "../../../../components/ui/PMSAdvisory/PMSAdvisory";
import { Paper } from "@mui/material";
import StepLayout from "../../../../layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "../../../../layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import { createEmergencyContact } from "../../../../store/thunks/emergencyContact/create/createEmergencyContact";
import { createOccupant } from "../../../../store/thunks/occupant/create/createOccupant";
import { useNavigate } from "react-router";
import { selectOccupantDetailsLoading }
  from "../../../../store/reducers/occupantInformationSlice/occupantInformationSlice";
import { showSnackbar } from "../../../../store/reducers/snackbarSlice/snackbarSlice";

const AddEmergencyContact: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const occupantLoading = useAppSelector(selectOccupantDetailsLoading);
  const ecLoading = useAppSelector(selectECInformationLoading);
  const loading = occupantLoading || ecLoading;

  const stepperId = "emergency-contact";

  const handleSubmit = async(values: EmergencyContactValues) => {
    await dispatch(setEmergencyContact(values));
    await dispatch(createOccupant())
      .then((resp) => {
        if (resp.type.includes("rejected")) {
          throw new Error("There was an error creating the Occupant");
        }
        dispatch(createEmergencyContact(resp.payload.id))
          .then((resp) => {
            if (resp.type.includes("rejected")) {
              dispatch(showSnackbar({
                message: "There was an error creating the Emergency Contact",
                variant: "error"
              }));
              throw new Error("There was an error creating the Emergency Contact");
            } else {
              dispatch(showSnackbar({
                message: "Occupant successfully added",
                variant: "success"
              }));
              navigate("/occupants/manage-occupants");
            }
          });
      });
  };

  return (
    <>
      <PMSAdvisory
        title={"Emergency Contact Information"}
        advisoryText={"Please add the following information for the occupant’s emergency contact."}
        icons={<ECIcons />}
      />
      <Paper elevation={1}>
        <StepLayout
          stepperId={stepperId}
          buttons={
            <StepperButtons
              data-testid={"emergency-contact-buttons"}
              stepperId={stepperId}
              loading={loading}
              forwardActionText={"Finish"}
            />
          }
        >
          <EmergencyContact data-testid={"ec-form"} stepperId={stepperId} onSubmitHandler={handleSubmit} />
        </StepLayout>
      </Paper>
    </>
  );
};

export default AddEmergencyContact;
