import { RootState } from "src/store/rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSearchParams } from "../../../../utils/getSearchParams/getSearchParams";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";
import moment from "moment";

export const getTasks = createAsyncThunk(
  "getTasks", ({ getNotifications }: { getNotifications: boolean }, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;

    const searchValue = state.tasks.tasksSearchValue;
    const filter = state.tasks.tasksFilter;
    const facility_id = state.selectedFacility.selectedFacility.facility_id;

    const params = {
      params: {
        ...getSearchParams(state, true),
        with: ["taskCategory", "assignees"],
        sortBy: "name",
        "filter[name]": searchValue
      }
    };

    if (filter) {
      params.params = { ...params.params, ...filter };
    }

    const notificationsParams = {
      params: {
        ...getSearchParams(state, true),
        with: ["taskCategory", "assignees"],
        sortBy: "due_date",
        sortDirection: "asc",
        "filter[is_completed]": 0,
        "filter[assignees:facility_id]": facility_id,
        "filter[due_date]": `lte:${moment().format("yyyy-MM-DD")}`
      }
    };

    const getParams = getNotifications ? notificationsParams : params;

    return axios.get("/api/v1/tasks", getParams)
      .then((resp) => {
        return { getNotifications, ...resp.data };
      })
      .catch(() => {
        const errorMessage = `There was an error getting the Tasks`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
