import { RevenueClass } from "src/models/RevenueClass";
import { RootState } from "src/store/rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const enableOrDisableRevenueClass = createAsyncThunk(
  "enableOrDisableRevenueClass",
  (revenueClass: RevenueClass, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const facilityId = state.selectedAdminFacility.selectedAdminFacility!.id;
    const payload = {
      is_active: !revenueClass.is_active
    };

    return axios.post(`/api/v1/facilities/${facilityId}/revenue-classes/${revenueClass.id}/enabled`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: `${revenueClass.name} successfully ${!revenueClass.is_active ? "enabled" : "disabled"}`,
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        if (err.response.status === 422) {
          if (err.response.data.detail) return rejectWithValue(err.response.data.detail);
          const errorMessage = format422(err.response.data.errors);

          return rejectWithValue(errorMessage);
        }

        const errorMessage = "There was an error updating the Revenue Class";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
