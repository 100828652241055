import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Build Report" })((theme) => ({
  sectionPadding: {
    padding: 4
  },
  borderRadius: {
    borderRadius: "10px"
  }
}
));

export default useStyles;
