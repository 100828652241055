import { FormControlLabel, FormControlLabelProps, FormGroup, Switch, SwitchProps } from "@mui/material";
import React, { ChangeEvent, ReactElement, ReactNode } from "react";

import useStyles from "./PMSSwitch.styles";

interface PMSSwitchProps {
    name?: string
    changeHandler: (event: ChangeEvent<any>, child: ReactNode) => void
    isChecked: boolean
    isDisabled?: boolean
    value?: boolean
    labelPlacement?: FormControlLabelProps["labelPlacement"]
    smallText?: boolean
    size?: SwitchProps["size"]
    typographyNoWrap?: boolean
    label?: string
}

const PMSSwitch: React.FC<PMSSwitchProps> = ({
  name,
  changeHandler,
  size = "small",
  isChecked,
  smallText = false,
  isDisabled = false,
  typographyNoWrap = false,
  label = "",
  labelPlacement = "end",
  value
}: PMSSwitchProps): ReactElement => {
  const { classes } = useStyles({ smallText: smallText });

  return (
    <FormGroup>
      <FormControlLabel
        data-testid={`${name}-switch-label`}
        htmlFor={name}
        color={"black"}
        className={classes.root}
        componentsProps={{ typography: { noWrap: typographyNoWrap } }}
        labelPlacement={labelPlacement}
        control={
          <Switch
            id={name}
            data-testid={`${name}-switch`}
            value={value}
            disabled={isDisabled}
            checked={isChecked}
            name={name}
            size={size}
            onChange={changeHandler}
        />
        }
        label={label}
        />
    </FormGroup>
  );
};

export default PMSSwitch;
