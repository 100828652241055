import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import { MoveInConfirmation } from "src/models/MoveInConfirmation";
import { Promotion } from "src/models/Promotion";
import { Unit } from "src/models/Unit";
import { selectMoveInConfirmation } from "src/store/reducers/moveInSlice/moveInSlice";
import { formattedAmount } from "src/utils/formattedAmount/formattedAmount";
import { getUnitSizeFromUnit } from "src/utils/getUnitSize/getUnitSize";
import useStyles from "../TransferUnitConfirmation.styles";
import { formatDate } from "src/utils/__dateAndTimeUtils__/formatDate/formatDate";
import { Ledger } from "src/models/Ledger";

type TransferUnitConfirmationTableProps = {
    unit?: Unit
    ledger?: Ledger
    promotion?: Promotion | null
}

const TransferUnitConfirmationTable = ({ unit, ledger, promotion }: TransferUnitConfirmationTableProps) => {
  const { classes } = useStyles();
  return (
    <Grid xs={8} mt={2} className={classes.table}
      item>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align={"left"}>Unit Number</TableCell>
            <TableCell align={"center"}>Unit Size</TableCell>
            <TableCell align={"center"}>Floor</TableCell>
            <TableCell align={"center"}>Deal Rate</TableCell>
            <TableCell align={"center"}>Move In Date</TableCell>
            <TableCell align={"center"}>Applied Discount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align={"left"}>{unit?.unit_number}</TableCell>
            <TableCell align={"center"}>
              {getUnitSizeFromUnit(unit)}
            </TableCell>
            <TableCell align={"center"}>{unit?.floor}</TableCell>
            <TableCell align={"center"}>
              {formattedAmount("Fixed Amount", ledger?.monthly_rate.toString()!)}
            </TableCell>
            <TableCell align={"center"}>{formatDate(ledger?.moved_in_at || "")}</TableCell>
            <TableCell align={"center"}>{promotion?.name ?? "None"}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
};

export default TransferUnitConfirmationTable;
