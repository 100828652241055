import { Deal } from "../../models/Deal";

export function getProductTypeName(deal: Deal) {
  if (deal.product_type || deal.unit?.product_type) {
    if (deal.product_type) {
      return deal.product_type.name;
    }

    if (deal.unit && deal.unit.product_type) {
      return deal.unit.product_type.name;
    }
  }

  return "N/A";
}
