import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { APIListResponse } from "../../../models/responses/APIListResponse";
import { RootState } from "../../rootStore";
import { Task } from "../../../models/Task";
import { TasksSliceState } from "./TasksSliceState";
import { getAllTasksForCalendar } from "../../thunks/task/getAllForCalendar/getAllTasksForCalendar";
import { getTasks } from "src/store/thunks/task/getAll/getTasks";

export const initialState: TasksSliceState = {
  tasks: [],
  calendarTasks: [],
  tasksFilter: "",
  tasksSearchValue: "",
  tasksLoading: false,
  tasksError: ""
};

export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setTasksFilter: (state, action) => {
      state.tasksFilter = action.payload;
    },
    setTasksSearchValue: (state, action) => {
      state.tasksSearchValue = action.payload;
    },
    resetTasksSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTasks.pending, (state) => {
        state.tasksLoading = true;
      })
      .addCase(getTasks.fulfilled, (state, action) => {
        state.tasksLoading = false;
        state.tasks = action.payload.data;
      })
      .addCase(getTasks.rejected, (state, action) => {
        state.tasksLoading = false;
        state.tasksError = action.payload as string;
      })
      .addCase(getAllTasksForCalendar.pending, (state) => {
        state.tasksLoading = true;
      })
      .addCase(getAllTasksForCalendar.fulfilled, (state, action: PayloadAction<APIListResponse<Task[]>>) => {
        state.tasksLoading = false;
        state.calendarTasks = action.payload.data;
      })
      .addCase(getAllTasksForCalendar.rejected, (state, action) => {
        state.tasksLoading = false;
        state.tasksError = action.payload as string;
      });
  }
});

export const selectTasks = (state: RootState) => state.tasks.tasks;
export const selectTasksFilter = (state: RootState) => state.tasks.tasksFilter;
export const selectCalendarTasks = (state: RootState) => state.tasks.calendarTasks;
export const selectTasksLoading = (state: RootState) => state.tasks.tasksLoading;
export const selectTasksSearchValue = (state: RootState) => state.tasks.tasksSearchValue;

export const {
  setTasksFilter,
  setTasksSearchValue,
  resetTasksSlice
} = tasksSlice.actions;

export default tasksSlice.reducer;
