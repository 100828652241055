import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getLeasePackage = createAsyncThunk(
  "getLeasePackage",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const ledgerId = state.moveIn.moveInConfirmation?.ledger.id;

    return axios.get(`/api/v1/esign-documents/get-lease-package/${ledgerId}`)
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        let errorMessage = "There was an error getting the Lease Package";

        if (err &&
          err.response &&
          err.response.status &&
          err.response.status === 422 &&
          err.response.data.errors &&
          Object.keys(err.response.data.errors).length
        ) {
          errorMessage = format422(err.response.data.errors);
        } else if (err && err.response && err.response.data && err.response.data.detail) {
          errorMessage += `: ${err.response.data.detail}`;
        }

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
