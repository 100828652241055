import { Divider, Grid, GridProps, Paper, PaperProps, Typography } from "@mui/material";
import React, { ComponentType, FC, ReactNode } from "react";

interface ReportsPaperProps{
  category?: string,
  children: ReactNode[] | ReactNode
}

interface ExtendedGridProps extends GridProps{
  component: ComponentType<PaperProps>
  variant: PaperProps["variant"]
};

const gridPaperProps:ExtendedGridProps = {
  container: true,
  component: Paper,
  p: 4,
  minHeight: "45rem",
  maxHeight: "45rem",
  variant: "outlined"
};

const ReportsPaper:FC<ReportsPaperProps> = ({ category = "Report Category", children }) => {
  const innerGridStyles = {
    overflow: "auto",
    maxHeight: "37.5rem",
    mt: 1
  };

  return (
    <Grid {...gridPaperProps}>
      <Grid alignContent={"flex-start"} container spacing={2}>
        <Grid item xs={12}>
          <Typography textAlign={"center"} variant={"h5"}>
            {category}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider/>
        </Grid>
        <Grid
          style={innerGridStyles}
          item
          xs={12}
        >
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportsPaper;
