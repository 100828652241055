import { FormControlLabel, Radio } from "@mui/material";
import React, { ChangeEvent } from "react";

import { CheckCircleOutline } from "@mui/icons-material";
import PMSRadio from "src/components/ui/PMSRadio/PMSRadio";
import { PaymentMethod as PaymentMethodEnum } from "../../../../../enums/PaymentMethod";

const items = [
  { value: PaymentMethodEnum.cash, label: "Cash" },
  { value: PaymentMethodEnum.moneyOrder, label: "Check/Money Order" },
  { value: PaymentMethodEnum.creditCard, label: "Credit Card" }
  // { value: PaymentMethodEnum.ach, label: "eCheck/ACH Payment" }
];

const PaymentMethod = ({
  selectedOption,
  onChange,
  paymentOptions = []
}: {
  selectedOption: PaymentMethodEnum;
  paymentOptions?: PaymentMethodEnum[];
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const getPaymentMethodItems = () => {
    return items
      .filter((item) => paymentOptions.includes(item.value) || !paymentOptions.length)
      .map((item) => (
        <FormControlLabel
          key={item.value}
          value={item.value}
          control={<Radio checkedIcon={<CheckCircleOutline />} />}
          label={item.label}
        />
      ));
  };

  return (
    <PMSRadio value={selectedOption.toPrecision()} name={"payment-method"} changeHandler={onChange}>
      {getPaymentMethodItems()}
    </PMSRadio>
  );
};

export default PaymentMethod;
