import { CheckCircleOutline } from "@mui/icons-material";
import { FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, Radio, RadioGroup } from "@mui/material";
import React, { ReactNode } from "react";
import { useController } from "react-hook-form";

interface FormRadioGroupProps {
  label?: string;
  children: ReactNode;
  name: string;
  valuesAsNumber?: boolean;
}

const FormRadioGroupOption = ({ value, label }: { value: any; label: string }) => {
  return <FormControlLabel value={value} control={<Radio checkedIcon={<CheckCircleOutline />} />} label={label} />;
};

const FormRadioGroup = ({ label, children, name, valuesAsNumber }: FormRadioGroupProps) => {
  const {
    field,
    fieldState: { error }
  } = useController({ name: name });
  return (
    <Grid item width={"100%"}>
      {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
      <FormControl>
        <RadioGroup
          {...field}
          onChange={(e) => (valuesAsNumber ? field.onChange(+e.target.value) : field.onChange(e))}
          data-testid={`${name}-group`}
          id={`${name}-group`}
          aria-label={name}
        >
          {children}
        </RadioGroup>
      </FormControl>
      {error?.message && (
        <FormHelperText error style={{ marginLeft: "14px" }}>
          {error.message}
        </FormHelperText>
      )}
    </Grid>
  );
};
FormRadioGroup.Option = FormRadioGroupOption;

export default FormRadioGroup;
