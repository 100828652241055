import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { axiosInstance as axios } from "../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const applyFees = createAsyncThunk(
  "applyFees",
  (
    { occupantId, ledgerId, fees }: { occupantId: string; ledgerId: string; fees: string[] | number[] },
    { dispatch, getState, rejectWithValue }
  ) => {
    return axios
      .post(`/api/v1/occupants/${occupantId}/ledgers/${ledgerId}/apply-fees`, { fees: fees })
      .then((resp) => {
        return resp.data;
      })
      .then(() => {
        dispatch(
          showSnackbar({
            message: "Fee(s) applied successfully",
            variant: "success"
          })
        );
      })
      .catch(() => {
        const errorMessage = `There was an error applying the apply fees`;

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  }
);
