import { Box, Divider, Grid, Paper, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import React, { PropsWithChildren, ReactElement, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  resetSelectedOccupantSlice,
  selectSelectedOccupant
} from "src/store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { ArrowForward } from "@mui/icons-material";
import {
  resetPointOfSalesSlice,
  selectMerchLineItemIds,
  selectPosModalOpen,
  selectPurchaseLoading,
  setPosMerchItemLineIds,
  setPosModalOpen
} from "src/store/reducers/pointOfSalesSlice/pointOfSalesSlice";
import { resetPaymentSlice } from "src/store/reducers/paymentSlice/paymentSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { AppDispatch } from "src/store/rootStore";
import AppModal from "src/components/ui/AppModal/AppModal";
import { Breadcrumb } from "src/models/Breadcrumb";
import Buttons from "./components/Buttons/Buttons";
import MerchandiseSummary from "./MerchandiseSummary/MerchandiseSummary";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import formatPhone from "src/utils/formatPhone/formatPhone";
import { resetMerchandiseItemsSlice } from "src/store/reducers/merchandiseItemsSlice/merchandiseItemsSlice";
import { blockNavigation } from "src/components/router/CustomRouter";
import { setOccupantsSearchValue } from "src/store/reducers/occupantsSlice/occupantsSlice";
import { resetTransactionSlice } from "src/store/reducers/transactionSlice/transactionSlice";
import { resetCreditCardSlice } from "src/store/reducers/creditCardSlice/creditCardSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Merchandise"
  },
  {
    name: "Point of Sales",
    bold: true
  }
];

const secondaryBreadcrumbs: Breadcrumb[] = [
  {
    name: "Choose Retail Merchandise"
  },
  {
    name: "Review & Pay"
  },
  {
    name: "Merchandise Summary"
  }
];

export const handleReset = (dispatch: AppDispatch) => {
  dispatch(resetSelectedOccupantSlice());
  dispatch(setPosModalOpen(false));
  dispatch(setPosMerchItemLineIds([]));
  dispatch(resetMerchandiseItemsSlice());
  dispatch(resetPointOfSalesSlice());
};

const PointOfSales: React.FC<PropsWithChildren> = ({ children, ...props }): ReactElement => {
  const pageHeader = <PageHeader title={"Point of Sales"} breadcrumbs={breadcrumbs} />;

  const selectedOccupant = useAppSelector(selectSelectedOccupant);

  const selectedMerchItemIds = useAppSelector(selectMerchLineItemIds);

  const dispatch = useAppDispatch();

  const modalOpen = useAppSelector(selectPosModalOpen);

  const pageLocation = useLocation();

  const [crumbPathValue, setCrumbPathValue] = useState(0);

  const navigate = useNavigate();

  const last = pageLocation.pathname.split("/").pop();

  const [localTargetPath, setLocalTargetPath] = React.useState<string>("");

  const formId = "point-of-sales-payment-form";

  const purchaseLoading = useAppSelector(selectPurchaseLoading);

  const onReviewAndPaySubmit = (e: React.FormEvent<HTMLFormElement>) => {
    dispatch(setPosMerchItemLineIds([]));
    dispatch(resetSelectedOccupantSlice());
    navigate("/merchandise/point-of-sales/merchandise-summary");
  };

  let unblock: Function;

  const handleReset = (dispatch: AppDispatch) => {
    dispatch(resetSelectedOccupantSlice());
    dispatch(setPosModalOpen(false));
    dispatch(resetPointOfSalesSlice());
    dispatch(resetPaymentSlice());
    dispatch(resetMerchandiseItemsSlice());
    dispatch(setOccupantsSearchValue(""));
  };

  const handleAdvisory = () => {
    return blockNavigation(({ location }) => {
      const nextLocation = location.pathname;
      const currentLocation = pageLocation.pathname;
      setLocalTargetPath(nextLocation);

      const workflowComplete = currentLocation === "/merchandise/point-of-sales/merchandise-summary";

      const restartingWorkflow =
        nextLocation !== "/merchandise/point-of-sales/choose-merch" &&
        nextLocation !== "/merchandise/point-of-sales/review-and-pay" &&
        nextLocation !== "/merchandise/point-of-sales/merchandise-summary";

      const cancelWorkflow =
        currentLocation.includes("/merchandise/point-of-sales") && nextLocation.includes("/merchandise/point-of-sales");

      if (cancelWorkflow) {
        setLocalTargetPath("/merchandise/point-of-sales");
      }

      if (
        // you're not on the first step
        currentLocation !== "/merchandise/point-of-sales" &&
        // and you aren't navigating b/w steps or completely restarting the workflow
        (!nextLocation.includes("merchandise/point-of-sales") || restartingWorkflow) &&
        // and you have not completed the move-in
        !workflowComplete
        // then show the advisory modal
      ) {
        dispatch(setPosModalOpen(true));
        return false;
      }

      unblock();
      navigate(nextLocation);
    });
  };

  const xs = useMediaQuery("(min-width: 1200px)");

  const showStepRelevantComponents = last === "choose-merch" || last === "review-and-pay";

  useEffect(() => {
    if (last === "choose-merch") {
      return setCrumbPathValue(0);
    }

    if (last === "review-and-pay") {
      return setCrumbPathValue(1);
    }

    if (last === "merchandise-summary") {
      return setCrumbPathValue(2);
    }
  }, [pageLocation]);

  useEffect(() => {
    if (last !== "point-of-sales") {
      if (last === "choose-merch" || last === "review-and-pay" || last === "merchandise-summary") {
        return;
      }

      navigate("/merchandise/point-of-sales");
    }
  }, []);

  useEffect(() => {
    unblock = handleAdvisory();

    return () => {
      if (unblock) {
        unblock();
      }
    };
  });

  const handleNavigationAway = () => {
    if (unblock) {
      unblock();
    }

    handleReset(dispatch);
    navigate(localTargetPath);
    dispatch(setPosModalOpen(false));
  };

  useEffect(() => {
    dispatch(resetSelectedOccupantSlice());

    return () => {
      dispatch(resetCreditCardSlice());
      handleReset(dispatch);
    };
  }, []);

  return (
    <ViewWrapper pageHeader={pageHeader} {...props}>
      {showStepRelevantComponents && (
        <Box pb={2}>
          <Tabs value={crumbPathValue}>
            {secondaryBreadcrumbs.map((breadcrumb, i) => {
              const first = i === 0;
              const iconProps = {
                icon: <ArrowForward />,
                iconPosition: "start",
                sx: {
                  "padding-left": "8px",
                  "& .MuiSvgIcon-root": {
                    "margin-right": "16px"
                  }
                }
              };
              const props = first ? {} : iconProps;
              return (
                <Tab
                  disableRipple={true}
                  key={breadcrumb.name}
                  data-testid={`${i}-secondary-breadcrumb-text`}
                  label={breadcrumb.name}
                  disabled={i++ > crumbPathValue}
                  {...props}
                />
              );
            })}
            ;
          </Tabs>
        </Box>
      )}
      <Grid direction={xs ? "row" : "column-reverse"} container spacing={2}>
        <Grid
          item
          xs={12}
          lg={showStepRelevantComponents && (selectedMerchItemIds.length || selectedOccupant) ? 9 : 12}
        >
          {children}
        </Grid>
        {showStepRelevantComponents && (
          <Grid item xs={12} lg={3}>
            {selectedMerchItemIds.length ? <MerchandiseSummary /> : undefined}
            {selectedOccupant && (
              <Paper component={Grid} container p={2}
                mt={selectedMerchItemIds.length && 2} variant={"outlined"}>
                <Grid item xs={12}>
                  <Typography variant={"h5"}>Occupant Information</Typography>
                </Grid>
                <Grid py={1} item xs={12}>
                  <Divider />
                </Grid>
                <Grid py={1} container direction={"row"}
                  item xs={12}>
                  <Typography fontWeight={600}>Occupant Name: &nbsp;</Typography>
                  <Typography>
                    {selectedOccupant?.first_name}&nbsp;
                    {selectedOccupant?.middle_name ?? selectedOccupant?.middle_name} {selectedOccupant?.last_name}
                  </Typography>
                </Grid>
                <Grid py={1} container direction={"row"}
                  item xs={12}>
                  <Typography fontWeight={600}>Phone: &nbsp;</Typography>
                  <Typography>{formatPhone(selectedOccupant?.phone_primary)}</Typography>
                </Grid>
              </Paper>
            )}
            {(selectedMerchItemIds.length || selectedOccupant) && (
              <Buttons
                formId={formId}
                onSubmit={onReviewAndPaySubmit}
                disabledNextButton={!selectedMerchItemIds.length || purchaseLoading}
              />
            )}
          </Grid>
        )}
      </Grid>
      <AppModal
        open={modalOpen}
        cancelAction={() => dispatch(setPosModalOpen(false))}
        confirmAction={() => handleNavigationAway()}
        cancelButtonText={"No"}
        confirmButtonText={"Yes"}
      >
        <Typography gutterBottom variant={"body1"} textAlign={"center"}>
          All information will be lost.
        </Typography>
        <Typography variant={"body1"} textAlign={"center"}>
          Are you sure you want to cancel this transaction?
        </Typography>
      </AppModal>
    </ViewWrapper>
  );
};

export default PointOfSales;
