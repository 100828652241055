/* eslint-disable max-len */
import CreateMerchandise from "../pages/Merchandise/CreateMerchandise/CreateMerchandise";
import EditMerchandise from "../pages/Merchandise/EditMerchandise/EditMerchandise";
import Merchandise from "../pages/Merchandise/Merchandise";
import MerchandiseRefundReceipt from "src/pages/Merchandise/RefundMerchandise/MerchandiseRefundReceipt/MerchandiseRefundReceipt";
import React from "react";
import RefundMerchandise from "src/pages/Merchandise/RefundMerchandise/RefundMerchandise";
import { Route } from "react-router";
import SummaryOfSales from "../pages/Merchandise/SummaryOfSales/SummaryOfSales";

export const groupName = "merchandise";

export const MerchandiseRoutes = (
  <>
    <Route path={`${groupName}/manage-merchandise`} element={<Merchandise />} />
    <Route path={`${groupName}/create`} element={<CreateMerchandise />} />
    <Route path={`${groupName}/:id/edit`} element={<EditMerchandise />} />
    <Route path={`${groupName}/summary-of-sales`} element={<SummaryOfSales />} />
    <Route path={`${groupName}/refund-merchandise/:id`} element={<RefundMerchandise />} />
    <Route path={`${groupName}/refund-merchandise/receipt`} element={<MerchandiseRefundReceipt />} />
  </>
);

export default MerchandiseRoutes;
