import { Button, Divider, Grid, IconButton, InputLabel, Modal, Paper, TextField, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { AddOccupantModalFormValues } from "../../../../models/AddOccupantModalFormValues";
import { Close } from "@mui/icons-material";
import MaskedPhone from "../../../masking/MaskedPhone/MaskedPhone";
import addOccupantFormValidation from "./addOccupantFormValidation";
import clsx from "clsx";
import { inputError } from "../../../../utils/showInputError/showInputError";
import { useFormik } from "formik";
import useStyles from "./AddOccupantForm.styles";

interface AddOccupantFormProps {
  open: boolean;
  submitHandler: (values: AddOccupantModalFormValues) => void;
  cancelHandler: () => void;
}

const AddOccupantForm: React.FC<AddOccupantFormProps> = ({ open, submitHandler, cancelHandler }): ReactElement => {
  const { classes } = useStyles();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phone: ""
    },
    validationSchema: addOccupantFormValidation,
    onSubmit: (values) => submitHandler(values)
  });

  const { touched, values, errors, handleSubmit, handleChange, resetForm } = formik;

  const closeAndReset = () => {
    cancelHandler();
    resetForm();
  };

  return (
    <Modal open={open} data-testid={"add-occupant-form"} onClose={closeAndReset}
      className={classes.root}>
      <Grid component={Paper} item xs={12}
        container p={3}>
        {/* container */}
        <Grid item xs={12} onSubmit={handleSubmit}
          component={"form"}>
          {/* header row */}
          <Grid item xs={12} container
            justifyContent={"space-between"} alignItems={"center"}>
            <Typography variant={"h5"} component={"span"}>
              Add New Occupant
            </Typography>
            <IconButton data-testid={"close-fab"} onClick={closeAndReset}>
              <Close />
            </IconButton>
          </Grid>
          {/* end header row */}

          <Grid component={Divider} my={2} />

          <Typography variant={"subtitle1"}>Occupant Information</Typography>

          {/* form body */}
          <Grid container spacing={2}>
            <Grid item xs={5.5}>
              <InputLabel htmlFor={"first-name"}>First Name</InputLabel>
              <TextField
                fullWidth
                id={"first-name"}
                name={"firstName"}
                placeholder={"- First Name -"}
                value={values.firstName}
                onChange={handleChange}
                error={inputError("firstName", touched, errors).error}
                helperText={inputError("firstName", touched, errors).helperText}
              />
            </Grid>
            <Grid item xs={1}>
              <InputLabel htmlFor={"middle-name"}>M.I.</InputLabel>
              <TextField
                fullWidth
                id={"middle-name"}
                name={"middleName"}
                placeholder={"- M.I. -"}
                value={values.middleName}
                onChange={handleChange}
                error={inputError("middleName", touched, errors).error}
                helperText={inputError("middleName", touched, errors).helperText}
              />
            </Grid>
            <Grid item xs={5.5}>
              <InputLabel htmlFor={"last-name"}>Last Name</InputLabel>
              <TextField
                fullWidth
                id={"last-name"}
                name={"lastName"}
                placeholder={"- Last Name -"}
                value={values.lastName}
                onChange={handleChange}
                error={inputError("lastName", touched, errors).error}
                helperText={inputError("lastName", touched, errors).helperText}
              />
            </Grid>
            <Grid item xs={7}>
              <InputLabel htmlFor={"email"}>E-mail Address</InputLabel>
              <TextField
                fullWidth
                id={"email"}
                name={"email"}
                placeholder={"- E-mail Address -"}
                value={values.email}
                onChange={handleChange}
                error={inputError("email", touched, errors).error}
                helperText={inputError("email", touched, errors).helperText}
              />
            </Grid>
            <Grid item xs={5}>
              <InputLabel htmlFor={"phone"}>Primary Phone</InputLabel>
              <MaskedPhone
                fullWidth
                dataTestId={"phone"}
                id={"phone"}
                name={"phone"}
                placeholder={"- Primary Phone -"}
                value={values.phone}
                onChange={handleChange}
                error={inputError("phone", touched, errors).error}
                helperText={inputError("phone", touched, errors).helperText}
              />
            </Grid>
            <Grid item xs={12} mt={2}
              container justifyContent={"end"}>
              <Button
                size={"large"}
                variant={"text"}
                className={clsx(classes.button, classes.cancel)}
                onClick={closeAndReset}
              >
                Cancel
              </Button>
              <Button
                size={"large"}
                data-testid={"submit-button"}
                type={"submit"}
                variant={"contained"}
                className={clsx(classes.button, classes.save)}
              >
                Save
              </Button>
            </Grid>
          </Grid>
          {/* end form body */}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AddOccupantForm;
