import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MoveInCost } from "../../../models/MoveInCost";
import { MoveInCostSliceState } from "./MoveInCostSliceState";
import { RootState } from "../../rootStore";
import { TransferUnitCost } from "src/models/TransferUnitCost";
import { getMoveInCost } from "../../thunks/moveIn/getMoveInCost/getMoveInCost";
import { getTransferUnitCost } from "src/store/thunks/transfer/transferCost/getTransferUnitCost";
import { MoveItem } from "src/models/MoveItem";

export const initialState: MoveInCostSliceState = {
  rent: "0.00",
  promo_adjustments: "0.00",
  subtotal: "0.00",
  tax: "0.00",
  total_due_today: "0.00",
  next_payment: "0.00",
  next_payment_credit: "0.00",
  next_payment_date: "",
  fees: [],
  tpp_plan: null,
  security_deposit: "0.00",
  merchandise: [],
  additional_periods: "0.00",
  moveInCostLoading: false,
  moveInCostError: "",
  next_payment_tax_amount: "0.00",
  next_payment_promo_adjustments: "0.00",
  next_payment_tpp_plan: null,
  next_payment_fees: [],
  next_payment_subtotal: "0.00",
  remaining_credit: "0.00"
};

export const moveInCostSlice = createSlice({
  name: "moveInCost",
  initialState,
  reducers: {
    setTppPlan: (state, action: PayloadAction<MoveItem | null>) => {
      state.tpp_plan = action.payload;
    },
    setNextPaymentTppPlan: (state, action: PayloadAction<MoveItem | null>) => {
      state.next_payment_tpp_plan = action.payload;
    },
    resetMoveInCostSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMoveInCost.pending, (state) => {
        state.moveInCostLoading = true;
      })
      .addCase(getMoveInCost.fulfilled, (state, action: PayloadAction<MoveInCost>) => {
        state.moveInCostLoading = false;
        state.rent = parseFloat(action.payload.rent).toFixed(2);
        state.promo_adjustments = parseFloat(action.payload.promo_adjustments).toFixed(2);
        state.subtotal = parseFloat(action.payload.subtotal).toFixed(2);
        state.tax = parseFloat(action.payload.tax).toFixed(2);
        state.total_due_today = parseFloat(action.payload.total_due_today).toFixed(2);
        state.next_payment = parseFloat(action.payload.next_payment).toFixed(2);
        state.next_payment_date = action.payload.next_payment_date;
        state.fees = action.payload.fees;
        state.tpp_plan = action.payload.tpp_plan;
        state.security_deposit = action.payload.security_deposit;
        state.merchandise = action.payload.merchandise;
        state.additional_periods = parseFloat(action.payload.additional_periods).toFixed(2);
        state.next_payment_tax_amount = parseFloat(action.payload.next_payment_tax_amount).toFixed(2);
        state.next_payment_credit = parseFloat(`${action.payload?.next_payment_credit ?? 0}`).toFixed(2);
        state.next_payment_promo_adjustments = parseFloat(action.payload.next_payment_promo_adjustments).toFixed(2);
        state.next_payment_tpp_plan = action.payload.next_payment_tpp_plan;
        state.next_payment_fees = action.payload.next_payment_fees;
        state.next_payment_subtotal = action.payload.next_payment_subtotal;
        state.remaining_credit = action.payload.remaining_credit;
      });
  }
});

export const selectRent = (state: RootState) => state.moveInCost.rent;
export const selectPromoAdjustments = (state: RootState) => state.moveInCost.promo_adjustments;
export const selectSubtotal = (state: RootState) => state.moveInCost.subtotal;
export const selectTax = (state: RootState) => state.moveInCost.tax;
export const selectTotalDueToday = (state: RootState) => state.moveInCost.total_due_today;
export const selectNextPayment = (state: RootState) => state.moveInCost.next_payment;
export const selectNextPaymentDate = (state: RootState) => state.moveInCost.next_payment_date;
export const selectFees = (state: RootState) => state.moveInCost.fees;
export const selectMoveInCostMerchandise = (state: RootState) => state.moveInCost.merchandise;
export const selectInsurancePlan = (state: RootState) => state.moveInCost.tpp_plan;
export const selectNextPaymentInsurancePlan = (state: RootState) => state.moveInCost.next_payment_tpp_plan;
export const selectMerchandise = (state: RootState) => state.moveInCost.merchandise;
export const selectPreviousUnitBalance = (state: RootState) => state.moveInCost.previousUnitBalance;
export const selectAdditionalPeriods = (state: RootState) => state.moveInCost.additional_periods;
export const selectMoveInCostLoading = (state: RootState) => state.moveInCost.moveInCostLoading;
export const selectMoveInCostError = (state: RootState) => state.moveInCost.moveInCostError;
export const selectMoveInNextPaymentTaxAmount = (state: RootState) => state.moveInCost.next_payment_tax_amount;
export const selectNextPaymentPromoAdjustments = (state: RootState) => state.moveInCost.next_payment_promo_adjustments;
export const selectNextPaymentCredit = (state: RootState) => state.moveInCost.next_payment_credit;
export const selectMoveInNextPaymentFees = (state: RootState) => state.moveInCost.next_payment_fees;
export const selectRemainingCredit = (state: RootState) => state.moveInCost.remaining_credit;
export const selectSecurityDeposit = (state: RootState) => state.moveInCost.security_deposit;
export const selectMoveInNextPaymentSubtotalAmount = (state: RootState) => state.moveInCost.next_payment_subtotal;

export const {
  setTppPlan,
  setNextPaymentTppPlan,
  resetMoveInCostSlice
} = moveInCostSlice.actions;

export default moveInCostSlice.reducer;
