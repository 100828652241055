import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  reservationDateLimit: Yup.number().required("Limit is required"),
  dealDate: Yup.string().required("Limit is required"),
  moveInDate: Yup.string().required("Limit is required"),
  facilityReceiptTemplate: Yup.string().required("Facility Receipt Template is required"),
  facilityInvoiceTemplate: Yup.string().required("Facility Invoice Template is required"),
  quickPayEmailTemplate: Yup.string().required("Quick Pay Email Template is required"),
  facilityAutobillTemplate: Yup.string().required("Facility Autobill Template is required"),
  pastDueEmailTemplate: Yup.string().required("Past Due Email Template is required"),
  eSignEmailTemplate: Yup.string().required("ESign Email Template is required"),
  smsTemplate: Yup.string().required("SMS Template is required")
});

export default validationSchema;
