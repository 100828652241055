import { makeStyles } from "../../../../../makeStyles";

const useStyles = makeStyles({ label: "TaxesDisplay" })({
  taxesContainer: {
    display: "grid",
    "grid-template-columns": "repeat(4, auto minmax(75px, 9%))",
    "align-items": "center",
    "justify-content": "start",
    gap: "1rem",
    "@media (max-width: 1050px)": {
      "grid-template-columns": "repeat(2, auto minmax(75px, 0.4fr))",
      "justify-content": "space-around"
    }
  },
  taxRates: {
    "@media (min-width: 1165px)": {
      "grid-column": "2 / 5"
    }
  }
});

export default useStyles;
