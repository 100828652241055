import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import { DataGrid, GridCallbackDetails, GridCellEditCommitParams, GridRowId, GridSortModel } from "@mui/x-data-grid";
import { GetFlatUnitsResponse, useGetFlatUnitsLocalStateQuery, useUpdateUnitsMutation } from "src/api/endpoints/units";
import React, { FC, useMemo, useState } from "react";
import { Save, Search } from "@mui/icons-material";
import { createPermissions, selectFacilityPermissions } from "src/store/reducers/permissionsSlice/permissionsSlice";
import {
  selectOrder,
  selectOrderBy,
  selectPage,
  selectRowsPerPage,
  selectRowsPerPageOptions,
  setOrder,
  setOrderBy,
  setPage,
  setRowsPerPage
} from "src/store/reducers/tablesSlice/tablesSlice";
import {
  selectUnitsSearchValue,
  setBatchUnitUpdateModalOpen,
  setBatchUnitUploadModalOpen,
  setNewUnitModalOpen,
  setUnitsSearchValue
} from "src/store/reducers/unitsSliceNew/unitsSliceNew";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import ConfirmationModal from "./components/ConfirmationModal/ConfirmationModal";
import { FlatUnitChanges } from "../ManageUnits";
import SelectUploadUnitModal from "./components/SelectUploadUnitModal/SelectUnitUploadModal";
import { SyntheticInputEvent } from "react-number-format/types/types";
import UnrentableReasonModal from "./components/UnrentableReasonModal/UnrentableReasonModal";
import { isEqual } from "lodash";
import { selectProductTypeIdNew } from "src/store/reducers/productTypeSliceNew/productTypeSliceNew";
import useManageUnitsTableV3Columns from "./utils/useManageUnitsTableColumns";
import useStyles from "./ManageUnitsTable.styles";

type ManageUnitsTableProps = {};

export interface ModalState {
  isOpen: boolean,
  initialValue: boolean | null,
  editingId: number | null
}
const ManageUnitsTable: FC<ManageUnitsTableProps> = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
  const [editedRows, setEditedRows] = useState<FlatUnitChanges>({});
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
  const [updateUnits, { isLoading: isUpdateLoading }] = useUpdateUnitsMutation();
  const productTypeId = useAppSelector(selectProductTypeIdNew);
  const order = useAppSelector(selectOrder);
  const orderBy = useAppSelector(selectOrderBy);
  const pageSize = useAppSelector(selectRowsPerPage);
  const pageSizeOptions = useAppSelector(selectRowsPerPageOptions);
  const page = useAppSelector(selectPage);
  const permissions = useAppSelector(selectFacilityPermissions);

  const handleOpenNewUnitModal = () => {
    dispatch(setNewUnitModalOpen(true));
  };
  const handleBatchUploadUnitsModal = () => {
    dispatch(setBatchUnitUploadModalOpen(true));
  };
  const handleBatchUpdateUnitsModal = () => {
    dispatch(setBatchUnitUpdateModalOpen(true));
  };

  const unitsPermissions = createPermissions(permissions, "manage_units");
  const searchValue = useAppSelector(selectUnitsSearchValue);

  const handleSearchChange = (e: SyntheticInputEvent) => {
    setEditedRows({});
    setSelectedRows([]);
    dispatch(setUnitsSearchValue(e.target.value));
  };

  const params = useMemo(() => ({
    page: page + 1,
    pageSize,
    sortDirection: order,
    sortBy: orderBy,
    searchValue
  }), [page, pageSize, searchValue, order]);

  const { data, isLoading, isFetching } = useGetFlatUnitsLocalStateQuery({
    tableParams: params,
    productTypeId: productTypeId || undefined
  }, { refetchOnMountOrArgChange: true });

  const memoizedData = useMemo<GetFlatUnitsResponse | undefined>(() => data || undefined, [data]);

  const handleCellEditCommit = (params: GridCellEditCommitParams) => {
    const { id, field, value } = params;
    setEditedRows((prev) => {
      const newState:FlatUnitChanges = {
        ...prev,
        [id]: {
          ...prev[id as number],
          [field]: value
        }
      };

      const originalRow = memoizedData?.data.find(unit => unit.id === id);
      if (isEqual(originalRow, { ...originalRow, ...newState[id as number] })) {
        setSelectedRows(currentSelectedRows => currentSelectedRows.filter(row => row !== id));
      } else {
        setSelectedRows(currentSelectedRows => [...new Set([...currentSelectedRows, id])]);
      }

      return newState;
    });
  };

  const tableHeight = useMemo(() => {
    switch (pageSize) {
      case 5:
        return "40vh";
      case 25:
      case 50:
      default:
        return "80vh";
    }
  }, [pageSize]);

  const areThereUnsavedChanges = () => {
    return selectedRows.some(rowId => {
      const originalRow = data?.data.find(row => row.id === rowId);
      const editedRow = editedRows[rowId as number];
      return editedRow && !isEqual(originalRow, { ...originalRow, ...editedRow });
    });
  };

  const handlePageChange = (newPage: number) => {
    setEditedRows({});
    setSelectedRows([]);
    dispatch(setPage(newPage));
  };

  const saveButtonDisabled = useMemo(() => {
    return selectedRows.length === 0 || !areThereUnsavedChanges();
  }, [selectedRows, editedRows, data]);

  const handleSaveAttempt = async() => {
    setConfirmationModalOpen(true);
  };

  const handleChangeSortOrder = (model: GridSortModel, details:GridCallbackDetails) => {
    setEditedRows({});
    setSelectedRows([]);
    dispatch(setOrder(model[0].sort));
    dispatch(setOrderBy(model[0].field));
  };

  return (
    <Grid container spacing={2} item
      xs={12}>
      <Grid
        container
        spacing={2}
        alignItems={"center"}
        justifyContent={"flex-end"}
        item
        xs={12}
      >
        {unitsPermissions.create && (
          <Grid item xs={3} justifySelf={"flex-start"}>
            <SelectUploadUnitModal
              onAddNew={handleOpenNewUnitModal}
              onBatchAddNew={handleBatchUploadUnitsModal}
              onUpdateExisting={handleBatchUpdateUnitsModal}
            />
          </Grid>
        )}

        <Grid item xs={2} >
          <Button
            startIcon={<Save />}
            className={classes.saveButton}
            variant={"contained"}
            disabled={saveButtonDisabled}
            onClick={handleSaveAttempt}
          >
            Save Changes
          </Button>
        </Grid>
        <Grid item xs={7} display={"flex"}
          justifyContent={"flex-end"}>
          <TextField
            style={{ width: "30vw" }}
            id={"search-units-table"}
            placeholder={"- Search by Units Number -"}
            size={"small"}
            InputProps={{ endAdornment: <InputAdornment position={"end"}><Search /></InputAdornment> }}
            value={searchValue}
            variant={"outlined"}
            name={"unitsTableSearch"}
            onChange={handleSearchChange}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container style={{ height: tableHeight }}>
          <DataGrid
            disableColumnFilter
            disableSelectionOnClick
            rows={memoizedData?.data || []}
            sortingMode={"server"}
            sortingOrder={["asc", "desc"]}
            onSortModelChange={handleChangeSortOrder}
            columns={useManageUnitsTableV3Columns({
              setEditedRows,
              selectedRows,
              setSelectedRows
            })}
            sx={{
              // eslint-disable-next-line max-len
              "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root, & .MuiDataGrid-row .MuiDataGrid-cell:first-child .MuiCheckbox-root": {
                pointerEvents: "none",
                color: "rgba(0, 0, 0, 0.26)"
              }
            }}
            onCellEditStart={(params, event) => {
              if (params.field === "is_rentable" && params.value === true) {
                event.defaultMuiPrevented = true;
              }
            }}
            checkboxSelection
            paginationMode={"server"}
            pagination
            pageSize={pageSize}
            page={page}
            rowCount={memoizedData?.meta?.total || 0}
            loading={isLoading || isFetching || isUpdateLoading}
            onCellEditCommit={handleCellEditCommit}
            rowsPerPageOptions={pageSizeOptions}
            onPageSizeChange={(newPageSize) => dispatch(setRowsPerPage(newPageSize))}
            onPageChange={handlePageChange}
            selectionModel={selectedRows}
          />
        </Grid>
      </Grid>
      <UnrentableReasonModal />
      <ConfirmationModal
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        setEditedRows={setEditedRows}
        updateUnits={updateUnits}
        updateLoading={isUpdateLoading}
        open={confirmationModalOpen}
        data={memoizedData as GetFlatUnitsResponse}
        setOpen={setConfirmationModalOpen}
        editedRows={editedRows}
      />
    </Grid>
  );
};

export default ManageUnitsTable;
