import { Box, Button, Grid, Modal, Paper, Typography } from "@mui/material";
import React, { FormEvent } from "react";
import { LoadingButton } from "@mui/lab";
import useStyles from "./SpeedeRentConfirmationModal.styles";

const SpeedeRentConfirmationModal = ({
  open,
  onModalClose,
  onModalSubmit,
  loading
}: {
  open: boolean;
  onModalClose: Function;
  onModalSubmit: Function;
  loading: boolean;
}) => {
  const { classes } = useStyles();

  return (
    <Modal open={open} onClose={() => onModalClose()} className={classes.modal}>
      <Grid
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        spacing={2}
        className={classes.container}
        pb={2}
      >
        <Box
          component={"form"}
          className={classes.form}
          onSubmit={(e: FormEvent) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Typography variant={"h5"} className={classes.title}>
            Confirm SpeedeRent Process
          </Typography>
          <Grid container
            justifyContent={"center"}>
            <Grid item>
              <Button
                data-testid={"cancel-button"}
                className={classes.cancelButton}
                onClick={() => onModalClose()}
                variant={"outlined"}
                color={"secondary"}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                data-testid={"confirm-button-modal"}
                variant={"outlined"}
                className={classes.button}
                type={"submit"}
                loading={loading}
                onClick={() => onModalSubmit()}
              >
                Confirm
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Modal>
  );
};

export default SpeedeRentConfirmationModal;
