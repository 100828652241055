import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "FeeForm" })((theme) => ({
  noArrowInput: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    },
    "input[type=number]": {
      MozAppearance: "textfield"
    }
  },
  feeTypeIcon: {
    fontSize: "1.1rem",
    marginLeft: theme.spacing(1)
  },
  header: {
    width: "100%",
    borderBottom: "1px solid #ddd",
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2)
  }
}));

export default useStyles;
