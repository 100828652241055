import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance as axios } from "src/api/axiosInstance/axiosInstance";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getAllLedgersForOccupant = createAsyncThunk(
  "getAllLedgersForOccupant",
  ({ occupantId, filter }: {occupantId: number, filter?: {[key: string]: string}}, { dispatch, rejectWithValue }) => {
    const payload = {
      params: {
        with: ["unit.productType.revenueClass"],
        sortBy: "created_at",
        sortDirection: "desc",
        ...filter
      }
    };

    return axios.get(`api/v1/occupants/${occupantId}/ledgers`, payload)
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = "There was an error getting the Ledgers";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
