import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  resetTransactionSlice,
  selectTransactionsFilter,
  selectTransactionsSearchValue,
  setTransactionsFilter,
  setTransactionsSearchValue
} from "src/store/reducers/transactionSlice/transactionSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { APIFilter } from "src/models/responses/APIFilter";
import { useNavigate, useParams } from "react-router";
import { Breadcrumb } from "src/models/Breadcrumb";
import FilterButtonGroup from "src/components/ui/FilterButtonGroup/FilterButtonGroup";
import IssueRefundTable from "./IssueRefundTable/IssueRefundTable";
import { Ledger } from "src/models/Ledger";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import { Button, InputAdornment, TextField } from "@mui/material";
import { ArrowBack, Search } from "@mui/icons-material";
import { SyntheticInputEvent } from "react-number-format/types/types";
import TableWrapper from "src/layouts/TableWrapper/TableWrapper";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import clsx from "clsx";
import { debounce } from "lodash";
import { getLedger } from "src/store/thunks/ledger/get/getLedger";
import useStyles from "./IssueRefund.styles";
import {
  setSelectedLedger
} from "src/store/reducers/ledgersSlice/ledgersSlice";
import { getOccupant } from "src/store/thunks/occupant/getOne/getOccupant";

import { getUnitTransactions } from "src/store/thunks/transactions/getUnitTransaction/getUnitTransactions";
import { resetTablesSlice } from "src/store/reducers/tablesSlice/tablesSlice";
import { searchUnitTransactions } from "src/store/thunks/transactions/searchUnitTransactions/searchUnitTransactions";

import RefundModal from "./RefundModal/RefundModal";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Occupants"
  },
  {
    name: "Issue Refunds",
    bold: true
  }
];

export const completedFilter: APIFilter = {
  only_with_completed_transactions: 1
};

export const refundedFilter: APIFilter = {
  only_with_refunded_transactions: 1
};

export const voidedFilter: APIFilter = {
  only_with_voided_transactions: 1
};

export const processingFilter: APIFilter = {
  only_with_processing_transactions: 1
};

const IssueRefund: React.FC = (): ReactElement => {
  const searchValue = useAppSelector(selectTransactionsSearchValue);
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectTransactionsFilter);
  const navigate = useNavigate();
  const { classes } = useStyles({ filter: filter });

  const { ledgerId, id: occupantId } = useParams<{ ledgerId: string; id: string }>();
  const [ledger, setLedger] = useState<Ledger | null>(null);

  if (!occupantId || !ledgerId) {
    navigate(-1);
  }

  useEffect(() => {
    if (occupantId && ledgerId) {
      dispatch(getLedger({ occupantId, ledgerId })).then(async({ payload }) => {
        const ledger = payload as Ledger;

        await dispatch(setSelectedLedger(ledger));

        setLedger(ledger);
      });

      dispatch(getOccupant(occupantId));
    }
  }, []);

  const goBack = async() => {
    navigate(`/occupants/${occupantId}/edit`, {
      state: { isFromIssueCredits: true }
    });
  };

  const filterTransactions = (filter: APIFilter | "") => {
    dispatch(setTransactionsFilter(filter));
    dispatch(getUnitTransactions({ ledgerId: ledgerId! }));
  };

  const filterButtonGroup = (
    <FilterButtonGroup heading={"Display: "}>
      <Button
        className={clsx(classes.buttonText, classes.allFilter)}
        onClick={() => filterTransactions("")}
        data-testid={"active-button"}
      >
        All
      </Button>
      <Button
        className={clsx(classes.buttonText, classes.completedFilter)}
        onClick={() => filterTransactions(completedFilter)}
        data-testid={"completed-button"}
      >
        Completed
      </Button>
      <Button
        className={clsx(classes.buttonText, classes.processingFilter)}
        onClick={() => filterTransactions(processingFilter)}
        data-testid={"processing-button"}
      >
        Pending
      </Button>
      <Button
        className={clsx(classes.buttonText, classes.refundedFilter)}
        onClick={() => filterTransactions(refundedFilter)}
        data-testid={"refunded-button"}
      >
        Refunded
      </Button>
      <Button
        className={clsx(classes.buttonText, classes.voidedFilter)}
        onClick={() => filterTransactions(voidedFilter)}
        data-testid={"voided-button"}
      >
        Voided
      </Button>
    </FilterButtonGroup>
  );

  useEffect(() => {
    /* istanbul ignore next */ // cannot test this w/ enzyme */
    return () => {
      dispatch(resetTablesSlice());
      dispatch(resetTransactionSlice());
    };
  }, []);

  useEffect(() => {
    if (!searchValue.length) {
      dispatch(getUnitTransactions({ ledgerId: ledgerId! }));
      return;
    }

    dispatch(setTransactionsSearchValue(searchValue));
    dispatch(searchUnitTransactions({ ledgerId: ledgerId!, searchValue: searchValue }));
  }, [searchValue]);

  const debounceSearch = useCallback(
    debounce((text) => dispatch(setTransactionsSearchValue(text)), 600),
    []
  );

  const handleChange = (e: SyntheticInputEvent) => debounceSearch(e.target.value);

  const searchBar = (
    <TextField
      fullWidth
      id={"search-transactions-table"}
      placeholder={"Search"}
      size={"small"}
      InputProps={{
        endAdornment: (
          <InputAdornment position={"end"}>
            <Search />
          </InputAdornment>
        )
      }}
      variant={"outlined"}
      name={"transactionsTableSearch"}
      onChange={handleChange}
    />
  );

  const pageHeader = <PageHeader title={"Issue Refunds"} breadcrumbs={breadcrumbs} />;

  return (
    <>
      <ViewWrapper pageHeader={pageHeader}>
        <Button
          data-testid={"back-button"}
          variant={"text"}
          className={clsx(classes.textButton, classes.baseButton)}
          startIcon={<ArrowBack />}
          onClick={() => goBack()}
        >
          Go Back to Rented Units
        </Button>

        <TableWrapper
          title={"Payments"}
          divider
          filterButtonGroup={filterButtonGroup}
          searchBar={searchBar}
          table={<IssueRefundTable />}
        />

        <RefundModal />
      </ViewWrapper>
    </>
  );
};

export default IssueRefund;
