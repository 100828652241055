export const getRevenueClassTaxRate = (
  stateTax: string, countyTax: string, cityTax: string, localTax: string
): number => {
  const insureNum = (val: string) => {
    let parsedVal: number = parseFloat(val);

    if (parsedVal !== 0 && !parsedVal) {
      parsedVal = 0;
      return parsedVal;
    }

    return parsedVal;
  };

  const sum = insureNum(stateTax) + insureNum(countyTax) + insureNum(cityTax) + insureNum(localTax);

  return +sum.toFixed(3);
};
