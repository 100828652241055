import React, { ReactElement } from "react";
import PMSAdvisory from "../../../../components/ui/PMSAdvisory/PMSAdvisory";
import { Paper } from "@mui/material";
import SelectOccupant from "../../../../components/stepper/SelectOccupant/SelectOccupant";
import StepLayout from "../../../../layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "../../../../layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import { useNavigate } from "react-router";

const SelectOccupantForDeal: React.FC = (): ReactElement => {
  const navigate = useNavigate();
  const advisoryText = (
    <>
      <p>
        Search for an existing occupant and add them to the deal. If you want to apply a deal to a new occupant, you
        can add them into the system via the “Add New Occupant” button.
      </p>
      <p>Once an occupant has been selected, you may proceed to the next step.</p>
    </>
  );

  return (
    <>
      <PMSAdvisory title={"Select an Occupant"} advisoryText={advisoryText} />
      <Paper elevation={1}>
        <StepLayout
          buttons={
            <StepperButtons
              data-testid={"select-deal-occupant-buttons"}
              stepperId={"select-occupant"}
              backAction={() => navigate("/deals/create-new-deal")}
              forwardActionText={"Next"}
            />}
        >
          <SelectOccupant
            disablePagination
            data-testid={"select-deal-occupant"}
            onSubmitHandler={() => navigate("/deals/create-new-deal/schedule-deal")}
          />
        </StepLayout>
      </Paper>
    </>
  );
};

export default SelectOccupantForDeal;
