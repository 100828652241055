import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles<{is_active: boolean}>({
  label: "Delinquency Automation components"
})((theme, { is_active }) => ({
  buttonText: {
    textTransform: "capitalize"
  },
  button: {
    textTransform: "uppercase",
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.common.white
  },
  filterButtonActive: {
    color: is_active
      ? theme.palette.text.primary
      : theme.palette.text.disabled
  },
  filterButtonDisabled: {
    color: !is_active
      ? theme.palette.text.primary
      : theme.palette.text.disabled
  }

}));

export default useStyles;
