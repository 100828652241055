import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GetMerchandiseCostPayload } from "src/store/thunks/merchandise/getMerchandiseCost/getMetchandiseCostPayload";
import { PointOfSalesSliceState } from "./PointOfSalesSliceState";
import { RootState } from "../../rootStore";
import { getMerchandiseCost } from "src/store/thunks/merchandise/getMerchandiseCost/getMerchandiseCost";

export const initialState: PointOfSalesSliceState = {
  merchandiseCostLoading: false,
  merchLineItemIds: [],
  summaryLineItems: [],
  posModal: false,
  subTotal: "0.00",
  tax: "0.00",
  finalBalance: "0.00",
  occupant_id: "",
  posLineItems: [],
  refundModalOpen: false,
  refundMerchId: undefined,
  purchaseLoading: false,
  returnType: "refund"
};

export const pointOfSalesSlice = createSlice({
  name: "pointOfSales",
  initialState,
  reducers: {
    setPosModalOpen: (state, action) => {
      state.posModal = action.payload;
    },
    setRefundMerchId: (state, action) => {
      state.refundMerchId = action.payload;
    },
    setPosMerchItemLineIds: (state, action) => {
      state.merchLineItemIds = action.payload;
    },
    setOccupantId: (state, action) => {
      state.occupant_id = action.payload;
    },
    setPurchaseLoading: (state, action) => {
      state.purchaseLoading = action.payload;
    },
    resetPointOfSalesSlice: (state) => {
      Object.assign(state, initialState);
    },
    setRefundModalOpen: (state, action: PayloadAction<boolean>) => {
      state.refundModalOpen = action.payload;
    },
    setReturnType: (state, action: PayloadAction<string>) => {
      state.returnType = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMerchandiseCost.pending, (state) => {
        state.merchandiseCostLoading = true;
      })
      .addCase(getMerchandiseCost.fulfilled, (state, action: PayloadAction<GetMerchandiseCostPayload>) => {
        state.summaryLineItems = action.payload.merchandiseItems || [];
        state.finalBalance = action.payload.final_balance || "0.00";
        state.tax = action.payload.tax || "0.00";
        state.subTotal = action.payload.sub_total || "0.00";
        state.merchandiseCostLoading = false;
      })
      .addCase(getMerchandiseCost.rejected, (state) => {
        state.merchandiseCostLoading = false;
      });
  }
});

export const selectPosState = (state: RootState) => state.pointOfSales;
export const selectPosModalOpen = (state: RootState) => state.pointOfSales.posModal;
export const selectMerchLineItemIds = (state: RootState) => state.pointOfSales.merchLineItemIds;
export const selectSummaryPosLineItems = (state: RootState) => state.pointOfSales.summaryLineItems;
export const selectMerchandiseCostLoading = (state: RootState) => state.pointOfSales.merchandiseCostLoading;
export const selectFinalBalance = (state: RootState) => state.pointOfSales.finalBalance;
export const selectRefundModalOpen = (state: RootState) => state.pointOfSales.refundModalOpen;
export const selectReturnType = (state: RootState) => state.pointOfSales.returnType;
export const selectRefundMerchId = (state: RootState) => state.pointOfSales.refundMerchId;
export const selectOccupantId = (state: RootState) => state.pointOfSales.occupant_id;
export const selectPurchaseLoading = (state: RootState) => state.pointOfSales.purchaseLoading;

export const {
  setPosModalOpen,
  setPosMerchItemLineIds,
  setOccupantId,
  resetPointOfSalesSlice,
  setRefundMerchId,
  setRefundModalOpen,
  setReturnType,
  setPurchaseLoading
} = pointOfSalesSlice.actions;

export default pointOfSalesSlice.reducer;
