import {
  Grid,
  Paper,
  Typography
} from "@mui/material";
import React, { FC } from "react";
import { AccountCircle } from "@mui/icons-material";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import UpdateSettingsForm from "./UpdateSettingsForm/UpdateSettingsForm";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import useStyles from "./UserSettings.styles";

interface UserSettingsProps { }

const pageHeader = <PageHeader title={"User Settings"} />;

const UserSettings: FC<UserSettingsProps> = () => {
  const { classes } = useStyles();

  return (
    <ViewWrapper pageHeader={pageHeader}>
      <Grid
        container
        className={classes.contentContainer}
      >
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <Typography variant={"h6"}>Profile details</Typography>
            <Grid
              container
              direction={"column"}
              alignItems={"center"}
            >
              <AccountCircle className={`${classes.smallMargin} ${classes.userIcon}`} />
              <Typography className={classes.smallMargin} variant={"h5"}>[Username]</Typography>
              <Typography className={classes.smallMargin} variant={"subtitle1"}>User Account Title</Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography variant={"h6"}>Update your settings</Typography>
            <Grid
              container
              direction={"column"}
              alignItems={"center"}
            >
              <UpdateSettingsForm />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </ViewWrapper>
  );
};

export default UserSettings;
