import React from "react";
import { Box, Typography } from "@mui/material";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import useStyles from "./AlertChangeOwnership.styles";
const AlertChangeOwnership = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.containerIcon}>
        <ReportGmailerrorredOutlinedIcon color={"error"}/>
      </Box>
      <Box className={classes.containerText}>
        <Typography className={classes.title}>
          You are in the process of changing ownership
        </Typography>
        <Typography className={classes.subTitle}>
          Please fill out new owner information.
        </Typography>
      </Box>
    </Box>
  );
};

export default AlertChangeOwnership;
