
import { Alert, AlertColor, Grid, IconButton, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { Close } from "@mui/icons-material";
import useStyles from "./TaxExemptAlert.styles";
interface TaxExemptAlertProps {
    showTaxExemptAlert: boolean,
    setShowTaxExemptAlert: (show: boolean) => void,
    severity: AlertColor
}

const TaxExemptAlert: FC<TaxExemptAlertProps> =
 ({ showTaxExemptAlert, setShowTaxExemptAlert, severity }: TaxExemptAlertProps) => {
   const { classes } = useStyles();
   return (
     showTaxExemptAlert
       ? <Alert
           className={classes.root}
           hidden={!showTaxExemptAlert}
           severity={severity}
           >
         <Grid id={"foobar"} container alignItems={"center"}>
           <Grid item xs={11}>
             <Typography>
               {severity === "success"
                 ? (
                   <Typography>
                     Tax Certificate has been saved.
                   </Typography>

                   )
                 : severity === "error"
                   ? "A Tax Certificate must be uploaded to save."
                   : null
            }
             </Typography>
           </Grid>
           <Grid item xs={1}>
             <IconButton onClick={() => setShowTaxExemptAlert(false)} >
               <Close />
             </IconButton>
           </Grid>
         </Grid>
       </Alert>
       : null
   );
 };
export default TaxExemptAlert;
