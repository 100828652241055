import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ReviewAndPayCCSlicState } from "./reviewAndPayCCSliceState";
import { RootState } from "../../rootStore";
import creditCard from "credit-card-type";

export const initialState: ReviewAndPayCCSlicState = {
  cardholderFirstName: "",
  cardholderLastName: "",
  cvv: "",
  cvvMasked: "",
  creditCardNumber: "",
  creditCardNumberMasked: "",
  expDate: "",
  creditCardType: ""
};

export const reviewAndPayCCSlice = createSlice({
  name: "reviewAndPayCC",
  initialState,
  reducers: {
    setCardholderFirstName: (state, action: PayloadAction<string>) => {
      state.cardholderFirstName = action.payload;
    },
    setCardholderLastName: (state, action: PayloadAction<string>) => {
      state.cardholderLastName = action.payload;
    },
    setCvv: (state, action: PayloadAction<string>) => {
      state.cvv = action.payload;
    },
    setCvvMasked: (state, action: PayloadAction<string>) => {
      state.cvvMasked = action.payload;
    },
    setCreditCardNumber: (state, action: PayloadAction<string>) => {
      state.creditCardNumber = action.payload;

      const cardData = creditCard(action.payload);
      if (cardData.length) {
        state.creditCardType = cardData[0].type as string;
      }
    },
    setCreditCardNumberMasked: (state, action: PayloadAction<string>) => {
      state.creditCardNumberMasked = action.payload;
    },
    setExpDate: (state, action: PayloadAction<string>) => {
      state.expDate = action.payload;
    },
    resetReviewAndPayCCSlice: (state) => {
      Object.assign(state, initialState);
    }
  }
});

export const selectCardholderFirstName = (state: RootState) => state.reviewAndPayCC.cardholderFirstName;
export const selectCardholderLastName = (state: RootState) => state.reviewAndPayCC.cardholderLastName;
export const selectCreditCardNumber = (state: RootState) => state.reviewAndPayCC.creditCardNumber;
export const selectExpDate = (state: RootState) => state.reviewAndPayCC.expDate;
export const selectCvv = (state: RootState) => state.reviewAndPayCC.cvv;
export const selectCreditCardType = (state: RootState) => state.reviewAndPayCC.creditCardType;
export const selectCreditCardNumberMasked = (state: RootState) => state.reviewAndPayCC.creditCardNumberMasked;
export const selectCvvMasked = (state: RootState) => state.reviewAndPayCC.cvvMasked;

export const {
  setCardholderFirstName,
  setCardholderLastName,
  setCreditCardNumber,
  setCvv,
  setExpDate,
  setCreditCardNumberMasked,
  setCvvMasked,
  resetReviewAndPayCCSlice
} = reviewAndPayCCSlice.actions;

export default reviewAndPayCCSlice.reducer;
