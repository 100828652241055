import React from "react";
import { useController } from "react-hook-form";
import { Autocomplete, FormHelperText, Grid, InputLabel, TextField } from "@mui/material";

type FormAutoCompleteProps = {
  name: string;
  label: string;
  placeholder?: string;
  options: Array<any>;
  optionLabel: (option: any) => string;
  inputLabel: (id: any) => string;
};

const FormAutoComplete = ({ name, label, optionLabel, options, inputLabel }: FormAutoCompleteProps) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    name
  });

  return (
    <Grid item>
      {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
      <Autocomplete
        {...field}
        options={options}
        renderInput={(params) => <TextField {...params} />}
        getOptionLabel={optionLabel}
        inputValue={inputLabel(field.value)}
        onChange={(_, data) => field.onChange(data?.id)}
      />
      {error?.message && (
        <FormHelperText style={{ marginLeft: "14px" }} error>
          {error.message}
        </FormHelperText>
      )}
    </Grid>
  );
};

export default FormAutoComplete;
