import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../rootStore";
import { UserInfo } from "../../../models/UserInfo";
import { UserInfoSliceState } from "./UserInfoSliceState";
import { getUserInfo } from "src/store/thunks/userInfo/get/getUserInfo";

export const initialState: UserInfoSliceState = {
  userInfo: JSON.parse(localStorage.getItem("userInfo") as string) ?? null,
  userInfoError: "",
  userInfoLoading: false
};

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    resetUserInfoSlice: (state: UserInfoSliceState) => {
      Object.assign(state, initialState);
      localStorage.removeItem("userInfo");
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfo.pending, (state) => {
        state.userInfoLoading = true;
      })
      .addCase(getUserInfo.fulfilled, (state, action: PayloadAction<UserInfo>) => {
        state.userInfo = action.payload;
        state.userInfoLoading = false;
        localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.userInfoLoading = false;
        state.userInfoError = action.payload as string;
      });
  }
});

export const selectUserId = (state: RootState) => state.userInfo.userInfo?.id;
export const selectUserName = (state: RootState) =>
  `${state.userInfo.userInfo?.first_name} ${state.userInfo.userInfo?.last_name}`;
export const selectUserEmail = (state: RootState) => state.userInfo.userInfo?.email;
export const selectUserTitle = (state: RootState) => state.userInfo.userInfo?.title;
export const selectUserOperatorId = (state: RootState) => state.userInfo.userInfo?.operator_id;
export const selectUserInfoError = (state: RootState) => state.userInfo.userInfoError;
export const selectUserInfoLoading = (state: RootState) => state.userInfo.userInfoLoading;

export const { resetUserInfoSlice } = userInfoSlice.actions;

export default userInfoSlice.reducer;
