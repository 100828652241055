import { Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { FormEvent } from "react";
import PMSSelect from "../../../../components/ui/PMSSelect/PMSSelect";
import {
  selectRevenueClassId,
  setRevenueClassId
} from "../../../../store/reducers/productTypeSlice/productTypeSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import PMSAdvisory from "../../../../components/ui/PMSAdvisory/PMSAdvisory";
import Paper from "@mui/material/Paper";
import { RevenueClass } from "../../../../models/RevenueClass";
import StepLayout from "../../../../layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "../../../../layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import assignRevenueClassValidation from "./assignRevenueClassValidation";
import { getRevenueClassTaxRate } from "../../../../utils/getRevenueClassTaxRate/getRevenueClassTaxRate";
import { useNavigate } from "react-router";
import { inputError } from "../../../../utils/showInputError/showInputError";
import { selectRevenueClasses } from "../../../../store/reducers/revenueClassesSlice/revenueClassesSlice";
import { useFormik } from "formik";

const AssignRevenueClass: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const revenueClasses = useAppSelector(selectRevenueClasses);
  const revenueClassId = useAppSelector(selectRevenueClassId);
  const selectedRevenueClass = revenueClasses.find(revenueClass => revenueClass.id === Number(revenueClassId))!;

  /* istanbul ignore next */ // this is a temporary workaround to calculate a value that the BE will be returning soon.
  const facilityTaxRate = (selectedRevenueClass: RevenueClass) => {
    if (selectedRevenueClass) {
      return getRevenueClassTaxRate(
        selectedRevenueClass.tax_state,
        selectedRevenueClass.tax_county,
        selectedRevenueClass.tax_city,
        selectedRevenueClass.tax_local
      );
    }

    return 0;
  };

  const formik = useFormik({
    initialValues: {
      revenueClassId: revenueClassId || 0
    },
    validationSchema: assignRevenueClassValidation,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: () => navigate("/product-types/add-product-type/attributes")
  });

  const { values, errors, touched } = formik;

  const updateForm = (fieldName: string, fieldValue?: number): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleRevenueClassOnChange = (fieldName: string, fieldValue: number) => {
    updateForm(fieldName, fieldValue);
    dispatch(setRevenueClassId(fieldValue));
  };

  const revenueClassItems = revenueClasses.map(revenueClass => {
    return <option key={revenueClass.id} value={revenueClass.id}>{revenueClass.name}</option>;
  });

  const revenueTaxBreakdown = (
    <Grid
      mt={2}
      item
      container
      xs={12}
      spacing={3}
      data-testid={"revenue-tax-breakdown"}
    >
      <Grid item container xs={6}>
        <Grid item xs={12} container>
          <Grid item xs={5}>
            <InputLabel>Tax Rates</InputLabel>
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={3}
          alignItems={"center"}
        >
          <Grid
            item
            xs={3}
            component={InputLabel}
            htmlFor={"state-tax-rate"}
          >
            State
          </Grid>
          <Grid item xs={3} xl={2}>
            <TextField
              InputProps={{ endAdornment: <InputAdornment position={"end"}>%</InputAdornment> }}
              disabled
              value={selectedRevenueClass?.tax_state}
              variant={"outlined"}
            />
          </Grid>
          <Grid
            item
            xs={3}
            component={InputLabel}
            htmlFor={"county-tax-rate"}
          >
            County
          </Grid>
          <Grid item xs={3} xl={2}>
            <TextField
              id={"city-tax-rate"}
              InputProps={{ endAdornment: <InputAdornment position={"end"}>%</InputAdornment> }}
              disabled
              value={selectedRevenueClass?.tax_county}
              variant={"outlined"}
            />
          </Grid>
          <Grid
            item
            xs={3}
            component={InputLabel}
            htmlFor={"city-tax-rate"}
          >
            City
          </Grid>
          <Grid item xs={3} xl={2}>
            <TextField
              id={"city-tax-rate"}
              InputProps={{ endAdornment: <InputAdornment position={"end"}>%</InputAdornment> }}
              disabled
              value={selectedRevenueClass?.tax_city}
              variant={"outlined"}
            />
          </Grid>
          <Grid
            item
            xs={3}
            component={InputLabel}
            htmlFor={"local-tax-rate"}
          >
            Local
          </Grid>
          <Grid item xs={3} xl={2}>
            <TextField
              id={"local-tax-rate"}
              InputProps={{ endAdornment: <InputAdornment position={"end"}>%</InputAdornment> }}
              disabled
              value={selectedRevenueClass?.tax_local}
              variant={"outlined"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={6}>
        <Grid item xs={12}>
          <InputLabel htmlFor={"total-tax-rate"}>Revenue Class Total Tax Rate</InputLabel>
          <TextField
            id={"total-tax-rate"}
            disabled
            InputProps={{ endAdornment: <InputAdornment position={"end"}>%</InputAdornment> }}
            value={facilityTaxRate(selectedRevenueClass)}
            variant={"outlined"}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const stepperId = "revenue-class-form";

  const advisoryText = (
    <>
      <p>Now, select your revenue class. Revenue classes are tied to facilities</p>
      <p>Selecting a revenue class provides a tax breakdown for all units contained within your new product type.</p>
    </>
  );

  return (
    <>
      <PMSAdvisory title={"Select Your Revenue Class"} advisoryText={advisoryText} />
      <Paper elevation={1}>
        <StepLayout
          stepperId={stepperId}
          buttons={
            <StepperButtons
              data-testid={"product-type-revenue-class-buttons"}
              stepperId={stepperId}
              backAction={() => navigate("/product-types/add-product-type/select-category")}
            />
          }>
          <Grid
            id={stepperId}
            data-testid={stepperId}
            component={"form"}
            container
            direction={"column"}
            onSubmit={(e: FormEvent) => {
              e.preventDefault();
              e.stopPropagation();
              formik.handleSubmit();
            }}
          >
            <Grid
              item
              container
              xs={12}
              justifyContent={"space-between"}
              spacing={3}
            >
              <Grid item xs={6}>
                <Grid item xs={8}>
                  <PMSSelect
                    id={"revenue-class-select"}
                    name={"revenueClassId"}
                    label={"Revenue Class"}
                    value={values.revenueClassId}
                    changeHandler={e => handleRevenueClassOnChange("revenueClassId", e.target.value as number)}
                    error={inputError("revenueClassId", touched, errors).error}
                    helperText={inputError("revenueClassId", touched, errors).helperText}
                  >
                    <option value={0} disabled>- Select A Revenue Class -</option>
                    {revenueClassItems}
                  </PMSSelect>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                {selectedRevenueClass && <Grid item xs={8}>
                  <InputLabel htmlFor={"account-code"}>Account Code</InputLabel>
                  <TextField
                    id={"account-code"}
                    data-testid={"account-code"}
                    fullWidth
                    disabled
                    value={selectedRevenueClass?.account_code}
                    variant={"outlined"}
                  />
                </Grid>}
              </Grid>
            </Grid>
            {selectedRevenueClass && revenueTaxBreakdown}
          </Grid>
        </StepLayout>
      </Paper>
    </>
  );
};

export default AssignRevenueClass;
