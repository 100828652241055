import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";

export const getAutomations = createAsyncThunk("getAutomations",
  ({ facilityId, is_active }:{facilityId: number, is_active: boolean}, { dispatch, rejectWithValue }) => {
    const params = {
      params: {
        "filter[is_active]": is_active ? 1 : 0
      }
    };

    return axios.get(`/api/v1/facilities/${facilityId}/system-automations`, params)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = `There was an error fetching the list of Delinquency Automations`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
