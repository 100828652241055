import { moneyValidator, percentageValidator } from "src/utils/commonRegexes";

import { z } from "zod";

const feeFormValidation = z
  .object({
    feeName: z.string({ required_error: "Fee Name is required" }),
    feeAmount: z.string({ required_error: "Fee Amount is required" }),
    feeCategoryId: z.number().min(1, { message: "Fee Category is Required" }),
    feeType: z.number().min(1, { message: "Type is Required" }),
    feeRecurrence: z.number().min(1, "Recurrence is Required"),
    feeRevenueClassId: z.number().min(1, "Revenue Class is Required"),
    applyFeeAt: z.number(),
    feeDescription: z.string().optional(),
    isRequired: z.boolean(),
    floorAmount: z.string().optional(),
    amountFloorRestriction: z.boolean().optional()
  })
  .refine(
    (schema) => {
      if (schema.feeType === 1) {
        return percentageValidator.test(schema.feeAmount);
      }
      if (schema.feeType === 2) {
        return moneyValidator.test(schema.feeAmount);
      }
      return true;
    },
    { message: "Invalid Format", path: ["feeAmount"] }
  );

export type FeeFormType = z.infer<typeof feeFormValidation>;

export default feeFormValidation;
