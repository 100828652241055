import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Ledger } from "../../../models/Ledger";
import { MoveOutSliceState } from "./MoveOutSliceState";
import { RootState } from "../../rootStore";
import { MoveOut } from "src/models/MoveOut";
import { getMoveOutCost } from "src/store/thunks/moveOut/getMoveOutCost/getMoveOutCost";
import { MoveOutCost } from "src/models/MoveOutCost";
import { createMoveOut } from "src/store/thunks/moveOut/create/createMoveOut";

export const initialState: MoveOutSliceState = {
  moveOutLedger: JSON.parse(sessionStorage.getItem("moveOutLedger") as string) ?? null,
  moveOutLoading: false,
  moveOutError: "",
  moveOutDate: "",
  moveOut: { markRemainderAsBadDebt: true, takePaymentForRemainder: true } as MoveOut,
  moveOutCost: null,
  isMoveOutFromManageOccupants: false,
  moveOutConfirmation: null,
  isFromRentedUnits: false
};

export const moveOutSlice = createSlice({
  name: "moveOut",
  initialState,
  reducers: {
    setMoveOut: (state, action: PayloadAction<MoveOut>) => {
      state.moveOut = action.payload;
    },
    setMoveOutLedger: (state, action: PayloadAction<Ledger | null>) => {
      state.moveOutLedger = action.payload;
    },
    setMoveOutTakePaymentForRemainder: (state, action: PayloadAction<boolean>) => {
      state.moveOut!.takePaymentForRemainder = action.payload;
    },
    setMoveOutMarkRamainderOwedAsBadDebt: (state, action: PayloadAction<boolean>) => {
      state.moveOut!.markRemainderAsBadDebt = action.payload;
    },
    setMoveOutViaAuction: (state, action: PayloadAction<boolean>) => {
      state.moveOut!.moveOutViaAuction = action.payload;
    },
    setBuyerName: (state, action: PayloadAction<string>) => {
      state.moveOut!.buyerName = action.payload;
    },
    setMoveOutCompleted: (state, action: PayloadAction<boolean>) => {
      state.moveOut!.moveOutCompleted = action.payload;
    },
    setIsMoveOutFromManageOccupants: (state, action: PayloadAction<boolean>) => {
      state.isMoveOutFromManageOccupants = action.payload;
    },
    setIsFromRentedUnits: (state, action: PayloadAction<boolean>) => {
      state.isFromRentedUnits = action.payload;
    },
    resetMoveOutSlice: (state) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("moveOutLedger");
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMoveOutCost.pending, (state) => {
        state.moveOutLoading = true;
      })
      .addCase(getMoveOutCost.fulfilled, (state) => {
        state.moveOutLoading = false;
      })
      .addCase(getMoveOutCost.rejected, (state, action) => {
        state.moveOutLoading = false;
        state.moveOutCost = action.payload as MoveOutCost;
        state.moveOutError = action.payload as string;
      })
      .addCase(createMoveOut.pending, (state) => {
        state.moveOutLoading = true;
      })
      .addCase(createMoveOut.fulfilled, (state, action) => {
        state.moveOutConfirmation = action.payload;

        state.moveOutLoading = false;
      })
      .addCase(createMoveOut.rejected, (state) => {
        state.moveOutLoading = false;
      });
  }
});

export const selectMoveOutLedger = (state: RootState) => state.moveOut?.moveOutLedger;
export const selectMoveOut = (state: RootState) => state.moveOut?.moveOut;
export const selectMoveOutDate = (state: RootState) => state.moveOut?.moveOut?.moveOutDate;
export const selectMoveOutLoading = (state: RootState) => state.moveOut?.moveOutLoading;
export const selectMoveOutError = (state: RootState) => state.moveOut?.moveOutError;
export const selectSelectedLedgerUnitNumber = (state: RootState) => state.moveOut?.moveOutLedger?.unit?.unit_number;
export const selectSelectedLedgerUnitSize = (state: RootState) => {
  const unit = state.moveOut.moveOutLedger?.unit;

  if (!unit) {
    return "";
  }
  return unit?.width ? `${unit?.length}' x ${unit?.width}'` : `${unit?.length}'`;
};
// eslint-disable-next-line max-len
export const selectSelectedLedgerProductTypeName = (state: RootState) =>
  state.moveOut?.moveOutLedger?.unit?.product_type?.name;
export const selectSelectedLegerQuotedRate = (state: RootState) => state.moveOut?.moveOutLedger?.monthly_rate;
export const selectSelectedLedgerDatePaidThru = (state: RootState) => state.moveOut?.moveOutLedger?.date_paid_thru;
export const selectIssueCreditValue = (state: RootState) => state.moveOut?.moveOut?.issueCreditValue;
export const selectMoveOutCompleted = (state: RootState) => state.moveOut?.moveOut?.moveOutCompleted;
export const selectIsMoveOutFromManageOccupants = (state: RootState) => state.moveOut.isMoveOutFromManageOccupants;
export const selectMoveOutConfirmation = (state: RootState) => state.moveOut?.moveOutConfirmation;
export const selectIsFromRentedUnits = (state: RootState) => state.moveOut.isFromRentedUnits;

export const {
  setMoveOut,
  setMoveOutLedger,
  setMoveOutTakePaymentForRemainder,
  setMoveOutMarkRamainderOwedAsBadDebt,
  setMoveOutViaAuction,
  setBuyerName,
  setMoveOutCompleted,
  setIsMoveOutFromManageOccupants,
  setIsFromRentedUnits,
  resetMoveOutSlice
} = moveOutSlice.actions;

export default moveOutSlice.reducer;
