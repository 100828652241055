import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "ProductTypeAttributes" })((theme) => ({
  root: {
    height: "100%"
  },
  backButton: {
    display: "flex",
    flexWrap: "nowrap",
    minWidth: "6rem",
    justifyContent: "center"
  },
  selectLabel: {
    color: theme.palette.common.black,
    paddingBottom: theme.spacing(1)
  },
  noArrow: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    },
    "input[type=number]": {
      MozAppearance: "textfield"
    }
  },
  attributesWrapper: {
    width: "100%"
  },
  spinner: {
    color: theme.palette.common.white
  }
}));

export default useStyles;
