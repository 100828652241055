import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import React, { ReactElement, useEffect } from "react";
import {
  selectPromotionLoading,
  selectPromotionModalIsEnable,
  selectPromotionModalOpen,
  setPromotionModalIsEnable,
  setPromotionModalOpen
} from "src/store/reducers/promotionSlice/promotionSlice";
import {
  selectPromotions,
  selectPromotionsLoading,
  selectShowActivePromotions,
  setShowActive
} from "../../store/reducers/promotionsSlice/promotionsSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import AppModal from "src/components/ui/AppModal/AppModal";
import { Breadcrumb } from "../../models/Breadcrumb";
import FilterButtonGroup from "../../components/ui/FilterButtonGroup/FilterButtonGroup";
import ItemManagementCard from "../../components/itemManagement/ItemManagementCard/ItemManagementCard";
import ItemManagementLayout from "../../layouts/ItemManagement/ItemManagementLayout";
import { NavLink, useNavigate } from "react-router-dom";
import NoItemsDisplay from "../../components/itemManagement/NoItemsDisplay/NoItemsDisplay";
import PageHeader from "../../components/ui/PageHeader/PageHeader";
import { Promotion } from "src/models/Promotion";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import clsx from "clsx";
import { disablePromotion } from "src/store/thunks/promotion/disable/disablePromotion";
import { CreatePromotionAdvisoryText as displayText } from "./CreatePromotionAdvisoryText";
import { enablePromotion } from "src/store/thunks/promotion/enable/enablePromotion";
import { formatDate } from "../../utils/__dateAndTimeUtils__/formatDate/formatDate";
import { formattedAmount } from "../../utils/formattedAmount/formattedAmount";
import { getAllPromotions } from "../../store/thunks/promotion/getAll/getAllPromotions";
import { isExpired } from "../../utils/__dateAndTimeUtils__/isExpired/isExpired";
import { setSelectedPromotion } from "src/store/reducers/selectedPromotionSlice/selectedPromotionSlice";
import { titleText } from "../../layouts/ItemManagement/titleText/titleText";
import {
  createPermissions,
  hasPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

import useStyles from "./Promotions.styles";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Promotions",
    bold: true
  }
];

const Promotions: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const promotions = useAppSelector(selectPromotions);
  const promotionsLoading = useAppSelector(selectPromotionsLoading);
  const promotionModalOpen = useAppSelector(selectPromotionModalOpen);
  const promotionModalIsEnable = useAppSelector(selectPromotionModalIsEnable);
  const activePromotions = promotions.filter(promotion => promotion.is_active);
  const inactivePromotions = promotions.filter(promotion => !promotion.is_active);
  const showActivePromotions = useAppSelector(selectShowActivePromotions);
  const displayPromotions = showActivePromotions ? activePromotions : inactivePromotions;
  const promotionLoading = useAppSelector(selectPromotionLoading);
  const promotionThunk = promotionModalIsEnable ? enablePromotion() : disablePromotion();
  const { classes } = useStyles({ isEnabled: showActivePromotions });

  const permissions = useAppSelector(selectFacilityPermissions);
  const promotionsPermissions = createPermissions(permissions, "promotions");

  useEffect(() => {
    dispatch(getAllPromotions());
  }, []);

  const closePromotionModal = () => {
    dispatch(setPromotionModalIsEnable(false));
    dispatch(setPromotionModalOpen(false));
  };

  const promotionConfirmAction = () => {
    dispatch(promotionThunk)
      .then(() => {
        dispatch(setPromotionModalOpen(false));
        dispatch(getAllPromotions());
      });
  };

  const openPromotionModalDisable = (promotion: Promotion) => {
    dispatch(setPromotionModalIsEnable(false));
    dispatch(setPromotionModalOpen(true));
    dispatch(setSelectedPromotion(promotion));
  };

  const openPromotionModalReEnable = (promotion: Promotion) => {
    dispatch(setPromotionModalIsEnable(true));
    dispatch(setPromotionModalOpen(true));
    dispatch(setSelectedPromotion(promotion));
  };

  const filters = (
    <FilterButtonGroup heading={"Show Promotions:"}>
      <Button
        className={clsx(classes.buttonText, classes.enabledButton)}
        color={"secondary"}
        data-testid={"active-promotions"}
        onClick={() => dispatch(setShowActive(true))}
      >
        Active
      </Button>
      <Button
        className={clsx(classes.buttonText, classes.disabledButton)}
        data-testid={"disabled-promotions"}
        onClick={() => dispatch(setShowActive(false))}
      >
        Disabled
      </Button>
    </FilterButtonGroup>
  );

  const ctaButton = (
    <Button
      className={classes.newPromotionContainedButton}
      color={"primary"}
      variant={"contained"}
      component={NavLink}
      to={"/promotions/create"}
    >
      New Promotion
    </Button>
  );

  const editButton = (promotion: Promotion) => {
    if (promotion.is_active) {
      return (

        <Tooltip title={"Edit Promotion"}>
          <IconButton
            data-testid={"edit-promotion"}
            onClick={() => navigate(`/promotions/${promotion.id}/edit`)}
            className={classes.editButton}
        >
            <Edit />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const disableButton = (promotion: Promotion) => (
    <Tooltip title={"Disable Promotion"}>
      <IconButton
        data-testid={"delete-promotion"}
        aria-label={"delete-button"}
        onClick={() => openPromotionModalDisable(promotion)}
        color={"error"}
      >
        <Delete />
      </IconButton>
    </Tooltip>
  );

  const itemButtons = (
    promotion: Promotion
  ) => {
    if (promotion.is_active) {
      return (
        <Grid item>
          {promotionsPermissions.delete && disableButton(promotion)}
          {promotionsPermissions.update && editButton(promotion)}
        </Grid>
      );
    }

    if (!isExpired(promotion.end)) {
      return (
        <>
          <Button
            data-testid={"promotion-re-enable"}
            onClick={() => openPromotionModalReEnable(promotion)}
            className={classes.reenableButton}
            variant={"contained"}
            size={"large"}
          >
            Enable
          </Button>
          {promotionsPermissions.update && editButton(promotion)}
        </>
      );
    }
  };

  const bottomRow = (promotion: Promotion) => (
    <>
      <Grid
        item
        xs={6}
        pr={2}
        lg={"auto"}
        order={{ xs: 1, lg: 1 }}
      >
        <span className={classes.cellTitle}>Start Date: </span>{formatDate(promotion.start)}
      </Grid>
      <Grid
        item
        xs={6}
        pr={2}
        lg={"auto"}
        order={{ xs: 2, lg: 2 }}
      >
        <span className={classes.cellTitle}>End Date: </span>
        {promotion.is_indefinite ? "Indefinite" : formatDate(promotion.end)}
      </Grid>
      <Grid
        item
        xs={6}
        pr={2}
        lg={"auto"}
        order={{ xs: 5, lg: 3 }}
      >
        <span className={classes.cellTitle}>Amount: </span>{formattedAmount(promotion.type_name, promotion.amount)}
      </Grid>
      <Grid
        item
        xs={6}
        pr={2}
        lg={"auto"}
        order={{ xs: 3, lg: 4 }}
      >
        <span className={classes.cellTitle}>Units: </span>{promotion.ledgers_count}
      </Grid>
      <Grid
        item
        xs={6}
        pr={2}
        lg={"auto"}
        order={{ xs: 4, lg: 5 }}
      >
        <span className={classes.cellTitle}>Reservations: </span>{promotion.deals_count}
      </Grid>
    </>
  );

  const promotionsList = (
    <Grid mt={1} data-testid={"promotions-list"}>
      {displayPromotions.map(promotion => (
        <ItemManagementCard
          blurb={
            <Tooltip title={promotion.description}>
              <Typography noWrap variant={"subtitle1"} className={classes.description}>
                {promotion.description}
              </Typography>
            </Tooltip>
        }
          data-testid={"item-management-card"}
          bottomRow={bottomRow(promotion)}
          buttonGroup={itemButtons(promotion)}
          key={promotion.id}
          item={promotion}
        />
      ))}
    </Grid>
  );

  const promotionsDisplay = () => {
    if (!displayPromotions.length) {
      return <NoItemsDisplay data-testid={"no-promotions-display"} item={"promotion"} link={"/promotions/create"} />;
    }

    return promotionsList;
  };

  const pageHeader = <PageHeader title={"Promotions"} breadcrumbs={breadcrumbs}/>;

  return (
    <ViewWrapper pageHeader={pageHeader}>
      <ItemManagementLayout
        title={titleText(showActivePromotions, "Promotions")}
        filters={filters}
        headerButton={promotionsPermissions.create && ctaButton}
        loading={promotionsLoading}
          >
        {promotionsDisplay()}
      </ItemManagementLayout>
      <AppModal
        data-testid={"promotion-modal"}
        confirmActionLoading={promotionLoading}
        confirmAction={() => promotionConfirmAction()}
        cancelAction={() => closePromotionModal()}
        open={promotionModalOpen}
      >
        <Typography gutterBottom variant={"h6"}>{displayText(promotionModalIsEnable).title}</Typography>
        <Typography gutterBottom>{displayText(promotionModalIsEnable).advisoryText}</Typography>
      </AppModal>
    </ViewWrapper>
  );
};

export default Promotions;
