import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Chart of Accounts" })((theme) => ({
  buttonExport: {
    color: "#306DC8",
    marginRight: theme.spacing(4)
  }
}));

export default useStyles;
