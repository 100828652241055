import UpdateDealPayload from "./UpdateDealPayload";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const updateDeal = createAsyncThunk("updateDeal",
  ({ payload, deal_id }: { payload: UpdateDealPayload, deal_id: number }, { dispatch, rejectWithValue }) => {
    const { facility_id } = payload;

    return axios.put(`/api/v1/facilities/${facility_id}/deals/${deal_id}`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Deal updated successfully",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        if (err.response.status === 422) {
          if (err.response.data.detail) return rejectWithValue(err.response.data.detail);

          const errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        if (err.response.status === 400) {
          dispatch(showSnackbar({
            message: err.response.data.detail,
            variant: "error"
          }));

          return rejectWithValue(err.response.data.detail);
        }

        const errorMessage = "There was an error updating the Deal";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
