/* eslint-disable max-len */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { APIListResponse } from "../../../models/responses/APIListResponse";
import { Deal } from "../../../models/Deal";
import { Occupant } from "../../../models/Occupant";
import { RootState } from "../../rootStore";
import { SelectedOccupantSliceState } from "./SelectedOccupantSliceState";
import { createOccupant } from "../../thunks/occupant/create/createOccupant";
import { getDeal } from "../../thunks/deals/get/getDeal";
import { nameParser } from "../../../utils/nameParser/nameParser";
import { processRefundMerchandise } from "src/store/thunks/merchandise/processRefundMerchandise/processRefundMerchandise";
import { searchOccupants } from "../../thunks/occupant/search/searchOccupants";
import { updateOccupantInStepper } from "../../thunks/occupant/update/stepper/updateOccupantInStepper";
import { getOccupant } from "src/store/thunks/occupant/getOne/getOccupant";

export const initialState: SelectedOccupantSliceState = {
  availableOccupants: [],
  selectedOccupant: null,
  occupantsSearchOpen: false,
  addOccupantFormOpen: false,
  selectedOccupantLoading: false,
  selectedOccupantError: ""
};

export const selectedOccupantSlice = createSlice({
  name: "selectedOccupant",
  initialState,
  reducers: {
    setSelectedOccupant: (state, action: PayloadAction<Occupant | null>) => {
      state.selectedOccupant = action.payload;
      sessionStorage.setItem("selectedOccupant", JSON.stringify(action.payload));
    },
    toggleOccupantsSearchOpen: (state, action: PayloadAction<boolean>) => {
      state.occupantsSearchOpen = action.payload;
    },
    toggleAddOccupantFormOpen: (state, action: PayloadAction<boolean>) => {
      state.addOccupantFormOpen = action.payload;
    },
    resetSelectedOccupantSlice: (state) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("selectedOccupant");
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDeal.fulfilled, (state, action: PayloadAction<Deal>) => {
        state.selectedOccupant = {
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          middle_name: action.payload.middle_name ?? "",
          email: action.payload.email,
          id: action.payload.occupant_id
        } as Occupant;
      })
      .addCase(createOccupant.fulfilled, (state, action: PayloadAction<Occupant>) => {
        state.selectedOccupant = action.payload;
      })
      .addCase(updateOccupantInStepper.fulfilled, (state, action: PayloadAction<Occupant>) => {
        state.selectedOccupant = action.payload;
      })
      .addCase(searchOccupants.pending, (state) => {
        state.selectedOccupantLoading = true;
      })
      .addCase(searchOccupants.fulfilled, (state, action: PayloadAction<APIListResponse<Occupant[]>>) => {
        state.availableOccupants = action.payload.data;
        state.occupantsSearchOpen = true;
        state.selectedOccupantLoading = false;
      })
      .addCase(searchOccupants.rejected, (state, action) => {
        state.selectedOccupantLoading = false;
        state.selectedOccupantError = action.payload as string;
      })
      .addCase(processRefundMerchandise.fulfilled, (state, action) => {
        state.selectedOccupantLoading = false;
        state.selectedOccupant = action.payload.occupant;
      })
      .addCase(getOccupant.pending, (state, action) => {
        state.selectedOccupantLoading = true;
      })
      .addCase(getOccupant.fulfilled, (state, action) => {
        state.selectedOccupantLoading = false;
        state.selectedOccupant = action.payload;
      });
  }
});

export const selectAvailableOccupants = (state: RootState) => state.selectedOccupant.availableOccupants;
export const selectSelectedOccupant = (state: RootState) => state.selectedOccupant?.selectedOccupant;
export const selectSelectedOccupantLoading = (state: RootState) => state.selectedOccupant.selectedOccupantLoading;
export const selectOccupantsSearchOpen = (state: RootState) => state.selectedOccupant.occupantsSearchOpen;
export const selectAddOccupantFormOpen = (state: RootState) => state.selectedOccupant.addOccupantFormOpen;
export const selectSelectedOccupantName = (state: RootState) =>
  state.selectedOccupant.selectedOccupant?.first_name ?? "";
export const selectSelectedOccupantReservations = (state: RootState) => state.selectedOccupant
  .selectedOccupant?.deals?.filter(deal => deal.deal_type === 2 && !deal.is_converted && !deal.cancelled_at) ?? [];
export const selectSelectedOccupantLastNameFirst = (state: RootState) => state.selectedOccupant.selectedOccupant
  ? nameParser(
    state.selectedOccupant.selectedOccupant.first_name,
    state.selectedOccupant.selectedOccupant.last_name,
    state.selectedOccupant.selectedOccupant.middle_name
  )
  : "";
export const selectSelectedOccupantLedgers = (state: RootState) => state.selectedOccupant.selectedOccupant?.ledgers ??
 [];

export const {
  toggleOccupantsSearchOpen,
  toggleAddOccupantFormOpen,
  setSelectedOccupant,
  resetSelectedOccupantSlice
} = selectedOccupantSlice.actions;

export default selectedOccupantSlice.reducer;
