import React, { ReactElement } from "react";
import {
  ID_Type_Enum,
  selectBusinessName,
  selectDob,
  selectDriversLicenseNumber, selectDriversLicenseState,
  selectEmail,
  selectFirstName, selectGateCode, selectIdType, selectIsBusiness,
  selectLastName, selectMilitaryStatus, selectPrimaryPhone, selectTaxId, selectValidMailingAddress
} from "../../../store/reducers/occupantInformationSlice/occupantInformationSlice";
import InfoIcon from "../../ui/InfoIcon/InfoIcon";
import { emailTest } from "../../../utils/emailTest/emailTest";
import { useAppSelector } from "../../../store/hooks";

const OccupantInfoIcons: React.FC = (): ReactElement => {
  const firstName = useAppSelector(selectFirstName);
  const lastName = useAppSelector(selectLastName);
  const validAddress = useAppSelector(selectValidMailingAddress);
  const email = useAppSelector(selectEmail);
  const primaryPhone = useAppSelector(selectPrimaryPhone);
  const driversLicenseNumber = useAppSelector(selectDriversLicenseNumber);
  const driversLicenseState = useAppSelector(selectDriversLicenseState);
  const militaryStatus = useAppSelector(selectMilitaryStatus);
  const taxId = useAppSelector(selectTaxId);
  const dob = useAppSelector(selectDob);
  const gateCode = useAppSelector(selectGateCode);
  const businessSelected = useAppSelector(selectIsBusiness);
  const businessName = useAppSelector(selectBusinessName);
  const idType = useAppSelector(selectIdType);

  return (
    <>
      <InfoIcon title={"First Name"} complete={!!firstName} />
      <InfoIcon title={"Last Name"} complete={!!lastName} />
      <InfoIcon title={"Mailing Address"} complete={validAddress} />
      <InfoIcon title={"E-mail Address"} complete={emailTest(email)} />
      <InfoIcon title={"Primary Phone Number"} complete={!!(primaryPhone && primaryPhone.length === 10)} />
      <InfoIcon title={"Occupant ID #"} complete={!!driversLicenseNumber} />
      {Number(idType) !== ID_Type_Enum.Passport && <InfoIcon title={"ID State"} complete={!!driversLicenseState} />}
      <InfoIcon title={"Military Status"} complete={!!militaryStatus} />
      <InfoIcon title={"Date of Birth"} complete={!!dob} />
      <InfoIcon title={"Gate Code"} complete={!!gateCode} />
      {businessSelected && <InfoIcon title={"Business Name"} complete={!!businessName} />}
    </>
  );
};

export default OccupantInfoIcons;
