import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSearchParams } from "../../../../utils/getSearchParams/getSearchParams";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

interface GetAllPaginatedDocumentTemplatesProps {
  category?: number
  facilityId?: number
  type?: number
  searchValue?: string,
  showAllFacilities?: boolean,
}

export const getAllPaginatedDocumentTemplates = createAsyncThunk(
  "getAllPaginatedDocumentTemplates",
  ({ category, type, searchValue, showAllFacilities }: GetAllPaginatedDocumentTemplatesProps,
    { dispatch, getState, rejectWithValue }) => {
    const store = getState() as RootState;
    const facilityId = store.selectedFacility.selectedFacility.id;
    const selectedFacilityId = store.selectedAdminFacility.selectedAdminFacility?.id;

    let params: any = {
      params: {
        ...getSearchParams(store, true, "name"),
        "filter[is_latest_version]": 1,
        with: ["facilities", "systemAutomations", "tppPlans", "productTypes"],
        "filter[is_disabled]": 0,
        ...(type && { "filter[type]": type }),
        ...(searchValue && { "filter[name]": searchValue }),
        ...(showAllFacilities && { showAllFacilities })
      }
    };

    if (category) {
      params = {
        params: {
          "filter[is_latest_version]": 1,
          ...(category && { "filter[category]": category })
        }
      };
    }

    let facility = facilityId;

    if (selectedFacilityId) {
      facility = selectedFacilityId;
    }

    return axios.get(`/api/v1/facilities/${facility}/document-templates`, params)
      .then(resp => resp.data)
      .catch(() => {
        const errorMessage = "There was an error getting the Document Templates";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
