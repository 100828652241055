import { IconButton, Tooltip } from "@mui/material";
import React, { FC } from "react";
import {
  setEditInventoryModalOpen,
  setSelectedInventoryItem
} from "src/store/reducers/inventorySlice/inventorySlice";
import { Edit } from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";
import InventoryItem from "src/models/Inventory";
import { useAppDispatch } from "src/store/hooks";

interface ActionButtonsProps{row: InventoryItem }
const ActionButtons:FC<ActionButtonsProps> = ({ row }) => {
  const dispatch = useAppDispatch();

  const hadndleSelectInventory = () => {
    dispatch(setSelectedInventoryItem(row));
    dispatch(setEditInventoryModalOpen(true));
  };

  return (
    <Tooltip title={"Edit"}>
      <IconButton onClick={hadndleSelectInventory}>
        <Edit />
      </IconButton>
    </Tooltip>
  );
};

export const manageInventoryTableColumns = (): GridColDef[] => [
  {
    flex: 1,
    field: "name",
    headerName: "Name",
    valueGetter: ({ row }) => row.merchandise?.name
  },
  {
    flex: 1,
    field: "sku",
    headerName: "SKU #"
  },
  {
    flex: 1,
    field: "quantity",
    headerName: "Count"
  },
  {
    flex: 1,
    field: "remaining_quantity",
    headerName: "Remaining"
  },
  {
    flex: 1,
    field: "shipping_cost",
    headerName: "Shipping Cost",
    valueGetter: ({ row }) => row.shipping_cost ? `$ ${row.shipping_cost}` : "$0"
  },
  {
    flex: 1,
    field: "cost_of_goods_sold",
    headerName: "Cost of Goods Sold",
    valueGetter: ({ row }) => row.cost_of_goods_sold ? `$ ${row.cost_of_goods_sold}` : "$0"
  },
  {
    flex: 1,
    field: "actions",
    headerName: "Action",
    renderCell: ({ row }) => <ActionButtons row={row}/>
  }
];

export default manageInventoryTableColumns;
