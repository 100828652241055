import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Button, Grid, Modal, Paper, Typography } from "@mui/material";

import { Box } from "@mui/system";
import useStyles from "./PaymentCompleteModal.styles";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectCurrentTransaction } from "src/store/reducers/transactionSlice/transactionSlice";
import { printReceipt } from "src/store/thunks/moveIn/print/printReceipt";
import { emailReceipt } from "src/store/thunks/receipt/emailReceipt/emailReceipt";
import { selectSelectedDelinquent } from "src/store/reducers/collectionsSlice/collectionsSlice";

const PaymentCompleteModal = ({ open, onModalClose }: { open: boolean; onModalClose: Function }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const selectedDelinquent = useAppSelector(selectSelectedDelinquent);
  const currentTransaction = useAppSelector(selectCurrentTransaction);
  const [sendEmail, setSendEmail] = useState(false);

  const handlePrintReceipt = () => {
    dispatch(printReceipt(currentTransaction?.id)).then((resp) => {
      if (!resp.type.includes("rejected")) {
        const link = document.createElement("a");
        link.href = resp.payload as string;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  const handleEmailReceipt = () => {
    const occupantEmail = selectedDelinquent?.ledger?.occupant?.email;
    if (occupantEmail) {
      setSendEmail(true);
      dispatch(emailReceipt(occupantEmail));
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => onModalClose()}
      aria-labelledby={"Modal Component"}
      aria-describedby={"A Modal component."}
      className={classes.modal}
      data-testid={"payment-complete-modal"}
    >
      <Grid
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        className={classes.container}
        pb={2}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid item xs={12} className={classes.content}>
          <Typography variant={"h4"} className={classes.title}>
            Success!
          </Typography>
          <Typography variant={"h5"} textAlign={"center"} mb={3}>
            Your payment is confirmed.
          </Typography>

          <Typography variant={"h5"} textAlign={"center"} mb={3}>
            Confirmation #{currentTransaction?.id}
          </Typography>

          <Typography textAlign={"center"}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid pt={4} container spacing={2}
            justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={2} mr={2}>
              <Button
                data-testid={"cancel-button"}
                className={classes.close}
                color={"secondary"}
                onClick={() => onModalClose()}
              >
                Close
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                data-testid={"email-button-modal"}
                color={"primary"}
                variant={"outlined"}
                className={classes.button}
                onClick={handleEmailReceipt}
                disabled={sendEmail}
              >
                Email Receipt
              </Button>
            </Grid>

            <Grid item xs={4}>
              <Button
                data-testid={"print-button-modal"}
                color={"primary"}
                variant={"outlined"}
                className={classes.button}
                onClick={handlePrintReceipt}
              >
                Print Receipt
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default PaymentCompleteModal;
