import { Grid, Paper } from "@mui/material";
import React, { FC } from "react";
import {
  selectReportDate,
  selectReportEndDate,
  selectReportFacilities,
  selectReportFacility,
  selectReportHours,
  selectReportStartDate
} from "src/store/reducers/reportParamsSlice/reportParamsSlice";
import { Breadcrumb } from "src/models/Breadcrumb";
import LogiReportStudio from "../components/LogiReportStudio/LogiReportStudio";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import ReportParamsBuilder from "../components/ReportParamsBuilder/ReportParamsBuilder";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import clsx from "clsx";
import moment from "moment";
import { rptSvrInfo } from "src/utils/reportApiHelper/RptApi";
import { selectFacilities } from "src/store/reducers/facilitiesSlice/facilitiesSlice";
import { useAppSelector } from "src/store/hooks";
import useStyles from "./BuildReport.styles.";

interface BuildReportProps {

}

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Reporting"
  },
  {
    name: "Build Report",
    bold: true
  }
];

const pageHeader = <PageHeader title={"Reporting"} breadcrumbs={breadcrumbs} />;

const BuildReport: FC<BuildReportProps> = () => {
  const { classes } = useStyles();
  const attachedFacilities = useAppSelector(selectReportFacilities);
  const attachedFacility = useAppSelector(selectReportFacility);
  const startDate = useAppSelector(selectReportStartDate);
  const endDate = useAppSelector(selectReportEndDate);
  const date = useAppSelector(selectReportDate);
  const hours = useAppSelector(selectReportHours);
  const facilitiesMaster = useAppSelector(selectFacilities);
  const operator_id = facilitiesMaster.find((facility) => facility.id === attachedFacilities[0])?.operator_id;

  let effectiveOperatorId = operator_id;
  if (typeof effectiveOperatorId === "undefined" && facilitiesMaster.length > 0) {
    effectiveOperatorId = facilitiesMaster[0].operator_id;
  }

  const params = {
    ...effectiveOperatorId && { operator_id: effectiveOperatorId },
    ...attachedFacilities.length && { facility_id: attachedFacilities },
    ...attachedFacility.length && { single_facility_id: +attachedFacility[0] },
    ...startDate && { start_date: moment(startDate).format("MMMM D, YYYY") },
    ...endDate && { end_date: moment(endDate).format("MMMM D, YYYY") },
    ...date && { date: moment(date).format("MMMM D, YYYY") },
    ...hours && { hours: hours }
  };

  return (
    <ViewWrapper pageHeader={pageHeader}>
      <Grid mt={2} container spacing={2}>
        <Grid item xs={12}>
          <ReportParamsBuilder />
        </Grid>
        <Grid item xs={12}>
          <Paper className={clsx(classes.borderRadius, classes.sectionPadding)} variant={"outlined"} >
            <Grid container p={4} spacing={2}>
              <Grid minHeight={"50vh"} item xs={12}>
                <LogiReportStudio
                  id={"lr-studio"}
                  className={"lr-studio"}
                  svrInfo={rptSvrInfo}
                  params={params}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </ViewWrapper>
  );
};

export default BuildReport;
