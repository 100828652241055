import { Box, CircularProgress, Grid } from "@mui/material";
import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { getAllFacilities } from "../../store/thunks/facility/getAll/getAllFacilities";
import { getUserInfo } from "../../store/thunks/userInfo/get/getUserInfo";
// import history from "../../utils/history";
import { useAppDispatch } from "src/store/hooks";
import { useNavigate } from "react-router";
import { getTasks } from "src/store/thunks/task/getAll/getTasks";
import { getAllTaskCategories } from "src/store/thunks/taskCategory/getAll/getAllTaskCategories";

export const checkUserAuthentication = (tokenInStorage: string | null, tokenInQueryString: string) => {
  return new Promise((resolve) => {
    // 1. check to see if bearer token is in local storage. if it is, user has been auth'd and may continue
    if (tokenInStorage) {
      return resolve(true);
    }

    // 2. check to see if bearer token is in query params. if it does, persist in local storage. user is now auth'd
    if (tokenInQueryString) {
      sessionStorage.setItem("bearerToken", tokenInQueryString);
      window.history.pushState({}, document.title, window.location.pathname);
      return resolve(true);
    }

    // 3. if neither 1 or 2 are true, user is not auth'd and should be redirected to login.
    window.location.assign(window.config.identityFrontend);
  });
};

const AuthLanding: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const bearerTokenInStorage = sessionStorage.getItem("bearerToken");
  const bearerTokenInQueryString = Object.fromEntries(urlSearchParams.entries())?.bearer;

  useEffect(() => {
    checkUserAuthentication(bearerTokenInStorage, bearerTokenInQueryString)
      .then(() => dispatch(getAllFacilities()))
      .then(() => dispatch(getUserInfo()))
      .then(() => dispatch(getTasks({ getNotifications: true })))
      .then(() => dispatch(getAllTaskCategories()))
      .then(() => navigate("/"));
  }, []);

  return (
    <Grid
      container
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box my={3}>
        <Typography variant={"h4"} component={"h1"}>Checking Authentication...</Typography>
      </Box>
      <CircularProgress size={60} aria-label={"authenticating user"} />
    </Grid>
  );
};

export default AuthLanding;
