import { Button, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  addAllOptionForSelector,
  removeAllOptionForSelector
} from "src/store/reducers/facilitiesSlice/facilitiesSlice";
import {
  resetSelectedAdminFacilitySlice,
  selectSelectedAdminFacility,
  setSelectedAdminFacility
} from "src/store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import {
  resetESignDocumentsSlice,
  selectESignDocumentsFilter,
  setESignDocumentStatusFilter
} from "src/store/reducers/eSignStatusSlice/eSignStatusSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { APIFilter } from "src/models/responses/APIFilter";
import { Breadcrumb } from "../../../models/Breadcrumb";
import ESignStatusTable from "./ESignStatusTable/ESignStatusTable";
import { Facility } from "src/models/Facility";
import FacilitySelector from "src/components/navigation/FacilitySelector/FacilitySelector";
import FilterButtonGroup from "src/components/ui/FilterButtonGroup/FilterButtonGroup";
import ItemManagementLayout from "src/layouts/ItemManagement/ItemManagementLayout";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import ResendDocumentModal from "./ResendDocumentModal/ResendDocumentModal";
import { Search } from "@mui/icons-material";
import { SyntheticInputEvent } from "react-number-format/types/types";
import ViewESignDocumentModal from "./ViewESignDocumentModal/ViewESignDocumentModal";
import ViewWrapper from "../../../layouts/ViewWrapper/ViewWrapper";
import clsx from "clsx";
import {
  getAllEsignDocumentsForFacility
} from "../../../store/thunks/esignDocument/getAllForFacility/getAllEsignDocumentsForFacility";
import { resetTablesSlice } from "src/store/reducers/tablesSlice/tablesSlice";
import useStyles from "./ESignStatus.styles";
import { debounce } from "lodash";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Documents"
  },
  {
    name: "E-Sign Status",
    bold: true
  }
];

const allFilter: APIFilter = {
  "filter[is_signed]": "0,1"
};

const completedFilter: APIFilter = {
  "filter[is_signed]": "1"
};

const pendingFilter: APIFilter = { "filter[is_signed]": "0" };

const ESignStatus: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const selectedFacility = useAppSelector(selectSelectedAdminFacility);
  const filter = useAppSelector(selectESignDocumentsFilter);
  const { classes } = useStyles({ filter: filter });
  const pageHeader = <PageHeader title={"E-Sign Status"} breadcrumbs={breadcrumbs} />;
  const [searchInput, setSearchInput] = useState("");
  const permissions = useAppSelector(selectFacilityPermissions);
  const eSignStatusPermissions = createPermissions(permissions, "e_sign_status");

  const handleOnFacilityChange = async(selectedFacility: Facility) => {
    await dispatch(resetTablesSlice());
    await dispatch(setSelectedAdminFacility(selectedFacility));
    dispatch(
      getAllEsignDocumentsForFacility({
        facility_id: selectedFacility.id,
        filter: {
          ...filter,
          search_value: searchInput
        }
      })
    );
  };

  const filterDocuments = (filter: APIFilter) => {
    dispatch(
      setESignDocumentStatusFilter({
        ...filter,
        search_value: searchInput
      })
    );
    dispatch(
      getAllEsignDocumentsForFacility({
        facility_id: selectedFacility?.id!,
        filter: {
          ...filter,
          search_value: searchInput
        }
      })
    );
  };

  const [isOnFocus, setIsOnFocus] = useState(true);

  const handleVisibilityChange = useCallback(() => {
    setIsOnFocus(!document.hidden);
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (isOnFocus) {
      filterDocuments(filter);
    }
  }, [isOnFocus]);

  useEffect(() => {
    dispatch(addAllOptionForSelector());

    return () => {
      dispatch(removeAllOptionForSelector());
      dispatch(resetSelectedAdminFacilitySlice());
      dispatch(resetESignDocumentsSlice());
    };
  }, []);

  useEffect(() => {
    if (searchInput.length) {
      filterDocuments({
        ...filter,
        search_value: searchInput
      });
      return;
    }

    filterDocuments({ ...filter, search_value: "" });
  }, [searchInput]);

  const debounceSearch = useCallback(
    debounce((text) => setSearchInput(text), 600),
    []
  );

  const searchBar = () => {
    return (
      selectedFacility && (
        <TextField
          fullWidth
          placeholder={"Search"}
          size={"small"}
          hiddenLabel={true}
          inputProps={{ "aria-label": "Search ESign Documents" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position={"end"}>
                <Search />
              </InputAdornment>
            )
          }}
          variant={"outlined"}
          onChange={(e: SyntheticInputEvent) => debounceSearch(e.target.value)}
        />
      )
    );
  };

  const filterButtonGroup = () => {
    if (!selectedFacility) {
      return null;
    }

    return (
      <FilterButtonGroup heading={"Filter by: "}>
        <Button className={clsx(classes.buttonText, classes.allFilter)} onClick={() => filterDocuments(allFilter)}>
          All
        </Button>
        <Button
          className={clsx(classes.buttonText, classes.pendingFilter)}
          onClick={() => filterDocuments(pendingFilter)}
        >
          Pending
        </Button>
        <Button
          className={clsx(classes.buttonText, classes.completedFilter)}
          onClick={() => filterDocuments(completedFilter)}
        >
          Completed
        </Button>
      </FilterButtonGroup>
    );
  };

  const tableDisplay = () => {
    if (!selectedFacility) {
      return null;
    }

    return (
      <ESignStatusTable
        handlePaginationUpdate={() => {
          dispatch(
            getAllEsignDocumentsForFacility({
              facility_id: selectedFacility?.id!,
              filter: {
                ...filter
              }
            })
          );
        }}
      />
    );
  };

  const title = (
    <Grid item container alignItems={"center"}>
      <Grid item>
        <Typography component={"label"} htmlFor={"eSignStatus-facility-selector"} variant={"h6"}>
          Document Status for:
        </Typography>
      </Grid>
      <Grid item ml={2}>
        <FacilitySelector
          data-testid={"eSignStatus-facility-selector"}
          navBar={false}
          onFacilityChange={handleOnFacilityChange}
          elementId={"eSignStatus-facility-selector"}
        />
      </Grid>
    </Grid>
  );

  if (eSignStatusPermissions.read) {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <ItemManagementLayout title={title} headerButton={filterButtonGroup()} searchBar={searchBar()}>
          {tableDisplay()}
        </ItemManagementLayout>
        <ViewESignDocumentModal />
        <ResendDocumentModal />
      </ViewWrapper>
    );
  } else {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <h1>You do not have permission to view this page.</h1>
      </ViewWrapper>
    );
  }
};

export default ESignStatus;
