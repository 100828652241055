import { Facility } from "src/models/Facility";

export const selectedFacilitiesDisplay = (facilityIds: number[], facilities: Facility[]) => {
  if (!facilityIds || !facilityIds.length) {
    return "- Select Facility -";
  }

  if (facilityIds.includes(1)) {
    return "All Facilities";
  }

  return facilityIds
    .map((facilityId) => {
      return facilities.find((currentFacility) => currentFacility.id === facilityId)?.facility_id;
    })
    .join(", ");
};

export default selectedFacilitiesDisplay;
