import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Deal } from "src/models/Deal";
import { RootState } from "../../rootStore";
import { SelectedPromotionSliceState } from "./SelectedPromotionSliceState";
import { getDeal } from "src/store/thunks/deals/get/getDeal";

export const initialState: SelectedPromotionSliceState = {
  selectedPromotion: null,
  selectePromotionError: "",
  selectedPromotionLoading: false
};

export const selectedPromotionSlice = createSlice({
  name: "selectedPromotion",
  initialState,
  reducers: {
    setSelectedPromotion: (state, action) => {
      state.selectedPromotion = action.payload;
    },
    resetSelectedPromotionSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getDeal.fulfilled, (state, action: PayloadAction<Deal>) => {
        state.selectedPromotion = action.payload.promotion ?? null;
        state.selectedPromotionLoading = false;
      });
  }
});

export const selectSelectedPromotion = (state: RootState) => state.selectedPromotion.selectedPromotion;

export const {
  setSelectedPromotion,
  resetSelectedPromotionSlice
} = selectedPromotionSlice.actions;

export default selectedPromotionSlice.reducer;
