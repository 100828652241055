import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "EditAttributes" })((theme) => ({
  root: {
    background: theme.palette.common.white,
    marginTop: theme.spacing(1),
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`
  },
  editAttributesForm: {
    display: "grid",
    gap: "1rem",
    "@media (min-width: 1165px)": {
      "grid-template-columns": "repeat(4, minmax(150px, 1fr))"
    }
  }
}));

export default useStyles;
