import { APIListMetaData } from "src/models/responses/APIListMetaData";
import { Header } from "src/pages/Webhooks/WebhookForm/WebhookForm";
import { Webhook } from "src/models/Webhook";
import api from "../api";
import { format422 } from "src/utils/format422/format422";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";

export interface CreateWebhookPayload {
  name: string;
  description: string;
  isEnabled: boolean;
  receivingUrl: string;
  eventTrigger: string;
  authType: number;
  authUsername: string;
  authPassword: string;
  authToken: string;
  headers: Header[];
}

export interface ToggleWebhookPayload {
  isEnabled: boolean;
}

type GetWebhooksResponse = {
  data: Webhook[],
  meta: APIListMetaData
}

type GetWebhooksRequest = {
  params: {
    page: number,
    pageSize: number,
    searchValue?: string,
    sortDirection: string,
    sortBy: string,
    filter: number
  }
}
const headersObjectToArray = (headersObj: { [key: string]: string } | null | undefined): Header[] => {
  if (!headersObj) {
    return [{ key: "", value: "" }];
  }

  return Object.entries(headersObj).map(([key, value]) => ({ key, value }));
};

const headersArrayToObject = (headersArray: Header[] | any): { [key: string]: string } => {
  const headersObj: { [key: string]: string } = {};
  if (!Array.isArray(headersArray)) {
    return headersObj;
  }

  headersArray.forEach(header => {
    if (header.key && header.value != null) {
      headersObj[header.key] = header.value;
    }
  });
  return headersObj;
};

export const webhooksApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createWebhook: builder.mutation<void, CreateWebhookPayload>({
      query: (payload) => ({
        url: `api/v1/webhooks`,
        method: "POST",
        body: {
          name: payload.name,
          description: payload.description,
          is_enabled: payload.isEnabled,
          url: payload.receivingUrl,
          trigger: payload.eventTrigger,
          auth_type: payload.authType,
          auth_username: payload.authUsername,
          auth_password: payload.authPassword,
          auth_token: payload.authToken,
          headers: payload.headers.length ? headersArrayToObject(payload.headers) : {}
        }
      }),
      invalidatesTags: ["Webhooks"]
    }),
    updateWebhook: builder.mutation<any, { payload: CreateWebhookPayload; id: string }>({
      query: ({ payload, id }) => {
        const headers = payload.headers?.length === 0 ||
          (payload.headers.length === 1 && payload.headers[0].key === "" && payload.headers[0].value === "")
          ? null
          : payload.headers;

        return {
          url: `api/v1/webhooks/${id}`,
          method: "PUT",
          body: {
            name: payload.name,
            description: payload.description,
            is_enabled: payload.isEnabled,
            url: payload.receivingUrl,
            trigger: payload.eventTrigger,
            auth_type: payload.authType,
            auth_username: payload.authUsername,
            auth_password: payload.authPassword,
            auth_token: payload.authToken,
            headers: headers?.length ? headersArrayToObject(headers) : {}
          }
        };
      },
      invalidatesTags: ["Webhooks"]
    }),
    toggleWebhook: builder.mutation<any, { payload: ToggleWebhookPayload; id: string }>({
      query: ({ payload, id }) => {
        return {
          url: `api/v1/webhooks/${id}/toggle`,
          method: "POST",
          body: {
            is_enabled: payload.isEnabled
          }
        };
      },
      invalidatesTags: ["Webhooks"]
    }),
    deleteWebhook: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/v1/webhooks/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Webhooks"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            showSnackbar({
              message: "Webhook deleted successfully",
              variant: "success"
            })
          );
        } catch (err) {
          const errorMessage = "There was an error deleting the Webhook";
          dispatch(
            showSnackbar({
              message: errorMessage,
              variant: "error"
            })
          );
        }
      }
    }),
    getWebhook: builder.query<any, string>({
      query: (id) => `api/v1/webhooks/${id}`,
      providesTags: ["Webhooks"],
      transformResponse: (response: Webhook) => {
        // Check if headers exist and need transformation
        if (response?.headers) {
          return {
            ...response,
            headers: headersObjectToArray(response.headers)
          };
        }
        return response;
      }
    }),
    getWebhooks: builder.query<{ data: Webhook[], meta: APIListMetaData }, GetWebhooksRequest>({
      queryFn: async({ params }, _, _extraOptions, baseQuery) => {
        try {
          const filterVals = {
            ...(params.searchValue ? { "filter[name]": params.searchValue } : {})
          };

          const { searchValue, filter, ...tableParamsWithoutSearchValue } = params;

          const paramsObj = {
            ...tableParamsWithoutSearchValue,
            ...filterVals
          };

          const result = await baseQuery({
            url: `/api/v1/webhooks`,
            params: paramsObj
          });

          if (result.error) {
            return { error: result.error };
          }

          return { data: result.data as GetWebhooksResponse };
        } catch (error) {
          return { error: { status: "CUSTOM_ERROR", error: "Failed to fetch Webhooks" } };
        }
      },
      providesTags: ["Webhooks"],

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          const errorMessage = "There was an error getting the webhooks";
          dispatch(
            showSnackbar({
              message: errorMessage,
              variant: "error"
            })
          );
        }
      }
    }),
    getWebhookTriggerEvents: builder.query<string[], void>({
      query: () => `api/v1/events`,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          const errorMessage = "There was an error getting the webhook trigger events.";
          dispatch(
            showSnackbar({
              message: errorMessage,
              variant: "error"
            })
          );
        }
      }
    })
  })
});

export const {
  useGetWebhookTriggerEventsQuery,
  useUpdateWebhookMutation,
  useToggleWebhookMutation,
  useDeleteWebhookMutation,
  useGetWebhookQuery,
  useGetWebhooksQuery,
  useCreateWebhookMutation
} = webhooksApi;
