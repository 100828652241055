import {
  Button,
  Divider,
  Grid,
  InputLabel,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import React, { FC, FormEvent, useEffect } from "react";
import {
  selectAutomaticPaymentSlice,
  setAutobillingConfirmationModalOpened,
  setAwaitingSignatureSubmissionModalOpened,
  setCurrentESignDocument,
  setESignDocumentCreationLoading,
  setEmailAutoBillModalOpened
}
  from "src/store/reducers/automaticPaymentsSlice/automaticPaymentsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";

import { LoadingButton } from "@mui/lab";
import { createESignDocumentForAutoPay }
  from "src/store/thunks/occupant/createESignDocumentForAutoPay/createESignDocumentForAutoPay";
import { inputError } from "src/utils/showInputError/showInputError";
import { selectFacilityAutoBillTemplateSetting }
  from "src/store/reducers/facilitySettingInformationSlice/facilitySettingInformationSlice";
import { selectOccupant } from "src/store/reducers/occupantSlice/occupantSlice";
import { selectSelectedLedger } from "src/store/reducers/ledgersSlice/ledgersSlice";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { useFormik } from "formik";
import useStyles from "./EmailAutoBillModal.styles";
import validationSchema from "./EmailAutoBillValidation";

interface EmailAutoBillModalProps { }

const EmailAutoBillModal: FC<EmailAutoBillModalProps> = () => {
  const initialState = {
    email: "",
    picked: "old"
  };
  const dispatch = useAppDispatch();
  const {
    emailAutoBillModalOpened,
    eSignDocumentCreationLoading
  } = useAppSelector(selectAutomaticPaymentSlice);
  const occupant = useAppSelector(selectOccupant);
  const autoBillTemplate = useAppSelector(selectFacilityAutoBillTemplateSetting);
  const selectedLedger = useAppSelector(selectSelectedLedger);

  const onSubmit = async(values: {email: string}) => {
    dispatch(setESignDocumentCreationLoading(true));
    const { payload } = await dispatch(createESignDocumentForAutoPay({
      selectedDocuments: [{
        id: autoBillTemplate?.value
      }],
      ledgerId: selectedLedger?.id,
      sendViaEmail: true,
      email: values.email || ""
    }));

    if (payload.success) {
      const eSignDocument = payload.data.eSignDocument;
      await dispatch(setCurrentESignDocument(eSignDocument));
      await dispatch(setAwaitingSignatureSubmissionModalOpened(true));
      await dispatch(setAutobillingConfirmationModalOpened(false));

      dispatch(setEmailAutoBillModalOpened(false));
    } else {
      dispatch(showSnackbar({
        message: `There was an error creating the auto bill eSign document.`,
        variant: "error"
      }));
    }

    await dispatch(setESignDocumentCreationLoading(false));
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema,
    onSubmit: (values) => {
      //we do nothing here until the API has the endpoints we need, then we make the thunk.
      onSubmit(values);
    }
  });

  const handleClose = () => {
    dispatch(setAutobillingConfirmationModalOpened(true));
    dispatch(setEmailAutoBillModalOpened(false));
  };

  const { values, setFieldValue, handleChange, resetForm, handleSubmit, touched, errors } = formik;
  const { classes } = useStyles();

  useEffect(() => {
    resetForm({ values: initialState });
  }, [emailAutoBillModalOpened]);

  useEffect(() => {
    setFieldValue("email", "");
  }, [values.picked]);

  return (
    <Modal
      open={emailAutoBillModalOpened}
      onClose={handleClose}
      aria-describedby={"A Modal component to add or edit chart of account settings."}
      className={classes.modal}
        >
      <Grid
        container
        p={5}
        className={classes.container}
        component={Paper}
            >
        <form id={"new-email"} onSubmit={(e: FormEvent) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}>
          <RadioGroup
            aria-labelledby={"demo-controlled-radio-buttons-group"}
            name={"controlled-radio-buttons-group"}
            value={values.picked}
            onChange={handleChange}
                    >
            <Grid item xs={12}>
              <Typography gutterBottom variant={"h4"} textAlign={"center"}>
                Confirm to Send Unit Release Form to Occupant
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography textAlign={"center"} gutterBottom>
                Send document via email to occupant via existing email or different address.
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={2}>
                <Radio name={"picked"} value={"old"}/>
              </Grid>
              <Grid alignSelf={"center"} item xs={10}>
                <Typography gutterBottom>
                  Use existing email address:
                </Typography>
                <Grid>
                  {occupant?.email || "No email on file"}
                </Grid>
              </Grid>
            </Grid>
            <Grid item p={4} xs={12}>
              <Divider>
                or
              </Divider>
            </Grid>
            <Grid
              container
              mb={2}
              item
              xs={12}
            >
              <Grid item xs={2}>
                <Radio name={"picked"} value={"new"}/>
              </Grid>
              <Grid item xs={10} mb={2}>
                <InputLabel htmlFor={"email"}>
                  New E-mail Address
                </InputLabel>
                <TextField
                  disabled={values.picked === "old"}
                  fullWidth
                  placeholder={"- E-mail Address -"}
                  id={"email"}
                  name={"email"}
                  value={values.email}
                  onChange={handleChange}
                  error={inputError("email", touched, errors).error}
                  helperText={inputError("email", touched, errors).helperText}
                                />
              </Grid>
              <Grid container justifyContent={"center"}>
                <Button onClick={handleClose} className={classes.cancelButton} variant={"outlined"}>
                  Cancel
                </Button>
                <LoadingButton
                  type={"submit"}
                  className={classes.confirmationButton}
                  variant={"outlined"}
                  disabled={eSignDocumentCreationLoading}
                  loading={eSignDocumentCreationLoading}
                >
                  Confirm
                </LoadingButton>
              </Grid>
            </Grid>
          </RadioGroup>
        </form>
      </Grid>
    </Modal>
  );
};

export default EmailAutoBillModal;
