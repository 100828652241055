import { makeStyles } from "../../makeStyles";

const useStyles = makeStyles<{ }>({ label: "RateChanges" })((theme) => ({
  buttonText: {
    textTransform: "capitalize"
  },
  ctaButton: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.primary.contrastText,
    textTransform: "capitalize",
    fontWeight: 300,
    color: theme.palette.common.white,
    minWidth: "8rem",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main
    }
  },
  disabledButton: {
    color: theme.palette.text.disabled
  },
  enabledButton: {
    color: theme.palette.text.primary
  },
  buttonBase: {
    textTransform: "uppercase",
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main
    }
  },
  modal: {
    display: "flex",
    alignItems: "center",
    margin: "10%"
  },
  closeButton: {
    position: "absolute",
    right: 10,
    bottom: 0
  }
}));

export default useStyles;
