import { FormControlLabel, Radio } from "@mui/material";
import React, { ChangeEvent, ReactElement } from "react";
import { selectPaymentType, setPaymentType } from "../../../../../store/reducers/paymentSlice/paymentSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";

import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import PMSRadio from "src/components/ui/PMSRadio/PMSRadio";
import { PaymentMethod as PaymentMethodEnum } from "../../../../../enums/PaymentMethod";
import { PaymentType } from "../../../../../models/PaymentType";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";

interface PaymentMethodProps {
  label?: string;
  paymentOptions?: PaymentMethodEnum[];
  disabled?: boolean;
  customPaymentType?: number;
  handleOnChange?: (paymentType: PaymentType) => void;
}

const PaymentMethod: React.FC<PaymentMethodProps> = ({
  label,
  paymentOptions,
  disabled,
  customPaymentType,
  handleOnChange
}): ReactElement => {
  const dispatch = useAppDispatch();
  const paymentType = useAppSelector(selectPaymentType);
  const selectedFaciltiy = useAppSelector(selectSelectedFacility);

  const handlePaymentMethodOnchange = (paymentType: PaymentType) => {
    dispatch(setPaymentType(paymentType));
  };

  const paymentMethodItems = () => {
    const items = [
      { value: PaymentMethodEnum.cash, label: "Cash" },
      { value: PaymentMethodEnum.moneyOrder, label: "Check/Money Order" },
      { value: PaymentMethodEnum.creditCard, label: "Credit Card" }
      // { value: PaymentMethodEnum.ach, label: "eCheck/ACH Payment" }
    ];

    return items.map((item) => {
      return !paymentOptions
        ? (
          <FormControlLabel
            key={item.value}
            value={item.value}
            control={<Radio checkedIcon={<CheckCircleOutline />} />}
            label={item.label}
        />
          )
        : (
            paymentOptions.includes(item.value) && (
            <FormControlLabel
              key={item.value}
              value={item.value}
              control={<Radio checkedIcon={<CheckCircleOutline />} />}
              label={item.label}
          />
            )
          );
    });
  };

  return (
    <PMSRadio
      label={label}
      value={customPaymentType || paymentType}
      name={"payment-method"}
      changeHandler={(e: ChangeEvent<HTMLInputElement>) => {
        if (handleOnChange) {
          handleOnChange(Number(e.target.value) as PaymentType);
        } else {
          handlePaymentMethodOnchange(Number(e.target.value) as PaymentType);
        }
      }
    }
    >
      {paymentMethodItems()}
    </PMSRadio>
  );
};

export default PaymentMethod;
