import { FormikErrors, FormikTouched } from "formik";
import { Grid, InputLabel, TextField } from "@mui/material";
import React, { ChangeEvent, FC } from "react";
import moment, { Moment } from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import FacilityFormValues from "src/models/formikInputValues/FacilityFormValues";
import { inputError } from "src/utils/showInputError/showInputError";
import useStyles from "./FacilityDetailsSection.styles";

interface FacilityDetailsSectionProps {
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void :
         (e: string | ChangeEvent<any>) => void;
    },
    touched: FormikTouched<FacilityFormValues>
    errors: FormikErrors<FacilityFormValues>
    values: FacilityFormValues
}

const FacilityDetailsSection: FC<FacilityDetailsSectionProps> = ({ handleChange, touched, errors, values }) => {
  const { classes } = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <InputLabel className={classes.labelWeight} htmlFor={"name"}>Marketing Name</InputLabel>
        <TextField
          id={"name"}
          fullWidth
          value={values.name}
          variant={"outlined"}
          name={"name"}
          onChange={handleChange}
          error={inputError("name", touched, errors).error}
          helperText={inputError("name", touched, errors).helperText}
        />
      </Grid>
      <Grid item xs={2.5}>
        <InputLabel className={classes.labelWeight} htmlFor={"tax_id"}>Tax Id</InputLabel>
        <TextField
          id={"tax_id"}
          fullWidth
          value={values.tax_id}
          variant={"outlined"}
          name={"tax_id"}
          onChange={handleChange}
          error={inputError("tax_id", touched, errors).error}
          helperText={inputError("tax_id", touched, errors).helperText}
        />
      </Grid>
      <Grid item xs={2.5}>
        <InputLabel htmlFor={"disposition_date"}>Disposition Date</InputLabel>
        <DatePicker
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              id={"disposition_date"}
              name={"disposition_date"}
              error={inputError("disposition_date", touched, errors).error}
              helperText={inputError("disposition_date", touched, errors).helperText}

              />
          )}
          OpenPickerButtonProps={{ "aria-label": "Select Disposition Date" }}
          inputFormat={"MM/DD/yyyy"}
          onChange={(date:Moment|null) => handleChange({
            target: {
              name: "disposition_date",
              value: moment(date).format("MM/DD/YYYY")
            }
          })}
          value={values.disposition_date}
          componentsProps={{
            leftArrowButton: { "aria-label": "Move Backward" },
            rightArrowButton: { "aria-label": "Move Backward" }
          }}
          />

      </Grid>

      {/* second row start */}
      <Grid item xs={3}>
        <InputLabel className={classes.labelWeight} htmlFor={"district_manager"}>
          District Manager
        </InputLabel>
        <TextField
          id={"district_manager"}
          fullWidth
          value={values.district_manager}
          variant={"outlined"}
          name={"district_manager"}
          onChange={handleChange}
          error={inputError("district_manager", touched, errors).error}
          helperText={inputError("district_manager", touched, errors).helperText}
        />
      </Grid>
      <Grid item xs={3}>
        <InputLabel className={classes.labelWeight} htmlFor={"facility_id"}>Facility ID</InputLabel>
        <TextField
          id={"facility_id"}
          value={values.facility_id}
          fullWidth
          variant={"outlined"}
          name={"facility_id"}
          onChange={handleChange}
          error={inputError("facility_id", touched, errors).error}
          helperText={inputError("facility_id", touched, errors).helperText}
        />
      </Grid>
      <Grid item xs={3}>
        <InputLabel className={classes.labelWeight} htmlFor={"district"}>District</InputLabel>
        {/* is this a dropdown or text field... */}
        <TextField
          id={"district"}
          value={values.district}
          fullWidth
          variant={"outlined"}
          name={"district"}
          onChange={handleChange}
          error={inputError("district", touched, errors).error}
          helperText={inputError("district", touched, errors).helperText}
        />
      </Grid>
      <Grid item xs={3}>
        <InputLabel className={classes.labelWeight} htmlFor={"area"}>Area</InputLabel>
        <TextField
          id={"area"}
          value={values.area}
          fullWidth
          variant={"outlined"}
          name={"area"}
          onChange={handleChange}
          error={inputError("area", touched, errors).error}
          helperText={inputError("area", touched, errors).helperText}
        />
      </Grid>
      <Grid item xs={3}>
        <InputLabel className={classes.labelWeight} htmlFor={"district_region"}>Region</InputLabel>
        <TextField
          id={"district_region"}
          value={values.district_region}
          fullWidth
          variant={"outlined"}
          name={"district_region"}
          onChange={handleChange}
          error={inputError("district_region", touched, errors).error}
          helperText={inputError("district_region", touched, errors).helperText}
        />
      </Grid>
    </Grid>
  );
};

export default FacilityDetailsSection;
