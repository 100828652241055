import React, { ReactElement } from "react";
import {
  selectECId,
  selectECInformationLoading,
  setEmergencyContact
} from "src/store/reducers/emergencyContactInformationSlice/emergencyContactInformationSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import ECIcons from "src/components/stepper/EmergencyContactIcons/ECIcons";
import EmergencyContact from "src/components/stepper/EmergencyContact/EmergencyContact";
import { EmergencyContactValues } from "src/models/formikInputValues/occupant/EmergencyContactValues";
import PMSAdvisory from "src/components/ui/PMSAdvisory/PMSAdvisory";
import { Paper } from "@mui/material";
import StepLayout from "src/layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "src/layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import { createEmergencyContact } from "src/store/thunks/emergencyContact/create/createEmergencyContact";
import { createOccupant } from "src/store/thunks/occupant/create/createOccupant";
import { selectDeal } from "src/store/reducers/dealSlice/dealSlice";
import { selectOccupantDetailsLoading }
  from "src/store/reducers/occupantInformationSlice/occupantInformationSlice";
import { setTargetPath } from "src/store/reducers/navigationSlice/navigationSlice";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { updateEmergencyContact } from "src/store/thunks/emergencyContact/update/updateEmergencyContact";
import { updateOccupantInStepper } from "src/store/thunks/occupant/update/stepper/updateOccupantInStepper";
import { useLocation, useNavigate } from "react-router";
import { useLazyReservationsForOccupantQuery } from "src/api/endpoints/deals";
import { Occupant } from "src/models/Occupant";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { DealTypeEnum, DealTypeStringEnum } from "src/enums/DealType";

const AddMoveInEmergencyContact: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const customerLoading = useAppSelector(selectOccupantDetailsLoading);
  const ecLoading = useAppSelector(selectECInformationLoading);
  const existingId = useAppSelector(selectECId);
  const existingDeal = useAppSelector(selectDeal) as any;
  const location = useLocation();
  const navigate = useNavigate();
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const [trigger, { isLoading }] = useLazyReservationsForOccupantQuery();
  const loading = customerLoading || ecLoading || isLoading;

  const stepperId = "move-in-emergency-contact";

  // in place b/c we need to be able to account for the possibility of a user moving back to the "add occupant" steps
  const occupantRequest = existingId ? updateOccupantInStepper() : createOccupant();
  const ecRequest = (occupantId: number) =>
    existingId ? updateEmergencyContact(occupantId) : createEmergencyContact(occupantId);
  const requestAction = existingId ? "updating" : "creating";

  // in place b/c we may be creating an occupant to move-in an existing reservation, and want to skip promos and
  // unit selection if so
  // eslint-disable-next-line max-len
  const dealType = existingDeal?.deal_type;

  const isReservationDealType = typeof dealType === "number"
    ? dealType === DealTypeEnum.reservation
    : dealType?.toLowerCase() === DealTypeStringEnum.RESERVATION;

  // dead code? did not touch when fixing crash coming from move in.
  const nextRoute = existingDeal && isReservationDealType
    ? "/occupants/create-move-in/process-move-in"
    : "/occupants/create-move-in/select-unit";

  const handleSubmit = async(values: EmergencyContactValues) => {
    await dispatch(setEmergencyContact(values));
    await dispatch(occupantRequest).then((resp: any) => {
      if (resp.type.includes("rejected")) {
        throw new Error(`There was an error ${requestAction} the Occupant`);
      }

      const occupant = resp.payload as Occupant;

      dispatch(ecRequest(resp.payload.id)).then((resp: any) => {
        if (resp.type.includes("rejected")) {
          dispatch(
            showSnackbar({
              message: `There was an error ${requestAction} the Emergency Contact`,
              variant: "error"
            })
          );
          throw new Error(`There was an error ${requestAction} the Emergency Contact`);
        } else {
          dispatch(
            showSnackbar({
              message: `Occupant successfully ${existingId ? "updated" : "added"}`,
              variant: "success"
            })
          );

          trigger({
            email: occupant.email,
            firstName: occupant.first_name,
            middleName: occupant.middle_name,
            lastName: occupant.last_name,
            facilityId: String(selectedFacility.id)
            // @ts-ignore
          }).then(({ data }) => {
            let nextRoute = "/occupants/create-move-in/select-unit";

            if (existingDeal) {
              nextRoute = "/occupants/create-move-in/process-move-in";
              dispatch(setTargetPath(location.pathname));
            } else if (data && data.length > 0) {
              nextRoute = "/occupants/create-move-in/select-reservation";
            }
            navigate(nextRoute);
          });
        }
      });
    });
  };

  const handleGoBack = () => navigate("/occupants/create-move-in/occupant-contact");

  return (
    <>
      <PMSAdvisory
        title={"Emergency Contact Information"}
        advisoryText={"Please add the following information for the occupant’s emergency contact."}
        icons={<ECIcons />}
      />
      <Paper elevation={1}>
        <StepLayout
          stepperId={stepperId}
          buttons={
            <StepperButtons
              data-testid={"move-in-emergency-contact-buttons"}
              stepperId={stepperId}
              loading={loading}
              forwardActionText={"Next"}
              backAction={handleGoBack}
            />
          }
        >
          <EmergencyContact
            data-testid={"move-in-emergency-contact-form"}
            stepperId={stepperId}
            onSubmitHandler={handleSubmit}
          />
        </StepLayout>
      </Paper>
    </>
  );
};

export default AddMoveInEmergencyContact;
