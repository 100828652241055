import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { APIFilter } from "src/models/responses/APIFilter";
import { ESignStatusSliceState } from "./ESignStatusSliceState";
import { RootState } from "../../rootStore";
import ESignDocument from "src/models/ESignDocument";

export const initialState: ESignStatusSliceState = {
  eSignDocuments: [],
  eSignDocumentsLoading: false,
  emailModal: false,
  viewESignDocumentModal: false,
  eSignDocumentsError: "",
  eSignDocumentsFilter: {
    "filter[is_signed]": "0,1"
  },
  selectedESignDocument: null
};

export const eSignTemplatesSlice = createSlice({
  name: "eSignStatus",
  initialState,
  reducers: {
    setESignDocumentStatusFilter: (state, action: PayloadAction<APIFilter>) => {
      state.eSignDocumentsFilter = action.payload;
    },
    setEmailModal: (state, action: PayloadAction<boolean>) => {
      state.emailModal = action.payload;
    },
    setViewESignDocumentModal: (state, action: PayloadAction<boolean>) => {
      state.viewESignDocumentModal = action.payload;
    },
    setselectedESignDocument: (state, action: PayloadAction<ESignDocument | null>) => {
      state.selectedESignDocument = action.payload;
    },
    resetESignDocumentsSlice: (state) => {
      Object.assign(state, initialState);
    }
  }
});

export const selectESignStatusDocuments = (state: RootState) => state.eSignStatus.eSignDocuments;
export const selectESignDocumentsFilter = (state: RootState) => state.eSignStatus.eSignDocumentsFilter;
export const selectESignStatusDocumentsLoading = (state: RootState) => state.eSignStatus.eSignDocumentsLoading;
export const selectEmailModal = (state: RootState) => state.eSignStatus.emailModal;
export const selectViewESignDocumentsModal = (state: RootState) => state.eSignStatus.viewESignDocumentModal;
export const selectSelectedESignDocument = (state: RootState) => state.eSignStatus.selectedESignDocument;

export const {
  setEmailModal, setViewESignDocumentModal, setESignDocumentStatusFilter,
  setselectedESignDocument, resetESignDocumentsSlice
} =
  eSignTemplatesSlice.actions;

export default eSignTemplatesSlice.reducer;
