import { GlobalSettingsUpdateValues } from "../GlobalSettings";
import { UpdateGlobalSettingsPayload } from "src/store/thunks/globalSetting/batchUpdate/batchUpdateGlobalSettings";

const buildGlobalSettingsPayload = (
  reservationDaysLimitSettingId: number,
  dealDaysLimitSettingId: number,
  receiptTemplateSettingId: number,
  invoiceTemplateSettingId: number,
  autoBillTemplateSettingId: number,
  eSignTemplateSettingId: number,
  pastDueEmailTemplateSettingId: number,
  quickPayEmailTemplateSettingId: number,
  smsTemplateSettingId: number,
  flagIndividualUnitForAuctionSettingId: number,
  moveOutMarkBadDebtSettingId: number,
  moveInDaysLimitSettingId: number,
  alternativeRentalFlowSettingId: number,
  reservationRemoveUnitFromInventorySettingId: number,
  enableProratedMoveOutSettingId: number,
  enableLeadDealType: number,
  defaultEmailId: number,
  defaultSenderId: number,
  values: GlobalSettingsUpdateValues
): UpdateGlobalSettingsPayload => {
  return {
    global_settings: [
      {
        id: reservationDaysLimitSettingId,
        value: String(values.reservationDateLimit)
      },
      {
        id: dealDaysLimitSettingId,
        value: String(values.dealDate)
      },
      {
        id: receiptTemplateSettingId,
        value: values.facilityReceiptTemplate
      },
      {
        id: invoiceTemplateSettingId,
        value: values.facilityInvoiceTemplate
      },
      {
        id: autoBillTemplateSettingId,
        value: values.facilityAutobillTemplate
      },
      {
        id: eSignTemplateSettingId,
        value: values.eSignEmailTemplate
      },
      {
        id: pastDueEmailTemplateSettingId,
        value: values.pastDueEmailTemplate
      },
      {
        id: quickPayEmailTemplateSettingId,
        value: values.quickPayEmailTemplate
      },
      {
        id: smsTemplateSettingId,
        value: values.smsTemplate
      },
      {
        id: flagIndividualUnitForAuctionSettingId,
        value: values.flagIndividualUnitForAuction ? String(1) : String(0)
      },
      {
        id: moveOutMarkBadDebtSettingId,
        value: values.moveOutMarkBadDebt ? String(1) : String(0)
      },
      {
        id: moveInDaysLimitSettingId,
        value: String(values.moveInDate)
      },
      {
        id: alternativeRentalFlowSettingId,
        value: values.alternativeRentalFlow ? String(1) : String(0)
      },
      {
        id: reservationRemoveUnitFromInventorySettingId,
        value: values.reservationRemoveUnitFromInventory
          ? String(1)
          : String(0)
      },
      {
        id: enableProratedMoveOutSettingId,
        value: values.enableProratedMoveOut ? String(1) : String(0)
      },
      {
        id: enableLeadDealType,
        value: values.enableLeadDealType ? String(1) : String(0)
      },
      {
        id: defaultEmailId,
        value: String(values.defaultEmail)
      },
      {
        id: defaultSenderId,
        value: String(values.defaultSender)
      }
    ]
  };
};
export default buildGlobalSettingsPayload;
