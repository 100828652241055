import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { Receipt, RotateLeft } from "@mui/icons-material";

import { setOccupantId, setRefundMerchId, setRefundModalOpen, setReturnType }
  from "src/store/reducers/pointOfSalesSlice/pointOfSalesSlice";
import {
  setTransactionIdForMerchandiseRefund
} from "src/store/reducers/merchandiseForRefundSlice/merchandiseForRefundSlice";
import { getMerchandiseForRefund } from "src/store/thunks/transactions/getMerchandiseForRefund/getMerchandiseForRefund";
import { getOccupantTransaction } from "src/store/thunks/transactions/getOccupantTransaction/getOccupantTransactions";
import { GridColDef } from "@mui/x-data-grid";
import React, { useState } from "react";
import { Transaction } from "src/models/Transaction";
import { TransactionStatus } from "src/enums/TransactionStatus";
import moment from "moment";
import { printReceipt } from "src/store/thunks/moveIn/print/printReceipt";
import { useAppDispatch } from "src/store/hooks";
import useStyles from "./IssueRefundTableColumns.style";

interface TransactionsRenderDetailsButtonProps {
  row: Transaction;
}

const payment_methods = [
  "",
  "Cash",
  "Money Order",
  "Credit Card",
  "ACH/Check"
];

export const RenderDetailsButton: React.FC<TransactionsRenderDetailsButtonProps> = ({ row }) => {
  const dispatch = useAppDispatch();

  const refundDisabled = row.status === TransactionStatus.refunded ||
    row.status === TransactionStatus.voided || row.is_all_items_refunded ||
    row.is_refunded || row.is_voided ||
    (Number(row.refunded_amount) === Number(row.amount));

  let returnType = "Refund";
  if (
    row.status === TransactionStatus.pending ||
    row.status === TransactionStatus.processing ||
    (moment().diff(moment(row.created_at!.substring(0, 10)), "days") < 1)
  ) {
    returnType = "Void";
  }

  return (
    <Grid container direction={"row"} alignItems={"center"}
      justifyContent={"start"}>
      <Tooltip title={"Print Transaction"}>
        <IconButton
          data-testid={"print-transaction-button"}
          size={"small"}
          onClick={() => {
            dispatch(printReceipt(row.id)).then((resp) => {
              if (!resp.type.includes("rejected")) {
                const link = document.createElement("a");
                link.href = resp.payload as string;
                link.target = "_blank";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            });
          }}
        >
          <Receipt />
        </IconButton>
      </Tooltip>

      <Tooltip title={returnType + " Transaction"}>
        <IconButton
          size={"small"}
          data-testid={"refund-transaction-button"}
          disabled={refundDisabled}
          onClick={(async() => {
            dispatch(setRefundMerchId(row.id));
            await dispatch(getOccupantTransaction({ transactionId: +row.id! }));
            await dispatch(setTransactionIdForMerchandiseRefund(+row.id!));

            dispatch(setOccupantId(row.occupant_id));
            dispatch(setReturnType(returnType));
            dispatch(setRefundModalOpen(true));
          })}
        >
          <RotateLeft />
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

export const RenderTransactionStatus = (status?: string) => {
  const { classes } = useStyles();

  return (
    <div
      className={`${classes.completedStatus}
        ${(status === TransactionStatus.refunded || status === TransactionStatus.voided) && classes.refundedTransaction}
        ${(status === TransactionStatus.pending) && classes.refundedTransaction}
      `}>
      <Typography
        variant={"subtitle2"}
        component={"span"}
        sx={{ textTransform: "capitalize" }}
      >
        {status}
      </Typography>
    </div>
  );
};

export const IssueRefundTableColumns = (): GridColDef[] => [
  {
    field: "id",
    renderHeader: () => <Tooltip title={"Payment ID"}><Typography>Payment ID</Typography></Tooltip>,
    minWidth: 100,
    flex: 1,
    valueGetter: (params: { row: Transaction }) =>
      params.row.id
  },
  {
    field: "created_at",
    renderHeader: () => <Tooltip title={"Date"}><Typography>Date</Typography></Tooltip>,
    minWidth: 175,
    flex: 1,
    valueGetter: (params: { row: Transaction }) => moment(params.row.created_at).format("MM/DD/YYYY h:mm A")
  },
  {
    field: "amount",
    renderHeader: () => <Tooltip title={"Amount"}><Typography>Amount</Typography></Tooltip>,
    minWidth: 120,
    flex: 1,
    valueGetter: (params: { row: Transaction }) => params.row.amount
      ? `$ ${(+params.row.amount).toFixed(2)}`
      : params.row.amount
  },
  {
    field: "payment_method",
    renderHeader: () => <Tooltip title={"Type"}><Typography>Type</Typography></Tooltip>,
    minWidth: 120,
    flex: 1,
    valueGetter: (params: { row: Transaction }) => payment_methods[params.row.payment_method!]
  },
  {
    field: "is_refunded",
    renderHeader: () => <Tooltip title={"Status"}><Typography>Status</Typography></Tooltip>,
    minWidth: 150,
    flex: 1,
    renderCell: (params: { row: Transaction }) => RenderTransactionStatus(params.row.status)
  },
  {
    field: "actionIcons",
    renderHeader: () => <Tooltip title={"Actions"}><Typography>Actions</Typography></Tooltip>,
    sortable: false,
    minWidth: 120,
    flex: 1,
    renderCell: (row) => RenderDetailsButton(row)
  }
];

export default IssueRefundTableColumns;
