import { CheckCircle, RemoveCircle } from "@mui/icons-material";
import { Grid, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React, { ReactElement } from "react";
import useStyles from "./InfoIcon.styles";

interface InfoIconProps {
  title: string
  complete: boolean
}

const InfoIcon: React.FC<InfoIconProps> = ({ title, complete }: InfoIconProps): ReactElement => {
  const { classes } = useStyles();

  return (
    <Grid
      item
      xs={6}
      className={classes.root}
      component={"ul"}
      data-testid={`${title}-icon-wrapper`}
    >
      <ListItem disableGutters className={classes.item}>
        <ListItemIcon>
          {complete
            ? <CheckCircle data-testid={"check-icon"} className={classes.checkIcon} />
            : <RemoveCircle data-testid={"remove-icon"} />
          }
        </ListItemIcon>
        <ListItemText>{title}</ListItemText>
      </ListItem>
    </Grid>
  );
};

export default InfoIcon;
