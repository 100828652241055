import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { APIListResponse } from "../../../models/responses/APIListResponse";
import { Facility } from "../../../models/Facility";
import { RootState } from "../../rootStore";
import { SelectedFacilitySliceState } from "./SelectedFacilitySliceState";
import { getAllFacilities } from "../../thunks/facility/getAll/getAllFacilities";
import { updateMiscellaneousSettings } from "src/store/thunks/facility/update/updateMiscellaneousSettings";

export const initialState: SelectedFacilitySliceState = {
  selectedFacility: JSON.parse(sessionStorage.getItem("selectedFacility") as string) ?? null,
  selectedFacilityLoading: false,
  selectedFacilityError: "",
  newFacilityAdvisoryModal: false
};

export const selectedFacilitySlice = createSlice({
  name: "selectedFacility",
  initialState,
  reducers: {
    setSelectedFacility: (state, action: PayloadAction<Facility>) => {
      state.selectedFacility = action.payload;
      sessionStorage.setItem("selectedFacility", JSON.stringify(state.selectedFacility));
    },
    setNewFacilityAdvisoryModal: (state, action: PayloadAction<boolean>) => {
      state.newFacilityAdvisoryModal = action.payload;
    },
    resetSelectedFacilitySlice: (state) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("selectedFacility");
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllFacilities.fulfilled, (state, action: PayloadAction<APIListResponse<Facility[]>>) => {
        if (!state.selectedFacility) {
          state.selectedFacility = action.payload.data[0];
          sessionStorage.setItem("selectedFacility", JSON.stringify(state.selectedFacility));
        }
      })
      .addCase(updateMiscellaneousSettings.fulfilled, (state, action: PayloadAction<Facility>) => {
        if (state.selectedFacility.id === action.payload.id) {
          state.selectedFacility = action.payload;
          sessionStorage.setItem("selectedFacility", JSON.stringify(action.payload));
        }
      });
  }
});

export const selectSelectedFacility = (state: RootState) => state.selectedFacility?.selectedFacility;
export const selectSelectedFacilityReservationDateLimit = (state: RootState) =>
  state.selectedFacility?.selectedFacility?.reservation_date_limit;
export const selectNewFacilityAdvisoryModal = (state: RootState) => state.selectedFacility?.newFacilityAdvisoryModal;

export const { setSelectedFacility, setNewFacilityAdvisoryModal, resetSelectedFacilitySlice } =
  selectedFacilitySlice.actions;

export default selectedFacilitySlice.reducer;
