import { useState } from "react";

const useSound = (url: string) => {
  const [audio] = useState(new Audio(url));

  const play = () => {
    audio.play();
  };

  return { play };
};

export default useSound;
