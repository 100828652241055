import { CreditCard, Gavel } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import {
  setCollections,
  setCreateAuctionsModalOpen,
  setDelinquentOccupant,
  setFollowUpModalOpen,
  setPaymentModalOpen,
  setSelectedDelinquent
} from "src/store/reducers/collectionsSlice/collectionsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";

import CallIcon from "@mui/icons-material/Call";
import { CollectionData } from "src/models/CollectionData";
import React from "react";
import moment from "moment";
import { selectGlobalFlagIndividualUnitForAuctionSetting }
  from "src/store/reducers/globalSettingInformationSlice/globalSettingInformationSlice";
import { setSelectedRows } from "src/store/reducers/tablesSlice/tablesSlice";

interface DelinquentOccupantsRenderDetailsButtonProps {
  row: CollectionData;
}

export const RenderDetailsButton: React.FC<DelinquentOccupantsRenderDetailsButtonProps> = ({ row }) => {
  const dispatch = useAppDispatch();
  const flagUnitForActionSetting = useAppSelector(selectGlobalFlagIndividualUnitForAuctionSetting);

  const handleSelectDelinquentOccupant = () => {
    dispatch(setFollowUpModalOpen(true));
    dispatch(setDelinquentOccupant(row));
  };

  const openPaymentModal = () => {
    dispatch(setSelectedDelinquent(row));
    dispatch(setPaymentModalOpen(true));
  };

  const openFlagForAuctionModal = () => {
    dispatch(setSelectedRows([row.id]));
    dispatch(setCollections([row]));
    dispatch(setCreateAuctionsModalOpen(true));
  };

  return (
    <Grid container direction={"row"} alignItems={"center"}
      justifyContent={"start"}>
      <Tooltip title={"Follow Up"}>
        <IconButton onClick={() => handleSelectDelinquentOccupant()} size={"small"}>
          <CallIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={"Make a Payment"}>
        <IconButton size={"small"} onClick={openPaymentModal}>
          <CreditCard data-testid={"payment-button"} />
        </IconButton>
      </Tooltip>
      <Tooltip title={"Flag Unit for Auction"}>
        <IconButton size={"small"} onClick={openFlagForAuctionModal} disabled={!!row.ledger.auction ||
          !Number(flagUnitForActionSetting?.value)}>
          <Gavel data-testid={"auction-button"} />
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

export const DelinquentOccupantsTableColumns = (): GridColDef[] => [
  {
    field: "occupant_name",
    renderHeader: () => (
      <Tooltip title={"Name"}>
        <Typography>Name</Typography>
      </Tooltip>
    ),
    flex: 1
  },
  {
    field: "unit_name",
    renderHeader: () => (
      <Tooltip title={"Unit(s)"}>
        <Typography>Unit(s)</Typography>
      </Tooltip>
    ),
    sortable: true,
    flex: 1
  },
  {
    field: "balance",
    renderHeader: () => (
      <Tooltip title={"Balance"}>
        <Typography>Balance</Typography>
      </Tooltip>
    ),
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => `$${params.row.balance}`
  },
  {
    field: "delinquent_days",
    renderHeader: () => (
      <Tooltip title={"Delinquent Days"}>
        <Typography>Del. Days</Typography>
      </Tooltip>
    ),
    flex: 1,
    sortable: true
  },
  {
    field: "last_update",
    renderHeader: () => (
      <Tooltip title={"Last Update"}>
        <Typography>Last Updt</Typography>
      </Tooltip>
    ),
    flex: 1,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.last_update ? moment(params.row.last_update).format("MM/DD/YYYY") : "N/A"
  },
  {
    field: "ledger.auction.auction_date",
    headerName: "Auction Date",
    width: 150,
    renderHeader: () => (
      <Tooltip title={"Auction Date"}>
        <Typography>Auction Date</Typography>
      </Tooltip>
    ),
    valueGetter: (params: GridValueGetterParams) => {
      const auctionDate = params.row.ledger?.auction?.auction_date;
      return auctionDate ? moment(auctionDate).format("MM/DD/YYYY") : "N/A";
    }
  },
  {
    field: "actionIcons",
    renderHeader: () => (
      <Tooltip title={"Actions"}>
        <Typography>Actions</Typography>
      </Tooltip>
    ),
    sortable: false,
    flex: 1,
    renderCell: (row) => RenderDetailsButton(row)
  }
];

export default DelinquentOccupantsTableColumns;
