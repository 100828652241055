import PermissionsSliceState, { PermissionAction, Permissions, PermissionsKey } from "./PermissionsSliceState";
import { RootState } from "../../rootStore";
import { createSlice } from "@reduxjs/toolkit";
import { getFacilityPermissions } from "src/store/thunks/permissions/getFacilityPermissions";

export const initialState: PermissionsSliceState = {
  permissions: JSON.parse(sessionStorage.getItem("permissions") as string) ?? null,
  loading: false,
  error: null
};

export const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFacilityPermissions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFacilityPermissions.fulfilled, (state, action) => {
        state.loading = false;

        if (action.payload !== undefined) {
          state.permissions = action.payload;
          sessionStorage.setItem("permissions", JSON.stringify(action.payload));
        }
      })
      .addCase(getFacilityPermissions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || null;
        sessionStorage.removeItem("permissions");
      });
  }
});

export const selectFacilityPermissions = (state: RootState) => state.permissions.permissions;

export const selectHasAnyPermissions = (state: RootState) => {
  return state.permissions.permissions !== null && Object.keys(state.permissions.permissions).length > 0;
};

export const hasPermissions = (
  permissions: Permissions,
  permissionKey: string,
  requiredPermissions: PermissionAction[],
  mode: "one" | "all"
): boolean => {
  if (!permissions || !permissions[permissionKey]) {
    return false;
  }

  const permissionArray = permissions[permissionKey];

  switch (mode) {
    case "one":
      return requiredPermissions.some((rp) => permissionArray.includes(rp));

    case "all":
      return requiredPermissions.every((rp) => permissionArray.includes(rp));

    default:
      return false;
  }
};

export function createPermissions(permissions: any, context: string): PermissionsKey {
  const actions = ["create", "read", "update", "delete"] as const;
  return actions.reduce((acc, action) => ({
    ...acc,
    [action]: hasPermissions(permissions, context, [action], "one")
  }), {} as PermissionsKey); // Ensure the initial value in reduce matches the type
}

export default permissionsSlice.reducer;
