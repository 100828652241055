import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Note Styles" })((theme) => ({
  note: {
    position: "relative",
    background: "#f2f3f4",
    padding: theme.spacing(2),
    width: "100%"
  }
}));

export default useStyles;
