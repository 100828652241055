import { GridAlignment, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Tooltip, Typography } from "@mui/material";
import {
  selectGrandTotalPrepaid,
  selectLedgerPayments,
  selectLedgers,
  selectMakeAPaymentPromoAdjustment,
  selectNetGrandTotalGross,
  selectNetGrandTotalTax,
  selectSelectedLedgers,
  selectSelectedLedgersBeforePayment,
  selectTotalPaymentDue
} from "../../../../../../store/reducers/makeAPaymentSlice/makeAPaymentSlice";

import { Occupant } from "../../../../../../models/Occupant";
import React from "react";
import PaymentStatus from "../../../../../../components/ui/PaymentStatus/PaymentStatus";
import { formatDate } from "../../../../../../utils/__dateAndTimeUtils__/formatDate/formatDate";
import { useAppSelector } from "../../../../../../store/hooks";

const PaymentConfirmationTableColumns = (occupant: Occupant | null): GridColDef[] => {
  const ledgerPayments = useAppSelector(selectLedgerPayments);
  const selectedLedgerBeforePayment = useAppSelector(selectSelectedLedgersBeforePayment);
  const selectedOccupantLedgers = useAppSelector(selectLedgers);
  const netGrandTotalGross = useAppSelector(selectNetGrandTotalGross);
  const selectedLedgers = useAppSelector(selectSelectedLedgers);
  const rightAlignment: GridAlignment = "right";
  const baseSet = [
    {
      field: "row.unit.unit_number",
      renderHeader: () => (
        <Tooltip title={"Unit"}>
          <Typography>Unit</Typography>
        </Tooltip>
      ),
      flex: 1,
      valueGetter: ({ row }: GridRenderCellParams) => row.unit.unit_number
    },
    {
      field: "occupant",
      renderHeader: () => (
        <Tooltip title={"Occupant"}>
          <Typography>Occupant</Typography>
        </Tooltip>
      ),
      flex: 1,
      valueGetter: () => `${occupant?.first_name} ${occupant?.last_name}`
    },
    {
      field: "monthly_rate",
      renderHeader: () => (
        <Tooltip title={"Deal Rate"}>
          <Typography>Deal Rate</Typography>
        </Tooltip>
      ),
      flex: 1,
      valueGetter: ({ row }: GridRenderCellParams) => `$${row.monthly_rate}`,
      align: rightAlignment
    },
    {
      field: "due_date",
      renderHeader: () => (
        <Tooltip title={"Due Date"}>
          <Typography>Due Date</Typography>
        </Tooltip>
      ),
      valueGetter: ({ row }: GridRenderCellParams) => formatDate(row.due_date),
      flex: 1
    },
    {
      field: "delinquent_days",
      renderHeader: () => (
        <Tooltip title={"Status"}>
          <Typography>Status</Typography>
        </Tooltip>
      ),
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <PaymentStatus
            currentBalance={parseInt(row.current_balance)}
            delinquentDays={parseInt(row.delinquent_days)}
          />
        );
      }
    },
    {
      field: "balance",
      renderHeader: () => (
        <Tooltip title={"Balance"}>
          <Typography>Balance</Typography>
        </Tooltip>
      ),
      valueGetter: ({ row }: GridRenderCellParams) => {
        const selectedLedger = selectedLedgers.find((ledger) => ledger.id === row.id);

        return selectedLedger ? `$${selectedLedger.current_balance}` : "N/A";
      },
      align: rightAlignment
    }
  ];

  return baseSet;
};

export default PaymentConfirmationTableColumns;
