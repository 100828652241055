import React from "react";
import { Route } from "react-router";
import RateChanges from "../pages/RateChanges/RateChanges";

export const groupName = "rate-changes";

export const RateChangesRoutes = (
  <>
    <Route path={groupName} element={<RateChanges />} />

  </>
);

export default RateChangesRoutes;
