import { Grid, IconButton, Tooltip } from "@mui/material";
import { Note as NoteIcon, RemoveCircleOutline } from "@mui/icons-material";
import React, { FC, useMemo } from "react";
import { createPermissions, selectFacilityPermissions } from "src/store/reducers/permissionsSlice/permissionsSlice";
import {
  setNoteModalUnitId,
  setNotesModalOpen,
  setUnrentableRow,
  setUnrentableUnitModalOpen
} from "src/store/reducers/unitsSliceNew/unitsSliceNew";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { FlatUnit } from "src/api/endpoints/units";
import NoteType from "src/enums/NoteType";
import { getAllNotes } from "src/store/thunks/note/getAll/getAllNotes";

type NonEditableButtonsProps = {
    row: FlatUnit
    originalIsRentable: boolean
};

const NonEditableButtons: FC<NonEditableButtonsProps> = ({ row, originalIsRentable }) => {
  const permissions = useAppSelector(selectFacilityPermissions);
  const unitsPermissions = useMemo(() => createPermissions(permissions, "manage_units"), [permissions]);
  const dispatch = useAppDispatch();

  const openNotesModal = () => {
    dispatch(setNoteModalUnitId(row.id));
    dispatch(getAllNotes({ noteType: NoteType.units, itemId: row.id }));
    dispatch(setNotesModalOpen(true));
  };

  const openUnrentableModal = () => {
    dispatch(setUnrentableUnitModalOpen(true));
    dispatch(setUnrentableRow(row));
  };

  return (
    < Grid container>
      <Tooltip title={"Add Note to Unit"}>
        <IconButton onClick={() => openNotesModal()} size={"small"}>
          < NoteIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={"Mark Unit Unrentable"}>
        <IconButton disabled={!row.is_rentable} onClick={originalIsRentable ? openUnrentableModal : undefined}
          size={"small"}>
          <DoNotDisturbIcon />
        </IconButton>
      </Tooltip>
      {(!row.is_rented && !row.is_reserved && unitsPermissions.update)
        ? (
          <Tooltip title={"Disable Unit"}>
            <IconButton size={"small"} >
              <RemoveCircleOutline />
            </IconButton>
          </Tooltip>
          )
        : null}
    </Grid>
  );
};

export default NonEditableButtons;
