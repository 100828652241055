import React, { FC, useState } from "react";
import { DataGrid, GridSortModel } from "@mui/x-data-grid";
import { Grid, Paper, Typography } from "@mui/material";
import useStyles from "./AccessTable.styles";
import EmptyTable from "src/components/ui/PMSTable/EmptyTable/EmptyTable";
import { KeypadAccess } from "src/models/KeypadAccess";
import { TimeAccess } from "src/models/TimeAccess";
import { KeypadAccessTableColumns } from "./AccessTableColumns/KeypadAccessTableColumns";
import { TimeAccessTableColumns } from "./AccessTableColumns/TimeAccessTableColumns";
import { setFacilityAccessTableModal } from "src/store/reducers/facilitySlice/facilitySlice";
import { useAppDispatch } from "src/store/hooks";

interface LedgerTableProps {
  items: KeypadAccess[] | TimeAccess[]
  collection: string
  value: string
}

const CustomEmptyTable = ({ collection, value }: {collection: string, value: string;}) => {
  const dispatch = useAppDispatch();
  const type = value === "timeAccesses" ? "timeAccess" : "keypadAccess";
  return (
    <Grid container alignItems={"center"}>
      <EmptyTable
        collectionNotFound={collection}
        value={value}
        addAction={() => dispatch(setFacilityAccessTableModal({ open: true, type }))}
      />
    </Grid>
  );
};

const AccessTable: FC<LedgerTableProps> = ({ items, collection, value }) => {
  const { classes } = useStyles();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "zone",
      sort: "asc"
    }
  ]);

  const columns = value === "timeAccesses" ? TimeAccessTableColumns : KeypadAccessTableColumns;

  return (
    <Paper>
      <Grid
        container
        item
        xs={12}
    >
        <DataGrid
          columnBuffer={columns.length}
          className={classes.root}
          columns={columns}
          rows={items}
          autoHeight
          disableSelectionOnClick
          data-testid={"ledger-items-table"}
          rowCount={items.length}
          hideFooter
          componentsProps={{
            header: { "data-testid": `ledger-items-header` },
            row: { "data-testid": `ledger-items-row` }
          }}
          components={{
            NoResultsOverlay: () => <CustomEmptyTable collection={collection} value={value}/>,
            NoRowsOverlay: () => <CustomEmptyTable collection={collection} value={value}/>
          }}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
    />
      </Grid>
    </Paper>

  );
};

export default AccessTable;
