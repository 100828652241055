import { DataGrid, GridCallbackDetails, GridRowParams, GridSelectionModel } from "@mui/x-data-grid";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import {
  selectBucketFilter,
  selectCollectionsLoading,
  setCollections
} from "src/store/reducers/collectionsSlice/collectionsSlice";
import {
  selectOrder,
  selectOrderBy,
  selectPage,
  selectRowsPerPage,
  selectSearchValue,
  selectSelectedRows,
  setOrder,
  setOrderBy,
  setPage,
  setRowsPerPage,
  setSelectedRows
} from "src/store/reducers/tablesSlice/tablesSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { CollectionData } from "src/models/CollectionData";
import { DelinquentOccupantsTableColumns } from "./DelinquentOccupantsTableColumns/DelinquentOccupantsTableColumns";
import EmptyTable from "../../../../components/ui/PMSTable/EmptyTable/EmptyTable";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { useGetDelinquentOccupantsQuery } from "src/api/endpoints/delinquentOccupants";

const DelinquentOccupantsTable: React.FC = (): ReactElement => {
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const page = useAppSelector(selectPage);
  const pageSize = useAppSelector(selectRowsPerPage);
  const order = useAppSelector(selectOrder);
  const orderBy = useAppSelector(selectOrderBy);
  const [transformedData, setTransformedData] = useState<CollectionData[]>([]);
  const bucketFilter = useAppSelector(selectBucketFilter);
  const searchValue = useAppSelector(selectSearchValue);
  const selectedRows = useAppSelector(selectSelectedRows);
  const collectionsLoading = useAppSelector(selectCollectionsLoading);
  const params = useMemo(() => ({
    page: page + 1,
    pageSize,
    rowsPerPage: pageSize,
    sortDirection: order!,
    sortBy: orderBy,
    bucket: bucketFilter,
    searchValue
  }), [page, pageSize, order, orderBy, bucketFilter, searchValue]);

  const { data, isLoading, isFetching } = useGetDelinquentOccupantsQuery({ id: selectedFacility.id, params },
    { skip: !selectedFacility.id });

  const dispatch = useAppDispatch();

  const emptyTable = () => <EmptyTable collectionNotFound={"delinquent occupants"} value={"delinquent occupant"} />;

  useEffect(() => {
    if (data?.data) {
      const newData = data.data.map((item: CollectionData) => ({
        ...item,
        id: item.ledger_id
      }));
      setTransformedData(newData);
    }
  }, [data]);

  const handleSortModelChange = (model: any) => {
    dispatch(setOrder(model[0]?.sort));
    dispatch(setOrderBy(model[0]?.field));
  };

  const isRowSelectable = (params:GridRowParams<CollectionData>) => {
    return !params.row.ledger?.auction?.auction_date;
  };
  const handleRowSelect = (selectionModel: GridSelectionModel, details: GridCallbackDetails) => {
    const updatedSelections = transformedData?.filter(row => selectionModel.includes(row.id));
    dispatch(setSelectedRows(selectionModel));
    dispatch(setCollections(updatedSelections));
  };

  const handleRowsPerPageChange = (newPageSize: number) => {
    dispatch(setRowsPerPage(newPageSize));
  };

  const handleChangePage = (newPage: number) => {
    dispatch(setPage(newPage));
  };

  return (
    <DataGrid
      checkboxSelection
      autoHeight
      loading={collectionsLoading || isLoading || isFetching}
      selectionModel={selectedRows}
      onSelectionModelChange={handleRowSelect}
      rows={transformedData || []}
      columns={DelinquentOccupantsTableColumns()}
      pageSize={pageSize}
      onPageSizeChange={handleRowsPerPageChange}
      rowsPerPageOptions={[5, 25, 50]}
      pagination
      isRowSelectable={isRowSelectable}
      page={page}
      onSortModelChange={handleSortModelChange}
      onPageChange={handleChangePage}
      disableSelectionOnClick
      rowCount={data?.meta?.total || 0}
      paginationMode={"server"}
      sortingMode={"server"}
      components={{
        NoResultsOverlay: emptyTable,
        NoRowsOverlay: emptyTable
      }}
    />
  );
};

export default DelinquentOccupantsTable;
