import moment from "moment";
import { today } from "src/utils/__dateAndTimeUtils__/today";
import { dateWarning } from "src/utils/commonWarnings";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  policy_start_date: Yup.string()
    .required("Policy start date is required")
    .typeError("Date must be valid")
    .length(10, dateWarning)
    .test({
      name: "validDate",
      message: "Policy start date must be today or before",
      test: function(value) {
        return moment(value).isSameOrBefore(today);
      }
    }),
  policy_end_date: Yup.date().required("Policy end date is required").typeError("Date must be valid"),
  policy_number: Yup.string().required("Policy number is required"),
  coverage_amount: Yup.string().required("Coverage amount is required"),
  description: Yup.string().required("Plan description is required")
});

export default validationSchema;
