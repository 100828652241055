import { axiosInstance as axios } from "../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";

export const getOccupantTaxExemptDocument = createAsyncThunk(
  "getOccupantTaxExemptDocument",
  (
    { occupantId }: {occupantId: string | number},
    { dispatch, getState, rejectWithValue }
  ) => {
    return axios.get(`/api/v1/occupants/${occupantId}/uploaded-documents/get-occupant-tax-exempt`)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = "There was an error fetching the tax exempt document";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
