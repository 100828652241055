import { Unit } from "src/models/Unit";
import { UnrentableUnitPayload } from "./UnrentableUnitPayload";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const unitUnrentable = createAsyncThunk(
  "unitUnrentable",
  ({ unit, note }: {unit: Unit, note: string}, { dispatch, getState, rejectWithValue }) => {
    const payload: UnrentableUnitPayload = {
      unit_id: unit.id,
      note: {
        title: null,
        description: note
      },
      unrentable_reason: unit.unrentable_reason || 4
    };

    return axios.post(`/api/v1/units/unrentable`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: `Unit ${unit.unit_number} marked as unrentable`,
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = `There was an error marking Unit ${unit.unit_number} as unrentable`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
