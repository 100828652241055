/* eslint-disable max-len */
import { Divider, Grid, Paper, Typography } from "@mui/material";
import React, { FC } from "react";
import { selectMerchForRefundItems, selectSummaryLineItemsForRefund } from "src/store/reducers/merchandiseForRefundSlice/merchandiseForRefundSlice";
import { LineItemProps } from "src/components/paymentSummary/LineItem/LineItem";
import PaymentSummary from "src/components/paymentSummary/PaymentSummary/PaymentSummary";
import { useAppSelector } from "src/store/hooks";

const RefundMerchandiseSummary: FC = () => {
  const summaryLineItemsForRefund = useAppSelector(selectSummaryLineItemsForRefund);
  const selectMerchForRefund = useAppSelector(selectMerchForRefundItems);

  const lineItems: LineItemProps[] = summaryLineItemsForRefund.map(merch => {
    return {
      name: merch.name,
      amount: merch.cost
    };
  });

  let totals: Array<{ name: string; amount: string }> = [];

  totals = [
    {
      name: "Subtotal",
      amount: selectMerchForRefund.subTotal
    },
    {
      name: "Tax",
      amount: selectMerchForRefund.tax
    },
    {
      name: "Final Return",
      amount: selectMerchForRefund.finalBalance
    }
  ];

  return (
    <Paper
      component={Grid}
      container
      p={2}
      mt={2}
      variant={"outlined"}
      direction={"column"}
      py={3}
      >
      <Grid px={1} item xs={12}>
        <Typography gutterBottom variant={"h5"}>
          Total
        </Typography>
        <Grid px={1} pb={1} item
          xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <PaymentSummary
        lineItems={lineItems}
        totals={totals}
      />
    </Paper>
  );
};

export default RefundMerchandiseSummary;
