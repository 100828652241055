import Moment from "moment";
import { Task } from "../../models/Task";

export const generateDateAndTime = (task: Task) => {
  if (task.is_all_day || (!task.is_all_day && !task.due_time)) {
    return task.due_date;
  }
  // @ts-ignore
  const parsedTime = new Moment(task.due_time, ["h:mm A"]).format("HH:mm");
  // @ts-ignore
  return new Moment(task.due_date + "T" + parsedTime).toISOString();
};
