import { Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect } from "react";
import {
  selectFirstName,
  selectLastName,
  selectMiddleName,
  selectOccupantAddressInformation
} from "src/store/reducers/occupantInformationSlice/occupantInformationSlice";
import { FormikValues } from "formik";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import { inputError } from "src/utils/showInputError/showInputError";
import {
  selectOccupantBillingAddressOption
} from "src/store/reducers/occupantBillingInformationSlice/occupantBillingInformationSlice";
import usStates from "src/utils/usStates";
import { useAppSelector } from "src/store/hooks";
import { selectChangeOwnershipIsEnabled } from "src/store/reducers/occupantSlice/occupantSlice";
import { selectFormOccupantInformation } from "src/store/reducers/changeOwnership/changeOwnership";
import MaskedPostalCode from "src/components/masking/MaskedPostalCode/MaskedPostalCode";

interface BillingAddressFormProps {
    formik: FormikValues;
}

const BillingAddressForm = ({ formik }: BillingAddressFormProps) => {
  const { values, handleChange, touched, errors, setFieldError, setFieldValue, setFieldTouched } = formik;
  const occupantBillingAddressOption = useAppSelector(selectOccupantBillingAddressOption);
  const occupantAddressInformation = useAppSelector(selectOccupantAddressInformation);
  const occupantFirstName = useAppSelector(selectFirstName);
  const occupantMiddleName = useAppSelector(selectMiddleName);
  const occupantLastName = useAppSelector(selectLastName);
  const changeOwnershipIsEnabled = useAppSelector(selectChangeOwnershipIsEnabled);
  const formOccupantInformation = useAppSelector(selectFormOccupantInformation);

  const handleZipCodeChange = (fieldName: string, fieldValue: string) => {
    setFieldTouched(fieldName);
    setFieldValue(fieldName, fieldValue);
  };

  const setSameAsMailing = () => {
    if (changeOwnershipIsEnabled) {
      setFieldValue("firstName", formOccupantInformation.firstName);
      setFieldValue("middleName", formOccupantInformation.middleName);
      setFieldValue("lastName", formOccupantInformation.lastName);
      setFieldValue("addressOne", formOccupantInformation.address);
      setFieldValue("addressTwo", formOccupantInformation.addressTwo);
      setFieldValue("city", formOccupantInformation.city);
      setFieldValue("state", formOccupantInformation.state);
      setFieldValue("postalCode", formOccupantInformation.postalCode);
    } else {
      setFieldValue("firstName", occupantFirstName);
      setFieldValue("middleName", occupantMiddleName);
      setFieldValue("lastName", occupantLastName);
      setFieldValue("addressOne", occupantAddressInformation.address);
      setFieldValue("addressTwo", occupantAddressInformation.addressTwo);
      setFieldValue("city", occupantAddressInformation.city);
      setFieldValue("state", occupantAddressInformation.state);
      setFieldValue("postalCode", occupantAddressInformation.postalCode);
    }
    setFieldError("firstName", "");
    setFieldError("middleName", "");
    setFieldError("lastName", "");
    setFieldError("addressOne", "");
    setFieldError("addressTwo", "");
    setFieldError("city", "");
    setFieldError("state", "");
    setFieldError("postalCode", "");
  };

  useEffect(() => {
    occupantBillingAddressOption === 1 && setSameAsMailing();
  }, [occupantBillingAddressOption]);

  return (
    <Grid container spacing={2}>
      <Grid xs={8} lg={5} item>
        <InputLabel htmlFor={"first-name"}>First Name</InputLabel>
        <TextField
          disabled={occupantBillingAddressOption === 1}
          fullWidth
          placeholder={"- First Name -"}
          id={"first-name"}
          inputProps={{ "data-testid": "first-name" }}
          name={"firstName"}
          value={values.firstName}
          onChange={handleChange}
          error={inputError("firstName", touched, errors).error}
          helperText={inputError("firstName", touched, errors).helperText}
          />
      </Grid>
      <Grid xs={4} lg={2} item>
        <InputLabel htmlFor={"middle-name"}>Middle Initial</InputLabel>
        <TextField
          disabled={occupantBillingAddressOption === 1}
          fullWidth
          placeholder={"- Middle Initial -"}
          id={"middle-name"}
          inputProps={{ "data-testid": "middle-name" }}
          name={"middleName"}
          value={values.middleName}
          onChange={handleChange}
          error={inputError("middleName", touched, errors).error}
          helperText={inputError("middleName", touched, errors).helperText}
          />
      </Grid>
      <Grid xs={12} lg={5} item>
        <InputLabel htmlFor={"last-name"}>Last Name</InputLabel>
        <TextField
          disabled={occupantBillingAddressOption === 1}
          fullWidth
          placeholder={"- Last Name -"}
          id={"last-name"}
          inputProps={{ "data-testid": "last-name" }}
          name={"lastName"}
          value={values.lastName}
          onChange={handleChange}
          error={inputError("lastName", touched, errors).error}
          helperText={inputError("lastName", touched, errors).helperText}
          />
      </Grid>
      <Grid item xs={12} lg={6}>
        <InputLabel htmlFor={"mailing-address-one"}>Mailing Address 1</InputLabel>
        <TextField
          disabled={occupantBillingAddressOption === 1}
          fullWidth
          placeholder={"- Mailing Address 1 -"}
          id={"mailing-address-one"}
          inputProps={{ "data-testid": "mailing-address-one" }}
          name={"addressOne"}
          value={values.addressOne}
          onChange={handleChange}
          error={inputError("addressOne", touched, errors).error}
          helperText={inputError("addressOne", touched, errors).helperText}
          />
      </Grid>
      <Grid item xs={12} lg={6}>
        <InputLabel htmlFor={"mailing-address-two"}>Mailing Address 2</InputLabel>
        <TextField
          disabled={occupantBillingAddressOption === 1}
          fullWidth
          placeholder={"- Mailing Address 2 -"}
          id={"mailing-address-two"}
          inputProps={{ "data-testid": "mailing-address-two" }}
          name={"addressTwo"}
          value={values.addressTwo}
          onChange={handleChange}
          error={inputError("addressTwo", touched, errors).error}
          helperText={inputError("addressTwo", touched, errors).helperText}
          />
      </Grid>
      <Grid xs={6} lg={3} item>
        <InputLabel htmlFor={"city"}>City</InputLabel>
        <TextField
          disabled={occupantBillingAddressOption === 1}
          fullWidth
          placeholder={"- City -"}
          id={"city"}
          inputProps={{ "data-testid": "city" }}
          name={"city"}
          value={values.city}
          onChange={handleChange}
          error={inputError("city", touched, errors).error}
          helperText={inputError("city", touched, errors).helperText}
          />
      </Grid>
      <Grid xs={6} lg={3} item>
        <PMSSelect
          disabled={occupantBillingAddressOption === 1}
          id={"state"}
          name={"state"}
          label={"State"}
          value={values.state}
          changeHandler={handleChange}
          error={inputError("state", touched, errors).error}
          helperText={inputError("state", touched, errors).helperText}
          >
          <option value={""} disabled>{" - State -"}</option>
          {usStates.map(state => <option key={state.value} value={state.value}>{state.text}</option>)}
        </PMSSelect>
      </Grid>
      <Grid xs={6} lg={3} item>
        <InputLabel htmlFor={"postal-code"}>Postal Code</InputLabel>
        <MaskedPostalCode
          disabled={occupantBillingAddressOption === 1}
          fullWidth
          placeholder={"- Postal Code -"}
          id={"postal-code"}
          name={"postalCode"}
          value={values.postalCode}
          onChange={(e) => handleZipCodeChange("postalCode", e.target.value)}
          error={inputError("postalCode", touched, errors).error}
          helperText={inputError("postalCode", touched, errors).helperText}
          />
      </Grid>
      <Grid xs={6} lg={3} item>
        <InputLabel htmlFor={"country"}>Country</InputLabel>
        <Select
          fullWidth
          disabled
          defaultValue={""}
          id={"country"}
          name={"country"}
          label={"country"}
          displayEmpty
          variant={"outlined"}
          value={""}
          inputProps={{ "data-testid": "country" }}
          >
          <option value={""} disabled>United States</option>
        </Select>
      </Grid>
    </Grid>
  );
};

export default BillingAddressForm;
