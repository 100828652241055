import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "SelectedOccupantForDeal" })((theme) => ({
  greyCard: {
    position: "relative",
    background: "#f2f3f4",
    padding: theme.spacing(2)
  },
  boldName: {
    fontWeight: 900,
    paddingBottom: theme.spacing(1)
  },
  closeIcon: {
    cursor: "pointer",
    position: "absolute",
    top: 5,
    right: 5
  }
}));

export default useStyles;
