import { APIFilter } from "../../../../models/responses/APIFilter";
import { RootState } from "src/store/rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSearchParams } from "../../../../utils/getSearchParams/getSearchParams";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getAllActiveDeals = createAsyncThunk(
  "getAllActiveDeals",
  (filter: APIFilter | "", { dispatch, getState, rejectWithValue }) => {
    const store = getState() as RootState;
    const facilityId = store.selectedFacility.selectedFacility!.id;
    const searchParams = getSearchParams(store, true);
    const defaultParams = {
      sortDirection: "asc",
      sortBy: "contact_at"
    };

    const requestParams = {
      params: {
        ...Object.assign(
          {},
          searchParams,
          searchParams.sortBy === "" ? defaultParams : {}
        ),
        "filter[is_converted]": 0,
        with: ["facility", "unit", "productType", "unit.productType", "promotion:id,name"]
      }
    };

    if (filter) {
      requestParams.params = { ...filter, ...requestParams.params };
    }

    return axios.get(`/api/v1/facilities/${facilityId}/deals`, requestParams)
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = "There was an error fetching the Deals";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
