import { Alert, Button, Grid, Paper, Typography } from "@mui/material";
import React, { FC, SyntheticEvent } from "react";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import AccessHoursSection from "./AccessHoursSection/AccessHoursSection";
import Collapsible from "src/components/ui/Collapsible/Collapsible";
import FacilityDetails from "./FacilityDetailsSection/FacilityDetailsSection";
import FacilityFormValues from "src/models/formikInputValues/FacilityFormValues";
import FacilityLocationDetailsSection from "./FacilityLocationDetailsSection/FacilityLocationDetailsSection";
import HoursOfOperationSection from "./HoursOfOperationSection/HoursOfOperationSection";
import { createFacility } from "src/store/thunks/facility/create/createFacility";
import facilityFormValidation from "./facilityFormValidation";
import { formatDate } from "src/utils/__dateAndTimeUtils__/formatDate/formatDate";
import { getAllPaginatedFacilities } from "src/store/thunks/facility/getAllPaginated/getAllPaginatedFacilities";
import { selectFacility } from "src/store/reducers/facilitySlice/facilitySlice";
import { updateFacility } from "src/store/thunks/facility/update/updateFacility";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import useStyles from "./FacilityForm.styles";
import SIPConfigurationSection from "./SIPConfigurationSection/SIPConfigurationSection";
import {
  createPermissions,
  hasPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

interface FacilityFormRebuildProps {
  initialValues: FacilityFormValues;
  isUpdate?: boolean;
}

const FacilityFormRebuild: FC<FacilityFormRebuildProps> = ({ initialValues, isUpdate = false }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedFacility = useAppSelector(selectFacility);
  const { classes } = useStyles();
  const formik = useFormik({
    initialValues,
    validationSchema: facilityFormValidation,
    onSubmit: (values: FacilityFormValues) => {
      const lat = values.latitude ? parseFloat(String(values.latitude)).toFixed(2) : "0";
      const long = values.longitude ? parseFloat(String(values.longitude)).toFixed(2) : "0";

      const payload = {
        ...values,
        latitude: lat,
        longitude: long,
        disposition_date: formatDate(values.disposition_date, "YYYY-MM-DD", ""),
        country: "USA"
      };

      const facilityThunk = isUpdate
        ? updateFacility({
          ...payload,
          id: +selectedFacility!.id,
          legacy_id: selectedFacility?.legacy_id ? String(selectedFacility.legacy_id) : null
        })
        : createFacility(payload);

      dispatch(facilityThunk).then((resp) => {
        if (!resp.type.includes("rejected")) {
          navigate("/facility-management/manage-facilities");
          dispatch(getAllPaginatedFacilities());
        }
      });
    }
  });

  const { errors, touched, values, setFieldTouched, setFieldError, handleChange, setFieldValue, handleSubmit } = formik;
  const hasErrors = !!Object.keys(errors).length;

  const permissions = useAppSelector(selectFacilityPermissions);
  const addFacilityPermissions = createPermissions(permissions, "add_facility");
  const facilityPermissions = createPermissions(permissions, "facility");

  return (
    addFacilityPermissions.create || facilityPermissions.update
      ? (<Grid container>
        <Grid
          container
          component={"form"}
          onSubmit={(e: SyntheticEvent) => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit();
          }}
          id={"facility-form"}
        >
          <Typography component={Grid} item xs={12}
            variant={"h5"}>
            {isUpdate ? "Edit Current Facility" : "Add New Facility to Facility List"}
          </Typography>
          {hasErrors && (
            <Alert severity={"error"} className={classes.alert}>
              <Typography data-testid={"FacilityForm-ErrorPage-copy"} variant={"subtitle1"}>
                Some fields are required when {isUpdate ? "editing" : "adding"} a facility and cannot be blank.
              </Typography>
            </Alert>
          )}
          <Grid container component={Paper} variant={"outlined"}
            p={4} my={2}>
            <Grid item xs={12}>
              <Collapsible title={"Facility Details"}>
                <FacilityDetails values={values} errors={errors} touched={touched}
                  handleChange={handleChange} />
              </Collapsible>
            </Grid>
          </Grid>
          <Grid my={2} container component={Paper}
            variant={"outlined"} p={4}>
            <Grid item xs={12}>
              <Collapsible title={"Location Details"}>
                <FacilityLocationDetailsSection
                  handleChange={handleChange}
                  values={values}
                  touched={touched}
                  errors={errors}
                />
              </Collapsible>
            </Grid>
          </Grid>
          <Grid my={2} container component={Paper}
            variant={"outlined"} p={4}>
            <Grid item xs={12}>
              <Collapsible title={"Hours of Operation"}>
                <HoursOfOperationSection
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  setFieldError={setFieldError}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                />
              </Collapsible>
            </Grid>
          </Grid>
          <Grid my={2} container component={Paper}
            variant={"outlined"} p={4}>
            <Grid item xs={12}>
              <Collapsible title={"Gate Access Hours"}>
                <AccessHoursSection
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  setFieldError={setFieldError}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                />
              </Collapsible>
            </Grid>
          </Grid>
          <Grid my={2} container component={Paper}
            variant={"outlined"} p={4}>
            <Grid item xs={12}>
              <Collapsible title={"Location Details"}>
                <FacilityLocationDetailsSection
                  handleChange={handleChange}
                  values={values}
                  touched={touched}
                  errors={errors}
                />
              </Collapsible>
            </Grid>
          </Grid>
          <Grid my={2} container component={Paper}
            variant={"outlined"} p={4}>
            <Grid item xs={12}>
              <Collapsible title={"SIP Configuration"}>
                <SIPConfigurationSection handleChange={handleChange} values={values} touched={touched}
                  errors={errors} />
              </Collapsible>
            </Grid>
          </Grid>
          <Grid mt={2} container justifyContent={"flex-end"}
            spacing={2} item xs={12}>
            <Grid item>
              <Button onClick={() => navigate("/facility-management/manage-facilities")} color={"error"}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button type={"submit"} variant={"contained"} className={classes.saveButton}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>)
      : <Grid>
        <h1>You do not have permission to view this page.</h1>
      </Grid>
  );
};

export default FacilityFormRebuild;
