import { Chip, Grid, InputLabel, TextField } from "@mui/material";
import React, { ReactElement } from "react";
import useStyles from "./SelectedItemDisplay.styles";

export interface SelectedItemDisplayProps {
  selectedItem: string
  displayName: string
  removeAction: () => void
  price?: string
}

const SelectedItemDisplay: React.FC<SelectedItemDisplayProps> = ({
  selectedItem,
  displayName,
  removeAction,
  price
}): ReactElement => {
  const { classes } = useStyles();

  return (
    <Grid
      container
      item
      mt={3}
      justifyContent={"start"}
      alignItems={"start"}
    >
      <Grid item>
        <InputLabel className={classes.label} data-testid={"chip-label"}>
          Selected {selectedItem}:
        </InputLabel>
        <Chip
          data-testid={`remove-selected-${selectedItem.toLowerCase()}-icon`}
          label={displayName}
          onDelete={() => removeAction()}
          className={classes.chip}
        />
      </Grid>
      {price && <Grid
        direction={"column"}
        container
        item
        ml={5}
        xs={2}
        xl={1}
        justifyContent={"end"}
      >
        <InputLabel htmlFor={"street-rate"} className={classes.label}>Deal Rate:</InputLabel>
        <TextField
          id={"street-rate"}
          className={classes.textField}
          disabled
          value={`$${price}`}
        />
      </Grid>}
    </Grid>
  );
};

export default SelectedItemDisplay;
