import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { Breadcrumb } from "../../../models/Breadcrumb";
import FacilityForm from "../FacilityForm/FacilityForm";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import TabView from "src/layouts/TabView/TabView";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import initialFacilityFormValues from "../FacilityForm/FacilityHoursForm/initialFacilityFormValues";

const AddFacility: FC = () => {
  const breadcrumbs: Breadcrumb[] = [
    {
      name: "Facility Management"
    },
    {
      name: "Add Facility",
      bold: true
    }
  ];

  const pageHeader = <PageHeader title={"Add Facility"} breadcrumbs={breadcrumbs} />;

  const componentList = [
    {
      name: "Facility Information",
      component: <FacilityForm initialValues={initialFacilityFormValues} />
    },
    {
      name: "Facility Documents",
      component: <Grid container item xs={12}>
        <Typography>Docs</Typography>
      </Grid>
    }
  ];

  return (
    <ViewWrapper pageHeader={pageHeader}>
      <TabView
        innerPadding={false}
        showPaper={false}
        loading={false}
        components={componentList}
      />
    </ViewWrapper>
  );
};

export default AddFacility;
