import { makeStyles } from "../../makeStyles";

const useStyles = makeStyles({ label: "Redirect403" })((theme) => ({
  statusText: {
    color: theme.palette.text.primary,
    fontSize: "125px",
    fontWeight: "bold"
  },
  titleText: {
    color: theme.palette.text.primary,
    fontSize: "30px",
    fontWeight: "bold"
  },
  subtitleText: {
    color: theme.palette.text.primary,
    lineHeight: "1.6",
    fontSize: "20px"
  },
  returnText: {
    color: theme.palette.primary.contrastText,
    fontSize: "18px"
  }
}));

export default useStyles;
