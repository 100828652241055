import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";
import { TransferUnitCostPayload } from "./TransferUnitCostPayload";

export const getTransferUnitCost = createAsyncThunk(
  "getTransferUnitCost",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const unitId = state.selectedUnit.selectedUnit!.id;
    const leaseStartDate = state.transferUnit.leaseStartsOn;
    const monthlyRate = state.transferUnit.monthlyRate;
    const periods = state.transferUnit.periods;
    const promotionId = state.selectedPromotion.selectedPromotion?.id;
    const moveInFees = state.transferUnit.fees;
    const moveInPlan = state.transferUnit.insurancePlanId;
    const merchandise = state.transferUnit.merchandise;
    const dealId = state.deal.deal?.id ?? null;
    const promoDefermentStatus = state.transferUnit.promoDefermentStatus;
    const waiveFee = state.transferUnit.waiveFee.waiveFeeAmount;
    const refundType = state.transferUnitCost.refundType;

    const payload: TransferUnitCostPayload = {
      new_unit_id: unitId,
      lease_start_date: leaseStartDate,
      monthly_rate: +monthlyRate,
      periods: periods as number,
      fees: moveInFees,
      merchandise: merchandise,
      tpp_plan_id: moveInPlan,
      promotion_id: promotionId as number,
      deal_id: dealId,
      is_promo_deferred: promoDefermentStatus,
      waive_fee: waiveFee,
      refund_type: refundType
    };

    const occupantId = state.selectedOccupant.selectedOccupant?.id;
    const ledger = state.ledgers?.selectedLedgerToTransfer;

    return axios
      .post(`/api/v1/occupants/${occupantId}/ledgers/${ledger}/transfer-cost`, payload)
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        let errorMessage = "There was an error getting the Transfer Cost";

        if (
          err &&
          err.response &&
          err.response.status &&
          err.response.status === 422 &&
          err.response.data.errors &&
          Object.keys(err.response.data.errors).length
        ) {
          errorMessage = format422(err.response.data.errors);
        } else if (err && err.response && err.response.data && err.response.data.detail) {
          errorMessage += `: ${err.response.data.detail}`;
        }

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  }
);
