import { makeStyles } from "../../../../../../makeStyles";

const useStyles = makeStyles<{ isSelected: boolean}>({ label: "UnitRow" })((theme, { isSelected }) => ({
  unitRow: {
    "& > *": {
      borderTop: "unset",
      borderBottom: "unset",
      backgroundColor: isSelected ? theme.palette.active : theme.palette.background.default,
      color: isSelected ? theme.palette.background.paper : theme.palette.text.primary
    },
    "&:hover > *": {
      backgroundColor: theme.palette.hover,
      color: theme.palette.background.paper
    },
    cursor: "pointer"
  }
}));

export default useStyles;
