import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { Tooltip, Typography } from "@mui/material";
import React from "react";
import { Unit } from "src/models/Unit";
import { formatDate } from "../../../../../../utils/__dateAndTimeUtils__/formatDate/formatDate";
import { getUnitSizeFromUnit } from "src/utils/getUnitSize/getUnitSize";

export const displayUnitsTableColumns = (): GridColDef[] => [
  {
    minWidth: 120,
    flex: 1,
    field: "product_type",
    headerName: "Product Type",
    valueGetter: (params: GridValueGetterParams) => params.row?.unit?.product_type.name
  },
  {
    field: "unit",
    renderHeader: () => (
      <Tooltip title={"Unit"}>
        <Typography>Unit</Typography>
      </Tooltip>
    ),
    minWidth: 75,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.unit.unit_number
  },
  {
    field: "unit_size",
    renderHeader: () => (
      <Tooltip title={"Unit Size"}>
        <Typography>U Size</Typography>
      </Tooltip>
    ),
    minWidth: 100,
    flex: 1,
    valueGetter: ({ row }: GridValueGetterParams) => getUnitSizeFromUnit(row.unit as Unit)
  },
  {
    field: "floor",
    renderHeader: () => (
      <Tooltip title={"Floor"}>
        <Typography>Floor</Typography>
      </Tooltip>
    ),
    minWidth: 75,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.unit.floor
  },
  {
    field: "quoted_price",
    renderHeader: () => (
      <Tooltip title={"Deal Rate"}>
        <Typography>D Rate</Typography>
      </Tooltip>
    ),
    minWidth: 100,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => `$${Number(params.row.quoted_price).toFixed(2)}`
  },
  {
    field: "needed_at",
    renderHeader: () => (
      <Tooltip title={"Rental Date"}>
        <Typography>Rental Date</Typography>
      </Tooltip>
    ),
    minWidth: 100,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => formatDate(params.row.needed_at as string)
  },
  {
    field: "promotion",
    renderHeader: () => (
      <Tooltip title={"Applied Promotion"}>
        <Typography>Appl Promo</Typography>
      </Tooltip>
    ),
    minWidth: 160,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.promotion?.name || "None"
  }
];

export default displayUnitsTableColumns;
