import { Divider, Grid, IconButton, Modal, Paper, Typography } from "@mui/material";
import React, { FC } from "react";
import {
  selectCurrentReport,
  selectParamsBuilderModalOpen,
  setCurrentReport,
  setParamsBuilderModalOpen,
  setReportDate,
  setReportEndDate,
  setReportFacilities,
  setReportFacility,
  setReportHours,
  setReportStartDate
} from "src/store/reducers/reportParamsSlice/reportParamsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Close } from "@mui/icons-material";
import FacilityMultiSelectAndDateRange from "../ParamInputs/FacilityMultiSelectAndDate/FacilityMultiSelectAndDate";
import useStyles from "./ParamsBuilderModal.styles";

interface ParamsBuilderModalProps { }

const ParamsBuilderModal: FC<ParamsBuilderModalProps> = () => {
  const modalOpen = useAppSelector(selectParamsBuilderModalOpen);
  const reportName = useAppSelector(selectCurrentReport);
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setParamsBuilderModalOpen(false));
    dispatch(setCurrentReport(""));
    dispatch(setReportStartDate(""));
    dispatch(setReportDate(""));
    dispatch(setReportEndDate(""));
    dispatch(setReportHours(""));
    dispatch(setReportFacilities([]));
    dispatch(setReportFacility([]));
  };

  return (
    <Modal onClose={handleClose} className={classes.modal} open={modalOpen}>
      <Grid
        className={classes.container}
        component={Paper}
        elevation={0}
        container
        spacing={2}
        p={4}
        pl={2}
      >
        <Grid item xs={11}>
          <Typography variant={"h4"}>{reportName}</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
        <Grid
          component={"form"}
          id={"facility-and-dates"}
          container
          item
          pl={0}
          spacing={2}
          alignItems={"center"}
          xs={12}
        >
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.filterByText} textTransform={"uppercase"} fontWeight={"bold"}>
              Filter By
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Divider className={classes.filterByDivider} />
          </Grid>
          <Grid item xs={12}>
            <FacilityMultiSelectAndDateRange />
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ParamsBuilderModal;
