
import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "PMSTable" })((theme) => ({
  root: {
    "& .MuiTablePagination-toolbar": {
      display: "none"
    }
  },
  addEditButton: {
    textTransform: "capitalize",
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText
  },
  subtotalBar: {
    padding: theme.spacing(1),
    background: theme.palette.grey[200]
  }
}));

export default useStyles;
