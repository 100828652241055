const formatPhone = (phoneNumber?: string | number | null, withDashes?: boolean): string => {
  if (!phoneNumber) {
    return "N/A";
  }

  if (typeof phoneNumber === "number") {
    phoneNumber = phoneNumber.toFixed();
  }

  if (phoneNumber && phoneNumber.length !== 10) {
    return phoneNumber;
  }

  const formattedPhone = ("" + phoneNumber).replace(/\D/g, "");
  const match = formattedPhone.match(/^(\d{3})(\d{3})(\d{4})$/)!;

  if (!match || !match.length) {
    return "N/A";
  }

  return !withDashes ? `(${match[1]}) ${match[2]}-${match[3]}` : `${match[1]}-${match[2]}-${match[3]}`;
};

export default formatPhone;
