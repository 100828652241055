import { Button, Grid, Typography } from "@mui/material";
import clsx from "clsx";
import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import NoItemsDisplay from "src/components/itemManagement/NoItemsDisplay/NoItemsDisplay";
import FacilitySelector from "src/components/navigation/FacilitySelector/FacilitySelector";
import FilterButtonGroup from "src/components/ui/FilterButtonGroup/FilterButtonGroup";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import ItemManagementLayout from "src/layouts/ItemManagement/ItemManagementLayout";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { Breadcrumb } from "src/models/Breadcrumb";
import { Facility } from "src/models/Facility";
import { InsurancePlanValues } from "src/models/formikInputValues/InsurancePlanValues";
import { InsurancePlan } from "src/models/InsurancePlan";
import InsurancePlanCard from "src/pages/InsurancePlans/InsurancePlanCard/InsurancePlanCard";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  selectActiveInsurancePlans,
  selectInactiveInsurancePlans,
  selectInsurancePlansLoading,
  selectShowActiveInsurancePlans,
  setShowActiveInsurancePlans
} from "src/store/reducers/insurancePlansSlice/insurancePlansSlice";
import {
  resetSelectedAdminFacilitySlice,
  selectSelectedAdminFacility,
  setSelectedAdminFacility
} from "src/store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import { AppDispatch } from "src/store/rootStore";
import { disableInsurancePlan } from "src/store/thunks/insurancePlans/disable/disableInsurancePlan";
import { enableInsurancePlan } from "src/store/thunks/insurancePlans/enable/enableInsurancePlan";
import {
  getAllInsurancePlansForFacility
} from "src/store/thunks/insurancePlans/getAllForFacility/getAllInsurancePlansForFacility";
import useStyles from "./InsurancePlans.styles";
import {
  setInsurancePlanInformation,
  setPlanIsActive
} from "../../store/reducers/insurancePlanInformationSlice/insurancePlanInformationSlice";
import { mockFacilities } from "../../mocks/entities/mockFacilities";
import history from "src/utils/history";
import DisableInsurancePlanModal from "./DisableInsurancePlanModal/DisableInsurancePlanModal";
import { addMoveInInsurancePlan } from "src/store/reducers/moveInSlice/moveInSlice";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Fees & Charges"
  },
  {
    name: "Manage TPP",
    bold: true
  }
];

const InsurancePlans: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selectedAdminFacility = useAppSelector(selectSelectedAdminFacility);
  const insurancePlansLoading = useAppSelector(selectInsurancePlansLoading);

  const showActiveInsurancePlans = useAppSelector(selectShowActiveInsurancePlans);
  const activeInsurancePlans = useAppSelector(selectActiveInsurancePlans);
  const inActiveInsurancePlans = useAppSelector(selectInactiveInsurancePlans);
  const displayedInsurancePlans = showActiveInsurancePlans ? activeInsurancePlans : inActiveInsurancePlans;
  const { classes } = useStyles({ isEnabled: showActiveInsurancePlans });
  const permissions = useAppSelector(selectFacilityPermissions);
  const insurancePlansPermissions = createPermissions(permissions, "insurance_plans");

  const [disableModalOpen, setDisableModalOpen] = useState(false);
  const [selectedInsurancePlan, setSelectedInsurancePlan] = useState({} as InsurancePlan);

  const handleOnFacilityChange = (selectedFacility: Facility) => {
    dispatch(setSelectedAdminFacility(selectedFacility));
    dispatch(getAllInsurancePlansForFacility(selectedFacility.id));
  };

  useEffect(() => {
    if (selectedAdminFacility && displayedInsurancePlans.length === 0) {
      handleOnFacilityChange(selectedAdminFacility);
    }
  }, [selectedAdminFacility, displayedInsurancePlans]);

  const handleEnablePlan = (values: InsurancePlanValues) => {
    dispatch(setInsurancePlanInformation(values));
    dispatch(setPlanIsActive(true));
    dispatch(enableInsurancePlan(values.id!)).then((resp) => {
      if (!resp.type.includes("rejected")) {
        dispatch(getAllInsurancePlansForFacility(selectedAdminFacility!.id));
        dispatch(setShowActiveInsurancePlans(true));
      }
    });
  };

  const onSubmit = () => {
    dispatch(disableInsurancePlan(selectedInsurancePlan.id)).then((resp) => {
      if (!resp.type.includes("rejected")) {
        setDisableModalOpen(false);
        dispatch(getAllInsurancePlansForFacility(selectedAdminFacility!.id));
        dispatch(setShowActiveInsurancePlans(false));
      }
    });
  };

  const insurancePlansList = (
    <Grid container spacing={2} mt={1}
      data-testid={"fees-list"}>
      {displayedInsurancePlans.map((plan) => (
        <Grid key={plan.id} item xs={6}>
          <InsurancePlanCard
            item={plan}
            onEnablePlan={handleEnablePlan}
            onDisablePlan={() => {
              setDisableModalOpen(true);
              setSelectedInsurancePlan(plan);
            }}
          />
        </Grid>
      ))}
    </Grid>
  );

  const insurancePlansDisplay = () => {
    if (!selectedAdminFacility) {
      return null;
    }

    if (!displayedInsurancePlans.length) {
      return (
        <NoItemsDisplay
          item={`insurance plan`}
          link={"/insurance-plans/create"}
          message={showActiveInsurancePlans ? "No Active Insurance Plans" : "No Disabled Insurance Plans"}
        />
      );
    }

    return insurancePlansList;
  };

  const title = (
    <Grid item container alignItems={"center"}>
      <Grid item>
        <Typography component={"label"} htmlFor={"insurance-facility-selector"} variant={"h6"}>
          TPP for:
        </Typography>
      </Grid>
      <Grid item ml={2}>
        <FacilitySelector
          navBar={false}
          onFacilityChange={handleOnFacilityChange}
          elementId={"insurance-facility-selector"}
          data-testid={"insurance-plans-facility-selector"}
        />
      </Grid>
    </Grid>
  );

  const ctaButton = () => {
    return (
      selectedAdminFacility && (
        <Button
          data-testid={"fees-cta-button"}
          className={classes.ctaButton}
          color={"primary"}
          variant={"text"}
          // startIcon={<AddCircle />}
          component={NavLink}
          to={"/insurance-plans/create"}
        >
          New Insurance Plan
        </Button>
      )
    );
  };

  const filters = !!selectedAdminFacility && (
    <FilterButtonGroup heading={"Show Plans:"} data-testid={"fees-filters"}>
      <Button
        className={clsx(classes.buttonText, classes.enabledButton)}
        color={"secondary"}
        data-testid={"active-insurance-plans"}
        onClick={() => dispatch(setShowActiveInsurancePlans(true))}
      >
        Active
      </Button>
      <Button
        className={clsx(classes.buttonText, classes.disabledButton)}
        data-testid={"disabled-insurance-plans"}
        onClick={() => dispatch(setShowActiveInsurancePlans(false))}
      >
        Disabled
      </Button>
    </FilterButtonGroup>
  );

  const handleReset = (dispatch: AppDispatch) => {
    dispatch(resetSelectedAdminFacilitySlice());
  };

  /* istanbul ignore next */ // cannot test this w/ enzyme
  useEffect(() => {
    const unlisten = history.listen(location => {
      if (!location.location.pathname.includes("insurance-plans")) {
        handleReset(dispatch);
      }
    });
    return unlisten;
  }, []);

  const pageHeader = <PageHeader title={"Manage TPP"} breadcrumbs={breadcrumbs} />;

  if (insurancePlansPermissions.read) {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <ItemManagementLayout title={title} loading={insurancePlansLoading} filters={filters}
          headerButton={insurancePlansPermissions.create ? ctaButton() : null}>
          {insurancePlansDisplay()}
        </ItemManagementLayout>
        <DisableInsurancePlanModal
          open={disableModalOpen}
          onClose={() => setDisableModalOpen(false)}
          onSubmit={onSubmit}
        />
      </ViewWrapper>
    );
  } else {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <h1>You do not have permission to view this page.</h1>
      </ViewWrapper>
    );
  }
};

export default InsurancePlans;
