import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getAllInsurancePlansForFacility = createAsyncThunk(
  "getAllInsurancePlansForFacility",
  (facilityId: number, { dispatch, rejectWithValue }) => {
    const params = {
      params: {
        with: ["revenueClass", "documentTemplate"],
        sortBy: "name"
      }
    };

    return axios.get(`/api/v1/facilities/${facilityId}/tpp-plans`, params)
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = "There was an error getting the Insurance Plans for this Facility.";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
