import { RootState } from "../../rootStore";
import { UnitSliceState } from "./UnitSliceState"; // every slice should have a state typing
import { createSlice } from "@reduxjs/toolkit";

export const initialState: UnitSliceState = {
  unit: null,
  unitLoading: false,
  unitError: ""
};

export const unitSlice = createSlice({
  name: "unit",
  initialState,
  reducers: {
    setUnit: (state, action) => {
      state.unit = action.payload;
    },
    resetUnitSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {}
});

export const selectUnit = (state: RootState) => state.unit.unit;
export const selectUnitLoading = (state: RootState) => state.unit.unitLoading;

export const {
  setUnit,
  resetUnitSlice
} = unitSlice.actions;

export default unitSlice.reducer;
