import {
  Box,
  Button,
  CircularProgress, FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import React, { ChangeEvent } from "react";
import {
  selectNewProductCategoryName,
  selectNewProductSubCategoryName,
  selectProductCategory,
  selectProductCategoryLoading,
  selectProductSubCategory,
  selectProductSubCategoryLoading,
  setNewProductCategory, setNewProductSubCategory,
  setProductCategory,
  setProductSubCategory
} from "../../../../store/reducers/productCategorySlice/productCategorySlice";
import {
  filterProductSubCategories,
  selectFilteredSubCategories,
  selectProductCategories, selectProductSubCategories
} from "../../../../store/reducers/productCategoriesSlice/productCategoriesSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { ProductCategory } from "../../../../models/ProductCategory";
import StepLayout from "../../../../layouts/stepper/StepLayout/StepLayout";
import { createProductCategory } from "../../../../store/thunks/productCategory/create/createProductCategory";
import {
  createProductSubCategory
} from "../../../../store/thunks/productSubCategory/create/createProductSubCategory";
import { useFormik } from "formik";
import useStyles from "./ProductCategories.styles";
import PMSAdvisory from "../../../../components/ui/PMSAdvisory/PMSAdvisory";
import Paper from "@mui/material/Paper";
import StepperButtons from "../../../../layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import { useNavigate } from "react-router";
import { getAllProductCategories } from "../../../../store/thunks/productCategory/getAll/getAllProductCategories";

const ProductCategories: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const productCategoryLoading = useAppSelector(selectProductCategoryLoading);
  const productSubCategoryLoading = useAppSelector(selectProductSubCategoryLoading);
  const productCategories = useAppSelector(selectProductCategories);
  const productCategory = useAppSelector(selectProductCategory);
  const productSubCategories = useAppSelector(selectProductSubCategories);
  const filteredSubCategories = useAppSelector(selectFilteredSubCategories);
  const productSubCategory = useAppSelector(selectProductSubCategory);
  const newProductCategoryName = useAppSelector(selectNewProductCategoryName);
  const newProductSubCategoryName = useAppSelector(selectNewProductSubCategoryName);

  const formik = useFormik({
    initialValues: {
      productCategory: productCategory && productCategory.name ? productCategory.name : "",
      newProductCategory: newProductCategoryName,
      productSubCategory: productSubCategory && productSubCategory.name ? productSubCategory.name : "",
      newProductSubCategory: newProductSubCategoryName
    },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => navigate("/product-types/add-product-type/revenue-class")
  });

  const { touched, errors, values } = formik;

  const updateForm = (fieldName: string, fieldValue?: string | boolean): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleProductCategoryOnChange = (fieldName: string, fieldValue: string) => {
    // dispatch(setProductSubCategory(null));
    // updateForm("productSubCategory", "");
    //
    // if (fieldValue === "cancel") {
    //   updateForm(fieldName, "");
    //   dispatch(setProductCategory(null));
    //   return;
    // }
    //
    // const category = productCategories.find(category => category.name === fieldValue);
    // updateForm(fieldName, fieldValue);
    // dispatch(setProductCategory(category));
    // dispatch(filterProductSubCategories(category.id));
  };

  const handleNewProductCategory = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setNewProductCategory(fieldValue));
  };

  const handleCreateProductCategory = async() => {
    await dispatch(createProductCategory());
    await dispatch(getAllProductCategories());
    updateForm("newProductCategory", "");
  };

  const handleProductSubCategoryOnChange = (fieldName: string, fieldValue: string) => {
    // if (fieldValue === "cancel") {
    //   updateForm(fieldName, "");
    //   dispatch(setProductSubCategory(null));
    //   return;
    // }
    //
    // const subCategory = productSubCategories.find(category => category.name === fieldValue)!;
    // updateForm(fieldName, fieldValue);
    // dispatch(setProductSubCategory(subCategory));
  };

  const handleAddProductSubCategory = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setNewProductSubCategory(fieldValue));
  };

  const handleCreateProductSubCategory = async() => {
    await dispatch(createProductSubCategory());
    await dispatch(getAllProductCategories());

    if (productCategory) {
      dispatch(filterProductSubCategories(productCategory.id));
    }
    updateForm("newProductSubCategory", "");
  };

  const stepperId = "product-and-sub-class-form";

  const advisoryText = (
    <>
      <p>New classes can be entered into the system or selected
        from the dropdown of existing classes and sub-classes.</p>
      <p>
        For example, a class can be identified as
        <b> &quot;Inside Storage&quot;</b> while a sub-class can be identified as
        <b> &quot;Economy&quot;</b> or <b>&quot;Preferred&quot;</b>.
      </p>
      <p>
        <b>Note:</b>
        <br />
        You cannot select a product sub-class without first selecting a product class.
      </p>
    </>
  );

  return (
    <>
      <PMSAdvisory
        title={"Select Your Product Class & Sub-Class"}
        advisoryText={advisoryText}
        allowSkip={true}
        skipHandler={() => navigate("/product-types/add-product-type/revenue-class")}
      />
      <Paper elevation={1}>
        <StepLayout stepperId={stepperId} buttons={
          <StepperButtons
            data-testid={"select-product-type-buttons"}
            stepperId={stepperId}
            backAction={() => navigate("/product-types/add-product-type/define-product-type")}
          />
        }>
          <form
            id={stepperId}
            data-testid={stepperId}
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              formik.handleSubmit();
            }}
          >
            <Box margin={1}>
              <Grid container justifyContent={"space-between"}>
                <Grid
                  pr={1}
                  pb={1}
                  item
                  xs={12}
                >
                  <Grid item container alignItems={"center"}>
                    <Grid xs={9} mr={2} item>
                      <InputLabel htmlFor={"add-product-category-to-list"} className={classes.label}>
                        Add Product Class to List
                      </InputLabel>
                      <TextField
                        fullWidth
                        placeholder={"- New Product Class -"}
                        id={"add-product-category-to-list"}
                        inputProps={{ "data-testid": "add-product-category-to-list" }}
                        name={"newProductCategory"}
                        value={values.newProductCategory}
                        disabled
                        // onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        //   handleNewProductCategory("newProductCategory", e.target.value)
                        // }
                        error={
                          touched && touched.newProductCategory && errors && Boolean(errors.newProductCategory)
                        }
                        helperText={touched && touched.newProductCategory && errors && errors.newProductCategory}
                      />
                    </Grid>
                    <Grid item pt={2}>
                      <Button
                        className={classes.addToListButton}
                        variant={"contained"}
                        type={"button"}
                        disabled
                        data-testid={"add-product-category-button"}
                        onClick={() => handleCreateProductCategory()}
                      >
                        {productCategoryLoading
                          ? <CircularProgress
                              data-testid={"add-product-category-spinner"}
                              aria-label={"product class loading"}
                              size={"1.5rem"}
                              className={classes.spinner}
                          />
                          : <span>Add To List</span>
                        }
                      </Button>
                    </Grid>
                    <Grid mt={2} container item>
                      <InputLabel htmlFor={"product-category"} className={classes.label}>
                        Product Class
                      </InputLabel>
                      <Select
                        name={"productCategory"}
                        defaultValue={""}
                        value={values.productCategory}
                        disabled
                        // onChange={e => handleProductCategoryOnChange("productCategory", e.target.value)}
                        variant={"outlined"}
                        fullWidth
                        displayEmpty
                        id={"product-category"}
                        inputProps={{ "data-testid": "product-category" }}
                        error={touched && touched.productCategory && errors && Boolean(errors.productCategory)}
                      >
                        <MenuItem value={""} disabled>
                          <span>Select a product class...</span>
                        </MenuItem>
                        {productCategories.map((productCategory: ProductCategory) => (
                          <MenuItem key={productCategory.id} value={productCategory.name}>
                            {productCategory.name}
                          </MenuItem>
                        ))}
                        <MenuItem value={"cancel"}>Cancel</MenuItem>
                      </Select>
                      {touched &&
                        touched.productCategory &&
                        touched.productCategory &&
                        errors &&
                        errors.productCategory &&
                        Boolean(errors.productCategory) && (
                          <FormHelperText error>
                            {errors.productCategory}
                          </FormHelperText>)}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  pr={1}
                  pb={1}
                  item
                  xs={12}
                >
                  <Grid
                    item
                    container
                    mt={3}
                    alignItems={"center"}
                  >
                    <Grid xs={9} mr={2} item>
                      <InputLabel htmlFor={"add-product-sub-category-to-list"} className={classes.label}>
                        Add Product Sub-Class to List
                      </InputLabel>
                      <TextField
                        fullWidth
                        placeholder={"- New Product Sub-Class -"}
                        id={"add-product-sub-category-to-list"}
                        inputProps={{ "data-testid": "add-product-sub-category-to-list" }}
                        name={"newProductSubCategory"}
                        disabled={!productCategory}
                        value={values.newProductSubCategory}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleAddProductSubCategory("newProductSubCategory", e.target.value)
                        }
                        error={touched &&
                          touched.newProductSubCategory &&
                          errors &&
                          Boolean(errors.newProductSubCategory)
                        }
                        helperText={touched && touched.newProductSubCategory && errors && errors.newProductSubCategory}
                      />
                    </Grid>
                    <Grid item pt={2}>
                      <Button
                        className={classes.addToListButton}
                        variant={"contained"}
                        type={"button"}
                        data-testid={"add-product-sub-category-button"}
                        disabled={!productCategory}
                        onClick={() => handleCreateProductSubCategory()}
                      >
                        {productSubCategoryLoading
                          ? <CircularProgress
                              data-testid={"add-product-sub-category-spinner"}
                              aria-label={"product sub category loading"}
                              size={"1.5rem"}
                              className={classes.spinner}
                          />
                          : <span>Add To List</span>
                        }
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid mt={2}>
                    <InputLabel htmlFor={"product-sub-category"} className={classes.label}>
                      Product Sub-Class
                    </InputLabel>
                    <Select
                      name={"productSubCategory"}
                      defaultValue={""}
                      value={values.productSubCategory}
                      onChange={e => handleProductSubCategoryOnChange("productSubCategory", e.target.value)}
                      variant={"outlined"}
                      disabled={!productCategory}
                      fullWidth
                      displayEmpty
                      id={"product-sub-category"}
                      inputProps={{ "data-testid": "product-sub-category" }}
                      error={touched && touched.productSubCategory && errors && Boolean(errors.productSubCategory)}
                    >
                      <MenuItem value={""} disabled>
                        <span>Select a product sub-class...</span>
                      </MenuItem>
                      {filteredSubCategories.map((subCategory: ProductCategory) => (
                        <MenuItem key={subCategory.id} value={subCategory.name}>{subCategory.name}</MenuItem>
                      ))}
                      <MenuItem value={"cancel"}>Cancel</MenuItem>
                    </Select>
                    {touched &&
                      touched.productSubCategory &&
                      touched.productSubCategory &&
                      errors &&
                      errors.productSubCategory &&
                      Boolean(errors.productSubCategory) && (
                        <FormHelperText error>
                          {errors.productSubCategory}
                        </FormHelperText>)}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </form>
        </StepLayout>
      </Paper>
    </>
  );
};

export default ProductCategories;
