import { Grid, SelectChangeEvent, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { selectFacilities, selectFacilitiesLoading } from "../../../store/reducers/facilitiesSlice/facilitiesSlice";
import {
  selectNewFacilityAdvisoryModal,
  selectSelectedFacility,
  setNewFacilityAdvisoryModal,
  setSelectedFacility
} from "../../../store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useLocation, useNavigate } from "react-router";
import AppModal from "../../ui/AppModal/AppModal";
import { Facility } from "../../../models/Facility";
import PMSSelect from "../../ui/PMSSelect/PMSSelect";
import { groupName as delinquencyAutomationRoutes } from "src/routes/DelinquencyAutomationRoutes";
import { groupName as documentManagementRoutes } from "../../../routes/DocumentManagementRoutes";
import { groupName as feesRoutes } from "../../../routes/FeesRoutes";
import { getAllTaskCategories } from "src/store/thunks/taskCategory/getAll/getAllTaskCategories";
import { getTasks } from "src/store/thunks/task/getAll/getTasks";
import { groupName as insurancePlanRoutes } from "../../../routes/InsurancePlansRoutes";
import { groupName as promotionsRoutes } from "../../../routes/PromotionsRoutes";
import { groupName as revenueClassesRoutes } from "../../../routes/AccountingRoutes";
import {
  selectSelectedAdminFacility
} from "../../../store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import { groupName as taskManagementRoutes } from "../../../routes/TaskManagementRoutes";
import { groupName as rateChangesRoutes } from "../../../routes/RateChangesRoutes";
import useStyles from "./FacilitySelector.styles";
import { useSoftphone } from "src/hooks/useSoftphone";
import { getFacilityPermissions } from "src/store/thunks/permissions/getFacilityPermissions";
import { resetDealNotifications } from "src/store/reducers/dealNotificationSlice/dealNotificationSlice";
import { setIsDisplayed } from "src/store/reducers/appSoftphoneSlice/appSoftphoneSlice";
import { SoftphoneDisplayMode } from "src/store/reducers/appSoftphoneSlice/appSoftphoneSliceState";
import { resetTablesSlice } from "src/store/reducers/tablesSlice/tablesSlice";

export const getRouteGroupName = (fullPath: string) => fullPath.split("/")[1];

interface FacilitySelectorProps {
  navBar?: boolean;
  onFacilityChange?: Function;
  elementId?: string;
  handleOnAllFacilitiiesChange?: () => void;
}

const FacilitySelector: React.FC<FacilitySelectorProps> = ({
  navBar,
  onFacilityChange,
  elementId = "facility-selector",
  handleOnAllFacilitiiesChange
}): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const facilities = useAppSelector(selectFacilities);
  const facilitiesLoading = useAppSelector(selectFacilitiesLoading);
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const selectedAdminFacility = useAppSelector(selectSelectedAdminFacility);
  const newFacilityAdvisoryModal = useAppSelector(selectNewFacilityAdvisoryModal);
  const selectedFacilityId = !navBar ? selectedAdminFacility?.id || 0 : selectedFacility?.id || 0;
  const [facilityToBeSelected, setFacilityToBeSelected] = React.useState<Facility | null>(null);
  const { handleFacilityChange } = useSoftphone();
  const location = useLocation();
  const navigate = useNavigate();

  const adminRoutes = [
    documentManagementRoutes,
    delinquencyAutomationRoutes,
    feesRoutes,
    promotionsRoutes,
    revenueClassesRoutes,
    insurancePlanRoutes,
    taskManagementRoutes,
    rateChangesRoutes
  ];

  const inAdminSection = adminRoutes.includes(getRouteGroupName(location.pathname));

  const handleSelectedFacility = (event: SelectChangeEvent) => {
    const facilityIdSelected = parseInt(event.target.value);

    if (facilityIdSelected === selectedFacilityId) {
      return;
    }

    const facility = facilities.find((facility) => facility.id === facilityIdSelected);

    if (facility) {
      if (navBar) {
        dispatch(setNewFacilityAdvisoryModal(true));
        return setFacilityToBeSelected(facility);
      }

      if (onFacilityChange) {
        onFacilityChange(facility);
      }
    }

    if (!facility && handleOnAllFacilitiiesChange) {
      handleOnAllFacilitiiesChange();
    }
  };

  const handleNewFacilitySelection = async() => {
    dispatch(resetTablesSlice());
    dispatch(setSelectedFacility(facilityToBeSelected!));
    dispatch(getTasks({ getNotifications: true }));
    dispatch(getAllTaskCategories());
    dispatch(getFacilityPermissions());
    navigate("/");
    dispatch(setNewFacilityAdvisoryModal(false));
    handleFacilityChange(facilityToBeSelected);

    dispatch(setIsDisplayed(SoftphoneDisplayMode.Hide));
    dispatch(resetDealNotifications());
  };

  const facilitiesItems = facilities.map((facility) => (
    <option data-testid={`facility-${facility.id}`} key={facility.id} value={facility.id}>
      {facility.facility_id} - {facility.name}
    </option>
  ));

  return (
    <>
      <PMSSelect
        className={classes.facilitySelector}
        id={elementId}
        data-testid={"facility-selector"}
        name={"selectedFacility"}
        value={selectedFacilityId}
        changeHandler={handleSelectedFacility}
        disabled={facilitiesLoading || (navBar && inAdminSection)}
      >
        {!handleOnAllFacilitiiesChange
          ? (
            <option value={0} disabled>
              {facilitiesLoading ? "Facilities loading..." : "Select a Facility"}
            </option>
            )
          : (
            <option value={0}>{facilitiesLoading ? "Facilities loading..." : "All Facilities"}</option>
            )}

        {facilitiesItems}
      </PMSSelect>
      <AppModal
        confirmActionLoading={facilitiesLoading}
        data-testid={"facility-selector-advisory-modal"}
        open={newFacilityAdvisoryModal}
        confirmAction={() => handleNewFacilitySelection()}
        cancelAction={() => dispatch(setNewFacilityAdvisoryModal(false))}
      >
        <Grid item className={classes.modalTitle} px={5}>
          <Typography variant={"h6"} component={"span"} gutterBottom>
            Before you leave...
          </Typography>
        </Grid>
        <Grid container direction={"column"} my={2}
          mx={5}>
          <Typography>You have unsaved changes that will be lost</Typography>
          <Typography>if you navigate away from the page. Are you sure?</Typography>
        </Grid>
      </AppModal>
    </>
  );
};

export default FacilitySelector;
