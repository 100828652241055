import React, { PropsWithChildren, ReactElement, useEffect, useState } from "react";
import {
  resetMakeAPaymentSlice,
  selectIsFromPayNow
} from "../../../store/reducers/makeAPaymentSlice/makeAPaymentSlice";
import { useLocation, useNavigate } from "react-router";

import AppModal from "src/components/ui/AppModal/AppModal";
import { Breadcrumb } from "../../../models/Breadcrumb";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import { Typography } from "@mui/material";
import ViewWrapper from "../../../layouts/ViewWrapper/ViewWrapper";
import { blockNavigation } from "src/components/router/CustomRouter";
import { resetAchSlice } from "../../../store/reducers/achSlice/achSlice";
import { resetCashSlice } from "../../../store/reducers/cashSlice/cashSlice";
import { resetCreditCardSlice } from "../../../store/reducers/creditCardSlice/creditCardSlice";
import { resetPaymentSlice } from "../../../store/reducers/paymentSlice/paymentSlice";
import { resetSelectedOccupantSlice } from "../../../store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { resetTransactionSlice } from "src/store/reducers/transactionSlice/transactionSlice";
import { useAppDispatch } from "../../../store/hooks";
import { useSelector } from "react-redux";

const MakeAPayment: React.FC<PropsWithChildren> = ({ children }): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isFromPayNow = useSelector(selectIsFromPayNow);
  const pageLocation = useLocation();
  const [workflowIncompleteWarning, setWorkflowIncompleteWarning] = useState(false);
  const [localTargetPath, setLocalTargetPath] = useState<string>("");
  let unblock: Function;

  const breadcrumbs: Breadcrumb[] = [
    {
      name: "Collections"
    },
    {
      name: "Make A Payment"
    }
  ];

  const pageHeader = <PageHeader title={"Make A Payment"} breadcrumbs={breadcrumbs} />;

  const handleReset = () => {
    dispatch(resetMakeAPaymentSlice());
    dispatch(resetSelectedOccupantSlice());
    dispatch(resetPaymentSlice());
    dispatch(resetCashSlice());
    dispatch(resetCreditCardSlice());
    dispatch(resetAchSlice());
    dispatch(resetTransactionSlice());
  };

  const handleAdvisory = () => {
    return blockNavigation(({ location }) => {
      const nextLocation = location.pathname;
      const currentLocation = pageLocation.pathname;
      setLocalTargetPath(nextLocation);

      const workflowComplete = currentLocation === "/collections/make-a-payment/payment-confirmation";

      const restartingWorkflow = nextLocation === "/collections/make-a-payment";

      if (
        // you're not on the first step
        currentLocation !== "/collections/make-a-payment" &&
        // and you aren't navigating b/w steps or completely restarting the workflow
        (!nextLocation.includes("/collections/make-a-payment") || restartingWorkflow) &&
        // and you have not completed the move-in
        !workflowComplete
        // then show the advisory modal
      ) {
        setWorkflowIncompleteWarning(true);
        return false;
      }

      // clear state if completed and starting again
      if (workflowComplete && restartingWorkflow) {
        handleReset();
      }

      unblock();
      navigate(nextLocation);
    });
  };

  useEffect(() => {
    unblock = handleAdvisory();

    /* istanbul ignore next */ // cannot test this w/ enzyme
    return () => {
      if (unblock) {
        unblock();
      }
    };
  });

  useEffect(() => {
    if (!isFromPayNow) {
      handleReset();
    }
    return () => {
      if (!isFromPayNow) {
        handleReset();
      }
    };
  }, []);

  const confirmNavigationAway = () => {
    if (unblock) {
      unblock();
    }

    handleReset();

    navigate(localTargetPath);
    setWorkflowIncompleteWarning(false);
  };

  return (
    <ViewWrapper pageHeader={pageHeader}>
      {children}
      <AppModal
        open={workflowIncompleteWarning}
        cancelAction={() => setWorkflowIncompleteWarning(false)}
        confirmAction={confirmNavigationAway}
        data-testid={"app-layout-modal"}
      >
        <Typography gutterBottom variant={"h6"}>
          Warning
        </Typography>
        <Typography variant={"body1"}>
          You are about to leave an incomplete workflow. If you choose to continue, information you have provided during
          this and previous steps will be lost.
        </Typography>
      </AppModal>
    </ViewWrapper>
  );
};

export default MakeAPayment;
