import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { selectFacility, selectFacilityLoading } from "src/store/reducers/facilitySlice/facilitySlice";
import {
  useAppDispatch,
  useAppSelector
} from "src/store/hooks";
import clsx from "clsx";
import { disableFacility } from "src/store/thunks/facility/disable/disableFacility";
import { getAllFacilities } from "../../../../../store/thunks/facility/getAll/getAllFacilities";
import {
  getAllPaginatedFacilities
} from "../../../../../store/thunks/facility/getAllPaginated/getAllPaginatedFacilities";
import { toggleFacilityDisableModal } from "src/store/reducers/modalSlice/modalSlice";
import useStyles from "./DisableModal.styles";

export const DisableModal: FC = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const selectedFacility = useAppSelector(selectFacility);
  const facilityLoading = useAppSelector(selectFacilityLoading);

  const handleDisableFacility = () => {
    dispatch(disableFacility())
      .then(() => dispatch(getAllPaginatedFacilities()))
      .then(() => dispatch(getAllFacilities()));
  };

  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      className={classes.innerModalContainer}
    >
      <Typography
        className={classes.modalTitle}
        id={"simple-modal-title"}
        align={"center"}
        variant={"h5"}
      >
        Confirm Facility Disable
      </Typography>
      <Typography
        id={"simple-modal-description"}
        align={"center"}
      >
        Please confirm deactivation of facility {selectedFacility?.id}
      </Typography>
      <Grid
        container
        className={classes.modalInputs}
        direction={"column"}
      >
        <Typography gutterBottom>Facility ID: {selectedFacility?.id}</Typography>
        <Typography data-testid={"marketing-name"} gutterBottom>
          Marketing Name: {selectedFacility?.name}
        </Typography>
        <Typography gutterBottom>Legal Name: {selectedFacility?.legal_name}</Typography>

        <Grid
          item
          container
          justifyContent={"space-between"}
          className={classes.buttonRow}
        >
          <Button
            className={clsx(classes.modalButton, classes.cancel)}
            variant={"outlined"}
            data-testid={"modal-cancel-button"}
            onClick={() => dispatch(toggleFacilityDisableModal(false))}
          >
            Cancel
          </Button>
          <Button
            className={clsx(classes.modalButton, classes.confirm)}
            variant={"outlined"}
            data-testid={"modal-confirm-button"}
            disabled={facilityLoading}
            onClick={() => handleDisableFacility()}
          >
            {facilityLoading
              ? <CircularProgress
                  size={"1.25rem"}
                  data-testid={"submit-spinner"}
                  aria-label={"disable modal submitting"}
              />
              : "Disable"
            }
          </Button>
        </Grid>

      </Grid>
    </Grid>
  );
};

export default DisableModal;
