import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const downloadDocumentTemplate = createAsyncThunk("downloadDocumentTemplate", (
  documentTemplateId: number,
  { dispatch, getState, rejectWithValue }) => {
  return axios
    .get(`/api/v1/document-templates/${documentTemplateId}/print`, {
      responseType: "blob"
    })
    .then((resp) => {
      const fileUrl = window.URL.createObjectURL(new Blob([resp.data], { type: "application/pdf" }));
      dispatch(showSnackbar({
        message: `Document Template download successfuly`,
        variant: "success"
      }));
      return fileUrl;
    })
    .catch(() => {
      const errorMessage = "There was an error emailing the receipt";

      dispatch(
        showSnackbar({
          message: errorMessage,
          variant: "error"
        })
      );

      return rejectWithValue(errorMessage);
    });
});
