import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getAllFeesForFacility = createAsyncThunk(
  "getAllFeesForFacility",
  ({ facilityId, filter }: {facilityId: number, filter?: {[key: string]: string}}, { dispatch, rejectWithValue }) => {
    const payload = {
      params: {
        with: ["facility", "feeCategory", "revenueClass"],
        sortBy: "name",
        ...filter
      }
    };

    return axios.get(`/api/v1/facilities/${facilityId}/fees`, payload)
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = "There was an error getting the Fees";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
