import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "Edit Occupant" })((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    margin: "10%"
  },
  closeButton: {
    position: "absolute",
    right: 10,
    bottom: 0
  }
}));

export default useStyles;
