import { RootState } from "./../../../rootStore";
import { UpdateOccupantPayload } from "./UpdateOccupantPayload";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const updateOccupant = createAsyncThunk("updateOccupant",
  (values: UpdateOccupantPayload, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const { id, ...rest } = values;

    const newPayload: Partial<UpdateOccupantPayload> = {
      ...rest,
      facility_id: state.selectedFacility.selectedFacility.id,
      date_of_birth: rest.date_of_birth === ("Invalid date" || "N/A") ? null : rest.date_of_birth
    };

    return axios.put(`/api/v1/occupants/${id}`, newPayload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: `Occupant ${id} successfully updated.`,
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        if (err.response.status === 422) {
          const errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        const errorMessage = "There was an error updating the occupant";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
