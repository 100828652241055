import { makeStyles } from "../../makeStyles";

const useStyles = makeStyles({ label: "ViewWrapper" })((theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    maxWidth: "initial",
    padding: "0 5rem 0 5rem"
  },
  extraPadding: { padding: "0 10rem 0 10rem" }
}));

export default useStyles;
