import React from "react";
import { Route } from "react-router";
import MySchedule from "../pages/MySchedule/MySchedule";

export const groupName = "my-schedule";

export const MyScheduleRoutes = (
  <Route path={groupName} element={<MySchedule />} />
);

export default MyScheduleRoutes;
