import { Button, Divider, Grid, IconButton, Modal, Paper, Typography } from "@mui/material";
import {
  selectAutomaticPaymentSlice,
  selectReRunAutoBillModalLoading,
  selectReRunAutoBillModalOpen,
  setDisableAutoBillModalOpened,
  setReRunAutoBillModalOpen
} from "src/store/reducers/automaticPaymentsSlice/automaticPaymentsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";

import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import React from "react";
import useStyles from "./ReRunAutoBillModal.styles";
import { selectSelectedLedger, setSelectedLedger } from "src/store/reducers/ledgersSlice/ledgersSlice";
import { reRunAutoPay } from "src/store/thunks/ledger/autoPay/reRunAutoPay";
import { setChangeOwnerShipStep } from "src/store/reducers/occupantSlice/occupantSlice";
import { getOccupant } from "src/store/thunks/occupant/getOne/getOccupant";

const ReRunAutoBillModal = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectReRunAutoBillModalLoading);
  const open = useAppSelector(selectReRunAutoBillModalOpen);
  const selectedLedger = useAppSelector(selectSelectedLedger);

  const handleCloseModal = () => {
    dispatch(setReRunAutoBillModalOpen(false));
  };

  const handleSubmit = () => {
    dispatch(reRunAutoPay({ occupant_id: selectedLedger?.occupant_id!, ledger_id: selectedLedger?.id! })).then(() => {
      handleCloseModal();
      dispatch(setSelectedLedger(null));
      dispatch(setChangeOwnerShipStep(3));
      dispatch(getOccupant(String(selectedLedger?.occupant_id!)));
    });
  };

  return (
    <Modal onClose={handleCloseModal} open={open} className={classes.modal}>
      <Grid
        p={4}
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        justifyContent={"center"}
        className={classes.container}
      >
        <Grid item xs={11}>
          <Typography variant={"h5"} textAlign={"center"}>
            Confirm to Re-Run this Payment
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={handleCloseModal}>
            <Close />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} my={"1rem"}>
          <Typography variant={"body1"}>Are you sure you want to attempt to submit this payment again?</Typography>
        </Grid>

        <Grid container xs={12} mt={2}
          justifyContent={"center"} gap={2}>
          <Button onClick={handleCloseModal} color={"error"} variant={"text"}>
            Cancel
          </Button>
          <LoadingButton
            variant={"contained"}
            disabled={loading}
            onClick={handleSubmit}
            className={classes.button}
            loading={loading}
          >
            Confirm
          </LoadingButton>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ReRunAutoBillModal;
