import { DocumentTemplate } from "src/models/DocumentTemplate";

const documentTemplateNullOption: DocumentTemplate = {
  id: 0,
  name: "None",
  type: 0,
  category: 0,
  content: "",
  version: "",
  versionChanges: "",
  created_at: "",
  updated_at: "",
  is_default: 0,
  facilities: [],
  is_disabled: 0,
  is_latest_version: 1,
  rollback_allowed: 0,
  version_control_id: 0,
  product_types: [],
  system_automations: [],
  tpp_plans: []
};

export default documentTemplateNullOption;
