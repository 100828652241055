import { Dispatch, SetStateAction } from "react";
import { AppDispatch } from "src/store/rootStore";
import Froala from "froala-editor";
import _ from "lodash";
import froalaConfig from "../froalaConfig";
import {
  getDynamicInclusionKeywords
} from "src/store/thunks/documentTemplates/getDynamicInclusionKeywords/getDynamicInclusionKeywords";

const MANY_TO_MANY_RELATIONSHIPS = [
  "ledger_items",
  "merchandise",
  "unit_attributes",
  "fee"
];

const insertLoop = (cmd: string, val: string) => {
  return `
     @foreach ($${cmd} as $item)
      <li>{{ $item->${val} }}</li>
    @endforeach
  `;
};

const bootstrapFroalaButtons = (dispatch: AppDispatch, setShowEditor: Dispatch<SetStateAction<boolean>>) => {
  dispatch(getDynamicInclusionKeywords())
    .then(resp => {
      const dynamicKeywords = resp.payload;
      for (const keyword in dynamicKeywords) {
        const wordWithSpaces = keyword.replace(/_/g, " ");
        const finalKeyword = wordWithSpaces.replace(/(^\w{1})|(\s+\w{1})/g, (letter: string) => letter.toUpperCase());

        if (!froalaConfig.toolbarButtons.moreText.buttons.includes(keyword)) {
          froalaConfig.toolbarButtons.moreText.buttons.push(keyword);
        }

        const keywordObjInverted = _.invert(dynamicKeywords[keyword]);

        Froala.DefineIcon(keyword, { NAME: finalKeyword, template: "text" });

        Froala.RegisterCommand(keyword, {
          title: finalKeyword,
          focus: true,
          type: "dropdown",
          undo: true,
          options: keywordObjInverted,
          refreshAfterCallback: true,
          callback: function(cmd: any, val: any) {
            if (MANY_TO_MANY_RELATIONSHIPS.includes(cmd)) {
              this.html.insert(insertLoop(cmd, val));
              return;
            }

            this.html.insert(`{{ $${cmd}->${val} ?? '' }}`);
            this.undo.saveStep();
          }
        });
      };

      setShowEditor(true);
    });
};

export default bootstrapFroalaButtons;
