import { makeStyles } from "../../makeStyles";

const useStyles = makeStyles({ label: "Delinquency Automation" })((theme) => ({
  cellTitle: {
    color: theme.palette.primary.contrastText,
    fontStyle: "italic"
  },
  description: {
    margin: `0 ${theme.spacing(2)}`
  }
}));

export default useStyles;
