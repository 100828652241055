import { AccountType } from "./accountType";

const accountTypes: AccountType[] = [
  {
    value: "1",
    label: "Checking"
  },
  {
    value: "2",
    label: "Savings"
  }
];

export default accountTypes;
