import { PaymentSummarySliceState } from "./paymentSummarySliceState";
import { RootState } from "src/store/rootStore";
import { createSlice } from "@reduxjs/toolkit";
import {
  getPartialPaymentCostNew
} from "src/store/thunks/makeAPayment/getPartialPaymentCostNew/getPartialPaymentCostNew";
import { makeAPayment } from "src/store/thunks/makeAPayment/makeAPayment/makeAPayment";

export const initialState: PaymentSummarySliceState = {
  loading: false,
  paymentLoading: false,
  error: null,
  currentBalanceSubtotal: null,
  currentBalanceTaxTotal: null,
  currentBalanceTotal: null,
  projectedPrepaidBalanceSubtotal: null,
  projectedPrepaidBalanceTaxTotal: null,
  projectedPrepaidBalanceTotal: null,
  grandSubtotal: null,
  grandTaxTotal: null,
  grandTotal: null,
  requestedCustomPayment: null,
  requestedPrepaidPeriods: null,
  customPaymentCoversCurrentBalance: null,
  remainingCurrentBalanceTotal: null,
  customPaymentCoversCurrentAndRequestedProjectedPrepaidBalance: null,
  remainingCurrentAndRequestedProjectedPrepaidBalanceTotal: null,
  requiredPrepaidPeriods: null,
  requiredProjectedPrepaidBalanceSubtotal: null,
  requiredProjectedPrepaidBalanceTaxTotal: null,
  requiredProjectedPrepaidBalanceTotal: null,
  requiredGrandSubtotal: null,
  requiredGrandTaxTotal: null,
  requiredGrandTotal: null,
  customPaymentCoversCurrentAndRequiredProjectedPrepaidBalance: null,
  remainingCurrentAndRequiredProjectedPrepaidBalanceTotal: null,
  currentCharges: [],
  requestedProjectedFutureCharges: [],
  requiredProjectedFutureCharges: [],
  projectedPrepaidBucketApplied: null,
  prepaymentBucketTotal: 0,
  amountCarriedForward: null
};

export const paymentSummarySlice = createSlice({
  name: "paymentSummary",
  initialState,
  reducers: {
    resetPaymentSummarySlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPartialPaymentCostNew.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPartialPaymentCostNew.fulfilled, (state, action) => {
        state.loading = false;
        state.currentBalanceSubtotal = action.payload.current_balance_subtotal;
        state.currentBalanceTaxTotal = action.payload.current_balance_tax_total;
        state.currentBalanceTotal = action.payload.current_balance_total;
        state.projectedPrepaidBalanceSubtotal = action.payload.projected_prepaid_balance_subtotal;
        state.projectedPrepaidBalanceTaxTotal = action.payload.projected_prepaid_balance_tax_total;
        state.projectedPrepaidBalanceTotal = action.payload.projected_prepaid_balance_total;
        state.grandSubtotal = action.payload.grand_subtotal;
        state.grandTaxTotal = action.payload.grand_tax_total;
        state.grandTotal = action.payload.grand_total;
        state.requestedCustomPayment = action.payload.requested_custom_payment;
        state.requestedPrepaidPeriods = action.payload.requested_prepaid_periods;
        state.customPaymentCoversCurrentBalance = action.payload.custom_payment_covers_current_balance;
        state.remainingCurrentBalanceTotal = action.payload.remaining_current_balance_total;
        state.customPaymentCoversCurrentAndRequestedProjectedPrepaidBalance =
            action.payload.custom_payment_covers_current_and_requested_projected_prepaid_balance;
        state.remainingCurrentAndRequestedProjectedPrepaidBalanceTotal =
            action.payload.remaining_current_and_requested_projected_prepaid_balance_total;
        state.requiredPrepaidPeriods = action.payload.required_prepaid_periods;
        state.requiredProjectedPrepaidBalanceSubtotal = action.payload.required_projected_prepaid_balance_subtotal;
        state.requiredProjectedPrepaidBalanceTaxTotal = action.payload.required_projected_prepaid_balance_tax_total;
        state.requiredProjectedPrepaidBalanceTotal = action.payload.required_projected_prepaid_balance_total;
        state.requiredGrandSubtotal = action.payload.required_grand_subtotal;
        state.requiredGrandTaxTotal = action.payload.required_grand_tax_total;
        state.requiredGrandTotal = action.payload.required_grand_total;
        state.customPaymentCoversCurrentAndRequiredProjectedPrepaidBalance =
            action.payload.custom_payment_covers_current_and_required_projected_prepaid_balance;
        state.remainingCurrentAndRequiredProjectedPrepaidBalanceTotal =
            action.payload.remaining_current_and_required_projected_prepaid_balance_total;
        state.currentCharges = action.payload.current_charges;
        state.requestedProjectedFutureCharges = action.payload.requested_projected_future_charges;
        state.requiredProjectedFutureCharges = action.payload.required_projected_future_charges;
        state.projectedPrepaidBucketApplied = action.payload.projected_prepayment_bucket_applied;
        state.projectedPrepaidBucketApplied = action.payload.projected_prepayment_bucket_applied;
        state.prepaymentBucketTotal = action.payload.prepayment_bucket_total;
        state.amountCarriedForward = action.payload.amount_carried_forward_formatted;
      })
      .addCase(getPartialPaymentCostNew.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(makeAPayment.pending, (state, action) => {
        state.loading = true;
      }).addCase(makeAPayment.fulfilled, (state, action) => {
        state.loading = false;
      }).addCase(makeAPayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  }
});

export const selectLoading = (state: RootState) => state.paymentSummary.loading;
export const selectPaymentLoading = (state: RootState) => state.paymentSummary.loading;
export const selectError = (state: RootState) => state.paymentSummary.error;
export const selectCurrentBalanceSubtotal = (state: RootState) => state.paymentSummary.currentBalanceSubtotal;
export const selectCurrentBalanceTaxTotal = (state: RootState) => state.paymentSummary.currentBalanceTaxTotal;
export const selectCurrentBalanceTotal = (state: RootState) => state.paymentSummary.currentBalanceTotal;
export const selectProjectedPrepaidBalanceSubtotal = (state: RootState) =>
  state.paymentSummary.projectedPrepaidBalanceSubtotal;
export const selectProjectedPrepaidBalanceTaxTotal = (state: RootState) =>
  state.paymentSummary.projectedPrepaidBalanceTaxTotal;
export const selectProjectedPrepaidBalanceTotal = (state: RootState) =>
  state.paymentSummary.projectedPrepaidBalanceTotal;
export const selectGrandSubtotal = (state: RootState) => state.paymentSummary.grandSubtotal;
export const selectGrandTaxTotal = (state: RootState) => state.paymentSummary.grandTaxTotal;
export const selectGrandTotal = (state: RootState) => state.paymentSummary.grandTotal;
export const selectRequestedCustomPayment = (state: RootState) => state.paymentSummary.requestedCustomPayment;
export const selectRequestedPrepaidPeriods = (state: RootState) => state.paymentSummary.requestedPrepaidPeriods;
export const selectCustomPaymentCoversCurrentBalance = (state: RootState) =>
  state.paymentSummary.customPaymentCoversCurrentBalance;
export const selectRemainingCurrentBalanceTotal = (state: RootState) =>
  state.paymentSummary.remainingCurrentBalanceTotal;
export const selectCustomPaymentCoversCurrentAndRequestedProjectedPrepaidBalance = (state: RootState) =>
  state.paymentSummary.customPaymentCoversCurrentAndRequestedProjectedPrepaidBalance;
export const selectRemainingCurrentAndRequestedProjectedPrepaidBalanceTotal = (state: RootState) =>
  state.paymentSummary.remainingCurrentAndRequestedProjectedPrepaidBalanceTotal;
export const selectRequiredPrepaidPeriods = (state: RootState) => state.paymentSummary.requiredPrepaidPeriods;
export const selectRequiredProjectedPrepaidBalanceSubtotal = (state: RootState) =>
  state.paymentSummary.requiredProjectedPrepaidBalanceSubtotal;
export const selectRequiredProjectedPrepaidBalanceTaxTotal = (state: RootState) =>
  state.paymentSummary.requiredProjectedPrepaidBalanceTaxTotal;
export const selectRequiredProjectedPrepaidBalanceTotal = (state: RootState) =>
  state.paymentSummary.requiredProjectedPrepaidBalanceTotal;
export const selectRequiredGrandSubtotal = (state: RootState) => state.paymentSummary.requiredGrandSubtotal;
export const selectRequiredGrandTaxTotal = (state: RootState) => state.paymentSummary.requiredGrandTaxTotal;
export const selectRequiredGrandTotal = (state: RootState) => state.paymentSummary.requiredGrandTotal;
export const selectCustomPaymentCoversCurrentAndRequiredProjectedPrepaidBalance = (state: RootState) =>
  state.paymentSummary.customPaymentCoversCurrentAndRequiredProjectedPrepaidBalance;
export const selectRemainingCurrentAndRequiredProjectedPrepaidBalanceTotal = (state: RootState) =>
  state.paymentSummary.remainingCurrentAndRequiredProjectedPrepaidBalanceTotal;
export const selectRequestedProjectedFutureCharges = (state: RootState) =>
  state.paymentSummary.requestedProjectedFutureCharges;
export const selectRequiredProjectedFutureCharges = (state: RootState) =>
  state.paymentSummary.requiredProjectedFutureCharges;
export const selectProjectedPrepaidBucketApplied = (state: RootState) =>
  state.paymentSummary.projectedPrepaidBucketApplied;
export const selectPrepaymentBucketTotal = (state: RootState) =>
  state.paymentSummary.prepaymentBucketTotal;

export const selectPaymentSummarySlice = (state: RootState) => state.paymentSummary;

export const { resetPaymentSummarySlice } = paymentSummarySlice.actions;

export default paymentSummarySlice.reducer;
