import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Back Button Styles" })((theme) => ({
  textColor: {
    color: theme.palette.primary.contrastText
  }

}
));

export default useStyles;
