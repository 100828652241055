import { AxiosError } from "axios";
import { Charge } from "src/store/reducers/creditsSlice/creditsSliceState";
import { axiosInstance as axios } from "../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "src/utils/format422/format422";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";

export interface IssueCreditPayload {
  current_charges?: { id: number; reason: string; credit_amount: number }[];
  future_charges?: {
    description: string;
    charge_date: string;
    reason: string;
    credit_amount: number,
    parent_charge_id: number | null
  }[];
}

export const issueCredit = createAsyncThunk(
  "issueCredit",
  (
    { occupantId, ledgerId, data }: { occupantId: string; ledgerId: string; data: IssueCreditPayload },
    { dispatch, getState, rejectWithValue }
  ) => {
    return axios
      .post(`/api/v1/occupants/${occupantId}/ledgers/${ledgerId}/issue-credit`, data)
      .then((resp) => {
        return resp.data;
      })
      .then((data) => {
        dispatch(
          showSnackbar({
            message: "Credit issued successfully",
            variant: "success"
          })
        );
        return data;
      })
      .catch((err: AxiosError) => {
        let errorMessage = `There was an error issuing credits`;

        if (err.response?.status === 422) {
          errorMessage = `Could not issue credits: ${err.response?.data.detail}`;

          dispatch(
            showSnackbar({
              message: errorMessage,
              variant: "error"
            })
          );

          return rejectWithValue(errorMessage);
        }

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  }
);
