import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles<{ active: boolean }>({ label: "ItemCard" })((theme, { active }) => ({
  cardContent: {
    background: theme.palette.background.default,
    borderRadius: "1rem"
  },
  description: {
    margin: `0 ${theme.spacing(2)}`
  },
  cellTitle: {
    color: theme.palette.primary.contrastText,
    fontStyle: "italic"
  },
  itemStatus: {
    color: theme.palette.success.main,
    textTransform: "capitalize"
  },
  statusText: {
    color: active ? theme.palette.success.main : theme.palette.error.main
  },
  facilityChip: {
    background: theme.palette.primary.contrastText,
    color: theme.palette.common.white,
    margin: `0 ${theme.spacing(1)}`
  }
}));

export default useStyles;
