import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Merchandise Name is required"),
  sku: Yup.string().required("Sku is required"),
  quantity: Yup.string().required("Quantity is required"),
  shipping_cost: Yup.string().required("Shipping Cost is required"),
  cost_of_goods_sold: Yup.string().required("Cost of Goods Sold is required")
});

export default validationSchema;
