import * as Yup from "yup";
import { moneyValidator } from "../../../../../utils/commonRegexes";
import moment from "moment";

const validationSchema = Yup.object().shape({
  leaseStartsOn: Yup.date().nullable()
    .required("Lease Starts On is required")
    .test({
      name: "isCurrentOrFutureDate",
      message: "Lease Starts On must be a month or later",
      test: (value) => {
        const date = moment(value);
        return date.isSameOrAfter(date.subtract(1, "month"), "day");
      }
    }),
  monthlyRate: Yup.string()
    .matches(moneyValidator, "Incorrect format")
    .required("Monthly Rate is required"),
  periods: Yup.number()
    .min(0, "Minimum prepay periods must be greater than 0")
    .max(12, "Maximum prepay periods must be less than 12")
    .required(),
  waiveFeeAmount: Yup.string().when("waiveFee", {
    is: true,
    then: Yup.string().test({
      name: "waiveFeeAmount",
      message: "Please enter a valid amount",
      test: function(value) {
        if (!value) {
          return this.createError({ message: "Waive Fee Amount needs to be greater than 0" });
        }
        if (!(moneyValidator.test(value))) {
          return this.createError({ message: "Please enter a valid amount" });
        }
        if (parseFloat(value) > (parseFloat(this.parent?.rent) + parseFloat(this.parent?.tax))) {
          return this.createError({ message: "Waive Fee Amount needs to be less than the balance owed." });
        }
        return true;
      }
    })
  })
});

export default validationSchema;
