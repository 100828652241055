import { makeStyles } from "../../../../../makeStyles";

const useStyles = makeStyles({ label: "BackButton" })((theme) => ({
  root: {
    display: "flex",
    flexWrap: "nowrap",
    minWidth: "6rem",
    justifyContent: "center"
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));

export default useStyles;
