// No '/' in routes to prevent index route from excluding authorization
const routes = [
  "eSign-sign"
];

export const checkAppLiteRoute = (path: string) => {
  let found = false;

  routes.forEach((route: string) => {
    if (path.indexOf(route) > -1) {
      found = true;
    }
  });

  return found;
};
