import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { Tooltip, Typography } from "@mui/material";
import ESignActionButtons from "./components/ESignActionButtons";
import React from "react";
import StatusChip from "./components/StatusChip";
import moment from "moment";

export const ESignStatusTableColumns: GridColDef[] = [
  {
    field: "document_name",
    renderHeader: () => (
      <Tooltip title={"Document Name"}>
        <Typography>Document Name</Typography>
      </Tooltip>
    ),
    align: "center",
    minWidth: 150,
    headerAlign: "center",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row?.document_template?.name
  },
  {
    field: "unit",
    renderHeader: () => (
      <Tooltip title={"Unit Number"}>
        <Typography>Unit #</Typography>
      </Tooltip>
    ),
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row?.unit?.unit_number
  },
  {
    field: "signer_name",
    renderHeader: () => (
      <Tooltip title={"Occupant"}>
        <Typography>Occupant</Typography>
      </Tooltip>
    ),
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams) => (
      <Tooltip title={row.signer_name}>
        <Typography>{row.signer_name}</Typography>
      </Tooltip>
    )
  },
  {
    field: "created_at",
    renderHeader: () => (
      <Tooltip title={"Sent On"}>
        <Typography>Sent On</Typography>
      </Tooltip>
    ),
    flex: 1,
    minWidth: 150,
    renderCell: ({ row }: GridRenderCellParams) => (
      <Tooltip title={moment(row?.created_at).format("MM/DD/YYYY h:mm")}>
        <Typography>{moment(row?.created_at).format("MM/DD/YYYY h:mm")}</Typography>
      </Tooltip>
    )
  },
  {
    field: "updated_at",
    renderHeader: () => (
      <Tooltip title={"Last Update"}>
        <Typography>Last Update</Typography>
      </Tooltip>
    ),
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams) => (
      <Tooltip title={moment(row?.updated_at).format("MM/DD/YYYY h:mm")}>
        <Typography>{moment(row?.updated_at).format("MM/DD/YYYY h:mm")}</Typography>
      </Tooltip>
    )
  },
  {
    field: "created_by",
    renderHeader: () => (
      <Tooltip title={"Created By"}>
        <Typography>Created By</Typography>
      </Tooltip>
    ),
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams) => (
      <Tooltip title={row.created_by}>
        <Typography>{row.created_by}</Typography>
      </Tooltip>
    )
  },
  {
    field: "status",
    renderHeader: () => (
      <Tooltip title={"Status"}>
        <Typography>Status</Typography>
      </Tooltip>
    ),
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams) => {
      return <StatusChip status={row.is_signed} />;
    }
  },
  {
    field: "actions",
    renderHeader: () => (
      <Tooltip title={"Actions"}>
        <Typography>Actions</Typography>
      </Tooltip>
    ),
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams) => {
      return <ESignActionButtons eSignId={row.id} eSignDetails={row}/>;
    }
  }
];
