import moment from "moment";
import * as Yup from "yup";
import { dateWarning } from "../../../../utils/commonWarnings";
import { today } from "../../../../utils/__dateAndTimeUtils__/today";

const validationSchema = Yup.object().shape({
  rentalRate: Yup.string().required("Rental Rate is required"),
  effectiveDate: Yup.string().required("Effective Date is required").length(10, dateWarning).test(
    "validDate",
    "Effective Date needs to tomorrow or later",
    (value) => moment(value).isAfter(moment(today))
  )
});

export default validationSchema;
