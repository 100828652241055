import { PatternFormat, PatternFormatProps } from "react-number-format";
import React, { ChangeEventHandler, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";

interface PostalCodeFormatCustomProps {
  inputRef: (instance: PatternFormatProps | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

// eslint-disable-next-line react/display-name
export const PostalCodeFormatCustom = React.forwardRef((props: PostalCodeFormatCustomProps, ref: any) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      format={"#####-####"}
    />
  );
});

interface MaskedPostalCodeProps {
  id: string;
  dataTestId?: string;
  error?: boolean;
  fullWidth?: boolean;
  helperText?: string | false;
  name: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  type?: string;
  variant?: "standard" | "filled" | "outlined";
  value: number | string;
  disabled?: boolean;
}

export default function MaskedPostalCode(props: MaskedPostalCodeProps) {
  const { id, value, name, onChange, type = "text", dataTestId, ...options } = props;
  const [formattedVal, setFormattedVal] = useState("");

  useEffect(() => {
    setFormattedVal(String(value));
  }, []);

  return (
    <TextField
      id={id}
      data-testid={dataTestId}
      fullWidth={options.fullWidth}
      variant={options.variant}
      placeholder={options.placeholder}
      disabled={options.disabled}
      onChange={onChange}
      value={formattedVal}
      type={type}
      name={name}
      inputProps={{ "data-testid": dataTestId }}
      InputProps={{
        inputComponent: PostalCodeFormatCustom as any
      }}
      error={options.error}
      helperText={options.helperText}
    />
  );
}
