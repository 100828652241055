import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "InsurancePlanForm" })((theme) => ({
  activeSwitch: {
    margin: 0
  },
  noArrowInput: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    },
    "input[type=number]": {
      MozAppearance: "textfield"
    }
  }
}));

export default useStyles;
