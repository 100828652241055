import React, { PropsWithChildren, useEffect, useState } from "react";
import { resetOpenExpansionRows, resetTablesSlice } from "src/store/reducers/tablesSlice/tablesSlice";
import { resetTransferUnitSlice, setIsTransfer } from "src/store/reducers/transferUnitSlice/transferUnitSlice";
import {
  selectNewFacilityAdvisoryModal,
  selectSelectedFacility
} from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { AppDispatch } from "src/store/rootStore";
import AppModal from "src/components/ui/AppModal/AppModal";
import { Breadcrumb } from "src/models/Breadcrumb";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import { TRANSFER_UNIT_PATHS } from "src/routes/TransferUnitRoutes.";
import { Typography } from "@mui/material";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { blockNavigation } from "src/components/router/CustomRouter";
import { getAllFacilities } from "src/store/thunks/facility/getAll/getAllFacilities";
import { getAllFeesForFacility } from "src/store/thunks/fee/getAllForFacility/getAllFeesForFacility";
import {
  getAllInsurancePlansForFacility
} from "src/store/thunks/insurancePlans/getAllForFacility/getAllInsurancePlansForFacility";
import { getAllMerchandiseForMoveIn } from "src/store/thunks/merchandise/getAllForMoveIn/getAllMerchandiseForMoveIn";
import { getAllProductTypes } from "src/store/thunks/productType/getAll/getAllProductTypes";
import { getAllPromotionsForFacility } from "src/store/thunks/promotion/getAllForFacility/getAllPromotionsForFacility";
import { resetAchSlice } from "src/store/reducers/achSlice/achSlice";
import { resetCashSlice } from "src/store/reducers/cashSlice/cashSlice";
import { resetCreditCardSlice } from "src/store/reducers/creditCardSlice/creditCardSlice";
import { resetDealInformationSlice } from "src/store/reducers/dealInformationSlice/dealInformationSlice";
import { resetDealSlice } from "src/store/reducers/dealSlice/dealSlice";
import { resetLedgersSlice } from "src/store/reducers/ledgersSlice/ledgersSlice";
import { resetMoneyOrderSlice } from "src/store/reducers/moneyOrderSlice/moneyOrderSlice";
import { resetMoveInSlice } from "src/store/reducers/moveInSlice/moveInSlice";
import { resetMoveOutSlice } from "src/store/reducers/moveOutSlice/moveOutSlice";
import { resetPaymentSlice } from "src/store/reducers/paymentSlice/paymentSlice";
import { resetProductTypeSlice } from "src/store/reducers/productTypeSlice/productTypeSlice";
import { resetSelectedOccupantSlice } from "src/store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { resetSelectedPromotionSlice } from "src/store/reducers/selectedPromotionSlice/selectedPromotionSlice";
import { resetSelectedUnitSlice } from "src/store/reducers/selectedUnitSlice/selectedUnitSlice";
import { resetTransferUnitCostSlice } from "src/store/reducers/transferUnitCostSlice/transferUnitCostSlice";
import { resetUnitsSlice } from "src/store/reducers/unitsSlice/unitsSlice";
import { resetExternalTppPlanState } from "src/store/reducers/externalTppPlanSlice/externalTppPlanSlice";
import { useLocation, useNavigate } from "react-router";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Occupants"
  },
  {
    name: "Transfer Unit",
    bold: true
  }
];

export const handleReset = (dispatch: AppDispatch) => {
  dispatch(resetSelectedOccupantSlice());
  dispatch(resetAchSlice());
  dispatch(resetCashSlice());
  dispatch(resetCreditCardSlice());
  dispatch(resetDealInformationSlice());
  dispatch(resetDealSlice());
  dispatch(resetMoneyOrderSlice());
  dispatch(resetMoveInSlice());
  dispatch(resetPaymentSlice());
  dispatch(resetProductTypeSlice());
  dispatch(resetSelectedUnitSlice());
  dispatch(resetSelectedPromotionSlice());
  dispatch(resetSelectedOccupantSlice());
  dispatch(resetTablesSlice());
  dispatch(resetUnitsSlice());
  dispatch(resetOpenExpansionRows());
  dispatch(resetTransferUnitSlice());
  dispatch(resetTransferUnitCostSlice());
  dispatch(resetMoveOutSlice());
  dispatch(resetLedgersSlice());
  dispatch(resetExternalTppPlanState());
};

const TransferUnit = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [workflowIncompleteWarning, setWorkflowIncompleteWarning] = useState(false);
  const pageLocation = useLocation();
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const [localTargetPath, setLocalTargetPath] = React.useState<string>("");
  const newFacilityAdvisoryModal = useAppSelector(selectNewFacilityAdvisoryModal);
  let unblock: Function;

  const handleAdvisory = () => {
    return blockNavigation(({ location }) => {
      const nextLocation = location.pathname;
      const currentLocation = pageLocation.pathname;
      setLocalTargetPath(nextLocation);

      const workflowComplete = currentLocation === TRANSFER_UNIT_PATHS.confirmTransferUnit;

      const transferUnitsRoutes = Object.keys(TRANSFER_UNIT_PATHS)
        .filter((route) => route !== "getTransferUnitStarted")
        .map((route) => TRANSFER_UNIT_PATHS[route as keyof typeof TRANSFER_UNIT_PATHS])
        .join(", ");

      if (
        // you're not on the first step
        currentLocation !== TRANSFER_UNIT_PATHS.getTransferUnitStarted &&
        // and you aren't navigating b/w steps or completely restarting the workflow
        !transferUnitsRoutes.includes(nextLocation) &&
        // and you're not changing facilities (which should trigger a different modal)
        !newFacilityAdvisoryModal &&
        // and you have not completed the move-in
        !workflowComplete
        // then show the advisory modal
      ) {
        setWorkflowIncompleteWarning(true);
        return false;
      }

      unblock();
      navigate(nextLocation);
    });
  };

  const confirmNavigationAway = () => {
    if (unblock) {
      unblock();
    }
    handleReset(dispatch);
    setWorkflowIncompleteWarning(false);
    navigate(localTargetPath);
  };

  useEffect(() => {
    unblock = handleAdvisory();

    /* istanbul ignore next */ // cannot test this w/ enzyme
    return () => {
      if (unblock) {
        unblock();
      }
    };
  });

  useEffect(() => {
    // // if we get here redirect the user because this should be disabled
    // navigate("/");
    dispatch(getAllFacilities());
    dispatch(getAllFeesForFacility({ facilityId: selectedFacility?.id }));
    dispatch(getAllInsurancePlansForFacility(selectedFacility?.id));
    dispatch(getAllMerchandiseForMoveIn());
    dispatch(getAllProductTypes(selectedFacility?.id));
    dispatch(getAllPromotionsForFacility());
    dispatch(setIsTransfer(true));
    return () => handleReset(dispatch);
  }, []);

  const pageHeader = <PageHeader title={"Transfer Unit"} breadcrumbs={breadcrumbs} />;
  return (
    <ViewWrapper pageHeader={pageHeader}>
      <AppModal
        open={workflowIncompleteWarning}
        cancelAction={() => setWorkflowIncompleteWarning(false)}
        confirmAction={confirmNavigationAway}
      >
        <Typography gutterBottom variant={"h6"}>
          Warning
        </Typography>
        <Typography variant={"body1"}>
          You are about to leave an incomplete workflow. If you choose to continue, information you have provided during
          this and previous steps will be lost.
        </Typography>
      </AppModal>
      {children}
    </ViewWrapper>
  );
};

export default TransferUnit;
