import { Grid } from "@mui/material";
import React, { ReactElement } from "react";
import { selectProductCategories } from "src/store/reducers/productCategoriesSlice/productCategoriesSlice";
import { selectRevenueClasses } from "src/store/reducers/revenueClassesSlice/revenueClassesSlice";
import { useAppSelector } from "../../../../../store/hooks";
import PMSSelect from "../../../../../components/ui/PMSSelect/PMSSelect";
import { RevenueClass } from "../../../../../models/RevenueClass";
import useStyles from "./EditableAttributes.styles";
import { ProductCategory } from "src/models/ProductCategory.d";
import { selectDocumentList } from "src/store/reducers/documentSlice/documentSlice";

interface EditableAttributesProps {
  onChange: Function;
  productCategoryId: number | null;
  revenueClassId: number;
  leaseDocumentId: number | null;
  isInsurable: boolean
}

const EditableAttributes: React.FC<EditableAttributesProps> = ({
  onChange,
  productCategoryId,
  revenueClassId,
  leaseDocumentId,
  isInsurable
}): ReactElement => {
  const { classes } = useStyles();
  const productCategories: ProductCategory[] = useAppSelector(selectProductCategories);
  const revenueClasses: RevenueClass[] = useAppSelector(selectRevenueClasses);
  const documentTemplates = useAppSelector(selectDocumentList);

  const getOption = (id: number | string, text: string): ReactElement =>
    <option value={id} key={id}>{text}</option>;

  const getDocumentOptions = (categoryFilter: number): ReactElement[] =>
    documentTemplates
      .filter(({ category }) => category === categoryFilter)
      .map(({ id, name }) => getOption(id, name));

  return (
    <>
      <Grid>
        <PMSSelect
          className={classes.propertyType}
          label={"Product Category"}
          id={"edit-product-type-product-category"}
          changeHandler={e => onChange({ productCategoryId: e.target.value })}
          value={productCategoryId}
        >
          { productCategories?.map((productCategory) => productCategory
            ? getOption(productCategory.id, productCategory.name)
            : null) }
        </PMSSelect>
      </Grid>
      <Grid>
        <PMSSelect
          className={classes.propertyType}
          label={"Revenue Class"}
          id={"edit-product-type-revenue-class"}
          changeHandler={e => onChange({ revenueClassId: e.target.value })}
          value={revenueClassId}
        >
          { revenueClasses?.map(({ name, id, is_active }) => is_active ? getOption(id, name) : null) }
        </PMSSelect>
      </Grid>
      <Grid>
        <PMSSelect
          className={classes.propertyType}
          label={"Insurance"}
          id={"edit-product-type-insurance"}
          changeHandler={e => {
            const isInsurable = e.target.value === "1";
            const insuranceDocument = isInsurable ? {} : { insuranceDocumentId: null };
            onChange({
              isInsurable,
              ...insuranceDocument
            });
          }}
          value={+isInsurable}
        >
          <option value={1}>Yes</option>
          <option value={0}>No</option>
        </PMSSelect>
      </Grid>
      <Grid>
        <PMSSelect
          className={classes.propertyType}
          label={"Lease Document Attached"}
          id={"edit-product-type-lease-document"}
          changeHandler={e => onChange({ leaseDocumentId: parseInt(e.target.value) })}
          value={leaseDocumentId}
        >
          {/* todo: get enum from Daniels branch */}
          <option value={-1}>-- Lease Document Attached</option>
          { getDocumentOptions(6) }
        </PMSSelect>
      </Grid>
    </>
  );
};

export default EditableAttributes;
