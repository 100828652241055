import React, { ReactElement } from "react";
import {
  selectMerchandiseForRefund,
  selectMerchandiseForRefundLoading
} from "src/store/reducers/merchandiseForRefundSlice/merchandiseForRefundSlice";
import EmptyTable from "../../../../components/ui/PMSTable/EmptyTable/EmptyTable";
import PMSTable from "../../../../components/ui/PMSTable/PMSTable";
import RefundMerchandiseTableColumns from "./RefundMerchandiseTableColumns/RefundMerchandiseTableColumns";
import { useAppSelector } from "../../../../store/hooks";

const RefundMerchandiseTable: React.FC = (): ReactElement => {
  const refundMerch = useAppSelector(selectMerchandiseForRefund);
  const refundMerchLoading = useAppSelector(selectMerchandiseForRefundLoading);

  const emptyTable = () => (
    <EmptyTable
      collectionNotFound={"merchandise"}
      value={"merchandise"}
    />
  );

  return (
    <PMSTable
      data-testid={"refund-merchandise-table"}
      columns={RefundMerchandiseTableColumns()}
      rows={refundMerch}
      emptyTableDisplay={emptyTable}
      loading={refundMerchLoading}
      sortingMode={"client"}
      hidePagination
      hideFooter
      rowAutoHeight={true}
    />
  );
};

export default RefundMerchandiseTable;
