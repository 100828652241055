import React, { FC } from "react";
import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "src/store/hooks";
import useStyles from "../../ManageWebhooks.styles";

interface ManageWebhooksCTAButtonsProps { }

const ManageWebhooksCTAButtons: FC<ManageWebhooksCTAButtonsProps> = () => {
  const { classes } = useStyles();

  return (
    <Button
      startIcon={<Add />}
      className={classes.button}
      color={"primary"}
      variant={"contained"}
      component={NavLink}
      to={"/webhooks/create"}
    >
      New Webhook
    </Button>
  );
};

export default ManageWebhooksCTAButtons;
