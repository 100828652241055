import React, { ReactElement } from "react";
import OccupantInfoIcons from "../../../../components/stepper/OccupantInfoIcons/OccupantInfoIcons";
import OccupantNameAndLocation from "../../../../components/stepper/OccupantNameAndLocation/OccupantNameAndLocation";
import { OccupantNameAndLocationValues }
  from "../../../../models/formikInputValues/occupant/OccupantNameAndLocationValues";
import PMSAdvisory from "../../../../components/ui/PMSAdvisory/PMSAdvisory";
import { Paper } from "@mui/material";
import StepLayout from "../../../../layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "../../../../layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import { getGateCode } from "src/store/thunks/occupant/getGateCode/getGateCode";
import { setOccupantNameAndLocation }
  from "../../../../store/reducers/occupantInformationSlice/occupantInformationSlice";
import { useAppDispatch } from "../../../../store/hooks";
import { useNavigate } from "react-router";

const AddOccupantNameAndLocation: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleGoBack = () => navigate("/occupants/add-occupant");

  const handleSubmit = async(values: OccupantNameAndLocationValues) => {
    dispatch(setOccupantNameAndLocation(values));

    await dispatch(getGateCode());
    navigate("/occupants/add-occupant/occupant-contact");
  };

  const stepperId = "occupant-name-and-location";

  return (
    <>
      <PMSAdvisory
        title={"Occupant Information"}
        advisoryText={"First, please fill out the occupant's basic information below."}
        icons={<OccupantInfoIcons />}
      />
      <Paper elevation={1}>
        <StepLayout stepperId={stepperId} buttons={
          <StepperButtons
            data-testid={"occupant-name-and-location-buttons"}
            stepperId={stepperId}
            backAction={handleGoBack}
          />
        }>
          <OccupantNameAndLocation
            data-testid={"occupant-name-and-location-form"}
            stepperId={stepperId}
            onSubmitHandler={handleSubmit}
          />
        </StepLayout>
      </Paper>
    </>
  );
};

export default AddOccupantNameAndLocation;
