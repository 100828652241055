import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Moment from "moment-timezone";
import { MoveInMerchandise } from "./../../../models/MoveInMerchandise.d";
import { Reservation } from "src/models/Reservation";
import { RootState } from "src/store/rootStore";
import { TransferUnitSliceState } from "./TransferUnitSliceState";
import { WaiveFee } from "src/models/WaiveFee";
import moment from "moment";
import { createTransferUnit } from "src/store/thunks/transfer/create/createTransferUnit";

// @ts-ignore
// eslint-disable-next-line new-cap
const today = new Moment.utc().format("MM/DD/yyyy");

export const initialState: TransferUnitSliceState = {
  leaseStartsOn: today,
  monthlyRate: "",
  periods: 0,
  transferUnitLoading: false,
  occupantReservation: null,
  transferUnitError: "",
  isDealConversion: false,
  promotionId: null,
  fees: [],
  merchandise: [],
  insurancePlanId: null,
  promoDefermentStatus: false,
  transferUnitConfirmation: null,
  waiveFee: { waive: false, waiveFeeAmount: "0.00" },
  storingReason: "",
  isTransfer: false,
  leasePackageModalOpened: false,
  sendEmailModalOpened: false,
  selectedDocuments: [],
  defaultDocuments: [],
  transferUnitModalOpen: false
};

export const transferUnitSlice = createSlice({
  name: "transferUnit",
  initialState,
  reducers: {
    setTransferOccupantReservation: (state: TransferUnitSliceState, action: PayloadAction<Reservation | null>) => {
      state.occupantReservation = action.payload;
    },
    setTransferLeaseStartsOn: (state, action: PayloadAction<string>) => {
      state.leaseStartsOn = moment(action.payload).isValid() ? action.payload : today;
    },
    setTransferMonthlyRate: (state, action: PayloadAction<string>) => {
      state.monthlyRate = action.payload;
    },
    setTransferPeriods: (state, action: PayloadAction<number | number[]>) => {
      state.periods = action.payload;
    },
    addTransferUnitFee: (state, action: PayloadAction<number>) => {
      state.fees.push(action.payload);
    },
    addWaivefee: (state, action: PayloadAction<WaiveFee>) => {
      state.waiveFee = { ...state.waiveFee, ...action.payload };
    },
    setTransferWaiveFeeAmount: (state, action: PayloadAction<string>) => {
      state.waiveFee.waiveFeeAmount = action.payload;
    },
    removeTransferUnitFee: (state, action: PayloadAction<number>) => {
      state.fees = state.fees.filter((feeId) => feeId !== action.payload);
    },
    addTransferUnitMerchandise: (state, action: PayloadAction<MoveInMerchandise>) => {
      state.merchandise.push(action.payload);
    },
    removeTransferUnitMerchandise: (state, action: PayloadAction<number>) => {
      state.merchandise = state.merchandise.filter((selectedMerch) => selectedMerch.id !== action.payload);
    },
    setMerchandiseQuantityForTransferUnit:
    (state, action: PayloadAction<{ merchId: number; merchQuantity: number }>) => {
      const merchToUpdate = state.merchandise.find((merchItem) => merchItem.id === action.payload.merchId);

      if (merchToUpdate) {
        merchToUpdate.quantity = action.payload.merchQuantity;
      }
    },
    addTransferUnitInsurancePlan: (state, action: PayloadAction<number>) => {
      state.insurancePlanId = action.payload;
    },
    clearTransferUnitInsurancePlan: (state) => {
      state.insurancePlanId = initialState.insurancePlanId;
    },
    setTransferPromoDefermentStatus: (state, action: PayloadAction<boolean>) => {
      state.promoDefermentStatus = action.payload;
    },
    setTransferStoringReason: (state, action: PayloadAction<string>) => {
      state.storingReason = action.payload;
    },
    setIsTransfer: (state, action: PayloadAction<boolean>) => {
      state.isTransfer = action.payload;
    },
    setTransferLeasePackageModalOpened: (state) => {
      state.leasePackageModalOpened = !state.leasePackageModalOpened;
    },
    setTransferSendEmailModalOpened: (state) => {
      state.sendEmailModalOpened = !state.sendEmailModalOpened;
    },
    setTransferSelectedDocuments: (state, action) => {
      state.selectedDocuments = action.payload;
    },
    setTransferDefaultDocuments: (state, action) => {
      state.defaultDocuments = action.payload;
    },
    setTransferUnitModalOpen: (state, action: PayloadAction<boolean>) => {
      state.transferUnitModalOpen = action.payload;
    },
    resetTransferUnitSlice: (state: TransferUnitSliceState) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTransferUnit.pending, (state) => {
        state.transferUnitLoading = true;
      })
      .addCase(createTransferUnit.fulfilled, (state, action) => {
        state.transferUnitLoading = false;
        state.transferUnitConfirmation = action.payload;
      })
      .addCase(createTransferUnit.rejected, (state, action) => {
        state.transferUnitLoading = false;
        state.transferUnitError = action.payload as string;
      });
  }
});

export const selectOccupantReservation = (state: RootState) => state.transferUnit?.occupantReservation;
export const selectTransferLeaseStartsOn = (state: RootState) => state.transferUnit?.leaseStartsOn;
export const selectMonthlyRate = (state: RootState) => state.transferUnit?.monthlyRate;
export const selectTransferUnitLoading = (state: RootState) => state.transferUnit?.transferUnitLoading;
export const selectTransferPeriods = (state: RootState) => state.transferUnit?.periods;
export const selectPromotionId = (state: RootState) => state.transferUnit?.promotionId;
export const selectTransferUnitFees = (state: RootState) => state.transferUnit?.fees;
export const selectTransferUnitMerchandise = (state: RootState) => state.transferUnit?.merchandise;
export const selectTransferUnitInsurancePlan = (state: RootState) => state.transferUnit?.insurancePlanId;
export const selectPromoDefermentStatus = (state: RootState) => state.transferUnit?.promoDefermentStatus;
export const selectTransferUnitConfirmation = (state: RootState) => state.transferUnit?.transferUnitConfirmation;
export const selectWaiveFee = (state: RootState) => state.transferUnit?.waiveFee;
export const selectIsTransfer = (state: RootState) => state.transferUnit?.isTransfer;
export const selectLeasePackageModalOpened = (state: RootState) => state.transferUnit?.leasePackageModalOpened;
export const selectSendEmailModalOpened = (state: RootState) => state.transferUnit?.sendEmailModalOpened;
export const selectSelectedDocuments = (state: RootState) => state.transferUnit?.selectedDocuments;
export const selectDefaultDocuments = (state: RootState) => state.transferUnit?.defaultDocuments;
export const selectTransferUnitModalOpen = (state: RootState) => state.transferUnit?.transferUnitModalOpen;

export const {
  setTransferOccupantReservation,
  setTransferLeaseStartsOn,
  setTransferMonthlyRate,
  setTransferPeriods,
  addTransferUnitFee,
  addWaivefee,
  setTransferWaiveFeeAmount,
  removeTransferUnitFee,
  addTransferUnitMerchandise,
  removeTransferUnitMerchandise,
  setMerchandiseQuantityForTransferUnit,
  addTransferUnitInsurancePlan,
  clearTransferUnitInsurancePlan,
  setTransferPromoDefermentStatus,
  setTransferStoringReason,
  setIsTransfer,
  setTransferLeasePackageModalOpened,
  setTransferSendEmailModalOpened,
  setTransferSelectedDocuments,
  setTransferDefaultDocuments,
  setTransferUnitModalOpen,
  resetTransferUnitSlice
} = transferUnitSlice.actions;

export default transferUnitSlice.reducer;
