import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSearchParams } from "../../../../utils/getSearchParams/getSearchParams";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getAllOccupants = createAsyncThunk(
  "getAllOccupants",
  (_, { dispatch, getState, rejectWithValue }) => {
    const store = getState() as RootState;
    const selectedFacilityId = store.selectedFacility.selectedFacility.id;
    const occupantsFilter = store.occupants.occupantsFilter;

    const searchParams = {
      params: {
        ...getSearchParams(store, true),
        with: ["ledgers.unit", "ledgers.unit.productType"]
      }
    };

    if (occupantsFilter) {
      searchParams.params = { ...searchParams.params, ...occupantsFilter };
    }

    return axios.get(`api/v1/facilities/${selectedFacilityId}/occupants`, searchParams)
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = "There was an error fetching the occupants";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
