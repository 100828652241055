import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Facility Settings Styles" })((theme) => ({
  blueText: {
    fontWeight: 600,
    textTransform: "uppercase",
    color: theme.palette.primary.contrastText
  },
  actionButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: "white"
  },
  labelWeight: {
    fontWeight: 700
  },
  autocompleteError: {
    "& label.Mui-focused": {
      color: theme.palette.error.main
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.error.main
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.error.main
      },
      "&:hover fieldset": {
        borderColor: theme.palette.error.main
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.error.main
      }
    }
  },
  addButton: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.primary.contrastText,
    fontWeight: 300,
    color: theme.palette.common.white,
    minWidth: "8rem",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main
    }
  }
}));

export default useStyles;
