import { makeStyles } from "../../makeStyles";

const useStyles = makeStyles({ label: "TableWrapper" })((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    border: "1px"
  },
  addButton: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.primary.contrastText,
    textTransform: "capitalize",
    fontWeight: 300,
    color: theme.palette.common.white,
    minWidth: "8rem",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main
    }
  },
  bodyHeaderDivider: {
    background: theme.palette.primary.main,
    width: "100%",
    marginTop: theme.spacing(2)
  }
}));

export default useStyles;
