import { Button, Typography } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import { resetFeeInformationSlice, selectFee } from "src/store/reducers/feeInformationSlice/feeInformationSlice";
import {
  selectFeeCategories,
  selectFeeCategoriesLoading
} from "../../../store/reducers/feeCategoriesSlice/feeCategoriesSlice";
import {
  selectRevenueClasses,
  selectRevenueClassesLoading
} from "../../../store/reducers/revenueClassesSlice/revenueClassesSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useNavigate, useParams } from "react-router";

import AppModal from "src/components/ui/AppModal/AppModal";
import { Breadcrumb } from "../../../models/Breadcrumb";
import FeeForm from "../FeeForm/FeeForm";
import { FeeFormType } from "../FeeForm/feeFormValidation";
import ItemManagementLayout from "../../../layouts/ItemManagement/ItemManagementLayout";
import { LoadingButton } from "@mui/lab";
import { NavLink } from "react-router-dom";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import clsx from "clsx";
import { getAllFeeCategories } from "src/store/thunks/feeCategory/getAll/getAllFeeCategories";
import { getAllFeesForFacility } from "src/store/thunks/fee/getAllForFacility/getAllFeesForFacility";
import { getAllRevenueClasses } from "src/store/thunks/revenueClass/getAll/getAllRevenueClasses";
import { getFee } from "../../../store/thunks/fee/get/getFee";
import {
  selectSelectedAdminFacility
} from "../../../store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import { updateFee } from "src/store/thunks/fee/update/updateFee";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Fees & Charges"
  },
  {
    name: "Edit Fee",
    bold: true
  }
];

const EditFee: React.FC = (): ReactElement => {
  const selectedAdminFacility = useAppSelector(selectSelectedAdminFacility);
  const revenueClassesLoading = useAppSelector(selectRevenueClassesLoading);
  const feeCategoriesLoading = useAppSelector(selectFeeCategoriesLoading);
  const params = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const revenueClasses = useAppSelector(selectRevenueClasses);
  const feeCategories = useAppSelector(selectFeeCategories);
  const fee = useAppSelector(selectFee);
  const [open, setOpen] = useState(false);
  const [feeForm, setFeeForm] = useState<FeeFormType>({} as FeeFormType);
  const permissions = useAppSelector(selectFacilityPermissions);
  const feesPermissions = createPermissions(permissions, "manage_fees");

  useEffect(() => {
    if (!selectedAdminFacility) {
      return navigate("/fees");
    }

    dispatch(getFee(params.id!));
    dispatch(getAllFeeCategories());
    dispatch(getAllRevenueClasses(selectedAdminFacility!.id));
  }, []);

  const handleSubmit = () => {
    dispatch(
      updateFee({
        id: params.id!,
        name: feeForm.feeName,
        amount: feeForm.feeAmount,
        applyAt: feeForm.applyFeeAt,
        description: feeForm.feeDescription,
        feeCategoryId: feeForm.feeCategoryId,
        feeType: feeForm.feeType,
        isRequired: feeForm.isRequired,
        recurrence: feeForm.feeRecurrence,
        revenueClassId: feeForm.feeRevenueClassId,
        floorAmount: feeForm.floorAmount,
        amountFloorRestriction: feeForm.amountFloorRestriction
      })
    ).then((resp) => {
      if (!resp.type.includes("rejected")) {
        dispatch(getAllFeesForFacility({ facilityId: selectedAdminFacility!.id }));
        navigate("/fees");
      }
    });
  };

  const pageHeader = <PageHeader title={"Edit Fee"} breadcrumbs={breadcrumbs} />;

  if (feesPermissions.update) {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <FeeForm
          feeCategories={feeCategories}
          revenueClasses={revenueClasses}
          fee={fee}
          submitHandler={(data) => {
            setFeeForm(data);
            setOpen(true);
          }}
          />
        <AppModal
          onCloseAction={() => setOpen(false)}
          confirmAction={handleSubmit}
          cancelAction={() => setOpen(false)}
          open={open}
        >
          <Typography variant={"h6"}>Are you sure?</Typography>
          <Typography>
            Saving these edits will apply them to the current fee. All active units with this fee will be affected
            accordingly.
          </Typography>
        </AppModal>
      </ViewWrapper>
    );
  } else {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <h1>You do not have permission to view this page</h1>
      </ViewWrapper>
    );
  }
};

export default EditFee;
