import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, Modal, Paper, Typography } from "@mui/material";
import useStyles from "./GenerateLeasePackageModal.styles";
import { Close } from "@mui/icons-material";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  selectDefaultDocuments,
  selectLeasePackageModalOpened,
  selectSelectedDocuments,
  setDefaultDocuments,
  setLeasePackageModalOpened,
  setSelectedDocuments,
  setSendEmailModalOpened
} from "src/store/reducers/moveInSlice/moveInSlice";
import { getAllDocumentTemplates } from "src/store/thunks/documentTemplates/getAll/getAllDocumentTemplates";
import { selectDocumentTemplates } from "src/store/reducers/documentsSlice/documentsSlice";
import { getLeasePackage } from "src/store/thunks/moveIn/getLeasePackage/getLeasePackage";
import { generateLeasePackage } from "src/store/thunks/moveIn/generateLeasePackage/generateLeasePackage";
import { printReceipt } from "src/store/thunks/moveIn/print/printReceipt";

const GenerateLeasePackageModal = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const modalOpened = useAppSelector(selectLeasePackageModalOpened);
  const documentTemplates = useAppSelector(selectDocumentTemplates);
  const selectedDocuments = useAppSelector(selectSelectedDocuments);
  const defaultDocuments = useAppSelector(selectDefaultDocuments);
  const [currentSelectedDocument, setCurrentSelectedDocument] = useState(0);
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    dispatch(setLeasePackageModalOpened());
    dispatch(setDefaultDocuments([]));
    dispatch(setSelectedDocuments([]));
  };

  useEffect(() => {
    dispatch(getAllDocumentTemplates());
    if (defaultDocuments.length === 0) {
      dispatch(getLeasePackage()).then((resp) => {
        const documents = [...selectedDocuments];
        if (!resp.type.includes("rejected")) {
          for (const document of resp.payload.document_templates) {
            documents.push({
              id: document.id,
              name: document.name,
              isAutomaticallyAdded: true
            });
          }

          dispatch(setSelectedDocuments([...selectedDocuments, ...documents]));
          dispatch(setDefaultDocuments([...defaultDocuments, ...documents]));
        }
      });
    }
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    dispatch(generateLeasePackage({ selectedDocuments })).then((resp) => {
      if (!resp.type.includes("rejected")) {
        closeModal();
      }
    });
  };

  const handleEmailReceipt = () => {
    dispatch(setLeasePackageModalOpened());
    dispatch(setSendEmailModalOpened());
  };

  const handlePrintReceipt = () => {
    dispatch(printReceipt()).then((resp) => {
      if (!resp.type.includes("rejected")) {
        const link = document.createElement("a");
        link.href = resp.payload as string;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  const handleChange = (value: number) => {
    setCurrentSelectedDocument(value);
    if (documentTemplates.length > 0) {
      dispatch(
        setSelectedDocuments([
          ...selectedDocuments,
          {
            id: +value,
            name: documentTemplates.find((document) => document.id === +value)?.name,
            isAutomaticallyAdded: false
          }
        ])
      );
    }
    setCurrentSelectedDocument(0);
  };

  const documentItems = documentTemplates.map((document) => (
    <option
      data-testid={`document-${document.id}`}
      key={document.id}
      value={document.id}
      disabled={!!selectedDocuments.find((d) => d.id === document.id)}
    >
      {document.name}
    </option>
  ));

  return (
    <Modal
      open={modalOpened}
      onClose={closeModal}
      aria-labelledby={"Modal Component"}
      aria-describedby={"A Modal component."}
      className={classes.modal}
      data-testid={"modal-cancel-deal"}
    >
      <Grid
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        spacing={2}
        className={classes.container}
        pb={2}
      >
        <Box display={"flex"} justifyContent={"space-between"} className={classes.header}>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography variant={"h6"}>Generate Lease Package</Typography>
          </Box>
          <Box>
            <IconButton onClick={closeModal}>
              <Close />
            </IconButton>
          </Box>
        </Box>
        <Box mt={2} mb={2}>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam.
          </Typography>
        </Box>
        <Box width={"100%"}>
          <Typography variant={"body1"} fontWeight={"bold"}>
            Documents added to lease package:
          </Typography>
          <Box mt={2} border={1} borderRadius={"4px"}
            borderColor={"#ddd"}>
            <Box borderBottom={1} p={2} borderColor={"#ddd"}
              fontWeight={"bold"}>
              Document Name
            </Box>
            <Box p={2}>
              {selectedDocuments.map((document) => (
                <Box key={document.id} mb={1} display={"flex"}
                  justifyContent={"space-between"}>
                  <Link to={"#"}>{document.name}</Link>
                  {document.isAutomaticallyAdded
                    ? (
                      <Typography variant={"caption"}>automatically added</Typography>
                      )
                    : null}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box width={"100%"} mt={3}>
          <Typography variant={"body1"} fontWeight={"bold"}>
            Add document to package:
          </Typography>
          <Box width={"50%"}>
            <PMSSelect
              id={"notification-categories"}
              size={"small"}
              value={currentSelectedDocument}
              changeHandler={(e) => handleChange(e.target.value)}
            >
              <option value={0} selected>
                - Choose Document -
              </option>
              {documentItems}
            </PMSSelect>
          </Box>
          <Box width={"100%"} display={"flex"} justifyContent={"space-between"}
            mt={3}>
            <Button
              className={classes.buttonPrimary}
              color={"primary"}
              variant={"contained"}
              size={"large"}
              onClick={handleSubmit}
              disabled={loading}
            >
              Send Lease Package
            </Button>
            <Button
              className={classes.buttonSecondary}
              color={"primary"}
              variant={"outlined"}
              size={"large"}
              onClick={handleEmailReceipt}
            >
              Email Receipt
            </Button>
            <Button
              className={classes.buttonSecondary}
              color={"primary"}
              variant={"outlined"}
              size={"large"}
              onClick={handlePrintReceipt}
            >
              Print receipt
            </Button>
          </Box>
        </Box>
      </Grid>
    </Modal>
  );
};

export default GenerateLeasePackageModal;
