import { Box, Button, Grid, InputAdornment, InputLabel, TextField, Typography } from "@mui/material";
import React, { FormEvent, useEffect } from "react";
import { formatAmex, formatStandard, maskAmex, maskCVV, maskStandard } from "src/utils/creditCardUtils/creditCardUtils";
import {
  resetReviewAndPayBillingAddress,
  selectAddress,
  selectCity,
  selectEnableAutoBilling,
  selectFirstName,
  selectLastName,
  selectMiddleInitial,
  selectState,
  selectZip,
  setAddress,
  setCity,
  setFirstName,
  setLastName,
  setMiddleInitial,
  setState,
  setZip
} from "src/store/reducers/reviewAndPayBillingAddressSlice/reviewAndPayBillingAddressSlice";
import {
  resetReviewAndPayCCSlice,
  selectCardholderFirstName,
  selectCardholderLastName,
  selectCreditCardNumber,
  selectCreditCardNumberMasked,
  selectCreditCardType,
  selectCvv,
  selectCvvMasked,
  selectExpDate,
  setCardholderFirstName,
  setCardholderLastName,
  setCreditCardNumber,
  setCreditCardNumberMasked,
  setCvv,
  setCvvMasked,
  setExpDate
} from "src/store/reducers/reviewAndPayCC/reviewAndPayCCSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";

import { CreditCard } from "@mui/icons-material";
import MaskedExpirationDate from "src/components/masking/MaskedExpirationDate/MaskedExpirationDate";
import MaskedPostalCode from "src/components/masking/MaskedPostalCode/MaskedPostalCode";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import creditCardValidation from "./creditCardFormValidation";
import expStringRemovesSlash from "src/utils/expStringRemoveSlash/expStringRemoveSlash";
import { inputError } from "src/utils/showInputError/showInputError";
import states from "src/utils/usStates";
import useCardSwipe from "src/hooks/useCardSwipe";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import useStyles from "./CreditCard.styles";

interface CreditCardFormProps {
  formId: string
  onSubmit: () => void
  loading: boolean
}

const CreditCardForm = ({ formId, onSubmit, loading }: CreditCardFormProps) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const cardholderFirstName = useAppSelector(selectCardholderFirstName);
  const cardholderLastName = useAppSelector(selectCardholderLastName);
  const cvv = useAppSelector(selectCvv);
  const cvvMasked = useAppSelector(selectCvvMasked);
  const creditCardNumber = useAppSelector(selectCreditCardNumber);
  const creditCardNumberMasked = useAppSelector(selectCreditCardNumberMasked);
  const expDate = useAppSelector(selectExpDate);
  const creditCardType = useAppSelector(selectCreditCardType);
  const firstName = useAppSelector(selectFirstName);
  const middleInitial = useAppSelector(selectMiddleInitial);
  const lastName = useAppSelector(selectLastName);
  const address = useAppSelector(selectAddress);
  const city = useAppSelector(selectCity);
  const state = useAppSelector(selectState);
  const zip = useAppSelector(selectZip);
  const enableAutoBilling = useAppSelector(selectEnableAutoBilling);
  const selectedOccupant = useAppSelector(state => state.selectedOccupant.selectedOccupant);
  const [swipeData, resetSwipeData] = useCardSwipe();
  const formik = useFormik({
    initialValues: {
      cardholderFirstName,
      cardholderLastName,
      cvv: cvvMasked,
      creditCardNumber: creditCardNumberMasked,
      expDate,
      firstName,
      middleInitial,
      lastName,
      address,
      city,
      state,
      zip,
      enableAutoBilling
    },
    validationSchema: creditCardValidation,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit
  });

  const { handleSubmit, touched, errors, handleChange, values, setFieldValue } = formik;

  const updateForm = (fieldName: string, fieldValue?: string | boolean): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleCreditCardNumberOnChange = (fieldName: keyof typeof values, fieldValue: string) => {
    const updatedValue = creditCardType === "american-express" ? formatAmex(fieldValue) : formatStandard(fieldValue);
    updateForm(fieldName, updatedValue);
    dispatch(setCreditCardNumber(updatedValue));
  };

  const handleCreditCardOnFocus = () => {
    updateForm("creditCardNumber", creditCardNumber);
    dispatch(setCreditCardNumber(creditCardNumber));
  };

  const handleCreditCardOnBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    const updatedMaskedNumber = creditCardType === "american-express"
      ? maskAmex(e.target.value)
      : maskStandard(e.target.value);
    dispatch(setCreditCardNumberMasked(updatedMaskedNumber));
    updateForm("creditCardNumber", updatedMaskedNumber);
  };

  const handleCVVOnFocus = () => {
    updateForm("cvv", cvv);
    dispatch(setCvv(cvv));
  };

  const handleCVVOnBlur = () => {
    updateForm("cvv", maskCVV(cvv));
    dispatch(setCvvMasked(maskCVV(cvv)));
  };

  const maskZip = (value: string) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
  };

  const handleZipOnChange = (value: string) => {
    updateForm("zip", maskZip(value));
    dispatch(setZip(value.replace(/\D/g, "")));
  };

  useEffect(() => {
    return () => {
      dispatch(resetReviewAndPayCCSlice());
      dispatch(resetReviewAndPayBillingAddress());
    };
  }, []);

  useEffect(() => {
    if (swipeData?.cc_number && swipeData?.exp_date && swipeData.full_name) {
      const [firstName, lastName] = swipeData.full_name.split(" ");

      if (
        values.creditCardNumber !== swipeData.cc_number ||
        values.expDate !== swipeData.exp_date ||
        values.cardholderFirstName !== firstName ||
        values.cardholderLastName !== lastName
      ) {
        setFieldValue("creditCardNumber", swipeData.cc_number, false);
        dispatch(setCreditCardNumber(swipeData.cc_number));
        setFieldValue("expDate", expStringRemovesSlash(swipeData.exp_date), false);
        dispatch(setExpDate(expStringRemovesSlash(swipeData.exp_date)));
        setFieldValue("cardholderFirstName", firstName, false);
        dispatch(setCardholderFirstName(firstName));
        setFieldValue("cardholderLastName", lastName, false);
        dispatch(setCardholderLastName(lastName));

        const updatedMaskedNumber = creditCardType === "american-express"
          ? maskAmex(swipeData.cc_number)
          : maskStandard(swipeData.cc_number);

        setFieldValue("creditCardNumber", updatedMaskedNumber, false);
        dispatch(setCreditCardNumberMasked(updatedMaskedNumber));

        resetSwipeData();
      }
    }
  }, [swipeData, resetSwipeData, formik.setFieldValue, dispatch, values, creditCardType]);

  return (
    <Grid
      xs={10}
      item
      mt={"1rem"}
      component={"form"}
      id={formId}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
      }}
  >
      <Grid item xs={12}>
        <Typography
          className={classes.headerCell}
    >
          Credit Card Information
        </Typography>
      </Grid>

      <Grid item xs={12} display={"flex"}
        gap={"1rem"}>
        <Grid
          item
          xs={5}
      >
          <InputLabel htmlFor={"cardholder-first-name"}>Cardholder First Name</InputLabel>
          <TextField
            fullWidth
            placeholder={"Cardholder First Name"}
            id={"cardholder-first-name"}
            inputProps={{ "data-testid": "cardholder-first-name" }}
            name={"cardholderFirstName"}
            value={values.cardholderFirstName}
            onChange={(e) => {
              handleChange(e);
              dispatch(setCardholderFirstName(e.target.value));
            }}
            error={inputError("cardholderFirstName", touched, errors).error}
            helperText={inputError("cardholderFirstName", touched, errors).helperText}
        />
        </Grid>
        <Grid
          item
          xs={5}
      >
          <InputLabel htmlFor={"cardholder-last-name"}>Cardholder Last Name</InputLabel>
          <TextField
            fullWidth
            placeholder={"Cardholder Last Name"}
            id={"cardholder-last-name"}
            inputProps={{ "data-testid": "cardholder-last-name" }}
            name={"cardholderLastName"}
            value={values.cardholderLastName}
            onChange={(e) => {
              handleChange(e);
              dispatch(setCardholderLastName(e.target.value));
            }}
            error={inputError("cardholderLastName", touched, errors).error}
            helperText={inputError("cardholderLastName", touched, errors).helperText}
        />
        </Grid>

        <Grid
          item
          xs={2}
      >
          <InputLabel htmlFor={"cvv"}>CVV</InputLabel>
          <TextField
            fullWidth
            placeholder={"CVV"}
            data-mask-sensitive={true}
            id={"cvv"}
            inputProps={{
              "data-testid": "cvv",
              maxLength: creditCardType === "american-express" ? 4 : 3
            }}
            name={"cvv"}
            value={values.cvv}
            onChange={(e) => {
              handleChange(e);
              dispatch(setCvv(e.target.value));
            }}
            onBlur={handleCVVOnBlur}
            onFocus={handleCVVOnFocus}
            error={inputError("cvv", touched, errors).error}
            helperText={inputError("cvv", touched, errors).helperText}
        />
        </Grid>
      </Grid>

      <Grid item xs={12} mt={1}
        display={"flex"}
        justifyContent={"space-between"} gap={"1rem"}>
        <Grid
          item
          xs={10}
      >
          <InputLabel htmlFor={"credit-card-number"}>Credit Card Number</InputLabel>
          <TextField
            fullWidth
            placeholder={"Credit Card Number"}
            id={"credit-card-number"}
            data-mask-sensitive={true}
            inputProps={{
              "data-testid": "credit-card-number",
              maxLength: creditCardType === "american-express" ? 17 : 19
            }}
            name={"creditCardNumber"}
            InputProps={{
              startAdornment: (
                <InputAdornment position={"start"}>
                  <CreditCard />
                </InputAdornment>
              )
            }}
            value={values.creditCardNumber}
            onChange={(e) => handleCreditCardNumberOnChange("creditCardNumber", e.target.value)}
            onBlur={(e) => handleCreditCardOnBlur(e)}
            onFocus={handleCreditCardOnFocus}
            error={inputError("creditCardNumber", touched, errors).error}
            helperText={inputError("creditCardNumber", touched, errors).helperText}
        />
        </Grid>
        <Grid
          item
          xs={2}
      >
          <InputLabel htmlFor={"expDate"}>Exp Date</InputLabel>
          <MaskedExpirationDate
            id={"expDate"}
            dataTestId={"expDate"}
            fullWidth
            variant={"outlined"}
            name={"expDate"}
            type={"tel"}
            placeholder={"MM/YY"}
            onChange={(e) => {
              handleChange(e);
              dispatch(setExpDate(e.target.value));
            }}
            value={values.expDate}
            error={inputError("expDate", touched, errors).error}
            helperText={inputError("expDate", touched, errors).helperText}
          />
        </Grid>
      </Grid>

      {/* Billing Address */}
      <Grid item xs={12} mt={2}>
        <Box className={classes.headerCell} display={"flex"} alignItems={"center"}
          justifyContent={"space-between"}>
          <Typography>
            Billing Address
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} display={"flex"}
        justifyContent={"space-between"} gap={"1.2rem"}>
        <Grid
          item
          xs={5}
      >
          <InputLabel htmlFor={"firstName"}>First Name</InputLabel>
          <TextField
            fullWidth
            placeholder={"First Name"}
            id={"firstName"}
            inputProps={{ "data-testid": "firstName" }}
            name={"firstName"}
            onChange={(e) => {
              handleChange(e);
              dispatch(setFirstName(e.target.value));
            }}
            value={values.firstName}
            error={inputError("firstName", touched, errors).error}
            helperText={inputError("firstName", touched, errors).helperText}
        />
        </Grid>
        <Grid
          item
          xs={2}
      >
          <InputLabel htmlFor={"middleInitial"}>M.I.</InputLabel>
          <TextField
            fullWidth
            placeholder={"M.I."}
            id={"middleInitial"}
            inputProps={{ "data-testid": "middleInitial", maxLength: 1 }}
            name={"middleInitial"}
            onChange={(e) => {
              handleChange(e);
              dispatch(setMiddleInitial(e.target.value));
            }}
            value={values.middleInitial}
            error={inputError("middleInitial", touched, errors).error}
            helperText={inputError("middleInitial", touched, errors).helperText}
        />
        </Grid>

        <Grid
          item
          xs={5}
      >
          <InputLabel htmlFor={"lastName"}>Last Name</InputLabel>
          <TextField
            fullWidth
            placeholder={"Last Name"}
            id={"lastName"}
            inputProps={{ "data-testid": "last-name" }}
            name={"lastName"}
            onChange={(e) => {
              handleChange(e);
              dispatch(setLastName(e.target.value));
            }}
            value={values.lastName}
            error={inputError("lastName", touched, errors).error}
            helperText={inputError("lastName", touched, errors).helperText}
        />
        </Grid>
      </Grid>

      <Grid item xs={12} mt={1}
        display={"flex"}
        justifyContent={"space-between"} gap={"1.2rem"}>
        <Grid
          item
          xs={4}
      >
          <InputLabel htmlFor={"address"}>Address</InputLabel>
          <TextField
            fullWidth
            placeholder={"Address"}
            id={"address"}
            inputProps={{ "data-testid": "address" }}
            name={"address"}
            onChange={(e) => {
              handleChange(e);
              dispatch(setAddress(e.target.value));
            }}
            value={values.address}
            error={inputError("address", touched, errors).error}
            helperText={inputError("address", touched, errors).helperText}
        />
        </Grid>
        <Grid
          item
          xs={4}
      >
          <InputLabel htmlFor={"city"}>City</InputLabel>
          <TextField
            fullWidth
            placeholder={"City"}
            id={"city"}
            inputProps={{ "data-testid": "city" }}
            name={"city"}
            onChange={(e) => {
              handleChange(e);
              dispatch(setCity(e.target.value));
            }}
            value={values.city}
            error={inputError("city", touched, errors).error}
            helperText={inputError("city", touched, errors).helperText}
        />
        </Grid>
        <Grid
          item
          xs={2}
      >
          <PMSSelect
            id={"state"}
            name={"state"}
            label={"State"}
            value={values.state}
            changeHandler={(e) => {
              handleChange(e);
              dispatch(setState(e.target.value));
            }}
            error={inputError("state", touched, errors).error}
            helperText={inputError("state", touched, errors).helperText}
          >
            <option value={""}>{" - State -"}</option>
            {states.map(state => <option key={state.value} value={state.value}>{state.text}</option>)}
          </PMSSelect>
        </Grid>
        <Grid
          item
          xs={2}
      >
          <InputLabel htmlFor={"zip"}>ZIP/Postal Code</InputLabel>
          <MaskedPostalCode
            fullWidth
            placeholder={"ZIP/Postal Code"}
            id={"zip"}
            name={"zip"}
            onChange={(e) => {
              handleZipOnChange(e.target.value);
            }}
            value={values.zip}
            error={inputError("zip", touched, errors).error}
            helperText={inputError("zip", touched, errors).helperText}
        />
        </Grid>
      </Grid>
      <Grid
        pt={2}
        mt={4}
        container
        justifyContent={"flex-end"}
          >
        <Grid item>
          <Button
            data-testid={"cancel-button"}
            className={classes.cancelButton}
            variant={"text"}
            color={"secondary"}
            onClick={() => navigate("/merchandise/point-of-sales")}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            form={formId}
            type={"submit"}
            data-testid={"cancel-button"}
            className={classes.confirmButton}
            variant={"contained"}
            disabled={loading}
              >
            Place Order
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreditCardForm;
