import * as Yup from "yup";
import { dateTimeWarning, dateWarning } from "../../../../utils/commonWarnings";
import moment from "moment";

const validationSchema = Yup.object().shape({
  referralType: Yup.string().nullable(),
  dealType: Yup.string().required("Deal type is required"),
  neededOn: Yup
    .string()
    .nullable()
    .test({
      name: "is past date",
      test: function(value) {
        if (moment(value).isBefore(moment(), "day")) {
          return this.createError({ message: "A valid date is required" });
        }
        return true;
      }
    })
    .required("Rental Date is required")
    .length(10, dateWarning),
  followup: Yup
    .string()
    .nullable()
    .test({
      name: "is past date",
      test: function(value) {
        if (moment(value).isBefore(moment(), "day")) {
          return this.createError({ message: "A valid date is required" });
        }
        return true;
      }
    })
    .required("Follow-Up Date is required")
    .length(10, dateWarning),
  expiresOn: Yup.string().nullable().when("dealType", {
    is: "reservation",
    then: Yup
      .string()
      .nullable()
      .test({
        name: "is past date",
        test: function(value) {
          if (moment(value).isBefore(moment(), "day")) {
            return this.createError({ message: "A valid date is required" });
          }

          return true;
        }
      })
      .required("Expiration Date is required")
      .length(10, dateWarning)
  }),
  appointmentFor: Yup.string().nullable().when("dealType", {
    is: "reservation",
    then: Yup
      .string()
      .nullable()
      .test({
        name: "is past date",
        test: function(value) {
          if (moment(value).isBefore(moment(), "day")) {
            return this.createError({ message: "A valid date is required" });
          }

          return true;
        }
      })
      .required("Appointment Date is required").length(20, dateTimeWarning)
  })
});

export default validationSchema;
