import { Button, Grid, Modal, Paper, Typography } from "@mui/material";
import { resetDealsSlice, selectDealsFilter } from "src/store/reducers/dealsSlice/dealsSlice";
import { selectCancelDealModalOpen, selectDeal, setCancelDealModalOpen } from "src/store/reducers/dealSlice/dealSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Box } from "@mui/system";
import CancellationModalValidation from "./CancellationModalValidation";
import { Deal } from "src/models/Deal";
import { LoadingButton } from "@mui/lab";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import React from "react";
import UpdateDealPayload from "src/store/thunks/deals/update/UpdateDealPayload";
import { getAllActiveDeals } from "src/store/thunks/deals/getAllActive/getAllActiveDeals";
import { inputError } from "src/utils/showInputError/showInputError";
import moment from "moment";
import { resetTablesSlice } from "src/store/reducers/tablesSlice/tablesSlice";
import { updateDeal } from "src/store/thunks/deals/update/updateDeal";
import { useFormik } from "formik";
import useStyles from "./CancellationModal.styles";

const cancellationReasons = [
  { value: "No longer needed", text: "No longer needed" },
  { value: "Found storage elsewhere", text: "Found storage elsewhere" },
  { value: "Price is too high", text: "Price is too high" }
];

const CancellationModal = ({ onSubmit }: { onSubmit?: Function }) => {
  const { classes } = useStyles();

  const deal = useAppSelector(selectDeal) as Deal;
  const dispatch = useAppDispatch();
  const modalOpened = useAppSelector(selectCancelDealModalOpen);
  const dealsFilter = useAppSelector(selectDealsFilter);

  const handleCancelDeal = (values: { cancellationReason: string | null }) => {
    const payload: UpdateDealPayload = {
      facility_id: deal?.facility_id,
      occupant_id: deal?.occupant_id,
      product_type_id: deal.product_type_id,
      reservation_expires_at: deal.reservation_expires_at,
      ...(deal.promotion_id && { promotion_id: parseInt(deal.promotion_id) }),
      ...(deal.unit_id && { unit_id: parseInt(deal.unit_id) }),
      deal_type:
        typeof deal.deal_type === "string" ? (deal.deal_type === "Reservation" ? 2 : 1) : (deal.deal_type as number),
      appointment_at: deal.appointment_at ?? "",
      needed_at: deal?.needed_at,
      contact_at: deal.contact_at ?? "",
      last_contact_at: deal.last_contact_at ?? "",
      quoted_price: parseFloat(deal.quoted_price),
      first_name: deal.first_name,
      middle_name: deal.middle_name!,
      last_name: deal.last_name,
      email: deal.email,
      phone: deal.phone,
      address: deal.address,
      address_two: deal.address_two!,
      city: deal.city,
      region: deal.region,
      postal_code: deal.postal_code,
      country: deal.country,
      referral_type: deal.referral_type,
      source: deal.source,
      cancelled_at: moment().format("YYYY-MM-DD"),
      cancel_note: values.cancellationReason
    };

    return dispatch(updateDeal({ payload, deal_id: deal?.id })).then((resp) => {
      if (!resp.type.includes("rejected")) {
        dispatch(resetTablesSlice());
        dispatch(getAllActiveDeals(dealsFilter));
        dispatch(setCancelDealModalOpen(false));
        onSubmit && onSubmit();
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      cancellationReason: ""
    },
    validationSchema: CancellationModalValidation,
    onSubmit: async(values, { resetForm }) => {
      await handleCancelDeal(values);
      resetForm();
    }
  });

  const { touched, values, errors, handleSubmit, handleChange, resetForm } = formik;

  const handleClose = () => {
    resetForm();
    dispatch(setCancelDealModalOpen(false));
  };

  return (
    <Modal
      open={modalOpened}
      onClose={() => handleClose()}
      aria-labelledby={"Modal Component"}
      aria-describedby={"A Modal component."}
      className={classes.modal}
      data-testid={"modal-cancel-deal"}
    >
      <Grid
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        spacing={2}
        className={classes.container}
        pb={2}
      >
        <Box className={classes.content}>
          <Typography variant={"h5"} className={classes.title}>
            Confirm Deal Removal
          </Typography>
          <Typography>Are you sure you want to remove this deal?</Typography>
          <PMSSelect
            id={"cancellationReason"}
            name={"cancellationReason"}
            label={"Cancellation Reason"}
            value={values.cancellationReason}
            changeHandler={handleChange}
            error={inputError("cancellationReason", touched, errors).error}
            helperText={inputError("cancellationReason", touched, errors).helperText}
          >
            <option value={""}>{" - Reason -"}</option>
            {cancellationReasons.map((reason) => (
              <option key={reason.value} value={reason.value}>
                {reason.text}
              </option>
            ))}
          </PMSSelect>
          <Grid pt={4} spacing={4} container
            justifyContent={"center"}>
            <Grid item>
              <Button
                data-testid={"cancel-button"}
                className={classes.cancelButton}
                onClick={() => handleClose()}
                variant={"outlined"}
                color={"secondary"}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                data-testid={"confirm-button-modal"}
                color={"primary"}
                variant={"outlined"}
                className={classes.button}
                onClick={() => handleSubmit()}
              >
                Confirm
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Modal>
  );
};

export default CancellationModal;
