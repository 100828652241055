import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "UserMenu" })((theme) => ({
  flipHorizontal: {
    mozTransform: "scaleX(-1)",
    webkitTransform: "scaleX(-1)",
    oTransform: "scaleX(-1)",
    msFilter: "fliph",
    transform: "scaleX(-1)",
    filter: "fliph"
  },
  link: {
    textDecoration: "none",
    color: "black"
  },
  menuButton: {
    background: "inherit",
    boxShadow: "none",
    color: theme.palette.primary.dark
  },
  menuButtonIcon: {
    background: "inherit"
  }
}));

export default useStyles;
