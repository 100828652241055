import { PromotionSliceState } from "./PromotionSliceState";
import { RootState } from "../../rootStore";
import { createSlice } from "@reduxjs/toolkit";
import { disablePromotion } from "src/store/thunks/promotion/disable/disablePromotion";
import { enablePromotion } from "src/store/thunks/promotion/enable/enablePromotion";

export const initialState: PromotionSliceState = {
  promotionModalOpen: false,
  modalIsEnable: false,
  promotionLoading: false,
  promotionError: ""
};

export const promotionSlice = createSlice({
  name: "promotion",
  initialState,
  reducers: {
    setPromotionModalOpen: (state, action) => {
      state.promotionModalOpen = action.payload;
    },
    setPromotionModalIsEnable: (state, action) => {
      state.modalIsEnable = action.payload;
    },
    resetPromotionSlice: (state) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("promotion");
    }
  },
  extraReducers: builder => {
    builder
      .addCase(enablePromotion.pending, (state, action) => {
        state.promotionLoading = true;
      })
      .addCase(enablePromotion.fulfilled, (state, action) => {
        state.promotionLoading = false;
      })
      .addCase(enablePromotion.rejected, (state, action) => {
        state.promotionLoading = false;
        state.promotionError = action.payload as string;
      })
      .addCase(disablePromotion.pending, (state, action) => {
        state.promotionLoading = true;
      })
      .addCase(disablePromotion.fulfilled, (state, action) => {
        state.promotionLoading = false;
      })
      .addCase(disablePromotion.rejected, (state, action) => {
        state.promotionLoading = false;
        state.promotionError = action.payload as string;
      });
  }
});

export const selectPromotionLoading = (state: RootState) => state.promotion.promotionLoading;
export const selectPromotionModalOpen = (state: RootState) => state.promotion.promotionModalOpen;
export const selectPromotionModalIsEnable = (state: RootState) => state.promotion.modalIsEnable;

export const {
  setPromotionModalOpen,
  setPromotionModalIsEnable,
  resetPromotionSlice
} = promotionSlice.actions;

export default promotionSlice.reducer;
