import React, { ReactElement } from "react";
import { setOccupantContactInformation, setOccupantTaxExemptDocumentBase64 }
  from "../../../../store/reducers/occupantInformationSlice/occupantInformationSlice";
import OccupantContactInformation
  from "../../../../components/stepper/OccupantContactInformation/OccupantContactInformation";
import { OccupantContactValues }
  from "../../../../models/formikInputValues/occupant/OccupantContactValues";
import OccupantInfoIcons from "../../../../components/stepper/OccupantInfoIcons/OccupantInfoIcons";
import PMSAdvisory from "../../../../components/ui/PMSAdvisory/PMSAdvisory";
import { Paper } from "@mui/material";
import StepLayout from "src/layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "../../../../layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { toBase64 } from "../../EditOccupant/EditOccupantTabs/OccupantDocuments/OccupantDocuments";
import { useAppDispatch } from "../../../../store/hooks";
import { useNavigate } from "react-router";
import { validateGateCode } from "src/store/thunks/occupant/validateGateCode/validateGateCode";

const AddOccupantContact: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const stepperId = "occupant-information-two";

  const handleSubmit = async(values: OccupantContactValues) => {
    if (values.taxExempt && values.taxExemptDocument?.name) {
      const taxExemptDocumentBase64 = await toBase64(values.taxExemptDocument);
      dispatch(setOccupantTaxExemptDocumentBase64(taxExemptDocumentBase64 as string));
    }

    await dispatch(setOccupantContactInformation(values));
    const { payload } = await dispatch(validateGateCode());

    if (!payload?.valid_gate_code) {
      dispatch(showSnackbar({
        message: "Gate Code is in use",
        variant: "error"
      }));
      return;
    }

    navigate("/occupants/add-occupant/emergency-contact");
  };

  return (
    <>
      <PMSAdvisory
        title={"Occupant Information Cont'd"}
        advisoryText={"Next, please provide additional information for the occupant such as e-mail address, phone" +
        " number, etc."}
        icons={<OccupantInfoIcons />}
      />
      <Paper elevation={1}>
        <StepLayout
          stepperId={stepperId}
          buttons={
            <StepperButtons data-testid={"occupant-information-two-buttons"} stepperId={stepperId} />
          }
        >
          <OccupantContactInformation
            data-testid={"occupant-contact-form"}
            stepperId={stepperId}
            onSubmitHandler={handleSubmit}
          />
        </StepLayout>
      </Paper>
    </>
  );
};

export default AddOccupantContact;
