import {
  Autocomplete,
  AutocompleteRenderOptionState,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField
} from "@mui/material";
import React, { ReactNode, SyntheticEvent, forwardRef } from "react";
import clsx from "clsx";
import useStyles from "./PMSAutocomplete.styles";

interface PMSAutocompleteProps {
  id?: string;
  name?: string;
  defaultValue?: any;
  value: any;
  placeholder?: string;
  options: Array<any>;
  changeHandler: (event: SyntheticEvent<Element, Event>, value: any) => void;
  label?: string;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  getOptionLabel: (option: any) => string;
  renderOption?: (
    props: React.HTMLAttributes<HTMLElement>,
    option: any,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  ref?: React.Ref<HTMLDivElement>;
}

const PMSAutocomplete = forwardRef(function PMSAutocomplete({
  id,
  name,
  value,
  placeholder,
  options,
  changeHandler,
  label,
  error,
  helperText,
  defaultValue,
  fullWidth,
  getOptionLabel,
  renderOption,
  ref
}: PMSAutocompleteProps) {
  const { classes } = useStyles();

  return (
    <>
      {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
      <FormControl fullWidth variant={"outlined"}>
        <Autocomplete
          fullWidth={fullWidth}
          id={id}
          options={options}
          onChange={changeHandler}
          value={value}
          defaultValue={defaultValue}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          ref={ref}
          renderInput={(params) => (
            <TextField
              {...params}
              name={name}
              placeholder={placeholder}
              className={clsx({ [classes.autocompleteError]: error })}
            />
          )}
        />
      </FormControl>
      {error
        ? (
          <FormHelperText style={{ marginLeft: "14px" }} error>
            {helperText}
          </FormHelperText>
          )
        : null}
    </>
  );
});

export default PMSAutocomplete;
