import React, { ReactElement } from "react";
import GetStarted from "../../../../components/stepper/GetStarted/GetStarted";

const MakeAPaymentGetStarted: React.FC = (): ReactElement => {
  const advisoryText = `Bacon ipsum dolor amet sandbagger Alcatraz hamburguer pork chop,
cousin occaecat adipisicing prosciutto lorem liberal tempor tenderloin meatball voluptate laboris.

Chuck esse jerky elit tempor liberal
`;

  return (
    <GetStarted
      title={"Make a Payment"}
      advisoryText={advisoryText}
      nextStepPath={"/collections/make-a-payment/select-occupant-or-unit"}
    />
  );
};

export default MakeAPaymentGetStarted;
