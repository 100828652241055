import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "PaymentOptions" })((theme) => ({
  formGroupContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "start",
    margin: theme.spacing(1)
  }
}));

export default useStyles;
