import { Grid, InputAdornment, InputLabel, TextField } from "@mui/material";
import React, { ChangeEvent, FormEvent, ReactElement } from "react";
import {
  selectAmountReceived,
  selectMoneyOrderNumber,
  setAmountReceived,
  setMoneyOrderNumber
} from "../../../store/reducers/moneyOrderSlice/moneyOrderSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { inputError } from "../../../utils/showInputError/showInputError";
import moneyOrderFormValidation from "./moneyOrderFormValidation";
import { useFormik } from "formik";
import useStyles from "./MoneyOrderForm.styles";

interface MoneyOrderFormProps {
  handleSubmit: () => void;
  formId: string;
}

const MoneyOrderForm: React.FC<MoneyOrderFormProps> = ({ handleSubmit, formId }): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const amountReceived = useAppSelector(selectAmountReceived);
  const moneyOrderNumber = useAppSelector(selectMoneyOrderNumber);

  const formik = useFormik({
    initialValues: {
      amountReceived: amountReceived,
      moneyOrderNumber: moneyOrderNumber
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: moneyOrderFormValidation,
    /* istanbul ignore next */
    onSubmit: () => {
      handleSubmit();
    }
  });

  const updateForm = (fieldName: string, fieldValue?: string | boolean): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleAmountReceivedOnchange = (amountReceived: string) => {
    updateForm("amountReceived", amountReceived);
    dispatch(setAmountReceived(amountReceived));
  };

  const handleMoneyOrderNumberOnchange = (moneyOrderNumber: string) => {
    if (moneyOrderNumber.length <= 11) {
      updateForm("moneyOrderNumber", moneyOrderNumber);
      return dispatch(setMoneyOrderNumber(moneyOrderNumber));
    }
  };

  const { errors, touched, values } = formik;

  return (
    <Grid
      component={"form"}
      id={formId}
      spacing={2}
      container
      justifyContent={"space-between"}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        formik.handleSubmit();
      }}
    >
      <Grid item xs={4}>
        <InputLabel htmlFor={"amount-due"}>Amount</InputLabel>
        <TextField
          id={"amount-due"}
          type={"number"}
          value={values.amountReceived}
          className={classes.noArrow}
          inputProps={{ "data-testid": "amount-due" }}
          InputProps={{ startAdornment: <InputAdornment position={"start"}>$</InputAdornment> }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleAmountReceivedOnchange(e.target.value)}
          error={inputError("amountReceived", touched, errors).error}
          helperText={inputError("amountReceived", touched, errors).helperText}
        />
      </Grid>
      <Grid item xs={8}>
        <InputLabel htmlFor={"money-order-number"}>Order Number</InputLabel>
        <TextField
          fullWidth
          type={"number"}
          id={"money-order-number"}
          value={values.moneyOrderNumber}
          className={classes.noArrow}
          inputProps={{ "data-testid": "money-order-number" }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleMoneyOrderNumberOnchange(e.target.value)}
          error={inputError("moneyOrderNumber", touched, errors).error}
          helperText={inputError("moneyOrderNumber", touched, errors).helperText}
        />
      </Grid>
    </Grid>
  );
};

export default MoneyOrderForm;
