import { RootState } from "src/store/rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const printReceipt = createAsyncThunk("printReceipt",
  (transactionId: number | undefined, { dispatch, getState, rejectWithValue }) => {
    const store = getState() as RootState;

    const facilityId = store.selectedFacility?.selectedFacility.id;
    const transaction = transactionId ?? store.moveIn.moveInConfirmation?.transaction.id;

    return axios
      .get(`/api/v1/facilities/${facilityId}/transactions/${transaction}/print-receipt`, {
        responseType: "blob"
      })
      .then((resp) => {
        const fileUrl = window.URL.createObjectURL(new Blob([resp.data], { type: "application/pdf" }));
        return fileUrl;
      })
      .catch(() => {
        const errorMessage = "There was an error printing the receipt";

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  });
