import React, { ChangeEvent, FormEvent, ReactElement, useEffect, useRef, useState } from "react";
import { CreditCard } from "@mui/icons-material";
import { Divider, Grid, InputAdornment, InputLabel, SelectChangeEvent, TextField, Typography } from "@mui/material";
import creditCard from "credit-card-type";
import { useFormik } from "formik";
import MaskedExpirationDate from "src/components/masking/MaskedExpirationDate/MaskedExpirationDate";
import { inputError } from "src/utils/showInputError/showInputError";
import CreditCardValidation from "./CreditCardValidation";
import PaymentOptions from "../PaymentOptions/PaymentOptions";
import {
  formatAmex,
  formatStandard,
  maskAmex,
  maskCVV,
  maskStandard,
  parsedCCNumbers
} from "src/utils/creditCardUtils/creditCardUtils";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import {
  setCVV,
  setEnableAutoPay,
  setExpiration,
  setName,
  setNumber
} from "../../../../../../store/reducers/creditCardSlice/creditCardSlice";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import { selectSelectedOccupant } from "src/store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { PaymentInstrument } from "src/models/PaymentInstrument";
import useCardSwipe from "src/hooks/useCardSwipe";
import expStringRemovesSlash from "src/utils/expStringRemoveSlash/expStringRemoveSlash";

interface CreditCardFormProps {
  handleSubmit: (values: CreditCardFormValues) => void;
  actionButtons?: ReactElement;
  setFormik?: Function;
  useSavedPaymentInstrument?: boolean;
}

export interface CreditCardFormValues {
  cardholderName: string;
  cvv: string;
  creditCardNumber: string;
  expDate: string;
  firstName: string;
  middleInitial: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  isAutoBilling: boolean;
  isSaveAsPreferredMethod: boolean;
  usePaymentToken: boolean;
  paymentInstrument: number;
}

const CreditCardForm = ({
  handleSubmit,
  actionButtons,
  setFormik,
  useSavedPaymentInstrument = false
} : CreditCardFormProps) => {
  const dispatch = useAppDispatch();
  const [cvv, setCvv] = useState("");
  const [maskedCvv, setMaskedCvv] = useState("");
  const [creditCardNumber, setCreditCardNumber] = useState("");
  const [maskedCreditCardNumber, setmaskedCreditCardNumber] = useState("");
  const [cardHolderdName, setCardHolderName] = useState("");
  const [expDate, setExpDate] = useState("");
  const [autoBill, setAutoBill] = useState(false);
  const [swipeData, resetSwipeData] = useCardSwipe();
  const prevSwipeDataRef = useRef(swipeData);
  const [shouldManuallyValidate, setShouldManuallyValidate] = useState(false);

  const selectedOccupant = useAppSelector(selectSelectedOccupant);

  const paymentInstrumentList = selectedOccupant?.payment_instruments;
  let paymentInstruments: PaymentInstrument[];

  if ((paymentInstrumentList?.length ?? 0) > 0) {
    paymentInstruments = paymentInstrumentList!.reduce((acc, card) => {
      const existingCard = acc.find(c => c.last_4_digits === card.last_4_digits);
      if (!existingCard) {
        acc.push(card);
      } else if (card.created_at > existingCard.created_at) {
        const index = acc.indexOf(existingCard);
        acc[index] = card;
      }
      return acc;
    }, [] as PaymentInstrument[]);
  } else {
    paymentInstruments = [];
  }

  const formik = useFormik({
    initialValues: {
      cardholderName: "",
      cvv: maskedCvv,
      creditCardNumber: maskedCreditCardNumber,
      expDate: "",
      isAutoBilling: false,
      isSaveAsPreferredMethod: false,
      usePaymentToken: false,
      paymentInstrument: 0
    } as CreditCardFormValues,
    validationSchema: CreditCardValidation,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (formValues) => {
      const values = {
        ...formValues,
        creditCardNumber: creditCardNumber.replace(/\D/g, ""),
        cvv
      };
      handleSubmit(values);
    }
  });

  useEffect(() => {
    if (setFormik) {
      setFormik(formik);
    }
  }, []);

  const { touched, errors, handleChange, values, setFieldValue } = formik;

  const updateForm = (fieldName: string, fieldValue?: string | boolean | number): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const resetUseCreditCardSaved = () => {
    updateForm("usePaymentToken", false);
    updateForm("paymentInstrument", 0);
  };

  const handleNumberOnChange = (fieldValue: string) => {
    const ccType = creditCard(fieldValue)[0]?.type;
    const updatedValue = ccType === "american-express" ? formatAmex(fieldValue) : formatStandard(fieldValue);
    updateForm("creditCardNumber", updatedValue);
    setCreditCardNumber(updatedValue);

    resetUseCreditCardSaved();
  };

  const handleCCOnFocus = () => {
    updateForm("creditCardNumber", creditCardNumber);
    setCreditCardNumber(creditCardNumber);
  };

  const handleCCOnBlur = () => {
    const ccType = creditCard(creditCardNumber)[0]?.type;

    const updatedMaskedNumber =
      ccType === "american-express" ? maskAmex(creditCardNumber) : maskStandard(creditCardNumber);

    setmaskedCreditCardNumber(updatedMaskedNumber);
    updateForm("creditCardNumber", updatedMaskedNumber);
    dispatch(setNumber(creditCardNumber));
  };

  const handleCVVOnChange = (fieldValue: string) => {
    updateForm("cvv", parsedCCNumbers(fieldValue));
    setCvv(parsedCCNumbers(fieldValue));

    resetUseCreditCardSaved();
  };

  const handleCVVOnFocus = () => {
    updateForm("cvv", cvv);
    setCvv(cvv);
  };

  const handleCVVOnBlur = () => {
    const tempMaskedCvv = maskCVV(cvv);
    setMaskedCvv(tempMaskedCvv);
    updateForm("cvv", tempMaskedCvv);
    dispatch(setCVV(cvv));
  };

  const handleCardHolderNameOnChange = (fieldValue: string) => {
    updateForm("cardholderName", fieldValue);
    setCardHolderName(fieldValue);

    resetUseCreditCardSaved();
  };

  const handleCardHolderNameOnBlur = () => {
    dispatch(setName(cardHolderdName));
  };

  const handleExpDateOnChange = (fieldValue: string) => {
    updateForm("expDate", fieldValue);
    setExpDate(fieldValue);

    resetUseCreditCardSaved();
  };

  const handleExpDateOnBlur = () => {
    dispatch(setExpiration(expDate));
  };

  const handleAutoBillOnChange = (fieldValue: boolean) => {
    updateForm("isAutoBilling", fieldValue);
    setAutoBill(fieldValue);
    dispatch(setEnableAutoPay(fieldValue));

    resetUseCreditCardSaved();
  };

  const handleSelectedPaymentInstrumentChange = (event: SelectChangeEvent) => {
    updateForm("usePaymentToken", true);
    updateForm("paymentInstrument", event.target.value);
  };

  useEffect(() => {
    if (swipeData?.cc_number && swipeData?.full_name && swipeData.exp_date) {
      if (
        swipeData.cc_number !== prevSwipeDataRef.current?.cc_number ||
        swipeData.full_name !== prevSwipeDataRef.current?.full_name ||
        swipeData.exp_date !== prevSwipeDataRef.current?.exp_date
      ) {
        dispatch(setNumber(swipeData.cc_number));
        setCreditCardNumber(swipeData.cc_number);
        updateForm("creditCardNumber", swipeData.cc_number);

        dispatch(setName(swipeData.full_name));
        setCardHolderName(swipeData.full_name);
        updateForm("cardholderName", swipeData.full_name);

        dispatch(setExpiration(expStringRemovesSlash(swipeData.exp_date)));
        setExpDate(expStringRemovesSlash(swipeData.exp_date));
        updateForm("expDate", expStringRemovesSlash(swipeData.exp_date));

        const ccType = creditCard(swipeData.cc_number)[0]?.type;

        const updatedMaskedNumber =
          ccType === "american-express" ? maskAmex(swipeData.cc_number) : maskStandard(swipeData.cc_number);

        setmaskedCreditCardNumber(updatedMaskedNumber);
        updateForm("creditCardNumber", updatedMaskedNumber);

        setShouldManuallyValidate(true);
        resetSwipeData();
      }
      prevSwipeDataRef.current = swipeData;
    }
  }, [swipeData, resetSwipeData, setCreditCardNumber, setCardHolderName, setExpDate, updateForm]);

  useEffect(() => {
    if (shouldManuallyValidate) {
      formik.validateForm();
      setShouldManuallyValidate(false);
    }
  }, [shouldManuallyValidate]);

  return (
    <Grid
      xs={12}
      item
      mt={"1rem"}
      component={"form"}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        formik.handleSubmit();
      }}
    >
      {
        useSavedPaymentInstrument &&
        <Grid item xs={12} mb={2}>
          <InputLabel htmlFor={"select-saved-credit-card"}>Select a Credit Card</InputLabel>
          <PMSSelect
            id={"payment-instrument"}
            name={"selectedPaymentInstrument"}
            value={values.paymentInstrument}
            changeHandler={handleSelectedPaymentInstrumentChange}
            error={inputError("selectedPaymentInstrument", touched, errors).error}
            helperText={inputError("selectedPaymentInstrument", touched, errors).helperText}
          >
            <option value={0} disabled>Select a Credit Card</option>
            {paymentInstruments?.map((paymentInstrument) => (
              <option key={paymentInstrument.id} value={paymentInstrument.id}>
                {`xxxx xxxx xxxx ${paymentInstrument.last_4_digits}`} {
                `Exp. ${paymentInstrument.exp_month}/${paymentInstrument.exp_year}`}
              </option>
            ))}
          </PMSSelect>

          <Grid container spacing={2} mt={1}>
            <Grid item xs={5} alignSelf={"center"}>
              <Divider />
            </Grid>
            <Grid item xs={2}>
              <Typography textAlign={"center"}>OR</Typography>
            </Grid>
            <Grid item xs={5} alignSelf={"center"}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
      }

      <Grid item xs={12} display={"flex"}
        gap={"1rem"}>
        <Grid item xs={10}>
          <InputLabel htmlFor={"cardholder-name"}>Cardholder Name</InputLabel>
          <TextField
            fullWidth
            placeholder={"Cardholder Name"}
            id={"cardholder-name"}
            inputProps={{ "data-testid": "cardholder-name" }}
            name={"cardholderName"}
            value={values.cardholderName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleCardHolderNameOnChange(e.target.value)}
            onBlur={() => handleCardHolderNameOnBlur()}
            error={inputError("cardholderName", touched, errors).error}
            helperText={inputError("cardholderName", touched, errors).helperText}
          />
        </Grid>

        <Grid item xs={2}>
          <InputLabel htmlFor={"cvv"}>CVV</InputLabel>
          <TextField
            fullWidth
            placeholder={"CVV"}
            id={"cvv"}
            data-mask-sensitive={true}
            inputProps={{
              "data-testid": "cvv",
              maxLength: creditCard(creditCardNumber)[0]?.type === "american-express" ? 4 : 3
            }}
            name={"cvv"}
            value={values.cvv}
            onFocus={() => handleCVVOnFocus()}
            onBlur={() => handleCVVOnBlur()}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleCVVOnChange(e.target.value)}
            error={inputError("cvv", touched, errors).error}
            helperText={inputError("cvv", touched, errors).helperText}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} mt={1}
        display={"flex"} justifyContent={"space-between"} gap={"1rem"}>
        <Grid item xs={10}>
          <InputLabel htmlFor={"credit-card-number"}>Credit Card Number</InputLabel>
          <TextField
            fullWidth
            placeholder={"Credit Card Number"}
            id={"credit-card-number"}
            data-mask-sensitive={true}
            inputProps={{
              "data-testid": "credit-card-number",
              maxLength: creditCard(creditCardNumber)[0]?.type === "american-express" ? 17 : 19
            }}
            name={"creditCardNumber"}
            InputProps={{
              startAdornment: (
                <InputAdornment position={"start"}>
                  <CreditCard />
                </InputAdornment>
              )
            }}
            value={values.creditCardNumber}
            onFocus={() => handleCCOnFocus()}
            onBlur={() => handleCCOnBlur()}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleNumberOnChange(e.target.value)}
            error={inputError("creditCardNumber", touched, errors).error}
            helperText={inputError("creditCardNumber", touched, errors).helperText}
          />
        </Grid>
        <Grid item xs={2}>
          <InputLabel htmlFor={"expDate"}>Exp Date</InputLabel>
          <MaskedExpirationDate
            id={"expDate"}
            dataTestId={"expDate"}
            fullWidth
            variant={"outlined"}
            name={"expDate"}
            type={"tel"}
            placeholder={"MM/YY"}
            onBlur={() => handleExpDateOnBlur()}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleExpDateOnChange(e.target.value)}
            value={values.expDate}
            error={inputError("expDate", touched, errors).error}
            helperText={inputError("expDate", touched, errors).helperText}
          />
        </Grid>
      </Grid>
      <Grid m={1}>
        <PaymentOptions
          isAutoBillingEnabled={values.isAutoBilling}
          isSaveAsPreferredMethod={values.isSaveAsPreferredMethod}
          handleAutoBillingChange={() => handleAutoBillOnChange(!values.isAutoBilling)}
          handlePreferredMethodChange={() => setFieldValue("isSaveAsPreferredMethod", !values.isSaveAsPreferredMethod)}
        />
      </Grid>

      {actionButtons || null}
    </Grid>
  );
};

export default CreditCardForm;
