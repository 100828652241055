import { GridAlignment, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Tooltip, Typography } from "@mui/material";
import React from "react";
import { Occupant } from "../../../../../models/Occupant";
import { formatDate } from "../../../../../utils/__dateAndTimeUtils__/formatDate/formatDate";
import PaymentStatus from "../../../../../components/ui/PaymentStatus/PaymentStatus";

const LedgerTableColumns = (occupant: Occupant | null): GridColDef[] => {
  const rightAlignment: GridAlignment = "right";
  const baseSet = [
    {
      field: "row.unit.unit_number",
      renderHeader: () => (
        <Tooltip title={"Unit"}>
          <Typography>Unit</Typography>
        </Tooltip>
      ),
      flex: 1,
      valueGetter: ({ row }: GridRenderCellParams) => row.unit.unit_number
    },
    {
      field: "occupant",
      renderHeader: () => (
        <Tooltip title={"Occupant"}>
          <Typography>Occupant</Typography>
        </Tooltip>
      ),
      flex: 1,
      valueGetter: () => `${occupant?.first_name} ${occupant?.last_name}`
    },
    {
      field: "monthly_rate",
      renderHeader: () => (
        <Tooltip title={"Deal Rate"}>
          <Typography>Deal Rate</Typography>
        </Tooltip>
      ),
      flex: 1
    },
    {
      field: "due_date",
      renderHeader: () => (
        <Tooltip title={"Due Date"}>
          <Typography>Due Date</Typography>
        </Tooltip>
      ),
      valueGetter: ({ row }: GridRenderCellParams) => formatDate(row.due_date),
      flex: 1
    },
    {
      field: "delinquent_days",
      renderHeader: () => (
        <Tooltip title={"Status"}>
          <Typography>Status</Typography>
        </Tooltip>
      ),
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <PaymentStatus
            currentBalance={parseFloat(row.current_balance)}
            delinquentDays={parseFloat(row.delinquent_days ?? 0)}
          />
        );
      }
    },
    {
      field: "current_balance",
      renderHeader: () => (
        <Tooltip title={"Total Due"}>
          <Typography>Total Due</Typography>
        </Tooltip>
      ),
      valueGetter: ({ row }: GridRenderCellParams) => `$ ${Number(row.current_balance).toFixed(2)}`,
      align: rightAlignment
    }
  ];

  return baseSet;
};

export default LedgerTableColumns;
