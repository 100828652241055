import Grid from "@mui/material/Grid";
import React from "react";
import { Typography } from "@mui/material";
import centsToDollars from "src/utils/centsToDollars/centsToDollars";
import renderSummaryLineItem from "src/utils/RenderSummaryLineItem/RenderSummaryLineItem";
import { selectPaymentSummarySlice } from "src/store/reducers/paymentSummarySlice/paymentSummarySlice";
import { useAppSelector } from "src/store/hooks";

const RequiredLineItems: React.FC = () => {
  const {
    currentBalanceSubtotal,
    currentBalanceTaxTotal,
    currentBalanceTotal,
    projectedPrepaidBalanceSubtotal,
    projectedPrepaidBalanceTaxTotal,
    projectedPrepaidBalanceTotal,
    grandTotal,
    requestedCustomPayment,
    remainingCurrentBalanceTotal,
    remainingCurrentAndRequestedProjectedPrepaidBalanceTotal,
    requiredGrandTotal
  } = useAppSelector(selectPaymentSummarySlice);

  const shouldDisplayInfo = [
    currentBalanceTotal,
    projectedPrepaidBalanceTotal,
    grandTotal,
    requestedCustomPayment,
    remainingCurrentAndRequestedProjectedPrepaidBalanceTotal,
    requiredGrandTotal
  ].some(value => value !== 0 && value !== null);

  return shouldDisplayInfo
    ? (
      <Grid container p={1}>
        {/* Current Balance */}
        {currentBalanceTotal !== 0 && (
        <>
          {renderSummaryLineItem("Current Balance", centsToDollars(currentBalanceTotal))}
          {renderSummaryLineItem("Subtotal (see Charges above)", centsToDollars(currentBalanceSubtotal), true)}
          {renderSummaryLineItem("Tax Total", centsToDollars(currentBalanceTaxTotal), true)}
        </>
        )}

        {/* Remaining Current Balance Total */}
        {renderSummaryLineItem("Remaining Current Balance Total", centsToDollars(remainingCurrentBalanceTotal, true))}

        {/* Projected Prepaid Balance */}
        {projectedPrepaidBalanceTotal !== 0 && (
          <>
            {renderSummaryLineItem("Projected Prepaid Balance", centsToDollars(projectedPrepaidBalanceTotal, true))}
            {renderSummaryLineItem(
              "Subtotal (see Charges above)",
              centsToDollars(projectedPrepaidBalanceSubtotal, true),
              true
            )}
            {renderSummaryLineItem(
              "Tax Total",
              centsToDollars(projectedPrepaidBalanceTaxTotal, true),
              true
            )}
          </>
        )}

        {/* Grand Total */}
        {grandTotal !== 0 && (
          <>
            {renderSummaryLineItem("Grand Total", centsToDollars(grandTotal, true))}
          </>
        )}
        {/* Custom Payment */}
        {renderSummaryLineItem("Custom Payment", centsToDollars(requestedCustomPayment, true))}
      </Grid>
      )
    : <Grid container item xs={12}
        justifyContent={"center"} p={1}>
      <Typography align={"center"}>No information to show</Typography>
    </Grid>
  ;
};

export default RequiredLineItems;
