import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const generateLeasePackage =
createAsyncThunk("generateLeasePackage", ({ selectedDocuments, email, resend = false }:
   {selectedDocuments: any, email?: string | null, resend?: boolean}, { dispatch, getState, rejectWithValue }) => {
  const state = getState() as RootState;
  const facilityId = state.selectedUnit?.selectedUnit?.facility_id || state.selectedFacility?.selectedFacility?.id;
  const ledgerId = state.moveIn.moveInConfirmation?.ledger.id ||
  state.eSignStatus.selectedESignDocument?.unit?.ledger?.id;
  const transactionId = state.moveIn.moveInConfirmation?.transaction.id;
  const documents = selectedDocuments.map((document: any) => document.id);

  const body = {
    documents,
    ledger_id: ledgerId,
    transaction_id: transactionId,
    email,
    resend_existing_document: resend,
    esign_document_id: state.eSignStatus.selectedESignDocument?.id || null
  };
  return axios
    .post(`/api/v1/esign-documents/${facilityId}/generate-lease-package`, body)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      let errorMessage: string;
      if (err.response.status === 422) {
        errorMessage = format422(err.response.data.errors);

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      }

      errorMessage = "There was an error generating the Lease Package";

      if (err && err.response && err.response.status && err.response.status === 400) {
        errorMessage += `: ${err.response.data.detail}`;
      }

      dispatch(
        showSnackbar({
          message: errorMessage,
          variant: "error"
        })
      );

      return rejectWithValue(errorMessage);
    });
});
