import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store/rootStore";

interface SpeedeRentSliceState {
  isSpeedeRentProcess: boolean;
}

export const initialState: SpeedeRentSliceState = {
  isSpeedeRentProcess: false
};

export const speedeRentSlice = createSlice({
  name: "speedeRent",
  initialState,
  reducers: {
    setIsSpeedeRentProcess: (state, action: PayloadAction<boolean>) => {
      state.isSpeedeRentProcess = action.payload;
    },
    resetSpeedeRentState: (state) => {
      state.isSpeedeRentProcess = false;
    }
  }
});

export const selectIsSpeedeRentProcess = (state: RootState) =>
  state.speedeRent.isSpeedeRentProcess;

export const { setIsSpeedeRentProcess, resetSpeedeRentState } =
  speedeRentSlice.actions;

export default speedeRentSlice.reducer;
