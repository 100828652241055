import { Card, CardActions, CardContent, Collapse, Grid, IconButton, IconButtonProps, Typography } from "@mui/material";
import React, { FC, ReactNode, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import useStyles from "./Collapsible.styles";

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
  }

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest
  })
}));

  interface CollapsibleProps {
    children?: ReactNode
    title: string
  }

const Collapsible: FC<CollapsibleProps> = ({ children, title }) => {
  const [expanded, setExpanded] = useState(false);

  const { classes } = useStyles();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card elevation={0} className={classes.cardColor} >
      <CardActions disableSpacing>
        <Grid container alignItems={"center"}>
          <Grid item xs={10}>
            <Typography pl={5} variant={"h6"}>
              {title}
            </Typography>
          </Grid>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label={"Show more"}
            >
            <ExpandMoreIcon className={classes.arrowColor} />
          </ExpandMore>
        </Grid>
      </CardActions>
      <Collapse in={expanded} timeout={"auto"} unmountOnExit>
        <CardContent style={{ boxShadow: "none" }} className={classes.innerCardBackground}>
          {children}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default Collapsible;
