import {
  Button,
  Grid,
  IconButton,
  TextField
} from "@mui/material";
import React, { FC, ReactNode } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  showConfirmPassword,
  showCurrentPassword,
  showNewPassword,
  userSelector
} from "src/store/reducers/userSlice/userSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import useStyles from "./UpdateSettingsForm.styles";

interface UserSettingsProps { }

const UpdateSettingsForm: FC<UserSettingsProps> = () => {
  const { classes } = useStyles();
  const user = useAppSelector(userSelector);
  const dispatch = useAppDispatch();

  const currentPasswordIcon = user.showCurrentPassword
    ? <VisibilityOff data-testid={"current-password-off-icon"} />
    : <Visibility data-testid={"current-password-on-icon"} />;
  const newPasswordIcon = user.showNewPassword
    ? <VisibilityOff data-testid={"new-password-off-icon"} />
    : <Visibility data-testid={"new-password-on-icon"} />;
  const confirmPasswordIcon = user.showConfirmPassword
    ? <VisibilityOff data-testid={"confirm-password-off-icon"} />
    : <Visibility data-testid={"confirm-password-on-icon"} />;

  const passwordButton = (label: string, toggleAction: Function, icon: ReactNode) => (
    <IconButton
      aria-label={`toggle ${label} password visibility`}
      data-testid={`toggle-${label}-password-button`}
      onClick={() => dispatch(toggleAction())}
      size={"large"}>
      {icon}
    </IconButton>
  );

  return (
    <form className={classes.form}>
      <Grid
        container
        item
        mt={3}
        direction={"column"}
      >
        <Grid
          item
          container
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Grid
            item
            container
            direction={"column"}
            xs={6}
            className={classes.firstName}
          >
            <label htmlFor={"first-name-input"}>First Name</label>
            <TextField
              id={"first-name-input"}
              aria-describedby={"first-name-text"}
              margin={"dense"}
              variant={"outlined"}
              autoComplete={"first-name"}
              inputProps={{ "data-testid": "first-name-input" }}
            />
          </Grid>
          <Grid
            item
            container
            direction={"column"}
            xs={6}
          >
            <label htmlFor={"last-name-input"}>Last Name</label>
            <TextField
              id={"last-name-input"}
              aria-describedby={"last-name-text"}
              margin={"dense"}
              variant={"outlined"}
              autoComplete={"last-name"}
              inputProps={{ "data-testid": "last-name-input" }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction={"column"}
          mt={2}
        >
          <label htmlFor={"username-input"}>Username</label>
          <TextField
            disabled
            id={"username-input"}
            aria-describedby={"username-text"}
            margin={"dense"}
            variant={"outlined"}
            autoComplete={"username"}
            inputProps={{ "data-testid": "username-input" }}
            helperText={"Username cannot be changed"}
          />
        </Grid>
        <Grid
          item
          container
          direction={"column"}
          mt={2}
        >
          <label htmlFor={"e-mail-input"}>E-mail Address</label>
          <TextField
            type={"e-mail"}
            id={"e-mail-input"}
            aria-describedby={"e-mail-text"}
            margin={"dense"}
            variant={"outlined"}
            autoComplete={"e-mail"}
            inputProps={{ "data-testid": "e-mail-input" }}
          />
        </Grid>
        <Grid
          item
          container
          direction={"column"}
          mt={2}
        >
          <label htmlFor={"current-password-input"}>Current Password</label>
          <TextField
            type={user.showCurrentPassword ? "text" : "password"}
            id={"current-password-input"}
            aria-describedby={"current-password-text"}
            margin={"dense"}
            variant={"outlined"}
            autoComplete={"current-password"}
            inputProps={{ "data-testid": "current-password-input" }}
            InputProps={{ endAdornment: passwordButton("current", showCurrentPassword, currentPasswordIcon) }}
          />
        </Grid>
        <Grid
          item
          container
          direction={"column"}
          mt={2}
        >
          <label htmlFor={"new-password-input"}>New Password</label>
          <TextField
            type={user.showNewPassword ? "text" : "password"}
            id={"new-password-input"}
            aria-describedby={"new-password-text"}
            margin={"dense"}
            variant={"outlined"}
            autoComplete={"new-password"}
            inputProps={{ "data-testid": "new-password-input" }}
            InputProps={{ endAdornment: passwordButton("new", showNewPassword, newPasswordIcon) }}
          />
        </Grid>
        <Grid
          item
          container
          direction={"column"}
          my={2}
        >
          <label htmlFor={"confirm-password"}>Confirm Password</label>
          <TextField
            type={user.showConfirmPassword ? "text" : "password"}
            id={"confirm-password-input"}
            aria-describedby={"confirm-password-text"}
            margin={"dense"}
            variant={"outlined"}
            autoComplete={"confirm-password"}
            inputProps={{ "data-testid": "confirm-password-input" }}
            InputProps={{ endAdornment: passwordButton("confirm", showConfirmPassword, confirmPasswordIcon) }}
          />
        </Grid>
        <Button
          style={{
            textTransform: "capitalize",
            background: "#1977d2",
            padding: "0.75rem 0",
            marginBottom: ".5rem",
            color: "white"
          }}
          variant={"contained"}
        >
          Update Profile
        </Button>
      </Grid>
    </form>
  );
};

export default UpdateSettingsForm;
