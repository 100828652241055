import React, { ReactElement } from "react";
import { selectTransactions, selectTransactionsLoading } from "src/store/reducers/transactionSlice/transactionSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import EmptyTable from "../../../../components/ui/PMSTable/EmptyTable/EmptyTable";
import PMSTable from "../../../../components/ui/PMSTable/PMSTable";
import SummaryOfSalesTableColumns from "./ManageOccupantsTableColumns/SummaryOfSalesTableColumns";
import { getTransactions } from "src/store/thunks/transactions/getTransaction/getTransactions";
import { searchOccupants } from "../../../../store/thunks/occupant/search/searchOccupants";
import { selectOccupantsSearchValue } from "src/store/reducers/occupantsSlice/occupantsSlice";

const SummaryOfSalesTable: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();

  const transactions = useAppSelector(selectTransactions);
  const transactionsLoading = useAppSelector(selectTransactionsLoading);
  const searchValue = useAppSelector(selectOccupantsSearchValue);

  const handlePaginationUpdate = () => {
    if (!searchValue.length) {
      dispatch(getTransactions());
      return;
    }

    // TODO implement search
    dispatch(searchOccupants({ searchValue }));
  };

  const emptyTable = () => (
    <EmptyTable
      collectionNotFound={"transactions"}
      value={"transaction"}
      addLink={"/merchandise/point-of-sales"}
    />
  );

  return (
    <PMSTable
      data-testid={"manage-transactions-table"}
      columns={SummaryOfSalesTableColumns()}
      rows={transactions}
      emptyTableDisplay={emptyTable}
      onPaginationUpdate={handlePaginationUpdate}
      loading={transactionsLoading}
      sortingMode={"client"}
    />
  );
};

export default SummaryOfSalesTable;
