import React, { ReactElement, useEffect } from "react";
import {
  resetLedgersSlice,
  setSelectedLedger,
  setSelectedLedgerToTransfer
} from "src/store/reducers/ledgersSlice/ledgersSlice";
import { resetMoveOutSlice, setMoveOutLedger } from "src/store/reducers/moveOutSlice/moveOutSlice";
import { resetOpenExpansionRows, resetTablesSlice } from "../../../../store/reducers/tablesSlice/tablesSlice";
import { resetSelectedOccupantSlice, selectSelectedOccupant, selectSelectedOccupantReservations }
  from "../../../../store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

import { Ledger } from "src/models/Ledger";
import PMSAdvisory from "../../../../components/ui/PMSAdvisory/PMSAdvisory";
import { Paper } from "@mui/material";
import SelectOccupant from "../../../../components/stepper/SelectOccupant/SelectOccupant";
import StepLayout from "../../../../layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "../../../../layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import { TRANSFER_UNIT_PATHS } from "src/routes/TransferUnitRoutes.";
import { getActiveLedgers } from "src/utils/getActiveLedgers/getActiveLedgers";
import { handleReset } from "../TransferUnit";
import { resetSelectedPromotionSlice } from "src/store/reducers/selectedPromotionSlice/selectedPromotionSlice";
import { resetSelectedUnitSlice } from "../../../../store/reducers/selectedUnitSlice/selectedUnitSlice";
import { setIsTransfer } from "src/store/reducers/transferUnitSlice/transferUnitSlice";
import { setTargetPath } from "src/store/reducers/navigationSlice/navigationSlice";
import { useNavigate } from "react-router";

const SelectOccupantForTransferUnit: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const occupant = useAppSelector(selectSelectedOccupant);

  const advisoryText = (
    <>
      <p>
        Search for and select an existing occupant to transfer unit.
      </p>
      <p>Once an occupant has been selected, you may proceed to the next step.</p>
    </>
  );

  const handleGoBack = () => navigate(TRANSFER_UNIT_PATHS.getTransferUnitStarted);

  const handleGoForward = () => {
    navigate(TRANSFER_UNIT_PATHS.selectUnitForMoveOutTransferUnit);
    dispatch(setTargetPath(TRANSFER_UNIT_PATHS.selectOccupantForTransferUnit));
  };

  useEffect(() => {
    dispatch(resetSelectedOccupantSlice());
    dispatch(resetSelectedUnitSlice());
    dispatch(resetMoveOutSlice());
    dispatch(resetLedgersSlice());
    dispatch(resetOpenExpansionRows());
    dispatch(resetSelectedPromotionSlice());
    dispatch(setIsTransfer(true));
  }, []);

  return (
    <>
      <PMSAdvisory title={"Search by Occupant"} advisoryText={advisoryText} />
      <Paper elevation={1}>
        <StepLayout
          buttons={
            <StepperButtons
              data-testid={"select-transfer-unit-in-occupant-buttons"}
              stepperId={"select-occupant"}
              forwardActionText={"Next"}
              backAction={handleGoBack}
            />}
        >
          <SelectOccupant
            disableAddOccupant
            onlyWithActiveLedgers
            data-testid={"select-transfer-unit-occupant-form"}
            onSubmitHandler={handleGoForward}
          />
        </StepLayout>
      </Paper>
    </>
  );
};

export default SelectOccupantForTransferUnit;
