import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  category: Yup.number()
    .required("Account Category is required")
    .min(1, "Please select an Account Category")
    .max(5),
  account_name: Yup.string().required("Account Name is required"),
  gl_number: Yup.string().required("Account number is required")
});

export default validationSchema;
