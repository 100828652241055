import { Grid, IconButton, Paper } from "@mui/material";

import React from "react";
import { ReactComponent as SIPIcon } from "src/assets/icons/SIP.svg";

const SIPButton = ({ disabled, onClick }: { disabled: boolean; onClick: () => void }) => {
  return (
    <Grid>
      <IconButton disabled={disabled} onClick={onClick}>
        <SIPIcon style={{ color: "black" }} opacity={disabled ? "50%" : "100%"} />
      </IconButton>
    </Grid>
  );
};

export default SIPButton;
