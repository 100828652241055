import { Alert, AlertTitle, Grid, InputLabel, Paper, TextField, Typography } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import {
  resetMoveOutCostSlice,
  selectMoveOutCostFinalBalance,
  selectMoveOutcostApplyToUnit
} from "src/store/reducers/moveOutCostSlice/moveOutCostSlice";
import {
  resetMoveOutSlice,
  selectMoveOut,
  selectMoveOutLoading,
  setBuyerName,
  setMoveOutMarkRamainderOwedAsBadDebt,
  setMoveOutTakePaymentForRemainder,
  setMoveOutViaAuction
} from "src/store/reducers/moveOutSlice/moveOutSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

import CompletedMoveOutModal from "./CompletedMoveOutModal/CompletedMoveOutModal";
import DealDetails from "src/layouts/stepper/ReviewLayout/DealDetails/DealDetails";
import MoveOutConfirmationModal from "./ConfirmationModal/MoveOutConfirmationModal";
import PMSCheckbox from "src/components/ui/PMSCheckbox/PMSCheckbox";
import PaymentEntry from "../../CreateMoveIn/ReviewMoveIn/PaymentEntry/PaymentEntry";
import PaymentMethod from "../../CreateMoveIn/ReviewMoveIn/PaymentMethod/PaymentMethod";
import ReviewActionButtons from "src/layouts/stepper/ReviewLayout/ReviewActionButtons/ReviewActionButtons";
import ReviewHeader from "src/layouts/stepper/ReviewLayout/ReviewHeader/ReviewHeader";
import ReviewLayout from "src/layouts/stepper/ReviewLayout/ReviewLayout";
import ReviewMoveOutValidation from "./ReviewMoveOutValidation";
import SendEmailModal from "../../CreateMoveIn/GenerateLeasePackageModal/SendEmailModal/SendEmailModal";
import TemporaryFutureMoveOutModal from "./TemporaryFutureMoveOutModal/TermporaryFutureMoveOutModal";
import { createMoveOut } from "src/store/thunks/moveOut/create/createMoveOut";
import { handleReset } from "../CreateMoveOut";
import { inputError } from "src/utils/showInputError/showInputError";
import moment from "moment";
import { resetOccupantSlice } from "src/store/reducers/occupantSlice/occupantSlice";
import { resetSelectedOccupantSlice } from "src/store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { selectMoveOutMarkDebtSetting }
  from "src/store/reducers/globalSettingInformationSlice/globalSettingInformationSlice";
import { selectSendEmailModalOpened } from "src/store/reducers/moveInSlice/moveInSlice";
import { selectTargetPath } from "src/store/reducers/navigationSlice/navigationSlice";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import useStyles from "../styles/MoveOut.styles";

const ReviewMoveOut: React.FC = (): ReactElement => {
  const loading = useAppSelector(selectMoveOutLoading);
  const redirect = useAppSelector(selectTargetPath);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const formId = "move-out-payment-form";
  const { classes: moveOutClasses } = useStyles();
  const [moveOutModalOpen, setMoveOutModalOpen] = useState(false);
  const [temporaryFutureMoveOutModalOpen, setTemporaryFutureMoveOutModalOpen] = useState(false);
  const [completedModalOpen, setCompletedModalOpen] = useState(false);
  const applyToUnit = useAppSelector(selectMoveOutcostApplyToUnit);
  const sendEmailModalOpened = useAppSelector(selectSendEmailModalOpened);
  const isApplyToOtherUnit = !!applyToUnit?.length;
  const moveOutInfo = useAppSelector(selectMoveOut);
  const moveOutFinalBalance = useAppSelector(selectMoveOutCostFinalBalance);
  const moveOutMarkDebtAsBadSetting = useAppSelector(selectMoveOutMarkDebtSetting);
  const isMarkAsBadDebt = (parseFloat(moveOutFinalBalance) > 0 &&
      Number(moveOutMarkDebtAsBadSetting?.value) === 1);

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      takePaymentForRemainder: false,
      markAsBadDebt: isMarkAsBadDebt,
      moveOutViaAuction: false,
      buyerName: ""
    },
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: ReviewMoveOutValidation,
    onSubmit: () => {
      setMoveOutModalOpen(true);
    }
  });

  const clearBuyerName = () => {
    setFieldValue("buyerName", "");
    dispatch(setBuyerName(""));
  };

  useEffect(() => {
    if (values.takePaymentForRemainder) {
      setFieldValue("takePaymentForRemainder", true);
      dispatch(setMoveOutTakePaymentForRemainder(true));
    }
  }, [values.takePaymentForRemainder]);

  useEffect(() => {
    setFieldValue("markAsBadDebt", isMarkAsBadDebt);
    dispatch(setMoveOutMarkRamainderOwedAsBadDebt(isMarkAsBadDebt));
  }, [moveOutFinalBalance]);

  const handleTakePaymentForRemainder = () => {
    dispatch(setMoveOutTakePaymentForRemainder(!values.takePaymentForRemainder));
    setFieldValue("takePaymentForRemainder", !values.takePaymentForRemainder);
  };

  const handleMarkAsBadDebt = (value: boolean) => {
    setFieldValue("markAsBadDebt", !values.markAsBadDebt);
    dispatch(setMoveOutMarkRamainderOwedAsBadDebt(!values.markAsBadDebt));

    //reset buyer name and move out via action when is unchecked
    if (value === false) {
      setFieldValue("moveOutViaAuction", false);
      dispatch(setMoveOutViaAuction(false));

      clearBuyerName();
    }
  };

  //get the move out date and check if its in the future with momentJS
  const isMoveOutFuture = moment(moveOutInfo?.moveOutDate).isAfter(moment());

  const handleMoveOutViaAuction = (value: boolean) => {
    setFieldValue("moveOutViaAuction", !values.moveOutViaAuction);
    dispatch(setMoveOutViaAuction(!values.moveOutViaAuction));

    //reset buyer name when is unchecked
    if (value === false) {
      clearBuyerName();
    }
  };

  const handleBuyerName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue("buyerName", e.target.value);
    dispatch(setBuyerName(e.target.value));
  };

  const handleGoBack = () => {
    navigate("/occupants/create-move-out/process-move-out");
  };

  const handleCancel = () => {
    if (redirect && redirect.includes("manage-deals")) {
      return navigate(redirect);
    }

    navigate("/occupants/create-move-out");
  };

  const handleModalSubmit = async() => {
    dispatch(createMoveOut()).then((resp) => {
      if (!resp.type.includes("rejected")) {
        setMoveOutModalOpen(false);

        if (resp.payload?.message) {
          return setTemporaryFutureMoveOutModalOpen(true);
        }

        setCompletedModalOpen(true);
      }
    });
  };

  const handleCloseModal = () => {
    setCompletedModalOpen(false);
    navigate("/occupants/create-move-out");

    dispatch(resetSelectedOccupantSlice());
    dispatch(resetOccupantSlice());
    dispatch(resetMoveOutSlice());
    dispatch(resetMoveOutCostSlice());

    handleReset(dispatch);
  };

  const handleCloseTemporaryFutureMoveOutModal = () => {
    setTemporaryFutureMoveOutModalOpen(false);
    navigate("/occupants/create-move-out");

    dispatch(resetSelectedOccupantSlice());
    dispatch(resetOccupantSlice());
    dispatch(resetMoveOutSlice());
    dispatch(resetMoveOutCostSlice());

    handleReset(dispatch);
  };

  return (
    <ReviewLayout
      header={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ReviewHeader
              headerText={"Complete Move Out & Payment"}
              backMethod={handleGoBack}
              goBackText={"Process Move-Out"}
            />
          </Grid>
          {isMoveOutFuture
            ? (
              <Grid item xs={9} alignSelf={"center"}>
                <Alert severity={"error"}>
                  <AlertTitle>Important</AlertTitle>
                  <Typography variant={"body2"}>
                    This move out is scheduled for the future. A note will be created for a manual move out reminder and
                    all move out options will be discarded.
                  </Typography>
                </Alert>
              </Grid>
              )
            : null}
        </Grid>
      }
      actionButtons={
        <ReviewActionButtons ctaText={"Move Out"} loading={loading} formId={formId}
          cancelMethod={handleCancel} />
      }
    >
      <Paper elevation={1}>
        <DealDetails isMoveOut />
        <Grid container item spacing={1}
          justifyContent={"space-between"} py={1} px={2}
          xs={12} lg={"auto"} mb={2}>
          <Grid container item spacing={1}
            direction={"column"} xs={12} lg={3}>
            <Grid md={12} xs={12} item>
              <InputLabel htmlFor={"refunds"} className={moveOutClasses.headerCell}>
                Auctions & Bad Debt
              </InputLabel>

              <PMSCheckbox
                changeHandler={handleTakePaymentForRemainder}
                isChecked={values.takePaymentForRemainder}
                label={"Take partial payment to vacate"}
              />
              <PMSCheckbox
                label={"Mark remainder owed as bad debt"}
                isChecked={values.markAsBadDebt}
                changeHandler={(event: React.ChangeEvent<any>) => handleMarkAsBadDebt(event.target.checked)}
                disabled={isMarkAsBadDebt}
              />
              {values.markAsBadDebt && (
                <PMSCheckbox
                  label={"Move Out via auction"}
                  isChecked={values.moveOutViaAuction}
                  changeHandler={(event: React.ChangeEvent<any>) => handleMoveOutViaAuction(event.target.checked)}
                />
              )}
              {values.moveOutViaAuction && values.markAsBadDebt && (
                <>
                  <InputLabel htmlFor={"buyerName"}>Buyer Name</InputLabel>
                  <TextField
                    fullWidth
                    placeholder={"- Buyer Name -"}
                    id={"buyer-name"}
                    name={"buyerName"}
                    value={values.buyerName}
                    onChange={handleBuyerName}
                    error={inputError("buyerName", touched, errors).error}
                    helperText={inputError("buyerName", touched, errors).helperText}
                  />
                </>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} lg={3}>
            <Grid md={12} xs={12} item>
              <InputLabel htmlFor={"refunds"} className={moveOutClasses.headerCell}>
                Payment Method
              </InputLabel>
              <PaymentMethod disabled={isApplyToOtherUnit} />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <PaymentEntry formId={formId} handleSubmit={handleSubmit} />
          </Grid>
        </Grid>
      </Paper>
      <MoveOutConfirmationModal
        open={moveOutModalOpen}
        onModalClose={() => setMoveOutModalOpen(false)}
        onModalSubmit={handleModalSubmit}
        loading={loading}
      />
      <TemporaryFutureMoveOutModal
        open={temporaryFutureMoveOutModalOpen}
        onModalClose={() => handleCloseTemporaryFutureMoveOutModal()}
      />
      <CompletedMoveOutModal
        onModalClose={handleCloseModal}
        onModalSubmit={() => {}}
        open={completedModalOpen}
        setCompletedModalOpen={setCompletedModalOpen}
        formId={formId}
      />
      {sendEmailModalOpened
        ? (
          <SendEmailModal setCompletedModalOpen={setCompletedModalOpen} isFromMoveOut={true} />
          )
        : null}
    </ReviewLayout>
  );
};
export default ReviewMoveOut;
