import { Box, Button, Typography } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import React, { ReactElement, useEffect } from "react";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";
import {
  resetPromotionInformationSlice,
  selectPromotionInformationUpdating,
  selectPromotionSwitches,
  selectUpdatePromotionInformationModal,
  setUpdatePromotionInformationModal
} from "../../../store/reducers/promotionInformationSlice/promotionInformationSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { AppDispatch } from "../../../store/rootStore";
import AppModal from "../../../components/ui/AppModal/AppModal";
import { Breadcrumb } from "../../../models/Breadcrumb";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import PromotionForm from "../PromotionForm/PromotionForm";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import clsx from "clsx";
import { getPromotion } from "../../../store/thunks/promotion/get/getPromotion";
import { resetPromotionSlice } from "../../../store/reducers/promotionSlice/promotionSlice";
import { updatePromotion } from "../../../store/thunks/promotion/update/updatePromotion";
import { useParams } from "react-router";
import useStyles from "../EditPromotion/EditPromotion.styles";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Promotions"
  },
  {
    name: "Edit Promotion",
    bold: true
  }
];

export const handleResets = (dispatch: AppDispatch) => {
  dispatch(resetPromotionInformationSlice());
  dispatch(resetPromotionSlice());
};

const EditPromotion: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const updating = useAppSelector(selectPromotionInformationUpdating);
  const modalOpen = useAppSelector(selectUpdatePromotionInformationModal);
  const permissions = useAppSelector(selectFacilityPermissions);
  const promotionsPermissions = createPermissions(permissions, "promotions");
  const promotionSwitches = useAppSelector(selectPromotionSwitches);
  const footerButtons = (
    <>
      <Button
        variant={"text"}
        className={clsx(classes.cancelButton, classes.buttonBase)}
        size={"large"}
        component={NavLink}
        to={"/promotions"}
      >
        Cancel
      </Button>
      <Button
        form={"promotion-form"}
        variant={"contained"}
        className={clsx(classes.saveButton, classes.buttonBase)}
        size={"large"}
        type={"submit"}
      >
        Save & Continue
      </Button>
    </>
  );

  const toggleModal = (modalOpen: boolean) => {
    dispatch(setUpdatePromotionInformationModal(modalOpen));
  };

  const handleUpdate = () => {
    dispatch(updatePromotion({ promotionId: params.id!, switchs: promotionSwitches })).then((resp) => {
      if (!resp.type.includes("rejected")) {
        navigate("/promotions");
        dispatch(resetPromotionInformationSlice());
      }
    });
  };

  useEffect(() => {
    dispatch(getPromotion(params.id!));
    return () => {
      handleResets(dispatch);
    };
  }, []);

  const pageHeader = <PageHeader title={"Edit Promotion"} breadcrumbs={breadcrumbs} />;

  return (
    promotionsPermissions.update
      ? (<ViewWrapper pageHeader={pageHeader}>
        <PromotionForm
          data-testid={"edit-promotion-form"}
          submitHandler={() => toggleModal(true)}
          isEdit
        />
        <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
          {footerButtons}
        </Box>
        <AppModal
          data-testid={"edit-promotion-advisory-modal"}
          open={modalOpen}
          confirmAction={handleUpdate}
          confirmActionLoading={updating}
          cancelAction={() => toggleModal(false)}
        >
          <Typography variant={"h6"} gutterBottom>
            Are you sure?
          </Typography>
          <Typography gutterBottom>
            Saving these edits will apply them to the current promotion. All active units with this promotion will be
            affected accordingly
          </Typography>
        </AppModal>
      </ViewWrapper>)
      : <ViewWrapper pageHeader={pageHeader}>
        <h1>You do not have permission to view this page.</h1>
      </ViewWrapper>
  );
};

export default EditPromotion;
