import React from "react";
import { Route } from "react-router";
import CreateFee from "../pages/Fees/CreateFee/CreateFee";
import EditFee from "../pages/Fees/EditFee/EditFee";
import Fees from "../pages/Fees/Fees";

export const groupName = "fees";

export const FeesRoutes = (
  <>
    <Route path={groupName} element={<Fees />} />
    <Route path={`${groupName}/:id/edit`} element={<EditFee />} />
    <Route path={`${groupName}/create`} element={<CreateFee />} />
  </>
);

export default FeesRoutes;
