import React, { ReactElement, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import PMSAdvisory from "../../../../components/ui/PMSAdvisory/PMSAdvisory";
import { Paper } from "@mui/material";
import SelectOccupant from "../../../../components/stepper/SelectOccupant/SelectOccupant";
import StepLayout from "../../../../layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "../../../../layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import { resetMoveInSlice } from "../../../../store/reducers/moveInSlice/moveInSlice";
import { resetSelectedUnitSlice } from "../../../../store/reducers/selectedUnitSlice/selectedUnitSlice";
import {
  selectECFirstName
} from "../../../../store/reducers/emergencyContactInformationSlice/emergencyContactInformationSlice";
import {
  resetSelectedOccupantSlice,
  selectSelectedOccupant,
  selectSelectedOccupantReservations
} from "../../../../store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { resetTablesSlice } from "../../../../store/reducers/tablesSlice/tablesSlice";
import { useNavigate } from "react-router";
import { resetDealsSlice } from "src/store/reducers/dealsSlice/dealsSlice";
import { useReservationsForOccupantQuery } from "src/api/endpoints/deals";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";

const SelectOccupantForMoveIn: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const reservations = useAppSelector(selectSelectedOccupantReservations);
  const selectedOccupant = useAppSelector(selectSelectedOccupant);
  const emergencyContact = useAppSelector(selectECFirstName);
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const { data: reservationsWithSameOccupantInfo, isLoading } = useReservationsForOccupantQuery(
    {
      email: selectedOccupant?.email!,
      firstName: selectedOccupant?.first_name!,
      lastName: selectedOccupant?.last_name!,
      middleName: selectedOccupant?.middle_name,
      facilityId: String(selectedFacility.id)
    },
    { skip: !selectedOccupant }
  );
  const advisoryText = (
    <>
      <p>
        Search for and select an existing occupant to move in. If you would like to move in a new occupant, you can add
        them to the system via the “Add New Occupant” button.
      </p>
      <p>Once an occupant has been selected, you may proceed to the next step.</p>
    </>
  );

  const handleGoBack = () => navigate("/occupants/create-move-in/");

  const handleGoForward = () => {
    if (emergencyContact) {
      return navigate("/occupants/create-move-in/occupant-name-and-location");
    }

    if (reservations.length || reservationsWithSameOccupantInfo?.length) {
      return navigate("/occupants/create-move-in/select-reservation");
    }

    navigate("/occupants/create-move-in/select-unit");
  };

  useEffect(() => {
    dispatch(resetSelectedOccupantSlice());
    return () => {
      dispatch(resetSelectedUnitSlice());
      dispatch(resetMoveInSlice());
      dispatch(resetTablesSlice());
      dispatch(resetDealsSlice());
    };
  }, []);

  return (
    <>
      <PMSAdvisory title={"Select an Occupant"} advisoryText={advisoryText} />
      <Paper elevation={1}>
        <StepLayout
          buttons={
            <StepperButtons
              data-testid={"select-move-in-occupant-buttons"}
              stepperId={"select-occupant"}
              loading={isLoading}
              forwardActionText={"Next"}
              backAction={handleGoBack}
            />
          }
        >
          <SelectOccupant
            isMoveIn
            disablePagination
            data-testid={"select-move-in-occupant-form"}
            onSubmitHandler={handleGoForward}
          />
        </StepLayout>
      </Paper>
    </>
  );
};

export default SelectOccupantForMoveIn;
