import { Button, Grid, Modal, Paper, Typography } from "@mui/material";
import {
  selectDeal,
  selectReactivateDealModalOpen,
  setReactivateDealModalOpen,
  setStepEditDealToReactivate,
  setUnableToReactivateDealType,
  setUnableToReactivateModalOpen
} from "src/store/reducers/dealSlice/dealSlice";
import { selectDealsFilter, setDealsFilter } from "src/store/reducers/dealsSlice/dealsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Box } from "@mui/system";
import { Deal } from "src/models/Deal";
import { LoadingButton } from "@mui/lab";
import React from "react";
import { getAllActiveDeals } from "src/store/thunks/deals/getAllActive/getAllActiveDeals";
import { resetTablesSlice } from "src/store/reducers/tablesSlice/tablesSlice";
import { updateDeal } from "src/store/thunks/deals/update/updateDeal";
import useStyles from "./ReactivationModal.styles";

const ReactivationModal = ({ onSubmit }: {onSubmit?: Function}) => {
  const { classes } = useStyles();

  const deal = useAppSelector(selectDeal) as Deal;
  const dispatch = useAppDispatch();
  const modalOpened = useAppSelector(selectReactivateDealModalOpen);
  const dealsFilter = useAppSelector(selectDealsFilter);
  const handleClose = () => {
    dispatch(setReactivateDealModalOpen(false));
  };

  const handleSubmit = () => {
    const payload = {
      facility_id: deal.facility_id,
      product_type_id: deal.product_type_id,
      occupant_id: deal?.occupant_id,
      reservation_expires_at: deal.reservation_expires_at,
      ...(deal.promotion_id && { promotion_id: parseInt(deal.promotion_id) }),
      ...(deal.unit_id && { unit_id: parseInt(deal.unit_id) }),
      deal_type: (typeof deal.deal_type === "string")
        ? (deal.deal_type === "Reservation") ? 2 : 1
        : deal.deal_type as number,
      appointment_at: deal.appointment_at ?? "",
      needed_at: deal?.needed_at,
      contact_at: deal.contact_at ?? "",
      last_contact_at: deal.last_contact_at ?? "",
      quoted_price: parseFloat(deal.quoted_price),
      first_name: deal.first_name,
      middle_name: deal.middle_name!,
      last_name: deal.last_name,
      email: deal.email,
      phone: deal.phone,
      address: deal.address,
      address_two: deal.address_two!,
      city: deal.city,
      region: deal.region,
      postal_code: deal.postal_code,
      country: deal.country,
      referral_type: deal.referral_type,
      source: deal.source,
      cancelled_at: null
    };

    const unableToReactivateMessages = [
      "This Unit is linked to another Deal",
      "This Unit is unrentable",
      "This Unit is linked with a active Ledger",
      "There are no Units Available for this Product Type",
      "Product Type not found"
    ];

    if (deal.product_type_id === null) {
      dispatch(setReactivateDealModalOpen(false));
      dispatch(setStepEditDealToReactivate(1));
      dispatch(setUnableToReactivateModalOpen(true));
      dispatch(setUnableToReactivateDealType((deal.deal_type === 1 || deal.deal_type === "Lead")
        ? "LEAD"
        : "RESERVATION"));

      return;
    }

    dispatch(updateDeal({ payload, deal_id: deal?.id })).then((resp) => {
      if (!resp.type.includes("rejected")) {
        dispatch(resetTablesSlice());
        dispatch(getAllActiveDeals(dealsFilter));
        dispatch(setReactivateDealModalOpen(false));
      } else {
        dispatch(setReactivateDealModalOpen(false));
        if (unableToReactivateMessages.includes(resp.payload)) {
          dispatch(setStepEditDealToReactivate(1));
          dispatch(setUnableToReactivateModalOpen(true));
          dispatch(setUnableToReactivateDealType((deal.deal_type === 1 || deal.deal_type === "Lead")
            ? "LEAD"
            : "RESERVATION"));
        }
      }
    });
  };

  return (
    <Modal
      open={modalOpened}
      onClose={() => handleClose()}
      aria-labelledby={"Modal Component"}
      aria-describedby={"A Modal component."}
      className={classes.modal}
      data-testid={"reactivate-cancel-deal"}
  >
      <Grid
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        spacing={2}
        className={classes.container}
        pb={2}>
        <Box className={classes.content}>
          <Typography variant={"h5"} className={classes.title}>Confirm Deal Reactivation</Typography>
          <Typography>Are you sure you want to reactivate this deal?</Typography>
          <Grid
            pt={4}
            spacing={4}
            container
            justifyContent={"center"}
      >
            <Grid item>
              <Button
                data-testid={"cancel-button"}
                className={classes.cancelButton}
                onClick={() => handleClose()}
                variant={"outlined"}
                color={"secondary"}
          >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                data-testid={"confirm-button-modal"}
                color={"primary"}
                variant={"outlined"}
                className={classes.button}
                onClick={() => handleSubmit()}
          >
                Confirm
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Modal>
  );
};

export default ReactivationModal;
