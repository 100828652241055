import { Button, Divider, Grid, Modal, Paper, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import {
  resetMerchandiseCategoriesSlice,
  selectMerchandiseCategories
} from "src/store/reducers/merchandiseCategoriesSlice/merchandiseCategoriesSlice";
import {
  resetMerchandiseFormSlice,
  selectMerchandiseFormLoading,
  selectMerchandiseId,
  selectMerchandiseName,
  setSelectedMerchandiseId,
  setSelectedMerchandiseName
} from "src/store/reducers/merchandiseFormSlice/merchandiseFormSlice";
import {
  resetRevenueClassesSlice,
  selectRevenueClasses
} from "src/store/reducers/revenueClassesSlice/revenueClassesSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Add } from "@mui/icons-material";
import { AppDispatch } from "src/store/rootStore";
import EditInventoryModal from "../../EditInventoryModal/EditInventoryModal";
import { LoadingButton } from "@mui/lab";
import ManageInventoryTable from "../../ManageInventoryTable/ManageInventoryTable";
import MerchandiseForm from "src/pages/Merchandise/MerchandiseForm/MerchandiseForm";
import { MerchandiseValues } from "src/models/formikInputValues/MerchandiseValues";
import { NavLink } from "react-router-dom";
import NewInventoryModal from "../../NewInventoryModal/NewInventoryModal";
import RemoveMerchandiseModal from "src/pages/Merchandise/RemoveMerchandiseModal/RemoveMerchandiseModal";
import clsx from "clsx";
import { resetMerchandiseItemsSlice } from "src/store/reducers/merchandiseItemsSlice/merchandiseItemsSlice";
import { selectInventory, setInventoryModalOpen } from "src/store/reducers/inventorySlice/inventorySlice";
import { toggleMerchandiseDeleteModal } from "src/store/reducers/modalSlice/modalSlice";
import { updateMerchandise } from "src/store/thunks/merchandise/update/updateMerchandise";
import { useNavigate } from "react-router";
import useStyles from "./EditMerchandiseInformation.styles";

export const handleReset = (dispatch: AppDispatch) => {
  dispatch(resetMerchandiseFormSlice());
  dispatch(resetRevenueClassesSlice());
  dispatch(resetMerchandiseCategoriesSlice());
  dispatch(resetMerchandiseItemsSlice());
};

const EditMerchandiseInformation: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const revenueClasses = useAppSelector(selectRevenueClasses);
  const merchandiseCategories = useAppSelector(selectMerchandiseCategories);
  const merchandiseFormLoading = useAppSelector(selectMerchandiseFormLoading);
  const merchandiseId = useAppSelector(selectMerchandiseId);
  const merchandiseName = useAppSelector(selectMerchandiseName);
  const inventory = useAppSelector(selectInventory);
  const handleSubmit = (merchFormData: MerchandiseValues) => {
    dispatch(updateMerchandise(merchFormData)).then((resp: { type: string | string[] }) => {
      if (!resp.type.includes("rejected")) {
        navigate("/merchandise/manage-merchandise");
      }
    });
  };

  const openDeleteModal = () => {
    dispatch(setSelectedMerchandiseId(String(merchandiseId)));
    dispatch(setSelectedMerchandiseName(merchandiseName));
    dispatch(toggleMerchandiseDeleteModal(true));
  };

  return (
    <Grid container spacing={2}>
      <Grid m={2} p={2} item
        component={Paper} elevation={0} variant={"outlined"}
        xs={12}>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent={"space-between"}
            mb={1}>
            <Grid item xs={12}>
              <Typography variant={"h6"}>Edit Merchandise</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          <MerchandiseForm
            merchandiseCategories={merchandiseCategories}
            revenueClasses={revenueClasses}
            submitHandler={handleSubmit}
          />
        </Grid>
        <Grid mt={2} container justifyContent={"space-between"}
          alignContent={"center"} alignItems={"center"}>
          <Grid item>
            {!inventory.length || inventory.every((i) => i.remaining_quantity === 0)
              ? (
                <Button
                  variant={"outlined"}
                  color={"error"}
                  className={clsx(classes.buttonBase)}
                  onClick={() => openDeleteModal()}
              >
                  Remove
                </Button>
                )
              : null}
          </Grid>
          <Grid item>
            <Button variant={"text"} color={"error"} component={NavLink}
              to={"/merchandise/manage-merchandise"}>
              Cancel
            </Button>
            <LoadingButton
              form={"merchandise-form"}
              variant={"contained"}
              className={clsx(classes.saveButton, classes.buttonBase)}
              type={"submit"}
              loading={merchandiseFormLoading}
              disabled={merchandiseFormLoading}
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid m={2} p={2} item
        component={Paper} elevation={0} variant={"outlined"}
        xs={12}>
        <Grid container spacing={2} mb={1}>
          <Grid item xs={"auto"}>
            <Typography variant={"h6"}>Manage Inventory</Typography>
          </Grid>
          <Grid item>
            <Button
              className={clsx(classes.saveButton, classes.buttonBase)}
              variant={"contained"}
              startIcon={<Add />}
              onClick={() => dispatch(setInventoryModalOpen(true))}
            >
              New Inventory
            </Button>
          </Grid>
        </Grid>
        <ManageInventoryTable />
      </Grid>
      <NewInventoryModal />
      <EditInventoryModal />
      <RemoveMerchandiseModal />
    </Grid>
  );
};

export default EditMerchandiseInformation;
