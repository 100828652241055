import React from "react";
import { Tooltip, Typography } from "@mui/material";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import StatusColumn from "./components/StatusColumn/StatusColumn";
import PartialPaymentColumn from "./components/PartialPaymentColumn/PartialPaymentColumn";
import moment from "moment";

export const DelinquentPaymentTableColumns = (): GridColDef[] => [
  {
    field: "unit_name",
    renderHeader: () => (
      <Tooltip title={"Unit"}>
        <Typography>Unit</Typography>
      </Tooltip>
    ),
    width: 80
  },
  {
    field: "occupant_name",
    renderHeader: () => (
      <Tooltip title={"Occupant"}>
        <Typography>Occupant</Typography>
      </Tooltip>
    ),
    sortable: false,
    width: 150,
    renderCell: (params) => (
      <Tooltip title={params.row.occupant_name}>
        <Typography>{params.row.occupant_name}</Typography>
      </Tooltip>
    )
  },
  {
    field: "deal_rate",
    renderHeader: () => (
      <Tooltip title={"Deal Rate"}>
        <Typography>Deal Rate</Typography>
      </Tooltip>
    ),
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => `$${params.row.deal_rate}`
  },
  {
    field: "due_date",
    renderHeader: () => (
      <Tooltip title={"Due Date"}>
        <Typography>Due Date</Typography>
      </Tooltip>
    ),
    flex: 1,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => moment(params.row.due_date).format("MM/DD/YYYY")
  },
  {
    field: "status",
    renderHeader: () => (
      <Tooltip title={"Status"}>
        <Typography>Status</Typography>
      </Tooltip>
    ),
    flex: 1,
    renderCell: () => <StatusColumn />
  },
  {
    field: "partial_payment",
    width: 180,
    renderHeader: () => (
      <Tooltip title={"Partial Payment"}>
        <Typography>Partial Payment</Typography>
      </Tooltip>
    ),
    renderCell: () => <PartialPaymentColumn />
  },
  {
    field: "Total",
    renderHeader: () => (
      <Tooltip title={"Total"}>
        <Typography>Total</Typography>
      </Tooltip>
    ),
    sortable: false,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => `$${params.row.total}`
  }
];

export default DelinquentPaymentTableColumns;
