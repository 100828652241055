import React, { ReactElement, useEffect } from "react";
import { resetTasksSlice, selectCalendarTasks, selectTasksLoading } from "../../store/reducers/tasksSlice/tasksSlice";
import {
  selectTaskModalData,
  selectTaskModalOpen,
  setTaskModalOpen
} from "src/store/reducers/modalSlice/modalSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import AppModal from "src/components/ui/AppModal/AppModal";
import { Breadcrumb } from "../../models/Breadcrumb";
import { CalendarTask } from "../../models/CalendarTask";
import ItemManagementLayout from "../../layouts/ItemManagement/ItemManagementLayout";
import PMSCalendar from "../../components/calendar/PMSCalendar";
import PageHeader from "../../components/ui/PageHeader/PageHeader";
import { Task } from "../../models/Task";
import { Typography } from "@mui/material";
import ViewWrapper from "../../layouts/ViewWrapper/ViewWrapper";
import { generateDateAndTime } from "../../utils/__calendarUtils__/generateDateAndTime";
import { generateRecurrence } from "../../utils/__calendarUtils__/generateRecurrence";
import { getAllTasksForCalendar } from "../../store/thunks/task/getAllForCalendar/getAllTasksForCalendar";
import theme from "../../theme";
import {
  updateCalendarTaskCompletion
} from "src/store/thunks/task/updateCalendarTaskCompletion/updateCalendarTaskCompletion";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "My Schedule",
    bold: true
  }
];

const MySchedule: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const tasks = useAppSelector(selectCalendarTasks);
  const tasksLoading = useAppSelector(selectTasksLoading);
  const confirmOpen = useAppSelector(selectTaskModalOpen);
  const modalTaskData = useAppSelector(selectTaskModalData);

  const pageHeader = <PageHeader title={"My Schedule"} breadcrumbs={breadcrumbs} />;

  const tasksForCalendar: CalendarTask[] = tasks.map((task: Task) => {
    return {
      id: task.id,
      title: task.name,
      start: generateDateAndTime(task),
      color: task.task_category?.color ?? theme.palette.secondary.main,
      allDay: task.is_all_day,
      rrule: generateRecurrence(task),
      item: task
    };
  });

  const handleUpdateTaskCompletion = () => {
    if (!modalTaskData) {
      return;
    }

    dispatch(updateCalendarTaskCompletion(modalTaskData))
      .then((resp) => {
        if (!resp.type.includes("rejected")) {
          dispatch(setTaskModalOpen(false));
          dispatch(getAllTasksForCalendar());
        }
      });
  };

  useEffect(() => {
    dispatch(getAllTasksForCalendar());

    return () => {
      dispatch(resetTasksSlice());
    };
  }, []);

  return (
    <ViewWrapper pageHeader={pageHeader}>
      <ItemManagementLayout
        title={"Task Calendar"}
        subtitle={"Tasks can be selected in List view to review details and mark task as complete or incomplete."}
        loading={tasksLoading}
      >
        <PMSCalendar events={tasksForCalendar} />
      </ItemManagementLayout>
      <AppModal
        open={confirmOpen}
        cancelAction={() => dispatch(setTaskModalOpen(false))}
        onCloseAction={() => dispatch(setTaskModalOpen(false))}
        confirmAction={handleUpdateTaskCompletion}
      >
        <Typography gutterBottom variant={"h6"}>
          Task Details
        </Typography>
        <Typography>
          {`Mark as ${modalTaskData?.is_completed ? "incomplete" : "complete"}?`}
        </Typography>
      </AppModal>
    </ViewWrapper>
  );
};

export default MySchedule;
