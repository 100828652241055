import * as Yup from "yup";
import { FormikValues } from "formik";
import { Report } from "src/pages/Reporting/reports";
import moment from "moment";

const getValidationSchema = (selectedReport: Report) => {
  const schemaFields: { [key: string]: any } = {};

  // Adjust these based off of what they want to allow
  // Start date: 4 years ago from today at most
  // End date: up to and including "today"
  const startDateMax = moment().subtract(4, "years").format("MM/DD/yyyy");
  const endDateMax = moment().format("MM/DD/yyyy");

  if (selectedReport?.inputs?.includes("DateRange")) {
    schemaFields.startDate = Yup.string()
      .required("Start date is required")
      .nullable()
      .test(
        "is-date",
        "Start date must be a valid date",
        (value) => moment(value, "MM/DD/yyyy", true).isValid()
      )
      .test(
        "not-too-old",
        `Start date cannot be earlier than ${startDateMax}`,
        (value) => moment(value).isSameOrAfter(startDateMax)
      )
      .test(
        "not-too-future",
        `Start date cannot be later than ${endDateMax}`,
        (value) => moment(value).isSameOrBefore(endDateMax)
      );

    schemaFields.endDate = Yup.string()
      .required("End date is required")
      .nullable()
      .test(
        "is-date",
        "End date must be a valid date",
        (value) => moment(value, "MM/DD/yyyy", true).isValid()
      )
      .test(
        "not-too-old",
        `End date cannot be earlier than ${startDateMax}`,
        (value) => moment(value).isSameOrAfter(startDateMax)
      )
      .test(
        "not-too-future",
        `End date cannot be later than ${endDateMax}`,
        (value) => moment(value).isSameOrBefore(endDateMax)
      )
      .test(
        "is-greater",
        "End date must be later than start date",
        function(value) {
          const { startDate } = this.parent as FormikValues;
          return moment(value).isSameOrAfter(moment(startDate));
        }
      );
  }

  if (selectedReport?.inputs?.includes("SingleSelect")) {
    schemaFields.attachedFacility = Yup.array()
      .min(1, "A Facility must be selected")
      .required("A Facility must be selected");
  }

  if (selectedReport?.inputs?.includes("MultiSelect")) {
    schemaFields.attachedFacilities = Yup.array()
      .min(1, "At least one facility must be selected")
      .required("At least one facility must be selected");
  }

  if (selectedReport?.inputs?.includes("Date")) {
    schemaFields.date = Yup.string()
      .required("Date is required")
      .nullable()
      .test(
        "is-date",
        "Date must be a valid date",
        (value) => moment(value, "MM/DD/yyyy", true).isValid()
      );
  }

  if (selectedReport?.inputs?.includes("Hours")) {
    schemaFields.hours = Yup.string()
      .required("Hours are required")
      .test(
        "is-not-empty",
        "Hours field must not be empty",
        (value) => {
          // Check for undefined and set to empty string if necessary
          const checkedValue = value ?? "";
          // Treat empty string as "empty"
          return checkedValue.trim() !== "";
        }
      );
  }

  return Yup.object().shape(schemaFields);
};

export default getValidationSchema;
