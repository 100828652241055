import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "OccupantContact" })((theme) => ({
  noArrowInput: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    },
    "input[type=number]": {
      MozAppearance: "textfield"
    }
  },
  uploadButton: {
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText
  },
  fileNameContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 1,
    maxWidth: "80%",
    padding: "0.3rem 0.1rem",
    border: `1px solid ${theme.palette.primary.contrastText}`,
    borderRadius: "1rem"

  },
  fileName: {
    textOverflow: "ellipsis",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: theme.palette.primary.contrastText
  },
  cancelIcon: {
    color: theme.palette.primary.main,
    fontSize: "1.5rem"
  },
  generateGateCodeButton: {
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText,
    maxWidth: "15rem",
    maxHeight: "3rem"
  }
}));

export default useStyles;
