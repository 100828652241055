import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "PaymentSummaryLineItem" })((theme) => ({
  lineItem: {
    fontWeight: "bold",
    maxWidth: "70%",
    overflow: "visible"
  },
  incDecWrapper: {
    minWidth: "4rem"
  },
  subLineItem: {
    paddingLeft: "0.5rem",
    fontWeight: "normal !important"
  },
  transferItem: {
    backgroundColor: "rgba(255, 198, 115, 0.25)"
  }
}));

export default useStyles;
