import React, { ReactElement } from "react";
import {
  selectECEmail,
  selectECFirstName,
  selectECLastName, selectECPhone, selectECRelationship, selectEcValidMailingAddress
} from "../../../store/reducers/emergencyContactInformationSlice/emergencyContactInformationSlice";
import InfoIcon from "../../ui/InfoIcon/InfoIcon";
import { emailTest } from "../../../utils/emailTest/emailTest";
import { useAppSelector } from "../../../store/hooks";

const ECIcons: React.FC = (): ReactElement => {
  const ecFirstName = useAppSelector(selectECFirstName);
  const ecLastName = useAppSelector(selectECLastName);
  const ecValidAddress = useAppSelector(selectEcValidMailingAddress);
  const ecPhone = useAppSelector(selectECPhone);
  const ecRelationship = useAppSelector(selectECRelationship);
  const ecEmail = useAppSelector(selectECEmail);

  return (
    <>
      <InfoIcon title={"First Name"} complete={!!ecFirstName} />
      <InfoIcon title={"Last Name"} complete={!!ecLastName} />
      <InfoIcon title={"Mailing Address"} complete={ecValidAddress} />
      <InfoIcon title={"Phone Number"} complete={ecPhone?.length === 10} />
      <InfoIcon title={"Relationship"} complete={!!ecRelationship} />
      <InfoIcon title={"E-mail Address"} complete={emailTest(ecEmail)} />
    </>
  );
};

export default ECIcons;
