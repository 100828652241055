import { Button, Grid, Paper, Typography } from "@mui/material";
import React, { ReactElement } from "react";
// import history from "src/utils/history";
import useStyles from "../GetStarted.styles";
import { useNavigate } from "react-router";

export interface ButtonItemProps {
  text: string
  path?: string
  onClick?: Function
}

export const ButtonItem: React.FC<ButtonItemProps> = ({
  text,
  path,
  onClick
}: ButtonItemProps): ReactElement => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (path) {
      navigate(path);
    }
  };

  return (
    <Button
      size={"large"}
      className={classes.button}
      sx={{ mb: 3 }}
      variant={"contained"}
      data-testid={"get-started-button"}
      onClick={() => handleClick()}
    >
      { text }
    </Button>
  );
};
