import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "AssignRevenueClass" })((theme) => ({
  disabledInput: {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#838383"
    }
  },
  blackDisabledInput: {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#000"
    }
  }
}));

export default useStyles;
