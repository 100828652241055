/* eslint-disable max-len */
import { CreateNewUnitFormValues } from "./initialValues";
import { CreateUnitPayload } from "src/store/thunks/unitNew/create/CreateUnitPayload";
import UnitAttribute from "src/models/UnitAttribute";

export interface UnitAtributePayload {
  id: number;
  value: boolean;
}

const selectAttributeIds = (values: CreateNewUnitFormValues, unitAttributes: UnitAttribute[]): number[] => {
  const unitAttributesKeys: number[] = [];

  unitAttributes.forEach(attr => {
    unitAttributesKeys.push(attr.sort_order);
  });

  return unitAttributesKeys;
};

const getIdFromSortOrder = (sort_order: number, unitAttributes: UnitAttribute[]) => {
  const id = unitAttributes.find(attr => attr.sort_order === sort_order)?.id;
  return id;
};

const parseAttributes = (values: CreateNewUnitFormValues, unitAttributes: UnitAttribute[]):
 { id: number, value: boolean }[] => {
  const attributes: any[] = [];

  const unitAttributesKeys = selectAttributeIds(values, unitAttributes);

  // todo: refactor this in the future to use real types instead of any
  Object.keys(values).forEach((obj: any) => {
    if (unitAttributesKeys.includes((values as any)[obj]?.sort_order) && (values as any)[obj]?.value) {
      attributes.push({ id: getIdFromSortOrder((values as any)[obj]?.sort_order, unitAttributes), value: (values as any)[obj]?.value });
    }
  });

  return attributes;
};

const assembleNewUnitPayload = (values: CreateNewUnitFormValues, unitAttributes: UnitAttribute[]):
 CreateUnitPayload => {
  const payload: CreateUnitPayload = {
    unit_number: values.unit_number,
    product_type_id: values.product_type,
    floor: values.floor.toString(),
    is_ada_compliant: false,
    length: +values.length,
    width: +values.width,
    height: +values.height,
    price: values.price,
    is_rentable: values.rentable,
    is_rented: false,
    door_type: 1,
    walk_through_order: values.walk_through_order,
    // attributes assembled in {id: number, value: boolean }[] format.
    // grab the attributes from the store we pulled from the api (2nd argument in this function),
    //  use the sort order to pull their ids.
    // attributes: attributes
    attributes: parseAttributes(values, unitAttributes)
  };
  return payload;
};

export default assembleNewUnitPayload;
