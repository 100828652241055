import { Button, Divider, Grid, Modal, Paper, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import SendEmailForm from "./SendEmailReceiptForm/SendEmailReceiptForm";
import { printReceipt } from "src/store/thunks/moveIn/print/printReceipt";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { useLocation, useNavigate } from "react-router";
import useStyles from "./MerchSummary.styles";
import { handleReset } from "../PointOfSales";
import { selectCurrentTransaction } from "src/store/reducers/transactionSlice/transactionSlice";

interface MerchSummaryProps {}

const MerchSummary: FC<MerchSummaryProps> = () => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentTransaction = useAppSelector(selectCurrentTransaction);
  const location = useLocation();

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handlePrintReceipt = () => {
    dispatch(printReceipt(currentTransaction?.id)).then((resp) => {
      if (!resp.type.includes("rejected")) {
        const link = document.createElement("a");
        link.href = resp.payload as string;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  useEffect(() => {
    return () => handleReset(dispatch);
  }, [location]);

  return (
    <Grid container>
      <Grid container justifyContent={"flex-end"} item
        p={2} pl={0} xs={12}>
        <Grid pr={1}>
          <Button className={classes.textButton} variant={"text"} onClick={() => navigate("/")}>
            RETURN TO DASHBOARD
          </Button>
        </Grid>
        <Grid>
          <Button
            className={classes.baseButton}
            variant={"contained"}
            onClick={() => navigate("/merchandise/point-of-sales")}
          >
            NEW TRANSACTION
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper mt={1} p={4} spacing={2}
          container component={Grid} variant={"outlined"}
          px={6}>
          <Grid item xs={12}>
            <Typography variant={"h4"}>Transaction Successful - Confirmation # {currentTransaction?.id} </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item my={4} xs={12}>
            <Typography variant={"body1"}>
              Please select an option below to print and/or email the transaction’s receipt if desired.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={"h6"}>Transaction Information</Typography>
          </Grid>
          <Grid container item>
            <Grid item mr={2}>
              <Button className={classes.textButton} variant={"outlined"} onClick={handlePrintReceipt}>
                PRINT RECEIPT
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleOpenModal} className={classes.textButton} variant={"outlined"}>
                EMAIL RECEIPT
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Modal onClose={handleCloseModal} open={open} className={classes.modal}>
        <Grid container className={classes.modalContainer} component={Paper}
          elevation={0}>
          <SendEmailForm closeButtonAction={handleCloseModal} />
        </Grid>
      </Modal>
    </Grid>
  );
};

export default MerchSummary;
