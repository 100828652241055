import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridFeatureMode,
  GridRowId,
  GridRowIdGetter,
  GridRowParams,
  GridSelectionModel,
  GridSortModel
} from "@mui/x-data-grid";
import React, { JSXElementConstructor, ReactElement, ReactNode } from "react";
import {
  selectPage,
  selectRowsPerPage,
  selectRowsPerPageOptions,
  selectSortModel,
  selectTotalItems,
  setPage,
  setRowsPerPage,
  setSortModel
} from "../../../store/reducers/tablesSlice/tablesSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import useStyles from "./PMSTable.styles";

interface PMSTableProps {
  identifier?: string;
  checkboxSelection?: boolean;
  stopPropagation?: boolean;
  selectionModel?: GridRowId[];
  // eslint-disable-next-line max-len
  onCellEditCommit?: any;
  onSelectionModelChange?: ((selectionModel: GridSelectionModel, details: GridCallbackDetails) => void) | undefined;
  columns: GridColDef[];
  rows: any[];
  emptyTableDisplay: JSXElementConstructor<any>;
  onPaginationUpdate?: () => void;
  loading?: boolean;
  onRowClick?: (rowData: GridRowParams) => void;
  sortingMode?: GridFeatureMode;
  hideSelectAllCheckbox?: boolean;
  additionalRowsPerPageOptions?: number[];
  hideFooter?: boolean;
  hidePagination?: boolean;
  rowAutoHeight?: boolean;
}

const PMSTable: React.FC<PMSTableProps> = ({
  columns,
  checkboxSelection = false,
  stopPropagation = false,
  onCellEditCommit = () => {},
  selectionModel,
  onSelectionModelChange = () => {},
  identifier,
  rows,
  emptyTableDisplay,
  onPaginationUpdate,
  loading,
  onRowClick,
  sortingMode,
  hideSelectAllCheckbox,
  additionalRowsPerPageOptions,
  hideFooter,
  hidePagination = false,
  rowAutoHeight
}): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const page = useAppSelector(selectPage);
  const rowsPerPage = useAppSelector(selectRowsPerPage);
  const rowsPerPageOptions = useAppSelector(selectRowsPerPageOptions);
  const rowCount = useAppSelector(selectTotalItems);
  const sortModel = useAppSelector(selectSortModel);

  const handlePageChange = async(page: number) => {
    await dispatch(setPage(page));

    if (onPaginationUpdate) {
      onPaginationUpdate();
    }
  };

  const handlePageSizeChange = async(page: number) => {
    await dispatch(setRowsPerPage(page));

    if (onPaginationUpdate) {
      onPaginationUpdate();
    }
  };

  const handleOnSortModelChange = async(model: GridSortModel) => {
    await dispatch(setSortModel(model));

    if (onPaginationUpdate) {
      onPaginationUpdate();
    }
  };

  const handleRowClick = (rowData: GridRowParams) => {
    if (onRowClick) {
      onRowClick(rowData);
    }
  };

  const idString = identifier ? `${identifier}` : "pms-table";

  const handleRowsPerPageOptions = (rowsPerPageOptions: number[], additionalRowsPerPage?: number[]) => {
    if (!additionalRowsPerPage) {
      return rowsPerPageOptions;
    }

    return [...rowsPerPageOptions, ...additionalRowsPerPage];
  };

  return (
    <DataGrid
      getRowHeight={() => (rowAutoHeight ? "auto" : null)}
      checkboxSelection={checkboxSelection}
      selectionModel={selectionModel}
      columnBuffer={columns.length}
      className={`${classes.root} ${hideSelectAllCheckbox && classes.hideSelectAllCheckbox}`}
      columns={columns}
      rows={rows}
      onCellKeyDown={stopPropagation ? (params, events) => events.stopPropagation() : undefined}
      autoHeight
      disableSelectionOnClick
      data-testid={"pms-table"}
      paginationMode={"server"}
      sortingMode={sortingMode ?? "server"}
      loading={loading}
      page={page}
      rowCount={rowCount}
      hideFooter={hideFooter}
      onSelectionModelChange={onSelectionModelChange}
      onCellEditCommit={onCellEditCommit}
      rowsPerPageOptions={handleRowsPerPageOptions(rowsPerPageOptions, additionalRowsPerPageOptions)}
      sortModel={sortModel}
      pageSize={rowsPerPage}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      onRowClick={handleRowClick}
      onSortModelChange={handleOnSortModelChange}
      componentsProps={{ header: { "data-testid": `${idString}-header` }, row: { "data-testid": `${idString}-row` } }}
      components={{
        NoResultsOverlay: emptyTableDisplay,
        NoRowsOverlay: emptyTableDisplay
      }}
      hideFooterPagination={hidePagination}
    />
  );
};

export default PMSTable;
