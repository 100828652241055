import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "UpdateSettingsForm" })({
  form: {
    width: "95%"
  },
  firstName: {
    paddingRight: "0.75rem"
  }
});

export default useStyles;
