import React from "react";
import { useController } from "react-hook-form";
import { Checkbox, FormControlLabel, FormGroup, Grid, InputLabel, TextField, Typography } from "@mui/material";

type FormCheckboxProps = {
  name: string;
  label: string;
};

const FormCheckbox = ({ name, label }: FormCheckboxProps) => {
  const { field } = useController({
    name
  });

  return (
    <FormGroup>
      <FormControlLabel
        {...field}
        data-testid={"pms-checkbox-label"}
        componentsProps={{ typography: { noWrap: true } }}
        labelPlacement={"end"}
        checked={field.value}
        control={<Checkbox data-testid={"pms-checkbox"} />}
        label={label}
      />
    </FormGroup>
  );
};

export default FormCheckbox;
