import React from "react";
import { useController } from "react-hook-form";
import { Grid, InputLabel, TextField, Typography } from "@mui/material";

type FormTextProps = {
  id: string;
  name: string;
  label?: string;
  placeholder?: string;
  multiline?: boolean;
  rows?: number;
};

const FormText = ({ id, name, placeholder, label, multiline, rows = 0 }: FormTextProps) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    name
  });

  return (
    <Grid>
      {label && (
        <InputLabel htmlFor={name}>
          <Typography noWrap>{label}</Typography>
        </InputLabel>
      )}

      <TextField
        {...field}
        id={id}
        type={"text"}
        placeholder={placeholder}
        error={!!error?.message}
        helperText={error?.message}
        fullWidth
        multiline={multiline}
        rows={rows}
      />
    </Grid>
  );
};

export default FormText;
