import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  Paper,
  TextField,
  Typography
} from "@mui/material";
import React, { FC, SyntheticEvent } from "react";
import {
  selectEditInventoryModalOpen,
  selectSelectedInventoryItem,
  setEditInventoryModalOpen
} from "src/store/reducers/inventorySlice/inventorySlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Close } from "@mui/icons-material";
import NoteType from "src/enums/NoteType";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import { getAllNotes } from "src/store/thunks/note/getAll/getAllNotes";
import { getMerchInventory } from "src/store/thunks/inventory/getMerchInventory/getMerchInventory";
import { inputError } from "src/utils/showInputError/showInputError";
import { updateInventory } from "src/store/thunks/inventory/update/updateInventory";
import { useFormik } from "formik";
import { useParams } from "react-router";
import useStyles from "./EditInventoryModal.styles";
import validationSchema from "./editInventoryModalValidation";

interface EditInventoryModalProps {}

interface EditInventoryValues {
  name: string
  sku: string
  remaining_quantity: string
  shipping_cost: string
  quantity_change_reason: string
  cost_of_goods_sold: string
}

const EditInventoryModal: FC<EditInventoryModalProps> = () => {
  const selectedInventoryItem = useAppSelector(selectSelectedInventoryItem);

  const dispatch = useAppDispatch();

  const { classes } = useStyles();
  const initialInventoryValues: EditInventoryValues = {
    name: selectedInventoryItem?.merchandise?.name ?? "",
    sku: selectedInventoryItem?.sku ?? "",
    remaining_quantity: (selectedInventoryItem && selectedInventoryItem?.remaining_quantity >= 0)
      ? String(selectedInventoryItem.remaining_quantity)
      : "",
    quantity_change_reason: "",
    shipping_cost: selectedInventoryItem?.shipping_cost ? String(selectedInventoryItem.shipping_cost) : "",
    cost_of_goods_sold: selectedInventoryItem?.cost_of_goods_sold
      ? String(selectedInventoryItem.cost_of_goods_sold)
      : ""
  };

  const params = useParams<{ id: string }>();

  const formik = useFormik({
    initialValues: initialInventoryValues,
    enableReinitialize: true,
    validationSchema: validationSchema(String(selectedInventoryItem?.remaining_quantity)),
    onSubmit: (values) => {
      dispatch(updateInventory({ id: String(selectedInventoryItem?.id), merchandiseId: String(params.id), values }))
        .then((resp) => {
          if (!resp.type.includes("rejected")) {
            dispatch(getAllNotes({ itemId: parseInt(String(params?.id), 10), noteType: NoteType.merch }));
            dispatch(getMerchInventory(String(params.id)));
          }
        });

      dispatch(setEditInventoryModalOpen(false));
      formik.resetForm({ values: initialInventoryValues });
    }
  });

  const { handleSubmit, setFieldTouched, setFieldValue, handleChange, values, touched, errors } = formik;

  const disableReason = () => initialInventoryValues.remaining_quantity === values.remaining_quantity;

  const handleCurrencyChange = (fieldName: string, amount: number | string) => {
    setFieldTouched(fieldName);
    setFieldValue(fieldName, String(amount));
  };

  const editInventoryModalOpen = useAppSelector(selectEditInventoryModalOpen);

  return (
    <Modal
      className={classes.modal}
      onClose={() => dispatch(setEditInventoryModalOpen(false))}
      open={editInventoryModalOpen}
    >
      <Grid
        container
        component={Paper}

        className={classes.container}
        pb={2}
      >
        <Grid
          component={"form"}
          container
          spacing={1}
          p={2}
          id={"Edit-inventory-form"}
          onSubmit={(e: SyntheticEvent) => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit();
          }}
        >
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              <Typography variant={"h5"}>Edit Inventory</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => dispatch(setEditInventoryModalOpen(false))}>
                <Close fontSize={"small"} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid pb={4} item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <InputLabel htmlFor={"name"}>
              Merchandise Name
            </InputLabel>
            <TextField
              id={"name"}
              fullWidth
              disabled
              variant={"outlined"}
              name={"name"}
              placeholder={"- Merchandise Name -"}
              value={values.name}
              onChange={handleChange}
              error={inputError("name", touched, errors).error}
              helperText={inputError("name", touched, errors).helperText}
            />
          </Grid>
          <Grid item pb={2} xs={6}>
            <InputLabel htmlFor={"sku"}>SKU</InputLabel>
            <TextField
              id={"sku"}
              disabled
              fullWidth
              variant={"outlined"}
              name={"sku"}
              placeholder={"- SKU -"}
              value={values.sku}
              onChange={handleChange}
              error={inputError("sku", touched, errors).error}
              helperText={inputError("sku", touched, errors).helperText}
            />
          </Grid>
          <Grid item pb={2} xs={6}>
            <InputLabel htmlFor={"remaining_quantity"}>Remaining Quantity</InputLabel>
            <TextField
              id={"remaining_quantity"}
              fullWidth
              type={"number"}
              inputProps={{ step: "any" }}
              variant={"outlined"}
              name={"remaining_quantity"}
              value={values.remaining_quantity}
              onChange={handleChange}
              error={inputError("remaining_quantity", touched, errors).error}
              helperText={inputError("remaining_quantity", touched, errors).helperText}
            />
          </Grid>
          <Grid item pb={2} xs={6}>
            <InputLabel htmlFor={"quantity"}>Quantity Change Reason</InputLabel>
            <PMSSelect
              disabled={disableReason()}
              id={"quantity_change_reason"}
              value={values.quantity_change_reason}
              changeHandler={handleChange}
              error={inputError("quantity_change_reason", touched, errors).error}
              helperText={inputError("quantity_change_reason", touched, errors).helperText}
            >
              <option value={""} disabled>
                - Change Quantity to Select Reason -
              </option>
              <option value={"Arrived Damaged from Supplier"}>Arrived Damaged from Supplier</option>
              <option value={"Became Unsellable"}>Became Unsellable</option>
              <option value={"Theft"}>Theft</option>
              <option value={"Transfer to Another Store"}>Transfer to Another Store</option>
            </PMSSelect>
          </Grid>
          <Grid item xs={6}>
            <InputLabel htmlFor={"count"}>Count</InputLabel>
            <TextField
              fullWidth
              disabled
              value={selectedInventoryItem?.quantity}
              id={"count"}
              name={"count"}
              type={"number"}
              inputProps={{ step: "any" }}
              variant={"outlined"}
              placeholder={"000"}
            />
          </Grid>
          <Grid item xs={6}>

          </Grid>
          <Grid item xs={6}>
            <InputLabel htmlFor={"cost-of-goods"}>Shipping Cost</InputLabel>
            <TextField
              id={"shipping_cost"}
              fullWidth
              type={"number"}
              inputProps={{ step: "any" }}
              variant={"outlined"}
              name={"shipping_cost"}
              InputProps={{ startAdornment: <InputAdornment position={"start"}>$</InputAdornment> }}
              value={values.shipping_cost}
              onChange={(e) => handleCurrencyChange("shipping_cost", e.target.value)}
              error={inputError("shipping_cost", touched, errors).error}
              helperText={inputError("shipping_cost", touched, errors).helperText}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel htmlFor={"cost_of_goods_sold"}>Cost of Goods Sold</InputLabel>
            <TextField
              id={"cost_of_goods_sold"}
              fullWidth
              type={"number"}
              inputProps={{ step: "any" }}
              variant={"outlined"}
              name={"cost_of_goods_sold"}
              placeholder={"- Cost of Goods Sold -"}
              InputProps={{ startAdornment: <InputAdornment position={"start"}>$</InputAdornment> }}
              value={values.cost_of_goods_sold}
              onChange={(e) => handleCurrencyChange("cost_of_goods_sold", e.target.value)}
              error={inputError("cost_of_goods_sold", touched, errors).error}
              helperText={inputError("cost_of_goods_sold", touched, errors).helperText}
            />
          </Grid>
          <Grid
            mt={2}
            container
            justifyContent={"flex-end"}
            spacing={2}
            item
            xs={12}
          >
            <Grid item>
              <Button onClick={() => dispatch(setEditInventoryModalOpen(false))} color={"error"}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                id={"Edit-inventory-form"}
                type={"submit"}
                variant={"contained"}
                className={classes.saveButton}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default EditInventoryModal;
