import NoteType from "src/enums/NoteType";
import api from "../api";

export interface PostNotePayload {
  notable_id: number;
  notable_type: NoteType;
  description: string;
}

const notesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    postNote: builder.mutation<void, PostNotePayload>({
      query: (payload) => ({
        url: `api/v1/notes`,
        method: "POST",
        body: payload
      }),
      invalidatesTags: ["DealNotifications"]
    })
  })
});

export const { usePostNoteMutation } = notesApi;
