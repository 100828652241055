import React from "react";
import { Grid, Modal, Paper, Typography } from "@mui/material";
import useStyles from "./PortraitBlockerModal.styles";

interface PortraitBlockerModalProps {
  open: boolean;
}

const PortraitBlockerModal = ({ open }: PortraitBlockerModalProps) => {
  const { classes } = useStyles();

  return (
    <Modal open={open} className={classes.modal} disableEnforceFocus>
      <Grid
        container
        height={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
        component={Paper}>
        <Grid item>
          <Typography textAlign={"center"} variant={"h5"}>
            View unsupported in application
          </Typography>
          <Typography textAlign={"center"}>
            Please adjust device view to landscape to continue.
          </Typography>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default PortraitBlockerModal;
