import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getDynamicInclusionKeywords = createAsyncThunk(
  "getDynamicInclusionKeywords", (_, { dispatch, getState, rejectWithValue }) => {
    return axios.get(`/api/v1/document-templates/inclusion-keywords`)
      .then(resp => resp.data)
      .catch(() => {
        const errorMessage = "There was an error getting the dynamic keywords";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
