import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Cancellation Modal" })((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  container: {
    width: "30rem",
    borderRadius: "1rem"
  },
  content: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "40vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "5px"
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
      borderRadius: "5px",
      border: "2px solid #ddd",
      boxShadow: "inset 1px 1px 5px #ddd"
    }
  },
  header: {
    width: "100%",
    height: "20%",
    padding: theme.spacing(2),
    borderBottom: "1px solid #ddd"
  }
}));

export default useStyles;
