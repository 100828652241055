import { CashSliceState } from "./CashSliceState";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../rootStore";

export const getChangeDue = (cashDue: string, cashReceived: string) => {
  if (cashDue && cashReceived) {
    const changeDue = (+cashReceived - +cashDue);

    if (changeDue >= 0.00) {
      // prevent displaying negative numbers in "change due"
      return changeDue.toFixed(2);
    }
  }

  return "0.00";
};

export const initialState: CashSliceState = {
  amountReceived: "",
  amountDue: "0"
};

export const cashSlice = createSlice({
  name: "cash",
  initialState,
  reducers: {
    setAmountDue: (state, action: PayloadAction<string>) => {
      state.amountDue = action.payload;
    },
    setAmountReceived: (state, action: PayloadAction<string>) => {
      state.amountReceived = action.payload;
    },
    resetCashSlice: (state) => {
      Object.assign(state, initialState);
    }
  }
});

export const selectAmountDue = (state: RootState) => state.cash.amountDue;
export const selectAmountReceived = (state: RootState) => state.cash.amountReceived;
export const selectChangeDue = (state: RootState) => getChangeDue(state.cash.amountDue, state.cash.amountReceived
  ? state.cash.amountReceived
  : "0.00");

export const {
  setAmountDue,
  setAmountReceived,
  resetCashSlice
} = cashSlice.actions;

export default cashSlice.reducer;
