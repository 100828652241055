import React, { ReactElement } from "react";
import { Breadcrumb } from "../../../models/Breadcrumb";
import { Breadcrumbs } from "@mui/material";
import { NavLink } from "react-router-dom";
import useStyles from "./AppBreadcrumbs.styles";

interface AppBreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
}

const AppBreadcrumbs: React.FC<AppBreadcrumbsProps> = ({ breadcrumbs }: AppBreadcrumbsProps): ReactElement => {
  const { classes } = useStyles();

  const fullBreadcrumbs = breadcrumbs.map(breadcrumb => (
    breadcrumb.linkPath
      ? <NavLink
          key={breadcrumb.name}
          className={classes.link}
          to={breadcrumb.linkPath}
          data-testid={`${breadcrumb.name}-breadcrumb-link`}
      >
        {breadcrumb.name}
      </NavLink>
      : <span
          key={breadcrumb.name}
          style={{ fontWeight: breadcrumb.bold ? 900 : 100, color: breadcrumb.bold ? "black" : "#59687d" }}
          data-testid={`${breadcrumb.name}-breadcrumb-text`}
      >
        {breadcrumb.name}
      </span>
  ));

  return (
    <Breadcrumbs aria-label={"breadcrumb"} data-testid={"app-breadcrumbs"}>
      <NavLink
        className={classes.link}
        to={"/"}
        data-testid={"dashboard-breadcrumb-link"}
      >
        Dashboard
      </NavLink>
      {fullBreadcrumbs}
    </Breadcrumbs>
  );
};

export default AppBreadcrumbs;
