import FacilitySettingEnum from "src/enums/FacilitySetting";
import { FacilitySettingInformationSliceState } from "./facilitySettingInformationSliceState";
import { HttpProblem } from "../../../models/HttpProblem";
import { RootState } from "../../rootStore";
import { createSlice } from "@reduxjs/toolkit";
import { getAllFacilitySettings } from "src/store/thunks/facilitySetting/getAll/getAllFacilitySettings";

const initialState: FacilitySettingInformationSliceState = {
  facilitySettingInformationLoading: false,
  facilitySettingInformationError: "",
  facilitySettings: []
};

export const facilitySettingInformationSlice = createSlice({
  name: "facilitySettingInformation",
  initialState,
  reducers: {
    resetFacilityInformationSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllFacilitySettings.pending, (state) => {
        state.facilitySettingInformationLoading = true;
      })
      .addCase(getAllFacilitySettings.fulfilled, (state, action) => {
        state.facilitySettingInformationLoading = false;
        state.facilitySettings = action.payload;
      })
      .addCase(getAllFacilitySettings.rejected, (state, action) => {
        state.facilitySettingInformationLoading = false;
        state.facilitySettingInformationError = action.payload as HttpProblem;
      });
  }
});

export const selectFacilityReservationDaysLimitSetting = (state: RootState) =>
  state.facilitySettings.facilitySettings.find(item =>
    item.identifier === FacilitySettingEnum.RESERVATION_DAYS_LIMIT);
export const selectFacilityDealDaysLimitSetting = (state: RootState) =>
  state.facilitySettings.facilitySettings.find(item =>
    item.identifier === FacilitySettingEnum.DEAL_DAYS_LIMIT);
export const selectFacilityReceiptTemplateSetting = (state: RootState) =>
  state.facilitySettings.facilitySettings.find(item => item.identifier === FacilitySettingEnum.RECEIPT_TEMPLATE);
export const selectFacilityInvoiceTemplateSetting = (state: RootState) =>
  state.facilitySettings.facilitySettings.find(item => item.identifier === FacilitySettingEnum.INVOICE_TEMPLATE);
export const selectFacilityAutoBillTemplateSetting = (state: RootState) =>
  state.facilitySettings.facilitySettings.find(item =>
    item.identifier === FacilitySettingEnum.AUTOBILL_TEMPLATE);
export const selectFacilityESignTemplateSetting = (state: RootState) =>
  state.facilitySettings.facilitySettings.find(item =>
    item.identifier === FacilitySettingEnum.ESIGN_EMAIL_TEMPLATE);
export const selectFacilityPastDueEmailTemplateSetting = (state: RootState) =>
  state.facilitySettings.facilitySettings.find(item =>
    item.identifier === FacilitySettingEnum.PAST_DUE_EMAIL_TEMPLATE);
export const selectFacilityQuickPayEmailTemplateSetting = (state: RootState) =>
  state.facilitySettings.facilitySettings.find(item =>
    item.identifier === FacilitySettingEnum.QUICK_PAY_EMAIL_TEMPLATE);
export const selectFacilitySmsTemplateSetting = (state: RootState) =>
  state.facilitySettings.facilitySettings.find(item => item.identifier === FacilitySettingEnum.SMS_TEMPLATE);
export const selectFacilityfacilitySettingInformationLoading = (state: RootState) =>
  state.facilitySettings.facilitySettingInformationLoading;
export const selectFacilityAllSettings = (state: RootState) => state.facilitySettings.facilitySettings;
export const selectFacilityGateSystemSetting = (state: RootState) =>
  state.facilitySettings.facilitySettings.find(item =>
    item.identifier === FacilitySettingEnum.GATE_SYSTEM);
export const selectFacilityEmergencyGateOverrideSetting = (state: RootState) =>
  state.facilitySettings.facilitySettings.find(item =>
    item.identifier === FacilitySettingEnum.EMERGENCY_GATE_OVERRIDE);
export const selectFacilityMoveInDaysLimitSetting = (state: RootState) =>
  state.facilitySettings.facilitySettings.find(item =>
    item.identifier === FacilitySettingEnum.MOVE_IN_DAYS_LIMIT);

export const selectFacilityMoveOutMarkDebtSetting = (state: RootState) =>
  state.facilitySettings.facilitySettings.find(item =>
    item.identifier === FacilitySettingEnum.MOVE_OUT_MARK_DEBT);

export const {
  resetFacilityInformationSlice
} = facilitySettingInformationSlice.actions;

export default facilitySettingInformationSlice.reducer;
