import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles<{ showDrawer: boolean; isCalling: boolean }>({ label: "NavBar" })(
  (theme, { showDrawer, isCalling }) => ({
    appBar: {
      backgroundColor: "#f2f3f4",
      padding: "0.5rem"
    },
    toolbar: {
      flexDirection: "row",
      flexGrow: 1
    },
    toggleNavButton: {
      background: "white",
      color: "black",
      right: "3.1rem",
      height: "2rem",
      width: "2.2rem"
    },
    buttonContainer: {
      marginLeft: "auto",
      justifyContent: "flex-end"
    },
    viewText: {
      color: theme.palette.text.primary
    },
    facilitySelectorCell: {
      justifyContent: "flex-end"
    },
    button: {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.common.white
    },
    outboundCall: {
      backgroundColor: isCalling ? theme.palette.error.main : theme.palette.primary.contrastText,
      marginBottom: "-0.5rem",
      padding: "0.5rem 1rem",
      borderTopLeftRadius: "20px",
      borderTopRightRadius: "20px",
      cursor: "pointer",
      color: theme.palette.common.white
    },
    outboundCallIcon: {
      width: "22px",
      height: "22x",
      color: theme.palette.common.white
    }
  })
);

export default useStyles;
