import { Badge, Box, Button, Checkbox, Fab, Menu } from "@mui/material";
import { NotificationsOutlined, Settings } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import useStyles from "./AppNotifications.styles";
import { getTasks } from "src/store/thunks/task/getAll/getTasks";
import moment from "moment";
import { selectNotifications, selectTaskCategories } from "src/store/reducers/notificationSlice/notificationSlice";
import { getAllTaskCategories } from "src/store/thunks/taskCategory/getAll/getAllTaskCategories";

const AppNotifications: React.FC = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const tasksCategories = useAppSelector(selectTaskCategories);
  const notifications = useAppSelector(selectNotifications);
  const [currentCategory, setCurrentCategory] = useState(0);

  const [menuAnchor, setMenuAnchor] = useState<any>(null);

  const handleClose = () => {
    setMenuAnchor(null);
  };

  const openNotificationsMenu = (event: React.MouseEvent<HTMLSpanElement>) => {
    setMenuAnchor(event.target);
  };

  useEffect(() => {
    dispatch(getTasks({ getNotifications: true }));
    dispatch(getAllTaskCategories());
  }, []);

  const goToMySchedule = () => {
    navigate("/my-schedule");
    location.state = { fromNotifications: true };
    handleClose();
  };

  return (
    <div>
      <Fab
        data-testid={"open-notifications-menu-button"}
        aria-label={"notifications"}
        aria-controls={"notifications-menu"}
        aria-haspopup={"true"}
        component={Badge}
        className={classes.menuButton}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => openNotificationsMenu(e)}
      >
        <Badge
          badgeContent={notifications.length}
          classes={{
            badge: classes.badge
          }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
          <NotificationsOutlined color={"action"} />
        </Badge>
      </Fab>
      <Menu
        id={"notifications-menu"}
        data-testid={"notifications-menu"}
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        MenuListProps={{
          className: classes.container
        }}
      >
        {notifications.length > 0
          ? (
            <Box className={classes.content}>
              <Box className={classes.header}>
                <Box className={classes.align}>
                  <Typography>All notifications </Typography>
                  <Box className={classes.circle} ml={1} style={{ background: "red" }}>
                    {notifications.length}
                  </Box>
                </Box>
                {/* <PMSSelect id={"notification-categories"} value={currentCategory}
                  changeHandler={(e) => setCurrentCategory(e.target.value)}>
                  <option value={0} selected>All notifications</option>
                  {tasksCategories.map(category => (
                    <option key={category.id} value={category.id}>{category.name}</option>
                  ))}
                </PMSSelect> */}

              </Box>
              <Box className={classes.list}>
                {notifications.map((task, i) => (
                  <Box key={i} className={classes.item}>
                    <Box className={classes.circle} mr={1} style={{ background: task?.task_category?.color }}/>
                    <Box style={{ width: "100%" }}>
                      <Box className={classes.row} mb={1}>
                        <Typography className={classes.itemTitle}>{task.name}</Typography>
                        {moment(task.due_date).isBefore(moment(), "day") && (
                          <Box className={classes.boxPast}>
                            <Typography
                              className={classes.description}
                              lineHeight={1}
                              color={"#FFF"}>Past Due</Typography>
                          </Box>
                        )}
                      </Box>
                      <Typography className={classes.description} color={"#8a8a8a"}>
                        {task.description}</Typography>
                      <Box className={classes.row}>
                        <Typography className={classes.description}>Due Date: Today</Typography>
                        {/* <Box display={"flex"} alignItems={"center"}>
                          <Typography className={classes.description}>Mark as complete</Typography>
                          <Checkbox checked={task.is_completed} />
                        </Box> */}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
              <Button color={"info"} className={classes.footer} onClick={() => goToMySchedule()}>
                View All
              </Button>
            </Box>
            )
          : (
            <NavLink
              className={classes.link}
              to={"/future-link"}
          >
              <MenuItem data-testid={"go-to-notifications-button"}>
                <ListItemIcon>
                  <Settings fontSize={"small"} />
                </ListItemIcon>
                <Typography variant={"inherit"}>There are no new notifications.</Typography>
              </MenuItem>
            </NavLink>
            )}

      </Menu>
    </div>
  );
};

export default AppNotifications;
