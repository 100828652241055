import React from "react";
import { Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { formatSuffixedNumber } from "../../../../utils/formatSuffixedNumber/formatSuffixedNumber";

const SelectPromotionTableColumns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params.row.name}>
        <Typography>{params.row.name}</Typography>
      </Tooltip>
    ),
    flex: 1,
    renderHeader: () => <Tooltip title={"Name"}><span>Name</span></Tooltip>
  },
  {
    field: "amount",
    flex: 1,
    renderHeader: () => <Tooltip title={"Promo Amount"}><span>Amount</span></Tooltip>,
    valueGetter: (params: GridValueGetterParams) => parseFloat(params.row.amount)
  },
  {
    field: "type",
    flex: 1,
    renderHeader: () => <Tooltip title={"Promo type"}><span>Type</span></Tooltip>,
    valueGetter: (params: GridValueGetterParams) => {
      switch (params.row.type) {
        case 1:
          return "Percentage";
        case 2:
          return "Fixed Dollar";
        case 3:
          return "Apply Rent";
      }
    }
  },
  {
    field: "duration",
    flex: 1,
    renderHeader: () => <Tooltip title={"Promo Duration"}><span>Duration</span></Tooltip>,
    // eslint-disable-next-line max-len
    valueGetter: (params: GridValueGetterParams) => {
      const word = params.row.duration > 1 ? `Months` : `Month`;
      return `${params.row.duration > 0 ? `${params.row.duration} ${word}` : "Non-Expiry"}`;
    }
  },
  {
    field: "applied_month",
    flex: 1,
    renderHeader: () => <Tooltip title={"Promo Applied Month"}><span>Appl. Month</span></Tooltip>,
    valueGetter: (params: GridValueGetterParams) => `${formatSuffixedNumber(params.row.applied_month)} Month`
  }
];

export default SelectPromotionTableColumns;
