import { Deal } from "../../models/Deal";
import { Unit } from "src/models/Unit";

export function getUnitSize(deal: Deal) {
  let width;
  // let height;
  let length;

  if (deal.product_type || deal.unit?.product_type) {
    if (deal.product_type) {
      length = deal.product_type.length;
      width = deal.product_type.width;
    }

    if (deal.unit && deal.unit.product_type) {
      length = deal.unit.product_type.length;
      width = deal.unit.product_type.width;
    }

    if (!width && !length) {
      return "N/A";
    }

    if (!width) {
      // parking units only have a length value
      return `${length}'`;
    }

    return `${width}' x ${length}'`;
  }

  return "N/A";
}

export function getUnitSizeFromUnit(unit: Unit | undefined | null) {
  if (!unit) {
    return "N/A";
  }

  const width = unit?.width;
  const length = unit?.length;

  if (!width) {
    // parking units only have a length value
    return `${length}'`;
  }

  return `${width}' x ${length}'`;
}
