import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { APIListResponse } from "../../../models/responses/APIListResponse";
import { FlatUnit } from "src/api/endpoints/units";
import { GridRowId } from "@mui/x-data-grid";
import { RootState } from "../../rootStore";
import { Unit } from "../../../models/Unit";
import UnitAttribute from "src/models/UnitAttribute";
import { UnitsSliceNewState } from "./UnitsSliceNewState";
import { batchUpdateUnits } from "src/store/thunks/unit/batch/update/batchUpdateUnits";
import { batchUploadUnits } from "src/store/thunks/unit/batch/new/batchUploadNewUnits";
import { createUnitNew } from "src/store/thunks/unitNew/create/createUnitNew";
import { getAllUnitAttributes } from "src/store/thunks/unitAttributes/getAll/getAllUnitAttributes";
import { getAllUnitsNew } from "src/store/thunks/unitNew/get/getAllUnitsNew";

export const initialState: UnitsSliceNewState = {
  unitsLoading: false,
  unitsError: "",
  attributesError: "",
  attributesList: [],
  attributesLoading: false,
  units: [],
  unitsSearchValue: "",
  notesModalUnitId: undefined,
  batchEditMode: false,
  textFieldFocusActive: false,
  unrentableModalOpen: false,
  notesModalOpen: false,
  newUnitModal: false,
  confirmChangeMultipleRowsModalOpen: false,
  batchUnitUploadModal: false,
  unrentableRow: null,
  batchUnitUpdateModal: false,
  currentEditableRow: [],
  creatingNewUnitLoading: false,
  batchUploadLoading: false,
  batchUpdateLoading: false,
  batchUploadError: "",
  batchUpdateError: ""
};

export const unitsSlice = createSlice({
  name: "unitsNew",
  initialState,
  reducers: {
    setCurrentEditableRow: (state, action) => {
      state.currentEditableRow = action.payload;
    },
    updateAttribute: (state, action: PayloadAction<{ unitIds: GridRowId[], attributeId: number, value?: boolean }>) => {
      action.payload.unitIds.map(id => {
        const unitRow: Unit = state.units.filter(unit => unit.id === id)[0];

        const attr: UnitAttribute = unitRow.attributes?.filter(attr => attr.id === action.payload.attributeId)[0]!;

        if (!action.payload.value) {
          attr.pivot.value = !attr.pivot.value;
          return id;
        }

        attr.pivot.value = action.payload.value;

        return id;
      });
    },
    setBatchEditMode: (state, action: PayloadAction<boolean>) => {
      state.batchEditMode = action.payload;
    },
    setNewUnitModalOpen: (state, action: PayloadAction<boolean>) => {
      state.newUnitModal = action.payload;
    },
    setBatchUnitUploadModalOpen: (state, action: PayloadAction<boolean>) => {
      state.batchUnitUploadModal = action.payload;
    },
    setBatchUnitUpdateModalOpen: (state, action: PayloadAction<boolean>) => {
      state.batchUnitUpdateModal = action.payload;
    },
    setNotesModalOpen: (state, action: PayloadAction<boolean>) => {
      state.notesModalOpen = action.payload;
    },
    setConfirmChangeMultipleRowsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.confirmChangeMultipleRowsModalOpen = action.payload;
    },
    setUnitsSearchValue: (state, action) => {
      state.unitsSearchValue = action.payload;
    },
    insertMissingAttribute: (state, action: PayloadAction<Partial<UnitAttribute>>) => {
      const unitRow: Unit = state.units.filter(unit => unit.id === action.payload.pivot?.unit_id)[0];
      unitRow.attributes?.push(action.payload as UnitAttribute);
    },
    updateUnitRentable: (state, action: PayloadAction<{ unitIds: number[], value?: boolean }>) => {
      action.payload.unitIds.map(id => {
        const unitRow: Unit = state.units.filter(unit => unit.id === id)[0];

        if (!action.payload.value) {
          unitRow.is_rentable = !unitRow.is_rentable;
          return id;
        }

        unitRow.is_rentable = action.payload.value;
        return id;
      });
    },
    setUnrentableUnitModalOpen: (state, action: PayloadAction<boolean>) => {
      state.unrentableModalOpen = action.payload;
    },
    setUnrentableRow: (state, action: PayloadAction<FlatUnit | null>) => {
      state.unrentableRow = action.payload;
    },
    setNoteModalUnitId: (state, action: PayloadAction<number>) => {
      state.notesModalUnitId = action.payload;
    },
    setTextfieldFocusActive: (state, action: PayloadAction<boolean>) => {
      state.textFieldFocusActive = action.payload;
    },
    updateUnitProductTypeId: (state, action: PayloadAction<{ unitId: number, productTypeId: number }>) => {
      const unitRow: Unit = state.units.filter(unit => unit.id === action.payload.unitId)[0];
      unitRow.product_type_id = action.payload.productTypeId;
    },
    updateField: (state, action: PayloadAction<{ unitId: number, fieldName: string, fieldValue: string | number }>) => {
      const unitRow: Unit = state.units.filter(unit => unit.id === action.payload.unitId)[0];
      const name = action.payload.fieldName;
      //@ts-ignore
      if (unitRow) {
        (unitRow as any)[name] = action.payload.fieldValue;
      }
    },
    handleUnitsSliceReset: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAllUnitsNew.pending, (state) => {
        state.unitsLoading = true;
      })
      .addCase(getAllUnitsNew.fulfilled, (state, action: PayloadAction<APIListResponse<Unit[]>>) => {
        state.unitsLoading = false;
        state.units = action.payload.data;
      })
      .addCase(getAllUnitsNew.rejected, (state, action) => {
        state.unitsLoading = false;
        state.unitsError = action.payload as string;
      })
      .addCase(getAllUnitAttributes.pending, (state) => {
        state.attributesLoading = true;
      })
      .addCase(getAllUnitAttributes.fulfilled, (state, action) => {
        state.attributesLoading = false;
        state.attributesList = action.payload.data;
      })
      .addCase(getAllUnitAttributes.rejected, (state, action) => {
        state.attributesLoading = false;
        state.attributesError = action.payload as string;
      })
      .addCase(createUnitNew.pending, (state) => {
        state.creatingNewUnitLoading = true;
      })
      .addCase(createUnitNew.fulfilled, (state, action) => {
        state.creatingNewUnitLoading = false;
      })
      .addCase(createUnitNew.rejected, (state, action) => {
        state.creatingNewUnitLoading = false;
      })
      .addCase(batchUploadUnits.pending, (state) => {
        state.batchUploadLoading = true;
      })
      .addCase(batchUploadUnits.fulfilled, (state) => {
        state.batchUploadLoading = false;
      })
      .addCase(batchUploadUnits.rejected, (state, action) => {
        state.batchUploadLoading = false;
        state.batchUploadError = action.payload as string;
      })
      .addCase(batchUpdateUnits.pending, (state) => {
        state.batchUpdateLoading = true;
      })
      .addCase(batchUpdateUnits.fulfilled, (state) => {
        state.batchUpdateLoading = false;
      })
      .addCase(batchUpdateUnits.rejected, (state, action) => {
        state.batchUpdateLoading = false;
        state.batchUpdateError = action.payload as string;
      });
  }
});

export const selectUnits = (state: RootState) => state.unitsNew.units;
export const selectUnitAttributes = (state: RootState) => state.unitsNew.attributesList;
export const selectCurrentEditableRow = (state: RootState) => state.unitsNew.currentEditableRow;
export const selectUnrentableRow = (state: RootState) => state.unitsNew.unrentableRow;
export const selectUnitsLoading = (state: RootState) => state.unitsNew.unitsLoading;
export const selectNewUnitModalOpen = (state: RootState) => state.unitsNew.newUnitModal;
export const selectBatchEditMode = (state: RootState) => state.unitsNew.batchEditMode;
export const selectTextFieldFocusActive = (state: RootState) => state.unitsNew.textFieldFocusActive;
export const selectNoteModalUnitId = (state: RootState) => state.unitsNew.notesModalUnitId;
export const selectAttributesLoading = (state: RootState) => state.unitsNew.attributesLoading;
export const selectNotesModalOpen = (state: RootState) => state.unitsNew.notesModalOpen;
export const selectBatchUnitUploadModalOpen = (state: RootState) => state.unitsNew.batchUnitUploadModal;
export const selectBatchUnitUpdateModalOpen = (state: RootState) => state.unitsNew.batchUnitUpdateModal;
export const selectUnrentableUnitModalOpen = (state: RootState) => state.unitsNew.unrentableModalOpen;
export const selectConfirmChangeMultipleRowsModalOpen = (state: RootState) =>
  state.unitsNew.confirmChangeMultipleRowsModalOpen;
export const selectCreatingNewUnitLoading = (state: RootState) => state.unitsNew.creatingNewUnitLoading;
export const selectUnitsSearchValue = (state: RootState) => state.unitsNew.unitsSearchValue;
export const selectBatchUploadLoading = (state: RootState) => state.unitsNew.batchUploadLoading;
export const selectBatchUpdateLoading = (state: RootState) => state.unitsNew.batchUpdateLoading;
export const {
  setBatchUnitUploadModalOpen,
  setBatchUnitUpdateModalOpen,
  setTextfieldFocusActive,
  handleUnitsSliceReset,
  insertMissingAttribute,
  setCurrentEditableRow,
  setConfirmChangeMultipleRowsModalOpen,
  setNewUnitModalOpen,
  setNoteModalUnitId,
  setUnrentableRow,
  setBatchEditMode,
  setUnitsSearchValue,
  updateField,
  updateAttribute,
  updateUnitProductTypeId,
  updateUnitRentable,
  setUnrentableUnitModalOpen,
  setNotesModalOpen
} = unitsSlice.actions;

export default unitsSlice.reducer;
