import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "NoItemsDisplay" })((theme) => ({
  displayContainer: {
    minHeight: "25rem"
  },
  newItemButton: {
    marginTop: theme.spacing(2),
    textTransform: "capitalize",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.contrastText,
    border: `${theme.palette.primary.contrastText} 1px solid`
  }
}));

export default useStyles;
