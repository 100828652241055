import { makeStyles } from "../../../../../makeStyles";

const useStyles = makeStyles({ label: "PaymentSummary" })((theme) => ({
  dueToday: {
    backgroundColor: theme.palette.success.main,
    borderRadius: theme.spacing(0.5)
  },
  dueTodayLine: {
    fontWeight: "bold"
  },
  processPaymentButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.common.white,
    fontWeight: "normal",
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  cancelButton: {
    color: theme.palette.error.contrastText,
    fontWeight: "normal",
    width: "100%"
  },
  button: {
    borderColor: theme.palette.grey[400],
    color: theme.palette.primary.contrastText,
    fontWeight: "normal",
    textTransform: "uppercase"
  },
  additionalAccountFunds: {
    border: `2px solid ${theme.palette.success.main}`,
    width: "100%"
  }
}));

export default useStyles;
