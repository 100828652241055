import NoteType from "src/enums/NoteType";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

interface GetAllNotes {
  itemId: number
  noteType: NoteType
}

export const getAllNotes = createAsyncThunk(
  "getAllNotes",
  ({ itemId, noteType }: GetAllNotes, { dispatch, getState, rejectWithValue }) => {
    const searchParams = {
      params: {
        sortBy: "created_at",
        sortDirection: "DESC"
      }
    };

    return axios.get(`api/v1/${noteType}/${itemId}/notes`, searchParams)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = "There was an error fetching the notes";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
