import { Add, Search } from "@mui/icons-material";
import { Button, Grid, InputAdornment, Modal, Paper, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import {
  selectDeleteProductTypeModalOpen,
  selectProductTypeToDelete,
  setDeleteProductTypeModalOpen
} from "src/store/reducers/productTypesSlice/productTypesSlice";
import {
  setProductTypeInsuranceDocument,
  setProductTypeIsInsurable,
  setProductTypeLeaseDocument,
  setProductTypeName,
  setProductTypeProductCategoryId,
  setProductTypeRevenueClassId
} from "src/store/reducers/productTypeSliceNew/productTypeSliceNew";
import {
  createPermissions,
  hasPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import AppModal from "src/components/ui/AppModal/AppModal";
import AssignInsuranceDocument from "./AssignInsuranceDocument/AssignInsuranceDocument";
import AssignLeaseDocument from "./AssignLeaseDocument/AssignLeaseDocument";
import AssignRevenueClass from "./AssignRevenueClass/AssignRevenueClass";
import { Breadcrumb } from "src/models/Breadcrumb";
import CreateNewProductTypeForm from "./CreateNewProductType/CreateNewProductType";
import ManageProductTypesTable from "./ManageProductTypesTable";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import ReviewProductType from "./ReviewProductType/ReviewProductType";
import TableWrapper from "src/layouts/TableWrapper/TableWrapper";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { debounce } from "lodash";
import { deleteProductType } from "src/store/thunks/productType/delete/deleteProductType";
import { getAllPaginatedProductTypes } from "src/store/thunks/productType/getAllPaginated/getAllPaginatedProductTypes";
import { searchProductTypes } from "src/store/thunks/productType/search/searchProductTypes";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import useStyles from "./ManageProductTypes.styles";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Units"
  },
  {
    name: "Manage Product Types",
    bold: true
  }
];

const ManageProductTypes: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState("");
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const deleteProductTypeModalOpen = useAppSelector(selectDeleteProductTypeModalOpen);
  const productTypeToDelete = useAppSelector(selectProductTypeToDelete);
  const { classes } = useStyles();
  const [step, setStep] = useState(0);
  const [open, setOpen] = useState(false);

  const permissions = useAppSelector(selectFacilityPermissions);
  const productTypesPermissions = createPermissions(permissions, "product_types");

  const handleSearch = (searchValue: string) => {
    if (!searchValue) {
      dispatch(getAllPaginatedProductTypes(selectedFacility?.id));
      return;
    }
    dispatch(searchProductTypes(searchValue));
  };

  const handleOpenNewProductType = () => {
    setOpen(true);
  };

  const handleCloseNewProductType = () => {
    setStep(0);
    dispatch(setProductTypeName(""));
    dispatch(setProductTypeProductCategoryId(""));
    dispatch(setProductTypeLeaseDocument(null));
    dispatch(setProductTypeRevenueClassId(null));
    dispatch(setProductTypeIsInsurable(false));
    dispatch(setProductTypeInsuranceDocument(null));
    setOpen(false);
  };

  const handlePaginationUpdate = () => {
    handleSearch(searchValue);
  };

  const debounceSearch = useCallback(debounce((text) => handleSearch(text), 600), []);

  const searchBar = useMemo(() => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setSearchValue(value);
      debounceSearch(value);
    };

    return (
      <TextField
        fullWidth
        placeholder={"Search"}
        size={"small"}
        hiddenLabel={true}
        inputProps={{ "aria-label": "Search Chart of Account Settings" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position={"end"}>
              <Search />
            </InputAdornment>
          )
        }}
        variant={"outlined"}
        onChange={handleChange}
        value={searchValue}
      />
    );
  }, [searchValue]);

  const handleDeleteUnit = () => {
    dispatch(deleteProductType(productTypeToDelete!)).then(resp => {
      if (!resp.type.includes("rejected")) {
        dispatch(setDeleteProductTypeModalOpen(false));
        dispatch(getAllPaginatedProductTypes(selectedFacility?.id));
      }
    });
  };

  const stepRoutes = () => {
    switch (step) {
      case 0:
        return <CreateNewProductTypeForm setStep={setStep} handleClose={handleCloseNewProductType} />;
      case 1:
        return <AssignRevenueClass handleClose={handleCloseNewProductType} setStep={setStep} />;
      case 2:
        return <AssignLeaseDocument handleClose={handleCloseNewProductType} setStep={setStep} />;
      case 3:
        return <ReviewProductType setStep={setStep} handleClose={handleCloseNewProductType} />;
      default:
        return <Typography>Something went wrong, please try again</Typography>;
    }
  };

  const pageHeader = <PageHeader title={"Manage Product Types"} breadcrumbs={breadcrumbs} />;

  useEffect(() => {
    if (selectedFacility?.id) {
      dispatch(getAllPaginatedProductTypes(selectedFacility?.id));
    }
  }, []);

  const openModalButton = () => {
    return (
      <Button onClick={handleOpenNewProductType} className={classes.buttonBase} startIcon={<Add />}
        color={"primary"}
        variant={"contained"}>
        New Product type
      </Button>
    );
  };

  return (
    productTypesPermissions.create ||
    productTypesPermissions.read ||
    productTypesPermissions.update ||
    productTypesPermissions.delete
      ? (<ViewWrapper pageHeader={pageHeader}>
        <TableWrapper
          title={"Product Types"}
          searchBar={searchBar}
          functionButton={productTypesPermissions.create && openModalButton()}
          table={<ManageProductTypesTable onPaginationUpdate={handlePaginationUpdate} />}
        />
        <AppModal
          open={deleteProductTypeModalOpen}
          onCloseAction={() => dispatch(setDeleteProductTypeModalOpen(false))}
          cancelAction={() => dispatch(setDeleteProductTypeModalOpen(false))}
          confirmAction={() => handleDeleteUnit()}
          data-testid={"delete-product-type-modal"}
        >
          <Typography gutterBottom variant={"h6"} textAlign={"center"}>
            Confirm Product Type Removal
          </Typography>
          <Typography gutterBottom variant={"body1"} textAlign={"justify"}>
            To confirm removal of a product type, please hit confirm after reviewing the information below.
          </Typography>
          <Typography gutterBottom variant={"body1"} textAlign={"justify"}>
            <strong>Note: </strong>
            Only product types without units can be deleted. Please
            review whether you intend to delete the listed Product Type.
          </Typography>
        </AppModal>
        <Modal
          className={classes.modal}
          onClose={handleCloseNewProductType}
          open={open}
        >
          <Grid
            container
            component={Paper}
            elevation={0}
            variant={"outlined"}
            className={classes.container}
            pb={2}
          >
            {stepRoutes()}
          </Grid>
        </Modal>
      </ViewWrapper>)
      : <ViewWrapper pageHeader={pageHeader}>
        <h1>You do not have permission to view this page.</h1>
      </ViewWrapper>
  );
};

export default ManageProductTypes;
