import { Button, Grid, IconButton, Paper, Typography } from "@mui/material";
import React, { FC } from "react";
import { CreditCard, Phone } from "@mui/icons-material";
import formatPhone from "src/utils/formatPhone/formatPhone";
import { getUnitSizeFromUnit } from "src/utils/getUnitSize/getUnitSize";
import { selectDelinquentOccupant } from "src/store/reducers/collectionsSlice/collectionsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import useStyles from "./ContactDetails.styles";
import { setInputNumber, setIsDisplayed } from "src/store/reducers/appSoftphoneSlice/appSoftphoneSlice";
import { SoftphoneDisplayMode } from "src/store/reducers/appSoftphoneSlice/appSoftphoneSliceState";

interface ContactDetailsProps {}

const ContactDetails: FC<ContactDetailsProps> = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const delinquentOccupant = useAppSelector(selectDelinquentOccupant);
  const bold = 600;
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Grid container item xs={12}
          component={Paper} elevation={0} p={2}
          className={classes.paperColor}>
          <Grid item xs={12}>
            <Typography gutterBottom className={classes.blueText} variant={"h6"}>
              {delinquentOccupant.occupant_name ?? ""}
            </Typography>
          </Grid>
          <Grid mb={2} container spacing={1}
            item xs={12}>
            <Grid item xs={6}>
              <Typography data-testid={"primary-phone-title"} fontWeight={bold}>Primary Phone:</Typography>
            </Grid>
            <Grid item xs={6} display={"flex"}
              alignItems={"center"}>
              <Typography data-testid={"primary-phone-value"}>
                {formatPhone(delinquentOccupant.ledger.occupant?.phone_primary)}
              </Typography>
              <IconButton
                data-testid={"primary-phone-call-icon"}
                size={"small"}
                onClick={() => {
                  dispatch(setInputNumber(delinquentOccupant.ledger.occupant?.phone_primary ?? ""));
                  dispatch(setIsDisplayed(SoftphoneDisplayMode.Display));
                }}
              >
                <Phone />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <Typography data-testid={"secondary-phone-title"} fontWeight={bold}>Secondary Phone:</Typography>
            </Grid>
            <Grid item xs={6} display={"flex"}
              alignItems={"center"}>
              <Typography data-testid={"secondary-phone-value"}>
                {formatPhone(delinquentOccupant.ledger.occupant?.phone_alternate)}
              </Typography>
              <IconButton
                data-testid={"secondary-phone-call-icon"}
                size={"small"}
                onClick={() => {
                  dispatch(setInputNumber(delinquentOccupant.ledger.occupant?.phone_alternate ?? ""));
                  dispatch(setIsDisplayed(SoftphoneDisplayMode.Display));
                }}
              >
                <Phone />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <Typography data-testid={"email-title"} fontWeight={bold}>Email:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography data-testid={"email-value"} whiteSpace={"pre"} noWrap>
                {delinquentOccupant.ledger.occupant?.email}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} item
            xs={12}>
            <Grid item xs={6}>
              <Typography data-testid={"ec-contact-title"} fontWeight={bold}>Emergency Contact:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography data-testid={"ec-contact-value"}>
                {delinquentOccupant.ledger.occupant?.contacts?.length
                  ? delinquentOccupant.ledger.occupant?.contacts[0].first_name +
                    " " +
                    delinquentOccupant.ledger.occupant?.contacts[0].last_name
                  : "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography data-testid={"ec-phone-title"} fontWeight={bold}>Emergency Phone:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography data-testid={"ec-phone-value"}>
                {delinquentOccupant.ledger.occupant?.contacts?.length
                  ? formatPhone(delinquentOccupant.ledger.occupant?.contacts[0].phone_primary)
                  : "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography data-testid={"ec-email-title"} fontWeight={bold}>Emergency Email:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography data-testid={"ec-email-value"} whiteSpace={"pre"} noWrap={true}>
                {delinquentOccupant.ledger.occupant?.contacts?.length
                  ? delinquentOccupant.ledger.occupant?.contacts[0].email
                  : "N/A"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={1} item
          xs={12} p={2}>
          <Grid item xs={6}>
            <Typography data-testid={"unit-name-title"} fontWeight={bold}>Unit Name:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography data-testid={"unit-name-value"}>{delinquentOccupant.ledger.unit?.unit_number}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography data-testid={"unit-type-title"} fontWeight={bold}>Unit Type:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography data-testid={"unit-type-value"}>
              {delinquentOccupant.ledger.unit?.product_type?.name}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography data-testid={"unit-size-title"} fontWeight={bold}>Unit Size:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography data-testid={"unit-size-value"}>
              {getUnitSizeFromUnit(delinquentOccupant.ledger.unit)
              }</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography data-testid={"unit-price-title"} fontWeight={bold}>Quoted Price:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography data-testid={"unit-price-value"}>$ {delinquentOccupant.ledger.unit?.price} / Month</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography data-testid={"promotion-title"} fontWeight={bold}>Promotion Offered:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography data-testid={"promotion-value"}>
              {delinquentOccupant.ledger.promotion ? delinquentOccupant.ledger.promotion?.name : "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography data-testid={"unit-features-title"} fontWeight={bold}>Unit Features:</Typography>
          </Grid>
          <Grid item xs={6}>
            {/* unit type */}
            <Typography data-testid={"unit-features-value"}>
              {delinquentOccupant.ledger.unit?.product_type?.name ?? "N/A"
              }</Typography>
            {/* floor */}
            <Typography data-testid={"unit-features-value-floor"}>
              Floor {delinquentOccupant.ledger.unit?.floor ?? "N/A"}
            </Typography>
            {/* revenue class */}
            <Typography data-testid={"unit-features-value-revenue-class"} whiteSpace={"pre"} noWrap={true}>
              {delinquentOccupant.ledger.unit?.product_type?.revenue_class?.name ?? "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography data-testid={"facility-title"} fontWeight={bold}>Facility:</Typography>
          </Grid>
          <Grid container item xs={6}>
            <Typography data-testid={"facility-value"}>{delinquentOccupant.ledger.facility?.name}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography data-testid={"postal-code-title"} fontWeight={bold}>Postal Code:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography data-testid={"postal-code-value"}>
              {delinquentOccupant.ledger.facility?.postal_code ?? "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography data-testid={"facility-phone-title"} fontWeight={bold}>Phone:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography data-testid={"facility-phone-value"}>
              {formatPhone(delinquentOccupant.ledger.facility?.phone)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography data-testid={"facility-address-title"} fontWeight={bold}>Address:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography data-testid={"facility-address-value"}>
              {delinquentOccupant.ledger.facility?.address}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactDetails;
