import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "StepperButtons" })((theme) => ({
  root: {},
  backButton: {
    color: theme.palette.primary.contrastText,
    textTransform: "capitalize",
    height: "3rem",
    marginRight: theme.spacing(2)
  },
  arrowBack: {
    marginRight: theme.spacing(1)
  },
  nextButtonContainer: {
    justifySelf: "flex-end"
  },
  nextButton: {
    background: theme.palette.primary.contrastText,
    color: theme.palette.common.white,
    textTransform: "capitalize",
    padding: "0 2.5rem",
    height: "3rem"
  },
  spinner: {
    color: theme.palette.common.white
  }
}));

export default useStyles;
