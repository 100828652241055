import React, { ReactElement } from "react";

export const CreatePromotionAdvisoryText =
    (isEnable: boolean): { title: string, advisoryText: string | ReactElement } => {
      let title: string;
      let advisoryText: string | ReactElement;

      switch (isEnable) {
        case true:
          title = "Re-enable Promotion?";
          advisoryText = "Re-enabling a promotion allows it to be assigned in its applied workflows";
          break;
        case false:
          title = "Disable Active Promotion?";
          // eslint-disable-next-line max-len
          advisoryText = `This promotion is currently active. Disabling this promotion will cause it to not be usable in any applicable workflows until it is re-enabled. Active occupants with the promotion applied will still have the promotion active through its current duration setting.`;
          break;
        default:
          title = "Promotion";
          advisoryText = <div>Something went wrong.</div>;
          break;
      }

      return { title, advisoryText };
    };
