import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import ModalSliceState from "./modalSliceState";
import { RootState } from "../../rootStore";
import { disableFacility } from "../../thunks/facility/disable/disableFacility";

export const initialState: ModalSliceState = {
  modalOpen: false,
  modalTitle: "",
  modalMessage: "",
  facilityDisableOpen: false,
  facilityDisableLoading: false,
  facilityDisableError: "",
  merchandiseDeleteOpen: false,
  merchandiseDeleteLoading: false,
  merchandiseDeleteError: "",
  taskModalOpen: false,
  taskData: null
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    toggleModal: (state: ModalSliceState, action) => {
      state.modalOpen = action.payload;
    },
    setTaskModalData: (state: ModalSliceState, action) => {
      state.taskData = action.payload;
    },
    setTaskModalOpen: (state: ModalSliceState, action: PayloadAction<boolean>) => {
      state.taskModalOpen = action.payload;
    },
    toggleFacilityDisableModal: (state: ModalSliceState, action: PayloadAction<boolean>) => {
      state.facilityDisableOpen = action.payload;
    },
    toggleMerchandiseDeleteModal: (state: ModalSliceState, action: PayloadAction<boolean>) => {
      state.merchandiseDeleteOpen = action.payload;
    },
    setModalTitle: (state, action: PayloadAction<string>) => {
      state.modalTitle = action.payload;
    },
    setModalMessage: (state, action: PayloadAction<string>) => {
      state.modalMessage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(disableFacility.fulfilled, (state) => {
        state.facilityDisableOpen = false;
      });
  }
});

export const modalSelector = (state: RootState) => state.modal;
export const selectTaskModalOpen = (state: RootState) => state.modal.taskModalOpen;
export const selectTaskModalData = (state: RootState) => state.modal.taskData;
export const selectModalOpen = (state: RootState) => state.modal.modalOpen;
export const selectFacilityDisableModalOpen = (state: RootState) => state.modal.facilityDisableOpen;
export const selectModalTitle = (state: RootState) => state.modal.modalTitle;
export const selectModalMessage = (state: RootState) => state.modal.modalMessage;
export const selectMerchandiseDeleteOpen = (state: RootState) => state.modal.merchandiseDeleteOpen;
export const selectMerchandiseDeleteLoading = (state: RootState) => state.modal.merchandiseDeleteLoading;

export const {
  toggleModal,
  toggleFacilityDisableModal,
  toggleMerchandiseDeleteModal,
  setModalTitle,
  setTaskModalData,
  setTaskModalOpen,
  setModalMessage
} = modalSlice.actions;

export default modalSlice.reducer;
