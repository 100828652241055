import { makeStyles } from "../../../../../makeStyles";

const useStyles = makeStyles({ label: "Edit Occupant Emergency Contact" })((theme) => ({
  saveButton: {
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText,
    width: "10rem"
  },
  backButton: {
    color: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.grey[300]}`,
    marginRight: "1rem",
    width: "10rem"
  },
  containerTitle: {
    display: "flex",
    marginBottom: "1rem"
  },
  changeOfOwnershipButton: {
    color: theme.palette.common.white,
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    marginLeft: "1rem"
  },
  icon: {
    marginRight: "0.5rem"
  },
  iconRotated: {
    marginRight: "0.5rem",
    transform: "rotate(120deg)"
  }
}));

export default useStyles;
