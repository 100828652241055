function expStringRemovesSlash(expDate: string): string {
  // Remove any non-digit characters
  const cleanedInput = expDate.replace(/\D/g, "");

  // Ensure we have at least 4 digits
  if (cleanedInput.length < 4) {
    throw new Error("Invalid expiration date format");
  }

  // Take the first 4 digits and convert to a number
  const numberOutput = cleanedInput.slice(0, 4);

  return numberOutput;
}

export default expStringRemovesSlash;
