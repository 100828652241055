import React, { FC } from "react";
import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import { selectSelectedAdminFacility } from "src/store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import { useAppSelector } from "src/store/hooks";
import useStyles from "../DelinquencyAutomationsComponents.styles";
interface DelinquencyAutomationsCTAButtonsProps { }

const DelinquencyAutomationsCTAButtons: FC<DelinquencyAutomationsCTAButtonsProps> = () => {
  const { classes } = useStyles();

  const selectedFacility = useAppSelector(selectSelectedAdminFacility);

  return selectedFacility && (
    <Button
      fullWidth
      startIcon={<Add />}
      className={classes.button}
      color={"primary"}
      variant={"contained"}
      component={NavLink}
      to={"/delinquency-automations/create-automation"}
    >
      New Automation
    </Button>
  );
};

export default DelinquencyAutomationsCTAButtons;
