import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "InfoIcon" })((theme) => ({
  root: {
    paddingLeft: "0"
  },
  item: {
    padding: "0.33rem 0"
  },
  checkIcon: {
    color: theme.palette.success.main
  }
}));

export default useStyles;
