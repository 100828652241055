import React, { ReactElement, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import PMSAdvisory from "../../../../components/ui/PMSAdvisory/PMSAdvisory";
import { Paper } from "@mui/material";
import SelectOccupant from "../../../../components/stepper/SelectOccupant/SelectOccupant";
import StepLayout from "../../../../layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "../../../../layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import { setMoveOutLedger } from "src/store/reducers/moveOutSlice/moveOutSlice";
import {
  resetSelectedOccupantSlice,
  selectSelectedOccupant
} from "../../../../store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { Ledger } from "src/models/Ledger";
import { getActiveLedgers } from "src/utils/getActiveLedgers/getActiveLedgers";
import { useNavigate } from "react-router";

const SelectOccupantForMoveOut: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const occupant = useAppSelector(selectSelectedOccupant);

  const advisoryText = (
    <>
      <p>Search for and select an existing occupant to move out.</p>
      <p>Once an occupant has been selected, you may proceed to the next step.</p>
    </>
  );

  useEffect(() => {
    dispatch(resetSelectedOccupantSlice());
  }, []);

  const onSubmitHandler = () => {
    const activeLedgers = getActiveLedgers(occupant!.ledgers!);
    if (activeLedgers!.length > 1) {
      return navigate("/occupants/create-move-out/select-unit");
    }
    dispatch(setMoveOutLedger(activeLedgers?.[0] as Ledger));
    navigate("/occupants/create-move-out/process-move-out");
  };

  return (
    <>
      <PMSAdvisory title={"Select an Occupant"} advisoryText={advisoryText} />
      <Paper elevation={1}>
        <StepLayout
          buttons={
            <StepperButtons
              data-testid={"select-move-out-occupant-buttons"}
              stepperId={"select-occupant"}
              backAction={() => navigate("/occupants/create-move-out")}
              forwardActionText={"Next"}
            />
          }
        >
          <SelectOccupant
            data-testid={"select-move-out-occupant"}
            disableAddOccupant
            onlyWithActiveLedgers
            onSubmitHandler={onSubmitHandler}
            pageSize={999}
          />
        </StepLayout>
      </Paper>
    </>
  );
};

export default SelectOccupantForMoveOut;
