const froalaConfig = {
  key: "MZC1rE1D4F4D3A14A11D6jF1QUg1Xc2OZE1ABVJRDRNGGUH1ITrA1C7A6D5E1D4B3E1B9A8==",
  height: "auto",
  heightMax: "800px",
  fontFamily: {
    "Arial,Helvetica,sans-serif": "Arial",
    "Georgia,serif": "Georgia",
    "'Times New Roman',Times,serif": "Times New Roman",
    "Verdana,Geneva,sans-serif": "Verdana"
  },
  events: {
    "image.beforeUpload": function(files: any) {
      const editor = this as any;
      if (files.length) {
        const reader = new FileReader();
        reader.onload = function() {
          editor.image.insert(reader.result, null, null, editor.image.get());
        };
        reader.readAsDataURL(files[0]);
      }

      return false;
    }
  },
  placeholderText: "Edit Your Content Here!",
  charCounterCount: false,
  toolbarButtons: {
    moreText: {
      buttons: [
        "bold",
        "italic",
        "underline",
        "strikeThrough",
        "subscript",
        "superscript",
        "fontFamily",
        "fontSize",
        "textColor",
        "backgroundColor",
        "inlineClass",
        "inlineStyle",
        "clearFormatting"
      ],
      align: "left",
      buttonsVisible: 11
    },
    moreParagraph: {
      buttons: [
        "quote",
        "alignLeft",
        "alignCenter",
        "formatOLSimple",
        "alignRight",
        "alignJustify",
        "formatOL",
        "formatUL",
        "paragraphFormat",
        "paragraphStyle",
        "lineHeight",
        "outdent",
        "indent"
      ],
      align: "left",
      buttonsVisible: 4
    },
    moreRich: {
      buttons: [
        "insertHR",
        "insertTable",
        "specialCharacters"
      ],
      align: "left"
    },
    moreMisc: {
      buttons: [
        "html",
        "undo",
        "redo",
        "fullscreen",
        "print",
        "selectAll",
        "help"
      ],
      align: "left",
      buttonsVisible: 3
    }
  }
};

export default froalaConfig;
