import { Grid, InputLabel, TextField, TextFieldProps, Typography } from "@mui/material";
import { Moment } from "moment";
import React from "react";
import { TimePicker } from "@mui/x-date-pickers";
import clsx from "clsx";
import { useController } from "react-hook-form";
import useStyles from "./Form.styles";

type FormTimePickerProps = {
  name: string;
  label: string;
  placeholder?: string;
  minTime?: Moment;
  maxTime?: Moment;
  inputFormat?: string;
  disabled?: boolean;
};

const FormTimePicker = ({ name, placeholder, label, disabled = false, ...rest }: FormTimePickerProps) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    name
  });

  const { classes } = useStyles();

  return (
    <Grid item>
      <InputLabel htmlFor={name}>
        <Typography noWrap className={clsx(classes.boldLabel)}>
          {label}
        </Typography>
      </InputLabel>

      <TimePicker
        ampm
        {...field}
        {...rest}
        disabled={disabled}
        renderInput={(params: TextFieldProps) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              placeholder: placeholder
            }}
            fullWidth
            error={!!error?.message}
            helperText={error?.message}
          />
        )}
      />
    </Grid>
  );
};

export default FormTimePicker;
