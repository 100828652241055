import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Edit Inventory Modal Styles" })((theme) => ({
  saveButton: {
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  container: {
    width: "40em",
    borderRadius: "1rem",
    padding: theme.spacing(2)
  }
}));

export default useStyles;
