import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "Merch Summary Page" })((theme) => ({
  baseButton: {
    background: theme.palette.primary.contrastText,
    color: theme.palette.common.white
  },
  modal: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
    maxWidth: "35%",
    minHeight: "10rem",
    borderRadius: "1rem"
  },
  modalContainer: {
    borderRadius: "1rem",
    padding: theme.spacing(4)
  },
  textButton: { color: theme.palette.primary.contrastText }
}));

export default useStyles;
