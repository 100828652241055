import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { MonthlyCharge } from "src/store/reducers/paymentSummarySlice/paymentSummarySliceState";
import SummaryLineItem from "../SummaryLineItem/SummaryLineItem";
import centsToDollars from "src/utils/centsToDollars/centsToDollars";
import { selectPaymentSummarySlice } from "src/store/reducers/paymentSummarySlice/paymentSummarySlice";
import { useAppSelector } from "src/store/hooks";

interface ChargesDisplayProps {}

const ChargesDisplay: FC<ChargesDisplayProps> = () => {
  const {
    currentCharges,
    requiredProjectedFutureCharges,
    requestedProjectedFutureCharges,
    requestedCustomPayment
  } = useAppSelector(selectPaymentSummarySlice);

  const renderCharges = (charges: MonthlyCharge[], category: string) => {
    const chargeCounts: Record<string, number> = {};
    const chargeTotals: Record<string, number> = {};

    const filteredCharges = charges.filter(charge => charge.pending_amount_before_tax > 0);

    filteredCharges.forEach(charge => {
      if (!chargeCounts[charge.description]) {
        chargeCounts[charge.description] = 0;
        chargeTotals[charge.description] = 0;
      }
      chargeCounts[charge.description]++;
      // eslint-disable-next-line max-len
      chargeTotals[charge.description] += charge.pending_amount_before_tax;
    });

    return (
      <div key={category}>
        <h4>{category}</h4>
        {
          filteredCharges.length > 0
            ? (
                Object.keys(chargeCounts).map(description => (
                  <SummaryLineItem
                    key={description}
                    name={`${description} x${chargeCounts[description]}`}
                    value={centsToDollars(chargeTotals[description], true)}
              />
                ))
              )
            : (
              <Grid container item xs={12}
                justifyContent={"center"} p={1}>
                <Typography align={"center"}>No information to show</Typography>
              </Grid>
              )
        }
      </div>
    );
  };

  const hasData = [
    currentCharges,
    requiredProjectedFutureCharges,
    requestedProjectedFutureCharges
  ].some(arr => arr && arr.length > 0);

  return hasData
    ? (
      <>
        {currentCharges?.length ? renderCharges(currentCharges, "Current Charges") : null}
        {requiredProjectedFutureCharges?.length && !requestedCustomPayment
          ? renderCharges(requiredProjectedFutureCharges, "Prepaid Future Charges")
          : null}
        {requestedProjectedFutureCharges?.length && !requestedCustomPayment
          ? renderCharges(requestedProjectedFutureCharges, "Prepaid Future Charges")
          : null}
      </>
      )
    : (
      <Grid container item xs={12}
        justifyContent={"center"} p={1}>
        <Typography align={"center"}>No information to show</Typography>
      </Grid>
      );
};

export default ChargesDisplay;
