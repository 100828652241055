import {
  FormControl,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  TextField
} from "@mui/material";
import React, { ChangeEvent, ReactElement } from "react";
import {
  selectAttributes,
  selectHeight,
  selectLength,
  selectPrice,
  selectProductTypeLoading,
  selectWidth,
  setAttribute,
  setHeight,
  setLength, setPrice, setWidth
} from "../../../../store/reducers/productTypeSlice/productTypeSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import PMSAdvisory from "../../../../components/ui/PMSAdvisory/PMSAdvisory";
import PMSCheckbox from "../../../../components/ui/PMSCheckbox/PMSCheckbox";
import Paper from "@mui/material/Paper";
import { ProductTypeAttribute } from "src/models/ProductTypeAttribute";
import StepLayout from "../../../../layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "../../../../layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import { useNavigate } from "react-router";
import { inputError } from "../../../../utils/showInputError/showInputError";
import productTypeAttributesValidation from "./productTypeAttributesValidation";
import { useFormik } from "formik";
import useStyles from "./ProductTypeAttributes.styles";

const ProductTypeAttributes: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector(selectProductTypeLoading);
  const unitLength = useAppSelector(selectLength);
  const unitWidth = useAppSelector(selectWidth);
  const unitHeight = useAppSelector(selectHeight);
  const streetRate = useAppSelector(selectPrice);
  const unitAttributes = useAppSelector(selectAttributes);

  const formik = useFormik({
    initialValues: {
      unitLength: unitLength,
      unitWidth: unitWidth,
      unitHeight: unitHeight,
      streetRate: streetRate
    },
    validationSchema: productTypeAttributesValidation,
    validateOnBlur: true,
    onSubmit: () => navigate("/product-types/add-product-type/import-units")
  });

  const { touched, errors, values } = formik;

  const updateForm = (fieldName: string, fieldValue?: string | boolean): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleLengthOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setLength(fieldValue));
  };

  const handleHeightOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setHeight(fieldValue));
  };

  const handleWidthOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setWidth(fieldValue));
  };

  const handlePriceOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setPrice(fieldValue));
  };

  const stepperId = "product-type-attributes-form";

  const advisoryText = (
    <>
      <p>Before we finish creating your product type, please assign the various attributes of the units within it.</p>
      <p>If you have a csv of product type attributes already, you can go back and import those beforehand.</p>
    </>
  );

  return (
    <>
      <PMSAdvisory
        title={"Set Your Product Type Attributes"}
        advisoryText={advisoryText}
      />
      <Paper elevation={1}>
        <StepLayout
          stepperId={stepperId}
          buttons={
            <StepperButtons
              data-testid={"product-type-attributes-buttons"}
              stepperId={stepperId}
              loading={loading}
              backAction={() => navigate("/product-types/add-product-type/revenue-class")}
            />
        }>
          <form
            id={stepperId}
            data-testid={stepperId}
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              formik.handleSubmit();
            }}
          >
            <Grid container justifyContent={"start"}>
              <Grid
                item
                container
                xs={9}
                lg={7}
              >
                <Grid
                  item
                  container
                  flexDirection={"column"}
                  xs={3}
                >
                  <InputLabel className={classes.selectLabel} htmlFor={"unit-length"}>
                    Unit Length
                  </InputLabel>
                  <Grid
                    item
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Grid
                      item
                      container
                      flexDirection={"row"}
                      alignItems={"center"}
                      xs={10}
                    >
                      <Grid item>
                        <TextField
                          fullWidth
                          placeholder={"- Length -"}
                          id={"unit-length"}
                          inputProps={{ "data-testid": "unit-length" }}
                          InputProps={{ endAdornment: <InputAdornment position={"end"}>ft</InputAdornment> }}
                          name={"unitLength"}
                          value={values.unitLength}
                          className={classes.noArrow}
                          type={"number"}
                          onChange={(e: ChangeEvent<{ value: string }>) =>
                            handleLengthOnChange("unitLength", e.target.value)
                          }
                          error={inputError("unitLength", touched, errors).error}
                          helperText={inputError("unitLength", touched, errors).helperText}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={1} mr={0.5}>x</Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  flexDirection={"column"}
                  xs={3}
                >
                  <InputLabel className={classes.selectLabel} htmlFor={"unit-width"}>
                    Unit Width
                  </InputLabel>
                  <Grid
                    item
                    container
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Grid
                      item
                      container
                      flexDirection={"row"}
                      alignItems={"center"}
                      xs={10}
                    >
                      <Grid item>
                        <TextField
                          fullWidth
                          placeholder={"- Width -"}
                          id={"unit-width"}
                          inputProps={{ "data-testid": "unit-width" }}
                          InputProps={{ endAdornment: <InputAdornment position={"end"}>ft</InputAdornment> }}
                          name={"unitWidth"}
                          value={values.unitWidth}
                          className={classes.noArrow}
                          type={"number"}
                          onChange={(e: ChangeEvent<{ value: string }>) =>
                            handleWidthOnChange("unitWidth", e.target.value)
                          }
                          error={inputError("unitWidth", touched, errors).error}
                          helperText={inputError("unitWidth", touched, errors).helperText}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={1} mr={0.5}>x</Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  flexDirection={"column"}
                  xs={3}
                >
                  <InputLabel className={classes.selectLabel} htmlFor={"unit-height"}>
                    Unit Height
                  </InputLabel>
                  <Grid
                    item
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Grid
                      item
                      container
                      flexDirection={"row"}
                      alignItems={"center"}
                      xs={10}
                    >
                      <Grid item>
                        <TextField
                          fullWidth
                          placeholder={"- Height -"}
                          id={"unit-height"}
                          inputProps={{ "data-testid": "unit-height" }}
                          InputProps={{ endAdornment: <InputAdornment position={"end"}>ft</InputAdornment> }}
                          name={"unitHeight"}
                          value={values.unitHeight}
                          className={classes.noArrow}
                          type={"number"}
                          onChange={(e: ChangeEvent<{ value: string }>) =>
                            handleHeightOnChange("unitHeight", e.target.value)
                          }
                          error={inputError("unitHeight", touched, errors).error}
                          helperText={inputError("unitHeight", touched, errors).helperText}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction={"column"}
                xs={2}
              >
                <InputLabel className={classes.selectLabel} htmlFor={"street-rate"} >
                  Unit Price
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={"- Rate -"}
                  id={"street-rate"}
                  inputProps={{ "data-testid": "street-rate" }}
                  name={"streetRate"}
                  value={values.streetRate}
                  className={classes.noArrow}
                  InputProps={{ startAdornment: <InputAdornment position={"start"}>$</InputAdornment> }}
                  onChange={(e: ChangeEvent<{ value: string }>) => handlePriceOnChange("streetRate", e.target.value)}
                  error={inputError("streetRate", touched, errors).error}
                  helperText={inputError("streetRate", touched, errors).helperText}
                />
              </Grid>
            </Grid>
            <Grid mt={5}>
              <InputLabel className={classes.selectLabel}>
                Unit Attributes
              </InputLabel>
              <FormControl className={classes.attributesWrapper}>
                <FormGroup>
                  <Grid container mt={1}>
                    {unitAttributes.map((attribute: ProductTypeAttribute) => {
                      return (
                        <Grid
                          key={attribute.name}
                          item
                          xs={6}
                          lg={4}
                          mb={1}
                        >
                          <PMSCheckbox
                            data-testid={"attr-checkbox"}
                            label={attribute.name}
                            name={attribute.name}
                            isChecked={attribute.value}
                            changeHandler={() => {
                              dispatch(setAttribute({
                                key: attribute.name,
                                value: !attribute.value
                              }));
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </FormGroup>
              </FormControl>
            </Grid>
          </form>
        </StepLayout>
      </Paper>
    </>
  );
};

export default ProductTypeAttributes;
