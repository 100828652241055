import { Button, ButtonProps } from "@mui/material";
import React, { ReactElement } from "react";
import { LoadingButton } from "@mui/lab";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import useStyles from "./ItemManagementFooterButtons.styles";

interface ItemManagementFooterButtonsProps {
  formId?: string
  loading?: boolean
  size?: ButtonProps["size"]
  cancelVariant?: ButtonProps["variant"]
  cancelReturnPath?: string
  confirmVariant?: ButtonProps["variant"]
  confirmType?: ButtonProps["type"]
}

const ItemManagementFooterButtons: React.FC<ItemManagementFooterButtonsProps> = ({
  formId,
  loading = false,
  size = "large",
  cancelVariant = "text",
  cancelReturnPath = "",
  confirmVariant = "contained",
  confirmType = "submit"
}): ReactElement => {
  const { classes } = useStyles();

  return (
    <>
      <Button
        variant={cancelVariant}
        className={clsx(classes.cancelButton, classes.buttonBase)}
        size={size}
        component={NavLink}
        to={cancelReturnPath}
      >
        Cancel
      </Button>
      <LoadingButton
        form={formId}
        variant={confirmVariant}
        className={clsx(classes.saveButton, classes.buttonBase)}
        size={size}
        type={confirmType}
        loading={loading}
        disabled={loading}
      >
        Save & Continue
      </LoadingButton>
    </>
  );
};

export default ItemManagementFooterButtons;
