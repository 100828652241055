import * as Yup from "yup";

const CashFormValidation = Yup.object().shape({
  cashReceivedAmount: Yup.string()
    .matches(/^\d+(\.\d{1,2})?$/, "Cash received must be a valid currency amount with up to 2 decimal places")
    .test("is-positive", "Cash received must be a positive value", (value) => {
      return parseFloat(value || "0") > 0;
    })
    .test("is-greater-than-due", "Cash received must be equal or higher than the cash due", function(value) {
      const cashReceived = parseFloat(value || "0");
      const amountDue = parseFloat((this.parent.amountDue || "0").replace(/[^0-9.]/g, ""));
      return cashReceived >= amountDue;
    })
});

export default CashFormValidation;
