import React from "react";

const ErrorPage = () => {
  return (
    <div>
      <h1>Something went wrong</h1>
      <p>
        We apologize but but something went wrong.
        If you continue to experience this error place contact support
      </p>
    </div>
  );
};

export default ErrorPage;
