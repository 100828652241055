import { Button, Grid, Typography } from "@mui/material";
import React, { ReactElement, useEffect, useCallback } from "react";
import {
  resetRevenueClassesSlice, selectDisableRevenueClassOpenModal, selectFromRevenueClassPage, selectRevenueClassesFilter,
  selectRevenueClassesLoading, selectUnableToDisableRevenueClassOpenModal,
  setFromRevenueClassPage, setRevenueClassesFilter
} from "../../../store/reducers/revenueClassesSlice/revenueClassesSlice";
import { resetSelectedAdminFacilitySlice, selectSelectedAdminFacility, setSelectedAdminFacility }
  from "../../../store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import { resetTablesSlice } from "src/store/reducers/tablesSlice/tablesSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { APIFilter } from "../../../models/responses/APIFilter";
import { AddCircle } from "@mui/icons-material";
import Advisory from "../../../components/ui/PMSAdvisory/PMSAdvisory";
import { Breadcrumb } from "../../../models/Breadcrumb";
import { Facility } from "../../../models/Facility";
import FacilitySelector from "../../../components/navigation/FacilitySelector/FacilitySelector";
import FilterButtonGroup from "../../../components/ui/FilterButtonGroup/FilterButtonGroup";
import ItemManagementLayout from "../../../layouts/ItemManagement/ItemManagementLayout";
import { NavLink, useNavigate } from "react-router-dom";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import RevenueClassesTable from "./RevenueClassesTable/RevenueClassesTable";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import clsx from "clsx";
import { getAllFacilities } from "../../../store/thunks/facility/getAll/getAllFacilities";
import { getAllPaginatedRevenueClasses }
  from "../../../store/thunks/revenueClass/getAllPaginated/getAllPaginatedRevenueClasses";
import useStyles from "./RevenueClasses.styles";
import DisableRevenueClassModal from "./DisableRevenueClassModal/DisableRevenueClassModal";
import UnableToDisableRevenueClassModal from "./UnableToDisableRevenueClassModal/UnableToDisableRevenueClassModal";
import { debounce } from "lodash";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Accounting"
  },
  {
    name: "Revenue Classes",
    bold: true
  }
];

const activeFilter: APIFilter = {
  "filter[is_active]": "1"
};

const disabledFilter: APIFilter = {
  "filter[is_active]": "0"
};

const allFilter: APIFilter = {
  "filter[is_active]": "0,1"
};

const RevenueClasses: React.FC = (): ReactElement => {
  const filter = useAppSelector(selectRevenueClassesFilter);
  const { classes } = useStyles({ filter: filter });
  const dispatch = useAppDispatch();
  const revenueClassesLoading = useAppSelector(selectRevenueClassesLoading);
  const selectedFacility = useAppSelector(selectSelectedAdminFacility);
  const modalDisable = useAppSelector(selectDisableRevenueClassOpenModal);
  const modalUnable = useAppSelector(selectUnableToDisableRevenueClassOpenModal);
  const revenueClassesFacilityId = selectedFacility?.id || 0;
  const fromRevenueClassPage = useAppSelector(selectFromRevenueClassPage);
  const permissions = useAppSelector(selectFacilityPermissions);
  const revenueClassesPermissions = createPermissions(permissions, "revenue_classes");

  const revenueClassesAdvisoryText = (
    <>
      <p>
        To manage your Revenue Classes, select which facility you’d like to view them on. Revenue Classes ensure you
        have the correct tax rates assigned to your Product Types, Retail Merchandise, etc. at each of your facility
        locations. They can also aid in your operation’s reports.
      </p>
      <p>
        You can configure your Revenue Class with a name and trackable account code, as well as define the applicable
        tax rates for the Revenue Class.
      </p>
    </>
  );

  /* istanbul ignore next */ // todo'd in appropriate test file - Conor
  const addRevenueClassButton = revenueClassesFacilityId
    ? (
      <Button
        className={classes.newRevenueClassButton}
        color={"primary"}
        variant={"text"}
        data-testid={"add-revenue-class-button"}
        startIcon={<AddCircle />}
        component={NavLink}
        to={"/accounting/revenue-classes/create"}
      >
        Add Revenue Class
      </Button>
      )
    : null;

  /* istanbul ignore next */ // todo'd in appropriate test file - Conor
  const revenueClassesTable = revenueClassesFacilityId
    ? <RevenueClassesTable data-testid={"revenue-classes-table"} />
    : null;

  const handleOnFacilityChange = async(selectedFacility: Facility) => {
    await dispatch(resetTablesSlice());
    await dispatch(setSelectedAdminFacility(selectedFacility));
    dispatch(getAllPaginatedRevenueClasses({ facilityId: selectedFacility.id, filter: filter }));
  };

  const debounceRevClasses = useCallback(
    debounce(() => dispatch(getAllFacilities()), 600),
    []
  );

  const handleReset = () => {
    if (!fromRevenueClassPage) {
      dispatch(resetRevenueClassesSlice());
      dispatch(resetSelectedAdminFacilitySlice());
      dispatch(resetTablesSlice());
    }
  };

  useEffect(() => {
    debounceRevClasses();
    if (selectedFacility) {
      dispatch(getAllPaginatedRevenueClasses({ facilityId: selectedFacility?.id, filter: filter }));
    }
  }, []);

  useEffect(() => {
    handleReset();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setFromRevenueClassPage(false));
    };
  }, []);

  const title = (
    <Grid item container alignItems={"center"}>
      <Grid item>
        <Typography
          component={"label"}
          htmlFor={"revenue-classes-facility-selector"}
          variant={"h6"}
        >
          Revenue Classes for:
        </Typography>
      </Grid>
      <Grid item ml={2}>
        <FacilitySelector
          data-testid={"revenue-classes-facility-selector"}
          navBar={false}
          onFacilityChange={handleOnFacilityChange}
          elementId={"revenue-classes-facility-selector"}
        />
      </Grid>
    </Grid>
  );

  const pageHeader = <PageHeader title={"Revenue Classes"} breadcrumbs={breadcrumbs} />;

  const filterRevenueClasses = (filter: APIFilter) => {
    dispatch(getAllPaginatedRevenueClasses({ facilityId: selectedFacility?.id, filter }));
    dispatch(setRevenueClassesFilter(filter));
  };

  const filterButtonGroup = (
    <FilterButtonGroup heading={"Filter by: "}>
      <Button
        className={clsx(classes.buttonText, classes.allFilter)}
        onClick={() => filterRevenueClasses(allFilter)}
      >
        All
      </Button>
      <Button
        className={clsx(classes.buttonText, classes.activeFilter)}
        onClick={() => filterRevenueClasses(activeFilter)}
      >
        Active
      </Button>
      <Button
        className={clsx(classes.buttonText, classes.disableFilter)}
        onClick={() => filterRevenueClasses(disabledFilter)}
      >
        Disabled
      </Button>
    </FilterButtonGroup>
  );

  if (revenueClassesPermissions.read) {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <Advisory title={"Revenue Classes"} advisoryText={revenueClassesAdvisoryText} />
        <ItemManagementLayout
          title={title}
          headerButton={revenueClassesPermissions.create ? addRevenueClassButton : null}
          loading={revenueClassesLoading}
          filters={selectedFacility && filterButtonGroup}
        >
          {revenueClassesTable}
        </ItemManagementLayout>
        {modalDisable && <DisableRevenueClassModal />}

        {modalUnable && <UnableToDisableRevenueClassModal />}
      </ViewWrapper>
    );
  } else {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <h1>You do not have permission to view this page.</h1>
      </ViewWrapper>
    );
  }
};

export default RevenueClasses;
