import React, { ReactElement } from "react";
import { Breadcrumb } from "src/models/Breadcrumb";
import GetStarted from "../../../components/stepper/GetStarted/GetStarted";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";

const GetCreateNewDocumentStarted: React.FC = (): ReactElement => {
  const advisoryText = `Bacon ipsum dolor amet sandbagger Alcatraz hamburguer pork chop,
  cousin occaecat adipisicing prosciutto lorem liberal tempor tenderloin meatball voluptate laboris.

  Chuck esse jerky elit tempor liberal
  `;

  const breadcrumbs: Breadcrumb[] = [
    {
      name: "Document Management"
    },
    {
      name: "Create Document",
      bold: true
    }
  ];

  const pageHeader = <PageHeader title={"Create New Document"} breadcrumbs={breadcrumbs} />;

  return (
    <ViewWrapper pageHeader={pageHeader}>
      <GetStarted
        title={"Create Document"}
        advisoryText={advisoryText}
        nextStepPath={"/documents/new-document/create"}
      />
    </ViewWrapper>
  );
};

export default GetCreateNewDocumentStarted;
