import { Grid, Typography } from "@mui/material";
import React, { FC, ReactNode } from "react";
import useStyles from "./LineItem.styles";
import clsx from "clsx";

export interface LineItemProps {
  name: string | ReactNode;
  amount: string;
  subLineItem?: boolean;
  transferItem?: boolean;
  smallText?: boolean;
}

const LineItem: FC<LineItemProps> = ({ name, amount, subLineItem, transferItem, smallText }) => {
  const { classes } = useStyles();

  amount = parseFloat(amount).toFixed(2);

  let amountDisplay = `$${amount}`;

  if (parseFloat(amount) < 0) {
    amountDisplay = `-$${(parseFloat(amount) * -1).toFixed(2)}`;
  }

  return (
    <Grid
      item
      container
      justifyContent={"space-between"}
      py={0.65}
      className={`${transferItem && classes.transferItem}`}
    >
      <Grid
        item
        xs={"auto"}
        component={Typography}
        variant={"subtitle2"}
        data-testid={"line-item-name"}
        className={`${classes.lineItem} ${subLineItem && classes.subLineItem}`}
      >
        {name}
      </Grid>
      <Grid
        item
        container
        justifyContent={"end"}
        xs={3.5}
        component={Typography}
        variant={"subtitle2"}
        data-testid={`line-item-amount-${name}`}
        className={clsx({ [classes.lineItem]: !smallText, [classes.subLineItem]: subLineItem })}
      >
        {amountDisplay}
      </Grid>
    </Grid>
  );
};

export default LineItem;
