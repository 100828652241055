import DelinquencyAutomation from "src/models/DelinquencyAutomations";

const sortAutomationsList = (automations: DelinquencyAutomation[]): DelinquencyAutomation[] => {
  const originalsInOrder = automations
    .filter(automation => automation.is_original)
    .sort((a, b) => a.days > b.days ? 1 : -1);

  const restInOrder = automations
    .sort((a, b) => a.days > b.days ? 1 : -1)
    .filter(automation => !automation.is_original);

  return [...originalsInOrder, ...restInOrder];
};

export default sortAutomationsList;
