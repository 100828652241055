import moment, { Moment } from "moment";

export const formatDate = (
  date: Moment | null | string,
  dateFormat = "MM/DD/YYYY",
  noDateDisplay = ""
) => {
  if (!date) {
    return noDateDisplay;
  }

  const returnedDate = moment(date);

  // moment will coerce incomplete values in input fields like "10/20/202" to "10/20/0202". This will break our
  // current validation. The long-term solution is probably to re-think how date inputs get handled with formik, yup
  // etc. but for now this will respect our current architecture and still enforce a full, correct date to be
  // input by the user.
  if (Number(returnedDate.year() < 1900)) {
    return "N/A";
  }

  return moment(date).format(dateFormat);
};
