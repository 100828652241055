import { Grid, Typography } from "@mui/material";
import React, { FC, ReactElement, ReactNode } from "react";
import clsx from "clsx";
import useStyles from "./SummaryLineItem.styles";

export interface SummaryLineItemProps {
  name: string | ReactNode;
  value: string | number | boolean | null;
  subLineItem?: boolean;
  transferItem?: boolean;
  smallText?: boolean;
}

interface RenderLineItemArguments {
  (
    name: string | React.ReactNode,
    valueDisplay: string | null,
    classes: Record<"transferItem" | "lineItem" | "subLineItem" | "incDecWrapper", string>,
    subLineItem?: boolean,
    transferItem?: boolean,
    smallText?: boolean
  ): ReactElement;
}

interface FormatValueFunc {
  (val: string | number | boolean | null): string | null;
}

const formatValue: FormatValueFunc = (val) => {
  if (val === null) {
    return null;
  }
  if (typeof val === "boolean") {
    return val ? "Yes" : "No";
  }

  return val.toString();
};

const renderLineItem: RenderLineItemArguments = (
  name,
  valueDisplay,
  classes,
  subLineItem = false,
  transferItem = false,
  smallText = false
) => (
  <Grid
    item
    container
    justifyContent={"space-between"}
    py={0.65}
    className={clsx({ [classes.transferItem]: transferItem })}
  >
    <Grid
      item
      xs={"auto"}
      component={Typography}
      variant={"subtitle2"}
      data-testid={"line-item-name"}
      className={clsx({ [classes.lineItem]: true, [classes.subLineItem]: subLineItem })}
    >
      {name}
    </Grid>
    <Grid
      item
      container
      justifyContent={"end"}
      xs={3.5}
      component={Typography}
      variant={"subtitle2"}
      data-testid={`line-item-value-${name}`}
      className={clsx({ [classes.lineItem]: !smallText, [classes.subLineItem]: subLineItem })}
    >
      {valueDisplay}
    </Grid>
  </Grid>
);

const SummaryLineItem: FC<SummaryLineItemProps> = ({ name, value, subLineItem = false, transferItem, smallText }) => {
  const { classes } = useStyles();
  const valueDisplay = formatValue(value);

  return renderLineItem(
    name,
    valueDisplay,
    classes,
    subLineItem,
    transferItem,
    smallText
  );
};

export default SummaryLineItem;
