import { Button, Grid, InputLabel, Modal, Paper, TextField, Typography } from "@mui/material";
import React, { Dispatch, FC, FormEvent, SetStateAction, useEffect, useState } from "react";
import {
  selectFacilityAccessTableModal,
  selectFacilitySelectedAccessTable,
  setFacilityAccessTableModal,
  setSelectedAccessTable
}
  from "src/store/reducers/facilitySlice/facilitySlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { LoadingButton } from "@mui/lab";
import { inputError } from "src/utils/showInputError/showInputError";
import { useFormik } from "formik";
import useStyles from "./NewAccessModal.styles";
import newAccessModalValidation from "./newAccessModalValidation";
import { createNewTimeAccess } from "src/store/thunks/facilitySetting/timeAccess/createNewTimeAccess";
import { createNewKeypadAccess } from "src/store/thunks/facilitySetting/keypadAccess/createNewKeypadAccess";
import { getFacility } from "src/store/thunks/facility/get/getFacility";
import { editTimeAccess } from "src/store/thunks/facilitySetting/timeAccess/editTimeAccess";
import { editKeypadAccess } from "src/store/thunks/facilitySetting/keypadAccess/editKeypadAccess";

interface NewAccessModalProps {
  facilityId: number;
}

const NewAccessModal: FC<NewAccessModalProps> = ({ facilityId }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const facilityAccessTableModal = useAppSelector(selectFacilityAccessTableModal);
  const selectedAccessTable = useAppSelector(selectFacilitySelectedAccessTable);
  const [edit, setEdit] = useState(false);

  const formik = useFormik({
    initialValues: {
      zone: selectedAccessTable?.zone || "",
      accessName: selectedAccessTable?.name || ""
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: newAccessModalValidation,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      let action;
      if (edit) {
        action = facilityAccessTableModal.type === "timeAccess" ? editTimeAccess : editKeypadAccess;
      } else {
        action = facilityAccessTableModal.type === "timeAccess" ? createNewTimeAccess : createNewKeypadAccess;
      }
      const payload = {
        ...values,
        zone: +values.zone < 10 ? `0${+values.zone}` : values.zone.toString()
      };
      dispatch(action(payload)).then((resp: any) => {
        if (resp.type.includes("fulfilled")) {
          dispatch(getFacility(facilityId));
          dispatch(setFacilityAccessTableModal({ open: false, type: "" }));
          dispatch(setSelectedAccessTable(null));
          setEdit(false);
          resetForm();
        }
      });
      setSubmitting(false);
    }
  });

  const { values, resetForm, handleSubmit, handleChange, isSubmitting, touched, errors, setFieldValue } = formik;

  useEffect(() => {
    setFieldValue("zone", selectedAccessTable?.zone || "");
    setFieldValue("accessName", selectedAccessTable?.name || "");

    if (selectedAccessTable?.zone) {
      setEdit(true);
    }
  }, [facilityAccessTableModal.open]);

  const handleClose = () => {
    dispatch(setFacilityAccessTableModal({
      open: false,
      type: ""
    }));
    dispatch(setSelectedAccessTable(null));
    setEdit(false);
    resetForm({ values: formik.initialValues });
  };

  return (
    <Modal className={classes.modal} onClose={handleClose} open={facilityAccessTableModal.open}>
      <Grid container p={4} className={classes.root}
        component={Paper} elevation={0}>
        <Grid
          container
          spacing={1}
          component={"form"}
          onSubmit={(e: FormEvent) => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit();
          }}
        >
          <Grid item xs={12} textAlign={"center"}>
            <Typography variant={"h5"}>
              {edit ? "Edit" : "Add"} {facilityAccessTableModal.type === "timeAccess" ? "Time" : "Keypad"} Access
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor={"zone"}>Zone</InputLabel>
            <TextField
              size={"small"}
              fullWidth
              id={"zone"}
              name={"zone"}
              value={values.zone}
              onChange={handleChange}
              error={inputError("zone", touched, errors).error}
              helperText={inputError("zone", touched, errors).helperText}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor={"accessName"}>Access Name</InputLabel>
            <TextField
              name={"accessName"}
              fullWidth
              size={"small"}
              value={values.accessName}
              onChange={handleChange}
              error={inputError("accessName", touched, errors).error}
              helperText={inputError("accessName", touched, errors).helperText}
            />
          </Grid>
          {/* buttons */}
          <Grid container spacing={2} justifyContent={"center"}
            item xs={12} mt={1}>
            <Grid item xs={2}>
              <Button onClick={handleClose} variant={"outlined"} color={"error"}
                fullWidth>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={2}>
              <LoadingButton
                onClick={() => handleSubmit()}
                className={classes.buttonBase}
                variant={"contained"}
                fullWidth
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default NewAccessModal;
