import React, { FC } from "react";
import { ESignStatusTableColumns } from "./ESignStatusTableColumns";
import EmptyTable from "src/components/ui/PMSTable/EmptyTable/EmptyTable";
import PMSTable from "src/components/ui/PMSTable/PMSTable";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  selectEsignDocuments,
  selectEsignDocumentsLoading
} from "../../../../store/reducers/esignDocumentsSlice/esignDocumentsSlice";
import {
  getAllEsignDocumentsForFacility
} from "src/store/thunks/esignDocument/getAllForFacility/getAllEsignDocumentsForFacility";
import { selectSelectedAdminFacility } from "src/store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import { selectESignDocumentsFilter } from "src/store/reducers/eSignStatusSlice/eSignStatusSlice";

const emptyTable = () => <EmptyTable collectionNotFound={`documents`} value={"document"} addLink={"/"} />;

interface ESignStatusTableProps {
  handlePaginationUpdate: () => void;
}

const ESignStatusTable: FC<ESignStatusTableProps> = ({ handlePaginationUpdate }) => {
  const eSignStatusDocuments = useAppSelector(selectEsignDocuments);
  const eSignStatusDocumentsLoading = useAppSelector(selectEsignDocumentsLoading);
  const dispatch = useAppDispatch();
  const selectedFacility = useAppSelector(selectSelectedAdminFacility);
  const filter = useAppSelector(selectESignDocumentsFilter);

  return (
    <PMSTable
      loading={eSignStatusDocumentsLoading}
      columns={ESignStatusTableColumns}
      rows={eSignStatusDocuments}
      emptyTableDisplay={emptyTable}
      onPaginationUpdate={handlePaginationUpdate}
    />
  );
};

export default ESignStatusTable;
