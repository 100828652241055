import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const exportPromotions = createAsyncThunk(
  "exportPromotions",
  (operatorId: number, { dispatch, rejectWithValue }) => {
    return axios.get(`/api/v1/promotions/export`, { responseType: "blob" })
      .then(response => {
        const blob = new Blob([response.data], { type: "text/csv" });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = downloadUrl;
        link.setAttribute("download", `active_promotions.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        dispatch(showSnackbar({
          message: "Promotions exported successfully.",
          variant: "success"
        }));
        return response.data;
      })
      .catch(() => {
        const errorMessage = "There was an error exporting the Promotions.";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
