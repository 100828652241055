import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Select Unit Upload Modal" })((theme) => ({
  buttonBase: {
    textTransform: "uppercase",
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main
    }
  }
}));

export default useStyles;
