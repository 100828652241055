import {
  PatternFormat,
  PatternFormatProps
} from "react-number-format";
import React, { ChangeEventHandler, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";

interface NumberFormatCustomProps {
  inputRef: (instance: PatternFormatProps | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const NumberFormatCustom = React.forwardRef<PatternFormatProps, NumberFormatCustomProps>(
  function NumberFormatCustom(props, ref) {
    const { inputRef, onChange, ...other } = props;

    return (
      <PatternFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          });
        }}
        format={"##/##"}
        mask={"_"}
      />
    );
  }
);

interface MaskedExpirationDateProps {
  id: string;
  dataTestId?: string;
  error?: boolean;
  fullWidth?: boolean;
  helperText?: string | false;
  name: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  type?: string;
  variant?: "standard" | "filled" | "outlined";
  value: number | string;
  disabled?: boolean;
  onBlur?: () => void;
}

export default function MaskedExpirationDate(props: MaskedExpirationDateProps) {
  const { id, value, name, onChange, type = "text", dataTestId, onBlur, ...options } = props;
  const [fieldVal, setFieldVal] = useState("");

  const formatExpDate = (val: string) => {
    const cleaned = val.replace(/\D/g, "");
    const limited = cleaned.slice(0, 4);
    return limited.length === 4 ? `${limited.slice(0, 2)}/${limited.slice(2)}` : limited;
  };

  useEffect(() => {
    // Pre-format the value
    const formatted = formatExpDate(String(value));
    setFieldVal(formatted);
  }, [value]);
  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setFieldVal(event.target.value);
    onChange(event);
  };

  return (
    <TextField
      id={id}
      data-testid={dataTestId}
      data-mask-sensitive={true}
      fullWidth={options.fullWidth}
      variant={options.variant}
      placeholder={options.placeholder}
      onChange={handleChange}
      autoComplete={"cc-exp"}
      value={fieldVal}
      type={type}
      name={name}
      inputProps={{ "data-testid": dataTestId }}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
        inputProps: {
          value: fieldVal,
          onChange: handleChange
        }
      }}
      error={options.error}
      helperText={options.helperText}
      disabled={options.disabled}
      onBlur={onBlur}
    />
  );
}
