import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Facility Settings Alert" })((theme) => ({
  root: {
    "& .MuiAlert-message": {
      minWidth: "100%"
    },
    "& .MuiAlert-icon": {
      display: "flex",
      alignSelf: "center"
    },
    marginBottom: theme.spacing(2)
  }
}));

export default useStyles;
