import React, { ReactElement } from "react";
import GetStarted from "../../../../components/stepper/GetStarted/GetStarted";

const GetCreateNewDealStarted: React.FC = (): ReactElement => {
  const advisoryText = "You can add a new Deal to the system using the following workflow. You will be able to tie an" +
  " occupant and unit to the Deal, as well as identify it as a Lead or Reservation. The workflow will also" +
  " allow you to set important dates and promotions for the Deal.";

  return (
    <GetStarted
      title={"Create a New Deal"}
      advisoryText={advisoryText}
      nextStepPath={"/deals/create-new-deal/select-occupant"}
    />
  );
};

export default GetCreateNewDealStarted;
