import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getAutomation = createAsyncThunk("getAutomation",
  ({ id, facilityId }: { id: string, facilityId: string }, { dispatch, getState, rejectWithValue }) => {
    const params = {
      // include_get_usage_counts: true,
    };

    return axios.get(`/api/v1/facilities/${facilityId}/system-automations/${id}`, params)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = `There was an error getting automation ${id}`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
