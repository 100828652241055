import {
  selectAutomaticPaymentSlice,
  setAutobillingConfirmationModalOpened,
  setAwaitingSignatureSubmissionModalOpened,
  setSignatureAcceptedModalOpened
} from "src/store/reducers/automaticPaymentsSlice/automaticPaymentsSlice";
import {
  selectTransferUnitLoading,
  selectTransferUnitModalOpen,
  setTransferUnitModalOpen
} from "src/store/reducers/transferUnitSlice/transferUnitSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";

import ESignDocument from "src/models/ESignDocument";
import React from "react";
import { Typography } from "@mui/material";
import WarningModal from "src/components/modal/WarningModal";
import { getEsignDocumentById } from "src/store/thunks/esignDocument/getById/getEsignDocumentById";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";

const AwaitingSignatureSubmissionModal = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectTransferUnitLoading);
  const { awaitingSignatureSubmissionModalOpened, currentESignDocument } = useAppSelector(selectAutomaticPaymentSlice);

  const handleSubmit = async() => {
    const { payload } = await dispatch(getEsignDocumentById({
      id: currentESignDocument?.id!
    }));

    if (payload?.is_signed) {
      dispatch(setSignatureAcceptedModalOpened(true));
      dispatch(setAwaitingSignatureSubmissionModalOpened(false));
    } else {
      dispatch(
        showSnackbar({
          message: "Auto-Bill document has not been signed yet.",
          variant: "error"
        })
      );
    }
  };

  const handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      dispatch(setAutobillingConfirmationModalOpened(true));
      dispatch(setAwaitingSignatureSubmissionModalOpened(false));
    }
  };

  return (
    <WarningModal
      open={awaitingSignatureSubmissionModalOpened}
      buttonLabel={"Retry"}
      onButtonClick={handleSubmit}
      onCloseClick={handleClose}
      title={"Awaiting Signature Submission"}
      loading={loading}
      sx={{ textAlign: "center" }}
      >
      <>
        <Typography>Please ensure the occupant has signed the applicable autobill form for your facility.</Typography>
      </>
    </WarningModal>
  );
};

export default AwaitingSignatureSubmissionModal;
