import { dateSchema } from "src/components/form/schema/dateSchema";
import { z } from "zod";

const FlagForAuctionValidation = z.object({
  auctionTime: dateSchema,
  auctionDate: dateSchema
});

export type FlagForAuctionValidationType = z.infer<typeof FlagForAuctionValidation>;

export default FlagForAuctionValidation;
