import { Grid, Paper, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import PMSAdvisory from "../../../../components/ui/PMSAdvisory/PMSAdvisory";
import SelectPromotion from "../../../../components/stepper/SelectPromotion/SelectPromotion";
import StepLayout from "../../../../layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "../../../../layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import { createLead } from "../../../../store/thunks/lead/create/createLead";
import { createReservation } from "../../../../store/thunks/reservation/create/createReservation";
import { useNavigate } from "react-router";
import { selectDealLoading } from "../../../../store/reducers/dealSlice/dealSlice";
import { selectDealType } from "../../../../store/reducers/dealInformationSlice/dealInformationSlice";
import { selectSelectedFacility } from "../../../../store/reducers/selectedFacilitySlice/selectedFacilitySlice";

const SelectPromotionForDeal: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const dealType = useAppSelector(selectDealType);
  const loading = useAppSelector(selectDealLoading);

  const advisoryText = (
    <>
      <p>Here you are able to select a promotion for a unit if applicable.</p>
      <p>
        You can view a list of available promotions at your facility. This list also displays promotion attributes
        that can be used to guide an occupant towards the one that best fits their needs.
      </p>
    </>
  );

  const submitHandler = () => {
    const dealThunk = dealType === "lead" ? createLead() : createReservation();
    dispatch(dealThunk)
      .then(resp => {
        if (!resp.type.includes("rejected")) {
          navigate("/deals/manage-deals");
        }
      });
  };

  return (
    <>
      <PMSAdvisory
        title={"Select a Promotion"}
        data-testid={"promotion-advisory"}
        advisoryText={advisoryText}
        allowSkip
        skipHandler={submitHandler}
      />
      <Paper elevation={1}>
        <StepLayout
          buttons={
            <StepperButtons
              data-testid={"select-deal-promotion-buttons"}
              backAction={() => navigate("/deals/create-new-deal/select-unit")}
              forwardAction={submitHandler}
              loading={loading}
              forwardActionText={"Finish"}
            />}
        >
          <Grid
            component={Typography}
            mb={2}
            variant={"h6"}
          >
            Available Promotions at {selectedFacility?.name}
          </Grid>
          <SelectPromotion />
        </StepLayout>
      </Paper>
    </>
  );
};

export default SelectPromotionForDeal;
