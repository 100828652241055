import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles<{ color: string }>({ label: "PMSTaskCategoryBadge" })((theme, { color }) => ({
  dot: {
    marginRight: theme.spacing(2)
  },
  color: { backgroundColor: color }

}));

export default useStyles;
