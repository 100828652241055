import { FormControl, FormGroup, InputLabel, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { Fee } from "src/models/Fee";
import { FormikValues } from "formik";
import ItemSelector from "../CreateMoveIn/ProcessMoveIn/ItemSelector/ItemSelector";
import { formattedAmount } from "src/utils/formattedAmount/formattedAmount";
import { isEmpty } from "lodash";
import { selectSelectedUnit } from "src/store/reducers/selectedUnitSlice/selectedUnitSlice";
import { selectWaiveFee } from "src/store/reducers/moveInSlice/moveInSlice";
import { useAppSelector } from "src/store/hooks";
import useStyles from "./FeesList.styles";

export interface FeeListProps {
  title: string;
  fees: Array<Fee>;
  isFeeChecked(id: number): boolean;
  isDisabled?(id: number): boolean;
  handleChange(event: React.ChangeEvent<HTMLInputElement>): Promise<void> | void;
  removeFee(id: number): Promise<void> | void;
  handleWaiveFeeOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  formik?: FormikValues;
}

const FeesList: React.FC<FeeListProps> = ({
  title,
  fees,
  isFeeChecked,
  handleChange,
  removeFee,
  handleWaiveFeeOnChange,
  isDisabled,
  formik
}): ReactElement => {
  const { classes } = useStyles();
  const waiveMoveInFee = useAppSelector(selectWaiveFee);
  const selectedUnit = useAppSelector(selectSelectedUnit);

  const feeList = fees?.map((fee) => (
    <ItemSelector
      key={fee.id}
      item={fee}
      isChecked={isFeeChecked(fee.id) || fee.is_required}
      itemLabel={`${fee.name} ${fee.recurrence === 1 ? "(one-time)" : "(recurring)"}`}
      itemPrice={formattedAmount(fee.type, fee.amount)}
      changeHandler={handleChange}
      removeItem={removeFee}
      formik={formik}
      isDisabled={isDisabled && isDisabled(fee.id)}
    />
  ));

  const waiveFee = () => (
    <ItemSelector
      item={{ name: "Waive Rental Fee", id: -1, quantity_for_cart: 0 }}
      isChecked={waiveMoveInFee.waive}
      itemLabel={"Waive Rental Fee"}
      itemPrice={""}
      changeHandler={handleChange}
      removeItem={removeFee}
      handleWaiveFeeOnChange={handleWaiveFeeOnChange}
      formik={formik}
    />
  );

  return (
    <>
      <InputLabel className={classes.headerCell} htmlFor={"fees"}>
        <Typography noWrap>{title}</Typography>
      </InputLabel>
      <FormControl className={classes.feesListWrapper} component={"fieldset"} variant={"standard"}>
        <FormGroup data-testid={"fees-display"}>
          {fees?.length
            ? (
              <>
                {feeList}
                {!isEmpty(selectedUnit) && waiveFee()}
              </>
              )
            : (
              <Typography>No fees available at this facility</Typography>
              )}
        </FormGroup>
      </FormControl>
    </>
  );
};

export default FeesList;
