import { GridRowId, GridSortDirection, GridSortModel } from "@mui/x-data-grid";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { APIListMetaData } from "../../../models/responses/APIListMetaData";
import { APIListResponse } from "../../../models/responses/APIListResponse";
import { Deal } from "../../../models/Deal";
import { Occupant } from "../../../models/Occupant";
import { ProductType } from "src/models/ProductType";
import { RootState } from "../../rootStore";
import { TablesSliceState } from "./TablesSliceState";
import { Transaction } from "src/models/Transaction";
import { Unit } from "src/models/Unit";
import { delinquentOccupantsApi } from "src/api/endpoints/delinquentOccupants";
import { getAllActiveDeals } from "../../thunks/deals/getAllActive/getAllActiveDeals";
import { getAllEsignDocumentsForFacility }
  from "src/store/thunks/esignDocument/getAllForFacility/getAllEsignDocumentsForFacility";
import { getAllEsignTemplates } from "../../thunks/esignTemplates/getAll/getAllEsignTemplates";
import { getAllMerchandise } from "../../thunks/merchandise/getAll/getAllMerchandise";
import { getAllOccupants } from "../../thunks/occupant/getAll/getAllOccupants";
import {
  getAllPaginatedDocumentTemplates
} from "src/store/thunks/documentTemplates/getAll/getAllPaginatedDocumentTemplates";
import { getAllPaginatedFacilities } from "../../thunks/facility/getAllPaginated/getAllPaginatedFacilities";
import { getAllPaginatedOccupantDocuments } from "src/store/thunks/occupantDocuments/getAllPaginatedOccupantDocuments";
import { getAllPaginatedProductTypes } from "src/store/thunks/productType/getAllPaginated/getAllPaginatedProductTypes";
import { getAllPaginatedRevenueClasses } from "../../thunks/revenueClass/getAllPaginated/getAllPaginatedRevenueClasses";
import { getAllPromotionsForFacility } from "../../thunks/promotion/getAllForFacility/getAllPromotionsForFacility";
import { getAllUnitsNew } from "src/store/thunks/unitNew/get/getAllUnitsNew";
import {
  getAvailableUnitsByProductType
} from "../../thunks/unit/getAvailableUnitsByProductType/getAvailableUnitsByProductType";
import { getChartOfAccountSettings } from "src/store/thunks/chartOfAccounts/get/getChartOfAccountsSettings";
import { getMerchInventory } from "src/store/thunks/inventory/getMerchInventory/getMerchInventory";
import { getOccupantHistory } from "src/store/thunks/occupantHistory/getAll/getOccupantHistory";
import { getTasks } from "src/store/thunks/task/getAll/getTasks";
import { getTransactions } from "src/store/thunks/transactions/getTransaction/getTransactions";
import { getUnitsByProductType } from "../../thunks/unit/getUnitsByProductType/getUnitsByProductType";
import { searchChartOfAccountSettings } from "src/store/thunks/chartOfAccounts/search/searchChartOfAccountsSettings";
import { searchOccupants } from "../../thunks/occupant/search/searchOccupants";
import { searchProductTypes } from "src/store/thunks/productType/search/searchProductTypes";
import { searchTransaction } from "src/store/thunks/transactions/searchTransaction/searchTransaction";
import { webhooksApi } from "src/api/endpoints/webhooks";

export const updatePagination = (state: TablesSliceState, paginationData: APIListMetaData) => {
  state.page = paginationData.current_page - 1; // b/c MUI tables are 0-indexed, we need to decrement w/e returns by 1
  state.rowsPerPage = (typeof paginationData.per_page === "string")
    ? parseInt(paginationData.per_page)
    : paginationData.per_page;
  state.totalItems = paginationData.total;
};

export const initialState: TablesSliceState = {
  order: "desc",
  orderBy: "id",
  page: 0,
  pageCount: 0,
  totalItems: 0,
  totalPages: 0,
  sortModel: [],
  rowsPerPage: 50,
  selectedRows: [],
  rowsPerPageOptions: [5, 10, 25, 50],
  openExpansionRows: [],
  expansionRowsLoading: [],
  searchValue: ""
};

export const tablesSlice = createSlice({
  name: "tables",
  initialState,
  reducers: {
    setOrder: (state, action: PayloadAction<GridSortDirection>) => {
      state.order = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    setPageCount: (state, action: PayloadAction<number>) => {
      state.pageCount = action.payload;
    },
    resetPage: (state) => {
      state.page = 0;
    },
    resetRowsPerPage: (state) => {
      state.rowsPerPage = initialState.rowsPerPage;
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.rowsPerPage = action.payload;
    },
    setSortModel: (state, action: PayloadAction<GridSortModel>) => {
      state.sortModel = action.payload;
    },
    setTotalItems: (state, action: PayloadAction<number>) => {
      state.totalItems = action.payload;
    },
    setSelectedRows: (state, action: PayloadAction<GridRowId[]>) => {
      state.selectedRows = action.payload;
    },
    setOpenExpansionRows: (state, action: PayloadAction<number>) => {
      state.openExpansionRows.includes(action.payload)
        ? state.openExpansionRows = state.openExpansionRows.filter(row => row !== action.payload)
        : state.openExpansionRows.push(action.payload);
    },
    resetOpenExpansionRows: (state) => {
      state.openExpansionRows = [];
    },
    setExpansionRowsLoading: (state, action: PayloadAction<number>) => {
      state.expansionRowsLoading.includes(action.payload)
        ? state.expansionRowsLoading = state.expansionRowsLoading.filter(row => row !== action.payload)
        : state.expansionRowsLoading.push(action.payload);
    },
    resetTablesSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAvailableUnitsByProductType.fulfilled, (state) => {
        state.expansionRowsLoading = [];
      })
      .addCase(getAllActiveDeals.fulfilled, (state, action: PayloadAction<APIListResponse<Deal[]>>) => {
        updatePagination(state, action.payload.meta);
      })
      .addCase(getAllEsignTemplates.fulfilled, (state, action: PayloadAction<APIListResponse<Deal[]>>) => {
        updatePagination(state, action.payload.meta);
      })
      .addCase(getAllMerchandise.fulfilled, (state, action: PayloadAction<APIListResponse<Deal[]>>) => {
        updatePagination(state, action.payload.meta);
      })
      .addCase(getAllOccupants.fulfilled, (state, action: PayloadAction<APIListResponse<Deal[]>>) => {
        updatePagination(state, action.payload.meta);
      })
      .addCase(getAllPaginatedFacilities.fulfilled, (state, action: PayloadAction<APIListResponse<Deal[]>>) => {
        updatePagination(state, action.payload.meta);
      })
      .addCase(getAllPaginatedRevenueClasses.fulfilled, (state, action: PayloadAction<APIListResponse<Deal[]>>) => {
        updatePagination(state, action.payload.meta);
      })
      .addCase(getAllPromotionsForFacility.fulfilled, (state, action: PayloadAction<APIListResponse<Deal[]>>) => {
        updatePagination(state, action.payload.meta);
      })
      .addCase(getUnitsByProductType.fulfilled, (state, action: PayloadAction<APIListResponse<Deal[]>>) => {
        updatePagination(state, action.payload.meta);
      })
      .addCase(getAllPaginatedProductTypes.fulfilled, (state, action: PayloadAction<APIListResponse<Deal[]>>) => {
        updatePagination(state, action.payload.meta);
      })
      .addCase(searchOccupants.fulfilled, (state, action: PayloadAction<APIListResponse<Occupant[]>>) => {
        updatePagination(state, action.payload.meta);
      })
      .addCase(getTasks.fulfilled, (state, action) => {
        updatePagination(state, action.payload.meta);
      })
      .addCase(getChartOfAccountSettings.fulfilled, (state, action) => {
        updatePagination(state, action.payload.meta);
      })
      .addCase(searchChartOfAccountSettings.fulfilled, (state, action) => {
        updatePagination(state, action.payload.meta);
      })
      .addCase(searchProductTypes.fulfilled, (state, action: PayloadAction<APIListResponse<ProductType[]>>) => {
        updatePagination(state, action.payload.meta);
      })
      .addCase(getTransactions.fulfilled, (state, action: PayloadAction<APIListResponse<Transaction[]>>) => {
        updatePagination(state, action.payload.meta);
      })
      .addCase(getAllUnitsNew.fulfilled, (state, action: PayloadAction<APIListResponse<Unit[]>>) => {
        updatePagination(state, action.payload.meta);
      })
      .addCase(getAllPaginatedDocumentTemplates.fulfilled, (state, action) => {
        updatePagination(state, action.payload.meta);
      })
      .addCase(getAllPaginatedOccupantDocuments.fulfilled, (state, action) => {
        updatePagination(state, action.payload.meta);
      })
      .addCase(getAllEsignDocumentsForFacility.fulfilled, (state, action) => {
        updatePagination(state, action.payload.meta);
      })
      .addCase(getMerchInventory.fulfilled, (state, action) => {
        updatePagination(state, action.payload.meta);
      })
      .addCase(searchTransaction.fulfilled, (state, action) => {
        updatePagination(state, action.payload.meta);
      })
      .addCase(getOccupantHistory.fulfilled, (state, action) => {
        updatePagination(state, action.payload.meta);
      })
      .addMatcher(
        delinquentOccupantsApi.endpoints.getDelinquentOccupants.matchFulfilled,
        (state, action) => {
          if (action.payload) {
          // Assuming the action.payload structure includes a 'meta' key
            updatePagination(state, action.payload.meta);
          }
        })
      .addMatcher(webhooksApi.endpoints.getWebhooks.matchFulfilled, (state, action) => {
        updatePagination(state, action.payload.meta);
      });
  }
});

export const selectOrder = (state: RootState) => state.tables.order;
export const selectTotalItems = (state: RootState) => state.tables.totalItems;
export const selectTotalPages = (state: RootState) => state.tables.totalPages;
export const selectOrderBy = (state: RootState) => state.tables.orderBy;
export const selectPage = (state: RootState) => state.tables.page;
export const selectPageCount = (state: RootState) => state.tables.pageCount;
export const selectRowsPerPage = (state: RootState) => state.tables.rowsPerPage;
export const selectRowsPerPageOptions = (state: RootState) => state.tables.rowsPerPageOptions;
export const selectSortModel = (state: RootState) => state.tables.sortModel;
export const selectOpenExpansionRows = (state: RootState) => state.tables.openExpansionRows;
export const selectExpansionRowsLoading = (state: RootState) => state.tables.expansionRowsLoading;
export const selectSearchValue = (state: RootState) => state.tables.searchValue;
export const selectSelectedRows = (state: RootState) => state.tables.selectedRows;

export const {
  setOrder,
  setOrderBy,
  setPage,
  resetPage,
  setSearchValue,
  resetRowsPerPage,
  setPageCount,
  setRowsPerPage,
  setSortModel,
  setTotalItems,
  setSelectedRows,
  setOpenExpansionRows,
  setExpansionRowsLoading,
  resetOpenExpansionRows,
  resetTablesSlice
} = tablesSlice.actions;

export default tablesSlice.reducer;
