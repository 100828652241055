import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  routingNumber: Yup.string().required("Routing Number is required").min(9, "Routing Number must be 9 digits")
    .matches(/^\d+$/, "Routing number should have digits only"),
  bankName: Yup.string().required("Bank Name is required"),
  accountNumber: Yup.string().required("Account Number is required"),
  accountNumberConfirm: Yup.string().required("Confirmed Account Number is required"),
  accountName: Yup.string().required("Account Name is required").min(3, "Name on Account must be at least 3 letters")
    .matches(/^(\w+)\s(\w+)/, "Account name should be in the format of \"First Last\""),
  checkNumber: Yup.string().when("isOccupantBillingInformation", {
    is: false,
    then: Yup.string().required("Check Number is required")
  })
});

export default validationSchema;
