import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  description: Yup
    .string()
    .max(500, "Note description cannot be longer than 500 characters")
    .required("New note description is required")

});

export default validationSchema;
