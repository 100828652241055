import { CreateTaskPayload } from "./CreateTaskPayload";
import { RootState } from "../../../rootStore";
import { TaskValues } from "../../../../models/formikInputValues/TaskValues";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { formatDate } from "../../../../utils/__dateAndTimeUtils__/formatDate/formatDate";
import moment from "moment";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const createTask = createAsyncThunk(
  "createTask",
  (taskFormValues: TaskValues, { dispatch, getState, rejectWithValue }) => {
    const getAssignees = () => {
      let facilitiesArray = taskFormValues.taskFacilities;

      if (facilitiesArray.includes("all")) {
        facilitiesArray = (getState() as RootState).facilities.facilities.map(facility => facility.id);
      }

      return facilitiesArray;
    };

    const payload: CreateTaskPayload = {
      name: taskFormValues.taskName,
      task_category_id: Number(taskFormValues.taskCategoryId),
      assignee: getAssignees(),
      due_date: formatDate(taskFormValues.taskDueDate, "YYYY-MM-DD"),
      due_time: moment(taskFormValues.taskDueTime).format("hh:mm A"),
      recurrence: Number(taskFormValues.taskRecurrence),
      is_all_day: taskFormValues.taskIsAllDay,
      description: taskFormValues.taskDescription
    };

    return axios.post("/api/v1/tasks", payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Task created successfully",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = `There was an error creating the Task`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
