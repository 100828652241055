import { Button, Divider, Grid, Modal, Paper, Typography } from "@mui/material";
import React, { FC } from "react";
import {
  selectViewESignDocumentsModal,
  setEmailModal,
  setViewESignDocumentModal
} from "src/store/reducers/eSignStatusSlice/eSignStatusSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import PDFViewer from "src/components/PdfViewer/PDFViewer";
import { selectSelectedDocumentPDFUrl } from "src/store/reducers/documentsSlice/documentsSlice";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import useStyles from "./ViewESignDocumentModal.styles";

interface ViewESignDocumentModalProps {}

const ViewESignDocumentModal: FC<ViewESignDocumentModalProps> = () => {
  const dispatch = useAppDispatch();
  const viewESignDocumentModal = useAppSelector(selectViewESignDocumentsModal);
  const pdf = useAppSelector(selectSelectedDocumentPDFUrl);
  const { classes } = useStyles();

  const handleClose = () => {
    dispatch(setViewESignDocumentModal(false));
  };

  const handleDownloadDocument = () => {
    dispatch(
      showSnackbar({
        message: `Mock document downloaded successfully.`,
        variant: "success"
      })
    );

    dispatch(setViewESignDocumentModal(false));
  };

  const handleSendDocumentSMS = () => {
    dispatch(
      showSnackbar({
        message: `Mock document SMS sent successfully.`,
        variant: "success"
      })
    );

    dispatch(setViewESignDocumentModal(false));
  };

  const handleSendFollowupEmail = () => {
    dispatch(setViewESignDocumentModal(false));
    dispatch(setEmailModal(true));
  };

  return (
    <Modal
      open={viewESignDocumentModal}
      onClose={handleClose}
      aria-describedby={"A Modal component to add or edit chart of account settings."}
      className={classes.modal}
    >
      <Grid container spacing={1} p={2}
        className={classes.container} component={Paper}>
        <Grid item xs={12}>
          <Typography gutterBottom variant={"h4"} textAlign={"left"}>
            View Document
          </Typography>
        </Grid>
        <Grid pb={1} item xs={12}>
          <Divider />
        </Grid>
        <Grid pb={1} item container
          justifyContent={"center"} xs={12} spacing={2}>
          <Grid item>
            <Button onClick={handleDownloadDocument} variant={"contained"} className={classes.confirmationButton}>
              Sign In Store
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={handleSendFollowupEmail} variant={"contained"} className={classes.confirmationButton}>
              Send Follow Up Email
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={handleSendDocumentSMS} variant={"contained"} className={classes.confirmationButton}>
              Send SMS
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent={"center"} item
          pb={1} xs={12}>
          <Grid item xs={6}>
            <PDFViewer pdfUrl={pdf} />
          </Grid>
        </Grid>
        <Grid pb={1} item container
          justifyContent={"flex-end"} xs={12}>
          <Button onClick={handleClose} className={classes.cancelButton}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ViewESignDocumentModal;
