import { makeStyles } from "../../../../../makeStyles";

const useStyles = makeStyles({ label: "HoursOfOperation" })(theme => ({
  switch: {
    marginLeft: "1rem"
  },
  storeHoursDay: {
    textTransform: "capitalize",
    fontWeight: 900
  },
  dayCell: {
    flexBasis: "2.5rem"
  },
  toCell: {
    flexBasis: "1rem"
  },
  flexCell: {
    flexGrow: 1
  },
  errorText: {
    color: theme.palette.error.main
  }
}));

export default useStyles;
