import {
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography
} from "@mui/material";
import React, { FC } from "react";
import {
  selectAutomaticPaymentSlice,
  setSignatureAcceptedModalOpened
} from "src/store/reducers/automaticPaymentsSlice/automaticPaymentsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";

import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { enrollAutoPay } from "src/store/thunks/occupant/enrollAutoPay/enrollAutoPay";
import { getOccupant } from "src/store/thunks/occupant/getOne/getOccupant";
import { setChangeOwnerShipStep } from "src/store/reducers/occupantSlice/occupantSlice";
import { useParams } from "react-router";
import useStyles from "./SignatureAcceptedModal.styles";

interface SignatureAcceptedModalProps {}

const SignatureAcceptedModal: FC<SignatureAcceptedModalProps> = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const {
    signatureAcceptedModalOpened
  } = useAppSelector(selectAutomaticPaymentSlice);
  const formId = "automatic-payments-form";
  const params = useParams<{ id: string }>();

  const handleCloseModal = () => {
    dispatch(setSignatureAcceptedModalOpened(false));
  };

  const handleSubmit = async() => {
    const { type } = await dispatch(enrollAutoPay());

    if (!type.includes("rejected")) {
      handleCloseModal();
      dispatch(setChangeOwnerShipStep(3));
      dispatch(getOccupant(params.id!));
    }
  };

  return (
    <Modal onClose={handleCloseModal} open={signatureAcceptedModalOpened} className={classes.modal}>
      <Grid
        p={4}
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        justifyContent={"center"}
        className={classes.container}
      >
        <Grid item xs={11}>
          <Typography variant={"h5"} textAlign={"center"}>Signature Accepted</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={handleCloseModal}>
            <Close />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} my={"1rem"}>
          <Typography variant={"body1"} fontWeight={"bold"}>
            What happens next:
          </Typography>

          <Typography variant={"body1"} ml={"auto"}>
            Cillum occaecat velit laboris minim consectetur mollit
            voluptate enim laboris incididunt consequat proident do
            ex. Culpa pariatur veniam sint tempor aliquip. Aliqua
            magna aute aute pariatur aliqua aliqua magna minim irure cillum.
          </Typography>
        </Grid>

        <Grid
          container
          item
          gap={0.5}
          my={2}
          justifyContent={"center"}
        >
          <Button className={classes.button} variant={"contained"}
            >
            Download
          </Button>
          <Button className={classes.button} variant={"contained"} >
            Email
          </Button>
          <Button className={classes.button} variant={"contained"} >
            SMS
          </Button>
          <Button className={classes.button} variant={"contained"} >
            Print
          </Button>
        </Grid>

        <Grid item container xs={12}
          mt={2} justifyContent={"center"}>
          <LoadingButton
            variant={"contained"}
            type={"submit"}
            // disabled={eSignDocumentCreationLoading}
            onClick={handleSubmit}
            form={formId}
            className={classes.button}
            // loading={eSignDocumentCreationLoading}
          >
            Submit Autobill Update
          </LoadingButton>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default SignatureAcceptedModal;
