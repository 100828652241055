import { Button, Grid } from "@mui/material";

import React from "react";
import { axiosInstance } from "src/api/axiosInstance/axiosInstance";
import { useAppDispatch } from "src/store/hooks";

const QA403 = () => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    axiosInstance.post("api/v1/dummy-403-route").catch((err) => {
      console.log("err", err);
    });
  };

  return (
    <Grid container >
      <Grid
        item
        xs={12}
        alignItems={"center"}
        justifyContent={"center"}
        display={"flex"}
        flexDirection={"column"}
        height={"90vh"}
      >
        <Grid
          item
          xs={6}
        >
          <Button variant={"contained"} color={"primary"} onClick={handleClick}>
            Test 403
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QA403;
