import { Box, CircularProgress, Grid, IconButton, Modal, Paper, Typography } from "@mui/material";
import { selectDocumentModalOpened, setDocumentModalOpened } from "src/store/reducers/documentSlice/documentSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  selectDocumentTemplateChangelogLoading,
  selectSelectedDocumentTemplate,
  selectSelectedDocumentTemplateChangelog,
  setDocumentTemplateChangelogLoading
} from "src/store/reducers/documentsSlice/documentsSlice";
import useStyles from "./ChangelogModal.styles";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses
} from "@mui/lab";
import { Close } from "@mui/icons-material";

const ChangelogModal = () => {
  const { classes } = useStyles();
  const modalOpened = useAppSelector(selectDocumentModalOpened);
  const selectedDocumentTemplate = useAppSelector(selectSelectedDocumentTemplate);
  const changelog = useAppSelector(selectSelectedDocumentTemplateChangelog);
  const dispatch = useAppDispatch();
  const changelogLoading = useAppSelector(selectDocumentTemplateChangelogLoading);
  const [allDates, setAllDates] = useState<any>([]);

  const handleClose = () => {
    dispatch(setDocumentModalOpened());
  };

  useEffect(() => {
    dispatch(setDocumentTemplateChangelogLoading());
    const splittedDates = changelog.map((template) => template.created_at.split(" ")[0]);
    const filteredDates = [...new Set(splittedDates)];
    const changesByDates = [];

    for (let i = 0; i < filteredDates.length; i++) {
      changesByDates.push({ changes: [] as any, date: filteredDates[i] });
    }

    for (let i = 0; i < changesByDates.length; i++) {
      for (let y = 0; y < changelog.length; y++) {
        if (changelog[y].created_at.split(" ")[0] === changesByDates[i].date) {
          changesByDates[i].changes.push(changelog[y]);
        }
      }
    }

    setAllDates(changesByDates);
    dispatch(setDocumentTemplateChangelogLoading());
  }, [changelog]);

  return (
    <Modal
      open={modalOpened}
      onClose={() => handleClose()}
      aria-labelledby={"Modal Component"}
      aria-describedby={"A Modal component."}
      className={classes.modal}
      data-testid={"modal-cancel-deal"}
  >
      <Grid
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        spacing={2}
        className={classes.container}
        pb={2}>

        <Box display={"flex"} justifyContent={"space-between"} className={classes.header}>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography variant={"h6"}>{selectedDocumentTemplate?.name} Changelog</Typography>
            <Typography variant={"caption"}>{moment(new Date()).format("MM/DD/YYYY")}</Typography>
          </Box>
          <Box>
            <IconButton onClick={() => handleClose()}><Close /></IconButton>
          </Box>
        </Box>
        <Box className={classes.content}>
          {!changelogLoading
            ? (
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0
                  }
                }}
>
                {allDates.map((changelog: { date: string, changes: any[]}, index: number) => (
                  <TimelineItem key={index}>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography variant={"body2"}><strong>Changes on</strong> {changelog.date}</Typography>
                      <Box ml={4} mt={2}>
                        {changelog.changes.map((template: any) => (
                          <>
                            <Box mt={1}>
                              {template.version_changes?.split(";").filter((x: string) => x)
                                .map((change: string, index: number) => (
                                  <Box key={index} mt={1}>
                                    <Typography variant={"body2"}>
                                      {`${template.created_at.split(" ")[1]}
                                        ${template.created_at.split(" ")[2]} - ${change}`}
                                    </Typography>
                                  </Box>
                                ))}
                            </Box>
                          </>
                        ))}
                      </Box>

                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
              )
            : (
              <Box className={classes.content} display={"flex"} justifyContent={"center"}
                alignItems={"center"}>
                <CircularProgress size={20} />
              </Box>
              )}
          <Box pl={2} pt={2} style={{ borderTop: "1px solid #ddd" }}>
            <Typography variant={"caption"}>
              <strong>{selectedDocumentTemplate?.name} Changelog</strong>
            </Typography>
          </Box>
        </Box>

      </Grid>
    </Modal>
  );
};

export default ChangelogModal;
