import { Button, Divider, Grid, IconButton, Modal, Paper, Typography } from "@mui/material";
import React, { FC } from "react";
import {
  selectCurrentReport,
  selectParamsBuilderCondirmationModalOpen,
  selectReportDate,
  selectReportEndDate,
  selectReportFullFacilities,
  selectReportFullFacility,
  selectReportHours,
  selectReportStartDate,
  setCurrentReport,
  setParamsBuilderConfirmationModalOpen,
  setReportDate,
  setReportEndDate,
  setReportFacilities,
  setReportFacility,
  setReportHours,
  setReportStartDate
} from "src/store/reducers/reportParamsSlice/reportParamsSlice";
import { Close } from "@mui/icons-material";
import { useAppSelector } from "src/store/hooks";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import useStyles from "./ParamsBuilderConfirmationModal.styles";

interface ParamsBuilderConfirmationModalProps { }

const DetailRow: FC<{ label: string, value: string | string[] }> = ({ label, value }) => (
  <Grid container alignItems={"flex-start"} item
    xs={12}>
    <Grid item xs={4}>
      <Typography fontWeight={"bold"} variant={"body1"}>
        {label}:
      </Typography>
    </Grid>
    <Grid item xs={"auto"}>
      {Array.isArray(value)
        ? value.map((val, index) => (
          <Typography key={index} variant={"body1"}>
            {val}
          </Typography>
        ))
        : <Typography variant={"body1"}>
          {value}
        </Typography>
      }
    </Grid>
  </Grid>
);

const ParamsBuilderConfirmationModal: FC<ParamsBuilderConfirmationModalProps> = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modalOpen = useAppSelector(selectParamsBuilderCondirmationModalOpen);
  const reportName = useAppSelector(selectCurrentReport);
  const attachedFacilitiesFull = useAppSelector(selectReportFullFacilities);
  const attachedFacilityFull = useAppSelector(selectReportFullFacility);
  const startDate = useAppSelector(selectReportStartDate);
  const endDate = useAppSelector(selectReportEndDate);
  const date = useAppSelector(selectReportDate);
  const hours = useAppSelector(selectReportHours);

  const handleClose = () => {
    dispatch(setParamsBuilderConfirmationModalOpen(false));
    dispatch(setCurrentReport(""));
    dispatch(setReportStartDate(""));
    dispatch(setReportEndDate(""));
    dispatch(setReportDate(""));
    dispatch(setReportHours(""));
    dispatch(setReportFacilities([]));
    dispatch(setReportFacility([]));
  };

  const handleConfirm = () => {
    dispatch(setParamsBuilderConfirmationModalOpen(false));
    navigate("build");
  };

  return (
    <Modal onClose={handleClose} className={classes.modal} open={modalOpen}>
      <Grid className={classes.container} component={Paper} elevation={0}
        container spacing={2} p={4}
        pl={2}>
        {/* Header */}
        <Grid item xs={11}>
          <Typography variant={"h4"}>{reportName}</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {/* Details */}
        <Grid
          container
          spacing={2}
          justifyContent={"flex-end"}
          alignItems={"center"}
          item
          xs={12}
        >
          <Grid item xs={3}>
            <Typography
              className={classes.filterDetiailsText}
              textTransform={"uppercase"}
              fontWeight={"bold"}
            >
              Filter Details
            </Typography>
          </Grid>
          <Grid item xs={9}><Divider className={classes.filterDetiailsDivider} /></Grid>
          {/* All selected Paramenters Display */}
          {reportName && <DetailRow label={"Report Name"} value={reportName} />}
          {attachedFacilitiesFull.length > 0 &&
            <DetailRow label={"Facilities"} value={attachedFacilitiesFull.map(facility => facility.name)} />}
          {attachedFacilityFull.length > 0 &&
            <DetailRow label={"Facility"} value={attachedFacilityFull.map(facility => facility.name)} />}
          {(startDate && endDate) && <DetailRow label={"Date Range"} value={`${startDate} - ${endDate}`} />}
          {(date) && <DetailRow label={"Date"} value={date} />}
          {(hours) && <DetailRow label={"Hours"} value={hours} />}

          {/* Actions */}
          <Grid item>
            <Button onClick={handleClose} color={"error"}>Cancel</Button>
          </Grid>
          <Grid item>
            <Button disableElevation onClick={handleConfirm} variant={"contained"}
              className={classes.saveButton}>Next</Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ParamsBuilderConfirmationModal;
