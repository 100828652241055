import { GetMoveOutCostPayload } from "./GetMoveOutCostPayload";
import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getMoveOutCost = createAsyncThunk(
  "getMoveOutCost",
  (
    {
      moveOutDate,
      fees,
      creditProratedValue,
      waiveFees,
      issueCreditValue,
      refundType
    }: {
      moveOutDate: string;
      fees: Array<number>;
      creditProratedValue: boolean;
      waiveFees: boolean;
      issueCreditValue?: string;
      refundType: string;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const facilityId = state.selectedFacility.selectedFacility?.id;
    const unitId = state.moveOut.moveOutLedger!.id;
    const issueCreditState = state.moveOutCost.issue_credit;

    const payload: GetMoveOutCostPayload = {
      ledger_id: unitId,
      move_out_date: moveOutDate,
      fees: fees,
      prorate_charges: creditProratedValue,
      waive_fees: waiveFees,
      issue_credit: issueCreditValue ?? issueCreditState,
      refund_type: refundType,
      occupant_is_tax_exempt: state.selectedOccupant?.selectedOccupant?.is_tax_exempt
    };

    return axios
      .post(`/api/v1/facilities/${facilityId}/move-out-cost`, payload)
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        let errorMessage = "There was an error getting the Move-Out Cost";

        if (
          err &&
          err.response &&
          err.response.status &&
          err.response.status === 422 &&
          err.response.data.errors &&
          Object.keys(err.response.data.errors).length
        ) {
          errorMessage = format422(err.response.data.errors);
        } else if (err && err.response && err.response.data && err.response.data.detail) {
          errorMessage += `: ${err.response.data.detail}`;
        }

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  }
);
