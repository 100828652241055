import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "StepperAdvisory" })((theme) => ({
  advisoryText: {
    marginTop: theme.spacing(2)
  },
  root: {
    backgroundColor: "#f2f3f4", // @TODO: replace with theme var when we figure out how to extend the theme
    height: "inherit",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.short
    })
  },
  headerText: {
    fontWeight: "bold"
  },
  iconContainer: {
    margin: `${theme.spacing(1)} 0`
  },
  skipButton: {
    textTransform: "capitalize",
    color: theme.palette.primary.contrastText
  }
}));

export default useStyles;
