import { Button, FormControl, Grid, Modal, Tab, Tabs, TextField, Typography } from "@mui/material";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import FacilitySelector from "src/components/navigation/FacilitySelector/FacilitySelector";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import ItemManagementLayout from "src/layouts/ItemManagement/ItemManagementLayout";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { Breadcrumb } from "src/models/Breadcrumb";
import { Facility } from "src/models/Facility";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  selectSelectedAdminFacility,
  setSelectedAdminFacility
} from "src/store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";

import {
  exportAskingRateChangesHeadings
} from "src/store/thunks/askingRateChanges/exportForFacility/exportAskingRateChangesHeadings";

import {
  exportAskingRateChangesForFacility
} from "src/store/thunks/askingRateChanges/exportForFacility/exportAskingRateChangesForFacility";
import {
  exportAskingRateChangesForFacility2
} from "src/store/thunks/askingRateChanges/exportForFacility/exportAskingRateChangesForFacility2";
import {
  exportAskingRateChangesForOperator
} from "src/store/thunks/askingRateChanges/exportForOperator/exportAskingRateChangesForOperator";
import {
  exportAskingRateChangesForOperator2
} from "src/store/thunks/askingRateChanges/exportForOperator/exportAskingRateChangesForOperator2";
import { batchUploadAskingRates } from "src/store/thunks/askingRateChanges/batch/batchUploadAskingRateChanges";
import { batchUploadAskingRates2 } from "src/store/thunks/askingRateChanges/batch/batchUploadAskingRateChanges2";

import useStyles from "./AskingRateChanges.styles";
import { useFormik } from "formik";
import {
  selectBatchUploadLoading
} from "../../store/reducers/unitsSliceNew/unitsSliceNew";
import { showSnackbar } from "../../store/reducers/snackbarSlice/snackbarSlice";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import {
  selectMissingFieldsChangeOwnershipModalOpened, selectUndoChangeOwnershipModalOpened,
  setMissingFieldsChangeOwnershipModalOpened, setUndoChangeOwnershipModalOpened
} from "../../store/reducers/occupantSlice/occupantSlice";
import CustomModal from "../Occupants/EditOccupant/components/CustomModal/CustomModal";
import {
  askingRateChanges,
  selectAskingRateChangesSettingsLoading, selectDocumentCurrentTabView,
  setDocumentCurrentTabView
} from "../../store/reducers/askingRateChangesSlice/askingRateChangesSlice";
import {
  createPermissions,
  hasPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Admin Settings"
  },
  {
    name: "Asking Rate Changes",
    bold: true
  }
];

const fileValidationSchema = Yup.object().shape({
  file: Yup
    .mixed()
    .required("A file is required")
    .test(
      "fileType",
      "Uploaded file is not a CSV",
      value => value && value.type === "text/csv"
    )
});

const AskingRateChanges: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedAdminFacility = useAppSelector(selectSelectedAdminFacility);
  const batchUploadLoading = useAppSelector(selectBatchUploadLoading);
  const askingRateChangesLoading = useAppSelector(selectAskingRateChangesSettingsLoading);

  const successModalOpened = useAppSelector(selectMissingFieldsChangeOwnershipModalOpened);
  const errorModelOpened = useAppSelector(selectUndoChangeOwnershipModalOpened);
  const documentCurrentTabView = useAppSelector(selectDocumentCurrentTabView);
  const permissions = useAppSelector(selectFacilityPermissions);
  const askingRateChangePermissions = createPermissions(permissions, "asking_rate_changes");

  const formik = useFormik({
    initialValues: {
      file: null,
      file2: null
    },
    onSubmit: (values) => {
      if (values.file) {
        dispatch(batchUploadAskingRates({ file: values.file }))
          .then((resp) => {
            if (resp.type.includes("fulfilled")) {
              dispatch(setMissingFieldsChangeOwnershipModalOpened(true));
              return;
            }

            dispatch(showSnackbar({
              message: resp.payload,
              variant: "error"
            }));
            dispatch(setUndoChangeOwnershipModalOpened(true));
          });
      }
      if (values.file2) {
        dispatch(batchUploadAskingRates2({ file: values.file2 }))
          .then((resp) => {
            if (resp.type.includes("fulfilled")) {
              dispatch(setMissingFieldsChangeOwnershipModalOpened(true));
              return;
            }

            dispatch(showSnackbar({
              message: resp.payload,
              variant: "error"
            }));
            dispatch(setUndoChangeOwnershipModalOpened(true));
          });
      }
    }
  });

  const { handleSubmit, touched, errors, resetForm } = formik;
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const file = files[0];
      formik.setFieldValue("file", file);
      formik.setFieldTouched("file", true, false);
    }
  };
  const handleFileChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const file = files[0];
      formik.setFieldValue("file2", file);
      formik.setFieldTouched("file2", true, false);
    }
  };

  const { classes } = useStyles({});

  const handleOnFacilityChange = (selectedFacility: Facility) => {
    dispatch(setSelectedAdminFacility(selectedFacility));
  };

  const exportFacilityButton = () => {
    return (
      selectedAdminFacility && (
        <Grid spacing={2} item justifyContent={"space-between"}
          p={1} xs={12} lg={"auto"}>
          <Button
            data-testid={"fees-cta-button"}
            className={classes.buttonBase}
            color={"primary"}
            variant={"text"}
            onClick={() => dispatch(exportAskingRateChangesForFacility(selectedAdminFacility.id))}
          >
            Export Selected Facility
          </Button>
        </Grid>
      )
    );
  };
  const exportFacilityButton2 = () => {
    return (
      selectedAdminFacility && (
        <Grid spacing={2} item justifyContent={"space-between"}
          p={1} xs={12} lg={"auto"}>
          <Button
            data-testid={"fees-cta-button"}
            className={classes.buttonBase}
            color={"primary"}
            variant={"text"}
            onClick={() => dispatch(exportAskingRateChangesForFacility2(selectedAdminFacility.id))}
          >
            Export Selected Facility
          </Button>
        </Grid>
      )
    );
  };

  const ctaButton2 = (
    <Grid spacing={2} item justifyContent={"space-between"}
      p={4} xs={12} lg={"auto"}>
      <Button
        data-testid={"fees-cta-button"}
        className={classes.ctaButton}
        color={"primary"}
        variant={"text"}
        onClick={() => dispatch(exportAskingRateChangesHeadings(0))}
      >
        Export Active Tenants
      </Button>
    </Grid>
  );

  const title = (
    <Grid container>
      <Grid item spacing={2} justifyContent={"space-between"}
        p={1}>
        <Typography variant={"h5"}>
          Asking Rate Change Tools<br/>
        </Typography>
      </Grid>
    </Grid>
  );

  const exportHeadings = (
    <Grid container>
      <Grid item spacing={2} justifyContent={"space-between"}
        p={2}>
        <Typography component={"label"} variant={"h6"}>
          Export the rate change template headings only. <br/>
        </Typography>
      </Grid>
      <Grid spacing={10} item justifyContent={"space-between"}
        p={2}>
        <Button
          data-testid={"fees-cta-button"}
          className={classes.buttonBase}
          color={"primary"}
          variant={"text"}
          onClick={() => dispatch(exportAskingRateChangesHeadings(0))}
        >
          Export Template Headings Only
        </Button>
      </Grid>
    </Grid>
  );
  const exportFacility = () => {
    return (
      <Grid item container alignItems={"center"}>
        <Grid item spacing={2} justifyContent={"space-between"}
          p={2}>
          <Typography variant={"h6"}>
            Export current asking rates (by unit) for 1 facility.   Select the facility:
          </Typography>
        </Grid>
        <Grid item spacing={2} justifyContent={"space-between"}
          p={1}>
          <FacilitySelector
            navBar={false}
            onFacilityChange={handleOnFacilityChange}
            elementId={"rc-facility-selector"}
            data-testid={"rate-change-facility-selector"}
          />
        </Grid>
        {exportFacilityButton()}
      </Grid>
    );
  };

  const exportFacility2 = () => {
    return (
      <Grid item container alignItems={"center"}>
        <Grid item spacing={2} justifyContent={"space-between"}
          p={2}>
          <Typography variant={"h6"}>
            Export current asking rates (by unit groups) for 1 facility.   Select the facility:
          </Typography>
        </Grid>
        <Grid item spacing={2} justifyContent={"space-between"}
          p={1}>
          <FacilitySelector
            navBar={false}
            onFacilityChange={handleOnFacilityChange}
            elementId={"rc-facility-selector"}
            data-testid={"rate-change-facility-selector"}
          />
        </Grid>
        {exportFacilityButton2()}
      </Grid>
    );
  };
  const exportAllFacilities = (
    <Grid container>
      <Grid item spacing={2} justifyContent={"space-between"}
        p={2}>
        <Typography variant={"h6"}>
          Export current asking rates (by unit) for all facilities. (can take over 2 minutes)<br/>
        </Typography>
      </Grid>
      <Grid spacing={10} item justifyContent={"space-between"}
        p={2}>
        <LoadingButton
          data-testid={"fees-cta-button"}
          className={classes.buttonBase}
          color={"primary"}
          variant={"text"}
          loading={askingRateChangesLoading}
          disabled={askingRateChangesLoading}
          onClick={() => dispatch(exportAskingRateChangesForOperator(1))}
        >
          Export All Facilities
        </LoadingButton>
      </Grid>
    </Grid>
  );

  const exportAllFacilities2 = (
    <Grid container>
      <Grid item spacing={2} justifyContent={"space-between"}
        p={2}>
        <Typography variant={"h6"}>
          Export current asking rates (by unit groups) for all facilities.
          (Takes 5 minutes. CSV will be emailed to you.)<br/>
        </Typography>
      </Grid>
      <Grid spacing={10} item justifyContent={"space-between"}
        p={2}>
        <LoadingButton
          data-testid={"fees-cta-button"}
          className={classes.buttonBase}
          color={"primary"}
          variant={"text"}
          loading={askingRateChangesLoading}
          disabled={askingRateChangesLoading}
          onClick={() => dispatch(exportAskingRateChangesForOperator2(1))}
        >
          Export All Facilities
        </LoadingButton>
      </Grid>
    </Grid>
  );

  const importForm = () => {
    return (
      <form
        id={"batch-upload-units-form"}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}>
        <Grid item container alignItems={"center"}>
          <Grid item spacing={2} justifyContent={"space-between"}
            p={2}>
            <Typography variant={"h6"}>
              Import Asking Rate Changes (by unit):
            </Typography>
          </Grid>

          <Grid spacing={2} justifyContent={"space-between"}
            p={2}>
            <FormControl error={Boolean(touched.file && errors.file)} fullWidth>
              <TextField
                error={Boolean(touched.file && errors.file)}
                helperText={touched.file && errors.file ? errors.file : ""}
                id={"file"}
                type={"file"}
                onChange={handleFileChange}
                InputLabelProps={{
                  shrink: true
                }}
                variant={"outlined"}
                fullWidth
                margin={"normal"}
              />
            </FormControl>
          </Grid>
          <Grid item spacing={2} justifyContent={"space-between"}
            p={2}>
            <LoadingButton
              className={classes.buttonBase}
              type={"submit"}
              loading={batchUploadLoading}
              form={"batch-upload-units-form"}
              variant={"contained"}
            >
              Import
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item spacing={2} justifyContent={"space-between"}
            p={2}>
            <p>
              Import Rules:
              <ul>
                <li>New Rate <b>can not</b> be empty.  (The file should contain only asking rate changes.
                  No other records allowed.)
                </li>
                <li>There are 5 required column headings:
                  <strong> Location Code, Unit ID, Current Rate, New Rate, Note</strong></li>
                <li>CSV file can include other columns.  Other columns will be ignored.</li>
                <li>CSV length is limited to 5000 rows. System will reject the file if it has more than 5000 rows. </li>
                <li>CSV can contain data for MULTIPLE facilities.  (You are not limited to 1 facility per CSV)</li>
                <li>Unit ID <b>can not</b> be entered more than 1 time.  It must be unique.</li>
                <li>Unit ID must be active.  (Upload will fail if any are inactive)</li>
                <li>Note field can be empty. (If supplied, it will be appended to the end of the system note.)</li>
              </ul>
              The system will automatically
              add a <strong>System Note</strong> on each updated unit that looks like this below. <br/>
              <br/>
              Example System Note: <i>Scheduled unit price change from $40 to $45</i>
            </p>
            <p>
              Note: Uploaded rate changes are processed by the system every 5 minutes.
            </p>
          </Grid>
        </Grid>
      </form>
    );
  };

  const importForm2 = () => {
    return (
      <form
        id={"batch-upload-units-form"}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}>
        <Grid item container alignItems={"center"}>
          <Grid item spacing={2} justifyContent={"space-between"}
            p={2}>
            <Typography variant={"h6"}>
              Import Asking Rate Changes (by unit groups):
            </Typography>
          </Grid>

          <Grid spacing={2} justifyContent={"space-between"}
            p={2}>
            <FormControl error={Boolean(touched.file && errors.file)} fullWidth>
              <TextField
                error={Boolean(touched.file && errors.file)}
                helperText={touched.file && errors.file ? errors.file : ""}
                id={"file"}
                type={"file"}
                onChange={handleFileChange2}
                InputLabelProps={{
                  shrink: true
                }}
                variant={"outlined"}
                fullWidth
                margin={"normal"}
              />
            </FormControl>
          </Grid>
          <Grid item spacing={2} justifyContent={"space-between"}
            p={2}>
            <LoadingButton
              className={classes.buttonBase}
              type={"submit"}
              loading={batchUploadLoading}
              form={"batch-upload-units-form"}
              variant={"contained"}
            >
              Import
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item spacing={2} justifyContent={"space-between"}
            p={2}>
            <p>
              Import Rules:
              <ul>
                <li>New Rate <b>can not</b> be empty.  (The file should contain only asking rate changes.
                  No other records allowed.)
                </li>
                <li>The CSV must contain these columns:
                  <strong> Location Code, Product Type ID, Width, Length, Floor,
                    Current Rate, New Rate, Note</strong></li>
                <li>CSV file can include other columns.  Other columns will be ignored.</li>
                <li>CSV length is limited to 5000 rows. System will reject the file if it has more than 5000 rows. </li>
                <li>CSV can contain data for MULTIPLE facilities.  (You are not limited to 1 facility per CSV)</li>
                <li>Floor is a semicolon separated list of floors.   Example:  1;2;3;4 </li>
                <li>Value for floor must be an integer</li>
                <li>Product Type ID / Width / Length / Floor / Current Rate combination <b>can not</b> be entered more
                  than 1 time.  The combination must be unique.</li>
                <li>Note field can be empty. (If supplied, it will be appended to the end of the system note.)</li>
              </ul>
              The system will automatically
              add a <strong>System Note</strong> on each updated unit that looks like this below. <br/>
              <br/>
              Example System Note: <i>Scheduled unit price change from $40 to $45</i>
            </p>
            <p>
              Note: Uploaded rate changes are processed by the system every 5 minutes.
            </p>
          </Grid>
        </Grid>
      </form>
    );
  };

  const pageHeader = <PageHeader title={"Manage Asking Rate Changes"} breadcrumbs={breadcrumbs} />;

  const handleTabChange = (e: React.SyntheticEvent, newValue: number) => {
    dispatch(setDocumentCurrentTabView(newValue));
  };
  return askingRateChangePermissions.create
    ? (<ViewWrapper pageHeader={pageHeader}>
      <Tabs value={documentCurrentTabView} onChange={handleTabChange} aria-label={"basic tabs example"}>
        <Tab label={"Units"} value={0} />
        <Tab label={"Unit Groups"} value={1} />
      </Tabs>

      <ItemManagementLayout title={title}>
        {documentCurrentTabView === 0 &&
          <Grid>
            {exportFacility()}
            {exportAllFacilities}
            <hr/>
            {importForm()}
          </Grid>}
        {documentCurrentTabView === 1 &&
          <Grid>
            {exportFacility2()}
            {exportAllFacilities2}
            <hr/>
            {importForm2()}
          </Grid>}
      </ItemManagementLayout>

      <CustomModal
        title={"Import Asking Rate Changes"}
        open={successModalOpened}
        confirmButtonLabel={"Close"}
        hasCancel={false}
        handleSubmit={() => {
          dispatch(setMissingFieldsChangeOwnershipModalOpened(false));
          window.location.reload();
        }}
        handleClose={() => {
          dispatch(setMissingFieldsChangeOwnershipModalOpened(false));
          window.location.reload();
        }}
      >
        <Typography>
          The import was Successful.
        </Typography>
      </CustomModal>

      <CustomModal
        title={"Failure!"}
        open={errorModelOpened}
        confirmButtonLabel={"Close"}
        hasCancel={false}
        handleSubmit={() => {
          dispatch(setUndoChangeOwnershipModalOpened(false));
          window.location.reload();
        }}
        handleClose={() => {
          dispatch(setUndoChangeOwnershipModalOpened(false));
          window.location.reload();
        }}
      >
        <Typography>
          The import failed.  Review the error messages, update the file and try again.
        </Typography>
      </CustomModal>
    </ViewWrapper>)
    : <ViewWrapper pageHeader={pageHeader}>
      <h1>You do not have permission to view this page.</h1>
    </ViewWrapper>;
};

export default AskingRateChanges;
