import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getLedgerAsPdf = createAsyncThunk(
  "getLedgerAsPdf", (occupantId: string,
    { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const ledgerId = state.ledgers.selectedLedger?.id;

    return axios.get(`/api/v1/occupants/${occupantId}/ledgers/${ledgerId}/export-pdf`, {
      responseType: "blob"
    })
      .then((resp) => {
        return window.URL.createObjectURL(new Blob([resp.data], { type: "application/pdf" }));
      })
      .catch((err) => {
        let errorMessage = "There was an error getting the pdf for this ledger";

        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
