import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "Deal Follow Up Modal" })((theme) => ({
  textButton: {
    color: theme.palette.primary.contrastText,
    marginTop: theme.spacing(2)
  },
  tableHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    backgroundColor: theme.palette.grey[200],
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius
  },
  table: {
    backgroundColor: "white",
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    justifyContent: "space-between",
    textAlign: "center"
  },
  bodyHeaderText: {
    color: theme.palette.primary.contrastText,
    fontWeight: "bold",
    textTransform: "uppercase",
    whiteSpace: "nowrap"
  },
  bodyHeaderDivider: {
    background: theme.palette.primary.contrastText,
    width: "100%"
  },
  removeButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  },
  cancelButton: {
    color: theme.palette.error.main
  },
  addButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "80%"
  },
  ledgerModal: {
    margin: "auto"
  }
}));

export default useStyles;
