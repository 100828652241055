export const formattedAmount = (type: "Percentage" | "Fixed Amount" | number, amount: string) => {
  if (!amount) {
    return "";
  }

  if (type === "Percentage" || type === 1) {
    return `${amount}%`;
  }

  return `$${amount}`;
};
