import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles()((theme) => ({
  link: {
    textDecoration: "none",
    "&:visited": {
      color: theme.palette.primary.contrastText
    }
  }
}));

export default useStyles;
