import { Button, Collapse, Divider, Grid, InputLabel, Paper, TextField, Typography } from "@mui/material";
import React, { FormEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import Add from "@mui/icons-material/Add";
import AppNote from "src/components/note/Note";
import { LoadingButton } from "@mui/lab";
import NoteType from "src/enums/NoteType";
import { createNote } from "src/store/thunks/note/createNote/createNote";
import editMerchNoteValidation from "./EditMerchandiseNotesValidation";
import { inputError } from "src/utils/showInputError/showInputError";
import { selectMerchandiseName } from "src/store/reducers/merchandiseFormSlice/merchandiseFormSlice";
import { selectNotes } from "src/store/reducers/notesSlice/notesSlice";
import { useFormik } from "formik";
import { useParams } from "react-router";
import useStyles from "./EditMerchandiseNotes.styles";

const formId = "edit-merchandise-notes-form";

const EditMerchandiseNotes = () => {
  const [isAddNewNoteOpen, setIsAddNewNoteOpen] = useState(false);
  const notes = useAppSelector(selectNotes);
  const merchandiseName = useAppSelector(selectMerchandiseName);
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();

  const formik = useFormik({
    initialValues: {
      description: ""
    },
    validationSchema: editMerchNoteValidation,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      dispatch(createNote({
        id: +params.id!,
        type: NoteType.merch,
        description: values.description
      }));

      formik.resetForm();
      setIsAddNewNoteOpen(false);
    }
  });

  const { touched, errors, values, handleChange, setFieldValue } = formik;

  const handleCancel = () => {
    setIsAddNewNoteOpen(false);
    setFieldValue("description", "");
  };

  return (
    <Grid
      container spacing={2} component={Paper}
      mx={2}
      my={0.5}
      p={2}
    >
      <Grid
        container
        spacing={2}
        component={"form"}
        id={formId}
        onSubmit={(e: FormEvent) => {
          e.preventDefault();
          e.stopPropagation();
          formik.handleSubmit();
        }}
      >
        <Grid item xs={"auto"}>
          <Typography variant={"h6"}>
            Notes for: {merchandiseName}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Button
            className={classes.button}
            variant={"contained"}
            startIcon={<Add />}
            onClick={() => setIsAddNewNoteOpen(true)}>
            Add Note
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Collapse in={isAddNewNoteOpen}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel htmlFor={"note-description"}>New note description</InputLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id={"note-description"}
                  multiline
                  rows={4}
                  fullWidth
                  name={"description"}
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 500
                  }}
                  error={inputError("description", touched, errors).error}
                  helperText={inputError("description", touched, errors).helperText}
                  value={values.description}
                />
              </Grid>
              <Grid
                container
                spacing={2}
                justifyContent={"flex-end"}
                item xs={12}
              >
                <Grid item>
                  <Button
                    color={"error"}
                    variant={"text"}
                    onClick={() => handleCancel()}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    form={formId}
                    variant={"contained"}
                    type={"submit"}
                    className={classes.button}
                  >
                    Save
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
          <Grid
            container
            rowGap={2}
            mt={2}
          >
            {notes?.map((note, index) => <AppNote key={index} {...note} />)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditMerchandiseNotes;
