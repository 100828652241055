import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getMerchandiseForRefund = createAsyncThunk(
  "getMerchandiseForRefund",
  (
    { transactionId, searchValue }: { transactionId: number; searchValue?: string },
    { dispatch, getState, rejectWithValue }
  ) => {
    let url = `/api/v1/transactions/${transactionId}/merchandise-for-refund`;

    if (searchValue) {
      url = `/api/v1/transactions/${transactionId}/merchandise-for-refund?filter=${searchValue}`;
    }

    return axios
      .get(url)
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        let errorMessage = `There was an error getting the merchandise items to refund`;

        if (err?.response?.status === 422 && err?.response?.data?.detail.length) {
          errorMessage += `: ${err?.response?.data?.detail}`;
        }

        if (err?.response?.status === 400) {
          errorMessage += `: ${err.response.data.detail}`;
        }

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  }
);
