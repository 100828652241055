import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  Switch,
  Typography
} from "@mui/material";
import React, { ReactElement, useEffect } from "react";
import {
  addMoveInInsurancePlan,
  clearMoveInInsurancePlan,
  selectMoveInInsurancePlan
} from "../../../../../store/reducers/moveInSlice/moveInSlice";
import {
  selectExternalTPPModalOpen, selectExternalTppPlanActive,
  setExternalTPPModalOpen,
  setExternalTppPlanActive,
  setIsEditExternalPlan,
  setSelectedExternalTppPlan
} from "src/store/reducers/externalTppPlanSlice/externalTppPlanSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { Edit } from "@mui/icons-material";
import ItemSelector from "../ItemSelector/ItemSelector";
import { formattedAmount } from "src/utils/formattedAmount/formattedAmount";
import { getMoveInCost } from "../../../../../store/thunks/moveIn/getMoveInCost/getMoveInCost";
import {
  selectActiveInsurancePlans
} from "src/store/reducers/insurancePlansSlice/insurancePlansSlice";
import useStyles from "./InsurancePlansList.styles";

const InsurancePlans: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const plans = useAppSelector(selectActiveInsurancePlans);
  const moveInInsurancePlan = useAppSelector(selectMoveInInsurancePlan);
  const hasExternalTpp = useAppSelector(selectExternalTppPlanActive);
  const externalTPPModalOpen = useAppSelector(selectExternalTPPModalOpen);
  const dispatch = useAppDispatch();

  const updateSummary = () => {
    dispatch(getMoveInCost());
  };

  const isChecked = (id: number) => moveInInsurancePlan === id;

  const removePlan = () => {
    dispatch(clearMoveInInsurancePlan());
    updateSummary();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const planId = parseInt(event.target.value);

    if (planId === moveInInsurancePlan) {
      removePlan();
      return;
    }

    dispatch(addMoveInInsurancePlan(planId));
    updateSummary();
  };

  const editExternalTppPlan = () => {
    dispatch(setIsEditExternalPlan(true));
    dispatch(setExternalTPPModalOpen(true));
  };

  const handleExternalTppChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (hasExternalTpp) {
      dispatch(setIsEditExternalPlan(false));
      dispatch(setExternalTppPlanActive(false));
      dispatch(setSelectedExternalTppPlan(null));
      return;
    }

    dispatch(setExternalTPPModalOpen(!externalTPPModalOpen));
    dispatch(clearMoveInInsurancePlan());
  };

  const selectLowestPlan = async() => {
    if (hasExternalTpp) {
      return;
    }

    const preferredTppPlan = plans.filter((plan) => plan.is_auto_enroll);

    if (preferredTppPlan.length) {
      await dispatch(addMoveInInsurancePlan(preferredTppPlan[0].id));
    } else {
      const lowestPlan = plans.sort((a, b) => parseFloat(a.premium_amount) - parseFloat(b.premium_amount));

      if (lowestPlan.length) {
        await dispatch(addMoveInInsurancePlan(lowestPlan[0].id));
      }
    }

    await updateSummary();
  };

  useEffect(() => {
    selectLowestPlan();
  }, []);

  const planList = plans.map((plan) => (
    <ItemSelector
      key={plan.id}
      item={plan}
      isChecked={isChecked(plan.id)}
      itemLabel={`${plan.name}`}
      itemPrice={formattedAmount(0, plan.premium_amount)}
      changeHandler={handleChange}
      removeItem={removePlan}
      isDisabled={hasExternalTpp}
    />
  ));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InputLabel className={classes.headerCell} htmlFor={"move-in-charges"}>
          <Typography noWrap>Insurance Plans</Typography>
        </InputLabel>
      </Grid>

      <Grid item xs={10}>
        <FormControl className={classes.feesListWrapper} component={"fieldset"} variant={"standard"}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch onChange={handleExternalTppChange} checked={hasExternalTpp} />
            }
              label={"I have my own external plan"}
            />
          </FormGroup>
        </FormControl>
      </Grid>
      {hasExternalTpp
        ? <Grid item xs={2}>
          <IconButton onClick={editExternalTppPlan}>
            <Edit/>
          </IconButton>
        </Grid>
        : null}
      <Grid item xs={12}>
        {plans.length ? planList : <Typography>No plans available at this facility</Typography>}
      </Grid>
    </Grid>
  );
};

export default InsurancePlans;
