import { FormikErrors, FormikTouched } from "formik";
import { Grid, InputLabel, TextField } from "@mui/material";
import React, { ChangeEvent, FC } from "react";
import FacilityFormValues from "src/models/formikInputValues/FacilityFormValues";
import FacilityHoursForm from "../FacilityHoursForm/FacilityHoursForm";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import { inputError } from "src/utils/showInputError/showInputError";
import { timeZones } from "src/utils/__dateAndTimeUtils__/timeZones";
import useStyles from "./HoursOfOperationSection.styles";

interface HoursOfOperationSectionProps {
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void :
            (e: string | ChangeEvent<any>) => void;
    },
    touched: FormikTouched<FacilityFormValues>
    errors: FormikErrors<FacilityFormValues>
    values: FacilityFormValues
    setFieldError: (field: string, value: string | undefined) => void
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

const HoursOfOperationSection: FC<HoursOfOperationSectionProps> = ({
  handleChange,
  touched,
  errors,
  values,
  setFieldError,
  setFieldTouched,
  setFieldValue
}) => {
  const { classes } = useStyles();

  return (
    <Grid container>
      <Grid
        container
        spacing={2}
        item
        xs={12}
      >
        <Grid item xs={6}>
          <FacilityHoursForm
            formik={{
              handleChange,
              errors,
              touched,
              values,
              setFieldValue,
              setFieldTouched,
              setFieldError
            }}
        />
        </Grid>
        <Grid
          container
          spacing={2}
          item
          xs={6}
        >
          <Grid item xs={12}>
            <InputLabel className={classes.labelWeight} htmlFor={"timezone"}>Time Zone</InputLabel>
            {/* is this a dropdown or text field... */}
            <PMSSelect
              id={"timezone"}
              name={"timezone"}
              value={values.timezone}
              changeHandler={handleChange}
              error={inputError("timezone", touched, errors).error}
              helperText={inputError("timezone", touched, errors).helperText}
            >
              <option disabled value={""}>Select a Time Zone...</option>
              {timeZones.map((item) => <option key={item.name} value={item.name}>{item.displayName}</option>)}
            </PMSSelect>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HoursOfOperationSection;
