import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles<{ showDrawer: boolean }>({ label: "NavDrawer" })((theme, { showDrawer }) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    height: "100%",
    "@media (min-height: 1024px)": {
      width: "100%"
    }
  },
  divider: {
    margin: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    width: showDrawer ? "79%" : "22%",
    background: theme.palette.text.disabled
  },
  appBar: {
    padding: "0.5rem 0.2rem",
    backgroundColor: theme.palette.primary.dark
  },
  closeDrawerButton: {
    background: theme.palette.common.white,
    color: theme.palette.common.black,
    [theme.breakpoints.up("md")]: {
      display: "none"
    },
    [theme.breakpoints.down("lg")]: {
      display: "visible",
      left: "4.75rem"
    },
    "@media (max-height: 414px)": {
      left: "2.75rem"
    },
    [theme.breakpoints.down("md")]: {
      left: "2.25rem"
    }
  },
  headerText: {
    marginLeft: "0.15rem",
    flexGrow: 1,
    color: theme.palette.common.white
  },
  userAvatar: {
    backgroundColor: "#001b65",
    marginRight: "1.25rem"
  }
}));

export default useStyles;
