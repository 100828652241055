import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "SelectedLedgerTableCoumns" })((theme) => ({
  dueStatus: {
    padding: theme.spacing(0, 1),
    border: `1px solid ${theme.palette.success.main}`,
    color: theme.palette.success.main
  },
  pastDueStatus: {
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main
  }
}));

export default useStyles;
