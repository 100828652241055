import { CreateRevenueClassPayload } from "../create/CreateRevenueClassPayload";
import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const updateRevenueClass = createAsyncThunk(
  "updateRevenueClass",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const facilityId = state.selectedAdminFacility.selectedAdminFacility!.id;
    const revenueClassName = state.revenueClassInformation.revenueClassName;
    const chartAccountId = state.revenueClassInformation.chartAccountId;
    const stateTax = state.revenueClassInformation.stateTax;
    const cityTax = state.revenueClassInformation.cityTax;
    const countyTax = state.revenueClassInformation.countyTax;
    const localTax = state.revenueClassInformation.localTax;
    const totalTax = state.revenueClassInformation.totalTax;
    const revenueClassId = state.revenueClassInformation.selectedRevenueClassId;

    if (!facilityId) {
      const errorMessage = "Facility ID not found";

      dispatch(showSnackbar({
        message: errorMessage,
        variant: "error"
      }));

      rejectWithValue(errorMessage);
      return;
    }

    if (!revenueClassId) {
      const errorMessage = "Revenue Class ID not found";

      dispatch(showSnackbar({
        message: errorMessage,
        variant: "error"
      }));

      rejectWithValue(errorMessage);
      return;
    }

    const payload: CreateRevenueClassPayload = {
      name: revenueClassName,
      chart_account_id: chartAccountId,
      tax_state: stateTax,
      tax_city: cityTax,
      tax_county: countyTax,
      tax_local: localTax,
      tax_total: totalTax
    };

    return axios.put(`/api/v1/facilities/${facilityId}/revenue-classes/${revenueClassId}`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: `${revenueClassName} successfully updated.`,
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an error updating this Revenue Class";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
