import Checkbox, { PMSCheckboxProps } from "../../components/ui/PMSCheckbox/PMSCheckbox";
import { Meta, Story } from "@storybook/react";
import React, { useState } from "react";

export default {
  title: "1 • COMPONENTS/UI",
  component: Checkbox
} as Meta;

const Template: Story<PMSCheckboxProps> = (args: any) => {
  const [checked, setChecked] = useState(false);
  return (
    <Checkbox
      {...args}
      isChekecd={checked}
      changeHandler={() => setChecked(!checked)}
      />
  );
};

export const PMSCheckbox = Template.bind({});

PMSCheckbox.args = {
  label: "Option A"
};

PMSCheckbox.parameters = {
  layout: "centered",
  docs: {
    source: {
      code: `
      <PMSCheckbox
        changeHandler={() => {}}
        label={label}
        size="medium"
        name={name}
        isChecked={isChecked}
        smallText={false}
        typographyNoWrap={false}
        value={value}
      />
      `
    }
  }
};
