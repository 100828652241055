import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const api = createApi({
  tagTypes: [
    "DealNotifications",
    "Notes",
    "Auctions",
    "CommonProductTypes",
    "CommonUnitSizes",
    "Webhooks",
    "TppPlans",
    "DelinquentOccupants",
    "FlatUnitsPage",
    "FlatUnit"
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.config.apiUri}`,
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem("bearerToken");

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
    validateStatus: (resp: Response) => {
      if (resp.status === 401) {
        sessionStorage.removeItem("bearerToken");
        sessionStorage.removeItem("refreshToken");
        window.location.assign(window.config.identityFrontend);
        return false;
      }

      if (resp.status === 403) {
        window.location.assign("/403");
        return false;
      }

      if (resp.status === 500) {
        window.location.assign("/500");
        return false;
      }

      return true;
    }
  }),
  endpoints: (builder) => ({})
});

export default api;
