import { Grid, Paper, Typography } from "@mui/material";
import React, { FC } from "react";
import { Note } from "src/models/Note";
import moment from "moment";
import useStyles from "./Note.styles";

type NoteProps = Note;

const AppNote: FC<NoteProps> = ({ description, username, created_at = null }) => {
  const { classes } = useStyles();

  return (
    <Paper data-testid={"note"} className={classes.note}>
      <Grid
        container
        item
        xs={12}
      >
        <Grid
          container
          alignItems={"center"}
          justifyContent={"flex-end"}>
          <Typography
            fontWeight={900}
            fontStyle={"italic"}
            gutterBottom
          >
            {username}&nbsp;
          </Typography>
          <Typography
            fontStyle={"italic"}
            gutterBottom
          >
            {moment(created_at).format("MM/DD/YYYY h:mm A")}
          </Typography>
        </Grid>
      </Grid>
      <Grid>
        <Typography>{description}</Typography>
      </Grid>
    </Paper>
  );
};

export default AppNote;
