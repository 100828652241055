import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getAllTasksForCalendar = createAsyncThunk(
  "getAllTasksForCalendar",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const facilityId = state.selectedFacility.selectedFacility?.id;

    const params = {
      params: {
        with: ["taskCategory:id,name,color", "assignees:id,facility_id"],
        "filter[assignees:id]": facilityId,
        pageSize: 999
      }
    };

    return axios.get("/api/v1/tasks", params)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = `There was an error getting the Tasks`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
