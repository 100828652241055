import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles<{ }>({ label: "Review and Apply Payment" })(
  (theme) => ({
    root: {
      width: "100%"
    },
    remainingBalance: {
      border: "solid #000 1px",
      borderColor: theme.palette.text.primary,
      borderRadius: "5px",
      color: theme.palette.text.primary
    },
    remainingBalanceFont: {
      fontWeight: "bold"
    },
    table: {
      border: "none",
      backgroundColor: "#EFF4FB",
      marginBottom: theme.spacing(2)
    },
    total: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(1),
      border: `1.5px solid ${theme.palette.text.primary}`,
      borderRadius: "0.25rem",
      marginTop: theme.spacing(2)
    },
    headerCell: {
      color: theme.palette.common.black,
      background: theme.palette.background.default,
      padding: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      width: "100%"
    },
    baseButton: {
      textTransform: "capitalize"
    },
    textButton: {
      color: theme.palette.primary.contrastText
    },
    prepayPeriodsHeaderCell: {
      color: theme.palette.common.black,
      padding: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      width: "100%"
    }
  }));

export default useStyles;
