import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles<{ hasRemainingBalance: boolean }>({ label: "Payment Confirmation Table" })(
  (theme, { hasRemainingBalance }) => ({
    table: {
      border: "none",
      marginBottom: theme.spacing(2),
      width: "100%"
    },
    buttons: {
      justifyContent: "flex-end"
    },
    total: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(1),
      border: `1.5px solid ${hasRemainingBalance ? theme.palette.error.main : theme.palette.info.main}`,
      width: "100%",
      color: hasRemainingBalance ? theme.palette.error.main : theme.palette.text.primary
    },
    amountPaid: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(1)
    }
  }));
export default useStyles;
