import {
  Button,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  SelectChangeEvent,
  TextField
} from "@mui/material";
import React, { FC, ReactNode, useEffect } from "react";
import reports, { Report } from "src/pages/Reporting/reports";
import {
  selectCurrentReport,
  selectReportDate,
  selectReportEndDate,
  selectReportFacilities,
  selectReportFacility,
  selectReportHours,
  selectReportStartDate,
  setCurrentReport,
  setDisableApplyParametersButton,
  setParamsBuilderConfirmationModalOpen,
  setParamsBuilderModalOpen,
  setReportDate,
  setReportEndDate,
  setReportFacilities,
  setReportFacility,
  setReportHours,
  setReportStartDate
} from "src/store/reducers/reportParamsSlice/reportParamsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { DatePicker } from "@mui/x-date-pickers";
import { Facility } from "src/models/Facility";
import { PMSCheckbox } from "src/stories/ui/pms-checkbox.stories";
import PMSMultiSelect from "src/components/ui/PMSMultiSelect/PMSMultiSelect";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import getValidationSchema from "./facilityMultiSelectAndDateValidation";
import { inputError } from "src/utils/showInputError/showInputError";
import moment from "moment";
import { selectFacilities } from "src/store/reducers/facilitiesSlice/facilitiesSlice";
import selectedFacilitiesDisplay from "./selectedFacilitiesDisplay";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import useStyles from "./FacilityMultiSelectAndDate.styles";

interface FacilityMultiSelectAndDateProps {
  isParamEdit?: boolean;
}

const FacilityMultiSelectAndDateRange: FC<FacilityMultiSelectAndDateProps> = ({ isParamEdit = false }) => {
  const dispatch = useAppDispatch();
  const allFacilities = useAppSelector(selectFacilities);
  const allFacilitiesExcludeTest = allFacilities.filter((facility) => Boolean(facility.is_test) === false);
  const attachedFacilities = useAppSelector(selectReportFacilities);
  const attachedFacility = useAppSelector(selectReportFacility);
  const startDate = useAppSelector(selectReportStartDate);
  const endDate = useAppSelector(selectReportEndDate);
  const hours = useAppSelector(selectReportHours);
  const date = useAppSelector(selectReportDate);
  const reportName = useAppSelector(selectCurrentReport);
  const selectedReport = reports.find((report) => report.name === reportName);

  const { classes } = useStyles();

  interface FormikFields {
    attachedFacilities?: number[];
    attachedFacility?: number[];
    startDate?: string;
    endDate?: string;
    date?: string;
    hours?: string;
  }

  const initialValues: FormikFields = {};

  if (selectedReport?.inputs.includes("SingleSelect")) {
    initialValues.attachedFacility = attachedFacility ?? [];
  }

  if (selectedReport?.inputs.includes("MultiSelect")) {
    initialValues.attachedFacilities = attachedFacilities ?? [];
  }

  if (selectedReport?.inputs.includes("DateRange")) {
    initialValues.startDate = startDate ?? "";
    initialValues.endDate = endDate ?? "";
  }

  if (selectedReport?.inputs.includes("Date")) {
    initialValues.date = date ?? "";
  }

  if (selectedReport?.inputs.includes("Hours")) {
    initialValues.hours = hours ?? "";
  }

  const handleNext = () => {
    dispatch(setParamsBuilderModalOpen(false));
    dispatch(setParamsBuilderConfirmationModalOpen(true));
  };

  const handleClose = () => {
    dispatch(setParamsBuilderModalOpen(false));
    dispatch(setCurrentReport(""));
    dispatch(setReportEndDate(""));
    dispatch(setReportStartDate(""));
    dispatch(setReportDate(""));
    dispatch(setReportHours(""));
    dispatch(setReportFacilities([]));
    dispatch(setReportFacility([]));
  };

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(selectedReport as Report),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => { handleNext(); }
  });
  const { values, errors, touched, setFieldValue, handleSubmit } = formik;

  const handleSingleSelectFacility = (e: SelectChangeEvent<any>, _: ReactNode) => {
    const value = [e.target.value] as number[];
    setFieldValue("attachedFacility", value);
    dispatch(setReportFacility(value));
  };

  const handleFacility = (e: SelectChangeEvent<any>, _: ReactNode) => {
    let value = e.target.value as number[];
    value = value.includes(1) ? [1] : value;

    setFieldValue("attachedFacilities", value);
    dispatch(setReportFacilities(value));
  };

  const handleDate = (fieldName: string, action: ActionCreatorWithPayload<any, string>, date?: string | null) => {
    const formattedDate = moment(date).format("MM/DD/yyyy");
    setFieldValue(fieldName, formattedDate);

    dispatch(action(formattedDate));
  };

  const handleHours = (fieldName: string, value:string) => {
    setFieldValue(fieldName, value);

    dispatch(setReportHours(value));
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (!selectedReport) {
      navigate("/reporting/view-reports");
    }
  }, []);

  const disabled = Boolean(
    errors.startDate ||
    errors.endDate ||
    errors.date ||
    errors.hours ||
    errors.attachedFacilities ||
    errors.attachedFacility
  );

  // use this effect for when you no longer have access to the formik instance,
  // and still need to validate input. The idea is, validate form in modal.
  // if in other instance, where it is isParamEdit, validate on blur or change.
  // if there are errors, disable the apply parameters button.
  useEffect(() => {
    dispatch(setDisableApplyParametersButton(disabled));
  }, [disabled]);

  const showFacilityValidationError = (isParamEdit && disabled && Boolean(errors.attachedFacilities)) ||
  inputError("attachedFacilities", touched, errors).error;
  const showSingleFacilityValidationError = (isParamEdit && disabled && Boolean(errors.attachedFacility)) ||
  inputError("attachedFacility", touched, errors).error;
  const showHoursValidationError = (isParamEdit && disabled && Boolean(errors.hours)) ||
  inputError("hours", touched, errors).error;
  const showStartDateValidationError = (isParamEdit && disabled && Boolean(errors.startDate)) ||
    inputError("startDate", touched, errors).error;
  const showEndDateValidationError = (isParamEdit && disabled && Boolean(errors.endDate)) ||
    inputError("endDate", touched, errors).error;
  const showDateValidationError = (isParamEdit && disabled && Boolean(errors.date)) ||
    inputError("date", touched, errors).error;
  const facilityInputHelperText = isParamEdit
    ? errors.attachedFacilities
    : inputError("attachedFacilities", touched, errors).helperText;
  const singleFacilityInputHelperText = isParamEdit
    ? errors.attachedFacility
    : inputError("attachedFacility", touched, errors).helperText;
  const startDateInputHelperText = isParamEdit ? errors.startDate : inputError("startDate", touched, errors).helperText;
  const endDateInputHelperText = isParamEdit ? errors.endDate : inputError("endDate", touched, errors).helperText;
  const dateInputHelperText = isParamEdit ? errors.date : inputError("date", touched, errors).helperText;
  const hoursInputHelperText = isParamEdit ? errors.hours : inputError("hours", touched, errors).helperText;

  return (
    <Grid
      component={"form"}
      id={"facility-and-dates"}
      container
      spacing={2}
    >
      {selectedReport?.inputs.includes("SingleSelect") && (
        <Grid item xs={isParamEdit ? 3 : 12}>
          <PMSSelect
            defaultValue={""}
            id={"attached-facility"}
            label={"Facilities"}
            value={values.attachedFacility}
            changeHandler={handleSingleSelectFacility}
            error={showSingleFacilityValidationError}
            helperText={singleFacilityInputHelperText}
            >
            <option value={""} disabled>- Select Facility -</option>
            {allFacilitiesExcludeTest.map((facility) => (
              <option key={facility.id} value={facility.id}>
                <ListItemText primary={facility.facility_id} />
              </option>
            ))}
          </PMSSelect>
        </Grid>
      )}
      {selectedReport?.inputs.includes("MultiSelect") && (
        <Grid item xs={isParamEdit ? 3 : 12}>
          <PMSMultiSelect
            id={"attached-facilities"}
            label={"Facilities"}
            value={values.attachedFacilities}
            changeHandler={handleFacility}
            error={showFacilityValidationError}
            helperText={facilityInputHelperText}
            renderValue={(facilityIds) => selectedFacilitiesDisplay(facilityIds, allFacilitiesExcludeTest)}
          >
            <MenuItem disabled>- Select Facility -</MenuItem>
            <MenuItem key={1} value={1}>
              <PMSCheckbox isChecked={!!values.attachedFacilities?.includes(1)} />
              <ListItemText primary={"All Facilities"} />
            </MenuItem>
            {allFacilitiesExcludeTest.map((facility:Facility) => (
              <MenuItem disabled={!!values.attachedFacilities?.includes(1)} key={facility.id} value={facility.id}>
                <PMSCheckbox isChecked={!!values.attachedFacilities?.includes(facility.id)} />
                <ListItemText primary={facility.facility_id} />
              </MenuItem>
            ))}
          </PMSMultiSelect>
        </Grid>
      )}
      {selectedReport?.inputs.includes("Hours") && (
        <Grid item xs={isParamEdit ? 3 : 12}>
          <PMSSelect
            id={"attached-facilities"}
            label={"Hours"}
            value={values.hours}
            changeHandler={(e) => handleHours("hours", e.target.value)}
            error={showHoursValidationError}
            helperText={hoursInputHelperText}
          >
            <option value={""} disabled>- Hours -</option>
            <option value={"6"}>6</option>
            <option value={"12"}>12</option>
            <option value={"18"}>18</option>
            <option value={"24"}>24</option>
            <option value={"48"}>48</option>
            <option value={"72"}>72</option>
            <option value={"96"}>96</option>
          </PMSSelect>
        </Grid>
      )}
      {selectedReport?.inputs.includes("DateRange") && (
        <>
          <Grid item xs={isParamEdit ? 3 : 6}>
            <InputLabel htmlFor={"start-date"}>Start Date:</InputLabel>
            <DatePicker
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={"startDate"}
                  variant={"outlined"}
                  fullWidth
                  helperText={startDateInputHelperText}
                  onKeyDown={(e) => e.preventDefault()}
                  error={showStartDateValidationError}
                  />
              )}
              value={values.startDate}
              onChange={(date) => handleDate("startDate", setReportStartDate, date)}
            />
          </Grid>
          <Grid item xs={isParamEdit ? 3 : 6}>
            <InputLabel htmlFor={"end-date"}>End Date:</InputLabel>
            <DatePicker
              renderInput={params => (
                <TextField
                  {...params}
                  name={"endDate"}
                  variant={"outlined"}
                  fullWidth
                  onKeyDown={(e) => e.preventDefault()}
                  error={showEndDateValidationError}
                  helperText={endDateInputHelperText}
                />
              )}
              value={values.endDate}
              onChange={(date) => handleDate("endDate", setReportEndDate, date)}
            />
          </Grid>
        </>
      )}
      {selectedReport?.inputs.includes("Date") && (
        <Grid item xs={isParamEdit ? 3 : 12}>
          <InputLabel htmlFor={"date"}>Date:</InputLabel>
          <DatePicker
            renderInput={(params) => (
              <TextField
                {...params}
                name={"date"}
                variant={"outlined"}
                fullWidth
                onKeyDown={(e) => e.preventDefault()}
                error={showDateValidationError}
                helperText={dateInputHelperText}
                />
            )}
            value={values.date}
            onChange={(date) => handleDate("date", setReportDate, date)}
            />
        </Grid>
      )}
      {!isParamEdit
        ? <Grid
            mt={2}
            container
            justifyContent={"flex-end"}
            spacing={2}
            item
            xs={12}
        >
          <Grid item>
            <Button onClick={handleClose} color={"error"}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              disableElevation
              onClick={() => handleSubmit()}
              variant={"contained"}
              className={classes.saveButton}
            >
              Next
            </Button>
          </Grid>
        </Grid>
        : null}
    </Grid>
  );
};

export default FacilityMultiSelectAndDateRange;
