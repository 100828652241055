import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../rootStore";
import { getApplyFeeCost } from "src/store/thunks/applyFee/getApplyFeeCost";
import { Fee } from "src/models/Fee";
import { getAppliedFees } from "src/store/thunks/applyFee/getAppliedFees";

interface ApplyFee {
  id: number;
  name: string;
  amount: string;
  total_amount: string;
  tax_amount: string;
}

interface ApplyFeeCost {
  fees?: ApplyFee[];
  amount?: string;
  total_amount?: string;
  tax_amount?: string;
}

interface ApplyFeeState {
  applyFeeCost: ApplyFeeCost;
  loading: boolean;
  alreadyAppliedFees: Fee[];
}

export const initialState: ApplyFeeState = {
  applyFeeCost: {
    fees: [],
    amount: "",
    total_amount: "",
    tax_amount: ""
  },
  alreadyAppliedFees: [],
  loading: false
};

export const applyFee = createSlice({
  name: "applyFee",
  initialState,
  reducers: {
    resetApplyFeeCost: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApplyFeeCost.fulfilled, (state, action) => {
        state.applyFeeCost.amount = action.payload.amount;
        state.applyFeeCost.total_amount = action.payload.total_amount;
        state.applyFeeCost.tax_amount = action.payload.tax_amount;
        state.applyFeeCost.fees = action.payload.fees;
        state.loading = false;
      })
      .addCase(getApplyFeeCost.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getApplyFeeCost.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAppliedFees.fulfilled, (state, action) => {
        state.alreadyAppliedFees = action.payload;
      });
  }
});

export const selectApplyFee = (state: RootState) => state.applyFee;

export const { resetApplyFeeCost } = applyFee.actions;

export default applyFee.reducer;
