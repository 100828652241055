import React, { ReactElement } from "react";
import {
  selectExpansionRowsLoading,
  selectOpenExpansionRows,
  setExpansionRowsLoading,
  setOpenExpansionRows
} from "../../../../../store/reducers/tablesSlice/tablesSlice";
import {
  selectSelectedProductType,
  setSelectedProductType
} from "../../../../../store/reducers/selectedUnitSlice/selectedUnitSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ProductType } from "../../../../../models/ProductType";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import UnitRow from "./UnitRow/UnitRow";
import clsx from "clsx";
import {
  getAvailableUnitsByProductType
} from "../../../../../store/thunks/unit/getAvailableUnitsByProductType/getAvailableUnitsByProductType";
import { selectDealType } from "src/store/reducers/dealInformationSlice/dealInformationSlice";
import { selectExpansionTableUnits } from "../../../../../store/reducers/unitsSlice/unitsSlice";
import useStyles from "./ProductTypesRow.styles";

interface ProductTypesRowProps {
  productType: ProductType;
}

const ProductTypesRow: React.FC<ProductTypesRowProps> = ({ productType }: ProductTypesRowProps): ReactElement => {
  const dispatch = useAppDispatch();
  const openExpansionRows = useAppSelector(selectOpenExpansionRows);
  const rowsLoading = useAppSelector(selectExpansionRowsLoading);
  const units = useAppSelector(selectExpansionTableUnits);
  const isOpen = openExpansionRows.includes(productType.id);
  const rowLoading = rowsLoading.includes(productType.id);
  const price = productType.price;

  const selectedProductType = useAppSelector(selectSelectedProductType);
  const activeProductTypeRow = {
    isSelected: selectedProductType?.id === productType.id
  };
  const { classes } = useStyles(activeProductTypeRow);
  const iconArrow = isOpen
    ? (
      <KeyboardArrowUpIcon data-testid={"up-arrow"} />
      )
    : (
      <KeyboardArrowDownIcon data-testid={"down-arrow"} />
      );

  const expansionRowDisplay = () => {
    if (rowLoading || !isOpen) {
      return null;
    }

    if (units[productType.id] && Object.keys(units).length) {
      return units[productType.id].map((unit) => {
        const unitSize = unit.width ? `${unit.width}' x ${unit.length}'` : `${unit.length}'`;
        return (
          <UnitRow key={unit.id} data-testid={"unit-row"} unit={unit}
            price={`${unit.price}`} unitSize={unitSize} />
        );
      });
    }

    return (
      <TableRow>
        <TableCell
          className={classes.noProductTypesRow}
          colSpan={6}
          component={"th"}
          scope={"unit"}
          data-testid={`no-units-row`}
        >
          No units available for this Product Type
        </TableCell>
      </TableRow>
    );
  };

  const handleExpansionClick = () => {
    if (!isOpen) {
      dispatch(setExpansionRowsLoading(productType.id));
      dispatch(getAvailableUnitsByProductType(productType.id));
    }

    dispatch(setSelectedProductType(productType));
    dispatch(setOpenExpansionRows(productType.id));
  };

  return (
    <>
      <TableRow
        data-testid={"product-type-row"}
        onClick={() => {}}
        className={clsx(
          classes.productTypeRow
          // dealType === "lead" && classes.pointerActive,
          // dealType === "lead" && classes.activeProductTypeRow
        )}
        hover
        tabIndex={-1}
      >
        <TableCell component={"th"} scope={"row"}>
          {productType.name}
        </TableCell>
        <TableCell>W X L</TableCell>
        {/*The following line is an intentionally empty table cell. The parent row of the Product Types table*/}
        {/*does not display any information about floor, but the expanded child row does - Conor */}
        <TableCell />
        <TableCell>${price}</TableCell>
        <TableCell>{productType.available_units}</TableCell>
        <TableCell>{productType.total_units}</TableCell>
        <TableCell>
          <IconButton
            data-testid={`${productType.id}-expansion-button`}
            aria-label={`${productType.name} expansion row`}
            size={"small"}
            disabled={rowLoading}
            onClick={() => handleExpansionClick()}
          >
            {rowLoading ? <CircularProgress data-testid={"loading-spinner"} size={22} /> : iconArrow}
          </IconButton>
        </TableCell>
      </TableRow>
      {expansionRowDisplay()}
    </>
  );
};

export default ProductTypesRow;
