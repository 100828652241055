import { makeStyles } from "../../../../../makeStyles";

const useStyles = makeStyles({ label: "DisableModal" })((theme) => ({
  innerModalContainer: {
    maxWidth: "25rem",
    padding: "1rem"
  },
  modalTitle: {
    margin: "0.75rem 0rem"
  },
  modalInputs: {
    marginTop: "1rem",
    width: "15rem"
  },
  buttonRow: {
    margin: "1rem 0"
  },
  modalButton: {
    textTransform: "capitalize",
    borderRadius: "5px",
    color: "black",
    width: "6rem",
    height: "3rem"
  },
  confirm: {
    border: `1px solid ${theme.palette.primary.contrastText}`
  },
  cancel: {
    border: `1px solid ${theme.palette.error.contrastText}`
  }
}));

export default useStyles;
