import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "SelectProductType" })((theme) => ({
  root: {
    height: "100%"
  },
  label: {
    color: theme.palette.common.black,
    paddingBottom: theme.spacing(1)
  },
  cancelButton: {
    color: theme.palette.primary.contrastText,
    textTransform: "capitalize",
    height: "3rem"
  },
  arrowBack: {
    marginRight: theme.spacing(1)
  },
  nextButtonContainer: {
    justifySelf: "flex-end"
  },
  nextButton: {
    background: theme.palette.primary.contrastText,
    color: theme.palette.common.white,
    textTransform: "capitalize",
    width: "10rem",
    height: "3rem"
  },
  boldLabel: {
    fontWeight: 500
  },
  backButton: {
    display: "flex",
    flexWrap: "nowrap",
    minWidth: "6rem",
    justifyContent: "center"
  },
  propertyType: {
    textTransform: "capitalize"
  },
  warningText: {
    marginLeft: theme.spacing(2)
  }
}));

export default useStyles;
