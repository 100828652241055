import { Divider, Typography } from "@mui/material";
import React, { ReactElement, useEffect } from "react";
import {
  selectCustomPaymentAmount,
  selectLedgerPayments,
  selectNetGrandTotalGross,
  selectOutStandingBalance,
  selectRunningBalance,
  selectSelectedLedgers,
  selectSelectedLedgersBeforePayment
} from "../../../../../store/reducers/makeAPaymentSlice/makeAPaymentSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";

import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import EmptyTable from "../../../../../components/ui/PMSTable/EmptyTable/EmptyTable";
import PaymentConfirmationTableColumns from "./PaymentConfirmationTableColumns/PaymentConfirmationTableColumns";
import {
  getAllLedgersForOccupant
} from "../../../../../store/thunks/ledger/getAllForOccupant/getAllLedgersForOccupant";
import { selectSelectedOccupant } from "../../../../../store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import useStyles from "./PaymentConfirmationTable.styles";

const PaymentConfirmationTable: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const selectedOccupant = useAppSelector(selectSelectedOccupant);
  const selectedLedgersBeforePayment = useAppSelector(selectSelectedLedgersBeforePayment);
  const selectedLedgers = useAppSelector(selectSelectedLedgers);
  const selectedLedgerIds = selectedLedgers.map(l => l.id);
  const remainingBalance = useAppSelector(selectRunningBalance);
  const hasRemainingBalance = remainingBalance > 0;
  const { classes } = useStyles({ hasRemainingBalance });
  const [ledgerPayment] = useAppSelector(selectLedgerPayments);
  const outstandingBalance = useAppSelector(selectOutStandingBalance);

  const emptyTable = () => (
    <EmptyTable
      collectionNotFound={`data`}
      value={"data"}
    />
  );

  useEffect(() => {
    if (selectedOccupant) {
      dispatch(getAllLedgersForOccupant({
        occupantId: selectedOccupant.id,
        filter: { "filter[id]": selectedLedgerIds.join(",") }
      }));
    }
  }, [selectedOccupant, selectedLedgers]);

  return (
    <DataGrid
      className={classes.table}
      autoHeight
      components={{
        NoResultsOverlay: emptyTable,
        NoRowsOverlay: emptyTable
      }}
      columns={PaymentConfirmationTableColumns(selectedOccupant)}
      rows={selectedLedgersBeforePayment}
      hideFooterPagination={true}
      hideFooterSelectedRowCount={true}
      disableSelectionOnClick
      loading={false}
      checkboxSelection={false}
    />
  );
};

export default PaymentConfirmationTable;
