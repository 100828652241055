import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PaymentMethod } from "../../../enums/PaymentMethod";
import { PaymentSliceState } from "./PaymentSliceState";
import { PaymentType } from "../../../models/PaymentType";
import { RootState } from "../../rootStore";
import centsToDollars from "src/utils/centsToDollars/centsToDollars";
import { getMaxAmount } from "src/store/thunks/makeAPayment/getMaxAmount/getMaxAmount";

export const initialState: PaymentSliceState = {
  paymentType: PaymentMethod.cash,
  totalDueToday: "",
  maxPaymentAmount: null
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setPaymentType: (state, action: PayloadAction<PaymentType>) => {
      state.paymentType = action.payload;
    },
    setTotalDueToday: (state, action: PayloadAction<string>) => {
      state.totalDueToday = action.payload;
    },
    resetPaymentSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMaxAmount.fulfilled, (state, action) => {
      if (action.payload.grand_total === 0) {
        state.maxPaymentAmount = 0;
      }

      if (action.payload.grand_total) {
        state.maxPaymentAmount = action.payload.grand_total === 0 ? 0 : centsToDollars(action.payload.grand_total);
      }
    });
  }
});

export const selectTotalDueToday = (state: RootState) => state.payment.totalDueToday;
export const selectMaxPaymentAmount = (state: RootState) => state.payment.maxPaymentAmount;
export const selectPaymentType = (state: RootState) => state.payment.paymentType;

export const {
  setPaymentType,
  setTotalDueToday,
  resetPaymentSlice
} = paymentSlice.actions;

export default paymentSlice.reducer;
