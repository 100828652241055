import { FormGroup } from "@mui/material";
import PMSCheckbox from "src/components/ui/PMSCheckbox/PMSCheckbox";
import React from "react";

function PaymentOptions({
  isAutoBillingEnabled,
  isSaveAsPreferredMethod,
  handleAutoBillingChange,
  handlePreferredMethodChange
}: {
  isAutoBillingEnabled: boolean;
  isSaveAsPreferredMethod: boolean;
  handleAutoBillingChange: () => void;
  handlePreferredMethodChange: () => void;
}) {
  return (
    <FormGroup row>
      <PMSCheckbox
        smallText
        label={"Save Payment Method"}
        name={"save-payment-method"}
        isChecked={isSaveAsPreferredMethod}
        changeHandler={() => handlePreferredMethodChange()}
      />
    </FormGroup>
  );
}

export default PaymentOptions;
