import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import FormsSliceState from "./FormsSliceState";
import { RootState } from "../../rootStore";

export const initialState: FormsSliceState = {
  currentStep: sessionStorage.getItem("currentStep") ? parseInt(sessionStorage.getItem("currentStep")!) : 1,
  advisoryOpen: JSON.parse(sessionStorage.getItem("advisoryOpen") as string) ?? true
};

export const formsReducerSlice = createSlice({
  name: "forms",
  initialState,
  reducers: {
    setCurrentStep: (state: FormsSliceState, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
      sessionStorage.setItem("currentStep", state.currentStep.toFixed());
    },
    setAdvisoryOpen: (state: FormsSliceState) => {
      state.advisoryOpen = !state.advisoryOpen;
      sessionStorage.setItem("advisoryOpen", JSON.stringify(state.advisoryOpen));
    },
    resetCurrentStep: (state: FormsSliceState) => {
      state.currentStep = 1;
      sessionStorage.setItem("currentStep", state.currentStep.toFixed());
      sessionStorage.removeItem("advisoryOpen");
    }
  }
});

export const selectCurrentStep = (state: RootState) => state.forms.currentStep;
export const selectAdvisoryOpen = (state: RootState) => state.forms.advisoryOpen;

export const {
  setCurrentStep,
  setAdvisoryOpen,
  resetCurrentStep
} = formsReducerSlice.actions;

export default formsReducerSlice.reducer;
