import { Button, Grid } from "@mui/material";
import React, { FC, useCallback, useEffect } from "react";
import {
  resetDealSlice,
  selectDealLoading,
  selectStepEditDealToReactivate,
  setStepEditDealToReactivate
} from "../../../store/reducers/dealSlice/dealSlice";
import { resetNoteSlice, selectNoteLoading } from "src/store/reducers/noteSlice/noteSlice";
import { resetNotesSlice, selectNotesLoading } from "src/store/reducers/notesSlice/notesSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { useNavigate, useParams } from "react-router";
import { ArrowBack } from "@mui/icons-material";
import { Breadcrumb } from "src/models/Breadcrumb";
import CancellationModal from "../CancellationModal/CancellationModal";
import EditDealInformation from "./EditDealTabs/EditDealInformation/EditDealInformation";
import EditDealNoteInformation from "./EditDealTabs/EditDealNoteInformation/EditDealNoteInformation";
import EditDealOccupantInformation from "./EditDealTabs/EditDealOccupantInformation/EditDealOccupantInformation";
import NoteType from "src/enums/NoteType";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import TabView from "../../../layouts/TabView/TabView";
import ViewWrapper from "../../../layouts/ViewWrapper/ViewWrapper";
import { getAllNotes } from "src/store/thunks/note/getAll/getAllNotes";
import { getDeal } from "../../../store/thunks/deals/get/getDeal";
import { resetDealInformationSlice } from "../../../store/reducers/dealInformationSlice/dealInformationSlice";
import { resetSelectedOccupantSlice } from "../../../store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { selectSelectedFacility } from "../../../store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { resetSelectedPromotionSlice } from "src/store/reducers/selectedPromotionSlice/selectedPromotionSlice";
import { resetSelectedUnitSlice } from "src/store/reducers/selectedUnitSlice/selectedUnitSlice";
import { resetTablesSlice } from "src/store/reducers/tablesSlice/tablesSlice";

interface EditDealProps { }

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Deals"
  },
  {
    name: "View and Edit Deal",
    bold: true
  }
];

const pageHeader = <PageHeader title={"View and Edit Deal"} breadcrumbs={breadcrumbs} />;

const componentList = [
  {
    name: "Occupant Information", component: <EditDealOccupantInformation />
  },
  {
    name: "Deal Information", component: <EditDealInformation />
  },
  {
    name: "Deal Notes", component: <EditDealNoteInformation />
  }
];

const EditDeal: FC<EditDealProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const dealLoading = useAppSelector(selectDealLoading);
  const stepToEditDeal = useAppSelector(selectStepEditDealToReactivate);
  const notesLoading = useAppSelector(selectNotesLoading);
  const noteLoading = useAppSelector(selectNoteLoading);

  const onSubmit = useCallback(() => {
    navigate("/deals/manage-deals");
  }, []);

  useEffect(() => {
    dispatch(getDeal({ facilityId: +selectedFacility.id, dealId: +params.id! }));
    return () => {
      dispatch(resetDealSlice());
      dispatch(resetDealInformationSlice());
      dispatch(resetSelectedOccupantSlice());
      dispatch(resetNotesSlice());
      dispatch(resetNoteSlice());
      dispatch(resetSelectedPromotionSlice());
      dispatch(resetSelectedUnitSlice());
      dispatch(resetTablesSlice());
    };
  }, []);

  return (
    <ViewWrapper pageHeader={pageHeader}>
      <Grid mt={2} item xs={12}>
        <Button
          onClick={() => navigate("/deals/manage-deals")}
          startIcon={<ArrowBack />}
          variant={"text"}>
          Go back to manage deals
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TabView
          step={stepToEditDeal}
          loading={dealLoading || notesLoading || noteLoading}
          components={componentList}
          onChangeAction={(step) => {
            dispatch(setStepEditDealToReactivate(step));
            dispatch(getDeal({ facilityId: +selectedFacility.id, dealId: +params.id! }));
            dispatch(getAllNotes({ itemId: +params.id!, noteType: NoteType.deals }));
          }}
        />
      </Grid>
      <CancellationModal onSubmit={onSubmit}/>
    </ViewWrapper >
  );
};

export default EditDeal;
