import { FormikErrors, FormikTouched } from "formik";
import { Grid, InputLabel, TextField } from "@mui/material";
import React, { ChangeEvent, FC } from "react";
import FacilityFormValues from "src/models/formikInputValues/FacilityFormValues";
import MaskedPhone from "src/components/masking/MaskedPhone/MaskedPhone";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import { inputError } from "src/utils/showInputError/showInputError";
import { timeZones } from "src/utils/__dateAndTimeUtils__/timeZones";
import usStates from "src/utils/usStates";
import useStyles from "./FacilityLocationDetailsSection.styles";

interface FacilityLocationDetailsSectionProps {
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void :
      (e: string | ChangeEvent<any>) => void;
  },
  touched: FormikTouched<FacilityFormValues>
  errors: FormikErrors<FacilityFormValues>
  values: FacilityFormValues
}

const FacilityLocationDetailsSection: FC<FacilityLocationDetailsSectionProps> =
  ({ handleChange, touched, errors, values }) => {
    const { classes } = useStyles();

    return (
      <Grid container spacing={2}>
        <Grid
          container
          spacing={2}
          item
          xs={6}
        >
          <Grid item xs={12}>
            <InputLabel className={classes.labelWeight} htmlFor={"legal_name"}>Legal Name</InputLabel>
            <TextField
              id={"legal_name"}
              fullWidth
              variant={"outlined"}
              value={values.legal_name}
              name={"legal_name"}
              onChange={handleChange}
              error={inputError("legal_name", touched, errors).error}
              helperText={inputError("legal_name", touched, errors).helperText}
              />
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.labelWeight} htmlFor={"address"}>Address Line 1</InputLabel>
            <TextField
              id={"address"}
              fullWidth
              value={values.address}
              variant={"outlined"}
              name={"address"}
              onChange={handleChange}
              error={inputError("address", touched, errors).error}
              helperText={inputError("address", touched, errors).helperText}
              />
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.labelWeight} htmlFor={"address_two"}>Address Line 2</InputLabel>
            <TextField
              id={"address_two"}
              value={values.address_two}
              fullWidth
              variant={"outlined"}
              name={"address_two"}
              onChange={handleChange}
              error={inputError("address_two", touched, errors).error}
              helperText={inputError("address_two", touched, errors).helperText}
              />
          </Grid>
          <Grid item xs={6}>
            <InputLabel className={classes.labelWeight} htmlFor={"city"}>City</InputLabel>
            <TextField
              id={"city"}
              fullWidth
              value={values.city}
              variant={"outlined"}
              name={"city"}
              onChange={handleChange}
              error={inputError("city", touched, errors).error}
              helperText={inputError("city", touched, errors).helperText}
              />
          </Grid>
          <Grid item xs={3}>
            <InputLabel className={classes.labelWeight} htmlFor={"region"}>State</InputLabel>
            <PMSSelect
              id={"region"}
              name={"region"}
              value={values.region}
              changeHandler={handleChange}
              error={inputError("region", touched, errors).error}
              helperText={inputError("region", touched, errors).helperText}
              >
              <option disabled value={""}>Select a State...</option>
              {usStates.map((state) => <option key={state.text} value={state.value}>{state.text}</option>)}
            </PMSSelect>
          </Grid>
          <Grid item xs={3}>
            <InputLabel className={classes.labelWeight} htmlFor={"postal_code"}>Zip</InputLabel>
            <TextField
              id={"postal_code"}
              value={values.postal_code}
              fullWidth
              variant={"outlined"}
              name={"postal_code"}
              onChange={handleChange}
              error={inputError("postal_code", touched, errors).error}
              helperText={inputError("postal_code", touched, errors).helperText}
              />
          </Grid>
          <Grid item xs={6}>
            <InputLabel className={classes.labelWeight} htmlFor={"email"}>Email</InputLabel>
            <TextField
              id={"email"}
              fullWidth
              value={values.email}
              variant={"outlined"}
              name={"email"}
              onChange={handleChange}
              error={inputError("email", touched, errors).error}
              helperText={inputError("email", touched, errors).helperText}
              />
          </Grid>
          <Grid item xs={3}>
            <InputLabel className={classes.labelWeight} htmlFor={"phone"}>Phone</InputLabel>

            <MaskedPhone
              id={"phone"}
              fullWidth
              variant={"outlined"}
              name={"phone"}
              value={values.phone}
              type={"tel"}
              onChange={handleChange}
              error={inputError("phone", touched, errors).error}
              helperText={inputError("phone", touched, errors).helperText}
            />
          </Grid>
          <Grid item xs={3}>
            <InputLabel className={classes.labelWeight} htmlFor={"fax"}>Fax</InputLabel>
            <MaskedPhone
              id={"fax"}
              fullWidth
              variant={"outlined"}
              name={"fax"}
              value={values.fax}
              type={"tel"}
              onChange={handleChange}
              error={inputError("fax", touched, errors).error}
              helperText={inputError("fax", touched, errors).helperText}
              />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          alignContent={"start"}
          item
          xs={6}
          >
          <Grid item xs={6}>
            <InputLabel className={classes.labelWeight} htmlFor={"latitude"}>Latitude</InputLabel>
            <TextField
              fullWidth
              type={"number"}
              id={"latitude"}
              name={"latitude"}
              inputProps={{ step: "any" }}
              variant={"outlined"}
              value={values.latitude}
              onChange={handleChange}
              error={inputError("latitude", touched, errors).error}
              helperText={inputError("latitude", touched, errors).helperText}
          />
          </Grid>
          <Grid item xs={6}>
            <InputLabel className={classes.labelWeight} htmlFor={"longitude"}>Longitude</InputLabel>
            <TextField
              fullWidth
              type={"number"}
              inputProps={{ step: "any" }}
              id={"longitude"}
              name={"longitude"}
              variant={"outlined"}
              value={values.longitude}
              onChange={handleChange}
              error={inputError("longitude", touched, errors).error}
              helperText={inputError("longitude", touched, errors).helperText}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.labelWeight} htmlFor={"timezone"}>Accounting Time Zone</InputLabel>
            <PMSSelect
              id={"end_of_days_timezone"}
              name={"end_of_days_timezone"}
              value={values.end_of_days_timezone}
              changeHandler={handleChange}
              error={inputError("end_of_days_timezone", touched, errors).error}
              helperText={inputError("end_of_days_timezone", touched, errors).helperText}
            >
              <option disabled value={""}>Select a Time Zone...</option>
              {timeZones.map((item) => <option key={item.name} value={item.name}>{item.displayName}</option>)}
            </PMSSelect>
          </Grid>
        </Grid>
      </Grid>
    );
  };

export default FacilityLocationDetailsSection;
