import { Button, FormControl, Grid, Modal, Tab, Tabs, TextField, Typography } from "@mui/material";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import FacilitySelector from "src/components/navigation/FacilitySelector/FacilitySelector";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import ItemManagementLayout from "src/layouts/ItemManagement/ItemManagementLayout";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { Breadcrumb } from "src/models/Breadcrumb";
import { Facility } from "src/models/Facility";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  selectSelectedAdminFacility,
  setSelectedAdminFacility
} from "src/store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";

import { exportPromotions } from "src/store/thunks/promotion/export/exportPromotions";
import { bulkAddPromotions } from "src/store/thunks/promotion/import/bulkAddPromotions";
import { bulkEditPromotions } from "src/store/thunks/promotion/import/bulkEditPromotions";

import useStyles from "./BulkPromotions.styles";
import { useFormik } from "formik";
import {
  selectBatchUploadLoading
} from "../../../store/reducers/unitsSliceNew/unitsSliceNew";
import { showSnackbar } from "../../../store/reducers/snackbarSlice/snackbarSlice";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import {
  selectMissingFieldsChangeOwnershipModalOpened, selectUndoChangeOwnershipModalOpened,
  setMissingFieldsChangeOwnershipModalOpened, setUndoChangeOwnershipModalOpened
} from "../../../store/reducers/occupantSlice/occupantSlice";
import CustomModal from "../../Occupants/EditOccupant/components/CustomModal/CustomModal";
import {
  askingRateChanges,
  selectAskingRateChangesSettingsLoading, selectDocumentCurrentTabView,
  setDocumentCurrentTabView
} from "../../../store/reducers/askingRateChangesSlice/askingRateChangesSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Admin Settings"
  },
  {
    name: "Bulk Promotions",
    bold: true
  }
];

const fileValidationSchema = Yup.object().shape({
  file: Yup
    .mixed()
    .required("A file is required")
    .test(
      "fileType",
      "Uploaded file is not a CSV",
      value => value && value.type === "text/csv"
    )
});

const BulkPromotions: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedAdminFacility = useAppSelector(selectSelectedAdminFacility);
  const batchUploadLoading = useAppSelector(selectBatchUploadLoading);
  const askingRateChangesLoading = useAppSelector(selectAskingRateChangesSettingsLoading);

  const successModalOpened = useAppSelector(selectMissingFieldsChangeOwnershipModalOpened);
  const errorModelOpened = useAppSelector(selectUndoChangeOwnershipModalOpened);
  const documentCurrentTabView = useAppSelector(selectDocumentCurrentTabView);

  const formik = useFormik({
    initialValues: {
      file: null,
      file2: null
    },
    //validationSchema: fileValidationSchema,
    onSubmit: (values) => {
      if (values.file) {
        dispatch(bulkAddPromotions({ file: values.file }))
          .then((resp) => {
            if (resp.type.includes("fulfilled")) {
              dispatch(setMissingFieldsChangeOwnershipModalOpened(true));
              return;
            }

            dispatch(showSnackbar({
              message: resp.payload,
              variant: "error"
            }));
            dispatch(setUndoChangeOwnershipModalOpened(true));
          });
      }
      if (values.file2) {
        dispatch(bulkEditPromotions({ file: values.file2 }))
          .then((resp) => {
            if (resp.type.includes("fulfilled")) {
              dispatch(setMissingFieldsChangeOwnershipModalOpened(true));
              return;
            }

            dispatch(showSnackbar({
              message: resp.payload,
              variant: "error"
            }));
            dispatch(setUndoChangeOwnershipModalOpened(true));
          });
      }
    }
  });

  const { handleSubmit, touched, errors, resetForm } = formik;
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const file = files[0];
      formik.setFieldValue("file", file);
      formik.setFieldTouched("file", true, false);
    }
  };
  const handleFileChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const file = files[0];
      formik.setFieldValue("file2", file);
      formik.setFieldTouched("file2", true, false);
    }
  };

  const { classes } = useStyles({});

  const handleOnFacilityChange = (selectedFacility: Facility) => {
    dispatch(setSelectedAdminFacility(selectedFacility));
  };

  const title = (
    <Grid container>
      <Grid item spacing={2} justifyContent={"space-between"}
        p={1}>
        <Typography variant={"h5"}>
          Bulk Promotions Tools<br/>
        </Typography>
      </Grid>
    </Grid>
  );

  const exportAllFacilities2 = (
    <Grid container>
      <Grid item spacing={2} justifyContent={"space-between"}
        p={2}>
        <Typography variant={"h6"}>
          Export all active promotions<br/>
        </Typography>
      </Grid>
      <Grid spacing={10} item justifyContent={"space-between"}
        p={2}>
        <LoadingButton
          data-testid={"fees-cta-button"}
          className={classes.buttonBase}
          color={"primary"}
          variant={"text"}
          loading={askingRateChangesLoading}
          disabled={askingRateChangesLoading}
          onClick={() => dispatch(exportPromotions(1))}
        >
          Export
        </LoadingButton>
      </Grid>
    </Grid>
  );

  const importForm = () => {
    return (
      <form
        id={"batch-upload-units-form"}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}>
        <Grid item container alignItems={"center"}>
          <Grid item spacing={2} justifyContent={"space-between"}
            p={2}>
            <Typography variant={"h6"}>
              Bulk Add New Promotions
            </Typography>
          </Grid>

          <Grid spacing={2} justifyContent={"space-between"}
            p={2}>
            <FormControl error={Boolean(touched.file && errors.file)} fullWidth>
              <TextField
                error={Boolean(touched.file && errors.file)}
                helperText={touched.file && errors.file ? errors.file : ""}
                id={"file"}
                type={"file"}
                onChange={handleFileChange}
                InputLabelProps={{
                  shrink: true
                }}
                variant={"outlined"}
                fullWidth
                margin={"normal"}
              />
            </FormControl>
          </Grid>
          <Grid item spacing={2} justifyContent={"space-between"}
            p={2}>
            <LoadingButton
              className={classes.buttonBase}
              type={"submit"}
              loading={batchUploadLoading}
              form={"batch-upload-units-form"}
              variant={"contained"}
            >
              Import
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item spacing={2} justifyContent={"space-between"}
            p={2}>
            <h3>Getting Started</h3>
            <p>
              The fastest way to create new promotions is to export the current promotions.
              Select the &quot;Edit&quot; tab above, then click the &quot;Export&quot; button.
              This will generate
              a CSV file with all the headings. Next clear out the existing data and start
              adding your new entries. Make sure you return to the “Create” tab that you
              started from before you import!
            </p>
            <h3>Import Rules for Creating New Promotions:</h3>
            <p>
              <ul>
                <li>Bulk creation of promotions with the Unit Size Rule Restriction is not supported</li>
                <li>
                  Required columns for importing the csv are (all of the columns included
                  when Exporting from the &quot;Edit&quot; tab): Promotion ID, Name, Description, Amount,
                  Type, Apply, Month, Duration,
                  Start Date, End Date, Indefinite,	Location Codes,	Channels,	Product Types,	Occupancy Target,
                  Occupancy Comparison, Occupancy Value1, Occupancy Value2, Total Unit Restriction Comparison,
                  Total Unit Restriction Value1, Total Unit Restriction Value2, Area Comparison,
                  Area Value1, Area Value2
                </li>
                <ul>
                  <li>See below for what fields can be blank when creating a promotion</li>
                </ul>
                <li> Fields and applicable values: </li>
                <ul>
                  <li>Promotion ID: This field is required to be in the file,
                    but it must be blank as the system will generate this automatically upon creating the promotion
                  </li>
                  <li>Name: required to have a value</li>
                  <li>Description: optional to have a value</li>
                  <li>Amount: required to have a value </li>
                  <ul>
                    <li>This must be a numeric value</li>
                  </ul>
                  <li>Type: required to have a value </li>
                  <ul>
                    <li>Value must be one of the following: Fixed Dollar, Percentage, Apply Rent</li>
                  </ul>
                  <li>Month: required to have a value</li>
                  <ul>
                    <li>This must be a numeric value from 1 to 24</li>
                  </ul>
                  <li>Duration: required to have a value</li>
                  <ul>
                    <li>Value must be a number from 1 to 24 or -1. Use -1 to indicate a Non-Expiring promotion</li>
                  </ul>
                  <li>Start Date: required to have a value</li>
                  <ul><li>Value must be in the
                    format of mm/dd/yyyy and must be today or some day in the future</li></ul>
                  <li>End Date: optional to have a value</li>
                  <ul><li>Value must be in the format of mm/dd/yyyy and must
                    be a date after the Start Date
                  </li></ul>
                  <li>Indefinite: required to have a value</li>
                  <ul>
                    <li>Value must be numeric and 0 or 1. 0=No, 1=Yes</li>
                  </ul>
                  <li>Location Codes: required to have a value</li>
                  <ul>
                    <li>Value should be a semicolon delimited list. </li>
                    <li> Example: 1001;1002;1003 </li>
                  </ul>
                  <li>Channels: optional to have a value</li>
                  <ul>
                    <li>
                      Value should be a semicolon delimited list.
                      Valid values are: WalK-in, Kiosk, Call-center, Website, All
                    </li>
                  </ul>
                  <li>Product Types: optional to have a value</li>
                  <ul>
                    <li>Value should be a semicolon delimited list.</li>
                    <li>Example: Self Storage - CC; Self Storage - DU                    </li>
                  </ul>
                  <li>Occupancy Target: optional to have a value</li>
                  <ul>
                    <li>Valid values are: unit or facility                    </li>
                  </ul>
                  <li>Occupancy Comparison: optional to have a value</li>
                  <ul><li>Valid values are: &lt;, &gt; or between </li>
                    <ul>
                      <li>Occupancy Value1: optional - numeric value                      </li>
                      <ul>
                        <li>This field is required if you have a value in Occupancy Target/Occupancy Comparison</li>
                      </ul>
                      <li>Occupancy Value2: optional - numeric value                      </li>
                      <ul>
                        <li>This field is required if you have a value in Occupancy Target/Occupancy Comparison
                          and used a value of &quot;between&quot;</li>
                      </ul>
                    </ul>
                  </ul>
                  <li>Total Unit Restriction Comparison: optional to have a value</li>
                  <ul><li>Valid values are: &lt;, &gt; or between </li>
                    <ul>
                      <li>Total Unit Restriction Value1: optional - numeric value                      </li>
                      <ul>
                        <li>This field is required if you have a value in Total Unit Restriction Comparison</li>
                      </ul>
                      <li>Total Unit Restriction Value2: optional - numeric value                      </li>
                      <ul>
                        <li>This field is required if you have a value in Total Unit Restriction Comparison
                          and used a value of &quot;between&quot;</li>
                      </ul>
                    </ul>
                  </ul>
                  <li>Area Comparison: optional to have a value</li>
                  <ul><li>Valid values are: &lt;, &gt; or between </li>
                    <ul>
                      <li>Area Value1: optional - numeric value                      </li>
                      <ul>
                        <li>This field is required if you have a value in Area Comparison</li>
                      </ul>
                      <li>Area Value2: optional - numeric value                      </li>
                      <ul>
                        <li>This field is required if you have a value in Area Comparison and used a value of
                          &quot;between&quot;</li>
                      </ul>
                    </ul>
                  </ul>
                </ul>
              </ul>
              Notes:
              <ul>
                <li>The promotions will be added immediately.  No waiting!</li>
              </ul>
            </p>
          </Grid>
        </Grid>
      </form>
    );
  };

  const importForm2 = () => {
    return (
      <form
        id={"batch-upload-units-form"}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}>
        <Grid item container alignItems={"center"}>
          <Grid item spacing={2} justifyContent={"space-between"}
            p={2}>
            <Typography variant={"h6"}>
              Bulk Edit Existing Promotions
            </Typography>
          </Grid>

          <Grid spacing={2} justifyContent={"space-between"}
            p={2}>
            <FormControl error={Boolean(touched.file && errors.file)} fullWidth>
              <TextField
                error={Boolean(touched.file && errors.file)}
                helperText={touched.file && errors.file ? errors.file : ""}
                id={"file"}
                type={"file"}
                onChange={handleFileChange2}
                InputLabelProps={{
                  shrink: true
                }}
                variant={"outlined"}
                fullWidth
                margin={"normal"}
              />
            </FormControl>
          </Grid>
          <Grid item spacing={2} justifyContent={"space-between"}
            p={2}>
            <LoadingButton
              className={classes.buttonBase}
              type={"submit"}
              loading={batchUploadLoading}
              form={"batch-upload-units-form"}
              variant={"contained"}
            >
              Import
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item spacing={2} justifyContent={"space-between"}
            p={2}>
            <h3>Getting Started</h3>
            <p>
              The fastest way to create new promotions is to export the current promotions. Select the
              &quot;Edit&quot; tab above, then click the &quot;Export&quot; button. This will generate a CSV file with
              all the headings. Next clear out the existing data and start adding your new entries.
              Make sure you return to the “Create” tab that you started from before you import!
            </p>

            <h3>Import Rules for Editing Existing Promotions:</h3>
            <p>
              <ul>
                <li>Promotions that have Unit Size rule restrictions are not exported as this
                  rule restriction is not supported with batch actions</li>
                <li>
                  Required columns for importing the csv are (all of the columns
                  included when Exporting from the &quot;Edit&quot; tab): Promotion ID, Name, Description,
                  Amount, Type, Apply, Month, Duration, Start Date, End Date,
                  Indefinite, Location Codes, Channels, Product Types, Occupancy Target,
                  Occupancy Comparison, Occupancy Value1, Occupancy Value2, Total Unit Restriction Comparison,
                  Total Unit Restriction Value1, Total Unit Restriction Value2,
                  Area Comparison, Area Value1, Area Value2
                </li>
                <ul>
                  <li>See below for what fields can be blank when creating a promotion</li>
                </ul>
                <li>Fields and applicable values:                </li>
                <ul>
                  <li>Promotion ID: This value cannot be edited once created; if edited, the edit will be skipped</li>
                  <li>Name: This value cannot be edited once created; if edited, the edit will be skipped</li>
                  <li>Description: optional to have a value; this value can be edited</li>
                  <li>Amount: This value cannot be edited once created; if edited, the edit will be skipped</li>
                  <li>Type: This value cannot be edited once created; if edited, the edit will be skipped</li>
                  <li>Month: This value cannot be edited once created; if edited, the edit will be skipped</li>
                  <li>Duration: This value cannot be edited once created; if edited, the edit will be skipped</li>
                  <li>Start Date: This value cannot be edited once created; if edited, the edit will be skipped</li>
                  <li>End Date: This value cannot be edited once created; if edited, the edit will be skipped</li>
                  <li>Indefinite: This value cannot be edited once created; if edited, the edit will be skipped</li>

                  <li>Location Codes: required to have a value; can be edited once created</li>
                  <ul>
                    <li>Value should be a semicolon delimited list. </li>
                    <li> Example: 1001;1002;1003 </li>
                  </ul>
                  <li>Channels: optional to have a value; can be edited once created</li>
                  <ul>
                    <li>
                      Value should be a semicolon delimited list.
                      Valid values are: WalK-in, Kiosk, Call-center, Website, All
                    </li>
                  </ul>
                  <li>Product Types: optional to have a value; can be edited once created</li>
                  <ul>
                    <li>Value should be a semicolon delimited list.</li>
                    <li>Example: Self Storage - CC; Self Storage - DU                    </li>
                  </ul>
                  <li>Occupancy Target: optional to have a value; can be edited once created</li>
                  <ul>
                    <li>Valid values are: unit or facility                    </li>
                  </ul>
                  <li>Occupancy Comparison: optional to have a value; can be edited once created</li>
                  <ul><li>Valid values are: &lt;, &gt; or between </li>
                    <ul>
                      <li>Occupancy Value1: optional - numeric value; can be edited once created</li>
                      <ul>
                        <li>This field is required if you have a value in Occupancy Target/Occupancy Comparison</li>
                      </ul>
                      <li>Occupancy Value2: optional - numeric value; can be edited once created</li>
                      <ul>
                        <li>This field is required if you have a value in Occupancy Target/Occupancy Comparison
                          and used a value of &quot;between&quot;</li>
                      </ul>
                    </ul>
                  </ul>
                  <li>Total Unit Restriction Comparison: optional to have a value; can be edited once created</li>
                  <ul><li>Valid values are: &lt;, &gt; or between </li>
                    <ul>
                      <li>Total Unit Restriction Value1: optional - numeric value; can be edited once created</li>
                      <ul>
                        <li>This field is required if you have a value in Total Unit Restriction Comparison</li>
                      </ul>
                      <li>Total Unit Restriction Value2: optional - numeric value; can be edited once created</li>
                      <ul>
                        <li>This field is required if you have a value in Total Unit Restriction Comparison
                          and used a value of &quot;between&quot;</li>
                      </ul>
                    </ul>
                  </ul>
                  <li>Area Comparison: optional to have a value; can be edited once created</li>
                  <ul><li>Valid values are: &lt;, &gt; or between </li>
                    <ul>
                      <li>Area Value1: optional - numeric value; can be edited once created</li>
                      <ul>
                        <li>This field is required if you have a value in Area Comparison</li>
                      </ul>
                      <li>Area Value2: optional - numeric value; can be edited once created</li>
                      <ul>
                        <li>This field is required if you have a value in Area Comparison and used a value of
                          &quot;between&quot;</li>
                      </ul>
                    </ul>
                  </ul>
                </ul>
              </ul>
              Notes
              <ul>
                <li>The edits will take effect immediately. No waiting!</li>
              </ul>
            </p>
          </Grid>
        </Grid>
      </form>
    );
  };

  const pageHeader = <PageHeader title={"Bulk Promotions"} breadcrumbs={breadcrumbs} />;

  const handleTabChange = (e: React.SyntheticEvent, newValue: number) => {
    dispatch(setDocumentCurrentTabView(newValue));
  };
  return (
    <ViewWrapper pageHeader={pageHeader}>
      <Tabs value={documentCurrentTabView} onChange={handleTabChange} aria-label={"basic tabs example"}>
        <Tab label={"Create"} value={0} />
        <Tab label={"Edit"} value={1} />
      </Tabs>

      <ItemManagementLayout title={title}>
        {documentCurrentTabView === 0 &&
          <Grid>
            {importForm()}
          </Grid>}
        {documentCurrentTabView === 1 &&
          <Grid>
            {exportAllFacilities2}
            <hr/>
            {importForm2()}
          </Grid>}
      </ItemManagementLayout>

      <CustomModal
        title={"Import Bulk Promotions"}
        open={successModalOpened}
        confirmButtonLabel={"Close"}
        hasCancel={false}
        handleSubmit={() => {
          dispatch(setMissingFieldsChangeOwnershipModalOpened(false));
          window.location.reload();
        }}
        handleClose={() => {
          dispatch(setMissingFieldsChangeOwnershipModalOpened(false));
          window.location.reload();
        }}
      >
        <Typography>
          The import was Successful.
        </Typography>
      </CustomModal>

      <CustomModal
        title={"Failure!"}
        open={errorModelOpened}
        confirmButtonLabel={"Close"}
        hasCancel={false}
        handleSubmit={() => {
          dispatch(setUndoChangeOwnershipModalOpened(false));
          window.location.reload();
        }}
        handleClose={() => {
          dispatch(setUndoChangeOwnershipModalOpened(false));
          window.location.reload();
        }}
      >
        <Typography>
          The import failed.  Review the error messages, update the file and try again.
        </Typography>
      </CustomModal>
    </ViewWrapper>
  );
};

export default BulkPromotions;
