import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getRevenueClass = createAsyncThunk(
  "getRevenueClass",
  (revenueClassId: string, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const facilityId = state.selectedAdminFacility.selectedAdminFacility!.id;

    if (!facilityId) {
      const errorMessage = "Facility ID not found";

      dispatch(showSnackbar({
        message: errorMessage,
        variant: "error"
      }));

      rejectWithValue(errorMessage);
      return;
    }

    if (!revenueClassId) {
      const errorMessage = "Revenue Class ID not found";

      dispatch(showSnackbar({
        message: errorMessage,
        variant: "error"
      }));

      rejectWithValue(errorMessage);
      return;
    }

    return axios.get(`/api/v1/facilities/${facilityId}/revenue-classes/${revenueClassId}`)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = `There was an error getting the information for Revenue Class ${revenueClassId}`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
