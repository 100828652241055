import { FormikErrors, FormikTouched } from "formik";
import React, { ReactNode } from "react";
import { FormHelperText } from "@mui/material";

interface InputErrorReturnType {
    error: boolean,
    helperText: string
    display: ReactNode | null
}

export const inputError = (
  inputName: string,
  touched: FormikTouched<any>,
  errors: FormikErrors<any>,
  noTouch = false
):
InputErrorReturnType => {
  let error;
  let helperText;
  error = touched && !!touched[inputName] && errors && !!errors[inputName];
  helperText = (touched && !!touched[inputName] && errors && errors[inputName])
    ? errors[inputName] as string
    : "";

  if (noTouch) {
    error = errors && !!errors[inputName];
    helperText = (errors && errors[inputName])
      ? errors[inputName] as string
      : "";
  }
  return {
    error: error,
    helperText: helperText,
    display: error ? <FormHelperText style={{ marginLeft: "14px" }} error>{helperText}</FormHelperText> : null
  };
};
