import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../rootStore";
import { TransferUnitCost } from "src/models/TransferUnitCost";
import { TransferUnitCostSliceState } from "./TransferUnitCostSliceState";
import { getTransferUnitCost } from "src/store/thunks/transfer/transferCost/getTransferUnitCost";

export const initialState: TransferUnitCostSliceState = {
  rent: "0.00",
  promo_adjustments: "0.00",
  subtotal: "0.00",
  tax: "0.00",
  total_due_today: "0.00",
  next_payment: "0.00",
  next_payment_credit: "0.00",
  next_payment_date: "",
  fees: [],
  tpp_plan: null,
  security_deposit: "0.00",
  merchandise: [],
  additional_periods: "0.00",
  transferUnitCostLoading: false,
  transferUnitCostError: "",
  next_payment_tax_amount: "0.00",
  next_payment_promo_adjustments: "0.00",
  next_payment_tpp_plan: null,
  next_payment_fees: [],
  next_payment_subtotal: "0.00",
  remaining_credit: "0.00",
  showApplyToOtherUnitdDropdown: false,
  refundType: "",
  applyToUnit: "",
  moveOutCredits: [],
  newUnitTaxDetails: null,
  nextPaymentTaxDetails: null
};

export const transferUnitCostSlice = createSlice({
  name: "transferUnitCost",
  initialState,
  reducers: {
    setTransferRefundType: (state, action: PayloadAction<string>) => {
      state.refundType = action.payload;
    },
    setTransferShowApplyToOtherUnitDropdown: (state, action: PayloadAction<boolean>) => {
      state.showApplyToOtherUnitdDropdown = action.payload;
    },
    setTransferApplyToUnit: (state, action: PayloadAction<string>) => {
      state.applyToUnit = action.payload;
    },
    resetTransferUnitCostSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransferUnitCost.pending, (state) => {
        state.transferUnitCostLoading = true;
      })
      .addCase(getTransferUnitCost.fulfilled, (state, action: PayloadAction<TransferUnitCost>) => {
        state.transferUnitCostLoading = false;
        state.rent = parseFloat(action.payload.new_unit_rent).toFixed(2);
        state.promo_adjustments = parseFloat(action.payload.promo_adjustments).toFixed(2);
        state.subtotal = parseFloat(action.payload.subtotal).toFixed(2);
        state.tax = parseFloat(action.payload.total_tax).toFixed(2);
        state.total_due_today = parseFloat(action.payload.total_due_today).toFixed(2);
        state.next_payment = parseFloat(action.payload.next_payment).toFixed(2);
        state.next_payment_date = action.payload.new_unit_next_payment_date;
        state.next_payment_tax_amount = parseFloat(action.payload.next_payment_tax_amount).toFixed(2);
        state.next_payment_promo_adjustments = parseFloat(action.payload.next_payment_promo_adjustments).toFixed(2);
        state.next_payment_tpp_plan = action.payload.next_payment_tpp_plan;
        state.next_payment_fees = action.payload.next_payment_fees;
        state.fees = action.payload.new_unit_fees;
        state.tpp_plan = action.payload.tpp_plan;
        state.merchandise = action.payload.merchandise;
        state.next_payment_subtotal = action.payload.next_payment_subtotal;
        state.additional_periods = parseFloat(action.payload.additional_periods).toFixed(2);
        state.previousUnitBalance = parseFloat(action.payload.balance_carried_forward).toFixed(2);
        state.moveOutCredits = action.payload.move_out_credits;
        state.newUnitTaxDetails = action.payload.new_unit_tax_details;
        state.nextPaymentTaxDetails = action.payload.next_payment_tax_details;
      });
  }
});

export const selectRent = (state: RootState) => state.transferUnitCost.rent;
export const selectPromoAdjustments = (state: RootState) => state.transferUnitCost.promo_adjustments;
export const selectSubtotal = (state: RootState) => state.transferUnitCost.subtotal;
export const selectTax = (state: RootState) => state.transferUnitCost.tax;
export const selectTransferUnitTotalDueToday = (state: RootState) => state.transferUnitCost.total_due_today;
export const selectNextPayment = (state: RootState) => state.transferUnitCost.next_payment;
export const selectNextPaymentDate = (state: RootState) => state.transferUnitCost.next_payment_date;
export const selectFees = (state: RootState) => state.transferUnitCost.fees;
export const selectTransferUnitCostMerchandise = (state: RootState) => state.transferUnitCost.merchandise;
export const selectInsurancePlan = (state: RootState) => state.transferUnitCost.tpp_plan;
export const selectNextPaymentInsurancePlan = (state: RootState) => state.transferUnitCost.next_payment_tpp_plan;
export const selectMerchandise = (state: RootState) => state.transferUnitCost.merchandise;
export const selectPreviousUnitBalance = (state: RootState) => state.transferUnitCost.previousUnitBalance;
export const selectAdditionalPeriods = (state: RootState) => state.transferUnitCost.additional_periods;
export const selectTransferUnitCostLoading = (state: RootState) => state.transferUnitCost.transferUnitCostLoading;
export const selectTransferUnitCostError = (state: RootState) => state.transferUnitCost.transferUnitCostError;
export const selectTransferUnitNextPaymentTaxAmount = (state: RootState) =>
  state.transferUnitCost.next_payment_tax_amount;
export const selectNextPaymentPromoAdjustments = (state: RootState) =>
  state.transferUnitCost.next_payment_promo_adjustments;
export const selectNextPaymentCredit = (state: RootState) => state.transferUnitCost.next_payment_credit;
export const selectTransferUnitNexttPaymentFees = (state: RootState) => state.transferUnitCost.next_payment_fees;
export const selectRemainingCredit = (state: RootState) => state.transferUnitCost.remaining_credit;
export const selectSecurityDeposit = (state: RootState) => state.transferUnitCost.security_deposit;
export const selectTransferUnitNextPaymentSubtotalAmount = (state: RootState) =>
  state.transferUnitCost.next_payment_subtotal;
export const selectTransferMoveOutCostCredits = (state: RootState) => state.transferUnitCost.moveOutCredits;
export const selectTransferRefundType = (state: RootState) => state.transferUnitCost.refundType;
export const selectTransferNewUnitTaxDetails = (state: RootState) => state.transferUnitCost.newUnitTaxDetails;
export const selectTransferNextPaymentTaxDetails = (state: RootState) => state.transferUnitCost.nextPaymentTaxDetails;

export const {
  setTransferRefundType,
  setTransferShowApplyToOtherUnitDropdown,
  setTransferApplyToUnit,
  resetTransferUnitCostSlice
} = transferUnitCostSlice.actions;

export default transferUnitCostSlice.reducer;
