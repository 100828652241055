/* eslint-disable max-len */
import { Button, Divider, Grid, InputAdornment, Paper, SelectChangeEvent, TextField, Typography } from "@mui/material";
import React, { PropsWithChildren, ReactElement, useEffect, useState } from "react";
import {
  selectMerchandiseForRefund,
  selectMerchandiseForRefundLoading,
  selectReturnMerchandiseValidation,
  setRefundMethodSelected,
  setTransactionIdForMerchandiseRefund
} from "src/store/reducers/merchandiseForRefundSlice/merchandiseForRefundSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { useNavigate, useParams } from "react-router";
import { Breadcrumb } from "src/models/Breadcrumb";
import MerchandiseTransactionInfo from "./MerchandiseTransactionInfo/MerchandiseTransactionInfo";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import RefundMerchandiseSummary from "./RefundMerchandiseSummary/RefundMerchandiseSummary";
import RefundMerchandiseTable from "./RefundMerchandiseTable/RefundMerchandiseTable";
import TableWrapper from "src/layouts/TableWrapper/TableWrapper";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { getMerchandiseCostForRefund } from "src/store/thunks/merchandise/getMerchandiseCostForRefund/getMerchandiseCostForRefund";
import { getMerchandiseForRefund } from "src/store/thunks/transactions/getMerchandiseForRefund/getMerchandiseForRefund";
import { processRefundMerchandise } from "src/store/thunks/merchandise/processRefundMerchandise/processRefundMerchandise";
import { selectRefundMerchId } from "src/store/reducers/pointOfSalesSlice/pointOfSalesSlice";
import useStyles from "./RefundMerchandise.styles";
import { Search } from "@mui/icons-material";
import { SyntheticInputEvent } from "react-number-format/types/types";
import { debounce } from "lodash";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Merchandise"
  },
  {
    name: "Refund",
    bold: true
  }
];

const RefundMerchandise: React.FC<PropsWithChildren> = ({ children, ...props }): ReactElement => {
  const pageHeader = <PageHeader title={"Refund Merchandise"} breadcrumbs={breadcrumbs} />;
  const { classes } = useStyles();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const refundId = useAppSelector(selectRefundMerchId);
  const refundMerchList = useAppSelector(selectMerchandiseForRefund);
  const readyToRefundMerchandiseValidation = useAppSelector(selectReturnMerchandiseValidation);
  const loading = useAppSelector(selectMerchandiseForRefundLoading);

  const refundMerch = refundMerchList[0];
  const merchandiseForRefund = refundMerchList.filter((merch) => merch.return_quantity > 0);

  const params = useParams<{ id: string }>();
  const id = refundId || params.id;

  const [refundMethodSelection, setRefundMethodSelection] = useState(0);
  const [refundTable, setRefundTable] = useState<any>(null);

  const getData = async() => {
    if (id) {
      await dispatch(getMerchandiseForRefund({ transactionId: +id }));
      await dispatch(setTransactionIdForMerchandiseRefund(+id));
      await setRefundTable(<RefundMerchandiseTable />);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (merchandiseForRefund.length > 0) {
      dispatch(getMerchandiseCostForRefund());
    }
  }, [merchandiseForRefund]);

  const handleChangeRefundMethod = (event: SelectChangeEvent) => {
    const selectedRefundMethod = event.target.value;
    setRefundMethodSelection(+selectedRefundMethod);
    dispatch(setRefundMethodSelected(+selectedRefundMethod));
  };

  const handleCancel = () => {
    navigate("/merchandise/summary-of-sales");
  };

  const handleProcessReturn = () => {
    dispatch(processRefundMerchandise()).then((resp) => {
      if (!resp.type.includes("rejected")) {
        navigate("/merchandise/refund-merchandise/receipt");
      }
    });
  };

  const handleChange = (e: SyntheticInputEvent) =>
    dispatch(getMerchandiseForRefund({ transactionId: +id!, searchValue: e.target.value })).then(() => {
      setRefundTable(<RefundMerchandiseTable />);
    });

  const debounceSearch = debounce((e: SyntheticInputEvent) => handleChange(e), 300);

  const searchBar = (
    <TextField
      fullWidth
      id={"search-refund-table"}
      placeholder={"- Search -"}
      size={"small"}
      InputProps={{
        endAdornment: (
          <InputAdornment position={"end"}>
            <Search />
          </InputAdornment>
        )
      }}
      variant={"outlined"}
      name={"refundTableSearch"}
      onChange={debounceSearch}
    />
  );

  return (
    <ViewWrapper pageHeader={pageHeader} {...props}>
      <Grid container spacing={3}>
        <Grid item container direction={"column"}
          xs={9}>
          <TableWrapper searchBar={searchBar} loading={loading} title={"Review Items to Return"}
            table={refundTable} />
        </Grid>
        <Grid item xs={3}>
          <Grid direction={"column"} container>
            <RefundMerchandiseSummary />

            <MerchandiseTransactionInfo />

            <Paper component={Grid} container variant={"outlined"}
              direction={"column"} py={3} p={2}
              mt={2}>
              <Grid px={1} item xs={12}>
                <Typography gutterBottom variant={"h6"}>
                  Refund Method
                </Typography>
                <Grid px={1} pb={1} item
                  xs={12}>
                  <Divider />
                  <PMSSelect
                    id={"select-refund-method"}
                    name={"select_refund_method"}
                    value={refundMethodSelection}
                    changeHandler={handleChangeRefundMethod}
                  >
                    <option value={0} disabled>
                      - Select Option -
                    </option>
                    <option value={1} disabled={refundMerch?.payment_method === 3}>
                      Cash
                    </option>
                    {refundMerch?.payment_method === 3 && (
                      <option value={3}>Card on File (**** {refundMerch?.credit_card})</option>
                    )}
                  </PMSSelect>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid pt={2} mt={4} container
            justifyContent={"space-around"}>
            <Grid item>
              <Button
                data-testid={"cancel-button"}
                className={classes.cancelButton}
                variant={"text"}
                color={"secondary"}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                type={"submit"}
                data-testid={"cancel-button"}
                className={classes.confirmButton}
                variant={"contained"}
                onClick={handleProcessReturn}
                disabled={!readyToRefundMerchandiseValidation}
              >
                Process Return
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ViewWrapper>
  );
};

export default RefundMerchandise;
