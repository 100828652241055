import React, { useCallback, useEffect, useState } from "react";
import { Search } from "@mui/icons-material";
import { Button, Divider, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import FilterButtonGroup from "src/components/ui/FilterButtonGroup/FilterButtonGroup";
import useStyles from "./OccupantHistory.style";
import clsx from "clsx";
import { debounce } from "lodash";
import { getOccupantHistory } from "src/store/thunks/occupantHistory/getAll/getOccupantHistory";
import { selectSelectedOccupant } from "src/store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import OccupantHistoryTable from "./OccupantHistoryTable";

const OccupantHistory = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [newest, setNewest] = useState(true);
  const selectedOccupant = useAppSelector(selectSelectedOccupant);

  const handleSearch = (text: string) => {
    dispatch(getOccupantHistory({ searchValue: text, occupantId: selectedOccupant?.id!, newest }));
  };

  const debounceSearch = useCallback(
    debounce((text) => handleSearch(text), 600),
    [newest]
  );

  useEffect(() => {
    dispatch(getOccupantHistory({ occupantId: selectedOccupant?.id!, newest, searchValue }));
  }, [newest]);

  return (
    <>
      <Grid component={"form"}>
        <Grid container mb={1} alignItems={"center"}
          justifyContent={"space-between"}>
          <Typography variant={"h5"}>History</Typography>
          <Grid item display={"flex"} columnGap={2}
            alignItems={"center"}>
            <Grid item>
              <FilterButtonGroup heading={"Filter:"}>
                <Button
                  onClick={() => setNewest(true)}
                  className={clsx(newest ? classes.filterButtonActive : classes.filterButtonDisabled)}
                >
                  Newest
                </Button>
                <Button
                  onClick={() => setNewest(false)}
                  className={clsx(newest ? classes.filterButtonDisabled : classes.filterButtonActive)}
                >
                  Oldest
                </Button>
              </FilterButtonGroup>
            </Grid>

            <Grid item>
              <TextField
                id={"search-occupants-table"}
                placeholder={"- Search -"}
                size={"small"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={"end"}>
                      <Search />
                    </InputAdornment>
                  )
                }}
                variant={"outlined"}
                name={"occupantsTableSearch"}
                onChange={({ target: { value } }) => {
                  setSearchValue(value);
                  debounceSearch(value);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <OccupantHistoryTable newest={newest} searchValue={searchValue} />
      </Grid>
    </>
  );
};

export default OccupantHistory;
