import { axiosInstance } from "src/api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "src/utils/format422/format422";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";

interface ReRunAutoPayPayload {
  occupant_id: number;
  ledger_id: number;
}

export const reRunAutoPay = createAsyncThunk(
  "reRunAutoPay",
  async({ occupant_id, ledger_id }: ReRunAutoPayPayload, { dispatch, getState, rejectWithValue }) => {
    return axiosInstance
      .post(`/api/v1/occupants/${occupant_id}/ledgers/${ledger_id}/rerun-autopay`)
      .then((resp) => {
        dispatch(
          showSnackbar({
            message: `Auto Pay has been scheduled successfully`,
            variant: "success"
          })
        );

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(
            showSnackbar({
              message: errorMessage,
              variant: "error"
            })
          );

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an error scheduling the Auto Pay";

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  }
);
