import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  documentName: Yup.string().required("Document name is required"),
  documentType: Yup.number().test({
    name: "type",
    test: function(value) {
      if (!value) {
        return this.createError({ message: "Document type is required" });
      }

      return true;
    }
  }),
  documentCategory: Yup.number().test({
    name: "category",
    test: function(value) {
      if (!value) {
        return this.createError({ message: "Document category is required" });
      }

      return true;
    }
  }),
  attachedFacility: Yup.array().min(1, "Minimum 1 Facility required")
});

export default validationSchema;
