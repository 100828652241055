import { GetMerchandiseCostPayload } from "./getMetchandiseCostPayload";
import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getMerchandiseCost = createAsyncThunk(
  "getMerchandiseCost",
  (
    _,
    { dispatch, getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const facilityId = state.selectedFacility.selectedFacility?.id;
    const lineItems = state.merchandiseItems.merchandiseItems.filter(merchItems =>
      state.pointOfSales.merchLineItemIds.includes(merchItems.id));
    const occupantId = state.selectedOccupant?.selectedOccupant?.id!;
    const payload: GetMerchandiseCostPayload = {
      merchandise: {
        ...lineItems.map(item => ({
          ...item,
          quantity: item.quantity_for_cart!
        }))
      },
      ...(occupantId && { occupant_id: occupantId })
    };

    return axios
      .post(`/api/v1/facilities/${facilityId}/merchandise/cost`, payload)
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        let errorMessage = "There was an error getting the Merchandise Cost";

        if (
          err?.response?.status === 422 &&
          err.response.data.errors &&
          Object.keys(err.response.data.errors).length
        ) {
          errorMessage = format422(err.response.data.errors);
        } else if (err?.response?.data && err.response.data.detail) {
          errorMessage += `: ${err.response.data.detail}`;
        }

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  }
);
