import React from "react";
import { Route } from "react-router";
import GetCreateNewDealStarted from "src/pages/Deals/CreateNewDeal/GetCreateNewDealStarted/GetCreateNewDealStarted";
import ScheduleDeal from "src/pages/Deals/CreateNewDeal/ScheduleDeal/ScheduleDeal";
import SelectOccupantForDeal from "src/pages/Deals/CreateNewDeal/SelectOccupantForDeal/SelectOccupantForDeal";
import SelectPromotionForDeal from "src/pages/Deals/CreateNewDeal/SelectPromotionForDeal/SelectPromotionForDeal";
import SelectUnitForDeal from "src/pages/Deals/CreateNewDeal/SelectUnitForDeal/SelectUnitForDeal";
import CreateNewDeal from "src/pages/Deals/CreateNewDeal/CreateNewDeal";

export const groupName = "deals/create-new-deal";

export const CreateNewDealRoutes = (
  <>
    <Route
      path={groupName}
      element={
        <CreateNewDeal>
          <GetCreateNewDealStarted />
        </CreateNewDeal>
      }
    />
    <Route
      path={`${groupName}/select-occupant`}
      element={
        <CreateNewDeal>
          <SelectOccupantForDeal />
        </CreateNewDeal>
      }
    />
    <Route
      path={`${groupName}/schedule-deal`}
      element={
        <CreateNewDeal>
          <ScheduleDeal />
        </CreateNewDeal>
      }
    />
    <Route
      path={`${groupName}/select-unit`}
      element={
        <CreateNewDeal>
          <SelectUnitForDeal />
        </CreateNewDeal>
      }
    />
    <Route
      path={`${groupName}/select-promotion`}
      element={
        <CreateNewDeal>
          <SelectPromotionForDeal />
        </CreateNewDeal>
      }
    />
  </>
);

export default CreateNewDealRoutes;
