import { Facility } from "../../models/Facility";

export const prepareSelectedFacilitiesForAPI = (facilities: Facility[], facilitiesSelected: (number | "all")[]) => {
  if (facilitiesSelected.includes("all")) {
    facilitiesSelected = facilities.map(facility => facility.id);
  }

  facilitiesSelected = facilitiesSelected.filter(facility => facility !== "all");

  return facilitiesSelected as number[];
};
