import {
  selectTransferUnitLoading,
  selectTransferUnitModalOpen,
  setTransferUnitModalOpen
} from "src/store/reducers/transferUnitSlice/transferUnitSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import React from "react";
import { Typography } from "@mui/material";
import WarningModal from "src/components/modal/WarningModal";

interface ConfirmTransferUnitModalProps {
    handleSubmit: (any: any) => Promise<void>;
}

const ConfirmTransferUnitModal = ({ handleSubmit }: ConfirmTransferUnitModalProps) => {
  const dispatch = useAppDispatch();
  const isTransferUnitModalOpen = useAppSelector(selectTransferUnitModalOpen);
  const loading = useAppSelector(selectTransferUnitLoading);
  return (
    <WarningModal
      open={isTransferUnitModalOpen}
      buttonLabel={"Transfer unit"}
      onButtonClick={handleSubmit}
      onCloseClick={() => dispatch(setTransferUnitModalOpen(false))}
      title={"Transferring Unit"}
      loading={loading}
      >
      <>
        <Typography>You are in the process of transfering to a different unit.</Typography>
        <Typography mt={2}>Are you sure you want to transfer the unit?</Typography>
      </>
    </WarningModal>
  );
};

export default ConfirmTransferUnitModal;
