import { Box, FormGroup, Grid, InputLabel, TextField } from "@mui/material";
import React, { FormEvent, ReactElement, useEffect } from "react";
import {
  selectAutomationState,
  setIsDeliveryByCertifiedEmail,
  setIsDeliveryByEmail,
  setIsDeliveryByPostalMail
} from "src/store/reducers/automationSlice/automationSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { Automation } from "src/models/Automation";
import AutomationFormValidation from "./AutomationFormValidation";
import { DocumentTemplate } from "src/models/DocumentTemplate";
import { Fee } from "src/models/Fee";
import PMSAutocomplete from "src/components/ui/PMSAutocomplete/PMSAutocomplete";
import PMSCheckbox from "src/components/ui/PMSCheckbox/PMSCheckbox";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import { Task } from "src/models/Task";
import { getAllDocumentTemplates } from "src/store/thunks/documentTemplates/getAll/getAllDocumentTemplates";
import { getAllFeesForFacility } from "src/store/thunks/fee/getAllForFacility/getAllFeesForFacility";
import { getTasks } from "src/store/thunks/task/getAll/getTasks";
import { inputError } from "src/utils/showInputError/showInputError";
import { selectDocumentTemplatesForAutomationsAutocomplete } from "src/store/reducers/documentsSlice/documentsSlice";
import { selectFees } from "src/store/reducers/feesSlice/feesSlice";
import { selectTasks } from "src/store/reducers/tasksSlice/tasksSlice";
import { useFormik } from "formik";
import { selectSelectedAdminFacility } from "src/store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";

interface AutomationFormProps {
  submitHandler: (automation: Automation) => void;
  isUpdate?: boolean;
}

const AutomationForm: React.FC<AutomationFormProps> = ({ submitHandler, isUpdate = false }): ReactElement => {
  const dispatch = useAppDispatch();
  const tasks = useAppSelector(selectTasks);
  const fees = useAppSelector(selectFees);
  const selectedAdminFacility = useAppSelector(selectSelectedAdminFacility);
  const automation = useAppSelector(selectAutomationState);
  const documentTemplates = useAppSelector(selectDocumentTemplatesForAutomationsAutocomplete);
  const formik = useFormik<Automation>({
    initialValues: automation,
    validationSchema: AutomationFormValidation,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => submitHandler(values)
  });

  const { touched, values, errors, handleChange, handleSubmit, setFieldValue } = formik;

  useEffect(() => {
    // GET CURRENT TASKS
    dispatch(getTasks({ getNotifications: false }));
    dispatch(getAllDocumentTemplates());
    dispatch(getAllFeesForFacility({ facilityId: selectedAdminFacility?.id!, filter: { "filter[is_active]": "1" } }));
  }, []);

  const handleDays = (fieldName: string, fieldValue: string) => {
    setFieldValue(fieldName, fieldValue.replace(/[^0-9]/g, ""));
  };

  const handleDeliveryMethod = (fieldName: "email" | "postal_mail" | "certified_email" | "all") => {
    const allChecked = values.isDeliveryByEmail && values.isDeliveryByCertifiedEmail && values.isDeliveryByPostalMail;

    switch (fieldName) {
      case "email":
        dispatch(setIsDeliveryByEmail({}));
        setFieldValue("isDeliveryByEmail", !values.isDeliveryByEmail);
        break;
      case "postal_mail":
        dispatch(setIsDeliveryByPostalMail({}));
        setFieldValue("isDeliveryByPostalMail", !values.isDeliveryByPostalMail);
        break;
      case "certified_email":
        dispatch(setIsDeliveryByCertifiedEmail({}));
        setFieldValue("isDeliveryByCertifiedEmail", !values.isDeliveryByCertifiedEmail);
        break;
      case "all":
        dispatch(setIsDeliveryByEmail({ value: !allChecked }));
        dispatch(setIsDeliveryByPostalMail({ value: !allChecked }));
        dispatch(setIsDeliveryByCertifiedEmail({ value: !allChecked }));
        setFieldValue("isDeliveryByEmail", !allChecked);
        setFieldValue("isDeliveryByPostalMail", !allChecked);
        setFieldValue("isDeliveryByCertifiedEmail", !allChecked);
        break;
      default:
    }
  };

  return (
    <>
      <Grid
        id={"create-automation-form"}
        container
        spacing={2}
        mt={1}
        component={"form"}
        onSubmit={(e: FormEvent) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}
      >
        <Grid item xs={12}>
          <InputLabel htmlFor={"automation-name"}>Automation Name</InputLabel>
          <TextField
            id={"automation-name"}
            fullWidth
            variant={"outlined"}
            name={"name"}
            inputProps={{ maxLength: 40 }}
            placeholder={"Automation Name"}
            value={values.name}
            disabled={automation.isOriginal}
            onChange={handleChange}
            error={inputError("name", touched, errors).error}
            helperText={inputError("name", touched, errors).helperText}
          />
        </Grid>
        <Grid item xs={3}>
          <PMSSelect
            id={"automation_category"}
            label={"Automation Category"}
            name={"automation_category"}
            value={values.automation_category}
            disabled={true}
            changeHandler={handleChange}
            error={inputError("automation_category", touched, errors).error}
            helperText={inputError("automation_category", touched, errors).helperText}
          >
            <option value={0}>Reminders</option>
            <option value={1}>Delinquency</option>
          </PMSSelect>
        </Grid>
        <Grid item xs={3}>
          <InputLabel htmlFor={"days"}>Days</InputLabel>
          <TextField
            id={"days"}
            fullWidth
            variant={"outlined"}
            name={"days"}
            inputProps={{ maxLength: 3 }}
            value={values.days}
            placeholder={"Days"}
            onChange={(e) => handleDays("days", e.target.value)}
            error={inputError("days", touched, errors).error}
            helperText={inputError("days", touched, errors).helperText}
          />
        </Grid>
        <Grid item xs={3}>
          <Box>
            <PMSSelect
              id={"is_due_before"}
              label={"Due"}
              name={"is_due_before"}
              value={values.is_due_before}
              changeHandler={handleChange}
              error={inputError("is_due_before", touched, errors).error}
              helperText={inputError("is_due_before", touched, errors).helperText}
            >
              <option value={1}>Before Due</option>
              <option value={0}>After Due</option>
            </PMSSelect>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <PMSSelect
              id={"fee-id"}
              label={"Apply Fee To Automation"}
              name={"feeId"}
              value={values.feeId}
              changeHandler={handleChange}
              error={inputError("feeId", touched, errors).error}
              helperText={inputError("feeId", touched, errors).helperText}
            >
              <option value={undefined}>None</option>
              {fees.map((fee: Fee) => (
                <option key={fee.id} value={fee.id}>
                  {fee.name}
                </option>
              ))}
            </PMSSelect>
          </Box>
        </Grid>

        <Grid item xs={6} display={"flex"}
          flexDirection={"column"}>
          <Grid item xs={12}>
            <PMSAutocomplete
              id={"applicable-document-for-automation"}
              name={"applicableDocumentForAutomation"}
              label={"Applicable Document For Automation"}
              options={documentTemplates}
              value={documentTemplates?.find(
                (template) => template.id === Number(values.applicableDocumentForAutomation)
              )}
              getOptionLabel={(option: DocumentTemplate) => option.name}
              changeHandler={(event, value: DocumentTemplate) =>
                setFieldValue("applicableDocumentForAutomation", value?.id)
              }
              error={inputError("applicableDocumentForAutomation", touched, errors).error}
              helperText={inputError("applicableDocumentForAutomation", touched, errors).helperText}
              renderOption={(props, option: DocumentTemplate) => (
                <Box component={"li"} {...props} key={option.id}>
                  {option.name}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Box>
              <PMSSelect
                id={"task-id"}
                label={"Task Applied To Automation"}
                name={"taskId"}
                value={values.taskId}
                disabled={true}
                changeHandler={handleChange}
                error={inputError("taskId", touched, errors).error}
                helperText={inputError("taskId", touched, errors).helperText}
              >
                <option value={undefined}>None</option>
                {tasks.map((task: Task) => (
                  <option key={task.id} value={task.id}>
                    {task.name}
                  </option>
                ))}
              </PMSSelect>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <InputLabel htmlFor={"description"}>Description</InputLabel>
          <TextField
            id={"description"}
            fullWidth
            multiline
            rows={5}
            inputProps={{ maxLength: 255 }}
            name={"description"}
            placeholder={"Automation Description"}
            value={values.description}
            onChange={handleChange}
            error={inputError("description", touched, errors).error}
            helperText={inputError("description", touched, errors).helperText}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel htmlFor={"Delivery Method"}>Delivery Method</InputLabel>
          <FormGroup>
            <PMSCheckbox
              smallText
              data-testid={"email-method"}
              label={"Email"}
              name={"email-method"}
              isChecked={values.isDeliveryByEmail}
              changeHandler={() => handleDeliveryMethod("email")}
            />
            <PMSCheckbox
              smallText
              data-testid={"certified-method"}
              label={"Certified Email"}
              name={"certified-method"}
              isChecked={values.isDeliveryByCertifiedEmail}
              changeHandler={() => handleDeliveryMethod("certified_email")}
            />
            <PMSCheckbox
              smallText
              data-testid={"postal-method"}
              label={"Postal mail"}
              name={"postal-method"}
              isChecked={values.isDeliveryByPostalMail}
              changeHandler={() => handleDeliveryMethod("postal_mail")}
            />
            <PMSCheckbox
              smallText
              data-testid={"select-all-methods"}
              label={"Select All Methods"}
              name={"select-all-methods"}
              isChecked={values.isDeliveryByEmail && values.isDeliveryByCertifiedEmail && values.isDeliveryByPostalMail}
              changeHandler={() => handleDeliveryMethod("all")}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
};

export default AutomationForm;
