import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Collapsible Section Styles" })((theme) => ({
  cardColor: {
    background: theme.palette.primary.contrastText,
    borderRadius: 0,
    color: "white"
  },
  innerCardBackground: {
    background: "white"
  },
  arrowColor: {
    color: "white"
  }
}));

export default useStyles;
