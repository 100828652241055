import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RevenueClassInformationSliceState } from "./RevenueClassInformationSliceState";
import { RootState } from "../../rootStore";
import { getRevenueClass } from "src/store/thunks/revenueClass/get/getRevenueClass";
import { getRevenueClassTaxRate } from "../../../utils/getRevenueClassTaxRate/getRevenueClassTaxRate";
import { updateRevenueClass } from "src/store/thunks/revenueClass/update/updateRevenueClass";

export const initialState: RevenueClassInformationSliceState = {
  selectedRevenueClassId: +(sessionStorage.getItem("selectedRevenueClassId") as string) ?? null,
  selectedRevenueClass: JSON.parse(sessionStorage.getItem("selectedRevenueClass") as string) ?? null,
  revenueClassName: sessionStorage.getItem("revenueClassName") ?? "",
  chartAccountId: sessionStorage.getItem("chartAccountId") ?? "",
  stateTax: parseFloat(sessionStorage.getItem("stateTax") as string) || 0,
  countyTax: parseFloat(sessionStorage.getItem("countyTax") as string) || 0,
  cityTax: parseFloat(sessionStorage.getItem("cityTax") as string) || 0,
  localTax: parseFloat(sessionStorage.getItem("localTax") as string) || 0,
  totalTax: parseFloat(sessionStorage.getItem("totalTax") as string) || 0,
  revenueClassInformationLoading: false,
  revenueClassInformationError: "",
  updateRevenueClassModalOpen: false
};

export const revenueClassInformationSlice = createSlice({
  name: "revenueClassInformation",
  initialState,
  reducers: {
    toggleUpdateRevenueClassModal: (state, action: PayloadAction<boolean>) => {
      state.updateRevenueClassModalOpen = action.payload;
    },
    setRevenueClassName: (state, action: PayloadAction<string>) => {
      state.revenueClassName = action.payload;
      sessionStorage.setItem("revenueClassName", state.revenueClassName);
    },
    setSelectedRevenueClassId: (state, action) => {
      state.selectedRevenueClassId = action.payload;
      sessionStorage.setItem("selectedRevenueClassId", String(state.selectedRevenueClassId));
    },
    setSelectedRevenueClass: (state, action) => {
      state.selectedRevenueClass = action.payload;
      sessionStorage.setItem("selectedRevenue", JSON.stringify(action.payload));
    },
    setChartAccountId: (state, action: PayloadAction<string>) => {
      state.chartAccountId = action.payload;
      sessionStorage.setItem("chartAccountId", state.chartAccountId);
    },
    setStateTax: (state, action: PayloadAction<number>) => {
      state.stateTax = action.payload;
      sessionStorage.setItem("stateTax", String(state.stateTax));

      state.totalTax =
        getRevenueClassTaxRate(String(state.stateTax),
          String(state.countyTax),
          String(state.cityTax),
          String(state.localTax));
      sessionStorage.setItem("totalTax", String(state.totalTax));
    },
    setCountyTax: (state, action: PayloadAction<number>) => {
      state.countyTax = action.payload;
      sessionStorage.setItem("countyTax", String(state.countyTax));

      state.totalTax =
        getRevenueClassTaxRate(String(state.stateTax),
          String(state.countyTax),
          String(state.cityTax),
          String(state.localTax));
      sessionStorage.setItem("totalTax", String(state.totalTax));
    },
    setCityTax: (state, action: PayloadAction<number>) => {
      state.cityTax = action.payload;
      sessionStorage.setItem("cityTax", String(state.cityTax));

      state.totalTax =
        getRevenueClassTaxRate(String(state.stateTax),
          String(state.countyTax),
          String(state.cityTax),
          String(state.localTax));
      sessionStorage.setItem("totalTax", String(state.totalTax));
    },
    setLocalTax: (state, action: PayloadAction<number>) => {
      state.localTax = action.payload;
      sessionStorage.setItem("localTax", String(state.localTax));

      state.totalTax =
        getRevenueClassTaxRate(String(state.stateTax),
          String(state.countyTax),
          String(state.cityTax),
          String(state.localTax));
      sessionStorage.setItem("totalTax", String(state.totalTax));
    },
    resetRevenueClassInformationSlice: (state) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("revenueClassName");
      sessionStorage.removeItem("chartAccountId");
      sessionStorage.removeItem("stateTax");
      sessionStorage.removeItem("countyTax");
      sessionStorage.removeItem("cityTax");
      sessionStorage.removeItem("localTax");
      sessionStorage.removeItem("totalTax");
      sessionStorage.removeItem("selectedRevenueClass");
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getRevenueClass.pending, (state, action) => {
        state.revenueClassInformationLoading = true;
      })
      .addCase(getRevenueClass.fulfilled, (state, action) => {
        state.revenueClassInformationLoading = false;
        state.chartAccountId = action.payload.chart_account_id;
        state.revenueClassName = action.payload.name;
        state.countyTax = action.payload.tax_county;
        state.stateTax = action.payload.tax_state;
        state.cityTax = action.payload.tax_city;
        state.localTax = action.payload.tax_local;
        state.totalTax = action.payload.tax_total;
      })
      .addCase(getRevenueClass.rejected, (state, action) => {
        state.revenueClassInformationLoading = false;
        state.revenueClassInformationError = action.payload as string;
      })
      .addCase(updateRevenueClass.pending, (state, action) => {
        state.revenueClassInformationLoading = true;
      })
      .addCase(updateRevenueClass.fulfilled, (state, action) => {
        state.revenueClassInformationLoading = false;
        state.chartAccountId = action.payload.chart_account_id;
        state.revenueClassName = action.payload.name;
        state.countyTax = action.payload.tax_county;
        state.stateTax = action.payload.tax_state;
        state.cityTax = action.payload.tax_city;
        state.localTax = action.payload.tax_local;
        state.totalTax = action.payload.tax_total;
      })
      .addCase(updateRevenueClass.rejected, (state, action) => {
        state.revenueClassInformationLoading = false;
        state.revenueClassInformationError = action.payload as string;
      });
  }
});

export const selectSelectedRevenueClassId = (state: RootState) => state.revenueClassInformation.selectedRevenueClassId;
export const selectRevenueClassName = (state: RootState) => state.revenueClassInformation.revenueClassName;
export const selectChartAccountId = (state: RootState) => state.revenueClassInformation.chartAccountId;
export const selectStateTax = (state: RootState) => state.revenueClassInformation.stateTax;
export const selectCountyTax = (state: RootState) => state.revenueClassInformation.countyTax;
export const selectCityTax = (state: RootState) => state.revenueClassInformation.cityTax;
export const selectLocalTax = (state: RootState) => state.revenueClassInformation.localTax;
export const selectTotalTax = (state: RootState) => state.revenueClassInformation.totalTax;
export const selectRevenueClassInformationLoading =
  (state: RootState) => state.revenueClassInformation.revenueClassInformationLoading;
export const selectRevenueClassInformationError =
  (state: RootState) => state.revenueClassInformation.revenueClassInformationError;
export const selectUpdateRevenueClassModalOpen =
  (state: RootState) => state.revenueClassInformation.updateRevenueClassModalOpen;
export const selectSelectedRevenueClass = (state: RootState) => state.revenueClassInformation.selectedRevenueClass;

export const {
  toggleUpdateRevenueClassModal,
  setSelectedRevenueClassId,
  setSelectedRevenueClass,
  setRevenueClassName,
  setChartAccountId,
  setStateTax,
  setCountyTax,
  setCityTax,
  setLocalTax,
  resetRevenueClassInformationSlice
} = revenueClassInformationSlice.actions;

export default revenueClassInformationSlice.reducer;
