import { axiosInstance as axios } from "src/api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getLedgerView = createAsyncThunk(
  "getLedgerView",
  (
    { occupantId, ledgerId }: { occupantId: string | number; ledgerId: string | number },
    { dispatch, rejectWithValue }
  ) => {
    return axios
      .get(`api/v1/occupants/${occupantId}/ledgers/${ledgerId}/ledger-view`)
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = "There was an error getting the ledger view";

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  }
);
