import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Refund Modal" })((theme) => ({
  blueText: {
    fontWeight: 600,
    textTransform: "uppercase",
    color: theme.palette.primary.contrastText
  },
  actionButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: "white"
  },
  labelWeight: {
    fontWeight: 700
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    paddingRight: "5px"
  },
  autocompleteError: {
    "& label.Mui-focused": {
      color: theme.palette.error.main
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.error.main
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.error.main
      },
      "&:hover fieldset": {
        borderColor: theme.palette.error.main
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.error.main
      }
    }
  },
  bodyHeaderDivider: {
    background: theme.palette.primary.contrastText,
    marginLeft: "5px",
    flexGrow: 1
  },
  cancelButton: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    color: theme.palette.error.contrastText,
    marginRight: theme.spacing(2)
  }
}));

export default useStyles;
