/* eslint-disable max-len */
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { SelectChangeEvent, TextField } from "@mui/material";
import { selectMerchandiseForRefund, setUpdateMerchandiseForRefundReason } from "src/store/reducers/merchandiseForRefundSlice/merchandiseForRefundSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import Grid from "@mui/material/Grid";
import { MerchandiseForRefund } from "src/models/MerchandiseForRefund";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";

interface SelectMerchandiseRefundReasonProps {
  merchandiseId: number,
}

const Reasons = [
  "Do not need item",
  "Item was damaged when purchased",
  "Purchased wrong item",
  "Other"
];

const SelectMerchandiseRefundReason: FC<SelectMerchandiseRefundReasonProps> = ({ merchandiseId }) => {
  const [selectedReasonValue, setSelectedReasonValue] = useState(0);
  const [descriptionReason, setDescriptionReason] = useState("");

  const dispatch = useAppDispatch();

  const merchandise = useAppSelector(selectMerchandiseForRefund);
  const item = merchandise.find(item => item.id === merchandiseId);

  const handleSelectedReason = (event: SelectChangeEvent) => {
    const selectedReason = event.target.value;
    const reason = Reasons[+selectedReason - 1];

    setSelectedReasonValue(+selectedReason);

    if (+selectedReason !== 4) {
      dispatch(setUpdateMerchandiseForRefundReason({ id: merchandiseId, reason: reason }));
    }

    if (item?.return_quantity! <= 0) {
      dispatch(setUpdateMerchandiseForRefundReason({ id: merchandiseId, reason: "" }));
    }
  };

  const handleDescriptionReason = (reason: string) => {
    setDescriptionReason(reason);
    dispatch(setUpdateMerchandiseForRefundReason({ id: merchandiseId, reason: reason }));
  };

  const validateRow = (item: MerchandiseForRefund | undefined): boolean => {
    if (!item) {
      return true;
    }

    if (item?.return_quantity > 0 && Boolean(item.reason) === false) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (item?.return_quantity === 0) {
      setSelectedReasonValue(0);
    }
  }, [merchandise]);

  return (
    <Grid container direction={"column"} spacing={1}
      p={1}>
      <Grid item xs={12}>
        <PMSSelect
          id={"select-merchandise-refund-reason"}
          name={"select_merchandise_refund_reason"}
          value={selectedReasonValue}
          changeHandler={handleSelectedReason}
          size={"small"}
          error={validateRow(item)}
      >
          <option value={0}disabled >- Select Reason -</option>
          {
            Reasons.map((reason, index) => {
              return (<option key={index + 1} value={index + 1}>{reason}</option>);
            })
          }
        </PMSSelect>
      </Grid>

      {selectedReasonValue === 4 && (
        <Grid item xs={12}>
          <TextField
            id={"merchandise-description"}
            fullWidth
            multiline
            rows={4}
            inputProps={{ maxLength: 255 }}
            name={"description"}
            placeholder={"Description"}
            error={validateRow(item)}
            value={descriptionReason}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleDescriptionReason(e.target.value)}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default SelectMerchandiseRefundReason;
