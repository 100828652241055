import { AxiosError } from "axios";
import { CreateMoveOutPayload } from "./CreateMoveOutPayload";
import Moment from "moment";
import { PaymentMethod } from "src/enums/PaymentMethod";
import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { setMoveOutCompleted } from "src/store/reducers/moveOutSlice/moveOutSlice";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const createMoveOut = createAsyncThunk(
  "createMoveOut",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState; ;
    const facilityId = state.selectedFacility.selectedFacility?.id;
    const issueCredit = state.moveOutCost?.issue_credit;
    const amountsReceived = [
      state.cash.amountReceived,
      state.moneyOrder.amountReceived
    ];
    const paymentAmount = amountsReceived[state.payment.paymentType - 1] ?? state.moveOutCost.final_balance;

    const payload: CreateMoveOutPayload = {
      move_out_date: Moment(state.moveOut.moveOut?.moveOutDate).format("yyyy-MM-DD"),
      occupant_id: state.moveOut.moveOutLedger?.occupant_id,
      ledger_id: state.moveOut.moveOutLedger?.id,
      refund_type: state.moveOut.moveOut?.refundType ? state.moveOut.moveOut?.refundType : null,
      refund_ledger_id: state.moveOutCost.applyToUnit.length > 0 ? +state.moveOutCost.applyToUnit : null,
      fees: state.moveOut.moveOut?.fees,
      prorate_charges: state.moveOutCost.creditProratedValue,
      waive_fees: state.moveOutCost.waiveFee,
      mark_unrentable: state.moveOutCost.markAsUnrentable,
      unrentable_note: state.moveOutCost.noteDescription,
      take_payment_for_remainder: state.moveOut.moveOut?.takePaymentForRemainder,
      mark_remainder_owed_as_bad_debt: state.moveOut.moveOut?.markRemainderAsBadDebt,
      move_out_via_auction: state.moveOut.moveOut?.moveOutViaAuction,
      buyer_name: state.moveOut.moveOut?.buyerName,
      payment_method: state.payment.paymentType,
      payment_amount: parseFloat(paymentAmount),
      issue_credit: issueCredit,
      unrentable_reason: state.moveOutCost.unrentableReason
    };

    if (payload.payment_method === PaymentMethod.creditCard || payload.payment_method === PaymentMethod.ach) {
      payload.billing = {
        first_name: "",
        last_name: "",
        enable_autobill: false
      };

      if (payload.payment_method === PaymentMethod.creditCard) {
        payload.billing.first_name = state.creditCard.name.split(" ")[0];
        payload.billing.last_name = state.creditCard.name.split(" ")[1];
        payload.billing.card_number = state.creditCard.number;
        payload.billing.exp_month = state.creditCard.expiration.substring(0, 2);
        payload.billing.exp_year = state.creditCard.expiration.substring(2, 4);
        payload.billing.cvv = state.creditCard.cvv;
        payload.billing.credit_card_type = state.creditCard.type;
      } else {
        payload.billing.first_name = state.ach.accountName.split(" ")[0];
        payload.billing.last_name = state.ach.accountName.split(" ")[1];
        payload.billing.account_number = state.ach.accountNumber;
        payload.billing.routing_number = state.ach.routingNumber;
        payload.billing.check_number = state.ach.checkNumber;
      }
    }

    if (payload.payment_method === PaymentMethod.moneyOrder) {
      payload.billing = {
        first_name: state.selectedOccupant.selectedOccupant?.first_name ?? "",
        last_name: state.selectedOccupant.selectedOccupant?.last_name ?? "",
        money_order_number: state.moneyOrder.moneyOrderNumber
      };
    }

    return axios.post(`/api/v1/facilities/${facilityId}/move-out`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Move-Out created successfully",
          variant: "success"
        }));

        dispatch(setMoveOutCompleted(true));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage = "There was an error creating the Move-out";

        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        if (err && err.response && err.response.status && err.response.status === 400) {
          errorMessage += `: ${err.response.data.detail}`;
        }

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
