import { InsurancePlansSliceState } from "./InsurancePlansSliceState";
import { RootState } from "src/store/rootStore";
import { createSlice } from "@reduxjs/toolkit";
import { disableInsurancePlan } from "src/store/thunks/insurancePlans/disable/disableInsurancePlan";
import {
  getAllInsurancePlansForFacility
} from "src/store/thunks/insurancePlans/getAllForFacility/getAllInsurancePlansForFacility";
import { getLedgerInsurancePlan } from "src/store/thunks/insurancePlans/get/getLedgerInsurancePlan";

export const initialState: InsurancePlansSliceState = {
  insurancePlans: [],
  insurancePlansLoading: false,
  insurancePlansError: "",
  showActive: true,
  selectedInsurancePlan: null
};

export const insurancePlansSlice = createSlice({
  name: "insurancePlans",
  initialState,
  reducers: {
    setShowActiveInsurancePlans: (state, action) => {
      state.showActive = action.payload;
    },
    setSelectedInsurancePlan: (state, action) => {
      state.selectedInsurancePlan = action.payload;
    },
    resetInsurancePlansSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllInsurancePlansForFacility.pending, (state) => {
        state.insurancePlansLoading = true;
      })
      .addCase(getAllInsurancePlansForFacility.fulfilled, (state, action) => {
        state.insurancePlansLoading = false;
        state.insurancePlans = action.payload.data;
      })
      .addCase(getLedgerInsurancePlan.pending, (state) => {
        state.insurancePlansLoading = true;
      })
      .addCase(getLedgerInsurancePlan.fulfilled, (state, action) => {
        state.insurancePlansLoading = false;
        state.selectedInsurancePlan = action.payload;
      })
      .addCase(getLedgerInsurancePlan.rejected, (state, action) => {
        state.insurancePlansLoading = false;
        state.selectedInsurancePlan = null;
        state.insurancePlansError = action.payload as string;
      })
      .addCase(getAllInsurancePlansForFacility.rejected, (state, action) => {
        state.insurancePlansLoading = false;
        state.insurancePlansError = action.payload as string;
      })
      .addCase(disableInsurancePlan.pending, (state) => {
        state.insurancePlansLoading = true;
      })
      .addCase(disableInsurancePlan.fulfilled, (state) => {
        state.insurancePlansLoading = false;
      })
      .addCase(disableInsurancePlan.rejected, (state, action) => {
        state.insurancePlansLoading = false;
        state.insurancePlansError = action.payload as string;
      });
  }
});
export const selectInsurancePlans = (state: RootState) => state.insurancePlans.insurancePlans;
export const selectInsurancePlansLoading = (state: RootState) => state.insurancePlans.insurancePlansLoading;
export const selectActiveInsurancePlans = (state: RootState) =>
  state.insurancePlans.insurancePlans.filter((plan) => plan.is_active);
export const selectInactiveInsurancePlans = (state: RootState) =>
  state.insurancePlans.insurancePlans.filter((plan) => !plan.is_active);
export const selectShowActiveInsurancePlans = (state: RootState) => state.insurancePlans.showActive;
export const selectSelectedInsurancePlan = (state: RootState) => state.insurancePlans.selectedInsurancePlan;

export const {
  setShowActiveInsurancePlans,
  resetInsurancePlansSlice,
  setSelectedInsurancePlan
} = insurancePlansSlice.actions;

export default insurancePlansSlice.reducer;
