import { Button, Grid, InputLabel, ListItemText, MenuItem, Paper, TextField } from "@mui/material";
import React, { FormEvent, ReactElement, useEffect, useRef, useState } from "react";
import {
  resetDocumentSlice,
  selectAttachedFacilities,
  selectDocumentCategory,
  selectDocumentContent,
  selectDocumentLoading,
  selectDocumentName,
  selectDocumentType,
  selectDocumentVersion
} from "src/store/reducers/documentSlice/documentSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import DocumentFormValidation from "./DocumentFormValidation";
import { Facility } from "src/models/Facility";
import FroalaEditor from "react-froala-wysiwyg";
import { LoadingButton } from "@mui/lab";
import LoadingSpinner from "src/components/ui/LoadingSpinner/LoadingSpinner";
import PMSCheckbox from "src/components/ui/PMSCheckbox/PMSCheckbox";
import PMSMultiSelect from "src/components/ui/PMSMultiSelect/PMSMultiSelect";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import _ from "lodash";
import { createDocumentTemplate } from "src/store/thunks/documentTemplates/create/createDocumentTemplate";
import froalaConfig from "./froalaConfig";
import getDocumentChanges from "./helpers/getDocumentChanges";
import { inputError } from "src/utils/showInputError/showInputError";
import { selectFacilities } from "src/store/reducers/facilitiesSlice/facilitiesSlice";
import selectedFacilitiesDisplay from "./helpers/selectedFacilitiesDisplay";
import { updateDocumentTemplate } from "src/store/thunks/documentTemplates/update/updateDocumentTemplate";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import useStyles from "./DocumentForm.styles";

// Froala imports
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/languages/de.js";
import "froala-editor/js/third_party/image_tui.min.js";
import "froala-editor/js/third_party/embedly.min.js";
import "froala-editor/js/third_party/spell_checker.min.js";
import "font-awesome/css/font-awesome.css";
import "froala-editor/js/third_party/font_awesome.min.js";
import bootstrapFroalaButtons from "./helpers/bootStrapFroalaButtons";

interface DocumentFormProps {
  editId?: number
};

const DocumentForm: React.FC<DocumentFormProps> = ({ editId }): ReactElement => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const facilities = useAppSelector(selectFacilities);
  const documentName = useAppSelector(selectDocumentName);
  const documentType = useAppSelector(selectDocumentType);
  const documentCategory = useAppSelector(selectDocumentCategory);
  const attachedFacility = useAppSelector(selectAttachedFacilities);
  const documentContent = useAppSelector(selectDocumentContent);
  const documentVersion = useAppSelector(selectDocumentVersion);
  const loading = useAppSelector(selectDocumentLoading);
  const [render, setRender] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const content = useRef("");

  const formik = useFormik({
    initialValues: {
      documentName,
      documentType,
      documentCategory,
      attachedFacility,
      documentContent
    },
    validationSchema: DocumentFormValidation,
    validateOnChange: false,
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: (values) => {
      const form = {
        name: values.documentName,
        type: values.documentType,
        category: values.documentCategory,
        facilities: values.attachedFacility,
        content: _.unescape(content.current)
      };

      const changes = getDocumentChanges(
        form,
        documentName,
        documentType,
        documentCategory,
        documentContent,
        attachedFacility
      );

      if (editId) {
        return dispatch(updateDocumentTemplate({
          id: editId,
          version_changes: changes,
          version: documentVersion,
          ...form
        }))
          .then((resp) => {
            if (!resp.type.includes("rejected")) {
              navigate("/documents/document-library");
            }
          });
      }

      return dispatch(createDocumentTemplate(form))
        .then((resp) => {
          if (!resp.type.includes("rejected")) {
            navigate("/documents/document-library");
          }
        });
    }
  });

  useEffect(() => {
    if (documentContent) {
      content.current = documentContent;
      setRender(!render);
    }
  }, [documentContent]);

  useEffect(() => {
    bootstrapFroalaButtons(dispatch, setShowEditor);

    return () => {
      dispatch(resetDocumentSlice());
    };
  }, []);

  const { setFieldTouched, setFieldValue, touched, errors, values } = formik;

  const updateForm = (fieldName: string, fieldValue?: string | number): void => {
    setFieldTouched(fieldName);
    setFieldValue(fieldName, fieldValue);
  };

  const availableFacilitiesDisplay = facilities.map((facility: Facility) => (
    <MenuItem data-testid={`available-facility-${facility.id}`} key={facility.id} value={facility.id}>
      <PMSCheckbox changeHandler={() => null} isChecked={values.attachedFacility.includes(facility.id)} />
      <ListItemText primary={facility.facility_id} />
    </MenuItem>
  ));

  return (
    <Grid
      mt={3}
      p={3}
      component={Paper}
      elevation={0}
      variant={"outlined"}
      container
      direction={"column"}
    >
      {!loading
        ? <Grid
            id={"document-template-form"}
            data-testid={"document-template-form"}
            container
            spacing={2}
            mt={1}
            component={"form"}
            onSubmit={(e: FormEvent) => {
              e.preventDefault();
              e.stopPropagation();
              formik.handleSubmit();
            }}
        >
          <Grid item xs={3}>
            <InputLabel htmlFor={"document-name"}>Document Name</InputLabel>
            <TextField
              id={"document-name"}
              inputProps={{ "data-testid": "document-name" }}
              fullWidth
              variant={"outlined"}
              name={"documentName"}
              placeholder={"- Document Name -"}
              value={values.documentName}
              onChange={(e) => updateForm("documentName", e.target.value)}
              error={inputError("documentName", touched, errors).error}
              helperText={inputError("documentName", touched, errors).helperText}
            />
          </Grid>
          <Grid item xs={3}>
            <InputLabel htmlFor={"document-type"}>Type</InputLabel>
            <PMSSelect
              data-testid={"document-type"}
              id={"document-type"}
              name={"documentType"}
              value={values.documentType}
              changeHandler={(e) => updateForm("documentType", e.target.value)}
              error={inputError("documentType", touched, errors).error}
              helperText={inputError("documentType", touched, errors).helperText}
            >
              <option value={0} disabled selected>- Choose Type -</option>
              <option value={1}>E-Sign</option>
              <option value={2}>E-mail</option>
              <option value={3}>Printed Mail</option>
            </PMSSelect>
          </Grid>
          <Grid item xs={3}>
            <InputLabel htmlFor={"document-category"}>Category</InputLabel>
            <PMSSelect
              data-testid={"document-category"}
              id={"document-category"}
              name={"documentCategory"}
              value={values.documentCategory}
              changeHandler={(e) => updateForm("documentCategory", e.target.value)}
              error={inputError("documentCategory", touched, errors).error}
              helperText={inputError("documentCategory", touched, errors).helperText}
            >
              <option value={0} disabled selected>- Choose Category -</option>
              <option value={1}>Auctions</option>
              <option value={2}>Printed Mail</option>
              <option value={3}>Collections</option>
              <option value={4}>Commercial</option>
              <option value={5}>Insurance</option>
              <option value={6}>Lease</option>
              <option value={7}>Miscellaneous</option>
              <option value={8}>Occupant</option>
              <option value={9}>Receipts</option>
              <option value={10}>Invoices</option>
              <option value={11}>Uncategorized</option>
            </PMSSelect>
          </Grid>
          <Grid item xs={3}>
            <PMSMultiSelect
              id={"attached-facilities"}
              label={"Attached Facility"}
              value={values.attachedFacility}
              name={"attachedFacilities"}
              changeHandler={(e) => updateForm("attachedFacility", e.target.value)}
              error={inputError("attachedFacility", touched, errors).error}
              helperText={inputError("attachedFacility", touched, errors).helperText}
              renderValue={(facilityIds) => selectedFacilitiesDisplay(facilityIds, facilities)}
            >
              <MenuItem disabled>- Select Facility -</MenuItem>
              {availableFacilitiesDisplay}
            </PMSMultiSelect>
          </Grid>
          <Grid item xs={12} mt={3}>
            <InputLabel htmlFor={"document-detail"}>Document Detail</InputLabel>
            {showEditor && <FroalaEditor
              tag={"textarea"}
              model={content.current}
              onModelChange={(model: any) => {
                content.current = model;
              }}
              config={froalaConfig}
            />}
          </Grid>
          <Grid
            pt={5}
            mt={5}
            spacing={4}
            container
            justifyContent={"flex-end"}
          >
            <Grid item>
              <Button
                data-testid={"cancel-button"}
                className={classes.cancelButton}
                onClick={() => navigate("/documents/document-library")}
                variant={"text"}
                color={"secondary"}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                data-testid={"confirm-button-modal"}
                color={"primary"}
                variant={"contained"}
                className={classes.button}
                type={"submit"}
              >
                SAVE
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
        : <LoadingSpinner />
      }

    </Grid>
  );
};

export default DocumentForm;
