import CreateAutomation from "src/pages/DelinquencyAutomations/CreateAutomation/CreateAutomation";
import DelinquencyAutomations from "src/pages/DelinquencyAutomations/DelinquencyAutomations";
import EditAutomation from "src/pages/DelinquencyAutomations/EditAutomation/EditAutomation";
import React from "react";
import { Route } from "react-router";

export const groupName = "delinquency-automations";

export const DelinquencyAutomationsRoutes = (
  <>
    <Route path={`${groupName}`} element={<DelinquencyAutomations />} />
    <Route path={`${groupName}/create-automation`} element={<CreateAutomation />} />
    <Route path={`${groupName}/:id/edit-automation`} element={<EditAutomation />} />
  </>
);

export default DelinquencyAutomationsRoutes;
