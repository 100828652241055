import { moneyValidator } from "src/utils/commonRegexes";

import { z } from "zod";

const refundFormValidation = z
  .object({
    refundType: z.number().min(1, { message: "Method is Required" }),
    refundDescription: z.string().nonempty({ message: "Reason is required" }),
    refundAmount: z.string({ required_error: "Refund Amount is required" })
  })
  .refine(
    (schema) => {
      return moneyValidator.test(schema.refundAmount);
    },
    { message: "Invalid Format", path: ["refundAmount"] }
  );

export type RefundFormType = z.infer<typeof refundFormValidation>;

export default refundFormValidation;
