
interface DocTemplateChanges {
  name: string
  type: number
  category: number
  content: string
  facilities: number[]
}

const getDocumentChanges = (
  form: DocTemplateChanges,
  documentName: string,
  documentType: number,
  documentCategory: number,
  documentContent: string,
  attachedFacility: number[]
) => {
  let updateChanges = "";

  if (form.name !== documentName) {
    updateChanges += "Document Name Updated;";
  }

  if (form.type !== documentType) {
    updateChanges += "Document Type Updated;";
  }

  if (form.category !== documentCategory) {
    updateChanges += "Document Category Updated;";
  }

  if (JSON.stringify(form.facilities) !== JSON.stringify(attachedFacility)) {
    updateChanges += "Attached Facilities Updated;";
  }

  if (form.content !== documentContent) {
    updateChanges += "Document Content Updated";
  }

  if (
    form.content === documentContent &&
    form.content === documentContent &&
    JSON.stringify(form.facilities) === JSON.stringify(attachedFacility) &&
    form.category === documentCategory &&
    form.type === documentType &&
    form.name === documentName
  ) {
    updateChanges += "Document Content Saved with No Changes";
  }

  return updateChanges;
};
export default getDocumentChanges;
