import { FormControl, FormHelperText, InputLabel, Select, SelectChangeEvent, SelectProps } from "@mui/material";
import React, { ReactElement, ReactNode, forwardRef } from "react";

interface PMSSelectProps {
  id: string;
  children: ReactNode;
  value: unknown;
  changeHandler: (event: SelectChangeEvent<any>, child: ReactNode) => void;
  name?: string;
  native?: boolean;
  label?: string;
  fullWidth?: boolean;
  variant?: SelectProps["variant"];
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  className?: any;
  size?: SelectProps["size"];
  onBlur?: (event: { target: { name: string; value: string } }) => void;
  labelStyles?: React.CSSProperties;
  defaultValue?: string;
}

const PMSSelect: React.FC<PMSSelectProps> = forwardRef(function PMSSelect(
  {
    id,
    value,
    children,
    changeHandler,
    label,
    name,
    native = true,
    fullWidth = true,
    variant = "outlined",
    disabled = false,
    error = false,
    helperText = "",
    className,
    size = "medium",
    onBlur = () => {},
    labelStyles,
    defaultValue = ""
  }: PMSSelectProps,
  ref
) {
  return (
    <>
      {label && <InputLabel htmlFor={id} style={labelStyles}>{label}</InputLabel>}
      <FormControl fullWidth={fullWidth} variant={variant}>
        <Select
          defaultValue={defaultValue}
          data-testid={name}
          className={className}
          id={id}
          value={value}
          name={name}
          native={native}
          fullWidth={fullWidth}
          variant={variant}
          size={size}
          onChange={changeHandler}
          error={error}
          disabled={disabled}
          onBlur={onBlur}
          ref={ref}
        >
          {children}
        </Select>
      </FormControl>
      {error
        ? (
          <FormHelperText style={{ marginLeft: "14px" }} error>
            {helperText}
          </FormHelperText>
          )
        : null}
    </>
  );
});

export default PMSSelect;
