import React from "react";
import { Route } from "react-router";
import CreateInsurancePlan from "../pages/InsurancePlans/CreateInsurancePlan/CreateInsurancePlan";
import EditInsurancePlan from "../pages/InsurancePlans/EditInsurancePlan/EditInsurancePlan";
import InsurancePlans from "../pages/InsurancePlans/InsurancePlans";

export const groupName = "insurance-plans";

export const InsurancePlansRoutes = (
  <>
    <Route path={groupName} element={<InsurancePlans />} />
    <Route path={`${groupName}/:id/edit`} element={<EditInsurancePlan />} />
    <Route path={`${groupName}/create`} element={<CreateInsurancePlan />} />
  </>
);

export default InsurancePlansRoutes;
