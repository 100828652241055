import { APIFilter } from "../../../../models/responses/APIFilter";
import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSearchParams } from "../../../../utils/getSearchParams/getSearchParams";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";
import { today } from "../../../../utils/__dateAndTimeUtils__/today";

export const getAllPromotionsForFacility = createAsyncThunk(
  "getAllPromotionsForFacility",
  (unitId: number | undefined, { dispatch, getState, rejectWithValue }) => {
    const store = getState() as RootState;
    const facilityId = store.selectedFacility.selectedFacility?.id;
    const dealNeededOn = store.dealInformation.neededOn;
    const referralType = store.dealInformation.referralType;

    const params = {
      params: {
        ...getSearchParams(store, true, "name"),
        "filter[is_active]": 1,
        "filter[start]": `lte:${today}`,
        ...(!!unitId && { unit_id: unitId }),
        walk_in_only: "true",
        ...(dealNeededOn && { referral_type: referralType })
      }
    };

    return axios.get(`/api/v1/facilities/${facilityId}/promotions`, params)
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = "There was an error getting the Promotions";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
