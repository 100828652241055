import {
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography
} from "@mui/material";
import React, { FC } from "react";
import {
  selectAutomaticPaymentSlice,
  setAutobillingConfirmationModalOpened,
  setAwaitingSignatureSubmissionModalOpened,
  setCurrentESignDocument,
  setESignDocumentCreationLoading,
  setEmailAutoBillModalOpened
} from "src/store/reducers/automaticPaymentsSlice/automaticPaymentsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";

import { Close } from "@mui/icons-material";
import ESignDocument from "src/models/ESignDocument";
import { LoadingButton } from "@mui/lab";
import { createESignDocumentForAutoPay }
  from "src/store/thunks/occupant/createESignDocumentForAutoPay/createESignDocumentForAutoPay";
import { getAllEsignDocumentsForFacility }
  from "src/store/thunks/esignDocument/getAllForFacility/getAllEsignDocumentsForFacility";
import { grey } from "@mui/material/colors";
import { prepareForSigning } from "src/store/thunks/esignDocument/sign/prepareForSigning";
import { selectFacilityAutoBillTemplateSetting }
  from "src/store/reducers/facilitySettingInformationSlice/facilitySettingInformationSlice";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { selectSelectedLedger } from "src/store/reducers/ledgersSlice/ledgersSlice";
import { sendSms } from "src/store/thunks/esignDocument/sms/sendSms";
import useStyles from "./AutobillingConfirmationModal.styles";

interface AutobillingConfirmationModalProps {}

const SIGN_OPTION = {
  DOWNLOAD: 1,
  EMAIL: 2,
  SMS: 3,
  PRINT: 4
};

const AutobillingConfirmationModal: FC<AutobillingConfirmationModalProps> = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const {
    autobillingConfirmationModalOpened,
    eSignDocumentCreationLoading
  } = useAppSelector(selectAutomaticPaymentSlice);
  const formId = "automatic-payments-form";
  const autoBillTemplate = useAppSelector(selectFacilityAutoBillTemplateSetting);
  const selectedLedger = useAppSelector(selectSelectedLedger);
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const startSigning = async(eSignId: number) => {
    await dispatch(prepareForSigning({ id: eSignId })).then(() => {
      window.open(`/documents/eSign-sign/${eSignId}`, "_blank");
    });
  };

  const handleSignOption = async(eSignDocument: ESignDocument, option: number) => {
    await dispatch(setCurrentESignDocument(eSignDocument));
    if (option === SIGN_OPTION.DOWNLOAD) {
      await startSigning(eSignDocument.id);
      await dispatch(setAwaitingSignatureSubmissionModalOpened(true));
      await dispatch(setAutobillingConfirmationModalOpened(false));
    }

    if (option === SIGN_OPTION.SMS) {
      const smsResponse = await dispatch(sendSms({ id: eSignDocument.id }));
      if (!smsResponse.type.includes("rejected")) {
        await dispatch(setAwaitingSignatureSubmissionModalOpened(true));
        await dispatch(setAutobillingConfirmationModalOpened(false));
      }
    }

    await dispatch(setESignDocumentCreationLoading(false));
  };

  const getAutoBillDocumentByExternalId = async(externalId: string): Promise<ESignDocument | null> => {
    const eSignDocument = await dispatch(getAllEsignDocumentsForFacility({
      facility_id: selectedFacility?.id,
      filter: { "filter[external_id]": externalId }
    }));

    if (eSignDocument.payload.data.length > 0) {
      return eSignDocument.payload.data[0];
    }

    return null;
  };

  const handleSignInPerson = async(option: number) => {
    dispatch(setESignDocumentCreationLoading(true));
    const { payload } = await dispatch(createESignDocumentForAutoPay({
      selectedDocuments: [{
        id: autoBillTemplate?.value
      }],
      ledgerId: selectedLedger?.id
    }));

    if (payload.success) {
      const eSignDocument = await getAutoBillDocumentByExternalId(payload.data[0].document_id);

      if (eSignDocument) {
        await handleSignOption(eSignDocument, option);
      }
    }
  };

  const onSendSmsClick = () => {
    handleSignInPerson(SIGN_OPTION.SMS);
  };

  const onPrintClick = () => {
    handleSignInPerson(SIGN_OPTION.PRINT);
  };

  const handleCloseModal = () => {
    dispatch(setAutobillingConfirmationModalOpened(false));
  };

  const ModalBody = () => {
    return (
      (
        <Grid item pb={1} xs={12}>
          <Paper
            component={Grid}
            container
            variant={"outlined"}
            justifyContent={"center"}
            style={{ minHeight: "20rem", background: grey[400] }}
            >
            <Typography variant={"h5"} alignSelf={"center"}>
              Document Template Preview
            </Typography>
          </Paper>
        </Grid>
      )
    );
  };

  const handleClickedEmail = () => {
    dispatch(setEmailAutoBillModalOpened(true));
    dispatch(setAutobillingConfirmationModalOpened(false));
  };

  return (
    <Modal onClose={handleCloseModal} open={autobillingConfirmationModalOpened} className={classes.modal}>
      <Grid
        p={4}
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        justifyContent={"center"}
        className={classes.container}
      >
        <Grid item xs={11}>
          <Typography variant={"h5"}>Confirmation of Autobilling</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={handleCloseModal}>
            <Close />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid
          container
          item
          gap={0.5}
          my={2}
        >
          <Button
            className={classes.button}
            sx={{ marginLeft: "auto" }}
            variant={"contained"}
            // onClick={() => setSignOption(SIGN_OPTION.DOWNLOAD)}
            >
            Download
          </Button>
          <Button className={classes.button} variant={"contained"} onClick={handleClickedEmail}>
            Email
          </Button>
          <LoadingButton
            className={classes.button}
            variant={"contained"}
            onClick={() => onSendSmsClick()}
          >
            SMS
          </LoadingButton>
          <Button
            className={classes.button}
            variant={"contained"}
          //  onClick={() => setSignOption(SIGN_OPTION.PRINT)}
          >
            Print
          </Button>
        </Grid>

        <ModalBody/>

        <Grid item container xs={12}
          mt={2} justifyContent={"flex-end"}>
          <LoadingButton
            variant={"contained"}
            type={"submit"}
            disabled={eSignDocumentCreationLoading}
            onClick={() => handleSignInPerson(SIGN_OPTION.DOWNLOAD)}
            form={formId}
            className={classes.button}
            loading={eSignDocumentCreationLoading}
          >
            Sign in-person
          </LoadingButton>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AutobillingConfirmationModal;
