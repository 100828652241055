import { Button, Checkbox, Divider, Grid, IconButton, Modal, Paper, Typography, useMediaQuery } from "@mui/material";
import FlagForAuctionValidation, { FlagForAuctionValidationType }
  from "./FlagForAuctionValidation";
import React, { FC } from "react";
import {
  selectCollectionsArray,
  selectCreateAuctionsModalOpen,
  setAuction,
  setConfirmCreateAuctionsModalOpen,
  setCreateAuctionsModalOpen
} from "src/store/reducers/collectionsSlice/collectionsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Close } from "@mui/icons-material";
import Form from "../../../../components/form/Form";
import clsx from "clsx";
import moment from "moment";
import useStyles from "./CreateAuctionsModal.styles";

type CreateAuctionsModalProps = {
  // Define your prop types here
};

const tenAMToday = moment().set({ hour: 10, minute: 0, second: 0 });
const initialValues = { auctionDate: moment(), auctionTime: tenAMToday };

const CreateAuctionsModal: FC<CreateAuctionsModalProps> = (props) => {
  const { classes } = useStyles();
  const modalOpen = useAppSelector(selectCreateAuctionsModalOpen);
  const dispatch = useAppDispatch();
  const xs = useMediaQuery("(min-width: 1300px)");
  const today = moment();
  const collections = useAppSelector(selectCollectionsArray);

  const handleClose = () => {
    dispatch(setCreateAuctionsModalOpen(false));
  };

  const handleSubmit = async(values: FlagForAuctionValidationType) => {
    dispatch(
      setAuction({
        auctionDate: values.auctionDate.format("MM/DD/YYYY"),
        auctionTime: values.auctionTime.format("HH:mmZ")
      })
    );
    handleClose();
    dispatch(setConfirmCreateAuctionsModalOpen(true));
  };

  return (
    <Form
      formId={"createAuctions"}
      onSubmit={handleSubmit}
      defaultValues={initialValues}
      schema={FlagForAuctionValidation}
      resetOnSubmit
    >
      <Modal
        className={clsx(classes.root, { [classes.xs]: xs, [classes.lg]: !xs })}
        open={modalOpen}
        onClose={() => handleClose()}
      >
        <Grid className={classes.inner} spacing={2} component={Paper}
          container padding={2}>
          <Grid padding={2} container alignItems={"center"}>
            <Grid xs={11}>
              <Typography variant={"h4"}>Flag Units for Auction</Typography>
            </Grid>
            <Grid xs={1}>
              <IconButton onClick={() => handleClose()}>
                <Close fontSize={"large"} />
              </IconButton>
            </Grid>
          </Grid>

          <Grid paddingX={2} xs={12}>
            <Divider />
          </Grid>
          <Grid xs={12} padding={2}>
            <Typography variant={"body1"}>Selected delinquent units for auction:</Typography>
            <Grid container item alignItems={"center"}
              padding={2}>
              <Checkbox checked />
              <Typography variant={"h6"} fontWeight={"bold"}>
                {collections.map((collection) => collection.unit_name).join(", ")}
              </Typography>
            </Grid>

            <Grid container item mt={2}>
              <Grid xs={6} padding={1}>
                <Form.DatePicker label={"Auction Date"} name={"auctionDate"} minDate={today} />
              </Grid>
              <Grid xs={6} padding={1}>
                <Form.TimePicker disabled={true} label={"Auction Time"} name={"auctionTime"} />
              </Grid>
            </Grid>

            <Grid container item xs={12}
              justifyContent={"end"} gap={2} mt={4}
              mb={2}>
              <Grid item>
                <Button
                  data-testid={"cancel-button"}
                  variant={"text"}
                  color={"secondary"}
                  className={classes.cancelButton}
                  onClick={() => handleClose()}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Form.Submit formId={"createAuctions"} variant={"contained"} label={"SAVE"} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </Form>
  );
};

export default CreateAuctionsModal;
