import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Confirm Automatic Payments Modal" })((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  container: {
    width: "30rem",
    borderRadius: "1rem"
  },
  button: {
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText
  }
}));

export default useStyles;
