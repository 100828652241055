import React, { ReactElement, useEffect } from "react";
import {
  resetTaskCategoriesSlice,
  selectTaskCategories, selectTaskCategoriesLoading
} from "../../../store/reducers/taskCategoriesSlice/taskCategoriesSlice";
import {
  selectTaskCategory,
  selectTaskDescription,
  selectTaskDueDate,
  selectTaskDueTime,
  selectTaskFacilities,
  selectTaskFormLoading,
  selectTaskIsAllDay,
  selectTaskIsCompleted,
  selectTaskName,
  selectTaskRecurrence
} from "../../../store/reducers/taskFormSlice/taskFormSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Breadcrumb } from "../../../models/Breadcrumb";
import ItemManagementFooterButtons
  from "../../../components/itemManagement/ItemManagementFooterButtons/ItemManagementFooterButtons";
import ItemManagementLayout from "../../../layouts/ItemManagement/ItemManagementLayout";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import TaskForm from "../TaskForm/TaskForm";
import { TaskValues } from "../../../models/formikInputValues/TaskValues";
import ViewWrapper from "../../../layouts/ViewWrapper/ViewWrapper";
import { createTask } from "../../../store/thunks/task/create/createTask";
import { getAllTaskCategories } from "../../../store/thunks/taskCategory/getAll/getAllTaskCategories";
import taskFormValidation from "../TaskForm/taskFormValidation";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Task Management"
  },
  {
    name: "Add Task",
    bold: true
  }
];

const CreateNewTask: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const taskCategories = useAppSelector(selectTaskCategories);
  const viewLoading = useAppSelector(selectTaskCategoriesLoading);
  const formLoading = useAppSelector(selectTaskFormLoading);
  const navigate = useNavigate();
  const taskName = useAppSelector(selectTaskName);
  const taskCategoryId = useAppSelector(selectTaskCategory);
  const taskRecurrence = useAppSelector(selectTaskRecurrence);
  const taskDueDate = useAppSelector(selectTaskDueDate);
  const taskDueTime = useAppSelector(selectTaskDueTime);
  const taskIsAllDay = useAppSelector(selectTaskIsAllDay);
  const taskIsCompleted = useAppSelector(selectTaskIsCompleted);
  const taskDescription = useAppSelector(selectTaskDescription);
  const taskFacilities = useAppSelector(selectTaskFacilities);
  const permissions = useAppSelector(selectFacilityPermissions);
  const manageTasksPermissions = createPermissions(permissions, "manage_tasks");

  const handleSubmit = (values: TaskValues) => {
    dispatch(createTask(values))
      .then((resp) => {
        if (!resp.type.includes("rejected")) {
          navigate("/task-management");
        }
      });
  };

  const formik = useFormik<TaskValues>({
    initialValues: {
      taskName: taskName,
      taskCategoryId: taskCategoryId,
      taskRecurrence: taskRecurrence,
      taskDueDate: taskDueDate,
      taskDueTime: taskDueTime,
      taskIsAllDay: taskIsAllDay,
      taskIsCompleted: taskIsCompleted,
      taskDescription: taskDescription,
      taskFacilities: taskFacilities
    },
    validationSchema: taskFormValidation,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: (values: TaskValues) => handleSubmit(values)
  });

  const pageHeader = <PageHeader title={"Add Task"} breadcrumbs={breadcrumbs} />;

  useEffect(() => {
    return () => {
      dispatch(resetTaskCategoriesSlice());
      dispatch(getAllTaskCategories());
    };
  }, []);

  if (manageTasksPermissions.create) {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <ItemManagementLayout title={"Add New Task"} loading={viewLoading} footerButtons={
          <ItemManagementFooterButtons cancelReturnPath={"/task-management"}
            formId={"task-form"} loading={formLoading} />
        }>
          <TaskForm formik={formik} taskCategories={taskCategories} />
        </ItemManagementLayout>
      </ViewWrapper>
    );
  } else {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <h1>You do not have permission to view this page.</h1>
      </ViewWrapper>
    );
  }
};

export default CreateNewTask;
