import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "DealDetails" })((theme) => ({
  bold: {
    fontWeight: "bold"
  },
  headerCell: {
    color: theme.palette.common.black,
    background: theme.palette.background.default,
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1)
  },
  promotion: {
    color: theme.palette.primary.contrastText
  },
  paidDate: {
    color: theme.palette.primary.contrastText
  },
  actionButton: {
    textTransform: "capitalize",
    height: "100%",
    width: "20%",
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.primary.contrastText
  },
  divider: {
    margin: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`,
    color: theme.palette.background.default
  }
}));

export default useStyles;
