import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { APIListResponse } from "src/models/responses/APIListResponse";
import { Note } from "src/models/Note";
import { NotesSliceState } from "./NotesSliceState";
import { RootState } from "../../rootStore";
import { createNote } from "src/store/thunks/note/createNote/createNote";
import { getAllNotes } from "src/store/thunks/note/getAll/getAllNotes";

export const initialState: NotesSliceState = {
  notes: [],
  notesLoading: false,
  notesError: ""
};

export const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    setNotes: (state, action: PayloadAction<Note[]>) => {
      state.notes = action.payload;
    },
    resetNotesSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllNotes.pending, (state) => {
        state.notesLoading = true;
      })
      .addCase(getAllNotes.fulfilled, (state, action: PayloadAction<APIListResponse<Note[]>>) => {
        state.notes = action.payload.data;
        state.notesLoading = false;
      })
      .addCase(getAllNotes.rejected, (state, action) => {
        state.notesLoading = false;
        state.notesError = action.payload as string;
      })
      .addCase(createNote.fulfilled, (state, action) => {
        state.notes = [action.payload as Note, ...state.notes];
      });
  }
});

export const selectNotes = (state: RootState) => state.notes.notes;
export const selectNotesLoading = (state: RootState) => state.notes.notesLoading;

export const {
  resetNotesSlice,
  setNotes
} = notesSlice.actions;

export default notesSlice.reducer;
