import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getDocumentTemplateChangelog = createAsyncThunk(
  "getDocumentTemplateChangelog",
  (id: number, { dispatch, getState, rejectWithValue }) => {
    return axios.get(`/api/v1/document-templates/changelog/${id}`)
      .then(resp => resp.data)
      .catch(() => {
        const errorMessage = "There was an error getting the Document Template Changelog";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
