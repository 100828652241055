import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "EmptyTable" })((theme) => ({
  root: {
    border: 0,
    "& .MuiDataGrid-columnSeparator": {
      display: "none"
    },
    "& .MuiDataGrid-colCellTitle": {
      overflow: "unset"
    },
    height: "auto",
    marginBottom: "5rem"
  },
  noResultsOverlay: {
    background: theme.palette.common.white,
    zIndex: 200
  },
  noResultsOverlayNoLink: {
    background: theme.palette.common.white,
    zIndex: 200,
    minHeight: "5rem"
  },
  addButton: {
    marginTop: theme.spacing(1),
    zIndex: 201,
    textTransform: "capitalize",
    color: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.primary.contrastText}`
  }
}));

export default useStyles;
