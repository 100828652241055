import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const enableFee = createAsyncThunk(
  "enableFee",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const facilityId = state.fee.fee?.facility_id;
    const feeId = state.fee.fee?.id;

    return axios.post(`/api/v1/facilities/${facilityId}/fees/${feeId}/enabled`, { is_active: true })
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Fee successfully enabled.",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string = "There was an error enabling this fee.";

        if (err &&
          err.response &&
          err.response.status &&
          err.response.status === 422 &&
          err.response.data &&
          err.response.data.errors &&
          Object.keys(err.response.data.errors).length
        ) {
          errorMessage = format422(err.response.data.errors);
        }

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        rejectWithValue(errorMessage);
      });
  }
);
