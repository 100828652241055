import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Facility } from "../../../models/Facility";
import { FacilitySliceState } from "./FacilitySliceState";
import { KeypadAccess } from "src/models/KeypadAccess";
import { RootState } from "../../rootStore";
import { TimeAccess } from "src/models/TimeAccess";
import { createFacility } from "src/store/thunks/facility/create/createFacility";
import { getFacility } from "../../thunks/facility/get/getFacility";
import initialFacilityFormValues
  from "src/pages/FacilityManagement/FacilityForm/FacilityHoursForm/initialFacilityFormValues";
import { updateMiscellaneousSettings } from "src/store/thunks/facility/update/updateMiscellaneousSettings";

export const initialState: FacilitySliceState = {
  selectedFacilityId: JSON.parse(sessionStorage.getItem("selectedFacilityId") as string) ?? null,
  facility: JSON.parse(sessionStorage.getItem("facility") as string) ?? null,
  id: 0,
  operator_id: 0,
  facility_id: JSON.parse(sessionStorage.getItem("facility_id") as string) ?? "",
  name: JSON.parse(sessionStorage.getItem("name") as string) ?? "",
  legal_name: JSON.parse(sessionStorage.getItem("legal_name") as string) ?? "",
  manager: JSON.parse(sessionStorage.getItem("manager") as string) ?? "",
  email: JSON.parse(sessionStorage.getItem("email") as string) ?? "",
  phone: JSON.parse(sessionStorage.getItem("phone") as string) ?? "",
  fax: JSON.parse(sessionStorage.getItem("fax") as string) ?? "",
  timezone: JSON.parse(sessionStorage.getItem("timezone") as string) ?? "",
  address: JSON.parse(sessionStorage.getItem("address") as string) ?? "",
  address_two: JSON.parse(sessionStorage.getItem("address_two") as string) ?? "",
  area: JSON.parse(sessionStorage.getItem("area") as string) ?? "",
  district_region: JSON.parse(sessionStorage.getItem("district_region") as string) ?? "",
  district: JSON.parse(sessionStorage.getItem("district") as string) ?? "",
  city: JSON.parse(sessionStorage.getItem("city") as string) ?? "",
  region: JSON.parse(sessionStorage.getItem("region") as string) ?? "",
  postal_code: JSON.parse(sessionStorage.getItem("postal_code") as string) ?? "",
  country: JSON.parse(sessionStorage.getItem("country") as string) ?? "",
  tax_id: JSON.parse(sessionStorage.getItem("tax_id") as string) ?? "",
  latitude: JSON.parse(sessionStorage.getItem("latitude") as string) ?? 0,
  longitude: JSON.parse(sessionStorage.getItem("longitude") as string) ?? 0,
  disposition_date: JSON.parse(sessionStorage.getItem("disposition_date") as string) ?? "",
  is_enabled: true,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  facilityError: "",
  facilityLoading: false,
  timeAccesses: [],
  keypadAccesses: [],
  accessTableModal: {
    open: false,
    type: ""
  },
  selectedAccessTable: null,
  removeAccessTableModal: {
    open: false,
    type: ""
  },
  sip_username: null,
  sip_password: null,
  sip_host: null,
  sip_port: null
};

export const facilitySlice = createSlice({
  name: "facility",
  initialState,
  reducers: {
    setFacility: (state, action: PayloadAction<Facility>) => {
      state.facility = action.payload;
      sessionStorage.setItem("facility", JSON.stringify(state.facility));
    },
    updateFacilityId: (state, action: PayloadAction<number>) => {
      state.selectedFacilityId = action.payload;

      sessionStorage.setItem("selectedFacilityId", JSON.stringify(state.facility));
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
      sessionStorage.setItem("name", JSON.stringify(state.name));
    },
    setLocationCode: (state, action: PayloadAction<string>) => {
      state.facility_id = action.payload;
      sessionStorage.setItem("facility_id", JSON.stringify(state.facility_id));
    },
    setLegalName: (state, action: PayloadAction<string>) => {
      state.legal_name = action.payload;
      sessionStorage.setItem("legal_name", JSON.stringify(state.legal_name));
    },
    setDistrict: (state, action: PayloadAction<string>) => {
      state.district = action.payload;
      sessionStorage.setItem("district", JSON.stringify(state.district));
    },
    setTaxId: (state, action: PayloadAction<string>) => {
      state.tax_id = action.payload;
      sessionStorage.setItem("tax_id", JSON.stringify(state.tax_id));
    },
    setManager: (state, action: PayloadAction<string>) => {
      state.manager = action.payload;
      sessionStorage.setItem("manager", JSON.stringify(state.manager));
    },
    setPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
      sessionStorage.setItem("phone", JSON.stringify(state.phone));
    },
    setFax: (state, action: PayloadAction<string>) => {
      state.fax = action.payload;
      sessionStorage.setItem("fax", JSON.stringify(state.fax));
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
      sessionStorage.setItem("email", JSON.stringify(state.email));
    },
    setTimezone: (state, action: PayloadAction<string>) => {
      state.timezone = action.payload;
      sessionStorage.setItem("timezone", JSON.stringify(state.timezone));
    },
    setLat: (state, action: PayloadAction<number>) => {
      state.latitude = action.payload;
      sessionStorage.setItem("latitude", JSON.stringify(state.latitude));
    },
    setLong: (state, action: PayloadAction<number>) => {
      state.longitude = action.payload;
      sessionStorage.setItem("longitude", JSON.stringify(state.longitude));
    },
    setAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload;
      sessionStorage.setItem("address", JSON.stringify(state.address));
    },
    setAddressTwo: (state, action: PayloadAction<string>) => {
      state.address_two = action.payload;
      sessionStorage.setItem("address_two", JSON.stringify(state.address_two));
    },
    setCity: (state, action: PayloadAction<string>) => {
      state.city = action.payload;
      sessionStorage.setItem("city", JSON.stringify(state.city));
    },
    setRegion: (state, action: PayloadAction<string>) => {
      state.region = action.payload;
      sessionStorage.setItem("region", JSON.stringify(state.region));
    },
    setArea: (state, action: PayloadAction<string>) => {
      state.area = action.payload;
      sessionStorage.setItem("area", JSON.stringify(state.area));
    },
    setDistrictRegion: (state, action: PayloadAction<string>) => {
      state.district_region = action.payload;
      sessionStorage.setItem("district_region", JSON.stringify(state.district_region));
    },
    setPostalCode: (state, action: PayloadAction<string>) => {
      state.postal_code = action.payload;
      sessionStorage.setItem("postal_code", JSON.stringify(state.postal_code));
    },
    setDispositionDate: (state, action: PayloadAction<string | null>) => {
      state.disposition_date = action.payload!.length ? action.payload : null;
      sessionStorage.setItem("disposition_date", JSON.stringify(state.disposition_date));
    },
    setFacilityAccessTableModal: (state, action: PayloadAction<{ open: boolean; type: string }>) => {
      state.accessTableModal = action.payload;
    },
    setSelectedAccessTable: (state, action: PayloadAction<TimeAccess | KeypadAccess | null>) => {
      state.selectedAccessTable = action.payload;
    },
    setRemoveAccessTableModal: (state, action: PayloadAction<{ open: boolean; type: string }>) => {
      state.removeAccessTableModal = action.payload;
    },
    setSIPUsername: (state, action: PayloadAction<string>) => {
      state.sip_username = action.payload;
    },
    setSIPPassword: (state, action: PayloadAction<string>) => {
      state.sip_password = action.payload;
    },
    setSIPHost: (state, action: PayloadAction<string>) => {
      state.sip_host = action.payload;
    },
    setSIPPort: (state, action: PayloadAction<string | number>) => {
      state.sip_port = action.payload;
    },
    resetFacilitySlice: (state) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("facility");
      sessionStorage.removeItem("district_region");
      sessionStorage.removeItem("area");
      sessionStorage.removeItem("name");
      sessionStorage.removeItem("legal_name");
      sessionStorage.removeItem("facility_id");
      sessionStorage.removeItem("district");
      sessionStorage.removeItem("district_region");
      sessionStorage.removeItem("area");
      sessionStorage.removeItem("tax_id");
      sessionStorage.removeItem("manager");
      sessionStorage.removeItem("phone");
      sessionStorage.removeItem("fax");
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("timezone");
      sessionStorage.removeItem("latitude");
      sessionStorage.removeItem("longitude");
      sessionStorage.removeItem("address");
      sessionStorage.removeItem("address_two");
      sessionStorage.removeItem("facility");
      sessionStorage.removeItem("region");
      sessionStorage.removeItem("postal_code");
      sessionStorage.removeItem("disposition_date");
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createFacility.pending, (state) => {
        state.facilityLoading = true;
      })
      .addCase(createFacility.fulfilled, (state, action) => {
        state.facilityLoading = false;
        state.facility = action.payload;
      })
      .addCase(createFacility.rejected, (state, action) => {
        state.facilityLoading = false;
        state.facilityError = action.payload as string;
      })
      .addCase(getFacility.pending, (state) => {
        state.facilityLoading = true;
      })
      .addCase(getFacility.fulfilled, (state, action) => {
        state.facilityLoading = false;
        state.facility = action.payload;
        state.id = action.payload.id;
        state.facility_id = action.payload.facility_id;
        state.name = action.payload.name;
        state.legal_name = action.payload.legal_name;
        state.district = action.payload.district;
        state.district_region = action.payload.district_region;
        state.area = action.payload.area;
        state.tax_id = action.payload.tax_id;
        state.manager = action.payload.manager;
        state.phone = action.payload.phone;
        state.fax = action.payload.fax;
        state.email = action.payload.email;
        state.timezone = action.payload.timezone;
        state.latitude = action.payload.latitude;
        state.longitude = action.payload.longitude;
        state.address = action.payload.address;
        state.address_two = action.payload.address_two;
        state.city = action.payload.city;
        state.region = action.payload.region;
        state.postal_code = action.payload.postal_code;
        state.disposition_date = action.payload.disposition_date;
        state.timeAccesses = action.payload.time_accesses;
        state.keypadAccesses = action.payload.keypad_accesses;
        state.sip_username = action.payload.sip_username;
        state.sip_password = action.payload.sip_password;
        state.sip_host = action.payload.sip_host;
        state.sip_port = action.payload.sip_port;

        if (!action.payload.access_hours && state.facility) {
          state.facility.access_hours = initialFacilityFormValues.access_hours;
        }

        if (!action.payload.access_timezone && state.facility) {
          state.facility.access_timezone = initialFacilityFormValues.access_timezone;
        }

        sessionStorage.setItem("facility", JSON.stringify(state.facility));
      })
      .addCase(getFacility.rejected, (state, action) => {
        state.facilityError = action.payload as string;
        state.facilityLoading = false;
      })
      .addCase(updateMiscellaneousSettings.pending, (state) => {
        state.facilityLoading = true;
      })
      .addCase(updateMiscellaneousSettings.fulfilled, (state, action) => {
        state.facilityLoading = false;
        state.facility = action.payload;
      })
      .addCase(updateMiscellaneousSettings.rejected, (state, action) => {
        state.facilityLoading = false;
        state.facilityError = action.payload as string;
      });
  }
});

export const selectFacility = (state: RootState) => state.facility.facility;
export const selectFacilityName = (state: RootState) => state.facility.name;
export const selectFacilityLegalName = (state: RootState) => state.facility.legal_name;
export const selectFacilityLocationCode = (state: RootState) => state.facility.facility_id;
export const selectFacilityDistrict = (state: RootState) => state.facility.district;
export const selectFacilityTaxId = (state: RootState) => state.facility.tax_id;
export const selectFacilityManager = (state: RootState) => state.facility.manager;
export const selectFacilityPhone = (state: RootState) => state.facility.phone;
export const selectFacilityFax = (state: RootState) => state.facility.fax;
export const selectFacilityEmail = (state: RootState) => state.facility.email;
export const selectFacilityTimezone = (state: RootState) => state.facility.timezone;
export const selectFacilityLatitude = (state: RootState) => state.facility.latitude;
export const selectFacilityLongitude = (state: RootState) => state.facility.longitude;
export const selectFacilityAddress = (state: RootState) => state.facility.address;
export const selectFacilityAddressTwo = (state: RootState) => state.facility.address_two;
export const selectFacilityCity = (state: RootState) => state.facility.city;
export const selectFacilityState = (state: RootState) => state.facility.region;
export const selectFacilityPostalCode = (state: RootState) => state.facility.postal_code;
export const selectFacilityError = (state: RootState) => state.facility.facilityError;
export const selectFacilityLoading = (state: RootState) => state.facility.facilityLoading;
export const selectSelectedFacilityId = (state: RootState) => state.facility.selectedFacilityId;
export const selectFacilityDispositionDate = (state: RootState) => state.facility.disposition_date;
export const selectFacilityDistrictRegion = (state: RootState) => state.facility.district_region;
export const selectFacilityArea = (state: RootState) => state.facility.area;
export const selectFacilityTimeAccesses = (state: RootState) => state.facility.timeAccesses;
export const selectFacilityKeypadAccesses = (state: RootState) => state.facility.keypadAccesses;
export const selectFacilityAccessTableModal = (state: RootState) => state.facility.accessTableModal;
export const selectFacilitySelectedAccessTable = (state: RootState) => state.facility.selectedAccessTable;
export const selectFaciltiyRemoveAccessModal = (state: RootState) => state.facility.removeAccessTableModal;
export const selectFacilitysip_username = (state: RootState) => state.facility.sip_username;
export const selectFacilitysip_password = (state: RootState) => state.facility.sip_password;
export const selectFacilitysip_host = (state: RootState) => state.facility.sip_host;
export const selectFacilitySipPort = (state: RootState) => state.facility.sip_port;

export const {
  setFacility,
  setAddress,
  setAddressTwo,
  setCity,
  setDistrict,
  setArea,
  setDistrictRegion,
  setEmail,
  setLocationCode,
  setPostalCode,
  setFax,
  setLat,
  setLong,
  setManager,
  setName,
  setLegalName,
  setPhone,
  setRegion,
  setTaxId,
  setTimezone,
  setDispositionDate,
  updateFacilityId,
  setFacilityAccessTableModal,
  setSelectedAccessTable,
  setRemoveAccessTableModal,
  setSIPUsername,
  setSIPPassword,
  setSIPHost,
  setSIPPort,
  resetFacilitySlice
} = facilitySlice.actions;

export default facilitySlice.reducer;
