import React, { ReactElement, useEffect } from "react";
import {
  resetRevenueClassSlice,
  selectRevenueClassLoading
} from "../../../../store/reducers/revenueClassSlice/revenueClassSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { AppDispatch } from "../../../../store/rootStore";
import { Breadcrumb } from "../../../../models/Breadcrumb";
import FooterButtons from "../../../../components/itemManagement/ItemManagementCard/FooterButtons/FooterButtons";
import ItemManagementLayout from "../../../../layouts/ItemManagement/ItemManagementLayout";
import PageHeader from "../../../../components/ui/PageHeader/PageHeader";
import RevenueClassForm from "../RevenueClassForm/RevenueClassForm";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { resetRevenueClassInformationSlice }
  from "../../../../store/reducers/revenueClassInformationSlice/revenueClassInformationSlice";
import { setFromRevenueClassPage } from "src/store/reducers/revenueClassesSlice/revenueClassesSlice";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Accounting"
  },
  {
    name: "Revenue Classes",
    bold: true
  }
];

export const handleResets = (dispatch: AppDispatch) => {
  dispatch(resetRevenueClassSlice());
  dispatch(resetRevenueClassInformationSlice());
};

const CreateRevenueClass: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectRevenueClassLoading);
  const permissions = useAppSelector(selectFacilityPermissions);
  const revenueClassesPermissions = createPermissions(permissions, "revenue_classes");

  useEffect(() => {
    dispatch(setFromRevenueClassPage(true));
    return () => {
      handleResets(dispatch);
    };
  }, []);

  const pageHeader = <PageHeader title={"New Revenue Class"} breadcrumbs={breadcrumbs} />;

  if (revenueClassesPermissions.create) {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <ItemManagementLayout
          title={"New Revenue Class"}
          footerButtons={
            <FooterButtons
              formId={"revenue-class-form"}
              cancelPath={"/accounting/revenue-classes"}
              submitLoading={loading}
              submitButtonText={"Create Revenue Class"}
            />
          }
        >
          <RevenueClassForm />
        </ItemManagementLayout>
      </ViewWrapper>
    );
  } else {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <h1>You do not have permission to view this page.</h1>
      </ViewWrapper>
    );
  }
};

export default CreateRevenueClass;
