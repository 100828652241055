import { Button, Grid, Modal, Paper, Typography } from "@mui/material";
import { Note } from "@mui/icons-material";
import React from "react";
import { selectSelectedLedgerUnitNumber } from "src/store/reducers/moveOutSlice/moveOutSlice";
import { useAppSelector } from "src/store/hooks";
import useStyles from "./TemporaryFutureMoveOutModal.styles";

interface TemporaryFutureMoveOutModalProps {
  open: boolean;
  onModalClose: () => void;
}

const TemporaryFutureMoveOutModal = ({ open, onModalClose }: TemporaryFutureMoveOutModalProps) => {
  const { classes } = useStyles();
  const unitNumber = useAppSelector(selectSelectedLedgerUnitNumber);

  return (
    <Modal
      open={open}
      onClose={() => onModalClose()}
      aria-labelledby={"Modal Component"}
      aria-describedby={"A Modal component."}
      className={classes.modal}
    >
      <Grid container className={classes.modal}>
        <Grid
          container
          component={Paper}
          elevation={0}
          variant={"outlined"}
          spacing={2}
          className={classes.container}
          pb={2}
          bgcolor={"green"}
        >
          <Grid container>
            <Grid item xs={12} justifyContent={"center"}
              textAlign={"center"} fontSize={"5rem"}>
              <Note className={classes.icon} fontSize={"large"} />
            </Grid>
            <Grid item xs={12} justifyContent={"center"}
              textAlign={"center"}>
              <Typography variant={"h5"} textAlign={"center"} color={"white"}>
                Future Move Out Note Created
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          component={Paper}
          elevation={0}
          variant={"outlined"}
          spacing={2}
          className={classes.actionButtonContainer}
          pb={2}
        >
          <Grid container justifyContent={"center"}>
            <Grid item className={classes.description}>
              <Typography variant={"body1"} textAlign={"center"}>
                A Note has been created for a future Move Out of Unit {unitNumber}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            justifyContent={"center"}
            py={2}
          >
            <Button
              data-testid={"cancel-button"}
              className={classes.cancelButton}
              onClick={() => onModalClose()}
              variant={"outlined"}
              color={"secondary"}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default TemporaryFutureMoveOutModal;
