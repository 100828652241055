import { RootState } from "src/store/rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";

interface BatchUploadAskingRatesPayload {
    file: File
}

export const batchUploadAskingRates = createAsyncThunk(
  "units/batchUpload",
  ({ file }: BatchUploadAskingRatesPayload, { dispatch, getState, rejectWithValue }) => {
    const formData = new FormData();
    const store = getState() as RootState;
    formData.append("csv_file", file);
    return axios.post(`/api/v1/asking-rate-changes/import`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })

      .then((resp) => {
        dispatch(showSnackbar({
          message: "Asking Rates batch uploaded successfully.",
          variant: "success"
        }));
        return resp.data;
      })
      .catch((err) => {
        const errorMessage = err.response.data.detail || "An error occurred while uploading asking rates.";

        return rejectWithValue(errorMessage);
      });
  }
);
