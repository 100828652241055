import { CreateLeadPayload } from "./CreateLeadPayload";
import NoteType from "src/enums/NoteType";
import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createNote } from "../../note/createNote/createNote";
import { format422 } from "../../../../utils/format422/format422";
import { formatDate } from "../../../../utils/__dateAndTimeUtils__/formatDate/formatDate";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const createLead = createAsyncThunk(
  "createLead",
  async(_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const facilityId = state.selectedFacility.selectedFacility.id;
    const productTypeId = state.selectedUnit.selectedProductType!.id;
    const referralType = state.dealInformation.referralType;
    const needed = formatDate(state.dealInformation.neededOn, "YYYY-MM-DD");
    const followUp = formatDate(state.dealInformation.followup, "YYYY-MM-DD");
    const quoted = state.selectedUnit.quotedRate;
    const firstName = state.selectedOccupant.selectedOccupant!.first_name;
    const middleName = state.selectedOccupant.selectedOccupant!.middle_name;
    const lastName = state.selectedOccupant.selectedOccupant!.last_name;
    const email = state.selectedOccupant.selectedOccupant!.email;
    const phone = state.selectedOccupant.selectedOccupant!.phone_primary;
    const address = state.selectedOccupant.selectedOccupant!.address;
    const addressTwo = state.selectedOccupant.selectedOccupant!.address_two;
    const city = state.selectedOccupant.selectedOccupant!.city;
    const region = state.selectedOccupant.selectedOccupant!.region;
    const postalCode = state.selectedOccupant.selectedOccupant!.postal_code;
    const occupantId = state.selectedOccupant.selectedOccupant?.id ?? undefined;
    const promotionId = state.selectedPromotion.selectedPromotion?.id;
    const note = state.note.note;
    const unitId = state.selectedUnit.selectedUnit!.id;

    const payload: CreateLeadPayload = {
      occupant_id: occupantId,
      deal_type: 1,
      product_type_id: productTypeId,
      unit_id: unitId,
      facility_id: facilityId,
      referral_type: referralType === "" ? null : referralType,
      needed_at: needed,
      follow_up: followUp,
      reservation_expires_at: null,
      quoted_price: parseFloat(quoted),
      cancelled_at: "",
      contact_at: followUp,
      last_contact_at: "",
      first_name: firstName,
      middle_name: middleName ?? "",
      last_name: lastName,
      email: email,
      phone: phone,
      address: address ?? "",
      address_two: addressTwo ?? "",
      city: city ?? "",
      region: region ?? "",
      postal_code: postalCode ?? "",
      country: "USA",
      source: "",
      promotion_id: promotionId
    };

    return axios.post(`/api/v1/facilities/${payload.facility_id}/deals`, payload)
      .then((resp) => {
        if (note) {
          dispatch(createNote({ id: resp.data.id, type: NoteType.deal, description: note }));
        }

        dispatch(showSnackbar({
          message: "New Lead Created",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an error creating the Lead";

        if (err.response.status === 400) {
          errorMessage = err.response.data.detail;
        }

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
