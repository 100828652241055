import { Container, Divider, Grid, useMediaQuery } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";
import useStyles from "./ViewWrapper.styles";
import clsx from "clsx";

interface ViewWrapperProps {
  children: ReactNode
  pageHeader?: ReactNode
}

const ViewWrapper: React.FC<ViewWrapperProps> = ({ children, pageHeader }): ReactElement => {
  const { classes } = useStyles();
  const size = useMediaQuery("(min-width: 2000px)");

  return (
    <Grid
      container
      direction={"column"}
      justifyContent={"start"}
      alignItems={"center"}
    >
      <Container className={clsx(classes.container, size ? classes.extraPadding : null)} >
        {pageHeader}
        <Divider />
        {children}
      </Container>
    </Grid>
  );
};

export default ViewWrapper;
