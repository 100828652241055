import { RootState } from "src/store/rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const emailReceipt = createAsyncThunk("emailReceipt", (
  email : string,
  { dispatch, getState, rejectWithValue }) => {
  const store = getState() as RootState;

  const facilityId = store.selectedFacility?.selectedFacility.id;
  const transactionId = store.transactions?.currentTransaction?.id;
  const moveInTransactionId = store.moveIn.moveInConfirmation?.transaction.id;
  const moveOutTransactionId = store.moveOut.moveOutConfirmation?.transaction.id;
  const makeAPaymentTransactionId = store.makeAPayment?.transaction?.id;

  const id = moveInTransactionId ?? transactionId ?? moveOutTransactionId ?? makeAPaymentTransactionId;

  return axios
    .post(`/api/v1/facilities/${facilityId}/transactions/${id}/email-receipt`, {
      email
    })
    .then((resp) => {
      dispatch(showSnackbar({
        message: `Receipt successfully sent to ${email}`,
        variant: "success"
      }));
    })
    .catch(() => {
      const errorMessage = "There was an error emailing the receipt";

      dispatch(
        showSnackbar({
          message: errorMessage,
          variant: "error"
        })
      );

      return rejectWithValue(errorMessage);
    });
});
