import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Contact Notes Modal" })((theme) => ({
  paperColor: {
    height: "100%",
    background: "#EFF4FB",
    borderRadius: "1rem"
  },
  blueText: {
    color: theme.palette.primary.contrastText,
    fontWeight: 600
  },
  paymentButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: "white",
    height: "2rem"
  }
}));

export default useStyles;
