import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "PaymentSummary" })((theme) => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

export default useStyles;
