import { Button, CircularProgress, Grid, InputAdornment, InputLabel, TextField, Typography } from "@mui/material";
import React, { FormEvent, useEffect, useState } from "react";
import { ReactComponent as CashIcon } from "./icons/cash.svg";
import cashFormValidation from "./cashFormValidation";
import { inputError } from "src/utils/showInputError/showInputError";
import { useFormik } from "formik";
import useStyles from "./CashForm.styles";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  resetReviewAndPayCashSlice,
  selectCashDue,
  selectCashReceived,
  selectChangeOwed,
  setCashDue,
  setCashReceived
} from "src/store/reducers/reviewAndPayCashSlice/reviewAndPayCashSlice";
import {
  selectFinalBalance
} from "src/store/reducers/pointOfSalesSlice/pointOfSalesSlice";

interface CashFormProps {
  formId: string
  onSubmit: () => void
  loading: boolean
}

const CashForm = ({ formId, onSubmit, loading } : CashFormProps) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const cashReceived = useAppSelector(selectCashReceived);
  const cashDue = useAppSelector(selectFinalBalance);
  const changeOwed = useAppSelector(selectChangeOwed);

  const formik = useFormik({
    initialValues: {
      cashDue: parseFloat(cashDue) >= 0 ? cashDue : "0.00",
      cashReceived
    },
    validationSchema: cashFormValidation,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit
  });

  const { handleSubmit, touched, errors, values, handleChange, setFieldValue } = formik;

  useEffect(() => {
    setFieldValue("cashDue", parseFloat(cashDue) >= 0 ? cashDue : "0.00");
  }, [cashDue]);

  useEffect(() => {
    return () => {
      dispatch(resetReviewAndPayCashSlice());
    };
  }, []);

  return (
    <Grid
      xs={10}
      item
      mt={"1rem"}
      component={"form"}
      id={formId}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
      }}
  >
      <Grid item xs={12}>
        <Typography
          className={classes.headerCell}
    >
          Payment Information
        </Typography>
      </Grid>
      <Grid item xs={12} mt={2}
        display={"flex"}
        justifyContent={"center"}>
        <CashIcon />
      </Grid>
      <Grid item xs={12} mt={4}
        display={"flex"}
        justifyContent={"center"} gap={"1.2rem"}>
        <Grid
          item
          xs={2}
      >
          <InputLabel htmlFor={"cash-due"}>Cash Due</InputLabel>
          <TextField
            fullWidth
            id={"cash-due"}
            inputProps={{ "data-testid": "cash-due" }}
            value={values.cashDue}
            name={"cashDue"}
            InputProps={{ startAdornment: <InputAdornment position={"start"}>$</InputAdornment> }}
            disabled
          />
        </Grid>
        <Grid
          item
          xs={2}
      >
          <InputLabel htmlFor={"cashReceived"}>Cash Received</InputLabel>
          <TextField
            fullWidth
            type={"number"}
            placeholder={"00.00"}
            id={"cashReceived"}
            inputProps={{ "data-testid": "cashReceived" }}
            name={"cashReceived"}
            value={values.cashReceived}
            onChange={(e) => {
              handleChange(e);
              dispatch(setCashReceived(e.target.value));
            }}
            InputProps={{ startAdornment: <InputAdornment position={"start"}>$</InputAdornment> }}
            error={inputError("cashReceived", touched, errors).error}
            helperText={inputError("cashReceived", touched, errors).helperText}
        />
        </Grid>
      </Grid>
      <Grid item xs={12}
        display={"flex"}
        justifyContent={"center"}
        gap={"1.2rem"}
        mt={2}
        >
        <Grid
          item
          xs={2}
        >
          <InputLabel htmlFor={"cash-due"}>Change Owed</InputLabel>
          <TextField
            fullWidth
            placeholder={"00.00"}
            id={"change-owed"}
            inputProps={{ "data-testid": "change-owed" }}
            name={"changeOwed"}
            value={changeOwed}
            InputProps={{ startAdornment: <InputAdornment position={"start"}>$</InputAdornment> }}
            disabled
          />
        </Grid>
        <Grid
          item
          xs={2}
        />
      </Grid>
      <Grid
        pt={2}
        mt={4}
        container
        justifyContent={"flex-end"}
          >
        <Grid item>
          <Button
            data-testid={"cancel-button"}
            className={classes.cancelButton}
            variant={"text"}
            color={"secondary"}
              >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            form={formId}
            type={"submit"}
            data-testid={"cancel-button"}
            className={classes.confirmButton}
            variant={"contained"}
            disabled={loading}
              >
            Place Order
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CashForm;
