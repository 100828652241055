import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles<{ pulse: boolean }>({ label: "FabPulsatingButton" })((theme, { pulse }) => ({
  pulse: {
    width: "fit-content",
    borderRadius: "50%",
    position: "absolute",
    bottom: 30,
    right: 30,
    cursor: "pointer"
  },
  pulseSpan: {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: "inherit",
    animation: pulse ? "pulseAnimate 3000ms ease-out infinite" : "none",
    opacity: 0.8,
    backgroundColor: pulse ? theme.palette.primary.contrastText : "inherit"
  },
  lead: {
    backgroundColor: theme.palette.primary.contrastText,
    padding: " 0 1rem",
    marginBottom: "0.5rem",
    borderRadius: "2rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    animation: "slide 1s forwards, moveUp 2s 2s forwards",
    position: "relative"
  },
  lead2: {
    backgroundColor: theme.palette.primary.contrastText,
    padding: " 0 1rem",
    marginBottom: "0.5rem",
    borderRadius: "2rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    top: "2rem"
  },
  leadText: {
    textTransform: "uppercase",
    color: "#fff"
  },
  plusDeals: {
    position: "absolute",
    bottom: "0%",
    right: "0",
    padding: "0.3rem",
    backgroundColor: theme.palette.error.main,
    width: "18px",
    borderRadius: "100%",
    height: "18px",
    textAlign: "center",
    color: "white"
  }
}));

export default useStyles;
