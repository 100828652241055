import { Grid, InputLabel, TextField, Typography } from "@mui/material";
import React, { FC, SyntheticEvent, useEffect } from "react";
import {
  selectProductCategoriesList,
  selectProductCategoriesListLoading
} from "src/store/reducers/productCategoriesSlice/productCategoriesSlice";
import {
  selectProductTypeName,
  selectProductTypeProductCategoryId,
  setProductTypeName,
  setProductTypeProductCategoryId
} from "src/store/reducers/productTypeSliceNew/productTypeSliceNew";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import StepLayout from "src/layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "src/layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import createNewProductTypeValidation from "./createNewProductTypeValidation";
import { getAllProductCategoriesNew } from "src/store/thunks/productCategory/getAllNew/getAllProductCategoriesNew";
import { inputError } from "src/utils/showInputError/showInputError";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { useFormik } from "formik";

const stepperId = "new-product-type-form";

interface CreateNewProductTypeProps {
  handleClose: () => void
  setStep: React.Dispatch<React.SetStateAction<number>>
};

const CreateNewProductTypeForm:FC<CreateNewProductTypeProps> = ({ handleClose, setStep }) => {
  const dispatch = useAppDispatch();

  //this is NOT supposed to be an admin facility. We use Global here.
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const productCategories = useAppSelector(selectProductCategoriesList);
  const productCategoriesLoading = useAppSelector(selectProductCategoriesListLoading);
  const productTypeName = useAppSelector(selectProductTypeName);
  const productCatId = useAppSelector(selectProductTypeProductCategoryId);

  const formik = useFormik({
    initialValues: {
      name: productTypeName || "",
      product_category: productCatId || ""
    },
    enableReinitialize: true,
    validationSchema: createNewProductTypeValidation,
    onSubmit: (values) => {
      // navigate to next path
      dispatch(setProductTypeName(values.name));
      dispatch(setProductTypeProductCategoryId(values.product_category));
      setStep((state) => state + 1);
    }
  });

  const handleGoBack = () => {
    setStep(state => state - 1);
    handleClose();
  };

  const { touched, values, errors, handleChange } = formik;

  useEffect(() => {
    if (selectedFacility) {
      dispatch(getAllProductCategoriesNew({ facilityId: +selectedFacility?.id }));
    }
  }, []);

  return (
    <StepLayout
      padding={2}
      gridDirection={"row"}
      buttons={
        <StepperButtons
          showCancelButton
          onCancel={handleClose}
          stepperId={stepperId}
          forwardActionText={"Next"}
          backAction={handleGoBack}
      />
    }
  >
      <Grid
        component={"form"}
        container
        spacing={1}
        id={stepperId}
        onSubmit={(e: SyntheticEvent) => {
          e.preventDefault();
          e.stopPropagation();
          formik.handleSubmit();
        }}
        >
        <Grid item xs={12}>
          <Typography textAlign={"center"} variant={"h5"}>Create New Product Type</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography textAlign={"center"} >
            To create a new product type, first give the new type a name and define it&apos;s property type
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor={"name"}>
            Product Type Name
          </InputLabel>
          <TextField
            fullWidth
            id={"name"}
            name={"name"}
            value={values.name}
            onChange={handleChange}
            error={inputError("name", touched, errors).error}
            helperText={inputError("name", touched, errors).helperText}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor={"product_category"}>
            Product Category
          </InputLabel>
          <PMSSelect
            disabled={productCategoriesLoading}
            id={"product_category"}
            name={"product_category"}
            value={values.product_category}
            changeHandler={handleChange}
            error={inputError("product_category", touched, errors).error}
            helperText={inputError("product_category", touched, errors).helperText}
          >
            <option value={""}>
              {" - Product Category -"}
            </option>
            {productCategories.map(category =>
              <option key={category.id} value={category.id}>{category.name}</option>
            )}
          </PMSSelect>
        </Grid>
      </Grid>
    </StepLayout>
  );
};

export default CreateNewProductTypeForm;
