import { Grid, ListItem, ListItemIcon, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import FullCalendar from "@fullcalendar/react"; // must always precede plugins in the import order
import clsx from "clsx";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import { getAllTasksForCalendar } from "src/store/thunks/task/getAllForCalendar/getAllTasksForCalendar";
import interactionPlugin from "@fullcalendar/interaction"; // a plugin!
import listPlugin from "@fullcalendar/list"; // a plugin!
import rrulePlugin from "@fullcalendar/rrule";
import { selectDrawerTransitionState } from "../../store/reducers/navigationSlice/navigationSlice";
import theme from "../../theme";
import timeGridPlugin from "@fullcalendar/timegrid"; // a plugin!
import {
  updateCalendarTaskCompletion
} from "src/store/thunks/task/updateCalendarTaskCompletion/updateCalendarTaskCompletion";
import useStyles from "./PMSCalendar.styles";

// need emotion/styled to override css here
import styled from "@emotion/styled";
import { CalendarTask } from "../../models/CalendarTask";
import { setTaskModalData, setTaskModalOpen } from "src/store/reducers/modalSlice/modalSlice";
import { customButtons } from "./customButtons";
import { useLocation } from "react-router";

export const StyleWrapper = styled.div`
  .fc-button.fc-prev-button, .fc-button.fc-next-button, .fc-button.fc-button-primary {
    text-transform: capitalize;
  }
  .fc .fc-button {
    border-radius: 10px;
  }
  .fc-button.fc-prev-button, .fc-button.fc-next-button {
    background: transparent;
    border: transparent;
    color: ${theme.palette.primary.main};
  }

  .fc .fc-button[aria-pressed="false"] {
    color: ${theme.palette.primary.main};
    background: transparent;
  }

  a {
    margin: ${theme.spacing(0.33)};
    padding: ${theme.spacing(0.33)};
    border-radius: 10px;
  }
  .fc .fc-toolbar {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
  }
  .fc-toolbar-chunk > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fc-toolbar h2 {
    display: inline;
    padding-inline: ${theme.spacing(2)}
  }
  .fc-event-main-frame, .fc-event-time, .fc-event-title-container {
    textTransform: "uppercase"
  }
`;

interface PMSCalendarProps {
  events: CalendarTask[]
}

// component with calendar, surround the calendar with the StyleWrapper
function PMSCalendar({ events }: PMSCalendarProps) {
  const navDrawerTransitionState = useAppSelector(selectDrawerTransitionState);
  const calendarRef = useRef(null);
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const { classes } = useStyles();

  useEffect(() => {
    // @ts-ignore
    calendarRef.current?.getApi();
  }, []);

  const handleEventClick = async(task: any) => {
    if (calendarRef.current && (calendarRef.current as FullCalendar).getApi().view.type === "listWeek") {
      const currentTask = task.event.extendedProps.item;
      dispatch(setTaskModalData(currentTask));
      dispatch(setTaskModalOpen(true));
    }
  };

  useEffect(() => {
    if (state?.fromNotifications) {
      // @ts-ignore
      calendarRef.current?.getApi().changeView("listWeek");
    }
  }, [state]);

  const handleResize = () => {
    if (calendarRef.current) {
      // @ts-ignore
      calendarRef.current.getApi().updateSize();
    }
  };

  const renderEventContent = (eventInfo: any) => {
    const isCompleted = eventInfo.event._def.extendedProps.item.is_completed;

    return (
      <Typography
        className={clsx({ [classes.strikethrough]: isCompleted })}
        variant={"subtitle2"}
        component={"span"}
      >
        {eventInfo.timeText} {eventInfo.event.title}
      </Typography>
    );
  };

  useEffect(() => {
    if (navDrawerTransitionState === "inactive") {
      handleResize();
    }
  }, [navDrawerTransitionState]);

  return (
    <StyleWrapper>
      <Grid p={1} mt={2}>
        <FullCalendar
          ref={calendarRef}
          editable
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, rrulePlugin]}
          initialView={"dayGridMonth"}
          events={events}
          handleWindowResize
          windowResizeDelay={1}
          eventContent={renderEventContent}
          eventClick={handleEventClick}
          eventDisplay={"block"}
          customButtons={customButtons(calendarRef)}
          headerToolbar={{
            start: "today",
            center: "prev,title,next",
            end: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
          }}
        />
      </Grid>
    </StyleWrapper>
  );
}

export default PMSCalendar;
