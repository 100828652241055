import { UploadDocumentPayload } from "./UploadDocumentPayload";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "src/utils/format422/format422";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";

interface UploadDocumentProps {
    file: string,
    occupantId: number,
    unitId?: number,
    name: string,
    type: number
}

export const uploadOccupantDocument = createAsyncThunk(
  "uploadOccupantDocument",
  ({ file, occupantId, unitId, name, type }: UploadDocumentProps, { dispatch, rejectWithValue }) => {
    const payload: UploadDocumentPayload = {
      content: file,
      name: name,
      unit_id: unitId,
      type
    };

    return axios.post(`/api/v1/occupants/${occupantId}/uploaded-documents`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: `Document uploaded successfuly.`,
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage + " Max file size is 20MB",
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an error uploading the document";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
