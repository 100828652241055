import { RootState } from "src/store/rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const exportChartOfAccountsSettingsAsExcel = createAsyncThunk(
  "exportChartOfAccountsSettingsAsExcel",
  (_, { dispatch, getState, rejectWithValue }) => {
    const store = getState() as RootState;

    const facilityId = store.selectedAdminFacility.selectedAdminFacility?.id;

    return axios.get(`/api/v1/facilities/${facilityId}/chart-accounts/export-excel`, {
      responseType: "blob"
    })
      .then((resp) => {
        const fileUrl = window.URL.createObjectURL(new Blob([resp.data]));
        return fileUrl;
      }
      )
      .catch(() => {
        const errorMessage = "There was an error exporting the Chart of Accounts settings";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
