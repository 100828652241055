import React from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Modal, Paper, Typography } from "@mui/material";
import { Deal } from "src/models/Deal";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  selectDeal,
  selectUnableToReactivateModalOpen,
  setUnableToReactivateModalOpen
} from "src/store/reducers/dealSlice/dealSlice";
import { useNavigate } from "react-router";
import useStyles from "./UnableToReactivateModal.styles";

const UnableToReactivateModal = ({ onSubmit, type }: {onSubmit?: Function, type: "LEAD" | "RESERVATION"}) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const deal = useAppSelector(selectDeal) as Deal;
  const dispatch = useAppDispatch();
  const modalOpened = useAppSelector(selectUnableToReactivateModalOpen);

  const handleClose = () => {
    dispatch(setUnableToReactivateModalOpen(false));
  };

  const renderTitle = {
    LEAD: "The unit and/or product type is not available.",
    RESERVATION: "The reserved unit is not available"
  };

  const renderDescription = {
    LEAD: "Please choose another unit and/or product type before reactivating the deal.",
    RESERVATION: "Please choose another unit before reactivating the deal"
  };

  const handleEditDeal = () => {
    navigate(`/deals/${deal.id}/edit`);
  };

  return (
    <Modal
      open={modalOpened}
      onClose={() => handleClose()}
      aria-labelledby={"Modal Component"}
      aria-describedby={"A Modal component."}
      className={classes.modal}
      data-testid={"modal-unable-to-reactivate-deal"}
  >
      <Grid
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        spacing={2}
        className={classes.container}
        pb={2}>
        <Box className={classes.content}>
          <Typography variant={"h5"} className={classes.title}>Unable to Reactivate Deal</Typography>
          <Typography>{renderTitle[type]}</Typography>
          <Typography className={classes.description}>{renderDescription[type]}</Typography>
          <Grid
            pt={4}
            spacing={4}
            container
            justifyContent={"center"}
      >
            <Grid item>
              <Button
                data-testid={"cancel-button"}
                className={classes.cancelButton}
                onClick={() => handleClose()}
                variant={"outlined"}
                color={"secondary"}
          >
                CANCEL
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                data-testid={"confirm-button-modal"}
                color={"primary"}
                variant={"contained"}
                className={classes.button}
                onClick={() => handleEditDeal()}
          >
                EDIT DEAL
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Modal>
  );
};

export default UnableToReactivateModal;
