import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { APIListResponse } from "../../../models/responses/APIListResponse";
import { Merchandise } from "../../../models/Merchandise";
import { MerchandiseItemsSliceState } from "./MerchandiseItemsSliceState";
import { RootState } from "../../rootStore";
import { getAllMerchandise } from "../../thunks/merchandise/getAll/getAllMerchandise";
import { getAllMerchandiseForMoveIn } from "../../thunks/merchandise/getAllForMoveIn/getAllMerchandiseForMoveIn";
import {
  getAllMerchandiseNoPagination
} from "src/store/thunks/merchandise/getAllMerchandiseNoPagination/getAllMerchandiseNoPagination";
import { getAllRetailMerch } from "src/store/thunks/merchandise/getAllRetailMerch/getAllRetailMerch";

export const initialState: MerchandiseItemsSliceState = {
  merchandiseItems: [],
  merchandiseItemsLoading: false,
  merchandiseItemsError: ""
};

export const merchandiseItemsSlice = createSlice({
  name: "merchandiseItems",
  initialState,
  reducers: {
    setMerchandiseQuantity: (state, action: PayloadAction<{ merchId: number, merchQuantity: number }>) => {
      const merchToUpdate = state.merchandiseItems.find(merchItem => merchItem.id === action.payload.merchId);

      if (merchToUpdate) {
        merchToUpdate.quantity_for_cart = action.payload.merchQuantity;
      }
    },
    resetMerchandiseItemsSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllMerchandise.pending, (state) => {
        state.merchandiseItemsLoading = true;
      })
      .addCase(getAllMerchandise.fulfilled, (
        state, action: PayloadAction<APIListResponse<Merchandise[]>>
      ) => {
        const formattedMerchandise = action.payload.data.map(merchItem => ({ ...merchItem, quantity_for_cart: 1 }));

        state.merchandiseItemsLoading = false;
        state.merchandiseItems = formattedMerchandise;
      })
      .addCase(getAllMerchandise.rejected, (state, action) => {
        state.merchandiseItemsLoading = false;
        state.merchandiseItemsError = action.payload as string;
      })
      .addCase(getAllMerchandiseForMoveIn.pending, (state) => {
        state.merchandiseItemsLoading = true;
      })
      .addCase(getAllMerchandiseForMoveIn.fulfilled, (
        state, action: PayloadAction<APIListResponse<Merchandise[]>>
      ) => {
        const formattedMerchandise = action.payload.data.map(merchItem => ({ ...merchItem, quantity_for_cart: 1 }));

        state.merchandiseItemsLoading = false;
        state.merchandiseItems = formattedMerchandise;
      })
      .addCase(getAllMerchandiseForMoveIn.rejected, (state, action) => {
        state.merchandiseItemsLoading = false;
        state.merchandiseItemsError = action.payload as string;
      })
      .addCase(getAllMerchandiseNoPagination.pending, (state) => {
        state.merchandiseItemsLoading = true;
      })
      .addCase(getAllMerchandiseNoPagination.fulfilled, (state, action) => {
        const formattedMerchandise = action.payload.data.map(
          (merchItem:Merchandise) => ({ ...merchItem, quantity_for_cart: 1 })
        );

        state.merchandiseItemsLoading = false;
        state.merchandiseItems = formattedMerchandise;
      })
      .addCase(getAllMerchandiseNoPagination.rejected, (state, action) => {
        state.merchandiseItemsLoading = false;
        state.merchandiseItemsError = action.payload as string;
      })
      .addCase(getAllRetailMerch.pending, (state) => {
        state.merchandiseItemsLoading = true;
      })
      .addCase(getAllRetailMerch.fulfilled, (state, action) => {
        const formattedMerchandise = action.payload.data.map(
          (merchItem:Merchandise) => ({ ...merchItem, quantity_for_cart: 1 })
        );

        state.merchandiseItemsLoading = false;
        state.merchandiseItems = formattedMerchandise;
      })
      .addCase(getAllRetailMerch.rejected, (state, action) => {
        state.merchandiseItemsLoading = false;
        state.merchandiseItemsError = action.payload as string;
      });
  }
});

export const selectMerchandiseItems = (state: RootState) => state.merchandiseItems.merchandiseItems;
export const selectMerchandiseItemsLoading = (state: RootState) => state.merchandiseItems.merchandiseItemsLoading;

export const {
  setMerchandiseQuantity,
  resetMerchandiseItemsSlice
} = merchandiseItemsSlice.actions;

export default merchandiseItemsSlice.reducer;
