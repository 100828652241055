import { RootState } from "src/store/rootStore";
import { Task } from "src/models/Task";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const updateCalendarTaskCompletion = createAsyncThunk(
  "updateCalendarTaskCompletion",
  (values: Task, { dispatch, getState, rejectWithValue }) => {
    const getAssignees = () => {
      return (getState() as RootState).facilities.facilities.map(facility => facility.id);
    };

    const toggleCompleted = {
      ...values,
      is_completed: !values.is_completed,
      assignee: getAssignees() ?? []
    };

    return axios.put(`/api/v1/tasks/${values.id}`, toggleCompleted)
      .then((resp) => {
        dispatch(showSnackbar({
          message: `Task successfully updated`,
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an error updating this Task";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
