import { PMSPaginationValues } from "src/hooks/usePagination";

export const getPaginationParams = (
  values: PMSPaginationValues,
  initialSortDirection: string = "",
  initialSortByValue: string = ""
) => {
  const searchParams = new URLSearchParams();

  // b/c MUI tables are 0-indexed, we need to increment w/e goes out by 1
  searchParams.append("page", String(values.page + 1));
  searchParams.append("pageSize", String(values.pageSize));
  searchParams.append("sortDirection", values.sortModel[0]?.sort || initialSortDirection);
  searchParams.append("sortBy", values.sortModel[0]?.field || initialSortByValue);

  return searchParams;
};
