import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Facility Multi Select and Date Styles" })((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  saveButton: {
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText,
    minWidth: "5rem"
  },
  container: {
    maxWidth: "40rem",
    minWidth: "40rem",
    borderRadius: ".625rem .625rem .625rem .625rem",
    overflow: "hidden"
  }
}));

export default useStyles;
