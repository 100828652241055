import { RootState } from "src/store/rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const changeOwnership = createAsyncThunk("changeOwnership",
  (id: string, { dispatch, getState, rejectWithValue }) => {
    const store = getState() as RootState;

    const payload = {
      occupant: {
        legacy_id: null,
        first_name: store.changeOwnership.formOccupantInformation.firstName,
        middle_name: store.changeOwnership.formOccupantInformation.middleName,
        last_name: store.changeOwnership.formOccupantInformation.lastName,
        email: store.changeOwnership.formOccupantInformation.email,
        tax_id: store.changeOwnership.formOccupantInformation.taxExemptNumber,
        gate_code: store.changeOwnership.formOccupantInformation.gateCode.toString(),
        id_number: store.changeOwnership.formOccupantInformation.idNumber,
        id_state: store.changeOwnership.formOccupantInformation.state,
        military_status: store.changeOwnership.formOccupantInformation.military,
        date_of_birth: store.changeOwnership.formOccupantInformation.dob,
        is_tax_exempt: store.changeOwnership.formOccupantInformation.taxExempt,
        address: store.changeOwnership.formOccupantInformation.address,
        is_business: store.changeOwnership.formOccupantInformation.business,
        city: store.changeOwnership.formOccupantInformation.city,
        region: store.changeOwnership.formOccupantInformation.state,
        postal_code: store.changeOwnership.formOccupantInformation.postalCode,
        country: store.changeOwnership.formOccupantInformation.country,
        phone_primary: store.changeOwnership.formOccupantInformation.primaryPhone,
        tax_exempt_number: store.changeOwnership.formOccupantInformation.taxExemptNumber,
        business_name: store.changeOwnership.formOccupantInformation.businessName,
        address_two: store.changeOwnership.formOccupantInformation.addressTwo,
        phone_alternate: store.changeOwnership.formOccupantInformation.alternatePhone
      },
      contact: {
        first_name: store.changeOwnership.formEmergencyContactInfo.first_name,
        middle_name: store.changeOwnership.formEmergencyContactInfo.middle_name,
        last_name: store.changeOwnership.formEmergencyContactInfo.last_name,
        email: store.changeOwnership.formEmergencyContactInfo.email,
        relationship: store.changeOwnership.formEmergencyContactInfo.relationship,
        address: store.changeOwnership.formEmergencyContactInfo.address,
        address_two: store.changeOwnership.formEmergencyContactInfo.address_two,
        city: store.changeOwnership.formEmergencyContactInfo.city,
        region: store.changeOwnership.formEmergencyContactInfo.region,
        postal_code: store.changeOwnership.formEmergencyContactInfo.postal_code,
        country: "USA",
        phone_primary: store.changeOwnership.formEmergencyContactInfo.phone_primary,
        phone_alternate: null
      },
      billing: {
        first_name: store.changeOwnership.billingInformationForm.firstName ||
        store.changeOwnership.formOccupantInformation.firstName,
        middle_name: store.changeOwnership.billingInformationForm.middleName ||
         store.changeOwnership.formOccupantInformation.middleName,
        last_name: store.changeOwnership.billingInformationForm.lastName ||
         store.changeOwnership.formOccupantInformation.lastName,
        email: store.changeOwnership.formOccupantInformation.email,
        relationship: null,
        address: store.changeOwnership.billingInformationForm.addressOne ||
         store.changeOwnership.formOccupantInformation.address,
        address_two: store.changeOwnership.billingInformationForm.addressTwo ||
         store.changeOwnership.formOccupantInformation.addressTwo,
        city: store.changeOwnership.billingInformationForm.city || store.changeOwnership.formOccupantInformation.city,
        region: store.changeOwnership.billingInformationForm.state ||
         store.changeOwnership.formOccupantInformation.state,
        postal_code: store.changeOwnership.billingInformationForm.postalCode ||
         store.changeOwnership.formOccupantInformation.postalCode,
        country: store.changeOwnership.formOccupantInformation.country,
        phone_primary: store.changeOwnership.formOccupantInformation.primaryPhone,
        phone_alternate: store.changeOwnership.formOccupantInformation.alternatePhone
      },
      payment_type: store.payment.paymentType === 3 ? 1 : 2,
      payment: {
        card_number: store.changeOwnership.creditCardForm.ccNumber.replace(/\s/g, ""),
        card_holder_name: store.changeOwnership.creditCardForm.ccName,
        expiration_month: store.changeOwnership.creditCardForm.ccExpiration.substring(0, 2),
        expiration_year: store.changeOwnership.creditCardForm.ccExpiration
          ? "20" + store.changeOwnership.creditCardForm.ccExpiration.substring(2, 4)
          : "",
        card_processor: store.changeOwnership.creditCardForm.ccType,
        card_security_code: store.changeOwnership.creditCardForm.ccCVV,
        account_holder_name: store.changeOwnership.achForm.accountName,
        bank_name: store.changeOwnership.achForm.bankName,
        routing_number: store.changeOwnership.achForm.routingNumber,
        confirm_routing_number: store.changeOwnership.achForm.routingNumber,
        account_number: store.changeOwnership.achForm.accountNumber,
        confirm_accounting_number: store.changeOwnership.achForm.accountNumberConfirm,
        account_type: store.changeOwnership.achForm.accountType,
        is_preferred_payment: store.payment.paymentType === 3
          ? store.changeOwnership.creditCardForm.isPreferredPayment
          : store.changeOwnership.achForm.isPreferredPayment,
        is_enable_autobilling: store.payment.paymentType === 3
          ? store.changeOwnership.creditCardForm.enableAutoPay
          : store.changeOwnership.achForm.enableAutoPay
      }
    };

    return axios.post(`/api/v1/occupants/${id}/change-ownership`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: `Ownership successfully changed.`,
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        if (err.response.status === 422) {
          const errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        const errorMessage = "There was an error changing ownership";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
