import { Grid, InputLabel, TextField, Typography } from "@mui/material";
import React, { FC, SyntheticEvent, useEffect } from "react";
import {
  selectProductTypeIsInsurable,
  selectProductTypeRevenueClassId,
  setProductTypeInsuranceDocument,
  setProductTypeIsInsurable,
  setProductTypeRevenueClassId
} from "src/store/reducers/productTypeSliceNew/productTypeSliceNew";
import {
  selectRevenueClasses, selectRevenueClassesLoading
} from "src/store/reducers/revenueClassesSlice/revenueClassesSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import StepLayout from "src/layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "src/layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import assignRevenueClassValidation from "./assignRevenueClassValidation";
import { getAllRevenueClasses } from "src/store/thunks/revenueClass/getAll/getAllRevenueClasses";
import { inputError } from "src/utils/showInputError/showInputError";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { useFormik } from "formik";
import useStyles from "./AssignRevenueClass.styles";

const stepperId = "new-product-type-form";

interface AssignRevenueClassProps {
  handleClose: () => void
  setStep: React.Dispatch<React.SetStateAction<number>>
};

export const formatPercentages = (value: string) => {
  return `${value} %`;
};

const AssignRevenueClass:FC<AssignRevenueClassProps> = ({ setStep, handleClose }) => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();

  //this is NOT supposed to be an admin facility. We use Global here.
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const revenueClassesLoading = useAppSelector(selectRevenueClassesLoading);
  const revenueClasses = useAppSelector(selectRevenueClasses);
  const revenueClassId = useAppSelector(selectProductTypeRevenueClassId);
  const hasInsurance = useAppSelector(selectProductTypeIsInsurable);
  const formik = useFormik({
    initialValues: {
      revenue_class: revenueClassId || "",
      insurance: hasInsurance || ""
    },
    enableReinitialize: true,
    validationSchema: assignRevenueClassValidation,
    onSubmit: (values) => {
      if (!+values.insurance) {
        dispatch(setProductTypeInsuranceDocument(null));
      }

      dispatch(setProductTypeRevenueClassId(values.revenue_class));
      dispatch(setProductTypeIsInsurable(values.insurance));
      setStep(state => state + 1);
    }
  });

  const handleGoBack = () => {
    setStep((state) => state - 1);
  };

  const { touched, values, errors, handleChange } = formik;

  const selectedRevenueClass = revenueClasses.filter(item => item.id === +values.revenue_class)[0];
  useEffect(() => {
    if (selectedFacility) {
      dispatch(getAllRevenueClasses(selectedFacility.id));
    }
  }, []);

  return (
    <StepLayout
      padding={2}
      gridDirection={"row"}
      buttons={
        <StepperButtons
          showCancelButton
          onCancel={handleClose}
          stepperId={stepperId}
          forwardActionText={"Next"}
          backAction={handleGoBack}
      />
    }
    >
      <Grid
        component={"form"}
        container
        spacing={1}
        id={stepperId}
        onSubmit={(e: SyntheticEvent) => {
          e.preventDefault();
          e.stopPropagation();
          formik.handleSubmit();
        }}
        >
        <Grid item xs={12}>
          <Typography textAlign={"center"} variant={"h5"}>Assign Revenue Class</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography textAlign={"center"} >
            Assign a revenue class to product type
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor={"revenue_class"}>
            Revenue Class
          </InputLabel>
          <PMSSelect
            disabled={revenueClassesLoading}
            id={"revenue_class"}
            name={"revenue_class"}
            value={values.revenue_class}
            changeHandler={handleChange}
            error={inputError("revenue_class", touched, errors).error}
            helperText={inputError("revenue_class", touched, errors).helperText}
          >
            <option value={""}>
              {" - Revenue Class -"}
            </option>
            {revenueClasses.map(category =>
              <option key={category.id} value={category.id}>{category.name}</option>
            )}
          </PMSSelect>
        </Grid>
        {values.revenue_class
          ? <Grid
              container
              my={2}
              item
              xs={12}
            >
            {/* total row */}
            <Grid item xs={12}>
              <InputLabel htmlFor={"revenue_class"}>
                Total Tax Rate
              </InputLabel>
              <TextField
                disabled={true}
                value={formatPercentages(selectedRevenueClass.tax_total)}
                inputProps={{ style: { textAlign: "center" } }}
                InputProps={{ sx: { width: "50%", background: "#F7F7F7" } }}
                className={classes.disabledInput}
              />
            </Grid>
            {/* rate grid */}
            <Grid my={1} container item
              xs={12}>
              <Grid item xs={12}>
                <InputLabel htmlFor={"revenue_class"}>
                  Tax Rates
                </InputLabel>
              </Grid>
              <Grid
                item xs={6}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                >
                <InputLabel htmlFor={"revenue_class"}>
                  State
                </InputLabel>
                <TextField
                  disabled={true}
                  value={formatPercentages(selectedRevenueClass.tax_state)}
                  inputProps={{ style: { textAlign: "center" } }}
                  InputProps={{ sx: { width: "40%", background: "#F7F7F7" } }}
                  className={classes.disabledInput}
                />
              </Grid>
              <Grid item xs={6}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}>
                <InputLabel htmlFor={"revenue_class"}>
                  County
                </InputLabel>
                <TextField
                  disabled={true}
                  value={formatPercentages(selectedRevenueClass.tax_county)}
                  inputProps={{ style: { textAlign: "center" } }}
                  InputProps={{ sx: { width: "40%", background: "#F7F7F7" } }}
                  className={classes.disabledInput}
                />
              </Grid>
              <Grid item xs={6}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                mt={2}
                >
                <InputLabel htmlFor={"revenue_class"}>
                  City
                </InputLabel>
                <TextField
                  disabled={true}
                  value={formatPercentages(selectedRevenueClass.tax_city)}
                  inputProps={{ style: { textAlign: "center" } }}
                  InputProps={{ sx: { width: "40%", background: "#F7F7F7" } }}
                  className={classes.disabledInput}
                />
              </Grid>
              <Grid item xs={6}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <InputLabel htmlFor={"revenue_class"}>
                  Local
                </InputLabel>
                <TextField
                  disabled={true}
                  value={formatPercentages(selectedRevenueClass.tax_local)}
                  inputProps={{ style: { textAlign: "center" } }}
                  InputProps={{ sx: { width: "40%", background: "#F7F7F7" } }}
                  className={classes.disabledInput}
                  />
              </Grid>
            </Grid>
          </Grid>
          : null
        }
        <Grid item xs={12}>
          <InputLabel htmlFor={"product_category"}>
            Insurance
          </InputLabel>
          <PMSSelect
            id={"insurance"}
            name={"insurance"}
            value={values.insurance}
            changeHandler={handleChange}
            error={inputError("insurance", touched, errors).error}
            helperText={inputError("insurance", touched, errors).helperText}
          >
            <option value={""}>
              {" - Insurance -"}
            </option>
            <option value={1}>
              Yes
            </option>

            <option value={0}>
              No
            </option>
          </PMSSelect>
        </Grid>
      </Grid>
    </StepLayout>
  );
};

export default AssignRevenueClass;
