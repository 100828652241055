import moment from "moment";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  cardholderFirstName: Yup.string().required("Cardholder First Name is required"),
  cardholderLastName: Yup.string().required("Cardholder Last Name is required"),
  cvv: Yup.string().required("CVV is required").min(3, "CVV must be at least 3 digits"),
  creditCardNumber: Yup.string().required("Credit Card Number is required")
    .min(17, "Credit Card Number must be at least 15 digits"),
  expDate: Yup
    .string()
    .test({
      name: "is past date",
      test: function(value) {
        const valueAsDate = `${value?.slice(0, 2)}-01-${value?.slice(2, 4)}`;
        if (moment(valueAsDate).isBefore(moment(), "day")) {
          return this.createError({ message: "A valid date is required" });
        }

        return true;
      }
    })
    .required("Exp Date is required")
    .length(4, "Exp Date format should be MM/YY"),
  firstName: Yup.string().required("First Name is required"),
  middleInitial: Yup.string().max(1, "M.I. must be only one character"),
  lastName: Yup.string().required("Last Name is required"),
  address: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  zip: Yup.string().test({
    name: "length",
    test: function(value) {
      if (value && value.length < 5) {
        return this.createError({ message: "ZIP/Postal Code must be at least 5 characters" });
      }

      if (value && value.length > 5 && value.length < 10) {
        return this.createError({ message: "ZIP/Postal Code must be in the format xxxxx-xxxx" });
      }

      return true;
    }
  })
    .required("ZIP/Postal Code is required")
});

export default validationSchema;
