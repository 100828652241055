import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { axiosInstance as axios } from "../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getAppliedFees = createAsyncThunk(
  "getAppliedFees",
  ({ occupantId, ledgerId }: { occupantId: string; ledgerId: string }, { dispatch, getState, rejectWithValue }) => {
    return axios
      .get(`/api/v1/occupants/${occupantId}/ledgers/${ledgerId}/applied-fees`)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = `There was an error getting the apply fee cost`;

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  }
);
