import { makeStyles } from "../../makeStyles";

const useStyles = makeStyles({ label: "ItemManagementLayout" })((theme) => ({
  footerButtons: {
    justifyContent: "flex-end" // NECESSARY FOR PROPER ALIGNMENT IN SAFARI - Conor
  },
  containerWrap: {
    width: "100%"
  },
  divider: {
    marginTop: theme.spacing(2)
  }
}));

export default useStyles;
