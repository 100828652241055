import { Button, Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router";
import useStyles from "./BackButton.styles";

interface BackButtonProps {
  path?: string;
  pathName?: string;
}

const BackButton:FC<BackButtonProps> = ({ path = "/", pathName = "Dashboard" }) => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  return (

    <Grid my={2} item xs={12}>
      <Button
        className={classes.textColor}
        onClick={() => navigate(path)}
        startIcon={<ArrowBack />}
        variant={"text"}
      >
        <Typography variant={"body1"}>
          Back to {pathName}
        </Typography>
      </Button>
    </Grid>
  );
};

export default BackButton;
