import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DocumentTemplate } from "src/models/DocumentTemplate";
import { ProductTypeNewSliceState } from "./ProductTypeNewSliceState";
import { RootState } from "src/store/rootStore";
import { getProductType } from "src/store/thunks/productType/get/getProductType";

export const initialState: ProductTypeNewSliceState = {
  facility_id: null,
  name: "",
  is_insurable: false,
  revenue_class_id: null,
  product_category_id: null,
  insurance_document: null,
  lease_document: null,
  productTypeId: null
};

export const productTypeSliceNew = createSlice({
  name: "productTypeNew",
  initialState,
  reducers: {
    setProductTypeFacilityId: (state, action) => {
      state.facility_id = action.payload.facility_id;
    },
    setProductTypeName: (state, action) => {
      state.name = action.payload;
    },
    setProductTypeIsInsurable: (state, action) => {
      state.is_insurable = action.payload;
    },
    setProductTypeRevenueClassId: (state, action) => {
      state.revenue_class_id = action.payload;
    },
    setProductTypeProductCategoryId: (state, action) => {
      state.product_category_id = action.payload;
    },
    setProductTypeInsuranceDocument: (state, action: PayloadAction<DocumentTemplate | null>) => {
      state.insurance_document = action.payload;
    },
    setProductTypeLeaseDocument: (state, action: PayloadAction<DocumentTemplate | null>) => {
      state.lease_document = action.payload;
    },
    setProductTypeId: (state, action) => {
      state.productTypeId = action.payload;
    },
    resetProductTypeSliceNew: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductType.fulfilled, (state, action) => {
        state.productTypeId = action.payload.id;
      });
  }
});

export const selectProductTypeName = (state: RootState) => state.productTypeNew.name;
export const selectProductTypeFacilityId = (state: RootState) => state.productTypeNew.facility_id;
export const selectProductTypeIsInsurable = (state: RootState) => state.productTypeNew.is_insurable;
export const selectProductTypeRevenueClassId = (state: RootState) => state.productTypeNew.revenue_class_id;
export const selectProductTypeProductCategoryId = (state: RootState) => state.productTypeNew.product_category_id;
export const selectProductTypeInsuranceDocument = (state: RootState) => state.productTypeNew.insurance_document;
export const selectProductTypeLeaseDocument = (state: RootState) => state.productTypeNew.lease_document;
export const selectProductTypeIdNew = (state: RootState) => state.productTypeNew.productTypeId;

export const {
  setProductTypeName,
  setProductTypeIsInsurable,
  setProductTypeRevenueClassId,
  setProductTypeProductCategoryId,
  setProductTypeInsuranceDocument,
  setProductTypeLeaseDocument,
  resetProductTypeSliceNew
} = productTypeSliceNew.actions;

export default productTypeSliceNew.reducer;
