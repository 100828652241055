import { Button, Grid, InputLabel, Modal, Paper, TextField, Typography } from "@mui/material";
import React, { Dispatch, FC, FormEvent, SetStateAction, useEffect, useState } from "react";
import {
  selectFacilityAccessTableModal,
  selectFacilitySelectedAccessTable,
  selectFaciltiyRemoveAccessModal,
  setRemoveAccessTableModal
  , setSelectedAccessTable
}
  from "src/store/reducers/facilitySlice/facilitySlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { LoadingButton } from "@mui/lab";
import { inputError } from "src/utils/showInputError/showInputError";
import { useFormik } from "formik";
import useStyles from "./RemoveAccessModal.styles";
import { createNewTimeAccess } from "src/store/thunks/facilitySetting/timeAccess/createNewTimeAccess";
import { createNewKeypadAccess } from "src/store/thunks/facilitySetting/keypadAccess/createNewKeypadAccess";
import { getFacility } from "src/store/thunks/facility/get/getFacility";
import { deleteTimeAccess } from "src/store/thunks/facilitySetting/timeAccess/deleteTimeAccess";
import { deleteKeypadAccess } from "src/store/thunks/facilitySetting/keypadAccess/deleteKeypadAccess";

interface RemoveAccessModalProps {
  facilityId: number;
}

const RemoveAccessModal: FC<RemoveAccessModalProps> = ({ facilityId }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const removeAccessModal = useAppSelector(selectFaciltiyRemoveAccessModal);

  const handleSubmit = () => {
    const action = removeAccessModal.type === "timeAccess" ? deleteTimeAccess : deleteKeypadAccess;

    dispatch(action()).then((resp: any) => {
      if (resp.type.includes("fulfilled")) {
        dispatch(getFacility(facilityId));
        dispatch(setRemoveAccessTableModal({ open: false, type: "" }));
        dispatch(setSelectedAccessTable(null));
      }
    });
  };

  const handleClose = async() => {
    dispatch(setSelectedAccessTable(null));
    dispatch(setRemoveAccessTableModal({
      open: false,
      type: ""
    }));
  };

  return (
    <Modal className={classes.modal} onClose={handleClose} open={removeAccessModal.open}>
      <Grid container p={4} className={classes.root}
        component={Paper} elevation={0}>
        <Grid
          container
          spacing={1}
          component={"form"}
          onSubmit={(e: FormEvent) => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit();
          }}
        >
          <Grid item xs={12} textAlign={"center"}>
            <Typography variant={"h5"}>
              Confirm to Remove {removeAccessModal.type === "timeAccess" ? "Time" : "Keypad"} Access Option
            </Typography>

            <Typography textAlign={"center"}>
              Are you sure you want to remove this option?
            </Typography>
          </Grid>

          {/* buttons */}
          <Grid container spacing={2} justifyContent={"center"}
            item xs={12} mt={1}>
            <Grid item xs={2}>
              <Button onClick={handleClose} variant={"outlined"} color={"error"}
                fullWidth>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={2}>
              <LoadingButton
                onClick={() => handleSubmit()}
                className={classes.buttonBase}
                variant={"contained"}
                fullWidth
              >
                Remove
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default RemoveAccessModal;
