import * as Yup from "yup";
import { moneyValidator, percentageValidator } from "../../../utils/commonRegexes";

const validationSchema = Yup.object().shape({
  promotionName: Yup.string().required("Promotion Name is required"),
  promotionAmount: Yup.string().required("Amount is required")
    .when("promotionType", {
      is: 1,
      then: Yup.string().matches(moneyValidator, "Incorrect Format")
    })
    .when("promotionType", {
      is: 2,
      then: Yup.string().matches(percentageValidator, "Incorrect Format")
    }),
  promotionType: Yup.number().moreThan(0, "Type is required"),
  appliedPromotionMonth: Yup.number().moreThan(0, "Applied Month is required"),
  appliedPromotionFacilities: Yup.array().test({
    name: "appliedPromotionFacilities",
    message: "Minimun 1 Facility Required",
    test: (value) => {
      if (!value) {
        return false;
      }

      return value?.length > 0;
    }
  }),
  appliedPromotionDuration: Yup.number().required("Duration is required"),
  promotionStart: Yup.string().required("Promotion Start Date is required"),
  promotionEnd: Yup.string()
    .when("promotionIndefiniteEnd", {
      is: false,
      then: Yup.string().required("Promotion End Date is required")
    })
});

export default validationSchema;
