import moment from "moment";

const validDateTime = (value: string | Date | null | undefined, strict?: boolean) => {
  const formatString = "MM/DD/YY hh:mm A";

  const val = moment(value).format(formatString);

  return moment(val, formatString).isValid();
};

export default validDateTime;
