import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "AppModal" })((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
    width: "35rem",
    height: "10rem",
    boxShadow: "0 .188rem .375rem rgba(0,0,0, .2)",
    borderRadius: "1rem"
  },
  button: {
    borderColor: theme.palette.primary.contrastText,
    color: theme.palette.common.black,
    minWidth: theme.spacing(15),
    minHeight: theme.spacing(7),
    textTransform: "capitalize",
    fontWeight: "normal"
  },
  cancelButton: {
    minWidth: theme.spacing(15),
    minHeight: theme.spacing(7),
    borderColor: theme.palette.error.contrastText,
    color: theme.palette.common.black,
    textTransform: "none",
    fontWeight: "normal"
  }
}));

export default useStyles;
