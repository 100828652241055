import { Button, Collapse, Divider, Grid, InputLabel, TextField, Typography } from "@mui/material";
import React, { FormEvent, useState } from "react";
import { selectNotes, setNotes } from "src/store/reducers/notesSlice/notesSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import Add from "@mui/icons-material/Add";
import AppNote from "src/components/note/Note";
import { LoadingButton } from "@mui/lab";
import { Note } from "src/models/Note";
import NoteType from "src/enums/NoteType";
import { createNote } from "src/store/thunks/note/createNote/createNote";
import editDealNoteInformationValidation from "./EditDealNoteInformationValidation";
import { inputError } from "src/utils/showInputError/showInputError";
import { selectDeal } from "src/store/reducers/dealSlice/dealSlice";
import { useFormik } from "formik";
import useStyles from "./EditDealNoteInformation.styles";

const formId = "edit-deal-edit-notes-information-form";

const EditDealNoteInformation = () => {
  const [isAddNewNoteOpen, setIsAddNewNoteOpen] = useState(false);
  const notes = useAppSelector(selectNotes);
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const deal = useAppSelector(selectDeal);
  const formik = useFormik({
    initialValues: {
      description: ""
    },
    validationSchema: editDealNoteInformationValidation,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      dispatch(createNote({ id: deal?.id!, type: NoteType.deal, description: values.description }));
      formik.resetForm();
      setIsAddNewNoteOpen(false);
    }
  });

  const { touched, errors, values, handleChange, setFieldValue } = formik;

  const handleCancel = () => {
    setIsAddNewNoteOpen(false);
    setFieldValue("description", "");
  };

  return (
    <Grid
      component={"form"}
      id={formId}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        formik.handleSubmit();
      }}>
      <Grid container justifyContent={"space-between"} mb={1}>
        <Typography variant={"h6"}>
          Deal Notes
        </Typography>
        <Button
          className={classes.button}
          variant={"contained"}
          startIcon={<Add />}
          onClick={() => setIsAddNewNoteOpen(true)}>
          Add Note
        </Button>
      </Grid>
      <Divider />
      <Collapse in={isAddNewNoteOpen}>
        <Grid container item>
          <Grid item xs={12} mt={1}>
            <InputLabel htmlFor={"note-description"}>New note description</InputLabel>
            <TextField
              id={"note-description"}
              multiline
              rows={4}
              fullWidth
              name={"description"}
              onChange={handleChange}
              inputProps={{
                maxLength: 500
              }}
              error={inputError("description", touched, errors).error}
              helperText={inputError("description", touched, errors).helperText}
              value={values.description} />
          </Grid>
          <Grid
            item
            container
            xs={12}
            mt={1}
            justifyContent={"flex-end"}>
            <Button
              color={"error"}
              variant={"text"}
              onClick={() => handleCancel()}>
              Cancel
            </Button>
            <LoadingButton
              form={formId}
              variant={"contained"}
              type={"submit"}
              className={classes.button}
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </Collapse>

      <Grid
        container
        rowGap={2}
        mt={2}>
        {notes?.map((note, index) => <AppNote key={index} {...note} />)}
      </Grid>

    </Grid>
  );
};

export default EditDealNoteInformation;
