import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "StatusColumn" })((theme) => ({
  root: {
    border: "1.5px solid",
    borderRadius: "0.25rem",
    fontStyle: "italic",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  current: {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main
  },
  due: {
    borderColor: theme.palette.warning.main,
    color: theme.palette.warning.main
  },
  pastDue: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main
  }
}));

export default useStyles;
