import { makeStyles } from "../../../../../makeStyles";

const useStyles = makeStyles({ label: "OccupantContact" })((theme) => ({
  changeOfOwnershipButton: {
    color: theme.palette.common.white,
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    marginLeft: "1rem"
  },
  icon: {
    marginRight: "0.5rem"
  },
  iconRotated: {
    marginRight: "0.5rem",
    transform: "rotate(120deg)"
  }
}));

export default useStyles;
