const prepaidMarks = [
  {
    value: 0,
    label: "0"
  },
  {
    value: 1,
    label: null
  },
  {
    value: 2,
    label: null
  },
  {
    value: 3,
    label: "3"
  },
  {
    value: 4,
    label: null
  },
  {
    value: 5,
    label: null
  },
  {
    value: 6,
    label: "6"
  },
  {
    value: 7,
    label: null
  },
  {
    value: 8,
    label: null
  },
  {
    value: 9,
    label: "9"
  },
  {
    value: 10,
    label: null
  },
  {
    value: 11,
    label: null
  },
  {
    value: 12,
    label: "12"
  }
];
export default prepaidMarks;
