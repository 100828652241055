import { OccupantBillingInformation } from "./occupantBillingInformationState";
import { RootState } from "src/store/rootStore";
import { createBillingContact } from "src/store/thunks/billingContact/create/createBillingContact";
import { createSlice } from "@reduxjs/toolkit";
import { getOccupant } from "src/store/thunks/occupant/getOne/getOccupant";
import { updateBillingContact } from "src/store/thunks/billingContact/update/updateBillingContact";

export const initialState: OccupantBillingInformation = {
  billingAddressOption: 1,
  existingBillingAddress: undefined
};

export const occupantInformationSlice = createSlice({
  name: "occupantBillingInformation",
  initialState,
  reducers: {
    setOccuppantBillingAddressOption: (state, action) => {
      state.billingAddressOption = action.payload;
    },
    resetOccupantExistingBillingAddress: (state) => {
      state.existingBillingAddress = initialState.existingBillingAddress;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOccupant.fulfilled, (state, action) => {
        if (!action.payload.billing_contacts.length) {
          state.existingBillingAddress = initialState.existingBillingAddress;
          return;
        }

        state.existingBillingAddress = {
          id: action.payload.billing_contacts[0].id,
          firstName: action.payload.billing_contacts[0].first_name,
          middleName: action.payload.billing_contacts[0].middle_name,
          lastName: action.payload.billing_contacts[0].last_name,
          addressOne: action.payload.billing_contacts[0].address,
          addressTwo: action.payload.billing_contacts[0].address_two,
          city: action.payload.billing_contacts[0].city,
          state: action.payload.billing_contacts[0].region,
          postalCode: action.payload.billing_contacts[0].postal_code
        };
      })
      .addCase(createBillingContact.fulfilled, (state, action) => {
        state.existingBillingAddress = {
          id: action.payload.id,
          firstName: action.payload.first_name,
          middleName: action.payload.middle_name,
          lastName: action.payload.last_name,
          addressOne: action.payload.address,
          addressTwo: action.payload.address_two,
          city: action.payload.city,
          state: action.payload.region,
          postalCode: action.payload.postal_code
        };
      })
      .addCase(updateBillingContact.fulfilled, (state, action) => {
        state.existingBillingAddress = {
          id: action.payload.id,
          firstName: action.payload.first_name,
          middleName: action.payload.middle_name,
          lastName: action.payload.last_name,
          addressOne: action.payload.address,
          addressTwo: action.payload.address_two,
          city: action.payload.city,
          state: action.payload.region,
          postalCode: action.payload.postal_code
        };
      });
  }
});

export const selectExistingOccupantBillingAddressContact = (state:RootState) =>
  state.occupantBillingInformation.existingBillingAddress;

export const selectOccupantBillingAddressOption = (state: RootState) =>
  state.occupantBillingInformation.billingAddressOption;

export const {
  setOccuppantBillingAddressOption,
  resetOccupantExistingBillingAddress
} = occupantInformationSlice.actions;

export default occupantInformationSlice.reducer;
