import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { APIFilter } from "src/models/responses/APIFilter";
import { APIListResponse } from "../../../models/responses/APIListResponse";
import { Occupant } from "../../../models/Occupant";
import { OccupantsSliceState } from "./OccupantsSliceState";
import { RootState } from "src/store/rootStore";
import { getAllOccupants } from "../../thunks/occupant/getAll/getAllOccupants";
import { searchOccupants } from "src/store/thunks/occupant/search/searchOccupants";

export const initialState: OccupantsSliceState = {
  occupantsLoading: false,
  occupantsError: "",
  occupants: [],
  occupantsSearchValue: "",
  occupantsFilter: ""
};

export const occupantsSlice = createSlice({
  name: "occupants",
  initialState,
  reducers: {
    resetOccupantsSlice: (state) => {
      Object.assign(state, initialState);
    },
    setOccupantsSearchValue: (state, action:PayloadAction<string>) => {
      state.occupantsSearchValue = action.payload as string;
    },
    setOccupantsFilter: (state, action: PayloadAction<APIFilter | "">) => {
      state.occupantsFilter = action.payload;
      sessionStorage.setItem("dealsFilter", JSON.stringify(action.payload));
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllOccupants.pending, (state) => {
        state.occupantsLoading = true;
      })
      .addCase(getAllOccupants.fulfilled, (state, action: PayloadAction<APIListResponse<Occupant[]>>) => {
        state.occupants = action.payload.data;
        state.occupantsLoading = false;
      })
      .addCase(getAllOccupants.rejected, (state, action) => {
        state.occupantsLoading = false;
        state.occupantsError = action.payload as string;
      })
      .addCase(searchOccupants.pending, (state) => {
        state.occupantsLoading = true;
      })
      .addCase(searchOccupants.fulfilled, (state, action: PayloadAction<APIListResponse<Occupant[]>>) => {
        state.occupants = action.payload.data;
        state.occupantsLoading = false;
      })
      .addCase(searchOccupants.rejected, (state, action) => {
        state.occupantsLoading = false;
        state.occupantsError = action.payload as string;
      });
  }
});

export const selectOccupants = (state: RootState) => state.occupants.occupants;
export const selectOccupantsLoading = (state: RootState) => state.occupants.occupantsLoading;
export const selectOccupantsSearchValue = (state: RootState) => state.occupants.occupantsSearchValue;
export const selectOccupantsFilter = (state: RootState) => state.occupants.occupantsFilter;

export const {
  resetOccupantsSlice,
  setOccupantsSearchValue,
  setOccupantsFilter
} = occupantsSlice.actions;

export default occupantsSlice.reducer;
