import React, { FC, useEffect } from "react";
import RptAPI, { RptSvrInfo } from "src/utils/reportApiHelper/RptApi";
import reports from "../../reports";
import { selectCurrentReport } from "src/store/reducers/reportParamsSlice/reportParamsSlice";
import { useAppSelector } from "src/store/hooks";

interface ReportParams {
  operator_id?: number;
  facility_id?: number[] | string;
  start_date?: string;
  end_date?: string;
  period?: string;
}

interface LogiReportStudioProps {
  id: string;
  className?: string;
  svrInfo: RptSvrInfo;
  params: ReportParams;
};

const LogiReportStudio: FC<LogiReportStudioProps> = ({ id, className, svrInfo, params }) => {
  const currentReport = useAppSelector(selectCurrentReport);
  const selectedReport = reports.find((report) => report.name === currentReport);
  const rptDef = { name: selectedReport?.path };
  const catDef = { name: selectedReport?.catalog };

  useEffect(() => {
    if (!id || !svrInfo) return;
    RptAPI.init(svrInfo, id);
  }, []);

  useEffect(() => {
    if (!rptDef || !catDef || !params) {
      return;
    }

    RptAPI.runReport(rptDef, catDef, params);
  }, []);

  return (
    <div id={id} style={{ height: "100%", position: "relative" }} className={className}>
      {/* @ts-ignore */}
      <style jsx>{`
      #${id} iframe {
        position: absolute;
        border: none;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
      `}
      </style>
    </div>
  );
};

export default LogiReportStudio;
