import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { axiosInstance as axios } from "../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "src/utils/format422/format422";
import { getSearchParams } from "src/utils/getSearchParams/getSearchParams";
import { RootState } from "src/store/rootStore";

export const getAllPaginatedOccupantDocuments = createAsyncThunk(
  "getAllPaginatedOccupantDocuments",
  (
    { occupantId, unitId, searchValue }: { occupantId: number, unitId?: number, searchValue?: string },
    { dispatch, getState, rejectWithValue }
  ) => {
    const store = getState() as RootState;

    const params: any = {
      params: {
        ...getSearchParams(store, true),
        with: ["unit"],
        ...(unitId && unitId > 0 && { "filter[unit_id]": unitId }),
        ...(searchValue && { "filter[name]": searchValue })
      }
    };

    return axios.get(`/api/v1/occupants/${occupantId}/uploaded-documents`, params)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = "There was an error fetching occupant documents";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
