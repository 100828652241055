import { useAppDispatch, useAppSelector } from "src/store/hooks";
import EmptyTable from "src/components/ui/PMSTable/EmptyTable/EmptyTable";
import ManageTasksTableColumns from "./ManageTasksTableColumns/ManageTasksTableColumns";
import PMSTable from "../../../components/ui/PMSTable/PMSTable";
import React from "react";
import { getTasks } from "src/store/thunks/task/getAll/getTasks";
import { selectTasks } from "src/store/reducers/tasksSlice/tasksSlice";

const emptyTable = () => (
  <EmptyTable
    data-testId={"tasks-zero-state"}
    collectionNotFound={"tasks"}
    value={"task"}
    addLink={"/task-management/create"}
  />
);

const TaskTable = () => {
  const dispatch = useAppDispatch();
  const tasks = useAppSelector(selectTasks);

  const handlePaginationUpdate = () => {
    dispatch(getTasks({ getNotifications: false }));
  };

  return (
    <PMSTable
      onPaginationUpdate={handlePaginationUpdate}
      columns={ManageTasksTableColumns()}
      emptyTableDisplay={emptyTable}
      rows={tasks}
    />
  );
};

export default TaskTable;
