import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { AutomaticPaymentsSlice } from "./automaticPaymentsSlice.d";
import { CreditCardProvider } from "src/models/CreditCardProvider";
import ESignDocument from "src/models/ESignDocument";
import { PaymentMethod as PaymentMethodEnum } from "src/enums/PaymentMethod";
import { RootState } from "../../rootStore";
import {
  createESignDocumentForAutoPay
} from "src/store/thunks/occupant/createESignDocumentForAutoPay/createESignDocumentForAutoPay";
import creditCard from "credit-card-type";
import { disabledAutoPay } from "src/store/thunks/occupant/disabledAutoPay/disabledAutoPay";
import { enrollAutoPay } from "src/store/thunks/occupant/enrollAutoPay/enrollAutoPay";
import { reRunAutoPay } from "src/store/thunks/ledger/autoPay/reRunAutoPay";

const isProcessDone = (state: AutomaticPaymentsSlice) => {
  if (
    state.automaticPaymentsModalOpened === false &&
    state.autobillingConfirmationModalOpened === false &&
    state.emailAutoBillModalOpened === false &&
    state.awaitingSignatureSubmissionModalOpened === false &&
    state.signatureAcceptedModalOpened === false &&
    state.disabledAutoBillModal.opened === false
  ) {
    return true;
  }
  return false;
};

export const initialState: AutomaticPaymentsSlice = {
  automaticPaymentsModalOpened: false,
  confirmAutomaticPaymentsModalOpen: false,
  autobillingConfirmationModalOpened: false,
  enrollAutoPayLoading: false,
  emailAutoBillModalOpened: false,
  awaitingSignatureSubmissionModalOpened: false,
  signatureAcceptedModalOpened: false,
  automaticPaymentMethod: 0,
  selectedPaymentInstrument: 0,
  currentESignDocument: null,
  eSignDocumentCreationLoading: false,
  selectedPaymentType: PaymentMethodEnum.creditCard,
  automaticPaymentsCreditCardForm: {
    name: "",
    number: "",
    numberMasked: "",
    cvv: "",
    cvvMasked: "",
    expiration: "",
    type: "",
    savePaymentMethod: false,
    enableAutoPay: false,
    showCCNumber: false,
    isPreferredPayment: false
  },
  disabledAutoBillModal: {
    submitting: false,
    opened: false
  },
  reRunAutoBillModal: {
    open: false,
    loading: false
  }
};

export const automaticPaymentsSlice = createSlice({
  name: "automaticPaymentsSlice",
  initialState,
  reducers: {
    resetAutomaticPaymentsSlice: (state) => {
      Object.assign(state, initialState);
    },
    setAutomaticPaymentsModalOpened: (state, action: PayloadAction<boolean>) => {
      state.automaticPaymentsModalOpened = action.payload;
      // if (isProcessDone(state)) {
      //   Object.assign(state, initialState);
      // }
    },
    setConfirmAutomaticPaymentsModalOpened: (state, action: PayloadAction<boolean>) => {
      state.confirmAutomaticPaymentsModalOpen = action.payload;
      // if (isProcessDone(state)) {
      //   Object.assign(state, initialState);
      // }
    },
    setAutobillingConfirmationModalOpened: (state, action: PayloadAction<boolean>) => {
      state.autobillingConfirmationModalOpened = action.payload;
      // if (isProcessDone(state)) {
      //   Object.assign(state, initialState);
      // }
    },
    setEmailAutoBillModalOpened: (state, action: PayloadAction<boolean>) => {
      state.emailAutoBillModalOpened = action.payload;
      // if (isProcessDone(state)) {
      //   Object.assign(state, initialState);
      // }
    },
    setAwaitingSignatureSubmissionModalOpened: (state, action: PayloadAction<boolean>) => {
      state.awaitingSignatureSubmissionModalOpened = action.payload;
      // if (isProcessDone(state)) {
      //   Object.assign(state, initialState);
      // }
    },
    setSignatureAcceptedModalOpened: (state, action: PayloadAction<boolean>) => {
      state.signatureAcceptedModalOpened = action.payload;
      // if (isProcessDone(state)) {
      //   Object.assign(state, initialState);
      // }
    },
    setDisableAutoBillModalOpened: (state, action: PayloadAction<boolean>) => {
      state.disabledAutoBillModal.opened = action.payload;
      // if (isProcessDone(state)) {
      //   Object.assign(state, initialState);
      // }
    },
    setAutomaticPaymentMethod: (state, action: PayloadAction<number>) => {
      state.automaticPaymentMethod = action.payload;
    },
    setSelectedPaymentInstrument: (state, action: PayloadAction<number>) => {
      state.selectedPaymentInstrument = action.payload;
    },
    setCurrentESignDocument: (state, action: PayloadAction<ESignDocument | null>) => {
      state.currentESignDocument = action.payload;
    },
    setESignDocumentCreationLoading: (state, action: PayloadAction<boolean>) => {
      state.eSignDocumentCreationLoading = action.payload;
    },
    setSelectedPaymentType: (state, action: PayloadAction<number>) => {
      state.selectedPaymentType = action.payload;
    },
    setAutomaticPaymentsCreditCardForm: (state, action) => {
      const cardData = creditCard(action.payload.number);
      state.automaticPaymentsCreditCardForm = {
        ...state.automaticPaymentsCreditCardForm,
        number: action.payload.number,
        name: action.payload.name,
        expiration: action.payload.expiration,
        cvv: action.payload.cvv,
        numberMasked: action.payload.numberMasked,
        cvvMasked: action.payload.masked,
        enableAutoPay: false,
        savePaymentMethod: false,
        showCCNumber: false,
        isPreferredPayment: false,
        type: cardData.length && state.automaticPaymentsCreditCardForm ? (cardData[0].type as CreditCardProvider) : ""
      };
    },
    setReRunAutoBillModalOpen: (state, action: PayloadAction<boolean>) => {
      state.reRunAutoBillModal.open = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createESignDocumentForAutoPay.rejected, (state, action) => {
        state.eSignDocumentCreationLoading = false;
      })
      .addCase(disabledAutoPay.pending, (state, action) => {
        state.disabledAutoBillModal.submitting = true;
      })
      .addCase(disabledAutoPay.fulfilled, (state, action) => {
        state.disabledAutoBillModal.submitting = false;
      })
      .addCase(enrollAutoPay.pending, (state, action) => {
        state.enrollAutoPayLoading = true;
      })
      .addCase(enrollAutoPay.rejected, (state, action) => {
        state.enrollAutoPayLoading = false;
      })
      .addCase(enrollAutoPay.fulfilled, (state, action) => {
        state.enrollAutoPayLoading = false;
      })
      .addCase(reRunAutoPay.pending, (state, action) => {
        state.reRunAutoBillModal.loading = true;
      })
      .addCase(reRunAutoPay.rejected, (state, action) => {
        state.reRunAutoBillModal.loading = false;
      })
      .addCase(reRunAutoPay.fulfilled, (state, action) => {
        state.reRunAutoBillModal.loading = false;
      });
  }
});

export const selectAutomaticPaymentSlice = (state: RootState) => state.automaticPayments;

export const selectEnrollAutopayLoading = (state: RootState) => state.automaticPayments.enrollAutoPayLoading;
export const selectConfirmAutomaticPaymentModalOpen = (state: RootState) =>
  state.automaticPayments.confirmAutomaticPaymentsModalOpen;
export const selectAutomaticPaymentsCCName = (state: RootState) =>
  state.automaticPayments.automaticPaymentsCreditCardForm?.name;
export const selectAutomaticPaymentsCCNumber = (state: RootState) =>
  state.automaticPayments.automaticPaymentsCreditCardForm?.number;
export const selectAutomaticPaymentsCCNumberMasked = (state: RootState) =>
  state.automaticPayments.automaticPaymentsCreditCardForm?.numberMasked;
export const selectAutomaticPaymentsCCCVV = (state: RootState) =>
  state.automaticPayments.automaticPaymentsCreditCardForm?.cvv;
export const selectAutomaticPaymentsCCCVVMasked = (state: RootState) =>
  state.automaticPayments.automaticPaymentsCreditCardForm?.cvvMasked;
export const selectAutomaticPaymentsCCExpiration = (state: RootState) =>
  state.automaticPayments.automaticPaymentsCreditCardForm?.expiration;
export const selectAutomaticPaymentsCCType = (state: RootState) =>
  state.automaticPayments.automaticPaymentsCreditCardForm?.type;
export const selectAutomaticPaymentsEnableAutoPay = (state: RootState) =>
  state.automaticPayments.automaticPaymentsCreditCardForm?.enableAutoPay;
export const selectAutomaticPaymentsSavePaymentMethod = (state: RootState) =>
  state.automaticPayments.automaticPaymentsCreditCardForm?.savePaymentMethod;
export const selectAutomaticPaymentsIsPreferredPayment = (state: RootState) =>
  state.automaticPayments.automaticPaymentsCreditCardForm?.isPreferredPayment;
export const selectAutomaticPaymentsautomaticPaymentsCreditCardForm = (state: RootState) =>
  state.automaticPayments.automaticPaymentsCreditCardForm;
export const selectReRunAutoBillModalOpen = (state: RootState) => state.automaticPayments.reRunAutoBillModal.open;
export const selectReRunAutoBillModalLoading = (state: RootState) => state.automaticPayments.reRunAutoBillModal.loading;

export const {
  resetAutomaticPaymentsSlice,
  setAutomaticPaymentsModalOpened,
  setConfirmAutomaticPaymentsModalOpened,
  setAutobillingConfirmationModalOpened,
  setAwaitingSignatureSubmissionModalOpened,
  setSignatureAcceptedModalOpened,
  setEmailAutoBillModalOpened,
  setDisableAutoBillModalOpened,
  setCurrentESignDocument,
  setAutomaticPaymentMethod,
  setSelectedPaymentInstrument,
  setESignDocumentCreationLoading,
  setAutomaticPaymentsCreditCardForm,
  setSelectedPaymentType,
  setReRunAutoBillModalOpen
} = automaticPaymentsSlice.actions;

export default automaticPaymentsSlice.reducer;
