import { makeStyles } from "../../makeStyles";

const useStyles = makeStyles({ label: "Form" })((theme) => ({
  cancelButton: {
    textTransform: "capitalize",
    marginLeft: theme.spacing(2),
    color: theme.palette.error.contrastText
  },
  submitButtonContained: {
    textTransform: "capitalize",
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText,
    width: "10rem"
  },
  submitButtonOutlined: {
    color: theme.palette.primary.contrastText,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`
  },
  boldLabel: {
    fontWeight: "bold",
    color: theme.palette.primary.main
  }
}));

export default useStyles;
