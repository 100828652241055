import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "MoveInActionButtons" })((theme) => ({
  baseButton: {
    textTransform: "capitalize"
  },
  filledButton: {
    background: theme.palette.primary.contrastText,
    color: theme.palette.common.white,
    margin: `${theme.spacing(1)} 0`
  },
  textButton: {
    color: theme.palette.primary.contrastText
  }
}));

export default useStyles;
