import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RevenueClass } from "../../../models/RevenueClass";
import { RevenueClassSliceState } from "./RevenueClassSliceState";
import { RootState } from "../../rootStore";
import { createRevenueClass } from "../../thunks/revenueClass/create/createRevenueClass";

export const initialState: RevenueClassSliceState = {
  revenueClass: JSON.parse(sessionStorage.getItem("revenueClass") as string) ?? null,
  revenueClassLoading: false,
  revenueClassError: ""
};

export const revenueClassSlice = createSlice({
  name: "revenueClass",
  initialState,
  reducers: {
    resetRevenueClassSlice: (state) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("revenueClass");
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRevenueClass.pending, (state) => {
        state.revenueClassLoading = true;
      })
      .addCase(createRevenueClass.fulfilled, (state, action: PayloadAction<RevenueClass>) => {
        state.revenueClassLoading = false;
        state.revenueClass = action.payload;
        sessionStorage.setItem("revenueClass", JSON.stringify(state.revenueClass));
      })
      .addCase(createRevenueClass.rejected, (state, action) => {
        state.revenueClassLoading = false;
        state.revenueClassError = action.payload as string;
      });
  }
});

export const selectRevenueClass = (state: RootState) => state.revenueClass.revenueClass;
export const selectRevenueClassLoading = (state: RootState) => state.revenueClass.revenueClassLoading;

export const {
  resetRevenueClassSlice
} = revenueClassSlice.actions;

export default revenueClassSlice.reducer;
