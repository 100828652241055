import { makeStyles } from "../../makeStyles";

const useStyles = makeStyles({ label: "IssueCredit" })((theme) => ({
  table: {
    border: "none",
    backgroundColor: "#EFF4FB",
    marginBottom: theme.spacing(2)
  },
  baseButton: {
    textTransform: "capitalize",
    marginTop: theme.spacing(2)
  },
  textButton: {
    color: theme.palette.primary.contrastText
  },
  removeButton: {
    textTransform: "capitalize",
    color: theme.palette.error.main
  },
  addButton: {
    textTransform: "capitalize",
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText,
    width: "10rem"
  },
  bodyHeaderText: {
    color: theme.palette.primary.contrastText,
    fontWeight: "bold",
    textTransform: "uppercase",
    whiteSpace: "nowrap"
  },

  bodyHeaderDivider: {
    background: theme.palette.primary.contrastText,
    width: "100%"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "80%"
  },
  ledgerModal: {
    margin: "auto"
  },
  buttonBase: {
    textTransform: "uppercase",
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main
    }
  },
  root: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
    maxWidth: "65%",
    minHeight: "10rem",
    borderRadius: "1rem"
  }
}));

export default useStyles;
