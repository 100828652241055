import { Divider, Grid, IconButton, Modal, Paper, Typography, useMediaQuery } from "@mui/material";
import React, { FC, useState } from "react";
import {
  selectFollowUpModalOpen,
  setFollowUpModalOpen
} from "src/store/reducers/collectionsSlice/collectionsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Close } from "@mui/icons-material";
import ContactDetails from "./components/ContactDetails/ContactDetails";
import ContactNotes from "./components/ContactNotes/ContactNotes";
import { TabComponentListItem } from "src/models/TabComponentListItem";
import TabView from "src/layouts/TabView/TabView";
import clsx from "clsx";
import { resetNotesSlice } from "src/store/reducers/notesSlice/notesSlice";
import useStyles from "./FollowUpModal.styles";

interface FollowUpModalProps{}

const FollowUpModal:FC<FollowUpModalProps> = () => {
  const { classes } = useStyles();
  const followUpModalOpen = useAppSelector(selectFollowUpModalOpen);
  const dispatch = useAppDispatch();
  const xs = useMediaQuery("(min-width: 1300px)");
  const [step, setStep] = useState(0);

  const handleCloseFollowup = () => {
    dispatch(setFollowUpModalOpen(false));
    dispatch(resetNotesSlice());
    setStep(0);
  };

  const componentList: TabComponentListItem[] = [
    {
      name: "Contact Details",
      component: <ContactDetails/>
    },
    {
      name: "Contact Notes",
      component: <ContactNotes/>
    }
  ];

  return (
    <Modal
      className={xs ? clsx(classes.root, classes.xs) : clsx(classes.root, classes.lg)}
      open={followUpModalOpen}
      onClose={() => handleCloseFollowup()}
      >
      <Grid
        className={classes.inner}
        spacing={2}
        component={Paper}
        container
        p={2}
      >
        <Grid item xs={11}>
          <Typography variant={"h5"}>
            Follow Up
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={() => handleCloseFollowup()}>
            <Close/>
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider/>
        </Grid>
        <Grid item xs={12}>
          <TabView
            components={componentList}
            step={step}
            loading={false}
            showPaper={false}
            innerPadding={false}
            onChangeAction={(step) => setStep(step)}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default FollowUpModal;
