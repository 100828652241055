import api from "../api";

export interface CreateSpeedeRentPayload {
  facility_id: number
  occupant_id?: number
  unit_id: number
  deal_id: number | null
  promotion_id?: number | null
  lease_start_date: string
}

const speedeRentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createSpeedeRent: builder.mutation<void, CreateSpeedeRentPayload>({
      query: (payload) => ({
        url: `/api/v1/facilities/${payload.facility_id}/speederent`,
        method: "POST",
        body: payload
      })
    })

  })
});

export const { useCreateSpeedeRentMutation } = speedeRentApi;
