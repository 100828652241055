import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import React, { FC } from "react";
import { Edit } from "@mui/icons-material";
import { Merchandise } from "src/models/Merchandise";
import { MerchandiseCategory } from "../../../../models/MerchandiseCategory";
import { NavLink } from "react-router-dom";
import OpenDeleteModalButton from "./OpenDeleteModalButton/OpenDeleteModalButton";
import { RevenueClass } from "../../../../models/RevenueClass";

interface MerchActionButtonsProps {
  row: Merchandise
}

const MerchActionButtons: FC<MerchActionButtonsProps> = ({ row }) => {
  return (
    <Grid
      container
      direction={"row"}
      alignItems={"center"}
      justifyContent={"start"}
    >
      <Tooltip title={"Edit/View Merchandise"}>
        <IconButton to={`/merchandise/${row.id}/edit`} component={NavLink} size={"small"}>
          <Edit />
        </IconButton>
      </Tooltip>
      <OpenDeleteModalButton merchandise={row} />
    </Grid>
  );
};

export const MerchandiseTableColumns = (
  merchandiseCategories: MerchandiseCategory[],
  revenueClasses: RevenueClass[]
): GridColDef[] => {
  const showMerchandiseCategory = (id: number) => merchandiseCategories.length
    ? merchandiseCategories.find(merchCategory => merchCategory.id === id)?.name
    : "N/A";
  const showRevenueClass = (id: number) => revenueClasses.length
    ? revenueClasses.find(revenueClass => revenueClass.id === id)?.name
    : "N/A";

  return [
    {
      field: "id",
      renderHeader: () => <Tooltip title={"Merchandise ID"}><Typography>Merch ID</Typography></Tooltip>,
      align: "center",
      headerAlign: "center",
      minWidth: 165,
      flex: 1
    },
    {
      field: "name",
      renderHeader: () => <Tooltip title={"Merchandise Name"}><Typography>Merch Name</Typography></Tooltip>,
      headerAlign: "center",
      align: "center",
      minWidth: 180,
      flex: 1
    },
    {
      field: "revenue_class_id",
      renderHeader: () => <Tooltip title={"Revenue Class"}><Typography>Rev Class</Typography></Tooltip>,
      headerAlign: "center",
      align: "center",
      minWidth: 220,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => showRevenueClass(params.row.revenue_class_id)
    },
    {
      field: "price",
      renderHeader: () => <Tooltip title={"Price"}><Typography>Price</Typography></Tooltip>,
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `$${parseFloat(params.row.price).toFixed(2)}`
    },
    {
      field: "merchandise_category_id",
      renderHeader: () => <Tooltip title={"Merchandise Category"}><Typography>Merch Ctgy</Typography></Tooltip>,
      headerName: "Merchandise Category",
      align: "center",
      minWidth: 180,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => showMerchandiseCategory(params.row.merchandise_category_id)
    },
    {
      field: "actions",
      renderHeader: () => <Tooltip title={"Actions"}><Typography>Actions</Typography></Tooltip>,
      sortable: false,
      minWidth: 150,
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Grid
          container
          direction={"row"}
          alignItems={"center"}
          justifyContent={"start"}
        >
          <Tooltip title={"Edit/View Merchandise"}>
            <IconButton size={"small"} to={`/merchandise/${row.id}/edit`} component={NavLink}>
              <Edit />
            </IconButton>
          </Tooltip>
          {!row.inventories.length || row.inventories.every((inventory: any) => inventory.remaining_quantity === 0)
            ? <OpenDeleteModalButton merchandise={row} />
            : null}
        </Grid>
      )
    }
  ];
};
