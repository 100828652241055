import React, { ReactElement } from "react";

export const CreateFeeAdvisoryText = (isEnable: boolean): { title: string, advisoryText: string | ReactElement } => {
  let title: string;
  let advisoryText: string | ReactElement;

  switch (isEnable) {
    case true:
      title = "Re-enable Fee?";
      advisoryText = "Re-enabling a Fee allows it to be assigned in its applied workflows.";
      break;
    case false:
      title = "Disable Active Fee?";
      // eslint-disable-next-line max-len
      advisoryText = "This Fee is currently active. Disabling this fee will cause it to not be usable in any applicable workflows until it is re-enabled.";
      break;
    default:
      title = "Fee";
      advisoryText = <div>Something went wrong.</div>;
      break;
  }

  return { title, advisoryText };
};
