import { FormControl, FormHelperText, InputLabel, RadioGroup } from "@mui/material";
import React, { ChangeEvent, ReactElement, ReactNode } from "react";

interface PMSRadioProps {
    label?: string
    children: ReactNode,
    name: string
    value: string | number
    changeHandler: (event: ChangeEvent<any>, child: ReactNode) => void,
    helperText?: string
    helperTextStyles?: string
}

const PMSRadio: React.FC<PMSRadioProps> = ({
  label,
  children,
  name,
  value,
  changeHandler,
  helperText,
  helperTextStyles
}: PMSRadioProps): ReactElement => {
  return (
    <>
      {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
      <FormControl error>
        <RadioGroup
          data-testid={`${name}-group`}
          id={`${name}-group`}
          aria-label={name}
          value={value}
          name={name}
          onChange={changeHandler}
      >
          {children}
        </RadioGroup>
      </FormControl>
      {helperText && (
        <FormHelperText className={helperTextStyles}>
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};

export default PMSRadio;
