import { Button, Collapse, Grid, IconButton, Typography } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import React, { ReactElement } from "react";
import { selectAdvisoryOpen, setAdvisoryOpen } from "../../../store/reducers/formsSlice/formsSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import useStyles from "./PMSAdvisory.styles";

interface PMSAdvisoryProps {
  title: string
  advisoryText: string | ReactElement
  icons?: ReactElement | null
  allowSkip?: boolean
  allowSkipDisabled?: boolean
  skipHandler?: () => void
}

const PMSAdvisory: React.FC<PMSAdvisoryProps> = ({
  title,
  advisoryText,
  icons,
  allowSkip,
  allowSkipDisabled = false,
  skipHandler
}: PMSAdvisoryProps): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const advisoryOpen = useAppSelector(selectAdvisoryOpen);

  const handleAdvisoryToggle = () => dispatch(setAdvisoryOpen());

  return (
    <Grid
      container
      item
      xs={12}
      p={3}
      my={3}
      className={classes.root}
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <Grid item>
        <Grid
          item
          container
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            variant={"h5"}
            component={"h3"}
            data-testid={"pms-advisory-title"}
          >
            {title}
          </Typography>
          <IconButton
            color={"secondary"}
            aria-label={"toggle advisory text"}
            data-testid={"toggle-advisory-button"}
            onClick={() => handleAdvisoryToggle()}
            size={"large"}>
            {advisoryOpen
              ? <ExpandLess data-testid={"expand-less-icon"} />
              : <ExpandMore data-testid={"expand-more-icon"} />
            }
          </IconButton>
        </Grid>
        <Collapse in={advisoryOpen}>
          <Typography className={classes.advisoryText} component={"div"} data-testid={"pms-advisory-text"}>
            {advisoryText}
          </Typography>
          {icons &&
            <Grid className={classes.iconContainer} container data-testid={"advisory-icons"}>{icons}</Grid>
          }
          {allowSkip &&
            <Grid item container justifyContent={"end"}>
              <Button
                variant={"text"}
                className={classes.skipButton}
                onClick={skipHandler}
                disabled={allowSkipDisabled}
              >
                Skip for now
              </Button>
            </Grid>
          }
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default PMSAdvisory;
