declare global {
  interface Window {
      com: any;
  }
}

const RptAPI = Object.create(null, {
  /**
   * Init RptAPI with svrinfo and report entry id
   * @param {Object} svrinfo
   * @param {String} entryId
   */
  init: {
    value: function(svrinfo: any, entryId: any) {
      this.svrinfo = svrinfo;
      this.entryId = entryId;
    }
  },

  /**
   * Run report with specified rptDef, catDef and parameters
   * @param {Object} rptDef The report resource
   * @param {Object} catDef The catalog resource
   * @param {Object} params The paramters for running the report.
   * @return The report studio app
   */
  runReport: {
    value: function(rptDef: any, catDef: any, params: any) {
      // @ts-ignore
      if (window.com && window.com.jinfonet && window.com.jinfonet.api) {
        const fac = window.com.jinfonet.api.AppFactory;
        return fac.runReport(this.svrinfo, rptDef, catDef, params, this.entryId);
      }

      console.error("Report API is not properly loaded.");
    }
  },

  getRptStudio: {
    value: function() {
      // @ts-ignore
      const fac = window.com.jinfonet.api.AppFactory;
      return fac.getApp(this.entryId);
    }
  }

});

interface PageReportPreferences {
  feature_UserInfoBar: boolean;
  feature_ToolBar: boolean;
  feature_Toolbox: boolean;
  feature_DSOTree: boolean;
  feature_TOCTree: boolean;
  feature_PopupMenu: boolean;
  feature_ADHOC: boolean;
}

interface WebReportViewMode {
  hasToolbar: boolean;
  hasSideArea: boolean;
}

export interface RptSvrInfo {
  url?: string;
  user?: string;
  pass?: string;
  jrd_studio_mode: string;
  jrd_prefer: {
    pagereport: PageReportPreferences;
    webreport?: {
      viewMode: WebReportViewMode;
    };
  };
}

export const rptSvrInfo:RptSvrInfo = {
  // url: process.env.REACT_APP_LOGI_BASE_URL,
  // user: process.env.REACT_APP_LOGI_USER,
  // pass: process.env.REACT_APP_LOGI_PASS,
  url: process.env.REACT_APP_LOGI_BASE_URL || "https://reports.storage360.app/jinfonet/tryView.jsp",
  user: process.env.REACT_APP_LOGI_USER || "Corporate",
  pass: process.env.REACT_APP_LOGI_PASS || "xeroo8eWrLrW5ujOUJmI",
  jrd_studio_mode: "view",
  jrd_prefer: {
    pagereport: {
      feature_UserInfoBar: false,
      feature_ToolBar: true,
      feature_Toolbox: false,
      feature_DSOTree: false,
      feature_TOCTree: false,
      feature_PopupMenu: false,
      feature_ADHOC: false
    },
    webreport: {
      viewMode: {
        hasToolbar: false,
        hasSideArea: false
      }
    }
  }
};

export const rptSvrInfoNoBar:RptSvrInfo = {
  // url: process.env.REACT_APP_LOGI_BASE_URL,
  // user: process.env.REACT_APP_LOGI_USER,
  // pass: process.env.REACT_APP_LOGI_PASS,
  url: process.env.REACT_APP_LOGI_BASE_URL || "https://reports.storage360.app/jinfonet/tryView.jsp",
  user: process.env.REACT_APP_LOGI_USER || "Corporate",
  pass: process.env.REACT_APP_LOGI_PASS || "xeroo8eWrLrW5ujOUJmI",
  jrd_studio_mode: "view",
  jrd_prefer: {
    pagereport: {
      feature_UserInfoBar: false,
      feature_ToolBar: false,
      feature_Toolbox: false,
      feature_DSOTree: false,
      feature_TOCTree: false,
      feature_PopupMenu: false,
      feature_ADHOC: false
    },
    webreport: {
      viewMode: {
        hasToolbar: false,
        hasSideArea: false
      }
    }
  }
};

export default RptAPI;
