import { Button, Divider, Grid, IconButton, Paper, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import LedgerMiddle from "./LedgerMiddle/LedgerMiddle";
import LedgerPrintAndSelectUnit from "./LedgerPrintAndSelectUnit/LedgerPrintAndSelectUnit";
import React from "react";
import { setLedgersModalOpen } from "src/store/reducers/ledgersSlice/ledgersSlice";
import { useAppDispatch } from "src/store/hooks";
import useStyles from "./LedgerView.styles";
import { LedgerViewProvider } from "./LedgerViewContext";

interface LedgerViewProps {
  showLedgerPrintAndSelectUnit?: boolean;
}

const LedgerView = ({ showLedgerPrintAndSelectUnit = true }: LedgerViewProps) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  return (
    <LedgerViewProvider>
      <Grid
        className={classes.container}
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        spacing={2}
        pb={2}
      >
        <Grid container p={2} pb={0}
          spacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight={500} variant={"h4"}>
              View Unit Ledger
            </Typography>
            <IconButton onClick={() => dispatch(setLedgersModalOpen(false))} className={classes.closeIcon}>
              <Close />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        {showLedgerPrintAndSelectUnit && <LedgerPrintAndSelectUnit />}
        <LedgerMiddle />
        <Grid container spacing={0} justifyContent={"end"}>
          <Button onClick={() => dispatch(setLedgersModalOpen(false))} className={classes.closeButton} variant={"text"}>
            Close
          </Button>
        </Grid>
      </Grid>
    </LedgerViewProvider>
  );
};

export default LedgerView;
