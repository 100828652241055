import React, { ReactElement } from "react";
import { Settings, SettingsPowerRounded } from "@mui/icons-material";
import { Fab } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { NavLink } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { logout } from "src/store/thunks/user/logout/logout";
import { useAppDispatch } from "../../../store/hooks";
import useStyles from "./UserMenu.styles";
import LogRocket from "logrocket";

const UserMenu: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const [menuAnchor, setMenuAnchor] = React.useState<Element | null>(null);

  const openUserMenu = (menu: Element) => {
    setMenuAnchor(menu);
  };

  const handleLogout = async() => {
    await dispatch(logout());
    setMenuAnchor(null);
    sessionStorage.removeItem("bearerToken");
    LogRocket.startNewSession();
    window.location.assign(window.config.identityFrontend);
  };

  return (
    <>
      <Fab
        data-testid={"user-menu-button"}
        aria-label={"user menu button"}
        aria-controls={"user-menu"}
        aria-haspopup={"true"}
        component={"span"}
        className={classes.menuButton}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => openUserMenu(e.target as Element)}
      >
        <SettingsPowerRounded className={classes.menuButtonIcon} />
      </Fab>
      <Menu
        id={"user-menu"}
        data-testid={"user-menu"}
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <NavLink
          className={classes.link}
          data-testid={"user-settings-link"}
          to={"/user-settings"}
        >
          <MenuItem data-testid={"go-to-settings-button"}>
            <ListItemIcon>
              <Settings className={classes.flipHorizontal} fontSize={"small"} />
            </ListItemIcon>
            <Typography variant={"inherit"}>Settings</Typography>
          </MenuItem>
        </NavLink>
        <MenuItem onClick={() => handleLogout()} data-testid={"go-to-logout-button"}>
          <ListItemIcon>
            <Logout className={classes.flipHorizontal} fontSize={"small"} />
          </ListItemIcon>
          <Typography variant={"inherit"}>Logout</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
