import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "UIModal" })((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  form: {
    width: "100%"
  },
  title: {
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "2rem"
  },
  container: {
    maxWidth: "30rem",
    borderRadius: "1rem",
    padding: "35px",
    justifyContent: "center"
  },
  button: {
    color: theme.palette.primary.contrastText,
    minWidth: theme.spacing(15),
    minHeight: theme.spacing(5),
    borderColor: "#ccc"
  },
  cancelButton: {
    color: theme.palette.error.contrastText,
    minWidth: theme.spacing(15),
    minHeight: theme.spacing(5),
    borderColor: "#ccc"
  },
  text: {
    textAlign: "center"
  },
  bold: {
    fontWeight: "bold"
  }
}));

export default useStyles;
