import React, { FC, PropsWithChildren } from "react";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { Blocker, createBrowserHistory, History } from "history";

const history:History = createBrowserHistory();

const CustomRouter: FC<PropsWithChildren> = ({ children, ...props }) => {
  return (
    <HistoryRouter history={history} {...props}>
      {children}
    </HistoryRouter>
  );
};

export const rootNavigate = (to: string) => {
  history.push(to);
};

export const blockNavigation = (blocker: Blocker) => {
  return history.block(blocker);
};

export default CustomRouter;
