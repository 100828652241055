import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Cancellation Modal" })((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  container: {
    width: "35rem",
    borderRadius: "1rem",
    padding: theme.spacing(2)
  },
  header: {
    width: "100%",
    height: "20%",
    borderBottom: "1px solid #ddd"
  },
  buttonPrimary: {
    boxShadow: "none",
    backgroundColor: theme.palette.primary.contrastText,
    textTransform: "capitalize",
    fontWeight: 300,
    color: theme.palette.common.white
  },
  buttonSecondary: {
    boxShadow: "none",
    borderColor: theme.palette.grey[500],
    textTransform: "capitalize",
    fontWeight: 300,
    color: theme.palette.primary.contrastText
  }
}));

export default useStyles;
