import { makeStyles } from "../../makeStyles";

const useStyles = makeStyles<{ isEnabled: boolean }>({ label: "InsurancePlans" })((theme, { isEnabled }) => ({
  buttonText: {
    textTransform: "capitalize"
  },
  ctaButton: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.primary.contrastText,
    textTransform: "capitalize",
    fontWeight: 300,
    color: theme.palette.common.white,
    minWidth: "8rem",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main
    }
  },
  disabledButton: {
    color: isEnabled ? theme.palette.text.disabled : theme.palette.text.primary
  },
  enabledButton: {
    color: isEnabled ? theme.palette.text.primary : theme.palette.text.disabled
  }
}));

export default useStyles;
