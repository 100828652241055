import React, { ReactElement } from "react";
import { ArrowBack } from "@mui/icons-material";
import { Typography } from "@mui/material";
import useStyles from "./BackButton.styles";

const BackButton: React.FC = (): ReactElement => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <ArrowBack className={classes.icon} />
      <Typography>
        Go Back
      </Typography>
    </div>
  );
};

export default BackButton;
