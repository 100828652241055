import { Download, Print, Visibility } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { selectOccupant, setOccupantDocumentModalOpened, setPDF } from "src/store/reducers/occupantSlice/occupantSlice";
import {
  selectUploadedDocuments,
  selectUploadedDocumentsLoading
} from "src/store/reducers/uploadedDocumentsSlice/uploadedDocumentsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";

import EmptyTable from "src/components/ui/PMSTable/EmptyTable/EmptyTable";
import PMSTable from "src/components/ui/PMSTable/PMSTable";
import React from "react";
import { UPLOADED_DOCUMENT_TYPES } from "./OccupantDocuments";
import { UploadedDocument } from "src/models/UploadedDocument";
import { downloadOccupantDocument } from "src/store/thunks/occupantDocuments/downloadOccupantDocument";
import { getAllPaginatedOccupantDocuments } from "src/store/thunks/occupantDocuments/getAllPaginatedOccupantDocuments";
import { getKeyByValue } from "src/utils/getKeyByValue/getKeyByValue";

const RenderActionButtons = (uploadedDocument: UploadedDocument) => {
  const dispatch = useAppDispatch();

  const print = () => {
    dispatch(
      downloadOccupantDocument({
        occupantId: uploadedDocument.occupant_id,
        documentId: uploadedDocument.id
      })
    ).then(({ payload }) => {
      const blob = new Blob([payload], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      window.open(url, "_blank");
      window.URL.revokeObjectURL(url);
    });
  };

  const download = () => {
    dispatch(
      downloadOccupantDocument({ occupantId: uploadedDocument.occupant_id, documentId: uploadedDocument.id })
    ).then(({ payload }) => {
      const blob = new Blob([payload], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "document.pdf";

      link.click();

      window.URL.revokeObjectURL(url);
    });
  };

  const openViewDocument = () => {
    dispatch(
      downloadOccupantDocument({ occupantId: uploadedDocument.occupant_id, documentId: uploadedDocument.id })
    ).then(async({ payload }) => {
      const url = window.URL.createObjectURL(payload);
      dispatch(setPDF(url));
      dispatch(setOccupantDocumentModalOpened(true));
    });
  };

  return (
    <Grid container justifyContent={"space-between"} alignItems={"center"}>
      <Tooltip title={"View Document"}>
        <IconButton onClick={() => openViewDocument()} size={"small"}>
          <Visibility />
        </IconButton>
      </Tooltip>
      <Tooltip title={"Download Document"}>
        <IconButton size={"small"} onClick={() => download()}>
          <Download />
        </IconButton>
      </Tooltip>
      <Tooltip title={"Print Document"}>
        <IconButton size={"small"} onClick={() => print()}>
          <Print />
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

const OccupantDocumentsTableColumns = (): GridColDef[] => [
  {
    field: "name",
    renderHeader: () => (
      <Tooltip title={"Document Name"}>
        <Typography>Document Name</Typography>
      </Tooltip>
    ),
    minWidth: 170,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.name
  },
  {
    field: "type",
    renderHeader: () => (
      <Tooltip title={"Document Type"}>
        <Typography>Document Type</Typography>
      </Tooltip>
    ),
    minWidth: 170,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      const value = params.row.type;
      return getKeyByValue(UPLOADED_DOCUMENT_TYPES, value);
    }
  },
  {
    field: "unit_id",
    renderHeader: () => (
      <Tooltip title={"Unit Number"}>
        <Typography>Unit Num</Typography>
      </Tooltip>
    ),
    minWidth: 170,
    flex: 1,
    // eslint-disable-next-line max-len
    valueGetter: (params: GridValueGetterParams) =>
      params.row.unit?.unit_number && `Unit ${params.row.unit?.unit_number}`
  },
  {
    field: "actions",
    minWidth: 140,

    renderHeader: () => (
      <Tooltip title={"Actions"}>
        <Typography>Actions</Typography>
      </Tooltip>
    ),
    renderCell: ({ row }: GridRenderCellParams) => RenderActionButtons(row)
  }
];

const OccupantDocumentsTable = () => {
  const dispatch = useAppDispatch();
  const selectedOccupant = useAppSelector(selectOccupant);
  const documents = useAppSelector(selectUploadedDocuments);
  const loading = useAppSelector(selectUploadedDocumentsLoading);

  const handlePaginationUpdate = () => {
    dispatch(getAllPaginatedOccupantDocuments({ occupantId: selectedOccupant?.id! }));
  };

  const emptyTable = () => <EmptyTable collectionNotFound={`documents`} value={"document"} />;

  return (
    <PMSTable
      columns={OccupantDocumentsTableColumns()}
      rows={documents}
      loading={loading}
      emptyTableDisplay={emptyTable}
      onPaginationUpdate={handlePaginationUpdate}
    />
  );
};

export default OccupantDocumentsTable;
