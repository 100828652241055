import DelinquencyAutomation from "src/models/DelinquencyAutomations";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const enableOrDisableAutomation = createAsyncThunk("enableOrDisableAutomation",
  ({ automation, facilityId, is_active }: { automation: DelinquencyAutomation, facilityId: string, is_active: boolean},
    { dispatch, getState, rejectWithValue }) => {
    const automationId = automation?.id;

    return axios.post(`/api/v1/facilities/${facilityId}/system-automations/${automationId}/enabled`, { is_active })
      .then((resp) => {
        dispatch(showSnackbar({
          message: `Automation successfully ${resp.data.is_active ? "enabled" : "disabled"}`,
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        if (err.response.status === 422) {
          if (err.response.data.detail) return rejectWithValue(err.response.data.detail);

          const errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        const errorMessage = `There was an error ${!is_active ? "enabling" : "disabling"} the Automation`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
