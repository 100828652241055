import { Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { FormEvent, ReactElement } from "react";
import {
  selectECAddressOne,
  selectECAddressSameAsOccupant,
  selectECAddressTwo,
  selectECCity,
  selectECEmail,
  selectECFirstName,
  selectECLastName,
  selectECMiddleName,
  selectECPhone,
  selectECPostalCode,
  selectECRelationship,
  selectECState,
  setEmergencyContact,
  setEmergencyContactAddressSameAsOccupant
} from "../../../store/reducers/emergencyContactInformationSlice/emergencyContactInformationSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { EmergencyContactValues } from "../../../models/formikInputValues/occupant/EmergencyContactValues";
import MaskedPhone from "../../masking/MaskedPhone/MaskedPhone";
import PMSCheckbox from "src/components/ui/PMSCheckbox/PMSCheckbox";
import PMSSelect from "../../ui/PMSSelect/PMSSelect";
import emergencyContactValidation from "./emergencyContactValidation";
import { inputError } from "../../../utils/showInputError/showInputError";
import {
  selectMilitaryStatus,
  selectOccupantAddressInformation
} from "src/store/reducers/occupantInformationSlice/occupantInformationSlice";
import states from "../../../utils/usStates";
import { useFormik } from "formik";

interface EmergencyContactProps {
  stepperId: string;
  onSubmitHandler: (values: EmergencyContactValues) => void;
}

const EmergencyContact: React.FC<EmergencyContactProps> = ({ stepperId, onSubmitHandler }): ReactElement => {
  const firstName = useAppSelector(selectECFirstName);
  const middleName = useAppSelector(selectECMiddleName);
  const lastName = useAppSelector(selectECLastName);
  const addressOne = useAppSelector(selectECAddressOne);
  const addressTwo = useAppSelector(selectECAddressTwo);
  const city = useAppSelector(selectECCity);
  const state = useAppSelector(selectECState);
  const postalCode = useAppSelector(selectECPostalCode);
  const phone = useAppSelector(selectECPhone);
  const relationship = useAppSelector(selectECRelationship);
  const email = useAppSelector(selectECEmail);
  const ecSameAddressAsOccupant = useAppSelector(selectECAddressSameAsOccupant);
  const occupantAddressInformation = useAppSelector(selectOccupantAddressInformation);
  const militaryStatus = useAppSelector(selectMilitaryStatus);
  const dispatch = useAppDispatch();

  const toggleECSameAsOccupant = () => {
    dispatch(setEmergencyContactAddressSameAsOccupant(occupantAddressInformation));
  };

  const formik = useFormik({
    initialValues: {
      firstName: firstName,
      middleName: middleName ?? "",
      lastName: lastName,
      addressOne: addressOne,
      addressTwo: addressTwo ?? "",
      city: city,
      state: state,
      postalCode: postalCode,
      phone: phone ?? "",
      relationship: relationship,
      email: email
    },
    enableReinitialize: true,
    validationSchema: emergencyContactValidation(militaryStatus),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values: EmergencyContactValues) => onSubmitHandler(values)
  });

  const setEmergencyContactInformation = () => {
    dispatch(setEmergencyContact(formik.values));
  };

  const { touched, errors, values, handleChange } = formik;

  const maskZipCode = (fieldValue: string) => {
    const value = fieldValue.replace(/\D/g, "")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");

    return value;
  };

  return (
    <Grid
      component={"form"}
      p={1}
      id={stepperId}
      data-testid={stepperId}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        formik.handleSubmit();
      }}
    >
      <Typography variant={"h6"} component={"h3"}>
        Emergency Contact Information
      </Typography>
      <Grid container justifyContent={"space-between"} mt={2}
        spacing={2}>
        <Grid xs={12} lg={5} item>
          <InputLabel htmlFor={"first-name"}>First Name</InputLabel>
          <TextField
            fullWidth
            placeholder={"- First Name -"}
            id={"first-name"}
            inputProps={{ "data-testid": "first-name" }}
            name={"firstName"}
            value={values.firstName}
            onChange={handleChange}
            error={inputError("firstName", touched, errors).error}
            helperText={inputError("firstName", touched, errors).helperText}
            onBlur={setEmergencyContactInformation}
          />
        </Grid>
        <Grid xs={12} lg={2} item>
          <InputLabel htmlFor={"middle-name"}>Initials</InputLabel>
          <TextField
            fullWidth
            placeholder={"- Initials -"}
            id={"middle-name"}
            inputProps={{ "data-testid": "middle-name" }}
            name={"middleName"}
            value={values.middleName}
            onChange={handleChange}
            error={inputError("middleName", touched, errors).error}
            helperText={inputError("middleName", touched, errors).helperText}
            onBlur={setEmergencyContactInformation}
          />
        </Grid>
        <Grid xs={12} lg={5} item>
          <InputLabel htmlFor={"last-name"}>Last Name</InputLabel>
          <TextField
            fullWidth
            placeholder={"- Last Name -"}
            id={"last-name"}
            inputProps={{ "data-testid": "last-name" }}
            name={"lastName"}
            value={values.lastName}
            onChange={handleChange}
            error={inputError("lastName", touched, errors).error}
            helperText={inputError("lastName", touched, errors).helperText}
            onBlur={setEmergencyContactInformation}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <InputLabel htmlFor={"mailing-address-one"}>Mailing Address 1</InputLabel>
          <TextField
            fullWidth
            placeholder={"- Mailing Address 1 -"}
            id={"mailing-address-one"}
            inputProps={{ "data-testid": "mailing-address-one" }}
            name={"addressOne"}
            value={values.addressOne}
            onChange={handleChange}
            error={inputError("addressOne", touched, errors).error}
            helperText={inputError("addressOne", touched, errors).helperText}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <InputLabel htmlFor={"mailing-address-two"}>Mailing Address 2</InputLabel>
          <TextField
            fullWidth
            placeholder={"- Mailing Address 2 -"}
            id={"mailing-address-two"}
            inputProps={{ "data-testid": "mailing-address-two" }}
            name={"addressTwo"}
            value={values.addressTwo}
            onChange={handleChange}
            error={inputError("addressTwo", touched, errors).error}
            helperText={inputError("addressTwo", touched, errors).helperText}
          />
        </Grid>
        <Grid xs={12} lg={8} item>
          <InputLabel htmlFor={"city"}>City</InputLabel>
          <TextField
            fullWidth
            placeholder={"- City -"}
            id={"city"}
            inputProps={{ "data-testid": "city" }}
            name={"city"}
            value={values.city}
            onChange={handleChange}
            error={inputError("city", touched, errors).error}
            helperText={inputError("city", touched, errors).helperText}
          />
        </Grid>
        <Grid xs={12} lg={4} item>
          <PMSSelect
            id={"state"}
            name={"state"}
            label={"State"}
            value={values.state}
            changeHandler={handleChange}
            error={inputError("state", touched, errors).error}
            helperText={inputError("state", touched, errors).helperText}
          >
            <option value={""}>{" - State -"}</option>
            {states.map((state) => (
              <option key={state.value} value={state.value}>
                {state.text}
              </option>
            ))}
          </PMSSelect>
        </Grid>
        <Grid xs={12} lg={6} item>
          <InputLabel htmlFor={"postal-code"}>Zip/postal code</InputLabel>
          <TextField
            fullWidth
            placeholder={"- Zip/postal code -"}
            id={"postal-code"}
            name={"postalCode"}
            value={maskZipCode(values.postalCode)}
            onChange={handleChange}
            error={inputError("postalCode", touched, errors).error}
            helperText={inputError("postalCode", touched, errors).helperText}
          />
        </Grid>
        <Grid xs={12} lg={6} item>
          <InputLabel htmlFor={"country"}>Country</InputLabel>
          <Select
            fullWidth
            disabled
            defaultValue={""}
            id={"country"}
            displayEmpty
            variant={"outlined"}
            value={""}
            inputProps={{ "data-testid": "country" }}
          >
            <MenuItem value={""} disabled>
              United States
            </MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12}>
          <PMSCheckbox
            name={"ec-same-as-checkbox"}
            label={"Same Address as Occupant"}
            smallText
            isChecked={ecSameAddressAsOccupant}
            changeHandler={toggleECSameAsOccupant}
          />
        </Grid>
        <Grid xs={12} lg={6} item>
          <InputLabel htmlFor={"phone"}>Phone</InputLabel>
          <MaskedPhone
            fullWidth
            id={"phone"}
            dataTestId={"phone"}
            placeholder={"- Phone -"}
            name={"phone"}
            type={"tel"}
            value={values.phone}
            onChange={handleChange}
            onBlur={setEmergencyContactInformation}
            error={inputError("phone", touched, errors).error}
            helperText={inputError("phone", touched, errors).helperText}
          />
        </Grid>
        <Grid xs={12} lg={6} item>
          <PMSSelect
            id={"relationship"}
            name={"relationship"}
            label={"Relationship"}
            value={values.relationship}
            changeHandler={handleChange}
            onBlur={setEmergencyContactInformation}
            error={inputError("relationship", touched, errors).error}
            helperText={inputError("relationship", touched, errors).helperText}
          >
            <option value={""} disabled>
              {" - Relationship -"}
            </option>
            <option value={"parent/guardian"}>Parent/Guardian</option>
            <option value={"sibling"}>Sibling</option>
            <option value={"spouse"}>Spouse</option>
            <option value={"significant other"}>Significant Other</option>
            <option value={"friend"}>Friend</option>
            <option value={"other relative"}>Other Relative</option>
          </PMSSelect>
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor={"email"}>E-mail Address</InputLabel>
          <TextField
            fullWidth
            placeholder={"- E-mail Address -"}
            id={"email"}
            inputProps={{ "data-testid": "email" }}
            name={"email"}
            value={values.email}
            onChange={handleChange}
            onBlur={setEmergencyContactInformation}
            error={inputError("email", touched, errors).error}
            helperText={inputError("email", touched, errors).helperText}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EmergencyContact;
