import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const createDocumentTemplate = createAsyncThunk(
  "createDocumentTemplate",
  (form: any, { dispatch, getState, rejectWithValue }) => {
    const payload = {
      ...form,
      version_changes: "Document Created",
      version: "v1",
      version_control_id: null,
      is_disabled: 0,
      rollback_allowed: 1,
      is_latest_version: true
    };

    return axios.post("/api/v1/document-templates", payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Document Template created",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an error creating the Document Template";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
