import {
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  Paper,
  Switch,
  TextField,
  TextFieldProps,
  Typography
} from "@mui/material";
import React, { ChangeEvent, FC } from "react";
import {
  resetExternalTppPlanState,
  selectExternalTPPModalOpen,
  selectExternalTppLoading,
  selectExternalTppPlanActive,
  selectSelectedExternalTppPlan,
  setExternalTPPModalOpen,
  setExternalTppPlanActive,
  setInsuranceChangeModalOpen,
  setSelectedExternalTppPlan
} from "src/store/reducers/externalTppPlanSlice/externalTppPlanSlice";
import {
  selectActiveInsurancePlans,
  selectSelectedInsurancePlan,
  setSelectedInsurancePlan
} from "src/store/reducers/insurancePlansSlice/insurancePlansSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Close } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import ExternalTppPlan from "src/models/ExternalTppPlan";
import ItemSelector from "src/pages/Deals/CreateMoveIn/ProcessMoveIn/ItemSelector/ItemSelector";
import { LoadingButton } from "@mui/lab";
import moment, { Moment as MomentType } from "moment";
import { formatDate } from "src/utils/__dateAndTimeUtils__/formatDate/formatDate";
import { formattedAmount } from "src/utils/formattedAmount/formattedAmount";
import { inputError } from "src/utils/showInputError/showInputError";
import { useFormik } from "formik";
import useStyles from "./UpdateInsurancePolicyModal.styles";
import validationSchema from "./updateInsurancePolicyModalValidation";
import { today } from "src/utils/__dateAndTimeUtils__/today";

interface UpdateInsurancePolicyModalProps {}

const UpdateInsurancePolicyModal: FC<UpdateInsurancePolicyModalProps> = () => {
  const { classes } = useStyles();
  const plans = useAppSelector(selectActiveInsurancePlans);
  const externalTPPModalOpen = useAppSelector(selectExternalTPPModalOpen);
  const dispatch = useAppDispatch();
  const hasExternalTppPlan = useAppSelector(selectExternalTppPlanActive);
  const selectedExternalTppPlan = useAppSelector(selectSelectedExternalTppPlan);
  const externalTppLoading = useAppSelector(selectExternalTppLoading);
  const selectedInternalTppPlan = useAppSelector(selectSelectedInsurancePlan);

  const planList = plans.map((plan) => (
    <ItemSelector
      key={plan.id}
      item={plan}
      isChecked={String(selectedInternalTppPlan?.id) === String(plan.id)}
      itemLabel={`${plan.name}`}
      itemPrice={formattedAmount(0, plan.premium_amount)}
      changeHandler={(e) => dispatch(setSelectedInsurancePlan(plan))}
      removeItem={(e) => dispatch(setSelectedInsurancePlan(null))}
      isDisabled={hasExternalTppPlan}
    />
  ));

  const initialValues: ExternalTppPlan = {
    policy_start_date: selectedExternalTppPlan?.policy_start_date ?? "",
    policy_end_date: selectedExternalTppPlan?.policy_end_date ?? "",
    coverage_amount: selectedExternalTppPlan?.coverage_amount ?? "",
    policy_number: selectedExternalTppPlan?.policy_number ?? "",
    description: selectedExternalTppPlan?.description ?? ""
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (hasExternalTppPlan) {
        dispatch(setSelectedExternalTppPlan({ id: selectedExternalTppPlan?.id, ...values }));
      }

      dispatch(setExternalTPPModalOpen(false));
      dispatch(setInsuranceChangeModalOpen(true));
    }
  });

  const { values, errors, touched, handleChange, resetForm, setFieldValue, handleSubmit } = formik;

  const handleSave = (values: ExternalTppPlan) => {
    if (hasExternalTppPlan && Object.keys(errors)) {
      return;
    }

    if (hasExternalTppPlan) {
      dispatch(setSelectedExternalTppPlan(values));
    }

    dispatch(setExternalTPPModalOpen(false));
    dispatch(setInsuranceChangeModalOpen(true));
  };

  const handleCloseModal = () => {
    resetForm({ values: initialValues });
    dispatch(resetExternalTppPlanState());
    dispatch(setSelectedInsurancePlan(null));
  };

  const handleChangeExternalTppFlag = () => {
    dispatch(setSelectedInsurancePlan(null));
    dispatch(setExternalTppPlanActive(!hasExternalTppPlan));
  };

  return (
    <Modal onClose={handleCloseModal} open={externalTPPModalOpen} className={classes.modal}>
      <Grid
        p={4}
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        justifyContent={"center"}
        className={classes.container}
      >
        <Grid item xs={11}>
          <Typography variant={"h5"}>Edit External Insurance</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={handleCloseModal}>
            <Close />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            htmlFor={"external-tpp-plan-active"}
            color={"black"}
            control={
              <Switch
                name={"external-tpp-plan-active"}
                value={hasExternalTppPlan}
                checked={hasExternalTppPlan}
                onChange={handleChangeExternalTppFlag}
              />
            }
            label={"External Plan"}
          />
        </Grid>
        {externalTppLoading ?? (
          <Grid pt={1} container spacing={2}>
            <CircularProgress size={"large"} />
          </Grid>
        )}
        {hasExternalTppPlan && !externalTppLoading
          ? (
            <Grid pt={1} container component={"form"}
              id={"external-tpp-form"} onSubmit={handleSubmit} spacing={2}>
              <Grid item xs={6}>
                <InputLabel htmlFor={"policy_start_date"}>
                  <Typography noWrap>Policy Start Date:</Typography>
                </InputLabel>
                <DatePicker
                  renderInput={(props: TextFieldProps) => (
                    <TextField
                      {...props}
                      fullWidth
                      name={"policy_start_date"}
                      error={inputError("policy_start_date", touched, errors).error}
                      helperText={inputError("policy_start_date", touched, errors).helperText}
                  />
                  )}
                  OpenPickerButtonProps={{ "aria-label": "Select Start Date" }}
                  componentsProps={{
                    leftArrowButton: { "aria-label": "Move Backward" },
                    rightArrowButton: { "aria-label": "Move Backward" }
                  }}
                  inputFormat={"MM/DD/yyyy"}
                  value={values.policy_start_date}
                  maxDate={today}
                  onChange={(value: MomentType | null) => setFieldValue("policy_start_date", formatDate(value))}
              />
              </Grid>
              <Grid item xs={6}>
                <InputLabel htmlFor={"policy_end_date"}>
                  <Typography noWrap>Policy End Date:</Typography>
                </InputLabel>
                <DatePicker
                  renderInput={(props: TextFieldProps) => (
                    <TextField
                      {...props}
                      fullWidth
                      name={"policy_end_date"}
                      error={inputError("policy_end_date", touched, errors).error}
                      helperText={inputError("policy_end_date", touched, errors).helperText}
                  />
                  )}
                  OpenPickerButtonProps={{ "aria-label": "Select Start Date" }}
                  componentsProps={{
                    leftArrowButton: { "aria-label": "Move Backward" },
                    rightArrowButton: { "aria-label": "Move Backward" }
                  }}
                  inputFormat={"MM/DD/yyyy"}
                  value={values.policy_end_date}
                  minDate={moment(values.policy_start_date)}
                  onChange={(value: MomentType | null, _?: string | undefined) =>
                    setFieldValue("policy_end_date", formatDate(value))
                }
              />
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor={"policy_number"}>
                  <Typography noWrap>Policy Number:</Typography>
                </InputLabel>
                <TextField
                  name={"policy_number"}
                  value={values.policy_number}
                  onChange={handleChange}
                  fullWidth
                  error={inputError("policy_number", touched, errors).error}
                  helperText={inputError("policy_number", touched, errors).helperText}
              />
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor={"description"}>
                  <Typography noWrap>Coverage amount:</Typography>
                </InputLabel>
                <TextField
                  fullWidth
                  inputProps={{ "data-testid": "monthly-rate" }}
                  type={"number"}
                  name={"coverage_amount"}
                  value={values.coverage_amount}
                  InputProps={{ startAdornment: <InputAdornment position={"start"}>$</InputAdornment> }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("coverage_amount", e.target.value)}
                  error={inputError("coverage_amount", touched, errors).error}
                  helperText={inputError("coverage_amount", touched, errors).helperText}
              />
                <Grid item xs={12}>
                  <InputLabel htmlFor={"description"}>
                    <Typography noWrap>Policy description:</Typography>
                  </InputLabel>
                  <TextField
                    fullWidth
                    name={"description"}
                    onChange={handleChange}
                    value={values.description}
                    rows={5}
                    multiline
                    error={inputError("description", touched, errors).error}
                    helperText={inputError("description", touched, errors).helperText}
                />
                </Grid>
              </Grid>
            </Grid>
            )
          : null}
        {hasExternalTppPlan
          ? null
          : (
            <Grid className={classes.innerFormScroll} item xs={12}>
              {plans.length ? planList : <Typography>No plans available at this facility</Typography>}
            </Grid>
            )}
        <Grid item container xs={12}
          mt={2} justifyContent={"flex-end"}>
          <Button onClick={handleCloseModal} color={"error"} variant={"text"}
            sx={{ marginRight: 5 }}>
            Cancel
          </Button>
          <LoadingButton
            form={"external-tpp-form"}
            variant={"contained"}
            type={"submit"}
            disabled={Boolean(!selectedInternalTppPlan) && !hasExternalTppPlan}
            onClick={() => handleSave(values)}
            className={classes.button}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default UpdateInsurancePolicyModal;
