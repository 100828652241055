import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FacilityHoursSliceState } from "./FacilityHoursSliceState";
import { RootState } from "../../rootStore";
import { getFacility } from "src/store/thunks/facility/get/getFacility";
import moment from "moment";

export const initialState: FacilityHoursSliceState = {
  sunday: {
    open: moment().startOf("day").format(),
    close: moment().startOf("day").format(),
    is_open_24_hours: false,
    is_closed_for_the_day: false
  },
  monday: {
    open: moment().startOf("day").format(),
    close: moment().startOf("day").format(),
    is_open_24_hours: false,
    is_closed_for_the_day: false
  },
  tuesday: {
    open: moment().startOf("day").format(),
    close: moment().startOf("day").format(),
    is_open_24_hours: false,
    is_closed_for_the_day: false
  },
  wednesday: {
    open: moment().startOf("day").format(),
    close: moment().startOf("day").format(),
    is_open_24_hours: false,
    is_closed_for_the_day: false
  },
  thursday: {
    open: moment().startOf("day").format(),
    close: moment().startOf("day").format(),
    is_open_24_hours: false,
    is_closed_for_the_day: false
  },
  friday: {
    open: moment().startOf("day").format(),
    close: moment().startOf("day").format(),
    is_open_24_hours: false,
    is_closed_for_the_day: false
  },
  saturday: {
    open: moment().startOf("day").format(),
    close: moment().startOf("day").format(),
    is_open_24_hours: false,
    is_closed_for_the_day: false
  }
};

export const facilityHoursSlice = createSlice({
  name: "facilityHours",
  initialState,
  reducers: {
    setSundayOpen: (state:FacilityHoursSliceState, action:PayloadAction<string>) => {
      state.sunday.open = action.payload;
    },
    setMondayOpen: (state:FacilityHoursSliceState, action:PayloadAction<string>) => {
      state.monday.open = action.payload;
    },
    setTuesdayOpen: (state:FacilityHoursSliceState, action:PayloadAction<string>) => {
      state.tuesday.open = action.payload;
    },
    setWednesdayOpen: (state:FacilityHoursSliceState, action:PayloadAction<string>) => {
      state.wednesday.open = action.payload;
    },
    setThursdayOpen: (state:FacilityHoursSliceState, action:PayloadAction<string>) => {
      state.thursday.open = action.payload;
    },
    setFridayOpen: (state:FacilityHoursSliceState, action:PayloadAction<string>) => {
      state.friday.open = action.payload;
    },
    setSaturdayOpen: (state:FacilityHoursSliceState, action:PayloadAction<string>) => {
      state.saturday.open = action.payload;
    },
    setSundayClose: (state:FacilityHoursSliceState, action:PayloadAction<string>) => {
      state.sunday.close = action.payload;
    },
    setMondayClose: (state:FacilityHoursSliceState, action:PayloadAction<string>) => {
      state.monday.close = action.payload;
    },
    setTuesdayClose: (state:FacilityHoursSliceState, action:PayloadAction<string>) => {
      state.tuesday.close = action.payload;
    },
    setWednesdayClose: (state:FacilityHoursSliceState, action:PayloadAction<string>) => {
      state.wednesday.close = action.payload;
    },
    setThursdayClose: (state:FacilityHoursSliceState, action:PayloadAction<string>) => {
      state.thursday.close = action.payload;
    },
    setFridayClose: (state:FacilityHoursSliceState, action:PayloadAction<string>) => {
      state.friday.close = action.payload;
    },
    setSaturdayClose: (state:FacilityHoursSliceState, action:PayloadAction<string>) => {
      state.saturday.close = action.payload;
    },
    setSundayTwentyFourHours: (state:FacilityHoursSliceState, action:PayloadAction<boolean>) => {
      state.sunday.is_open_24_hours = action.payload;
    },
    setMondayTwentyFourHours: (state:FacilityHoursSliceState, action:PayloadAction<boolean>) => {
      state.monday.is_open_24_hours = action.payload;
    },
    setTuesdayTwentyFourHours: (state:FacilityHoursSliceState, action:PayloadAction<boolean>) => {
      state.tuesday.is_open_24_hours = action.payload;
    },
    setWednesdayTwentyFourHours: (state:FacilityHoursSliceState, action:PayloadAction<boolean>) => {
      state.wednesday.is_open_24_hours = action.payload;
    },
    setThursdayTwentyFourHours: (state:FacilityHoursSliceState, action:PayloadAction<boolean>) => {
      state.thursday.is_open_24_hours = action.payload;
    },
    setFridayTwentyFourHours: (state:FacilityHoursSliceState, action:PayloadAction<boolean>) => {
      state.friday.is_open_24_hours = action.payload;
    },
    setSaturdayTwentyFourHours: (state:FacilityHoursSliceState, action:PayloadAction<boolean>) => {
      state.saturday.is_open_24_hours = action.payload;
    },
    setSundayIsClosed: (state:FacilityHoursSliceState, action:PayloadAction<boolean>) => {
      state.sunday.is_closed_for_the_day = action.payload;
    },
    setMondayIsClosed: (state:FacilityHoursSliceState, action:PayloadAction<boolean>) => {
      state.monday.is_closed_for_the_day = action.payload;
    },
    setTuesdayIsClosed: (state:FacilityHoursSliceState, action:PayloadAction<boolean>) => {
      state.tuesday.is_closed_for_the_day = action.payload;
    },
    setWednesdayIsClosed: (state:FacilityHoursSliceState, action:PayloadAction<boolean>) => {
      state.wednesday.is_closed_for_the_day = action.payload;
    },
    setThursdayIsClosed: (state:FacilityHoursSliceState, action:PayloadAction<boolean>) => {
      state.thursday.is_closed_for_the_day = action.payload;
    },
    setFridayIsClosed: (state:FacilityHoursSliceState, action:PayloadAction<boolean>) => {
      state.friday.is_closed_for_the_day = action.payload;
    },
    setSaturdayIsClosed: (state:FacilityHoursSliceState, action:PayloadAction<boolean>) => {
      state.saturday.is_closed_for_the_day = action.payload;
    },
    resetFacilityHoursSlice: (state) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("facilityHours");
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getFacility.fulfilled, (state, action) => {
        state.sunday.open = moment(action.payload.operational_hours.sunday.open).format();
        state.sunday.close = moment(action.payload.operational_hours.sunday.close).format();
        state.sunday.is_open_24_hours = action.payload.operational_hours.sunday.is_open_24_hours;
        state.sunday.is_closed_for_the_day = action.payload.operational_hours.sunday.is_closed_for_the_day;

        state.monday.open = moment(action.payload.operational_hours.monday.open).format();
        state.monday.close = moment(action.payload.operational_hours.monday.close).format();
        state.monday.is_open_24_hours = action.payload.operational_hours.monday.is_open_24_hours;
        state.monday.is_closed_for_the_day = action.payload.operational_hours.monday.is_closed_for_the_day;

        state.tuesday.open = moment(action.payload.operational_hours.tuesday.open).format();
        state.tuesday.close = moment(action.payload.operational_hours.tuesday.close).format();
        state.tuesday.is_open_24_hours = action.payload.operational_hours.tuesday.is_open_24_hours;
        state.tuesday.is_closed_for_the_day = action.payload.operational_hours.tuesday.is_closed_for_the_day;

        state.wednesday.open = moment(action.payload.operational_hours.wednesday.open).format();
        state.wednesday.close = moment(action.payload.operational_hours.wednesday.close).format();
        state.wednesday.is_open_24_hours = action.payload.operational_hours.wednesday.is_open_24_hours;
        state.wednesday.is_closed_for_the_day = action.payload.operational_hours.wednesday.is_closed_for_the_day;

        state.thursday.open = moment(action.payload.operational_hours.thursday.open).format();
        state.thursday.close = moment(action.payload.operational_hours.thursday.close).format();
        state.thursday.is_open_24_hours = action.payload.operational_hours.thursday.is_open_24_hours;
        state.thursday.is_closed_for_the_day = action.payload.operational_hours.thursday.is_closed_for_the_day;

        state.friday.open = moment(action.payload.operational_hours.friday.open).format();
        state.friday.close = moment(action.payload.operational_hours.friday.close).format();
        state.friday.is_open_24_hours = action.payload.operational_hours.friday.is_open_24_hours;
        state.friday.is_closed_for_the_day = action.payload.operational_hours.friday.is_closed_for_the_day;

        state.saturday.open = moment(action.payload.operational_hours.saturday.open).format();
        state.saturday.close = moment(action.payload.operational_hours.saturday.close).format();
        state.saturday.is_open_24_hours = action.payload.operational_hours.saturday.is_open_24_hours;
        state.saturday.is_closed_for_the_day = action.payload.operational_hours.saturday.is_closed_for_the_day;
      });
  }
});

export const selectSundayHours = (state: RootState) => state.facilityHours.sunday;
export const selectMondayHours = (state: RootState) => state.facilityHours.monday;
export const selectTuesdayHours = (state: RootState) => state.facilityHours.tuesday;
export const selectWednesdayHours = (state: RootState) => state.facilityHours.wednesday;
export const selectThursdayHours = (state: RootState) => state.facilityHours.thursday;
export const selectFridayHours = (state: RootState) => state.facilityHours.friday;
export const selcectSaturdayHours = (state: RootState) => state.facilityHours.saturday;
export const selectFacilityHours = (state: RootState) => state.facilityHours;

export const {
  setFridayClose, setFridayIsClosed,
  setFridayOpen,
  setFridayTwentyFourHours,
  setMondayClose,
  setMondayIsClosed,
  setMondayOpen,
  setMondayTwentyFourHours,
  setSaturdayClose,
  setSaturdayIsClosed,
  setSaturdayOpen,
  setSaturdayTwentyFourHours,
  setSundayClose,
  setSundayIsClosed,
  setSundayOpen,
  setSundayTwentyFourHours,
  setThursdayClose,
  setThursdayIsClosed,
  setThursdayOpen,
  setThursdayTwentyFourHours,
  setTuesdayClose,
  setTuesdayIsClosed,
  setTuesdayOpen,
  setTuesdayTwentyFourHours,
  setWednesdayClose,
  setWednesdayIsClosed,
  setWednesdayOpen,
  setWednesdayTwentyFourHours,
  resetFacilityHoursSlice
} = facilityHoursSlice.actions;

export default facilityHoursSlice.reducer;
