import { Button, Grid, Modal, Paper, Typography } from "@mui/material";
import { selectCancelDealModalOpen, selectDeal, setCancelDealModalOpen } from "src/store/reducers/dealSlice/dealSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Box } from "@mui/system";
import { Deal } from "src/models/Deal";
import { LoadingButton } from "@mui/lab";
import React from "react";
import useStyles from "./DisableInsurancePlanModal.styles";

const DisableInsurancePlanModal = ({
  onSubmit,
  open,
  onClose
}: {
  onSubmit: Function;
  open: boolean;
  onClose: Function;
}) => {
  const { classes } = useStyles();

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby={"Modal Component"}
      aria-describedby={"A Modal component."}
      className={classes.modal}
      data-testid={"modal-cancel-deal"}
    >
      <Grid
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        spacing={2}
        className={classes.container}
        pb={2}
      >
        <Box className={classes.container}>
          <Typography variant={"h5"} className={classes.title}>
            Confirm Plan Removal
          </Typography>
          <Typography className={classes.text}>
            Are you sure you want to remove this insurance plan for this location?
          </Typography>
          <Grid pt={4} spacing={4} container
            justifyContent={"center"}>
            <Grid item>
              <Button
                data-testid={"cancel-button"}
                className={classes.cancelButton}
                onClick={() => onClose()}
                variant={"outlined"}
                color={"secondary"}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                data-testid={"confirm-button-modal"}
                color={"primary"}
                variant={"outlined"}
                className={classes.button}
                onClick={() => onSubmit()}
              >
                Confirm
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Modal>
  );
};

export default DisableInsurancePlanModal;
