import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "MoveSummary" })({
  title: {
    fontWeight: "bold",
    textAlign: "center"
  },
  summaryContainer: { width: "100%" }
});

export default useStyles;
