import React, { PropsWithChildren, ReactElement, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { AppDispatch } from "../../../store/rootStore";
import AppModal from "src/components/ui/AppModal/AppModal";
import { Breadcrumb } from "../../../models/Breadcrumb";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import { Typography } from "@mui/material";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { blockNavigation } from "src/components/router/CustomRouter";
import { getAllFeesForFacility } from "src/store/thunks/fee/getAllForFacility/getAllFeesForFacility";
import { resetMoveOutCostSlice } from "src/store/reducers/moveOutCostSlice/moveOutCostSlice";
import {
  resetMoveOutSlice,
  selectIsFromRentedUnits,
  selectMoveOutCompleted,
  setIsFromRentedUnits,
  setIsMoveOutFromManageOccupants
} from "../../../store/reducers/moveOutSlice/moveOutSlice";
import { resetOccupantSlice } from "../../../store/reducers/occupantSlice/occupantSlice";
import { resetSelectedOccupantSlice } from "../../../store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { resetAchSlice } from "src/store/reducers/achSlice/achSlice";
import { resetCreditCardSlice } from "src/store/reducers/creditCardSlice/creditCardSlice";
import { resetCashSlice } from "src/store/reducers/cashSlice/cashSlice";
import { resetMoneyOrderSlice } from "src/store/reducers/moneyOrderSlice/moneyOrderSlice";
import { resetTablesSlice } from "src/store/reducers/tablesSlice/tablesSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Deals"
  },
  {
    name: "Move Out",
    bold: true
  }
];

export const handleReset = (dispatch: AppDispatch,
  isMoveOutFromOccupant: any = null,
  isFromRentedUnits: any = null) => {
//We need to figure out a way to NOT hit these coming from manage occupant/rented units...
  // dispatch(resetSelectedOccupantSlice());
  // dispatch(resetOccupantSlice());
  // dispatch(resetMoveOutSlice());
  // dispatch(resetMoveOutCostSlice());

  dispatch(resetAchSlice());
  dispatch(resetCreditCardSlice());
  dispatch(resetCashSlice());
  dispatch(resetMoneyOrderSlice());
  dispatch(resetTablesSlice());

  if (isMoveOutFromOccupant === false || isFromRentedUnits === false) {
    dispatch(resetMoveOutSlice());
    dispatch(resetMoveOutCostSlice());
  }

  dispatch(setIsFromRentedUnits(false));
};

const pageHeader = <PageHeader title={"Move Out"} breadcrumbs={breadcrumbs} />;

const CreateMoveOut: React.FC<PropsWithChildren> = ({ children, ...props }): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const pageLocation = useLocation();
  const [workflowIncompleteWarning, setWorkflowIncompleteWarning] = useState(false);
  const [localTargetPath, setLocalTargetPath] = React.useState<string>("");
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const moveOutCompleted = useAppSelector(selectMoveOutCompleted);
  const isFromRentedUnits = useAppSelector(selectIsFromRentedUnits);

  let unblock: Function;

  const handleAdvisory = () => {
    return blockNavigation(({ location }) => {
      const nextLocation = location.pathname;
      const currentLocation = pageLocation.pathname;
      setLocalTargetPath(nextLocation);

      let workflowComplete =
        currentLocation === "/occupants/create-move-out/review-move-out" &&
        nextLocation === "/occupants/confirm-move-out";

      const restartingWorkflow =
        currentLocation !== "/occupants/create-move-out" &&
        currentLocation !== "/occupants/select-occupant" &&
        nextLocation === "/occupants/create-move-out";

      if (moveOutCompleted) {
        workflowComplete = true;
      }

      if (
        // you're not on the first step
        currentLocation !== "/occupants/create-move-out" &&
        // and you aren't navigating b/w steps or completely restarting the workflow
        (!nextLocation.includes("/occupants/create-move-out") || restartingWorkflow) &&
        // and you have not completed the move-in
        !workflowComplete
        // then show the advisory modal
      ) {
        setWorkflowIncompleteWarning(true);
        return false;
      }

      unblock();
      navigate(nextLocation);
    });
  };

  useEffect(() => {
    unblock = handleAdvisory();
    if (pageLocation.state?.isMoveOutFromOccupant) {
      dispatch(setIsMoveOutFromManageOccupants(true));
    }
    dispatch(getAllFeesForFacility({ facilityId: selectedFacility?.id!, filter: { "filter[apply_at]": "1,3" } }));

    /* istanbul ignore next */ // cannot test this w/ enzyme
    return () => {
      if (unblock) {
        unblock();
      }
    };
  });

  const confirmNavigationAway = () => {
    if (unblock) {
      unblock();
    }

    handleReset(dispatch, pageLocation.state?.isMoveOutFromOccupant, isFromRentedUnits);
    navigate(localTargetPath);
    setWorkflowIncompleteWarning(false);
  };

  useEffect(() => {
    /* istanbul ignore next */ // cannot test this w/ enzyme
    return () => {
      if (!pageLocation.state?.isMoveOutFromOccupant) {
        handleReset(dispatch, pageLocation.state?.isMoveOutFromOccupant, isFromRentedUnits);
      }
    };
  }, []);

  return (
    <ViewWrapper pageHeader={pageHeader} {...props}>
      {children}
      <AppModal
        open={workflowIncompleteWarning}
        cancelAction={() => setWorkflowIncompleteWarning(false)}
        confirmAction={() => confirmNavigationAway()}
      >
        <Typography gutterBottom variant={"h6"}>
          Warning
        </Typography>
        <Typography variant={"body1"}>
          You are about to leave an incomplete workflow. If you choose to continue, information you have provided during
          this and previous steps will be lost.
        </Typography>
      </AppModal>
    </ViewWrapper>
  );
};

export default CreateMoveOut;
