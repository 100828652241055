import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Description } from "@mui/icons-material";
import EmptyTable from "src/components/ui/PMSTable/EmptyTable/EmptyTable";
import { Ledger } from "src/models/Ledger";
import PMSTable from "src/components/ui/PMSTable/PMSTable";
import PaymentStatus from "src/components/ui/PaymentStatus/PaymentStatus";
import React from "react";
import { formatDate } from "src/utils/__dateAndTimeUtils__/formatDate/formatDate";
import { formattedAmount } from "src/utils/formattedAmount/formattedAmount";
import moment from "moment";
import { setLedgersModalOpen } from "src/store/reducers/ledgersSlice/ledgersSlice";
import { useAppDispatch } from "src/store/hooks";

const RenderActionButtons = (row: GridRenderCellParams) => {
  const dispatch = useAppDispatch();

  const handleOpenModal = async() => {
    dispatch(setLedgersModalOpen(true));
  };

  return (
    <Grid
      data-testid={"render-details-button"}
      container
      direction={"row"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Tooltip title={"View Ledger"}>
        <IconButton size={"small"} onClick={handleOpenModal}>
          <Description />
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

const columns = (balance: string): GridColDef[] => [
  {
    field: "row.unit.unit_number",
    sortable: false,
    renderHeader: () => (
      <Tooltip title={"Unit"}>
        <Typography>Unit</Typography>
      </Tooltip>
    ),
    valueGetter: ({ row }: GridRenderCellParams) => row?.unit?.unit_number,
    disableReorder: true,
    disableColumnMenu: true
  },
  {
    field: "monthly_rate",
    sortable: false,
    renderHeader: () => (
      <Tooltip title={"Rental Rate"}>
        <Typography>Rental Rate</Typography>
      </Tooltip>
    ),
    valueGetter: ({ row }: GridRenderCellParams) => formattedAmount("Fixed Amount", row?.monthly_rate?.toString()!),
    flex: 1,
    disableReorder: true,
    disableColumnMenu: true,
    align: "right",
    headerAlign: "right"
  },
  {
    field: "due_date",
    sortable: false,
    renderHeader: () => (
      <Tooltip title={"Due Date"}>
        <Typography>Due Date</Typography>
      </Tooltip>
    ),
    valueGetter: ({ row }: GridRenderCellParams) => {
      const ledger = row as Ledger;
      const withPendingAmount = ledger.ledger_items?.filter((item) => item.pending_amount! > 0);
      if (withPendingAmount?.length === 0) {
        return formatDate(moment.utc(row.due_date));
      }

      const oldest = withPendingAmount?.reduce((acc, item) => {
        if (moment.utc(item.start_date).isBefore(moment.utc(acc.start_date))) {
          return item;
        }
        return acc;
      }, withPendingAmount[0]);

      return formatDate(moment.utc(oldest?.start_date));
    },
    flex: 1,
    disableReorder: true,
    disableColumnMenu: true,
    align: "center",
    headerAlign: "center"
  },
  {
    field: "status",
    sortable: false,
    renderHeader: () => (
      <Tooltip title={"Status"}>
        <Typography>Status</Typography>
      </Tooltip>
    ),
    renderCell: ({ row }: GridRenderCellParams) => {
      return (
        <PaymentStatus
          currentBalance={parseFloat(balance ?? 0)}
          delinquentDays={parseFloat(row.delinquent_days ?? 0)}
        />
      );
    },
    flex: 1,
    disableReorder: true,
    disableColumnMenu: true,
    align: "center",
    headerAlign: "center"
  },
  {
    field: "current_balance",
    sortable: false,
    renderHeader: () => (
      <Tooltip title={"Outstanding Balance"}>
        <Typography>Outstanding Balance*</Typography>
      </Tooltip>
    ),
    valueGetter: ({ row }: GridRenderCellParams) => `$ ${balance}`,
    flex: 1,
    disableReorder: true,
    disableColumnMenu: true,
    align: "right",
    headerAlign: "right"
  },
  {
    field: "actions",
    sortable: false,
    renderHeader: () => (
      <Tooltip title={"Actions"}>
        <Typography>Actions</Typography>
      </Tooltip>
    ),
    renderCell: (params: GridRenderCellParams) => RenderActionButtons(params),
    flex: 1,
    disableReorder: true,
    disableColumnMenu: true,
    headerAlign: "center",
    align: "center"
  }
];

const emptyTable = () => <EmptyTable collectionNotFound={`data`} value={"data"} />;

const IssueCreditInformationTable = ({ ledger, balance }: { ledger: Ledger | null; balance: string }) => {
  return (
    <Box p={2}>
      <PMSTable
        data-test-id={"ledgers-information-table"}
        columns={columns(balance)}
        rows={[ledger]}
        emptyTableDisplay={emptyTable}
        hideFooter
      />
    </Box>
  );
};

export default IssueCreditInformationTable;
