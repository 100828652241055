import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "AvailableUnitsTable" })({
  root: {
    width: "100%"
  },
  container: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    minWidth: 50
  }
});

export default useStyles;
