import React, { ReactElement, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

import { APIListResponse } from "src/models/responses/APIListResponse";
import EmptyTable from "src/components/ui/PMSTable/EmptyTable/EmptyTable";
import PMSAdvisory from "../../../../components/ui/PMSAdvisory/PMSAdvisory";
import { Paper } from "@mui/material";
import { Promotion } from "src/models/Promotion";
import SelectUnitInStepper from "src/components/stepper/SelectUnit/SelectUnitInStepper/SelectUnitInStepper";
import StepLayout from "../../../../layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "../../../../layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import { createLead } from "../../../../store/thunks/lead/create/createLead";
import { createReservation } from "../../../../store/thunks/reservation/create/createReservation";
import { getAllPromotionsForFacility } from "src/store/thunks/promotion/getAllForFacility/getAllPromotionsForFacility";
import { selectDealLoading } from "../../../../store/reducers/dealSlice/dealSlice";
import { selectDealType } from "../../../../store/reducers/dealInformationSlice/dealInformationSlice";
import { selectProductTypes } from "../../../../store/reducers/productTypesSlice/productTypesSlice";
import { selectPromotionsForFacility } from "src/store/reducers/promotionsSlice/promotionsSlice";
import { selectSelectedUnit } from "src/store/reducers/selectedUnitSlice/selectedUnitSlice";
import { useNavigate } from "react-router";

const SelectOccupantForDeal: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const dealType = useAppSelector(selectDealType);
  const loading = useAppSelector(selectDealLoading);
  const productTypes = useAppSelector(selectProductTypes);
  const selectedUnit = useAppSelector(selectSelectedUnit);
  const facilityPromotions = useAppSelector(selectPromotionsForFacility);
  const [loadingPromotions, setLoadingPromotions] = useState(false);

  const isLead = dealType === "lead";
  const advisoryTitle = isLead ? "Select a Product Type" : "Select a Unit";
  const advisoryInstruction = isLead
    ? "Now, you are able to select the Product Type the Occupant is interested in renting and tie it to the Deal."
    : `Now, you are able to add an available unit to the Deal. This will allow the Occupant to reserve the unit and lock
    in a monthly rate and promotion (if applicable).`;

  const advisoryText = (
    <>
      <p>{advisoryInstruction}</p>
    </>
  );

  const handleSubmit = async() => {
    if (facilityPromotions.length && !isLead) {
      return navigate("/deals/create-new-deal/select-promotion");
    }

    const dealThunk = isLead ? createLead() : createReservation();
    dispatch(dealThunk)
      .then(resp => {
        if (!resp.type.includes("rejected")) {
          navigate("/deals/manage-deals");
        }
      });
  };

  useEffect(() => {
    const loadPromotions = async() => {
      if (selectedUnit && !loadingPromotions) {
        setLoadingPromotions(true);

        await dispatch(getAllPromotionsForFacility(selectedUnit?.id));
      }
      setLoadingPromotions(false);
    };

    loadPromotions();
  }, [selectedUnit]);

  const productTypeTable = productTypes.length
    ? <SelectUnitInStepper data-testid={"select-unit"} onSubmitHandler={handleSubmit} />
    : (
      <EmptyTable
        data-testid={"empty-deal-product-types"}
        collectionNotFound={"product types"}
        value={"product type"}
        addLink={"/product-types/add-product-type"} />
      );

  return (
    <>
      <PMSAdvisory title={advisoryTitle} advisoryText={advisoryText} />
      <Paper elevation={1}>
        <StepLayout
          buttons={
            <StepperButtons
              data-testid={"select-deal-unit-buttons"}
              stepperId={"select-unit"}
              loading={loading || loadingPromotions}
              backAction={() => navigate("/deals/create-new-deal/schedule-deal")}
              forwardActionText={(facilityPromotions.length && !isLead) ? "Next" : "Finish"}
            />}
          >
          {productTypeTable}
        </StepLayout>
      </Paper>
    </>
  );
};

export default SelectOccupantForDeal;
