import { EsignDocumentSliceState } from "./EsignDocumentSliceState";
import {
  getAllEsignDocumentsForFacility
} from "../../thunks/esignDocument/getAllForFacility/getAllEsignDocumentsForFacility";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { APIListResponse } from "../../../models/responses/APIListResponse";
import ESignDocument from "../../../models/ESignDocument";
import { RootState } from "../../rootStore";
import { sendSms } from "src/store/thunks/esignDocument/sms/sendSms";
import { getOccupant } from "src/store/thunks/occupant/getOne/getOccupant";

export const initialState: EsignDocumentSliceState = {
  esignDocuments: [],
  esignDocumentLoading: false,
  esignDocumentError: "",
  occupantESignDocuments: []
};

export const esignDocumentsSlice = createSlice({
  name: "esignDocuments",
  initialState,
  reducers: {
    resetESignDocumentsSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllEsignDocumentsForFacility.pending, (state) => {
        state.esignDocumentLoading = true;
      })
      .addCase(
        getAllEsignDocumentsForFacility.fulfilled,
        (state, action: PayloadAction<APIListResponse<ESignDocument[]>>) => {
          state.esignDocumentLoading = false;
          state.esignDocuments = action.payload.data;
        })
      .addCase(getAllEsignDocumentsForFacility.rejected, (state, action) => {
        state.esignDocumentLoading = false;
        state.esignDocumentError = action.payload as string;
      })
      .addCase(sendSms.pending, (state) => {
        state.esignDocumentLoading = true;
      })
      .addCase(sendSms.fulfilled, (state) => {
        state.esignDocumentLoading = false;
      })
      .addCase(sendSms.rejected, (state) => {
        state.esignDocumentLoading = false;
      })
      .addCase(getOccupant.fulfilled, (state, action) => {
        state.occupantESignDocuments = action.payload.esign_documents;
      });
  }
});

export const selectEsignDocuments = (state: RootState) => state.eSignDocuments.esignDocuments;
export const selectEsignDocumentsLoading = (state: RootState) => state.eSignDocuments.esignDocumentLoading;
export const selectOccupantESignDocuments = (state: RootState) => state.eSignDocuments.occupantESignDocuments;
export const {
  resetESignDocumentsSlice
} = esignDocumentsSlice.actions;
export default esignDocumentsSlice.reducer;
