import { format422 } from "../../../../utils/format422/format422";
import { CreateProductTypePayload } from "./CreateProductTypePayload";
import { ProductCategorySliceState } from "../../../reducers/productCategorySlice/ProductCategorySliceState";
import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";
import { stringToFloat } from "../../../../utils/stringToFloat/stringToFloat";

export const getCategoryId = (state: ProductCategorySliceState): number | null => {
  // if a subcategory has been selected, return the subcategory id
  if (state.productSubCategory?.id) {
    return state.productSubCategory.id;
  }

  // if a subcategory has not been selected, but a parent category has been selected, return the parent category id
  if (state.productCategory?.id) {
    return state.productCategory.id;
  }

  // if no categories have been selected, return null
  return null;
};

export const createProductType = createAsyncThunk(
  "productType/createProductType",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const name = state.productType.productTypeName;
    const height = state.productType.height;
    const length = state.productType.length;
    const width = state.productType.width;
    const facilityId = state.selectedFacility.selectedFacility!.id;
    const propertyType = state.productType.propertyType;
    const price = state.productType.price;
    const productCategoryId = getCategoryId(state.productCategory);
    const revenueClassId = state.productType.revenueClassId;
    const productTypeAttributes = state.productType.attributes;

    const payload: CreateProductTypePayload = {
      name,
      type: propertyType,
      width: stringToFloat(width),
      length: stringToFloat(length),
      height: stringToFloat(height),
      price,
      attributes: productTypeAttributes,
      product_category_id: productCategoryId,
      revenue_class_id: revenueClassId
    };

    return axios.post(`/api/v1/facilities/${facilityId}/product-types`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "New Product Type Created",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an error creating the Product Type";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
