import { Alert, Collapse, Grid, Snackbar, useTheme } from "@mui/material";
import React, { FC, ReactNode, useEffect, useRef } from "react";
import {
  hideSnackbar,
  selectSnackbarMessage,
  selectSnackbarVariant
} from "src/store/reducers/snackbarSlice/snackbarSlice";
import {
  selectShowDrawer,
  toggleDrawer, toggleDrawerTransitionState
} from "src/store/reducers/navigationSlice/navigationSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import NavBar from "../../components/navigation/NavBar/NavBar";
import NavDrawer from "../../components/navigation/NavDrawer/NavDrawer";
import useStyles from "./AppLayout.styles";

interface AppLayoutProps {
  children: ReactNode
}

const AppLayout: FC<AppLayoutProps> = ({ children }: AppLayoutProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const snackbarMessage = useAppSelector(selectSnackbarMessage);
  const snackbarVariant = useAppSelector(selectSnackbarVariant);
  const showDrawer = useAppSelector(selectShowDrawer);
  const { classes } = useStyles({ showDrawer: showDrawer });
  const transitionRef = useRef(null);

  const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const tabletWidth = theme.breakpoints.values.md;
  const mobileWidth = theme.breakpoints.values.sm;

  useEffect(() => {
    const drawerCollapseFinished = (transitionEvent: TransitionEvent) => {
      if (transitionEvent.propertyName === "background-color") {
        setTimeout(() => { dispatch(toggleDrawerTransitionState("inactive")); }, 100);
      }
    };

    if (transitionRef && transitionRef.current) {
      // @ts-ignore
      transitionRef.current.addEventListener("transitionend", drawerCollapseFinished, false);

      return () => {
        if (transitionRef && transitionRef.current) {
          // @ts-ignore
          transitionRef.current.removeEventListener("transitionend", drawerCollapseFinished, false);
        }
      };
    }
  }, []);

  const handleResize = () => {
    if (windowWidth <= tabletWidth && windowWidth > mobileWidth) {
      dispatch(toggleDrawer());
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    /* istanbul ignore next */ // cannot test this w/ enzyme
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navDrawer = windowWidth > mobileWidth
    ? (
      <Grid
        ref={transitionRef}
        component={Collapse}
        orientation={"horizontal"}
        item
        in={showDrawer}
        collapsedSize={"6.25rem"}
      >
        <NavDrawer />
      </Grid>
      )
    : null;

  return (
    <>
      <Grid item container>
        {navDrawer}
        <Grid
          item
          container
          direction={"column"}
          className={classes.mainView}
          mb={5}
        >
          <NavBar />
          <main>
            {children}
          </main>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={!!snackbarMessage}
        autoHideDuration={5000}
        data-testid={"snackbar-wrapper"}
        onClose={() => dispatch(hideSnackbar())}
      >
        <Alert
          data-testid={"snackbar-message"}
          severity={snackbarVariant}
          onClose={() => dispatch(hideSnackbar())}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AppLayout;
