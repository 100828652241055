import { Button, Menu, MenuItem, Typography } from "@mui/material";

import { FileDownloadOutlined } from "@mui/icons-material";
import React from "react";
import { getLedgerAsPdf } from "src/store/thunks/ledger/print/getLedgerAsPdf";
import { useAppDispatch } from "src/store/hooks";
import { useParams } from "react-router";
import useStyles from "./LedgerPrintMenu.styles";

interface Props {
    handleCloseExportMenu: () => void;
    anchorEl: HTMLElement | null;
    occupantId?: string;
}

const LedgerPrintMenu = ({ anchorEl, handleCloseExportMenu, occupantId } : Props) => {
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const params = useParams<{ id: string }>();

  const handleClick = () => {
    dispatch(getLedgerAsPdf(params.id!)).then((resp) => {
      if (!resp.type.includes("rejected")) {
        const link = document.createElement("a");
        link.href = resp.payload as string;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  return (
    <Menu
      id={"basic-menu"}
      anchorEl={anchorEl}
      open={open}
      onClose={handleCloseExportMenu}
      MenuListProps={{
        "aria-labelledby": "basic-button"
      }}
>
      <MenuItem onClick={handleClick} className={classes.menuItem}>
        <Button
          endIcon={<FileDownloadOutlined sx={{ color: "#306DC8" }}/>}>
          <Typography className={classes.buttonText} >Export as PDF</Typography>
        </Button>
      </MenuItem>
    </Menu>
  );
};

export default LedgerPrintMenu;
