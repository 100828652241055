import {
  AccountBalance,
  Assignment,
  Business,
  Campaign,
  DocumentScanner,
  FormatListNumbered,
  MonetizationOn,
  MoneyOff,
  PriceChange,
  Settings
} from "@mui/icons-material";
import {
  hasPermissions,
  selectFacilityPermissions,
  selectHasAnyPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";
import { NavLink } from "src/models/NavLink";
import { PermissionAction } from "src/store/reducers/permissionsSlice/PermissionsSliceState";
import React from "react";
import { useAppSelector } from "src/store/hooks";
import {
  selectUserName,
  selectUserEmail,
  selectUserId
} from "src/store/reducers/userInfoSlice/userInfoSlice";
import LogRocket from "logrocket";

export const useAdminLinks = () => {
  const userName = useAppSelector(selectUserName);
  const userEmail = useAppSelector(selectUserEmail);
  const userId = useAppSelector(selectUserId);
  const permissions = useAppSelector(selectFacilityPermissions);
  const hasAnyPermissions = useAppSelector(selectHasAnyPermissions);
  const permissionsCrud: PermissionAction[] = ["create", "delete", "read", "update"];

  if (userId) {
    LogRocket.identify(String(userId), {
      name: String(userName),
      email: String(userEmail)
    });
  }

  if (!hasAnyPermissions) {
    return [];
  }

  const permissionsObject = {
    units: {
      sidebar: hasPermissions(permissions, "product_types", permissionsCrud, "one") ||
        hasPermissions(permissions, "manage_units", permissionsCrud, "one"),
      productTypes: hasPermissions(permissions, "product_types", permissionsCrud, "one"),
      manageUnits: hasPermissions(permissions, "manage_units", permissionsCrud, "one")
    },
    promotions: {
      sidebar: hasPermissions(permissions, "promotions", permissionsCrud, "one")
    },
    feesAndCharges: {
      sidebar: hasPermissions(permissions, "manage_fees", permissionsCrud, "one") ||
        hasPermissions(permissions, "insurance_plans", permissionsCrud, "one") ||
        hasPermissions(permissions, "automations", permissionsCrud, "one"),
      manageFees: hasPermissions(permissions, "manage_fees", permissionsCrud, "one"),
      insurancePlans: hasPermissions(permissions, "insurance_plans", permissionsCrud, "one")
    },
    documents: {
      sidebar: hasPermissions(permissions, "document_library", permissionsCrud, "one") ||
        hasPermissions(permissions, "e_sign_status", permissionsCrud, "one"),
      document_library: hasPermissions(permissions, "document_library", permissionsCrud, "one"),
      esign_status: hasPermissions(permissions, "e_sign_status", permissionsCrud, "one")
    },
    facilities: {
      sidebar: hasPermissions(permissions, "facility", permissionsCrud, "one") ||
        hasPermissions(permissions, "add_facility", ["create"], "one"),
      manage_facilities: hasPermissions(permissions, "facility", permissionsCrud, "one"),
      add_facility: hasPermissions(permissions, "add_facility", ["create"], "one")
    },
    revenue: {
      sidebar: hasPermissions(permissions, "asking_rate_changes", ["create"], "one") ||
        hasPermissions(permissions, "occupant_rate_changes", ["create"], "one"),
      occupantRateChanges: hasPermissions(permissions, "occupant_rate_changes", ["create"], "one"),
      askingRateChanges: hasPermissions(permissions, "asking_rate_changes", ["create"], "one")
    },
    automations: {
      sidebar: hasPermissions(permissions, "automations", permissionsCrud, "one"),
      systemAutomations: hasPermissions(permissions, "automations", permissionsCrud, "one"),
      webhooks: hasPermissions(permissions, "automations", ["create"], "one")
    },
    accounting: {
      sidebar: hasPermissions(permissions, "revenue_classes", permissionsCrud, "one") ||
        hasPermissions(permissions, "chart_of_accounts", permissionsCrud, "one"),
      revenueClasses: hasPermissions(permissions, "revenue_classes", permissionsCrud, "one"),
      chartOfAccounts: hasPermissions(permissions, "chart_of_accounts", permissionsCrud, "one")
    },
    marketing: {
      sidebar: hasPermissions(permissions, "data_management", permissionsCrud, "one"),
      dataManagement: hasPermissions(permissions, "data_management", permissionsCrud, "one")
    },
    taskManagement: {
      sidebar: hasPermissions(permissions, "manage_tasks", permissionsCrud, "one") ||
        hasPermissions(permissions, "task_history", permissionsCrud, "one"),
      manageTasks: hasPermissions(permissions, "manage_tasks", permissionsCrud, "one"),
      taskHistory: hasPermissions(permissions, "task_history", permissionsCrud, "one")
    },
    administratorSettings: {
      sidebar: hasPermissions(permissions, "global_settings", ["update"], "one") ||
              hasPermissions(permissions, "configuration_manager", permissionsCrud, "one"),
      globalSettings: hasPermissions(permissions, "global_settings", ["update"], "one"),
      managePermissions: hasPermissions(permissions, "global_settings", permissionsCrud, "one"),
      configurationManager: hasPermissions(permissions, "configuration_manager", permissionsCrud, "one")
    }
  };

  const adminLinks: (NavLink | null)[] = [
    permissionsObject.units.sidebar
      ? {
          name: "Units",
          path: "",
          icon: <Assignment />,
          isOpen: true,
          childLinks: [
            permissionsObject.units.productTypes
              ? { name: "Manage Product Types", path: "/product-types/manage-product-types-new" }
              : null,
            permissionsObject.units.manageUnits
              ? { name: "Manage Units", path: "/units/manage-units" }
              : null
          ]
        }
      : null,
    permissionsObject.promotions.sidebar
      ? {
          name: "Promotions",
          path: "/promotions",
          icon: <MoneyOff />
        }
      : null,
    permissionsObject.feesAndCharges.sidebar
      ? {
          name: "Fees & Charges",
          path: "",
          icon: <MonetizationOn />,
          isOpen: true,
          childLinks: [
            permissionsObject.feesAndCharges.manageFees
              ? {
                  name: "Manage Fees",
                  path: "/fees"
                }
              : null,
            permissionsObject.feesAndCharges.insurancePlans
              ? {
                  name: "Manage TPP",
                  path: "/insurance-plans"
                }
              : null
          ]
        }
      : null,
    permissionsObject.documents.sidebar
      ? {
          name: "Documents",
          path: "",
          icon: <DocumentScanner />,
          isOpen: true,
          childLinks: [
            permissionsObject.documents.document_library
              ? { name: "Document Library", path: "/documents/document-library" }
              : null,
            permissionsObject.documents.esign_status
              ? { name: "E-Sign Status", path: "/documents/status" }
              : null
          ]
        }
      : null,
    permissionsObject.facilities.sidebar
      ? {
          name: "Facility Management",
          path: "",
          icon: <Business />,
          isOpen: true,
          childLinks: [
            permissionsObject.facilities.manage_facilities
              ? { name: "Manage Facilities", path: "/facility-management/manage-facilities" }
              : null,
            permissionsObject.facilities.add_facility
              ? { name: "Add Facility", path: "/facility-management/add-facility" }
              : null
          ]
        }
      : null,
    permissionsObject.revenue.sidebar
      ? {
          name: "Revenue",
          path: "",
          icon: <PriceChange />,
          isOpen: true,
          childLinks: [
            permissionsObject.revenue.occupantRateChanges
              ? { name: "Occupant Rate Changes", path: "/rate-changes" }
              : null,
            permissionsObject.revenue.askingRateChanges
              ? { name: "Asking Rate Changes", path: "/asking-rate-changes" }
              : null,
            permissionsObject.revenue.askingRateChanges
              ? { name: "Batch Promotions", path: "/promotions/bulk" }
              : null
          ]
        }
      : null,
    permissionsObject.automations.sidebar
      ? {
          name: "Automations",
          path: "",
          icon: <Settings />,
          isOpen: true,
          childLinks: [
            permissionsObject.automations.systemAutomations
              ? {
                  name: "System Automations",
                  path: "/delinquency-automations"
                }
              : null,
            permissionsObject.automations.webhooks
              ? {
                  name: "Webhooks",
                  path: "/webhooks"
                }
              : null
          ]
        }
      : null,
    permissionsObject.accounting.sidebar
      ? {
          name: "Accounting",
          path: "",
          icon: <AccountBalance />,
          isOpen: true,
          childLinks: [
            permissionsObject.accounting.revenueClasses
              ? {
                  name: "Revenue Classes",
                  path: "/accounting/revenue-classes"
                }
              : null,
            permissionsObject.accounting.chartOfAccounts
              ? {
                  name: "Chart of Accounts",
                  path: "/accounting/chart-of-accounts"
                }
              : null
          ]
        }
      : null,
    permissionsObject.marketing.sidebar
      ? {
          name: "Marketing",
          path: "",
          icon: <Campaign />,
          isOpen: true,
          childLinks: [
            permissionsObject.marketing.dataManagement
              ? {
                  name: "Data Management",
                  path: "/marketing/data-management"
                }
              : null
          ]
        }
      : null,
    permissionsObject.taskManagement.sidebar
      ? {
          name: "Task Management",
          path: "",
          icon: <FormatListNumbered />,
          isOpen: true,
          childLinks: [
            permissionsObject.taskManagement.manageTasks
              ? { name: "Manage Tasks", path: "/task-management" }
              : null,
            permissionsObject.taskManagement.taskHistory
              ? { name: "Task History", path: "/task-management/task-history" }
              : null
          ]
        }
      : null,
    permissionsObject.administratorSettings.sidebar
      ? {
          name: "Administrator Settings",
          path: "",
          icon: <Settings />,
          isOpen: true,
          childLinks: [
            permissionsObject.administratorSettings.globalSettings
              ? { name: "Global Settings", path: "/global-settings" }
              : null,
            permissionsObject.administratorSettings.managePermissions
              ? { name: "Manage Permissions", path: "/manage-permissions" }
              : null,
            permissionsObject.administratorSettings.configurationManager
              ? { name: "Configuration Manager", path: "/config-manager" }
              : null
          ]
        }
      : null
  ];

  return adminLinks.filter((link) => link !== null);
};
