import { Charge, CreditInformation, CreditsSliceState } from "./creditsSliceState";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../rootStore";
import { getProjectedLedgerItems } from "src/store/thunks/ledger/get/getProjectedLedgerItems";
import { getUnitCharges } from "src/store/thunks/credits/getUnitCharges";
import { issueCredit } from "src/store/thunks/credits/issueCredit";
import { uniqueId } from "lodash";

//'Rent Credit',  'TPP Credit', 'Fee Credit',  'Merchandise Credit', 'Other Credit'

export const futureCreditDescriptionMap: { [key: string]: string } = {
  "Rent Credit": "Unit",
  "TPP Credit": "TPP",
  "Fee Credit": "Fee",
  "Merchandise Credit": "Merchandise",
  "Other Credit": ""
};

const storeCurrentCredit = (
  state: CreditsSliceState,
  action: PayloadAction<{
    current_charges: Charge[];
    requested_projected_future_charges: Charge[];
    current_balance_total_formatted: string;
  }>
) => {
  const currentItems = action.payload.current_charges || [];

  //we need to link the credit with the ledger_item
  const currentCharges = currentItems?.filter((charge: Charge) => !charge.description.includes("Credit")) as Charge[];

  const chargesWithCredits = currentCharges?.map((charge: Charge) => {
    const amount = currentItems.find(
      (item) => charge.id === item.ledger_item_id_credit_applies_to
    )?.issued_credit_amount_formatted;
    if (amount) {
      charge.applied_credit = amount;
    }
    return charge;
  });

  state.currentCharges = chargesWithCredits;
};

const storeFutureCredit = (
  state: CreditsSliceState,
  action: PayloadAction<{
    current_charges: Charge[];
    requested_projected_future_charges: Charge[];
    current_balance_total_formatted: string;
  }>
) => {
  //future items don't have ID so we need to link them using the description and charge date
  const futureItems: Charge[] = action.payload.requested_projected_future_charges || [];

  const futureItemsWithId = futureItems?.map((charge) => {
    charge.id = Number(uniqueId());
    return charge;
  });

  const futureCharges: Charge[] = [];
  const futureCredits: Charge[] = [];

  futureItemsWithId.forEach((item) => {
    if (item.description.includes("Credit")) {
      futureCredits.push(item);
    } else {
      futureCharges.push(item);
    }
  });

  futureCredits.forEach((credit) => {
    const chargeForCredit = futureCharges.find(
      (charge) =>
        charge.charge_date === credit.charge_date &&
        charge.chargeable_id === credit.parent_charge_id &&
        charge?.chargeable_type?.toLowerCase().includes(futureCreditDescriptionMap[credit.description].toLowerCase())
    );

    if (chargeForCredit) {
      chargeForCredit.applied_credit = credit.issued_credit_amount_formatted as string;
    }
  });

  state.creditInformation = {
    current_balance_total_formatted: action.payload.current_balance_total_formatted
  } as CreditInformation;
  state.futureCharges = futureCharges;
};

export const initialState: CreditsSliceState = {
  currentCharges: [],
  futureCharges: [],
  chargesLoading: false
};

export const creditsSlice = createSlice({
  name: "credits",
  initialState,
  reducers: {
    resetCredits: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUnitCharges.pending, (state) => {
        state.chargesLoading = true;
      })
      .addCase(getUnitCharges.fulfilled, (state, action) => {
        storeFutureCredit(state, action);
        state.chargesLoading = false;
      })
      .addCase(getUnitCharges.rejected, (state) => {
        state.chargesLoading = false;
      })
      .addCase(getProjectedLedgerItems.pending, (state) => {
        state.chargesLoading = true;
      })
      .addCase(getProjectedLedgerItems.fulfilled, (state, action) => {
        storeCurrentCredit(state, action);
        storeFutureCredit(state, action);
        state.chargesLoading = false;
      })
      .addCase(getProjectedLedgerItems.rejected, (state) => {
        state.chargesLoading = false;
      })
      .addCase(issueCredit.fulfilled, (state, action) => {
        storeCurrentCredit(
          state,
          action as PayloadAction<{
            current_charges: Charge[];
            requested_projected_future_charges: Charge[];
            current_balance_total_formatted: string;
          }>
        );
      });
  }
});

export const selectChargesLoading = (state: RootState) => state.credits.chargesLoading;
export const selectCurrentCharges = (state: RootState) => state.credits.currentCharges;
export const selectFutureCharges = (state: RootState) => state.credits.futureCharges;
export const selectCreditInformation = (state: RootState) => state.credits.creditInformation;

export const { resetCredits } = creditsSlice.actions;

export default creditsSlice.reducer;
