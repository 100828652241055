import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "DelinquentOccupant" })((theme) => ({
  filtersContainer: {
    flexBasis: "initial",
    maxWidth: "initial"
  },
  inactiveFilter: {
    color: theme.palette.text.disabled
  },
  activeFilter: {
    color: theme.palette.text.primary
  },
  buttonBase: {
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText
  }
}));

export default useStyles;
