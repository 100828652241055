import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const createESignDocumentForAutoPay =
createAsyncThunk("createESignDocumentForAutoPay",
  ({ selectedDocuments, email, sendViaEmail = false, ledgerId }:
   {selectedDocuments: any, email?: string | null, sendViaEmail?: boolean, ledgerId?: number},
  { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const ledger_id = ledgerId || state.moveIn.moveInConfirmation?.ledger.id ||
  state.eSignStatus.selectedESignDocument?.unit?.ledger?.id;
    const documents = selectedDocuments.map((document: any) => document.id);
    const occupantId = state.occupant.occupant?.id;

    const body = {
      documents,
      ledger_id,
      email,
      sent_via_email: sendViaEmail,
      esign_document_id: state.eSignStatus.selectedESignDocument?.id || null
    };

    return axios
      .post(`/api/v1/occupants/${occupantId}/ledgers/${ledger_id}/create-esign-document-for-autopay`, body)
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(
            showSnackbar({
              message: errorMessage,
              variant: "error"
            })
          );

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an error creating the Autobill eSign Document";

        if (err && err.response && err.response.status && err.response.status === 400) {
          errorMessage += `: ${err.response.data.detail}`;
        }

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  });
