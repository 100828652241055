import { makeStyles } from "../../../../../makeStyles";

const useStyles = makeStyles({ label: "DisplayUnitsTable" })((theme) => ({
  root: {
    border: 0,
    "& .MuiDataGrid-columnSeparator": {
      display: "none"
    },
    "& .MuiDataGrid-colCellTitle": {
      overflow: "unset"
    },
    height: "auto",
    marginBottom: theme.spacing(5)
  },
  addUnitButton: {
    textTransform: "capitalize",
    color: theme.palette.primary.contrastText
  },
  noResultsOverlay: {
    background: theme.palette.common.white,
    zIndex: 200
  }
}));

export default useStyles;
