import { Button, Grid, SelectChangeEvent, Typography } from "@mui/material";
import {
  selectSelectedLedger,
  selectUnitLedgers,
  setSelectedLedger
} from "src/store/reducers/ledgersSlice/ledgersSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { FileDownloadOutlined } from "@mui/icons-material";
import LedgerPrintMenu from "../LedgerPrintMenu/LedgerPrintMenu";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import React, { useContext } from "react";
import useStyles from "./LedgerPrintAndSelectUnit.styles";
import { LedgerViewContext } from "../LedgerViewContext";

const LedgerPrintAndSelectUnit = () => {
  const { classes } = useStyles();
  const unitLedgers = useAppSelector(selectUnitLedgers);
  const selectedLedger = useAppSelector(selectSelectedLedger);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { changeLedger } = useContext(LedgerViewContext);
  const handleUnitChange = (e: SelectChangeEvent) => {
    const ledger = unitLedgers.filter((ledger) => ledger.id === +e.target.value)[0];
    dispatch(setSelectedLedger(ledger));
    changeLedger(ledger.occupant_id, ledger.id);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseExportMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container pt={2} spacing={2}
      justifyContent={"flex-end"}>
      <Grid container width={"auto"} alignItems={"center"}
        item>
        <Typography id={"ledger-select"} fontWeight={700} pr={1}>
          View unit ledger for:{" "}
        </Typography>
        <Grid item>
          <PMSSelect
            id={"ledger-select"}
            variant={"standard"}
            changeHandler={handleUnitChange}
            value={selectedLedger?.id}
          >
            <option value={0} disabled={true}>
              - Select Unit -
            </option>
            {unitLedgers.map((ledger) => (
              <option key={ledger.id} value={ledger.id}>
                {ledger.unit?.unit_number}
              </option>
            ))}
          </PMSSelect>
        </Grid>
      </Grid>
      <Grid item pr={2}>
        <Button onClick={handleClick} endIcon={<FileDownloadOutlined sx={{ color: "#306DC8" }} />}>
          <Typography className={classes.buttonText} pl={1} pr={3}>
            Export as:{" "}
          </Typography>
        </Button>

        <LedgerPrintMenu anchorEl={anchorEl} handleCloseExportMenu={handleCloseExportMenu} />
      </Grid>
    </Grid>
  );
};

export default LedgerPrintAndSelectUnit;
