import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Facility } from "../../../models/Facility";
import { RootState } from "../../rootStore";
import { SelectedAdminFacilitySliceState } from "./SelectedAdminFacilitySliceState";

export const initialState: SelectedAdminFacilitySliceState = {
  selectedAdminFacility: null,
  selectedAdminFacilityLoading: false,
  selectedAdminFacilityError: ""
};

export const selectedAdminFacilitySlice = createSlice({
  name: "selectedAdminFacility",
  initialState,
  reducers: {
    setSelectedAdminFacility: (state, action: PayloadAction<Facility>) => {
      state.selectedAdminFacility = action.payload;
    },
    resetSelectedAdminFacilitySlice: (state) => {
      Object.assign(state, initialState);
    }
  }
});

export const selectSelectedAdminFacility = (state: RootState) => state.selectedAdminFacility.selectedAdminFacility;

export const {
  setSelectedAdminFacility,
  resetSelectedAdminFacilitySlice
} = selectedAdminFacilitySlice.actions;

export default selectedAdminFacilitySlice.reducer;
