import { Box, Button, Grid, IconButton, Modal, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  selectGenerateDocumentModal,
  selectOccupantSelectedDocuments,
  setChangeOwnerShipStep,
  setGenerateDocumentModalOpened,
  setOccupantSelectedDocuments
}
  from "src/store/reducers/occupantSlice/occupantSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";

import { Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import { generateDocument } from "src/store/thunks/ledger/generateDocument/generateDocument";
import { getAllDocumentTemplates } from "src/store/thunks/documentTemplates/getAll/getAllDocumentTemplates";
import { selectDocumentTemplates } from "src/store/reducers/documentsSlice/documentsSlice";
import { setSelectedLedger } from "src/store/reducers/ledgersSlice/ledgersSlice";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import useStyles from "./GenerateDocumentModal.styles";

const GenerateDocumentModal = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { opened, submitting } = useAppSelector(selectGenerateDocumentModal);
  const documentTemplates = useAppSelector(selectDocumentTemplates);
  const selectedDocuments = useAppSelector(selectOccupantSelectedDocuments);
  const [currentSelectedDocument, setCurrentSelectedDocument] = useState(0);

  const closeModal = () => {
    dispatch(setGenerateDocumentModalOpened(false));
    dispatch(setSelectedLedger(null));
    dispatch(setOccupantSelectedDocuments([]));
  };

  useEffect(() => {
    dispatch(getAllDocumentTemplates());
  }, []);

  const handleSubmit = () => {
    dispatch(generateDocument({ selectedDocuments })).then((resp) => {
      if (!resp.type.includes("rejected")) {
        dispatch(
          showSnackbar({
            message: "Documents Generated Successfully",
            variant: "success"
          })
        );
        closeModal();
        dispatch(setChangeOwnerShipStep(4));
      }
    });
  };

  const handleChange = (value: number) => {
    setCurrentSelectedDocument(value);
    if (documentTemplates.length > 0) {
      dispatch(
        setOccupantSelectedDocuments([
          ...selectedDocuments,
          {
            id: +value,
            name: documentTemplates.find((document) => document.id === +value)?.name,
            isAutomaticallyAdded: false
          }
        ])
      );
    }
    setCurrentSelectedDocument(0);
  };

  const documentItems = documentTemplates.map((document) => (
    <option
      data-testid={`document-${document.id}`}
      key={document.id}
      value={document.id}
      disabled={!!selectedDocuments.find((d) => d.id === document.id)}
    >
      {document.name}
    </option>
  ));

  return (
    <Modal
      open={opened}
      onClose={closeModal}
      aria-labelledby={"Modal Component"}
      aria-describedby={"A Modal component."}
      className={classes.modal}
      data-testid={"modal-cancel-deal"}
    >
      <Grid
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        spacing={2}
        className={classes.container}
        pb={2}
      >
        <Box display={"flex"} justifyContent={"space-between"} className={classes.header}>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography variant={"h6"}>Generate Document</Typography>
          </Box>
          <Box>
            <IconButton onClick={closeModal}>
              <Close />
            </IconButton>
          </Box>
        </Box>
        <Box mt={2} mb={2}>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam.
          </Typography>
        </Box>
        <Box width={"100%"}>
          <Typography variant={"body1"} fontWeight={"bold"}>
            Documents added to lease package:
          </Typography>
          <Box mt={2} border={1} borderRadius={"4px"}
            borderColor={"#ddd"}>
            <Box borderBottom={1} p={2} borderColor={"#ddd"}
              fontWeight={"bold"}>
              Document Name
            </Box>
            <Box p={2}>
              {selectedDocuments.length > 0
                ? (
                    selectedDocuments.map((document) => (
                      <Box key={document.id} mb={1} display={"flex"}
                        justifyContent={"space-between"}>
                        <Link to={"#"}>{document.name}</Link>
                        {document.isAutomaticallyAdded
                          ? (
                            <Typography variant={"caption"}>automatically added</Typography>
                            )
                          : null}
                      </Box>
                    ))
                  )
                : (
                  <Grid container justifyContent={"center"}>
                    <Typography fontStyle={"italic"} variant={"caption"} textAlign={"center"}>
                      No document(s) added to lease package
                    </Typography>
                  </Grid>
                  )}
            </Box>
          </Box>
        </Box>
        <Box width={"100%"} mt={3}>
          <Typography variant={"body1"} fontWeight={"bold"}>
            Add document to Generate:
          </Typography>
          <Box width={"50%"}>
            <PMSSelect
              id={"notification-categories"}
              size={"small"}
              value={currentSelectedDocument}
              changeHandler={(e) => handleChange(e.target.value)}
            >
              <option value={0} selected>
                - Choose Document -
              </option>
              {documentItems}
            </PMSSelect>
          </Box>
          <Box width={"100%"} display={"flex"} justifyContent={"flex-end"}
            mt={3} gap={"1rem"}>
            <Button
              color={"error"}
              variant={"text"}
              size={"large"}
              onClick={closeModal}
            >
              Cancel
            </Button>
            <LoadingButton
              className={classes.buttonPrimary}
              color={"primary"}
              variant={"contained"}
              size={"large"}
              onClick={handleSubmit}
              disabled={submitting || selectedDocuments.length === 0}
              loading={submitting}
            >
              Generate
            </LoadingButton>
          </Box>
        </Box>
      </Grid>
    </Modal>
  );
};

export default GenerateDocumentModal;
