import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getAllProductTypeAttributes = createAsyncThunk(
  "getAllProductTypeAttributes",
  (_, { dispatch, rejectWithValue }) => {
    return axios.get("/api/v1/product-type-attributes")
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = "There was an error getting the Product Type attributes";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
