import { TimeZone } from "../../models/TimeZone";

export const timeZones: TimeZone[] = [
  {
    displayName: "Eastern (UTC - 4:00)",
    name: "America/New_York"
  },
  {
    displayName: "Central (UTC - 5:00)",
    name: "America/Chicago"
  },
  {
    displayName: "Mountain (UTC - 6:00)",
    name: "America/Denver"
  },
  {
    displayName: "Mountain (No Daylight Savings) (UTC - 7:00)",
    name: "America/Phoenix"
  },
  {
    displayName: "Pacific (UTC - 7:00)",
    name: "America/Los_Angeles"
  },
  {
    displayName: "Alaska (UTC - 8:00)",
    name: "America/Anchorage"
  },
  {
    displayName: "Hawaii (UTC - 9:00)",
    name: "America/Adak"
  },
  {
    displayName: "Hawaii (No Daylight Savings) (UTC - 10:00)",
    name: "America/Honolulu"
  },
  {
    displayName: "UTC",
    name: "UTC"
  }
];
