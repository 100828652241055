import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "ReviewAndApplyPaymentTable" })((theme) => ({
  table: {
    border: "none",
    backgroundColor: "#EFF4FB",
    marginBottom: theme.spacing(2)
  }
}));

export default useStyles;
