import * as Yup from "yup";
import { percentageValidator } from "../../../../utils/commonRegexes";

const validationSchema = Yup.object().shape({
  revenueClassName: Yup.string().required("Revenue Class Name required"),
  chartAccountId: Yup.string().required("Associated Account required"),
  stateTax: Yup.string().required("State Tax required").matches(percentageValidator, "Incorrect Format"),
  countyTax: Yup.string().required("County Tax required").matches(percentageValidator, "Incorrect Format"),
  cityTax: Yup.string().required("City Tax required").matches(percentageValidator, "Incorrect Format"),
  localTax: Yup.string().required("Local Tax required").matches(percentageValidator, "Incorrect Format")
});

export default validationSchema;
