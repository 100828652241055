import { AppBar, Fab, Grid, IconButton, Toolbar, Typography } from "@mui/material";
import { ChevronLeft, ChevronRight, Close, CloseFullscreen, ZoomOutMap } from "@mui/icons-material";
import {
  selectShowDrawer,
  toggleDrawer,
  toggleDrawerTransitionState
} from "src/store/reducers/navigationSlice/navigationSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";

import AppNotifications from "../AppNotifications/AppNotifications";
import FacilitySelector from "../FacilitySelector/FacilitySelector";
import React from "react";
import SIPButton from "../SIPButton/SIPButton";
import UserMenu from "../UserMenu/UserMenu";
import useStyles from "./NavBar.styles";
import {
  selectCallDuration,
  selectIsDisplayed,
  selectIsInteractable,
  selectStatus,
  setInputNumber,
  setIsDisplayed
} from "src/store/reducers/appSoftphoneSlice/appSoftphoneSlice";
import CallIcon from "@mui/icons-material/Call";
import { formatCallDuration } from "src/utils/formatPhone/formatCallDuration";
import { SoftphoneDisplayMode, SoftphoneStatus } from "src/store/reducers/appSoftphoneSlice/appSoftphoneSliceState";

const NavBar: React.FC = () => {
  const showDrawer = useAppSelector(selectShowDrawer);
  const phoneIsDisplayed = useAppSelector(selectIsDisplayed);
  const phoneStatus = useAppSelector(selectStatus);
  const phoneIsInteractable = useAppSelector(selectIsInteractable);
  const callDuration = useAppSelector(selectCallDuration);

  const { classes } = useStyles({
    showDrawer: showDrawer,
    isCalling: phoneStatus === SoftphoneStatus.Calling || phoneStatus === SoftphoneStatus.Connected
  });
  const dispatch = useAppDispatch();

  const handleToggle = () => {
    dispatch(toggleDrawer());
    dispatch(toggleDrawerTransitionState("active"));
  };

  const openPhoneDisplay = () => {
    dispatch(setIsDisplayed(SoftphoneDisplayMode.Display));
  };

  const getMinizedDescription = () => {
    if (phoneIsDisplayed === SoftphoneDisplayMode.MinimizeFollowUp) {
      return "New Lead";
    }

    return "Outbound Call";
  };

  const getMinimizedSoftphoneDescription = () => {
    if (phoneStatus === SoftphoneStatus.Calling) {
      return `${getMinizedDescription()} - Calling`;
    }

    if (phoneStatus === SoftphoneStatus.Connected) {
      return `${getMinizedDescription()} - ${formatCallDuration(callDuration)}`;
    }

    return getMinizedDescription();
  };

  const navDrawerIcon = showDrawer
    ? (
      <ChevronLeft data-testid={"hide-drawer-icon"} />
      )
    : (
      <ChevronRight data-testid={"expand-drawer-icon"} />
      );

  return (
    <AppBar
      color={"primary"}
      position={"relative"}
      elevation={0}
      className={classes.appBar}
      data-testid={"navigation-bar"}
    >
      <Toolbar className={classes.toolbar}>
        <Fab
          color={"primary"}
          size={"small"}
          aria-label={"toggle navigation drawer"}
          data-testid={"toggle-drawer-button"}
          onClick={handleToggle}
          className={classes.toggleNavButton}
        >
          {navDrawerIcon}
        </Fab>
        {phoneIsDisplayed === "MINIMIZE" || phoneIsDisplayed === SoftphoneDisplayMode.MinimizeFollowUp
          ? (
            <Grid
              alignItems={"center"}
              className={classes.outboundCall}
              display={"flex"}
              alignSelf={"end"}
              justifyContent={"center"}
          >
              <Typography variant={"subtitle1"} mr={1}>
                {getMinimizedSoftphoneDescription()}
              </Typography>
              <IconButton
                onClick={() => {
                  if (phoneIsDisplayed === SoftphoneDisplayMode.MinimizeFollowUp) {
                    dispatch(setIsDisplayed(SoftphoneDisplayMode.DisplayFollowUp));
                    return;
                  }
                  dispatch(setIsDisplayed(SoftphoneDisplayMode.Display));
                }}
            >
                <ZoomOutMap className={classes.outboundCallIcon} />
              </IconButton>
              {!(phoneStatus === SoftphoneStatus.Calling || phoneStatus === SoftphoneStatus.Connected) && (
              <IconButton
                onClick={() => {
                  dispatch(setIsDisplayed(SoftphoneDisplayMode.Hide));
                  dispatch(setInputNumber(""));
                }}
              >
                <Close className={classes.outboundCallIcon} />
              </IconButton>
              )}
            </Grid>
            )
          : null}
        <Grid item container xs={8}
          lg={7} direction={"row"} alignItems={"center"}
          className={classes.buttonContainer}>
          <Grid item xs={9.5} pr={1}
            container alignItems={"center"} className={classes.facilitySelectorCell}>
            <Grid item xs={"auto"} pr={1}
              container>
              <Typography className={classes.viewText} component={"label"} htmlFor={"facility-selector"}>
                View Facility:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FacilitySelector navBar />
            </Grid>
          </Grid>
          <Grid item container justifyContent={"center"}
            xs={1.325} lg={0.8}>
            <SIPButton onClick={openPhoneDisplay} disabled={!phoneIsInteractable} />
          </Grid>
          <Grid item container justifyContent={"center"}
            xs={1.325} lg={0.8}>
            <AppNotifications />
          </Grid>
          <Grid item container justifyContent={"center"}
            xs={1.325} lg={0.8}>
            <UserMenu />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
