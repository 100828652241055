import React, { ReactElement, useState } from "react";
import { getUnitPaymentStatus } from "src/utils/getUnitPaymentStatus";
import PaymentStatusText from "./PaymentStatusText";

interface PaymentStatusProp {
  currentBalance: number;
  delinquentDays: number;
  startDate?: string;
}
const PaymentStatus: React.FC<PaymentStatusProp> = ({ currentBalance, delinquentDays, startDate }): ReactElement => {
  const [status] = useState<string>(getUnitPaymentStatus(startDate, currentBalance, delinquentDays));

  return (
    <PaymentStatusText status={status} />
  );
};

export default PaymentStatus;
