import React from "react";
import { Route } from "react-router";
import AddMoveInEmergencyContact
  from "src/pages/Deals/CreateMoveIn/AddMoveInEmergencyContact/AddMoveInEmergencyContact";
import AddMoveInOccupantContact from "src/pages/Deals/CreateMoveIn/AddMoveInOccupantContact/AddMoveInOccupantContact";
import AddMoveInOccupantNameAndLocation
  from "src/pages/Deals/CreateMoveIn/AddMoveInOccupantNameAndLocation/AddMoveInOccupantNameAndLocation";
import GetMoveInStarted from "src/pages/Deals/CreateMoveIn/GetMoveInStarted/GetMoveInStarted";
import MoveInConfirmation from "src/pages/Deals/CreateMoveIn/MoveInConfirmation/MoveInConfirmation";
import ProcessMoveIn from "src/pages/Deals/CreateMoveIn/ProcessMoveIn/ProcessMoveIn";
import ReviewMoveIn from "src/pages/Deals/CreateMoveIn/ReviewMoveIn/ReviewMoveIn";
import SelectOccupantForMoveIn from "src/pages/Deals/CreateMoveIn/SelectOccupantForMoveIn/SelectOccupantForMoveIn";
import SelectPromotionForMoveIn from "src/pages/Deals/CreateMoveIn/SelectPromotionForMoveIn/SelectPromotionForMoveIn";
import SelectReservation from "src/pages/Deals/CreateMoveIn/SelectReservation/SelectReservation";
import SelectUnitForMoveIn from "src/pages/Deals/CreateMoveIn/SelectUnitForMoveIn/SelectUnitForMoveIn";
import CreateMoveIn from "src/pages/Deals/CreateMoveIn/CreateMoveIn";

export const groupName = "occupants/create-move-in";

export const CreateMoveInRoutes = (
  <>
    <Route
      path={groupName}
      element={
        <CreateMoveIn>
          <GetMoveInStarted />
        </CreateMoveIn>
      }
    />
    <Route
      path={`${groupName}/select-occupant`}
      element={
        <CreateMoveIn>
          <SelectOccupantForMoveIn />
        </CreateMoveIn>
      }
    />
    <Route
      path={`${groupName}/occupant-name-and-location`}
      element={
        <CreateMoveIn>
          <AddMoveInOccupantNameAndLocation />
        </CreateMoveIn>
      }
    />
    <Route
      path={`${groupName}/occupant-contact`}
      element={
        <CreateMoveIn>
          <AddMoveInOccupantContact />
        </CreateMoveIn>
      }
    />
    <Route
      path={`${groupName}/emergency-contact`}
      element={
        <CreateMoveIn>
          <AddMoveInEmergencyContact />
        </CreateMoveIn>
      }
    />
    <Route
      path={`${groupName}/select-reservation`}
      element={
        <CreateMoveIn>
          <SelectReservation />
        </CreateMoveIn>
      }
    />
    <Route
      path={`${groupName}/select-unit`}
      element={
        <CreateMoveIn>
          <SelectUnitForMoveIn />
        </CreateMoveIn>
      }
    />
    <Route
      path={`${groupName}/select-promotion`}
      element={
        <CreateMoveIn>
          <SelectPromotionForMoveIn />
        </CreateMoveIn>
      }
    />
    <Route
      path={`${groupName}/process-move-in`}
      element={
        <CreateMoveIn>
          <ProcessMoveIn />
        </CreateMoveIn>
      }
    />
    <Route
      path={`${groupName}/review-move-in`}
      element={
        <CreateMoveIn>
          <ReviewMoveIn />
        </CreateMoveIn>
      }
    />
    <Route
      path={`${groupName}/confirm-move-in`}
      element={
        <CreateMoveIn>
          <MoveInConfirmation />
        </CreateMoveIn>
      }
    />
  </>
);

export default CreateMoveInRoutes;
