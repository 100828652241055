import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Chart of Accounts" })((theme) => ({
  buttonText: {
    textTransform: "capitalize"
  },
  facilitySelector: {
    backgroundColor: theme.palette.common.white
  },
  modal: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
    maxWidth: "35%",
    minHeight: "10rem",
    borderRadius: "1rem"
  },
  modalRemove: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  newSettingButton: {
    textTransform: "capitalize",
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.common.white,
    width: "9rem"
  },
  displayContainer: {
    minHeight: "25rem"
  },
  newItemButton: {
    marginTop: theme.spacing(2),
    textTransform: "capitalize",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.contrastText,
    border: `${theme.palette.primary.contrastText} 1px solid`
  }
}));

export default useStyles;
