import { Button, Grid, Modal, Typography } from "@mui/material";
import React, { FC, useEffect, useMemo } from "react";
import {
  resetOccupantInformationSlice,
  selectOccupantDetailsLoading
} from "src/store/reducers/occupantInformationSlice/occupantInformationSlice";
import {
  resetOccupantSlice,
  selectChangeOwnerShipStep,
  selectChangeOwnershipIsEnabled,
  selectChangeOwnershipModalOpened,
  selectConfirmChangeOwnershipModalOpened,
  selectMissingFieldsChangeOwnershipModalOpened,
  selectUndoChangeOwnershipModalOpened,
  setChangeOwnerShipStep,
  setChangeOwnershipIsEnabled,
  setChangeOwnershipModalOpened,
  setConfirmChangeOwnershipModalOpened,
  setMissingFieldsChangeOwnershipModalOpened,
  setUndoChangeOwnershipModalOpened
} from "src/store/reducers/occupantSlice/occupantSlice";
import {
  selectLedgersModalOpen,
  selectRentedUnitsModalOpen,
  setLedgersModalOpen
} from "src/store/reducers/ledgersSlice/ledgersSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { useLocation, useParams } from "react-router";
import { ArrowBack } from "@mui/icons-material";
import BillingInformation from "./EditOccupantTabs/BillingInformation/BillingInformation";
import { Breadcrumb } from "src/models/Breadcrumb";
import CustomModal from "./components/CustomModal/CustomModal";
import EditOccupantEmergencyContactInformation
  from "./EditOccupantTabs/EditOccupantEmergencyContactInformation/EditOccupantEmergencyContactInformation";
import EditOccupantInformation from "./EditOccupantTabs/EditOccupantInformation/EditOccupantInformation";
import EditRentalAmountModal from "./EditRentalAmountModal/EditRentalAmountModal";
import LedgerView from "./LedgerView/LedgerView";
import OccupantDocuments from "./EditOccupantTabs/OccupantDocuments/OccupantDocuments";
import OccupantHistory from "./EditOccupantTabs/History/OccupantHistory";
import OccupantNotes from "./EditOccupantTabs/OccupantNotes/OccupantNotes";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import RentedUnits from "./EditOccupantTabs/RentedUnits/RentedUnits";
import TabView from "src/layouts/TabView/TabView";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import WarningModal from "src/components/modal/WarningModal";
import { changeOwnership } from "src/store/thunks/occupant/changeOwnership/changeOwnership";
import { getAllFacilitySettings } from "src/store/thunks/facilitySetting/getAll/getAllFacilitySettings";
import { getOccupant } from "src/store/thunks/occupant/getOne/getOccupant";
import { getOccupantTaxExemptDocument } from "src/store/thunks/occupantDocuments/getOccupantTaxExemptDocument";
import { resetAchSlice } from "src/store/reducers/achSlice/achSlice";
import { resetChangeOwnership } from "src/store/reducers/changeOwnership/changeOwnership";
import { resetCreditCardSlice } from "src/store/reducers/creditCardSlice/creditCardSlice";
import {
  resetEmergencyContactInformationSlice
} from "src/store/reducers/emergencyContactInformationSlice/emergencyContactInformationSlice";
import { resetNotesSlice } from "src/store/reducers/notesSlice/notesSlice";
import { selectIsTransfer } from "src/store/reducers/moveInSlice/moveInSlice";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { useNavigate } from "react-router-dom";
import useStyles from "./EditOccupant.styles";

interface EditOccupantProps {}

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Occupants"
  },
  {
    name: "Edit Occupant",
    bold: true
  }
];

const pageHeader = <PageHeader title={"Edit Occupant"} breadcrumbs={breadcrumbs} />;

const EditOccupant: FC<EditOccupantProps> = () => {
  const navigate = useNavigate();
  const pageLocation = useLocation();
  const ledgersModalOpen = useAppSelector(selectLedgersModalOpen);
  const dispatch = useAppDispatch();
  const modalOpened = useAppSelector(selectRentedUnitsModalOpen);
  const params = useParams<{ id: string }>();
  const { classes } = useStyles();
  const occupantLoading = useAppSelector(selectOccupantDetailsLoading);
  const changeOwnershipModalOpened = useAppSelector(selectChangeOwnershipModalOpened);
  const changeOwnershipIsEnabled = useAppSelector(selectChangeOwnershipIsEnabled);
  const changeOwnershipStep = useAppSelector(selectChangeOwnerShipStep);
  const undoChangeOwnershipModalOpened = useAppSelector(selectUndoChangeOwnershipModalOpened);
  const missingFieldsChangeOwnershipModalOpened = useAppSelector(selectMissingFieldsChangeOwnershipModalOpened);
  const confirmChangeOwnershipModalOpened = useAppSelector(selectConfirmChangeOwnershipModalOpened);
  const isTransfer = useAppSelector(selectIsTransfer);
  const selectedFacility = useAppSelector(selectSelectedFacility);

  const componentList = [
    {
      name: "Occupant Information",
      component: <EditOccupantInformation />
    },
    {
      name: "Billing Information",
      component: <BillingInformation />
    },
    {
      name: "Emergency Contact",
      component: <EditOccupantEmergencyContactInformation />
    },
    {
      name: "Rented Units",
      component: <RentedUnits />,
      disabled: changeOwnershipIsEnabled
    },
    {
      name: "Documents",
      component: <OccupantDocuments />,
      disabled: changeOwnershipIsEnabled
    },
    {
      name: "Occupant Notes",
      component: <OccupantNotes />,
      disabled: changeOwnershipIsEnabled
    },
    {
      name: "History",
      component: <OccupantHistory />,
      disabled: changeOwnershipIsEnabled
    }
  ];

  useEffect(() => {
    dispatch(getOccupantTaxExemptDocument({ occupantId: params.id! }));
    dispatch(getOccupant(params.id!));
    dispatch(resetNotesSlice());
    dispatch(getAllFacilitySettings(String(selectedFacility?.id)));
    dispatch(setChangeOwnerShipStep(3));

    return () => {
      dispatch(resetOccupantSlice());
      dispatch(resetOccupantInformationSlice());
      dispatch(resetEmergencyContactInformationSlice());
      dispatch(resetChangeOwnership());
      dispatch(resetAchSlice());
      dispatch(resetCreditCardSlice());
    };
  }, []);

  const handleChangeOwnership = () => {
    dispatch(setChangeOwnershipIsEnabled(true));
    dispatch(setChangeOwnershipModalOpened(false));
  };

  const handleSubmitChangeOwnership = () => {
    dispatch(changeOwnership(params.id!)).then((resp) => {
      if (!resp.type.includes("rejected")) {
        dispatch(setChangeOwnershipIsEnabled(false));
      }
    });

    dispatch(setConfirmChangeOwnershipModalOpened(false));
    navigate("/occupants/manage-occupants");
  };

  return (
    <ViewWrapper pageHeader={pageHeader}>
      <Grid mt={2} item xs={12}>
        <Button onClick={() => navigate("/occupants/manage-occupants")} startIcon={<ArrowBack />} variant={"text"}>
          Go back to Occupants
        </Button>
      </Grid>
      <TabView
        loading={occupantLoading}
        components={componentList}
        step={changeOwnershipStep}
        onChangeAction={(step) => {
          dispatch(setChangeOwnerShipStep(step));
        }}
      />
      <Modal
        open={ledgersModalOpen}
        onClose={() => dispatch(setLedgersModalOpen(false))}
        aria-labelledby={"Modal Component"}
        aria-describedby={"A Modal component."}
        className={classes.modal}
      >
        {/* this fragment here gets rid of a strange forwardRef console error locally. */}
        <>
          <LedgerView />
        </>
      </Modal>
      {modalOpened && <EditRentalAmountModal />}
      {changeOwnershipModalOpened && (
        <WarningModal
          open={changeOwnershipModalOpened}
          buttonLabel={"Changing Ownership"}
          onButtonClick={() => handleChangeOwnership()}
          onCloseClick={() => dispatch(setChangeOwnershipModalOpened(false))}
          title={"Changing Ownership"}
        >
          <>
            <Typography>
              You must fill out new <strong>Occupant Information</strong>,<strong>Billing Information</strong>, and{" "}
              <strong>Emergency Contact Information</strong> before ownership change can be completed.
            </Typography>
            <Typography mt={2}>Are you sure you want to change ownership?</Typography>
          </>
        </WarningModal>
      )}
      <CustomModal
        title={"Confirm to Undo Changes"}
        open={undoChangeOwnershipModalOpened}
        handleSubmit={() => {
          dispatch(setChangeOwnershipIsEnabled(false));
          dispatch(setUndoChangeOwnershipModalOpened(false));
        }}
        handleClose={() => dispatch(setUndoChangeOwnershipModalOpened(false))}
      >
        <Typography>Are you sure you want to undo changes?</Typography>
        <Typography mt={2}>All new information entered will be lost.</Typography>
      </CustomModal>
      <CustomModal
        title={"Save Unsuccessful"}
        open={missingFieldsChangeOwnershipModalOpened}
        confirmButtonLabel={"OKAY"}
        hasCancel={false}
        handleSubmit={() => {
          dispatch(setMissingFieldsChangeOwnershipModalOpened(false));
        }}
        handleClose={() => dispatch(setMissingFieldsChangeOwnershipModalOpened(false))}
      >
        <Typography>
          You must fill out new <strong>Occupant Information</strong>, <strong>Billing Information</strong>, and{" "}
          <strong>Emergency Contact Information</strong> before ownership change can be completed.
        </Typography>
        <Typography mt={2}>Please fill out any missing fields.</Typography>
      </CustomModal>
      <CustomModal
        title={"Confirm Change of Ownership"}
        open={confirmChangeOwnershipModalOpened}
        handleSubmit={() => handleSubmitChangeOwnership()}
        handleClose={() => dispatch(setConfirmChangeOwnershipModalOpened(false))}
      >
        <Typography>Are you sure you want to change ownership?</Typography>
        <Typography mt={2}>
          All new information entered will be updated and replace previous ownership information.
        </Typography>
      </CustomModal>
    </ViewWrapper>
  );
};

export default EditOccupant;
