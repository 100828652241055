import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ReviewAndPayBillingAddressSlicState } from "./reviewAndPayBillingAddressSliceState";
import { RootState } from "../../rootStore";

export const initialState: ReviewAndPayBillingAddressSlicState = {
  firstName: "",
  middleInitial: "",
  lastName: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  enableAutoBilling: false
};

export const reviewAndPayBillingAddress = createSlice({
  name: "reviewAndPayBillingAddress",
  initialState,
  reducers: {
    setFirstName: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload;
    },
    setMiddleInitial: (state, action: PayloadAction<string>) => {
      state.middleInitial = action.payload;
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload;
    },
    setAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload;
    },
    setCity: (state, action: PayloadAction<string>) => {
      state.city = action.payload;
    },
    setState: (state, action: PayloadAction<string>) => {
      state.state = action.payload;
    },
    setZip: (state, action: PayloadAction<string>) => {
      state.zip = action.payload;
    },
    setEnableAutoBilling: (state, action: PayloadAction<boolean>) => {
      state.enableAutoBilling = action.payload;
    },
    resetReviewAndPayBillingAddress: (state) => {
      Object.assign(state, initialState);
    }
  }
});

export const selectFirstName = (state: RootState) => state.reviewAndPayBillingAddress.firstName;
export const selectMiddleInitial = (state: RootState) => state.reviewAndPayBillingAddress.middleInitial;
export const selectLastName = (state: RootState) => state.reviewAndPayBillingAddress.lastName;
export const selectAddress = (state: RootState) => state.reviewAndPayBillingAddress.address;
export const selectCity = (state: RootState) => state.reviewAndPayBillingAddress.city;
export const selectState = (state: RootState) => state.reviewAndPayBillingAddress.state;
export const selectZip = (state: RootState) => state.reviewAndPayBillingAddress.zip;
export const selectEnableAutoBilling = (state: RootState) => state.reviewAndPayBillingAddress.enableAutoBilling;

export const {
  setFirstName,
  setMiddleInitial,
  setLastName,
  setAddress,
  setCity,
  setState,
  setZip,
  setEnableAutoBilling,
  resetReviewAndPayBillingAddress
} = reviewAndPayBillingAddress.actions;

export default reviewAndPayBillingAddress.reducer;
