import { Grid } from "@mui/material";
import { useFormik } from "formik";
import React, { FC, FormEvent, useState } from "react";
import { Reservation } from "src/models/Reservation";
import { useAppSelector } from "src/store/hooks";
import { selectDealFacility, selectDealType } from "src/store/reducers/dealInformationSlice/dealInformationSlice";
import { setCurrentStep } from "src/store/reducers/formsSlice/formsSlice";
import { selectSelectedProductType, selectSelectedUnit } from "src/store/reducers/selectedUnitSlice/selectedUnitSlice";
import { AppDispatch } from "src/store/rootStore";
import SelectUnit from "../SelectUnit";

export function handleGoBack(
  reservations: Reservation[], currentStep: number, dispatch: AppDispatch, pathname: string
) {
  if (pathname.includes("create-new-deal")) {
    return dispatch(setCurrentStep(currentStep - 1));
  }

  return reservations.length
    ? dispatch(setCurrentStep(currentStep - 1))
    : dispatch(setCurrentStep(currentStep - 2));
}

  interface SelectUnitInStepperProps {
    onSubmitHandler?: Function
  }

const SelectUnitInStepper: FC<SelectUnitInStepperProps> = ({ onSubmitHandler }) => {
  const dealFacility = useAppSelector(selectDealFacility);
  const selectedUnit = useAppSelector(selectSelectedUnit);
  const dealFacilityName = dealFacility ? dealFacility.legal_name : "";
  const [selectionError, setSelectionError] = useState("");

  const submitHandler = () => {
    if (!selectedUnit) {
      setSelectionError("You must select a Unit");
      return;
    }

    if (onSubmitHandler) {
      return onSubmitHandler();
    }
  };

  const formik = useFormik({
    initialValues: { dealFacility: dealFacilityName },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {
      return submitHandler();
    }
  });

  const stepperId = "select-unit";

  return (
    <Grid
      id={stepperId}
      mb={2}
      data-testid={stepperId}
      container
      component={"form"}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        formik.handleSubmit();
      }}
    >
      <SelectUnit validationError={selectionError} />
    </Grid>
  );
};

export default SelectUnitInStepper;
