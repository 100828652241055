import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  resetTablesSlice,
  selectOrder,
  selectOrderBy,
  selectPage,
  selectRowsPerPage,
  setOrder,
  setOrderBy,
  setPage,
  setRowsPerPage
} from "src/store/reducers/tablesSlice/tablesSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Breadcrumb } from "src/models/Breadcrumb";
import ConfirmDeleteModal from "./ConfirmDeleteModal/ConfirmDeleteModal";
import { DataGrid, GridCallbackDetails, GridSortModel } from "@mui/x-data-grid";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import { Search } from "@mui/icons-material";
import TableWrapper from "src/layouts/TableWrapper/TableWrapper";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { useGetWebhooksQuery } from "src/api/endpoints/webhooks";
import { useManageWebhooksTableColumns } from "./useManageWebhooksTableColumns";
import ManageWebhooksCTAButtons from "./components/CTAButton/ManageWebhooksCTAButton";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Automations"
  },
  {
    name: "Webhooks",
    bold: true
  }
];

const ManageWebhooks = () => {
  const dispatch = useAppDispatch();
  const page = useAppSelector(selectPage);
  const pageSize = useAppSelector(selectRowsPerPage);
  const order = useAppSelector(selectOrder);
  const orderBy = useAppSelector(selectOrderBy);
  const [searchValue, setSearchValue] = useState("");
  const [filter, _] = useState<number>(1);
  const permissions = useAppSelector(selectFacilityPermissions);
  const automationPermissions = createPermissions(permissions, "automations");

  const params = useMemo(() => ({
    page: page + 1,
    pageSize,
    rowsPerPage: pageSize,
    sortDirection: order!,
    sortBy: orderBy,
    filter,
    searchValue
  }), [page, pageSize, searchValue, order, orderBy]);

  const { data: webhooks, isLoading: webhooksLoading } = useGetWebhooksQuery(
    { params },
    { refetchOnMountOrArgChange: true }
  );

  const columns = useManageWebhooksTableColumns();

  const searchBar = (
    <TextField
      fullWidth
      id={"search-transactions-table"}
      placeholder={"Search"}
      size={"small"}
      InputProps={{
        endAdornment: (
          <InputAdornment position={"end"}>
            <Search />
          </InputAdornment>
        )
      }}
      variant={"outlined"}
      onChange={(e) => setSearchValue(e.target.value)}
    />
  );

  const pageHeader = <PageHeader title={"Manage Webhooks"} breadcrumbs={breadcrumbs} />;

  const handleChangeSortOrder = (model: GridSortModel, details: GridCallbackDetails) => {
    dispatch(setOrder(model[0]?.sort));
    dispatch(setOrderBy(model[0]?.field));
  };

  useEffect(() => {
    dispatch(resetTablesSlice());
  }, []);

  return (
    <ViewWrapper pageHeader={pageHeader}>
      {automationPermissions.create
        ? (
          <>
            <TableWrapper
              title={"Manage Webhooks"}
              functionButton={automationPermissions.create ? <ManageWebhooksCTAButtons/ > : null}
              searchBar={searchBar}
              table={
                <DataGrid
                  autoHeight
                  disableSelectionOnClick
                  rows={webhooks?.data || []}
                  columns={columns}
                  sortingMode={"server"}
                  paginationMode={"server"}
                  onSortModelChange={handleChangeSortOrder}
                  rowCount={webhooks?.meta.total || 0}
                  rowsPerPageOptions={[5, 25, 50]}
                  onPageSizeChange={(newPageSize) => dispatch(setRowsPerPage(newPageSize))}
                  loading={webhooksLoading}
                  pageSize={pageSize}
                  page={page}
                  hideFooterSelectedRowCount={true}
                  sx={{
                    "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
                      outline: "none",
                      boxShadow: "none"
                    },
                    "& .MuiDataGrid-row:focus, & .MuiDataGrid-row:focus-within": {
                      outline: "none",
                      boxShadow: "none"
                    },
                    "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
                      outline: "none",
                      boxShadow: "none"
                    }
                  }}
                  onPageChange={(newPage) => dispatch(setPage(newPage))}
                />
              }
            />
            <ConfirmDeleteModal />
          </>
          )
        : (
          <h1>You do not have permission to view this page</h1>
          )
      }
    </ViewWrapper>
  );
};

export default ManageWebhooks;
