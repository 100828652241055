import React, { ReactNode } from "react";
import { useController } from "react-hook-form";
import { FormHelperText, Grid, InputLabel, Select, Typography } from "@mui/material";

const FormSelectOption = ({
  value,
  children,
  disabled
}: {
  value: any;
  children: string | ReactNode;
  disabled?: boolean;
}) => {
  return (
    <option value={value} disabled={disabled}>
      {children}
    </option>
  );
};

type FormSelectProps = {
  id: string;
  name: string;
  label?: string;
  children: ReactNode[];
  valuesAsNumber?: boolean;
};

const FormSelect = ({ id, name, label, children, valuesAsNumber }: FormSelectProps) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    name
  });

  return (
    <Grid item>
      {label &&
        <InputLabel htmlFor={name}>
          <Typography noWrap>{label}</Typography>
        </InputLabel>
      }
      <Select
        {...field}
        onChange={(e) => (valuesAsNumber ? field.onChange(+e.target.value) : field.onChange(e))}
        id={id}
        error={!!error?.message}
        native
        fullWidth
      >
        {children}
      </Select>
      {error?.message && (
        <FormHelperText style={{ marginLeft: "14px" }} error>
          {error.message}
        </FormHelperText>
      )}
    </Grid>
  );
};

FormSelect.Option = FormSelectOption;

export default FormSelect;
