import FacilityFormValues from "src/models/formikInputValues/FacilityFormValues";
import moment from "moment";

const initialFacilityHours = {
  monday: {
    open: moment().startOf("day").format(),
    close: moment().startOf("day").format(),
    is_open_24_hours: false,
    is_closed_for_the_day: false
  },
  tuesday: {
    open: moment().startOf("day").format(),
    close: moment().startOf("day").format(),
    is_open_24_hours: false,
    is_closed_for_the_day: false
  },
  wednesday: {
    open: moment().startOf("day").format(),
    close: moment().startOf("day").format(),
    is_open_24_hours: false,
    is_closed_for_the_day: false
  },
  thursday: {
    open: moment().startOf("day").format(),
    close: moment().startOf("day").format(),
    is_open_24_hours: false,
    is_closed_for_the_day: false
  },
  friday: {
    open: moment().startOf("day").format(),
    close: moment().startOf("day").format(),
    is_open_24_hours: false,
    is_closed_for_the_day: false
  },
  saturday: {
    open: moment().startOf("day").format(),
    close: moment().startOf("day").format(),
    is_open_24_hours: false,
    is_closed_for_the_day: false
  },
  sunday: {
    open: moment().startOf("day").format(),
    close: moment().startOf("day").format(),
    is_open_24_hours: false,
    is_closed_for_the_day: false
  }
};

const initialFacilityFormValues = {
  name: "",
  legal_name: "",
  facility_id: "",
  district: "",
  tax_id: "",
  area: "",
  district_region: "",
  phone: "",
  fax: "",
  disposition_date: "",
  address: "",
  address_two: "",
  city: "",
  region: "",
  postal_code: "",
  timezone: "",
  access_timezone: "",
  end_of_days_timezone: "",
  email: "",
  latitude: "",
  longitude: "",
  operational_hours: initialFacilityHours,
  access_hours: initialFacilityHours
} as FacilityFormValues;

export default initialFacilityFormValues;
