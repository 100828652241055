import { Cached, Pageview, Phone, RemoveCircle } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { setCancelDealModalOpen, setDeal, setReactivateDealModalOpen } from "src/store/reducers/dealSlice/dealSlice";
import { setInputNumber, setIsDisplayed } from "src/store/reducers/appSoftphoneSlice/appSoftphoneSlice";
import { AppDispatch } from "src/store/rootStore";
import { Deal } from "src/models/Deal";
import { DealTypeStringEnum } from "../../../../../enums/DealType";
import Moment from "moment";
import { NavLink } from "react-router-dom";
import React from "react";
import ReservationMoveInButton from "./ReservationMoveInButton/ReservationMoveInButton";
import { SoftphoneDisplayMode } from "src/store/reducers/appSoftphoneSlice/appSoftphoneSliceState";
import { formatDate } from "src/utils/__dateAndTimeUtils__/formatDate/formatDate";
import { getProductTypeName } from "src/utils/getProductTypeName/getProductTypeName";
import { getUnitSizeFromUnit } from "src/utils/getUnitSize/getUnitSize";
import { nameParser } from "src/utils/nameParser/nameParser";
import { today } from "src/utils/__dateAndTimeUtils__/today";
import { useAppDispatch } from "src/store/hooks";

export const RenderDetailsButton = (deal: Deal) => {
  const dispatch = useAppDispatch();

  const canShowReservationMoveInButton = () => {
    const dealType = typeof deal.deal_type !== "number" ? deal.deal_type.toLowerCase() : deal.deal_type;

    if (dealType === DealTypeStringEnum.RESERVATION &&
      !deal.cancelled_at &&
      Moment.utc(deal.reservation_expires_at).isSameOrAfter(Moment.utc(today))
    ) {
      return true;
    }

    if (dealType === DealTypeStringEnum.LEAD &&
      !deal.cancelled_at &&
      Moment.utc(deal.needed_at).isSameOrAfter(Moment.utc(today))) {
      return true;
    }
  };

  return (
    <Grid
      data-testid={"render-details-button"}
      container
      direction={"row"}
      alignItems={"center"}
      justifyContent={"start"}
    >
      <Tooltip title={"Call now"}>
        <IconButton
          size={"small"}
          onClick={() => {
            dispatch(setInputNumber(deal.phone));
            dispatch(setIsDisplayed(SoftphoneDisplayMode.Display));
          }}
        >
          <Phone />
        </IconButton>
      </Tooltip>

      <Tooltip title={"Edit/View Deal"}>
        <IconButton component={NavLink} to={`/deals/${deal.id}/edit`} size={"small"}>
          <Pageview data-testid={"edit-deal-button"} />
        </IconButton>
      </Tooltip>
      {canShowReservationMoveInButton() && <ReservationMoveInButton deal={deal} />}
      {!deal.cancelled_at && (
        <Tooltip title={"Cancel Deal"}>
          <IconButton
            size={"small"}
            data-testid={"cancel-deal-button"}
            onClick={() => {
              dispatch(setCancelDealModalOpen(true));
              dispatch(setDeal(deal));
            }}
          >
            <RemoveCircle />
          </IconButton>
        </Tooltip>
      )}
      {deal.cancelled_at && (
        <Tooltip title={"Reactivate Deal"}>
          <IconButton
            size={"small"}
            data-testid={"reactivate-deal-button"}
            onClick={() => {
              dispatch(setReactivateDealModalOpen(true));
              dispatch(setDeal(deal));
            }}
          >
            <Cached />
          </IconButton>
        </Tooltip>
      )}
    </Grid>
  );
};

export const manageDealsTableColumns = (dispatch: AppDispatch): GridColDef[] => {
  return [
    {
      field: "last_name",
      renderHeader: () => (
        <Tooltip title={"Name"}>
          <Typography variant={"body2"}>Name</Typography>
        </Tooltip>
      ),
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        nameParser(params.row.first_name, params.row.last_name, params.row.middle_name)
    },
    {
      field: "productType.length",
      renderHeader: () => (
        <Tooltip title={"Unit Size"}>
          <Typography variant={"body2"}>Unit Size</Typography>
        </Tooltip>
      ),
      sortable: false, // @TODO: update when BE is able to figure out server side sorting for this val.
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => getUnitSizeFromUnit(row.unit) || "N/A"
    },
    {
      field: "productType.name",
      renderHeader: () => (
        <Tooltip title={"Product Type"}>
          <Typography variant={"body2"}>Product Type</Typography>
        </Tooltip>
      ),
      sortable: false, // @TODO: update when BE is able to figure out server side sorting for this val.
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => getProductTypeName(row as Deal)
    },
    {
      field: "deal_type",
      renderHeader: () => (
        <Tooltip title={"Deal Type"}>
          <Typography variant={"body2"}>Type</Typography>
        </Tooltip>
      ),
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.deal_type === 1 || params.row.deal_type === "Lead" ? "Lead" : "Reservation"
    },
    {
      field: "created_at",
      renderHeader: () => (
        <Tooltip title={"Created Date/Time"}>
          <Typography variant={"body2"}>Created Date/Time</Typography>
        </Tooltip>
      ),
      minWidth: 200,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => Moment(params.row.created_at).format("MM/DD/YYYY h:mm A")
    },
    {
      field: "contact_at",
      renderHeader: () => (
        <Tooltip title={"Follow-Up Date"}>
          <Typography variant={"body2"}>Follow-up</Typography>
        </Tooltip>
      ),
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.contact_at ? formatDate(params.row.contact_at) : "N/A"
    },
    {
      field: "appointment_at",
      headerName: "Appointment Date",
      renderHeader: () => (
        <Tooltip title={"Appointment Date"}>
          <Typography variant={"body2"}>Appointment</Typography>
        </Tooltip>
      ),
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.appointment_at ? formatDate(params.row.appointment_at) : "N/A"
    },
    {
      field: "needed_at",
      renderHeader: () => (
        <Tooltip title={"Move-In Date"}>
          <Typography variant={"body2"}>Move-In Date</Typography>
        </Tooltip>
      ),
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => formatDate(params.row.needed_at)
    },
    {
      field: "actionIcons",
      headerName: "Actions",
      renderHeader: () => (
        <Tooltip title={"Actions"}>
          <Typography variant={"body2"}>Actions</Typography>
        </Tooltip>
      ),
      flex: 1,
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams) => RenderDetailsButton(row)
    }
  ];
};

export default manageDealsTableColumns;
