import React, { FC } from "react";
import {
  selectDelinquencyAutomationsFilter,
  setAutomationsFilter
} from "src/store/reducers/delinquencyAutomationsSlice/delinquencyAutomationsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Button } from "@mui/material";
import FilterButtonGroup from "src/components/ui/FilterButtonGroup/FilterButtonGroup";
import clsx from "clsx";
import { getAutomations } from "src/store/thunks/automations/getAll/getAutomations";
import { selectSelectedAdminFacility } from "src/store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import useStyles from "./DelinquencyAutomationsFilterButtons.styles";

interface DelinquencyAutomationsFilterButtonsProps{

}

const DelinquencyAutomationsFilterButtons: FC<DelinquencyAutomationsFilterButtonsProps> = () => {
  const dispatch = useAppDispatch();
  const selectedFacility = useAppSelector(selectSelectedAdminFacility);
  const automationsFilter = useAppSelector(selectDelinquencyAutomationsFilter);
  const { classes } = useStyles({ is_active: automationsFilter });

  const setActive = async() => {
    await dispatch(setAutomationsFilter(true));

    if (selectedFacility?.id) {
      dispatch(getAutomations({ facilityId: selectedFacility?.id, is_active: true }));
    }
  };

  const setDisabled = async() => {
    await dispatch(setAutomationsFilter(false));

    if (selectedFacility?.id) {
      dispatch(getAutomations({ facilityId: selectedFacility?.id, is_active: false }));
    }
  };

  return selectedFacility
    ? (
      <FilterButtonGroup heading={"Filter:"}>
        <Button onClick={setActive} className={clsx(classes.buttonText, classes.filterButtonActive)}>
          Active
        </Button>
        <Button onClick={setDisabled} className={clsx(classes.buttonText, classes.filterButtonDisabled)}>
          Disabled
        </Button>
      </FilterButtonGroup>
      )
    : null;
};

export default DelinquencyAutomationsFilterButtons;
