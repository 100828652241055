import React, { ReactElement, useEffect } from "react";
import {
  selectFeeCategories,
  selectFeeCategoriesLoading
} from "../../../store/reducers/feeCategoriesSlice/feeCategoriesSlice";
import {
  selectRevenueClasses,
  selectRevenueClassesLoading
} from "../../../store/reducers/revenueClassesSlice/revenueClassesSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import { Breadcrumb } from "../../../models/Breadcrumb";
import FeeForm from "../FeeForm/FeeForm";
import { FeeFormType } from "../FeeForm/feeFormValidation";
import ItemManagementLayout from "../../../layouts/ItemManagement/ItemManagementLayout";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { createFee } from "../../../store/thunks/fee/create/createFee";
import { getAllFeeCategories } from "../../../store/thunks/feeCategory/getAll/getAllFeeCategories";
import { getAllFeesForFacility } from "../../../store/thunks/fee/getAllForFacility/getAllFeesForFacility";
import { getAllRevenueClasses } from "../../../store/thunks/revenueClass/getAll/getAllRevenueClasses";
import {
  selectSelectedAdminFacility
} from "../../../store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import { useNavigate } from "react-router-dom";
import useStyles from "./CreateFee.styles";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Fees & Charges"
  },
  {
    name: "Create Fee",
    bold: true
  }
];

const CreateFee: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedFacility = useAppSelector(selectSelectedAdminFacility);
  const revenueClasses = useAppSelector(selectRevenueClasses);
  const feeCategories = useAppSelector(selectFeeCategories);
  const revenueClassesLoading = useAppSelector(selectRevenueClassesLoading);
  const feeCategoriesLoading = useAppSelector(selectFeeCategoriesLoading);
  const permissions = useAppSelector(selectFacilityPermissions);
  const feesPermissions = createPermissions(permissions, "manage_fees");

  useEffect(() => {
    dispatch(getAllFeeCategories());
    dispatch(getAllRevenueClasses(selectedFacility?.id));
  }, []);

  const handleSubmit = (data: FeeFormType) => {
    dispatch(
      createFee({
        name: data.feeName,
        amount: data.feeAmount,
        applyAt: data.applyFeeAt,
        description: data.feeDescription,
        feeCategoryId: data.feeCategoryId,
        feeType: data.feeType,
        isRequired: data.isRequired,
        recurrence: data.feeRecurrence,
        revenueClassId: data.feeRevenueClassId,
        floorAmount: data.floorAmount,
        amountFloorRestriction: data.amountFloorRestriction
      })
    ).then((resp) => {
      if (!resp.type.includes("rejected")) {
        dispatch(getAllFeesForFacility({ facilityId: selectedFacility!.id }));
        navigate("/fees");
      }
    });
  };

  const pageHeader = <PageHeader title={"New Fee"} breadcrumbs={breadcrumbs} />;

  return (
    <ViewWrapper pageHeader={pageHeader}>
      {
        feesPermissions.create
          ? (<FeeForm feeCategories={feeCategories} revenueClasses={revenueClasses} submitHandler={handleSubmit} />)
          : <h1>You do not have permission to view this page.</h1>
      }
    </ViewWrapper>
  );
};

export default CreateFee;
