import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Billing Information" })((theme) => ({
  headerCell: {
    color: theme.palette.common.black,
    background: theme.palette.grey[200],
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius
  },
  confirmButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.common.white,
    minWidth: theme.spacing(18),
    minHeight: theme.spacing(5),
    fontWeight: "normal"
  },
  cancelButton: {
    minWidth: theme.spacing(18),
    minHeight: theme.spacing(5),
    color: theme.palette.error.contrastText,
    fontWeight: "normal"
  }
}));

export default useStyles;
