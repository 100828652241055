import {
  Button, Divider, Grid, InputLabel, Paper, Slider, Typography
} from "@mui/material";
import { FormikHandlers, FormikValues } from "formik";
import { PaymentMethod as Method, PaymentMethod as PaymentMethodEnum } from "../../../../enums/PaymentMethod";
import React, { ChangeEvent, FC, ReactElement, SyntheticEvent, useEffect, useMemo, useState } from "react";
import {
  selectCustomPaymentAmount,
  selectLedgerPayments,
  selectMakeAPaymentPeriods,
  selectSelectedLedgers,
  setEffectiveAmount,
  setLedgerPayments,
  setMakeAPaymentPeriods,
  setSelectedLedgers,
  setTransaction
} from "../../../../store/reducers/makeAPaymentSlice/makeAPaymentSlice";
import {
  selectMaxPaymentAmount, setPaymentType
} from "../../../../store/reducers/paymentSlice/paymentSlice";
import {
  selectPaymentSummarySlice, selectPrepaymentBucketTotal, selectProjectedPrepaidBucketApplied
} from "src/store/reducers/paymentSummarySlice/paymentSummarySlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import ACHForm from "./PaymentForms/AchForm/AchForm";
import { ArrowBack } from "@mui/icons-material";
import { Box } from "@mui/system";
import CashForm from "./PaymentForms/CashForm/CashForm";
import CreditCardForm from "./PaymentForms/CreditCardForm/CreditCardForm";
import MoneyOrderForm from "./PaymentForms/MoneyOrderForm/MoneyOrderForm";
import PaymentMethod from "../../DelinquentOccupants/PaymentModal/payment/PaymentMethod";
import PaymentSummaryNew from "./PaymentSummaryNew/PaymentSummaryNew";
import ReviewAndApplyPaymentTable from "./ReviewAndApplyPaymentTable/ReviewAndApplyPaymentTable";
import centsToDollars from "src/utils/centsToDollars/centsToDollars";
import clsx from "clsx";
import formatPhone from "../../../../utils/formatPhone/formatPhone";
import { getOccupant } from "src/store/thunks/occupant/getOne/getOccupant";
import {
  getPartialPaymentCostNew
} from "src/store/thunks/makeAPayment/getPartialPaymentCostNew/getPartialPaymentCostNew";
import { makeAPayment } from "src/store/thunks/makeAPayment/makeAPayment/makeAPayment";
import prepaidMarks from "./ReviewAndApplyPaymentTable/components/PrepaidMarks";
import {
  selectSelectedOccupant
} from "../../../../store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { useNavigate } from "react-router";
import useStyles from "./ReviewAndApplyPayment.styles";

const ReviewAndApplyPayment: FC = (): ReactElement => {
  // Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { classes } = useStyles({ });

  // Selectors
  const selectedOccupant = useAppSelector(selectSelectedOccupant);
  const selectedLedgerPayments = useAppSelector(selectLedgerPayments);
  const currentLedger = useAppSelector(selectSelectedLedgers);
  const periods = useAppSelector(selectMakeAPaymentPeriods);
  const maxPaymentAmount = useAppSelector(selectMaxPaymentAmount);
  const prepayedBucketApplied = useAppSelector(selectProjectedPrepaidBucketApplied);
  const prepaymentBucketTotal = useAppSelector(selectPrepaymentBucketTotal);
  const {
    grandTotal,
    currentBalanceTotal,
    requiredPrepaidPeriods
  } = useAppSelector(selectPaymentSummarySlice);
  const customPaymentAmount = useAppSelector(selectCustomPaymentAmount);

  // State
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(PaymentMethodEnum.cash);
  const [formik, setFormik] = useState<FormikHandlers>();
  const [localPeriods, setLocalperiods] = useState(0);
  const [lastSliderChangeTime, setLastSliderChangeTime] = useState(Date.now());
  // Computed Values
  const effectiveAmount = customPaymentAmount || centsToDollars(grandTotal);
  const disablePrepaidSlider = Boolean(customPaymentAmount) || maxPaymentAmount === 0 || (
    requiredPrepaidPeriods !== null &&
    requiredPrepaidPeriods >= 0
  );

  const handleSubmit = <T extends unknown>(values: T) => {
    dispatch(makeAPayment({ values: values as FormikValues, effectiveAmount })).then((resp) => {
      if (!resp.type.includes("rejected")) {
        dispatch(setTransaction(resp.payload.transaction));
        if (resp.payload.ledgers.length) {
          dispatch(setSelectedLedgers([resp.payload.ledgers[0]]));
        }
        dispatch(setLedgerPayments([]));
        navigate("/collections/make-a-payment/payment-confirmation");
      }
    });
  };

  const paymentForms = useMemo(() => ({
    cash: <CashForm amountDue={effectiveAmount.toString()} handleSubmit={handleSubmit} setFormik={setFormik} />,
    ach: <ACHForm handleSubmit={handleSubmit} setFormik={setFormik} />,
    creditCard: <CreditCardForm handleSubmit={handleSubmit} setFormik={setFormik} useSavedPaymentInstrument/>,
    moneyOrder: <MoneyOrderForm
      handleSubmit={handleSubmit}
      setFormik={setFormik}
      amountDue={effectiveAmount?.toString()}
    />
  }), [effectiveAmount, selectedPaymentMethod]);

  const paymentEntryContent = (paymentType: PaymentMethodEnum) => {
    let header = "";
    let paymentForm = null;

    switch (paymentType) {
      case Method.cash:
        header = "Payment Information";
        paymentForm = paymentForms.cash;
        break;
      case Method.ach:
        header = "ACH Bank Information";
        paymentForm = paymentForms.ach;
        break;
      case Method.creditCard:
        header = "Credit Card Information";
        paymentForm = paymentForms.creditCard;
        break;
      case Method.moneyOrder:
        header = "Check/Money Order Information";
        paymentForm = paymentForms.moneyOrder;
        break;
    }

    return { header, paymentForm };
  };

  async function getPartialPaymentCost() {
    if (selectedOccupant?.id && currentLedger[0]?.id) {
      dispatch(getPartialPaymentCostNew({ occupantId: selectedOccupant.id, ledgerId: currentLedger[0].id }));
    }
  }

  const handlePeriodsOnChange = (event: Event, newValue: number | number[]) => {
    setLocalperiods(newValue as number);
  };

  const handlePeriodsOnChangeCommitted =
  (_: Event | SyntheticEvent<Element, Event>, newValue: number | number[]) => {
    dispatch(setMakeAPaymentPeriods(newValue as number));
  };

  useEffect(() => {
    getPartialPaymentCost();
  }, [selectedLedgerPayments]);

  useEffect(() => {
    if (!customPaymentAmount) {
      getPartialPaymentCost();
    }
    setLocalperiods(periods as number);
  }, [periods]);

  useEffect(() => {
    dispatch(setEffectiveAmount(effectiveAmount));
  }, [effectiveAmount]);

  useEffect(() => {
    if (selectedOccupant?.id) {
      dispatch(getOccupant(selectedOccupant?.id.toString()));
    }
  }, []);

  // gotta do this so the api don't go bananas
  const handleKeyDown = (event: React.KeyboardEvent) => {
    const currentTime = Date.now();
    const isArrowKey = event.key === "ArrowLeft" || event.key === "ArrowRight";
    const timeSinceLastChange = currentTime - lastSliderChangeTime;
    const isThrottlePeriodElapsed = timeSinceLastChange >= 1000;

    if (isArrowKey && isThrottlePeriodElapsed) {
      setLastSliderChangeTime(currentTime);
    }

    if (isArrowKey && !isThrottlePeriodElapsed) {
      event.preventDefault();
    }
  };

  return (
    <>
      <Grid item mt={2}>
        <Typography variant={"h5"} component={"h3"} fontWeight={"bold"}>Review and Apply Payment</Typography>
        <Grid item mt={1}>
          <Button
            data-testid={"back-button"}
            variant={"text"}
            className={clsx(classes.textButton, classes.baseButton)}
            startIcon={<ArrowBack />}
            onClick={() => navigate("/collections/make-a-payment/select-occupant-or-unit")}
          >
            Go Back To Select Occupant
          </Button>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          columnSpacing={3}
          display={"flex"}
          direction={"row"}
          mt={5}
          component={"form"}
          onSubmit={(event: React.FormEvent) => {
            event.preventDefault();
            formik?.handleSubmit();
          }}
        >
          <Grid item xs={9}>
            <Paper variant={"outlined"}>
              <Grid
                container
                justifyContent={"space-between"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                p={5}
                rowGap={3}
              >
                <Grid container>
                  <Grid item>
                    <Typography data-testid={"occupant-name-heading"} variant={"h5"}>
                      {selectedOccupant?.first_name} {selectedOccupant?.last_name}
                    </Typography>
                  </Grid>
                  <Grid item display={"flex"} flexDirection={"row"}
                    gap={3} ml={"auto"} alignItems={"center"}>
                    <Typography data-testid={"occupant-email-heading"}>{selectedOccupant?.email}</Typography>
                    <Typography data-testid={"occupant-phone-heading"}>
                      {formatPhone(selectedOccupant?.phone_primary, true)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container display={"flex"} flexDirection={"column"}>
                  <Divider />
                </Grid>
                <Grid container flexDirection={"column"}>
                  <Grid
                    mb={2}
                    pb={2}
                    item
                    xs={12}
                    className={classes.total}
                  >
                    <Typography data-testid={"max-allowed-heading"} fontWeight={"bold"}>
                      MAX REMAINING PREPAYMENT ALLOWED
                    </Typography>
                    <Typography data-testid={"max-allowed-value"} fontWeight={"bold"}> {maxPaymentAmount !== null
                      ? `$${maxPaymentAmount}`
                      : "N/A"}</Typography>
                  </Grid>
                  {maxPaymentAmount === 0
                    ? <Grid
                        pb={2}
                        item
                        xs={12}
                        className={classes.total}
                  >
                      <Typography data-testid={"max-allowed-reached-text"} fontWeight={"bold"}>
                        You have paid ahead the 12 month maximum on this ledger.
                      </Typography>
                    </Grid>
                    : null}
                  <Grid pb={2} item xs={12}>
                    <ReviewAndApplyPaymentTable />
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    alignItems={"left"}
                    pt={3}
                    px={3.5}
                    xs={8}
                  >
                    <InputLabel htmlFor={"prepay-periods"} className={classes.headerCell}>
                      <Typography noWrap>Prepay Periods</Typography>
                    </InputLabel>
                    <Slider
                      value={Number(requiredPrepaidPeriods) || localPeriods }
                      defaultValue={0}
                      step={1}
                      onKeyDown={handleKeyDown}
                      disabled={disablePrepaidSlider}
                      marks={prepaidMarks}
                      valueLabelDisplay={"auto"}
                      data-testid={"prepay-periods-slider"}
                      min={0}
                      max={12}
                      onChange={handlePeriodsOnChange}
                      onChangeCommitted={handlePeriodsOnChangeCommitted}
                    />
                  </Grid>
                  <Box className={classes.total}>
                    <Typography data-testid={"heading-outstanding-balance-due"} fontWeight={"bold"}>
                      OUTSTANDING BALANCE DUE
                    </Typography>
                    <Typography data-testid={"heading-outstanding-balance-due-value"} fontWeight={"bold"}>
                      {centsToDollars(currentBalanceTotal, true)}
                    </Typography>
                  </Box>
                  <Box className={classes.total}>
                    <Typography data-testid={"heading-existing-prepayment-amount"} fontWeight={"bold"}>
                      EXISTING PREPAYMENT AMOUNT
                    </Typography>
                    <Typography data-testid={"heading-existing-prepayment-amount-value"} fontWeight={"bold"}>
                      {centsToDollars(prepaymentBucketTotal, true)}
                    </Typography>
                  </Box>
                  <Box className={classes.total}>
                    <Typography data-testid={"heading-existing-prepayment-amount-applied"} fontWeight={"bold"}>
                      EXISTING PREPAYMENT APPLIED WITH NEW PAYMENT:
                    </Typography>
                    <Typography data-testid={"heading-existing-prepayment-amount-applied-value"} fontWeight={"bold"}>
                      {centsToDollars(prepayedBucketApplied, true)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid container display={"flex"} columnSpacing={2}>
                  <Grid item md={12} lg={3}>
                    <InputLabel className={classes.headerCell}>
                      <Typography noWrap>Payment Method</Typography>
                    </InputLabel>
                    <PaymentMethod
                      paymentOptions={[
                        PaymentMethodEnum.ach,
                        PaymentMethodEnum.cash,
                        PaymentMethodEnum.creditCard,
                        PaymentMethodEnum.moneyOrder
                      ]}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setSelectedPaymentMethod(Number(e.target.value) as PaymentMethodEnum);
                        dispatch(setPaymentType(Number(e.target.value) as PaymentMethodEnum));
                      }}
                      selectedOption={selectedPaymentMethod}
                    />
                  </Grid>
                  <Grid item container md={12}
                    lg={8}>
                    <InputLabel className={classes.headerCell}>
                      <Typography noWrap>{paymentEntryContent(selectedPaymentMethod).header}</Typography>
                    </InputLabel>
                    {paymentEntryContent(selectedPaymentMethod).paymentForm}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <PaymentSummaryNew />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ReviewAndApplyPayment;
