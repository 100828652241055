import {
  Alert,
  FormControlLabel,
  FormHelperTextProps,
  Grid,
  InputLabel,
  Paper,
  Radio,
  TextField,
  TextFieldProps,
  Typography
} from "@mui/material";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import {
  selectAppointmentFor,
  selectExpiresOn,
  selectFollowup,
  selectNeededOn,
  selectReferralType,
  setAppointmentFor,
  setDealType,
  setExpiresOn,
  setFollowup,
  setNeededOn,
  setReferralType
} from "../../../../store/reducers/dealInformationSlice/dealInformationSlice";
import {
  selectDealDaysLimitSetting,
  selectEnableLeadDealTypeSetting,
  selectReservationDaysLimitSetting
} from "src/store/reducers/globalSettingInformationSlice/globalSettingInformationSlice";
import {
  selectFacilityDealDaysLimitSetting,
  selectFacilityReservationDaysLimitSetting
} from "src/store/reducers/facilitySettingInformationSlice/facilitySettingInformationSlice";
import { selectNote, setNote } from "../../../../store/reducers/noteSlice/noteSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { DealType } from "../../../../models/DealType";
import { DealTypeStringEnum } from "src/enums/DealType";
import PMSAdvisory from "../../../../components/ui/PMSAdvisory/PMSAdvisory";
import PMSRadio from "src/components/ui/PMSRadio/PMSRadio";
import PMSSelect from "../../../../components/ui/PMSSelect/PMSSelect";
import { ReferralType } from "../../../../models/ReferralType";
import StepLayout from "../../../../layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "../../../../layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import { formatDate } from "../../../../utils/__dateAndTimeUtils__/formatDate/formatDate";
import { getAllFacilitySettings } from "src/store/thunks/facilitySetting/getAll/getAllFacilitySettings";
import { getAllGlobalSettings } from "src/store/thunks/globalSetting/getAll/getAllGlobalSettings";
import { inputError } from "../../../../utils/showInputError/showInputError";
import moment from "moment";
import scheduleDealValidation from "./scheduleDealValidation";
import {
  selectSelectedFacility
} from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { useFormik } from "formik";
import { useNavigate } from "react-router";

const ScheduleDeal: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const referralType = useAppSelector(selectReferralType);
  const neededOn = useAppSelector(selectNeededOn);
  const followup = useAppSelector(selectFollowup);
  const expiresOn = useAppSelector(selectExpiresOn);
  const appointmentFor = useAppSelector(selectAppointmentFor);
  const dealNote = useAppSelector(selectNote);
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const enableLeadDealTypeSetting = useAppSelector(selectEnableLeadDealTypeSetting);
  const enableLeadDealType = Number(enableLeadDealTypeSetting?.value) === 1;

  //day limits
  const globalReservationDaysLimitSetting = useAppSelector(selectReservationDaysLimitSetting);
  const facilityReservationDaysLimitSetting = useAppSelector(selectFacilityReservationDaysLimitSetting);
  const globalDealDaysLimitSetting = useAppSelector(selectDealDaysLimitSetting);
  const facilityDealDaysLimitSetting = useAppSelector(selectFacilityDealDaysLimitSetting);
  const [dateLimit, setDateLimit] = useState<number | undefined>(undefined);

  const formik = useFormik({
    initialValues: {
      referralType: referralType,
      dealType: "reservation",
      neededOn: neededOn,
      followup: followup,
      expiresOn: expiresOn,
      appointmentFor: appointmentFor,
      dealNote: dealNote
    },
    validationSchema: scheduleDealValidation,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (!enableLeadDealType) {
        values.dealType = "reservation";
      }
      dispatch(setDealType(values.dealType as DealType));
      navigate("/deals/create-new-deal/select-unit");
    }
  });

  const { touched, errors, values, setFieldValue } = formik;

  const updateForm = (fieldName: string, fieldValue?: string | boolean | null): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleReferralTypeOnchange = (fieldName: string, fieldValue: ReferralType) => {
    updateForm(fieldName, fieldValue);
    dispatch(setReferralType(fieldValue));
  };

  const handleNeededOnOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setNeededOn(fieldValue));
  };

  const handleFollowupOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setFollowup(fieldValue));
  };

  const handleExpiresOnOnchange = (fieldName: string, fieldValue: string | null) => {
    updateForm(fieldName, fieldValue);
    dispatch(setExpiresOn(fieldValue));
  };

  const handleAppointmentForOnchange = (fieldName: string, fieldValue: any) => {
    updateForm(fieldName, fieldValue);
    dispatch(setAppointmentFor(fieldValue));
  };

  const handleDealTypeOnchange = (fieldName: string, fieldValue: DealType) => {
    updateForm(fieldName, fieldValue);
    dispatch(setDealType(fieldValue));

    // leads do not require expiresOn or appointmentFor, toggling to lead should reset these values
    if (fieldValue === "lead") {
      handleExpiresOnOnchange("expiresOn", null);
      handleAppointmentForOnchange("appointmentFor", null);
    }
  };

  const handleDealNoteOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setNote(fieldValue));
  };

  useEffect(() => {
    if (!enableLeadDealType) {
      setFieldValue("dealType", "reservation");
    }
  }, [enableLeadDealType, setFieldValue]);

  const radioItems = (enableLeadDealType: boolean) => {
    const items = [
      { value: "lead", label: "Lead", disabled: !enableLeadDealType },
      { value: "reservation", label: "Reservation", disabled: false }
    ];

    return items.map(item => (
      <FormControlLabel
        key={item.value}
        value={item.value}
        label={item.label}
        control={<Radio />}
        disabled={item.disabled}
        checked={values.dealType === item.value}
        onChange={() => handleDealTypeOnchange("dealType", item.value as DealType)}
      />
    ));
  };

  const stepperId = "schedule-deal";

  useEffect(() => {
    dispatch(getAllGlobalSettings());
    dispatch(getAllFacilitySettings(String(selectedFacility.id)));
  }, []);

  useEffect(() => {
    if (values.dealType === DealTypeStringEnum.RESERVATION && globalReservationDaysLimitSetting?.value &&
      facilityReservationDaysLimitSetting?.value === undefined) {
      return setDateLimit(+globalReservationDaysLimitSetting?.value);
    }

    if (values.dealType === DealTypeStringEnum.RESERVATION && facilityReservationDaysLimitSetting?.value) {
      return setDateLimit(+facilityReservationDaysLimitSetting?.value);
    }

    if (values.dealType === DealTypeStringEnum.LEAD &&
      globalDealDaysLimitSetting?.value && facilityDealDaysLimitSetting?.value === undefined) {
      return setDateLimit(+globalDealDaysLimitSetting?.value);
    }

    if (values.dealType === DealTypeStringEnum.LEAD && facilityDealDaysLimitSetting?.value) {
      return setDateLimit(+facilityDealDaysLimitSetting?.value);
    }
  }, [
    values.dealType,
    globalReservationDaysLimitSetting,
    facilityReservationDaysLimitSetting,
    globalDealDaysLimitSetting,
    facilityDealDaysLimitSetting
  ]);

  useEffect(() => {
    dispatch(setDealType(values.dealType as DealType));
  }, [values.dealType]);

  const advisoryText = (
    <>
      <p>
        Here, you can select whether your Deal is a Lead or a Reservation and input dates relevant to the Deal (such
        as when the unit is needed, follow-up date, Deal expiration date, etc.).
      </p>
      <p>
        If the Deal is a Reservation, you can set a date and time for an appointment to complete the move in
        process.
      </p>
      <p>
        You are also able to track how the occupant heard about the unit via the Referral field.
      </p>
    </>
  );

  return (
    <>
      <PMSAdvisory title={"Schedule Deal"} advisoryText={advisoryText} />
      <Paper elevation={1}>
        <StepLayout stepperId={stepperId} buttons={
          <StepperButtons
            data-testid={"schedule-deal-buttons"}
            stepperId={stepperId}
            backAction={() => navigate("/deals/create-new-deal/select-occupant")}
          />
        }>
          <form
            id={stepperId}
            data-testid={stepperId}
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              formik.handleSubmit();
            }}
          >
            <Typography variant={"h6"} component={"h3"}>Schedule your deal</Typography>

            {(values.dealType === "reservation" || !enableLeadDealType) &&
              <Alert severity={"warning"} data-testid={"selection-alert"}>
                You must select a unit in the next step for a reservation.
              </Alert>}

            {!enableLeadDealType &&
              <Alert severity={"info"} data-testid={"selection-alert"}>
                <b>Note: </b>The &quot;Lead&quot; Deal Type is Currently Disabled. Only reservations are allowed.
              </Alert>
            }

            <Grid
              item
              container
              spacing={2}
              xs={12}
              mt={1}
            >
              <Grid
                item
                container
                spacing={2}
                xs={12}
                lg={8}
              >
                <Grid xs={6} item>
                  <PMSSelect
                    id={"referral-type"}
                    name={"referralType"}
                    label={"How did you hear about us?"}
                    value={values.referralType}
                    changeHandler={e => handleReferralTypeOnchange("referralType", e.target.value as ReferralType)}
                    error={inputError("referralType", touched, errors).error}
                    helperText={inputError("referralType", touched, errors).helperText}
                  >
                    <option value={""}>- Referral Type -</option>
                    <option value={"referral"}>Referral</option>
                    <option value={"phone call"}>Phone call</option>
                    <option value={"email message"}>E-mail message</option>
                    <option value={"walk-in"}>Walk-in</option>
                    <option value={"website"}>Website</option>
                    <option value={"web chat"}>Web Chat</option>
                    <option value={"unknown"}>Unknown</option>
                  </PMSSelect>
                </Grid>
                <Grid xs={6} item>
                  <PMSRadio
                    label={"What type of deal is this?"}
                    name={"dealType"}
                    value={values.dealType}
                    changeHandler={(e: ChangeEvent<HTMLInputElement>) =>
                      handleDealTypeOnchange("dealType", e.target.value as DealType)}
                  >
                    <Grid
                      pt={0.5}
                      pl={0.5}
                      container
                      item
                      xs={12}>
                      {radioItems(enableLeadDealType)}
                    </Grid>
                  </PMSRadio>
                </Grid>
                <Grid xs={6} item>
                  <InputLabel htmlFor={"needed-on"}>Rental Date:</InputLabel>
                  <DatePicker
                    renderInput={(params) => (
                      <TextField
                        onKeyDown={(e) => e.preventDefault()}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "- Rental Date -"
                        }}
                        name={"neededOn"}
                        id={"needed-on"}
                        fullWidth
                        FormHelperTextProps={
                          { "data-testid": "needed-on-validation" } as Partial<FormHelperTextProps<"p", {}>>
                        }
                        error={inputError("neededOn", touched, errors).error}
                        helperText={inputError("neededOn", touched, errors).helperText}
                      />
                    )}
                    OpenPickerButtonProps={{ "aria-label": "Select Rental Date" }}
                    componentsProps={{
                      leftArrowButton: { "aria-label": "Move Backward" },
                      rightArrowButton: { "aria-label": "Move Backward" }
                    }}
                    minDate={moment()}
                    maxDate={moment().add(dateLimit, "days")}
                    inputFormat={"MM/DD/yyyy"}
                    data-testid={"needed-on"}
                    value={values.neededOn}
                    onChange={(e) => handleNeededOnOnchange("neededOn", formatDate(e, "MM/DD/YYYY", "N/A"))}
                  />
                </Grid>
                <Grid xs={6} item>
                  <InputLabel htmlFor={"followup"}>Follow-Up Date:</InputLabel>
                  <DatePicker
                    renderInput={(params: TextFieldProps) => (
                      <TextField
                        {...params}
                        onKeyDown={(e) => e.preventDefault()}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "- Follow-Up Date -"
                        }}
                        name={"followup"}
                        id={"followup"}
                        fullWidth
                        FormHelperTextProps={
                          { "data-testid": "follow-up-validation" } as Partial<FormHelperTextProps<"p", {}>>
                        }
                        error={inputError("followup", touched, errors).error}
                        helperText={inputError("followup", touched, errors).helperText}
                      />
                    )}
                    OpenPickerButtonProps={{ "aria-label": "Select Follow-Up Date" }}
                    componentsProps={{
                      leftArrowButton: { "aria-label": "Move Backward" },
                      rightArrowButton: { "aria-label": "Move Backward" }
                    }}
                    minDate={moment()}
                    inputFormat={"MM/DD/yyyy"}
                    data-testid={"followup"}
                    value={values.followup}
                    onChange={(e) => handleFollowupOnchange("followup", formatDate(e, "MM/DD/YYYY", "N/A"))}
                  />
                </Grid>
                <Grid xs={6} item>
                  <InputLabel htmlFor={"expires-on"}>Expiration Date:</InputLabel>
                  <DatePicker
                    renderInput={(params: TextFieldProps) => (
                      <TextField
                        {...params}
                        onKeyDown={(e) => e.preventDefault()}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "- Expiration Date -"
                        }}
                        name={"expiresOn"}
                        id={"expires-on"}
                        fullWidth
                        FormHelperTextProps={
                          { "data-testid": "expires-on-validation" } as Partial<FormHelperTextProps<"p", {}>>
                        }
                        error={inputError("expiresOn", touched, errors).error}
                        helperText={inputError("expiresOn", touched, errors).helperText}
                      />
                    )}
                    OpenPickerButtonProps={{ "aria-label": "Select Expiration Date" }}
                    componentsProps={{
                      leftArrowButton: { "aria-label": "Move Backward" },
                      rightArrowButton: { "aria-label": "Move Backward" }
                    }}
                    disabled={values.dealType === "lead" && enableLeadDealType}
                    minDate={moment()}
                    inputFormat={"MM/DD/yyyy"}
                    data-testid={"expires-on"}
                    value={values.expiresOn}
                    onChange={(e) => handleExpiresOnOnchange("expiresOn", formatDate(e, "MM/DD/YYYY", "N/A"))}
                  />
                </Grid>
                <Grid xs={6} item>
                  <InputLabel htmlFor={"appointmentFor"}>Appointment Date:</InputLabel>
                  <DateTimePicker
                    renderInput={(params: TextFieldProps) => {
                      return (
                        <TextField
                          onKeyDown={(e) => e.preventDefault()}
                          {...params}
                          name={"appointmentFor"}
                          id={"appointmentFor"}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "- Appointment Date -"
                          }}
                          fullWidth
                          FormHelperTextProps={
                            { "data-testid": "appointment-for-validation" } as Partial<FormHelperTextProps<"p", {}>>
                          }
                          error={inputError("appointmentFor", touched, errors).error}
                          helperText={inputError("appointmentFor", touched, errors).helperText}
                        />
                      );
                    }}
                    disabled={values.dealType === "lead" && enableLeadDealType}
                    minDate={moment()}
                    data-testid={"appointment-for"}
                    value={values.appointmentFor}
                    onChange={(e) =>
                      handleAppointmentForOnchange("appointmentFor", formatDate(e, "MM/DD/YYYY, hh:mm A", "N/A"))}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputLabel htmlFor={"deal-note"}>Deal Notes</InputLabel>
                <TextField
                  id={"deal-note"}
                  fullWidth
                  multiline
                  rows={5}
                  InputProps={{
                    sx: {
                      height: "9.95rem"
                    }
                  }}
                  inputProps={{
                    "data-testid": "deal-notes",
                    maxLength: 500
                  }}
                  name={"dealNote"}
                  value={values.dealNote}
                  onChange={(e) => handleDealNoteOnChange("dealNote", e.target.value)}
                />
              </Grid>
            </Grid>
          </form>
        </StepLayout>
      </Paper>
    </>
  );
};

export default ScheduleDeal;
