import { FormControlLabel, Radio } from "@mui/material";
import React, { ChangeEvent, ReactElement } from "react";
import {
  resetOccupantExistingBillingAddress,
  selectExistingOccupantBillingAddressContact,
  selectOccupantBillingAddressOption,
  setOccuppantBillingAddressOption
} from "src/store/reducers/occupantBillingInformationSlice/occupantBillingInformationSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { BillingAddress } from "src/enums/BillingAddress";
import { BillingAddressOption } from "src/models/BillingAddressOption";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import { FormikValues } from "formik";
import PMSRadio from "src/components/ui/PMSRadio/PMSRadio";
import { deleteBillingContact } from "src/store/thunks/billingContact/delete/deleteBillingContact";
import { useParams } from "react-router";
import { selectChangeOwnershipIsEnabled } from "src/store/reducers/occupantSlice/occupantSlice";

const BillingAddressOptions: React.FC<{
  formik: FormikValues,
  label?: string,
}> = ({ label, formik }): ReactElement => {
  const dispatch = useAppDispatch();
  const occupantBillingAddressOption = useAppSelector(selectOccupantBillingAddressOption);
  const billingContact = useAppSelector(selectExistingOccupantBillingAddressContact);
  const params = useParams<{id: string}>();
  const changeOwnershipIsEnabled = useAppSelector(selectChangeOwnershipIsEnabled);

  const handleBillingAddressOnchange = (billingAddressOption: BillingAddressOption) => {
    dispatch(setOccuppantBillingAddressOption(billingAddressOption));
    if (!changeOwnershipIsEnabled) {
      if (billingAddressOption === 1 && !!billingContact) {
        const payload = { occupantId: params.id!, contactId: billingContact.id! };

        dispatch(deleteBillingContact(payload))
          .then((resp) => {
            if (!resp.type.includes("rejected")) {
              dispatch(resetOccupantExistingBillingAddress());
            }
          });
      }
    }
  };

  const billingAddressItems = () => {
    const items = [
      { value: BillingAddress.sameAsMailingAddress, label: "Same as Mailing Address" },
      { value: BillingAddress.newBillingAddress, label: "New Billing Address" }
    ];

    return items.map((item) => (
      <FormControlLabel
        key={item.value}
        value={item.value}
        control={<Radio checkedIcon={<CheckCircleOutline />} />}
        label={item.label}
      />
    ));
  };

  return (
    <PMSRadio
      label={label}
      value={occupantBillingAddressOption}
      name={"billing-address"}
      changeHandler={(e: ChangeEvent<HTMLInputElement>) =>
        handleBillingAddressOnchange(Number(e.target.value) as BillingAddressOption)
      }
    >
      {billingAddressItems()}
    </PMSRadio>
  );
};

export default BillingAddressOptions;
