import { RootState } from "src/store/rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const validateGateCode = createAsyncThunk(
  "validateGateCode", (_,
    { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const facilityId = state.selectedFacility.selectedFacility?.id;
    const gateCode = state.occupantInformation.gateCode;

    return axios.get(`/api/v1/facilities/${facilityId}/validate-gate-code/${gateCode}`)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = `There was an error validating the gate code`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
