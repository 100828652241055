import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MoveOutCost } from "src/models/MoveOutCost";
import { MoveOutCostSliceState } from "./MoveOutCostSliceState";
import { RootState } from "../../rootStore";
import { getMoveOutCost } from "src/store/thunks/moveOut/getMoveOutCost/getMoveOutCost";

export const initialState: MoveOutCostSliceState = {
  balance: "0.00",
  current_balance: "0.00",
  creditProratedValue: false,
  refundType: "",
  waiveFee: false,
  markAsUnrentable: false,
  noteDescription: "",
  fees: [],
  total_credits: "0.00",
  credits: [],
  subtotal: "0.00",
  tax: "0.00",
  final_balance: "0.00",
  moveOutCostLoading: false,
  moveOutCostError: "",
  enableIssueCredit: false,
  showApplyToOtherUnitdDropdown: false,
  unrentableReason: "",
  applyToUnit: "",
  additional_periods: {
    periods: 0,
    periods_total: "0.00"
  }
};

export const moveOutCostSlice = createSlice({
  name: "moveOutCost",
  initialState,
  reducers: {
    setCreditProratedValue: (state, action: PayloadAction<boolean>) => {
      state.creditProratedValue = action.payload;
    },
    setrefundType: (state, action: PayloadAction<string>) => {
      state.refundType = action.payload;
    },
    setMoveOutCostWaiveFee: (state, action: PayloadAction<boolean>) => {
      state.waiveFee = action.payload;
    },
    setMoveOutCostUnrentable: (state, action: PayloadAction<boolean>) => {
      state.markAsUnrentable = action.payload;
    },
    setMoveOutNoteDescription: (state, action: PayloadAction<string>) => {
      state.noteDescription = action.payload;
    },
    setMoveOutCostEnableIssueCredit: (state, action: PayloadAction<boolean>) => {
      state.enableIssueCredit = action.payload;
    },
    setShowApplyToOtherUnitDropdown: (state, action: PayloadAction<boolean>) => {
      state.showApplyToOtherUnitdDropdown = action.payload;
    },
    setApplyToUnit: (state, action: PayloadAction<string>) => {
      state.applyToUnit = action.payload;
    },
    setMoveOutCostUnrentableReason: (state, action: PayloadAction<string>) => {
      state.unrentableReason = action.payload;
    },
    resetMoveOutCostSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMoveOutCost.pending, (state) => {
        state.moveOutCostLoading = true;
      })
      .addCase(getMoveOutCost.fulfilled, (state, action: PayloadAction<MoveOutCost>) => {
        state.moveOutCostLoading = false;
        state.current_balance = parseFloat(action.payload.current_balance).toFixed(2);
        state.additional_periods.periods = action.payload.additional_periods?.periods;
        state.additional_periods.periods_total = action.payload.additional_periods?.periods_total;
        state.fees = action.payload.fees;
        state.tax = parseFloat(action.payload.tax).toFixed(2);
        state.credits = action.payload.credits;
        if (action.payload.credits?.length > 0) {
          state.total_credits = parseFloat(action.payload.credits.reduce((acc, cur) => {
            if (cur.name === "issue_credit") {
              state.issue_credit = parseFloat(cur.amount).toFixed(2);
            }
            return acc + parseFloat(cur.amount);
          }, 0).toFixed(2)).toString();
        }
        state.final_balance = parseFloat(action.payload.final_balance).toFixed(2);
        state.balance = parseFloat(action.payload.balance).toFixed(2);
      });
  }
});

export const selectMoveOutCostCurrentBalance = (state: RootState) => state.moveOutCost.current_balance;
export const selectMoveOutCostBalance = (state: RootState) => state.moveOutCost.balance;
export const selectMoveOutCostFees = (state: RootState) => state.moveOutCost.fees;
export const selectMoveOutCostCredits = (state: RootState) => state.moveOutCost.credits;
export const selectMoveOutCostTotalCredits = (state: RootState) => state.moveOutCost.total_credits;
export const selectMoveOutCostFinalBalance = (state: RootState) => state.moveOutCost.final_balance;
export const selectMoveOutCostTax = (state: RootState) => state.moveOutCost.tax;
export const selectMoveOutCostLoading = (state: RootState) => state.moveOutCost.moveOutCostLoading;
export const selectMoveOutCostError = (state: RootState) => state.moveOutCost.moveOutCostError;
export const selectIssueCredit = (state: RootState) => state.moveOutCost.issue_credit;
export const selectRefundType = (state: RootState) => state.moveOutCost.refundType;
export const selectMoveOutCostUnrentable = (state: RootState) => state.moveOutCost.markAsUnrentable;
export const selectMoveOutCostWaiveFee = (state: RootState) => state.moveOutCost.waiveFee;
export const selectMoveOutCostCreditProratedValue = (state: RootState) => state.moveOutCost.creditProratedValue;
export const selectMoveOutCostNoteDescription = (state: RootState) => state.moveOutCost.noteDescription;
export const selectMoveOutCostEnableIssueCredit = (state: RootState) => state.moveOutCost.enableIssueCredit;
export const selectMoveOutCostShowApplyToOtherUnitDropdown = (state: RootState) =>
  state.moveOutCost.showApplyToOtherUnitdDropdown;
export const selectMoveOutCostUnrentableReason = (state: RootState) => state.moveOutCost.unrentableReason;
export const selectMoveOutcostApplyToUnit = (state: RootState) => state.moveOutCost.applyToUnit;
export const selectMoveOutAdditionalPeriods = (state: RootState) => state.moveOutCost.additional_periods.periods ?? 0;
export const selectMoveOutAdditionalPeriodsAmount = (state: RootState) =>
  state.moveOutCost.additional_periods.periods_total ?? "0.00";

export const {
  setCreditProratedValue,
  setMoveOutCostWaiveFee,
  setMoveOutCostUnrentable,
  setMoveOutNoteDescription,
  setrefundType,
  setMoveOutCostEnableIssueCredit,
  setShowApplyToOtherUnitDropdown,
  setApplyToUnit,
  setMoveOutCostUnrentableReason,
  resetMoveOutCostSlice
} = moveOutCostSlice.actions;

export default moveOutCostSlice.reducer;
