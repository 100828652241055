
import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "Occupant Ledger Print and Select Unit" })((theme) => ({
  buttonText: {
    textTransform: "none",
    color: "#306DC8"
  },
  menuItem: {
    padding: 8
  }
}));

export default useStyles;
