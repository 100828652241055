import { Box, Divider, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { FormEvent, useEffect } from "react";
import {
  selectECAddressOne,
  selectECAddressSameAsOccupant,
  selectECAddressTwo,
  selectECCity, selectECEmail,
  selectECFirstName,
  selectECId,
  selectECLastName,
  selectECMiddleName,
  selectECPhone,
  selectECPostalCode,
  selectECRelationship,
  selectECState,
  setECPrimaryPhone,
  setEmergencyContact,
  setEmergencyContactAddressSameAsOccupant
} from "../../../../../store/reducers/emergencyContactInformationSlice/emergencyContactInformationSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import EditOccupantEmergencyContactInformationFooterButtons
  from "./EditOccupantEmergencyContactInformationFooterButtons";
import MaskedPhone from "src/components/masking/MaskedPhone/MaskedPhone";
import PMSCheckbox from "src/components/ui/PMSCheckbox/PMSCheckbox";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import { SyntheticInputEvent } from "react-number-format/types/types";
import { createEmergencyContact } from "src/store/thunks/emergencyContact/create/createEmergencyContact";
import editEmergencyContactValidation from "./editOccupantEmergencyContactInformationValidation";
import { inputError } from "../../../../../utils/showInputError/showInputError";
import { selectOccupantAddressInformation }
  from "../../../../../store/reducers/occupantInformationSlice/occupantInformationSlice";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { updateEmergencyContact } from "src/store/thunks/emergencyContact/update/updateEmergencyContact";
import usStates from "src/utils/usStates";
import { useFormik } from "formik";
import { useParams } from "react-router";
import useStyles from "./EditOccupantEmergencyContactInformation.styles";
import { OwnershipButtons } from "../../components/OwnershipButtons/OwnershipButtons";
import {
  selectChangeOwnershipIsEnabled,
  selectOccupant,
  setConfirmChangeOwnershipModalOpened,
  setMissingFieldsChangeOwnershipModalOpened
} from "src/store/reducers/occupantSlice/occupantSlice";
import {
  selectAchForm,
  selectChangeOwnershipOccupantAddressInformation,
  selectCreditCardForm,
  selectEmergencyContactInfoBackup,
  selectFormEmergencyContactAddressSameAsOccupant,
  selectFormEmergencyContactInfo,
  selectFormOccupantInformation,
  setEmergencyContactAddressSameAsOccupantBackup,
  setEmergencyContactInfoBackup,
  setFormEmergencyContactAddressSameAsOccupant,
  setFormEmergencyContactInfo
} from "src/store/reducers/changeOwnership/changeOwnership";
import AlertChangeOwnership from "../../components/AlertChangeOwnership/AlertChangeOwnership";
import occupantInformationValidationSchema from "../EditOccupantInformation/editOccupantInformationValidation";
import { selectPaymentType } from "src/store/reducers/paymentSlice/paymentSlice";
import { PaymentMethod as PaymentMethodEnum } from "src/enums/PaymentMethod";
import achFormValidation from "src/components/payment/ACHForm/achFormValidation";
import creditCardFormValidation from "src/components/payment/CreditCardForm/creditCardFormValidation";
import MaskedPostalCode from "src/components/masking/MaskedPostalCode/MaskedPostalCode";

const EditOccupantEmergencyContactInformation = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const firstName = useAppSelector(selectECFirstName);
  const middleName = useAppSelector(selectECMiddleName);
  const lastName = useAppSelector(selectECLastName);
  const addressOne = useAppSelector(selectECAddressOne);
  const addressTwo = useAppSelector(selectECAddressTwo);
  const city = useAppSelector(selectECCity);
  const state = useAppSelector(selectECState);
  const postalCode = useAppSelector(selectECPostalCode);
  const phone = useAppSelector(selectECPhone);
  const relationship = useAppSelector(selectECRelationship);
  const email = useAppSelector(selectECEmail);
  const ecSameAddressAsOccupant = useAppSelector(selectECAddressSameAsOccupant);
  const occupantAddressInformation = useAppSelector(selectOccupantAddressInformation);
  const params = useParams<{id: string}>();
  const ecId = useAppSelector(selectECId);
  const changeOwnershipIsEnabled = useAppSelector(selectChangeOwnershipIsEnabled);
  const occupantEmergencyContactInfoBackup = useAppSelector(selectEmergencyContactInfoBackup);
  const formEcSameAddressAsOccupant = useAppSelector(selectFormEmergencyContactAddressSameAsOccupant);
  const occupantAddressInformationChangeOwnership = useAppSelector(selectChangeOwnershipOccupantAddressInformation);
  const formEmergencyContactInfo = useAppSelector(selectFormEmergencyContactInfo);
  const formOccupantInformation = useAppSelector(selectFormOccupantInformation);
  const paymentType = useAppSelector(selectPaymentType);
  const achForm = useAppSelector(selectAchForm);
  const creditCardForm = useAppSelector(selectCreditCardForm);
  const occupant = useAppSelector(selectOccupant);

  const formik = useFormik({
    initialValues: {
      firstName: firstName,
      middleName: middleName ?? "",
      lastName: lastName,
      addressOne: addressOne,
      addressTwo: addressTwo ?? "",
      city: city,
      state: state,
      postalCode: postalCode,
      phone: phone ?? "",
      relationship: relationship,
      email: email
    },
    validationSchema: editEmergencyContactValidation(occupant?.military_status as number),
    onSubmit: async(values) => {
      if (changeOwnershipIsEnabled) {
        dispatch(setFormEmergencyContactInfo(values));

        let paymentFormIsValid = null;
        if (paymentType === PaymentMethodEnum.ach) {
          paymentFormIsValid = await achFormValidation.isValid(achForm);
        }
        if (paymentType === PaymentMethodEnum.creditCard) {
          paymentFormIsValid = await creditCardFormValidation.isValid(creditCardForm);
        }

        const occupantInformationValues = {
          firstName: formOccupantInformation.firstName,
          middleName: formOccupantInformation.middleName,
          lastName: formOccupantInformation.lastName,
          addressOne: formOccupantInformation.address,
          addressTwo: formOccupantInformation.addressTwo,
          city: formOccupantInformation.city,
          state: formOccupantInformation.state,
          postalCode: formOccupantInformation.postalCode,
          email: formOccupantInformation.email,
          primaryPhone: formOccupantInformation.primaryPhone,
          secondaryPhone: formOccupantInformation.alternatePhone,
          idNumber: formOccupantInformation.idNumber,
          idState: formOccupantInformation.idState,
          taxIdNumber: formOccupantInformation.taxExemptNumber,
          militaryStatus: formOccupantInformation.military,
          dob: formOccupantInformation.dob,
          gateCode: formOccupantInformation.gateCode,
          business: formOccupantInformation.business,
          taxExempt: formOccupantInformation.taxExempt,
          businessName: formOccupantInformation.businessName
        };

        const occupantFormIsValid = await
        occupantInformationValidationSchema.isValid(occupantInformationValues);

        if (occupantFormIsValid && paymentFormIsValid) {
          dispatch(setConfirmChangeOwnershipModalOpened(true));
        } else {
          dispatch(setMissingFieldsChangeOwnershipModalOpened(true));
        }
      } else {
        await dispatch(setEmergencyContact(values));

        if (!ecId) {
          return dispatch(createEmergencyContact(+params.id!)).then(resp => {
            if (resp.type !== "rejected") {
              dispatch(showSnackbar({
                message: `Emergency Contact created for Occupant`,
                variant: "success"
              }));
            }
          });
        }

        dispatch(updateEmergencyContact(+params.id!));
      }
    }
  });

  const { values, handleChange, setFieldTouched, setFieldValue, touched, errors, resetForm, initialValues } = formik;

  const toggleECSameAsOccupant = () => {
    if (changeOwnershipIsEnabled) {
      dispatch(setFormEmergencyContactAddressSameAsOccupant(occupantAddressInformationChangeOwnership));
      if (!formEcSameAddressAsOccupant) {
        resetForm({
          values: {
            firstName: formEmergencyContactInfo.first_name,
            middleName: formEmergencyContactInfo.middle_name,
            lastName: formEmergencyContactInfo.last_name,
            addressOne: occupantAddressInformationChangeOwnership.address,
            addressTwo: occupantAddressInformationChangeOwnership.addressTwo as string,
            city: occupantAddressInformationChangeOwnership.city,
            state: occupantAddressInformationChangeOwnership.state,
            postalCode: occupantAddressInformationChangeOwnership.postalCode,
            phone: formEmergencyContactInfo.phone_primary,
            relationship: formEmergencyContactInfo.relationship,
            email: formEmergencyContactInfo.email
          }
        });
      } else {
        resetForm({
          values: {
            firstName: formEmergencyContactInfo.first_name,
            middleName: formEmergencyContactInfo.middle_name,
            lastName: formEmergencyContactInfo.last_name,
            addressOne: "",
            addressTwo: "",
            city: "",
            state: "",
            postalCode: "",
            phone: formEmergencyContactInfo.phone_primary,
            relationship: formEmergencyContactInfo.relationship,
            email: formEmergencyContactInfo.email
          }
        });
      }
    } else {
      dispatch(setEmergencyContactAddressSameAsOccupant(occupantAddressInformation));
      if (!ecSameAddressAsOccupant) {
        resetForm({
          values: {
            firstName: firstName,
            middleName: middleName ?? "",
            lastName: lastName,
            addressOne: occupantAddressInformation.address,
            addressTwo: occupantAddressInformation.addressTwo as string,
            city: occupantAddressInformation.city,
            state: occupantAddressInformation.state,
            postalCode: occupantAddressInformation.postalCode,
            phone: phone ?? "",
            relationship: relationship,
            email: email
          }
        });
      } else {
        resetForm({
          values: {
            firstName: firstName,
            middleName: middleName ?? "",
            lastName: lastName,
            addressOne: "",
            addressTwo: "",
            city: "",
            state: "",
            postalCode: "",
            phone: phone ?? "",
            relationship: relationship,
            email: email
          }
        });
      }
    }
  };
  const setEmergencyContactInformation = () => {
    if (changeOwnershipIsEnabled) {
      dispatch(setFormEmergencyContactInfo(formik.values));
    } else {
      dispatch(setEmergencyContact(formik.values));
    }
  };

  const handlePhoneChange = (e:SyntheticInputEvent) => {
    setFieldValue("phone", e.target.value);
    dispatch(setECPrimaryPhone(e.target.value));
  };

  const handleZipCodeChange = (fieldName: string, fieldValue: string) => {
    setFieldTouched(fieldName);
    setFieldValue(fieldName, fieldValue);
  };

  useEffect(() => {
    if (changeOwnershipIsEnabled) {
      if (initialValues.firstName) {
        const values = {
          firstName: initialValues.firstName,
          middleName: initialValues.middleName,
          lastName: initialValues.lastName,
          addressOne: initialValues.addressOne,
          addressTwo: initialValues.addressTwo,
          city: initialValues.city,
          state: initialValues.state,
          postalCode: initialValues.postalCode,
          phone: initialValues.phone,
          relationship: initialValues.relationship,
          email: initialValues.email
        };

        dispatch(setEmergencyContactInfoBackup(values));
        dispatch(setEmergencyContactAddressSameAsOccupantBackup(ecSameAddressAsOccupant));
      }
      const form = {
        firstName: formEmergencyContactInfo.first_name,
        middleName: formEmergencyContactInfo.middle_name,
        lastName: formEmergencyContactInfo.last_name,
        addressOne: formEmergencyContactInfo.address,
        addressTwo: formEmergencyContactInfo.address_two,
        city: formEmergencyContactInfo.city,
        state: formEmergencyContactInfo.region,
        postalCode: formEmergencyContactInfo.postal_code,
        phone: formEmergencyContactInfo.phone_primary,
        relationship: formEmergencyContactInfo.relationship,
        email: formEmergencyContactInfo.email
      };

      resetForm({
        values: form
      });
    } else {
      if (occupantEmergencyContactInfoBackup.first_name) {
        const form = {
          firstName: occupantEmergencyContactInfoBackup.first_name,
          middleName: occupantEmergencyContactInfoBackup.middle_name,
          lastName: occupantEmergencyContactInfoBackup.last_name,
          addressOne: occupantEmergencyContactInfoBackup.address,
          addressTwo: occupantEmergencyContactInfoBackup.address_two,
          city: occupantEmergencyContactInfoBackup.city,
          state: occupantEmergencyContactInfoBackup.region,
          postalCode: occupantEmergencyContactInfoBackup.postal_code,
          phone: occupantEmergencyContactInfoBackup.phone_primary,
          relationship: occupantEmergencyContactInfoBackup.relationship,
          email: occupantEmergencyContactInfoBackup.email
        };
        resetForm({ values: form });
      }
    }
  }, [changeOwnershipIsEnabled]);

  return (
    <Grid container
      component={"form"}
      id={"edit-occupant-ec-form"}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        formik.handleSubmit();
      }}
    >
      <Grid item xs={12}>
        <Box className={classes.containerTitle}>
          <Typography variant={"h6"} gutterBottom>Emergency Contact</Typography>
          <OwnershipButtons />
        </Box>
        <Divider />
        {changeOwnershipIsEnabled && (
          <AlertChangeOwnership />
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent={"space-between"}
          mt={2}
          spacing={2}
        >
          <Grid xs={12} lg={3} item>
            <InputLabel htmlFor={"first-name"}>
              First Name
            </InputLabel>
            <TextField
              fullWidth
              placeholder={"- First Name -"}
              id={"first-name"}
              inputProps={{ "data-testid": "first-name" }}
              name={"firstName"}
              value={values.firstName}
              onChange={handleChange}
              error={inputError("firstName", touched, errors).error}
              helperText={inputError("firstName", touched, errors).helperText}
              onBlur={setEmergencyContactInformation}
            />
          </Grid>
          <Grid xs={12} lg={1} item>
            <InputLabel htmlFor={"middle-name"}>
              Middle Initial
            </InputLabel>
            <TextField
              fullWidth
              placeholder={"- Initials -"}
              id={"middle-name"}
              inputProps={{ "data-testid": "middle-name" }}
              name={"middleName"}
              value={values.middleName}
              onChange={handleChange}
              error={inputError("middleName", touched, errors).error}
              helperText={inputError("middleName", touched, errors).helperText}
              onBlur={setEmergencyContactInformation}
            />
          </Grid>
          <Grid xs={12} lg={2} item>
            <InputLabel htmlFor={"last-name"}>
              Last Name
            </InputLabel>
            <TextField
              fullWidth
              placeholder={"- Last Name -"}
              id={"last-name"}
              inputProps={{ "data-testid": "last-name" }}
              name={"lastName"}
              value={values.lastName}
              onChange={handleChange}
              error={inputError("lastName", touched, errors).error}
              helperText={inputError("lastName", touched, errors).helperText}
              onBlur={setEmergencyContactInformation}
            />
          </Grid>
          <Grid xs={12} lg={3} item>
            <InputLabel htmlFor={"phone"}>
              Phone
            </InputLabel>
            <MaskedPhone
              fullWidth
              id={"phone"}
              dataTestId={"phone"}
              placeholder={"- Phone -"}
              name={"phone"}
              type={"tel"}
              value={values.phone}
              onChange={handlePhoneChange}
              error={inputError("phone", touched, errors).error}
              helperText={inputError("phone", touched, errors).helperText}
            />
          </Grid>
          <Grid xs={12} lg={3} item>
            <PMSSelect
              id={"relationship"}
              name={"relationship"}
              label={"Relationship"}
              value={values.relationship}
              changeHandler={handleChange}
              error={inputError("relationship", touched, errors).error}
              helperText={inputError("relationship", touched, errors).helperText}
            >
              <option value={""} disabled>{" - Relationship -"}</option>
              <option value={"parent/guardian"}>Parent/Guardian</option>
              <option value={"sibling"}>Sibling</option>
              <option value={"spouse"}>Spouse</option>
              <option value={"significant other"}>Significant Other</option>
              <option value={"friend"}>Friend</option>
              <option value={"other relative"}>Other Relative</option>
            </PMSSelect>
          </Grid>
          <Grid item xs={12} lg={3}>
            <InputLabel htmlFor={"email"}>
              E-mail Address
            </InputLabel>
            <TextField
              fullWidth
              placeholder={"- E-mail Address -"}
              id={"email"}
              inputProps={{ "data-testid": "email" }}
              name={"email"}
              value={values.email}
              onChange={handleChange}
              error={inputError("email", touched, errors).error}
              helperText={inputError("email", touched, errors).helperText}
              onBlur={setEmergencyContactInformation}
            />
          </Grid>
          <Grid item xs={12} lg={4.5}>
            <InputLabel htmlFor={"mailing-address-one"}>
              Mailing Address 1
            </InputLabel>
            <TextField
              fullWidth
              placeholder={"- Mailing Address 1 -"}
              id={"mailing-address-one"}
              inputProps={{ "data-testid": "mailing-address-one" }}
              name={"addressOne"}
              value={values.addressOne}
              onChange={handleChange}
              error={inputError("addressOne", touched, errors).error}
              helperText={inputError("addressOne", touched, errors).helperText}
            />
          </Grid>
          <Grid item xs={12} lg={4.5}>
            <InputLabel htmlFor={"mailing-address-two"}>
              Mailing Address 2
            </InputLabel>
            <TextField
              fullWidth
              placeholder={"- Mailing Address 2 -"}
              id={"mailing-address-two"}
              inputProps={{ "data-testid": "mailing-address-two" }}
              name={"addressTwo"}
              value={values.addressTwo}
              onChange={handleChange}
              error={inputError("addressTwo", touched, errors).error}
              helperText={inputError("addressTwo", touched, errors).helperText}
            />
          </Grid>
          <Grid xs={12} lg={3} item>
            <InputLabel htmlFor={"city"}>
              City
            </InputLabel>
            <TextField
              fullWidth
              placeholder={"- City -"}
              id={"city"}
              inputProps={{ "data-testid": "city" }}
              name={"city"}
              value={values.city}
              onChange={handleChange}
              error={inputError("city", touched, errors).error}
              helperText={inputError("city", touched, errors).helperText}
            />
          </Grid>
          <Grid xs={12} lg={3} item>
            <PMSSelect
              id={"state"}
              name={"state"}
              label={"State"}
              value={values.state}
              changeHandler={handleChange}
              error={inputError("state", touched, errors).error}
              helperText={inputError("state", touched, errors).helperText}
            >
              <option value={""}>{" - State -"}</option>
              {usStates.map(state => <option key={state.value} value={state.value}>{state.text}</option>)}
            </PMSSelect>
          </Grid>
          <Grid xs={12} lg={3} item>
            <InputLabel htmlFor={"postal-code"}>
              Postal Code
            </InputLabel>
            <MaskedPostalCode
              fullWidth
              placeholder={"- Zip/postal code -"}
              id={"postal-code"}
              name={"postalCode"}
              value={values.postalCode}
              onChange={(e) => handleZipCodeChange("postalCode", e.target.value)}
              error={inputError("postalCode", touched, errors).error}
              helperText={inputError("postalCode", touched, errors).helperText}
            />
          </Grid>
          <Grid xs={12} lg={3} item>
            <InputLabel htmlFor={"country"}>
              Country
            </InputLabel>
            <Select
              fullWidth
              disabled
              defaultValue={""}
              id={"country"}
              displayEmpty
              variant={"outlined"}
              value={""}
              inputProps={{ "data-testid": "country" }}
            >
              <MenuItem value={""} disabled>United States</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <PMSCheckbox
              name={"ec-same-as-checkbox"}
              label={"Same Address as Occupant"}
              smallText
              isChecked={changeOwnershipIsEnabled ? formEcSameAddressAsOccupant : ecSameAddressAsOccupant}
              changeHandler={toggleECSameAsOccupant}
            />
          </Grid>
          <EditOccupantEmergencyContactInformationFooterButtons />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditOccupantEmergencyContactInformation;
