import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getAllFeeCategories = createAsyncThunk(
  "getAllFeeCategories",
  (_, { dispatch, rejectWithValue }) => {
    return axios.get("/api/v1/fee-categories")
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = "There was an error getting the Fee Categories";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
