
import * as yup from "yup";
import { GridColDef, GridInputSelectionModel, GridRowId } from "@mui/x-data-grid";
import React, { Dispatch, SetStateAction } from "react";
import ActionButtons from "../components/ActionButtons/ActionButtons";
import { FlatUnitChanges } from "../../ManageUnits";
import ProductTypeSelection from "../components/ProductTypeSelection/ProductTypeSelection";
import { Tooltip } from "@mui/material";

const threeDigitIntSchema = yup
  .string()
  .nullable()
  .matches(/^\d{0,3}$/, "Must be a number with up to 3 digits or empty");
const priceSchema = yup
  .string()
  .matches(/^\d+(\.\d{1,2})?$/, "Price must be a valid number with up to two decimal places")
  .required("Price is required");

interface useManageUnitsTableV3ColumnsProps {
  setEditedRows: Dispatch<SetStateAction<FlatUnitChanges>>
  selectedRows: GridInputSelectionModel;
  setSelectedRows:Dispatch<SetStateAction<GridRowId[]>>
}

const useManageUnitsTableV3Columns = ({
  setEditedRows,
  selectedRows,
  setSelectedRows
}:useManageUnitsTableV3ColumnsProps) => {
  const handleProductTypeChange = (id:number, newTypeId:number) => {
    setSelectedRows(prev => [...prev, id]);
    setEditedRows(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        product_type_id: newTypeId
      }
    }));
  };

  const columns: GridColDef[] = [
    {
      field: "unit_number",
      headerName: "Unit #",
      minWidth: 100,
      flex: 1,
      editable: true,
      renderHeader: () => <Tooltip title={"Unit #"}><span>Unit #</span></Tooltip>,
      preProcessEditCellProps: (params) => {
        const isValid = /^[a-z0-9]+$/i.test(params.props.value);
        return { ...params.props, error: !isValid };
      }
    },
    {
      field: "width",
      headerName: "Width",
      minWidth: 100,
      flex: 1,
      editable: true,
      renderHeader: () => (
        <Tooltip title={"Width"} arrow>
          <span>Width</span>
        </Tooltip>
      ),
      valueFormatter: (params) => {
        return `${params.value} ft`;
      },
      preProcessEditCellProps: (params) => {
        const value = params.props.value;
        // Ensure no periods and no more than three digits
        const isValid = /^\d{1,3}$/.test(value);
        return { ...params.props, error: !isValid };
      }
    },
    {
      field: "length",
      headerName: "length",
      flex: 1,
      minWidth: 100,
      editable: true,
      renderHeader: () => <Tooltip title={"Length"} arrow><span>Length</span></Tooltip>,
      valueFormatter: (params) => {
        return `${params.value} ft`;
      },
      preProcessEditCellProps: (params) => {
        const value = params.props.value;
        // Ensure no periods and no more than three digits
        const isValid = /^\d{1,3}$/.test(value);
        return { ...params.props, error: !isValid };
      }
    },
    {
      field: "height",
      headerName: "height",
      flex: 1,
      minWidth: 100,
      editable: true,
      renderHeader: () => <Tooltip title={"Height"} arrow><span>Height</span></Tooltip>,
      valueFormatter: (params) => {
        return `${params.value} ft`;
      },
      preProcessEditCellProps: (params) => {
        const value = params.props.value;
        // Ensure no periods and no more than three digits
        const isValid = /^\d{1,3}$/.test(value);
        return { ...params.props, error: !isValid };
      }
    },
    {
      field: "price",
      headerName: "Rate",
      minWidth: 150,
      flex: 1,
      editable: true,
      renderHeader: () => (
        <Tooltip title={"Rate"} arrow>
          <span>Rate</span>
        </Tooltip>
      ),
      valueFormatter: (params) => {
        return `$${Number(params.value).toFixed(2)}`;
      },
      preProcessEditCellProps: (params) => {
        const value = params.props.value;
        try {
          priceSchema.validate(value);
          return { ...params.props, error: false };
        } catch (error) {
          return { ...params.props, error: true };
        }
      }
    },
    {
      field: "floor",
      headerName: "Floor",
      minWidth: 100,
      flex: 1,
      editable: true,
      renderHeader: () => <Tooltip title={"Floor"} arrow><span>Floor</span></Tooltip>,
      valueFormatter: (params) => {
        return `${params.value}`;
      },
      preProcessEditCellProps: async(params) => {
        const value = params.props.value;
        try {
          await threeDigitIntSchema.validate(value);
          return { ...params.props, error: false };
        } catch (error) {
          return { ...params.props, error: true };
        }
      }
    },
    {
      field: "walk_through_order",
      headerName: "Walk Through Order",
      flex: 1,
      minWidth: 100,
      editable: true,
      renderHeader: () => <Tooltip title={"Walk Through Order"} arrow><span>Walk Through Order</span></Tooltip>,
      valueFormatter: (params) => {
        return params.value !== null && params.value !== undefined ? `${params.value}` : "";
      },
      valueParser: (value) => {
        return value === "" ? null : value;
      },
      preProcessEditCellProps: (params) => {
        const { value } = params.props;
        const schema = yup.string().nullable().matches(/^\d{0,3}$/, "Must be a number with up to 3 digits or empty");

        try {
          schema.validateSync(value);
          return { ...params.props, error: false };
        } catch (error) {
          return { ...params.props, error: true };
        }
      }
    },
    {
      field: "product_type_id",
      headerName: "Product Type",
      minWidth: 200,
      flex: 1,
      type: "singleSelect",
      renderHeader: () => <Tooltip title={"Product Type"} arrow><span>Product Type</span></Tooltip>,
      renderCell: ({ row }) => <ProductTypeSelection row={row} handleProductTypeChange={handleProductTypeChange} />
    },
    {
      field: "Interior",
      headerName: "Interior",
      sortable: false,
      flex: 1,
      minWidth: 100,
      type: "boolean",
      editable: true,
      renderHeader: () => <Tooltip title={"Interior"} arrow><span>Interior</span></Tooltip>
    },
    {
      field: "Drive Up",
      headerName: "Drive-Up",
      sortable: false,
      flex: 1,
      minWidth: 100,
      type: "boolean",
      editable: true,
      renderHeader: () => <Tooltip title={"Drive-Up"} arrow><span>Drive-Up</span></Tooltip>

    },
    {
      field: "Climate Controlled",
      headerName: "Climate",
      sortable: false,
      flex: 1,
      minWidth: 100,
      type: "boolean",
      editable: true,
      renderHeader: () => <Tooltip title={"Climate"} arrow><span>Climate</span></Tooltip>

    },
    {
      field: "Air Cooled",
      headerName: "Air",
      sortable: false,
      flex: 1,
      minWidth: 100,
      type: "boolean",
      editable: true,
      renderHeader: () => <Tooltip title={"Air"} arrow><span>Air</span></Tooltip>

    },
    {
      field: "Heated",
      headerName: "Heated",
      sortable: false,
      flex: 1,
      minWidth: 100,
      type: "boolean",
      editable: true,
      renderHeader: () => <Tooltip title={"Heated"} arrow><span>Heated</span></Tooltip>

    },
    {
      field: "Illuminated",
      headerName: "Illuminated",
      sortable: false,
      flex: 1,
      minWidth: 100,
      type: "boolean",
      editable: true,
      renderHeader: () => <Tooltip title={"Illuminated"} arrow><span>Illuminated</span></Tooltip>

    },
    {
      field: "Electric",
      headerName: "Electric",
      sortable: false,
      flex: 1,
      minWidth: 100,
      type: "boolean",
      editable: true,
      renderHeader: () => <Tooltip title={"Electric"} arrow><span>Electric</span></Tooltip>

    },
    {
      field: "Alarmed",
      headerName: "Alarm",
      sortable: false,
      flex: 1,
      minWidth: 100,
      type: "boolean",
      editable: true,
      renderHeader: () => <Tooltip title={"Alarm"} arrow><span>Alarm</span></Tooltip>

    },
    {
      field: "Elevator Access",
      sortable: false,
      headerName: "Elevator",
      flex: 1,
      minWidth: 100,
      type: "boolean",
      editable: true,
      renderHeader: () => <Tooltip title={"Elevator"} arrow><span>Elevator</span></Tooltip>
    },
    {
      field: "Stair Access",
      sortable: false,
      headerName: "Stairs",
      flex: 1,
      minWidth: 100,
      type: "boolean",
      editable: true,
      renderHeader: () => <Tooltip title={"Stairs"} arrow><span>Stairs</span></Tooltip>

    },
    {
      field: "Ada Compliant",
      sortable: false,
      headerName: "ADA",
      flex: 1,
      minWidth: 100,
      type: "boolean",
      editable: true,
      renderHeader: () => <Tooltip title={"ADA"} arrow><span>ADA</span></Tooltip>
    },
    {
      field: "is_rentable",
      headerName: "Rentable",
      type: "boolean",
      minWidth: 100,
      editable: true

    },
    {
      field: "actionIcons",
      headerName: "Actions",
      sortable: false,
      flex: 1,
      minWidth: 150,
      type: "actions",
      renderHeader: () => <Tooltip title={"Actions"} arrow><span>Actions</span></Tooltip>,
      renderCell: (params) => <ActionButtons row={params.row} originalIsRentable={params.row.is_rentable} />
    }
  ];

  return columns;
};

export default useManageUnitsTableV3Columns;
