import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "ManageAuctions" })((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "80%"
  },
  ledgerModal: {
    margin: "auto"
  },
  buttonText: {
    textTransform: "capitalize"
  },
  disabled: {
    color: theme.palette.text.disabled
  },
  selected: { color: theme.palette.text.primary }
}));

export default useStyles;
