import {
  setOutStandingBalance, setRunningBalance, setTotalGrossAmount,
  setTotalPaymentDue,
  setTotalTax
} from "../../../reducers/makeAPaymentSlice/makeAPaymentSlice";
import { GetPartialPaymentCostPayload } from "./GetPartialPaymentCostPayload";
import { GetPartialPaymentCostResponse } from "./GetPartialPaymentCostResponse";
import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getPartialPaymentCost = createAsyncThunk(
  "getPartialPaymentCost",
  (_, { dispatch, getState, rejectWithValue }) => {
    const store = getState() as RootState;
    const facilityId = store.selectedFacility.selectedFacility.id;
    const selectedLedgerPayments = store.makeAPayment.ledgerPayments;
    const prepaidPeriods = store.makeAPayment.periods || 0;
    const outstandingBalance = store.makeAPayment.outStandingBalance;

    const payload: GetPartialPaymentCostPayload = {
      ledgers: selectedLedgerPayments.map((ledger) => {
        return {
          ...ledger,
          payment_amount: ledger.payment_amount > 0 ? ledger.payment_amount : outstandingBalance,
          ...(Number(prepaidPeriods) > 0 && { prepaid_periods: prepaidPeriods })
        };
      })
    };

    return axios.post(`/api/v1/facilities/${facilityId}/partial-payment-cost`, payload)
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        dispatch(showSnackbar({
          message: err.response.data.detail,
          variant: "error"
        }));

        return rejectWithValue(err.response.data.detail);
      });
  }
);
