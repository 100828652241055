import { Grid } from "@mui/material";
import React from "react";
import SummaryLineItem from
// eslint-disable-next-line max-len
  "src/pages/Collections/MakeAPayment/ReviewAndApplyPayment/PaymentSummaryNew/components/SummaryLineItem/SummaryLineItem";

const renderSummaryLineItem = (name: string, value: boolean | string | number | null, subLineItem?: boolean) => {
  if (value !== null && value !== undefined && value !== "N/A") {
    return (
      <Grid item xs={12}>
        <SummaryLineItem
          name={name}
          value={value}
          subLineItem={subLineItem}
        />
      </Grid>
    );
  }
  return null;
};

export default renderSummaryLineItem;
