import { Box, Grid, Paper, Typography } from "@mui/material";
import feeFormValidation, { FeeFormType } from "./feeFormValidation";

import { Fee } from "src/models/Fee";
import { FeeApplyAt } from "../../../enums/FeeApplyAt";
import { FeeCategory } from "../../../models/FeeCategory";
import { FeeTypeEnum } from "src/enums/FeeType";
import Form from "src/components/form/Form";
import React from "react";
import { RevenueClass } from "../../../models/RevenueClass";
import { useLocation, useNavigate } from "react-router";
import useStyles from "./FeeForm.styles";
import { useWatch } from "react-hook-form";

interface FeeFormProps {
  feeCategories: FeeCategory[];
  revenueClasses: RevenueClass[];
  submitHandler: (data: FeeFormType) => void;
  fee?: Fee;
}

const FeeForm: React.FC<FeeFormProps> = ({ feeCategories, revenueClasses, submitHandler, fee }) => {
  const navigate = useNavigate();

  const radioOptions = [
    { name: "Move-In", value: FeeApplyAt.moveIn },
    { name: "Move-Out", value: FeeApplyAt.moveOut },
    { name: "Transfer", value: FeeApplyAt.transfer },
    { name: "Payment", value: FeeApplyAt.payment },
    { name: "All", value: FeeApplyAt.all }
  ];

  const defaultValues = {
    feeName: fee?.name,
    feeDescription: fee?.description || "",
    feeCategoryId: fee?.fee_category_id || 0,
    feeAmount: fee?.amount,
    feeType: fee?.type || 0,
    feeRecurrence: fee?.recurrence || 0,
    feeRevenueClassId: fee?.revenue_class_id || 0,
    applyFeeAt: fee?.apply_at,
    isRequired: fee?.is_required || false,
    floorAmount: fee?.floor_amount ? String(fee?.floor_amount) : "",
    amountFloorRestriction: Number(fee?.floor_amount) > 0
  };

  const { classes } = useStyles();
  const location = useLocation();

  const Restrictions = () => {
    const [feeType, amountFloorRestriction] = useWatch({ name: ["feeType", "amountFloorRestriction"] });

    return (
      <Grid container xs={5.8} xl={5.8}
        component={Paper}>
        <Grid item container direction={"column"}
          xs={12} p={5}>
          <Box className={classes.header}>
            <Typography variant={"h6"}>Rule Restrictions</Typography>
          </Box>

          <Form.Switch
            label={"Amount Floor Restriction"}
            name={"amountFloorRestriction"}
            isDisabled={feeType === FeeTypeEnum.fixedDollar}
            withLabel={false}
          />

          {amountFloorRestriction && feeType === FeeTypeEnum.percentage && (
            <Grid container item mt={2.5}>
              <Form.Currency id={"floorAmount"} name={"floorAmount"} placeholder={"00.00"} />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <Form
      onSubmit={(data: FeeFormType, e) => {
        e?.preventDefault();
        submitHandler(data);
      }}
      schema={feeFormValidation}
      defaultValues={defaultValues}
    >
      <Grid container mt={1} gap={{ md: 1, lg: 2 }}>
        <Grid item container xs={6}
          xl={6} p={5} component={Paper}
          display={"flex"} flex={1}>
          <Grid item container>
            <Box className={classes.header}>
              <Typography variant={"h6"}>{location.pathname.includes("edit") ? "Edit" : "New"} Fee</Typography>
            </Box>

            <Grid item container justifyContent={"space-between"}
              spacing={2}>
              <Grid item xs={9}>
                <Form.Text
                  id={"feeName"}
                  label={"Fee Name"}
                  name={"feeName"}
                  placeholder={"- Fee Name -"}
                  // fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <Form.Text id={"feeAmount"} label={"Amount"} name={"feeAmount"}
                  placeholder={"- Fee Amount -"} />
              </Grid>
            </Grid>
            <Grid item container spacing={2}
              xs={12} marginTop={2.5}>
              <Grid item xs={6}>
                <Form.Select id={"feeType"} name={"feeType"} label={"Type"}
                  valuesAsNumber>
                  <Form.Select.Option value={0} disabled>
                    - Select Type -
                  </Form.Select.Option>
                  <Form.Select.Option value={FeeTypeEnum.fixedDollar}>Fixed Dollar</Form.Select.Option>
                  <Form.Select.Option value={FeeTypeEnum.percentage}>Percentage</Form.Select.Option>
                </Form.Select>
              </Grid>
              <Grid item xs={6}>
                <Form.Select id={"feeRecurrence"} label={"Fee Recurrence"} name={"feeRecurrence"}
                  valuesAsNumber>
                  <Form.Select.Option value={0} disabled>
                    - Select Recurrence -
                  </Form.Select.Option>
                  <Form.Select.Option value={1}>One-Time Fee</Form.Select.Option>
                  <Form.Select.Option value={2}>Recurring</Form.Select.Option>
                </Form.Select>
              </Grid>
            </Grid>

            <Grid item container xs={12}
              spacing={2} marginTop={2.5}>
              <Grid item xs={6}>
                <Form.Select id={"feeCategory"} label={"Fee Category"} name={"feeCategoryId"}
                  valuesAsNumber>
                  <Form.Select.Option value={0} disabled>
                    - Select Category -
                  </Form.Select.Option>
                  {feeCategories.map((feeCategory) => (
                    <Form.Select.Option key={feeCategory.id} value={feeCategory.id}>
                      {feeCategory.name}
                    </Form.Select.Option>
                  ))}
                </Form.Select>
              </Grid>
              <Grid item xs={6}>
                <Form.Select id={"revenueClass"} label={"Revenue Class"} name={"feeRevenueClassId"}
                  valuesAsNumber>
                  <Form.Select.Option value={0} disabled>
                    - Select Revenue Class -
                  </Form.Select.Option>
                  {revenueClasses.map((revenueClass) => (
                    <Form.Select.Option key={revenueClass.id} value={revenueClass.id}>
                      {revenueClass.name}
                    </Form.Select.Option>
                  ))}
                </Form.Select>
              </Grid>
            </Grid>

            <Grid item container xs={12}
              spacing={2} marginTop={2.5}>
              <Grid item xs={3} xl={3}>
                <Form.RadioGroup label={"Apply At"} name={"applyFeeAt"} valuesAsNumber>
                  {radioOptions.map((option) => (
                    <Form.RadioGroup.Option key={option.value} value={option.value} label={option.name} />
                  ))}
                </Form.RadioGroup>
              </Grid>
              <Grid item xs={9} xl={9}>
                <Form.Text
                  id={"description"}
                  label={"Description"}
                  name={"feeDescription"}
                  multiline
                  rows={5}
                  // fullWidth
                />
              </Grid>
            </Grid>

            <Grid container item xs={12}
              marginTop={2.5} justifyContent={"flex-end"}>
              <Form.Checkbox label={"Required"} name={"isRequired"} />
            </Grid>
          </Grid>
        </Grid>

        <Restrictions />
      </Grid>

      <Grid xs={12} item container
        justifyContent={"flex-end"} mt={3}>
        <Form.Cancel onClick={() => navigate("/fees")} />
        <Form.Submit label={"Save & Continue"} />
      </Grid>
    </Form>
  );
};

export default FeeForm;
