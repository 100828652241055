import { Grid, Paper, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import SelectOccupant from "src/components/stepper/SelectOccupant/SelectOccupant";
import StepLayout from "src/layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "src/layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import {
  selectSelectedOccupant
} from "src/store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { setPosModalOpen } from "src/store/reducers/pointOfSalesSlice/pointOfSalesSlice";
import { useNavigate } from "react-router";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";

const SelectOccupantPoS: React.FC = (): ReactElement => {
  const onSubmitHandler = () => { };

  const selectedOccupant = useAppSelector(selectSelectedOccupant);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const forwardActionText = selectedOccupant
    ? "CHOOSE MERCHANDISE"
    : "SKIP FOR WALK-IN CUSTOMER";

  const handleNext = () => {
    navigate("/merchandise/point-of-sales/choose-merch");
  };

  return (
    <Grid py={2}>
      <Paper variant={"outlined"} sx={{ pt: 5 }}>
        <Typography
          variant={"body1"}
          mx={5}
          >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit,
          sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        </Typography>
        <StepLayout
          buttons={
            <StepperButtons
              data-testid={"select-occupant-pos-buttons"}
              stepperId={"select-occupant-pos"}
              showBackButton={false}
              onCancel={() => dispatch(setPosModalOpen(true))}
              forwardAction={handleNext}
              showCancelButton={!!selectedOccupant}
              forwardActionText={forwardActionText}
              />
            }
            >
          <SelectOccupant
            data-testid={"select-move-out-occupant"}
            disableAddOccupant
            onlyWithActiveLedgers
            showWarning={false}
            onSubmitHandler={onSubmitHandler}
            />
        </StepLayout>
      </Paper>

    </Grid>
  );
};

export default SelectOccupantPoS;
