import { Button, Grid, Modal, Paper, Typography } from "@mui/material";
import React, { useMemo } from "react";
import {
  selectBucketFilter,
  selectCollections,
  selectConfirmCreateAuctionsModalOpen,
  setConfirmCreateAuctionsModalOpen
} from "src/store/reducers/collectionsSlice/collectionsSlice";
import {
  selectOrder,
  selectOrderBy,
  selectPage,
  selectRowsPerPage,
  selectSearchValue,
  setSelectedRows
} from "src/store/reducers/tablesSlice/tablesSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { useFlagForAuctionMutation } from "src/api/endpoints/auctions";
import { useLazyGetDelinquentOccupantsQuery } from "src/api/endpoints/delinquentOccupants";
import useStyles from "./CreateAuctionsConfirmationModal.styles";

const CreateAuctionsConfirmationModal = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const {
    auction,
    collections
  } = useAppSelector(selectCollections);
  const page = useAppSelector(selectPage);
  const pageSize = useAppSelector(selectRowsPerPage);
  const order = useAppSelector(selectOrder);
  const orderBy = useAppSelector(selectOrderBy);
  const bucketFilter = useAppSelector(selectBucketFilter);
  const searchValue = useAppSelector(selectSearchValue);
  const modalOpen = useAppSelector(selectConfirmCreateAuctionsModalOpen);
  const params = useMemo(() => ({
    page: page + 1,
    pageSize,
    rowsPerPage: pageSize,
    sortDirection: order!,
    sortBy: orderBy,
    bucket: bucketFilter,
    searchValue
  }), [page, pageSize, order, orderBy, bucketFilter, searchValue]);

  const [flagForAuctionMutation, { isError, isLoading }] = useFlagForAuctionMutation();

  const [lazyGetDelinquentOccupants] = useLazyGetDelinquentOccupantsQuery();

  const handleClose = () => {
    dispatch(setConfirmCreateAuctionsModalOpen(false));
  };

  const handleSubmit = async() => {
    if (!auction || !(collections.length > 0)) {
      return;
    }
    await flagForAuctionMutation({
      facilityId: collections[0]?.ledger.facility_id,
      payload: collections?.map((collection) => collection.ledger_id),
      auctionDate: moment(auction?.auctionDate, "MM/DD/YYYY"),
      auctionTime: moment(auction?.auctionTime, "HH:mmZ")
    });

    if (!isError) {
      dispatch(
        showSnackbar({
          message: "Unit successfully flagged for auction.",
          variant: "success"
        })
      );
      dispatch(setSelectedRows([]));
    } else {
      dispatch(
        showSnackbar({
          message: "There was an error flagging unit for auction.",
          variant: "error"
        })
      );
    }

    lazyGetDelinquentOccupants({ id: collections[0]?.ledger.facility_id, params });
    handleClose();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby={"Confirm flag unit for auction"}
      aria-describedby={"Modal to confirm flag unit for auction"}
      className={classes.modal}
      data-testid={"modal-remove-unit"}
    >
      <Grid
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        spacing={2}
        className={classes.container}
        pb={2}
      >
        <Box className={classes.content}>
          <Typography variant={"h5"} className={classes.title}>
            Confirm Flag Unit(s) for Auction
          </Typography>
          <Typography>Please flag unit(s) for auction for the selected time:</Typography>

          <Typography m={2}>
            Date: {moment(auction?.auctionDate, "MM/DD/YYYY").format("YYYY-MM-DD") ?? "N/A"}
          </Typography>
          <Typography>Time: {moment(auction?.auctionTime, "HH:mmZ").format("hh:mm A") ?? "N/A"}</Typography>
          <Grid pt={4} spacing={4} container
            justifyContent={"center"}>
            <Grid item>
              <Button
                data-testid={"modal-remove-unit-cancel-button"}
                className={classes.cancelButton}
                onClick={handleClose}
                variant={"outlined"}
                color={"secondary"}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                data-testid={"modal-remove-unit-confirm-button"}
                color={"primary"}
                variant={"outlined"}
                className={classes.button}
                onClick={handleSubmit}
                loading={isLoading}
              >
                Confirm
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Modal>
  );
};

export default CreateAuctionsConfirmationModal;
