import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../rootStore";
import { Deal } from "src/models/Deal";

export interface DealNotificationSliceState {
  selectedDeal?: Deal;
  lastInteractionTime: number;
  startedFollowUp: Array<number>;
}

export const initialState: DealNotificationSliceState = {
  selectedDeal: undefined,
  lastInteractionTime: 0,
  startedFollowUp: []
};

export const dealNotificationSlice = createSlice({
  name: "dealNotification",
  initialState,
  reducers: {
    setLastInteractionTime: (state, action) => {
      state.lastInteractionTime = action.payload;
    },
    setStartedFollowUp: (state, action: PayloadAction<number>) => {
      state.startedFollowUp.push(action.payload);
    },
    setSelectedDealNotification: (state, action) => {
      state.selectedDeal = action.payload;
    },
    resetDealNotifications: (state) => {
      Object.assign(state, initialState);
    }
  }
});

export const selectDealNotification = (state: RootState) => state.dealNotification;

export const { setLastInteractionTime, setSelectedDealNotification, setStartedFollowUp, resetDealNotifications } =
  dealNotificationSlice.actions;

export default dealNotificationSlice.reducer;
