import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { allFacilitiesFakeFacility, allFacilitiesValue } from "../../../utils/AllFacilitiesFakeFacility";
import { APIListResponse } from "../../../models/responses/APIListResponse";
import { FacilitiesSliceState } from "./FacilitiesSliceState";
import { Facility } from "../../../models/Facility";
import { RootState } from "../../rootStore";
import { getAllFacilities } from "../../thunks/facility/getAll/getAllFacilities";
import { getAllPaginatedFacilities } from "../../thunks/facility/getAllPaginated/getAllPaginatedFacilities";

export const initialState: FacilitiesSliceState = {
  facilities: JSON.parse(sessionStorage.getItem("facilities") as string) ?? [],
  paginationFacilities: JSON.parse(sessionStorage.getItem("paginationFacilities") as string) ?? [],
  facilitiesLoading: false,
  facilitiesError: ""
};

export const facilitiesReducerSlice = createSlice({
  name: "facilities",
  initialState,
  reducers: {
    addAllOptionForSelector: (state) => {
      state.facilities = [allFacilitiesFakeFacility, ...state.facilities];
    },
    removeAllOptionForSelector: (state) => {
      const facilities = state.facilities.filter(facility => facility.id !== allFacilitiesValue);

      state.facilities = facilities;
    },
    resetFacilitiesSlice: (state) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("facilities");
      sessionStorage.removeItem("paginationFacilities");
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllFacilities.pending, (state) => {
        state.facilitiesLoading = true;
      })
      .addCase(getAllFacilities.fulfilled, (state, action: PayloadAction<APIListResponse<Facility[]>>) => {
        state.facilities = action.payload.data;
        sessionStorage.setItem("facilities", JSON.stringify(action.payload.data));
        state.facilitiesLoading = false;
      })
      .addCase(getAllFacilities.rejected, (state, action) => {
        state.facilitiesError = action.payload as string;
        state.facilitiesLoading = false;
      })
      .addCase(getAllPaginatedFacilities.pending, (state) => {
        state.facilitiesLoading = true;
      })
      .addCase(getAllPaginatedFacilities.fulfilled, (state, action: PayloadAction<APIListResponse<Facility[]>>) => {
        state.paginationFacilities = action.payload.data;
        sessionStorage.setItem("paginationFacilities", JSON.stringify(action.payload.data));
        state.facilitiesLoading = false;
      })
      .addCase(getAllPaginatedFacilities.rejected, (state, action) => {
        state.facilitiesError = action.payload as string;
        state.facilitiesLoading = false;
      });
  }
});

export const selectFacilities = (state: RootState) => state.facilities.facilities;
export const selectPaginationFacilities = (state: RootState) => state.facilities.paginationFacilities;
export const selectFacilitiesLoading = (state: RootState) => state.facilities.facilitiesLoading;
export const selectFacilitiesError = (state: RootState) => state.facilities.facilitiesError;

export const {
  resetFacilitiesSlice,
  addAllOptionForSelector,
  removeAllOptionForSelector
} = facilitiesReducerSlice.actions;

export default facilitiesReducerSlice.reducer;
