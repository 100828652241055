import { LONG_DAMAGE, NOT_APPLICABLE, POST_AUCTION, SHORT_DAMAGE } from "./unrentableReasons";

const getUnitUnrentableReason = (reason: string | null) => {
  switch (reason) {
    case SHORT_DAMAGE:
      return 1;
    case LONG_DAMAGE:
      return 2;
    case POST_AUCTION:
      return 3;
    case NOT_APPLICABLE:
      return 4;
    default:
      return 4;
  }
};

export default getUnitUnrentableReason;
