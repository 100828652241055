import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";

import { ArrowBack } from "@mui/icons-material";
import { ReactComponent as ErrorIcon } from "src/assets/icons/403.svg";
import { Link } from "react-router-dom";
import useStyles from "./Redirect403.styles";

interface Redirect403Props{}

const Redirect403: FC<Redirect403Props> = () => {
  const { classes } = useStyles();
  return (
    <Grid container >
      <Grid
        item
        xs={12}
        alignItems={"center"}
        justifyContent={"center"}
        display={"flex"}
        flexDirection={"column"}
        height={"90vh"}
      >
        <Grid
          item
          xs={6}
        >
          <Grid container alignItems={"center"} gap={2}>
            <Typography className={classes.statusText}>
              403
            </Typography>
            <ErrorIcon/>
          </Grid>

          <Typography className={classes.titleText}>
            Oops. This page needs a key to enter
          </Typography>
          <p className={classes.subtitleText}>
            Sorry but the page you&rsquo;re looking for requires permission to view. <br/>
            Please check your credentials and try again.
          </p>
          <Link to={"/"} style={{ textDecoration: "none" }}>
            <Grid
              display={"flex"}
              flexDirection={"row"}
              gap={1}
              alignItems={"center"}
            >
              <ArrowBack/>
              <Typography className={classes.returnText}>
                Return to dashboard
              </Typography>
            </Grid>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Redirect403;
