import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import PaymentMethodSection from "./PaymentMethodSection/PaymentMethodSection";
import ReviewAndPayQuantityTable from "./ReviewAndPayQuantityTable/ReviewAndPayQuantityTable";
import { getMerchandiseCost } from "src/store/thunks/merchandise/getMerchandiseCost/getMerchandiseCost";
import { selectMerchLineItemIds } from "src/store/reducers/pointOfSalesSlice/pointOfSalesSlice";
import { selectMerchandiseItems } from "src/store/reducers/merchandiseItemsSlice/merchandiseItemsSlice";
import { useNavigate } from "react-router";

interface ReviewAndPayProps {}

const ReviewAndPay: FC<ReviewAndPayProps> = () => {
  const selectedMerchItemIds = useAppSelector(selectMerchLineItemIds);
  const merchandise = useAppSelector(selectMerchandiseItems);
  const selectedMerchItems = merchandise.filter(merchItems => selectedMerchItemIds.includes(merchItems.id));

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    !selectedMerchItemIds.length && navigate("/merchandise/point-of-sales");
  }, []);

  useEffect(() => {
    if (selectedMerchItemIds.length > 0) {
      dispatch(getMerchandiseCost());
    }
  }, [selectedMerchItems]);

  return (
    <>
      <ReviewAndPayQuantityTable />
      <PaymentMethodSection />
    </>
  );
};

export default ReviewAndPay;
