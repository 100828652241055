import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "GetStarted" })((theme) => ({
  button: {
    boxShadow: "none",
    width: "12rem",
    backgroundColor: theme.palette.primary.contrastText,
    textTransform: "capitalize",
    fontWeight: 300,
    color: theme.palette.common.white
  }
}));

export default useStyles;
