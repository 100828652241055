import { Button, CircularProgress } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";
import BackButton from "./BackButton/BackButton";
import { useNavigate } from "react-router";
import useStyles from "./StepperButtons.styles";

interface StepperButtonsProps {
  loading?: boolean
  backAction?: () => void
  backActionText?: ReactNode
  forwardActionText?: ReactNode
  forwardAction?: () => void
  stepperId?: string
  disabled?: boolean
  onCancel?: () => void
  showBackButton?: boolean
  showCancelButton?: boolean
}

const StepperButtons: React.FC<StepperButtonsProps> = ({
  loading,
  disabled = false,
  backActionText = <BackButton />,
  forwardActionText = "Next",
  backAction,
  onCancel,
  showCancelButton = false,
  forwardAction = () => {},
  stepperId = "something",
  showBackButton = true
}: StepperButtonsProps): ReactElement => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const goBackHandler = () => {
    if (backAction) {
      return backAction();
    }

    navigate(-1);
  };

  const cancelHandler = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <>
      { showCancelButton &&
        <Button
          color={"error"}
          onClick={() => cancelHandler()}
          variant={"text"}
          sx={{ mr: 2 }}
          data-testid={"step-cancel-button"}
        >
          Cancel
        </Button>
      }
      { showBackButton &&
        <Button
          className={classes.backButton}
          onClick={goBackHandler}
          variant={"text"}
          data-testid={"step-back-button"}
        >
          {backActionText}
        </Button>
      }
      <Button
        form={stepperId}
        disabled={disabled}
        type={"submit"}
        variant={"contained"}
        className={classes.nextButton}
        data-testid={"step-forward-button"}
        onClick={forwardAction}
      >
        {loading
          ? <CircularProgress
              data-testid={"next-loading-spinner"}
              size={"1.5rem"}
              className={classes.spinner}
              aria-label={"stepper loading"}
            />
          : forwardActionText}
      </Button>
    </>
  );
};

export default StepperButtons;
