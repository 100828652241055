import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Deal } from "../../../models/Deal";
import { DealSliceState } from "./DealSliceState";
import { Reservation } from "src/models/Reservation";
import { RootState } from "../../rootStore";
import { createLead } from "../../thunks/lead/create/createLead";
import { createReservation } from "../../thunks/reservation/create/createReservation";
import { getDeal } from "../../thunks/deals/get/getDeal";

export const initialState: DealSliceState = {
  deal: JSON.parse(sessionStorage.getItem("deal") as string) ?? null,
  dealLoading: false,
  dealError: "",
  dealSubmitted: false,
  cancelDealModalOpen: false,
  reactivateDealModalOpen: false,
  stepEditDealToReactivate: 0,
  unableToReactivateModalOpen: false,
  unableToReactivateDealType: "RESERVATION",
  searchValue: ""
};

export const dealSlice = createSlice({
  name: "deal",
  initialState,
  reducers: {
    setDeal: (state, action: PayloadAction<Deal | Reservation >) => {
      state.deal = action.payload;
      sessionStorage.setItem("deal", JSON.stringify(state.deal));
    },
    resetDealSlice: (state: DealSliceState) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("deal");
    },
    setCancelDealModalOpen: (state, action: PayloadAction<boolean>) => {
      state.cancelDealModalOpen = action.payload;
    },
    setReactivateDealModalOpen: (state, action: PayloadAction<boolean>) => {
      state.reactivateDealModalOpen = action.payload;
    },
    setStepEditDealToReactivate: (state, action: PayloadAction<number>) => {
      state.stepEditDealToReactivate = action.payload;
    },
    setUnableToReactivateModalOpen: (state, action: PayloadAction<boolean>) => {
      state.unableToReactivateModalOpen = action.payload;
    },
    setUnableToReactivateDealType: (state, action: PayloadAction<"RESERVATION" | "LEAD">) => {
      state.unableToReactivateDealType = action.payload;
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDeal.pending, (state) => {
        state.dealLoading = true;
      })
      .addCase(getDeal.fulfilled, (state, action: PayloadAction<Deal>) => {
        state.dealLoading = false;
        state.deal = action.payload;
        sessionStorage.setItem("deal", JSON.stringify(state.deal));
      })
      .addCase(getDeal.rejected, (state, action) => {
        state.dealError = action.payload as string;
        state.dealLoading = false;
      })
      .addCase(createLead.pending, (state: DealSliceState) => {
        state.dealSubmitted = true;
        state.dealLoading = true;
      })
      .addCase(createLead.fulfilled, (state: DealSliceState, action: PayloadAction<Deal>) => {
        state.dealLoading = false;
        state.deal = action.payload;
        sessionStorage.setItem("deal", JSON.stringify(state.deal));
      })
      .addCase(createLead.rejected, (state: DealSliceState, action) => {
        state.dealLoading = false;
        state.dealError = action.payload as string;
      })
      .addCase(createReservation.pending, (state: DealSliceState) => {
        state.dealSubmitted = true;
        state.dealLoading = true;
      })
      .addCase(createReservation.fulfilled, (state: DealSliceState, action: PayloadAction<Deal>) => {
        state.dealLoading = false;
        state.deal = action.payload;
        sessionStorage.setItem("deal", JSON.stringify(state.deal));
      })
      .addCase(createReservation.rejected, (state: DealSliceState, action) => {
        state.dealLoading = false;
        state.dealError = action.payload as string;
      });
  }

});

export const selectDeal = (state: RootState) => state.deal.deal;
export const selectDealLoading = (state: RootState) => state.deal.dealLoading;
export const selectDealError = (state: RootState) => state.deal.dealError;
export const selectDealSubmitted = (state: RootState) => state.deal.dealSubmitted;
export const selectCancelDealModalOpen = (state: RootState) => state.deal.cancelDealModalOpen;
export const selectReactivateDealModalOpen = (state: RootState) => state.deal.reactivateDealModalOpen;
export const selectStepEditDealToReactivate = (state: RootState) => state.deal.stepEditDealToReactivate;
export const selectUnableToReactivateModalOpen = (state: RootState) => state.deal.unableToReactivateModalOpen;
export const selectUnableToReactivateDealType = (state: RootState) => state.deal.unableToReactivateDealType;
export const selectSearchValue = (state: RootState) => state.deal.searchValue;

export const {
  setDeal,
  resetDealSlice,
  setCancelDealModalOpen,
  setReactivateDealModalOpen,
  setStepEditDealToReactivate,
  setUnableToReactivateModalOpen,
  setSearchValue,
  setUnableToReactivateDealType
} = dealSlice.actions;

export default dealSlice.reducer;
