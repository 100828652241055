import {
  Button,
  Divider,
  ExtendButton,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Modal,
  Paper,
  Radio,
  SelectChangeEvent,
  Typography
} from "@mui/material";
import { CheckCircleOutline, Close } from "@mui/icons-material";
import React, { ChangeEvent, FC, FormEvent } from "react";
import {
  selectAutomaticPaymentSlice,
  setAutomaticPaymentMethod,
  setAutomaticPaymentsModalOpened,
  setConfirmAutomaticPaymentsModalOpened,
  setSelectedPaymentInstrument,
  setSelectedPaymentType
} from "src/store/reducers/automaticPaymentsSlice/automaticPaymentsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";

import { LoadingButton } from "@mui/lab";
import PMSRadio from "src/components/ui/PMSRadio/PMSRadio";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import PaymentEntry from "src/pages/Deals/CreateMoveIn/ReviewMoveIn/PaymentEntry/PaymentEntry";
import PaymentMethod from "src/pages/Deals/CreateMoveIn/ReviewMoveIn/PaymentMethod/PaymentMethod";
import { PaymentMethod as PaymentMethodEnum } from "src/enums/PaymentMethod";
import { PaymentType } from "src/models/PaymentType";
import { inputError } from "src/utils/showInputError/showInputError";
import { selectSelectedOccupant } from "src/store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { setSelectedLedger } from "src/store/reducers/ledgersSlice/ledgersSlice";
import { useFormik } from "formik";
import useStyles from "./AutomaticPaymentsModal.styles";
import validationSchema from "./AutomaticPaymentsModalValidation";

interface AutomaticPaymentsModalProps {}

export const PAYMENT_METHODS = {
  SAVED_PAYMENT_METHODS: 1,
  ADD_NEW_PAYMENT_METHOD: 2
};

export const AUTOMATIC_PAYMENTS_FORM_ID = "automatic-payments-form";

const AutomaticPaymentsModal: FC<AutomaticPaymentsModalProps> = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const selectedOccupant = useAppSelector(selectSelectedOccupant);
  const paymentInstruments = selectedOccupant?.payment_instruments;
  const {
    automaticPaymentsModalOpened,
    automaticPaymentMethod,
    selectedPaymentInstrument,
    selectedPaymentType
  } = useAppSelector(selectAutomaticPaymentSlice);

  const initialValues = {
    paymentMethod: automaticPaymentMethod,
    selectedPaymentInstrument: selectedPaymentInstrument
  };

  const handleSave = () => {
    dispatch(setConfirmAutomaticPaymentsModalOpened(true));
    dispatch(setAutomaticPaymentsModalOpened(false));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values) => {
      handleSave();
    }
  });

  const { values, errors, touched, resetForm, setFieldValue } = formik;

  const handleCloseModal = async() => {
    resetForm({ values: initialValues });
    await dispatch(setAutomaticPaymentsModalOpened(false));
    await dispatch(setSelectedLedger(null));
  };

  const handlePaymentMethodOnchange = (value: number) => {
    dispatch(setAutomaticPaymentMethod(value));
    setFieldValue("paymentMethod", value);

    if (value === PAYMENT_METHODS.ADD_NEW_PAYMENT_METHOD) {
      dispatch(setSelectedPaymentInstrument(0));
      setFieldValue("selectedPaymentInstrument", 0);
    }
  };

  const handlePaymentTypeOnChange = (value: PaymentType) => {
    dispatch(setSelectedPaymentType(value));
  };

  const AutomaticPaymentsMethods = () => {
    const handleSelectedPaymentInstrumentChange = (event: SelectChangeEvent) => {
      dispatch(setSelectedPaymentInstrument(+event.target.value));
      setFieldValue("selectedPaymentInstrument", +event.target.value);
    };

    return (
      <FormControlLabel
        key={PAYMENT_METHODS.SAVED_PAYMENT_METHODS}
        value={PAYMENT_METHODS.SAVED_PAYMENT_METHODS}
        control={<Radio checkedIcon={<CheckCircleOutline />} />}
        label={
          <PMSSelect
            id={"payment-instrument"}
            name={"selectedPaymentInstrument"}
            value={values.selectedPaymentInstrument}
            changeHandler={handleSelectedPaymentInstrumentChange}
            className={classes.selectPaymentInstrumentContainer}
            error={inputError("selectedPaymentInstrument", touched, errors).error}
            helperText={inputError("selectedPaymentInstrument", touched, errors).helperText}
            disabled={values.paymentMethod !== PAYMENT_METHODS.SAVED_PAYMENT_METHODS}
          >
            <option value={0} disabled>
              Select Payment Instrument
            </option>
            {paymentInstruments?.map((paymentInstrument) => (
              <option key={paymentInstrument.id} value={paymentInstrument.id}>
                {`xxxx xxxx xxxx ${paymentInstrument.last_4_digits}`} {
                `Exp. ${paymentInstrument.exp_month}/${paymentInstrument.exp_year}`}
              </option>
            ))}
          </PMSSelect>
            }
          />
    );
  };

  const loadingButtonProps = {
    onClick: () => {},
    disabled: false
  };

  if (values.paymentMethod === PAYMENT_METHODS.SAVED_PAYMENT_METHODS) {
    loadingButtonProps.onClick = () => handleSave();
    loadingButtonProps.disabled = !values.selectedPaymentInstrument;
  }

  return (
    <Modal onClose={handleCloseModal} open={automaticPaymentsModalOpened} className={classes.modal}>

      <Grid
        p={4}
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        justifyContent={"center"}
        className={classes.container}
        >

        <Grid item xs={11}>
          <Typography variant={"h5"}>Automatic Payments</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={handleCloseModal}>
            <Close />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"body1"}>
            Choose the payment method you would like to set up automatic payments with.
          </Typography>
        </Grid>

        <Grid container xs={12}>
          <Grid item xs={12}>
            <FormHelperText error={!!errors.paymentMethod}>
              {errors.paymentMethod }
            </FormHelperText>
          </Grid>
          <PMSRadio
            value={automaticPaymentMethod}
            name={"paymentMethod"}
            changeHandler={(e: ChangeEvent<HTMLInputElement>) =>
              handlePaymentMethodOnchange(Number(e.target.value))
              }
            >
            {AutomaticPaymentsMethods()}
            <FormControlLabel
              key={PAYMENT_METHODS.ADD_NEW_PAYMENT_METHOD}
              value={PAYMENT_METHODS.ADD_NEW_PAYMENT_METHOD}
              control={<Radio checkedIcon={<CheckCircleOutline />} />}
              label={"Add New Payment Method"}
              // disabled={disabled}
              />
          </PMSRadio>
        </Grid>

        {automaticPaymentMethod === PAYMENT_METHODS.ADD_NEW_PAYMENT_METHOD && (
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12} lg={3}>
              <InputLabel className={classes.headerCell}>Payment Method</InputLabel>
              <PaymentMethod
                paymentOptions={[PaymentMethodEnum.creditCard, PaymentMethodEnum.ach]}
                customPaymentType={selectedPaymentType}
                handleOnChange={handlePaymentTypeOnChange}
              />
            </Grid>
            <Grid item xs={12} lg={9}>
              <PaymentEntry
                formId={AUTOMATIC_PAYMENTS_FORM_ID}
                handleSubmit={handleSave}
                withPaymentOptions={false}
                customPaymentType={selectedPaymentType}
                />
            </Grid>
          </Grid>
        )}

        <Grid item container xs={12}
          mt={2} justifyContent={"flex-end"}>
          <Button onClick={handleCloseModal} color={"error"} variant={"text"}
            sx={{ marginRight: 5 }}>
            Cancel
          </Button>
          <LoadingButton
            variant={"contained"}
            type={"submit"}
            form={AUTOMATIC_PAYMENTS_FORM_ID}
            className={classes.button}
            {...(loadingButtonProps)}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AutomaticPaymentsModal;
