import React, { ReactElement } from "react";
import {
  selectPaginationRevenueClasses, selectRevenueClassesFilter
} from "../../../../store/reducers/revenueClassesSlice/revenueClassesSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import EmptyTable from "../../../../components/ui/PMSTable/EmptyTable/EmptyTable";
import PMSTable from "../../../../components/ui/PMSTable/PMSTable";
import RevenueClassesTableColumns from "./RevenueClassesTableColumns/RevenueClassesTableColumns";
import { getAllPaginatedRevenueClasses }
  from "../../../../store/thunks/revenueClass/getAllPaginated/getAllPaginatedRevenueClasses";
import { selectSelectedAdminFacility }
  from "../../../../store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const RevenueClassesTable: React.FC = (): ReactElement => {
  const filter = useAppSelector(selectRevenueClassesFilter);
  const dispatch = useAppDispatch();
  const facility = useAppSelector(selectSelectedAdminFacility);
  const paginationRevenueClasses = useAppSelector(selectPaginationRevenueClasses);
  const permissions = useAppSelector(selectFacilityPermissions);
  const revenueClassesPermissions = createPermissions(permissions, "revenue_classes");

  const handlePaginationUpdate = () => {
    dispatch(getAllPaginatedRevenueClasses({ facilityId: facility?.id, filter }));
  };

  const emptyTable = () => (
    <EmptyTable
      collectionNotFound={`revenue classes`}
      value={"revenue class"}
      addLink={"/accounting/revenue-classes/create"}
    />
  );

  return (
    <PMSTable
      data-testid={"revenue-classes-table"}
      columns={RevenueClassesTableColumns(revenueClassesPermissions.update)}
      rows={paginationRevenueClasses}
      emptyTableDisplay={emptyTable}
      onPaginationUpdate={handlePaginationUpdate}
    />
  );
};

export default RevenueClassesTable;
