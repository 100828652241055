import { makeStyles } from "../../makeStyles";

const useStyles = makeStyles({ label: "Fees" })((theme) => ({
  buttonText: {
    textTransform: "capitalize"
  },
  description: {
    margin: `0 ${theme.spacing(2)}`
  },
  newFeeContainedButton: {
    textTransform: "capitalize",
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.common.white,
    width: "9rem"
  },
  cellTitle: {
    color: theme.palette.primary.contrastText,
    fontStyle: "italic"
  },
  disabledButton: {
    color: theme.palette.text.disabled
  },
  enabledButton: {
    color: theme.palette.text.primary
  },
  editButton: {
    marginLeft: theme.spacing(1),
    background: theme.palette.primary.contrastText,
    color: theme.palette.common.white,
    textTransform: "capitalize"
  }
}));

export default useStyles;
