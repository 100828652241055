import UpdateProductTypePayload from "./UpdateProductTypePayload";
import { axiosInstance as axios } from "src/api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "src/utils/format422/format422";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { mapKeys, snakeCase } from "lodash";

export const updateProductType = createAsyncThunk("updateProductType",
  (payload: UpdateProductTypePayload, { dispatch, rejectWithValue }) => {
    const { facilityId, productTypeId, ...body } = payload;
    const snakeCaseBody = mapKeys(body, (_, key) => snakeCase(key));

    return axios.put(`/api/v1/facilities/${facilityId}/product-types/${productTypeId}`, snakeCaseBody)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Product Type updated successfully",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        if (err.response.status === 422) {
          if (err.response.data.detail) return rejectWithValue(err.response.data.detail);

          const errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        const errorMessage = "There was an error updating the Product Type";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
