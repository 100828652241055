import { IconButton, Tooltip } from "@mui/material";
import React, { FC, ReactElement } from "react";
import {
  setSelectedMerchandiseId,
  setSelectedMerchandiseName
} from "../../../../../store/reducers/merchandiseFormSlice/merchandiseFormSlice";
import { Merchandise } from "../../../../../models/Merchandise";
import { RemoveCircleOutline } from "@mui/icons-material";
import { toggleMerchandiseDeleteModal } from "../../../../../store/reducers/modalSlice/modalSlice";
import { useAppDispatch } from "../../../../../store/hooks";

interface OpenDeleteModalButtonProps{
  merchandise: Merchandise
}

const OpenDeleteModalButton: FC<OpenDeleteModalButtonProps> = ({ merchandise }): ReactElement => {
  const dispatch = useAppDispatch();

  const openDeleteModal = (merchandise: Merchandise) => {
    dispatch(setSelectedMerchandiseId(String(merchandise.id)));
    dispatch(setSelectedMerchandiseName(merchandise.name));
    dispatch(toggleMerchandiseDeleteModal(true));
  };

  return (
    <Tooltip title={"Remove Merchandise"}>
      <IconButton
        size={"small"}
        data-testid={"merchandise-delete-button"}
        onClick={() => openDeleteModal(merchandise)}
      >
        <RemoveCircleOutline />
      </IconButton>
    </Tooltip>
  );
};

export default OpenDeleteModalButton;
