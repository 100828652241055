import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "AddOccupantForm" })((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
    maxWidth: "65%",
    minHeight: "10rem",
    borderRadius: "1rem"
  },
  button: {
    textTransform: "capitalize"
  },
  cancel: {
    color: theme.palette.primary.contrastText
  },
  save: {
    marginLeft: theme.spacing(2),
    background: theme.palette.primary.contrastText,
    color: theme.palette.common.white
  }
}));

export default useStyles;
