import React, { ReactElement } from "react";
import {
  selectOccupants,
  selectOccupantsLoading,
  selectOccupantsSearchValue
} from "src/store/reducers/occupantsSlice/occupantsSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import EmptyTable from "../../../../components/ui/PMSTable/EmptyTable/EmptyTable";
import ManageOccupantsTableColumns from "./ManageOccupantsTableColumns/ManageOccupantsTableColumns";
import PMSTable from "../../../../components/ui/PMSTable/PMSTable";
import { getAllOccupants } from "../../../../store/thunks/occupant/getAll/getAllOccupants";
import { searchOccupants } from "../../../../store/thunks/occupant/search/searchOccupants";
import { getOccupantWithActiveLedgers } from "src/utils/getActiveLedgers/getActiveLedgers";

const ManageOccupantsTable: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();

  const occupants = useAppSelector(selectOccupants);
  const occupantsLoading = useAppSelector(selectOccupantsLoading);
  const searchValue = useAppSelector(selectOccupantsSearchValue);

  const handlePaginationUpdate = () => {
    if (!searchValue.length) {
      dispatch(getAllOccupants());
      return;
    }

    dispatch(searchOccupants({ searchValue }));
  };

  const emptyTable = () => (
    <EmptyTable
      collectionNotFound={"occupants"}
      value={"occupant"}
      addLink={"/occupants/add-occupant"}
    />
  );

  return (
    <PMSTable
      data-testid={"manage-occupants-table"}
      columns={ManageOccupantsTableColumns()}
      rows={getOccupantWithActiveLedgers(occupants)}
      emptyTableDisplay={emptyTable}
      onPaginationUpdate={handlePaginationUpdate}
      loading={occupantsLoading}
    />
  );
};

export default ManageOccupantsTable;
