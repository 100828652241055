import { makeStyles } from "../../../../../makeStyles";

const useStyles = makeStyles()((theme) => ({
  container: {
    border: "0.125rem solid red",
    borderLeft: "1rem solid red",
    borderRadius: "0.25rem",
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "flex-start"
  },
  containerIcon: {
    marginLeft: theme.spacing(2),
    padding: `${theme.spacing(1)} 0`
  },
  containerText: {
    marginLeft: theme.spacing(3),
    padding: `${theme.spacing(1)} 0`
  },
  title: {
    fontWeight: "bold",
    fontSize: "0.9rem"
  },
  subTitle: {
    fontSize: "0.7rem",
    fontStyle: "italic"
  }
}));

export default useStyles;
