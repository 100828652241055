import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";
import { format422 } from "src/utils/format422/format422";

interface UpdateInventoryPayload {

}

export const updateInventory = createAsyncThunk("updateInventory",
  ({ id, merchandiseId, values } : {id: string, merchandiseId: string, values: UpdateInventoryPayload},
    { dispatch, rejectWithValue }) => {
    return axios.put(`/api/v1/merchandise/${merchandiseId}/merchandise-inventories/${id}`, values)
      .then((resp) => {
        dispatch(showSnackbar({
          message: `Inventory successfully updated.`,
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;

        if (err.response.status === 422) {
          errorMessage = err.response.data.detail;

          if (err.response.data.errors) {
            errorMessage = format422(err.response.data.errors);
          }
          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an error updating the inventory";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
