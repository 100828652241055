import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getOccupant = createAsyncThunk("getOccupant", (id: string, { dispatch, getState, rejectWithValue }) => {
  const params = {
    params: {
      with: [
        "ledgers",
        "ledgers.unit",
        "ledgers.ledgerItems",
        "ledgers.unit.productType.revenueClass",
        "ledgers.facility",
        "ledgers.promotion",
        "ledgers.ledgerItems.transactions",
        "ledgers.ledgerTransfer.fromUnit",
        "ledgers.ledgerTransfer.toUnit",
        "ledgers.paymentInstruments",
        "contacts",
        "notes",
        "billingContacts",
        "esignDocuments.unit.ledger",
        "paymentInstruments"
      ]
    }
  };
  return axios
    .get(`/api/v1/occupants/${id}`, params)
    .then((resp) => {
      return resp.data;
    })
    .catch(() => {
      const errorMessage = `There was an error getting occupant ${id}`;

      dispatch(
        showSnackbar({
          message: errorMessage,
          variant: "error"
        })
      );

      return rejectWithValue(errorMessage);
    });
});
