import { Button, Grid, InputLabel, Modal, Paper, TextField, Typography } from "@mui/material";
import React, { FC, FormEvent, SyntheticEvent } from "react";
import initialValues, { CreateNewUnitFormValues } from "./helpers/initialValues";
import {
  selectCreatingNewUnitLoading,
  selectNewUnitModalOpen,
  selectUnitAttributes,
  setNewUnitModalOpen
} from "src/store/reducers/unitsSliceNew/unitsSliceNew";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { LoadingButton } from "@mui/lab";
import { NumericFormat, PatternFormat } from "react-number-format";
import PMSCheckbox from "src/components/ui/PMSCheckbox/PMSCheckbox";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import { PatternFormatProps, SyntheticInputEvent } from "react-number-format/types/types";
import { ValueOptions } from "@mui/x-data-grid";
import assembleNewUnitPayload from "./helpers/assembleNewUnitPayload";
import { createUnitNew } from "src/store/thunks/unitNew/create/createUnitNew";
import { getAllUnitsNew } from "src/store/thunks/unitNew/get/getAllUnitsNew";
import { inputError } from "src/utils/showInputError/showInputError";
import newUnitValidation from "./newUnitValidation";
import { selectProductTypes } from "src/store/reducers/productTypesSlice/productTypesSlice";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { unrentableReasons } from "src/utils/constants";
import { useFormik } from "formik";
import useStyles from "./NewUnitModal.styles";
import sizeFormatter from "src/utils/sizeFormatter/sizeFormatter";

interface NewUnitModalProps {
  isWithinProductType?: boolean;
  productTypeId?: string;
}

const NewUnitModal: FC<NewUnitModalProps> = ({ isWithinProductType, productTypeId }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const newUnitModalOpen = useAppSelector(selectNewUnitModalOpen);
  const productTypes = useAppSelector(selectProductTypes);
  const unitAttributes = useAppSelector(selectUnitAttributes);
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const loadingNewUnit = useAppSelector(selectCreatingNewUnitLoading);

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: newUnitValidation,
    onSubmit: (values: CreateNewUnitFormValues, { resetForm }) => {
      const payload = assembleNewUnitPayload(values, unitAttributes);
      //dispatch thunkage and whatnot with this payload and a facility id
      dispatch(createUnitNew(payload)).then((resp: any) => {
        if (resp.type.includes("fulfilled")) {
          dispatch(setNewUnitModalOpen(false));
          if (isWithinProductType) {
            dispatch(getAllUnitsNew({ id: String(selectedFacility.id), productTypeId }));
          } else {
            dispatch(getAllUnitsNew({ id: String(selectedFacility.id) }));
          }

          resetForm();
        }
      });
    }
  });

  const { values, resetForm, handleSubmit, handleChange, setFieldValue, touched, errors } = formik;

  const handleClose = () => {
    dispatch(setNewUnitModalOpen(false));
    resetForm({ values: formik.initialValues });
  };

  const toggleAttrCheckbox = (fieldName: string) => {
    const attr: { sort_order: number; value: boolean } = values[fieldName as keyof ValueOptions];
    setFieldValue(fieldName + ".value", !attr.value);
  };

  const handleSizeUpdate = (e: SyntheticInputEvent) => {
    const formattedValue = sizeFormatter(e.target.value);
    handleChange({ target: { name: e.target.name, value: formattedValue } });
  };

  return (
    <Modal className={classes.modal} onClose={handleClose} open={newUnitModalOpen}>
      <Grid container p={4} className={classes.root}
        component={Paper} elevation={0}>
        <Grid
          container
          spacing={1}
          component={"form"}
          onSubmit={(e: FormEvent) => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit();
          }}
        >
          <Grid item xs={12}>
            <Typography variant={"h5"}>Unit Information</Typography>
          </Grid>
          <Grid item xs={3}>
            <InputLabel htmlFor={"unit_number"}>Unit Number</InputLabel>
            <TextField
              size={"small"}
              fullWidth
              id={"unit_number"}
              name={"unit_number"}
              value={values.unit_number}
              onChange={handleChange}
              error={inputError("unit_number", touched, errors).error}
              helperText={inputError("unit_number", touched, errors).helperText}
            />
          </Grid>
          <Grid item xs={3}>
            <InputLabel htmlFor={"unit_number"}>Price</InputLabel>
            <NumericFormat
              fullWidth
              size={"small"}
              customInput={TextField}
              valueIsNumericString={true}
              value={values.price}
              decimalScale={2}
              onValueChange={(values) => setFieldValue("price", values.formattedValue)}
              InputProps={{
                startAdornment: <span>$</span>
              }}
              error={inputError("price", touched, errors).error}
              helperText={inputError("price", touched, errors).helperText}
            />
          </Grid>
          <Grid item xs={3}>
            <InputLabel htmlFor={"Product Type"}>Product Type</InputLabel>
            <PMSSelect
              size={"small"}
              helperText={inputError("product_type", touched, errors).helperText}
              error={inputError("product_type", touched, errors).error}
              id={"product_type"}
              name={"product_type"}
              value={values.product_type}
              changeHandler={(e) => setFieldValue("product_type", e.target.value)}
            >
              <option disabled value={0}>
                Product Type
              </option>
              {productTypes.map((productType) => (
                <option key={productType.id} value={productType.id}>
                  {productType.name}
                </option>
              ))}
            </PMSSelect>
          </Grid>
          <Grid item xs={3}>
            <InputLabel htmlFor={"walk_through_order"}>Walk Through Order</InputLabel>
            <TextField
              type={"number"}
              size={"small"}
              fullWidth
              id={"walk_through_order"}
              name={"walk_through_order"}
              value={values.walk_through_order}
              onChange={handleChange}
              onInput={(e: SyntheticInputEvent) => {
                const val = (e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3));
                return val;
              }}
              error={inputError("walk_through_order", touched, errors).error}
              helperText={inputError("walk_through_order", touched, errors).helperText}
            />
          </Grid>
          <Grid item xs={3}>
            <InputLabel htmlFor={"width"}>Width</InputLabel>
            <TextField
              type={"number"}
              fullWidth
              size={"small"}
              id={"width"}
              name={"width"}
              value={values.width}
              onChange={handleSizeUpdate}
              InputProps={{
                endAdornment: <span>ft.</span>
              }}
              error={inputError("width", touched, errors).error}
              helperText={inputError("width", touched, errors).helperText}
            />
          </Grid>
          <Grid item xs={3}>
            <InputLabel htmlFor={"length"}>Length</InputLabel>
            <TextField
              type={"number"}
              size={"small"}
              fullWidth
              id={"length"}
              name={"length"}
              value={values.length}
              onChange={handleSizeUpdate}
              InputProps={{
                endAdornment: <span>ft.</span>
              }}
              error={inputError("length", touched, errors).error}
              helperText={inputError("length", touched, errors).helperText}
            />
          </Grid>
          <Grid item xs={3}>
            <InputLabel htmlFor={"height"}>Height</InputLabel>
            <TextField
              type={"number"}
              fullWidth
              size={"small"}
              id={"height"}
              name={"height"}
              value={values.height}
              onChange={handleSizeUpdate}
              InputProps={{
                endAdornment: <span>ft.</span>
              }}
              error={inputError("height", touched, errors).error}
              helperText={inputError("height", touched, errors).helperText}
            />
          </Grid>
          <Grid item xs={3}>
            <InputLabel htmlFor={"floor"}>Floor</InputLabel>
            <TextField
              type={"number"}
              fullWidth
              size={"small"}
              id={"floor"}
              name={"floor"}
              value={values.floor}
              onChange={handleChange}
              onInput={(e: SyntheticInputEvent) => {
                const val = (e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2));
                return val;
              }}
              error={inputError("floor", touched, errors).error}
              helperText={inputError("floor", touched, errors).helperText}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant={"h6"}>Unit Attributes</Typography>
          </Grid>
          {/* Checkbox columns */}
          <Grid container item xs={6}>
            <Grid item xs={12}>
              <PMSCheckbox
                name={"interior"}
                label={"Interior"}
                changeHandler={() => toggleAttrCheckbox("interior")}
                isChecked={values.interior.value}
              />
            </Grid>
            <Grid item xs={12}>
              <PMSCheckbox
                name={"drive-up"}
                label={"Drive-Up"}
                changeHandler={() => toggleAttrCheckbox("drive_up")}
                isChecked={values.drive_up.value}
              />
            </Grid>
            <Grid item xs={12}>
              <PMSCheckbox
                name={"climate"}
                label={"Climate"}
                changeHandler={() => toggleAttrCheckbox("climate")}
                isChecked={values.climate.value}
              />
            </Grid>
            <Grid item xs={12}>
              <PMSCheckbox
                name={"air"}
                label={"Air"}
                changeHandler={() => toggleAttrCheckbox("air")}
                isChecked={values.air.value}
              />
            </Grid>
            <Grid item xs={12}>
              <PMSCheckbox
                name={"Heated"}
                label={"Heated"}
                changeHandler={() => toggleAttrCheckbox("heated")}
                isChecked={values.heated.value}
              />
              <Grid item xs={12}>
                <PMSCheckbox
                  name={"Illuminated"}
                  label={"Illuminated"}
                  changeHandler={() => toggleAttrCheckbox("illuminated")}
                  isChecked={values.illuminated.value}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={12}>
              <PMSCheckbox
                name={"Electric"}
                label={"Electric"}
                changeHandler={() => toggleAttrCheckbox("electric")}
                isChecked={values.electric.value}
              />
            </Grid>
            <Grid item xs={12}>
              <PMSCheckbox
                name={"Alarm"}
                label={"Alarm"}
                changeHandler={() => toggleAttrCheckbox("alarm")}
                isChecked={values.alarm.value}
              />
            </Grid>
            <Grid item xs={12}>
              <PMSCheckbox
                name={"Elevator"}
                label={"Elevator"}
                changeHandler={() => toggleAttrCheckbox("elevator")}
                isChecked={values.elevator.value}
              />
            </Grid>
            <Grid item xs={12}>
              <PMSCheckbox
                name={"Stairs"}
                label={"Stairs"}
                changeHandler={() => toggleAttrCheckbox("stairs")}
                isChecked={values.stairs.value}
              />
            </Grid>
            <Grid item xs={12}>
              <PMSCheckbox
                name={"ADA"}
                label={"ADA"}
                changeHandler={() => toggleAttrCheckbox("ada")}
                isChecked={values.ada.value}
              />
            </Grid>
            <Grid item xs={12}>
              <PMSCheckbox
                name={"Rentable"}
                label={"Rentable"}
                changeHandler={() => setFieldValue("rentable", !values.rentable)}
                isChecked={values.rentable}
              />
            </Grid>
          </Grid>
          {!values.rentable && (
            <>
              <Grid item xs={12}>
                <Typography variant={"h6"}>Confirm Unrentable Reason</Typography>
              </Grid>
              <Grid item xs={12}>
                <PMSSelect
                  size={"small"}
                  id={"unrentable_reason"}
                  name={"unrentable_reason"}
                  value={values.unrentable_reason}
                  changeHandler={(e) => setFieldValue("unrentable_reason", e.target.value)}
                >
                  {unrentableReasons.map((reason) => (
                    <option key={reason.value} value={reason.value}>
                      {reason.label}
                    </option>
                  ))}
                </PMSSelect>
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor={"unrentable-reason"}>Notes:</InputLabel>
                <TextField
                  id={"unrentable_note"}
                  fullWidth
                  multiline
                  rows={3}
                  inputProps={{ maxLength: 500 }}
                  variant={"outlined"}
                  name={"unrentable_note"}
                  value={values.unrentable_note}
                  onChange={handleChange}
                  error={inputError("unrentable_note", touched, errors).error}
                  helperText={inputError("unrentable_note", touched, errors).helperText}
                />
              </Grid>
            </>
          )}
          {/* buttons */}
          <Grid container spacing={2} justifyContent={"flex-end"}
            item xs={12}>
            <Grid item xs={2}>
              <Button onClick={handleClose} variant={"outlined"} color={"error"}
                fullWidth>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={2}>
              <LoadingButton
                onClick={() => handleSubmit()}
                className={classes.buttonBase}
                variant={"contained"}
                fullWidth
                loading={loadingNewUnit}
                disabled={loadingNewUnit}
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default NewUnitModal;
