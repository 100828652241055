import { Button, Typography } from "@mui/material";
import React, { ReactElement, useEffect } from "react";
import {
  resetInsurancePlanInformationSlice,
  selectCoverageAmount,
  selectPlanDescription,
  selectPlanDocumentTemplateId,
  selectPlanFreeTrail,
  selectPlanInformationLoading,
  selectPlanInformationModal,
  selectPlanIsActive,
  selectPlanIsAutoEnroll,
  selectPlanName,
  selectPlanRevenueClassId,
  selectPremiumAmount,
  setInsurancePlanInformationModalOpen
} from "src/store/reducers/insurancePlanInformationSlice/insurancePlanInformationSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useNavigate, useParams } from "react-router";
import AppModal from "../../../components/ui/AppModal/AppModal";
import { Breadcrumb } from "../../../models/Breadcrumb";
import InsurancePlanForm from "../InsurancePlanForm/InsurancePlanForm";
import { InsurancePlanValues } from "src/models/formikInputValues/InsurancePlanValues";
import ItemManagementLayout from "../../../layouts/ItemManagement/ItemManagementLayout";
import { LoadingButton } from "@mui/lab";
import { NavLink } from "react-router-dom";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import clsx from "clsx";
import {
  getAllInsurancePlansForFacility
} from "src/store/thunks/insurancePlans/getAllForFacility/getAllInsurancePlansForFacility";
import {
  getAllPaginatedDocumentTemplates
} from "src/store/thunks/documentTemplates/getAll/getAllPaginatedDocumentTemplates";
import { getAllRevenueClasses } from "src/store/thunks/revenueClass/getAll/getAllRevenueClasses";
import { getInsurancePlan } from "../../../store/thunks/insurancePlans/get/getInsurancePlan";
import { selectDocumentTemplates } from "src/store/reducers/documentsSlice/documentsSlice";
import { selectRevenueClasses } from "src/store/reducers/revenueClassesSlice/revenueClassesSlice";
import {
  selectSelectedAdminFacility
} from "../../../store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import useStyles from "./EditInsurancePlan.styles";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";
import { TppPlanPayload, useUpdateTppPlanMutation } from "src/api/endpoints/insurancePlan";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { selectSelectedInsurancePlan } from "src/store/reducers/insurancePlansSlice/insurancePlansSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Fees & Charges"
  },
  {
    name: "Edit Insurance Plan",
    bold: true
  }
];

const EditInsurancePlan: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const selectedAdminFacility = useAppSelector(selectSelectedAdminFacility);
  const revenueClasses = useAppSelector(selectRevenueClasses);
  const documentTemplates = useAppSelector(selectDocumentTemplates);
  const insurancePlanInformationLoading = useAppSelector(selectPlanInformationLoading);
  const openModal = useAppSelector(selectPlanInformationModal);
  const permissions = useAppSelector(selectFacilityPermissions);
  const insurancePlansPermissions = createPermissions(permissions, "insurance_plans");

  const planName = useAppSelector(selectPlanName);
  const premiumAmount = useAppSelector(selectPremiumAmount);
  const coverageAmount = useAppSelector(selectCoverageAmount);
  const planDescription = useAppSelector(selectPlanDescription);
  const planRevenueClassId = useAppSelector(selectPlanRevenueClassId);
  const documentTemplateId = useAppSelector(selectPlanDocumentTemplateId) ?? 0;
  const planIsActive = useAppSelector(selectPlanIsActive);
  const planIsAutoEnroll = useAppSelector(selectPlanIsAutoEnroll);
  const freeTrail = useAppSelector(selectPlanFreeTrail);

  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [updateTppPlan] = useUpdateTppPlanMutation();

  const footerButtons = (
    <>
      <Button
        variant={"text"}
        className={clsx(classes.cancelButton, classes.buttonBase)}
        size={"large"}
        component={NavLink}
        to={"/insurance-plans"}
      >
        Cancel
      </Button>
      <LoadingButton
        form={"insurance-plan-form"}
        variant={"contained"}
        className={clsx(classes.saveButton, classes.buttonBase)}
        size={"large"}
        type={"submit"}
        loading={insurancePlanInformationLoading}
        disabled={insurancePlanInformationLoading}
      >
        Save & Continue
      </LoadingButton>
    </>
  );

  useEffect(() => {
    dispatch(getInsurancePlan(params.id as string));
    dispatch(getAllRevenueClasses(selectedAdminFacility?.id));
    dispatch(getAllPaginatedDocumentTemplates({ category: 5 }));

    /* istanbul ignore next */ // cannot test this w/ enzyme */
    return () => {
      dispatch(resetInsurancePlanInformationSlice());
    };
  }, []);

  const handleSubmit = (values: InsurancePlanValues) => {
    const payloadUpdateTppPlan: TppPlanPayload = {
      id: parseInt(params.id!),
      facility_id: selectedAdminFacility?.id!,
      name: planName,
      premium_amount: premiumAmount,
      coverage_amount: coverageAmount,
      line_item_description: planDescription,
      revenue_class_id: planRevenueClassId,
      document_template_id: documentTemplateId === 0 ? null : documentTemplateId,
      is_active: planIsActive,
      free_trial: freeTrail,
      is_auto_enroll: planIsAutoEnroll
    };

    updateTppPlan(payloadUpdateTppPlan).then((data) => {
      // @ts-ignore
      if (data.data.status === 422) {
        return dispatch(showSnackbar({
          // @ts-ignore
          message: data.data.detail,
          variant: "error"
        }));
      }

      if ("error" in data) {
        return dispatch(showSnackbar({
          message: "Error updating Insurance Plan",
          variant: "error"
        }));
      }

      if ("data" in data) {
        dispatch(showSnackbar({
          message: "Insurance Plan updated successfully",
          variant: "success"
        }));

        dispatch(getAllInsurancePlansForFacility(selectedAdminFacility!.id));
        navigate("/insurance-plans");
      }
    });
  };

  const pageHeader = <PageHeader title={"Edit Insurance Plan"} breadcrumbs={breadcrumbs}/>;

  if (insurancePlansPermissions.update) {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <ItemManagementLayout
          title={`Edit Insurance Plan for ${selectedAdminFacility?.name}`}
          footerButtons={footerButtons}
          loading={insurancePlanInformationLoading}
        >
          <InsurancePlanForm
            revenueClasses={revenueClasses}
            documentTemplates={documentTemplates}
            submitHandler={() => dispatch(setInsurancePlanInformationModalOpen(true))}
          />
        </ItemManagementLayout>
        <AppModal
          open={openModal}
          onCloseAction={() => dispatch(setInsurancePlanInformationModalOpen(false))}
          cancelAction={() => dispatch(setInsurancePlanInformationModalOpen(false))}
          confirmAction={handleSubmit}
          data-testid={"app-layout-modal"}
        >
          <Typography gutterBottom variant={"h6"}>
            Are you sure?
          </Typography>
          <Typography gutterBottom variant={"body1"}>
            Saving these edits will apply them to the current insurance plan. All active occupants with this insurance
            plan will be affected accordingly.
          </Typography>
        </AppModal>
      </ViewWrapper>
    );
  } else {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <h1>You do not have permission to view this page.</h1>
      </ViewWrapper>
    );
  }
};

export default EditInsurancePlan;
