import { Box, Button, Grid } from "@mui/material";
import React, { ReactElement, useEffect } from "react";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";
import { selectNote, setNote } from "src/store/reducers/noteSlice/noteSlice";
import { selectUnit, setUnit } from "src/store/reducers/unitSlice/unitSlice";
import {
  selectUnitUnrentableModalOpen,
  setUnitUnrentableModalOpen
} from "src/store/reducers/unitsSlice/unitsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { AppDispatch } from "../../../store/rootStore";
import AppModal from "src/components/ui/AppModal/AppModal";
import { ArrowBack } from "@mui/icons-material";
import { Breadcrumb } from "../../../models/Breadcrumb";
import EditAttributesForm from "./EditAttributesForm/EditAttributesForm";
import ManageUnitsTable from "src/pages/Units/ManageUnits/ManageUnitsTable/ManageUnitsTable";
import { NavLink } from "react-router-dom";
import NewUnitModal from "src/pages/Units/ManageUnits/ManageUnitsTable/components/NewUnitModal/NewUnitModal";
import NotesModal from "src/pages/Units/ManageUnits/ManageUnitsTable/components/NotesModal/NotesModal";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import TableWrapper from "../../../layouts/TableWrapper/TableWrapper";
import { UnitUnrentableInput } from "../../../models/formikInputValues/UnitUnrentableInput";
import UnrentableReasonForm from "./UnrentableReasonForm/UnrentableReasonForm";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { getAllProductCategories } from "src/store/thunks/productCategory/getAll/getAllProductCategories";
import { getAllProductTypes } from "src/store/thunks/productType/getAll/getAllProductTypes";
import { getAllRevenueClasses } from "src/store/thunks/revenueClass/getAll/getAllRevenueClasses";
import { getAllUnitAttributes } from "src/store/thunks/unitAttributes/getAll/getAllUnitAttributes";
import { getAllUnitsNew } from "src/store/thunks/unitNew/get/getAllUnitsNew";
import { getDocumentTemplateListForNewProductType } from
  // eslint-disable-next-line max-len
  "src/store/thunks/documentTemplates/getDocumentTemplateListForNewpProductType/getDocumentTemplateListForNewProductType";
import { getProductType }
  from "src/store/thunks/productType/get/getProductType";
import { getUnitsByProductType } from "src/store/thunks/unit/getUnitsByProductType/getUnitsByProductType";
import {
  handleUnitsSliceReset
} from "src/store/reducers/unitsSliceNew/unitsSliceNew";
import { manageProductTypesPath } from "src/routes/ProductTypeRoutes";
import { resetProductCategoriesSlice } from "../../../store/reducers/productCategoriesSlice/productCategoriesSlice";
import { resetProductCategorySlice } from "../../../store/reducers/productCategorySlice/productCategorySlice";
import { resetProductTypeSlice } from "../../../store/reducers/productTypeSlice/productTypeSlice";
import { resetProductTypeSliceNew } from "src/store/reducers/productTypeSliceNew/productTypeSliceNew";
import { resetProductTypesSlice } from "../../../store/reducers/productTypesSlice/productTypesSlice";
import { resetRevenueClassesSlice } from "../../../store/reducers/revenueClassesSlice/revenueClassesSlice";
import { resetTablesSlice } from "src/store/reducers/tablesSlice/tablesSlice";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { unitUnrentable } from "src/store/thunks/unit/unrentable/unitUnrentable";
import { useParams } from "react-router";
import useStyles from "./EditProductTypes.styles";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Units"
  },
  {
    name: "Manage Product Types",
    bold: true
  }
];

export const handleReset = (dispatch: AppDispatch) => {
  dispatch(resetProductCategorySlice());
  dispatch(resetProductCategoriesSlice());
  dispatch(resetProductTypesSlice());
  dispatch(resetProductTypeSlice());
  dispatch(resetRevenueClassesSlice());
  dispatch(resetTablesSlice());
  dispatch(handleUnitsSliceReset());
  dispatch(resetProductTypeSliceNew());
};

const EditProductTypes: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const unitUnrentableModalOpen = useAppSelector(selectUnitUnrentableModalOpen);
  const selectedUnit = useAppSelector(selectUnit);
  const note = useAppSelector(selectNote);
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const permissions = useAppSelector(selectFacilityPermissions);
  const productTypesPermissions = createPermissions(permissions, "product_types");

  const handleSubmitUnrentableReason = async(data: UnitUnrentableInput) => {
    dispatch(setUnitUnrentableModalOpen(false));
    await dispatch(setUnit({ ...selectedUnit, unrentable_reason: data.unrentableReason }));
    await dispatch(setNote(data.unrentableNotes));

    dispatch(unitUnrentable({
      unit: selectedUnit!,
      note: note
    })).then(resp => {
      if (!resp.type.includes("rejected")) {
        dispatch(getProductType(params.id!));
        dispatch(getUnitsByProductType(params.id!));
      }
    });
  };

  useEffect(() => {
    dispatch(getProductType(params.id!));
    dispatch(getUnitsByProductType(params.id!));
    dispatch(getAllProductCategories());
    dispatch(getDocumentTemplateListForNewProductType(null));
    dispatch(getAllRevenueClasses(selectedFacility?.id));
    dispatch(getAllUnitsNew({ id: String(selectedFacility.id), productTypeId: String(params.id) }));
    dispatch(getAllUnitAttributes());
    dispatch(getAllProductTypes(selectedFacility?.id));
    return () => {
      handleReset(dispatch);
    };
  }, []);

  const pageHeader = <PageHeader title={"Manage Product Types"} breadcrumbs={breadcrumbs} />;

  return (
    productTypesPermissions.create ||
      productTypesPermissions.update ||
      productTypesPermissions.delete
      ? (<ViewWrapper pageHeader={pageHeader}>
        <Grid mt={2}>
          <Button
            variant={"text"}
            className={classes.goBackButton}
            startIcon={<ArrowBack />}
            component={NavLink}
            to={manageProductTypesPath}
          >
            Go Back To Product Types
          </Button>
          <EditAttributesForm />
          <TableWrapper
            title={"Units within Product Type"}
            table={
              <Box padding={2}>
                <ManageUnitsTable />
              </Box>
              }
          />
          <AppModal
            formId={"unrentable-reason-form"}
            cancelAction={() => dispatch(setUnitUnrentableModalOpen(false))}
            onCloseAction={() => dispatch(setUnitUnrentableModalOpen(false))}
            open={unitUnrentableModalOpen}
          >
            <UnrentableReasonForm submitHandler={handleSubmitUnrentableReason} />
          </AppModal>
          <NotesModal />
          <NewUnitModal isWithinProductType productTypeId={params.id} />
        </Grid>
      </ViewWrapper>)
      : <ViewWrapper pageHeader={pageHeader}>
        <h1>You do not have permission to view this page.</h1>
      </ViewWrapper>
  );
};

export default EditProductTypes;
