import { RootState } from "../../../rootStore";
import { UpdateTaskPayload } from "./UpdateTaskPayload";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { formatDate } from "../../../../utils/__dateAndTimeUtils__/formatDate/formatDate";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const updateTask = createAsyncThunk(
  "updateTask",
  (id: string, { dispatch, getState, rejectWithValue }) => {
    const state = (getState() as RootState);
    const values = state.taskForm.taskUpdateValues;

    if (!id) {
      const errorMessage = "Task ID not found";

      dispatch(showSnackbar({
        message: errorMessage,
        variant: "error"
      }));

      return rejectWithValue(errorMessage);
    }

    if (!values || Object.values(values).includes(undefined)) {
      const errorMessage = "Task values not provided";

      dispatch(showSnackbar({
        message: errorMessage,
        variant: "error"
      }));

      return rejectWithValue(errorMessage);
    }

    const getAssignees = () => {
      let facilitiesArray = values.taskFacilities;

      if (facilitiesArray.includes("all")) {
        facilitiesArray = (getState() as RootState).facilities.facilities.map(facility => facility.id);
      }

      return facilitiesArray;
    };

    const payload: UpdateTaskPayload = {
      name: values.taskName,
      task_category_id: Number(values.taskCategoryId),
      assignee: getAssignees(),
      due_date: formatDate(values.taskDueDate, "YYYY-MM-DD"),
      due_time: values.taskDueTime,
      is_completed: values.taskIsCompleted ?? false,
      recurrence: Number(values.taskRecurrence),
      is_all_day: values.taskIsAllDay,
      description: values.taskDescription
    };

    return axios.put(`/api/v1/tasks/${id}`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: `Task successfully updated`,
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an error updating this Task";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
