import { Backdrop, Button, Grid, Modal, Paper } from "@mui/material";
import React, { FC } from "react";
import {
  selectUnrentableRow,
  selectUnrentableUnitModalOpen,
  setUnrentableRow,
  setUnrentableUnitModalOpen
} from "src/store/reducers/unitsSliceNew/unitsSliceNew";
import { unitsApi, useMarkUnitUnrentableMutation } from "src/api/endpoints/units";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import UnrentableReasonForm from "src/pages/ProductTypes/EditProductTypes/UnrentableReasonForm/UnrentableReasonForm";
import { setNote } from "src/store/reducers/noteSlice/noteSlice";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import useStyles from "./UnrentableReasonModal.styles";

interface UnrentableReasonModalProps {}

const UnrentableReasonModal: FC<UnrentableReasonModalProps> = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const unrentableOpen = useAppSelector(selectUnrentableUnitModalOpen);
  const [markUnitUnrentable] = useMarkUnitUnrentableMutation();
  const unrentableRow = useAppSelector(selectUnrentableRow);

  const handleCancelUnrentable = () => {
    dispatch(setUnrentableUnitModalOpen(false));
    if (unrentableRow) {
      dispatch(setUnrentableRow(null));
      dispatch(unitsApi.util.invalidateTags([{ type: "FlatUnit", id: unrentableRow.id }]));
    }
  };

  const handleSubmitUnrentableReason = (data: { unrentableReason: number, unrentableNotes: string }) => {
    dispatch(setUnrentableUnitModalOpen(false));
    dispatch(setNote(data.unrentableNotes));

    if (!unrentableRow) {
      return;
    }

    const unrentableReason = {
      unit: {
        ...unrentableRow,
        unrentable_reason: data.unrentableReason
      },
      note: data.unrentableNotes
    };

    markUnitUnrentable(unrentableReason).then((data) => {
      dispatch(showSnackbar({ message: "Unit marked as unrentable", severity: "success" }));
      dispatch(unitsApi.util.invalidateTags([{ type: "FlatUnit", id: unrentableRow.id }]));
    });
  };

  return (
    <Modal
      onClose={handleCancelUnrentable}
      open={unrentableOpen}
      className={classes.root}
      components={{
        Backdrop: (props) => <Backdrop {...props} className={classes.backdrop} />
      }}
    >
      <Grid
        container
        p={2}
        className={classes.root}
        component={Paper}
        elevation={0}
      >
        <UnrentableReasonForm submitHandler={handleSubmitUnrentableReason} />
        <Grid
          container
          p={2}
          spacing={2}
          item
          xs={12}
          justifyContent={"flex-end"}
        >
          <Grid item>
            <Button
              className={classes.buttonBase}
              type={"submit"}
              form={"unrentable-reason-form"}
              variant={"contained"}
            >
              Submit
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleCancelUnrentable}
              variant={"text"}
              color={"error"}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default UnrentableReasonModal;
