import { LoadingButton } from "@mui/lab";
import React from "react";
import { useFormContext } from "react-hook-form";
import useStyles from "./Form.styles";
import clsx from "clsx";

type FormSubmitProps = {
  label?: string;
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  variant?: "contained" | "outlined" | "text";
  formId?: string;
  className?: string;
  value?: string;
};

const FormSubmit = ({
  label = "Submit",
  size = "medium",
  disabled,
  variant = "contained",
  formId = "formId",
  className,
  value
}: FormSubmitProps) => {
  const { formState } = useFormContext();
  const { classes } = useStyles();

  return (
    <LoadingButton
      loading={formState.isSubmitting}
      type={"submit"}
      className={clsx({
        [classes.submitButtonContained]: variant === "contained",
        [classes.submitButtonOutlined]: variant === "outlined",
        className
      }) }
      size={size}
      disabled={disabled}
      variant={variant}
      form={formId}
      value={value}
    >
      {label}
    </LoadingButton>
  );
};

export default FormSubmit;
