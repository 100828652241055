import React, { PropsWithChildren, ReactElement, useEffect } from "react";
import {
  resetDealInformationSlice,
  selectDealType,
  setDealType
} from "../../../store/reducers/dealInformationSlice/dealInformationSlice";
import {
  resetPromotionsSlice,
  selectPromotionsForFacility
} from "../../../store/reducers/promotionsSlice/promotionsSlice";
import {
  selectNewFacilityAdvisoryModal,
  selectSelectedFacility
} from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useLocation, useNavigate } from "react-router";

import { AppDispatch } from "../../../store/rootStore";
import AppModal from "../../../components/ui/AppModal/AppModal";
import { Breadcrumb } from "../../../models/Breadcrumb";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import { Typography } from "@mui/material";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { blockNavigation } from "src/components/router/CustomRouter";
import { getAllFacilities } from "../../../store/thunks/facility/getAll/getAllFacilities";
import { getAllProductTypes } from "src/store/thunks/productType/getAll/getAllProductTypes";
import { getAllPromotionsForFacility }
  from "../../../store/thunks/promotion/getAllForFacility/getAllPromotionsForFacility";
import { resetDealSlice } from "../../../store/reducers/dealSlice/dealSlice";
import { resetNoteSlice } from "../../../store/reducers/noteSlice/noteSlice";
import { resetProductTypesSlice } from "../../../store/reducers/productTypesSlice/productTypesSlice";
import { resetSelectedOccupantSlice } from "../../../store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { resetSelectedPromotionSlice } from "../../../store/reducers/selectedPromotionSlice/selectedPromotionSlice";
import { resetSelectedUnitSlice } from "../../../store/reducers/selectedUnitSlice/selectedUnitSlice";
import { resetUnitsSlice } from "src/store/reducers/unitsSlice/unitsSlice";
import { toggleModal } from "src/store/reducers/modalSlice/modalSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Deals"
  },
  {
    name: "Create New Deal",
    bold: true
  }
];

export const resetCreateNewDeal = (dispatch: AppDispatch) => {
  dispatch(toggleModal(false));
  dispatch(resetDealSlice());
  dispatch(resetDealInformationSlice());
  dispatch(resetNoteSlice());
  dispatch(resetProductTypesSlice());
  dispatch(resetPromotionsSlice());
  dispatch(resetSelectedOccupantSlice());
  dispatch(resetSelectedPromotionSlice());
  dispatch(resetSelectedUnitSlice());
  dispatch(setDealType("lead"));
  dispatch(resetUnitsSlice());
};

const CreateNewDeal: React.FC<PropsWithChildren> = ({ children, ...props }): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const pageLocation = useLocation();
  const redirect = pageLocation.state ?? null;
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const newFacilityAdvisoryModal = useAppSelector(selectNewFacilityAdvisoryModal);
  const facilityPromotions = useAppSelector(selectPromotionsForFacility);
  const [flowIsIncomplete, setFlowIsIncomplete] = React.useState(false);
  const [targetPath, setTargetPath] = React.useState<string>("");
  const dealType = useAppSelector(selectDealType);
  let unblock: Function;

  const handleBlock = () => {
    return blockNavigation(({ location }) => {
      const nextLocation = location.pathname;
      const currentLocation = pageLocation.pathname;
      setTargetPath(nextLocation);

      const completedWorkflow = (
        (((!facilityPromotions.length || dealType === "lead") &&
            currentLocation === "/deals/create-new-deal/select-unit") ||
          (facilityPromotions.length && currentLocation === "/deals/create-new-deal/select-promotion")) &&
        nextLocation === "/deals/manage-deals"
      );

      if (
        // if you aren't on step 1
        currentLocation !== "/deals/create-new-deal" &&
        // and you aren't navigating b/w steps
        !nextLocation.includes("/deals/create-new-deal") &&
        // and you aren't creating a new occupant
        !nextLocation.includes("/occupants/add-occupant/occupant-information-one") &&
        // and you haven't completed the workflow
        !completedWorkflow &&
        // and you're not changing facilities (which should trigger a different modal)
        !newFacilityAdvisoryModal
      ) {
        setFlowIsIncomplete(true);
        return false;
      }

      unblock();
      navigate(nextLocation);
    });
  };

  useEffect(() => {
    if (redirect) {
      navigate("/deals/create-new-deal/select-occupant");
      navigate(location.pathname, { replace: true });
    }
  }, []);

  useEffect(() => {
    dispatch(resetSelectedOccupantSlice());
    dispatch(getAllProductTypes(selectedFacility?.id));
    dispatch(getAllPromotionsForFacility());
    dispatch(getAllFacilities());
    /* istanbul ignore next */ // cannot test this w/ enzyme */
    return () => {
      resetCreateNewDeal(dispatch);
    };
  }, []);

  useEffect(() => {
    unblock = handleBlock();

    return () => {
      if (unblock) {
        unblock();
      }
    };
  });

  const handleNavigationAway = () => {
    if (unblock) {
      unblock();
    }

    navigate(targetPath);
  };

  const pageHeader = <PageHeader title={"Create New Deal"} breadcrumbs={breadcrumbs} />;

  return (
    <ViewWrapper pageHeader={pageHeader} {...props}>
      {children}
      <AppModal
        open={flowIsIncomplete}
        cancelAction={() => setFlowIsIncomplete(false)}
        confirmAction={handleNavigationAway}
        data-testid={"app-layout-modal"}
      >
        <Typography gutterBottom variant={"h6"}>Warning</Typography>
        <Typography variant={"body1"}>
          You are about to leave an incomplete workflow. If you choose to continue, information you have provided
          during this and previous steps will be lost.
        </Typography>
      </AppModal>
    </ViewWrapper>
  );
};

export default CreateNewDeal;
