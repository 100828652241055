import {
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography
} from "@mui/material";
import {
  selectAutomaticPaymentSlice,
  setDisableAutoBillModalOpened
} from "src/store/reducers/automaticPaymentsSlice/automaticPaymentsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";

import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import React from "react";
import { disabledAutoPay } from "src/store/thunks/occupant/disabledAutoPay/disabledAutoPay";
import { getOccupant } from "src/store/thunks/occupant/getOne/getOccupant";
import { setChangeOwnerShipStep } from "src/store/reducers/occupantSlice/occupantSlice";
import { useParams } from "react-router";
import useStyles from "./DisableAutoBillModal.styles";
import { setSelectedLedger } from "src/store/reducers/ledgersSlice/ledgersSlice";

const DisableAutoBillModal = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const {
    disabledAutoBillModal
  } = useAppSelector(selectAutomaticPaymentSlice);
  const formId = "automatic-payments-form";
  const params = useParams<{ id: string }>();

  const handleCloseModal = () => {
    dispatch(setDisableAutoBillModalOpened(false));
  };

  const handleSubmit = async() => {
    const { payload } = await dispatch(disabledAutoPay());

    handleCloseModal();
    dispatch(setSelectedLedger(null));

    if (Object.keys(payload).length > 0) {
      dispatch(setChangeOwnerShipStep(3));
      dispatch(getOccupant(params.id!));
    }
  };

  return (
    <Modal onClose={handleCloseModal} open={disabledAutoBillModal.opened} className={classes.modal}>
      <Grid
        p={4}
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        justifyContent={"center"}
        className={classes.container}
      >
        <Grid item xs={11}>
          <Typography variant={"h5"} textAlign={"center"}>Confirm to Disable Autobill</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={handleCloseModal}>
            <Close />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} my={"1rem"}>
          <Typography variant={"body1"} >
            Are you sure you want to disable auto-bill for this unit?
          </Typography>

          <Typography variant={"body1"} mt={"1rem"}>
            All auto-bill information for this unit will no longer exist.
          </Typography>

        </Grid>

        <Grid
          container
          xs={12}
          mt={2}
          justifyContent={"center"}
          gap={2}
          >
          <Button
            onClick={handleCloseModal}
            color={"error"}
            variant={"text"}
          >
            Cancel
          </Button>
          <LoadingButton
            variant={"contained"}
            type={"submit"}
            disabled={disabledAutoBillModal.submitting}
            onClick={handleSubmit}
            form={formId}
            className={classes.button}
            loading={disabledAutoBillModal.submitting}
          >
            Confirm
          </LoadingButton>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default DisableAutoBillModal;
