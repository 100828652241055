import { CreateOccupantPayload } from "./CreateOccupantPayload";
import Moment from "moment-timezone";
import { RootState } from "../../../rootStore";
import {
  UPLOADED_DOCUMENT_TYPES
} from "src/pages/Occupants/EditOccupant/EditOccupantTabs/OccupantDocuments/OccupantDocuments";
import { UploadDocumentPayload } from "./../../occupantDocuments/uploadDocument/UploadDocumentPayload.d";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const createOccupant = createAsyncThunk("createOccupant", async(_, { dispatch, getState, rejectWithValue }) => {
  const state = getState() as RootState;
  const occupantInfo = state.occupantInformation;
  const facilityId = state.selectedFacility.selectedFacility.id;
  const dealId = state.deal.deal?.id;
  const taxExemptDocumentBase64 = state.occupantInformation.occupantTaxExemptDocumentBase64;

  const documentPayload: UploadDocumentPayload | null = taxExemptDocumentBase64
    ? {
        content: taxExemptDocumentBase64,
        name: (occupantInfo.taxExemptDocument as File).name,
        type: UPLOADED_DOCUMENT_TYPES["Tax Exempt"]
      }
    : null;

  const payload: CreateOccupantPayload = {
    first_name: occupantInfo.firstName,
    middle_name: occupantInfo.middleName,
    last_name: occupantInfo.lastName,
    email: occupantInfo.email,
    gate_code: String(occupantInfo.gateCode),
    id_number: occupantInfo.idNumber,
    id_state: occupantInfo.idState,
    military_status: occupantInfo.military,
    // @ts-ignore
    // eslint-disable-next-line new-cap
    date_of_birth: new Moment.utc(occupantInfo.dob!).toISOString(),
    is_tax_exempt: occupantInfo.taxExempt,
    tax_exempt_number: occupantInfo.taxExemptNumber,
    is_business: occupantInfo.business,
    business_name: occupantInfo.businessName,
    phone_primary: occupantInfo.primaryPhone,
    phone_alternate: occupantInfo.alternatePhone,
    address: occupantInfo.address,
    address_two: occupantInfo.addressTwo,
    city: occupantInfo.city,
    region: occupantInfo.state,
    postal_code: occupantInfo.postalCode,
    country: occupantInfo.country,
    facility_id: facilityId,
    deal_id: dealId,
    ...(taxExemptDocumentBase64 && { tax_exempt_document: documentPayload }),
    id_type: occupantInfo.idType,
    ...(occupantInfo.military === 4 || occupantInfo.military === 5
      ? { military_branch: occupantInfo.militaryBranch, military_id: occupantInfo.militaryId }
      : {})
  };

  return axios
    .post("/api/v1/occupants", payload)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      let errorMessage: string;
      if (err.response.status === 422) {
        errorMessage = format422(err.response.data.errors);

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      }

      errorMessage = "There was an error creating the Occupant";

      dispatch(
        showSnackbar({
          message: errorMessage,
          variant: "error"
        })
      );

      return rejectWithValue(errorMessage);
    });
});
