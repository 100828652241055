import React, { PropsWithChildren, ReactElement, useEffect } from "react";
import {
  resetDealInformationSlice,
  setDealType
} from "../../../store/reducers/dealInformationSlice/dealInformationSlice";
import { resetOpenExpansionRows, resetTablesSlice } from "../../../store/reducers/tablesSlice/tablesSlice";
import {
  resetSelectedOccupantSlice,
  setSelectedOccupant
} from "../../../store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import {
  resetSelectedPromotionSlice,
  setSelectedPromotion
} from "../../../store/reducers/selectedPromotionSlice/selectedPromotionSlice";
import {
  selectNewFacilityAdvisoryModal,
  selectSelectedFacility
} from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useLocation, useNavigate } from "react-router";
import { AppDispatch } from "../../../store/rootStore";
import AppModal from "../../../components/ui/AppModal/AppModal";
import { Breadcrumb } from "../../../models/Breadcrumb";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import { Typography } from "@mui/material";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { blockNavigation } from "src/components/router/CustomRouter";
import { getAllFacilities } from "../../../store/thunks/facility/getAll/getAllFacilities";
import { getAllFacilitySettings } from "src/store/thunks/facilitySetting/getAll/getAllFacilitySettings";
import { getAllFeesForFacility } from "../../../store/thunks/fee/getAllForFacility/getAllFeesForFacility";
import {
  getAllInsurancePlansForFacility
} from "src/store/thunks/insurancePlans/getAllForFacility/getAllInsurancePlansForFacility";
import {
  getAllMerchandiseForMoveIn
} from "../../../store/thunks/merchandise/getAllForMoveIn/getAllMerchandiseForMoveIn";
import { getAllProductTypes } from "src/store/thunks/productType/getAll/getAllProductTypes";
import {
  getAllPromotionsForFacility
} from "../../../store/thunks/promotion/getAllForFacility/getAllPromotionsForFacility";
import { resetAchSlice } from "../../../store/reducers/achSlice/achSlice";
import { resetCashSlice } from "../../../store/reducers/cashSlice/cashSlice";
import { resetCreditCardSlice } from "../../../store/reducers/creditCardSlice/creditCardSlice";
import { resetDealSlice } from "../../../store/reducers/dealSlice/dealSlice";
import { resetDealsSlice } from "../../../store/reducers/dealsSlice/dealsSlice";
import {
  resetEmergencyContactInformationSlice
} from "../../../store/reducers/emergencyContactInformationSlice/emergencyContactInformationSlice";
import { resetMerchandiseItemsSlice } from "../../../store/reducers/merchandiseItemsSlice/merchandiseItemsSlice";
import { resetMoneyOrderSlice } from "../../../store/reducers/moneyOrderSlice/moneyOrderSlice";
import { resetMoveInCostSlice } from "../../../store/reducers/moveInCostSlice/moveInCostSlice";
import { resetMoveInSlice } from "src/store/reducers/moveInSlice/moveInSlice";
import {
  resetOccupantInformationSlice
} from "../../../store/reducers/occupantInformationSlice/occupantInformationSlice";
import { resetPaymentSlice } from "../../../store/reducers/paymentSlice/paymentSlice";
import { resetProductTypeSlice } from "../../../store/reducers/productTypeSlice/productTypeSlice";
import { resetProductTypesSlice } from "../../../store/reducers/productTypesSlice/productTypesSlice";
import { resetPromotionSlice } from "../../../store/reducers/promotionSlice/promotionSlice";
import { resetPromotionsSlice } from "../../../store/reducers/promotionsSlice/promotionsSlice";
import { resetSelectedUnitSlice } from "src/store/reducers/selectedUnitSlice/selectedUnitSlice";
import { resetUnitsSlice } from "../../../store/reducers/unitsSlice/unitsSlice";
import { toggleModal } from "../../../store/reducers/modalSlice/modalSlice";
import { getAllGlobalSettings } from "src/store/thunks/globalSetting/getAll/getAllGlobalSettings";
import { selectIsSpeedeRentProcess } from "src/store/reducers/speedeRent/speedeRentSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Deals"
  },
  {
    name: "Move In",
    bold: true
  }
];

export const handleReset = (dispatch: AppDispatch) => {
  dispatch(resetAchSlice());
  dispatch(resetCashSlice());
  dispatch(resetCreditCardSlice());
  dispatch(resetDealInformationSlice());
  dispatch(resetDealSlice());
  dispatch(resetDealsSlice());
  dispatch(resetEmergencyContactInformationSlice());
  dispatch(resetMerchandiseItemsSlice());
  dispatch(resetMoneyOrderSlice());
  dispatch(resetMoveInCostSlice());
  dispatch(resetMoveInSlice());
  dispatch(resetOccupantInformationSlice());
  dispatch(resetPaymentSlice());
  dispatch(resetProductTypeSlice());
  dispatch(resetProductTypesSlice());
  dispatch(resetPromotionSlice());
  dispatch(resetPromotionsSlice());
  dispatch(resetSelectedUnitSlice());
  dispatch(resetSelectedPromotionSlice());
  dispatch(resetSelectedOccupantSlice());
  dispatch(resetTablesSlice());
  dispatch(resetUnitsSlice());
  dispatch(setDealType("lead"));
  dispatch(toggleModal(false));
  dispatch(resetOpenExpansionRows());
};

const CreateMoveIn: React.FC<PropsWithChildren> = ({ children, ...props }): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const pageLocation = useLocation();
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const newFacilityAdvisoryModal = useAppSelector(selectNewFacilityAdvisoryModal);
  const isSpeedeRentProcess = useAppSelector(selectIsSpeedeRentProcess);

  const [workflowIncompleteWarning, setWorkflowIncompleteWarning] = React.useState(false);
  const [localTargetPath, setLocalTargetPath] = React.useState<string>("");
  let unblock: Function;

  const handleAdvisory = () => {
    return blockNavigation(({ location }) => {
      const nextLocation = location.pathname;
      const currentLocation = pageLocation.pathname;
      setLocalTargetPath(nextLocation);

      const workflowComplete = currentLocation === "/occupants/create-move-in/confirm-move-in";

      const restartingWorkflow =
        currentLocation !== "/occupants/create-move-in" &&
        currentLocation !== "/occupants/select-occupant" &&
        nextLocation === "/occupants/create-move-in";

      if (
        // you're not on the first step
        currentLocation !== "/occupants/create-move-in" &&
        // and you aren't navigating b/w steps or completely restarting the workflow
        (!nextLocation.includes("/occupants/create-move-in") || restartingWorkflow) &&
        // and you're not changing facilities (which should trigger a different modal)
        !newFacilityAdvisoryModal &&
        // and you have not completed the move-in
        !workflowComplete &&
        // is not the speedeRent Flow
        !isSpeedeRentProcess
        // then show the advisory modal
      ) {
        setWorkflowIncompleteWarning(true);
        return false;
      }

      unblock();
      navigate(nextLocation);
    });
  };

  useEffect(() => {
    dispatch(getAllFacilities());
    dispatch(getAllFeesForFacility({ facilityId: selectedFacility?.id }));
    dispatch(getAllInsurancePlansForFacility(selectedFacility?.id));
    dispatch(getAllMerchandiseForMoveIn());
    dispatch(getAllProductTypes(selectedFacility?.id));
    dispatch(getAllPromotionsForFacility());
    dispatch(getAllFacilitySettings(String(selectedFacility?.id)));
  }, []);

  useEffect(() => {
    unblock = handleAdvisory();

    /* istanbul ignore next */ // cannot test this w/ enzyme
    return () => {
      if (unblock) {
        unblock();
      }
    };
  });

  useEffect(() => {
    /* istanbul ignore next */ // cannot test this w/ enzyme
    return () => {
      //Avoid clearing store if navigating from Edit Occupant as a transfer.
      if (!pageLocation.state?.isTransfer && !pageLocation.state?.isMoveInFromDeals) {
        handleReset(dispatch);
      }
    };
  }, []);

  const confirmNavigationAway = () => {
    if (unblock) {
      unblock();
    }

    dispatch(setSelectedOccupant(null));
    dispatch(setSelectedPromotion(null));
    dispatch(resetDealSlice());
    dispatch(resetMoveInCostSlice());
    dispatch(resetOpenExpansionRows());
    dispatch(resetSelectedOccupantSlice());
    dispatch(resetOccupantInformationSlice());
    dispatch(resetEmergencyContactInformationSlice());
    dispatch(resetSelectedPromotionSlice());
    dispatch(resetCashSlice());
    dispatch(resetMoneyOrderSlice());
    dispatch(resetCreditCardSlice());
    dispatch(resetAchSlice());
    navigate(localTargetPath);
    setWorkflowIncompleteWarning(false);
  };

  useEffect(() => {
    dispatch(setDealType("reservation")); // we may want/need to revisit this if/when we allow selection of leads
  }, []);

  const pageHeader = <PageHeader title={"Move In"} breadcrumbs={breadcrumbs} />;

  return (
    <ViewWrapper pageHeader={pageHeader}>
      {children}
      <AppModal
        open={workflowIncompleteWarning}
        cancelAction={() => setWorkflowIncompleteWarning(false)}
        confirmAction={confirmNavigationAway}
        data-testid={"app-layout-modal"}
      >
        <Typography gutterBottom variant={"h6"}>
          Warning
        </Typography>
        <Typography variant={"body1"}>
          You are about to leave an incomplete workflow. If you choose to continue, information you have provided during
          this and previous steps will be lost.
        </Typography>
      </AppModal>
    </ViewWrapper>
  );
};

export default CreateMoveIn;
