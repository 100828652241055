import { Divider, Grid, Paper, Typography } from "@mui/material";
import React, { FC } from "react";
import { getBillingCycle, getBillingDay } from "src/utils/getBillingCycle/getBillingCycle";
import {
  selectBusinessName,
  selectEmail,
  selectFirstName,
  selectIsBusiness,
  selectIsTaxExempt,
  selectLastName,
  selectOccupantAddressInformation,
  selectPrimaryPhone,
  selectSecondaryPhone,
  selectTaxId
} from "src/store/reducers/occupantInformationSlice/occupantInformationSlice";

import { BillingCycle } from "../../../../../enums/BillingCycle";
import { Charge } from "src/store/reducers/creditsSlice/creditsSliceState";
import ESignDocument from "src/models/ESignDocument";
import { Ledger } from "../../../../../models/Ledger";
import LedgerTable from "../LedgerItemsTable/LedgerItemsTable";
import formatPhone from "src/utils/formatPhone/formatPhone";
import { futureCreditDescriptionMap } from "src/store/reducers/creditsSlice/creditsSlice";
import { getProjectedLedgerItems } from "src/store/thunks/ledger/get/getProjectedLedgerItems";
import { getUnitSizeFromUnit } from "src/utils/getUnitSize/getUnitSize";
import moment from "moment";
import { parseNumber } from "../LedgerItemsTable/ManageLedgerItemsTableColumns/ManageLedgerItemsTableColumns";
import { selectOccupantESignDocuments } from "src/store/reducers/esignDocumentsSlice/esignDocumentsSlice";
import { selectSelectedLedger } from "src/store/reducers/ledgersSlice/ledgersSlice";
import { uniqueId } from "lodash";
import { useAppSelector } from "src/store/hooks";
import useStyles from "./LedgerMiddle.styles";

interface LedgerMiddleProps {}

export const LEDGER_COLUMNS_TO_TOTAL_UP = [
  "applied_refund_amount",
  "issued_credit_amount",
  "payment_amount",
  "charge_amount",
  "tax_amount",
  "pending_amount"
];

const LedgerMiddle: FC<LedgerMiddleProps> = () => {
  const selectedLedger = useAppSelector(selectSelectedLedger);
  const occupantFirstName = useAppSelector(selectFirstName);
  const occupantLastName = useAppSelector(selectLastName);
  const occupantAddress = useAppSelector(selectOccupantAddressInformation);
  const isBusiness = useAppSelector(selectIsBusiness);
  const businessName = useAppSelector(selectBusinessName);
  const occupantEmail = useAppSelector(selectEmail);
  const primaryPhone = useAppSelector(selectPrimaryPhone);
  const secondaryPhone = useAppSelector(selectSecondaryPhone);
  const isTaxExempt = useAppSelector(selectIsTaxExempt);
  const taxExemptNumber = useAppSelector(selectTaxId);
  const { address } = occupantAddress;
  const { classes } = useStyles();
  const occupantESignDocuments = useAppSelector(selectOccupantESignDocuments);

  const occupantLeasedocument: ESignDocument = occupantESignDocuments.find(
    (esignDocument: ESignDocument) =>
      esignDocument.document_type === "Lease" && esignDocument?.unit?.id === selectedLedger?.unit?.id
  );

  const {
    ledger_number,
    monthly_rate,
    unit,
    credit_balance,
    current_balance,
    deposit_liability,
    refund_due,
    facility,
    created_at,
    billing_cycle,
    date_paid_thru,
    ledger_transfer,
    moved_in_at,
    lease_number
  } = selectedLedger as Ledger;

  return (
    <Grid container component={Paper} variant={"outlined"}
      m={2} p={2}>
      <Grid direction={"column"} container item
        xs={2}>
        <Typography>Ledger No.</Typography>
        <Typography fontWeight={700}>{ledger_number}</Typography>
      </Grid>
      <Grid direction={"column"} container item
        xs={4}>
        <Typography>Facility Address</Typography>
        <Typography fontWeight={700}>{facility?.address}</Typography>
      </Grid>
      <Grid direction={"column"} container item
        xs={2}>
        <Typography>Facility Number</Typography>
        <Typography fontWeight={700}>{formatPhone(facility?.phone)}</Typography>
      </Grid>
      <Grid direction={"column"} container item
        xs={4}>
        <Typography align={"right"}>Ledger Date</Typography>
        <Typography align={"right"} fontWeight={700}>
          {moment(created_at).format("MM/DD/YYYY")}
        </Typography>
      </Grid>
      <Grid item xs={12} pt={2}
        pb={2}>
        <Divider />
      </Grid>
      <Grid container item xs={12}>
        <Grid container item xs={3}>
          <Grid container item xs={12}>
            <Typography fontWeight={700}>Customer: &nbsp;</Typography>
            <Typography>{`${occupantFirstName} ${occupantLastName}`}</Typography>
          </Grid>
          <Grid container item xs={12}>
            <Typography fontWeight={700}>Business: &nbsp;</Typography>
            <Typography>{isBusiness ? businessName : "-"}</Typography>
          </Grid>
          <Grid container item xs={12}>
            <Typography fontWeight={700}>Unit: &nbsp;</Typography>
            <Typography>{unit?.unit_number}</Typography>
          </Grid>
          <Grid container item xs={12}>
            <Typography fontWeight={700}>Unit Size: &nbsp;</Typography>
            <Typography>{getUnitSizeFromUnit(unit)}</Typography>
          </Grid>
          <Grid container item xs={12}>
            <Typography fontWeight={700}>Lease #: &nbsp;</Typography>
            <Typography>{selectedLedger?.lease_number ? selectedLedger.lease_number : "N/A"}</Typography>
          </Grid>
          <Grid container item xs={12}>
            <Typography fontWeight={700}>Move In Date: &nbsp;</Typography>
            <Typography>
              {selectedLedger?.moved_in_at ? moment(selectedLedger.moved_in_at).format("MM/DD/YYYY") : "-"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} item
          xs={3}>
          <Grid container item xs={12}>
            <Typography fontWeight={700}>Address:&nbsp;</Typography>
            <Typography>{address}</Typography>
          </Grid>
          <Grid container item xs={12}>
            <Typography fontWeight={700}>Primary Phone: &nbsp;</Typography>
            <Typography>{formatPhone(primaryPhone)}</Typography>
          </Grid>
          <Grid container item xs={12}>
            <Typography fontWeight={700}>Secondary Phone: &nbsp;</Typography>
            <Typography>{secondaryPhone ? formatPhone(secondaryPhone) : "-"}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} item
          xs={3}>
          <Grid container item xs={12}>
            <Typography fontWeight={700}>Billing Cycle: &nbsp;</Typography>
            <Typography>{getBillingCycle(billing_cycle as BillingCycle)}</Typography>
          </Grid>
          <Grid container item xs={12}>
            <Typography fontWeight={700}>Billing Day: &nbsp;</Typography>
            <Typography>{getBillingDay(moved_in_at!, billing_cycle as BillingCycle) ?? "-"}</Typography>
          </Grid>
          <Grid container item xs={12}>
            <Typography fontWeight={700}>Rented Rate: &nbsp;</Typography>
            <Typography>${monthly_rate}</Typography>
          </Grid>
          <Grid container item xs={12}>
            <Typography fontWeight={700}>Email Address: &nbsp;</Typography>
            <Typography className={classes.longEmail}>{occupantEmail}</Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={1}
          xs={3}>
          <Grid container item xs={12}>
            <Typography fontWeight={700}>Date Paid Thru: &nbsp;</Typography>
            <Typography>{date_paid_thru}</Typography>
          </Grid>
          <Grid container item xs={12}>
            <Typography fontWeight={700}>Move Out Date: &nbsp;</Typography>
            <Typography>
              {selectedLedger?.moved_out_at ? moment(selectedLedger.moved_out_at).format("MM/DD/YYYY") : "N/A"}
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Typography fontWeight={700}>NSF Checks: &nbsp;</Typography>
            <Typography>N/A</Typography>
          </Grid>

          <Grid container item xs={12}>
            <Typography fontWeight={700}>Deposit Liability (Cash Basis): &nbsp;</Typography>
            <Typography>{deposit_liability ? `$${deposit_liability}` : "-"}</Typography>
          </Grid>
        </Grid>
        <Grid pt={3} container item
          xs={12}>
          <Grid container item xs={3}>
            <Typography fontWeight={700}>Tax Exemption #: &nbsp;</Typography>
            <Typography>{isTaxExempt && taxExemptNumber ? taxExemptNumber : "-"}</Typography>
          </Grid>
          <Grid container item xs={3}>
            <Typography fontWeight={700}>Transferred From: &nbsp;</Typography>
            <Typography>{ledger_transfer?.id ? ledger_transfer?.from_unit?.unit_number : "-"} </Typography>
          </Grid>
          <Grid container item xs={3}>
            <Typography fontWeight={700}>Transferred To: &nbsp;</Typography>
            <Typography>{ledger_transfer?.id ? ledger_transfer?.to_unit?.unit_number : "-"} </Typography>
          </Grid>
          <Grid container item xs={3}>
            <Typography fontWeight={700}>Refund Due: &nbsp;</Typography>
            <Typography>{refund_due ? `$${refund_due}` : "-"}</Typography>
          </Grid>
          <Grid pt={2} item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
      <LedgerTable />
    </Grid>
  );
};

export default LedgerMiddle;
