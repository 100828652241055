import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ImportProductTypesSliceState } from "./importProductTypesSliceState";
import { RootState } from "../../rootStore";
import { importUnits } from "../../thunks/unit/import/importUnits";

export const initialState: ImportProductTypesSliceState = {
  fileName: "",
  importError: "",
  importLoading: false
};

export const importProductTypesSlice = createSlice({
  name: "importProductTypes",
  initialState,
  reducers: {
    setFileName: (state: ImportProductTypesSliceState, action: PayloadAction<string>) => {
      state.fileName = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(importUnits.pending, (state: ImportProductTypesSliceState) => {
        state.importLoading = true;
      })
      .addCase(importUnits.fulfilled, (state: ImportProductTypesSliceState) => {
        state.importLoading = false;
      })
      .addCase(importUnits.rejected, (state: ImportProductTypesSliceState, action) => {
        state.importLoading = false;
        state.importError = action.payload as string;
      });
  }
});

export const selectFileName = (state: RootState) => state.importProductTypes.fileName;
export const selectImportLoading = (state: RootState) => state.importProductTypes.importLoading;

export const {
  setFileName
} = importProductTypesSlice.actions;

export default importProductTypesSlice.reducer;
