import { Button, Grid, Modal, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import React from "react";
import useStyles from "./RollbackDocumentModal.styles";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  selectDocumentCreatedAt,
  selectRollbackModalOpened,
  setDocumentRollbackModalOpened
} from "src/store/reducers/documentSlice/documentSlice";
import moment from "moment";

const RollbackDocumentModal = ({
  onSubmit
}: {
  onSubmit: Function;
}) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const created_at = useAppSelector(selectDocumentCreatedAt);
  const modalOpened = useAppSelector(selectRollbackModalOpened);

  const onClose = () => dispatch(setDocumentRollbackModalOpened());

  return (
    <Modal
      open={modalOpened}
      onClose={() => onClose()}
      aria-labelledby={"Modal Component"}
      aria-describedby={"A Modal component."}
      className={classes.modal}
      data-testid={"modal-cancel-deal"}
    >
      <Grid
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        spacing={2}
        className={classes.container}
        pb={2}
      >
        <Box className={classes.container}>
          <Typography variant={"h5"} className={classes.title}>
            Revert to Previous Change
          </Typography>
          <Typography className={classes.text}>
            You are reverting to the previous change made on,
          </Typography>
          <Box mt={2} mb={2}>
            <Typography className={classes.text}>{moment(created_at).format("MM/DD/YYYY hh:mm A")}</Typography>
          </Box>
          <Typography className={classes.text}>
            Reverting to a previous change will remove
            all current changes made to the document after the previous update.
            Please confirm whether you want to revert to the previous change, all current edits will be erased
          </Typography>
          <Grid pt={4} spacing={4} container
            justifyContent={"center"}>
            <Grid item>
              <Button
                data-testid={"cancel-button"}
                className={classes.cancelButton}
                onClick={() => onClose()}
                variant={"outlined"}
                color={"secondary"}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                data-testid={"confirm-button-modal"}
                color={"primary"}
                variant={"outlined"}
                className={classes.button}
                onClick={() => onSubmit()}
              >
                Confirm
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Modal>
  );
};

export default RollbackDocumentModal;
