import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const sendSms = createAsyncThunk(
  "sendSms",
  ({ id }: { id: number }, { dispatch, rejectWithValue }) => {
    return axios.post(`/api/v1/esign-documents/${id}/sms`)
      .then((resp) => {
        dispatch(showSnackbar({ message: "SMS sent successfully", variant: "success" }));
        return resp.data;
      })
      .catch(() => {
        const errorMessage = "There was an error sending the SMS";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
