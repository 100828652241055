import {
  DataGrid,
  GridSelectionModel,
  GridToolbarQuickFilter
} from "@mui/x-data-grid";
import { Grid, Paper, Toolbar, Typography, useMediaQuery } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import {
  selectMerchLineItemIds,
  setPosMerchItemLineIds,
  setPosModalOpen
} from "src/store/reducers/pointOfSalesSlice/pointOfSalesSlice";
import {
  selectMerchandiseCategories,
  selectMerchandiseCategoriesLoading
} from "src/store/reducers/merchandiseCategoriesSlice/merchandiseCategoriesSlice";
import {
  selectMerchandiseItems,
  selectMerchandiseItemsLoading,
  setMerchandiseQuantity
} from "src/store/reducers/merchandiseItemsSlice/merchandiseItemsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import ChooseRetailMerchTableColumns from "./ChooseRetailMerchTableColumns/ChooseRetailMerchTableColumns";
import EmptyTable from "src/components/ui/PMSTable/EmptyTable/EmptyTable";
import StepLayout from "src/layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "src/layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import { getAllMerchandiseCategories } from "src/store/thunks/merchandiseCategory/getAll/getAllMerchandiseCategories";
import {
  getAllMerchandiseNoPagination
} from "src/store/thunks/merchandise/getAllMerchandiseNoPagination/getAllMerchandiseNoPagination";
import { getMerchandiseCost } from "src/store/thunks/merchandise/getMerchandiseCost/getMerchandiseCost";
import { useNavigate } from "react-router";
import useStyles from "./ChooseRetailMerch.styles";
import { getAllRetailMerch } from "src/store/thunks/merchandise/getAllRetailMerch/getAllRetailMerch";

interface ChooseMerchProps { }

const ChooseMerch: FC<ChooseMerchProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const merchandiseCategories = useAppSelector(selectMerchandiseCategories);
  const merchandiseCategoriesLoading = useAppSelector(selectMerchandiseCategoriesLoading);
  const merchandiseItemsLoading = useAppSelector(selectMerchandiseItemsLoading);
  const merchandise = useAppSelector(selectMerchandiseItems);
  const selectedMerchItemIds = useAppSelector(selectMerchLineItemIds);
  const selectedMerchItems = merchandise.filter(merchItems => selectedMerchItemIds.includes(merchItems.id));
  const [pageSize, setPageSize] = useState(5);
  const { classes } = useStyles();

  useEffect(() => {
    if (!merchandise.length) {
      // turn this back on and disable getAllRetailMerch() if it's borked, anderson :)

      // dispatch(getAllMerchandiseNoPagination());

      dispatch(getAllRetailMerch());
      dispatch(getAllMerchandiseCategories());
    }
  }, []);

  useEffect(() => {
    if (selectedMerchItemIds.length > 0) {
      dispatch(getMerchandiseCost());
    }
  }, [selectedMerchItems]);

  const emptyTable = () => (
    <EmptyTable
      collectionNotFound={"merchandise items"}
      value={"merchandise item"}
      addLink={"/merchandise/create"}
    />
  );

  const handleChangeLineItems = (selectedIds: GridSelectionModel) => {
    const diff = selectedMerchItemIds.filter(x => !selectedIds.includes(x));

    if (diff.length) {
      diff.map(item => dispatch(setMerchandiseQuantity({ merchId: +item, merchQuantity: 1 })));
    }

    dispatch(setPosMerchItemLineIds(selectedIds));
  };

  return (
    <Paper variant={"outlined"} elevation={0}>
      <StepLayout
        buttons={
          <StepperButtons
            data-testid={"choose-retail-merch-buttons"}
            stepperId={"point-of-sale"}
            backAction={() => dispatch(setPosModalOpen(true))}
            forwardActionText={"Next"}
            disabled={!selectedMerchItemIds.length}
            forwardAction={() => navigate("/merchandise/point-of-sales/review-and-pay")}
          />
        }
      >
        <Grid container>
          <Grid item xs={8}>
            <Typography variant={"h5"} >
              Choose Retail Merchandise
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              className={classes.tableStroke}
              autoHeight
              components={{
                Toolbar: () => (
                  <Grid
                    container
                    justifyContent={"flex-end"}
                    item
                    xs={12}
                    >
                    <GridToolbarQuickFilter size={"medium"} />
                  </Grid>
                ),
                NoResultsOverlay: emptyTable,
                NoRowsOverlay: emptyTable
              }}
              onPageSizeChange={setPageSize}
              pageSize={pageSize}
              disableSelectionOnClick
              rowsPerPageOptions={[5, 10, 15, 20]}
              loading={merchandiseCategoriesLoading || merchandiseItemsLoading}
              selectionModel={selectedMerchItemIds}
              onSelectionModelChange={handleChangeLineItems}
              checkboxSelection={true}
              columns={ChooseRetailMerchTableColumns(merchandiseCategories, selectedMerchItemIds)}
              rows={merchandise}
            />
          </Grid>
        </Grid>
      </StepLayout>
    </Paper>
  );
};

export default ChooseMerch;
