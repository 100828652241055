import { APIFilter } from "../../../../models/responses/APIFilter";
import { RootState } from "src/store/rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSearchParams } from "./../../../../utils/getSearchParams/getSearchParams";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getAllEsignDocumentsForFacility = createAsyncThunk(
  "getAllEsignDocumentsForFacility",
  (
    {
      facility_id,
      filter
    }: {
      facility_id: number;
      filter?: APIFilter;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    const store = getState() as RootState;
    const searchParams = getSearchParams(store, true);
    const defaultParams = {
      sortDirection: "desc",
      sortBy: "created_at"
    };

    if (searchParams.sortBy === "") {
      Object.assign(searchParams, defaultParams);
    }

    const payload = {
      params: Object.assign(
        {
          ...searchParams,
          facility_id: facility_id === 9999 ? undefined : facility_id,
          with: ["documentTemplate", "occupant", "unit.ledger"]
        },
        filter ?? {}
      )
    };

    return axios
      .get("/api/v1/esign-documents", payload)
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = "There was an error getting the E-sign documents";

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  }
);
