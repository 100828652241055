import "@mui/styles";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "./index.css";

import * as Sentry from "@sentry/react";

import { Theme, ThemeProvider } from "@mui/material/styles";

import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import StylesProvider from "@mui/styles/StylesProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Integrations } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import CustomRouter from "./components/router/CustomRouter";
import reportWebVitals from "./reportWebVitals";
import { rootStore } from "./store/rootStore";
import theme from "./theme";
import { checkAppLiteRoute } from "./utils/checkAppLiteRoute";

declare module "@mui/styles/defaultTheme" {
  // @typescript-eslint/no-empty-interface
  // eslint-disable-next-line no-unused-vars
  interface DefaultTheme extends Theme {}
}
if (
  window.config.reactAppSentryEnvironment &&
  window.config.reactAppSentryDSN &&
  window.config.reactAppSentryEnvironmentWhitelist &&
  window.config.reactAppSentryEnvironmentWhitelist.split(",").includes(
    window.config.reactAppSentryEnvironment
  )
) {
  Sentry.init({
    dsn: window.config.reactAppSentryDSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: window.config.reactAppSentryEnvironment
  });
}

if (window.config.apiUri === "http://127.0.0.1:3000") {
  const axe = require("@axe-core/react");
  axe(React, ReactDOM, 1000);
}

export const muiCache = createCache({
  key: "mui",
  prepend: true
});

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container!);
const token = sessionStorage.getItem("bearerToken");
const params = new URLSearchParams(window.location.search);
const bearer = params.get("bearer");
const isAuthenticated = (token || bearer);
const showLiteApp = checkAppLiteRoute(window.location.pathname);

if (isAuthenticated || showLiteApp) {
  root.render(
    <Provider store={rootStore}>
      <React.StrictMode>
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={theme}>
            <StylesProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <CustomRouter>
                  <App />
                </CustomRouter>
              </LocalizationProvider>
            </StylesProvider>
          </ThemeProvider>
        </CacheProvider>
      </React.StrictMode>
    </Provider>
  );
} else {
  window.location.assign(window.config.identityFrontend);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
