import { CreateFeePayload } from "../create/CreateFeePayload";
import { FeeTypeEnum } from "src/enums/FeeType";
import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const updateFee = createAsyncThunk(
  "updateFee",
  (
    {
      id,
      name,
      description,
      amount,
      recurrence,
      revenueClassId,
      feeCategoryId,
      feeType,
      applyAt,
      isRequired,
      floorAmount,
      amountFloorRestriction
    }: {
      id: string;
      name: string;
      description?: string;
      amount: string;
      recurrence: number;
      revenueClassId: number;
      feeCategoryId: number;
      feeType: number;
      applyAt: number;
      isRequired: boolean;
      floorAmount?: string;
      amountFloorRestriction?: boolean
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const facilityId = state.selectedAdminFacility.selectedAdminFacility!.id;

    const payload: CreateFeePayload = {
      facilities: [facilityId],
      name: name,
      description: description,
      amount: +amount,
      recurrence: recurrence,
      revenue_class_id: revenueClassId,
      fee_category_id: feeCategoryId,
      is_active: true,
      type: feeType,
      apply_at: applyAt,
      is_required: isRequired,
      floor_amount: feeType === FeeTypeEnum.percentage && amountFloorRestriction ? floorAmount : null
    };

    return axios
      .put(`/api/v1/facilities/${facilityId}/fees/${id}`, payload)
      .then((resp) => {
        dispatch(
          showSnackbar({
            message: "Fee updated successfully",
            variant: "success"
          })
        );

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(
            showSnackbar({
              message: errorMessage,
              variant: "error"
            })
          );

          return rejectWithValue(errorMessage);
        }

        errorMessage = `There was an error updating the Fee`;

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  }
);
