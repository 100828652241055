import { Grid, Table, TableBody, TableContainer } from "@mui/material";
import React, { useState } from "react";
import { AvailabileUnitsTableHeadCells } from "../../../../models/AvailabileUnitsTableHeadCells";
import AvailableUnitsTableHead from "./AvailableUnitsTableHead/AvailableUnitsTableHead";
import Paper from "@mui/material/Paper";
import { ProductType } from "../../../../models/ProductType";
import ProductTypesRow from "./ProductTypesRow/ProductTypesRow";
import { orderBy as lodashOrderBy } from "lodash";
import { selectProductTypes } from "../../../../store/reducers/productTypesSlice/productTypesSlice";
import { useAppSelector } from "../../../../store/hooks";
import useStyles from "./AvailableUnitsTable.styles";

const mapTableColumsToOrderBy: Map<
  keyof AvailabileUnitsTableHeadCells | "default",
  Array<keyof ProductType | ((obj: ProductType) => unknown)> | keyof ProductType | ((obj: ProductType) => unknown)
> = new Map();

mapTableColumsToOrderBy.set("default", ["name", "length", "width", (obj: ProductType) => parseFloat(obj.price)]);
mapTableColumsToOrderBy.set("name", "name");
mapTableColumsToOrderBy.set("available_units", "available_units");
mapTableColumsToOrderBy.set("unitSize", ["length", "width"]);
mapTableColumsToOrderBy.set("price", (obj: ProductType) => parseFloat(obj.price));
mapTableColumsToOrderBy.set("floor", "floor" as keyof ProductType);

export default function AvailableUnitsTable() {
  const { classes } = useStyles();

  const productTypes = useAppSelector(selectProductTypes);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof AvailabileUnitsTableHeadCells | "default">("default");

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof AvailabileUnitsTableHeadCells) => {
    setOrder(order === "asc" ? "desc" : "asc");
    setOrderBy(property);
  };

  const sort = (productTypes: ProductType[]) => {
    return lodashOrderBy(productTypes, mapTableColumsToOrderBy.get(orderBy), order);
  };

  return (
    <Grid item xs={12} className={classes.root}>
      <TableContainer component={Paper} className={classes.container} data-testid={"available-units-table-container"}>
        <Table
          className={classes.table}
          aria-labelledby={"tableTitle"}
          aria-label={"available units table"}
          size={"small"}
        >
          <AvailableUnitsTableHead
            data-testid={"available-units-table-head"}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
          />
          <TableBody>
            {sort(productTypes).map((row, i) => (
              <ProductTypesRow key={i} productType={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
