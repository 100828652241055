import { makeStyles } from "../../../../../makeStyles";

const useStyles = makeStyles({ label: "OccupantBillingInformation" })((theme) => ({
  noArrowInput: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    },
    "input[type=number]": {
      MozAppearance: "textfield"
    }
  },
  footerButtons: {
    justifyContent: "flex-end"
  },
  saveButton: {
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText,
    width: "10rem"
  },
  backButton: {
    color: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.grey[300]}`,
    marginRight: "1rem",
    width: "10rem"
  }
}));

export default useStyles;
