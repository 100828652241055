import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Deal Note Information" })((theme) => ({
  note: {
    position: "relative",
    background: "#f2f3f4",
    padding: theme.spacing(2),
    width: "100%"
  },
  button: {
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText
  }
}));

export default useStyles;
