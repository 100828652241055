import { createAsyncThunk } from "@reduxjs/toolkit";
import { identityInstance } from "./../../../api/identityInstance/identityInstance";

export const refreshToken = createAsyncThunk(
  "refreshToken",
  (_, { rejectWithValue }) => {
    return identityInstance.get("/api/v1/auth/refresh")
      .then(resp => {
        if (resp.status === 200) {
          sessionStorage.setItem("bearerToken", `${resp.data.data.token}`);
          sessionStorage.setItem("isAuthenticated", "true");
        }
        return resp.data;
      })
      .catch(error => {
        if (error.response.data?.status === 401) {
          return rejectWithValue("Oops! Please try again. Either your email or password are incorrect.");
        }

        if (error.response && error.response.data) {
          return rejectWithValue(error.response.data.detail);
        }

        return rejectWithValue("Please try again later, we're experiencing a server error. We're working on it!");
      });
  }
);
