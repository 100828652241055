import Moment from "moment-timezone";
import { RootState } from "../../../../rootStore";
import { UpdateOccupantInStepperPayload } from "./UpdateOccupantInStepperPayload";
import { axiosInstance as axios } from "../../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../../utils/format422/format422";
import { setCurrentStep } from "../../../../reducers/formsSlice/formsSlice";
import { showSnackbar } from "../../../../reducers/snackbarSlice/snackbarSlice";

export const updateOccupantInStepper = createAsyncThunk(
  "updateOccupantInStepper",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const currentStep = state.forms.currentStep;
    const occupantInfo = state.occupantInformation;
    const facilityId = state.selectedFacility.selectedFacility.id;

    const payload: UpdateOccupantInStepperPayload = {
      id: occupantInfo.id,
      first_name: occupantInfo.firstName,
      middle_name: occupantInfo.middleName,
      last_name: occupantInfo.lastName,
      email: occupantInfo.email,
      gate_code: String(occupantInfo.gateCode),
      id_number: occupantInfo.idNumber,
      id_state: occupantInfo.idState,
      military_status: occupantInfo.military,
      // @ts-ignore
      // eslint-disable-next-line new-cap
      date_of_birth: new Moment.utc(occupantInfo.dob!).toISOString(),
      is_tax_exempt: occupantInfo.taxExempt,
      tax_exempt_number: occupantInfo.taxExemptNumber,
      is_business: occupantInfo.business,
      business_name: occupantInfo.businessName,
      phone_primary: occupantInfo.primaryPhone,
      phone_alternate: occupantInfo.alternatePhone,
      address: occupantInfo.address,
      address_two: occupantInfo.addressTwo,
      city: occupantInfo.city,
      region: occupantInfo.state,
      postal_code: occupantInfo.postalCode,
      country: occupantInfo.country,
      facility_id: facilityId,
      id_type: occupantInfo.idType
    };

    return axios.put(`/api/v1/occupants/${payload.id}`, payload)
      .then((resp) => {
        dispatch(setCurrentStep(currentStep + 1));

        return resp.data;
      })
      .catch((err) => {
        if (err.response.status === 422) {
          const errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        const errorMessage = "There was an error updating the occupant";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
