import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  Paper,
  TextField,
  Typography
} from "@mui/material";
import React, { FC, SyntheticEvent, useEffect } from "react";
import {
  resetInventorySlice,
  selectInventoryModalOpen,
  setInventoryModalOpen
} from "src/store/reducers/inventorySlice/inventorySlice";
import {
  selectMerchandiseId,
  selectMerchandiseName
} from "src/store/reducers/merchandiseFormSlice/merchandiseFormSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Close } from "@mui/icons-material";
import { createNewInventory } from "src/store/thunks/inventory/create/createNewInventory";
import { getMerchInventory } from "src/store/thunks/inventory/getMerchInventory/getMerchInventory";
import { inputError } from "src/utils/showInputError/showInputError";
import { useFormik } from "formik";
import useStyles from "./NewInventoryModal.styles";
import validationSchema from "./newInventoryModalValidation";

interface NewInventoryModalProps {
}

interface AddNewInventoryValues {
  name: string
  sku: string
  quantity: string
  shipping_cost: string
  cost_of_goods_sold: string
}

const NewInventoryModal: FC<NewInventoryModalProps> = () => {
  const merchandiseName = useAppSelector(selectMerchandiseName);
  const merchandiseId = useAppSelector(selectMerchandiseId);

  const dispatch = useAppDispatch();

  const { classes } = useStyles();
  const initialInventoryValues: AddNewInventoryValues = {
    name: merchandiseName,
    sku: "",
    quantity: "",
    shipping_cost: "",
    cost_of_goods_sold: ""
  };

  const formik = useFormik({
    initialValues: initialInventoryValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!merchandiseId) {
        return;
      }

      dispatch(createNewInventory({ merchandiseId: +merchandiseId, payload: values }))
        .then((resp) => {
          if (!resp.type.includes("rejected")) {
            dispatch(getMerchInventory(merchandiseId));
          }
        });

      formik.resetForm({ values: initialInventoryValues });
      dispatch(resetInventorySlice());
    }
  });

  const { handleSubmit, setFieldTouched, setFieldValue, handleChange, resetForm, values, touched, errors } = formik;

  const handleCurrencyChange = (fieldName: string, amount: number | string) => {
    setFieldTouched(fieldName);
    setFieldValue(fieldName, String(amount));
  };

  const newInventoryModalOpen = useAppSelector(selectInventoryModalOpen);

  const handleClose = () => {
    dispatch(setInventoryModalOpen(false));
    resetForm({ values: initialInventoryValues });
  };

  return (
    <Modal
      className={classes.modal}
      onClose={() => dispatch(setInventoryModalOpen(false))}
      open={newInventoryModalOpen}
    >
      <Grid
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        className={classes.container}
        pb={2}
      >
        <Grid
          component={"form"}
          container
          spacing={1}
          p={2}
          id={"new-inventory-form"}
          onSubmit={(e: SyntheticEvent) => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit();
          }}
        >
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              <Typography variant={"h5"}>New Inventory</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <Close fontSize={"small"} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid pb={4} item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <InputLabel htmlFor={"name"}>
              Merchandise Name
            </InputLabel>
            <TextField
              id={"name"}
              fullWidth
              disabled
              variant={"outlined"}
              name={"name"}
              placeholder={"- Merchandise Name -"}
              value={values.name}
              onChange={handleChange}
              error={inputError("name", touched, errors).error}
              helperText={inputError("name", touched, errors).helperText}
            />
          </Grid>
          <Grid item pb={2} xs={6}>
            <InputLabel htmlFor={"sku"}>SKU</InputLabel>
            <TextField
              id={"sku"}
              fullWidth
              variant={"outlined"}
              name={"sku"}
              placeholder={"- SKU -"}
              value={values.sku}
              onChange={handleChange}
              error={inputError("sku", touched, errors).error}
              helperText={inputError("sku", touched, errors).helperText}
            />
          </Grid>
          <Grid item pb={2} xs={6}>
            <InputLabel htmlFor={"quantity"}>Count</InputLabel>
            <TextField
              id={"quantity"}
              fullWidth
              type={"number"}
              inputProps={{ step: "any" }}
              variant={"outlined"}
              name={"quantity"}
              value={values.quantity}
              onChange={handleChange}
              error={inputError("quantity", touched, errors).error}
              helperText={inputError("quantity", touched, errors).helperText}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel htmlFor={"remaining_merchandise_quantity"}>Remaining Quantity</InputLabel>
            <TextField
              fullWidth
              disabled
              id={"remaining_merchandise_quantity"}
              name={"remaining_merchandise_quantity"}
              type={"number"}
              inputProps={{ step: "any" }}
              variant={"outlined"}
              placeholder={"000"}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel htmlFor={"cost-of-goods"}>Shipping Cost</InputLabel>
            <TextField
              id={"shipping_cost"}
              fullWidth
              type={"number"}
              inputProps={{ step: "any" }}
              variant={"outlined"}
              name={"shipping_cost"}
              InputProps={{ startAdornment: <InputAdornment position={"start"}>$</InputAdornment> }}
              value={values.shipping_cost}
              onChange={(e) => handleCurrencyChange("shipping_cost", e.target.value)}
              error={inputError("shipping_cost", touched, errors).error}
              helperText={inputError("shipping_cost", touched, errors).helperText}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel htmlFor={"cost_of_goods_sold"}>Cost of Goods Sold</InputLabel>
            <TextField
              id={"cost_of_goods_sold"}
              fullWidth
              type={"number"}
              inputProps={{ step: "any" }}
              variant={"outlined"}
              name={"cost_of_goods_sold"}
              placeholder={"- Cost of Goods Sold -"}
              InputProps={{ startAdornment: <InputAdornment position={"start"}>$</InputAdornment> }}
              value={values.cost_of_goods_sold}
              onChange={(e) => handleCurrencyChange("cost_of_goods_sold", e.target.value)}
              error={inputError("cost_of_goods_sold", touched, errors).error}
              helperText={inputError("cost_of_goods_sold", touched, errors).helperText}
            />
          </Grid>
          <Grid
            mt={2}
            container
            justifyContent={"flex-end"}
            spacing={2}
            item
            xs={12}
          >
            <Grid item>
              <Button onClick={handleClose} color={"error"}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                id={"new-inventory-form"}
                type={"submit"}
                variant={"contained"}
                className={classes.saveButton}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default NewInventoryModal;
