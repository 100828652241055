import {
  Button,
  Divider,
  Grid,
  InputLabel,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import React, { FC, FormEvent, useEffect } from "react";
import {
  selectEmailModal, selectSelectedESignDocument,
  setEmailModal,
  setselectedESignDocument
} from "src/store/reducers/eSignStatusSlice/eSignStatusSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import eSignStatusEmailValidation from "../eSignStatusEmailValidation";
import { inputError } from "src/utils/showInputError/showInputError";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { useFormik } from "formik";
import useStyles from "./ResendDocumentModal.styles";
import { generateLeasePackage } from "src/store/thunks/moveIn/generateLeasePackage/generateLeasePackage";

interface ResendDocumentModalProps { }

const ResendDocumentModal: FC<ResendDocumentModalProps> = () => {
  const initialState = {
    email: "",
    picked: "old"
  };
  const dispatch = useAppDispatch();
  const emailModalOpen = useAppSelector(selectEmailModal);
  const selectedESignDocument = useAppSelector(selectSelectedESignDocument);

  const onSubmit = (values: {email: string}) => {
    dispatch(
      generateLeasePackage({
        selectedDocuments: [selectedESignDocument?.document_template],
        email: values.email || null,
        resend: true
      })
    );

    dispatch(showSnackbar({
      message: `Document emailed successfully.`,
      variant: "success"
    }));

    dispatch(setEmailModal(false));
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: eSignStatusEmailValidation,
    onSubmit: (values) => {
      //we do nothing here until the API has the endpoints we need, then we make the thunk.
      onSubmit(values);
    }
  });

  const handleClose = () => {
    dispatch(setEmailModal(false));
    dispatch(setselectedESignDocument(null));
  };

  const { values, setFieldValue, handleChange, resetForm, handleSubmit, touched, errors } = formik;
  const { classes } = useStyles();

  useEffect(() => {
    resetForm({ values: initialState });
  }, [emailModalOpen]);

  useEffect(() => {
    setFieldValue("email", "");
  }, [values.picked]);

  return (
    <Modal
      open={emailModalOpen}
      onClose={handleClose}
      aria-describedby={"A Modal component to add or edit chart of account settings."}
      className={classes.modal}
        >
      <Grid
        container
        p={5}
        className={classes.container}
        component={Paper}
            >
        <form id={"new-email"} onSubmit={(e: FormEvent) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}>
          <RadioGroup
            aria-labelledby={"demo-controlled-radio-buttons-group"}
            name={"controlled-radio-buttons-group"}
            value={values.picked}
            onChange={handleChange}
                    >
            <Grid item xs={12}>
              <Typography gutterBottom variant={"h4"} textAlign={"center"}>
                Re-send Document to Occupant
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography textAlign={"center"} gutterBottom>
                Send document via email to occupant via existing email or different address.
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={2}>
                <Radio name={"picked"} value={"old"}/>
              </Grid>
              <Grid alignSelf={"center"} item xs={10}>
                <Typography gutterBottom>
                  Use existing email address:
                </Typography>
                <Grid>
                  {selectedESignDocument?.occupant?.email || "No email on file"}
                </Grid>
              </Grid>
            </Grid>
            <Grid item p={4} xs={12}>
              <Divider>
                or
              </Divider>
            </Grid>
            <Grid
              container
              mb={2}
              item
              xs={12}
            >
              <Grid item xs={2}>
                <Radio name={"picked"} value={"new"}/>
              </Grid>
              <Grid item xs={10} mb={2}>
                <InputLabel htmlFor={"email"}>
                  New E-mail Address
                </InputLabel>
                <TextField
                  disabled={values.picked === "old"}
                  fullWidth
                  placeholder={"- E-mail Address -"}
                  id={"email"}
                  name={"email"}
                  value={values.email}
                  onChange={handleChange}
                  error={inputError("email", touched, errors).error}
                  helperText={inputError("email", touched, errors).helperText}
                                />
              </Grid>
              <Grid container justifyContent={"center"}>
                <Button onClick={handleClose} className={classes.cancelButton} variant={"outlined"}>
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  className={classes.confirmationButton}
                  variant={"outlined"}
                                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </RadioGroup>
        </form>
      </Grid>
    </Modal>
  );
};

export default ResendDocumentModal;
