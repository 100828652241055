import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { GetPartialPaymentCostResponse }
  from "src/store/thunks/makeAPayment/getPartialPaymentCost/GetPartialPaymentCostResponse";
import { LedgerPaymentItem } from "../../../models/LedgerPayment";
import { MakeAPaymentSliceState } from "./MakeAPaymentSliceState";
import { RootState } from "../../rootStore";
import { Transaction } from "../../../models/Transaction";
import { getAllLedgersForOccupant } from "../../thunks/ledger/getAllForOccupant/getAllLedgersForOccupant";
import { getPartialPaymentCost } from "src/store/thunks/makeAPayment/getPartialPaymentCost/getPartialPaymentCost";
import {
  getPartialPaymentCostNew
} from "src/store/thunks/makeAPayment/getPartialPaymentCostNew/getPartialPaymentCostNew";
import { makeAPayment } from "src/store/thunks/makeAPayment/makeAPayment/makeAPayment";
import {
  partialPaymentMultipleLedgers
} from "src/store/thunks/makeAPayment/partialPayment/partialPaymentMultipleLedgers";
import { getOneLedger } from "src/store/thunks/ledger/getOneLedger/getOneLedger";

export const initialState: MakeAPaymentSliceState = {
  ledgers: [],
  selectedLedgers: [],
  ledgersLoading: false,
  makeAPaymentError: "",
  selectedOccupant: null,
  totalDue: 0,
  ledgerPayments: [],
  runningBalance: 0,
  checkedItems: [],
  transaction: null,
  outStandingBalance: 0,
  totalPaymentDue: 0,
  totalGrossAmount: 0,
  totalTax: 0,
  emailSent: false,
  isFromPayNow: false,
  periods: 0,
  makeAPaymentCostLoading: false,
  additionalAccountFund: 0,
  customPaymentAmount: 0,
  grandTotalPrepaid: 0,
  grandTotalPrepaidTax: 0,
  grandTotalPrepaidGross: 0,
  netGrandTotalPayment: 0,
  netGrandTotalTax: 0,
  netGrandTotalGross: 0,
  promoAdjustment: 0,
  selectedLedgersBeforePayment: [],
  effectiveAmount: 0
};

export const makeAPaymentSlice = createSlice({
  name: "makeAPayment",
  initialState,
  reducers: {
    setEffectiveAmount: (state, action) => {
      state.effectiveAmount = action.payload;
    },
    setSelectedLedgers: (state, action) => {
      state.selectedLedgers = action.payload;
    },
    setRunningBalance: (state, action: PayloadAction<number>) => {
      state.runningBalance = action.payload;
    },
    resetLedgers: (state) => {
      state.ledgers = [];
    },
    setLedgerPayments: (state, action: PayloadAction<LedgerPaymentItem[]>) => {
      state.ledgerPayments = action.payload;
    },
    setTransaction: (state, action: PayloadAction<Transaction>) => {
      state.transaction = action.payload;
    },
    updateLedgerPayments: (state, action: PayloadAction<LedgerPaymentItem>) => {
      const index = state.ledgerPayments.findIndex((ledgerPayment) => ledgerPayment.id === action.payload.id);

      state.ledgerPayments[index].payment_amount = action.payload.payment_amount;
    },
    setAdditionalAccountFund: (state, action: PayloadAction<number>) => {
      state.additionalAccountFund = action.payload;
    },
    resetSelectedLedgers: (state) => {
      state.selectedLedgers = [];
    },
    resetMakeAPaymentSlice: (state) => {
      Object.assign(state, initialState);
    },
    setOutStandingBalance: (state, action: PayloadAction<number>) => {
      state.outStandingBalance = action.payload;
    },
    setCheckedItems: (state, action: PayloadAction<number[]>) => {
      state.checkedItems = action.payload;
    },
    setTotalPaymentDue: (state, action: PayloadAction<number>) => {
      state.totalPaymentDue = action.payload;
    },
    setTotalGrossAmount: (state, action: PayloadAction<number>) => {
      state.totalGrossAmount = action.payload;
    },
    setTotalTax: (state, action: PayloadAction<number>) => {
      state.totalTax = action.payload;
    },
    setEmailSent: (state, action: PayloadAction<boolean>) => {
      state.emailSent = action.payload;
    },
    setIsFromPayNow: (state, action: PayloadAction<boolean>) => {
      state.isFromPayNow = action.payload;
    },
    setMakeAPaymentPeriods: (state, action: PayloadAction<number | number[]>) => {
      state.periods = action.payload;
    },
    setCustomPaymentAmount: (state, action: PayloadAction<number>) => {
      state.customPaymentAmount = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(partialPaymentMultipleLedgers.pending, (state) => {
        state.selectedLedgersBeforePayment = state.selectedLedgers;
        state.makeAPaymentCostLoading = true;
      })
      .addCase(partialPaymentMultipleLedgers.rejected, (state) => {
        state.makeAPaymentCostLoading = false;
      })
      .addCase(partialPaymentMultipleLedgers.fulfilled, (state) => {
        state.makeAPaymentCostLoading = false;
      })
      .addCase(getPartialPaymentCost.pending, (state) => {
        state.makeAPaymentCostLoading = true;
      })
      .addCase(getPartialPaymentCost.fulfilled, (state, action: PayloadAction<GetPartialPaymentCostResponse>) => {
        state.makeAPaymentCostLoading = false;
        state.totalPaymentDue = action.payload.grand_total_payment;
        state.totalTax = action.payload.grand_total_tax;
        state.totalGrossAmount = action.payload.grand_total_gross;
        state.outStandingBalance = action.payload.grand_outstanding_balance;
        state.runningBalance = action.payload.grand_running_balance;
        state.grandTotalPrepaid = action.payload.grand_total_prepaid;
        state.grandTotalPrepaidTax = action.payload.grand_total_prepaid_tax;
        state.grandTotalPrepaidGross = action.payload.grand_total_prepaid_gross;
        state.netGrandTotalPayment = action.payload.net_grand_total_payment;
        state.netGrandTotalTax = action.payload.net_grand_total_tax;
        state.netGrandTotalGross = action.payload.net_grand_total_gross;
        state.promoAdjustment = action.payload.promo_adjustment;
      })
      .addCase(getPartialPaymentCost.rejected, (state, action) => {
        state.makeAPaymentCostLoading = false;
        state.makeAPaymentError = action.payload as string;
      })
      .addCase(getAllLedgersForOccupant.pending, (state) => {
        state.ledgersLoading = true;
      })
      .addCase(getAllLedgersForOccupant.fulfilled, (state, action) => {
        state.ledgersLoading = false;
        state.ledgers = action.payload.data;
      })
      .addCase(getAllLedgersForOccupant.rejected, (state, action) => {
        state.ledgersLoading = false;
        state.makeAPaymentError = action.payload as string;
      })
      .addCase(getPartialPaymentCostNew.fulfilled, (state, action) => {
        if (action.payload.required_prepaid_periods) {
          state.periods = action.payload.required_prepaid_periods;
        }

        if (action.payload.requested_prepaid_periods) {
          state.periods = action.payload.requested_prepaid_periods;
        }
      }).addCase(makeAPayment.pending, (state, action) => {
        state.selectedLedgersBeforePayment = state.selectedLedgers;
      }).addCase(getOneLedger.fulfilled, (state, action) => {
        state.selectedLedgers = [action.payload];
      });
  }
});

export const selectLedgers = (state: RootState) => state.makeAPayment.ledgers;
export const selectEffectiveAmount = (state: RootState) => state.makeAPayment.effectiveAmount;
export const selectSelectedLedgers = (state: RootState) => state.makeAPayment.selectedLedgers;
export const selectSelectedLedgersBeforePayment = (state: RootState) => state.makeAPayment.selectedLedgersBeforePayment;
export const selectLedgersLoading = (state: RootState) => state.makeAPayment.ledgersLoading;
export const selectRunningBalance = (state: RootState) => state.makeAPayment.runningBalance;
export const selectLedgerPayments = (state: RootState) => state.makeAPayment.ledgerPayments;
export const selectCheckedItems = (state: RootState) => state.makeAPayment.checkedItems;
export const selectTransaction = (state: RootState) => state.makeAPayment.transaction;
export const selectOutStandingBalance = (state: RootState) => state.makeAPayment.outStandingBalance;
export const selectTotalPaymentDue = (state: RootState) => state.makeAPayment.totalPaymentDue;
export const selectTotalGrossAmount = (state: RootState) => state.makeAPayment.totalGrossAmount;
export const selectTotalTax = (state: RootState) => state.makeAPayment.totalTax;
export const selectEmailSent = (state: RootState) => state.makeAPayment.emailSent;
export const selectIsFromPayNow = (state: RootState) => state.makeAPayment.isFromPayNow;
export const selectMakeAPaymentPeriods = (state: RootState) => state.makeAPayment.periods;
export const selectMakeAPaymentCostLoading = (state: RootState) => state.makeAPayment.makeAPaymentCostLoading;
export const selectGrandTotalPrepaid = (state: RootState) => state.makeAPayment.grandTotalPrepaid;
export const selectGrandTotalPrepaidTax = (state: RootState) => state.makeAPayment.grandTotalPrepaidTax;
export const selectGrandTotalPrepaidGross = (state: RootState) => state.makeAPayment.grandTotalPrepaidGross;
export const selectAdditionalAccountFunds = (state: RootState) => state.makeAPayment.additionalAccountFund;
export const selectCustomPaymentAmount = (state: RootState) => state.makeAPayment.customPaymentAmount;
export const selectNetGrandTotalPayment = (state: RootState) => state.makeAPayment.netGrandTotalPayment;
export const selectNetGrandTotalTax = (state: RootState) => state.makeAPayment.netGrandTotalTax;
export const selectNetGrandTotalGross = (state: RootState) => state.makeAPayment.netGrandTotalGross;
export const selectMakeAPaymentPromoAdjustment = (state: RootState) => state.makeAPayment.promoAdjustment;

export const {
  setEffectiveAmount,
  setSelectedLedgers,
  setCheckedItems,
  resetSelectedLedgers,
  resetLedgers,
  updateLedgerPayments,
  setRunningBalance,
  setLedgerPayments,
  setTransaction,
  resetMakeAPaymentSlice,
  setOutStandingBalance,
  setTotalPaymentDue,
  setTotalGrossAmount,
  setTotalTax,
  setEmailSent,
  setIsFromPayNow,
  setAdditionalAccountFund,
  setCustomPaymentAmount,
  setMakeAPaymentPeriods
} = makeAPaymentSlice.actions;
export default makeAPaymentSlice.reducer;
