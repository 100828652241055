import { RootState } from "src/store/rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSearchParams } from "../../../../utils/getSearchParams/getSearchParams";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getOccupantHistory = createAsyncThunk(
  "getOccupantHistory",
  (
    { searchValue, newest, occupantId }: { searchValue?: string; newest: boolean; occupantId: number },
    { dispatch, getState, rejectWithValue }
  ) => {
    const store = getState() as RootState;
    const facilityId = store.selectedFacility.selectedFacility.id;

    const defaultParams = {
      sortDirection: newest ? "desc" : "asc",
      sortBy: "created_at"
    };

    const filter = {
      search_value: searchValue
    };
    const payload = {
      params: {
        ...getSearchParams(store, true),
        ...defaultParams,
        ...filter
      }
    };

    return axios
      .get(`/api/v1/facilities/${facilityId}/occupants/${occupantId}/history`, payload)
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = "There was an error searching for the Occupant";

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  }
);
