/* eslint-disable max-len */
import { RefundMerchandisePayload } from "./RefundTransactionPayload";
import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const processRefundTransaction = createAsyncThunk(
  "processRefundMerchandise",
  ({ transactionId, amount, reason, method }: { transactionId: number, amount: number | string, reason: string, method: number },
    { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const facilityId = state.selectedFacility.selectedFacility.id;

    const payload: RefundMerchandisePayload = {
      amount: amount,
      reason: reason,
      method: method
    };

    return axios.post(`/api/v1/refund/${facilityId}/${transactionId}`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Refund Transaction successfully",
          variant: "success"
        }));
        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;

        errorMessage = `There was an error creating refunding for the transaction`;

        if (err?.response?.status === 400) {
          errorMessage += `: ${err.response.data.detail}`;
        }

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
