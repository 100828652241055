import { makeStyles } from "../../makeStyles";

const useStyles = makeStyles<{ isCalling: boolean }>({ label: "App Softphone" })((theme, { isCalling }) => ({
  dialog: {
    width: "450px"
  },
  header: {
    backgroundColor: isCalling ? theme.palette.error.main : theme.palette.primary.contrastText,
    color: theme.palette.common.white,
    cursor: "move"
  },
  icon: {
    color: theme.palette.common.white
  },
  button: {
    backgroundColor: isCalling ? theme.palette.error.main : theme.palette.primary.contrastText,
    color: theme.palette.common.white
  }
}));

export default useStyles;
