import {
  Chat as ChatIcon,
  Download as DownloadIcon,
  Send as SendIcon,
  Visibility as VisibilityIcon
} from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import React, { FC } from "react";
import {
  setEmailModal,
  setViewESignDocumentModal,
  setselectedESignDocument
} from "src/store/reducers/eSignStatusSlice/eSignStatusSlice";
import { useAppDispatch } from "src/store/hooks";
import { useNavigate } from "react-router";
import { sendSms } from "src/store/thunks/esignDocument/sms/sendSms";
import { prepareForSigning } from "src/store/thunks/esignDocument/sign/prepareForSigning";
import ESignDocument from "src/models/ESignDocument";
import { downloadEsignDocument } from "src/store/thunks/esignDocument/download/downloadEsignDocument";
import {
  setSelectedDocumentPDFUrl
} from "src/store/reducers/documentsSlice/documentsSlice";
import {
  getDocumentTemplateBase64PDF
} from "src/store/thunks/documentTemplates/getB64PDF/getDocumentTemplateBase64PDF";

interface ESignActionButtonsProps {
  eSignId: number;
  eSignDetails: ESignDocument;
}

const ESignActionButtons: FC<ESignActionButtonsProps> = ({ eSignId, eSignDetails }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const openEmailModal = () => {
    dispatch(setEmailModal(true));
    dispatch(setselectedESignDocument(eSignDetails));
  };

  const startSigning = () => {
    dispatch(prepareForSigning({ id: eSignId })).then(() => {
      window.open(`/documents/eSign-sign/${eSignId}`, "_blank");
    });
  };

  const openViewESignDocumentModal = () => {
    dispatch(
      downloadEsignDocument(eSignDetails.id!)
    ).then(({ payload }) => {
      const url = URL.createObjectURL(payload);
      dispatch(setSelectedDocumentPDFUrl(url));
      dispatch(setViewESignDocumentModal(true));
    });
  };

  const onSendSmsClick = () => {
    dispatch(sendSms({ id: eSignId }));
  };

  return (
    <Grid container>
      <Grid item xs={3}>
        <Tooltip title={"Email Document"}>
          <IconButton onClick={openEmailModal}>
            <SendIcon fontSize={"small"} />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <Tooltip title={"Send SMS"}>
          <IconButton onClick={onSendSmsClick}>
            <ChatIcon fontSize={"small"} />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <Tooltip title={"Sign In Store"}>
          <IconButton>
            <DownloadIcon onClick={startSigning} fontSize={"small"} />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <Tooltip title={"View"}>
          <IconButton onClick={openViewESignDocumentModal}>
            <VisibilityIcon fontSize={"small"} />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default ESignActionButtons;
