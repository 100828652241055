import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { APIListResponse } from "../../../models/responses/APIListResponse";
import { Promotion } from "../../../models/Promotion";
import { PromotionsSliceState } from "./PromotionsSliceState";
import { RootState } from "../../rootStore";
import { getAllPromotions } from "../../thunks/promotion/getAll/getAllPromotions";
import { getAllPromotionsForFacility } from "../../thunks/promotion/getAllForFacility/getAllPromotionsForFacility";

export const initialState: PromotionsSliceState = {
  promotions: JSON.parse(sessionStorage.getItem("promotions") as string) ?? [],
  promotionsForFacility: JSON.parse(sessionStorage.getItem("promotionsForFacility") as string) ?? [],
  promotionsLoading: false,
  promotionsError: "",
  showActive: true
};

export const promotionsSlice = createSlice({
  name: "promotions",
  initialState,
  reducers: {
    setShowActive: (state, action) => {
      state.showActive = action.payload;
    },
    resetPromotionsSlice: (state) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("promotions");
      sessionStorage.removeItem("promotionsForFacility");
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPromotions.pending, (state) => {
        state.promotionsLoading = true;
      })
      .addCase(getAllPromotions.fulfilled, (state, action: PayloadAction<APIListResponse<Promotion[]>>) => {
        state.promotionsLoading = false;
        state.promotions = action.payload.data;
        sessionStorage.setItem("promotions", JSON.stringify(state.promotions));
      })
      .addCase(getAllPromotions.rejected, (state, action) => {
        state.promotionsLoading = false;
        state.promotionsError = action.payload as string;
      })
      .addCase(getAllPromotionsForFacility.pending, (state) => {
        state.promotionsLoading = true;
      })
      .addCase(getAllPromotionsForFacility.fulfilled, (state, action: PayloadAction<APIListResponse<Promotion[]>>) => {
        state.promotionsLoading = false;
        state.promotionsForFacility = action.payload.data;
        sessionStorage.setItem("promotionsForFacility", JSON.stringify(state.promotions));
      })
      .addCase(getAllPromotionsForFacility.rejected, (state, action) => {
        state.promotionsLoading = false;
        state.promotionsError = action.payload as string;
      });
  }
});

export const selectPromotions = (state: RootState) => state.promotions.promotions;
export const selectPromotionsForFacility = (state: RootState) => state.promotions.promotionsForFacility;
export const selectShowActivePromotions = (state: RootState) => state.promotions.showActive;
export const selectPromotionsLoading = (state: RootState) => state.promotions.promotionsLoading;

export const {
  setShowActive,
  resetPromotionsSlice
} = promotionsSlice.actions;

export default promotionsSlice.reducer;
