import { Unit } from "src/models/Unit";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";
import { CreateUnitPayload } from "./CreateUnitPayload";
import { RootState } from "src/store/rootStore";

export const createUnitNew = createAsyncThunk(
  "createUnitNew",
  (payload: CreateUnitPayload,
    { dispatch, rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const facilityId = state.selectedFacility.selectedFacility.id;

    return axios.post(`/api/v1/facilities/${facilityId}/units`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Unit created",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = `There was an error creating the Unit`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
