import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  selectCheckedItems,
  selectCustomPaymentAmount,
  selectLedgerPayments,
  selectOutStandingBalance,
  setAdditionalAccountFund,
  setCustomPaymentAmount,
  setMakeAPaymentPeriods,
  setTotalPaymentDue,
  updateLedgerPayments
} from "../../../../../../../store/reducers/makeAPaymentSlice/makeAPaymentSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Ledger } from "src/models/Ledger";
import { LedgerPaymentItem } from "../../../../../../../models/LedgerPayment";
import { SyntheticInputEvent } from "react-number-format/types/types";
import { selectMaxPaymentAmount } from "src/store/reducers/paymentSlice/paymentSlice";
import useStyles from "./PartialPaymentColumn.styles";

interface PartialPaymentColumnProp {
  ledger: Ledger;
}

const PartialPaymentColumn = ({ ledger }: PartialPaymentColumnProp) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const ledgerPayments = useAppSelector(selectLedgerPayments);
  const selectedLedgerPayments = ledgerPayments?.find(
    (lp) => lp.id === ledger.id
  )!;
  const [inputAmount, setInputAmount] = useState("");
  const outstandingBalance = useAppSelector(selectOutStandingBalance);
  const validCurrencyRegex = /^\d*\.?\d{0,2}$/;
  const maxPaymentAmount = useAppSelector(selectMaxPaymentAmount);

  const handleUpdateLedgerPaymentItems = (
    ledgerPaymentItem: LedgerPaymentItem
  ) => {
    // We need to filter the current ledger, because the value is not update in the store inside ledgerPayments.
    const totalPaymentAmount = ledgerPayments
      .filter(
        (payment: { id: any }) => payment.id !== selectedLedgerPayments?.id
      )
      .reduce(
        (total: number, payment: { payment_amount: any }) =>
          total + Number(payment.payment_amount),
        0
      );

    const allLedgersPaymentAmount =
      totalPaymentAmount + Number(ledgerPaymentItem.payment_amount);

    dispatch(updateLedgerPayments(ledgerPaymentItem));
    dispatch(setTotalPaymentDue(allLedgersPaymentAmount));
    dispatch(setCustomPaymentAmount(Number(ledgerPaymentItem.payment_amount)));
    if (allLedgersPaymentAmount > outstandingBalance) {
      dispatch(
        setAdditionalAccountFund(
          +(allLedgersPaymentAmount - outstandingBalance).toFixed(2)
        )
      );
    } else {
      dispatch(setAdditionalAccountFund(0));
    }
  };

  const handleCustomPaymentChange = (e: SyntheticInputEvent) => {
    let value = e.target.value;
    let isValueChanged = value !== inputAmount;

    if (Number(value) > Number(maxPaymentAmount) && maxPaymentAmount !== null) {
      value = String(maxPaymentAmount);
      isValueChanged = true;
    }

    if (validCurrencyRegex.test(value)) {
      if (isValueChanged) {
        dispatch(setMakeAPaymentPeriods(0));
      }

      if (value === "") {
        dispatch(setCustomPaymentAmount(0));
      }

      if (value !== "") {
        dispatch(setCustomPaymentAmount(Number(value)));
      }

      setInputAmount(value);
    }
  };

  const formatCurrencyInput = (value: string) => {
    const numberValue = parseFloat(value);
    return numberValue.toFixed(2);
  };

  const handleBlur = () => {
    if (inputAmount === "") {
      handleUpdateLedgerPaymentItems({
        id: selectedLedgerPayments.id,
        payment_amount: 0
      });

      setInputAmount("");
    } else {
      const formattedValue = formatCurrencyInput(inputAmount);

      handleUpdateLedgerPaymentItems({
        id: selectedLedgerPayments.id,
        payment_amount: Number(formattedValue) > 0 ? Number(formattedValue) : 0
      });
      setInputAmount(formattedValue);
    }
  };

  return (
    <Grid
      container
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Grid item xs={12}>
        <TextField
          data-testid={"partial-payment-input"}
          type={"text"}
          disabled={maxPaymentAmount === 0}
          name={"custommPayment"}
          onChange={handleCustomPaymentChange}
          value={inputAmount}
          onBlur={handleBlur}
          size={"small"}
          className={classes.partialPaymentInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"}>$</InputAdornment>
            )
          }}
        />
      </Grid>
    </Grid>
  );
};

export default PartialPaymentColumn;
