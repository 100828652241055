import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().required("E-mail Address is required").email("E-mail Address must be valid"),
  postalCode: Yup.string()
    .nullable()
    .test("length", "Postal Code must have 5 or 9 digits", (postalCode) => {
      return postalCode?.length === 5 || postalCode?.length === 9;
    }),
  phone: Yup.string().required("Primary Phone is required").length(10, "Primary Phone must be 10 digits")
});

export default validationSchema;
