import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "UIModal" })((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  container: {
    width: "25rem",
    borderRadius: "1rem",
    padding: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  button: {
    color: theme.palette.primary.contrastText,
    minWidth: theme.spacing(10),
    minHeight: theme.spacing(5),
    fontSize: theme.typography.body2.fontSize
  },
  cancelButton: {
    minWidth: theme.spacing(10),
    minHeight: theme.spacing(5),
    color: theme.palette.error.contrastText,
    fontSize: theme.typography.body2.fontSize
  }
}));

export default useStyles;
