import {
  ChangeOwnershipSliceState,
  EmergencyContactInfoState,
  OccupantInformationState
} from "./changeOwnershipSliceState";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { CreditCardProvider } from "src/models/CreditCardProvider";
import { RootState } from "src/store/rootStore";
import creditCard from "credit-card-type";
import moment from "moment";

export const initialState: ChangeOwnershipSliceState = {
  occupantInformationBackup: {
    id: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    gateCode: "",
    idNumber: "",
    idState: "",
    military: 0,
    dob: null,
    taxExempt: false,
    taxExemptNumber: "",
    business: false,
    businessName: "",
    primaryPhone: "",
    alternatePhone: "",
    address: "",
    addressTwo: "",
    city: "",
    state: "",
    postalCode: "",
    country: "USA",
    idType: 1
  },
  formOccupantInformation: {
    id: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    gateCode: "",
    idNumber: "",
    idState: "",
    military: 0,
    dob: null,
    taxExempt: false,
    taxExemptNumber: "",
    business: false,
    businessName: "",
    primaryPhone: "",
    alternatePhone: "",
    address: "",
    addressTwo: "",
    city: "",
    state: "",
    postalCode: "",
    country: "USA",
    idType: 1
  },
  emergencyContactInfoBackup: {
    first_name: "",
    middle_name: "",
    last_name: "",
    address: "",
    address_two: "",
    city: "",
    region: "",
    postal_code: "",
    phone_primary: "",
    relationship: "",
    email: ""
  },
  formEmergencyContactInfo: {
    first_name: "",
    middle_name: "",
    last_name: "",
    address: "",
    address_two: "",
    city: "",
    region: "",
    postal_code: "",
    phone_primary: "",
    relationship: "",
    email: ""
  },
  emergencyContactAddressSameAsOccupantBackup: false,
  formEmergencyContactAddressSameAsOccupant: false,
  achBackup: {
    routingNumber: "",
    bankName: "",
    accountNumber: "",
    accountNumberConfirm: "",
    accountName: "",
    checkNumber: "",
    accountType: "1",
    accountNumberMasked: "",
    accountNumberConfirmMasked: ""
  },
  achForm: {
    routingNumber: "",
    bankName: "",
    accountNumber: "",
    accountNumberConfirm: "",
    accountName: "",
    checkNumber: "",
    accountType: "1",
    accountNumberMasked: "",
    accountNumberConfirmMasked: "",
    isPreferredPayment: false,
    enableAutoPay: false
  },
  billingInformationForm: {
    firstName: "",
    middleName: "",
    lastName: "",
    addressOne: "",
    addressTwo: "",
    city: "",
    state: "",
    postalCode: ""
  },
  creditCardBackup: {
    ccNumber: "",
    ccNumberMasked: "",
    ccName: "",
    ccExpiration: "",
    ccCVV: "",
    ccCVVMasked: "",
    ccType: ""
  },
  creditCardForm: {
    ccNumber: "",
    ccNumberMasked: "",
    ccName: "",
    ccExpiration: "",
    ccCVV: "",
    ccCVVMasked: "",
    ccType: "",
    isPreferredPayment: false,
    enableAutoPay: false
  }
};

const setOccupantInfo = (action: PayloadAction<OccupantInformationState>) => {
  return {
    id: action.payload.id,
    firstName: action.payload.firstName,
    middleName: action.payload.middleName,
    lastName: action.payload.lastName,
    address: action.payload.address,
    addressTwo: action.payload.addressTwo || "",
    city: action.payload.city,
    state: action.payload.state,
    postalCode: action.payload.postalCode,
    country: action.payload.country,
    email: action.payload.email,
    taxExemptNumber: action.payload.taxExemptNumber,
    military: action.payload.military,
    primaryPhone: action.payload.primaryPhone,
    alternatePhone: action.payload.alternatePhone ?? "",
    dob: moment(action.payload.dob).format("MM/DD/YYYY"),
    gateCode: action.payload.gateCode,
    idNumber: action.payload.idNumber,
    idState: action.payload.idState,
    business: action.payload.business,
    taxExempt: action.payload.taxExempt,
    businessName: action.payload.businessName ?? "",
    idType: 1
  };
};

const setOccupantContact = (action: PayloadAction<any>) => {
  return {
    first_name: action.payload.firstName,
    middle_name: action.payload.middleName,
    last_name: action.payload.lastName,
    address: action.payload.addressOne,
    address_two: action.payload.addressTwo,
    city: action.payload.city,
    region: action.payload.state,
    postal_code: action.payload.postalCode,
    phone_primary: action.payload.phone,
    relationship: action.payload.relationship,
    email: action.payload.email
  };
};

export const changeOwnershipSlice = createSlice({
  name: "changeOwnership",
  initialState,
  reducers: {
    setOccupantInformationBackup: (state, action) => {
      state.occupantInformationBackup = {
        ...setOccupantInfo(action)
      };
    },
    setFormOccupantInformation: (state, action) => {
      state.formOccupantInformation = {
        ...setOccupantInfo(action)
      };
    },
    setEmergencyContactInfoBackup: (state, action) => {
      state.emergencyContactInfoBackup = {
        ...setOccupantContact(action)
      };
    },
    setFormEmergencyContactInfo: (state, action) => {
      state.formEmergencyContactInfo = {
        ...setOccupantContact(action)
      };
    },
    setEmergencyContactAddressSameAsOccupantBackup: (state, action) => {
      state.emergencyContactAddressSameAsOccupantBackup = action.payload;
    },
    setFormEmergencyContactAddressSameAsOccupant: (state, action) => {
      state.formEmergencyContactAddressSameAsOccupant = !state.formEmergencyContactAddressSameAsOccupant;

      if (state.formEmergencyContactAddressSameAsOccupant) {
        state.formEmergencyContactInfo = {
          ...state.formEmergencyContactInfo,
          address: action.payload.address,
          address_two: action.payload.addressTwo,
          city: action.payload.city,
          region: action.payload.state,
          postal_code: action.payload.postalCode
        };
      }

      if (!state.formEmergencyContactAddressSameAsOccupant) {
        state.formEmergencyContactInfo = {
          ...state.formEmergencyContactInfo,
          address: initialState.formEmergencyContactInfo.address,
          address_two: initialState.formEmergencyContactInfo.address_two,
          city: initialState.formEmergencyContactInfo.city,
          region: initialState.formEmergencyContactInfo.region,
          postal_code: initialState.formEmergencyContactInfo.postal_code
        };
      }
    },
    setAchBackup: (state, action) => {
      state.achBackup = {
        routingNumber: action.payload.routingNumber,
        bankName: action.payload.bankName,
        accountNumber: action.payload.accountNumber,
        accountNumberConfirm: action.payload.accountNumberConfirm,
        accountName: action.payload.accountName,
        checkNumber: action.payload.checkNumber,
        accountType: action.payload.accountType,
        accountNumberMasked: action.payload.accountNumberMasked,
        accountNumberConfirmMasked: action.payload.accountNumberConfirmMasked
      };
    },
    setAchForm: (state, action) => {
      state.achForm = {
        routingNumber: action.payload.routingNumber,
        bankName: action.payload.bankName,
        accountNumber: action.payload.accountNumber,
        accountNumberConfirm: action.payload.accountNumberConfirm,
        accountName: action.payload.accountName,
        checkNumber: action.payload.checkNumber,
        accountType: action.payload.accountType,
        accountNumberMasked: action.payload.accountNumberMasked,
        accountNumberConfirmMasked: action.payload.accountNumberConfirmMasked,
        isPreferredPayment: action.payload.isPreferredPayment,
        enableAutoPay: action.payload.isEnableAutoPay
      };
    },
    setAchPaymentOptions: (state, action) => {
      state.achForm = {
        ...state.achForm,
        isPreferredPayment: action.payload.isPreferredPayment,
        enableAutoPay: action.payload.enableAutoPay
      };
    },
    setCreditCardBackup: (state, action) => {
      state.creditCardBackup = {
        ccNumber: action.payload.ccNumber,
        ccName: action.payload.ccName,
        ccExpiration: action.payload.ccExpiration,
        ccCVV: action.payload.ccCVV,
        ccNumberMasked: action.payload.ccNumberMasked,
        ccCVVMasked: action.payload.ccCVVMasked,
        ccType: action.payload.ccType
      };
    },
    setCreditCardForm: (state, action) => {
      const cardData = creditCard(action.payload.ccNumber);
      if (cardData.length) {
        state.creditCardForm.ccType = cardData[0].type as CreditCardProvider;
      }

      state.creditCardForm = {
        ...state.creditCardForm,
        ccNumber: action.payload.ccNumber,
        ccName: action.payload.ccName,
        ccExpiration: action.payload.ccExpiration,
        ccCVV: action.payload.ccCVV,
        ccNumberMasked: action.payload.ccNumberMasked,
        ccCVVMasked: action.payload.ccCVVMasked
      };
    },
    setCreditCardPaymentOptions: (state, action) => {
      state.creditCardForm = {
        ...state.creditCardForm,
        isPreferredPayment: action.payload.isPreferredPayment,
        enableAutoPay: action.payload.enableAutoPay
      };
    },
    setBillingInformationForm: (state, action) => {
      state.billingInformationForm = action.payload;
    },
    resetChangeOwnership: (state) => {
      Object.assign(state, initialState);
    }
  }
});

export const selectOccupantInformationBackup = (state: RootState) => state.changeOwnership.occupantInformationBackup;
export const selectFormOccupantInformation = (state: RootState) => state.changeOwnership.formOccupantInformation;
export const selectEmergencyContactInfoBackup = (state: RootState) =>
  state.changeOwnership.emergencyContactInfoBackup;
export const selectFormEmergencyContactInfo = (state: RootState) =>
  state.changeOwnership.formEmergencyContactInfo;
export const selectChangeOwnershipOccupantAddressInformation = (state: RootState) => {
  return {
    address: state.changeOwnership.formOccupantInformation.address,
    addressTwo: state.changeOwnership.formOccupantInformation.addressTwo,
    city: state.changeOwnership.formOccupantInformation.city,
    state: state.changeOwnership.formOccupantInformation.state,
    postalCode: state.changeOwnership.formOccupantInformation.postalCode,
    country: state.changeOwnership.formOccupantInformation.country
  };
};
export const selectFormEmergencyContactAddressSameAsOccupant = (state: RootState) =>
  state.changeOwnership.formEmergencyContactAddressSameAsOccupant;
export const selectAchBackup = (state: RootState) => state.changeOwnership.achBackup;
export const selectAchForm = (state: RootState) => state.changeOwnership.achForm;
export const selectCreditCardBackup = (state: RootState) => state.changeOwnership.creditCardBackup;
export const selectCreditCardForm = (state: RootState) => state.changeOwnership.creditCardForm;
export const selectBillingInformationForm = (state: RootState) => state.changeOwnership.billingInformationForm;

export const {
  setOccupantInformationBackup,
  setFormOccupantInformation,
  setEmergencyContactInfoBackup,
  setFormEmergencyContactInfo,
  setFormEmergencyContactAddressSameAsOccupant,
  setEmergencyContactAddressSameAsOccupantBackup,
  setAchBackup,
  setAchForm,
  setCreditCardBackup,
  setCreditCardForm,
  setBillingInformationForm,
  setAchPaymentOptions,
  setCreditCardPaymentOptions,
  resetChangeOwnership
} = changeOwnershipSlice.actions;

export default changeOwnershipSlice.reducer;
