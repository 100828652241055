
const ROLL_UP = "Roll Up";
const SWING = "Swing";

const getUnitDoorType = (doorType: string) => {
  switch (doorType) {
    case SWING:
      return 1;
    case ROLL_UP:
      return 2;
    default:
      return 1;
  }
};

export default getUnitDoorType;
