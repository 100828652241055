import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../rootStore";
import { TaskCategoriesSliceState } from "./TaskCategoriesSliceState";
import { getAllTaskCategories } from "../../thunks/taskCategory/getAll/getAllTaskCategories";
import { APIListResponse } from "../../../models/responses/APIListResponse";
import { TaskCategory } from "../../../models/TaskCategory"; // every slice should have a state typing

export const initialState: TaskCategoriesSliceState = {
  taskCategories: [],
  taskCategoriesLoading: false,
  taskCategoriesError: ""
};

export const taskCategoriesSlice = createSlice({
  name: "taskCategories",
  initialState,
  reducers: {
    resetTaskCategoriesSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTaskCategories.pending, (state) => {
        state.taskCategoriesLoading = true;
      })
      .addCase(
        getAllTaskCategories.fulfilled,
        (state, action: PayloadAction<APIListResponse<TaskCategory[]>>) => {
          state.taskCategoriesLoading = false;
          state.taskCategories = action.payload.data;
        })
      .addCase(getAllTaskCategories.rejected, (state, action) => { // Example extarReducer action
        state.taskCategoriesLoading = false;
        state.taskCategoriesError = action.payload as string;
      });
  }
});

export const selectTaskCategories = (state: RootState) => state.taskCategories.taskCategories;
export const selectTaskCategoriesLoading = (state: RootState) => state.taskCategories.taskCategoriesLoading;

export const {
  resetTaskCategoriesSlice
} = taskCategoriesSlice.actions;

export default taskCategoriesSlice.reducer;
