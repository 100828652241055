import GlobalSettings from "src/pages/GlobalSettings/GlobalSettings";
import React from "react";
import { Route } from "react-router";

export const groupName = "global-settings";

export const GlobalSettingRoutes = (
  <>
    <Route path={groupName} element={<GlobalSettings />} />
  </>
);

export default GlobalSettingRoutes;
