import { makeStyles } from "../../../../../makeStyles";

const useStyles = makeStyles({ label: "MerchandiseDetails" })((theme) => ({
  headerCell: {
    color: theme.palette.common.black,
    background: theme.palette.background.default,
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1)
  },
  merchandiseListWrapper: {
    width: "100%"
  }
}));

export default useStyles;
