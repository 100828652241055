import moment from "moment";

export const isExpired = (expDate: string) => {
  if (!expDate) {
    return false;
  }

  const exp = moment(expDate);
  const now = moment();

  return now.diff(exp) > 0;
};
