import * as Yup from "yup";

const CreateAutomationFormValidation = Yup.object().shape({
  name: Yup.string().required("Automation Name is required")
    .max(40, "Automation Name must be less than 40 characters"),
  due: Yup.number(),
  days: Yup
    .number()
    .required("Days is required")
    .test("len", "Days can't be longer than 3 digits.", function(val) {
      if (val?.toString().length! <= 3) {
        return true;
      }
      return this.createError({ message: "Days can't be longer than 3 digits." });
    })
    .typeError("Days must be a number"),
  task_id: Yup.string(),
  fee_id: Yup.string()
});

export default CreateAutomationFormValidation;
