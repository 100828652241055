import React from "react";
import { Typography } from "@mui/material";
import useStyles from "./StatusColumn.styles";

const StatusColumn = () => {
  const { classes } = useStyles();
  return (
    <Typography className={classes.pastDue}>Past Due</Typography>
  );
};

export default StatusColumn;
