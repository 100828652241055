import { RootState } from "../../rootStore";
import { createSlice } from "@reduxjs/toolkit";
import { getAllEsignTemplates } from "../../thunks/esignTemplates/getAll/getAllEsignTemplates";

// @TODO: update & correctly type slice when relevant endpoints are made available
export const initialState: {
  buttonOptions: any
  editorContent: any
} = {
  buttonOptions: null,
  editorContent: null
};

export const froalaSlice = createSlice({
  name: "testFroala",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllEsignTemplates.fulfilled, (state, action) => {
        state.buttonOptions = {
          "{{ $occupantName }}": "Occupant Name",
          "{{ $facilityName }}": "Facility Name!!!",
          "{{ $unitAmount }}": "Unit Amount"
        };
      });
  }
});

export const selectButtonOptions = (state: RootState) => state.froala.buttonOptions;
export const selectFroalaContent = (state: RootState) => state.froala.editorContent;

export default froalaSlice.reducer;
