import React, { ReactElement } from "react";
import { selectIsTransfer, setTransferMonthlyRate } from "src/store/reducers/transferUnitSlice/transferUnitSlice";
import {
  selectSelectedUnitId,
  setQuotedRate,
  setSelectedUnit
} from "../../../../../../store/reducers/selectedUnitSlice/selectedUnitSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Unit } from "../../../../../../models/Unit";
import { setMonthlyRate } from "../../../../../../store/reducers/moveInSlice/moveInSlice";
import useStyles from "./UnitRow.styles";

interface UnitRowProps {
  unit: Unit
  unitSize: string
  price: string
}

const UnitRow: React.FC<UnitRowProps> = ({ unit, unitSize, price }: UnitRowProps): ReactElement => {
  const dispatch = useAppDispatch();
  const selectedUnitId = useAppSelector(selectSelectedUnitId);
  const isTransfer = useAppSelector(selectIsTransfer);

  const activeUnitRow = { isSelected: (selectedUnitId === unit.id) };
  const { classes } = useStyles(activeUnitRow);

  const handleRowClick = () => {
    dispatch(setSelectedUnit(unit));
    dispatch(setQuotedRate(price));
    if (isTransfer) {
      dispatch(setTransferMonthlyRate(price));
      return;
    }
    dispatch(setMonthlyRate(price));
  };

  return (
    <TableRow
      className={classes.unitRow}
      data-testid={`unit-${unit.id}-row`}
      onClick={() => handleRowClick()}
    >
      <TableCell component={"th"} scope={"unit"} data-testid={`unit-${unit.id}-number`}>{unit.unit_number}</TableCell>
      <TableCell data-testid={`unit-${unit.id}-size`}>{unitSize}</TableCell>
      <TableCell data-testid={`unit-${unit.id}-floor`}>{unit.floor}</TableCell>
      <TableCell data-testid={`unit-${unit.id}-price`}>${price}</TableCell>
      <TableCell />
      <TableCell />
    </TableRow>
  );
};

export default UnitRow;
