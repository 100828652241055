import { makeStyles } from "../../makeStyles";

const useStyles = makeStyles({ label: "Warning Modal" })((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "50rem",
    minHeight: "15rem",
    borderRadius: ".625rem .625rem .625rem .625rem",
    overflow: "hidden"
  },
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "55%",
    width: "100%"
  },
  title: {
    fontWeight: theme.typography.fontWeightBold
  },
  content: {
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
    textAlign: "center"
  },
  button: {
    color: theme.palette.primary.contrastText,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`
  },
  cancelButton: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    color: theme.palette.error.contrastText,
    marginRight: theme.spacing(2)
  }
}));

export default useStyles;
