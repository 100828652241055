import { UpdateBillingInformationContactPayload } from "./UpdateBillingInformationContactPayload";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const updateBillingContact = createAsyncThunk("updateBillingContact",
  (payload: UpdateBillingInformationContactPayload, { dispatch, getState, rejectWithValue }) => {
    const { contactId, occupantId } = payload;

    return axios.put(`/api/v1/occupants/${occupantId}/billing-contacts/${contactId}`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Billing Contact updated successfully",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        if (err.response.status === 422) {
          if (err.response.data.detail) return rejectWithValue(err.response.data.detail);

          const errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        const errorMessage = "There was an error updating the Billing Contact";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
