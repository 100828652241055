import { DisabledAutoPayPayload } from "./DisableAutoPayPayload";
import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const disabledAutoPay =
createAsyncThunk("disabledAutoPay",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const ledger_id = state.ledgers.selectedLedger?.id;
    const occupantId = state.occupant.occupant?.id;

    const body: DisabledAutoPayPayload = {
      is_active: false
    };

    return axios
      .post(`/api/v1/occupants/${occupantId}/ledgers/${ledger_id}/toggle-autopay-status`, body)
      .then((resp) => {
        dispatch(
          showSnackbar({
            message: "Ledger has been successfully un-enrolled from AutoPay",
            variant: "success"
          })
        );

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(
            showSnackbar({
              message: errorMessage,
              variant: "error"
            })
          );

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an error un-enrolling from AutoPay";

        if (err && err.response && err.response.status && err.response.status === 400) {
          errorMessage += `: ${err.response.data.detail}`;
        }

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  });
