import { Headers, WebhookSliceState } from "./WebhookSliceState";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../rootStore";
import { webhooksApi } from "src/api/endpoints/webhooks";

export const initialState: WebhookSliceState = {
  name: "",
  description: "",
  isEnabled: false,
  receivingUrl: "",
  authType: 0,
  authUsername: "",
  authPassword: "",
  eventTrigger: "",
  authToken: "",
  headers: [{
    key: "",
    value: ""
  }],
  webhookLoading: false,
  confirmDeleteModalOpen: false,
  currentWebhookId: null
};

export const webhookSlice = createSlice({
  name: "webhook",
  initialState,
  reducers: {
    setWebhookName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
      sessionStorage.setItem("webhookName", action.payload);
    },
    setWebhookDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setWebhookEnabled: (state, action) => {
      state.isEnabled = action.payload;
    },
    setWebhookReceivingUrl: (state, action: PayloadAction<string>) => {
      state.receivingUrl = action.payload;
    },
    setWebhookAuthType: (state, action: PayloadAction<number>) => {
      state.authType = action.payload;
    },
    setWebhookAuthUsername: (state, action: PayloadAction<string>) => {
      state.authUsername = action.payload;
    },
    setWebhookAuthPassword: (state, action: PayloadAction<string>) => {
      state.authPassword = action.payload;
    },
    setWebhookHeaders: (state, action: PayloadAction<Headers[]>) => {
      state.headers = action.payload;
    },
    setWebhookEventTrigger: (state, action) => {
      state.eventTrigger = action.payload;
    },
    setWebhookToken: (state, action: PayloadAction<string>) => {
      state.authToken = action.payload;
    },
    setConfirmDeleteWebhookModalOpen: (state, action: PayloadAction<boolean>) => {
      state.confirmDeleteModalOpen = action.payload;
    },
    setCurrentWebhookId: (state, action) => {
      state.currentWebhookId = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(webhooksApi.endpoints.getWebhook.matchPending, (state) => {
        state.webhookLoading = true;
      })
      .addMatcher(webhooksApi.endpoints.getWebhook.matchFulfilled, (state) => {
        state.webhookLoading = false;
      })
      .addMatcher(webhooksApi.endpoints.getWebhook.matchRejected, (state) => {
        state.webhookLoading = false;
      });
  }
});

export const selectWebhookName = (state: RootState) => state.webhookInformation.name;
export const selectWebhookDescription = (state: RootState) => state.webhookInformation.description;
export const selectDeleteWebhookModalOpen = (state: RootState) => state.webhookInformation.confirmDeleteModalOpen;
export const selectWebhookReceivingUrl = (state: RootState) => state.webhookInformation.receivingUrl;
export const selectCurrentWebhookId = (state: RootState) => state.webhookInformation.currentWebhookId;
export const selectEventTrigger = (state: RootState) => state.webhookInformation.eventTrigger;
export const selectWebhookAuthType = (state: RootState) => state.webhookInformation.authType;
export const selectWebhookAuthUsername = (state: RootState) => state.webhookInformation.authUsername;
export const selectWebhookAuthPassword = (state: RootState) => state.webhookInformation.authPassword;
export const selectWebhookHeaders = (state: RootState) => state.webhookInformation.headers;
export const selectWebhookLoading = (state: RootState) => state.webhookInformation.webhookLoading;
export const selectWebhookToken = (state: RootState) => state.webhookInformation.authToken;
export const selectWebhookEnabled = (state: RootState) => state.webhookInformation.isEnabled;

export const {
  setWebhookName,
  setWebhookDescription,
  setWebhookReceivingUrl,
  setWebhookAuthType,
  setWebhookEventTrigger,
  setWebhookAuthUsername,
  setWebhookAuthPassword,
  setCurrentWebhookId,
  setWebhookHeaders,
  setWebhookToken,
  setWebhookEnabled,
  setConfirmDeleteWebhookModalOpen
} = webhookSlice.actions;

export default webhookSlice.reducer;
