import { Button, Grid, Modal, Paper, SxProps, Theme, Typography } from "@mui/material";
import { Close, Info } from "@mui/icons-material";
import React, { ReactElement } from "react";

import useStyles from "./WarningModal.styles";

interface WarningModalProps {
  open: boolean;
  title: string;
  buttonLabel: string;
  onButtonClick: Function;
  onCloseClick: Function;
  children: ReactElement;
  loading?: boolean;
  sx?: SxProps<Theme> | undefined
}

const WarningModal: React.FC<WarningModalProps> = ({
  open,
  title,
  buttonLabel,
  onButtonClick,
  onCloseClick,
  children,
  loading = false,
  sx
}) => {
  const { classes } = useStyles();

  return (
    <Modal open={open} data-testid={"warning-modal"} onClose={(event, reason) => onCloseClick(event, reason)}
      className={classes.modal} >
      <Grid className={classes.container} component={Paper} elevation={0}>
        <Grid className={classes.header} p={2}>
          <Grid className={classes.closeIconContainer}>
            <Close className={classes.closeIcon} onClick={() => onCloseClick()} />
          </Grid>
          <Info className={classes.headerIcon} />
          <Typography variant={"h4"}
            className={classes.title}
            sx={sx}
          >
            {title}
          </Typography>
        </Grid>

        <Grid className={classes.content}>
          {children}
          <Grid mt={2}>
            <Button
              className={classes.cancelButton}
              variant={"outlined"}
              onClick={() => onCloseClick()}
            >
              Cancel
            </Button>
            <Button
              className={classes.button}
              variant={"outlined"}
              data-testid={"button-confirm-modal"}
              onClick={() => onButtonClick()}
              disabled={loading}
            >
              {buttonLabel}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default WarningModal;
