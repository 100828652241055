import FullCalendar from "@fullcalendar/react";
import React, { RefObject } from "react";

export const customButtons = (ref: RefObject<FullCalendar>) => {
  return {
    listWeek: {
      text: "Tasks",
      click: () => {
        if (ref.current) {
          (ref.current as FullCalendar).getApi().changeView("listWeek");
        }
      }
    }
  };
};
