import { ArrowBack, Delete, Description, Payment } from "@mui/icons-material";
import { Box, Button, Divider, Grid, IconButton, Modal, Paper, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import moment, { Moment } from "moment";
import {
  selectLedgersModalOpen,
  setLedgersModalOpen,
  setSelectedLedger
} from "src/store/reducers/ledgersSlice/ledgersSlice";
import {
  setIsFromPayNow,
  setLedgerPayments,
  setMakeAPaymentPeriods,
  setOutStandingBalance,
  setSelectedLedgers,
  setTotalPaymentDue
} from "src/store/reducers/makeAPaymentSlice/makeAPaymentSlice";
import { setOpenRemoveUnitFromAuctionModal, setSelectedAuction } from "src/store/reducers/auctionSlice/auctionSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { useGetAuctionQuery, useUpdateAuctionMutation } from "src/api/endpoints/auctions";
import { useNavigate, useParams } from "react-router";

import { Breadcrumb } from "src/models/Breadcrumb";
import Form from "src/components/form/Form";
import { GridColDef } from "@mui/x-data-grid";
import LedgerView from "src/pages/Occupants/EditOccupant/LedgerView/LedgerView";
import LoadingSpinner from "src/components/ui/LoadingSpinner/LoadingSpinner";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import RemoveAuctionModal from "./RemoveAuctionModal/RemoveAuctionModal";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import clsx from "clsx";
import { formatDate } from "src/utils/__dateAndTimeUtils__/formatDate/formatDate";
import formatPhone from "src/utils/formatPhone/formatPhone";
import { getMaxAmount } from "src/store/thunks/makeAPayment/getMaxAmount/getMaxAmount";
import { getOneLedger } from "src/store/thunks/ledger/getOneLedger/getOneLedger";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { setSelectedOccupant } from "src/store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import useStyles from "./EditAuction.styles";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Collections"
  },
  {
    name: "Manage Auctions",
    bold: true
  }
];

const EditAuction = () => {
  const dispatch = useAppDispatch();
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const { classes } = useStyles();
  const { auctionId } = useParams<{ auctionId: string }>();
  const navigate = useNavigate();
  const ledgersModalOpen = useAppSelector(selectLedgersModalOpen);

  if (!auctionId) {
    navigate(-1);
  }

  const {
    data: auction,
    isError,
    isLoading
  } = useGetAuctionQuery(
    { facilityId: String(selectedFacility.id), auctionId: auctionId! },
    {
      skip: !selectedFacility,
      refetchOnMountOrArgChange: true
    }
  );

  if (isError) {
    dispatch(
      showSnackbar({
        message: "There was an error getting the auctions.",
        variant: "error"
      })
    );
  }

  const [updateAuction] = useUpdateAuctionMutation();

  const pageHeader = <PageHeader title={"Edit Auction Schedule"} breadcrumbs={breadcrumbs} />;

  if (isLoading || !auction) {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <LoadingSpinner></LoadingSpinner>
      </ViewWrapper>
    );
  }

  const redirectToMakeAPayment = async() => {
    await dispatch(setSelectedLedgers([auction.ledger]));
    const currentBalance = auction.ledger.current_balance ?? 0;
    const ledgerPayment = {
      id: auction.ledger.id as number,
      payment_amount: String(currentBalance.toFixed(2))
    };

    dispatch(setSelectedOccupant(auction.ledger?.occupant!));

    dispatch(setOutStandingBalance(auction.ledger.current_balance));
    dispatch(setTotalPaymentDue(auction.ledger.current_balance));
    dispatch(setLedgerPayments([ledgerPayment]));

    dispatch(setIsFromPayNow(true));
    dispatch(setMakeAPaymentPeriods(0));
    await dispatch(getOneLedger({ occupantId: auction.ledger.occupant_id, ledgerId: auction.ledger.id }));
    await dispatch(getMaxAmount({ occupantId: auction.ledger.occupant_id, ledgerId: auction.ledger.id }));
    navigate("/collections/make-a-payment/review-and-apply-payment");
  };

  const handleOpenRemoveModal = () => {
    dispatch(setSelectedLedger(auction.ledger));
    dispatch(setSelectedAuction(auction));
    dispatch(setOpenRemoveUnitFromAuctionModal(true));
  };

  const handleSubmit = async(values: { auctionDate: Moment; auctionTime: Moment }) => {
    await updateAuction({ ...values, facilityId: auction.facility_id, auctionId: auction.id })
      .then(() => {
        dispatch(
          showSnackbar({
            message: "Auction successfully updated.",
            variant: "success"
          })
        );
      })
      .catch(() => {
        dispatch(
          showSnackbar({
            message: "There was an error updating the auction.",
            variant: "error"
          })
        );
      });
  };

  return (
    <ViewWrapper pageHeader={pageHeader}>
      <Form
        onSubmit={handleSubmit}
        defaultValues={{
          auctionTime: moment(auction.auction_time, "HH:mmZ"),
          auctionDate: moment(auction.auction_date)
        }}
      >
        <Button
          data-testid={"back-button"}
          variant={"text"}
          className={clsx(classes.textButton)}
          startIcon={<ArrowBack />}
          onClick={() => navigate(-1)}
        >
          GO BACK TO MANAGE AUCTIONS
        </Button>
        <Grid display={"flex"} flexDirection={"column"} item
          xs={12} container mt={2}>
          <Grid className={classes.tableHeader} container justifyContent={"space-between"}>
            <Typography variant={"h6"}>{auction?.ledger?.occupant?.name}</Typography>
            <Grid display={"flex"} item alignItems={"center"}
              gap={2}>
              <Typography>{auction?.ledger?.occupant?.email}</Typography>
              <Typography>{formatPhone(auction?.ledger?.occupant?.phone_primary)}</Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.table}>
            <Box>
              <Typography fontWeight={"bold"}>Unit</Typography>
              <Typography>{auction?.ledger?.unit?.unit_number}</Typography>
            </Box>
            <Box>
              <Typography fontWeight={"bold"}>Last Payment Date</Typography>
              <Typography>{formatDate(auction.last_payment_date)}</Typography>
            </Box>
            <Box>
              <Typography fontWeight={"bold"}>Days Past Due</Typography>
              <Typography>{auction?.ledger?.delinquent_days ?? 0}</Typography>
            </Box>
            <Box>
              <Typography fontWeight={"bold"}>Amount Owed</Typography>
              <Typography color={"error"}>${auction?.ledger?.current_balance}</Typography>
            </Box>
            <Box>
              <Typography fontWeight={"bold"}>Auction Date</Typography>
              <Typography>{formatDate(auction.auction_date)}</Typography>
            </Box>
            <Box>
              <Typography fontWeight={"bold"}>Actions</Typography>
              <Grid
                data-testid={"render-details-button"}
                container
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Tooltip title={"View Ledger"}>
                  <IconButton size={"small"} onClick={() => {
                    dispatch(setSelectedLedger(auction.ledger));
                    dispatch(setLedgersModalOpen(true));
                  }}>
                    <Description />
                  </IconButton>
                </Tooltip>
                <Tooltip title={"Make a Payment"}>
                  <IconButton size={"small"} onClick={redirectToMakeAPayment}>
                    <Payment />
                  </IconButton>
                </Tooltip>
                <Tooltip title={"Remove Unit from Auction"}>
                  <IconButton size={"small"} onClick={handleOpenRemoveModal}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Box>
          </Grid>

          <Grid mt={2} component={Paper} elevation={0}
            p={4}>
            <Grid item display={"flex"} alignItems={"center"}>
              <Grid mr={"5px"} item>
                <Typography mr={"5px"} className={classes.bodyHeaderText}>
                  Auction Details
                </Typography>
              </Grid>
              <Grid container>
                <Divider className={classes.bodyHeaderDivider} />
              </Grid>
            </Grid>

            <Grid container mt={2}>
              <Grid container item gap={2}>
                {/* Disabling until we have this value in our app */}
                {/* <Grid item container gap={12}>
                  <Typography fontWeight={"bold"} noWrap gutterBottom>
                    Lien Notice Date
                  </Typography>
                  <Typography>MM/DD/YYYY</Typography>
                </Grid> */}
                <Grid item container gap={2}>
                  <Form.DatePicker label={"Scheduled Auction Date"} name={"auctionDate"} bold />
                  <Form.TimePicker label={"Scheduled Auction Time"} name={"auctionTime"} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"} mt={4}>
            <Button variant={"outlined"} className={clsx(classes.removeButton)} onClick={handleOpenRemoveModal}>
              REMOVE
            </Button>
            <Grid display={"flex"} item gap={2}>
              <Form.Cancel onClick={() => navigate(-1)} label={"CANCEL"} />
              <Form.Submit label={"SAVE"} />
            </Grid>
          </Grid>
        </Grid>
      </Form>
      <RemoveAuctionModal />
      <Modal
        open={ledgersModalOpen}
        onClose={() => dispatch(setLedgersModalOpen(false))}
        aria-labelledby={"Modal Component"}
        aria-describedby={"A Modal component."}
        className={clsx(classes.modal, classes.ledgerModal)}
      >
        {/* this fragment here gets rid of a strange forwardRef console error locally. */}
        <>
          <LedgerView />
        </>
      </Modal>
    </ViewWrapper>
  );
};

export default EditAuction;
