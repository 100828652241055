import { Ledger } from "src/models/Ledger";
import { Occupant } from "src/models/Occupant";
import moment from "moment";

export const getActiveTransferrableLedgers = (ledgers?: Ledger[]) => {
  if (!ledgers) {
    return [];
  }

  if (ledgers?.length > 0) {
    return ledgers?.filter((ledger) => ledger.is_active)
      .filter((ledger) => !ledger.moved_out_at)
      .filter((ledger) => moment().diff(moment(ledger.moved_in_at), "days") < 30 || !ledger.transfer_from_id);
  }

  return [];
};

export const getOccupantWithActiveLedgers = (occupants: Occupant[]): Occupant[] => {
  return occupants.map((occupant) => {
    return {
      ...occupant,
      ledgers: getActiveTransferrableLedgers(occupant.ledgers)
    };
  });
};
