import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Remove Auction Modal" })((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  container: {
    width: "30rem",
    borderRadius: "1rem",
    padding: theme.spacing(4)
  },
  content: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center"
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  button: {
    borderColor: theme.palette.primary.contrastText,
    color: theme.palette.common.black,
    minWidth: theme.spacing(15),
    minHeight: theme.spacing(7),
    textTransform: "capitalize",
    fontWeight: "normal"
  },
  cancelButton: {
    minWidth: theme.spacing(15),
    minHeight: theme.spacing(7),
    borderColor: theme.palette.error.contrastText,
    color: theme.palette.common.black,
    textTransform: "none",
    fontWeight: "normal"
  }
}));

export default useStyles;
