import ChartOfAccounts from "src/pages/Accounting/ChartOfAccounts/ChartOfAccounts";
import CreateRevenueClass from "src/pages/Accounting/RevenueClasses/CreateRevenueClass/CreateRevenueClass";
import EditRevenueClass from "src/pages/Accounting/RevenueClasses/EditRevenueClass/EditRevenueClass";
import React from "react";
import RevenueClasses from "src/pages/Accounting/RevenueClasses/RevenueClasses";
import { Route } from "react-router";

export const groupName = "accounting";

const AccountingRoutes = (
  <>
    <Route path={`${groupName}/revenue-classes`} element={<RevenueClasses />} />
    <Route path={`${groupName}/revenue-classes/create`} element={<CreateRevenueClass />} />
    <Route path={`${groupName}/revenue-classes/:id/edit`} element={<EditRevenueClass />} />
    <Route path={`${groupName}/chart-of-accounts`} element={<ChartOfAccounts />} />
  </>
);

export default AccountingRoutes;
