import { RootState } from "../../rootStore";
import UserSliceState from "./userSliceState";
import { createSlice } from "@reduxjs/toolkit";

export const initialState: UserSliceState = {
  showCurrentPassword: false,
  showNewPassword: false,
  showConfirmPassword: false
};

export const userReducerSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    showCurrentPassword: (state: UserSliceState) => {
      state.showCurrentPassword = !state.showCurrentPassword;
    },
    showNewPassword: (state: UserSliceState) => {
      state.showNewPassword = !state.showNewPassword;
    },
    showConfirmPassword: (state: UserSliceState) => {
      state.showConfirmPassword = !state.showConfirmPassword;
    }
  }
});

//selectors here not in use yet
export const userSelector = (state: RootState) => state.user;

export const {
  showConfirmPassword,
  showCurrentPassword,
  showNewPassword
} = userReducerSlice.actions;

export default userReducerSlice.reducer;
