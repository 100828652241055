import { makeStyles } from "../../../../../makeStyles";

const useStyles = makeStyles({ label: "HeadingDisplay" })((theme) => ({
  root: {},
  button: {
    background: theme.palette.primary.contrastText,
    color: theme.palette.common.white,
    textTransform: "uppercase"
  },
  saveButton: {
    transform: "translateY(10px)"
  }
}));

export default useStyles;
