import { RootState } from "../../../rootStore";
import { uploadInstance as axios } from "../../../../api/uploadInstance/uploadInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const importUnits = createAsyncThunk(
  "importUnits",
  (csv: Blob, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const facilityId = state.selectedFacility.selectedFacility!.id;
    const id = state.productType.productTypeId;

    const formData = new FormData();
    formData.append("file", csv);

    return axios.post(`/api/v1/facilities/${facilityId}/product-types/${id}/units/import`, formData)
      .then(() => {
        dispatch(showSnackbar({
          message: "Units successfully imported",
          variant: "success"
        }));
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an error importing the Units";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
