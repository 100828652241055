import { Delete, Edit, Payment } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import {
  setIsFromPayNow,
  setLedgerPayments,
  setMakeAPaymentPeriods,
  setOutStandingBalance,
  setSelectedLedgers,
  setTotalPaymentDue
} from "src/store/reducers/makeAPaymentSlice/makeAPaymentSlice";
import { setOpenRemoveUnitFromAuctionModal, setSelectedAuction } from "src/store/reducers/auctionSlice/auctionSlice";
import { Auction } from "src/models/Auction";
import { GridColDef } from "@mui/x-data-grid";
import React from "react";
import { formattedAmount } from "src/utils/formattedAmount/formattedAmount";
import { getMaxAmount } from "src/store/thunks/makeAPayment/getMaxAmount/getMaxAmount";
import { getOneLedger } from "src/store/thunks/ledger/getOneLedger/getOneLedger";
import { nameParser } from "src/utils/nameParser/nameParser";
import { setSelectedLedger } from "src/store/reducers/ledgersSlice/ledgersSlice";
import { setSelectedOccupant } from "src/store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { useAppDispatch } from "src/store/hooks";
import { useNavigate } from "react-router";
import moment from "moment";

const RenderActionButtons = (row: Auction) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const redirectToMakeAPayment = async() => {
    await dispatch(setSelectedLedgers([row.ledger]));
    const currentBalance = row.ledger.current_balance ?? 0;
    const ledgerPayment = {
      id: row.ledger.id as number,
      payment_amount: String(currentBalance.toFixed(2))
    };

    dispatch(setSelectedOccupant(row.ledger?.occupant!));

    dispatch(setOutStandingBalance(row.ledger.current_balance));
    dispatch(setTotalPaymentDue(row.ledger.current_balance));
    dispatch(setLedgerPayments([ledgerPayment]));

    dispatch(setIsFromPayNow(true));
    dispatch(setMakeAPaymentPeriods(0));
    await dispatch(getOneLedger({ occupantId: row.ledger.occupant_id, ledgerId: row.ledger.id }));
    await dispatch(getMaxAmount({ occupantId: row.ledger.occupant_id, ledgerId: row.ledger.id }));
    navigate("/collections/make-a-payment/review-and-apply-payment");
  };

  const handleOpenRemoveModal = () => {
    dispatch(setSelectedLedger(row.ledger));
    dispatch(setSelectedAuction(row));
    dispatch(setOpenRemoveUnitFromAuctionModal(true));
  };

  return (
    <Grid
      data-testid={"render-details-button"}
      container
      direction={"row"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Tooltip title={"Edit"}>
        <IconButton size={"small"} onClick={() => navigate(`/collections/auctions/${row.id}`)}>
          <Edit />
        </IconButton>
      </Tooltip>

      <Tooltip title={"Make a Payment"}>
        <IconButton size={"small"} onClick={redirectToMakeAPayment}>
          <Payment />
        </IconButton>
      </Tooltip>
      <Tooltip title={"Remove Unit from Auction"}>
        <IconButton size={"small"} onClick={handleOpenRemoveModal}>
          <Delete />
        </IconButton>
      </Tooltip>
    </Grid>
  );
};
export const manageAuctionsColumns: GridColDef[] = [
  {
    field: "ledger.unit.unit_number",
    renderHeader: () => (
      <Tooltip title={"Unit"}>
        <Typography>Unit</Typography>
      </Tooltip>
    ),
    minWidth: 100,
    valueGetter: (params: { row: any }) => params.row.ledger.unit.unit_number,
    sortable: true,
    flex: 1
  },
  {
    field: "ledger.occupant.first_name",
    renderHeader: () => (
      <Tooltip title={"Occupant"}>
        <Typography>Occupant</Typography>
      </Tooltip>
    ),
    minWidth: 120,
    valueGetter: ({ row }: { row: Auction }) =>
      nameParser(row.ledger.occupant?.first_name!, row.ledger.occupant?.last_name!, row.ledger.occupant?.middle_name),
    sortable: true,
    flex: 1
  },
  {
    field: "last_payment_date",
    renderHeader: () => (
      <Tooltip title={"Last Payment Date"}>
        <Typography>Last Payment Date</Typography>
      </Tooltip>
    ),
    minWidth: 150,
    align: "center",
    headerAlign: "center",
    valueGetter: ({ row }: { row: Auction }) => row.last_payment_date,
    sortable: false,
    flex: 1
  },
  {
    field: "ledger.delinquent_days",
    renderHeader: () => (
      <Tooltip title={"Days Past Due"}>
        <Typography>Days Past Due</Typography>
      </Tooltip>
    ),
    minWidth: 120,
    align: "center",
    headerAlign: "center",
    valueGetter: ({ row }: { row: Auction }) => row.ledger.delinquent_days || "N/A",
    sortable: true,
    flex: 1
  },
  {
    field: "ledger.current_balance",
    renderHeader: () => (
      <Tooltip title={"Amount Owed"}>
        <Typography>Amount Owed</Typography>
      </Tooltip>
    ),
    align: "right",
    headerAlign: "right",
    minWidth: 100,
    flex: 1,
    valueGetter: ({ row }: { row: Auction }) => formattedAmount("Fixed Amount", row.current_balance) || "$00.00",
    sortable: true
  },
  {
    field: "auction_date",
    renderHeader: () => (
      <Tooltip title={"Auction Date"}>
        <Typography>Auction Date</Typography>
      </Tooltip>
    ),
    minWidth: 110,
    sortable: true,
    flex: 1,
    align: "center",
    headerAlign: "center"
  },
  {
    field: "auction_time",
    renderHeader: () => (
      <Tooltip title={"Auction Time"}>
        <Typography>Auction Time</Typography>
      </Tooltip>
    ),
    minWidth: 110,
    sortable: true,
    align: "center",
    headerAlign: "center",
    valueGetter: ({ row }) => moment(row.auction_time, "HH:mmZ").format("LT")
  },
  {
    field: "actionIcons",
    renderHeader: () => (
      <Tooltip title={"Actions"}>
        <Typography>Actions</Typography>
      </Tooltip>
    ),
    sortable: false,
    minWidth: 150,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) => RenderActionButtons(row)
  }
];
