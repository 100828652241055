import * as Yup from "yup";

import { dateWarning } from "src/utils/commonWarnings";
import moment from "moment";
import { today } from "src/utils/__dateAndTimeUtils__/today";

const validationSchema = Yup.object().shape({
  selectedPaymentInstrument: Yup.number().when(
    "paymentMethod",
    (paymentMethod: number, schema: any) => {
      if (paymentMethod === 1) {
        return schema.test(
          "selectedPaymentInstrument",
          "Payment Instrument is required",
          (value: number) => Number(value) > 0
        );
      }
      return schema;
    }
  )
});

export default validationSchema;
