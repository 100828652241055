import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

interface RemoveExternalTppPlanPayload {
    occupant_id: string;
    external_tpp_plan_id: string;
}

export const removeExternalTppPlan = createAsyncThunk(
  "removeExternalTppPlan",
  ({ occupant_id, external_tpp_plan_id }: RemoveExternalTppPlanPayload, { dispatch, getState, rejectWithValue }) => {
    return axios.delete(`/api/v1/occupants/${occupant_id}/external-tpp-plans/${external_tpp_plan_id}`)
      .then((resp) => {
        dispatch(showSnackbar({
          message: `External Tpp Plan disabled successfully`,
          variant: "success"
        }));

        return resp.data;
      })
      .catch(() => {
        const errorMessage = "There was an error disabling the External Tpp Plan";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
