import {
  Box,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  TextField,
  TextFieldProps,
  Typography
} from "@mui/material";
import { CommonProductTypesPayload, useGetCommonProductTypesQuery } from "src/api/endpoints/productTypes";
import React, { ChangeEvent, FormEvent, ReactElement, useState } from "react";
import moment, { Moment as MomentType } from "moment";
import {
  selectAppliedPromotionDuration,
  selectAppliedPromotionFacilities,
  selectAppliedPromotionMonth,
  selectChannelRestriction,
  selectCommonProductTypes,
  selectCommonUnitSizes,
  selectCommonUnitSizesRequest,
  selectHasFetchedPromotionInformation,
  selectIsUnitGroupOccupancy,
  selectOccupancyComparison,
  selectOccupancyFirstValue,
  selectOccupancySecondValue,
  selectOccupancyTarget,
  selectProductTypesRestriction,
  selectPromotionAmount,
  selectPromotionDescription,
  selectPromotionEnd,
  selectPromotionIndefiniteEnd,
  selectPromotionName,
  selectPromotionStart,
  selectPromotionSwitches,
  selectPromotionType,
  selectTotalUnitRestrictionComparison,
  selectTotalUnitRestrictionSecondValue,
  selectTotalUnitRestrictionValue,
  selectUnitAreaRestrictionComparison,
  selectUnitAreaRestrictionSecondValue,
  selectUnitAreaRestrictionValue,
  selectUnitSizes,
  setAppliedPromotionDuration,
  setAppliedPromotionFacilities,
  setAppliedPromotionMonth,
  setChannelRestriction,
  setIsUnitGroupOccupancy,
  setOccupancyComparison,
  setOccupancyFirstValue,
  setOccupancySecondValue,
  setOccupancyTarget,
  setProductTypesRestriction,
  setPromotionAmount,
  setPromotionDescription,
  setPromotionEnd,
  setPromotionIndefiniteEnd,
  setPromotionName,
  setPromotionStart,
  setPromotionSwitches,
  setPromotionType,
  setTotalUnitRestrictionComparison,
  setTotalUnitRestrictionSecondValue,
  setTotalUnitRestrictionValue,
  setUnitAreaRestrictionComparison,
  setUnitAreaRestrictionSecondValue,
  setUnitAreaRestrictionValue,
  setUnitSizes
} from "../../../store/reducers/promotionInformationSlice/promotionInformationSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { DatePicker } from "@mui/x-date-pickers";
import { Facility } from "../../../models/Facility";
import PMSCheckbox from "src/components/ui/PMSCheckbox/PMSCheckbox";
import PMSMultiSelect from "../../../components/ui/PMSMultiSelect/PMSMultiSelect";
import PMSSelect from "../../../components/ui/PMSSelect/PMSSelect";
import PMSSwitch from "src/components/ui/PMSSwitch/PMSSwitch";
import createPromotionValidation from "./promotionFormValidation";
import { formatSuffixedNumber } from "../../../utils/formatSuffixedNumber/formatSuffixedNumber";
import { inputError } from "../../../utils/showInputError/showInputError";
import { selectFacilities } from "../../../store/reducers/facilitiesSlice/facilitiesSlice";
import { useFormik } from "formik";
import { useGetCommonUnitSizesQuery } from "src/api/endpoints/units";
import useStyles from "./PromotionForm.styles";

const today = moment.utc();

export const selectedFacilitiesDisplay = (facilityIds: (number | "all")[], facilities: Facility[]) => {
  if (!facilityIds.length) {
    return "- Select Facility -";
  }

  if (facilityIds.includes("all")) {
    return "All Facilities";
  }

  return facilityIds
    .map((facilityId) => {
      return facilities.find((currentFacility) => currentFacility.id === facilityId)?.legal_name;
    })
    .join(", ");
};

export const selectedChannelRestrictions = (channels: (string | "all")[]) => {
  if (!channels.length) {
    return "- Select Option -";
  }

  if (channels.includes("all")) {
    return "All";
  }

  return channels.join(", ");
};

export const selectedProductTypes = (productTypesIds: number[], productTypes: CommonProductTypesPayload[]) => {
  if (!productTypesIds.length) {
    return "- Select Option -";
  }

  return productTypesIds
    .map((productTypeId) => {
      return productTypes.find((productType) => productType.temp_id === productTypeId)?.name;
    })
    .join(", ");
};

export const selectedUnitSizes = (currentUnitSizes: string[]) => {
  if (!currentUnitSizes.length) {
    return "- Select Option -";
  }

  return currentUnitSizes.join(", ");
};

export const dateDisplay = (date: string) => {
  if (date) {
    // @ts-ignore
    return moment(date);
  }

  return null;
};

interface PromotionFormProps {
  submitHandler: Function;
  isEdit?: boolean;
}

const PromotionForm: React.FC<PromotionFormProps> = ({
  submitHandler,
  isEdit = false
}): ReactElement => {
  const dispatch = useAppDispatch();
  const promotionSwitches = useAppSelector(selectPromotionSwitches);
  const facilities = useAppSelector(selectFacilities);
  const appliedPromotionFacilities = useAppSelector(selectAppliedPromotionFacilities);
  const promotionName = useAppSelector(selectPromotionName);
  const promotionAmount = useAppSelector(selectPromotionAmount);
  const promotionType = useAppSelector(selectPromotionType);
  const promotionDescription = useAppSelector(selectPromotionDescription);
  const appliedPromotionMonth = useAppSelector(selectAppliedPromotionMonth);
  const appliedPromotionDuration = useAppSelector(selectAppliedPromotionDuration);
  const promotionStart = useAppSelector(selectPromotionStart);
  const promotionEnd = useAppSelector(selectPromotionEnd);
  const promotionIndefiniteEnd = useAppSelector(selectPromotionIndefiniteEnd);
  const channel_restriction = useAppSelector(selectChannelRestriction);
  const occupancy_comparison = useAppSelector(selectOccupancyComparison);
  const occupancy_first_value = useAppSelector(selectOccupancyFirstValue);
  const occupancy_second_value = useAppSelector(selectOccupancySecondValue);
  const occupancyTarget = useAppSelector(selectOccupancyTarget);
  const total_unit_restriction_comparison = useAppSelector(selectTotalUnitRestrictionComparison);
  const total_unit_restriction_value = useAppSelector(selectTotalUnitRestrictionValue);
  const total_unit_restriction_second_value = useAppSelector(selectTotalUnitRestrictionSecondValue);
  const unit_area_restriction_comparison = useAppSelector(selectUnitAreaRestrictionComparison);
  const unit_area_restriction_value = useAppSelector(selectUnitAreaRestrictionValue);
  const unit_area_restriction_second_value = useAppSelector(selectUnitAreaRestrictionSecondValue);
  const productTypesRestriction = useAppSelector(selectProductTypesRestriction);
  const unit_sizes = useAppSelector(selectUnitSizes);
  const commonProductTypes = useAppSelector(selectCommonProductTypes);
  const isUnitGroupOccupancy = useAppSelector(selectIsUnitGroupOccupancy);

  const [facilitiesSelected, setFacilitiesSelected] = useState<(number | "all")[]>(appliedPromotionFacilities);
  const [
    channelRestrictionsSelected,
    setChannelRestrictionsSelected
  ] = useState<(string | "all")[]>(channel_restriction);
  const commonSizesRequest = useAppSelector(selectCommonUnitSizesRequest);
  const commonUnitSizes = useAppSelector(selectCommonUnitSizes);

  const hasFetchedInfo = useAppSelector(selectHasFetchedPromotionInformation);
  useGetCommonProductTypesQuery(appliedPromotionFacilities, {
    refetchOnMountOrArgChange: true,
    skip: isEdit ? !hasFetchedInfo : false
  });

  useGetCommonUnitSizesQuery(commonSizesRequest,
    {
      refetchOnMountOrArgChange: true,
      skip: isEdit ? !hasFetchedInfo || (hasFetchedInfo && !commonProductTypes.length) : false
    });

  const formik = useFormik({
    initialValues: {
      promotionName: promotionName,
      promotionAmount: promotionAmount,
      promotionType: promotionType,
      promotionDescription: promotionDescription,
      appliedPromotionFacilities: appliedPromotionFacilities,
      appliedPromotionMonth: appliedPromotionMonth,
      appliedPromotionDuration: appliedPromotionDuration,
      promotionStart: promotionStart,
      promotionEnd: promotionEnd,
      promotionIndefiniteEnd: promotionIndefiniteEnd,
      channel_restriction: channel_restriction,
      occupancy_comparison,
      occupancy_first_value,
      occupancy_second_value,
      total_unit_restriction_comparison,
      total_unit_restriction_value,
      total_unit_restriction_second_value,
      unit_area_restriction_comparison,
      unit_area_restriction_value,
      unit_area_restriction_second_value,
      occupancyTarget,
      unit_sizes,
      product_types: productTypesRestriction
    },
    validationSchema: createPromotionValidation,
    validateOnChange: false,
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: () => submitHandler()
  });

  const { values, errors, touched } = formik;

  const { classes } = useStyles({
    promoStartError: inputError("promotionStart", touched, errors).error,
    promoEndError: inputError("promotionEnd", touched, errors).error
  });

  const updateForm = (fieldName: string, fieldValue?: string | number | boolean | null | []): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handlePromotionNameOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setPromotionName(fieldValue));
  };

  const handlePromotionAmountOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setPromotionAmount(fieldValue));
  };

  const handlePromotionTypeOnChange = (fieldName: string, fieldValue: string) => {
    const parsedValue = parseInt(fieldValue);
    updateForm(fieldName, fieldValue);
    dispatch(setPromotionType(parsedValue));
  };

  const handlePromotionDescriptionOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setPromotionDescription(fieldValue));
  };

  const handleAppliedPromotionFacilitiesOnChange = (event: any) => {
    const {
      target: { value }
    } = event;

    if (facilitiesSelected.includes("all") && !value?.includes("all")) {
      setFacilitiesSelected([]);
      dispatch(setAppliedPromotionFacilities([]));
      updateForm("appliedPromotionFacilities", []);
      return;
    }

    setFacilitiesSelected(value);
    dispatch(setAppliedPromotionFacilities(value));
    updateForm("appliedPromotionFacilities", value);

    dispatch(setPromotionSwitches({
      ...promotionSwitches, product_type_restriction: false, unit_size_restriction: false, unit_area_restriction: false
    }));

    dispatch(setProductTypesRestriction([]));
    updateForm("product_types", []);
    updateForm("unit_area_restriction_value", null);
    dispatch(setUnitAreaRestrictionValue(null));
    updateForm("unit_area_restriction_second_value", null);
    dispatch(setUnitAreaRestrictionSecondValue(null));
  };

  const handleAppliedPromotionMonthOnChange = (fieldName: string, fieldValue: string) => {
    const parsedValue = parseInt(fieldValue);
    updateForm(fieldName, fieldValue);
    dispatch(setAppliedPromotionMonth(parsedValue));
  };

  const handleAppliedPromotionDurationOnChange = (fieldName: string, fieldValue: string) => {
    const parsedValue = parseInt(fieldValue);
    updateForm(fieldName, fieldValue);
    dispatch(setAppliedPromotionDuration(parsedValue));
  };

  const handlePromotionStartDateOnChange = (fieldName: string, fieldValue: MomentType) => {
    const parsedDate = fieldValue.format("YYYY-MM-DD");
    updateForm(fieldName, parsedDate);
    dispatch(setPromotionStart(parsedDate));
  };

  const handlePromotionEndDateOnChange = (fieldName: string, fieldValue: MomentType) => {
    const parsedDate = fieldValue.format("YYYY-MM-DD");
    updateForm(fieldName, parsedDate);
    dispatch(setPromotionEnd(parsedDate));
  };

  const handlePromotionIndefiniteEndOnChange = (fieldName: string, fieldValue: boolean) => {
    updateForm(fieldName, "indefinite");
    dispatch(setPromotionIndefiniteEnd(fieldValue));
  };

  const handleChannelRestriction = (event: any) => {
    const {
      target: { value }
    } = event;

    if (channelRestrictionsSelected.includes("all") && !value?.includes("all")) {
      setChannelRestrictionsSelected([]);
      dispatch(setChannelRestriction([]));
      updateForm("channel_restriction", []);
      return;
    }

    setChannelRestrictionsSelected(value);
    dispatch(setChannelRestriction(value));
    updateForm("channel_restriction", value);
  };

  const handleOccupancyFirstValue = (fieldValue: string) => {
    updateForm("occupancy_first_value", fieldValue);
    dispatch(setOccupancyFirstValue(fieldValue));
  };

  const handleOccupancySecondValue = (fieldValue: string) => {
    updateForm("occupancy_second_value", fieldValue);
    dispatch(setOccupancySecondValue(fieldValue));
  };

  const handleOccupancyComparison = (fieldValue: string) => {
    updateForm("occupancy_comparison", fieldValue);
    dispatch(setOccupancyComparison(fieldValue));
    handleOccupancySecondValue("");
  };

  const handleOccupancyTargetChange = (fieldValue: string) => {
    updateForm("occupancyTarget", fieldValue);
    dispatch(setOccupancyTarget(fieldValue));

    const flag = fieldValue === "unit_group";

    dispatch(setIsUnitGroupOccupancy(flag));

    if (flag) {
      dispatch(setPromotionSwitches({
        channel_restriction: false,
        occupancy: true,
        total_unit_restriction: false,
        unit_area_restriction: false,
        unit_size_restriction: false,
        product_type_restriction: false
      }));
    }
  };

  const handleTotalUnitRestrictionComparison = (fieldValue: string) => {
    updateForm("total_unit_restriction_comparison", fieldValue);
    dispatch(setTotalUnitRestrictionComparison(fieldValue));
  };

  const handleTotalUnitRestrictionValue = (fieldValue: number) => {
    updateForm("total_unit_restriction_value", fieldValue);
    dispatch(setTotalUnitRestrictionValue(fieldValue));
  };

  const handleTotalUnitRestrictionSecondValue = (fieldValue: number) => {
    updateForm("total_unit_restriction_second_value", fieldValue);
    dispatch(setTotalUnitRestrictionSecondValue(fieldValue));
  };

  const handleUnitAreaRestrictionComparison = (fieldValue: string) => {
    updateForm("unit_area_restriction_comparison", fieldValue);
    dispatch(setUnitAreaRestrictionComparison(fieldValue));
  };

  const handleUnitAreaRestrictionValue = (fieldValue: number) => {
    updateForm("unit_area_restriction_value", fieldValue);
    dispatch(setUnitAreaRestrictionValue(fieldValue));
  };

  const handleUnitAreaRestrictionSecondValue = (fieldValue: number) => {
    updateForm("unit_area_restriction_second_value", fieldValue);
    dispatch(setUnitAreaRestrictionSecondValue(fieldValue));
  };

  const handleProductTypesRestriction = (event: any) => {
    const {
      target: { value }
    } = event;
    dispatch(setProductTypesRestriction(value));
    updateForm("product_types", value);
  };

  const handleUnitSizes = (event: any) => {
    const {
      target: { value }
    } = event;

    dispatch(setUnitSizes(value));
    updateForm("unit_sizes", value);
  };

  const availableFacilitiesDisplay = facilities.map((facility) => (
    <MenuItem
      data-testid={`available-facility-${facility.id}`}
      key={facility.id}
      value={facility.id}
      disabled={appliedPromotionFacilities.includes("all")}
    >
      <PMSCheckbox
        changeHandler={() => null}
        isChecked={appliedPromotionFacilities.includes(facility.id) || appliedPromotionFacilities.includes("all")}
      />
      <ListItemText primary={facility.facility_id} />
    </MenuItem>
  ));

  const availableProductTypesDisplay = commonProductTypes.map((productType) => (
    <MenuItem data-testid={`available-product-type-${productType.temp_id}`}
      key={productType.temp_id} value={productType.temp_id}>
      <PMSCheckbox isChecked={values.product_types.includes(productType.temp_id)} />
      <ListItemText primary={productType.name} />
    </MenuItem>
  ));
  const availableUnitSizesDisplay = commonUnitSizes.map((unitSize) => (
    <MenuItem key={`${unitSize.width}x${unitSize.length}`} value={`${unitSize.width}x${unitSize.length}`}>
      <PMSCheckbox isChecked={unit_sizes.includes(`${unitSize.width}x${unitSize.length}`)} />
      <ListItemText primary={`${unitSize.width}' x ${unitSize.length}'`} />
    </MenuItem>
  ));

  const promotionAppliedMonthsDisplay = Array.from(Array(24).keys()).map((item) => {
    let monthText = "Month";

    if (item > 0) {
      monthText = `${monthText}s`;
    }

    return (
      <option key={item} value={item === 0 ? -1 : item}>
        {item > 0 ? `${item} ${monthText}` : `Non-Expiring`}
      </option>
    );
  });

  const promotionStartsMonthsDisplay = Array.from(Array(24).keys()).map((item) => {
    return (
      <option key={item} value={item + 1}>
        {formatSuffixedNumber(item + 1)} Month
      </option>
    );
  });

  return (
    <Grid
      container
      xs={12}
      flexDirection={"row"}
      id={"promotion-form"}
      data-testid={"promotion-form"}
      component={"form"}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        formik.handleSubmit();
      }}
      gap={{ md: 1, lg: 2 }}
      mt={3}
    >
      <Grid container component={Paper} xs={6}
        p={3} justifyContent={"space-between"}>
        <Grid item container direction={"column"}
          xs={12} spacing={3}>
          <Grid item container>
            <Box className={classes.header}>
              <Typography variant={"h6"}>New Promotion</Typography>
            </Box>
            <Grid item xs={12}>
              <InputLabel htmlFor={"promotion-name"}>Promotion Name</InputLabel>
              <TextField
                id={"promotion-name"}
                fullWidth
                placeholder={"- Name -"}
                inputProps={{ "data-testid": "promotion-name" }}
                name={"promotionName"}
                disabled={isEdit}
                value={values.promotionName}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handlePromotionNameOnChange("promotionName", e.target.value)
                }
                error={inputError("promotionName", touched, errors).error}
                helperText={inputError("promotionName", touched, errors).helperText}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12} md={2}>
              <InputLabel htmlFor={"promotion-amount"}>Amount</InputLabel>
              <TextField
                id={"promotion-amount"}
                fullWidth
                placeholder={"- Amount -"}
                className={classes.noArrowInput}
                type={"number"}
                inputProps={{ "data-testid": "promotion-amount" }}
                name={"promotionAmount"}
                disabled={isEdit}
                value={values.promotionAmount}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handlePromotionAmountOnChange("promotionAmount", e.target.value)
                }
                error={inputError("promotionAmount", touched, errors).error}
                helperText={inputError("promotionAmount", touched, errors).helperText}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PMSSelect
                id={"promotion-type"}
                label={"Type"}
                name={"promotionType"}
                disabled={isEdit}
                value={values.promotionType}
                changeHandler={(e) => handlePromotionTypeOnChange("promotionType", e.target.value as string)}
                error={inputError("promotionType", touched, errors).error}
                helperText={inputError("promotionType", touched, errors).helperText}
              >
                <option value={0} disabled>
                  - Select Type -
                </option>
                <option value={2}>Fixed Dollar</option>
                <option value={1}>Percentage</option>
                <option value={3}>Apply Rent</option>
              </PMSSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <PMSMultiSelect
                id={"promotion-facilities"}
                label={"Apply to Facilities"}
                value={values.appliedPromotionFacilities}
                changeHandler={handleAppliedPromotionFacilitiesOnChange}
                error={inputError("appliedPromotionFacilities", touched, errors).error}
                helperText={inputError("appliedPromotionFacilities", touched, errors).helperText}
                renderValue={(facilityIds) => selectedFacilitiesDisplay(facilityIds, facilities)}
                name={"appliedPromotionFacilities"}
              >
                <MenuItem disabled>- Select Facility -</MenuItem>
                <MenuItem value={"all"}>
                  <PMSCheckbox
                    data-testid={"promotion-name"}
                    changeHandler={() => null}
                    isChecked={appliedPromotionFacilities.includes("all" as never)}
                  />
                  <ListItemText primary={"Select All Facilities"} />
                </MenuItem>
                {availableFacilitiesDisplay}
              </PMSMultiSelect>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <PMSSelect
                  id={"promotion-applied-month"}
                  label={"Applied Month"}
                  name={"appliedPromotionMonth"}
                  disabled={isEdit}
                  value={values.appliedPromotionMonth}
                  changeHandler={(e) =>
                    handleAppliedPromotionMonthOnChange("appliedPromotionMonth", e.target.value as string)
                  }
                  error={inputError("appliedPromotionMonth", touched, errors).error}
                  helperText={inputError("appliedPromotionMonth", touched, errors).helperText}
                >
                  <option value={0} disabled>
                    - Select Month -
                  </option>
                  {promotionStartsMonthsDisplay}
                </PMSSelect>
              </Grid>
              <Grid item xs={6}>
                <PMSSelect
                  id={"promotion-duration"}
                  label={"Duration"}
                  name={"appliedPromotionDuration"}
                  disabled={isEdit}
                  value={values.appliedPromotionDuration}
                  changeHandler={(e) =>
                    handleAppliedPromotionDurationOnChange("appliedPromotionDuration", e.target.value as string)
                  }
                  error={inputError("appliedPromotionDuration", touched, errors).error}
                  helperText={inputError("appliedPromotionDuration", touched, errors).helperText}
                >
                  <option value={0} disabled>
                    - Select Duration -
                  </option>
                  {promotionAppliedMonthsDisplay}
                </PMSSelect>
              </Grid>
            </Grid>
            <Grid item container spacing={2}
              alignItems={"center"}>
              <Grid item xs={6} md={3}>
                <InputLabel htmlFor={"promotion-start-date"}>Promotion Start Date</InputLabel>
                <DatePicker
                  renderInput={(props: TextFieldProps) => (
                    <TextField
                      {...props}
                      id={"start-date"}
                      fullWidth
                      name={"promotionStart"}
                      disabled={isEdit}
                      error={inputError("promotionStart", touched, errors).error}
                      helperText={inputError("promotionStart", touched, errors).helperText}
                    />
                  )}
                  OpenPickerButtonProps={{ "aria-label": "Select Start Date" }}
                  componentsProps={{
                    leftArrowButton: { "aria-label": "Move Backward" },
                    rightArrowButton: { "aria-label": "Move Backward" }
                  }}
                  minDate={today}
                  inputFormat={"MM/DD/yyyy"}
                  data-testid={"promotion-start"}
                  value={promotionStart}
                  onChange={(value: MomentType | null, keyboardInputValue?: string | undefined) =>
                    value && handlePromotionStartDateOnChange("promotionStart", value)
                  }
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <InputLabel htmlFor={"promotion-end-date"}>Promotion End Date</InputLabel>
                <DatePicker
                  renderInput={(props: TextFieldProps) => (
                    <TextField
                      {...props}
                      id={"end-date"}
                      fullWidth
                      name={"promotionEnd"}
                      disabled={isEdit}
                      error={inputError("promotionEnd", touched, errors).error}
                      helperText={inputError("promotionEnd", touched, errors).helperText}
                    />
                  )}
                  OpenPickerButtonProps={{ "aria-label": "Select End Date" }}
                  componentsProps={{
                    leftArrowButton: { "aria-label": "Move Backward" },
                    rightArrowButton: { "aria-label": "Move Backward" }
                  }}
                  minDate={today}
                  inputFormat={"MM/DD/yyyy"}
                  data-testid={"promotion-end"}
                  value={promotionEnd}
                  onChange={(value: MomentType | null, keyboardInputValue?: string | undefined) =>
                    value && handlePromotionEndDateOnChange("promotionEnd", value)
                  }
                />
              </Grid>
              <Grid item mt={3}>
                <PMSCheckbox
                  smallText
                  size={"small"}
                  data-testid={"promotion-end-date-checkbox"}
                  name={"promotion-end-date-checkbox"}
                  disabled={isEdit}
                  label={"No Active End Date"}
                  changeHandler={(e) => handlePromotionIndefiniteEndOnChange("promotionEnd", e.target.checked)}
                  isChecked={promotionIndefiniteEnd}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor={"promotion-description"}>Description</InputLabel>
              <TextField
                id={"promotion-description"}
                fullWidth
                multiline
                rows={5}
                inputProps={{
                  "data-testid": "promotion-description",
                  maxLength: 500
                }}
                name={"promotionDescription"}
                value={values.promotionDescription}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handlePromotionDescriptionOnChange("promotionDescription", e.target.value)
                }
                error={inputError("promotionDescription", touched, errors).error}
                helperText={inputError("promotionDescription", touched, errors).helperText}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container component={Paper} xs={5.8}>
        <Grid item container direction={"column"}
          xs={12} p={3}>
          <Box className={classes.header}>
            <Typography variant={"h6"}>Rule Restrictions</Typography>
          </Box>
          <PMSSwitch
            name={"channel-restriction"}
            size={"medium"}
            smallText
            isChecked={promotionSwitches.channel_restriction}
            isDisabled={isUnitGroupOccupancy}
            label={"Channel Restriction"}
            changeHandler={(e) => dispatch(setPromotionSwitches({
              ...promotionSwitches, channel_restriction: !promotionSwitches.channel_restriction
            }))}
          />
          {promotionSwitches.channel_restriction && (
            <Box width={0.5} ml={4}>
              <PMSMultiSelect
                id={"channel_restriction"}
                value={values.channel_restriction}
                changeHandler={handleChannelRestriction}
                renderValue={selectedChannelRestrictions}
              >
                <MenuItem value={"all"}>
                  <PMSCheckbox isChecked={channel_restriction.includes("all" as never)} />
                  <ListItemText primary={"All"} />
                </MenuItem>
                <MenuItem value={"walk-in"} disabled={channel_restriction.includes("all" as never)}>
                  <PMSCheckbox
                    isChecked={
                      channelRestrictionsSelected.includes("walk-in") || channel_restriction.includes("all" as never)
                    }
                  />
                  <ListItemText primary={"Walk-in"} />
                </MenuItem>
                <MenuItem value={"kiosk"} disabled={channel_restriction.includes("all" as never)}>
                  <PMSCheckbox
                    isChecked={
                      channelRestrictionsSelected.includes("kiosk") || channel_restriction.includes("all" as never)
                    }
                  />
                  <ListItemText primary={"Kiosk"} />
                </MenuItem>
                <MenuItem value={"call-center"} disabled={channel_restriction.includes("all" as never)}>
                  <PMSCheckbox
                    isChecked={
                      channelRestrictionsSelected.includes("call-center") ||
                      channel_restriction.includes("all" as never)
                    }
                  />
                  <ListItemText primary={"Call-center"} />
                </MenuItem>
                <MenuItem value={"website"} disabled={channel_restriction.includes("all" as never)}>
                  <PMSCheckbox
                    isChecked={
                      channelRestrictionsSelected.includes("website") ||
                      channel_restriction.includes("all" as never)
                    }
                  />
                  <ListItemText primary={"Website"} />
                </MenuItem>
              </PMSMultiSelect>
            </Box>
          )}
          <Box mt={4} mb={4}>
            <PMSSwitch
              name={"occupancy"}
              size={"medium"}
              smallText
              isChecked={promotionSwitches.occupancy}
              label={"Occupancy"}
              changeHandler={(e) => {
                handleOccupancyComparison("greater than");
                handleOccupancyTargetChange("facility");
                handleOccupancyFirstValue("");
                handleOccupancySecondValue("");
                dispatch(setPromotionSwitches({ ...promotionSwitches, occupancy: !promotionSwitches.occupancy }));
              }}
            />
            {promotionSwitches.occupancy && (
              <Box display={"flex"} alignItems={"center"} ml={4}>
                <Typography>If </Typography>
                <Box width={0.2} mx={1}>
                  <PMSSelect
                    id={"occupancyTarget"}
                    name={"occupancyTarget"}
                    value={values.occupancyTarget}
                    changeHandler={(e) => handleOccupancyTargetChange(e.target.value as string)}
                  >
                    <option value={"facility"}>Facility</option>
                    <option value={"unit_group"}>Unit Group</option>
                  </PMSSelect>
                </Box>
                <Typography>Occupancy is</Typography>
                <Box width={0.3} ml={1}>
                  <PMSSelect
                    id={"occupancy_comparison"}
                    name={"occupancy_comparison"}
                    value={values.occupancy_comparison}
                    changeHandler={(e) => handleOccupancyComparison(e.target.value as string)}
                  >
                    <option value={"greater than"}>greater than</option>
                    <option value={"less than"}>less than</option>
                    <option value={"between"}>between</option>
                  </PMSSelect>
                </Box>
                <Box width={0.2} display={"flex"} alignItems={"center"}
                  ml={1} mr={1}>
                  <TextField
                    id={"occupancy_first_value"}
                    fullWidth
                    placeholder={"#"}
                    inputProps={{ "data-testid": "occupancy_first_value" }}
                    name={"occupancy_first_value"}
                    value={values.occupancy_first_value}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleOccupancyFirstValue(e.target.value)}
                  />
                </Box>
                %
                {values.occupancy_comparison === "between" && (
                  <>
                    <Box ml={1} mr={1}>
                      <Typography>and</Typography>
                    </Box>
                    <Box width={0.2} display={"flex"} alignItems={"center"}>
                      <Box display={"flex"} alignItems={"center"} mr={1}>
                        <TextField
                          id={"occupancy_second_value"}
                          fullWidth
                          placeholder={"#"}
                          inputProps={{ "data-testid": "occupancy_second_value" }}
                          name={"occupancy_second_value"}
                          value={values.occupancy_second_value}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => handleOccupancySecondValue(e.target.value)}
                        />
                      </Box>
                      %
                    </Box>
                  </>
                )}
              </Box>
            )}
          </Box>
          <PMSSwitch
            name={"total-unit-restriction"}
            size={"medium"}
            smallText
            isChecked={promotionSwitches.total_unit_restriction}
            isDisabled={isUnitGroupOccupancy}
            label={"Total Available Units"}
            changeHandler={(e) => {
              handleTotalUnitRestrictionComparison("greater than");
              dispatch(setPromotionSwitches({
                ...promotionSwitches, total_unit_restriction: !promotionSwitches.total_unit_restriction
              }));
            }}
          />
          {promotionSwitches.total_unit_restriction && (
            <Box display={"flex"} alignItems={"center"} ml={4}>
              <Typography>If Total Available Units are</Typography>
              <Box width={0.3} ml={1}>
                <PMSSelect
                  id={"total_unit_restriction_comparison"}
                  name={"total_unit_restriction_comparison"}
                  value={values.total_unit_restriction_comparison}
                  changeHandler={(e) => handleTotalUnitRestrictionComparison(e.target.value as string)}
                >
                  <option value={"greater than"}>greater than</option>
                  <option value={"less than"}>less than</option>
                  <option value={"between"}>between</option>
                </PMSSelect>
              </Box>

              <Box width={0.2} display={"flex"} alignItems={"center"}
                ml={1}>
                <TextField
                  id={"total_unit_restriction_value"}
                  fullWidth
                  placeholder={"#"}
                  inputProps={{ "data-testid": "total_unit_restriction_value" }}
                  name={"total_unit_restriction_value"}
                  value={values.total_unit_restriction_value}
                  type={"number"}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleTotalUnitRestrictionValue(+e.target.value)}
                />
              </Box>

              {values.total_unit_restriction_comparison === "between" && (
              <>
                <Box ml={1} mr={1}>
                  <Typography>and</Typography>
                </Box>
                <Box width={0.2} display={"flex"} alignItems={"center"}>
                  <Box display={"flex"} alignItems={"center"} mr={1}>
                    <TextField
                      id={"total_unit_restriction_second_value"}
                      fullWidth
                      placeholder={"#"}
                      name={"total_unit_restriction_second_value"}
                      value={values.total_unit_restriction_second_value}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleTotalUnitRestrictionSecondValue(Number(e.target.value))}
                        />
                  </Box>

                </Box>
              </>
              )}
            </Box>
          )}
          <PMSSwitch
            name={"product-type-restriction"}
            size={"medium"}
            smallText
            isDisabled={isUnitGroupOccupancy || !commonProductTypes.length}
            isChecked={promotionSwitches.product_type_restriction}
            label={"Product Type Restriction"}
            changeHandler={(e) =>
              dispatch(setPromotionSwitches({
                ...promotionSwitches,
                product_type_restriction: !promotionSwitches.product_type_restriction
              }))
              }
            />
          {promotionSwitches.product_type_restriction && (
          <Box width={0.9} ml={4} border={0}>
            <PMSMultiSelect fullWidth={false}
              id={"product_type_restriction"}
              value={values.product_types}
              changeHandler={handleProductTypesRestriction}
              renderValue={(productTypeIds) => selectedProductTypes(productTypeIds, commonProductTypes)}
            >
              {availableProductTypesDisplay}
            </PMSMultiSelect>

            <Box mt={3} mb={4}>
              <PMSSwitch
                name={"unit-size-restriction"}
                size={"medium"}
                isDisabled={!commonUnitSizes.length || !promotionSwitches.product_type_restriction}
                smallText
                isChecked={promotionSwitches.unit_size_restriction}
                label={"Unit Size Restriction"}
                changeHandler={(e) => {
                  dispatch(setUnitSizes([]));
                  dispatch(setUnitAreaRestrictionValue(null));
                  dispatch(setUnitAreaRestrictionSecondValue(null));
                  updateForm("unit_sizes", []);
                  dispatch(setPromotionSwitches({
                    ...promotionSwitches,
                    unit_size_restriction: !promotionSwitches.unit_size_restriction,
                    unit_area_restriction: false
                  }));
                }}
              />
              {promotionSwitches.unit_size_restriction && (
                <Box width={1} ml={4}>
                  <PMSMultiSelect fullWidth={false}
                    id={"unit_sizes"}
                    value={values.unit_sizes}
                    changeHandler={handleUnitSizes}
                    renderValue={selectedUnitSizes}
                  >
                    {availableUnitSizesDisplay}
                  </PMSMultiSelect>
                </Box>
              )}
            </Box>

            <PMSSwitch
              name={"unit-area-restriction"}
              size={"medium"}
              isDisabled={!commonUnitSizes.length || !promotionSwitches.product_type_restriction}
              smallText
              isChecked={promotionSwitches.unit_area_restriction}
              label={"Unit Area Restriction"}
              changeHandler={(e) => {
                dispatch(setUnitSizes([]));
                handleUnitAreaRestrictionComparison("greater than");
                dispatch(setPromotionSwitches({
                  ...promotionSwitches,
                  unit_area_restriction: !promotionSwitches.unit_area_restriction,
                  unit_size_restriction: false
                }));
              }}
            />
            {promotionSwitches.unit_area_restriction && (
              <Box display={"flex"} alignItems={"center"} ml={4}>
                <Typography>If Unit Area are</Typography>
                <Box width={0.3} ml={1}>
                  <PMSSelect
                    id={"unit_area_restriction_comparison"}
                    name={"unit_area_restriction_comparison"}
                    value={values.unit_area_restriction_comparison}
                    changeHandler={(e) => handleUnitAreaRestrictionComparison(e.target.value as string)}
                  >
                    <option value={"greater than"}>greater than</option>
                    <option value={"less than"}>less than</option>
                    <option value={"between"}>between</option>
                  </PMSSelect>
                </Box>

                <Box width={0.2} display={"flex"} alignItems={"center"}
                  ml={1}>
                  <TextField
                    id={"unit_area_restriction_value"}
                    fullWidth
                    placeholder={"#"}
                    inputProps={{ "data-testid": "unit_area_restriction_value" }}
                    name={"unit_area_restriction_value"}
                    value={values.unit_area_restriction_value}
                    type={"number"}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleUnitAreaRestrictionValue(+e.target.value)}
                  />
                </Box>

                {values.unit_area_restriction_comparison === "between" && (
                  <>
                    <Box ml={1} mr={1}>
                      <Typography>and</Typography>
                    </Box>
                    <Box width={0.2} display={"flex"} alignItems={"center"}>
                      <Box display={"flex"} alignItems={"center"} mr={1}>
                        <TextField
                          id={"unit_area_restriction_second_value"}
                          fullWidth
                          placeholder={"#"}
                          name={"unit_area_restriction_second_value"}
                          value={values.unit_area_restriction_second_value}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleUnitAreaRestrictionSecondValue(Number(e.target.value))}
                        />
                      </Box>

                    </Box>
                  </>
                )}
              </Box>
            )}

          </Box>
          )}

        </Grid>
      </Grid>
    </Grid>
  );
};

export default PromotionForm;
