import { AutoPayTypes as AutoPayTypesEnum } from "src/enums/AutoPayTypes";
import { EnrollAutoPayPayload } from "./EnrollAutoPayPayload";
import { PAYMENT_METHODS }
  from "src/pages/Occupants/EditOccupant/EditOccupantTabs/RentedUnits/AutomaticPaymentsModal/AutomaticPaymentsModal";
import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const enrollAutoPay =
createAsyncThunk("enrollAutoPay",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const ledger_id = state.ledgers.selectedLedger?.id;
    const occupantId = state.occupant.occupant?.id;
    const automaticPaymentMethod = state.automaticPayments.automaticPaymentMethod;

    const body: EnrollAutoPayPayload = {

    };

    if (automaticPaymentMethod === PAYMENT_METHODS.ADD_NEW_PAYMENT_METHOD) {
      body.type = AutoPayTypesEnum.CreditCard;
      body.card_holder_name = state.automaticPayments.automaticPaymentsCreditCardForm?.name;
      body.card_number = state.automaticPayments.automaticPaymentsCreditCardForm?.number.split(" ").join("");
      body.card_processor = state.automaticPayments.automaticPaymentsCreditCardForm?.type.replace(/-/g, " ");
      body.expiration_month = state.automaticPayments.automaticPaymentsCreditCardForm?.expiration.slice(0, 2);
      body.expiration_year = `20${state.automaticPayments.automaticPaymentsCreditCardForm?.expiration.substring(2)}`;
      body.card_security_code = state.automaticPayments.automaticPaymentsCreditCardForm?.cvv;
    } else {
      body.payment_instrument_id = state.automaticPayments.selectedPaymentInstrument!;
    }

    return axios
      .post(`/api/v1/occupants/${occupantId}/ledgers/${ledger_id}/enroll-autopay`, body)
      .then((resp) => {
        dispatch(
          showSnackbar({
            message: "Ledger has been successfully enrolled in AutoPay",
            variant: "success"
          })
        );

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(
            showSnackbar({
              message: errorMessage,
              variant: "error"
            })
          );

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an error enrolling in AutoPay";

        if (err && err.response && err.response.status && err.response.status === 400) {
          errorMessage += `: ${err.response.data.detail}`;
        }

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  });
