import { RootState } from "src/store/rootStore";
import { axiosInstance as axios } from "src/api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";

export const disableUnit = createAsyncThunk(
  "disablePromotion",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const facilityId = state.selectedFacility.selectedFacility!.id;
    const unitId = state.unit.unit?.id;

    return axios.post(`/api/v1/facilities/${facilityId}/units/${unitId}/enabled`, { is_active: false })
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Unit successfully disabled.",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = err.response.data.detail;

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = `There was an error disabling this unit.`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
