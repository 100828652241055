import { EmergencyContactSliceState } from "./EmergencyContactSliceState"; // every slice should have a state typing
import { RootState } from "../../rootStore";
import { createSlice } from "@reduxjs/toolkit";

export const initialState: EmergencyContactSliceState = {
  emergencyContact: null,
  emergencyContactLoading: false,
  emergencyContactError: ""
};

export const emergencyContactSlice = createSlice({
  name: "emergencyContact",
  initialState,
  reducers: {
    resetEmergencyContactSlice: (state) => {
      Object.assign(state, initialState);
    }
  }
});

export const selectEmergencyContact = (state: RootState) => state.emergencyContact.emergencyContact;
export const selectECLoading = (state: RootState) => state.emergencyContact.emergencyContactLoading;

export const {
  resetEmergencyContactSlice
} = emergencyContactSlice.actions;

export default emergencyContactSlice.reducer;
