import { Box, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";
import Paper from "@mui/material/Paper";
import useStyles from "./ItemManagementLayout.styles";

interface ItemManagementLayoutProps {
  children?: ReactNode;
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  filters?: ReactNode;
  headerButton?: ReactNode;
  footerButtons?: ReactNode;
  loading?: boolean;
  titleContainerClassName?: string;
  searchBar?: ReactNode;
  noMarginTop?: boolean;
  exportExcel?: ReactNode;
  noMarginFooter?: boolean;
}

const ItemManagementLayout: React.FC<ItemManagementLayoutProps> = ({
  children,
  noMarginTop = false,
  title,
  subtitle,
  filters,
  headerButton,
  footerButtons,
  searchBar,
  loading = false,
  titleContainerClassName = "",
  exportExcel,
  noMarginFooter = false
}: ItemManagementLayoutProps): ReactElement => {
  const { classes } = useStyles();

  const loadingSpinner = (
    <Grid my={5} container alignItems={"center"}
      justifyContent={"center"} data-testid={"item-loading"}>
      <CircularProgress size={50} />
    </Grid>
  );

  const mainDisplay = (
    <Grid item container direction={"column"}
      xs={12}>
      <Grid item xs={12}>
        {children}
      </Grid>
      {footerButtons && (
        <Grid
          item
          xs={12}
          mt={noMarginFooter ? 0 : 8}
          mb={1}
          container
          justifyContent={"end"}
          data-testid={"item-footer-buttons"}
          className={classes.footerButtons}
        >
          {footerButtons}
        </Grid>
      )}
    </Grid>
  );

  return (
    <>
      <Grid
        mt={noMarginTop ? 0 : 3}
        p={3}
        component={Paper}
        elevation={0}
        variant={"outlined"}
        container
        direction={"column"}
      >
        <Grid item container alignItems={"center"}
          justifyContent={"space-between"}>
          <Grid
            item
            direction={"row"}
            container
            alignItems={"center"}
            justifyContent={"space-between"}
            xs={filters ? 9 : "auto"}
            className={titleContainerClassName}
          >
            <Grid item component={Typography} mr={2}
              xs={"auto"} data-testid={"title-text"} variant={"h5"}>
              {title}
            </Grid>
            {subtitle && (
              <>
                <br></br>
                <Grid pt={1} item xs={12}
                  data-testid={"subtitle-text"}>
                  {subtitle}
                </Grid>
              </>
            )}
            {filters && (
              <Grid item xs={"auto"} data-testid={"item-filters"}>
                {filters}
              </Grid>
            )}
            {headerButton && (
              <Grid item data-testid={"item-header-button"}>
                {headerButton}
              </Grid>
            )}
          </Grid>
          <Box display={"flex"}>
            {exportExcel && (
              <Grid item data-testid={"item-header-export-excel"}>
                {exportExcel}
              </Grid>
            )}
            {searchBar && (
              <Grid item data-testid={"item-header-searchbar"}>
                {searchBar}
              </Grid>
            )}
          </Box>
        </Grid>
        {children && <Divider className={classes.divider} />}
        {loading ? loadingSpinner : mainDisplay}
      </Grid>
    </>
  );
};

export default ItemManagementLayout;
