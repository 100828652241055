/* eslint-disable max-len */
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { Grid, IconButton, TextField } from "@mui/material";
import React, { FC, ReactNode, useState } from "react";
import { setUpdateMerchandiseForRefundQuantity } from "src/store/reducers/merchandiseForRefundSlice/merchandiseForRefundSlice";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { useAppDispatch } from "src/store/hooks";
import useStyles from "./QuantityIncrementor.styles";

interface QuantityIncrementorProps {
  merchandiseId: number
  minValue?: number,
  maxValue: number
}

const QuantityIncrementor: FC<QuantityIncrementorProps> = ({ merchandiseId, minValue = 0, maxValue = 0 }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const [selectedValue, setSelectedValue] = useState(0);

  const handleSetQuantity = (val: number) => {
    if (val > maxValue) {
      dispatch(
        showSnackbar({
          message: `Maximum available quantity: ${maxValue}`,
          variant: "error"
        })
      );

      return;
    }

    if (val < minValue) {
      dispatch(
        showSnackbar({
          message: `Minimum available quantity: ${minValue}`,
          variant: "error"
        })
      );

      return;
    }

    setSelectedValue(val);
    dispatch(setUpdateMerchandiseForRefundQuantity({ id: merchandiseId, quantity: val }));
  };

  const quantityFab = (iconButton: ReactNode) => (
    <Grid
      item
      container
      justifyItems={"center"}
      xs={"auto"}
    >
      {iconButton}
    </Grid>
  );

  return (
    <>
      {quantityFab(
        <IconButton
          aria-label={"decrement button"}
          onClick={() => handleSetQuantity((selectedValue - 1))}
          className={classes.quantityButton}>
          <RemoveCircle />
        </IconButton>
      )}
      <Grid item xs={5}>
        <TextField
          size={"small"}
          variant={"outlined"}
          value={selectedValue}
          inputProps={{ "data-testid": "quantity-input" }}
          type={"number"}
          onChange={(e) => handleSetQuantity(+e.target.value)}
        />
      </Grid>
      {quantityFab(
        <IconButton
          aria-label={"increment button"}
          onClick={() => handleSetQuantity((selectedValue + 1))}
          className={classes.quantityButton}
        >
          <AddCircle />
        </IconButton>
      )}
    </>
  );
};

export default QuantityIncrementor;
