import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputLabel,
  Modal,
  Paper,
  TextField,
  Typography
} from "@mui/material";
import React, { FC, SyntheticEvent } from "react";
import {
  resetNotesSlice,
  selectNotes,
  selectNotesLoading
} from "src/store/reducers/notesSlice/notesSlice";
import {
  selectNoteModalUnitId,
  selectNotesModalOpen,
  setNotesModalOpen
} from "src/store/reducers/unitsSliceNew/unitsSliceNew";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import AppNote from "src/components/note/Note";
import NoteType from "src/enums/NoteType";
import { createNote } from "src/store/thunks/note/createNote/createNote";
import { inputError } from "src/utils/showInputError/showInputError";
import notesModalValidation from "./notesModalValidation";
import { useFormik } from "formik";
import useStyles from "./NotesModal.styles";

interface NotesModalProps { }

const NotesModal: FC<NotesModalProps> = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const notesModalOpen = useAppSelector(selectNotesModalOpen);
  const notesLoading = useAppSelector(selectNotesLoading);
  const notes = useAppSelector(selectNotes);
  const unitId = useAppSelector(selectNoteModalUnitId);

  const formik = useFormik({
    initialValues: {
      description: ""
    },
    validationSchema: notesModalValidation,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      if (unitId) {
        dispatch(createNote({
          id: unitId,
          type: NoteType.unit,
          description: values.description
        }));
      }

      formik.resetForm();
    }
  });

  const handleCancel = () => {
    dispatch(resetNotesSlice());
    dispatch(setNotesModalOpen(false));
    formik.resetForm();
  };

  const { touched, errors, values, handleChange, handleSubmit } = formik;

  return (
    <Modal
      onClose={handleCancel}
      open={notesModalOpen}
      className={classes.root}
    >
      <Grid
        container
        p={4}
        className={classes.root}
        component={Paper}
        elevation={0}
      >
        <Grid item xs={12}>
          <Typography gutterBottom variant={"h4"}>Unit Notes</Typography>
        </Grid>
        <Grid pb={1} item xs={12}>
          <Divider/>
        </Grid>
        <Grid
          component={"form"}
          id={"notes-modal"}
          item
          xs={12} mt={1}>
          <InputLabel htmlFor={"note-description"}>New note description</InputLabel>
          <TextField
            id={"note-description"}
            multiline
            rows={4}
            fullWidth
            name={"description"}
            onChange={handleChange}
            inputProps={{
              maxLength: 500
            }}
            error={inputError("description", touched, errors).error}
            helperText={inputError("description", touched, errors).helperText}
            value={values.description} />
        </Grid>
        <Grid
          container
          p={2}
          spacing={2}
          item
          xs={12}
          justifyContent={"flex-end"}
        >
          <Grid item>
            <Button
              onClick={handleCancel}
              variant={"text"}
              color={"error"}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.buttonBase}
              type={"submit"}
              onClick={(e:SyntheticEvent) => {
                e.preventDefault();
                e.stopPropagation();
                handleSubmit();
              }}
              form={"notes-modal"}
              variant={"contained"}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          rowGap={2}
          mt={2}
          maxHeight={350}
          overflow={"auto"}
          >
          {notesLoading ? <CircularProgress size={"large"}/> : null}
          {notes.map((note, index) => <AppNote key={index} {...note} />)}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default NotesModal;
