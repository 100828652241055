import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  Switch,
  Typography
} from "@mui/material";
import React, { ReactElement, useEffect } from "react";
import {
  addTransferUnitInsurancePlan,
  clearTransferUnitInsurancePlan,
  selectIsTransfer,
  selectTransferUnitInsurancePlan
} from "src/store/reducers/transferUnitSlice/transferUnitSlice";
import {
  selectExternalTPPModalOpen,
  selectExternalTppPlanActive,
  setExternalTPPModalOpen,
  setExternalTppPlanActive,
  setIsEditExternalPlan,
  setSelectedExternalTppPlan
} from "src/store/reducers/externalTppPlanSlice/externalTppPlanSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { Edit } from "@mui/icons-material";
import ItemSelector from "../ItemSelector/ItemSelector";
import { formattedAmount } from "src/utils/formattedAmount/formattedAmount";
import { getTransferUnitCost } from "src/store/thunks/transfer/transferCost/getTransferUnitCost";
import { selectActiveInsurancePlans } from "src/store/reducers/insurancePlansSlice/insurancePlansSlice";
import useStyles from "./InsurancePlansList.styles";
import { selectSelectedOccupant } from "src/store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { selectSelectedLedgerToTransfer } from "src/store/reducers/ledgersSlice/ledgersSlice";

const InsurancePlans: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const plans = useAppSelector(selectActiveInsurancePlans);
  const moveInInsurancePlan = useAppSelector(selectTransferUnitInsurancePlan);
  const isTransfer = useAppSelector(selectIsTransfer);
  const hasExternalTpp = useAppSelector(selectExternalTppPlanActive);
  const externalTppModalOpen = useAppSelector(selectExternalTPPModalOpen);
  const ledgerId = useAppSelector(selectSelectedLedgerToTransfer);
  const occupant = useAppSelector(selectSelectedOccupant);
  const dispatch = useAppDispatch();

  const updateSummary = () => {
    if (isTransfer) {
      return dispatch(getTransferUnitCost());
    }

    dispatch(getTransferUnitCost());
  };

  const editExternalTppPlan = () => {
    dispatch(setIsEditExternalPlan(true));
    dispatch(setExternalTPPModalOpen(true));
  };
  const isChecked = (id: number) => moveInInsurancePlan === id;

  const removePlan = () => {
    dispatch(clearTransferUnitInsurancePlan());
    updateSummary();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const planId = parseInt(event.target.value);

    if (planId === moveInInsurancePlan) {
      removePlan();
      return;
    }

    dispatch(addTransferUnitInsurancePlan(planId));
    updateSummary();
  };

  const handleExternalTppChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (hasExternalTpp) {
      dispatch(setIsEditExternalPlan(false));
      dispatch(setExternalTppPlanActive(false));
      dispatch(setSelectedExternalTppPlan(null));
      return;
    }

    dispatch(setExternalTPPModalOpen(!externalTppModalOpen));
    // removePlan();
  };

  useEffect(() => {
    if (moveInInsurancePlan) {
      return;
    }
    const lowestPlan = plans.sort((a, b) => parseFloat(a.premium_amount) - parseFloat(b.premium_amount));

    if (lowestPlan.length) {
      dispatch(addTransferUnitInsurancePlan(lowestPlan[0].id));
    }
  }, []);

  useEffect(() => {
    //move it to the end of the execution stack
    setTimeout(() => updateSummary());
  }, [moveInInsurancePlan]);

  const planList = plans.map((plan) => (
    <ItemSelector
      key={plan.id}
      item={plan}
      isChecked={isChecked(plan.id)}
      itemLabel={`${plan.name}`}
      itemPrice={formattedAmount(0, plan.premium_amount)}
      changeHandler={handleChange}
      removeItem={removePlan}
      isDisabled={hasExternalTpp}
    />
  ));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InputLabel className={classes.headerCell} htmlFor={"move-in-charges"}>
          <Typography noWrap>Insurance Plans</Typography>
        </InputLabel>
      </Grid>

      <Grid item xs={10}>
        <FormControl className={classes.feesListWrapper} component={"fieldset"} variant={"standard"}>
          <FormGroup>
            <FormControlLabel
              control={<Switch onChange={handleExternalTppChange} checked={hasExternalTpp} />}
              label={"I have my own external plan"}
            />
          </FormGroup>
        </FormControl>
      </Grid>
      {hasExternalTpp
        ? (
          <Grid item xs={2}>
            <IconButton onClick={editExternalTppPlan}>
              <Edit />
            </IconButton>
          </Grid>
          )
        : null}
      <Grid item xs={12}>
        {plans.length ? planList : <Typography>No plans available at this facility</Typography>}
      </Grid>
    </Grid>
  );
};

export default InsurancePlans;
