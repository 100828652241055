import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const updateDocumentTemplate = createAsyncThunk(
  "updateDocumentTemplate",
  (data: any, { dispatch, getState, rejectWithValue }) => {
    const { id, ...rest } = data;

    const payload = {
      ...rest,
      version: `${data.version[0]}${Number(data.version[1]) + 1}`,
      version_control_id: id,
      is_disabled: 0,
      rollback_allowed: 1,
      is_latest_version: true
    };

    return axios.put(`/api/v1/document-templates/${id}`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "New Document version created",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an error creating the new Document Template";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
