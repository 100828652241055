import React, { FC } from "react";
import {
  selectRefundMerchId,
  selectRefundModalOpen,
  setRefundModalOpen
} from "src/store/reducers/pointOfSalesSlice/pointOfSalesSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import AlertModal from "src/components/modal/AlertModal";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router";

interface RefundModalProps { }

const RefundModal: FC<RefundModalProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const refundModalOpen = useAppSelector(selectRefundModalOpen);
  const merchandiseTransactionId = useAppSelector(selectRefundMerchId);
  const handleCancel = () => {
    dispatch(setRefundModalOpen(false));
  };

  const handleConfirm = () => {
    dispatch(setRefundModalOpen(false));
    navigate(`/merchandise/refund-merchandise/${merchandiseTransactionId}`);
  };

  return (
    <AlertModal
      open={refundModalOpen}
      buttonLabel={"Confirm"}
      onCloseClick={() => handleCancel()}
      onButtonClick={() => handleConfirm()}
      title={" Confirm Refund Item"}
  >
      <>
        <Typography mt={2}>Are you sure you want to return and refund this item?</Typography>
      </>
    </AlertModal>
  );
};

export default RefundModal;
