import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "MoveInHeader" })((theme) => ({
  baseButton: {
    textTransform: "capitalize"
  },
  textButton: {
    color: theme.palette.primary.contrastText
  },
  transferUnitNumbers: {
    border: `1px solid ${theme.palette.primary.contrastText}`,
    padding: "0.5rem",
    borderRadius: "2rem",
    backgroundColor: theme.palette.primary.contrastText,
    color: "#fff"
  }
}));

export default useStyles;
