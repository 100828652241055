import { Button, Grid, Modal, Paper, Typography } from "@mui/material";
import {
  selectDelinquencyAutomationsOpenModal,
  selectSelectedDeliquencyAutomation,
  setAutomationsFilter,
  setOpenModal
} from
  "src/store/reducers/delinquencyAutomationsSlice/delinquencyAutomationsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import React from "react";
import { enableOrDisableAutomation } from "src/store/thunks/automations/update/enableOrDisableAutomation";
import { getAutomations } from "src/store/thunks/automations/getAll/getAutomations";
import { selectSelectedAdminFacility } from "src/store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import useStyles from "./DisableOrEnableModal.styles";

const DisableOrEnableModal = ({ type, automationName }: { type: "reenable" | "disable", automationName: string }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const modalOpened = useAppSelector(selectDelinquencyAutomationsOpenModal);
  const automation = useAppSelector(selectSelectedDeliquencyAutomation);
  const selectedAdminFacility = useAppSelector(selectSelectedAdminFacility);

  const renderTitle = {
    disable: "Confirm automation removal",
    reenable: `Re-enable ${automationName}`
  };

  const renderTextBody = {
    disable: "Are you sure want to remove this automation for this facility?",
    reenable: `Are you sure you want to re-enable ${automationName} for this location?`
  };

  const handleClose = () => {
    dispatch(setOpenModal(false));
  };

  const handleSubmit = () => {
    if (automation) {
      if (type === "disable") {
        dispatch(enableOrDisableAutomation({
          automation: automation,
          facilityId: String(selectedAdminFacility?.id),
          is_active: false
        })).then(resp => {
          if (!resp.type.includes("rejected")) {
            if (selectedAdminFacility?.id) {
              dispatch(setAutomationsFilter(resp.payload.is_active));
              dispatch(getAutomations({ facilityId: selectedAdminFacility?.id, is_active: resp.payload.is_active }));
            }
            handleClose();
          }
        });
        return;
      }

      dispatch(enableOrDisableAutomation({
        automation: automation,
        facilityId: String(selectedAdminFacility?.id),
        is_active: true
      })).then(resp => {
        if (!resp.type.includes("rejected")) {
          if (selectedAdminFacility?.id) {
            dispatch(setAutomationsFilter(resp.payload.is_active));
            dispatch(getAutomations({ facilityId: selectedAdminFacility?.id, is_active: resp.payload.is_active }));
          }
          handleClose();
        }
      });
    }
  };

  return (
    <Modal
      open={modalOpened}
      onClose={() => handleClose()}
      aria-labelledby={"Modal Component"}
      aria-describedby={"A Modal component."}
      className={classes.modal}
      data-testid={"reactivate-cancel-deal"}
  >
      <Grid
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        spacing={2}
        className={classes.container}
        pb={2}>
        <Box className={classes.content}>
          <Typography variant={"h5"} className={classes.title}>{renderTitle[type]}</Typography>
          <Typography>{renderTextBody[type]}</Typography>
          <Grid
            pt={4}
            spacing={4}
            container
            justifyContent={"center"}
      >
            <Grid item>
              <Button
                data-testid={"cancel-button"}
                className={classes.cancelButton}
                onClick={() => handleClose()}
                variant={"outlined"}
                color={"secondary"}
          >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                data-testid={"confirm-button-modal"}
                color={"primary"}
                variant={"outlined"}
                className={classes.button}
                onClick={() => handleSubmit()}
          >
                Confirm
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Modal>
  );
};

export default DisableOrEnableModal;
