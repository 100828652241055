import { CreateMoveInPayload } from "./CreateMoveInPayload";
import Moment from "moment";
import { PaymentMethod } from "../../../../enums/PaymentMethod";
import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const createMoveIn = createAsyncThunk("createMoveIn", (_, { dispatch, getState, rejectWithValue }) => {
  const state = getState() as RootState;

  const amountsReceived = [state.cash.amountReceived, state.moneyOrder.amountReceived];

  const paymentAmount = amountsReceived[state.payment.paymentType - 1] ?? state.moveInCost.total_due_today;

  const payload: CreateMoveInPayload = {
    facilityId: state.selectedUnit?.selectedUnit?.facility_id!,
    occupant_id: state.selectedOccupant?.selectedOccupant?.id,
    payment_amount: parseFloat(paymentAmount),
    unit_id: state.selectedUnit?.selectedUnit?.id!,
    lease_start_date: Moment(state.moveIn?.leaseStartsOn).format("yyyy-MM-DD"),
    monthly_rate: parseFloat(state.moveIn?.monthlyRate),
    deal_id: state.deal.deal?.id ?? null,
    promotion_id: state.selectedPromotion?.selectedPromotion?.id,
    fees: state.moveIn?.fees ?? null,
    tpp_plan_id: state.moveIn?.insurancePlanId,
    merchandise: state.moveIn?.merchandise,
    payment_method: state.payment?.paymentType,
    periods: state.moveIn?.periods,
    is_promo_deferred: state.moveIn?.promoDefermentStatus,
    waive_fee: state.moveIn?.waiveFee?.waiveFeeAmount,
    what_are_you_storing: state.moveIn.storingReason,
    has_external_tpp: state.externalTppPlan.externalTppPlanActive,
    external_tpp_plan: state.externalTppPlan.selectedExternalTppPlan,
    time_access_id: state.moveIn.timeAccess ? +state.moveIn.timeAccess : null,
    keypad_access_id: state.moveIn.keypadAccess ? +state.moveIn.keypadAccess : null
  };

  if (payload.payment_method === PaymentMethod.creditCard || payload.payment_method === PaymentMethod.ach) {
    payload.billing = {
      first_name: "",
      last_name: ""
    };

    if (payload.payment_method === PaymentMethod.creditCard) {
      payload.billing.first_name = state.creditCard.name.split(" ")[0];
      payload.billing.last_name = state.creditCard.name.split(" ")[1];
      payload.billing.card_number = state.creditCard.number.replaceAll(" ", "");
      payload.billing.exp_month = state.creditCard.expiration.substring(0, 2);
      payload.billing.exp_year = "20" + state.creditCard.expiration.substring(2, 4);
      payload.billing.cvv = state.creditCard.cvv;
      payload.billing.credit_card_type =
        state.creditCard.type === "american-express" ? "american express" : state.creditCard.type;
      payload.billing.instrument_type =
        state.creditCard.type === "american-express" ? "american express" : state.creditCard.type;
      payload.billing.enable_autobill = state.creditCard.enableAutoPay;
    } else if (payload.payment_method === PaymentMethod.ach) {
      payload.billing.first_name = state.ach.accountName.split(" ")[0];
      payload.billing.last_name = state.ach.accountName.split(" ")[1];
      payload.billing.account_number = state.ach.accountNumber;
      payload.billing.routing_number = state.ach.routingNumber;
      payload.billing.enable_autobill = state.ach.enableAutoPay;
      payload.billing.bank_name = state.ach.bankName;
      payload.billing.account_holder_name = state.ach.accountName;
      payload.billing.check_number = state.ach.checkNumber;
    }
  }

  if (payload.payment_method === PaymentMethod.moneyOrder) {
    payload.billing = {
      first_name: state.selectedOccupant.selectedOccupant?.first_name ?? "",
      last_name: state.selectedOccupant.selectedOccupant?.last_name ?? "",
      money_order_number: state.moneyOrder.moneyOrderNumber
    };
  }

  return axios
    .post(`/api/v1/facilities/${payload.facilityId}/move-in`, payload)
    .then((resp) => {
      dispatch(
        showSnackbar({
          message: "Move-In created successfully",
          variant: "success"
        })
      );

      return resp.data;
    })
    .catch((err) => {
      let errorMessage: string;
      if (err.response.status === 422) {
        errorMessage = format422(err.response.data.errors);

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      }

      errorMessage = "There was an error creating the Move-In";

      if (err && err.response && err.response.status && err.response.status === 400) {
        errorMessage += `: ${err.response.data.detail}`;
      }

      dispatch(
        showSnackbar({
          message: errorMessage,
          variant: "error"
        })
      );

      return rejectWithValue(errorMessage);
    });
});
