import { Button, Grid, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { ArrowBack } from "@mui/icons-material";
import clsx from "clsx";
import useStyles from "./ReviewHeader.styles";
import { useAppSelector } from "src/store/hooks";
import { selectIsTransfer } from "src/store/reducers/transferUnitSlice/transferUnitSlice";
import { selectMoveOutLedger } from "src/store/reducers/moveOutSlice/moveOutSlice";
import { selectSelectedUnit } from "src/store/reducers/selectedUnitSlice/selectedUnitSlice";

interface ReviewHeaderProps {
  headerText: string
  backMethod: () => void
  goBackText: string
}

const UnitNumber = ({ number }: {number: string}) => {
  const { classes } = useStyles();
  return (
    <Typography
      variant={"body2"}
      component={"span"}
      color={"primary"}
      className={classes.transferUnitNumbers}
    >
      {number}
    </Typography>
  );
};

const ReviewHeader: React.FC<ReviewHeaderProps> = ({
  headerText,
  backMethod,
  goBackText
}: ReviewHeaderProps): ReactElement => {
  const { classes } = useStyles();
  const isTransfer = useAppSelector(selectIsTransfer);
  const selectedMoveOutLedger = useAppSelector(selectMoveOutLedger);
  const selectedUnitFromTransfer = useAppSelector(selectSelectedUnit);

  return (
    <>
      <Typography variant={"h5"} component={"span"}>{headerText}</Typography>
      <Grid
        container
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
      >
        <Grid item mt={1} xs={8.5}>
          <Button
            data-testid={"back-button"}
            variant={"text"}
            className={clsx(classes.textButton, classes.baseButton)}
            startIcon={<ArrowBack />}
            onClick={backMethod}
        >
            Go Back To {goBackText}
          </Button>
        </Grid>
        {isTransfer && (
          <Grid item mt={1} xs={3.5}>
            <Typography variant={"body2"} component={"p"} lineHeight={2}>
              <Typography variant={"body2"} component={"span"} color={"primary"}>
                {"Transferring from "}
                <UnitNumber number={selectedMoveOutLedger?.unit?.unit_number!}/>
                {" to "}
                <UnitNumber number={selectedUnitFromTransfer?.unit_number!}/>
              </Typography>
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ReviewHeader;
