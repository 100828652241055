import { Autorenew, FileUpload } from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  TextFieldProps,
  Typography
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  ID_Type_Enum,
  selectBusinessName,
  selectDob,
  selectDriversLicenseNumber,
  selectDriversLicenseState,
  selectEmail,
  selectGateCode,
  selectGateCodeLoading,
  selectIdType,
  selectIsBusiness,
  selectIsTaxExempt,
  selectMilitaryBranch,
  selectMilitaryId,
  selectMilitaryStatus,
  selectPrimaryPhone,
  selectSecondaryPhone,
  selectTaxExemptDocument,
  selectTaxId, setSelectIdType,
  toggleIsBusiness
} from "../../../store/reducers/occupantInformationSlice/occupantInformationSlice";
import React, { FormEvent, ReactElement, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CancelIcon from "@mui/icons-material/Cancel";
import MaskedPhone from "../../masking/MaskedPhone/MaskedPhone";
import { Moment } from "moment";
import { OccupantContactValues } from "../../../models/formikInputValues/occupant/OccupantContactValues";
import PMSCheckbox from "src/components/ui/PMSCheckbox/PMSCheckbox";
import PMSSelect from "../../../components/ui/PMSSelect/PMSSelect";
import PMSSwitch from "../../ui/PMSSwitch/PMSSwitch";
import customerInformationTwoValidation from "./occupantContactInformationValidation";
import { formatDate } from "../../../utils/__dateAndTimeUtils__/formatDate/formatDate";
import { getGateCode } from "src/store/thunks/occupant/getGateCode/getGateCode";
import { inputError } from "../../../utils/showInputError/showInputError";
import {
  selectFacilityGateSystemSetting
} from "src/store/reducers/facilitySettingInformationSlice/facilitySettingInformationSlice";
import states from "../../../utils/usStates";
import { useFormik } from "formik";
import useStyles from "./OccupantContactInformation.styles";

interface OccupantContactProps {
  stepperId: string;
  onSubmitHandler: (values: OccupantContactValues) => void;
}

const ID_TYPES = [
  { label: "Driver's License #", value: ID_Type_Enum.DriversLicense },
  { label: "State ID #", value: ID_Type_Enum.StateId },
  { label: "Passport #", value: ID_Type_Enum.Passport }
];

const OccupantContactInformation: React.FC<OccupantContactProps> = ({ stepperId, onSubmitHandler }): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const customerEmail = useAppSelector(selectEmail);
  const customerPrimaryPhone = useAppSelector(selectPrimaryPhone);
  const customerSecondaryPhone = useAppSelector(selectSecondaryPhone);
  const driversLicenseNumber = useAppSelector(selectDriversLicenseNumber);
  const driversLicenseState = useAppSelector(selectDriversLicenseState);
  const taxId = useAppSelector(selectTaxId);
  const militaryStatus = useAppSelector(selectMilitaryStatus);
  const customerDob = useAppSelector(selectDob);
  const customerGateCode = useAppSelector(selectGateCode);
  const isBusiness = useAppSelector(selectIsBusiness);
  const isTaxExempt = useAppSelector(selectIsTaxExempt);
  const customerBusinessName = useAppSelector(selectBusinessName);
  const taxExemptDocument = useAppSelector(selectTaxExemptDocument);
  const gateCodeLoading = useAppSelector(selectGateCodeLoading);
  const gateCodeProvider = useAppSelector(selectFacilityGateSystemSetting);
  const idType = useAppSelector(selectIdType);
  const militaryBranch = useAppSelector(selectMilitaryBranch);
  const militaryId = useAppSelector(selectMilitaryId);

  const formik = useFormik({
    initialValues: {
      email: customerEmail,
      primaryPhone: customerPrimaryPhone,
      secondaryPhone: customerSecondaryPhone,
      idNumber: driversLicenseNumber,
      idState: driversLicenseState,
      taxIdNumber: taxId,
      militaryStatus: militaryStatus.toFixed(),
      militaryBranch: militaryBranch,
      militaryId: militaryId,
      dob: customerDob,
      gateCode: customerGateCode,
      business: isBusiness,
      taxExempt: isTaxExempt,
      businessName: customerBusinessName,
      taxExemptDocument: taxExemptDocument as File,
      gateCodeProvider: gateCodeProvider?.value,
      idType: idType
    },
    validationSchema: customerInformationTwoValidation,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values: OccupantContactValues) => onSubmitHandler(values)
  });

  const { touched, errors, values, handleChange, setFieldValue } = formik;

  const handleBusinessToggle = (isBusiness: boolean) => {
    dispatch(toggleIsBusiness(isBusiness));
    setFieldValue("business", isBusiness);
  };

  const handleTaxExemptToggle = async(event: React.ChangeEvent<HTMLInputElement>) => {
    await setFieldValue("taxExempt", event.target.checked);
    await setFieldValue("taxExemptDocument", null);
  };

  const handleTaxExemptDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];
    setFieldValue("taxExemptDocument", file);
  };

  const getGatecode = () => {
    dispatch(getGateCode()).then((resp) => {
      if (!resp.type.includes("rejected")) {
        setFieldValue("gateCode", resp.payload?.code);
      }
    });
  };

  const handleIdTypeChange = (event: any) => {
    setFieldValue("idType", event.target.value as number);
    dispatch(setSelectIdType(event.target.value as number));
  };

  return (
    <Grid
      component={"form"}
      p={1}
      id={stepperId}
      data-testid={stepperId}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        formik.handleSubmit();
      }}
    >
      <Typography variant={"h6"} component={"h3"}>
        Occupant Information
      </Typography>
      <Grid container justifyContent={"space-between"} mt={2}
        spacing={2}>
        <Grid xs={12} item>
          <InputLabel htmlFor={"email"}>E-mail Address</InputLabel>
          <TextField
            fullWidth
            placeholder={"- E-mail Address -"}
            id={"email"}
            inputProps={{ "data-testid": "email" }}
            name={"email"}
            value={values.email}
            onChange={handleChange}
            error={inputError("email", touched, errors).error}
            helperText={inputError("email", touched, errors).helperText}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel htmlFor={"primaryPhone"}>Primary Phone</InputLabel>
          <MaskedPhone
            id={"primaryPhone"}
            dataTestId={"primaryPhone"}
            fullWidth
            variant={"outlined"}
            name={"primaryPhone"}
            type={"tel"}
            placeholder={"- Primary Phone -"}
            onChange={handleChange}
            value={values.primaryPhone}
            error={inputError("primaryPhone", touched, errors).error}
            helperText={inputError("primaryPhone", touched, errors).helperText}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel htmlFor={"occupant-primary-phone"}>Secondary Phone</InputLabel>
          <MaskedPhone
            id={"secondaryPhone"}
            dataTestId={"secondaryPhone"}
            fullWidth
            variant={"outlined"}
            name={"secondaryPhone"}
            type={"tel"}
            placeholder={"- Secondary Phone -"}
            onChange={handleChange}
            value={values.secondaryPhone}
            error={inputError("secondaryPhone", touched, errors).error}
            helperText={inputError("secondaryPhone", touched, errors).helperText}
          />
        </Grid>
        <Grid item xs={Number(values.idType) === ID_Type_Enum.Passport ? 4 : 3}>
          <PMSSelect
            id={"idType"}
            label={"ID Type"}
            name={"idType"}
            value={values.idType}
            changeHandler={handleIdTypeChange}
            error={inputError("idType", touched, errors).error}
            helperText={inputError("idType", touched, errors).helperText}
          >
            <option value={0} disabled>
              {" - ID Type -"}
            </option>
            {ID_TYPES.map((type) => (
              <option key={type.value} value={type.value}>
                {type.label}
              </option>
            ))}
          </PMSSelect>
        </Grid>
        <Grid item xs={Number(values.idType) === ID_Type_Enum.Passport ? 8 : 5}>
          <InputLabel htmlFor={"idNumber"}>
            {ID_TYPES.find((idType) => idType.value === Number(values.idType))?.label}
          </InputLabel>
          <TextField
            fullWidth
            placeholder={`- ${ID_TYPES.find((idType) => idType.value === Number(values.idType))?.label} -`}
            id={"idNumber"}
            data-mask-sensitive={true}
            inputProps={{
              "data-testid": "idNumber",
              ...(Number(values.idType) === ID_Type_Enum.Passport && { maxLength: 9 })
            }}
            name={"idNumber"}
            value={values.idNumber}
            onChange={
              Number(values.idType) === ID_Type_Enum.Passport
                ? (e) => setFieldValue("idNumber", e.target.value.toUpperCase() as string)
                : handleChange
            }
            error={inputError("idNumber", touched, errors).error}
            helperText={inputError("idNumber", touched, errors).helperText}
          />
        </Grid>

        {Number(values.idType) !== ID_Type_Enum.Passport && (
          <Grid item xs={4}>
            <PMSSelect
              id={"id-state"}
              name={"idState"}
              label={Number(values.idType) === ID_Type_Enum.StateId ? "ID State" : "Driver's License State"}
              value={values.idState}
              changeHandler={handleChange}
              error={inputError("idState", touched, errors).error}
              helperText={inputError("idState", touched, errors).helperText}
              disabled={Number(values.idType) === ID_Type_Enum.Passport}
            >
              <option value={""}>Select a State...</option>
              {states.map((state) => (
                <option key={state.text} value={state.value}>
                  {state.text}
                </option>
              ))}
            </PMSSelect>
          </Grid>
        )}

        <Grid xs={6} item>
          <InputLabel htmlFor={"taxIdNumber"}>Tax ID</InputLabel>
          <TextField
            fullWidth
            placeholder={"- Tax ID -"}
            data-mask-sensitive={true}
            id={"taxIdNumber"}
            inputProps={{ "data-testid": "taxIdNumber" }}
            name={"taxIdNumber"}
            value={values.taxIdNumber}
            onChange={handleChange}
            error={inputError("taxIdNumber", touched, errors).error}
            helperText={inputError("taxIdNumber", touched, errors).helperText}
          />
        </Grid>
        <Grid xs={6} item>
          <PMSSelect
            id={"military-status"}
            label={"Military Status"}
            name={"militaryStatus"}
            value={values.militaryStatus}
            changeHandler={(ev) => {
              if (ev.target.value !== 4 && ev.target.value !== 5) {
                setFieldValue("militaryBranch", 0);
                setFieldValue("militaryId", "");
              }
              handleChange(ev);
            }}
            error={inputError("militaryStatus", touched, errors).error}
            helperText={inputError("militaryStatus", touched, errors).helperText}
          >
            <option value={0} disabled>
              {" - Military Status -"}
            </option>
            <option value={1}>Not Indicated</option>
            <option value={2}>Veteran</option>
            <option value={3}>Not a Veteran</option>
            <option value={4}>Active Duty</option>
            <option value={5}>Reserve</option>
          </PMSSelect>
        </Grid>
        {(Number(values.militaryStatus) === 4 || Number(values.militaryStatus) === 5) && (
          <>
            <Grid xs={6} item>
              <PMSSelect
                id={"military-branch"}
                label={"Military Branch"}
                name={"militaryBranch"}
                value={values.militaryBranch}
                changeHandler={handleChange}
                error={inputError("militaryBranch", touched, errors).error}
                helperText={inputError("militaryBranch", touched, errors).helperText}
              >
                <option value={0} disabled selected>
                  {" - Military Branch -"}
                </option>
                <option value={1}>Air Force</option>
                <option value={2}>Army</option>
                <option value={3}>Coast Guard</option>
                <option value={4}>Marine Corps</option>
                <option value={5}>Navy</option>
                <option value={6}>Space Force</option>
              </PMSSelect>
            </Grid>
            <Grid xs={6} item>
              <InputLabel htmlFor={"taxIdNumber"}>ID Number</InputLabel>
              <TextField
                fullWidth
                placeholder={"- ID Number -"}
                id={"militaryId"}
                type={"number"}
                inputProps={{ "data-testid": "militaryId" }}
                name={"militaryId"}
                value={values.militaryId}
                onChange={(e) => {
                  const value = String(e.target.value);
                  if (value.length > 10) {
                    return;
                  }
                  setFieldValue("militaryId", value);
                }}
                error={inputError("militaryId", touched, errors).error}
                helperText={inputError("militaryId", touched, errors).helperText}
              />
            </Grid>
          </>
        )}

        <Grid xs={6} item>
          <InputLabel htmlFor={"dob"}>DOB</InputLabel>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              OpenPickerButtonProps={{ "aria-label": "Select Date of Birth" }}
              componentsProps={{
                leftArrowButton: { "aria-label": "Move Backward" },
                rightArrowButton: { "aria-label": "Move Backward" }
              }}
              renderInput={(props: TextFieldProps) => (
                <TextField
                  name={"dob"}
                  id={"dob"}
                  fullWidth
                  placeholder={"- Date of Birth -"}
                  {...props}
                  helperText={inputError("dob", touched, errors).helperText}
                  error={inputError("dob", touched, errors).error}
                />
              )}
              inputFormat={"MM/DD/yyyy"}
              data-testid={"dob"}
              value={values.dob}
              onChange={(value: Moment | null, keyboardInputValue?: string | undefined) =>
                setFieldValue("dob", formatDate(value))
              }
            />
          </LocalizationProvider>
        </Grid>
        <Grid xs={6} item>
          <InputLabel htmlFor={"gateCode"}>Gate Code</InputLabel>
          <Grid display={"flex"} flexDirection={"row"} gap={2}>
            <TextField
              fullWidth
              className={classes.noArrowInput}
              placeholder={"- Gate Code -"}
              id={"gateCode"}
              inputProps={{ "data-testid": "gateCode" }}
              name={"gateCode"}
              type={"number"}
              value={values.gateCode}
              onChange={handleChange}
              error={inputError("gateCode", touched, errors).error}
              helperText={inputError("gateCode", touched, errors).helperText}
              style={{ width: "35%" }}
            />
            <Button
              className={classes.generateGateCodeButton}
              variant={"contained"}
              component={"label"}
              onClick={getGatecode}
              disabled={gateCodeLoading}
            >
              GENERATE NEW GATE CODE
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <PMSSwitch
            name={"business"}
            size={"medium"}
            smallText
            isChecked={values.business}
            label={"Business Account"}
            changeHandler={(e) => handleBusinessToggle(e.target.checked)}
          />
        </Grid>
        {values.business && (
          <Grid xs={12} item>
            <InputLabel htmlFor={"businessName"}>Business Name</InputLabel>
            <TextField
              fullWidth
              placeholder={"- Business Name -"}
              id={"businessName"}
              inputProps={{ "data-testid": "businessName" }}
              name={"businessName"}
              value={values.businessName}
              onChange={handleChange}
              error={inputError("businessName", touched, errors).error}
              helperText={inputError("businessName", touched, errors).helperText}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <PMSCheckbox
            smallText
            name={"taxExempt"}
            label={"Tax Exempt"}
            isChecked={values.taxExempt}
            changeHandler={handleTaxExemptToggle}
          />
        </Grid>
        {values.taxExempt && (
          <Grid alignSelf={"center"} item xs={3}
            display={"flex"} flexDirection={"column"}>
            <Button
              className={classes.uploadButton}
              variant={"contained"}
              startIcon={<FileUpload />}
              component={"label"}
            >
              Upload Document
              <input
                id={"taxExemptDocument"}
                name={"taxExemptDocument"}
                onChange={handleTaxExemptDocumentChange}
                type={"file"}
                hidden
              />
            </Button>
            <Typography variant={"caption"} color={"red"} mt={1}>
              {inputError("taxExemptDocument", touched, errors).helperText}
            </Typography>
          </Grid>
        )}
        {values.taxExemptDocument?.name && (
          <Grid alignSelf={"center"} container item
            xs={6}>
            <Grid item className={classes.fileNameContainer}>
              <Typography mt={1} variant={"caption"} className={classes.fileName}
                noWrap alignSelf={"center"}>
                {values.taxExemptDocument?.name}
              </Typography>

              <CancelIcon className={classes.cancelIcon} onClick={() => setFieldValue("taxExemptDocument", null)} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default OccupantContactInformation;
