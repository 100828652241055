import { RootState } from "src/store/rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSearchParams } from "../../../../utils/getSearchParams/getSearchParams";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getUnitsByProductType = createAsyncThunk(
  "getUnitsByProductType",
  (id: string, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as RootState;

    const facilityId = state.selectedFacility.selectedFacility!.id;

    const searchParams = {
      params: {
        ...getSearchParams(state, true)
      }
    };

    return axios.get(`/api/v1/facilities/${facilityId}/units?filter[product_type_id]=${id}`, searchParams)
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = "There was an error getting the Units for this Product Type";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
