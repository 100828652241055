import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DealInformationSliceState } from "./DealInformationSliceState";
import { DealType } from "../../../models/DealType";
import { Facility } from "../../../models/Facility";
import { ReferralType } from "../../../models/ReferralType";
import { RootState } from "../../rootStore";
import { getDeal } from "src/store/thunks/deals/get/getDeal";
import moment from "moment";

export const initialState: DealInformationSliceState = {
  dealFirstName: sessionStorage.getItem("dealFirstName") ?? null,
  dealMiddleName: sessionStorage.getItem("dealMiddleName") ?? null,
  dealLastName: sessionStorage.getItem("dealLastName") ?? null,
  dealEmail: sessionStorage.getItem("dealEmail") ?? "",
  dealPhone: sessionStorage.getItem("dealPhone") ?? "",
  dealAddress: sessionStorage.getItem("dealAddress") ?? null,
  dealAddressTwo: sessionStorage.getItem("dealAddressTwo") ?? null,
  dealCity: sessionStorage.getItem("dealCity") ?? null,
  dealRegion: sessionStorage.getItem("dealRegion") ?? null,
  dealPostalCode: sessionStorage.getItem("dealPostalCode") ?? null,
  dealCountry: sessionStorage.getItem("dealCountry") ?? null,
  dealFacility: JSON.parse(sessionStorage.getItem("dealFacility") as string) ?? null,
  dealType: sessionStorage.getItem("dealType") as DealType ?? "reservation",
  referralType: sessionStorage.getItem("dealReferralType") as ReferralType ?? "",
  neededOn: sessionStorage.getItem("dealNeededOn" as string) ?? null,
  followup: sessionStorage.getItem("dealFollowup" as string) ?? null,
  expiresOn: sessionStorage.getItem("dealExpiresOn" as string) ?? null,
  appointmentFor: sessionStorage.getItem("dealAppointmentFor" as string) ?? null,
  dealInformationLoading: false,
  dealInformationError: ""
};

export const dealInformationSlice = createSlice({
  name: "dealInformation",
  initialState,
  reducers: {
    setDealType: (state, action: PayloadAction<DealType>) => {
      state.dealType = action.payload;
      sessionStorage.setItem("dealType", action.payload);
    },
    setReferralType: (state, action: PayloadAction<ReferralType>) => {
      state.referralType = action.payload;
      sessionStorage.setItem("dealReferralType", action.payload);
    },
    setNeededOn: (state, action: PayloadAction<string>) => {
      state.neededOn = action.payload;
      sessionStorage.setItem("dealNeededOn", action.payload);
    },
    setFollowup: (state, action: PayloadAction<string>) => {
      state.followup = action.payload;
      sessionStorage.setItem("dealFollowup", action.payload);
    },
    setExpiresOn: (state, action: PayloadAction<string | null>) => {
      state.expiresOn = action.payload;
      sessionStorage.setItem("dealExpiresOn", String(action.payload));
    },
    setAppointmentFor: (state, action: PayloadAction<string | null>) => {
      state.appointmentFor = action.payload;
      sessionStorage.setItem("dealAppointmentFor", String(action.payload));
    },
    setDealFacility: (state, action: PayloadAction<Facility|null>) => {
      state.dealFacility = action.payload;
      sessionStorage.setItem("dealFacility", JSON.stringify(action.payload));
    },
    resetDealInformationSlice: (state) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("dealType");
      sessionStorage.removeItem("dealReferralType");
      sessionStorage.removeItem("dealNeededOn");
      sessionStorage.removeItem("dealFollowup");
      sessionStorage.removeItem("dealExpiresOn");
      sessionStorage.removeItem("dealAppointmentFor");
      sessionStorage.removeItem("dealNote");
      sessionStorage.removeItem("dealFacility");
      sessionStorage.removeItem("dealFirstName");
      sessionStorage.removeItem("dealMiddleName");
      sessionStorage.removeItem("dealLastName");
      sessionStorage.removeItem("dealEmail");
      sessionStorage.removeItem("dealPhone");
      sessionStorage.removeItem("dealAddress");
      sessionStorage.removeItem("dealAddressTwo");
      sessionStorage.removeItem("dealCity");
      sessionStorage.removeItem("dealRegion");
      sessionStorage.removeItem("dealPostalCode");
      sessionStorage.removeItem("dealCountry");
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDeal.pending, state => {
        state.dealInformationLoading = true;
      })
      .addCase(getDeal.fulfilled, (state, action) => {
        state.dealInformationLoading = false;
        state.referralType = action.payload.referral_type;
        state.dealType = action.payload.deal_type?.toLowerCase();
        state.neededOn = action.payload.needed_at;
        state.followup = action.payload.contact_at;
        state.expiresOn = action.payload.reservation_expires_at;
        state.appointmentFor = moment(action.payload.appointment_at).isValid()
          ? moment(action.payload.appointment_at).format("MM/DD/YYYY hh:mm A")
          : null;
        state.dealFirstName = action.payload.first_name;
        state.dealMiddleName = action.payload.middle_name;
        state.dealLastName = action.payload.last_name;
        state.dealEmail = action.payload.email;
        state.dealPhone = action.payload.phone;
        state.dealAddress = action.payload.address;
        state.dealAddressTwo = action.payload.address_two;
        state.dealCity = action.payload.city;
        state.dealRegion = action.payload.region;
        state.dealPostalCode = action.payload.postal_code;
        state.dealCountry = action.payload.country;

        sessionStorage.setItem("dealFirstName", String(state.dealFirstName));
        sessionStorage.setItem("dealMiddleName", String(state.dealMiddleName));
        sessionStorage.setItem("dealLastName", String(state.dealLastName));
        sessionStorage.setItem("dealEmail", String(state.dealEmail));
        sessionStorage.setItem("dealPhone", String(state.dealPhone));
        sessionStorage.setItem("dealAddress", String(state.dealAddress));
        sessionStorage.setItem("dealAddressTwo", String(state.dealAddressTwo));
        sessionStorage.setItem("dealCity", String(state.dealCity));
        sessionStorage.setItem("dealRegion", String(state.dealRegion));
        sessionStorage.setItem("dealPostalCode", String(state.dealPostalCode));
        sessionStorage.setItem("dealCountry", String(state.dealCountry));
      })
      .addCase(getDeal.rejected, (state, action) => {
        state.dealInformationLoading = false;
        state.dealInformationError = action.payload as string;
      });
  }
});

export const selectDealFirstName = (state: RootState) => state.dealInformation.dealFirstName;
export const selectDealMiddleName = (state: RootState) => state.dealInformation.dealMiddleName;
export const selectDealLastName = (state: RootState) => state.dealInformation.dealLastName;
export const selectDealEmail = (state: RootState) => state.dealInformation.dealEmail;
export const selectDealPhone = (state: RootState) => state.dealInformation.dealPhone;
export const selectDealAddress = (state: RootState) => state.dealInformation.dealAddress;
export const selectDealAddressTwo = (state: RootState) => state.dealInformation.dealAddressTwo;
export const selectDealCity = (state: RootState) => state.dealInformation.dealCity;
export const selectDealRegion = (state: RootState) => state.dealInformation.dealRegion;
export const selectDealPostalCode = (state: RootState) => state.dealInformation.dealPostalCode;
export const selectDealCountry = (state: RootState) => state.dealInformation.dealCountry;
export const selectReferralType = (state: RootState) => state.dealInformation.referralType;
export const selectDealType = (state: RootState) => state.dealInformation.dealType;
export const selectNeededOn = (state: RootState) => state.dealInformation.neededOn;
export const selectDealFacility = (state: RootState) => state.dealInformation.dealFacility;
export const selectFollowup = (state: RootState) => state.dealInformation.followup;
export const selectAppointmentFor = (state: RootState) => state.dealInformation.appointmentFor;
export const selectExpiresOn = (state: RootState) => state.dealInformation.expiresOn;
export const selectDealInformationLoading = (state: RootState) => state.dealInformation.dealInformationLoading;

export const {
  setDealType,
  setReferralType,
  setNeededOn,
  setFollowup,
  setExpiresOn,
  setAppointmentFor,
  setDealFacility,
  resetDealInformationSlice
} = dealInformationSlice.actions;

export default dealInformationSlice.reducer;
