import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Send Email Receipt Form" })((theme) => ({
  buttonBase: {
    textTransform: "capitalize",
    marginLeft: theme.spacing(2)
  },
  saveButton: {
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText
  }
}));

export default useStyles;
