import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "ManageUnitsTable" })((theme) => ({
  saveButton: {
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}));

export default useStyles;
