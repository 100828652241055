import { FeeSliceState } from "./FeeSliceState";
import { RootState } from "../../rootStore";
import { createSlice } from "@reduxjs/toolkit";
import { disableFee } from "../../thunks/fee/disable/disableFee";
import { enableFee } from "../../thunks/fee/enable/enableFee";

export const initialState: FeeSliceState = {
  fee: JSON.parse(sessionStorage.getItem("fee") as string) ?? null,
  feeModalOpen: false,
  modalIsEnable: false,
  feeLoading: false,
  feeError: ""
};

export const feeSlice = createSlice({
  name: "fee",
  initialState,
  reducers: {
    setFee: (state, action) => {
      state.fee = action.payload;
      sessionStorage.setItem("fee", JSON.stringify(action.payload));
    },
    setFeeModalOpen: (state, action) => {
      state.feeModalOpen = action.payload;
    },
    setFeeModalIsEnable: (state, action) => {
      state.modalIsEnable = action.payload;
    },
    resetFeeSlice: (state) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("fee");
    }
  },
  extraReducers: builder => {
    builder
      .addCase(enableFee.pending, (state) => {
        state.feeLoading = true;
      })
      .addCase(enableFee.fulfilled, (state) => {
        state.feeLoading = false;
      })
      .addCase(enableFee.rejected, (state, action) => {
        state.feeLoading = false;
        state.feeError = action.payload as string;
      })
      .addCase(disableFee.pending, (state) => {
        state.feeLoading = true;
      })
      .addCase(disableFee.fulfilled, (state) => {
        state.feeLoading = false;
      })
      .addCase(disableFee.rejected, (state, action) => {
        state.feeLoading = false;
        state.feeError = action.payload as string;
      });
  }
});

export const selectFeeId = (state: RootState) => state.fee.fee?.id;
export const selectFeeLoading = (state: RootState) => state.fee.feeLoading;
export const selectFeeModalOpen = (state: RootState) => state.fee.feeModalOpen;
export const selectFeeModalIsEnable = (state: RootState) => state.fee.modalIsEnable;

export const {
  setFee,
  setFeeModalOpen,
  setFeeModalIsEnable,
  resetFeeSlice
} = feeSlice.actions;

export default feeSlice.reducer;
