import { MerchandiseFormSliceState } from "./MerchandiseFormSliceState";
import { RootState } from "../../rootStore";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { createMerchandise } from "../../thunks/merchandise/create/createMerchandise";
import { getMerchandise } from "../../thunks/merchandise/get/getMerchandise";
import { updateMerchandise } from "../../thunks/merchandise/update/updateMerchandise";

export const initialState: MerchandiseFormSliceState = {
  id: undefined,
  name: "",
  revenue_class_id: 0,
  merchandise_category_id: 0,
  description: "",
  // sku: "",
  price: "",
  // quantity: 0,
  // cost_of_goods_sold: "",
  // quantity_change_reason: "",
  merchandiseFormLoading: false,
  merchandiseFormError: ""
};

export const merchandiseFormSlice = createSlice({
  name: "merchandiseForm",
  initialState,
  reducers: {
    resetMerchandiseFormSlice: (state) => {
      Object.assign(state, initialState);
    },
    setSelectedMerchandiseId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setSelectedMerchandiseName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createMerchandise.pending, (state) => {
        state.merchandiseFormLoading = true;
      })
      .addCase(createMerchandise.fulfilled, (state) => {
        state.merchandiseFormLoading = false;
      })
      .addCase(createMerchandise.rejected, (state, action) => {
        state.merchandiseFormLoading = false;
        state.merchandiseFormError = action.payload as string;
      })
      .addCase(updateMerchandise.pending, (state) => {
        state.merchandiseFormLoading = true;
      })
      .addCase(updateMerchandise.fulfilled, (state) => {
        state.merchandiseFormLoading = false;
      })
      .addCase(updateMerchandise.rejected, (state, action) => {
        state.merchandiseFormLoading = false;
        state.merchandiseFormError = action.payload as string;
      })
      .addCase(getMerchandise.pending, (state) => {
        state.merchandiseFormLoading = true;
      })
      .addCase(getMerchandise.fulfilled, (state, action) => {
        state.merchandiseFormLoading = false;
        state.id = action.payload.id;
        state.name = action.payload.name ?? "";
        state.revenue_class_id = action.payload.revenue_class_id;
        state.merchandise_category_id = action.payload.merchandise_category_id;
        state.description = action.payload.description ?? "";
        // state.sku = action.payload.sku ?? "";
        state.price = action.payload.price ?? "";
        // state.quantity = action.payload.quantity ?? 0;
        // state.cost_of_goods_sold = action.payload.cost_of_goods_sold ?? "";
      })
      .addCase(getMerchandise.rejected, (state, action) => {
        state.merchandiseFormLoading = false;
        state.merchandiseFormError = action.payload as string;
      });
  }
});

export const selectMerchandiseId = (state: RootState) => state.merchandiseForm.id;
export const selectMerchandiseName = (state: RootState) => state.merchandiseForm.name;
export const selectMerchandiseRevenueClassId = (state: RootState) => state.merchandiseForm.revenue_class_id;
export const selectMerchandiseCategoryId = (state: RootState) => state.merchandiseForm.merchandise_category_id;
export const selectMerchandiseDescription = (state: RootState) => state.merchandiseForm.description;
export const selectMerchandisePrice = (state: RootState) => state.merchandiseForm.price;
// export const selectMerchandiseQuantity = (state: RootState) => state.merchandiseForm.quantity;
// export const selectCostOfGoodsSold = (state: RootState) => state.merchandiseForm.cost_of_goods_sold;
// export const selectMerchandiseQuantityChangeReason = (state: RootState) =>
//  state.merchandiseForm.quantity_change_reason;
export const selectMerchandiseFormLoading = (state: RootState) => state.merchandiseForm.merchandiseFormLoading;
// export const selectMerchandiseSku = (state: RootState) => state.merchandiseForm.sku;

export const {
  resetMerchandiseFormSlice,
  setSelectedMerchandiseId,
  setSelectedMerchandiseName
} = merchandiseFormSlice.actions;

export default merchandiseFormSlice.reducer;
