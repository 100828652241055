import React, { ReactElement } from "react";
import { LoadingButton } from "@mui/lab";
import clsx from "clsx";
import useStyles from "./SpeedeRentActionButtons.styles";

interface SpeedeRentActionButtonsProps {
  loading?: boolean
  handlerMethod?: () => void
  formId?: string
  disabled?: boolean
}

const SpeedeRentActionButtons: React.FC<SpeedeRentActionButtonsProps> = ({
  loading,
  formId,
  disabled,
  handlerMethod
}: SpeedeRentActionButtonsProps): ReactElement => {
  const { classes } = useStyles();

  return (
    <>
      <LoadingButton
        className={clsx(classes.baseButton, classes.filledButton)}
        data-testid={"handler-button"}
        variant={"contained"}
        size={"large"}
        form={formId ?? "review-form"}
        onClick={handlerMethod}
        type={"button"}
        loading={loading}
        disabled={loading || disabled}
        role={"button"}
      >
        SpeedeRent
      </LoadingButton>
    </>
  );
};

export default SpeedeRentActionButtons;
