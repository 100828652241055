import { Edit, RemoveCircleOutline } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { NavLink } from "react-router-dom";
import React from "react";
import { RevenueClass } from "src/models/RevenueClass";
import { setDisableRevenueClassesOpenModal } from "src/store/reducers/revenueClassesSlice/revenueClassesSlice";
import { setSelectedRevenueClass }
  from "src/store/reducers/revenueClassInformationSlice/revenueClassInformationSlice";
import { useAppDispatch } from "src/store/hooks";

const RenderActionButtons = (params: GridRenderCellParams<any, RevenueClass>) => {
  const dispatch = useAppDispatch();

  return (
    <Grid
      data-testid={"render-details-button"}
      container
      direction={"row"}
      alignItems={"center"}
      justifyContent={"start"}
    >
      <Tooltip title={"Edit/View Revenue Class"}>
        <IconButton size={"small"} to={`/accounting/revenue-classes/${params.row.id}/edit`} component={NavLink}>
          <Edit />
        </IconButton>
      </Tooltip>
      {params.row.is_active && (
        <Tooltip title={"Disable Revenue Class"}>
          <IconButton size={"small"} data-testid={"disable-revenue-class-button"}
            onClick={() => {
              dispatch(setSelectedRevenueClass(params.row));
              dispatch(setDisableRevenueClassesOpenModal(true));
            }
            }>
            <RemoveCircleOutline />
          </IconButton>
        </Tooltip>
      )}
    </Grid>
  );
};
export const RevenueClassesTableColumns = (updateRevenueClassesPermission: boolean): GridColDef[] => {
  const columns: GridColDef[] = [
    {
      field: "name",
      renderHeader: () => <Tooltip title={"Name"}><Typography>Name</Typography></Tooltip>,
      minWidth: 280,
      flex: 1
    },
    {
      field: "",
      renderHeader: () => (
        <Tooltip title={"G/L Number - Account Name"}>
          <Typography>G/L Number - Acct Name</Typography>
        </Tooltip>
      ),
      sortable: false,
      minWidth: 280,
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Tooltip title={row.chart_account
          ? `${row.chart_account?.gl_number} - ${row.chart_account?.account_name}`
          : "N/A"}>
          <Typography variant={"inherit"} noWrap>{row.chart_account
            ? `${row.chart_account?.gl_number} - ${row.chart_account?.account_name}`
            : "N/A"}</Typography>
        </Tooltip>
      )
    },
    {
      field: "sub_category",
      renderHeader: () => <Tooltip title={"Sub-Account"}><Typography>Sub-Acct</Typography></Tooltip>,
      sortable: false,
      minWidth: 180,
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.chart_account?.sub_category || "-"
    },
    {
      field: "tax_total",
      renderHeader: () => (
        <Tooltip title={"Tax Rate (Revenue Class)"}>
          <Typography>Tax Rate (Rev Class)</Typography>
        </Tooltip>
      ),
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.tax_total}%`
    },
    {
      field: "tax_state",
      renderHeader: () => <Tooltip title={"State %"}><Typography>State %</Typography></Tooltip>,
      minWidth: 75,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.tax_state}%`
    },
    {
      field: "tax_city",
      renderHeader: () => <Tooltip title={"City %"}><Typography>City %</Typography></Tooltip>,
      minWidth: 75,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.tax_city}%`
    },
    {
      field: "tax_county",
      renderHeader: () => <Tooltip title={"County %"}><Typography>County %</Typography></Tooltip>,
      minWidth: 100,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.tax_county}%`
    },
    {
      field: "tax_local",
      renderHeader: () => <Tooltip title={"Local %"}><Typography>Local %</Typography></Tooltip>,
      minWidth: 75,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.tax_local}%`
    }
  ];

  if (updateRevenueClassesPermission) {
    columns.push({
      field: "actions",
      renderHeader: () => <Tooltip title={"Actions"}><Typography>Actions</Typography></Tooltip>,
      sortable: false,
      minWidth: 120,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => RenderActionButtons(params)
    });
  }

  return columns;
};

export default RevenueClassesTableColumns;
