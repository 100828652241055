import moment from "moment";
import { z } from "zod";

export const dateSchema = z.custom<moment.Moment>(
  (value) => {
    if (moment.isMoment(value) && value.isValid()) {
      return true;
    } else {
      return false;
    }
  },
  { message: "Invalid date" }
);
