import { FormikErrors, FormikTouched } from "formik";
import { Grid, InputLabel, TextField } from "@mui/material";
import React, { ChangeEvent, FC } from "react";
import moment, { Moment } from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import FacilityFormValues from "src/models/formikInputValues/FacilityFormValues";
import { inputError } from "src/utils/showInputError/showInputError";
import useStyles from "../FacilityForm.styles";

interface SIPConfigurationSectionProps {
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | ChangeEvent<any>) => void;
  };
  touched: FormikTouched<FacilityFormValues>;
  errors: FormikErrors<FacilityFormValues>;
  values: FacilityFormValues;
}

const SIPConfigurationSection: FC<SIPConfigurationSectionProps> = ({ handleChange, touched, errors, values }) => {
  const { classes } = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <InputLabel className={classes.labelWeight} htmlFor={"sip_host"}>
          SIP Host
        </InputLabel>
        <TextField
          id={"sip_host"}
          fullWidth
          value={values.sip_host}
          variant={"outlined"}
          name={"sip_host"}
          onChange={handleChange}
          error={inputError("sip_host", touched, errors).error}
          helperText={inputError("sip_host", touched, errors).helperText}
        />
      </Grid>
      <Grid item xs={3}>
        <InputLabel className={classes.labelWeight} htmlFor={"sip_port"}>
          SIP Port
        </InputLabel>
        <TextField
          id={"sip_port"}
          fullWidth
          value={values.sip_port}
          variant={"outlined"}
          name={"sip_port"}
          onChange={handleChange}
          error={inputError("sip_port", touched, errors).error}
          helperText={inputError("sip_port", touched, errors).helperText}
        />
      </Grid>
      <Grid item xs={3}>
        <InputLabel className={classes.labelWeight} htmlFor={"sip_username"}>
          SIP Username
        </InputLabel>
        <TextField
          id={"sip_username"}
          fullWidth
          value={values.sip_username}
          variant={"outlined"}
          name={"sip_username"}
          onChange={handleChange}
          error={inputError("sip_username", touched, errors).error}
          helperText={inputError("sip_username", touched, errors).helperText}
        />
      </Grid>
      <Grid item xs={3}>
        <InputLabel className={classes.labelWeight} htmlFor={"sip_password"}>
          SIP Password
        </InputLabel>
        <TextField
          id={"sip_password"}
          fullWidth
          value={values.sip_password}
          variant={"outlined"}
          name={"sip_password"}
          onChange={handleChange}
          error={inputError("sip_password", touched, errors).error}
          helperText={inputError("sip_password", touched, errors).helperText}
        />
      </Grid>
    </Grid>
  );
};

export default SIPConfigurationSection;
