import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const prepareForSigning = createAsyncThunk(
  "prepareForSigning",
  ({ id }: { id: number }, { dispatch, rejectWithValue }) => {
    return axios
      .post(`/api/v1/esign-documents/${id}/prepare-for-signing`)
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = "There was an error preparing this document for signature(s)";

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  }
);
