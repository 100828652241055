import { Grid, InputLabel, Paper, TextField, Typography } from "@mui/material";
import React, { FC } from "react";
import {
  selectProductTypeInsuranceDocument,
  selectProductTypeIsInsurable,
  selectProductTypeLeaseDocument,
  selectProductTypeName,
  selectProductTypeProductCategoryId,
  selectProductTypeRevenueClassId
} from "src/store/reducers/productTypeSliceNew/productTypeSliceNew";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import StepLayout from "src/layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "src/layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import { createProductTypeNew } from "src/store/thunks/productTypeNew/create/createProductTypeNew";
import { selectProductCategoriesList } from "src/store/reducers/productCategoriesSlice/productCategoriesSlice";
import { selectRevenueClasses } from "src/store/reducers/revenueClassesSlice/revenueClassesSlice";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { useNavigate } from "react-router";
import { getAllPaginatedProductTypes } from "src/store/thunks/productType/getAllPaginated/getAllPaginatedProductTypes";
import { formatPercentages } from "../AssignRevenueClass/AssignRevenueClass";
import useStyles from "./ReviewProductType.style";

const stepperId = "new-product-type-form";

interface ReviewProductTypeProps {
  handleClose: () => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const ReviewProductType: FC<ReviewProductTypeProps> = ({ handleClose, setStep }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const productTypeName = useAppSelector(selectProductTypeName);
  const navigate = useNavigate();
  const productCategories = useAppSelector(selectProductCategoriesList);
  const revenueClasses = useAppSelector(selectRevenueClasses);
  const productCategoryId = useAppSelector(selectProductTypeProductCategoryId);
  const selectedProductCategory = productCategories.filter((cat) => cat.id === +productCategoryId!)[0] ?? null;
  const selectedRevenueClassId = useAppSelector(selectProductTypeRevenueClassId);
  const selectHasInsurance = Boolean(+useAppSelector(selectProductTypeIsInsurable));
  const selectedRevenueClass = revenueClasses.filter((rc) => rc.id === +selectedRevenueClassId!)[0] ?? null;
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const leaseDoc = useAppSelector(selectProductTypeLeaseDocument);
  const insuranceDoc = useAppSelector(selectProductTypeInsuranceDocument);
  const hasInsurance = Boolean(+useAppSelector(selectProductTypeIsInsurable));

  const handleGoBack = () => {
    setStep((step) => step - 1);
  };

  const submitNewProductType = () => {
    const payload = {
      facility_id: String(selectedFacility?.id),
      name: productTypeName,
      product_category_id: selectedProductCategory.id,
      revenue_class_id: selectedRevenueClassId,
      is_insurable: selectHasInsurance,
      lease_document_id: leaseDoc?.id
    };

    dispatch(createProductTypeNew(payload)).then((resp) => {
      if (resp.type !== "rejected") {
        dispatch(getAllPaginatedProductTypes(selectedFacility?.id));
        handleClose();
        navigate("/product-types/manage-product-types-new");
      }
    });
  };

  return (
    <StepLayout
      padding={2}
      gridDirection={"row"}
      buttons={
        <StepperButtons
          showCancelButton
          onCancel={handleClose}
          stepperId={stepperId}
          forwardActionText={"CREATE PRODUCT TYPE"}
          forwardAction={submitNewProductType}
          backAction={handleGoBack}
        />
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography textAlign={"center"} variant={"h5"}>
            Review Product Type
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography textAlign={"center"}>
            Review your new product type settings and documents before creating and adding to your product type list
          </Typography>
        </Grid>
        <Grid
          container
          // my={2}
          spacing={0.5}
          item
          xs={12}
        >
          <Grid item xs={12}>
            <Typography component={InputLabel} variant={"subtitle1"} color={"black"}
              htmlFor={"name"}>
              Product Type Name
            </Typography>
            <TextField
              id={"name"}
              size={"small"}
              fullWidth
              disabled={true}
              value={productTypeName}
              className={classes.blackDisabledInput}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography component={InputLabel} variant={"subtitle1"} color={"black"}
              htmlFor={"product_category"}>
              Product Category
            </Typography>
            <TextField
              id={"product_category"}
              fullWidth
              size={"small"}
              disabled={true}
              value={selectedProductCategory?.name}
              className={classes.blackDisabledInput}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography component={InputLabel} variant={"subtitle1"} color={"black"}
              htmlFor={"revenue_class"}>
              Revenue Class
            </Typography>
            <TextField
              size={"small"}
              fullWidth
              disabled={true}
              value={selectedRevenueClass?.name}
              className={classes.blackDisabledInput}
            />
          </Grid>
          <Grid item xs={12}>
            {/* rate grid */}
            <Typography component={InputLabel} variant={"subtitle1"} color={"black"}
              htmlFor={"total_tax"}>
              Total Tax Rate
            </Typography>
            <TextField
              size={"small"}
              id={"total_tax"}
              disabled={true}
              value={formatPercentages(selectedRevenueClass?.tax_total)}
              inputProps={{ style: { textAlign: "center" } }}
              InputProps={{ sx: { width: "50%", background: "#F7F7F7" } }}
            />
          </Grid>
          <Grid container item xs={12}
            rowSpacing={1}>
            <Grid item xs={12}>
              <Typography component={InputLabel} variant={"subtitle1"} color={"black"}
                htmlFor={"revenue_class"}>
                Tax Rates
              </Typography>
            </Grid>
            <Grid item xs={6} display={"flex"}
              alignItems={"center"} justifyContent={"space-between"}>
              <Typography component={InputLabel} variant={"subtitle1"} color={"black"}
                htmlFor={"tax_state"}>
                State
              </Typography>
              <TextField
                size={"small"}
                id={"tax_state"}
                disabled={true}
                value={formatPercentages(selectedRevenueClass?.tax_state)}
                inputProps={{ style: { textAlign: "center" } }}
                InputProps={{ sx: { width: "40%", background: "#F7F7F7" } }}
                className={classes.disabledInput}
              />
            </Grid>
            <Grid item xs={6} display={"flex"}
              alignItems={"center"} justifyContent={"space-between"}>
              <Typography component={InputLabel} variant={"subtitle1"} color={"black"}
                htmlFor={"tax_county"}>
                County
              </Typography>
              <TextField
                size={"small"}
                id={"tax_county"}
                disabled={true}
                value={formatPercentages(selectedRevenueClass?.tax_county)}
                inputProps={{ style: { textAlign: "center" } }}
                InputProps={{ sx: { width: "40%", background: "#F7F7F7" } }}
                className={classes.disabledInput}
              />
            </Grid>
            <Grid item xs={6} display={"flex"}
              alignItems={"center"} justifyContent={"space-between"}>
              <Typography component={InputLabel} variant={"subtitle1"} color={"black"}
                htmlFor={"tax_city"}>
                City
              </Typography>
              <TextField
                size={"small"}
                id={"tax_city"}
                disabled={true}
                value={formatPercentages(selectedRevenueClass?.tax_city)}
                inputProps={{ style: { textAlign: "center" } }}
                InputProps={{ sx: { width: "40%", background: "#F7F7F7" } }}
                className={classes.disabledInput}
              />
            </Grid>
            <Grid item xs={6} display={"flex"}
              alignItems={"center"} justifyContent={"space-between"}>
              <Typography component={InputLabel} variant={"subtitle1"} color={"black"}
                htmlFor={"tax_local"}>
                Local
              </Typography>
              <TextField
                size={"small"}
                id={"tax_local"}
                disabled={true}
                value={formatPercentages(selectedRevenueClass?.tax_local)}
                inputProps={{ style: { textAlign: "center" } }}
                InputProps={{ sx: { width: "40%", background: "#F7F7F7" } }}
                className={classes.disabledInput}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography component={InputLabel} variant={"subtitle1"} color={"black"}>
            Insurance
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            size={"small"}
            disabled={true}
            value={hasInsurance ? "Yes" : "No"}
            className={classes.blackDisabledInput}
          />
        </Grid>
        {leaseDoc?.name || insuranceDoc?.name
          ? (
            <Grid container mt={0.5}>
              <Grid item xs={12}>
                <Typography variant={"subtitle1"} color={"black"}>
                  Documents Added:
                </Typography>
              </Grid>
              <Grid container item xs={12}
                component={Paper} variant={"outlined"} elevation={0}
                p={2}>
                <Grid item xs={12}>
                  <Typography>{leaseDoc?.name}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{insuranceDoc?.name}</Typography>
                </Grid>
              </Grid>
            </Grid>
            )
          : null}
      </Grid>
    </StepLayout>
  );
};

export default ReviewProductType;
