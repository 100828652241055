import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { Pageview, RemoveCircleOutline } from "@mui/icons-material";
import {
  setEditModal,
  setRemoveModalOpened,
  setSelectedSetting,
  setSettingModalOpen
} from "src/store/reducers/chartOfAccountsSlice/chartOfAccountsSlice";
import ChartOfAccountsSetting from "src/models/ChartOfAccountsSetting";
import React from "react";
import generateChartOfAccountsCategory from "src/utils/generateChartOfAccountsCategory/generateChartOfAccountsCategory";
import { useAppDispatch } from "src/store/hooks";

export const RenderDetailsButton = (setting: ChartOfAccountsSetting) => {
  const dispatch = useAppDispatch();

  const handleOpenEdit = async() => {
    await dispatch(setSelectedSetting(setting));
    await dispatch(setEditModal(true));
    dispatch(setSettingModalOpen(true));
  };

  const handleOpenRemoveSettingModal = () => {
    dispatch(setSelectedSetting(setting));
    dispatch(setRemoveModalOpened(true));
  };

  return (
    <Grid
      data-testid={"render-details-button"}
      container
      direction={"row"}
      alignItems={"center"}
      justifyContent={"start"}
    >
      <Tooltip title={"View/Edit Settings"}>
        <IconButton aria-label={"Edit Chart of Accounts Setting"} onClick={() => handleOpenEdit()} size={"small"}>
          <Pageview data-testid={"edit-setting-button"} />
        </IconButton>
      </Tooltip>
      {!setting.is_original && (
        <Tooltip title={"Remove Setting"}>
          <IconButton size={"small"} onClick={() => handleOpenRemoveSettingModal()}>
            <RemoveCircleOutline data-testid={"remove-setting-button"} />
          </IconButton>
        </Tooltip>
      )}
    </Grid>
  );
};

export const ChartOfAccountsTableColumns = (updateChartOfAccountsPermission: boolean): GridColDef[] => {
  const columns: GridColDef[] = [
    {
      field: "account_name",
      renderHeader: () => (
        <Tooltip title={"Account Name"}>
          <Typography>Acct Name</Typography>
        </Tooltip>
      ),
      minWidth: 280,
      flex: 1
    },
    {
      field: "category",
      renderHeader: () => (
        <Tooltip title={"Category"}>
          <Typography>Category</Typography>
        </Tooltip>
      ),
      minWidth: 280,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => generateChartOfAccountsCategory(params.row.category)
    },
    {
      field: "sub_category",
      renderHeader: () => (
        <Tooltip title={"Sub-Category"}>
          <Typography>Sub-Category</Typography>
        </Tooltip>
      ),
      minWidth: 280,
      flex: 1
    },
    {
      field: "gl_number",
      renderHeader: () => (
        <Tooltip title={"G/L Number"}>
          <Typography>G/L Number</Typography>
        </Tooltip>
      ),
      minWidth: 280,
      flex: 1
    }
  ];

  if (updateChartOfAccountsPermission) {
    columns.push({
      field: "actionIcons",
      renderHeader: () => (
        <Tooltip title={"Actions"}>
          <Typography>Actions</Typography>
        </Tooltip>
      ),
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => RenderDetailsButton(params.row)
    });
  }

  return columns;
};

export default ChartOfAccountsTableColumns;
