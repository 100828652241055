/* eslint-disable max-len */
import { Button, Divider, Grid, Modal, Paper, Typography } from "@mui/material";
import React, { PropsWithChildren, ReactElement, useState } from "react";
import { resetMerchandiseForRefundSlice, selectRefundedTransactionId } from "src/store/reducers/merchandiseForRefundSlice/merchandiseForRefundSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { useLocation, useNavigate } from "react-router";
import { AppDispatch } from "src/store/rootStore";
import { Breadcrumb } from "src/models/Breadcrumb";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import SendEmailForm from "./SendEmailReceiptForm/SendEmailReceiptForm";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { printReceipt } from "src/store/thunks/moveIn/print/printReceipt";
import { selectCurrentTransaction } from "src/store/reducers/transactionSlice/transactionSlice";
import useStyles from "./MerchandiseRefundReceipt.styles";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Merchandise"
  },
  {
    name: "Refund",
    bold: true
  }
];

const MerchandiseRefundReceipt: React.FC<PropsWithChildren> = ({ children, ...props }): ReactElement => {
  const pageHeader = <PageHeader title={"Refund Merchandise"} breadcrumbs={breadcrumbs} />;

  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const transaction = useAppSelector(selectCurrentTransaction);

  const [open, setOpen] = useState(false);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handlePrintReceipt = () => {
    dispatch(printReceipt(transaction!.id)).then((resp) => {
      if (!resp.type.includes("rejected")) {
        const link = document.createElement("a");
        link.href = resp.payload as string;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  const handleReset = (dispatch: AppDispatch) => {
    dispatch(resetMerchandiseForRefundSlice());
  };

  const handleNavigateToDashboard = () => {
    handleReset(dispatch);
    navigate("/");
  };

  const handleNavigateToSummaryOfSales = () => {
    handleReset(dispatch);
    navigate("/merchandise/summary-of-sales");
  };

  return (
    <ViewWrapper pageHeader={pageHeader} {...props}>
      <Grid container>
        <Grid
          container
          justifyContent={"flex-end"}
          item
          p={2}
          pl={0}
          xs={12}
      >
          <Grid pr={1}>
            <Button className={classes.textButton} variant={"text"} onClick={handleNavigateToDashboard}>
              RETURN TO DASHBOARD
            </Button>
          </Grid>
          <Grid>
            <Button
              className={classes.baseButton}
              variant={"contained"}
              onClick={handleNavigateToSummaryOfSales}
          >
              SUMMARY OF SALES
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper
            mt={1}
            p={4}
            spacing={2}
            container
            component={Grid}
            variant={"outlined"}
            px={6}
        >
            <Grid item xs={12}>
              <Typography variant={"h4"}>Refund Successful - Confirmation # {transaction!.id} </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item my={4} xs={12}>
              <Typography variant={"body1"}>
                Please select an option below to print and/or email the transaction’s receipt if desired.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={"h5"} className={classes.textButton}>
                Transaction Information
              </Typography>
            </Grid>
            <Grid item>
              <Button className={classes.textButton} variant={"outlined"} onClick={handlePrintReceipt}>
                PRINT RECEIPT
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleOpenModal} className={classes.textButton} variant={"outlined"}>
                EMAIL RECEIPT
              </Button>
            </Grid>
          </Paper>
        </Grid>
        <Modal onBackdropClick={handleCloseModal} open={open} className={classes.modal}>
          <Grid
            container
            className={classes.modalContainer}
            component={Paper}
            elevation={0}
        >
            <SendEmailForm closeButtonAction={handleCloseModal} />
          </Grid>
        </Modal>
      </Grid>
    </ViewWrapper>
  );
};

export default MerchandiseRefundReceipt;
