import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getDeal = createAsyncThunk(
  "getDeal",
  ({ facilityId, dealId }: { facilityId: number, dealId: number }, { dispatch, rejectWithValue }) => {
    const params = {
      params: {
        with: ["facility", "unit.productType", "productType", "unit", "promotion", "occupant"]
      }
    };

    return axios.get(`/api/v1/facilities/${facilityId}/deals/${dealId}`, params)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = `There was an error getting the information this Deal`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
