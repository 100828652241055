import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Occupant } from "../../../models/Occupant";
import { OccupantSliceState } from "./OccupantSliceState";
import { RootState } from "../../rootStore";
import { generateDocument } from "src/store/thunks/ledger/generateDocument/generateDocument";
import { getOccupant } from "src/store/thunks/occupant/getOne/getOccupant";
import { savePaymentInstrument } from "src/store/thunks/paymentInstrument/savePaymentInstrument";
import { updateOccupant } from "src/store/thunks/occupant/update/updateOccupant";
import { updateRentalAmount } from "src/store/thunks/occupant/update/rentalAmount/updateRentalAmount";

export const initialState: OccupantSliceState = {
  occupant: JSON.parse(sessionStorage.getItem("occupant") as string) ?? null,
  occupantSearchLoading: false,
  occupantSearchError: "",
  updateRentalAmountLoading: false,
  changeOwnershipModalOpened: false,
  occupantDocumentModalOpen: false,
  pdf: "",
  undoChangeOwnershipModalOpened: false,
  missingFieldsChangeOwnershipModalOpened: false,
  confirmChangeOwnershipModalOpened: false,
  generateDocumentModal: {
    opened: false,
    submitting: false
  },
  changeOwnershipIsEnabled: false,
  changeOwnerShipStep: 0,
  occupantLoading: false,
  selectedDocuments: [],
  idType: 0
};

export const occupantSlice = createSlice({
  name: "occupant",
  initialState,
  reducers: {
    setOccupant: (state, action: PayloadAction<Occupant | null>) => {
      state.occupant = action.payload;
      sessionStorage.setItem("occupant", JSON.stringify(state.occupant ?? null));
    },
    resetOccupantSlice: (state) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("occupant");
    },
    setChangeOwnershipIsEnabled: (state, action: PayloadAction<boolean>) => {
      state.changeOwnershipIsEnabled = action.payload;
    },
    setOccupantDocumentModalOpened: (state, action) => {
      state.occupantDocumentModalOpen = action.payload;
    },
    setPDF: (state, action) => {
      state.pdf = action.payload;
    },
    setChangeOwnershipModalOpened: (state, action: PayloadAction<boolean>) => {
      state.changeOwnershipModalOpened = action.payload;
    },
    setChangeOwnerShipStep: (state, action: PayloadAction<number>) => {
      state.changeOwnerShipStep = action.payload;
    },
    setUndoChangeOwnershipModalOpened: (state, action: PayloadAction<boolean>) => {
      state.undoChangeOwnershipModalOpened = action.payload;
    },
    setMissingFieldsChangeOwnershipModalOpened: (state, action: PayloadAction<boolean>) => {
      state.missingFieldsChangeOwnershipModalOpened = action.payload;
    },
    setConfirmChangeOwnershipModalOpened: (state, action: PayloadAction<boolean>) => {
      state.confirmChangeOwnershipModalOpened = action.payload;
    },
    setGenerateDocumentModalOpened: (state, action: PayloadAction<boolean>) => {
      state.generateDocumentModal.opened = action.payload;
    },
    setOccupantSelectedDocuments: (state, action) => {
      state.selectedDocuments = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateRentalAmount.pending, (state) => {
        state.updateRentalAmountLoading = true;
      })
      .addCase(updateRentalAmount.fulfilled, (state) => {
        state.updateRentalAmountLoading = false;
      })
      .addCase(updateRentalAmount.rejected, (state, action) => {
        state.updateRentalAmountLoading = false;
      })
      .addCase(getOccupant.pending, (state) => {
        state.occupantLoading = true;
      })
      .addCase(getOccupant.fulfilled, (state, action) => {
        state.occupantLoading = false;
        state.occupant = action.payload;
      })
      .addCase(getOccupant.rejected, (state) => {
        state.occupantLoading = false;
      })
      .addCase(updateOccupant.fulfilled, (state, action) => {
        state.occupant = action.payload;
      })
      .addCase(savePaymentInstrument.fulfilled, (state, action) => {
        state.occupant?.payment_instruments?.push(action.payload);
      })
      .addCase(generateDocument.pending, (state) => {
        state.generateDocumentModal.submitting = true;
      })
      .addCase(generateDocument.fulfilled, (state) => {
        state.generateDocumentModal.submitting = false;
        state.generateDocumentModal.opened = false;
      })
      .addCase(generateDocument.rejected, (state) => {
        state.generateDocumentModal.submitting = false;
      });
  }
});

export const selectOccupant = (state: RootState) => state.occupant.occupant;
export const selectOccupantSearchLoading = (state: RootState) => state.occupant.occupantSearchLoading;
export const selectSelectedOccupantFullName = (state: RootState) =>
  state.occupant.occupant ? `${state.occupant.occupant.first_name} ${state.occupant.occupant.last_name}` : "";

export const selectUpdateRentalAmountLoading = (state: RootState) => state.occupant.updateRentalAmountLoading;
export const selectChangeOwnershipModalOpened = (state: RootState) => state.occupant.changeOwnershipModalOpened;
export const selectOccupantDocumentModalOpened = (state: RootState) => state.occupant.occupantDocumentModalOpen;
export const selectChangeOwnershipIsEnabled = (state: RootState) => state.occupant.changeOwnershipIsEnabled;
export const selectChangeOwnerShipStep = (state: RootState) => state.occupant.changeOwnerShipStep;
export const selectPDF = (state: RootState) => state.occupant.pdf;
export const selectUndoChangeOwnershipModalOpened = (state: RootState) => state.occupant.undoChangeOwnershipModalOpened;
export const selectMissingFieldsChangeOwnershipModalOpened = (state: RootState) =>
  state.occupant.missingFieldsChangeOwnershipModalOpened;
export const selectConfirmChangeOwnershipModalOpened = (state: RootState) =>
  state.occupant.confirmChangeOwnershipModalOpened;
export const selectGenerateDocumentModal = (state: RootState) => state.occupant.generateDocumentModal;
export const selectOccupantLoading = (state: RootState) => state.occupant.occupantLoading;
export const selectOccupantSelectedDocuments = (state: RootState) => state.occupant.selectedDocuments;

export const {
  resetOccupantSlice,
  setOccupant,
  setPDF,
  setOccupantDocumentModalOpened,
  setChangeOwnershipModalOpened,
  setChangeOwnershipIsEnabled,
  setChangeOwnerShipStep,
  setUndoChangeOwnershipModalOpened,
  setMissingFieldsChangeOwnershipModalOpened,
  setConfirmChangeOwnershipModalOpened,
  setGenerateDocumentModalOpened,
  setOccupantSelectedDocuments
} = occupantSlice.actions;

export default occupantSlice.reducer;
