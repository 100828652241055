import { axiosInstance as axios } from "src/api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "src/utils/format422/format422";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";

export interface SaveAch {
  accountHolderName: string;
  bankName: string;
  accountNumber: string;
  routingNumber: string;
  accountType: string;
}

export interface SaveCreditCard {
  cardProcessor: string;
  cardHolderName: string;
  cardNumber: string;
  expirationMonth: string;
  expirationYear: string;
  cardSecurityCode: string;
}

export interface SavePaymentInstrumentProps {
  occupantId: string | number;
  ach?: SaveAch;
  creditCard?: SaveCreditCard;
}

export const savePaymentInstrument = createAsyncThunk(
  "savePaymentInstrument",
  (paymentInstrumentData: SavePaymentInstrumentProps, { dispatch, getState, rejectWithValue }) => {
    const { occupantId, ach, creditCard } = paymentInstrumentData;

    let payload;

    if (creditCard) {
      payload = {
        type: 1,
        card_holder_name: creditCard.cardHolderName,
        card_number: creditCard.cardNumber,
        expiration_month: creditCard.expirationMonth,
        expiration_year: creditCard.expirationYear,
        card_security_code: creditCard.cardSecurityCode,
        card_processor: creditCard.cardProcessor === "american-express" ? "american express" : creditCard.cardProcessor
      };
    }

    if (ach) {
      payload = {
        account_holder_name: ach.accountHolderName,
        bank_name: ach.bankName,
        account_number: ach.accountNumber,
        routing_number: ach.routingNumber,
        account_type: ach.accountType === "1" ? "checking" : "savings",
        type: 2
      };
    }

    return axios
      .post(`/api/v1/occupants/${occupantId}/payment-instruments`, payload)
      .then((resp) => {
        dispatch(
          showSnackbar({
            message: "Payment Instrument saved successfully",
            variant: "success"
          })
        );

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(
            showSnackbar({
              message: errorMessage,
              variant: "error"
            })
          );

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an error saving the preferred payment method.";

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  }
);
