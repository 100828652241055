import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  zone: Yup.number()
    .typeError("Zone must be a 2 digits number")
    .test("len", "Zone must be a 2 digits number", function(val) {
      if (val === 0) {
        return true;
      }

      if (!val) {
        return this.createError({ message: "Zone is required" });
      }

      const number = val < 10 ? `0${val}` : val.toString();
      return number.length === 2;
    }).required("Zone is required"),
  accessName: Yup.string().required("Access Name is required")
});

export default validationSchema;
