import { Grid, InputLabel } from "@mui/material";
import React, { ReactElement, useEffect } from "react";

import ACHForm from "../../../../../components/payment/ACHForm/ACHForm";
import CashForm from "../../../../../components/payment/CashForm/CashForm";
import CreditCardForm from "../../../../../components/payment/CreditCardForm/CreditCardForm";
import MoneyOrderForm from "../../../../../components/payment/MoneyOrderForm/MoneyOrderForm";
import { PaymentMethod } from "../../../../../enums/PaymentMethod";
import { PaymentType } from "../../../../../models/PaymentType";
import Typography from "@mui/material/Typography";
import { selectPaymentType } from "../../../../../store/reducers/paymentSlice/paymentSlice";
import { useAppSelector } from "../../../../../store/hooks";
import useStyles from "./PaymentEntry.styles";

interface PaymentEntryProps {
  handleSubmit: () => void;
  formId: string;
  disabled?: boolean;
  withPaymentOptions?: boolean;
  customPaymentType?: number;
}

interface PaymentEntryContent {
  header: string;
  paymentForm: ReactElement | null;
}

const PaymentEntry = ({
  handleSubmit = () => {},
  formId,
  disabled,
  withPaymentOptions = true,
  customPaymentType
}: PaymentEntryProps): ReactElement => {
  const { classes } = useStyles();
  const paymentType = useAppSelector(selectPaymentType);

  const paymentEntryContent = (paymentType: PaymentType | ""): PaymentEntryContent => {
    let header = "Payment Information";
    let paymentForm = null;

    switch (customPaymentType || paymentType) {
      case PaymentMethod.cash:
        paymentForm = <CashForm handleSubmit={handleSubmit} formId={formId} disabled={disabled} />;
        break;
      case PaymentMethod.moneyOrder:
        paymentForm = <MoneyOrderForm handleSubmit={handleSubmit} formId={formId} />;
        break;
      case PaymentMethod.ach:
        header = "ACH Bank Information";
        paymentForm = (
          <ACHForm
            formId={formId}
            disableSave={disabled}
            handleSubmit={handleSubmit}
            withPaymentOptions={withPaymentOptions}
           />
        );
        break;
      case PaymentMethod.creditCard:
        header = "Credit Card Information";
        paymentForm = (
          <CreditCardForm
            formId={formId}
            disableSave={disabled}
            handleSubmit={handleSubmit}
            withPaymentOptions={withPaymentOptions}
          />
        );
        break;
    }

    return { header, paymentForm };
  };

  return (
    <>
      <InputLabel className={classes.headerCell} htmlFor={"payment-entry"}>
        <Typography noWrap>{paymentEntryContent(paymentType).header}</Typography>
      </InputLabel>
      <Grid my={2}>{paymentEntryContent(paymentType).paymentForm}</Grid>
    </>
  );
};

export default PaymentEntry;
