import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "Create Auctions Modal" })((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
    minHeight: "10rem",
    borderRadius: "1rem"
  },
  inner: {
    height: "auto",
    maxHeight: "45rem",
    borderRadius: "1rem",
    overflow: "scroll"
  },
  xs: {
    maxWidth: "35%"
  },
  lg: {
    maxWidth: "85%"
  },
  cancelButton: {
    color: theme.palette.error.contrastText,
    fontWeight: "normal"
  }
}));

export default useStyles;
