import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import React, { ReactElement, useEffect } from "react";
import {
  resetFeeSlice,
  selectFeeLoading,
  selectFeeModalIsEnable,
  selectFeeModalOpen,
  setFee,
  setFeeModalIsEnable,
  setFeeModalOpen
} from "src/store/reducers/feeSlice/feeSlice";
import {
  resetFeesSlice, selectActiveFees,
  selectFees, selectFeesLoading,
  selectInactiveFees, selectShowFeesTypes,
  setShowFeesType
} from "../../store/reducers/feesSlice/feesSlice";
import { resetSelectedAdminFacilitySlice, selectSelectedAdminFacility, setSelectedAdminFacility }
  from "../../store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { AppDispatch } from "../../store/rootStore";
import AppModal from "../../components/ui/AppModal/AppModal";
import { Breadcrumb } from "../../models/Breadcrumb";
import { Facility } from "../../models/Facility";
import FacilitySelector from "../../components/navigation/FacilitySelector/FacilitySelector";
import { Fee } from "../../models/Fee";
import FilterButtonGroup from "../../components/ui/FilterButtonGroup/FilterButtonGroup";
import ItemManagementCard from "../../components/itemManagement/ItemManagementCard/ItemManagementCard";
import ItemManagementLayout from "../../layouts/ItemManagement/ItemManagementLayout";
import { NavLink, useNavigate } from "react-router-dom";
import NoItemsDisplay from "../../components/itemManagement/NoItemsDisplay/NoItemsDisplay";
import PageHeader from "../../components/ui/PageHeader/PageHeader";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import clsx from "clsx";
import { disableFee } from "../../store/thunks/fee/disable/disableFee";
import { CreateFeeAdvisoryText as displayText } from "./CreateFeeAdvisoryText";
import { enableFee } from "../../store/thunks/fee/enable/enableFee";
import { formattedAmount } from "src/utils/formattedAmount/formattedAmount";
import { getAllFacilities } from "../../store/thunks/facility/getAll/getAllFacilities";
import { getAllFeesForFacility } from "../../store/thunks/fee/getAllForFacility/getAllFeesForFacility";
import history from "../../utils/history";
import useStyles from "./Fees.styles";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Fees & Charges"
  },
  {
    name: "Manage Fees",
    bold: true
  }
];

export const handleReset = (dispatch: AppDispatch) => {
  dispatch(resetFeeSlice());
  dispatch(resetFeesSlice());
  dispatch(resetSelectedAdminFacilitySlice());
};

const Fees: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const feesLoading = useAppSelector(selectFeesLoading);
  const selectedFacility = useAppSelector(selectSelectedAdminFacility);
  const showFeesType = useAppSelector(selectShowFeesTypes);
  const activeFees = useAppSelector(selectActiveFees);
  const inActiveFees = useAppSelector(selectInactiveFees);
  const allFees = useAppSelector(selectFees);
  const feeModalOpen = useAppSelector(selectFeeModalOpen);
  const feeModalIsEnable = useAppSelector(selectFeeModalIsEnable);
  const feeLoading = useAppSelector(selectFeeLoading);
  const feeThunk = feeModalIsEnable ? enableFee() : disableFee();
  const { classes } = useStyles();
  const permissions = useAppSelector(selectFacilityPermissions);
  const feesPermissions = createPermissions(permissions, "manage_fees");

  const displayedFees = {
    all: allFees,
    active: activeFees,
    disabled: inActiveFees
  };

  const noFeesMessages = {
    active: "No active fees",
    disabled: "No disabled fees",
    all: "No fees"
  };

  useEffect(() => {
    dispatch(getAllFacilities());
  }, []);

  /* istanbul ignore next */ // cannot test this w/ enzyme
  useEffect(() => {
    const unlisten = history.listen(location => {
      if (!location.location.pathname.includes("fees")) {
        handleReset(dispatch);
      }
    });

    return unlisten;
  }, []);

  const closeFeeModal = () => {
    dispatch(setFeeModalIsEnable(false));
    dispatch(setFeeModalOpen(false));
  };

  const feeConfirmAction = () => {
    dispatch(feeThunk)
      .then(() => {
        dispatch(setFeeModalOpen(false));
        dispatch(getAllFeesForFacility({ facilityId: selectedFacility!.id }));
        dispatch(resetFeeSlice());
      });
  };

  const openFeeModalDisable = (fee: Fee) => {
    dispatch(setFeeModalIsEnable(false));
    dispatch(setFeeModalOpen(true));
    dispatch(setFee(fee));
  };

  const openFeeModalEnable = (fee: Fee) => {
    dispatch(setFeeModalIsEnable(true));
    dispatch(setFeeModalOpen(true));
    dispatch(setFee(fee));
  };

  const filters = !!selectedFacility && (
    <FilterButtonGroup heading={"Show Fees:"} data-testid={"fees-filters"}>
      <Button
        className={clsx(classes.buttonText, showFeesType === "all" ? classes.enabledButton : classes.disabledButton)}
        color={"secondary"}
        data-testid={"all-fees"}
        onClick={() => dispatch(setShowFeesType("all"))}
      >
        All
      </Button>
      <Button
        className={clsx(classes.buttonText, showFeesType === "active" ? classes.enabledButton : classes.disabledButton)}
        data-testid={"active-fees"}
        onClick={() => dispatch(setShowFeesType("active"))}
      >
        Active
      </Button>
      <Button
        className={clsx(classes.buttonText, showFeesType === "disabled"
          ? classes.enabledButton
          : classes.disabledButton)}
        data-testid={"disabled-fees"}
        onClick={() => dispatch(setShowFeesType("disabled"))}
      >
        Disabled
      </Button>
    </FilterButtonGroup>
  );

  const ctaButton = () => {
    return selectedFacility && (
      <Button
        data-testid={"fees-cta-button"}
        className={classes.newFeeContainedButton}
        color={"primary"}
        variant={"contained"}
        component={NavLink}
        to={"/fees/create"}
      >
        New Fee
      </Button>
    );
  };

  const itemButtons = (fee: Fee) => {
    if (feesPermissions.update) {
      if (fee.is_active) {
        return (
          <>
            <Grid item>
              <Tooltip title={"Disable Fee"}>
                <IconButton
                  data-testid={"item-management-confirm"}
                  color={"error"}
                  aria-label={"delete-button"}
                  onClick={() => openFeeModalDisable(fee)}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Edit/View Fee"}>
                <IconButton
                  data-testid={"item-management-cancel"}
                  onClick={() => navigate(`/fees/${fee.id}/edit`)}
                  color={"primary"}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            </Grid>
          </>
        );
      }

      return (
        <Button
          data-testid={"item-management-cancel"}
          className={classes.editButton}
          variant={"contained"}
          size={"large"}
          onClick={() => openFeeModalEnable(fee)}
          >
          Re-Enable
        </Button>
      );
    }
  };

  const bottomRow = (fee: Fee) => (
    <>
      <Grid
        item
        xs={6}
        pr={2}
        lg={"auto"}
      >
        <span className={classes.cellTitle}>Fee Amount: </span>{formattedAmount(fee.type, fee.amount)}
      </Grid>
      <Grid
        item
        xs={6}
        pr={2}
        lg={"auto"}
      >
        <span className={classes.cellTitle}>
          Fee Recurrence: </span>{fee.recurrence === 1 ? "One-Time Charge" : "Monthly Recurring"}
      </Grid>
      <Grid
        item
        xs={6}
        pr={2}
        lg={"auto"}
      >
        <span className={classes.cellTitle}>Revenue Class: </span>{fee.revenue_class?.name}
      </Grid>
      <Grid
        item
        xs={6}
        pr={2}
        lg={"auto"}
      >
        <span className={classes.cellTitle}>Fee Category: </span>{fee.fee_category.name}
      </Grid>
    </>
  );

  const feesList = (
    <Grid mt={1} data-testid={"fees-list"}>
      {displayedFees[showFeesType].map(fee => (
        <ItemManagementCard
          blurb={
            <Tooltip title={fee.description}>
              <Typography noWrap variant={"subtitle1"} className={classes.description}>
                {fee.description}
              </Typography>
            </Tooltip>
          }
          data-testid={"item-management-card-" + fee.id}
          buttonGroup={itemButtons(fee)}
          bottomRow={bottomRow(fee)}
          key={fee.id}
          item={fee}
        />
      ))}
    </Grid>
  );

  const feesDisplay = () => {
    if (!selectedFacility) {
      return null;
    }

    if (!displayedFees[showFeesType].length) {
      return (
        <NoItemsDisplay
          item={`fee`}
          link={"/fees/create"}
          message={noFeesMessages[showFeesType]}
        />
      );
    }

    return feesList;
  };

  const handleOnFacilityChange = async(selectedFacility: Facility) => {
    await dispatch(setSelectedAdminFacility(selectedFacility));
    dispatch(getAllFeesForFacility({ facilityId: selectedFacility.id }));
  };

  useEffect(() => {
    if (selectedFacility && !displayedFees[showFeesType].length) {
      handleOnFacilityChange(selectedFacility);
    }
  }, [selectedFacility, displayedFees[showFeesType]]);

  const title = (
    <Grid item container alignItems={"center"}>
      <Grid item>Active Fees for:</Grid>
      <Grid item ml={2}>
        <FacilitySelector
          data-testid={"revenue-classes-facility-selector"}
          navBar={false}
          onFacilityChange={handleOnFacilityChange}
        />
      </Grid>
    </Grid>
  );

  const pageHeader = <PageHeader title={"Manage Fees"} breadcrumbs={breadcrumbs} />;

  if (feesPermissions.read) {
    return (
      <>
        <ViewWrapper pageHeader={pageHeader} >
          <ItemManagementLayout
            title={title}
            filters={filters}
            headerButton={feesPermissions.create ? ctaButton() : null}
            loading={feesLoading}
          >
            {feesDisplay()}
          </ItemManagementLayout>
        </ViewWrapper>
        <AppModal
          data-testid={"fee-modal"}
          confirmActionLoading={feeLoading}
          confirmAction={() => feeConfirmAction()}
          cancelAction={() => closeFeeModal()}
          onCloseAction={() => closeFeeModal()}
          open={feeModalOpen}
        >
          <Typography gutterBottom variant={"h6"}>{displayText(feeModalIsEnable).title}</Typography>
          <Typography gutterBottom>{displayText(feeModalIsEnable).advisoryText}</Typography>
        </AppModal>
      </>
    );
  } else {
    return (
      <ViewWrapper pageHeader={pageHeader} >
        <h1>You do not have permission to view this page.</h1>
      </ViewWrapper>
    );
  }
};

export default Fees;
