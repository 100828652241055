import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "WebhookForm" })((theme) => ({
  header: {
    width: "100%",
    borderBottom: "1px solid #ddd",
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  inactiveFilter: {
    color: theme.palette.text.disabled
  },
  activeFilter: {
    color: theme.palette.text.primary
  },
  buttonBase: {
    textTransform: "capitalize",
    marginLeft: theme.spacing(2)
  },
  cancelButton: {
    color: theme.palette.primary.contrastText
  },
  saveButton: {
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText,
    width: "10rem"
  }
}));

export default useStyles;
