import { Button, Menu, MenuItem, Typography } from "@mui/material";
import { FileDownloadOutlined } from "@mui/icons-material";
import React from "react";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { exportChartOfAccountsSettingsAsExcel }
  from "src/store/thunks/chartOfAccounts/export/exportChartOfAccountsSettingsAsExcel";
import { selectSelectedAdminFacility } from "src/store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import useStyles from "./ExportChartOfAccountsAsExcel.styles";

const ExportChartOfAccountsAsExcel = ({ anchorEl, setAnchorEl }: any) => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const selectedFacility = useAppSelector(selectSelectedAdminFacility);

  const handleExport = () => {
    dispatch(exportChartOfAccountsSettingsAsExcel()).then((resp) => {
      if (!resp.type.includes("rejected")) {
        const link = document.createElement("a");
        link.href = resp.payload as string;
        link.setAttribute("download", `settings-facility-${selectedFacility?.facility_id}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  return (
    <Menu
      id={"basic-menu"}
      anchorEl={anchorEl}
      onClick={() => setAnchorEl(null)}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      MenuListProps={{
        "aria-labelledby": "basic-button"
      }}
>
      <MenuItem onClick={() => handleExport()} className={classes.menuItem}>
        <Button
          data-testid={"excel-option"}
          endIcon={<FileDownloadOutlined sx={{ color: "#306DC8" }}/>}>
          <Typography className={classes.buttonText} >Export as Excel</Typography>
        </Button>
      </MenuItem>
    </Menu>
  );
};

export default ExportChartOfAccountsAsExcel;
