import React from "react";
import { Route } from "react-router";
import ChooseMerch from "src/pages/Merchandise/PointOfSales/ChooseRetailMerch/ChooseRetailMerch";
import MerchSummary from "src/pages/Merchandise/PointOfSales/MerchSummary/MerchSummary";
import ReviewAndPay from "src/pages/Merchandise/PointOfSales/ReviewAndPay/ReviewAndPay";
import SelectOccupantPoS from "src/pages/Merchandise/PointOfSales/SelectOccupantPoS/SelectOccupantPoS";
import PointOfSales from "src/pages/Merchandise/PointOfSales/PointOfSales";

const groupName = "merchandise/point-of-sales";

const PointOfSalesRoutes = (
  <>
    <Route
      path={groupName}
      element={
        <PointOfSales>
          <SelectOccupantPoS />
        </PointOfSales>
      }
    />
    <Route
      path={`${groupName}/choose-merch`}
      element={
        <PointOfSales>
          <ChooseMerch />
        </PointOfSales>
      }
    />
    <Route
      path={`${groupName}/review-and-pay`}
      element={
        <PointOfSales>
          <ReviewAndPay />
        </PointOfSales>
      }
    />
    <Route
      path={`${groupName}/merchandise-summary`}
      element={
        <PointOfSales>
          <MerchSummary />
        </PointOfSales>
      }
    />
  </>
);

export default PointOfSalesRoutes;
