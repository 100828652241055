import {
  Alert,
  Divider,
  FormControlLabel,
  FormHelperTextProps,
  Grid,
  InputLabel,
  Radio,
  SelectChangeEvent,
  TextField,
  TextFieldProps,
  Typography
} from "@mui/material";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import React, { ChangeEvent, FC, FormEvent, useEffect, useState } from "react";
import {
  selectAppointmentFor,
  selectDealType,
  selectExpiresOn,
  selectFollowup,
  selectNeededOn,
  selectReferralType,
  setAppointmentFor,
  setDealType,
  setExpiresOn,
  setFollowup,
  setNeededOn,
  setReferralType
} from "../../../../../store/reducers/dealInformationSlice/dealInformationSlice";
import {
  selectDealDaysLimitSetting,
  selectEnableLeadDealTypeSetting,
  selectReservationDaysLimitSetting
} from "src/store/reducers/globalSettingInformationSlice/globalSettingInformationSlice";
import {
  selectFacilityDealDaysLimitSetting,
  selectFacilityReservationDaysLimitSetting
} from "src/store/reducers/facilitySettingInformationSlice/facilitySettingInformationSlice";
import {
  selectQuotedRate,
  selectSelectedProductType,
  selectSelectedUnit,
  setSelectedProductType,
  setSelectedUnit
} from "../../../../../store/reducers/selectedUnitSlice/selectedUnitSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { Deal } from "../../../../../models/Deal";
import { DealType } from "../../../../../models/DealType";
import { DealTypeEnum } from "../../../../../enums/DealType";
import EditDealFooterButtons from "../../EditDealHelpers/EditDealFooterButtons/EditDealFooterButtons";
import EmptyTable from "../../../../../components/ui/PMSTable/EmptyTable/EmptyTable";
import PMSRadio from "../../../../../components/ui/PMSRadio/PMSRadio";
import PMSSelect from "../../../../../components/ui/PMSSelect/PMSSelect";
import { ReferralType } from "../../../../../models/ReferralType";
import SelectPromotion from "../../../../../components/stepper/SelectPromotion/SelectPromotion";
import SelectUnit from "../../../../../components/stepper/SelectUnit/SelectUnit";
import UpdateDealPayload from "../../../../../store/thunks/deals/update/UpdateDealPayload";
import editDealInformationValidation from "./editDealInformationValidation";
import { formatDate } from "../../../../../utils/__dateAndTimeUtils__/formatDate/formatDate";
import { getAllFacilitySettings } from "src/store/thunks/facilitySetting/getAll/getAllFacilitySettings";
import { getAllGlobalSettings } from "src/store/thunks/globalSetting/getAll/getAllGlobalSettings";
import { getAllProductTypes } from "../../../../../store/thunks/productType/getAll/getAllProductTypes";
import { inputError } from "../../../../../utils/showInputError/showInputError";
import moment from "moment";
import { resetOpenExpansionRows } from "../../../../../store/reducers/tablesSlice/tablesSlice";
import { selectDeal } from "../../../../../store/reducers/dealSlice/dealSlice";
import { selectProductTypes } from "../../../../../store/reducers/productTypesSlice/productTypesSlice";
import { selectSelectedFacility } from "../../../../../store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import {
  selectSelectedPromotion
} from "../../../../../store/reducers/selectedPromotionSlice/selectedPromotionSlice";
import { updateDeal } from "../../../../../store/thunks/deals/update/updateDeal";
import { useFormik } from "formik";
import { useNavigate } from "react-router";

const EditDealInformation: FC = () => {
  const navigate = useNavigate();
  const formId = "edit-deal-information-form";
  const dispatch = useAppDispatch();
  const [dateLimit, setDateLimit] = useState<number|undefined>(undefined);
  const deal = useAppSelector(selectDeal) as Deal;
  const referralType = useAppSelector(selectReferralType);
  const dealType = useAppSelector(selectDealType);
  const neededOn = useAppSelector(selectNeededOn);
  const followup = useAppSelector(selectFollowup);
  const expiresOn = useAppSelector(selectExpiresOn);
  const appointmentFor = useAppSelector(selectAppointmentFor);
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const selectedProductType = useAppSelector(selectSelectedProductType);
  const selectedUnit = useAppSelector(selectSelectedUnit);
  const productTypes = useAppSelector(selectProductTypes);
  const quotedRate = useAppSelector(selectQuotedRate);
  const selectedPromotion = useAppSelector(selectSelectedPromotion);

  //day limits
  const globalReservationDaysLimitSetting = useAppSelector(selectReservationDaysLimitSetting);
  const facilityReservationDaysLimitSetting = useAppSelector(selectFacilityReservationDaysLimitSetting);
  const globalDealDaysLimitSetting = useAppSelector(selectDealDaysLimitSetting);
  const facilityDealDaysLimitSetting = useAppSelector(selectFacilityDealDaysLimitSetting);

  const enableLeadDealTypeSetting = useAppSelector(selectEnableLeadDealTypeSetting);
  const enableLeadDealType = Number(enableLeadDealTypeSetting?.value) === 1;

  const radioItems = (enableLeadDealType: any) => {
    const items = [
      { value: "lead", label: "Lead", disabled: !enableLeadDealType },
      { value: "reservation", label: "Reservation", disabled: false }
    ];

    const selectedValue = enableLeadDealType ? "lead" : "reservation";

    return items.map(item => (
      <FormControlLabel
        key={item.value}
        value={item.value}
        label={item.label}
        control={<Radio />}
        disabled={item.disabled}
      />
    ));
  };

  const formik = useFormik({
    initialValues: {
      productType: selectedProductType,
      promotionId: selectedPromotion?.id,
      unitId: selectedUnit?.id,
      unit: selectedUnit,
      expiresOn: expiresOn,
      neededOn: neededOn,
      dealType: dealType,
      appointmentFor: appointmentFor,
      followup: followup,
      quotedPrice: quotedRate,
      referralType: referralType
    },
    enableReinitialize: true,
    validationSchema: editDealInformationValidation,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      const payload: UpdateDealPayload = {
        facility_id: selectedFacility.id,
        occupant_id: deal?.occupant_id,
        product_type_id: values.productType?.id as number,
        ...(values.promotionId && values.dealType === "reservation" && { promotion_id: values.promotionId }),
        reservation_expires_at: values.dealType === "reservation" ? moment(values.expiresOn!).format("YYYY-MM-DD") : "",
        deal_type: DealTypeEnum[values.dealType],
        appointment_at:
          values.dealType === "reservation" ? moment(values.appointmentFor!).format("YYYY-MM-DD hh:mm:ss") : "",
        needed_at: moment(values.neededOn!).format("YYYY-MM-DD"),
        cancelled_at: deal?.cancelled_at!,
        contact_at: moment(values.followup!).format("YYYY-MM-DD"),
        last_contact_at: deal?.last_contact_at!,
        quoted_price: parseFloat(values.quotedPrice),
        first_name: deal?.first_name,
        middle_name: deal?.middle_name!,
        last_name: deal?.last_name,
        email: deal?.email,
        phone: deal?.phone,
        address: deal?.address,
        address_two: deal?.address_two,
        city: deal?.city,
        region: deal?.city,
        postal_code: deal?.postal_code,
        country: deal?.country,
        referral_type: values.referralType === "" ? null : values.referralType,
        source: deal?.source,
        cancel_note: deal?.cancel_note!,
        unit_id: values.unitId
      };

      dispatch(updateDeal({ payload, deal_id: deal?.id })).then((resp) => {
        if (!resp.type.includes("rejected")) {
          navigate("/deals/manage-deals");
        }
      });
    }
  });

  const { touched, errors, values } = formik;

  const updateForm = (fieldName: string, fieldValue?: string | boolean | null): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const updateDealType = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSelectedProductType(null));
    dispatch(resetOpenExpansionRows());
    dispatch(setSelectedUnit(null));
    dispatch(setDealType(e.target.value as DealType));
    updateForm("dealType", e.target.value as DealType);
  };

  const updateNeededOn = (e: string | moment.Moment | null) => {
    dispatch(setNeededOn(formatDate(e, "MM/DD/YYYY", "N/A")));
    updateForm("neededOn", formatDate(e, "MM/DD/YYYY", "N/A"));
  };

  const updateAppointmentFor = (e: string | moment.Moment | null) => {
    dispatch(setAppointmentFor(formatDate(e, "MM/DD/YYYY, hh:mm A", "N/A")));
    updateForm("appointmentFor", formatDate(e, "MM/DD/YYYY, hh:mm A", "N/A"));
  };

  const updateExpiresOn = (e: string | moment.Moment | null) => {
    dispatch(setExpiresOn(formatDate(e, "MM/DD/YYYY", "N/A")));
    updateForm("expiresOn", formatDate(e, "MM/DD/YYYY", "N/A"));
  };

  const updateFollowup = (e: string | moment.Moment | null) => {
    dispatch(setFollowup(formatDate(e, "MM/DD/YYYY", "N/A")));
    updateForm("followup", formatDate(e, "MM/DD/YYYY", "N/A"));
  };

  const updateReferralType = (e: SelectChangeEvent<any>) => {
    dispatch(setReferralType(e.target.value));
    updateForm("referralType", e.target.value as ReferralType);
  };

  useEffect(() => {
    if (selectedFacility) {
      dispatch(getAllProductTypes(selectedFacility.id));
      dispatch(getAllGlobalSettings());
      dispatch(getAllFacilitySettings(String(selectedFacility.id)));
    }
  }, []);

  useEffect(() => {
    if (dealType === "reservation" && globalReservationDaysLimitSetting?.value &&
     facilityReservationDaysLimitSetting?.value === undefined) {
      return setDateLimit(+globalReservationDaysLimitSetting?.value);
    }

    if (dealType === "reservation" && facilityReservationDaysLimitSetting?.value) {
      return setDateLimit(+facilityReservationDaysLimitSetting?.value);
    }

    if (dealType === "lead" && globalDealDaysLimitSetting?.value && facilityDealDaysLimitSetting?.value === undefined) {
      return setDateLimit(+globalDealDaysLimitSetting?.value);
    }

    if (dealType === "lead" && facilityDealDaysLimitSetting?.value) {
      return setDateLimit(+facilityDealDaysLimitSetting?.value);
    }
  }, [
    dealType,
    globalReservationDaysLimitSetting,
    facilityReservationDaysLimitSetting,
    globalDealDaysLimitSetting,
    facilityDealDaysLimitSetting
  ]);

  return (
    <Grid
      component={"form"}
      id={formId}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        formik.handleSubmit();
      }}
    >
      {dealType === "reservation" && (
        <Alert severity={"warning"} data-testid={"selection-alert"}>
          You must select a unit in the next step for a reservation.
        </Alert>
      )}
      <Typography variant={"h6"} gutterBottom mt={1}>
        Deal Information
      </Typography>
      <Divider />
      <Grid container my={2} spacing={2}>
        <Grid xs={6} lg={3} item
          container>
          <PMSRadio
            label={"What type of deal is this?"}
            name={"dealType"}
            value={values.dealType}
            changeHandler={(e: ChangeEvent<HTMLInputElement>) => updateDealType(e)}
          >
            <Grid xs={12} item>
              {radioItems(enableLeadDealType)}
            </Grid>
          </PMSRadio>
        </Grid>
        <Grid xs={6} lg={3} item
          container>
          <PMSSelect
            id={"referral-type"}
            name={"referralType"}
            label={"How did you hear about us?"}
            value={values.referralType}
            changeHandler={(e) => updateReferralType(e)}
          >
            <option value={""}>
              - Referral Type -
            </option>
            <option value={"referral"}>Referral</option>
            <option value={"phone call"}>Phone call</option>
            <option value={"email message"}>E-mail message</option>
            <option value={"walk-in"}>Walk-in</option>
            <option value={"website"}>Website</option>
            <option value={"web chat"}>Web Chat</option>
            <option value={"unknown"}>Unknown</option>
          </PMSSelect>
        </Grid>
      </Grid>
      <Grid container item xs={12}
        alignContent={"space-between"} spacing={2}>
        <Grid item xs={3}>
          <InputLabel htmlFor={"needed-on"}>Rental Date:</InputLabel>
          <DatePicker
            renderInput={(params: TextFieldProps) => (
              <TextField
                onKeyDown={(e) => e.preventDefault()}
                {...params}
                inputProps={{ ...params.inputProps, placeholder: "- Rental Date -" }}
                name={"neededOn"}
                id={"needed-on"}
                fullWidth
                FormHelperTextProps={{ "data-testid": "needed-on-validation" } as Partial<FormHelperTextProps<"p", {}>>}
                error={inputError("neededOn", touched, errors).error}
                helperText={inputError("neededOn", touched, errors).helperText}
              />
            )}
            OpenPickerButtonProps={{ "aria-label": "Select Rental Date" }}
            componentsProps={{
              leftArrowButton: { "aria-label": "Move Backward" },
              rightArrowButton: { "aria-label": "Move Forward" }
            }}
            maxDate={moment().add(dateLimit, "days")}
            minDate={moment()}
            inputFormat={"MM/DD/yyyy"}
            data-testid={"needed-on"}
            value={values.neededOn}
            onChange={(value: moment.Moment | null, keyboardInputValue?: string | undefined) => updateNeededOn(value)}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel htmlFor={"follow-up"}>Follow-Up Date:</InputLabel>
          <DatePicker
            renderInput={(params: TextFieldProps) => (
              <TextField
                onKeyDown={(e) => e.preventDefault()}
                {...params}
                inputProps={{ ...params.inputProps, placeholder: "- Follow-Up Date -" }}
                name={"followup"}
                id={"follow-up"}
                fullWidth
                FormHelperTextProps={{ "data-testid": "follow-up-validation" } as Partial<FormHelperTextProps<"p", {}>>}
                error={inputError("followup", touched, errors).error}
                helperText={inputError("followup", touched, errors).helperText}
              />
            )}
            OpenPickerButtonProps={{ "aria-label": "Select Follow-Up Date" }}
            componentsProps={{
              leftArrowButton: { "aria-label": "Move Backward" },
              rightArrowButton: { "aria-label": "Move Forward" }
            }}
            minDate={moment()}
            inputFormat={"MM/DD/yyyy"}
            data-testid={"follow-up"}
            value={values.followup}
            onChange={(value: moment.Moment | null, keyboardInputValue?: string | undefined) => updateFollowup(value)}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel htmlFor={"appointmentFor"}>Appointment Date:</InputLabel>
          <DateTimePicker
            renderInput={(params: TextFieldProps) => {
              return (
                <TextField
                  {...params}
                  onKeyDown={(e) => e.preventDefault()}
                  fullWidth
                  FormHelperTextProps={
                    { "data-testid": "appointment-for-validation" } as Partial<FormHelperTextProps<"p", {}>>
                  }
                  name={"appointmentFor"}
                  id={"appointmentFor"}
                  inputProps={{ ...params.inputProps, placeholder: "- Appointment Date -" }}
                  error={values.dealType === "reservation" && inputError("appointmentFor", touched, errors).error}
                  helperText={
                    values.dealType === "reservation" && inputError("appointmentFor", touched, errors).helperText
                  }
                />
              );
            }}
            disabled={values.dealType === "lead"}
            minDate={moment()}
            data-testid={"appointment-for"}
            value={values.appointmentFor}
            onChange={(value: moment.Moment | null, keyboardInputValue?: string | undefined) =>
              updateAppointmentFor(value)
            }
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel htmlFor={"expires-on"}>Expiration Date:</InputLabel>
          <DatePicker
            renderInput={(params: TextFieldProps) => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps, placeholder: "- Expiration Date -" }}
                fullWidth
                FormHelperTextProps={
                  { "data-testid": "expires-on-validation" } as Partial<FormHelperTextProps<"p", {}>>
                }
                name={"expiresOn"}
                onKeyDown={(e) => e.preventDefault()}
                id={"expires-on"}
                error={values.dealType === "reservation" && inputError("expiresOn", touched, errors).error}
                helperText={values.dealType === "reservation" && inputError("expiresOn", touched, errors).helperText}
              />
            )}
            OpenPickerButtonProps={{ "aria-label": "Select Expiration Date" }}
            componentsProps={{
              leftArrowButton: { "aria-label": "Move Backward" },
              rightArrowButton: { "aria-label": "Move Forward" }
            }}
            disabled={values.dealType === "lead"}
            minDate={moment()}
            inputFormat={"MM/DD/yyyy"}
            data-testid={"reservation-expires-on"}
            value={values.expiresOn}
            onChange={(value: moment.Moment | null, keyboardInputValue?: string | undefined) => updateExpiresOn(value)}
          />
        </Grid>
        <Grid container pl={2}>
          {productTypes.length
            ? (
              <SelectUnit
                chipLocation={"top"}
                data-testid={"select-unit-table"}
                validationError={errors.productType || errors.unit}
            />
              )
            : (
              <EmptyTable
                data-testid={"empty-deal-product-types"}
                collectionNotFound={"product types"}
                value={"product type"}
                addLink={"/product-types/add-product-type"}
            />
              )}
        </Grid>
        {dealType === "reservation" && <SelectPromotion chipLocation={"top"} />}
      </Grid>
      <EditDealFooterButtons formId={formId} />
    </Grid>
  );
};

export default EditDealInformation;
