import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "EditInsurancePlan" })((theme) => ({
  buttonBase: {
    textTransform: "capitalize",
    marginLeft: theme.spacing(2)
  },
  cancelButton: {
    color: theme.palette.primary.contrastText
  },
  saveButton: {
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText,
    width: "10rem"
  }
}));

export default useStyles;
