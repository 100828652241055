import { Grid, InputAdornment, InputLabel, TextField } from "@mui/material";
import React, { ChangeEvent, FormEvent, ReactElement, SyntheticEvent, useEffect, useMemo, useRef } from "react";
import {
  resetCreditCardSlice,
  selectCCCVV,
  selectCCCVVMasked,
  selectCCExpiration,
  selectCCName,
  selectCCNumber,
  selectCCNumberMasked,
  selectCCType,
  setCVV,
  setCVVMasked,
  setExpiration,
  setName,
  setNumber,
  setNumberMasked
} from "../../../store/reducers/creditCardSlice/creditCardSlice";
import {
  selectAutomaticPaymentSlice,
  selectAutomaticPaymentsCCCVV,
  selectAutomaticPaymentsCCCVVMasked,
  selectAutomaticPaymentsCCExpiration,
  selectAutomaticPaymentsCCName,
  selectAutomaticPaymentsCCNumber,
  selectAutomaticPaymentsCCNumberMasked,
  selectAutomaticPaymentsCCType,
  setAutomaticPaymentsCreditCardForm
} from "src/store/reducers/automaticPaymentsSlice/automaticPaymentsSlice";
import {
  selectCreditCardBackup,
  selectCreditCardForm,
  setCreditCardBackup,
  setCreditCardForm
} from "src/store/reducers/changeOwnership/changeOwnership";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import { AUTOMATIC_PAYMENTS_FORM_ID }
  from "src/pages/Occupants/EditOccupant/EditOccupantTabs/RentedUnits/AutomaticPaymentsModal/AutomaticPaymentsModal";
import { CreditCard } from "@mui/icons-material";
import MaskedExpirationDate from "../../masking/MaskedExpirationDate/MaskedExpirationDate";
import { PaymentMethod as PaymentMethodEnum } from "src/enums/PaymentMethod";
import PaymentOptions from "../PaymentOptions/PaymentOptions";
import creditCardFormValidation from "./creditCardFormValidation";
import creditCardFormValidationAmex from "./creditCardFormValidationAmex";
import { inputError } from "../../../utils/showInputError/showInputError";
import { selectChangeOwnershipIsEnabled } from "src/store/reducers/occupantSlice/occupantSlice";
import { selectFirstName } from "src/store/reducers/occupantInformationSlice/occupantInformationSlice";
import { useFormik } from "formik";
import useCardSwipe from "src/hooks/useCardSwipe";

interface CreditCardFormProps {
  handleSubmit: () => void;
  formId: string;
  disableSave?: boolean;
  withPaymentOptions?: boolean;
}

export const standardCCRegex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
export const parsedCCNumbers = (ccNumbers: string) => ccNumbers.replace(/[^\d]/g, "");

export const formatStandard = (ccNumbers: string) => {
  return parsedCCNumbers(ccNumbers).replace(standardCCRegex, (standardCCRegex, $1, $2, $3, $4) => {
    return [$1, $2, $3, $4].filter((group) => !!group).join(" ");
  });
};

const formatAmex = (ccNumbers: string) => {
  return ccNumbers.replace(/\D/g, "")
    .replace(/(\d{4})(\d)/, "$1 $2")
    .replace(/(\d{4})(\d)/, "$1 $2")
    .replace(/(\d{4})(\d{1,3})/, "$1 $2");
};

export const maskStandard = (ccNumbers: string) => {
  return parsedCCNumbers(ccNumbers).replace(standardCCRegex, (standardCCRegex, $1, $2, $3, $4) => {
    return [$1.replace(/./g, "*"), $2.replace(/./g, "*"), $3.replace(/./g, "*"), $4]
      .filter((group) => !!group)
      .join(" ");
  });
};

export const maskAmex = (ccNumbers: string) => {
  return ccNumbers.replace(/\D/g, "")
    .replace(/(\d{4})(\d)/, "**** $2")
    .replace(/(\d{4})(\d)/, "**** $2")
    .replace(/(\d{4})(\d{1,3})/, "**** $2");
};

export const maskCVV = (cvv: string) => "*".repeat(cvv?.length);

const CreditCardForm: React.FC<CreditCardFormProps> = ({
  handleSubmit,
  formId,
  disableSave,
  withPaymentOptions
}): ReactElement => {
  const dispatch = useAppDispatch();

  const isAutomaticPaymentsForm = formId === AUTOMATIC_PAYMENTS_FORM_ID;

  const ccNumber = useAppSelector(isAutomaticPaymentsForm ? selectAutomaticPaymentsCCNumber : selectCCNumber);
  const ccNumberMasked = useAppSelector(isAutomaticPaymentsForm
    ? selectAutomaticPaymentsCCNumberMasked
    : selectCCNumberMasked);
  const ccName = useAppSelector(isAutomaticPaymentsForm ? selectAutomaticPaymentsCCName : selectCCName);
  const ccExpiration = useAppSelector(isAutomaticPaymentsForm
    ? selectAutomaticPaymentsCCExpiration
    : selectCCExpiration);
  const ccCVV = useAppSelector(isAutomaticPaymentsForm ? selectAutomaticPaymentsCCCVV : selectCCCVV);
  const ccCVVMasked = useAppSelector(isAutomaticPaymentsForm ? selectAutomaticPaymentsCCCVVMasked : selectCCCVVMasked);
  const ccType = useAppSelector(isAutomaticPaymentsForm ? selectAutomaticPaymentsCCType : selectCCType);
  const changeOwnershipIsEnabled = useAppSelector(selectChangeOwnershipIsEnabled);
  const ccChangeOwnershipForm = useAppSelector(selectCreditCardForm);
  const ccChangeOwnershipBackup = useAppSelector(selectCreditCardBackup);
  // todo: update the selector to render accountType in occupant Billing Information for a better one in the future
  const isOccupantBillingInformation = useAppSelector(selectFirstName);
  const { automaticPaymentsCreditCardForm } = useAppSelector(selectAutomaticPaymentSlice);
  const [swipeData, resetSwipeData] = useCardSwipe();
  const previousSwipeData = useRef(swipeData);

  const formik = useFormik({
    initialValues: {
      ccNumber: ccNumberMasked,
      ccName: ccName,
      ccExpiration: ccExpiration,
      ccCVV: ccCVVMasked
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: ccType === "american-express" ? creditCardFormValidationAmex : creditCardFormValidation,
    onSubmit: () => {
      handleSubmit();
    }
  });

  useEffect(() => {
    return () => {
      !isOccupantBillingInformation && dispatch(resetCreditCardSlice());
    };
  }, []);

  const updateForm = (fieldName: string, fieldValue?: string | boolean): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleNameOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    if (changeOwnershipIsEnabled) {
      dispatch(setCreditCardForm({ ...ccChangeOwnershipForm, ccName: fieldValue }));
    } else if (isAutomaticPaymentsForm) {
      dispatch(setAutomaticPaymentsCreditCardForm({
        ...automaticPaymentsCreditCardForm,
        name: fieldValue
      }));
    } else {
      dispatch(setName(fieldValue));
    }
  };

  const handleExpirationOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    if (changeOwnershipIsEnabled) {
      dispatch(setCreditCardForm({ ...ccChangeOwnershipForm, ccExpiration: fieldValue }));
    } else if (isAutomaticPaymentsForm) {
      dispatch(setAutomaticPaymentsCreditCardForm({
        ...automaticPaymentsCreditCardForm,
        expiration: fieldValue
      }));
    } else {
      dispatch(setExpiration(fieldValue));
    }
  };

  const handleNumberOnChange = (fieldName: string, fieldValue: string) => {
    if (changeOwnershipIsEnabled) {
      const updatedValue =
        ccChangeOwnershipForm.ccType === "american-express" ? formatAmex(fieldValue) : formatStandard(fieldValue);
      updateForm(fieldName, updatedValue);
      dispatch(setCreditCardForm({ ...ccChangeOwnershipForm, ccNumber: updatedValue }));
    } else if (isAutomaticPaymentsForm) {
      const updatedValue =
        ccChangeOwnershipForm.ccType === "american-express" ? formatAmex(fieldValue) : formatStandard(fieldValue);
      dispatch(setAutomaticPaymentsCreditCardForm({
        ...automaticPaymentsCreditCardForm,
        number: fieldValue
      }));
      updateForm(fieldName, updatedValue);
    } else {
      const updatedValue = ccType === "american-express" ? formatAmex(fieldValue) : formatStandard(fieldValue);
      updateForm(fieldName, updatedValue);
      dispatch(setNumber(updatedValue));
    }
  };

  const handleCCOnFocus = () => {
    if (changeOwnershipIsEnabled) {
      updateForm("ccNumber", ccChangeOwnershipForm.ccNumber);
      dispatch(setCreditCardForm({ ...ccChangeOwnershipForm, ccNumber: ccChangeOwnershipForm.ccNumber }));
    } else if (isAutomaticPaymentsForm) {
      updateForm("ccNumber", automaticPaymentsCreditCardForm?.number);
      dispatch(setAutomaticPaymentsCreditCardForm({
        ...automaticPaymentsCreditCardForm,
        number: ccNumber
      }));
    } else {
      updateForm("ccNumber", ccNumber);
      dispatch(setNumber(ccNumber));
    }
  };

  const handleCCOnBlur = (e: any) => {
    if (changeOwnershipIsEnabled) {
      const updatedMaskedNumber =
        ccChangeOwnershipForm.ccType === "american-express" ? maskAmex(e.target.value) : maskStandard(e.target.value);
      updateForm("ccNumber", updatedMaskedNumber);
      dispatch(setCreditCardForm({ ...ccChangeOwnershipForm, ccNumberMasked: updatedMaskedNumber }));
    } else if (isAutomaticPaymentsForm) {
      const updatedMaskedNumber =
        automaticPaymentsCreditCardForm?.type === "american-express"
          ? maskAmex(e.target.value)
          : maskStandard(e.target.value);
      updateForm("ccNumber", updatedMaskedNumber);
      dispatch(setAutomaticPaymentsCreditCardForm({
        ...automaticPaymentsCreditCardForm,
        numberMasked: updatedMaskedNumber
      }));
    } else {
      const updatedMaskedNumber =
        ccType === "american-express" ? maskAmex(e.target.value) : maskStandard(e.target.value);
      updateForm("ccNumber", updatedMaskedNumber);
      dispatch(setNumberMasked(updatedMaskedNumber));
    }
  };

  const handleCVVOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, parsedCCNumbers(fieldValue));
    if (changeOwnershipIsEnabled) {
      dispatch(setCreditCardForm({ ...ccChangeOwnershipForm, ccCVV: parsedCCNumbers(fieldValue) }));
    } else if (isAutomaticPaymentsForm) {
      dispatch(setAutomaticPaymentsCreditCardForm({
        ...automaticPaymentsCreditCardForm, cvv: parsedCCNumbers(fieldValue)
      }));
    } else {
      dispatch(setCVV(parsedCCNumbers(fieldValue)));
    }
  };

  const handleCVVOnFocus = () => {
    if (changeOwnershipIsEnabled) {
      updateForm("ccCVV", ccChangeOwnershipForm.ccCVV);
      dispatch(setCreditCardForm({ ...ccChangeOwnershipForm, ccCVV: ccChangeOwnershipForm.ccCVV }));
    } else if (isAutomaticPaymentsForm) {
      updateForm("ccCVV", automaticPaymentsCreditCardForm?.cvv);
      dispatch(setCreditCardForm({ ...automaticPaymentsCreditCardForm, cvv: automaticPaymentsCreditCardForm?.cvv }));
    } else {
      updateForm("ccCVV", ccCVV);
      dispatch(setCVV(ccCVV));
    }
  };

  const handleCVVOnBlur = () => {
    if (changeOwnershipIsEnabled) {
      updateForm("ccCVV", maskCVV(ccChangeOwnershipForm.ccCVV));
      dispatch(setCreditCardForm({ ...ccChangeOwnershipForm, ccVVMasked: maskCVV(ccCVV!) }));
    } else if (isAutomaticPaymentsForm) {
      updateForm("ccCVV", maskCVV(automaticPaymentsCreditCardForm?.cvv!));
      dispatch(setCreditCardForm({ ...automaticPaymentsCreditCardForm, cvvMasked: maskCVV(ccCVV!) }));
    } else {
      updateForm("ccCVV", maskCVV(ccCVV!));
      dispatch(setCVVMasked(maskCVV(ccCVV!)));
    }
  };

  const { touched, errors, values, initialValues, resetForm } = formik;

  useEffect(() => {
    if (changeOwnershipIsEnabled) {
      if (initialValues.ccNumber && !ccChangeOwnershipForm.ccNumber) {
        const values = {
          ccNumber: initialValues.ccNumber,
          ccName: initialValues.ccName,
          ccExpiration: initialValues.ccExpiration,
          ccCVV: initialValues.ccCVV,
          ccType
        };

        dispatch(setCreditCardBackup(values));
      }

      const form = {
        ccNumber: ccChangeOwnershipForm.ccNumber,
        ccName: ccChangeOwnershipForm.ccName,
        ccExpiration: ccChangeOwnershipForm.ccExpiration,
        ccCVV: ccChangeOwnershipForm.ccCVV
      };

      resetForm({
        values: form
      });
    } else {
      if (ccChangeOwnershipBackup.ccNumber) {
        const form = {
          ccNumber: ccChangeOwnershipBackup.ccNumber,
          ccName: ccChangeOwnershipBackup.ccName,
          ccExpiration: ccChangeOwnershipBackup.ccExpiration,
          ccCVV: ccChangeOwnershipBackup.ccCVV
        };

        resetForm({ values: form });
      }
    }
  }, [changeOwnershipIsEnabled]);
  function expDateStringToNumber(expDate: string): string {
    // Remove any non-digit characters
    const cleanedInput = expDate.replace(/\D/g, "");

    // Ensure we have at least 4 digits
    if (cleanedInput.length < 4) {
      throw new Error("Invalid expiration date format");
    }

    // Take the first 4 digits and convert to a number
    const numberOutput = cleanedInput.slice(0, 4);

    return numberOutput;
  }
  useEffect(() => {
    if (swipeData?.full_name && swipeData.exp_date && swipeData.cc_number) {
      if (
        swipeData.full_name !== previousSwipeData.current?.full_name ||
        swipeData.exp_date !== previousSwipeData.current?.exp_date ||
        swipeData.cc_number !== previousSwipeData.current?.cc_number
      ) {
        handleNameOnChange("ccName", swipeData.full_name);
        handleNumberOnChange("ccNumber", swipeData.cc_number);
        handleExpirationOnChange("ccExpiration", expDateStringToNumber(swipeData.exp_date));

        const updatedMaskedNumber =
        ccType === "american-express" ? maskAmex(swipeData.cc_number) : maskStandard(swipeData.cc_number);
        updateForm("ccNumber", updatedMaskedNumber);
        dispatch(setNumberMasked(updatedMaskedNumber));
        resetSwipeData();
        previousSwipeData.current = swipeData;
      }
    }
  }, [swipeData]);
  return (
    <Grid
      component={"form"}
      autoComplete={"off"}
      id={formId}
      spacing={2}
      container
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        formik.handleSubmit();
      }}
    >
      <Grid component={"form"} item xs={8}>
        <InputLabel htmlFor={"cc-number"}>Card Number</InputLabel>
        <TextField
          fullWidth
          placeholder={"Card Number"}
          autoComplete={"cc-csc"}
          id={"cc-number"}
          inputProps={{
            "data-mask-sensitive": true,
            "data-testid": "cc-number",
            maxLength: ccType === "american-express" ? 18 : 19
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"}>
                <CreditCard />
              </InputAdornment>
            )
          }}
          name={"nu"}
          value={values.ccNumber}
          onFocus={() => handleCCOnFocus()}
          onBlur={(e: SyntheticEvent) => handleCCOnBlur(e)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleNumberOnChange("ccNumber", e.target.value)}
          error={inputError("ccNumber", touched, errors).error}
          helperText={inputError("ccNumber", touched, errors).helperText}
        />
      </Grid>
      <Grid component={"form"} item xs={4}>
        <InputLabel htmlFor={"cc-expiration"}>Exp Date</InputLabel>
        <MaskedExpirationDate
          id={"cc-expiration"}
          dataTestId={"cc-expiration"}
          fullWidth
          variant={"outlined"}
          name={"ex"}
          type={"tel"}
          placeholder={"MM/YY"}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleExpirationOnChange("ccExpiration", e.target.value)}
          value={useMemo(() => values.ccExpiration, [values.ccExpiration])!}
          error={inputError("ccExpiration", touched, errors).error}
          helperText={inputError("ccExpiration", touched, errors).helperText}
        />
      </Grid>
      <Grid component={"form"} item xs={8}>
        <InputLabel htmlFor={"cc-name"}>Cardholder Name</InputLabel>
        <TextField
          fullWidth
          placeholder={"Name on card"}
          id={"cc-name"}
          autoComplete={"cc-csc"}
          inputProps={{ "data-testid": "cc-name" }}
          name={"na"}
          value={values.ccName}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleNameOnChange("ccName", e.target.value)}
          error={inputError("ccName", touched, errors).error}
          helperText={inputError("ccName", touched, errors).helperText}
        />
      </Grid>
      <Grid component={"form"} item xs={4}>
        <InputLabel htmlFor={"cc-cvv"}>CVV</InputLabel>
        <TextField
          fullWidth
          placeholder={"CVV"}
          id={"cc-cvv"}
          autoComplete={"cc-csc"}
          data-mask-sensitive={true}
          inputProps={{
            "data-testid": "cc-cvv",
            maxLength: ccType === "american-express" ? 4 : 3
          }}
          name={"cv"}
          type={"tel"}
          value={values.ccCVV}
          onFocus={() => handleCVVOnFocus()}
          onBlur={() => handleCVVOnBlur()}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleCVVOnChange("ccCVV", e.target.value)}
          error={inputError("ccCVV", touched, errors).error}
          helperText={inputError("ccCVV", touched, errors).helperText}
        />
      </Grid>
      {withPaymentOptions && (
        <Grid m={1}>
          <PaymentOptions disabled={disableSave} paymentMethod={PaymentMethodEnum.creditCard} />
        </Grid>
      )}

    </Grid>
  );
};

export default CreditCardForm;
