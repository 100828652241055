import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "src/utils/format422/format422";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";

export interface CreateNewInventoryPayload {
  name: string
  sku: string
  quantity: string
  shipping_cost: string
  cost_of_goods_sold: string
}

export const createNewInventory = createAsyncThunk("createNewInventory", async(
  { merchandiseId, payload }: {merchandiseId:number, payload: CreateNewInventoryPayload},
  { dispatch, rejectWithValue }) => {
  return axios.post(`/api/v1/merchandise/${merchandiseId}/merchandise-inventories`, payload)
    .then((resp) => {
      dispatch(showSnackbar({
        message: "New Inventory created",
        variant: "success"
      }));

      return resp.data;
    })
    .catch((err) => {
      let errorMessage: string;
      if (err.response.status === 422) {
        errorMessage = format422(err.response.data.errors);

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      }

      errorMessage = "There was an error creating the Inventory";

      if (err.response.status === 400) {
        errorMessage = err.response.data.detail;
      }

      dispatch(showSnackbar({
        message: errorMessage,
        variant: "error"
      }));

      return rejectWithValue(errorMessage);
    });
}
);
