import { Grid, Paper } from "@mui/material";
import React, { FC, SyntheticEvent, useState } from "react";
import Box from "@mui/material/Box";
import LoadingSpinner from "src/components/ui/LoadingSpinner/LoadingSpinner";
import Tab from "@mui/material/Tab";
import { TabComponentListItem } from "../../models/TabComponentListItem";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import useStyles from "./TabView.styles";

interface TabViewProps {
  components: TabComponentListItem[]
  loading?: boolean
  showPaper?: boolean
  innerPadding?: boolean
  onChangeAction?: (step: number) => void
  step?: number
}

const TabView: FC<TabViewProps> = ({
  components,
  showPaper = true,
  innerPadding = true,
  loading,
  onChangeAction,
  step
}) => {
  const { classes } = useStyles();

  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (onChangeAction) {
      onChangeAction(newValue);
    }
  };

  const borderColor = "#0000001f";

  return (
    <Box className={classes.tabContainer}>
      <TabContext value={step !== undefined ? String(step) : String(value)}>
        <Box >
          <TabList onChange={handleChange} aria-label={"Tab View component tabs"}>
            {components.map((component, i) => (
              <Tab
                key={i}
                label={component.name}
                value={String(i)}
                disabled={component.disabled || false}
              />
            ))}
          </TabList>
        </Box>
        <Grid
          mt={3}
          p={innerPadding ? 3 : 0}
          component={showPaper ? Paper : Grid}
          elevation={0}
          border={showPaper ? 1 : 0}
          borderColor={showPaper ? borderColor : undefined}
          container
          direction={"column"}
        >
          {loading && <LoadingSpinner />}
          {!loading && components.map((component, i) => (
            <TabPanel
              className={innerPadding ? undefined : classes.noPad}
              key={i}
              value={String(i)}
            >{component.component}</TabPanel>
          ))
          }
        </Grid>
      </TabContext>
    </Box>
  );
};

export default TabView;
