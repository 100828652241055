import {
  Autocomplete,
  Button,
  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography
} from "@mui/material";
import React, { FormEvent, ReactElement, useEffect, useState } from "react";
import {
  selectAlternativeRentalFlowSetting,
  selectAutoBillTemplateSetting,
  selectDealDaysLimitSetting,
  selectDefaultEmailSetting,
  selectDefaultSenderSetting,
  selectESignTemplateSetting,
  selectEnableLeadDealTypeSetting,
  selectEnableProratedMoveOutSetting,
  selectGlobalFlagIndividualUnitForAuctionSetting,
  selectInvoiceTemplateSetting,
  selectMoveInDaysLimitSetting,
  selectMoveOutMarkDebtSetting,
  selectPastDueEmailTemplateSetting,
  selectQuickPayEmailTemplateSetting,
  selectReceiptTemplateSetting,
  selectReservationDaysLimitSetting,
  selectReservationRemoveUnitFromInventorySetting,
  selectSmsTemplateSetting
} from "src/store/reducers/globalSettingInformationSlice/globalSettingInformationSlice";
import {
  selectDocumentTemplateLoading,
  selectDocumentTemplates
} from "src/store/reducers/documentsSlice/documentsSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import AuctionSettings from "./Tabs/AuctionSettings";
import { Box } from "@mui/system";
import { Breadcrumb } from "../../models/Breadcrumb";
import { LoadingButton } from "@mui/lab";
import { NavLink } from "react-router-dom";
import PMSSwitch from "src/components/ui/PMSSwitch/PMSSwitch";
import PageHeader from "../../components/ui/PageHeader/PageHeader";
import { SyntheticInputEvent } from "react-number-format/types/types";
import TabView from "src/layouts/TabView/TabView";
import ViewWrapper from "../../layouts/ViewWrapper/ViewWrapper";
import buildGlobalSettingsPayload from "./helpers/buildGlobalSettingPayload";
import clsx from "clsx";
import {
  getAllDocumentTemplatesAllFacilities
} from "src/store/thunks/documentTemplates/getAll/getAllDocumentTemplatesAllFacilities";
import { getAllGlobalSettings } from "../../store/thunks/globalSetting/getAll/getAllGlobalSettings";
import {
  updateGlobalSettings
} from "src/store/thunks/globalSetting/batchUpdate/batchUpdateGlobalSettings";
import { useFormik } from "formik";
import useStyles from "./GlobalSetting.styles";
import validationSchema from "./helpers/globalSettingsFormValidation";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Admin Settings"
  },
  {
    name: "Global Settings",
    bold: true
  }
];

export interface GlobalSettingsUpdateValues {
  reservationDateLimit: string
  dealDate: string
  moveInDate: string
  facilityReceiptTemplate: string
  facilityInvoiceTemplate: string
  quickPayEmailTemplate: string
  facilityAutobillTemplate: string
  pastDueEmailTemplate: string
  eSignEmailTemplate: string
  smsTemplate: string
  flagIndividualUnitForAuction: boolean
  moveOutMarkBadDebt: boolean
  alternativeRentalFlow: boolean
  reservationRemoveUnitFromInventory: boolean
  enableProratedMoveOut: boolean
  enableLeadDealType: boolean
  defaultEmail: string
  defaultSender: string
}

const GlobalSettings: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const documentTemplates = useAppSelector(selectDocumentTemplates);
  const documentTemplatesLoading = useAppSelector(selectDocumentTemplateLoading);

  const reservationDaysLimitSetting = useAppSelector(selectReservationDaysLimitSetting);
  const dealDaysLimitSetting = useAppSelector(selectDealDaysLimitSetting);
  const receiptTemplateSetting = useAppSelector(selectReceiptTemplateSetting);
  const invoiceTemplateSetting = useAppSelector(selectInvoiceTemplateSetting);
  const autoBillTemplateSetting = useAppSelector(selectAutoBillTemplateSetting);
  const eSignTemplateSetting = useAppSelector(selectESignTemplateSetting);
  const pastDueEmailTemplateSetting = useAppSelector(selectPastDueEmailTemplateSetting);
  const quickPayEmailTemplateSetting = useAppSelector(selectQuickPayEmailTemplateSetting);
  const smsTemplateSetting = useAppSelector(selectSmsTemplateSetting);
  const flagIndividualUnitForAuctionSetting = useAppSelector(selectGlobalFlagIndividualUnitForAuctionSetting);
  const reservationRemoveUnitFromInventorySetting = useAppSelector(selectReservationRemoveUnitFromInventorySetting);
  const moveOutMarkBadDebtSetting = useAppSelector(selectMoveOutMarkDebtSetting);
  const moveInDaysLimitSetting = useAppSelector(selectMoveInDaysLimitSetting);
  const enableMoveOutProrationSetting = useAppSelector(selectEnableProratedMoveOutSetting);
  const enableLeadDealTypeSetting = useAppSelector(selectEnableLeadDealTypeSetting);
  const alternativeRentalFlowSetting = useAppSelector(selectAlternativeRentalFlowSetting);
  const defaultEmailSetting = useAppSelector(selectDefaultEmailSetting);
  const defaultSenderSetting = useAppSelector(selectDefaultSenderSetting);

  const [tabIndex, setTabIndex] = useState(0);

  const permissions = useAppSelector(selectFacilityPermissions);
  const globalSettingsPermissions = createPermissions(permissions, "global_settings");

  useEffect(() => {
    dispatch(getAllGlobalSettings());
    dispatch(getAllDocumentTemplatesAllFacilities());
  }, []);

  const initialValues: GlobalSettingsUpdateValues = {
    reservationDateLimit: reservationDaysLimitSetting?.value ?? "",
    dealDate: dealDaysLimitSetting?.value ?? "",
    moveInDate: moveInDaysLimitSetting?.value ?? "",
    facilityReceiptTemplate: receiptTemplateSetting?.value ?? "",
    facilityInvoiceTemplate: invoiceTemplateSetting?.value ?? "",
    quickPayEmailTemplate: quickPayEmailTemplateSetting?.value ?? "",
    facilityAutobillTemplate: autoBillTemplateSetting?.value ?? "",
    pastDueEmailTemplate: pastDueEmailTemplateSetting?.value ?? "",
    eSignEmailTemplate: eSignTemplateSetting?.value ?? "",
    smsTemplate: smsTemplateSetting?.value ?? "",
    flagIndividualUnitForAuction: !!Number(flagIndividualUnitForAuctionSetting?.value) ?? "",
    moveOutMarkBadDebt: !!Number(moveOutMarkBadDebtSetting?.value) ?? "",
    alternativeRentalFlow: !!Number(alternativeRentalFlowSetting?.value) ?? "",
    reservationRemoveUnitFromInventory: !!Number(reservationRemoveUnitFromInventorySetting?.value) ?? "",
    enableProratedMoveOut: !!Number(enableMoveOutProrationSetting?.value) ?? "",
    enableLeadDealType: !!Number(enableLeadDealTypeSetting?.value) ?? "",
    defaultEmail: defaultEmailSetting?.value ?? "",
    defaultSender: defaultSenderSetting?.value ?? ""
  };

  const [autocompleteOpen, setAutocompleteOpen] = useState(false);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!documentTemplates.length) {
        // we should probably not allow submission if the settings if the templates fail to fetch!
        return;
      }

      const payload = buildGlobalSettingsPayload(
        reservationDaysLimitSetting?.id!,
        dealDaysLimitSetting?.id!,
        receiptTemplateSetting?.id!,
        invoiceTemplateSetting?.id!,
        autoBillTemplateSetting?.id!,
        eSignTemplateSetting?.id!,
        pastDueEmailTemplateSetting?.id!,
        quickPayEmailTemplateSetting?.id!,
        smsTemplateSetting?.id!,
        flagIndividualUnitForAuctionSetting?.id!,
        moveOutMarkBadDebtSetting?.id!,
        moveInDaysLimitSetting?.id!,
        alternativeRentalFlowSetting?.id!,
        reservationRemoveUnitFromInventorySetting?.id!,
        enableMoveOutProrationSetting?.id!,
        enableLeadDealTypeSetting?.id!,
        defaultEmailSetting?.id!,
        defaultSenderSetting?.id!,
        values
      );

      dispatch(updateGlobalSettings(payload));
    }
  });

  const { handleChange, errors, values, handleSubmit, setFieldValue } = formik;

  const pageHeader = <PageHeader title={"System Settings"} breadcrumbs={breadcrumbs} />;

  const SystemSettings = () => {
    return (
      <form
        id={"global-settings-form"}
        onSubmit={(e: FormEvent) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h5"}>
              System Settings
            </Typography>
          </Grid>
          <Grid mb={1} item xs={12}>
            <Divider />
          </Grid>

          <Grid container alignItems={"center"} gap={1}>
            <Typography py={2} className={classes.blueText}>
              E-mail Settings
            </Typography>
            <Grid item style={{ flexGrow: 1 }}>
              <Divider style={{ borderColor: "#1976D2" }} />
            </Grid>
          </Grid>
          <Grid mb={1} item xs={12}>
            <InputLabel className={classes.labelWeight} htmlFor={"email"}>Email</InputLabel>
            <TextField
              id={"email"}
              fullWidth
              value={values.defaultEmail}
              variant={"outlined"}
              name={"defaultEmail"}
              onChange={handleChange}
            />
          </Grid>

          <Grid mb={1} item xs={12}>
            <InputLabel className={classes.labelWeight} htmlFor={"sender"}>Sender</InputLabel>
            <TextField
              id={"sender"}
              fullWidth
              value={values.defaultSender}
              variant={"outlined"}
              name={"defaultSender"}
              onChange={handleChange}
            />
          </Grid>

          <Grid container alignItems={"center"} gap={1}>
            <Typography py={2} className={classes.blueText}>
              Date Allowance Settings
            </Typography>
            <Grid item style={{ flexGrow: 1 }}>
              <Divider style={{ borderColor: "#1976D2" }} />
            </Grid>
          </Grid>
          <Grid mb={1} item xs={12}>
            <InputLabel className={classes.labelWeight} htmlFor={"reservationDateLimit"}>
              Reservation Date Limit
            </InputLabel>
            <Typography >
              When the number of days is entered into this global setting feature,
              no reservation date can be set beyond this total number of days in the future.
            </Typography>
            <TextField
              id={"reservationDateLimit"}
              name={"reservationDateLimit"}
              type={"number"}
              value={values.reservationDateLimit}
              onChange={handleChange}
              error={(Boolean(errors.reservationDateLimit))}
              onInput={(e: SyntheticInputEvent) => {
                const val = (e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3));
                return val;
              }}
            />
            <FormHelperText error>
              {errors.reservationDateLimit}
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.labelWeight} htmlFor={"dealDate"}>Lead Date Limit</InputLabel>
            <Typography >
              When the number of days is entered into this global setting feature,
              no lead date can be set beyond this total number of days in the future.
            </Typography>
            <TextField
              id={"dealDate"}
              name={"dealDate"}
              type={"number"}
              value={values.dealDate}
              onChange={handleChange}
              error={(Boolean(errors.dealDate))}
              onInput={(e: SyntheticInputEvent) => {
                const val = (e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3));
                return val;
              }}
            />
            <FormHelperText error>
              {errors.dealDate}
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.labelWeight} htmlFor={"moveInDate"}>Move-In Date Limit</InputLabel>
            <Typography >
              When the number of days is entered into this global setting feature,
              no move-in date can be set beyond this total number of days in the future.
            </Typography>
            <TextField
              id={"moveInDate"}
              name={"moveInDate"}
              type={"number"}
              value={values.moveInDate}
              onChange={handleChange}
              error={(Boolean(errors.moveInDate))}
              onInput={(e: SyntheticInputEvent) => {
                const val = (e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3));
                return val;
              }}
            />
            <FormHelperText error>
              {errors.moveInDate}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid mt={2}>
          <Grid container alignItems={"center"} gap={1}>
            <Typography pb={2} className={classes.blueText}>
              Document Template Settings
            </Typography>
            <Grid item style={{ flexGrow: 1 }}>
              <Divider style={{ borderColor: "#1976D2", marginBottom: "15px" }} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Typography className={classes.labelWeight}>
                Facility Templates
              </Typography>
            </Grid>
            <Grid container item xs={6}>
              <Grid item xs={12} pb={2}>
                <InputLabel
                  className={classes.labelWeight}
                  htmlFor={"facilityReceiptTemplate"}
                >
                  Facility Receipt Template
                </InputLabel>
                <Autocomplete
                  onOpen={() => setAutocompleteOpen(true)}
                  onClose={() => setAutocompleteOpen(false)}
                  id={"facilityReceiptTemplate"}
                  defaultValue={documentTemplates.find(template =>
                    template.id === +values.facilityReceiptTemplate)}
                  value={documentTemplates.find(template => template.id === +values.facilityReceiptTemplate)}
                  options={documentTemplates}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <Box component={"li"} {...props} key={option.id}>
                      {option.name}
                    </Box>
                  )}
                  onChange={(event, option) => {
                    if (option?.id) {
                      return setFieldValue("facilityReceiptTemplate", String(option.id));
                    };
                    setFieldValue("facilityReceiptTemplate", undefined);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={clsx({ [classes.autocompleteError]: errors.facilityReceiptTemplate })}
                    />
                  )}
                />
                <FormHelperText error>
                  {errors.facilityReceiptTemplate}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} pb={2}>
                <InputLabel className={classes.labelWeight} htmlFor={"facilityInvoiceTemplate"}>
                  Facility Invoice Template
                </InputLabel>
                <Autocomplete
                  onOpen={() => setAutocompleteOpen(true)}
                  onClose={() => setAutocompleteOpen(false)}
                  id={"facilityInvoiceTemplate"}
                  defaultValue={documentTemplates.find(template =>
                    template.id === +values.facilityInvoiceTemplate)}
                  value={documentTemplates.find(template => template.id === +values.facilityInvoiceTemplate)}
                  options={documentTemplates}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <Box component={"li"} {...props} key={option.id}>
                      {option.name}
                    </Box>
                  )}
                  onChange={(event, option) => {
                    if (option?.id) {
                      return setFieldValue("facilityInvoiceTemplate", String(option.id));
                    };
                    setFieldValue("facilityInvoiceTemplate", undefined);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={clsx({ [classes.autocompleteError]: errors.facilityInvoiceTemplate })}
                    />
                  )}
                />
                <FormHelperText error>
                  {errors.facilityInvoiceTemplate}
                </FormHelperText>

              </Grid>
              <Grid item xs={12} pb={2}>
                <InputLabel className={classes.labelWeight} htmlFor={"facilityAutobillTemplate"}>
                  Facility Auto-Bill Template
                </InputLabel>
                <Autocomplete
                  onOpen={() => setAutocompleteOpen(true)}
                  onClose={() => setAutocompleteOpen(false)}
                  id={"facilityAutobillTemplate"}
                  defaultValue={documentTemplates.find(template =>
                    template.id === +values.facilityAutobillTemplate)}
                  value={documentTemplates.find(template => template.id === +values.facilityAutobillTemplate)}
                  options={documentTemplates}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <Box component={"li"} {...props} key={option.id}>
                      {option.name}
                    </Box>
                  )}
                  onChange={(event, option) => {
                    if (option?.id) {
                      return setFieldValue("facilityAutobillTemplate", String(option.id));
                    };
                    setFieldValue("facilityAutobillTemplate", undefined);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={clsx({ [classes.autocompleteError]: errors.facilityAutobillTemplate })}
                    />
                  )}
                />
                <FormHelperText error>
                  {errors.facilityAutobillTemplate}
                </FormHelperText>
              </Grid>
            </Grid>
          </Grid>
          <Grid my={1} container>
            <Grid item xs={6}>
              <Typography className={classes.labelWeight}>
                Communication Templates
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12} pb={2}>
                <InputLabel
                  className={classes.labelWeight}
                  htmlFor={"eSignEmailTemplate"}
                >
                  E-Sign Email Template
                </InputLabel>
                <Autocomplete
                  onOpen={() => setAutocompleteOpen(true)}
                  onClose={() => setAutocompleteOpen(false)}
                  id={"eSignEmailTemplate"}
                  defaultValue={documentTemplates.find(template =>
                    template.id === +values.eSignEmailTemplate)}
                  value={documentTemplates.find(template => template.id === +values.eSignEmailTemplate)}
                  options={documentTemplates}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <Box component={"li"} {...props} key={option.id}>
                      {option.name}
                    </Box>
                  )}
                  onChange={(event, option) => {
                    if (option?.id) {
                      return setFieldValue("eSignEmailTemplate", String(option.id));
                    };
                    setFieldValue("eSignEmailTemplate", undefined);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={clsx({ [classes.autocompleteError]: errors.eSignEmailTemplate })}
                    />
                  )}
                />
                <FormHelperText error>
                  {errors.eSignEmailTemplate}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} pb={2}>
                <InputLabel
                  className={classes.labelWeight}
                  htmlFor={"pastDueEmailTemplate"}
                >
                  Past Due Email Template
                </InputLabel>
                <Autocomplete
                  id={"pastDueEmailTemplate"}
                  defaultValue={documentTemplates.find(template =>
                    template.id === +values.pastDueEmailTemplate)}
                  value={documentTemplates.find(template => template.id === +values.pastDueEmailTemplate)}
                  options={documentTemplates}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <Box component={"li"} {...props} key={option.id}>
                      {option.name}
                    </Box>
                  )}
                  onChange={(event, option) => {
                    if (option?.id) {
                      return setFieldValue("pastDueEmailTemplate", String(option.id));
                    };
                    setFieldValue("pastDueEmailTemplate", undefined);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={clsx({ [classes.autocompleteError]: errors.pastDueEmailTemplate })}
                    />
                  )}
                />
                <FormHelperText error>
                  {errors.pastDueEmailTemplate}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} pb={2}>
                <InputLabel className={classes.labelWeight} htmlFor={"quickPayEmailTemplate"}>
                  QuickPay Email Template
                </InputLabel>
                <Autocomplete
                  onOpen={() => setAutocompleteOpen(true)}
                  onClose={() => setAutocompleteOpen(false)}
                  id={"quickPayEmailTemplate"}
                  defaultValue={documentTemplates.find(template =>
                    template.id === +values.quickPayEmailTemplate)}
                  value={documentTemplates.find(template => template.id === +values.quickPayEmailTemplate)}
                  options={documentTemplates}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <Box component={"li"} {...props} key={option.id}>
                      {option.name}
                    </Box>
                  )}
                  onChange={(event, option) => {
                    if (option?.id) {
                      return setFieldValue("quickPayEmailTemplate", String(option.id));
                    };
                    setFieldValue("quickPayEmailTemplate", undefined);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={clsx({ [classes.autocompleteError]: errors.quickPayEmailTemplate })}
                    />
                  )}
                />
                <FormHelperText error>
                  {errors.quickPayEmailTemplate}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} pb={2}>
                <InputLabel className={classes.labelWeight} htmlFor={"smsTemplate"}>
                  SMS Template
                </InputLabel>
                <Autocomplete
                  onOpen={() => setAutocompleteOpen(true)}
                  onClose={() => setAutocompleteOpen(false)}
                  id={"smsTemplate"}
                  defaultValue={documentTemplates.find(template =>
                    template.id === +values.smsTemplate)}
                  value={documentTemplates.find(template => template.id === +values.smsTemplate)}
                  options={documentTemplates}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <Box component={"li"} {...props} key={option.id}>
                      {option.name}
                    </Box>
                  )}
                  onChange={(event, option) => {
                    if (option?.id) {
                      return setFieldValue("smsTemplate", String(option.id));
                    };
                    setFieldValue("smsTemplate", undefined);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={clsx({ [classes.autocompleteError]: errors.smsTemplate })}
                    />
                  )}
                />
                <FormHelperText error>
                  {errors.smsTemplate}
                </FormHelperText>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid container alignItems={"center"} gap={1}>
              <Grid item>
                <Typography pb={2} className={classes.blueText}>
                  Transaction Settings
                </Typography>
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                <Divider style={{ marginBottom: "15px", borderColor: "#1976D2" }} />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} pb={2}>
                <PMSSwitch
                  smallText
                  name={"moveOutMarkBadDebt"}
                  label={"Always book all remainder owed as bad debt by default upon a Move-out transaction"}
                  labelPlacement={"end"}
                  isChecked={values.moveOutMarkBadDebt}
                  changeHandler={handleChange}
                />
              </Grid>
              <Grid item xs={12} pb={2}>
                <PMSSwitch
                  smallText
                  name={"alternativeRentalFlow"}
                  label={"Alternate Rental / E-sign Flow"}
                  labelPlacement={"end"}
                  isChecked={values.alternativeRentalFlow}
                  changeHandler={handleChange}
                />
              </Grid>
              <Grid item xs={12} pb={2}>
                <PMSSwitch
                  smallText
                  name={"enableProratedMoveOut"}
                  label={"Enable Prorated Move Outs"}
                  labelPlacement={"end"}
                  isChecked={values.enableProratedMoveOut}
                  changeHandler={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  };

  const InventoryManagementSettings = () => {
    return (
      <form
        id={"global-settings-inventory-form"}
        onSubmit={(e: FormEvent) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h5"}>
              Inventory Management Settings
            </Typography>
          </Grid>
          <Grid mb={1} item xs={12}>
            <Divider />
          </Grid>
          <Grid container mt={2}>
            <Grid container alignItems={"center"} gap={1}>
              <Grid item>
                <Typography pb={2} className={classes.blueText}>
                  Deals Settings
                </Typography>
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                <Divider style={{ marginBottom: "15px", borderColor: "#1976D2" }} />
              </Grid>
            </Grid>
            <Grid container item xs={6}>
              <Grid item xs={12} pb={2}>
                <PMSSwitch
                  smallText
                  name={"reservationRemoveUnitFromInventory"}
                  label={"Reservations remove units from inventory"}
                  labelPlacement={"end"}
                  isChecked={values.reservationRemoveUnitFromInventory}
                  changeHandler={handleChange}
                />
              </Grid>
              <Grid item xs={12} pb={2}>
                <PMSSwitch
                  smallText
                  name={"enableLeadDealType"}
                  label={"Enable the 'Lead' Deal Type (Disabling will Force Reservations on all Deals)"}
                  labelPlacement={"end"}
                  isChecked={values.enableLeadDealType}
                  changeHandler={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  };

  const componentList = [
    {
      name: "System Settings",
      component: <SystemSettings />
    },
    {
      name: "Inventory Management",
      component: <InventoryManagementSettings />
    },
    {
      name: "Auction Settings",
      component: <AuctionSettings
        flagIndividualUnitForAuction={values.flagIndividualUnitForAuction}
        setFieldValue={setFieldValue}
      />
    }
  ];

  if (globalSettingsPermissions.update) {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <TabView
          loading={documentTemplatesLoading}
          components={componentList}
          step={tabIndex}
          onChangeAction={(step) => setTabIndex(step)}
        />
        <Grid
          item
          justifyContent={"flex-end"}
          display={"flex"}
          alignItems={"center"}
        >
          <Grid mt={2} gap={2} display={"flex"}
            justifyContent={"flex-end"}>
            <Button
              variant={"text"}
              color={"error"}
              component={NavLink}
              to={"/facility-management/manage-facilities"}
            >
              Cancel
            </Button>
            <LoadingButton
              disabled={Boolean(Object.keys(errors).length) || documentTemplatesLoading || autocompleteOpen}
              variant={"contained"}
              className={classes.actionButton}
              id={"global-settings-form"}
              type={"submit"}
              onClick={() => handleSubmit()}
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </ViewWrapper>
    );
  } else {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <h1>You do not have permission to view this page</h1>
      </ViewWrapper>
    );
  }
};

export default GlobalSettings;
