import { makeStyles } from "../../makeStyles";

const useStyles = makeStyles<{ isEnabled: boolean }>({ label: "Promotions" })((theme, { isEnabled }) => ({
  newPromotionContainedButton: {
    boxShadow: "none",
    textTransform: "capitalize",
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.common.white,
    width: "9rem"
  },
  description: {
    margin: `0 ${theme.spacing(2)}`
  },
  buttonText: {
    textTransform: "capitalize"
  },
  disabledButton: {
    color: isEnabled ? theme.palette.text.disabled : theme.palette.text.primary
  },
  enabledButton: {
    color: isEnabled ? theme.palette.text.primary : theme.palette.text.disabled
  },
  cellTitle: {
    color: theme.palette.primary.contrastText,
    fontStyle: "italic"
  },
  editButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.contrastText,
    textTransform: "capitalize"
  },
  reenableButton: {
    marginLeft: theme.spacing(1),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    textTransform: "capitalize"
  }
}));

export default useStyles;
