import { Delete, Edit, ToggleOff, ToggleOn } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import {
  setConfirmDeleteWebhookModalOpen,
  setCurrentWebhookId
} from "src/store/reducers/webhooks/webhookSlice";
import { GridColDef } from "@mui/x-data-grid";
import React from "react";
import { Webhook } from "src/models/Webhook";
import { useToggleWebhookMutation } from "src/api/endpoints/webhooks";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { useAppDispatch } from "src/store/hooks";
import { useNavigate } from "react-router";

const RenderActionButtons = (row: Webhook) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleOpen = () => {
    dispatch(setCurrentWebhookId(row.id));
    dispatch(setConfirmDeleteWebhookModalOpen(true));
  };

  return (
    <Grid
      data-testid={"render-details-button"}
      container
      direction={"row"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Tooltip title={"Edit"}>
        <IconButton size={"small"} onClick={() => navigate(`/webhooks/${row.id}/edit`)}>
          <Edit />
        </IconButton>
      </Tooltip>

      <Tooltip title={"Delete"}>
        <IconButton size={"small"} onClick={() => handleOpen()}>
          <Delete />
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

const RenderStatusToggle = (row: Webhook) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [toggleWebhook] = useToggleWebhookMutation();

  const handleToggle = () => {
    const webhookId = row.id.toString();
    const enabled = Boolean(row.is_enabled);

    toggleWebhook({
      id: webhookId,
      payload: {
        isEnabled: !enabled
      }
    }).then(response => {
      if ("error" in response) {
        return dispatch(showSnackbar({ message: "Error toggling Webhook", variant: "error" }));
      }

      if ("data" in response) {
        const responseMessage = response.data?.message || "Webhook toggled successfully";
        dispatch(showSnackbar({ message: responseMessage, variant: "success" }));
      }
    });
  };

  return (
    <IconButton
      disableRipple
      disableFocusRipple
      size={"small"}
      onClick={handleToggle}
      style={{
        color: row.is_enabled ? "#4FC43A" : "#FF5252",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
        outline: "none",
        boxShadow: "none"
      }}
    >
      {row.is_enabled
        ? (
          <>
            <ToggleOn style={{ marginRight: 4, fontSize: 32 }} />
            <Typography variant={"body2"}>Enabled</Typography>
          </>
          )
        : (
          <>
            <ToggleOff style={{ marginRight: 4, fontSize: 32 }} />
            <Typography variant={"body2"}>Disabled</Typography>
          </>
          )}
    </IconButton>
  );
};

export const useManageWebhooksTableColumns = () => {
  const columns: GridColDef<Webhook>[] = [
    {
      field: "name",
      renderHeader: () => (
        <Tooltip title={"Name"}>
          <Typography>Name</Typography>
        </Tooltip>
      ),
      minWidth: 100,
      valueGetter: (params: { row: any }) => params.row.name,
      sortable: true,
      flex: 1
    },
    {
      field: "description",
      renderHeader: () => (
        <Tooltip title={"Description"}>
          <Typography>Description</Typography>
        </Tooltip>
      ),
      minWidth: 120,
      valueGetter: ({ row }: { row: Webhook }) => row.description,
      sortable: true,
      flex: 1
    },
    {
      field: "status",
      renderHeader: () => (
        <Tooltip title={"Status"}>
          <Typography>Status</Typography>
        </Tooltip>
      ),
      minWidth: 110,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => RenderStatusToggle(row)
    },
    {
      field: "actionIcons",
      renderHeader: () => (
        <Tooltip title={"Actions"}>
          <Typography>Actions</Typography>
        </Tooltip>
      ),
      sortable: false,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => RenderActionButtons(row)
    }
  ];

  return columns;
};
