import { Automation } from "src/models/Automation";
import { CreateAutomationPayload } from "./CreateAutomationPayload";
import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import moment from "moment";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const createAutomation = createAsyncThunk(
  "createAutomation",
  async(
    { values, facilityId }: { values: Automation; facilityId: string },
    { dispatch, getState, rejectWithValue }
  ) => {
    const payload: CreateAutomationPayload = {
      name: values.name,
      description: values.description,
      amount: values.amount,
      recurrence: values.recurrence,
      revenue_class_id: values.revenueClassId,
      automation_category: values.automation_category,
      is_active: values.isActive,
      applied_at: moment(values.appliedAt).format("YYYY-MM-DD"),
      type: values.type,
      is_due_before: values.is_due_before,
      days: values.days,
      fee_id: values.feeId,
      task_id: values.taskId,
      document_template_id:
        values.applicableDocumentForAutomation === 0 ? null : values.applicableDocumentForAutomation,
      is_delivery_by_certified_email: +values.isDeliveryByCertifiedEmail,
      is_delivery_by_email: +values.isDeliveryByEmail,
      is_delivery_by_postal_mail: +values.isDeliveryByPostalMail
    };

    return axios
      .post(`/api/v1/facilities/${facilityId}/system-automations`, payload)
      .then((resp) => {
        dispatch(
          showSnackbar({
            message: "Successfully created automation",
            variant: "success"
          })
        );

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(
            showSnackbar({
              message: errorMessage,
              variant: "error"
            })
          );

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an error creating the Automation. Please try again.";

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  }
);
