import React, { FC } from "react";
import PageHeader from "../../components/ui/PageHeader/PageHeader";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";

interface FacilityManagementProps {}

const pageHeader = <PageHeader title={"Facility Management"} subtitle={"Manage your facility here."} />;

const FacilityManagement: FC<FacilityManagementProps> = () => {
  return (
    <ViewWrapper pageHeader={pageHeader}>

    </ViewWrapper>
  );
};

export default FacilityManagement;
