import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

const getExternalTppPlan = createAsyncThunk(
  "getExternalTppPlaN", ({ occupant_id, external_tpp_plan_id }: {occupant_id: string, external_tpp_plan_id: string},
    { dispatch, getState, rejectWithValue }) => {
    return axios.get(`/api/v1/occupants/${occupant_id}/external-tpp-plans/${external_tpp_plan_id}`)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = `There was an error getting the External TPP Plan`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);

export default getExternalTppPlan;
