const sizeFormatter = (value: string) => {
  // Remove all characters that are not digits or a dot
  const sanitizedValue = value.replace(/[^0-9.]/g, "");

  // Split the string by dot and consider only the first two parts
  const [whole, decimal] = sanitizedValue.split(".");

  // Limit the whole part to 3 digits
  const limitedWhole = whole.substring(0, 3);

  // If there's a decimal part, limit it to 1 digit
  const limitedDecimal = decimal ? decimal.substring(0, 1) : "";

  // Construct the final value
  return value.includes(".") ? `${limitedWhole}.${limitedDecimal}` : limitedWhole;
};

export default sizeFormatter;
