import NotificationSliceState from "./notificationSliceState";
import { RootState } from "../../rootStore";
import { createSlice } from "@reduxjs/toolkit";
import { getAllTaskCategories } from "src/store/thunks/taskCategory/getAll/getAllTaskCategories";
import { getTasks } from "src/store/thunks/task/getAll/getTasks";

export const initialState: NotificationSliceState = {
  showNotifications: false,
  notifications: [],
  taskCategories: []
};

export const notificationReducerSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    toggleNotification: (state: NotificationSliceState) => {
      state.showNotifications = !state.showNotifications;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTasks.fulfilled, (state, action) => {
        if (action.payload.getNotifications) {
          state.notifications = action.payload.data;
        }
      })
      .addCase(getAllTaskCategories.fulfilled, (state, action) => {
        state.taskCategories = action.payload.data;
      });
  }
});

export const notificationSelector = (state: RootState) => state.notification;
export const selectNotifications = (state: RootState) => state.notification.notifications;
export const selectTaskCategories = (state: RootState) => state.notification.taskCategories;

export const { toggleNotification } = notificationReducerSlice.actions;

export default notificationReducerSlice.reducer;
