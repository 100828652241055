import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Facility Form Rebuild" })((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200
    }
  },
  saveButton: {
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText
  },
  labelWeight: {
    fontWeight: 300,
    color: "black"
  },
  alert: {
    marginTop: "1rem"
  }
}));

export default useStyles;
