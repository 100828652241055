import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { AppSoftphoneSliceState, SoftphoneDisplayMode, SoftphoneStatus } from "./appSoftphoneSliceState";
import { RootState } from "../../rootStore";

const initialState: AppSoftphoneSliceState = {
  status: SoftphoneStatus.Initializing,
  isDisplayed: SoftphoneDisplayMode.Hide,
  isInteractable: false,
  callDuration: 0,
  inputNumber: ""
};

const appSoftphoneSlice = createSlice({
  name: "appSoftphone",
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<SoftphoneStatus>) => {
      state.status = action.payload;

      switch (action.payload) {
        case SoftphoneStatus.Ready:
        case SoftphoneStatus.Calling:
        case SoftphoneStatus.Connected:
          state.isInteractable = true;
          break;
        default:
          state.isInteractable = false;
      }
    },
    setIsDisplayed: (state, action: PayloadAction<SoftphoneDisplayMode>) => {
      state.isDisplayed = action.payload;
    },
    setCallDuration: (state, action: PayloadAction<number>) => {
      state.callDuration = action.payload;
    },
    updateCallDuration: (state) => {
      state.callDuration += 1;
    },
    setInputNumber: (state, action: PayloadAction<string>) => {
      state.inputNumber = action.payload;
    }
  }
});

export const selectStatus = (state: RootState) => state.appSoftphone.status;
export const selectIsDisplayed = (state: RootState) => state.appSoftphone.isDisplayed;
export const selectIsInteractable = (state: RootState) => state.appSoftphone.isInteractable;
export const selectCallDuration = (state: RootState) => state.appSoftphone.callDuration;
export const selectInputNumber = (state: RootState) => state.appSoftphone.inputNumber;

export const { setStatus, setIsDisplayed, setCallDuration, updateCallDuration, setInputNumber } =
  appSoftphoneSlice.actions;

export default appSoftphoneSlice.reducer;
