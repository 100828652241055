import {
  Box,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  TextField,
  TextFieldProps,
  Typography
} from "@mui/material";
import Moment, { Moment as MomentType } from "moment";
import React, { ChangeEvent, useEffect, useState } from "react";
import {
  selectIsTransfer,
  selectLeaseStartsOn,
  selectPeriods,
  selectPromoDefermentStatus,
  setKeyPadAccess,
  setLeaseStartsOn,
  setMonthlyRate,
  setPeriods,
  setPromoDefermentStatus,
  setTimeAccess
} from "../../../../../store/reducers/moveInSlice/moveInSlice";
import {
  selectSelectedPromotion,
  setSelectedPromotion
} from "../../../../../store/reducers/selectedPromotionSlice/selectedPromotionSlice";
import {
  selectMoveInDaysLimitSetting
} from "src/store/reducers/globalSettingInformationSlice/globalSettingInformationSlice";
import {
  selectFacilityMoveInDaysLimitSetting
} from "src/store/reducers/facilitySettingInformationSlice/facilitySettingInformationSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { DatePicker } from "@mui/x-date-pickers";
import { FormikValues } from "formik";
import PMSCheckbox from "src/components/ui/PMSCheckbox/PMSCheckbox";
import { formatDate } from "src/utils/__dateAndTimeUtils__/formatDate/formatDate";
import { getMoveInCost } from "../../../../../store/thunks/moveIn/getMoveInCost/getMoveInCost";
import { getTransferUnitCost } from "src/store/thunks/transfer/transferCost/getTransferUnitCost";
import { inputError } from "../../../../../utils/showInputError/showInputError";
import useStyles from "./RateAndAccessDetails.styles";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { TimeAccess } from "src/models/TimeAccess";
import { KeypadAccess } from "src/models/KeypadAccess";
import { selectFacilities } from "src/store/reducers/facilitiesSlice/facilitiesSlice";

interface RateAndAccessDetailsProps {
  formik: FormikValues;
}

const RateAndAccessDetails = ({ formik }: RateAndAccessDetailsProps) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const leaseStartsOn = useAppSelector(selectLeaseStartsOn);
  const periods = useAppSelector(selectPeriods);
  const [localPeriods, setLocalPeriods] = React.useState<number | number[]>(periods);
  const promotion = useAppSelector(selectSelectedPromotion);
  const promoDefermentStatus = useAppSelector(selectPromoDefermentStatus);
  const isTransfer = useAppSelector(selectIsTransfer);
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const facilities = useAppSelector(selectFacilities);

  const updatedFacility = facilities.find((facility) => facility.id === selectedFacility.id);

  const globalMoveInDaysLimitSetting = useAppSelector(selectMoveInDaysLimitSetting);
  const facilityMoveInDaysLimitSetting = useAppSelector(selectFacilityMoveInDaysLimitSetting);
  const [dateLimit, setDateLimit] = useState<number | undefined>(undefined);

  const { touched, errors, values, validateForm } = formik;

  const marks = [
    {
      value: 0,
      label: "0"
    },
    {
      value: 1,
      label: null
    },
    {
      value: 2,
      label: null
    },
    {
      value: 3,
      label: "3"
    },
    {
      value: 4,
      label: null
    },
    {
      value: 5,
      label: null
    },
    {
      value: 6,
      label: "6"
    },
    {
      value: 7,
      label: null
    },
    {
      value: 8,
      label: null
    },
    {
      value: 9,
      label: "9"
    },
    {
      value: 10,
      label: null
    },
    {
      value: 11,
      label: null
    },
    {
      value: 12,
      label: "12"
    }
  ];

  const updateForm = (fieldName: string, fieldValue?: string | boolean | number | MomentType): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const updateSummary = () => {
    if (isTransfer) {
      return dispatch(getTransferUnitCost());
    }

    dispatch(getMoveInCost());
  };

  const getMoveInCostOnBlur = () => {
    dispatch(setMonthlyRate(values.monthlyRate));

    validateForm().then((resp: ArrayLike<unknown>) => {
      if (!Object.values(resp).length) {
        updateSummary();
      }
    });
  };

  const setPromotionAppliedDate = (updatedDate: MomentType) => {
    if (promotion && updatedDate) {
      const newPromotion = { ...promotion };
      const appliedMonth = promotion.applied_month - 1;
      newPromotion.applied_date = formatDate(updatedDate.clone().add(appliedMonth, "months").date(1));
      dispatch(setSelectedPromotion(newPromotion));
    }
  };

  const handleLeaseStartsOnOnChange = (fieldName: string, fieldValue: MomentType) => {
    updateForm(fieldName, fieldValue);
    dispatch(setLeaseStartsOn(formatDate(fieldValue)));
    setPromotionAppliedDate(fieldValue);
  };

  const handleMonthlyRateOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
  };

  const handlePeriodsOnChange = (event: Event, newValue: number | number[]) => {
    setLocalPeriods(newValue);
  };

  const handlePeriodsOnChangeCommitted = (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
    setLocalPeriods(newValue as number);
    updateForm("periods", newValue as number);
    dispatch(setPeriods(newValue as number));
    getMoveInCostOnBlur();
  };

  const handlePromotionDeferPromotion = (fieldName: string, fieldValue: boolean) => {
    updateForm(fieldName, fieldValue);
    dispatch(setPromoDefermentStatus(fieldValue));
    getMoveInCostOnBlur();
  };

  const handleOnSelectChange = (event: SelectChangeEvent) => {
    updateForm(event.target.name, event.target.value);
    if (event.target.name === "keypadAccess") {
      dispatch(setKeyPadAccess(event.target.value));
    } else {
      dispatch(setTimeAccess(event.target.value));
    }
  };

  useEffect(() => {
    dispatch(setTimeAccess(values?.timeAccess));
    dispatch(setKeyPadAccess(values?.keypadAccess));
  }, []);

  useEffect(() => {
    if (globalMoveInDaysLimitSetting?.value && facilityMoveInDaysLimitSetting?.value === undefined) {
      return setDateLimit(+globalMoveInDaysLimitSetting?.value);
    }

    if (facilityMoveInDaysLimitSetting?.value) {
      return setDateLimit(+facilityMoveInDaysLimitSetting?.value);
    }
  }, [
    globalMoveInDaysLimitSetting,
    facilityMoveInDaysLimitSetting
  ]);

  return (
    <Grid
      container
      item
      spacing={2}
      justifyContent={"space-between"}
      px={2}
      xs={12}
      component={"form"}
      id={"review-form"}
    >
      <Grid item xs={6} lg={promotion && promotion?.applied_month <= 1 ? 3 : 4}
        className={classes.cellWrapper}>
        <InputLabel className={classes.headerCell} htmlFor={"start-date"}>
          <Typography noWrap>Lease Starts On:</Typography>
        </InputLabel>
        <DatePicker
          renderInput={(props: TextFieldProps) => (
            <TextField
              {...props}
              id={"start-date"}
              fullWidth
              onKeyDown={(e) => e.preventDefault()}
              name={"leaseStartsOn"}
              onBlur={getMoveInCostOnBlur}
              error={inputError("leaseStartsOn", touched, errors).error}
              helperText={inputError("leaseStartsOn", touched, errors).helperText}
            />
          )}
          OpenPickerButtonProps={{ "aria-label": "Select Start Date" }}
          componentsProps={{
            leftArrowButton: { "aria-label": "Move Backward" },
            rightArrowButton: { "aria-label": "Move Backward" }
          }}
          minDate={Moment()}
          maxDate={dateLimit ? Moment().add(dateLimit, "days") : Moment()}
          inputFormat={"MM/DD/yyyy"}
          data-testid={"start-date"}
          value={leaseStartsOn}
          onChange={(value: MomentType | null, keyboardInputValue?: string | undefined) =>
            handleLeaseStartsOnOnChange("leaseStartsOn", value!)}
          onClose={getMoveInCostOnBlur}
        />
      </Grid>
      <Grid item xs={6} lg={promotion && promotion?.applied_month <= 1 ? 3 : 4}
        className={classes.cellWrapper}>
        <InputLabel htmlFor={"time-access"} className={classes.headerCell}>
          <Typography noWrap>Time Access</Typography>
        </InputLabel>
        <Select id={"time-access"} value={values.timeAccess} variant={"outlined"}
          fullWidth displayEmpty onChange={handleOnSelectChange}
          name={"timeAccess"}>
          {updatedFacility?.time_accesses.map((timeAccess: TimeAccess) => (
            <MenuItem key={timeAccess.id} value={timeAccess.id}>
              {timeAccess.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>
          {errors?.timeAccess}
        </FormHelperText>
      </Grid>
      <Grid item xs={6} lg={promotion && promotion?.applied_month <= 1 ? 3 : 4}
        className={classes.cellWrapper}>
        <InputLabel htmlFor={"keypad-access"} className={classes.headerCell}>
          <Typography noWrap>Keypad Access</Typography>
        </InputLabel>
        <Select id={"keypad-access"} value={values.keypadAccess} variant={"outlined"}
          fullWidth displayEmpty onChange={handleOnSelectChange}
          name={"keypadAccess"}>
          {updatedFacility?.keypad_accesses.map((keypadAccess: KeypadAccess) => (
            <MenuItem key={keypadAccess.id} value={keypadAccess.id}>
              {keypadAccess.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>
          {errors?.keypadAccess}
        </FormHelperText>
      </Grid>
      {promotion && promotion?.applied_month <= 1 && (
        <Grid item xs={6} lg={3}
          className={classes.cellWrapper}>
          <InputLabel htmlFor={"keypad-access"} className={classes.headerCell}>
            <Typography noWrap>Defer Promotion</Typography>
          </InputLabel>
          <Box display={"flex"} alignItems={"center"} className={classes.containerCheckbox}>
            <PMSCheckbox
              smallText
              data-testid={"defer-to-first-full-month"}
              label={"Defer to First Full Month"}
              name={"defer-to-first-full-month"}
              isChecked={promoDefermentStatus}
              changeHandler={(e) => handlePromotionDeferPromotion("promoDefermentStatus", e.target.checked)}
            />
          </Box>
        </Grid>
      )}
      <Grid item xs={4} lg={4}
        className={classes.cellWrapper}>
        <InputLabel className={classes.headerCell} htmlFor={"monthly-rate"}>
          <Typography noWrap>Monthly Rate</Typography>
        </InputLabel>
        <TextField
          id={"monthly-rate"}
          fullWidth
          inputProps={{ "data-testid": "monthly-rate" }}
          type={"number"}
          name={"monthlyRate"}
          value={values.monthlyRate}
          className={classes.noArrow}
          InputProps={{ startAdornment: <InputAdornment position={"start"}>$</InputAdornment> }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleMonthlyRateOnChange("monthlyRate", e.target.value)}
          error={inputError("monthlyRate", touched, errors).error}
          helperText={inputError("monthlyRate", touched, errors).helperText}
          onBlur={getMoveInCostOnBlur}
        />
      </Grid>
      <Grid item xs={8} lg={8}
        className={classes.cellWrapper}>
        <InputLabel htmlFor={"periods"} className={classes.headerCell}>
          <Typography noWrap>Prepay Periods</Typography>
        </InputLabel>
        <Grid container spacing={2} alignItems={"left"}
          pt={3} px={3.5}>
          <Slider
            value={localPeriods as number}
            defaultValue={0}
            step={1}
            marks={marks}
            valueLabelDisplay={"auto"}
            data-testid={"prepay-periods-slider"}
            min={0}
            max={12}
            onChange={handlePeriodsOnChange}
            onChangeCommitted={handlePeriodsOnChangeCommitted}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RateAndAccessDetails;
