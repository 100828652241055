import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography
} from "@mui/material";
import React, { FC, ReactElement } from "react";
import {
  selectPaymentLoading,
  selectPaymentSummarySlice
} from "src/store/reducers/paymentSummarySlice/paymentSummarySlice";
import AdditionalInformationDisplay from "./components/AdditionalInformationDisplay/AdditionalInformationDisplay";
import ChargesDisplay from "./components/ChargesDisplay/ChargesDisplay";
import { LoadingButton } from "@mui/lab";
import RequiredLineItems from "./components/RequiredLineItems/RequiredLineItems";
import { selectEffectiveAmount } from "src/store/reducers/makeAPaymentSlice/makeAPaymentSlice";
import { useAppSelector } from "../../../../../store/hooks";
import { useNavigate } from "react-router";
import useStyles from "./PaymentSummaryNew.styles";

interface PaymentSummaryNewProps {
  completed?: boolean;
}

interface InformationBlockProps {
  title: string;
  loading: boolean;
  children: ReactElement;
}

const InformationBlock: FC<InformationBlockProps> = ({
  title,
  loading,
  children
}) => (
  <Grid
    component={Paper}
    variant={"outlined"}
    container
    spacing={1}
    item
    p={2}
    xs={12}
    mb={2}
  >
    <Grid item xs={12}>
      <Typography variant={"h6"} align={"center"}>
        {title}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Divider />
    </Grid>
    {loading
      ? (
        <Grid container m={2} justifyContent={"center"}>
          <Grid display={"contents"} item xs={12}
            justifySelf={"center"}>
            <CircularProgress size={"large"} />
          </Grid>
        </Grid>
        )
      : (
        <Grid container p={1}>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
        )}
  </Grid>
);

const PaymentSummaryNew: React.FC<PaymentSummaryNewProps> = ({
  completed = false
}): ReactElement => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { loading } = useAppSelector(selectPaymentSummarySlice);
  const effectiveAmount = useAppSelector(selectEffectiveAmount);
  const disableSubmitPayment =
    loading || Number(effectiveAmount) <= 0 || effectiveAmount === "N/A";
  const paymentLoading = useAppSelector(selectPaymentLoading);

  const actionButtons = (
    <>
      <LoadingButton
        variant={"contained"}
        className={classes.processPaymentButton}
        type={"submit"}
        loading={paymentLoading}
        disabled={disableSubmitPayment || paymentLoading}
      >
        Process Payment
      </LoadingButton>
      <Button
        variant={"text"}
        color={"secondary"}
        className={classes.cancelButton}
        onClick={() => navigate("/collections/make-a-payment")}
      >
        Cancel
      </Button>
    </>
  );

  return (
    <>
      <Grid container item mt={1}
        xs={12}>
        <InformationBlock title={"Charges"} loading={loading}>
          <ChargesDisplay />
        </InformationBlock>
        <InformationBlock title={"Payment Summary"} loading={loading}>
          <RequiredLineItems />
        </InformationBlock>
        <InformationBlock title={"Additional Information"} loading={loading}>
          <AdditionalInformationDisplay />
        </InformationBlock>
      </Grid>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12}>
          {!completed ? actionButtons : null}
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentSummaryNew;
