import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import dollarsToCents from "src/utils/dollarsToCents/dollarsToCents";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

type GetMaxAmountPayload = {
  occupantId: string | number;
  ledgerId: string | number;
};

export const getMaxAmount = createAsyncThunk(
  "getMaxAmount",
  ({ occupantId, ledgerId }: GetMaxAmountPayload,
    { dispatch, getState, rejectWithValue }) => {
    const params = {
      params: {
        prepaid_periods: 12
      }
    };

    return axios.get(`/api/v1/occupants/${occupantId}/ledgers/${ledgerId}/payment-cost`, params)
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        dispatch(showSnackbar({
          message: err.response.data.detail,
          variant: "error"
        }));

        return rejectWithValue(err.response.data.detail);
      });
  }
);
