import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "ApplyFee" })((theme) => ({
  label: {
    fontWeight: theme.typography.fontWeightBold,
    color: "black"
  },
  viewOccupantButton: {
    color: theme.palette.primary.contrastText
  },
  viewLedgerButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: "white"
  },
  submitButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: "white",
    padding: "0.5rem 1.5rem"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  ledgerModal: {
    margin: "10%"
  },
  chip: {
    padding: "0.5rem",
    border: `1px solid`,
    borderRadius: "20px"
  },
  alreadyAppliedChip: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main
  },
  newFeeChip: {
    borderColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.contrastText
  },
  container: {
    position: "relative",
    maxHeight: "85vh",
    overflowY: "auto",
    maxWidth: "30rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1rem"
  },
  button: {
    borderColor: theme.palette.grey[400],
    color: theme.palette.primary.contrastText,
    minWidth: theme.spacing(15),
    minHeight: theme.spacing(5),
    fontWeight: "normal"
  },
  cancelButton: {
    minWidth: theme.spacing(15),
    minHeight: theme.spacing(5),
    borderColor: theme.palette.grey[400],
    color: theme.palette.error.contrastText,
    fontWeight: "normal"
  },
  feeSummary: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.grey[100],
    width: "100%",
    margin: `${theme.spacing(2)} 10%`,
    borderRadius: "10px",
    marginTop: theme.spacing(2)
  },
  totalFee: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    margin: "10%",
    borderRadius: "5px",
    border: `1px solid ${theme.palette.success.main}`,
    padding: theme.spacing(1)
  }
}));

export default useStyles;
