import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const enableInsurancePlan = createAsyncThunk(
  "enableInsurancePlan", (id: number, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const facilityId = state.selectedAdminFacility.selectedAdminFacility!.id;

    return axios.post(`/api/v1/facilities/${facilityId}/tpp-plans/${id}/enabled`, { is_active: true })
      .then((resp) => {
        dispatch(showSnackbar({
          message: `${resp.data.name} enabled successfully`,
          variant: "success"
        }));

        return resp.data;
      })
      .catch(() => {
        const errorMessage = "There was an error enabling the Insurance Plan";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
