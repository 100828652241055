import {
  Button,
  FormHelperTextProps,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  Paper,
  TextField,
  TextFieldProps,
  Typography
} from "@mui/material";
import {
  selectRentedUnitsModalOpen,
  selectSelectedLedger,
  setRentedUnitsModalOpen
} from "src/store/reducers/ledgersSlice/ledgersSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Box } from "@mui/system";
import { Close } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import EditRentalAmountModalValidation from "./EditRentalAmountModalValidation";
import { LoadingButton } from "@mui/lab";
import React from "react";
import { formatDate } from "src/utils/__dateAndTimeUtils__/formatDate/formatDate";
import { inputError } from "src/utils/showInputError/showInputError";
import moment from "moment";
import { selectUpdateRentalAmountLoading } from "src/store/reducers/occupantSlice/occupantSlice";
import { updateRentalAmount } from "src/store/thunks/occupant/update/rentalAmount/updateRentalAmount";
import { useFormik } from "formik";
import useStyles from "./EditRentalAmountModal.styles";

const EditRentalAmountModal = () => {
  const { classes } = useStyles();
  const selectedLedger = useAppSelector(selectSelectedLedger);
  const modalOpened = useAppSelector(selectRentedUnitsModalOpen);
  const loading = useAppSelector(selectUpdateRentalAmountLoading);
  const dispatch = useAppDispatch();

  const handleUpdateAmount = async(values: { ledgerId: number, new_rent_amount: number, apply_at: string }) => {
    return dispatch(updateRentalAmount(values));
  };

  const { touched, values, errors, handleSubmit, handleChange, resetForm, setFieldValue } = useFormik({
    initialValues: {
      rentalRate: selectedLedger?.monthly_rate as number,
      effectiveDate: ""
    },
    validationSchema: EditRentalAmountModalValidation,
    onSubmit: async(values) => {
      const params = {
        ledgerId: selectedLedger?.id as number,
        new_rent_amount: values.rentalRate,
        apply_at: moment(values.effectiveDate).format("YYYY-MM-DD")
      };

      if (params.ledgerId) {
        await handleUpdateAmount(params);

        resetForm();
        dispatch(setRentedUnitsModalOpen(false));
      }
    }
  });

  const handleEffectiveDate = (date: any) => {
    setFieldValue("effectiveDate", formatDate(date));
  };

  const handleClose = () => {
    resetForm();
    dispatch(setRentedUnitsModalOpen(false));
  };

  return (
    <Modal
      open={modalOpened}
      onClose={() => handleClose()}
      aria-labelledby={"Modal Component"}
      aria-describedby={"A Modal component."}
      className={classes.modal}
      data-testid={"modal-edit-rental-amount"}
    >
      <Grid
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        spacing={2}
        className={classes.container}
        pb={2}>
        <Box className={classes.content}>
          <Box className={classes.containerHeader}>
            <Typography variant={"h5"} className={classes.title}>
              Update Rental Amount for Unit {selectedLedger?.unit?.unit_number}
            </Typography>
            <IconButton data-testid={"close-fab"} onClick={() => handleClose()}>
              <Close />
            </IconButton>
          </Box>
          <Grid
            container
            justifyContent={"space-between"}
            mt={2}
            spacing={2}
          >
            <Grid xs={6} item>
              <InputLabel htmlFor={"first-name"}>Rental Rate</InputLabel>
              <TextField
                fullWidth
                placeholder={"- Rental Rate -"}
                id={"rental-rate"}
                inputProps={{
                  "data-testid": "rental-rate"
                }}
                InputProps={{ startAdornment: <InputAdornment position={"start"}>$</InputAdornment> }}
                name={"rentalRate"}
                value={values.rentalRate}
                onChange={handleChange}
                error={inputError("rentalRate", touched, errors).error}
                helperText={inputError("rentalRate", touched, errors).helperText}
              />
            </Grid>
            <Grid xs={6} item>
              <InputLabel htmlFor={"effective-date"}>Effective Date</InputLabel>
              <DatePicker
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "- Effective Date -"
                    }}
                    name={"effectiveDate"}
                    id={"effective-date"}
                    fullWidth
                    FormHelperTextProps={
                      { "data-testid": "effective-date-validation" } as Partial<FormHelperTextProps<"p", {}>>
                    }
                    error={inputError("effectiveDate", touched, errors).error}
                    helperText={inputError("effectiveDate", touched, errors).helperText}
                  />
                )}
                OpenPickerButtonProps={{ "aria-label": "Select Rental Date" }}
                componentsProps={{
                  leftArrowButton: { "aria-label": "Move Backward" },
                  rightArrowButton: { "aria-label": "Move Backward" }
                }}
                minDate={moment().add(1, "days")}
                inputFormat={"MM/DD/yyyy"}
                data-testid={"effective-date"}
                value={values.effectiveDate}
                onChange={(value: moment.Moment | null, keyboardInputValue?: string | undefined) =>
                  handleEffectiveDate(value)
                }
              />
            </Grid>
          </Grid>
          <Grid
            pt={4}
            spacing={4}
            container
            justifyContent={"flex-end"}
          >
            <Grid item>
              <Button
                data-testid={"cancel-button"}
                className={classes.cancelButton}
                onClick={() => handleClose()}
                variant={"text"}
              >
                CANCEL
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                data-testid={"confirm-button"}
                variant={"contained"}
                className={classes.button}
                disabled={loading}
                onClick={() => handleSubmit()}
              >
                SAVE
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Modal>
  );
};

export default EditRentalAmountModal;
