import {
  Box,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
  TextFieldProps,
  Typography
} from "@mui/material";
import Moment, { Moment as MomentType } from "moment";
import React, { ChangeEvent } from "react";
import {
  selectIsTransfer,
  selectTransferLeaseStartsOn,
  selectTransferPeriods,
  setTransferLeaseStartsOn,
  setTransferMonthlyRate,
  setTransferPeriods,
  setTransferPromoDefermentStatus
} from "src/store/reducers/transferUnitSlice/transferUnitSlice";
import {
  selectLeaseStartsOn,
  selectPeriods,
  selectPromoDefermentStatus,
  setLeaseStartsOn,
  setMonthlyRate,
  setPeriods,
  setPromoDefermentStatus
} from "../../../../../store/reducers/moveInSlice/moveInSlice";
import {
  selectSelectedPromotion,
  setSelectedPromotion
} from "../../../../../store/reducers/selectedPromotionSlice/selectedPromotionSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { DatePicker } from "@mui/x-date-pickers";
import { FormikValues } from "formik";
import PMSCheckbox from "src/components/ui/PMSCheckbox/PMSCheckbox";
import { formatDate } from "src/utils/__dateAndTimeUtils__/formatDate/formatDate";
import { getMoveInCost } from "../../../../../store/thunks/moveIn/getMoveInCost/getMoveInCost";
import { getTransferUnitCost } from "src/store/thunks/transfer/transferCost/getTransferUnitCost";
import { inputError } from "../../../../../utils/showInputError/showInputError";
import useStyles from "./RateAndAccessDetails.styles";

interface RateAndAccessDetailsProps {
  formik: FormikValues;
}

const RateAndAccessDetails = ({ formik }: RateAndAccessDetailsProps) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const isTransfer = useAppSelector(selectIsTransfer);
  const leaseStartsOn = useAppSelector(isTransfer ? selectTransferLeaseStartsOn : selectLeaseStartsOn);
  const periods = useAppSelector(isTransfer ? selectTransferPeriods : selectPeriods);
  const [localPeriods, setLocalPeriods] = React.useState<number | number[]>(periods);
  const promotion = useAppSelector(selectSelectedPromotion);
  const promoDefermentStatus = useAppSelector(selectPromoDefermentStatus);

  const { touched, errors, values, validateForm } = formik;

  const marks = [
    {
      value: 0,
      label: "0"
    },
    {
      value: 1,
      label: null
    },
    {
      value: 2,
      label: null
    },
    {
      value: 3,
      label: "3"
    },
    {
      value: 4,
      label: null
    },
    {
      value: 5,
      label: null
    },
    {
      value: 6,
      label: "6"
    },
    {
      value: 7,
      label: null
    },
    {
      value: 8,
      label: null
    },
    {
      value: 9,
      label: "9"
    },
    {
      value: 10,
      label: null
    },
    {
      value: 11,
      label: null
    },
    {
      value: 12,
      label: "12"
    }
  ];

  const updateForm = (fieldName: string, fieldValue?: string | boolean | number | MomentType): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const updateSummary = () => {
    if (isTransfer) {
      return dispatch(getTransferUnitCost());
    }

    dispatch(getMoveInCost());
  };

  const getMoveInCostOnBlur = () => {
    if (isTransfer) {
      dispatch(setTransferMonthlyRate(values.monthlyRate));
    } else {
      dispatch(setMonthlyRate(values.monthlyRate));
    }

    validateForm().then((resp: ArrayLike<unknown>) => {
      if (!Object.values(resp).length) {
        updateSummary();
      }
    });
  };

  const setPromotionAppliedDate = (updatedDate: MomentType) => {
    if (promotion && updatedDate) {
      const newPromotion = { ...promotion };
      const appliedMonth = promotion.applied_month - 1;
      newPromotion.applied_date = formatDate(updatedDate.clone().add(appliedMonth, "months").date(1));
      dispatch(setSelectedPromotion(newPromotion));
    }
  };

  const handleLeaseStartsOnOnChange = (fieldName: string, fieldValue: MomentType) => {
    updateForm(fieldName, fieldValue);
    if (isTransfer) {
      dispatch(setTransferLeaseStartsOn(formatDate(fieldValue)));
    } else {
      dispatch(setLeaseStartsOn(formatDate(fieldValue)));
    }

    setPromotionAppliedDate(fieldValue);
  };

  const handleMonthlyRateOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
  };

  const handlePeriodsOnChange = (event: Event, newValue: number | number[]) => {
    setLocalPeriods(newValue);
  };

  const handlePeriodsOnChangeCommitted = (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
    setLocalPeriods(newValue as number);
    updateForm("periods", newValue as number);
    if (isTransfer) {
      dispatch(setTransferPeriods(newValue as number));
    } else {
      dispatch(setPeriods(newValue as number));
    }

    getMoveInCostOnBlur();
  };

  const handlePromotionDeferPromotion = (fieldName: string, fieldValue: boolean) => {
    updateForm(fieldName, fieldValue);
    if (isTransfer) {
      dispatch(setTransferPromoDefermentStatus(fieldValue));
    } else {
      dispatch(setPromoDefermentStatus(fieldValue));
    }

    getMoveInCostOnBlur();
  };

  return (
    <Grid
      container
      item
      spacing={2}
      justifyContent={"space-between"}
      px={2}
      xs={12}
      component={"form"}
      id={"review-form"}
    >
      <Grid item xs={6} lg={promotion && promotion?.applied_month <= 1 ? 3 : 4}
        className={classes.cellWrapper}>
        <InputLabel className={classes.headerCell} htmlFor={"start-date"}>
          <Typography noWrap>Lease Starts On:</Typography>
        </InputLabel>
        <DatePicker
          renderInput={(props: TextFieldProps) => (
            <TextField
              {...props}
              onKeyDown={(e) => e.preventDefault()}
              id={"start-date"}
              fullWidth
              name={"leaseStartsOn"}
              onBlur={getMoveInCostOnBlur}
              error={inputError("leaseStartsOn", touched, errors).error}
              helperText={inputError("leaseStartsOn", touched, errors).helperText}
            />
          )}
          OpenPickerButtonProps={{ "aria-label": "Select Start Date" }}
          componentsProps={{
            leftArrowButton: { "aria-label": "Move Backward" },
            rightArrowButton: { "aria-label": "Move Backward" }
          }}
          minDate={Moment()}
          maxDate={Moment()}
          inputFormat={"MM/DD/yyyy"}
          data-testid={"start-date"}
          value={leaseStartsOn}
          onChange={(value: MomentType | null, keyboardInputValue?: string | undefined) =>
            handleLeaseStartsOnOnChange("leaseStartsOn", value!)}
          onClose={getMoveInCostOnBlur}
        />
      </Grid>
      <Grid item xs={6} lg={promotion && promotion?.applied_month <= 1 ? 3 : 4}
        className={classes.cellWrapper}>
        <InputLabel htmlFor={"time-access"} className={classes.headerCell}>
          <Typography noWrap>Time Access</Typography>
        </InputLabel>
        <Select id={"time-access"} value={""} variant={"outlined"}
          fullWidth displayEmpty>
          <MenuItem value={""} disabled>
            Business Hours
          </MenuItem>
        </Select>
      </Grid>
      <Grid item xs={6} lg={promotion && promotion?.applied_month <= 1 ? 3 : 4}
        className={classes.cellWrapper}>
        <InputLabel htmlFor={"keypad-access"} className={classes.headerCell}>
          <Typography noWrap>Keypad Access</Typography>
        </InputLabel>
        <Select id={"keypad-access"} value={""} variant={"outlined"}
          fullWidth displayEmpty>
          <MenuItem value={""} disabled>
            All Keypads
          </MenuItem>
        </Select>
      </Grid>
      {promotion && promotion?.applied_month <= 1 && (
        <Grid item xs={6} lg={3}
          className={classes.cellWrapper}>
          <InputLabel htmlFor={"keypad-access"} className={classes.headerCell}>
            <Typography noWrap>Defer Promotion</Typography>
          </InputLabel>
          <Box display={"flex"} alignItems={"center"} className={classes.containerCheckbox}>
            <PMSCheckbox
              smallText
              data-testid={"defer-to-first-full-month"}
              label={"Defer to First Full Month"}
              name={"defer-to-first-full-month"}
              isChecked={promoDefermentStatus}
              changeHandler={(e) => handlePromotionDeferPromotion("promoDefermentStatus", e.target.checked)}
            />
          </Box>
        </Grid>
      )}
      <Grid item xs={4} lg={4}
        className={classes.cellWrapper}>
        <InputLabel className={classes.headerCell} htmlFor={"monthly-rate"}>
          <Typography noWrap>Monthly Rate</Typography>
        </InputLabel>
        <TextField
          id={"monthly-rate"}
          fullWidth
          inputProps={{ "data-testid": "monthly-rate" }}
          type={"number"}
          name={"monthlyRate"}
          value={values.monthlyRate}
          className={classes.noArrow}
          InputProps={{ startAdornment: <InputAdornment position={"start"}>$</InputAdornment> }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleMonthlyRateOnChange("monthlyRate", e.target.value)}
          error={inputError("monthlyRate", touched, errors).error}
          helperText={inputError("monthlyRate", touched, errors).helperText}
          onBlur={getMoveInCostOnBlur}
        />
      </Grid>
      <Grid item xs={8} lg={8}
        className={classes.cellWrapper}>
        <InputLabel htmlFor={"periods"} className={classes.headerCell}>
          <Typography noWrap>Prepay Periods</Typography>
        </InputLabel>
        <Grid container spacing={2} alignItems={"left"}
          pt={3} px={3.5}>
          <Slider
            value={localPeriods as number}
            defaultValue={0}
            step={1}
            marks={marks}
            valueLabelDisplay={"auto"}
            data-testid={"prepay-periods-slider"}
            min={0}
            max={12}
            onChange={handlePeriodsOnChange}
            onChangeCommitted={handlePeriodsOnChangeCommitted}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RateAndAccessDetails;
