import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "TableSortButtonGroup" })((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    border: `${theme.spacing(0.1)} solid ${theme.palette.text.disabled}`,
    borderOpacity: "50%",
    borderRadius: theme.spacing(0.5),
    "& .MuiButtonBase-root": {
      border: "transparent"
    }
  },
  heading: {
    marginLeft: theme.spacing(1)
  }
}));

export default useStyles;
