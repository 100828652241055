import { LocalAtm } from "@mui/icons-material";
import { Grid, InputAdornment, InputLabel, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { ChangeEvent, FormEvent, ReactElement, SyntheticEvent, useEffect } from "react";
import { inputError } from "src/utils/showInputError/showInputError";
import useStyles from "./CashForm.styles";
import CashFormValidation from "./CashFormValidation";

interface CashFormProps {
  handleSubmit: (values: CashFormValues) => void;
  amountDue: string;
  actionButtons?: ReactElement;
  setFormik?: Function;
}

const getChangeDue = (cashDue: string, cashReceived: string) => {
  const changeDue = +cashReceived - +cashDue;

  if (changeDue >= 0.0) {
    // prevent displaying negative numbers in "change due"
    return changeDue.toFixed(2);
  }

  return "0.00";
};

export interface CashFormValues {
  cashReceivedAmount: string;
  amountDue: string;
  changeDue: string;
}

const CashForm: React.FC<CashFormProps> = ({ handleSubmit, amountDue, actionButtons, setFormik }) => {
  const { classes } = useStyles();
  const validCurrencyRegex = /^\d*\.?\d{0,2}$/;

  const formik = useFormik({
    initialValues: {
      cashReceivedAmount: "0.00",
      amountDue: amountDue,
      changeDue: "0.00"
    },
    enableReinitialize: true,
    validationSchema: CashFormValidation,
    /* istanbul ignore next */
    onSubmit: (formValues) => {
      handleSubmit(formValues);
    }
  });

  useEffect(() => {
    if (setFormik) {
      setFormik(formik);
    }
  }, []);

  const { values, errors, touched, setFieldValue } = formik;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (validCurrencyRegex.test(event.target.value)) {
      setFieldValue("cashReceivedAmount", event.target.value);
      setFieldValue("changeDue", getChangeDue(amountDue, event.target.value));
    }
  };

  return (
    <Grid
      container
      direction={"column"}
      spacing={2}
      justifyContent={"center"}
      alignItems={"center"}
      component={"form"}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        formik.handleSubmit();
      }}
    >
      <Grid item container direction={"column"}
        alignItems={"center"}>
        <LocalAtm className={classes.moneyIcon} />
      </Grid>
      <Grid container item spacing={2}>
        <Grid item container xs={6}
          justifyContent={"center"}>
          <Grid item>
            <InputLabel htmlFor={"amount-due"}>Cash Due</InputLabel>
            <TextField
              id={"amount-due"}
              disabled
              value={values.amountDue}
              InputProps={{ startAdornment: <InputAdornment position={"start"}>$</InputAdornment> }}
            />
          </Grid>
        </Grid>
        <Grid item container xs={6}
          justifyContent={"center"}>
          <Grid item>
            <InputLabel htmlFor={"amount-received"}>Cash Received</InputLabel>
            <TextField
              id={"amount-received"}
              type={"text"}
              name={"cashReceivedAmount"}
              value={values.cashReceivedAmount}
              InputProps={{ startAdornment: <InputAdornment position={"start"}>$</InputAdornment> }}
              onChange={handleChange}
              error={inputError("cashReceivedAmount", touched, errors).error}
              helperText={inputError("cashReceivedAmount", touched, errors).helperText}
            />
          </Grid>
        </Grid>
        <Grid item container xs={6}
          justifyContent={"center"}>
          <Grid item>
            <InputLabel htmlFor={"change-owed"}>Change Owed</InputLabel>
            <TextField
              id={"change-owed"}
              disabled
              value={values.changeDue}
              InputProps={{ startAdornment: <InputAdornment position={"start"}>$</InputAdornment> }}
            />
          </Grid>
        </Grid>
      </Grid>
      {actionButtons ?? null}
    </Grid>
  );
};

export default CashForm;
