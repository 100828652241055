import { UpdateRentalAmountPayload } from "./updateRentalAmountPayload";
import { axiosInstance as axios } from "../../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../../reducers/snackbarSlice/snackbarSlice";

export const updateRentalAmount = createAsyncThunk("updateRentalAmount",
  (values: UpdateRentalAmountPayload, { dispatch, getState, rejectWithValue }) => {
    const { ledgerId, ...rest } = values;

    return axios.post(`/api/v1/ledgers/${ledgerId}/rate-changes`, rest)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Rental amount successfully updated.",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        if (err.response.status === 422) {
          dispatch(showSnackbar({
            message: err.response.data.detail,
            variant: "error"
          }));

          return rejectWithValue(err.response.data.detail);
        }

        const errorMessage = "There was an error updating the rental amount";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
