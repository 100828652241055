
import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "IssueRefundTableColumns" })((theme) => ({
  completedStatus: {
    padding: theme.spacing(0, 1),
    border: `1px solid ${theme.palette.success.main}`,
    color: theme.palette.success.main
  },
  refundedTransaction: {
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main
  },
  voidedTransaction: {
    border: `1px solid ${theme.palette.warning.main}`,
    color: theme.palette.warning.main
  }
}));

export default useStyles;
