import { Button } from "@mui/material";
import React from "react";
import useStyles from "./Form.styles";

type FormCancelProps = {
  label?: string;
  onClick: () => void;
  variant?: "contained" | "outlined" | "text";
};

const FormCancel = ({ label = "Cancel", onClick, variant = "text" }: FormCancelProps) => {
  const { classes } = useStyles();
  return (
    <Button variant={variant} className={classes.cancelButton} size={"large"}
      onClick={onClick}>
      {label}
    </Button>
  );
};

export default FormCancel;
