import { Card, Grid, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import React from "react";
import formatPhone from "src/utils/formatPhone/formatPhone";
import useStyles from "./SelectedOccupantDisplay.styles";

interface SelectedOccupantForDealProps {
  name: string
  id?: string | number
  email: string
  phone: string
  address?: string
  onClose: () => void
}

const SelectedOccupantDisplay = ({
  name,
  id,
  email,
  phone,
  address,
  onClose
}: SelectedOccupantForDealProps) => {
  const { classes } = useStyles();

  return (
    <Grid
      item
      container
      xs={12}
      lg={5}
    >
      <Grid item xs={12}>
        <Typography variant={"subtitle1"} component={"span"}>Selected Occupant:</Typography>
      </Grid>
      <Grid item xs={8}>
        <Card elevation={1} className={classes.greyCard}>
          <Close data-testid={"reset-occupant"} onClick={onClose} className={classes.closeIcon}/>
          <Typography data-testid={"selected-occupant-name"} gutterBottom className={classes.boldName}>
            {name}
          </Typography>
          {id && <Typography data-testid={"selected-occupant-id"} gutterBottom>
            Occupant Id: {id}
          </Typography>}
          {address && <Typography data-testid={"selected-occupant-address"} gutterBottom>
            {address}
          </Typography>}
          <Typography data-testid={"selected-occupant-email"} gutterBottom>
            {email}
          </Typography>
          <Typography data-testid={"selected-occupant-phone"} gutterBottom>
            {formatPhone(phone)}
          </Typography>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SelectedOccupantDisplay;
