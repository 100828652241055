import React from "react";
import { Route } from "react-router";
import ManageUnits from "src/pages/Units/ManageUnits/ManageUnits";

export const groupName = "units";

const UnitsRoutes = (
  <>
    <Route path={`/${groupName}/manage-units`} element={<ManageUnits />} />
  </>
);

export default UnitsRoutes;
