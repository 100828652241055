import React, { PropsWithChildren, ReactElement, useEffect } from "react";
import {
  selectNewFacilityAdvisoryModal,
  selectSelectedFacility
} from "../../../store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useLocation, useNavigate } from "react-router";
import { AppDispatch } from "../../../store/rootStore";
import AppModal from "../../../components/ui/AppModal/AppModal";
import { Breadcrumb } from "../../../models/Breadcrumb";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import { Typography } from "@mui/material";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { blockNavigation } from "src/components/router/CustomRouter";
import { getAllFacilitySettings } from "src/store/thunks/facilitySetting/getAll/getAllFacilitySettings";
import { resetEmergencyContactInformationSlice }
  from "../../../store/reducers/emergencyContactInformationSlice/emergencyContactInformationSlice";
import { resetOccupantInformationSlice }
  from "../../../store/reducers/occupantInformationSlice/occupantInformationSlice";
import { selectTargetPath } from "../../../store/reducers/navigationSlice/navigationSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Occupants"
  },
  {
    name: "Add Occupant",
    bold: true
  }
];

export const handleReset = (dispatch: AppDispatch) => {
  dispatch(resetOccupantInformationSlice());
  dispatch(resetEmergencyContactInformationSlice());
};

const AddOccupant: React.FC<PropsWithChildren> = ({ children, ...props }): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const pageLocation = useLocation();
  const targetPath = useAppSelector(selectTargetPath);
  const newFacilityAdvisoryModal = useAppSelector(selectNewFacilityAdvisoryModal);
  const [workflowIncompleteWarning, setWorkflowIncompleteWarning] = React.useState(false);
  const [localTargetPath, setLocalTargetPath] = React.useState<string>("");
  const selectedFacility = useAppSelector(selectSelectedFacility);
  let unblock: Function;

  const handleAdvisory = () => {
    return blockNavigation(({ location }) => {
      const nextLocation = location.pathname;
      const currentLocation = pageLocation.pathname;

      setLocalTargetPath(nextLocation);

      const workflowComplete = (
        currentLocation === "/occupants/add-occupant/emergency-contact" &&
        nextLocation === "/occupants/manage-occupants"
      );

      if (
        // you're not on the first step
        currentLocation !== "/occupants/add-occupant" &&
        // and you're not navigating b/w workflow steps
        !nextLocation.includes("/occupants/add-occupant") &&
        // and you haven't finished adding the occupant
        !workflowComplete &&
        // and you're not performing an allowed redirect
        nextLocation !== targetPath &&
        // and you're not changing facilities (which should trigger a different modal)
        !newFacilityAdvisoryModal
      ) {
        setWorkflowIncompleteWarning(true);
        return false;
      }
      unblock();
      navigate(nextLocation);
    });
  };

  useEffect(() => {
    unblock = handleAdvisory();

    /* istanbul ignore next */ // cannot test this w/ enzyme
    return () => {
      if (unblock) {
        unblock();
      }
    };
  });

  useEffect(() => {
    handleReset(dispatch);
    dispatch(getAllFacilitySettings(String(selectedFacility?.id)));
    /* istanbul ignore next */ // cannot test this w/ enzyme
    return () => {
      handleReset(dispatch);
    };
  }, []);

  const confirmNavigationAway = () => {
    if (unblock) {
      unblock();
    }

    navigate(localTargetPath);
  };

  const pageHeader = <PageHeader title={"Add Occupant"} breadcrumbs={breadcrumbs} />;

  return (
    <ViewWrapper pageHeader={pageHeader} {...props}>
      {children}
      <AppModal
        open={workflowIncompleteWarning}
        cancelAction={() => setWorkflowIncompleteWarning(false)}
        confirmAction={confirmNavigationAway}
        data-testid={"add-occupant-modal"}
      >
        <Typography gutterBottom variant={"h6"}>Warning</Typography>
        <Typography variant={"body1"}>
          You are about to leave an incomplete workflow. If you choose to continue, information you have provided
          during this and previous steps will be lost.
        </Typography>
      </AppModal>
    </ViewWrapper>
  );
};

export default AddOccupant;
