import { FileDownloadOutlined } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import ExportChartOfAccountsAsExcel from "../ExportChartOfAccountsAsExcel/ExportChartOfAccountsAsExcel";
import React from "react";
import { selectSelectedAdminFacility } from "src/store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import { useAppSelector } from "src/store/hooks";
import useStyles from "./ExportMenu.styles";

const ExportMenu = ({ value }: { value: number }) => {
  const selectedFacility = useAppSelector(selectSelectedAdminFacility);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { classes } = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return selectedFacility && !!value
    ? (
      <>
        <Button
          className={classes.buttonExport}
          onClick={handleClick}
          data-testid={"menu-export"}
          endIcon={<FileDownloadOutlined sx={{ color: "#306DC8" }}/>}>
          <Typography pl={1} >Export as: </Typography>
        </Button>
        <ExportChartOfAccountsAsExcel anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      </>
      )
    : <></>;
};

export default ExportMenu;
