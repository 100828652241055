import { RootState } from "src/store/rootStore";
import { axiosInstance as axios } from "src/api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";

export const getFacilityPermissions = createAsyncThunk(
  "getFacilityPermissions",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const facilityId = state.selectedFacility.selectedFacility!.id;

    return axios.get(`/api/v1/permissions/${facilityId}`)
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = `There was an error getting the permissions for the selected facility.`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
