import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { APIListResponse } from "../../../models/responses/APIListResponse";
import { disableUnit } from "src/store/thunks/unit/disable/disableUnit";
import { ProductType } from "../../../models/ProductType";
import { ProductTypesSliceState } from "./ProductTypesSliceState";
import { RootState } from "../../rootStore";
import { getAllProductTypes } from "../../thunks/productType/getAll/getAllProductTypes";
import { getAllPaginatedProductTypes } from "src/store/thunks/productType/getAllPaginated/getAllPaginatedProductTypes";
import { searchProductTypes } from "src/store/thunks/productType/search/searchProductTypes";

export const initialState: ProductTypesSliceState = {
  productTypes: JSON.parse(sessionStorage.getItem("productTypes") as string) ?? [],
  productTypesLoading: false,
  productTypesError: "",
  deleteProductTypeModalOpen: false,
  deleteUnitModalOpen: false,
  disableUnitLoading: false,
  productToDelete: null
};

export const productTypesSlice = createSlice({
  name: "productTypes",
  initialState,
  reducers: {
    setProductTypes: (state, action: PayloadAction<ProductType[]>) => {
      state.productTypes = action.payload;
      sessionStorage.setItem("productTypes", JSON.stringify(state.productTypes));
    },
    resetProductTypesSlice: (state) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("productTypes");
    },
    setDeleteUnitModalOpen: (state, action) => {
      state.deleteUnitModalOpen = action.payload;
    },
    setDeleteProductTypeModalOpen: (state, action) => {
      state.deleteProductTypeModalOpen = action.payload;
    },
    setProductToDelete: (state, action) => {
      state.productToDelete = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProductTypes.pending, (state, action) => {
        state.productTypesLoading = true;
      })
      .addCase(
        getAllProductTypes.fulfilled,
        (state, action: PayloadAction<APIListResponse<ProductType[]>>) => {
          state.productTypes = action.payload.data;
          sessionStorage.setItem("productTypes", JSON.stringify(state.productTypes));
          state.productTypesLoading = false;
        })
      .addCase(getAllProductTypes.rejected, (state, action) => {
        state.productTypesLoading = false;
        state.productTypesError = action.payload as string;
      })
      .addCase(getAllPaginatedProductTypes.pending, (state, action) => {
        state.productTypesLoading = true;
      })
      .addCase(
        getAllPaginatedProductTypes.fulfilled,
        (state, action: PayloadAction<APIListResponse<ProductType[]>>) => {
          state.productTypes = action.payload.data;
          sessionStorage.setItem("productTypes", JSON.stringify(state.productTypes));
          state.productTypesLoading = false;
        })
      .addCase(getAllPaginatedProductTypes.rejected, (state, action) => {
        state.productTypesLoading = false;
        state.productTypesError = action.payload as string;
      })
      .addCase(disableUnit.pending, state => {
        state.disableUnitLoading = true;
      })
      .addCase(disableUnit.fulfilled, state => {
        state.disableUnitLoading = false;
        state.deleteUnitModalOpen = false;
      })
      .addCase(disableUnit.rejected, state => {
        state.disableUnitLoading = false;
        state.deleteUnitModalOpen = false;
      })
      .addCase(
        searchProductTypes.fulfilled,
        (state, action: PayloadAction<APIListResponse<ProductType[]>>) => {
          state.productTypes = action.payload.data;
          state.productTypesLoading = false;
        })
      .addCase(searchProductTypes.rejected, (state, action) => {
        state.productTypesLoading = false;
        state.productTypesError = action.payload as string;
      })
      .addCase(searchProductTypes.pending, (state, action) => {
        state.productTypesLoading = true;
      });
  }
});

export const selectProductTypes = (state: RootState) => state.productTypes.productTypes;
export const selectProductTypesLoading = (state: RootState) => state.productTypes.productTypesLoading;
export const selectDeleteProductTypeModalOpen = (state: RootState) => state.productTypes.deleteProductTypeModalOpen;
export const selectDeleteUnitModalOpen = (state: RootState) => state.productTypes.deleteUnitModalOpen;
export const selectDisableUnitLoading = (state: RootState) => state.productTypes?.disableUnitLoading ?? false;
export const selectProductTypeToDelete = (state: RootState) => state.productTypes.productToDelete;

export const {
  resetProductTypesSlice,
  setProductTypes,
  setDeleteUnitModalOpen,
  setDeleteProductTypeModalOpen,
  setProductToDelete
} = productTypesSlice.actions;

export default productTypesSlice.reducer;
