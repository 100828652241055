import { makeStyles } from "../../../makeStyles";

// eslint-disable-next-line max-len
const useStyles = makeStyles<{ promoStartError: boolean, promoEndError: boolean }>({ label: "PromotionForm" })((theme, { promoStartError, promoEndError }) => ({
  promoTypeIcon: {
    fontSize: "1.1rem",
    marginLeft: theme.spacing(1)
  },
  calendarWrapper: {
    borderRadius: "1rem",
    minHeight: "26rem"
  },
  header: {
    width: "100%",
    borderBottom: "1px solid #ddd",
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  promotionStartError: {
    border: `0.1rem solid ${promoStartError ? theme.palette.error.main : "#BDBDBDFF"}`
  },
  promotionEndError: {
    border: `0.1rem solid ${promoEndError ? theme.palette.error.main : "#BDBDBDFF"}`
  },
  calendar: {
    "& .Mui-selected": {
      color: theme.palette.common.white,
      background: theme.palette.primary.contrastText
    },
    width: "100%"
  },
  checkBox: {
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(3)
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(10)
    }
  },
  noArrowInput: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    },
    "input[type=number]": {
      MozAppearance: "textfield"
    }
  }
}));

export default useStyles;
