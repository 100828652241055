import * as Yup from "yup";
import { dateWarning } from "../../../utils/commonWarnings";
import moment from "moment";
import { today } from "../../../utils/__dateAndTimeUtils__/today";

const validationSchema = Yup.object().shape({
  taskName: Yup.string().required("Task Name is required"),
  taskCategoryId: Yup.number().moreThan(-1, "Task Category is required"),
  taskRecurrence: Yup.number().moreThan(-1, "Task Recurrence is required"),
  taskDueDate: Yup.string().required("Due Date is required").length(10, dateWarning).test(
    "validDate",
    "Due Date cannot be in the past",
    (value) => moment(value).isSameOrAfter(moment(today))
  ),
  taskDueTime: Yup.string().when("taskIsAllDay", {
    is: false,
    then: Yup.string().required("Due Time is required").test(
      "validTime",
      "Time is invalid", (value) => {
        //"value" should still be iso here, if we pass validation, we ship out.
        return moment(moment(value).format("hh:mm A"), "hh:mm A", true).isValid();
      }
    )
  }),
  taskFacilities: Yup.array().min(1, "Minimum 1 Facility required")
});

export default validationSchema;
