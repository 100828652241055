import {
  Box,
  Button,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
  Typography
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  ID_Type_Enum,
  selectAddressOne,
  selectAddressTwo,
  selectBusinessName,
  selectCity,
  selectDob,
  selectDriversLicenseNumber,
  selectDriversLicenseState,
  selectEmail,
  selectFirstName,
  selectGateCode,
  selectIdType,
  selectIsBusiness,
  selectIsTaxExempt,
  selectLastName,
  selectMiddleName,
  selectMilitaryBranch,
  selectMilitaryId,
  selectMilitaryStatus,
  selectPostalCode,
  selectPrimaryPhone,
  selectSecondaryPhone,
  selectState,
  selectTaxExemptDocument,
  selectTaxId,
  toggleIsBusiness
} from "src/store/reducers/occupantInformationSlice/occupantInformationSlice";
import React, { FormEvent, useEffect, useState } from "react";
import { UPLOADED_DOCUMENT_TYPES, toBase64 } from "../OccupantDocuments/OccupantDocuments";
import {
  selectChangeOwnershipIsEnabled,
  selectOccupant,
  setChangeOwnerShipStep
} from "src/store/reducers/occupantSlice/occupantSlice";
import {
  selectFormOccupantInformation,
  selectOccupantInformationBackup,
  setFormOccupantInformation,
  setOccupantInformationBackup
} from "src/store/reducers/changeOwnership/changeOwnership";
import { useAppDispatch, useAppSelector } from "src/store/hooks";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import AlertChangeOwnership from "../../components/AlertChangeOwnership/AlertChangeOwnership";
import CancelIcon from "@mui/icons-material/Cancel";
import EditOccupantInformationFooterButtons from "./EditOccupantInformationFooterButtons";
import { FileUpload } from "@mui/icons-material";
import MaskedPhone from "src/components/masking/MaskedPhone/MaskedPhone";
import MaskedPostalCode from "src/components/masking/MaskedPostalCode/MaskedPostalCode";
import { Moment } from "moment";
import { OwnershipButtons } from "../../components/OwnershipButtons/OwnershipButtons";
import PMSCheckbox from "src/components/ui/PMSCheckbox/PMSCheckbox";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import PMSSwitch from "src/components/ui/PMSSwitch/PMSSwitch";
import TaxExemptAlert from "./TaxExemptAlert/TaxExemptAlert";
import { UpdateOccupantPayload } from "src/store/thunks/occupant/update/UpdateOccupantPayload";
import editOccuapntValidation from "./editOccupantInformationValidation";
import { formatDate } from "src/utils/__dateAndTimeUtils__/formatDate/formatDate";
import { getOccupantTaxExemptDocument } from "src/store/thunks/occupantDocuments/getOccupantTaxExemptDocument";
import { inputError } from "src/utils/showInputError/showInputError";
import {
  selectFacilityGateSystemSetting
} from "src/store/reducers/facilitySettingInformationSlice/facilitySettingInformationSlice";
import { updateOccupant } from "src/store/thunks/occupant/update/updateOccupant";
import { uploadOccupantDocument } from "src/store/thunks/occupantDocuments/uploadDocument/uploadDocument";
import usStates from "src/utils/usStates";
import { useFormik } from "formik";
import { useParams } from "react-router";
import useStyles from "./EditOccupantInformation.styles";

const ID_TYPES = [
  { label: "Driver's License Number", value: ID_Type_Enum.DriversLicense },
  { label: "State ID Number", value: ID_Type_Enum.StateId },
  { label: "Passport Number", value: ID_Type_Enum.Passport }
];

const EditOccupantInformation = () => {
  const dispatch = useAppDispatch();
  const firstName = useAppSelector(selectFirstName);
  const middleName = useAppSelector(selectMiddleName);
  const lastName = useAppSelector(selectLastName);
  const addressOne = useAppSelector(selectAddressOne);
  const addressTwo = useAppSelector(selectAddressTwo);
  const city = useAppSelector(selectCity);
  const state = useAppSelector(selectState);
  const postalCode = useAppSelector(selectPostalCode);
  const customerEmail = useAppSelector(selectEmail);
  const customerPrimaryPhone = useAppSelector(selectPrimaryPhone);
  const customerSecondaryPhone = useAppSelector(selectSecondaryPhone);
  const driversLicenseNumber = useAppSelector(selectDriversLicenseNumber);
  const driversLicenseState = useAppSelector(selectDriversLicenseState);
  const taxId = useAppSelector(selectTaxId);
  const militaryStatus = useAppSelector(selectMilitaryStatus);
  const customerDob = useAppSelector(selectDob);
  const customerGateCode = useAppSelector(selectGateCode);
  const isBusiness = useAppSelector(selectIsBusiness);
  const isTaxExempt = useAppSelector(selectIsTaxExempt);
  const customerBusinessName = useAppSelector(selectBusinessName);
  const changeOwnershipIsEnabled = useAppSelector(selectChangeOwnershipIsEnabled);
  const occupantInformationBackup = useAppSelector(selectOccupantInformationBackup);
  const formOccupantInformation = useAppSelector(selectFormOccupantInformation);
  const taxExemptDocument = useAppSelector(selectTaxExemptDocument);
  const occupant = useAppSelector(selectOccupant);
  const [showTaxExemptAlert, setShowTaxExemptAlert] = useState(isTaxExempt);
  const { classes } = useStyles();
  const [taxExemptDocumentBase64, setTaxExemptDocumentBase64] = useState<File | null>(null);
  const selectedOccupant = useAppSelector(selectOccupant);
  const gateCodeProvider = useAppSelector(selectFacilityGateSystemSetting);
  const idType = useAppSelector(selectIdType);
  const militaryBranch = useAppSelector(selectMilitaryBranch);
  const militaryId = useAppSelector(selectMilitaryId);

  const params = useParams<{ id: string }>();

  const formik = useFormik({
    initialValues: {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      addressOne: addressOne,
      addressTwo: addressTwo,
      city: city,
      state: state,
      postalCode: postalCode,
      email: customerEmail,
      primaryPhone: customerPrimaryPhone,
      secondaryPhone: customerSecondaryPhone,
      idNumber: driversLicenseNumber,
      idState: driversLicenseState,
      taxIdNumber: taxId,
      militaryStatus: militaryStatus ? militaryStatus.toFixed() : militaryStatus,
      dob: customerDob,
      gateCode: customerGateCode,
      business: isBusiness,
      taxExempt: isTaxExempt,
      taxExemptDocument: taxExemptDocument as File,
      businessName: customerBusinessName,
      gateCodeProvider: gateCodeProvider?.value,
      idType,
      militaryBranch: militaryBranch,
      militaryId: militaryId
    },
    validationSchema: editOccuapntValidation,
    validateOnBlur: false,
    validateOnChange: true,
    onSubmit: (values) => {
      if (changeOwnershipIsEnabled) {
        const payload = {
          ...values,
          id: params.id,
          military: values.militaryStatus,
          taxExemptNumber: values.taxIdNumber,
          address: values.addressOne,
          business: values.business,
          businessName: values.businessName,
          country: "USA",
          alternatePhone: values.secondaryPhone
        };

        dispatch(setFormOccupantInformation(payload));
        dispatch(setChangeOwnerShipStep(1));
      } else {
        const payload: UpdateOccupantPayload = {
          id: params.id!,
          first_name: values.firstName,
          middle_name: values.middleName!,
          last_name: values.lastName,
          email: values.email,
          tax_id: values.taxIdNumber,
          gate_code: String(values.gateCode),
          id_number: values.idNumber,
          id_state: values.idState,
          military_status: +values.militaryStatus,
          date_of_birth: values.dob,
          is_tax_exempt: values.taxExempt,
          is_business: values.business,
          business_name: values.businessName,
          address: values.addressOne,
          address_two: values.addressTwo!,
          city: values.city,
          region: values.state,
          postal_code: values.postalCode,
          country: "USA",
          phone_primary: values.primaryPhone,
          phone_alternate: values.secondaryPhone,
          id_type: values.idType
        };

        if (Number(values.militaryStatus) === 4 || Number(values.militaryStatus) === 5) {
          payload.military_branch = values.militaryBranch;
          payload.military_id = values.militaryId;
        }

        dispatch(updateOccupant(payload)).then(() => {
          if (taxExemptDocumentBase64) {
            toBase64(taxExemptDocumentBase64 as File)!
              .then((base64) => {
                return dispatch(
                  uploadOccupantDocument({
                    file: base64! as string,
                    occupantId: selectedOccupant?.id!,
                    name: taxExemptDocumentBase64?.name!,
                    type: UPLOADED_DOCUMENT_TYPES["Tax Exempt"]
                  })
                );
              })
              .then(() => dispatch(getOccupantTaxExemptDocument({ occupantId: params?.id! })));
          } else {
            dispatch(getOccupantTaxExemptDocument({ occupantId: params?.id! }));
          }
        });
      }
    }
  });

  const { values, errors, handleChange, touched, setFieldValue, setFieldTouched, resetForm, initialValues, isValid } =
    formik;

  const handleBusinessToggle = (isBusiness: boolean) => {
    dispatch(toggleIsBusiness(isBusiness));
    setFieldValue("business", isBusiness);
  };

  const handleZipCodeChange = (fieldName: string, fieldValue: string) => {
    setFieldTouched(fieldName);
    setFieldValue(fieldName, fieldValue);
  };

  useEffect(() => {
    if (changeOwnershipIsEnabled) {
      if (initialValues.firstName && !formOccupantInformation.firstName) {
        const values = {
          ...initialValues,
          id: params.id,
          military: initialValues.militaryStatus,
          taxExemptNumber: initialValues.taxIdNumber,
          taxEmpt: initialValues.taxExempt,
          address: initialValues.addressOne,
          business: initialValues.business,
          businessName: initialValues.businessName,
          country: "USA",
          alternatePhone: initialValues.secondaryPhone
        };

        dispatch(setOccupantInformationBackup(values));
      }

      if (formOccupantInformation.firstName) {
        const form = {
          firstName: formOccupantInformation.firstName,
          middleName: formOccupantInformation.middleName,
          lastName: formOccupantInformation.lastName,
          addressOne: formOccupantInformation.address,
          addressTwo: formOccupantInformation.addressTwo,
          city: formOccupantInformation.city,
          state: formOccupantInformation.state,
          postalCode: formOccupantInformation.postalCode,
          email: formOccupantInformation.email,
          primaryPhone: formOccupantInformation.primaryPhone,
          secondaryPhone: formOccupantInformation.alternatePhone,
          idNumber: formOccupantInformation.idNumber,
          idState: formOccupantInformation.idState,
          taxIdNumber: formOccupantInformation.taxExemptNumber,
          militaryStatus: formOccupantInformation.military,
          dob: formOccupantInformation.dob,
          gateCode: formOccupantInformation.gateCode,
          business: formOccupantInformation.business,
          taxExempt: formOccupantInformation.taxExempt,
          businessName: formOccupantInformation.businessName,
          taxExemptDocument: formOccupantInformation.taxExemptDocument as File,
          idType: formOccupantInformation.idType,
          militaryBranch: 0,
          militaryId: ""
        };

        resetForm({
          values: {
            ...form,
            gateCodeProvider: gateCodeProvider?.value
          }
        });
      } else {
        resetForm({
          values: {
            firstName: "",
            middleName: "",
            lastName: "",
            addressOne: "",
            addressTwo: "",
            city: "",
            state: "",
            postalCode: "",
            email: "",
            primaryPhone: "",
            secondaryPhone: "",
            idNumber: "",
            idState: "",
            taxIdNumber: "",
            militaryStatus: "",
            dob: null,
            gateCode: "",
            business: false,
            taxExempt: false,
            businessName: "",
            taxExemptDocument: {} as File,
            gateCodeProvider: "",
            idType: 1,
            militaryBranch: 0,
            militaryId: ""
          }
        });
      }
    } else {
      if (occupantInformationBackup.firstName) {
        const form = {
          firstName: occupantInformationBackup.firstName,
          middleName: occupantInformationBackup.middleName,
          lastName: occupantInformationBackup.lastName,
          addressOne: occupantInformationBackup.address,
          addressTwo: occupantInformationBackup.addressTwo,
          city: occupantInformationBackup.city,
          state: occupantInformationBackup.state,
          postalCode: occupantInformationBackup.postalCode,
          email: occupantInformationBackup.email,
          primaryPhone: occupantInformationBackup.primaryPhone,
          secondaryPhone: occupantInformationBackup.alternatePhone,
          idNumber: occupantInformationBackup.idNumber,
          idState: occupantInformationBackup.idState,
          taxIdNumber: occupantInformationBackup.taxExemptNumber,
          militaryStatus: occupantInformationBackup.military,
          dob: occupantInformationBackup.dob,
          gateCode: occupantInformationBackup.gateCode,
          business: occupantInformationBackup.business,
          taxExempt: occupantInformationBackup.taxExempt,
          businessName: occupantInformationBackup.businessName,
          taxExemptDocument: occupantInformationBackup.taxExemptDocument as File,
          idType: occupantInformationBackup.idType,
          militaryBranch: 0,
          militaryId: ""
        };

        resetForm({
          values: {
            ...form,
            gateCodeProvider: gateCodeProvider?.value
          }
        });
      }
    }
  }, [changeOwnershipIsEnabled]);

  useEffect(() => {
    setFieldValue("taxExemptDocument", taxExemptDocument);
  }, [taxExemptDocument]);

  const handleTaxExemptToggle = async(event: React.ChangeEvent<HTMLInputElement>) => {
    await setFieldValue("taxExempt", event.target.checked);
    if (event.target.checked) {
      setShowTaxExemptAlert(true);
      return;
    }
    setShowTaxExemptAlert(false);
    await setFieldValue("taxExemptDocument", null);
  };

  const handleTaxExemptDocumentChange = async(event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];
    setFieldValue("taxExemptDocument", file);
    setTaxExemptDocumentBase64(file ?? null);
  };

  return (
    <>
      <TaxExemptAlert
        showTaxExemptAlert={showTaxExemptAlert}
        setShowTaxExemptAlert={setShowTaxExemptAlert}
        severity={values.taxExemptDocument?.name ? "success" : "error"}
      />

      <Grid
        component={"form"}
        id={"edit-occupant-form"}
        onSubmit={async(e: FormEvent) => {
          e.preventDefault();
          e.stopPropagation();
          formik.handleSubmit();
        }}
      >
        <Box className={classes.containerTitle}>
          <Typography gutterBottom variant={"h6"} component={"h3"}>
            Primary Occupant Information
          </Typography>
          <OwnershipButtons isOccupantInformation />
        </Box>
        <Divider />
        {changeOwnershipIsEnabled && <AlertChangeOwnership />}
        <Grid container mt={2} spacing={2}>
          <Grid xs={8} lg={5} item>
            <InputLabel htmlFor={"first-name"}>First Name</InputLabel>
            <TextField
              fullWidth
              placeholder={"- First Name -"}
              id={"first-name"}
              inputProps={{ "data-testid": "first-name" }}
              name={"firstName"}
              value={values.firstName}
              onChange={handleChange}
              error={inputError("firstName", touched, errors).error}
              helperText={inputError("firstName", touched, errors).helperText}
            />
          </Grid>
          <Grid xs={4} lg={2} item>
            <InputLabel htmlFor={"middle-name"}>Middle Initial</InputLabel>
            <TextField
              fullWidth
              placeholder={"- Middle Initial -"}
              id={"middle-name"}
              inputProps={{ "data-testid": "middle-name" }}
              name={"middleName"}
              value={values.middleName}
              onChange={handleChange}
              error={inputError("middleName", touched, errors).error}
              helperText={inputError("middleName", touched, errors).helperText}
            />
          </Grid>
          <Grid xs={12} lg={5} item>
            <InputLabel htmlFor={"last-name"}>Last Name</InputLabel>
            <TextField
              fullWidth
              placeholder={"- Last Name -"}
              id={"last-name"}
              inputProps={{ "data-testid": "last-name" }}
              name={"lastName"}
              value={values.lastName}
              onChange={handleChange}
              error={inputError("lastName", touched, errors).error}
              helperText={inputError("lastName", touched, errors).helperText}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputLabel htmlFor={"mailing-address-one"}>Mailing Address 1</InputLabel>
            <TextField
              fullWidth
              placeholder={"- Mailing Address 1 -"}
              id={"mailing-address-one"}
              inputProps={{ "data-testid": "mailing-address-one" }}
              name={"addressOne"}
              value={values.addressOne}
              onChange={handleChange}
              error={inputError("addressOne", touched, errors).error}
              helperText={inputError("addressOne", touched, errors).helperText}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputLabel htmlFor={"mailing-address-two"}>Mailing Address 2</InputLabel>
            <TextField
              fullWidth
              placeholder={"- Mailing Address 2 -"}
              id={"mailing-address-two"}
              inputProps={{ "data-testid": "mailing-address-two" }}
              name={"addressTwo"}
              value={values.addressTwo}
              onChange={handleChange}
              error={inputError("addressTwo", touched, errors).error}
              helperText={inputError("addressTwo", touched, errors).helperText}
            />
          </Grid>
          <Grid xs={6} lg={3} item>
            <InputLabel htmlFor={"city"}>City</InputLabel>
            <TextField
              fullWidth
              placeholder={"- City -"}
              id={"city"}
              inputProps={{ "data-testid": "city" }}
              name={"city"}
              value={values.city}
              onChange={handleChange}
              error={inputError("city", touched, errors).error}
              helperText={inputError("city", touched, errors).helperText}
            />
          </Grid>
          <Grid xs={6} lg={3} item>
            <PMSSelect
              id={"state"}
              name={"state"}
              label={"State"}
              value={values.state}
              changeHandler={handleChange}
              error={inputError("state", touched, errors).error}
              helperText={inputError("state", touched, errors).helperText}
            >
              <option value={""} disabled>
                {" - State -"}
              </option>
              {usStates.map((state) => (
                <option key={state.value} value={state.value}>
                  {state.text}
                </option>
              ))}
            </PMSSelect>
          </Grid>
          <Grid xs={6} lg={3} item>
            <InputLabel htmlFor={"postal-code"}>Postal Code</InputLabel>
            <MaskedPostalCode
              fullWidth
              placeholder={"- Postal Code -"}
              id={"postal-code"}
              name={"postalCode"}
              value={values.postalCode}
              onChange={(e) => handleZipCodeChange("postalCode", e.target.value)}
              error={inputError("postalCode", touched, errors).error}
              helperText={inputError("postalCode", touched, errors).helperText}
            />
          </Grid>
          <Grid xs={6} lg={3} item>
            <InputLabel htmlFor={"country"}>Country</InputLabel>
            <Select
              fullWidth
              disabled
              defaultValue={""}
              id={"country"}
              displayEmpty
              variant={"outlined"}
              value={""}
              inputProps={{ "data-testid": "country" }}
            >
              <MenuItem value={""} disabled>
                United States
              </MenuItem>
            </Select>
          </Grid>
          <Grid item alignSelf={"center"} xs={6}
            lg={3}>
            <InputLabel htmlFor={"latitude"} disabled>
              Latitude
            </InputLabel>
            <TextField
              name={"latitude"}
              disabled
              fullWidth
              value={occupant?.latitude ? `${occupant.latitude}°` : "–"}
            />
          </Grid>
          <Grid item xs={6} alignSelf={"center"}
            lg={3}>
            <InputLabel htmlFor={"longitude"} disabled>
              Longitude
            </InputLabel>
            <TextField
              name={"longitude"}
              disabled
              fullWidth
              value={occupant?.longitude ? `${occupant.longitude}°` : "–"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputLabel htmlFor={"email"}>Email</InputLabel>
            <TextField
              fullWidth
              placeholder={"- Email Address -"}
              id={"email"}
              inputProps={{ "data-testid": "email" }}
              name={"email"}
              value={values.email}
              onChange={handleChange}
              error={inputError("email", touched, errors).error}
              helperText={inputError("email", touched, errors).helperText}
            />
          </Grid>
        </Grid>
        <Grid container mt={2} spacing={2}>
          <Grid item xs={6} lg={3}>
            <InputLabel htmlFor={"taxIdNumber"}>Tax ID</InputLabel>
            <TextField
              fullWidth
              placeholder={"- Tax ID -"}
              data-mask-sensitive={true}
              id={"taxIdNumber"}
              inputProps={{ "data-testid": "taxIdNumber" }}
              name={"taxIdNumber"}
              value={values.taxIdNumber}
              onChange={handleChange}
              error={inputError("taxIdNumber", touched, errors).error}
              helperText={inputError("taxIdNumber", touched, errors).helperText}
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <PMSSelect
              id={"military-status"}
              label={"Military Status"}
              name={"militaryStatus"}
              value={values.militaryStatus}
              changeHandler={(ev) => {
                if (ev.target.value !== 4 && ev.target.value !== 5) {
                  setFieldValue("militaryBranch", 0);
                  setFieldValue("militaryId", "");
                }
                handleChange(ev);
              }}
              error={inputError("militaryStatus", touched, errors).error}
              helperText={inputError("militaryStatus", touched, errors).helperText}
            >
              <option selected disabled value={""}>
                {" - Military Status -"}
              </option>
              <option value={1}>Not Indicated</option>
              <option value={2}>Veteran</option>
              <option value={3}>Not a Veteran</option>
              <option value={4}>Active Duty</option>
              <option value={5}>Reserve</option>
            </PMSSelect>
          </Grid>
          {(Number(values.militaryStatus) === 4 || Number(values.militaryStatus) === 5) && (
            <>
              <Grid xs={6} item>
                <PMSSelect
                  id={"military-branch"}
                  label={"Military Branch"}
                  name={"militaryBranch"}
                  value={values.militaryBranch}
                  changeHandler={handleChange}
                  error={inputError("militaryBranch", touched, errors).error}
                  helperText={inputError("militaryBranch", touched, errors).helperText}
                >
                  <option value={0} disabled selected>
                    {" - Military Branch -"}
                  </option>
                  <option value={1}>Air Force</option>
                  <option value={2}>Army</option>
                  <option value={3}>Coast Guard</option>
                  <option value={4}>Marine Corps</option>
                  <option value={5}>Navy</option>
                  <option value={6}>Space Force</option>
                </PMSSelect>
              </Grid>
              <Grid xs={6} item>
                <InputLabel htmlFor={"taxIdNumber"}>ID Number</InputLabel>
                <TextField
                  fullWidth
                  placeholder={"- ID Number -"}
                  id={"militaryId"}
                  type={"number"}
                  inputProps={{ "data-testid": "militaryId" }}
                  name={"militaryId"}
                  value={values.militaryId}
                  onChange={(e) => {
                    const value = String(e.target.value);
                    if (value.length > 10) {
                      return;
                    }
                    setFieldValue("militaryId", value);
                  }}
                  error={inputError("militaryId", touched, errors).error}
                  helperText={inputError("militaryId", touched, errors).helperText}
                />
              </Grid>
            </>
          )}
          <Grid item xs={6} lg={3}>
            <InputLabel htmlFor={"primaryPhone"}>Primary Phone</InputLabel>
            <MaskedPhone
              id={"primaryPhone"}
              dataTestId={"primaryPhone"}
              fullWidth
              variant={"outlined"}
              name={"primaryPhone"}
              type={"tel"}
              placeholder={"- Primary Phone -"}
              onChange={handleChange}
              value={values.primaryPhone}
              error={inputError("primaryPhone", touched, errors).error}
              helperText={inputError("primaryPhone", touched, errors).helperText}
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <InputLabel htmlFor={"secondaryPhone"}>Secondary Phone</InputLabel>
            <MaskedPhone
              id={"secondaryPhone"}
              dataTestId={"secondaryPhone"}
              fullWidth
              variant={"outlined"}
              name={"secondaryPhone"}
              type={"tel"}
              placeholder={"- Secondary Phone -"}
              onChange={handleChange}
              value={values.secondaryPhone}
              error={inputError("secondaryPhone", touched, errors).error}
              helperText={inputError("secondaryPhone", touched, errors).helperText}
            />
          </Grid>
          <Grid item xs={6} lg={2}>
            <InputLabel htmlFor={"dob"}>Date of Birth</InputLabel>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                OpenPickerButtonProps={{ "aria-label": "Select Date of Birth" }}
                componentsProps={{
                  leftArrowButton: { "aria-label": "Move Backward" },
                  rightArrowButton: { "aria-label": "Move Backward" }
                }}
                renderInput={(props: TextFieldProps) => (
                  <TextField
                    name={"dob"}
                    id={"dob"}
                    fullWidth
                    placeholder={"- Date of Birth -"}
                    {...props}
                    helperText={inputError("dob", touched, errors).helperText}
                    error={inputError("dob", touched, errors).error}
                  />
                )}
                inputFormat={"MM/DD/yyyy"}
                data-testid={"dob"}
                value={values.dob}
                onChange={(value: Moment | null, keyboardInputValue?: string | undefined) =>
                  setFieldValue("dob", formatDate(value))
                }
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} lg={2}>
            <InputLabel htmlFor={"gateCode"}>Gate Code</InputLabel>
            <TextField
              fullWidth
              className={classes.noArrowInput}
              placeholder={"- Gate Code -"}
              id={"gateCode"}
              inputProps={{ "data-testid": "gateCode" }}
              name={"gateCode"}
              type={"number"}
              value={values.gateCode}
              onChange={handleChange}
              error={inputError("gateCode", touched, errors).error}
              helperText={inputError("gateCode", touched, errors).helperText}
            />
          </Grid>

          <Grid item xs={6} lg={2}>
            <PMSSelect
              id={"idType"}
              label={"ID Type"}
              name={"idType"}
              value={values.idType}
              changeHandler={(e) => setFieldValue("idType", e.target.value)}
              error={inputError("idType", touched, errors).error}
              helperText={inputError("idType", touched, errors).helperText}
            >
              <option value={0} disabled>
                {" - ID Type -"}
              </option>
              {ID_TYPES.map((type) => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </PMSSelect>
          </Grid>

          <Grid item xs={6} lg={3}>
            <InputLabel htmlFor={"idNumber"}>
              {ID_TYPES.find((idType) => idType.value === Number(values.idType))?.label ?? "Driver's License Number"}
            </InputLabel>
            <TextField
              fullWidth
              placeholder={`- ${ID_TYPES.find((idType) => idType.value === Number(values.idType))?.label} -`}
              data-mask-sensitive={true}
              id={"idNumber"}
              inputProps={{
                "data-testid": "idNumber",
                ...(Number(values.idType) === ID_Type_Enum.Passport && { maxLength: 9 })
              }}
              name={"idNumber"}
              value={values.idNumber}
              onChange={
                Number(values.idType) === ID_Type_Enum.Passport
                  ? (e) => setFieldValue("idNumber", e.target.value.toUpperCase() as string)
                  : handleChange
              }
              error={inputError("idNumber", touched, errors).error}
              helperText={inputError("idNumber", touched, errors).helperText}
            />
          </Grid>

          {Number(values.idType) !== ID_Type_Enum.Passport && (
            <Grid item xs={6} lg={3}>
              <PMSSelect
                id={"id-state"}
                name={"idState"}
                label={Number(values.idType) === ID_Type_Enum.StateId ? "ID State" : "Driver's License State"}
                value={values.idState}
                changeHandler={handleChange}
                error={inputError("idState", touched, errors).error}
                helperText={inputError("idState", touched, errors).helperText}
                disabled={Number(values.idType) === ID_Type_Enum.Passport}
              >
                <option value={""}>Select a State...</option>
                {usStates.map((state) => (
                  <option key={state.text} value={state.value}>
                    {state.text}
                  </option>
                ))}
              </PMSSelect>
            </Grid>
          )}

          <Grid alignSelf={"center"} item xs={6}
            lg={3}>
            <PMSSwitch
              name={"business"}
              size={"medium"}
              smallText
              isChecked={values.business}
              label={"Business Account"}
              changeHandler={(e) => handleBusinessToggle(e.target.checked)}
            />
          </Grid>
          {values.business && (
            <Grid item xs={6} lg={3}>
              <InputLabel htmlFor={"businessName"}>Business Name</InputLabel>
              <TextField
                fullWidth
                placeholder={"- Business Name -"}
                id={"businessName"}
                inputProps={{ "data-testid": "businessName" }}
                name={"businessName"}
                value={values.businessName}
                onChange={handleChange}
                error={inputError("businessName", touched, errors).error}
                helperText={inputError("businessName", touched, errors).helperText}
              />
            </Grid>
          )}
          <Grid alignSelf={"center"} item xs={6}
            lg={3}>
            <PMSCheckbox
              smallText
              name={"taxExempt"}
              label={"Tax Exempt"}
              isChecked={values.taxExempt}
              changeHandler={handleTaxExemptToggle}
            />
          </Grid>
          {values.taxExempt && (
            <Grid alignSelf={"center"} item xs={6}
              lg={3}>
              <Button
                className={classes.uploadButton}
                variant={"contained"}
                startIcon={<FileUpload />}
                component={"label"}
              >
                Upload Document
                <input
                  id={"taxExemptDocument"}
                  name={"taxExemptDocument"}
                  onChange={handleTaxExemptDocumentChange}
                  type={"file"}
                  hidden
                />
              </Button>
            </Grid>
          )}
          {values.taxExempt && values.taxExemptDocument?.name && (
            <Grid alignSelf={"center"} container item
              xs={6} lg={3}>
              <Grid item className={classes.fileNameContainer}>
                <Typography mt={1} variant={"caption"} className={classes.fileName}
                  noWrap alignSelf={"center"}>
                  {values.taxExemptDocument?.name}
                </Typography>

                <CancelIcon className={classes.cancelIcon} onClick={() => setFieldValue("taxExemptDocument", null)} />
              </Grid>
            </Grid>
          )}
        </Grid>
        <EditOccupantInformationFooterButtons />
      </Grid>
    </>
  );
};

export default EditOccupantInformation;
