import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { Grid, InputLabel, ListItemText, MenuItem, TextField, TextFieldProps } from "@mui/material";
import React, { FormEvent, ReactElement, useEffect } from "react";
import moment, { Moment } from "moment";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Facility } from "../../../models/Facility";
import { FormikProps } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import PMSCheckbox from "../../../components/ui/PMSCheckbox/PMSCheckbox";
import PMSMultiSelect from "../../../components/ui/PMSMultiSelect/PMSMultiSelect";
import PMSSelect from "../../../components/ui/PMSSelect/PMSSelect";
import PMSSwitch from "../../../components/ui/PMSSwitch/PMSSwitch";
import { TaskCategory } from "../../../models/TaskCategory";
import { TaskRecurrence } from "../../../enums/TaskRecurrence";
import { TaskValues } from "../../../models/formikInputValues/TaskValues";
import { formatDate } from "../../../utils/__dateAndTimeUtils__/formatDate/formatDate";
import { inputError } from "../../../utils/showInputError/showInputError";
import {
  resetTaskFormSlice
} from "../../../store/reducers/taskFormSlice/taskFormSlice";
import { selectFacilities } from "../../../store/reducers/facilitiesSlice/facilitiesSlice";

export const selectedFacilitiesDisplay = (facilityIds: (number | "all")[], facilities: Facility[]) => {
  if (!facilityIds || !facilityIds.length) {
    return "- Select Facility -";
  }

  if (facilityIds.includes("all")) {
    return "All Facilities";
  }

  return facilityIds.map(facilityId => {
    return facilities.find(currentFacility => currentFacility.id === facilityId)?.legal_name;
  }).join(", ");
};

interface TaskFormProps {
  taskCategories: TaskCategory[]
  formik: FormikProps<TaskValues>
}

const TaskForm: React.FC<TaskFormProps> = ({
  taskCategories,
  formik
}): ReactElement => {
  const dispatch = useAppDispatch();
  const facilities = useAppSelector(selectFacilities);

  const { handleChange, handleSubmit, setFieldValue, values, touched, errors, setFieldTouched } = formik;

  const handleAppliedTaskFacilitiesOnChange = (event: any) => {
    const { target: { value } } = event;

    if (values.taskFacilities.includes("all") && !(value?.includes("all"))) {
      setFieldValue("taskFacilities", []);
      return;
    }
    setFieldValue("taskFacilities", value);
  };

  const availableFacilitiesDisplay = facilities.map((facility) => (
    <MenuItem
      data-testid={`available-facility-${facility.id}`}
      key={facility.id}
      value={facility.id}
      disabled={values.taskFacilities.includes("all")}
    >
      <PMSCheckbox
        changeHandler={() => null}
        isChecked={values.taskFacilities.includes(facility.id) || values.taskFacilities.includes("all")}
      />
      <ListItemText primary={facility.facility_id} />
    </MenuItem>
  ));

  const handleDueDateOnChange = (date: string | Moment | null) => {
    setFieldValue("taskDueDate", formatDate(date, "MM/DD/YYYY", "N/A"));
  };

  const handleDueTimeOnChange = (time: string | Moment | null) => {
    setFieldValue("taskDueTime", time);
  };

  const taskCategoriesDisplay = taskCategories.map(category => (
    <option key={category.id} value={category.id}>{category.name}</option>
  ));

  const taskRecurrenceDisplay = (
    <>
      <option value={TaskRecurrence.oneTime}>One-Time</option>
      <option value={TaskRecurrence.daily}>Daily</option>
      <option value={TaskRecurrence.weekly}>Weekly</option>
      <option value={TaskRecurrence.biweekly}>Biweekly</option>
      <option value={TaskRecurrence.monthly}>Monthly</option>
      <option value={TaskRecurrence.endOfMonth}>End of Month</option>
      <option value={TaskRecurrence.quarterly}>Quarterly</option>
      <option value={TaskRecurrence.annually}>Annually</option>
    </>
  );

  const handleTaskRecurrenceChange = (fieldValue: TaskRecurrence) => {
    if (fieldValue === TaskRecurrence.endOfMonth) {
      setFieldTouched("taskDueDate");
      setFieldValue("taskDueDate", moment().clone().endOf("month").format("MM/DD/YYYY"));
    }
    setFieldTouched("taskRecurrence");
    setFieldValue("taskRecurrence", fieldValue);
  };

  useEffect(() => {
    return () => {
      dispatch(resetTaskFormSlice());
    };
  }, []);

  return (
    <Grid
      id={"task-form"}
      container
      spacing={2}
      mt={1}
      component={"form"}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
      }}
    >
      <Grid
        item
        container
        xs={6}
        xl={3}
        direction={"column"}
      >
        <InputLabel htmlFor={"task-name"}>Task Name</InputLabel>
        <TextField
          id={"task-name"}
          fullWidth
          placeholder={"- Task Name -"}
          variant={"outlined"}
          name={"taskName"}
          value={values.taskName}
          onChange={handleChange}
          error={inputError("taskName", touched, errors).error}
          helperText={inputError("taskName", touched, errors).helperText}
        />
      </Grid>
      <Grid
        item
        container
        xs={6}
        xl={3}
        direction={"column"}
      >
        <PMSSelect
          id={"task-category"}
          label={"Task Category"}
          name={"taskCategoryId"}
          value={values.taskCategoryId}
          changeHandler={handleChange}
          error={inputError("taskCategoryId", touched, errors).error}
          helperText={inputError("taskCategoryId", touched, errors).helperText}
        >
          <option value={-1} disabled>- Choose Category -</option>
          {taskCategoriesDisplay}
        </PMSSelect>
      </Grid>
      <Grid
        item
        container
        xs={6}
        xl={3}
        direction={"column"}
      >
        <PMSSelect
          id={"task-recurrence"}
          label={"Task Recurrence"}
          name={"taskRecurrence"}
          value={values.taskRecurrence}
          changeHandler={e => handleTaskRecurrenceChange(parseInt(e.target.value))}
          error={inputError("taskRecurrence", touched, errors).error}
          helperText={inputError("taskRecurrence", touched, errors).helperText}
        >
          <option value={-1} disabled>- Choose Recurrence -</option>
          {taskRecurrenceDisplay}
        </PMSSelect>
      </Grid>
      <Grid
        item
        container
        xs={6}
        xl={3}
        direction={"column"}
      >
        <PMSMultiSelect
          id={"task-facilities"}
          label={"Apply to Facilities"}
          value={values.taskFacilities}
          name={"taskFacilities"}
          changeHandler={handleAppliedTaskFacilitiesOnChange}
          error={inputError("taskFacilities", touched, errors).error}
          helperText={inputError("taskFacilities", touched, errors).helperText}
          renderValue={(facilityIds) => selectedFacilitiesDisplay(facilityIds, facilities)}
        >
          <MenuItem disabled>- Select Facility -</MenuItem>
          <MenuItem value={"all"}>
            <PMSCheckbox
              changeHandler={() => null}
              isChecked={values.taskFacilities.includes("all" as never)}
            />
            <ListItemText primary={"Select All Facilities"} />
          </MenuItem>
          {availableFacilitiesDisplay}
        </PMSMultiSelect>
      </Grid>
      <Grid
        item
        container
        xs={6}
        direction={"column"}
      >
        <InputLabel htmlFor={"task-description"}>Description</InputLabel>
        <TextField
          id={"task-description"}
          fullWidth
          multiline
          rows={5.33}
          inputProps={{ maxLength: 255 }}
          name={"taskDescription"}
          value={values.taskDescription}
          onChange={handleChange}
          error={inputError("taskDescription", touched, errors).error}
          helperText={inputError("taskDescription", touched, errors).helperText}
        />
      </Grid>
      <Grid
        item
        container
        xs={6}
        spacing={2}
      >
        <Grid item xs={12} xl={6}>
          <InputLabel htmlFor={"task-due-date"}>Due Date:</InputLabel>
          <DatePicker
            renderInput={(params: TextFieldProps) => {
              return (
                <TextField
                  {...params}
                  id={"task-due-date"}
                  fullWidth
                  name={"taskDueDate"}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "- Due Date -"
                  }}
                  error={inputError("taskDueDate", touched, errors).error}
                  helperText={inputError("taskDueDate", touched, errors).helperText}
                />
              );
            }}
            inputFormat={"MM/DD/yyyy"}
            minDate={moment()}
            value={values.taskDueDate}
            onChange={handleDueDateOnChange}
          />
        </Grid>
        <Grid item xs={12} xl={6}>
          <InputLabel htmlFor={"task-due-time"}>Due Time:</InputLabel>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker
              ampm
              renderInput={(params: TextFieldProps) => (
                <TextField
                  {...params}
                  id={"task-due-time"}
                  fullWidth
                  name={"taskDueTime"}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "- Due Time -"
                  }}
                  error={inputError("taskDueTime", touched, errors).error}
                  helperText={!values.taskIsAllDay && inputError("taskDueTime", touched, errors).helperText}
                />
              )}
              disabled={values.taskIsAllDay}
              value={values.taskDueTime}
              onChange={handleDueTimeOnChange}
            />
          </LocalizationProvider>
          <Grid px={1} pt={2}>
            <PMSSwitch
              smallText
              name={"taskIsAllDay"}
              label={"All Day Event"}
              labelPlacement={"end"}
              isChecked={values.taskIsAllDay}
              changeHandler={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TaskForm;
