import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles<{ disabled?: boolean }>({ label: "CashForm" })((theme, { disabled }) => ({
  moneyIcon: {
    fontSize: 75,
    marginBottom: theme.spacing(1),
    color: disabled ? "grey" : ""
  },
  noArrow: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    },
    "input[type=number]": {
      MozAppearance: "textfield"
    }
  }
}));

export default useStyles;
