import React, { useCallback, useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { hideDrawer, selectShowDrawer } from "./store/reducers/navigationSlice/navigationSlice";
import { useAppDispatch, useAppSelector } from "./store/hooks";

import AccountingRoutes from "./routes/AccountingRoutes";
import AddOccupantRoutes from "./routes/AddOccupantRoutes";
import AddProductTypeRoutes from "./routes/AddProductTypeRoutes";
import AppSoftphone from "./components/softphone/AppSoftphone";
import AuthLanding from "./pages/AuthLanding/AuthLanding";
import { AuthenticatedRoute as AuthRouteType } from "./models/AuthenticatedRoute";
import CollectionsRoutes from "./routes/CollectionsRoutes";
import CreateMoveInRoutes from "./routes/CreateMoveInRoutes";
import CreateMoveOutRoutes from "./routes/CreateMoveOutRoutes";
import CreateNewDealRoutes from "./routes/CreateNewDealRoutes";
import Dashboard from "./pages/Dashboard/Dashboard";
import DealFollowUpModal from "./components/dealNotification/DealFollowUpModal";
import DealsRoutes from "./routes/DealsRoutes";
import DelinquencyAutomationRoutes from "./routes/DelinquencyAutomationRoutes";
import DocumentManagementRoutes from "./routes/DocumentManagementRoutes";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import EsignDocument from "./pages/DocumentManagement/ESign/EsignDocument";
import FabPulsatingButton from "./pages/Dashboard/FabPulsatingButton/FabPulsatingButton";
import FacilityManagementRoutes from "./routes/FacilityManagementRoutes";
import FeesRoutes from "./routes/FeesRoutes";
import GlobalSettingRoutes from "./routes/GlobalSettingRoutes";
import InsurancePlansRoutes from "./routes/InsurancePlansRoutes";
import RateChangesRoutes from "./routes/RateChangesRoutes";
import AskingRateChangesRoutes from "./routes/AskingRateChangesRoutes";
import Layout from "./layouts/AppLayout/AppLayout";
import LogRocket from "logrocket";
import MerchandiseRoutes from "./routes/MerchandiseRoutes";
import MyScheduleRoutes from "./routes/MyScheduleRoutes";
import OccupantRoutes from "./routes/OccupantRoutes";
import PointOfSalesRoutes from "./routes/PointOfSalesRoutes";
import PortraitBlockerModal from "./components/portraitBlocker/PortraitBlockerModal";
import ProductTypeRoutes from "./routes/ProductTypeRoutes";
import PromotionsRoutes from "./routes/PromotionsRoutes";
import QA403 from "./pages/Redirect403/QA403";
import Redirect403 from "./pages/Redirect403/Redirect403";
import Redirect404 from "./pages/Redirect404/Redirect404";
import Redirect500 from "./pages/Redirect500/Redirect500";
import ReportingRoutes from "./routes/ReportingRoutes";
import TaskManagementRoutes from "./routes/TaskManagementRoutes";
import TransferUnitRoutes from "./routes/TransferUnitRoutes.";
import UnitsRoutes from "./routes/UnitsRoutes";
import UserSettingsRoutes from "./routes/UserSettingsRoutes";
import { Userpilot } from "userpilot";
import WebhookRoutes from "./routes/WebhookRoutes";
import { checkAppLiteRoute } from "./utils/checkAppLiteRoute";
import { refreshToken } from "./store/thunks/refreshToken/refreshToken";
import { selectFatalError } from "./store/reducers/errorSlice/errorSlice";
import { selectSelectedFacility } from "./store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { throttle } from "lodash";
import { useSoftphone } from "./hooks/useSoftphone";

export const mapRoutes = (routes: AuthRouteType[]) => {
  return routes.map((routeProps, i) => <Route key={i} {...routeProps} />);
};

const showLiteApp = checkAppLiteRoute(window.location.pathname);
const getOrientation = () => window.matchMedia("(orientation: portrait)").matches;

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const fatalError = useAppSelector(selectFatalError);
  const [isPortrait, setIsPortrait] = useState<boolean>(getOrientation);
  const drawerOpen = useAppSelector(selectShowDrawer);
  const intervalRef = useRef<number>();
  const token = sessionStorage.getItem("bearerToken");
  const params = new URLSearchParams(window.location.search);
  const bearer = params.get("bearer");
  const isAuthenticated = Boolean(token || bearer);
  const location = useLocation();
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const { handleRegisterListeners } = useSoftphone();
  interface ObservabilityUserInfo {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    created_at: string;
  }

  // adding this event listener to prevent the browser from displaying
  // the default "leave site" dialog when the user is redirected to the 500 error page
  window.addEventListener("beforeunload", function(event) {
    event.stopImmediatePropagation();
  });

  if (process.env.REACT_APP_USERPILOT_TOKEN) {
    Userpilot.initialize(process.env.REACT_APP_USERPILOT_TOKEN);
  }

  const userInfo = localStorage.getItem("userInfo");
  let parsedUser: ObservabilityUserInfo | null = null;

  if (userInfo) {
    parsedUser = JSON.parse(userInfo);
  }

  useEffect(() => {
    if (isAuthenticated && parsedUser?.id) {
      const logRocketAppId = window.config.reactAppLogRocketAppId;
      if (logRocketAppId) {
        LogRocket.init(logRocketAppId, {
          dom: {
            privateAttributeBlocklist: ["data-mask-sensitive"]
          }
        });

        LogRocket.identify(String(parsedUser.id), {
          name: parsedUser.first_name + " " + parsedUser.last_name,
          email: parsedUser.email
        });
      }

      Userpilot.identify(parsedUser.id, {
        name: parsedUser.first_name + " " + parsedUser.last_name,
        email: parsedUser.email,
        created_at: parsedUser.created_at
      });

      window.upscope(
        parsedUser.id,
        parsedUser.first_name,
        parsedUser.last_name,
        parsedUser.email,
        parsedUser.created_at
      );
    }
  }, [isAuthenticated]);

  useEffect(() => {
    Userpilot.reload();
  }, [location]);

  const routes = (
    <Routes>
      <Route path={"/"} element={<Dashboard />} />
      {AccountingRoutes}
      {AddOccupantRoutes}
      {AddProductTypeRoutes}
      {CreateNewDealRoutes}
      {CreateMoveInRoutes}
      {CreateMoveOutRoutes}
      {MyScheduleRoutes}
      {DealsRoutes}
      {CollectionsRoutes}
      {DocumentManagementRoutes}
      {InsurancePlansRoutes}
      {RateChangesRoutes}
      {AskingRateChangesRoutes}
      {FacilityManagementRoutes}
      {FeesRoutes}
      {GlobalSettingRoutes}
      {MerchandiseRoutes}
      {OccupantRoutes}
      {PointOfSalesRoutes}
      {ProductTypeRoutes}
      {PromotionsRoutes}
      {ReportingRoutes}
      {TaskManagementRoutes}
      {UserSettingsRoutes}
      {DelinquencyAutomationRoutes}
      {UnitsRoutes}
      {TransferUnitRoutes}
      {WebhookRoutes}
      <Route path={"/authenticate"} element={<AuthLanding />} />
      <Route path={"/500"} element={<Redirect500 />} />
      <Route path={"/404"} element={<Redirect404 />} />
      {/* TODO: Remove this route once permissions are in place */}
      <Route path={"/QA-403"} element={<QA403 />} />
      <Route path={"/403"} element={<Redirect403 />} />
      <Route path={"*"} element={<Redirect404 />} />
    </Routes>
  );

  const liteAppRoutes = (
    <Routes>
      <Route path={"documents/eSign-sign/:eSignId"} element={<EsignDocument />} />
    </Routes>
  );

  useEffect(() => {
    if (isPortrait && drawerOpen) {
      dispatch(hideDrawer());
    }
  }, [isPortrait]);

  const getRefreshToken = useCallback(() => {
    dispatch(refreshToken());
  }, []);

  useEffect(() => {
    const interval = window.setInterval(() => getRefreshToken(), 1800000);
    intervalRef.current = interval;
    return () => window.clearInterval(interval);
  }, [getRefreshToken]);

  //Added throttle to avoid calling this function for every pixel if user is resizing the browser
  const updateOrientation = throttle(() => {
    setIsPortrait(getOrientation());
  }, 100);

  useEffect(() => {
    handleRegisterListeners(dispatch, selectedFacility);
    window.addEventListener("resize", updateOrientation);
    return () => window.removeEventListener("resize", updateOrientation);
  }, []);

  return showLiteApp
    ? (
        fatalError
          ? (
            <ErrorPage data-testid={"error"} />
            )
          : (
              liteAppRoutes
            )
      )
    : (
      <>
        <PortraitBlockerModal open={isPortrait} />
        <Layout>{fatalError ? <ErrorPage data-testid={"error"} /> : routes}</Layout>
        <AppSoftphone />
        <DealFollowUpModal />
        <FabPulsatingButton />
      </>
      );
};
export default App;
