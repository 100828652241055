import InventorySliceState from "./InventorySliceState";
import { RootState } from "src/store/rootStore";
import { createSlice } from "@reduxjs/toolkit";
import { getMerchInventory } from "src/store/thunks/inventory/getMerchInventory/getMerchInventory";

export const initialState: InventorySliceState = {
  loading: false,
  inventory: [],
  inventoryError: "",
  selectedInventoryItem: null,
  addInventoryModalOpen: false,
  editInventoryModalOpen: false
};

export const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    setSelectedInventoryItem: (state, action) => {
      state.selectedInventoryItem = action.payload;
    },
    setEditInventoryModalOpen: (state, action) => {
      state.editInventoryModalOpen = action.payload;
    },
    setInventoryModalOpen: (state, action) => {
      state.addInventoryModalOpen = action.payload;
    },
    resetInventorySlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMerchInventory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMerchInventory.fulfilled, (state, action) => {
        state.loading = false;
        state.inventory = action.payload.data;
      })
      .addCase(getMerchInventory.rejected, (state, action) => {
        state.loading = false;
        state.inventory = initialState.inventory;
        state.inventoryError = action.payload as string;
      });
  }
});

export const selectInventoryLoading = (state:RootState) => state.inventory.loading;
export const selectInventory = (state:RootState) => state.inventory.inventory;
export const selectSelectedInventoryItem = (state:RootState) => state.inventory.selectedInventoryItem;
export const selectInventoryError = (state:RootState) => state.inventory.inventoryError;
export const selectInventoryModalOpen = (state:RootState) => state.inventory.addInventoryModalOpen;
export const selectEditInventoryModalOpen = (state:RootState) => state.inventory.editInventoryModalOpen;

export const {
  setSelectedInventoryItem,
  setEditInventoryModalOpen,
  setInventoryModalOpen,
  resetInventorySlice
} = inventorySlice.actions;

export default inventorySlice.reducer;
