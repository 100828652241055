import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProductCategory } from "../../../models/ProductCategory";
import { ProductCategorySliceState } from "./ProductCategorySliceState"; // every slice should have a state typing
import { RootState } from "../../rootStore";
import { createProductCategory } from "../../thunks/productCategory/create/createProductCategory";
import { createProductSubCategory } from "../../thunks/productSubCategory/create/createProductSubCategory";
import { getProductCategory } from "../../thunks/productCategory/get/getProductCategory";
import { getProductType } from "src/store/thunks/productType/get/getProductType";

export const initialState: ProductCategorySliceState = {
  newProductCategoryName: "",
  newProductSubCategoryName: "",
  productCategory: JSON.parse(sessionStorage.getItem("productCategory") as string) ?? { name: "" },
  productSubCategory: JSON.parse(sessionStorage.getItem("productSubCategory") as string) ?? null,
  productCategoryLoading: false,
  productCategoryError: "",
  productSubCategoryLoading: false,
  productSubCategoryError: ""
};

export const productCategorySlice = createSlice({
  name: "productCategory",
  initialState,
  reducers: {
    setProductCategory: (state, action: PayloadAction<ProductCategory | null>) => {
      state.productCategory = action.payload;
      sessionStorage.setItem("productCategory", JSON.stringify(state.productCategory));
    },
    setProductSubCategory: (state, action: PayloadAction<ProductCategory | null>) => {
      state.productSubCategory = action.payload;
      sessionStorage.setItem("productSubCategory", JSON.stringify(state.productSubCategory));
    },
    setNewProductCategory: (state, action: PayloadAction<string>) => {
      state.newProductCategoryName = action.payload;
    },
    setNewProductSubCategory: (state, action: PayloadAction<string>) => {
      state.newProductSubCategoryName = action.payload;
    },
    resetProductCategorySlice: (state) => {
      Object.assign(state, initialState);
      sessionStorage.setItem("productCategory", JSON.stringify({ name: "" }));
      sessionStorage.removeItem("productSubCategory");
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductCategory.pending, (state) => {
        state.productCategoryLoading = true;
      })
      .addCase(getProductCategory.fulfilled, (state, action: PayloadAction<ProductCategory>) => {
        state.productCategoryLoading = false;
        state.productCategory = action.payload;
        sessionStorage.setItem("productCategory", JSON.stringify(state.productCategory));
      })
      .addCase(getProductCategory.rejected, (state, action) => {
        state.productCategoryError = action.payload as string;
        state.productCategoryLoading = false;
      })
      .addCase(createProductCategory.pending, (state) => {
        state.productCategoryLoading = true;
      })
      .addCase(createProductCategory.fulfilled, (state, action: PayloadAction<ProductCategory>) => {
        state.productCategoryLoading = false;
        state.productCategory = action.payload;
        sessionStorage.setItem("productCategory", JSON.stringify(state.productCategory));
      })
      .addCase(createProductCategory.rejected, (state, action) => {
        state.productCategoryError = action.payload as string;
        state.productCategoryLoading = false;
      })
      .addCase(createProductSubCategory.pending, (state) => {
        state.productSubCategoryLoading = true;
      })
      .addCase(createProductSubCategory.fulfilled, (state, action: PayloadAction<ProductCategory>) => {
        state.productSubCategoryLoading = false;
        state.productSubCategory = action.payload;
        sessionStorage.setItem("productSubCategory", JSON.stringify(state.productSubCategory));
      })
      .addCase(createProductSubCategory.rejected, (state, action) => {
        state.productSubCategoryError = action.payload as string;
        state.productSubCategoryLoading = false;
      })
      .addCase(getProductType.fulfilled, (state, action) => {
        const productCategory = action.payload.product_category ?? null;
        const productSubCategory = action.payload.product_category?.sub_categories ?? null;

        state.productCategory = productCategory;
        state.productSubCategory = productSubCategory;
        sessionStorage.setItem("productCategory", JSON.stringify(productCategory));
        sessionStorage.setItem("productSubCategory", JSON.stringify(productSubCategory));
      });
  }
});

export const selectProductCategory = (state: RootState) => state.productCategory.productCategory;
export const selectProductSubCategory = (state: RootState) => state.productCategory.productSubCategory;
export const selectNewProductCategoryName = (state: RootState) => state.productCategory.newProductCategoryName;
export const selectNewProductSubCategoryName = (state: RootState) => state.productCategory.newProductSubCategoryName;
export const selectProductCategoryLoading = (state: RootState) => state.productCategory.productCategoryLoading;
export const selectProductSubCategoryLoading = (state: RootState) => state.productCategory.productSubCategoryLoading;

export const {
  setNewProductCategory,
  setNewProductSubCategory,
  setProductCategory,
  setProductSubCategory,
  resetProductCategorySlice
} = productCategorySlice.actions;

export default productCategorySlice.reducer;
