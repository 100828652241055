import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "PaymentConfirmation" })((theme) => ({
  title: {
    fontWeight: "bold"
  },
  button: {
    borderColor: theme.palette.grey[400],
    color: theme.palette.primary.contrastText,
    fontWeight: "normal",
    textTransform: "uppercase"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
    maxWidth: "35%",
    minHeight: "10rem",
    borderRadius: "1rem"
  },
  modalContainer: {
    borderRadius: "1rem",
    padding: theme.spacing(4)
  }
}));

export default useStyles;
