import React, { FC, memo, useCallback, useState } from "react";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import { SelectChangeEvent } from "@mui/material";
import { Unit } from "src/models/Unit";
import { selectProductTypes } from "src/store/reducers/productTypesSlice/productTypesSlice";
import { useAppSelector } from "src/store/hooks";

interface ProductTypeSelectionProps {
  row: Unit;
  handleProductTypeChange: (id: number, newTypeId: number) => void;
}

const ProductTypeSelection: FC<ProductTypeSelectionProps> = ({ row, handleProductTypeChange }) => {
  const productTypes = useAppSelector(selectProductTypes);
  const [selectedProductTypeId, setSelectedProductTypeId] = useState<number>(row.product_type_id);

  const handleChange = useCallback((e: SelectChangeEvent) => {
    const newProductTypeId = Number(e.target.value);
    setSelectedProductTypeId(newProductTypeId);
    handleProductTypeChange(row.id, newProductTypeId);
  }, [handleProductTypeChange, row.id]);

  return (
    <PMSSelect
      size={"small"}
      id={`product-type-${row.id}`}
      // disabled={!contains}
      value={selectedProductTypeId.toString()}
      changeHandler={handleChange}
    >
      {productTypes.map(productType => (
        <option key={productType.id} value={productType.id}>{productType.name}</option>
      ))}
    </PMSSelect>
  );
};

export default memo(ProductTypeSelection);
