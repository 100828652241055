import { Button, Divider, Grid, IconButton, InputLabel, Modal, Paper, Typography } from "@mui/material";
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import {
  selectBucketFilter,
  selectPaymentAmount,
  selectSelectedDelinquent,
  setIsPartialPayment,
  setPaymentAmount,
  setPaymentCompleteModalOpen,
  setPaymentModalOpen
} from "src/store/reducers/collectionsSlice/collectionsSlice";
import {
  selectOrder,
  selectOrderBy,
  selectPage,
  selectRowsPerPage,
  selectSearchValue
} from "src/store/reducers/tablesSlice/tablesSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import ACHForm from "./payment/AchForm/AchForm";
import CashForm from "./payment/CashForm/CashForm";
import { Close } from "@mui/icons-material";
import CreditCardForm from "./payment/CreditCardForm/CreditCardForm";
import DelinquentPaymentTable from "./DelinquentPaymentTable/DelinquentPaymentTable";
import { PaymentMethod as Method } from "src/enums/PaymentMethod";
import MoneyOrderForm from "./payment/MoneyOrderForm/MoneyOrderForm";
import PaymentMethod from "./payment/PaymentMethod";
import { PaymentMethod as PaymentMethodEnum } from "../../../../enums/PaymentMethod";
import {
  partialPaymentDeliquentOccupants
} from "src/store/thunks/delinquentOccupants/partial-payment/partialPaymentDelinquentOccupants";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { useLazyGetDelinquentOccupantsQuery } from "src/api/endpoints/delinquentOccupants";
import useStyles from "./PaymentModal.styles";
import { getOccupant } from "src/store/thunks/occupant/getOne/getOccupant";

interface PaymentModalProps {
  open: boolean;
  onClose: Function;
}

const PaymentModal: FC<PaymentModalProps> = ({ open, onClose }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const selectedDelinquent = useAppSelector(selectSelectedDelinquent);
  const paymentAmount = useAppSelector(selectPaymentAmount);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(PaymentMethodEnum.cash);
  const facility = useAppSelector(selectSelectedFacility);
  const page = useAppSelector(selectPage);
  const pageSize = useAppSelector(selectRowsPerPage);
  const order = useAppSelector(selectOrder);
  const orderBy = useAppSelector(selectOrderBy);
  const bucketFilter = useAppSelector(selectBucketFilter);
  const searchValue = useAppSelector(selectSearchValue);
  const params = useMemo(() => ({
    page: page + 1,
    pageSize,
    rowsPerPage: pageSize,
    sortDirection: order!,
    sortBy: orderBy,
    bucket: bucketFilter,
    searchValue
  }), [page, pageSize, order, orderBy, bucketFilter, searchValue]);
  const [trigger] = useLazyGetDelinquentOccupantsQuery();
  const actionButtons = useMemo(
    () => (
      <Grid pt={2} mt={4} container
        justifyContent={"flex-end"} spacing={2}>
        <Grid item>
          <Button
            data-testid={"cancel-button"}
            variant={"text"}
            color={"secondary"}
            className={classes.cancelButton}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button type={"submit"} data-testid={"cancel-button"} variant={"contained"}
            className={classes.confirmButton}>
            Submit
          </Button>
        </Grid>
      </Grid>
    ),
    []
  );

  useEffect(() => {
    if (selectedDelinquent?.id) {
      dispatch(getOccupant(selectedDelinquent?.occupant_id.toString()));
    }

    return () => {
      dispatch(setIsPartialPayment(false));
      dispatch(setPaymentAmount(""));
    };
  }, []);

  const handleSubmit = <T extends unknown>(values: T) => {
    dispatch(partialPaymentDeliquentOccupants({ selectedPaymentMethod, paymentAmount, values })).then((resp) => {
      if (!resp.type.includes("rejected")) {
        dispatch(setPaymentCompleteModalOpen(true));
        trigger({ id: facility.id, params });
      }
    });
    dispatch(setPaymentModalOpen(false));
  };

  const cashForm = useMemo(
    () => (
      <CashForm
        actionButtons={actionButtons}
        handleSubmit={handleSubmit}
        amountDue={paymentAmount || String(selectedDelinquent?.balance)}
      />
    ),
    [paymentAmount, selectedDelinquent, selectedPaymentMethod]
  );

  const achForm = useMemo(
    () => <ACHForm actionButtons={actionButtons} handleSubmit={handleSubmit} />,
    [paymentAmount, selectedDelinquent, selectedPaymentMethod]
  );

  const creditCardForm = useMemo(
    () => <CreditCardForm actionButtons={actionButtons} handleSubmit={handleSubmit} useSavedPaymentInstrument/>,
    [paymentAmount, selectedDelinquent, selectedPaymentMethod]
  );

  const moneyOrderForm = useMemo(
    () => (
      <MoneyOrderForm
        handleSubmit={handleSubmit}
        amountDue={paymentAmount || String(selectedDelinquent?.balance)}
        actionButtons={actionButtons}
    />
    ),
    [paymentAmount, selectedDelinquent, selectedPaymentMethod]
  );

  const paymentEntryContent = (paymentType: PaymentMethodEnum) => {
    let header = "";
    let paymentForm = null;

    switch (paymentType) {
      case Method.cash:
        header = "Payment Information";
        paymentForm = cashForm;
        break;
      case Method.ach:
        header = "ACH Bank Information";
        paymentForm = achForm;
        break;
      case Method.creditCard:
        header = "Credit Card Information";
        paymentForm = creditCardForm;
        break;
      case Method.moneyOrder:
        header = "Check/Money Order Information";
        paymentForm = moneyOrderForm;
        break;
    }

    return { header, paymentForm };
  };

  return (
    <Modal open={open} className={classes.modal}>
      <Grid container p={4} className={classes.root}
        component={Paper} elevation={0}>
        <Grid xs={12} container item
          justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant={"h4"} gutterBottom>
            Quick Payment
          </Typography>
          <IconButton onClick={() => onClose()}>
            <Close fontSize={"large"} />
          </IconButton>
        </Grid>
        <Grid xs={12} item mb={2}>
          <Divider />
        </Grid>
        <Grid container xs={12} className={classes.remainingBalance}>
          <Typography variant={"h6"} fontWeight={"bold"}>
            Remaining Balance
          </Typography>
          <Typography fontWeight={"bold"}>
            $ {selectedDelinquent?.balance ? (+selectedDelinquent?.balance - +paymentAmount).toFixed(2) : "0.00"}
          </Typography>
        </Grid>
        <Grid xs={12}>
          <DelinquentPaymentTable />
        </Grid>
        <Grid item md={12} lg={3}>
          <InputLabel className={classes.headerCell}>
            <Typography noWrap>Payment Method</Typography>
          </InputLabel>
          <PaymentMethod
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSelectedPaymentMethod(Number(e.target.value) as PaymentMethodEnum);
            }}
            selectedOption={selectedPaymentMethod}
          />
        </Grid>
        <Grid item container md={12}
          lg={8}>
          <InputLabel className={classes.headerCell}>
            <Typography noWrap>{paymentEntryContent(selectedPaymentMethod).header}</Typography>
          </InputLabel>
          {paymentEntryContent(selectedPaymentMethod).paymentForm}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default PaymentModal;
