import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "Delinquency Automation components" })((theme) => ({
  buttonText: {
    textTransform: "capitalize"
  },
  button: {
    textTransform: "uppercase",
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.common.white
  }

}));

export default useStyles;
