import React, { FC } from "react";
import {
  selectAchForm,
  selectCreditCardForm,
  setAchPaymentOptions,
  setCreditCardPaymentOptions
} from "src/store/reducers/changeOwnership/changeOwnership";
import {
  selectEnableAutoPay as selectAutoPay,
  selectIsPreferredPayment as selectIsPreferred,
  setEnableAutoPay as setEnableAutoPayACH,
  setIsPreferredPayment as setIsPreferredPaymentACH
} from "src/store/reducers/achSlice/achSlice";
import {
  selectEnableAutoPay,
  selectIsPreferredPayment,
  setEnableAutoPay,
  setIsPreferredPayment
} from "src/store/reducers/creditCardSlice/creditCardSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import FormGroup from "@mui/material/FormGroup";
import PMSCheckbox from "src/components/ui/PMSCheckbox/PMSCheckbox";
import { PaymentMethod as PaymentMethodEnum } from "src/enums/PaymentMethod";
import { selectChangeOwnershipIsEnabled } from "src/store/reducers/occupantSlice/occupantSlice";
import useStyles from "./PaymentOptions.styles";

const PaymentOptions = ({ paymentMethod, disabled }: { paymentMethod: PaymentMethodEnum; disabled?: boolean }) => {
  const enableAutoBillingCC = useAppSelector(selectEnableAutoPay);
  const isPreferredPaymentCC = useAppSelector(selectIsPreferredPayment);
  const enableAutoBillingACH = useAppSelector(selectAutoPay);
  const isPreferredPaymentACH = useAppSelector(selectIsPreferred);
  const changeOwnershipIsEnabled = useAppSelector(selectChangeOwnershipIsEnabled);
  const achForm = useAppSelector(selectAchForm);
  const ccForm = useAppSelector(selectCreditCardForm);
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const [isDisabled, setIsDisabled] = React.useState<boolean>(false);

  const handleChangeAutoBilling = () => {
    if (changeOwnershipIsEnabled) {
      if (paymentMethod === PaymentMethodEnum.ach) {
        dispatch(
          setAchPaymentOptions({
            isPreferredPayment: achForm.isPreferredPayment,
            enableAutoPay: !achForm.enableAutoPay
          })
        );
      } else {
        dispatch(
          setCreditCardPaymentOptions({
            isPreferredPayment: ccForm.isPreferredPayment,
            enableAutoPay: !ccForm.enableAutoPay
          })
        );
      }
    } else {
      if (paymentMethod === PaymentMethodEnum.ach) {
        dispatch(setEnableAutoPayACH(!enableAutoBillingACH));

        if (!enableAutoBillingACH === true) {
          dispatch(setIsPreferredPaymentACH(true));
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
      } else {
        dispatch(setEnableAutoPay(!enableAutoBillingCC));

        if (!enableAutoBillingCC === true) {
          dispatch(setIsPreferredPayment(true));
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
      }
    }
  };

  const handleChangeSaveAsPreferred = () => {
    if (changeOwnershipIsEnabled) {
      if (paymentMethod === PaymentMethodEnum.ach) {
        dispatch(
          setAchPaymentOptions({
            isPreferredPayment: !achForm.isPreferredPayment,
            enableAutoPay: achForm.enableAutoPay
          })
        );
      } else {
        dispatch(
          setCreditCardPaymentOptions({
            isPreferredPayment: !ccForm.isPreferredPayment,
            enableAutoPay: ccForm.enableAutoPay
          })
        );
      }
    } else {
      if (paymentMethod === PaymentMethodEnum.ach) {
        dispatch(setIsPreferredPaymentACH(!isPreferredPaymentACH));
      } else {
        dispatch(setIsPreferredPayment(!isPreferredPaymentCC));
      }
    }
  };

  const handleAutoBillingChecked = () => {
    if (changeOwnershipIsEnabled) {
      if (paymentMethod === PaymentMethodEnum.ach) {
        return achForm.enableAutoPay;
      } else {
        return ccForm.enableAutoPay;
      }
    } else {
      if ((enableAutoBillingACH || enableAutoBillingCC) && !isDisabled) {
        setIsDisabled(true);
      }

      if (paymentMethod === PaymentMethodEnum.ach) {
        return enableAutoBillingACH;
      } else {
        return enableAutoBillingCC;
      }
    }
  };

  const handlePreferredPaymentChecked = () => {
    if (changeOwnershipIsEnabled) {
      if (paymentMethod === PaymentMethodEnum.ach) {
        return achForm.isPreferredPayment;
      } else {
        return ccForm.isPreferredPayment;
      }
    } else {
      if (paymentMethod === PaymentMethodEnum.ach) {
        return isPreferredPaymentACH;
      } else {
        return isPreferredPaymentCC;
      }
    }
  };

  return (
    <FormGroup className={classes.formGroupContainer}>
      <PMSCheckbox
        smallText
        data-testid={"enable-autopay"}
        label={"Enable Auto Billing"}
        name={"enable-auto-billing"}
        isChecked={handleAutoBillingChecked()}
        changeHandler={() => handleChangeAutoBilling()}
        disabled={disabled}
      />
      <PMSCheckbox
        smallText
        data-testid={"save-payment-method"}
        label={"Save Payment Method"}
        name={"save-payment-method"}
        isChecked={handlePreferredPaymentChecked()}
        changeHandler={() => handleChangeSaveAsPreferred()}
        disabled={disabled || isDisabled}
      />
    </FormGroup>
  );
};
export default PaymentOptions;
