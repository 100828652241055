import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "OccupantLedgerTable" })((theme) => ({
  table: {
    border: "none",
    marginBottom: theme.spacing(2),
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
      display: "none"
    }
  },
  buttons: {
    justifyContent: "flex-end"
  },
  total: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    border: `1.5px solid ${theme.palette.text.primary}`,
    borderRadius: "0.25rem"
  }
}));

export default useStyles;
