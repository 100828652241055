import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LedgersSliceState } from "./ledgersSliceState";
import { Occupant } from "../../../models/Occupant";
import { RootState } from "../../rootStore";
import { getLedgerAsPdf } from "src/store/thunks/ledger/print/getLedgerAsPdf";
import { getOccupant } from "../../thunks/occupant/getOne/getOccupant";
import _ from "lodash";
import { LEDGER_COLUMNS_TO_TOTAL_UP } from "src/pages/Occupants/EditOccupant/LedgerView/LedgerMiddle/LedgerMiddle";

export const initialState: LedgersSliceState = {
  ledgersModalOpen: false,
  rentedUnitsModalOpen: false,
  selectedLedger: null,
  ledgers: [],
  ledgersLoading: false,
  ledgerUnit: null,
  ledgersError: "",
  selectedLedgerToPrint: "",
  isTransferUnitModalOpen: false,
  selectedLedgerToTransfer: 0,
  ledgerItemsTotalAmount: {
    applied_refund_amount: 0,
    issued_credit_amount: 0,
    payment_amount: 0,
    charge_amount: 0,
    tax_amount: 0,
    pending_amount: 0
  }
};

export const ledgersSlice = createSlice({
  name: "ledgers",
  initialState,
  reducers: {
    setLedgersModalOpen: (state, action) => {
      state.ledgersModalOpen = action.payload;
    },
    setRentedUnitsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.rentedUnitsModalOpen = action.payload;
    },
    setSelectedLedger: (state, action) => {
      state.selectedLedger = action.payload;
      state.ledgerItemsTotalAmount = initialState.ledgerItemsTotalAmount;
    },
    setIsTransferUnitModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isTransferUnitModalOpen = action.payload;
    },
    setSelectedLedgerToTransfer: (state, action: PayloadAction<number>) => {
      state.selectedLedgerToTransfer = action.payload;
    },
    setLedgerItemsTotalAmount: (state, action: PayloadAction<{key: string, value:any}>) => {
      if (LEDGER_COLUMNS_TO_TOTAL_UP.includes(action.payload.key) && parseFloat(action.payload.value)) {
        state.ledgerItemsTotalAmount = {
          ...state.ledgerItemsTotalAmount,
          [action.payload.key]:
          state.ledgerItemsTotalAmount[action.payload.key as "payment_amount"] + parseFloat(action.payload.value)
        };
      };
    },
    resetLedgersSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOccupant.pending, (state) => {
        state.ledgersLoading = true;
      })
      .addCase(getOccupant.fulfilled, (state, action: PayloadAction<Occupant>) => {
        state.ledgersLoading = false;
        state.ledgers = action.payload.ledgers || [];
      })
      .addCase(getOccupant.rejected, (state, action) => {
        state.ledgersLoading = false;
        state.ledgersError = action.payload as string;
      })
      .addCase(getLedgerAsPdf.fulfilled, (state, action) => {
        state.selectedLedgerToPrint = action.payload as string;
      });
  }
});

export const {
  setLedgersModalOpen,
  setSelectedLedger,
  setRentedUnitsModalOpen,
  setIsTransferUnitModalOpen,
  setSelectedLedgerToTransfer,
  setLedgerItemsTotalAmount,
  resetLedgersSlice
} = ledgersSlice.actions;

export const selectUnitLedgers = (state: RootState) => state.ledgers.ledgers;
export const selectLedgersModalOpen = (state: RootState) => state.ledgers.ledgersModalOpen;
export const selectSelectedLedger = (state: RootState) => state.ledgers.selectedLedger;
export const selectRentedUnitsModalOpen = (state: RootState) => state.ledgers.rentedUnitsModalOpen;
export const selectSelectedLedgerToPrint = (state: RootState) => state.ledgers.selectedLedgerToPrint;
export const selectIsTransferUnitModalOpen = (state: RootState) => state.ledgers.isTransferUnitModalOpen;
export const selectSelectedLedgerToTransfer = (state: RootState) => state.ledgers.selectedLedgerToTransfer;
export const selectLedgerItemsTotalAmount = (state: RootState) => state.ledgers.ledgerItemsTotalAmount;

export default ledgersSlice.reducer;
