import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "SpeedeRentActionButtons" })((theme) => ({
  baseButton: {
    textTransform: "capitalize"
  },
  filledButton: {
    background: "#70D63F",
    color: theme.palette.common.white,
    margin: `${theme.spacing(1)} 0`,
    "&:hover": {
      background: "#1C3610"
    }
  },
  textButton: {
    color: theme.palette.primary.contrastText
  }
}));

export default useStyles;
