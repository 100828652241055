import React, { FormEvent } from "react";
import { Box, Button, Grid, Modal, Paper, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useStyles from "./MoveOutConfirmationModal.styles";

interface MoveOutConfirmationModalProps {
  open: boolean;
  onModalClose: Function;
  onModalSubmit: Function;
  loading: boolean;
}
const MoveOutConfirmationModal = ({ open, onModalClose, onModalSubmit, loading }: MoveOutConfirmationModalProps) => {
  const { classes } = useStyles();

  return (
    <Modal
      open={open}
      onClose={() => onModalClose()}
      aria-labelledby={"Modal Component"}
      aria-describedby={"A Modal component."}
      className={classes.modal}
      data-testid={"modal-move-in-confirmation"}
    >
      <Grid
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        spacing={2}
        className={classes.container}
        pb={2}
      >
        <Box
          component={"form"}
          onSubmit={(e: FormEvent) => {
            e.preventDefault();
            e.stopPropagation();
            onModalSubmit();
          }}
        >
          <Typography variant={"h5"} textAlign={"center"}>
            Confirm Move Out Payment
          </Typography>

          <Grid container justifyContent={"center"}>
            <Grid item mt={"0.5rem"}>
              <Typography variant={"body1"} textAlign={"center"}>
                Confirm move out payment for balance remainder on selected unit
              </Typography>
            </Grid>
          </Grid>

          <Grid pt={4} spacing={4} container
            justifyContent={"center"}>
            <Grid item>
              <Button
                data-testid={"cancel-button"}
                className={classes.cancelButton}
                onClick={() => onModalClose()}
                variant={"outlined"}
                color={"secondary"}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                data-testid={"confirm-button-modal"}
                variant={"outlined"}
                className={classes.button}
                type={"submit"}
                loading={loading}
              >
                Confirm
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Modal>
  );
};

export default MoveOutConfirmationModal;
