import {
  Box,
  Grid,
  InputLabel,
  TextField
} from "@mui/material";
import React, { ChangeEvent, ReactElement } from "react";
import {
  selectProductTypeName,
  selectPropertyType,
  setProductTypeName,
  setPropertyType
} from "src/store/reducers/productTypeSlice/productTypeSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import PMSAdvisory from "../../../../components/ui/PMSAdvisory/PMSAdvisory";
import PMSSelect from "../../../../components/ui/PMSSelect/PMSSelect";
import Paper from "@mui/material/Paper";
import StepLayout from "../../../../layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "../../../../layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import { ValidPropertyType } from "../../../../models/ValidPropertyType";
import { useNavigate } from "react-router";
import { inputError } from "../../../../utils/showInputError/showInputError";
import selectProductTypeValidation from "./selectProductTypeValidation";
import { selectSelectedFacility } from "../../../../store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { useFormik } from "formik";
import useStyles from "./SelectProductType.styles";
import validPropertyTypes from "../../../../utils/validPropertyTypes";

const SelectProductType: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const productTypeName = useAppSelector(selectProductTypeName);
  const propertyType = useAppSelector(selectPropertyType);

  const formik = useFormik({
    initialValues: {
      propertyType: propertyType,
      productTypeName: productTypeName,
      selectedFacility: selectedFacility ? selectedFacility.name : ""
    },
    validationSchema: selectProductTypeValidation,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => navigate("/product-types/add-product-type/select-category")
  });

  const { errors, touched, values } = formik;

  const updateForm = (fieldName: string, fieldValue: string | boolean): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleProductTypeName = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setProductTypeName(fieldValue));
  };

  const handlePropertyType = (fieldName: string, fieldValue: ValidPropertyType) => {
    updateForm(fieldName, fieldValue);
    dispatch(setPropertyType(fieldValue));
  };

  const stepperId = "product-categories-form";

  return (
    <>
      <PMSAdvisory
        title={"Define Your Product Type"}
        advisoryText={<>
          <p>To start, define your Product Type.</p>
          <p>This will be a new Product Type name (<b>i.e., 5x5 - Inside Storage</b>) as well as a selected Property
            Type (<b>Storage or Parking</b>) from the dropdown.</p>
          <p>You can also target which facility your product type will apply to.</p>
        </>}
      />
      <Paper elevation={1}>
        <StepLayout stepperId={stepperId} buttons={
          <StepperButtons
            data-testid={"select-product-type-buttons"}
            stepperId={stepperId}
            backAction={() => navigate("/product-types/add-product-type")}
          />
        }>
          <form
            id={stepperId}
            data-testid={stepperId}
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              formik.handleSubmit();
            }}
          >
            <Box margin={1}>
              <Grid container justifyContent={"space-between"}>
                <Grid
                  pr={1}
                  pb={1}
                  item
                  xs={12}
                >
                  <InputLabel htmlFor={"product-type-name"} className={classes.label}>
                    Product Type Name
                  </InputLabel>
                  <TextField
                    fullWidth
                    id={"product-type-name"}
                    inputProps={{ "data-testid": "product-type-name" }}
                    name={"productTypeName"}
                    value={values.productTypeName}
                    onChange={
                      (e: ChangeEvent<{ value: string }>) => handleProductTypeName("productTypeName", e.target.value)
                    }
                    error={inputError("productTypeName", touched, errors).error}
                    helperText={inputError("productTypeName", touched, errors).helperText}
                  />
                </Grid>
                <Grid
                  pr={1}
                  pb={1}
                  item
                  xs={12}
                  mt={2}
                >
                  <PMSSelect
                    id={"property-type"}
                    name={"propertyType"}
                    label={"Property Type"}
                    value={values.propertyType}
                    changeHandler={e => handlePropertyType("propertyType", e.target.value as ValidPropertyType)}
                    error={inputError("propertyType", touched, errors).error}
                    helperText={inputError("propertyType", touched, errors).helperText}
                  >
                    <option value={""} disabled>Select a property type</option>
                    <option value={"storage"}>Storage</option>
                    <option value={"parking"}>Parking</option>
                  </PMSSelect>
                </Grid>
              </Grid>
            </Box>
          </form>
        </StepLayout>
      </Paper>
    </>
  );
};

export default SelectProductType;
