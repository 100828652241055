import { Button, Grid, Modal, Paper, Typography } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import {
  resetMakeAPaymentSlice,
  selectEmailSent,
  selectTransaction,
  setMakeAPaymentPeriods
} from "../../../../store/reducers/makeAPaymentSlice/makeAPaymentSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import PaymentConfirmationTable from "./PaymentConfirmationTable/PaymentConfirmationTable";
import SendEmailForm from "../../../Merchandise/PointOfSales/MerchSummary/SendEmailReceiptForm/SendEmailReceiptForm";
import { printReceipt } from "../../../../store/thunks/moveIn/print/printReceipt";
import useStyles from "./PaymentConfirmation.styles";
import { resetCreditCardSlice } from "src/store/reducers/creditCardSlice/creditCardSlice";
import { resetPaymentSlice } from "src/store/reducers/paymentSlice/paymentSlice";

const PaymentConfirmation: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const transaction = useAppSelector(selectTransaction);
  const currentTransaction = useAppSelector(selectTransaction);
  const emailSent = useAppSelector(selectEmailSent);
  const [open, setOpen] = useState(false);

  const confirmMsg = `Your payment is confirmed. Confirmation # ${transaction?.id}`;

  const handlePrintReceipt = () => {
    dispatch(printReceipt(currentTransaction?.id)).then((resp) => {
      if (!resp.type.includes("rejected")) {
        window.open(resp.payload as string);
      }
    });
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    //do resets
    return () => {
      // strict mode in dev will bork this in the display in dev,
      //  disable these to see the ledger and make the balance correct.
      dispatch(resetMakeAPaymentSlice());
      dispatch(setMakeAPaymentPeriods(0));
      dispatch(resetCreditCardSlice());
      dispatch(resetPaymentSlice());
    };
  }, []);
  return (
    <Grid container columnSpacing={3} display={"flex"}
      direction={"row"} mt={5}>
      <Grid item xs={12}>
        <Paper variant={"outlined"}>
          <Grid container justifyContent={"space-between"} display={"flex"}
            flexDirection={"column"} p={5} rowGap={3}>
            <Grid item>
              <Grid container display={"flex"} flexDirection={"column"}
                rowGap={3}>
                <Typography variant={"h6"} className={classes.title}>
                  {confirmMsg}
                </Typography>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ligula augue, facilisis vel urna vel,
                  egestas cursus magna. Morbi laoreet augue ligula, a pulvinar diam rhoncus consectetur.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <PaymentConfirmationTable />
            </Grid>
            <Grid item>
              <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit:</Typography>
              <Grid pt={4} container spacing={2}>
                <Grid item>
                  <Button
                    data-testid={"email-button"}
                    color={"primary"}
                    variant={"outlined"}
                    className={classes.button}
                    onClick={handleOpenModal}
                    disabled={emailSent}
                  >
                    Email Receipt
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    data-testid={"print-button"}
                    color={"primary"}
                    variant={"outlined"}
                    className={classes.button}
                    onClick={handlePrintReceipt}
                  >
                    Print Receipt
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Modal onBackdropClick={handleCloseModal} open={open} className={classes.modal}>
        <Grid
          container
          className={classes.modalContainer}
          component={Paper}
          elevation={0}
        >
          <SendEmailForm closeButtonAction={handleCloseModal} />
        </Grid>
      </Modal>
    </Grid>
  );
};

export default PaymentConfirmation;
