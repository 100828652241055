/* eslint-disable max-len */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { APIFilter } from "src/models/responses/APIFilter";
import { APIListResponse } from "../../../models/responses/APIListResponse";
import { RootState } from "../../rootStore";
import { Transaction } from "src/models/Transaction";
import { TransactionSliceState } from "./TransactionSliceState";
import { getTransactions } from "src/store/thunks/transactions/getTransaction/getTransactions";
import { partialPaymentDeliquentOccupants } from "src/store/thunks/delinquentOccupants/partial-payment/partialPaymentDelinquentOccupants";
import { processRefundMerchandise } from "src/store/thunks/merchandise/processRefundMerchandise/processRefundMerchandise";
import { purchaseMerchandise } from "src/store/thunks/merchandise/purchaseMerchandise/purchaseMerchandise";
import { searchTransaction } from "src/store/thunks/transactions/searchTransaction/searchTransaction";
import { searchUnitTransactions } from "src/store/thunks/transactions/searchUnitTransactions/searchUnitTransactions";
import { partialPaymentMultipleLedgers } from "../../thunks/makeAPayment/partialPayment/partialPaymentMultipleLedgers";
import { getOccupantTransaction } from "src/store/thunks/transactions/getOccupantTransaction/getOccupantTransactions";

export const initialState: TransactionSliceState = {
  transactions: [],
  isLoading: false,
  currentTransaction: null,
  transactionForRefund: null,
  transactionsSearchValue: "",
  transactionsFilter: ""
};

export const occupantSlice = createSlice({
  name: "occupant",
  initialState,
  reducers: {
    resetTransactionSlice: (state) => {
      Object.assign(state, initialState);
    },
    setTransactionsSearchValue: (state, action:PayloadAction<string>) => {
      state.transactionsSearchValue = action.payload as string;
    },
    setTransactionsFilter: (state, action: PayloadAction<APIFilter | "">) => {
      state.transactionsFilter = action.payload;
      sessionStorage.setItem("transactionsFilter", JSON.stringify(action.payload));
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransactions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTransactions.fulfilled, (state, action: PayloadAction<APIListResponse<Transaction[]>>) => {
        state.isLoading = false;
        state.transactions = action.payload.data;
      })
      .addCase(getTransactions.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(purchaseMerchandise.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(purchaseMerchandise.fulfilled, (state, action: PayloadAction<Transaction>) => {
        state.isLoading = false;
        state.currentTransaction = action.payload;
      })
      .addCase(partialPaymentDeliquentOccupants.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentTransaction = action.payload.transaction;
      })
      .addCase(purchaseMerchandise.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(searchTransaction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchTransaction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.transactions = action.payload.data;
      })
      .addCase(searchTransaction.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(partialPaymentMultipleLedgers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentTransaction = action.payload.transaction;
      })
      .addCase(processRefundMerchandise.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentTransaction = action.payload.transaction;
      })
      .addCase(getOccupantTransaction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOccupantTransaction.fulfilled, (state, action) => {
        state.transactionForRefund = action.payload;
        state.isLoading = false;
      })
      .addCase(getOccupantTransaction.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(searchUnitTransactions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchUnitTransactions.fulfilled, (state, action: PayloadAction<APIListResponse<Transaction[]>>) => {
        state.isLoading = false;
        state.transactions = action.payload.data;
      })
      .addCase(searchUnitTransactions.rejected, (state, action) => {
        state.isLoading = false;
      });
  }
});

export const selectTransactions = (state: RootState) => state.transactions.transactions;
export const selectTransactionsLoading = (state: RootState) => state.transactions.isLoading;
export const selectCurrentTransaction = (state: RootState) => state.transactions.currentTransaction;
export const selectTransactionForRefund = (state: RootState) => state.transactions.transactionForRefund;
export const selectTransactionsSearchValue = (state: RootState) => state.transactions.transactionsSearchValue;
export const selectTransactionsFilter = (state: RootState) => state.transactions.transactionsFilter;

export const {
  resetTransactionSlice,
  setTransactionsSearchValue,
  setTransactionsFilter
} = occupantSlice.actions;

export const TRANSACTION_PAYMENT_METHOD = [
  "", "Cash", "Money Order", "Credit Card", "ACH/Check"
];

export default occupantSlice.reducer;
