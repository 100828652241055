import {
  AppBar,
  Avatar,
  Divider,
  Grid,
  List,
  Typography
} from "@mui/material";
import {
  selectUserName,
  selectUserTitle
} from "src/store/reducers/userInfoSlice/userInfoSlice";
import { NavLink } from "../../../models/NavLink";
import NavigationLink from "./NavigationLink/NavigationLink";
import React from "react";
import Toolbar from "@mui/material/Toolbar";
import {
  selectShowDrawer
} from "src/store/reducers/navigationSlice/navigationSlice";
import { useAdminLinks } from "src/hooks/useAdminLinks";
import { useAppSelector } from "src/store/hooks";
import { useStandardLinks } from "src/hooks/useStandardLinks";
import useStyles from "./NavDrawer.styles";

const NavDrawer: React.FC = () => {
  const showDrawer = useAppSelector(selectShowDrawer);
  const userName = useAppSelector(selectUserName);
  const userTitle = useAppSelector(selectUserTitle);
  const { classes } = useStyles({ showDrawer: showDrawer });
  const dynamicAdminLinks = useAdminLinks();
  const dynamicStandardLinks = useStandardLinks();

  const headerText = showDrawer
    ? (
      <Grid
        item
        container
        direction={"column"}
        className={classes.headerText}
        data-testid={"header-text"}
      >
        <Typography variant={"h6"} component={"span"}>{userName}</Typography>
        <Typography variant={"body2"} component={"span"}>{userTitle}</Typography>
      </Grid>
      )
    : null;

  return (
    <nav className={classes.root} data-testid={"navigation-drawer"}>
      <AppBar
        position={"relative"}
        elevation={0}
        className={classes.appBar}
        component={"div"}
      >
        <Toolbar>
          <Avatar className={classes.userAvatar}>SS</Avatar>
          <Typography noWrap component={"span"}>{headerText}</Typography>
        </Toolbar>
      </AppBar>
      <List dense component={"nav"} aria-label={"main navigation drawer"}>
        {dynamicStandardLinks.map((link: NavLink | null, i: number) => link
          ? <NavigationLink key={`${link.name}-${i}`} link={link} openInNewTab={link.openInNewTab}/>
          : null)}
        {dynamicAdminLinks.length ? <Divider className={classes.divider} /> : null}
        {dynamicAdminLinks.map((link: NavLink | null, i: number) => link
          ? <NavigationLink key={`${link.name}-${i}`} link={link} />
          : null
        )}
      </List>
    </nav>
  );
};

export default NavDrawer;
