import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "FeesList" })((theme) => ({
  headerCell: {
    color: theme.palette.common.black,
    background: theme.palette.background.default,
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1)
  },
  feesListWrapper: {
    width: "100%"
  }
}));

export default useStyles;
