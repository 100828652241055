import moment from "moment";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  ccNumber: Yup.string().required("Card number is required").min(17, "Card number must be at least 15 digits"),
  ccExpiration: Yup.string().test({
    name: "ccExpiration",
    message: "Please enter a valid expiration date",
    test: function(value) {
      const today = moment();
      const formattedDate = moment(value, "MMYY");

      if (!value) {
        return this.createError({ message: "Card expiration date is required" });
      }

      if (!formattedDate.isValid()) {
        return this.createError({ message: "Card expiration date is invalid" });
      }

      if (formattedDate.isBefore(today)) {
        return this.createError(
          {
            message: "Card expiration date is in the past. Please enter a valid expiration date"
          }
        );
      }

      return true;
    }
  }),
  // ccName needs validation for first name / last name
  ccName: Yup.string().required("Cardholder name is required").min(3, "Cardholder name must be at least 3 letters")
    .matches(/^(\w+)\s(\w+)/, "Cardholder name should be in the format of \"First Last\""),
  ccCVV: Yup.string().required("Card CVV is required").min(4, "CVV must be at least 4 digits")
});

export default validationSchema;
