interface UnitAttributeParsed{ sort_order: number, value: boolean }

export interface CreateNewUnitFormValues{
    unit_number: string
    length: string
    width: string
    height: string
    price: string
    floor: string
    walk_through_order?: number
    product_type: number
    interior: UnitAttributeParsed
    drive_up: UnitAttributeParsed
    climate: UnitAttributeParsed
    air: UnitAttributeParsed
    heated: UnitAttributeParsed
    illuminated: UnitAttributeParsed
    electric: UnitAttributeParsed
    alarm: UnitAttributeParsed
    elevator: UnitAttributeParsed
    stairs: UnitAttributeParsed
    ada: UnitAttributeParsed
    rentable: boolean
    unrentable_reason: number
    unrentable_note: "",
}

const initialValues: CreateNewUnitFormValues = {
  unit_number: "",
  length: "",
  width: "",
  height: "",
  price: "",
  floor: "",
  walk_through_order: undefined,
  product_type: 0,
  interior: { sort_order: 1, value: false },
  drive_up: { sort_order: 2, value: false },
  climate: { sort_order: 3, value: false },
  air: { sort_order: 4, value: false },
  heated: { sort_order: 5, value: false },
  illuminated: { sort_order: 6, value: false },
  electric: { sort_order: 7, value: false },
  alarm: { sort_order: 8, value: false },
  elevator: { sort_order: 9, value: false },
  stairs: { sort_order: 10, value: false },
  ada: { sort_order: 11, value: false },
  rentable: true,
  unrentable_reason: 0,
  unrentable_note: ""
};

export default initialValues;
