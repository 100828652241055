import React, { ReactElement, useEffect } from "react";
import {
  selectIsTransfer,
  setTransferPeriods,
  setTransferPromoDefermentStatus
}
  from "src/store/reducers/transferUnitSlice/transferUnitSlice";
import { selectSelectedPromotion, setSelectedPromotion }
  from "../../../store/reducers/selectedPromotionSlice/selectedPromotionSlice";
import { setPeriods, setPromoDefermentStatus } from "../../../store/reducers/moveInSlice/moveInSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import EmptyTable from "../../ui/PMSTable/EmptyTable/EmptyTable";
import { GridRowParams } from "@mui/x-data-grid";
import PMSTable from "../../ui/PMSTable/PMSTable";
import SelectPromotionTableColumns from "./SelectPromotionTableColumns/SelectPromotionTableColumns";
import SelectedItemDisplay from "../../ui/SelectedItemDisplay/SelectedItemDisplay";
import { getAllPromotionsForFacility }
  from "../../../store/thunks/promotion/getAllForFacility/getAllPromotionsForFacility";
import { selectPromotionsForFacility } from "../../../store/reducers/promotionsSlice/promotionsSlice";
import { selectSelectedUnit } from "src/store/reducers/selectedUnitSlice/selectedUnitSlice";

interface SelectPromotionProps {
  chipLocation?: "top" | "bottom"
}

const SelectPromotion: React.FC<SelectPromotionProps> = ({ chipLocation = "bottom" }): ReactElement => {
  const dispatch = useAppDispatch();
  const promotions = useAppSelector(selectPromotionsForFacility);
  const selectedPromotion = useAppSelector(selectSelectedPromotion);
  const isTransfer = useAppSelector(selectIsTransfer);
  const selectedUnit = useAppSelector(selectSelectedUnit);

  const handlePaginationUpdate = () => {
    dispatch(getAllPromotionsForFacility(selectedUnit?.id));
  };

  const handlePromotionSelected = (rowData: GridRowParams) => {
    dispatch(setSelectedPromotion(rowData.row));
    if (selectedPromotion?.id !== rowData.row.id) {
      if (isTransfer) {
        dispatch(setTransferPeriods(0));
        dispatch(setTransferPromoDefermentStatus(false));
        return;
      }
      dispatch(setPeriods(0));
      dispatch(setPromoDefermentStatus(false));
    }
  };

  const handlePromotionRemoved = () => {
    dispatch(setSelectedPromotion(null));
  };

  const emptyTable = () => (
    <EmptyTable
      collectionNotFound={"promotions"}
      value={"promotion"}
      addLink={"/promotions/create"}
    />
  );

  useEffect(() => {
    handlePaginationUpdate();
  }, []);

  return (
    <>
      {selectedPromotion && chipLocation === "top" &&
      <SelectedItemDisplay
        data-testid={"selected-promotion-display"}
        selectedItem={"Promotion"}
        displayName={selectedPromotion.name}
        removeAction={handlePromotionRemoved}
      />
      }
      <PMSTable
        identifier={"promotion"}
        data-testid={"select-promotion-table"}
        columns={SelectPromotionTableColumns}
        rows={promotions}
        emptyTableDisplay={emptyTable}
        onPaginationUpdate={handlePaginationUpdate}
        onRowClick={handlePromotionSelected}
      />
      {selectedPromotion && chipLocation === "bottom" &&
        <SelectedItemDisplay
          data-testid={"selected-promotion-display"}
          selectedItem={"Promotion"}
          displayName={selectedPromotion.name}
          removeAction={handlePromotionRemoved}
        />
      }
    </>
  );
};

export default SelectPromotion;
