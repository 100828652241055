import { Facility } from "src/models/Facility";
import ReportParamsSliceState from "./reportParamsSliceState";
import { RootState } from "../../rootStore";
import { createSlice } from "@reduxjs/toolkit";
import reports from "src/pages/Reporting/reports";

export const initialState: ReportParamsSliceState = {
  currentReport: "",
  facilities: [],
  facility: [],
  startDate: "",
  endDate: "",
  date: "",
  hours: "",
  paramsBuilderModalOpen: false,
  paramsBuilderCondirmationModalOpen: false,
  disableApplyParameters: false
};

export const reportParamsSlice = createSlice({
  name: "reportParams",
  initialState,
  reducers: {
    setCurrentReport: (state, action) => {
      state.currentReport = action.payload;
    },
    setReportFacilities: (state, action) => {
      state.facilities = action.payload;
    },
    setReportFacility: (state, action) => {
      state.facility = action.payload;
    },
    setReportStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setReportDate: (state, action) => {
      state.date = action.payload;
    },
    setReportEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setReportHours: (state, action) => {
      state.hours = action.payload;
    },
    setDisableApplyParametersButton: (state, action) => {
      state.disableApplyParameters = action.payload;
    },
    setParamsBuilderModalOpen: (state, action) => {
      state.paramsBuilderModalOpen = action.payload;
    },
    setParamsBuilderConfirmationModalOpen: (state, action) => {
      state.paramsBuilderCondirmationModalOpen = action.payload;
    }
  }
});

export const {
  setCurrentReport,
  setParamsBuilderModalOpen,
  setReportFacilities,
  setReportFacility,
  setReportStartDate,
  setReportDate,
  setReportHours,
  setDisableApplyParametersButton,
  setReportEndDate,
  setParamsBuilderConfirmationModalOpen
} = reportParamsSlice.actions;

export const selectCurrentReport = (state: RootState) => state.reportParams.currentReport;
export const selectReportFacilities = (state: RootState) => state.reportParams.facilities;
export const selectReportFacility = (state: RootState) => state.reportParams.facility;
export const selectReportApplyParametersDisabled = (state: RootState) => state.reportParams.disableApplyParameters;
export const selectReportFullFacilities = (state: RootState) => {
  if (state.reportParams.facilities.includes(1)) {
    return [{ id: 1, name: "All Facilities" }] as Facility[];
  }

  return state.facilities.facilities.filter((facility) =>
    state.reportParams.facilities.includes(facility.id)
  );
};
export const selectReportFullFacility = (state: RootState) =>
  state.facilities.facilities.filter((facility) => {
    return +state.reportParams.facility[0] === facility.id;
  });

export const selectReportStartDate = (state: RootState) => state.reportParams.startDate;
export const selectReportEndDate = (state: RootState) => state.reportParams.endDate;
export const selectReportHours = (state: RootState) => state.reportParams.hours;
export const selectReportDate = (state: RootState) => state.reportParams.date;
export const selectParamsBuilderModalOpen = (state: RootState) => state.reportParams.paramsBuilderModalOpen;
export const selectParamsBuilderCondirmationModalOpen = (state: RootState) =>
  state.reportParams.paramsBuilderCondirmationModalOpen;
export const selectAccountingReportRows =
  (state: RootState) => reports.filter((report) => report.category === "Accounting");
export const selectExecutiveReportRows =
  (state: RootState) => reports.filter((report) => report.category === "Executive");
export const selectOperationsReportRows = (state: RootState) => reports.filter((report) =>
  report.category === "Operations");

export default reportParamsSlice.reducer;
