import { Box, Grid, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import AppBreadcrumbs from "../../navigation/AppBreadcrumbs/AppBreadcrumbs";
import { Breadcrumb } from "../../../models/Breadcrumb";
import moment from "moment";
import useStyles from "./PageHeader.styles";

interface PageHeaderProps {
  breadcrumbs?: Breadcrumb[]
  title: string
  subtitle?: string
}

const PageHeader: React.FC<PageHeaderProps> = ({
  breadcrumbs,
  title,
  subtitle
}: PageHeaderProps): ReactElement => {
  const { classes } = useStyles();

  let subheadingDisplay;

  if (subtitle) {
    subheadingDisplay =
      <Typography variant={"h6"} component={"h2"} className={classes.subheading}>{subtitle}</Typography>;
  }

  if (breadcrumbs) {
    subheadingDisplay = <AppBreadcrumbs breadcrumbs={breadcrumbs} />;
  }

  const subheading = <Box mt={1} data-testid={"subtitle-display"}>{subheadingDisplay}</Box>;

  const date = moment().format("MMMM Do");

  return (
    <Box mt={4}>
      <Grid container direction={"column"}>
        <Grid
          item
          container
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Typography
            variant={"h4"}
            component={"h1"}
            className={classes.header}
          >
            {title}
          </Typography>
          <Typography
            variant={"h6"}
            component={"h2"}
            className={classes.date}
          >
            Today&apos;s date: {date}
          </Typography>
        </Grid>
        <Box mb={4}>
          {subheading}
        </Box>
      </Grid>
    </Box>
  );
};

export default PageHeader;
