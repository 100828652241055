import React, { FC } from "react";
import { Chip } from "@mui/material";

interface StatusChipProps{
    status: 0 | 1
  }

const StatusChip:FC<StatusChipProps> = ({ status }) => {
  const pendingHex = "#ff980080";
  const completedHex = "#1976d280";

  return (
    <Chip
      label={status === 1 ? "Completed" : "Pending" }
      style={{
        width: "6rem",
        background: status === 1 ? completedHex : pendingHex
      }}
      size={"small"}
      />
  );
};

export default StatusChip;
