import React from "react";
import { Route } from "react-router";

import CreateNewDeal from "../pages/Deals/CreateNewDeal/CreateNewDeal";
import EditDeal from "src/pages/Deals/EditDeal/EditDeal";
import ManageDeals from "../pages/Deals/ManageDeals/ManageDeals";

export const groupName = "deals";

export const DealsRoutes = (
  <>
    <Route path={`${groupName}/create-new-deal`} element={<CreateNewDeal />} />
    <Route path={`${groupName}/:id/edit`} element={<EditDeal />} />
    <Route path={`${groupName}/manage-deals`} element={<ManageDeals />} />

  </>
);

export default DealsRoutes;
