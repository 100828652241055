import { FormHelperText, Grid, Paper, Typography } from "@mui/material";
import React, { ReactElement, useEffect } from "react";
import {
  selectIsMoveOutFromManageOccupants,
  selectMoveOutLedger,
  setMoveOutLedger
} from "../../../../store/reducers/moveOutSlice/moveOutSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { DataGrid } from "@mui/x-data-grid";
import EmptyTable from "../../../../components/ui/PMSTable/EmptyTable/EmptyTable";
import { Ledger } from "../../../../models/Ledger";
import MoveOutUnitTableColumns from "./MoveOutUnitTableColumns/MoveOutUnitTableColumns";
import PMSAdvisory from "../../../../components/ui/PMSAdvisory/PMSAdvisory";
import SelectedItemDisplay from "../../../../components/ui/SelectedItemDisplay/SelectedItemDisplay";
import StepLayout from "../../../../layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "../../../../layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import { getActiveLedgers } from "src/utils/getActiveLedgers/getActiveLedgers";
import { selectSelectedOccupant } from "../../../../store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { setTargetPath } from "src/store/reducers/navigationSlice/navigationSlice";
import { useNavigate } from "react-router";
import useStyles from "./SelectUnitForMoveOut.styles";

const SelectUnitForMoveOut: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const occupant = useAppSelector(selectSelectedOccupant)!;
  const moveOutLedger = useAppSelector(selectMoveOutLedger);
  const [facilityNotSelectedMessage, setFacilityNotSelectedMessage] = React.useState<string>("");
  const isMoveOutFromManageOccupants = useAppSelector(selectIsMoveOutFromManageOccupants);

  const advisoryText = <p>Choose which unit you&apos;d like to move the occupant out of.</p>;

  const emptyTable = () => (
    <EmptyTable collectionNotFound={"units"} value={"unit"} addLink={"/deals/move-out/select-occupant"} />
  );

  const selectLedger = (ledger: Ledger) => {
    dispatch(setMoveOutLedger(ledger));
    setFacilityNotSelectedMessage("");
  };

  useEffect(() => {
    if (!occupant?.ledgers) {
      navigate("/");
    }
  }, []);

  const moveForwardHandler = () => {
    if (!moveOutLedger) {
      return setFacilityNotSelectedMessage("You must select a rental to continue");
    }

    dispatch(setTargetPath("/occupants/create-move-out/process-move-out"));
    navigate("/occupants/create-move-out/process-move-out");
  };

  return (
    <>
      <PMSAdvisory title={"Select a Unit"} advisoryText={advisoryText} />
      <Paper elevation={1}>
        <StepLayout
          buttons={
            <StepperButtons
              data-testid={"select-move-out-occupant-buttons"}
              stepperId={"select-occupant"}
              backAction={() =>
                isMoveOutFromManageOccupants
                  ? navigate("/occupants/manage-occupants")
                  : navigate("/occupants/create-move-out/select-occupant")
              }
              forwardAction={moveForwardHandler}
              forwardActionText={"Next"}
            />
          }
        >
          <Typography variant={"h6"} component={"span"}>
            Current Rentals
          </Typography>
          <Grid component={Typography} mt={1}>
            We have found these current rentals for this occupant. Select one of the units below to continue with the
            Move-Out.
          </Grid>
          <DataGrid
            data-testid={"select-move-out-unit-table"}
            className={classes.root}
            columns={MoveOutUnitTableColumns()}
            rows={getActiveLedgers(occupant?.ledgers!)}
            autoHeight
            disableSelectionOnClick
            hideFooterPagination
            onRowClick={(params) => selectLedger(params.row as Ledger)}
            components={{
              NoResultsOverlay: emptyTable,
              NoRowsOverlay: emptyTable,
              Footer: undefined
            }}
          />
          {facilityNotSelectedMessage && <FormHelperText error>{facilityNotSelectedMessage}</FormHelperText>}
          {moveOutLedger && (
            <SelectedItemDisplay
              data-testid={"selected-move-out-unit-display"}
              selectedItem={"unit"}
              displayName={`Unit ${moveOutLedger?.unit?.unit_number}`}
              removeAction={() => dispatch(setMoveOutLedger(null))}
            />
          )}
        </StepLayout>
      </Paper>
    </>
  );
};

export default SelectUnitForMoveOut;
