import { Grid, GridProps, Typography } from "@mui/material";
import React, { FC, useEffect } from "react";
import {
  selectAccountingReportRows,
  selectExecutiveReportRows,
  selectOperationsReportRows,
  setCurrentReport,
  setReportDate,
  setReportEndDate,
  setReportFacilities,
  setReportFacility,
  setReportHours,
  setReportStartDate
} from "src/store/reducers/reportParamsSlice/reportParamsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Breadcrumb } from "src/models/Breadcrumb";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import ParamsBuilderConfirmationModal from
  "./components/ParamsBuilderModalConfirmationModal/ParamsBuilderConfirmationModal";
import ParamsBuilderModal from "./components/ParamsBuilderModal/ParamsBuilderModal";
import ReportsPaper from "./components/ReportsPaper/ReportsPaper";
import ReportsTable from "./components/ReportsTable/ReportsTable";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";

interface ViewReportingProps { }

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Reporting",
    bold: true
  }
];

const pageHeader = <PageHeader title={"Reporting"} breadcrumbs={breadcrumbs} />;

const ViewReporting: FC<ViewReportingProps> = () => {
  const accountingRows = useAppSelector(selectAccountingReportRows);
  const operationsRows = useAppSelector(selectOperationsReportRows);
  const executiveRows = useAppSelector(selectExecutiveReportRows);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCurrentReport(""));
    dispatch(setReportStartDate(""));
    dispatch(setReportDate(""));
    dispatch(setReportEndDate(""));
    dispatch(setReportHours(""));
    dispatch(setReportFacilities([]));
    dispatch(setReportFacility([]));
  }, []);
  const gridResponsiveProps:Pick<GridProps, "xs"| "lg"| "xl"> = {
    xs: 12,
    lg: 6,
    xl: 4
  };

  return (
    <ViewWrapper pageHeader={pageHeader}>
      <Grid pt={2} container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={"h4"}>
            All Reports
          </Typography>
        </Grid>
        <Grid item
          {...gridResponsiveProps}
        >
          <ReportsPaper category={"Executive"} >
            <ReportsTable rows={executiveRows} />
          </ReportsPaper>
        </Grid>
        <Grid
          item
          {...gridResponsiveProps}
          >
          <ReportsPaper category={"Accounting"} >
            <ReportsTable rows={accountingRows} />
          </ReportsPaper>
        </Grid>
        <Grid
          item
          {...gridResponsiveProps}
       >
          <ReportsPaper category={"Operations"}>
            <ReportsTable rows={operationsRows} />
          </ReportsPaper>
        </Grid>
      </Grid>
      <ParamsBuilderModal />
      <ParamsBuilderConfirmationModal />
    </ViewWrapper>
  );
};

export default ViewReporting;
