import CreateProductTypeNewPayload from "./CreateProductTypeNewPayload";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const createProductTypeNew = createAsyncThunk("productType/createProductTypeNew",
  (payload:CreateProductTypeNewPayload, { dispatch, getState, rejectWithValue }) => {
    const { facility_id } = payload;

    return axios.post(`/api/v1/facilities/${facility_id}/product-types`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "New Product Type Created",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an error creating the Product Type";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
