import { Edit, RemoveCircle } from "@mui/icons-material";
import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";
import { InsurancePlan } from "src/models/InsurancePlan";
import { useNavigate } from "react-router";
import useStyles from "./InsurancePlanCard.styles";
import { useAppSelector } from "src/store/hooks";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

interface InsurancePlanCardProps {
  item: InsurancePlan
  onEnablePlan: Function
  onDisablePlan: Function
}

const InsurancePlanCard: React.FC<InsurancePlanCardProps> = ({ item, onDisablePlan, onEnablePlan }): ReactElement => {
  const { classes } = useStyles({ active: item.is_active });
  const navigate = useNavigate();
  const permissions = useAppSelector(selectFacilityPermissions);
  const insurancePlansPermissions = createPermissions(permissions, "insurance_plans");
  const ActionButtons = () => {
    if (item.is_active) {
      return (
        <Grid container>
          <Grid item xs={6} justifySelf={"end"}>
            <Tooltip title={"View/Edit Insurance Plan"}>
              <IconButton
                data-testid={"insurance-plan-management-edit"}
                onClick={() => navigate(`/insurance-plans/${item.id}/edit`)}
                color={"primary"}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Disable Insurance Plan"}>
              <IconButton
                data-testid={"insurance-plan-management-disable"}
                onClick={() => onDisablePlan()}
                color={"primary"}
              >
                <RemoveCircle />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      );
    }
    return (
      <Button
        data-testid={"item-management-cancel"}
        color={"primary"}
        variant={"text"}
        fullWidth
        onClick={() => onEnablePlan({ ...item, id: +item.id })}
      >
        Re-Enable
      </Button>
    );
  };

  return (
    <Grid
      container
      className={classes.cardContent}
      p={4}
      data-testid={"insurance-plan"}
    >
      <Grid item xs={8}>
        <Grid spacing={3} container direction={"column"}>
          <Grid width={"100%"} item xs={12}>
            <Tooltip title={item.name}>
              <Typography variant={"h6"} noWrap className={classes.boldTitle}>
                {item.name}
              </Typography>
            </Tooltip>
            <Tooltip title={item.line_item_description}>
              <Typography noWrap>
                {item.line_item_description}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Premium Amount: ${item.premium_amount}
            </Typography>
            <Typography >
              Coverage Amount: ${item.coverage_amount}
            </Typography>
          </Grid>
          <Grid width={"100%"} item xs={12}>
            <Typography >
              Revenue Class:
            </Typography>
            <Typography noWrap>
              {item.revenue_class?.name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        className={classes.itemStatus}
        pl={4}
        xs={4}
      >
        <Typography className={classes.statusText}>{item.is_active ? "Active" : "Disabled"}</Typography>
        {insurancePlansPermissions.update && <ActionButtons />}
      </Grid>
    </Grid>
  );
};

export default InsurancePlanCard;
