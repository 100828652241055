import api from "../api";

interface UniqueProductTypesPayload {
    id: number
    facility_id: number
}

export interface CommonProductTypesPayload {
    temp_id: number,
    name: string
    unique: UniqueProductTypesPayload[]
}

type CommonProductTypesRequest = (number | "all")[];

export const productTypesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCommonProductTypes: builder.query<CommonProductTypesPayload[], CommonProductTypesRequest>({
      query: (ids) => ({
        url: `api/v1/product-types/common?ids=${ids.join(",")}`
      }),
      providesTags: ["CommonProductTypes"]
    })
  })
});

export const { useGetCommonProductTypesQuery } = productTypesApi;
