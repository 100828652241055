import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { APIListResponse } from "../../../models/responses/APIListResponse";
import { BaseDocument } from "../../../models/Document";
import { ESignTemplatesSliceState } from "./ESignTemplatesSliceState";
import { RootState } from "../../rootStore";
import { getAllEsignTemplates } from "../../thunks/esignTemplates/getAll/getAllEsignTemplates";

export const initialState: ESignTemplatesSliceState = {
  eSignTemplates: [],
  eSignTemplatesLoading: false,
  eSignTemplatesError: ""
};

export const eSignTemplatesSlice = createSlice({
  name: "eSignTemplates",
  initialState,
  reducers: {
    resetDocumentsSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllEsignTemplates.pending, (state) => {
        state.eSignTemplatesLoading = true;
      })
      .addCase(getAllEsignTemplates.fulfilled, (state, action: PayloadAction<APIListResponse<BaseDocument[]>>) => {
        state.eSignTemplates = action.payload.data;
        state.eSignTemplatesLoading = false;
      });
  }
});

export const selectESignTemplates = (state: RootState) => state.eSignTemplates.eSignTemplates;
export const selectESignTemplatesLoading = (state: RootState) => state.eSignTemplates.eSignTemplatesLoading;

export const {
  resetDocumentsSlice
} = eSignTemplatesSlice.actions;

export default eSignTemplatesSlice.reducer;
