import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const downloadEsignDocument = createAsyncThunk(
  "downloadEsignDocument",
  (documentId: number, { dispatch, getState, rejectWithValue }) => {
    return axios
      .get(`/api/v1/esign-documents/${documentId}/download`, {
        responseType: "blob",
        headers: {
          Accept: "application/pdf"
        }
      })
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = "There was an error fetching documents";

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  }
);
