import { Grid, Paper } from "@mui/material";
import React, { ReactElement, useState } from "react";
import {
  selectAch,
  selectEnableAutoPay as selectEnableAutoPayACH
} from "src/store/reducers/achSlice/achSlice";
import {
  selectCreditCard,
  selectEnableAutoPay as selectEnableAutoPayCC
} from "src/store/reducers/creditCardSlice/creditCardSlice";
import {
  selectIsTransfer,
  selectMoveInLoading,
  setMoveInStoringReason
} from "../../../../store/reducers/moveInSlice/moveInSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import DealDetails from "../../../../layouts/stepper/ReviewLayout/DealDetails/DealDetails";
import MoveInConfirmationModal from "./ConfirmationModal/MoveInConfirmationModal";
import PaymentEntry from "./PaymentEntry/PaymentEntry";
import PaymentMethod from "./PaymentMethod/PaymentMethod";
import { PaymentMethod as PaymentMethodEnum } from "src/enums/PaymentMethod";
import ReviewActionButtons from "../../../../layouts/stepper/ReviewLayout/ReviewActionButtons/ReviewActionButtons";
import ReviewHeader from "../../../../layouts/stepper/ReviewLayout/ReviewHeader/ReviewHeader";
import ReviewLayout from "../../../../layouts/stepper/ReviewLayout/ReviewLayout";
import { createMoveIn } from "../../../../store/thunks/moveIn/create/createMoveIn";
import { createTransferUnit } from "src/store/thunks/transfer/create/createTransferUnit";
import { resetExternalTppPlanState } from "src/store/reducers/externalTppPlanSlice/externalTppPlanSlice";
import { savePaymentInstrument } from "src/store/thunks/paymentInstrument/savePaymentInstrument";
import {
  selectGlobalSettingInformationLoading
} from "src/store/reducers/globalSettingInformationSlice/globalSettingInformationSlice";
import { selectPaymentType } from "src/store/reducers/paymentSlice/paymentSlice";
import { selectSelectedOccupant } from "src/store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { selectTargetPath } from "../../../../store/reducers/navigationSlice/navigationSlice";
import { useNavigate } from "react-router";

const ReviewMoveIn: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector(selectMoveInLoading);
  const redirect = useAppSelector(selectTargetPath);
  const isTransfer = useAppSelector(selectIsTransfer);
  const creditCard = useAppSelector(selectCreditCard);
  const selectedOccupant = useAppSelector(selectSelectedOccupant);
  const ach = useAppSelector(selectAch);
  const paymentType = useAppSelector(selectPaymentType);
  const formId = "move-in-payment-form";
  const [moveInModalOpen, setMoveInModalOpen] = useState(false);
  const ccAutobill = useAppSelector(selectEnableAutoPayCC);
  const achAutobill = useAppSelector(selectEnableAutoPayACH);
  const globalSettingsLoading = useAppSelector(selectGlobalSettingInformationLoading);

  const handleMoveIn = () => {
    setMoveInModalOpen(true);
  };

  const handleGoBack = () => {
    navigate("/occupants/create-move-in/process-move-in");
  };

  const handleCancel = () => {
    if (redirect && redirect.includes("manage-deals")) {
      return navigate(redirect);
    }

    navigate("/occupants/create-move-in");
  };

  const handleModalSubmit = async(storingReason: string) => {
    await dispatch(setMoveInStoringReason(storingReason));

    await dispatch(isTransfer ? createTransferUnit() : createMoveIn()).then((resp) => {
      if (!resp.type.includes("rejected")) {
        dispatch(resetExternalTppPlanState());

        // if save payment is checked but not autobill. We need to save the payment instrument
        if (paymentType === PaymentMethodEnum.creditCard) {
          if (creditCard.isPreferredPayment && !ccAutobill) {
            dispatch(
              savePaymentInstrument({
                occupantId: selectedOccupant?.id!,
                creditCard: {
                  cardHolderName: creditCard.name,
                  cardNumber: creditCard.number.replaceAll(" ", ""),
                  expirationMonth: creditCard.expiration.slice(0, 2),
                  expirationYear: "20" + creditCard.expiration.slice(2, 4),
                  cardSecurityCode: creditCard.cvv,
                  cardProcessor: creditCard.type
                }
              })
            );
          }
        }

        if (paymentType === PaymentMethodEnum.ach) {
          if (ach.isPreferredPayment && !achAutobill) {
            dispatch(
              savePaymentInstrument({
                occupantId: selectedOccupant?.id!,
                ach: {
                  accountHolderName: ach.accountName,
                  accountNumber: ach.accountNumber,
                  routingNumber: ach.routingNumber,
                  accountType: ach.accountType!,
                  bankName: ach.bankName
                }
              })
            );
          }
        }

        navigate("/occupants/create-move-in/confirm-move-in");
      }
    });

    setMoveInModalOpen(false);
  };

  const getCtaText = () => {
    if (isTransfer) {
      return "Transfer & Move-In";
    }
    return "Move-In";
  };

  const getActionButton = () => {
    const isLoading = loading || globalSettingsLoading;
    return (
      <ReviewActionButtons ctaText={getCtaText()} loading={isLoading} formId={formId}
        cancelMethod={handleCancel} />
    );
  };

  return (
    <ReviewLayout
      header={<ReviewHeader headerText={"Review Move-In"} backMethod={handleGoBack} goBackText={"Process Move-In"} />}
      actionButtons={getActionButton()}
    >
      <Paper elevation={1}>
        <DealDetails />
        <Grid container spacing={1} item
          justifyContent={"space-between"} p={4} xs={12}
          lg={"auto"}>
          <Grid item xs={12} lg={3}>
            <PaymentMethod label={"Payment Method"} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <PaymentEntry formId={formId} handleSubmit={handleMoveIn} />
          </Grid>
        </Grid>
      </Paper>
      <MoveInConfirmationModal
        open={moveInModalOpen}
        onModalClose={() => setMoveInModalOpen(false)}
        onModalSubmit={handleModalSubmit}
        loading={loading}
      />
    </ReviewLayout>
  );
};

export default ReviewMoveIn;
