
import { Alert, Button, Grid, Modal, Paper, Typography } from "@mui/material";
import {
  FlatUnit,
  GetFlatUnitsResponse,
  UpdateFlatUnitsRequest,
  UpdateFlatUnitsResponse
} from "src/api/endpoints/units";
import React, { Dispatch, FC, SetStateAction } from "react";
import { FlatUnitChanges } from "../../../ManageUnits";
import { GridRowId } from "@mui/x-data-grid";
import getUnitDoorType from "src/utils/getUnitDoorType/getUnitDoorType";
import getUnitUnrentableReason from "src/utils/getUnitUnrentableReason/getUnitUnrentableReason";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { useAppDispatch } from "src/store/hooks";
import useStyles from "./ConfirmationModal.styles";

type ConfirmationModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  editedRows: FlatUnitChanges;
  updateUnits: ({ payload }: UpdateFlatUnitsRequest) => Promise<UpdateFlatUnitsResponse>;
  selectedRows: GridRowId[] | GridRowId;
  data: GetFlatUnitsResponse;
  setSelectedRows: Dispatch<SetStateAction<GridRowId[]>>;
  setEditedRows: Dispatch<SetStateAction<FlatUnitChanges>>;
  updateLoading: boolean;
};

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  open,
  setOpen,
  editedRows,
  updateUnits,
  selectedRows,
  data,
  setSelectedRows,
  setEditedRows,
  updateLoading
}) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const submitEditedUnits = async() => {
    const currentSelection: GridRowId[] = Array.isArray(selectedRows) ? selectedRows : [selectedRows];
    const filteredUnits = data?.data?.filter(unit => currentSelection.includes(unit.id));

    const unitsToUpdate = filteredUnits?.reduce((updatedUnits: FlatUnit[], unit: FlatUnit) => {
      const changes = editedRows[unit.id];
      if (!changes) {
        return updatedUnits;
      }

      const convertedDoorType = getUnitDoorType(String(unit.door_type));
      const convertedUnrentableReason = unit.unrentable_reason
        ? getUnitUnrentableReason(String(unit.unrentable_reason))
        : null;

      if (changes) {
        updatedUnits.push({
          ...unit,
          ...changes,
          door_type: convertedDoorType,
          unrentable_reason: convertedUnrentableReason
        });
      } else {
        updatedUnits.push(unit);
      }

      return updatedUnits;
    }, []);

    if (!unitsToUpdate?.length) {
      dispatch(showSnackbar({ message: "No changes to save.", variant: "info" }));
      return;
    }

    try {
      const result = await updateUnits({ payload: unitsToUpdate });
      if ("data" in result) {
        setSelectedRows([]);
        setEditedRows({});
        setOpen(false);
      }
    } catch (error) {
      dispatch(showSnackbar({ message: "An error occurred during the update.", variant: "error" }));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      onClose={handleClose}
      open={open}
      className={classes.root}
    >
      <Grid
        container
        p={2}
        className={classes.root}
        component={Paper}
        elevation={0}
      >
        <Grid
          container
          p={2}
          spacing={2}
          item
          xs={12}
          justifyContent={"flex-end"}
        >
          <Grid item xs={12}>
            <Typography variant={"h4"}>Confirm Multiple Row Changes</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h6"}>You have {Object.keys(editedRows).length} rows with changes.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Alert severity={"warning"}>
              If you&apos;ve changed product types, consider updating the unit attributes.
            </Alert>
          </Grid>

          <Grid item>
            <Button
              disabled={updateLoading}
              className={classes.buttonBase}
              type={"submit"}
              form={"unrentable-reason-form"}
              variant={"contained"}
              onClick={submitEditedUnits}
            >
              Submit
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleClose}
              variant={"text"}
              color={"error"}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ConfirmationModal;
