import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "FacilitySelector" })((theme) => ({
  facilitySelector: {
    backgroundColor: theme.palette.common.white
  },
  modalTitle: {
    textTransform: "capitalize"
  }
}));

export default useStyles;
