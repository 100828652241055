import { RootState } from "src/store/rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSearchParams } from "../../../../utils/getSearchParams/getSearchParams";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const searchProductTypes = createAsyncThunk(
  "searchProductTypes",
  (searchValue: string, { dispatch, getState, rejectWithValue }) => {
    const store = getState() as RootState;
    const facilityId = store.selectedFacility.selectedFacility?.id;
    const pagination = getSearchParams(store, true);

    const params = {
      with: ["revenueClass", "facility", "units"],
      ...pagination
    };

    return axios.get(`/api/v1/facilities/${facilityId}/product-types?filter=${searchValue}`, { params })
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = "There was an error fetching the Product Types";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
