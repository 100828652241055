import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { APIListResponse } from "../../../models/responses/APIListResponse";
import ProductCategoriesSliceState from "./productCategoriesSliceState";
import { ProductCategory } from "../../../models/ProductCategory";
import { RootState } from "../../rootStore";
import { getAllProductCategories } from "../../thunks/productCategory/getAll/getAllProductCategories";
import { getAllProductCategoriesNew } from "src/store/thunks/productCategory/getAllNew/getAllProductCategoriesNew";
import { getProductType } from "src/store/thunks/productType/get/getProductType";

export const initialState: ProductCategoriesSliceState = {
  addedProductCategoryLoading: false,
  addedProductCategory: JSON.parse(sessionStorage.getItem("addedProductCategory") as string) ?? "",
  addedProductSubCategoryLoading: false,
  addedProductSubCategory: JSON.parse(sessionStorage.getItem("addedProductSubCategory") as string) ?? "",
  error: "",
  getProductCategoriesLoading: false,
  productCategories: JSON.parse(sessionStorage.getItem("productCategories") as string) ?? [],
  productSubCategories: JSON.parse(sessionStorage.getItem("productSubCategories") as string) ?? [],
  filteredSubCategories: []
};

export const productCategoriesSlice = createSlice({
  name: "productCategories",
  initialState,
  reducers: {
    resetProductCategoriesSlice: (state) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("productCategories");
      sessionStorage.removeItem("addedProductCategory");
      sessionStorage.removeItem("addedProductSubCategory");
    },
    filterProductSubCategories: (state, action: PayloadAction<number>) => {
      state.filteredSubCategories = state.productSubCategories;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProductCategories.pending, (state) => {
        state.getProductCategoriesLoading = true;
      })
      .addCase(
        getAllProductCategories.fulfilled,
        (state, action: PayloadAction<APIListResponse<ProductCategory[]>>
        ) => {
          state.productCategories = action.payload.data;
          state.productSubCategories = action.payload.data;
          sessionStorage.setItem("productCategories", JSON.stringify(state.productCategories));
          sessionStorage.setItem("productSubCategories", JSON.stringify(state.productSubCategories));
          state.getProductCategoriesLoading = false;
        })
      .addCase(getAllProductCategories.rejected, (state, action) => {
        state.error = action.payload as string;
        state.getProductCategoriesLoading = false;
      })
      .addCase(getAllProductCategoriesNew.pending, (state) => {
        state.getProductCategoriesLoading = true;
      })
      .addCase(getAllProductCategoriesNew.fulfilled, (state, action) => {
        state.getProductCategoriesLoading = false;
        state.productCategories = action.payload.data;
      })
      .addCase(getAllProductCategoriesNew.rejected, (state, action) => {
        state.error = action.payload as string;
        state.getProductCategoriesLoading = false;
      })
      .addCase(getProductType.fulfilled, (state, action) => {
        state.productCategories = [action.payload.product_category];
        state.productSubCategories = action.payload.product_category?.sub_categories ?? [];
      });
  }
});

export const selectAddedProductCategoryLoading = (state: RootState) => state.productCategories
  .addedProductCategoryLoading;
export const selectAddedProductSubCategoryLoading = (state: RootState) => state.productCategories
  .addedProductSubCategoryLoading;
export const selectProductCategoriesList = (state: RootState) => state.productCategories.productCategories;
export const selectProductCategoriesListLoading = (state: RootState) =>
  state.productCategories.getProductCategoriesLoading;
export const selectProductCategories = (state: RootState) => state.productCategories.productCategories;
export const selectProductSubCategories = (state: RootState) => [];
// export const selectProductCategories = (state: RootState) =>
//   state.productCategories.productCategories.filter(category => !category.parent_id);
// export const selectProductSubCategories = (state: RootState) => state.productCategories.productSubCategories;
export const selectFilteredSubCategories = (state: RootState) => state.productCategories.filteredSubCategories;

export const {
  resetProductCategoriesSlice,
  filterProductSubCategories
} = productCategoriesSlice.actions;

export default productCategoriesSlice.reducer;
