import { AddCircle, Search } from "@mui/icons-material";
import { Button, InputAdornment, Tab, Tabs, TextField } from "@mui/material";
import React, { ReactElement, useCallback, useEffect } from "react";
import {
  selectDocumentCurrentTabView,
  selectDocumentTemplateLoading,
  selectDocumentTemplateSearchValue,
  selectSelectedDocumentTemplate,
  selectShowAllFacilitiesInDocumentLibrary,
  setDocumentCurrentTabView,
  setDocumentTemplateSearchValue
} from "src/store/reducers/documentsSlice/documentsSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Breadcrumb } from "../../../models/Breadcrumb";
import ChangelogModal from "../ChangelogModal/ChangelogModal";
import DocumentLibraryTable from "./DocumentLibraryTable/DocumentLibraryTable";
import DocumentLibraryTitle from "./components/Title/DocumentLibraryTitle";
import ItemManagementLayout from "../../../layouts/ItemManagement/ItemManagementLayout";
import { NavLink } from "react-router-dom";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import { SyntheticInputEvent } from "react-number-format/types/types";
import ViewWrapper from "../../../layouts/ViewWrapper/ViewWrapper";
import { debounce } from "lodash";
import {
  getAllPaginatedDocumentTemplates
} from "src/store/thunks/documentTemplates/getAll/getAllPaginatedDocumentTemplates";
import {
  resetSelectedAdminFacilitySlice
} from "src/store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import { resetTablesSlice } from "../../../store/reducers/tablesSlice/tablesSlice";
import useStyles from "./DocumentLibrary.styles";
import RemoveDocumentModal from "./components/RemoveDocumentModal/RemoveDocumentModal";
import RollbackDocumentModal from "../RollbackDocumentModal/RollbackDocumentModal";
import { rollbackDocumentTemplate } from "src/store/thunks/documentTemplates/rollback/rollbackDocumentTemplate";
import { resetDocumentSlice, setDocumentRollbackModalOpened } from "src/store/reducers/documentSlice/documentSlice";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const DocumentLibrary: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const documentTemplatesLoading = useAppSelector(selectDocumentTemplateLoading);
  const searchValue = useAppSelector(selectDocumentTemplateSearchValue);
  const documentCurrentTabView = useAppSelector(selectDocumentCurrentTabView);
  const showAllFacilities = useAppSelector(selectShowAllFacilitiesInDocumentLibrary);
  const selectedDocument = useAppSelector(selectSelectedDocumentTemplate);
  const permissions = useAppSelector(selectFacilityPermissions);
  const documentLibraryPermissions = createPermissions(permissions, "document_library");
  const breadcrumbs: Breadcrumb[] = [
    {
      name: "Documents"
    },
    {
      name: "Document Library",
      bold: true
    }
  ];

  const pageHeader = <PageHeader title={"Document Library"} breadcrumbs={breadcrumbs} />;

  const addNewDocumentButton = (
    <Button
      className={classes.newDocumentButton}
      color={"primary"}
      variant={"text"}
      startIcon={<AddCircle />}
      component={NavLink}
      to={"/documents/new-document/create"}
    >
      Add New Document
    </Button>
  );

  const debounceSearch = useCallback(
    debounce((text) => dispatch(setDocumentTemplateSearchValue(text)), 600),
    []
  );

  const handleChange = (e: SyntheticInputEvent) => debounceSearch(e.target.value);

  const handleTabChange = (e: React.SyntheticEvent, newValue: number) => {
    dispatch(setDocumentCurrentTabView(newValue));
    if (newValue === 0) {
      dispatch(getAllPaginatedDocumentTemplates({ showAllFacilities }));
    } else {
      dispatch(getAllPaginatedDocumentTemplates({ type: newValue }));
    }
  };

  const searchBar = (
    <TextField
      fullWidth
      id={"search-occupants-table"}
      placeholder={"- Search -"}
      size={"small"}
      InputProps={{
        endAdornment: (
          <InputAdornment position={"end"}>
            <Search />
          </InputAdornment>
        )
      }}
      variant={"outlined"}
      name={"occupantsTableSearch"}
      onChange={handleChange}
    />
  );

  useEffect(() => {
    if (searchValue && !searchValue.length) {
      dispatch(
        getAllPaginatedDocumentTemplates({
          showAllFacilities,
          type: documentCurrentTabView
        })
      );
      return;
    }

    dispatch(setDocumentTemplateSearchValue(searchValue));
    dispatch(
      getAllPaginatedDocumentTemplates({
        searchValue,
        type: documentCurrentTabView,
        showAllFacilities
      })
    );
  }, [searchValue]);

  useEffect(() => {
    dispatch(getAllPaginatedDocumentTemplates({ showAllFacilities }));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetTablesSlice());
      dispatch(resetSelectedAdminFacilitySlice());
    };
  }, []);

  const handleRollback = () => {
    if (selectedDocument?.id) {
      dispatch(rollbackDocumentTemplate(selectedDocument.id)).then((resp) => {
        if (!resp.type.includes("rejected")) {
          dispatch(setDocumentRollbackModalOpened());
          dispatch(getAllPaginatedDocumentTemplates({ showAllFacilities }));
          dispatch(resetDocumentSlice());
        }
      });
    }
  };

  if (documentLibraryPermissions.read) {
    return (
      <>
        <ViewWrapper pageHeader={pageHeader}>
          <Tabs value={documentCurrentTabView} onChange={handleTabChange} aria-label={"basic tabs example"}>
            <Tab label={"All Documents"} value={0} />
            <Tab label={"Email Templates"} value={2} />
          </Tabs>
          <ItemManagementLayout
            title={<DocumentLibraryTitle />}
            filters={documentLibraryPermissions.create ? addNewDocumentButton : null}
            loading={documentTemplatesLoading}
            searchBar={searchBar}
          >
            <DocumentLibraryTable />
            <RemoveDocumentModal />
          </ItemManagementLayout>
        </ViewWrapper>
        <ChangelogModal />
        <RollbackDocumentModal onSubmit={() => handleRollback()} />
      </>
    );
  } else {
    return (
      <>
        <ViewWrapper pageHeader={pageHeader}>
          <h1>You do not have permission to view this page</h1>
        </ViewWrapper>
      </>
    );
  }
};

export default DocumentLibrary;
