import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  InputLabel,
  List,
  ListItem,
  Modal,
  Paper,
  Typography
} from "@mui/material";
import React, { FormEvent, ReactElement, useEffect, useState } from "react";
import {
  resetFeesSlice,
  selectActiveFees,
  selectFees,
  selectFeesLoading
} from "src/store/reducers/feesSlice/feesSlice";
import {
  resetLedgersSlice,
  selectLedgersModalOpen,
  selectSelectedLedger,
  setLedgersModalOpen,
  setSelectedLedger
} from "src/store/reducers/ledgersSlice/ledgersSlice";
import {
  resetOccupantSlice,
  selectOccupant,
  selectOccupantLoading
} from "src/store/reducers/occupantSlice/occupantSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { useNavigate, useParams } from "react-router";

import ApplyFeeModal from "./Modal/ApplyFeeModal";
import { Breadcrumb } from "src/models/Breadcrumb";
import { ExpandMore } from "@mui/icons-material";
import { Fee } from "src/models/Fee";
import FooterButtons from "src/components/itemManagement/ItemManagementCard/FooterButtons/FooterButtons";
import ItemManagementLayout from "src/layouts/ItemManagement/ItemManagementLayout";
import { Ledger } from "src/models/Ledger";
import { LedgerItem } from "src/models/LedgerItem";
import LedgerView from "src/pages/Occupants/EditOccupant/LedgerView/LedgerView";
import { LoadingButton } from "@mui/lab";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import { PaymentMethod } from "src/enums/PaymentMethod";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { applyFees } from "src/store/thunks/applyFee/applyFees";
import clsx from "clsx";
import formatPhone from "src/utils/formatPhone/formatPhone";
import { formattedAmount } from "src/utils/formattedAmount/formattedAmount";
import { getAllFeesForFacility } from "src/store/thunks/fee/getAllForFacility/getAllFeesForFacility";
import { getAppliedFees } from "src/store/thunks/applyFee/getAppliedFees";
import { getApplyFeeCost } from "src/store/thunks/applyFee/getApplyFeeCost";
import { getOccupant } from "src/store/thunks/occupant/getOne/getOccupant";
import { map } from "lodash";
import moment from "moment";
import {
  resetEmergencyContactInformationSlice
} from "src/store/reducers/emergencyContactInformationSlice/emergencyContactInformationSlice";
import { resetEmergencyContactSlice } from "src/store/reducers/emergencyContactSlice/emergencyContactSlice";
import { selectApplyFee } from "src/store/reducers/applyFeeSlice/applyFeeSlice";
import useStyles from "./ApplyFee.styles";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Collections"
  },
  {
    name: "Delinquent Occupants"
  },
  {
    name: "Select Fee",
    bold: true
  }
];

const paymentMethodToText = {
  [PaymentMethod.cash]: "Cash",
  [PaymentMethod.creditCard]: "Credit Card",
  [PaymentMethod.ach]: "ACH",
  [PaymentMethod.moneyOrder]: "Check/Money Order"
} as Record<number, string>;

const chargeableFeeType = "App//Models//Fee";

const ApplyFee: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { occupantId, ledgerId } = useParams();
  const occupant = useAppSelector(selectOccupant);
  const occupantLoading = useAppSelector(selectOccupantLoading);
  const allFees = useAppSelector(selectActiveFees);
  const feesLoading = useAppSelector(selectFeesLoading);
  const selectedLedger = useAppSelector(selectSelectedLedger);
  const ledgersModalOpen = useAppSelector(selectLedgersModalOpen);
  const applyFeeState = useAppSelector(selectApplyFee);
  const [applyFee, setApplyFee] = useState<string>("");
  const [fees, setFees] = useState<Fee[]>([]);
  const [submittedFees, setSubmittedFees] = useState<Set<string>>(new Set());
  const [applyFeeModalOpen, setApplyFeeModalOpen] = useState<boolean>(false);
  const [ledgerItem, setLedgerItem] = useState<LedgerItem>();

  useEffect(() => {
    if (!occupantId || !ledgerId) {
      navigate(-1);
    }

    dispatch(getOccupant(occupantId!)).then(({ payload: occupant }) => {
      const ledger = occupant?.ledgers?.find((ledger: Ledger) => ledger.id === Number(ledgerId)) as Ledger;

      if (!ledger) {
        navigate(-1);
      }

      const ledgerItems = [...ledger?.ledger_items!];

      const ledgerItem = ledgerItems
        ?.sort((a, b) => (moment(a.created_at).isBefore(b.created_at) ? 1 : -1))
        .find(
          (item: LedgerItem) => item.description === "Payment" || item.description === "Auto Payment"
        ) as LedgerItem;

      setLedgerItem(ledgerItem);
      dispatch(setSelectedLedger(ledger));
      dispatch(getAllFeesForFacility({ facilityId: ledger.facility_id }));
      if (occupantId && ledgerId) {
        dispatch(getAppliedFees({ occupantId, ledgerId }));
      }
    });

    return () => {
      dispatch(resetLedgersSlice());
      dispatch(resetFeesSlice());
      dispatch(resetOccupantSlice());
      dispatch(resetEmergencyContactInformationSlice());
      dispatch(resetEmergencyContactSlice());
    };
  }, []);

  useEffect(() => {
    const appliedFees = selectedLedger?.ledger_items
      ?.filter((ledgerItem) => ledgerItem.chargeable_type === chargeableFeeType)
      .map((ledgerItem) => ledgerItem.chargeable_id);

    // should not show fee if it is reccurent and already applied
    const filteredFees = allFees.filter((fee) => {
      if (fee.recurrence === 1) {
        return true;
      }
      return !appliedFees?.includes(fee.id);
    });
    setFees(filteredFees);
  }, [allFees]);

  const formatOccupantName = () => {
    if (occupant?.middle_name) {
      return `${occupant?.first_name} ${occupant?.middle_name} ${occupant?.last_name}`;
    }
    return `${occupant?.first_name} ${occupant?.last_name}`;
  };

  const FeeDescription = () => {
    const fee = fees.find((fee) => fee.id === Number(applyFee));
    if (fee) {
      return (
        <Grid item display={"flex"} columnSpacing={1}>
          <Grid flexDirection={"column"} rowSpacing={1} alignItems={"center"}
            mr={1} item>
            <Typography className={classes.label}>Amount:</Typography>
            <Typography className={classes.label}>Recurrence:</Typography>
            <Typography className={classes.label}>Description:</Typography>
          </Grid>
          <Grid flexDirection={"column"} rowSpacing={1} alignItems={"center"}
            item>
            <Typography>{formattedAmount(fee.type, fee.amount)}</Typography>
            <Typography>{fee.recurrence === 1 ? "One-time" : "Recurring"}</Typography>
            <Typography>{fee.description}</Typography>
          </Grid>
        </Grid>
      );
    }
    return <></>;
  };

  const feeChip = (name: string, alreadyApplied = false) => {
    return (
      <Typography
        className={clsx(classes.chip, alreadyApplied ? classes.alreadyAppliedChip : classes.newFeeChip)}
        variant={"caption"}
      >
        {name}
      </Typography>
    );
  };

  const OccupantAccordion = () => (
    <Accordion elevation={1} defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant={"h4"} p={2}>
          {formatOccupantName()}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container paddingX={2}>
          <Grid lg={4} xs={5} item
            container pr={1} mr={1}>
            <Grid display={"flex"} flexDirection={"column"} xs={12}
              lg={6} mb={3}>
              <Typography>{occupant?.address}</Typography>
              <Typography>
                {occupant?.city} {occupant?.region}
              </Typography>
              <Typography>{formatPhone(occupant?.phone_primary)}</Typography>
            </Grid>
            <Grid flexDirection={"column"} rowSpacing={1} alignItems={"center"}
              width={"100%"} xs={12} lg={6}>
              <Grid display={"flex"} justifyContent={"space-between"} wrap={"wrap"}>
                <Typography className={classes.label}>Location:</Typography>
                <Typography textAlign={"right"}>{selectedLedger?.facility?.name}</Typography>
              </Grid>
              <Grid display={"flex"} justifyContent={"space-between"} wrap={"wrap"}>
                <Typography className={classes.label}>Unit:</Typography>
                <Typography>{selectedLedger?.unit?.unit_number}</Typography>
              </Grid>
              <Grid display={"flex"} justifyContent={"space-between"} wrap={"wrap"}>
                <Typography className={classes.label}>Transaction Type:</Typography>
                <Typography>{paymentMethodToText[ledgerItem?.transactions?.[0]?.payment_method!]}</Typography>
              </Grid>
              <Grid display={"flex"} justifyContent={"space-between"} wrap={"wrap"}>
                <Typography className={classes.label}>Transaction Date:</Typography>
                <Typography>{moment(ledgerItem?.transactions?.[0]?.created_at).format("MM/DD/YYYY")}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={6} lg={7} item
            container>
            <Grid display={"flex"} flexWrap={"wrap"} xs={12}
              lg={9} mb={3}>
              <Grid mr={1}>
                <Typography className={classes.label} noWrap>
                  Fees Applied:
                </Typography>
              </Grid>

              <Grid display={"flex"} alignItems={"start"} justifyContent={"start"}
                flexWrap={"wrap"} gap={1}>
                {applyFeeState.alreadyAppliedFees.map((fee) => feeChip(fee.name!, true))}
                {Array.from(submittedFees).map((feeId) => feeChip(fees.find((fee) => fee.id === Number(feeId))?.name!))}
              </Grid>
            </Grid>
            <Grid display={"flex"} xs={12} lg={3}>
              <Grid flexDirection={"column"} rowSpacing={1} alignItems={"center"}
                mr={1} item>
                <Typography className={classes.label}>Current Balance:</Typography>
              </Grid>
              <Grid flexDirection={"column"} rowSpacing={1} alignItems={"center"}
                item>
                <Typography>${selectedLedger?.current_balance}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent={"space-between"}>
            <Button
              variant={"outlined"}
              disableElevation
              className={classes.viewOccupantButton}
              onClick={() => navigate(`/occupants/${occupantId}/edit`)}
            >
              View Occupant
            </Button>
            <Button
              variant={"contained"}
              disableElevation
              className={classes.viewLedgerButton}
              onClick={() => dispatch(setLedgersModalOpen(true))}
            >
              View Ledger
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );

  const pageHeader = <PageHeader title={"Delinquent Occupants"} breadcrumbs={breadcrumbs} />;

  return (
    <Grid
      component={"form"}
      onSubmit={(event: FormEvent) => {
        event.preventDefault();
        setApplyFeeModalOpen(true);
      }}
      id={"apply-fee-form"}
    >
      <ViewWrapper pageHeader={pageHeader}>
        <ItemManagementLayout
          title={"Select Fee"}
          loading={occupantLoading || feesLoading}
          footerButtons={
            <FooterButtons
              formId={"apply-fee-form"}
              cancelPath={`/occupants/${occupantId}/edit`}
              submitLoading={false}
              disabled={submittedFees?.size === 0}
              submitButtonText={"Apply"}
            />
          }
        >
          <Grid mt={2}>
            <OccupantAccordion />
            <Grid display={"flex"} xs={6} mt={2}
              lg={3} mb={2} container>
              <Typography>Fee Type</Typography>
              <Grid display={"flex"} item xs={12}
                columnGap={2} alignItems={"center"}>
                <PMSSelect
                  id={"fee-selector"}
                  value={applyFee}
                  changeHandler={(event) => setApplyFee(event.target.value)}
                >
                  <option value={""} disabled>
                    - Select a Fee -
                  </option>
                  {fees
                    .filter(
                      (fee) =>
                        !applyFeeState.alreadyAppliedFees.find(
                          (appliedFee) => fee.id === appliedFee.id && fee.recurrence === 2
                        )
                    )
                    .map((fee: Fee) => (
                      <option key={fee.id} value={fee.id}>
                        {fee.name}
                      </option>
                    ))}
                </PMSSelect>
                <Button
                  className={classes.submitButton}
                  onClick={() => {
                    const fees = [...submittedFees, applyFee];
                    setSubmittedFees(() => new Set(fees));
                    if (occupantId && ledgerId) {
                      dispatch(getApplyFeeCost({ occupantId, ledgerId, fees }));
                    }
                  }}
                  disableElevation
                  variant={"contained"}
                  disabled={Number(applyFeeState) === 0}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
            <FeeDescription />
          </Grid>
        </ItemManagementLayout>
        <Modal
          open={ledgersModalOpen}
          onClose={() => dispatch(setLedgersModalOpen(false))}
          aria-labelledby={"Modal Component"}
          aria-describedby={"A Modal component."}
          className={clsx(classes.modal, classes.ledgerModal)}
        >
          {/* this fragment here gets rid of a strange forwardRef console error locally. */}
          <>
            <LedgerView />
          </>
        </Modal>
        <ApplyFeeModal
          open={applyFeeModalOpen}
          ledgerId={ledgerId || ""}
          occupantId={occupantId || ""}
          onClose={() => setApplyFeeModalOpen(false)}
          fees={map(applyFeeState?.applyFeeCost.fees, "id")}
        />
      </ViewWrapper>
    </Grid>
  );
};

export default ApplyFee;
