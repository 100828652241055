import { Button, Divider, Grid, IconButton, InputLabel, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, FC, SyntheticEvent } from "react";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import PMSCheckbox from "src/components/ui/PMSCheckbox/PMSCheckbox";
import clsx from "clsx";
import { emailReceipt } from "src/store/thunks/receipt/emailReceipt/emailReceipt";
import { inputError } from "src/utils/showInputError/showInputError";
import { selectSelectedOccupant } from "src/store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import sendEmailReceiptFormValidation from "./sendEmailReceiptFormValidation";
import { useFormik } from "formik";
import useStyles from "./SendEmailReceiptForm.styles";

interface SendEmailModalProps {
    closeButtonAction: () => void
}

interface SendEmailValues {
    email: string
    useExistingEmailAddress: boolean
}
const initialValues: SendEmailValues = {
  email: "",
  useExistingEmailAddress: false
};

const SendEmailForm: FC<SendEmailModalProps> = ({ closeButtonAction }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const selectedOccupant = useAppSelector(selectSelectedOccupant);

  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: sendEmailReceiptFormValidation,
    onSubmit: (values) => {
      dispatch(emailReceipt(values.email)).then((resp) => {
        if (!resp.type.includes("rejected")) {
          closeButtonAction();
        }
      });
    }
  });

  const { values, handleChange, handleSubmit, touched, errors, setFieldValue } = formik;

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue((e.target as HTMLInputElement).name, e.target.checked);
    if (e.target.checked) {
      setFieldValue("email", selectedOccupant?.email || "");
    } else {
      setFieldValue("email", "");
    }
  };

  return (
    <Grid
      pt={0}
      component={"form"}
      id={"send-merch-email-receipt"}
      onSubmit={(e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
      }}
      container
      spacing={2}
        >
      <Grid
        container
        alignContent={"center"}
        item
        xs={12}
        justifyContent={"space-between"}
            >
        <Typography variant={"h4"}>Send Email</Typography>
        <IconButton onClick={closeButtonAction}>
          <Close color={"primary"} fontSize={"large"} />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {selectedOccupant && (
        <Grid item xs={12}>
          <PMSCheckbox
            smallText
            data-testid={"use-existing-email-address"}
            label={"Use Existing Email Addresss"}
            name={"useExistingEmailAddress"}
            isChecked={values.useExistingEmailAddress}
            changeHandler={onCheckboxChange}
          />
          </Grid>
      )}
      <Grid item xs={12}>
        <InputLabel htmlFor={"subcategory"}>
          Email
        </InputLabel>
        <TextField
          fullWidth
          id={"email"}
          inputProps={{ "data-testid": "email" }}
          name={"email"}
          value={values.useExistingEmailAddress ? selectedOccupant?.email : values.email}
          onChange={handleChange}
          error={inputError("email", touched, errors).error}
          helperText={inputError("email", touched, errors).helperText}
          disabled={values.useExistingEmailAddress}
        />
      </Grid>
      <Grid
        container
        justifyContent={"flex-end"}
        item
        xs={12}
        >
        <Button
          onClick={closeButtonAction}
          variant={"text"}
          color={"error"}
          className={classes.buttonBase}
          size={"large"}
        >
          Cancel
        </Button>
        <LoadingButton
          variant={"contained"}
          className={clsx(classes.saveButton, classes.buttonBase)}
          size={"large"}
          type={"submit"}
          loading={false}
          disabled={false}
        >
          Confirm
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default SendEmailForm;
