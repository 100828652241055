import CreateMoveOut from "src/pages/Deals/CreateMoveOut/CreateMoveOut";
import GetMoveOutStarted from "src/pages/Deals/CreateMoveOut/GetMoveOutStarted/GetMoveOutStarted";
import ProcessMoveOut from "src/pages/Deals/CreateMoveOut/ProcessMoveOut/ProcessMoveOut";
import React from "react";
import ReviewMoveOut from "src/pages/Deals/CreateMoveOut/ReviewMoveOut/ReviewMoveOut";
import { Route } from "react-router";
import SelectOccupantForMoveOut from "src/pages/Deals/CreateMoveOut/SelectOccupantForMoveOut/SelectOccupantForMoveOut";
import SelectUnitForMoveOut from "src/pages/Deals/CreateMoveOut/SelectUnitForMoveOut/SelectUnitForMoveOut";

const groupName = "occupants/create-move-out";

const CreateMoveOutRoutes = (
  <>
    <Route
      path={groupName}
      element={
        <CreateMoveOut>
          <GetMoveOutStarted />
        </CreateMoveOut>
      }
    />
    <Route
      path={`${groupName}/select-occupant`}
      element={
        <CreateMoveOut>
          <SelectOccupantForMoveOut />
        </CreateMoveOut>
      }
    />
    <Route
      path={`${groupName}/select-unit`}
      element={
        <CreateMoveOut>
          <SelectUnitForMoveOut />
        </CreateMoveOut>
      }
    />
    <Route
      path={`${groupName}/process-move-out`}
      element={
        <CreateMoveOut>
          <ProcessMoveOut />
        </CreateMoveOut>
      }
    />
    <Route
      path={`${groupName}/review-move-out`}
      element={
        <CreateMoveOut>
          <ReviewMoveOut />
        </CreateMoveOut>
      }
    />
  </>
);

export default CreateMoveOutRoutes;
