import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Facility } from "src/models/Facility";
import { RootState } from "../../rootStore";
import { TaskFormSliceState } from "./TaskFormSliceState";
import { TaskValues } from "src/models/formikInputValues/TaskValues";
import { createTask } from "../../thunks/task/create/createTask";
import { getTask } from "src/store/thunks/task/getOne/getTask";
import moment from "moment";

export const initialState: TaskFormSliceState = {
  taskName: "",
  taskCategory: -1, // need this value (instead of 0) for default as one of the actual category values is 0
  taskRecurrence: -1, // need this value (instead of 0) for default as one of the actual recurrence values is 0
  taskDueDate: "",
  taskDueTime: "",
  taskIsAllDay: false,
  taskDescription: "",
  taskIsCompleted: false,
  taskFacilities: [],
  taskFormLoading: false,
  taskFormError: "",
  taskUpdateValues: undefined
};

export const taskFormSlice = createSlice({
  name: "taskForm",
  initialState,
  reducers: {
    setTaskIsCompleted: (state, action) => {
      state.taskIsCompleted = action.payload;
    },
    setTaskUpdateValues: (state, action: PayloadAction<TaskValues>) => {
      state.taskUpdateValues = action.payload;
    },
    resetTaskFormSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTask.pending, (state) => {
        state.taskFormLoading = true;
      })
      .addCase(createTask.fulfilled, (state) => {
        state.taskFormLoading = false;
      })
      .addCase(createTask.rejected, (state) => {
        state.taskFormLoading = false;
      })
      .addCase(getTask.pending, (state) => {
        state.taskFormLoading = true;
      })
      .addCase(getTask.fulfilled, (state, action) => {
        state.taskFormLoading = false;
        state.taskName = action.payload.name;
        state.taskCategory = action.payload.task_category_id;
        state.taskRecurrence = +action.payload.recurrence;
        state.taskIsCompleted = action.payload.is_completed;
        state.taskIsAllDay = action.payload.is_all_day;
        state.taskDueDate = action.payload.due_date;
        state.taskDueTime = moment(action.payload.due_time, "hh:mm A").format();
        state.taskDescription = action.payload.description ?? "";
        state.taskFacilities = action.payload.assignees.map((facility: Facility) => facility.id);
      })
      .addCase(getTask.rejected, (state) => {
        state.taskFormLoading = false;
      });
  }
});

export const selectTaskName = (state: RootState) => state.taskForm.taskName;
export const selectTaskCategory = (state: RootState) => state.taskForm.taskCategory;
export const selectTaskRecurrence = (state: RootState) => state.taskForm.taskRecurrence;
export const selectTaskDueDate = (state: RootState) => state.taskForm.taskDueDate;
export const selectTaskDueTime = (state: RootState) => state.taskForm.taskDueTime;
export const selectTaskIsAllDay = (state: RootState) => state.taskForm.taskIsAllDay;
export const selectTaskDescription = (state: RootState) => state.taskForm.taskDescription;
export const selectTaskFacilities = (state: RootState) => state.taskForm.taskFacilities;
export const selectTaskFormLoading = (state: RootState) => state.taskForm.taskFormLoading;
export const selectTaskIsCompleted = (state: RootState) => state.taskForm.taskIsCompleted;
export const selectTaskUpdateValues = (state: RootState) => state.taskForm.taskUpdateValues;

export const {
  setTaskIsCompleted,
  setTaskUpdateValues,
  resetTaskFormSlice
} = taskFormSlice.actions;

export default taskFormSlice.reducer;
