import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Cancellation Modal" })((theme) => ({

  button: {
    background: theme.palette.primary.contrastText,
    color: "#FFF",
    minWidth: theme.spacing(15),
    minHeight: theme.spacing(5),
    fontWeight: "normal"
  },
  cancelButton: {
    minWidth: theme.spacing(15),
    minHeight: theme.spacing(5),
    color: theme.palette.error.contrastText,
    fontWeight: "normal"
  }

}));

export default useStyles;
