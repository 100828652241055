import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

interface DisableLedgerInsurancePlan {
    occupant_id: string
    ledger_id: string
}

export const disableLedgerInsurancePlan = createAsyncThunk(
  "disableLedgerInsurancePlan",
  ({ occupant_id, ledger_id }: DisableLedgerInsurancePlan, { dispatch, getState, rejectWithValue }) => {
    return axios.get(`/api/v1/occupants/${occupant_id}/ledgers/${ledger_id}/cancel-current-enrolled-tpp-plan`)
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = "There was an error cancelling the Insurance Plan for this Ledger";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
