import React from "react";
import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  selectIsPartialPayment,
  selectPaymentAmount,
  selectSelectedDelinquent,
  setIsPartialPayment,
  setPaymentAmount
} from "src/store/reducers/collectionsSlice/collectionsSlice";
import useStyles from "./PartialPaymentColumn.styles";
import PMSCheckbox from "src/components/ui/PMSCheckbox/PMSCheckbox";

const PartialPaymentColumn = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const selectedDelinquent = useAppSelector(selectSelectedDelinquent);
  const isPartialPayment = useAppSelector(selectIsPartialPayment);
  const paymentAmount = useAppSelector(selectPaymentAmount);

  return (
    <>
      {isPartialPayment
        ? (
          <Grid display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Grid>
              <TextField
                id={"issue-credit-value"}
                name={"issueCreditValue"}
                onChange={(evt) => {
                  if (selectedDelinquent?.balance && +evt.target.value <= +selectedDelinquent?.balance) {
                    dispatch(setPaymentAmount(evt.target.value));
                  }
                }}
                value={paymentAmount}
                inputMode={"numeric"}
                size={"small"}
                className={classes.partialPaymentInput}
                InputProps={{
                  startAdornment: <InputAdornment position={"start"}>$</InputAdornment>
                }}
                      />
            </Grid>
            <Grid>
              <IconButton
                onClick={() => {
                  dispatch(setIsPartialPayment(!isPartialPayment));
                  dispatch(setPaymentAmount(""));
                }}
                size={"small"}
                      >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          )
        : (
          <PMSCheckbox
            isChecked={isPartialPayment}
            changeHandler={() => dispatch(setIsPartialPayment(!isPartialPayment))}
          />
          )}
    </>
  );
};

export default PartialPaymentColumn;
