import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { Grid, IconButton, TextField } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";
import useStyles from "./ItemSelectorIncrementor.styles";

interface ItemSelectorIncrementorProps<T> {
  item: T
  onManualQuantityChange: (quantity: number, item: T) => void
  onIncrement: (any?: any) => void
  onDecrement: (any?: any) => void
}

interface GenericInterface {
  name: string
  id: number
  quantity_for_cart?: number
}

const ItemSelectorIncrementor = <T extends GenericInterface>({
  item, onManualQuantityChange, onIncrement, onDecrement
}: ItemSelectorIncrementorProps<T>): ReactElement => {
  const { classes } = useStyles();

  const quantityFab = (iconButton: ReactNode) => (
    <Grid
      item
      container
      justifyItems={"center"}
      xs={"auto"}
    >
      {iconButton}
    </Grid>
  );

  return (
    <>
      {quantityFab(
        <IconButton aria-label={"decrement button"} onClick={onDecrement} className={classes.quantityButton}>
          <RemoveCircle />
        </IconButton>
      )}
      <Grid item xs={5}>
        <TextField
          size={"small"}
          variant={"outlined"}
          value={item.quantity_for_cart}
          inputProps={{ "data-testid": "quantity-input" }}
          type={"number"}
          onChange={(e) => onManualQuantityChange(parseInt(e.target.value), item)}
        />
      </Grid>
      {quantityFab(
        <IconButton aria-label={"increment button"} onClick={onIncrement} className={classes.quantityButton}>
          <AddCircle />
        </IconButton>
      )}
    </>
  );
};

export default ItemSelectorIncrementor;
