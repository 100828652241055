import { Box, Button, InputAdornment, MenuItem, Modal, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import { selectLedgersModalOpen, setLedgersModalOpen } from "src/store/reducers/ledgersSlice/ledgersSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Breadcrumb } from "src/models/Breadcrumb";
import FilterButtonGroup from "src/components/ui/FilterButtonGroup/FilterButtonGroup";
import LedgerView from "src/pages/Occupants/EditOccupant/LedgerView/LedgerView";
import ManageAuctionsTable from "./ManageAuctionsTable";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import RemoveAuctionModal from "./RemoveAuctionModal/RemoveAuctionModal";
import { Search } from "@mui/icons-material";
import TableWrapper from "src/layouts/TableWrapper/TableWrapper";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import clsx from "clsx";
import { debounce } from "lodash";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { useGetAuctionsQuery } from "src/api/endpoints/auctions";
import usePagination from "src/hooks/usePagination";
import useStyles from "./ManageAuctions.styles";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Collections"
  },
  {
    name: "Manage Auctions",
    bold: true
  }
];

const ManageAuctions = () => {
  const dispatch = useAppDispatch();
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const ledgerModalOpen = useAppSelector(selectLedgersModalOpen);
  const { classes } = useStyles();
  const [filter, setFilter] = useState<"upcoming" | "completed">("upcoming");
  const [search, setSearch] = useState("");

  const pagination = usePagination();

  const { auctions, totalCount, isFetching, isError } = useGetAuctionsQuery(
    { facilityId: selectedFacility.id, pagination: pagination.values, filter, search },
    {
      skip: !selectedFacility,
      selectFromResult: ({ data, isFetching, isError }) => ({
        auctions: data?.data ?? [],
        totalCount: data?.meta.total,
        isFetching: isFetching,
        isError
      }),
      refetchOnMountOrArgChange: true
    }
  );

  if (isError) {
    dispatch(
      showSnackbar({
        message: "There was an error getting the auctions.",
        variant: "error"
      })
    );
  }

  const debounceSearch = debounce((value) => setSearch(value), 500);

  const searchBar = (
    <TextField
      fullWidth
      id={"search-transactions-table"}
      placeholder={"Search"}
      size={"small"}
      InputProps={{
        endAdornment: (
          <InputAdornment position={"end"}>
            <Search />
          </InputAdornment>
        )
      }}
      variant={"outlined"}
      onChange={(e) => debounceSearch(e.target.value)}
    />
  );

  const pageHeader = <PageHeader title={"Manage Auctions"} breadcrumbs={breadcrumbs} />;

  const filterDropdown = (
    <Box width={200}>
      <Select
        size={"small"}
        fullWidth
        onChange={(event) => setFilter(event.target.value as "upcoming" | "completed")}
        value={filter}
      >
        <MenuItem value={"upcoming"}>Upcoming</MenuItem>
        <MenuItem value={"completed"}>Completed</MenuItem>
      </Select>
    </Box>
  );

  return (
    <ViewWrapper pageHeader={pageHeader}>
      <TableWrapper
        title={"Manage Auctions"}
        searchBar={searchBar}
        filterDropdown={filterDropdown}
        table={
          <ManageAuctionsTable
            auctions={auctions}
            loading={isFetching}
            pagination={pagination}
            totalCount={totalCount!}
          />
        }
      />
      <Modal
        open={ledgerModalOpen}
        onClose={() => dispatch(setLedgersModalOpen(false))}
        aria-labelledby={"Modal Component"}
        aria-describedby={"A Modal component."}
        className={clsx(classes.modal, classes.ledgerModal)}
      >
        {/* this fragment here gets rid of a strange forwardRef console error locally. */}
        <>
          <LedgerView showLedgerPrintAndSelectUnit={false} />
        </>
      </Modal>
      <RemoveAuctionModal />
    </ViewWrapper>
  );
};

export default ManageAuctions;
