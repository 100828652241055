import { FormHelperText, Grid, TextField, TextFieldProps, Typography } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import React, { ReactElement } from "react";
import moment, { Moment, MomentInput } from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { FormikProps } from "formik";
import PMSSwitch from "../../../../../components/ui/PMSSwitch/PMSSwitch";
import useStyles from "./FacilityHoursRow.styles";

interface Day {
  close: string
  is_closed_for_the_day: boolean,
  is_open_24_hours: boolean,
  open: string
}

interface FormValues {
    name: string,
    legal_name: string
    facility_id: string
    address: string
    city: string
    region: string,
    operational_hours: {
      monday: Day,
      tuesday: Day,
      wednesday: Day,
      thursday: Day,
      friday: Day,
      saturday: Day,
      sunday: Day,
    }
    postal_code: string,
    tax_id: string
    timezone: string
}

interface FacilityHoursRowProps {
  formik: Pick<FormikProps<FormValues>,
  "handleChange" | "errors" | "values" | "touched" | "setFieldValue" | "setFieldError" | "setFieldTouched">,
  day: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday"
  facilityClosed: boolean
  facilityTwentyFourHours: boolean
  valueOpen: string | null
  valueClosed: string | null
}

const FacilityHoursRow: React.FC<FacilityHoursRowProps> = ({
  day,
  formik,
  facilityClosed,
  facilityTwentyFourHours,
  valueOpen,
  valueClosed
}: FacilityHoursRowProps): ReactElement => {
  const { classes } = useStyles();

  const updateForm = (fieldName: string, fieldValue?: string | boolean| MomentInput): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleOpenTimeChange = (fieldName: string, fieldValue: Moment | null) => {
    updateForm(fieldName, moment(fieldValue).format());
  };

  const handleClosedTimeChange = (fieldName: string, fieldValue: Moment | null) => {
    updateForm(fieldName, moment(fieldValue).format());
  };

  const handleSetFacilityTwentyFourHours = () => {
    updateForm(`operational_hours.${day}.is_open_24_hours`, !facilityTwentyFourHours);
  };

  const handleSetFacilityClosed = () => {
    updateForm(`operational_hours.${day}.is_closed_for_the_day`, !facilityClosed);
  };

  return (
    <Grid
      container
      spacing={2}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Grid item xs={1}>
        <Typography color={"black"} className={classes.storeHoursDay}>{day.substr(0, 3)}</Typography>
      </Grid>
      <Grid item xs={3} >
        <LocalizationProvider dateAdapter={AdapterMoment} >
          <TimePicker
            ampm
            data-testid={"open-time-picker"}
            renderInput={(params: TextFieldProps) => <TextField name={`operational_hours.${day}.open`} {...params} />}
            value={valueOpen}
            disabled={(facilityClosed || facilityTwentyFourHours)}
            onChange={(date: Moment | null) => handleOpenTimeChange(`operational_hours.${day}.open`, date)}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={1}>
        <Typography color={"black"} >to</Typography>
      </Grid>
      <Grid item xs={3}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <TimePicker
            ampm
            data-testid={"close-time-picker"}
            renderInput={(params: TextFieldProps) => (
              <TextField
                {...params}
                name={`operational_hours.${day}.close`}
                error={Boolean(formik.errors.operational_hours?.[day]?.close)}
            />
            )}
            disabled={(facilityClosed || facilityTwentyFourHours)}
            value={valueClosed}
            onChange={(date:Moment |null) => handleClosedTimeChange(`operational_hours.${day}.close`, date)}
            />
          <FormHelperText className={classes.errorText}>
            {formik.errors.operational_hours && formik.errors.operational_hours?.[day] &&
            formik.errors.operational_hours?.[day]?.close}
          </FormHelperText>
        </LocalizationProvider>
      </Grid>
      <Grid pl={2} item xs={2}>
        <PMSSwitch
          name={`${day}-24-hours`}
          label={"24 Hours"}
          typographyNoWrap
          smallText
          isChecked={facilityTwentyFourHours}
          value={facilityTwentyFourHours}
          changeHandler={() => handleSetFacilityTwentyFourHours()}
        />
      </Grid>
      <Grid item xs={2}>
        <PMSSwitch
          name={`${day}-closed`}
          label={"Closed"}
          smallText
          isChecked={facilityClosed}
          value={facilityClosed}
          changeHandler={() => handleSetFacilityClosed()}
        />
      </Grid>
    </Grid>
  );
};

export default FacilityHoursRow;
