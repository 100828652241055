import React, { ReactElement, useEffect } from "react";
import { AvailabileUnitsTableHeadCells } from "../../../../../models/AvailabileUnitsTableHeadCells";
import { GridSortDirection } from "@mui/x-data-grid";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Tooltip } from "@mui/material";
import useStyles from "./AvailableUnitsTableHead.styles";

const headCells: { id: keyof AvailabileUnitsTableHeadCells, label: string, tooltip: string }[] = [
  { id: "name", label: "Prod Type", tooltip: "Product Type" },
  { id: "unitSize", label: "Unit Size", tooltip: "Unit Size" },
  { id: "floor", label: "Floor", tooltip: "Floor" },
  { id: "price", label: "Unit Price", tooltip: "Unit Price" },
  { id: "available_units", label: "Vacant", tooltip: "Vacant" },
  { id: "total_units", label: "Total", tooltip: "Total Units" }
];

export interface AvailableUnitsTableHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof AvailabileUnitsTableHeadCells) => void;
  order: GridSortDirection;
  orderBy: string;
}

const AvailableUnitsTableHead: React.FC<AvailableUnitsTableHeadProps> = ({
  onRequestSort,
  order,
  orderBy
}: AvailableUnitsTableHeadProps): ReactElement => {
  const createSortHandler = (property: keyof AvailabileUnitsTableHeadCells) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  const { classes } = useStyles();

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={order || false}
            >
            <TableSortLabel
              data-testid={"available-units-table-sort"}
              active={((orderBy === headCell.id) && (headCell.id !== "floor"))}
              direction={order || "asc"}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={headCell.id === "floor"}
              >
              <Tooltip title={headCell.tooltip}>
                <span>
                  {headCell.label}
                </span>
              </Tooltip>
              {orderBy === headCell.id
                ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                  )
                : null}
            </TableSortLabel>
          </TableCell>
        )
        )}
      </TableRow>
    </TableHead>
  );
};

export default AvailableUnitsTableHead;
