import { APIFilter } from "../../../models/responses/APIFilter";
import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles<{ filter: APIFilter | "" }>({ label: "ManageDeals" })((theme, { filter }) => ({
  buttonText: {
    textTransform: "capitalize"
  },
  activeFilter: {
    color: filter === "" ? theme.palette.text.primary : theme.palette.text.disabled
  },
  leadsFilter: {
    color: Object.values(filter).includes(1) ? theme.palette.text.primary : theme.palette.text.disabled
  },
  reservationsFilter: {
    color: Object.values(filter).includes(2) ? theme.palette.text.primary : theme.palette.text.disabled
  },
  expiredFilter: {
    color: Object.keys(filter).length && Object.keys(filter)[0].includes("include_expired")
      ? theme.palette.text.primary
      : theme.palette.text.disabled
  },
  onlyCancelledFilter: {
    color: Object.keys(filter).length && Object.keys(filter)[0].includes("only_cancelled")
      ? theme.palette.text.primary
      : theme.palette.text.disabled
  }
}));

export default useStyles;
