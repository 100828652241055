import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { Tooltip, Typography } from "@mui/material";

import React from "react";
import moment from "moment";
import { LedgerViewItem } from "../../LedgerViewContext";

export const parseNumber = (number: number, isCredit = false) => {
  const formattedNum = number.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  });

  return number < 0 || isCredit ? `($${Math.abs(number).toFixed(2)})` : formattedNum;
};

export const ManageLedgerItemsTableColumns: GridColDef[] = [
  {
    field: "start_date",
    renderHeader: () => (
      <Tooltip title={"Date"}>
        <Typography>Date</Typography>
      </Tooltip>
    ),
    minWidth: 150,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.start_date
  },
  {
    field: "end_date",
    renderHeader: () => (
      <Tooltip title={"End Date"}>
        <Typography>End Date</Typography>
      </Tooltip>
    ),
    minWidth: 150,
    flex: 1,
    resizable: true,
    valueGetter: (params: GridValueGetterParams) => params.row.end_date
  },
  {
    field: "description",
    renderHeader: () => (
      <Tooltip title={"Description"}>
        <Typography>Description</Typography>
      </Tooltip>
    ),
    minWidth: 350,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params.row.description}>
        <Typography variant={"inherit"} fontSize={"0.9rem"}>
          {params.row.description}
        </Typography>
      </Tooltip>
    )
  },
  {
    field: "check_cc_no",
    renderHeader: () => (
      <Tooltip title={"Check/CC #"}>
        <Typography>Check/CC #</Typography>
      </Tooltip>
    ),
    minWidth: 150,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row?.method_last_4 || "-"
  },
  {
    field: "reciept",
    renderHeader: () => (
      <Tooltip title={"Receipt"}>
        <Typography>Receipt</Typography>
      </Tooltip>
    ),
    minWidth: 150,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row?.receipt_id || "-"
  },
  {
    field: "invoice",
    renderHeader: () => (
      <Tooltip title={"Invoice"}>
        <Typography>Invoice</Typography>
      </Tooltip>
    ),
    minWidth: 150,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => "-"
  },
  {
    field: "refund_amount",
    renderHeader: () => (
      <Tooltip title={"Applied Refund"}>
        <Typography>Applied Refund</Typography>
      </Tooltip>
    ),
    minWidth: 150,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) =>
      Number(params.row.refund_amount) ? `${parseNumber(Number(params.row.refund_amount))}` : "-"
  },
  {
    field: "credit_amount",
    renderHeader: () => (
      <Tooltip title={"Issued Credit"}>
        <Typography>Issued Credit</Typography>
      </Tooltip>
    ),
    minWidth: 150,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) =>
      Number(params.row.credit_amount) ? `${parseNumber(Number(params.row.credit_amount), true)}` : "-"
  },
  {
    field: "payment_amount",
    renderHeader: () => (
      <Tooltip title={"Payment"}>
        <Typography>Payment</Typography>
      </Tooltip>
    ),
    minWidth: 150,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) =>
      Number(params.row.payment_amount) ? `${parseNumber(Number(params.row.payment_amount))}` : "-"
  },
  {
    field: "charge_amount",
    renderHeader: () => (
      <Tooltip title={"Charge"}>
        <Typography>Charge</Typography>
      </Tooltip>
    ),
    minWidth: 150,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) =>
      Number(params.row.charge_amount) ? `${parseNumber(Number(params.row.charge_amount))}` : "-"
  },
  {
    field: "tax_amount",
    renderHeader: () => (
      <Tooltip title={"Tax"}>
        <Typography>Tax</Typography>
      </Tooltip>
    ),
    minWidth: 150,
    flex: 1,
    valueGetter: ({ row }: { row: LedgerViewItem }) => {
      if (Number(row.tax_amount)) {
        return `${parseNumber(Number(row.tax_amount), row.description.toLowerCase().includes("credit"))}`;
      }
      return "-";
    }
  }
];
