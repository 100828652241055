import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("E-mail Address must be valid").when("useExistingEmailAddress", {
    is: false,
    then: Yup.string().email("E-mail Address must be valid").required("E-mail Address is required")
  })
});

export default validationSchema;
