import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "SelectMoveOutUnitForTransferUnit" })((theme) => ({
  root: {
    border: 0,
    "& .MuiDataGrid-columnSeparator": {
      display: "none"
    },
    "& .MuiDataGrid-colCellTitle": {
      overflow: "unset"
    },
    "& .MuiDataGrid-footerContainer": {
      display: "none"
    },
    margin: `${theme.spacing(2)} 0 ${theme.spacing(3)} 0`
  }
}));

export default useStyles;
