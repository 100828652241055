import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { APIFilter } from "../../../models/responses/APIFilter";
import { APIListResponse } from "../../../models/responses/APIListResponse";
import { Deal } from "../../../models/Deal";
import { DealsSliceState } from "./DealsSliceState";
import { RootState } from "src/store/rootStore";
import { getAllActiveDeals } from "../../thunks/deals/getAllActive/getAllActiveDeals";
import { getAllDeals } from "../../thunks/deals/getAll/getAllDeals";

export const initialState: DealsSliceState = {
  deals: JSON.parse(sessionStorage.getItem("deals") as string) ?? [],
  dealsFilter: JSON.parse(sessionStorage.getItem("dealsFilter") as string) ?? "",
  dealsLoading: false,
  dealsError: ""
};

export const dealsSlice = createSlice({
  name: "deals",
  initialState,
  reducers: {
    setDeals: (state, action: PayloadAction<APIListResponse<Deal[]>>) => {
      state.deals = action.payload.data;
      sessionStorage.setItem("deals", JSON.stringify(action.payload.data));
    },
    setDealsFilter: (state, action: PayloadAction<APIFilter | "">) => {
      state.dealsFilter = action.payload;
      sessionStorage.setItem("dealsFilter", JSON.stringify(action.payload));
    },
    resetDealsSlice: (state) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("dealsError");
      sessionStorage.removeItem("deals");
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAllDeals.pending, (state) => {
        state.dealsLoading = true;
      })
      .addCase(getAllDeals.fulfilled, (state, action: PayloadAction<APIListResponse<Deal[]>>) => {
        state.dealsLoading = false;
        state.deals = action.payload.data;
        sessionStorage.setItem("deals", JSON.stringify(action.payload.data));
      })
      .addCase(getAllDeals.rejected, (state, action) => {
        state.dealsLoading = false;
        state.dealsError = action.payload as string;
      })
      .addCase(getAllActiveDeals.pending, (state) => {
        state.dealsLoading = true;
      })
      .addCase(getAllActiveDeals.fulfilled, (state, action: PayloadAction<APIListResponse<Deal[]>>) => {
        state.dealsLoading = false;
        state.deals = action.payload.data;
        sessionStorage.setItem("deals", JSON.stringify(action.payload.data));
      })
      .addCase(getAllActiveDeals.rejected, (state, action) => {
        state.dealsLoading = false;
        state.dealsError = action.payload as string;
      });
  }
});

export const selectDeals = (state: RootState) => state.deals.deals;
export const selectDealsFilter = (state: RootState) => state.deals.dealsFilter;
export const selectDealsLoading = (state: RootState) => state.deals.dealsLoading;
export const selectDealsError = (state: RootState) => state.deals.dealsError;

export const { setDeals, setDealsFilter, resetDealsSlice } = dealsSlice.actions;

export default dealsSlice.reducer;
