import { PMSPagination, PMSPaginationValues } from "src/hooks/usePagination";
import moment, { Moment } from "moment";

import { APIListResponse } from "src/models/responses/APIListResponse";
import { Auction } from "src/models/Auction";
import api from "../api";
import { getPaginationParams } from "src/utils/paginationParams/getPaginationParams";
import { today } from "src/utils/__dateAndTimeUtils__/today";

const auctions = api.injectEndpoints({
  endpoints: (builder) => ({
    getAuctions: builder.query<
      APIListResponse<Auction[]>,
      { facilityId: string | number; pagination: PMSPaginationValues; filter: "upcoming" | "completed"; search: string }
    >({
      providesTags: ["Auctions"],
      query: ({ facilityId, pagination, filter, search }) => {
        const params = getPaginationParams(pagination);
        params.append("with[]", "ledger.unit");
        params.append("with[]", "ledger.occupant");
        params.append("filter[auction_date]", filter === "upcoming" ? `gte:${today}` : `lt:${today}`);
        params.append("searchValue", search);

        return {
          url: `api/v1/facilities/${facilityId}/auctions`,
          params: params
        };
      }
    }),
    getAuction: builder.query<Auction, { auctionId: string; facilityId: string }>({
      query: ({ auctionId, facilityId }) => {
        const params = new URLSearchParams();
        params.append("with[]", "ledger.unit");
        params.append("with[]", "ledger.occupant");
        return {
          url: `api/v1/facilities/${facilityId}/auctions/${auctionId}`,
          params: params
        };
      },
      providesTags: ["Auctions"]
    }),
    flagForAuction: builder.mutation<
      void,
      {
        facilityId: number;
        payload: number[];
        auctionDate: Moment;
        auctionTime: Moment;
        unflag?: boolean;
        removalReason?: number;
      }
    >({
      invalidatesTags: ["Auctions"],
      query: ({ facilityId, payload, auctionDate, auctionTime, unflag, removalReason }) => ({
        url: `api/v1/facilities/${facilityId}/auctions/flag-for-auction`,
        method: "POST",
        body: {
          payload,
          auction_time: moment(auctionTime).utc().format("HH:mmZ"),
          auction_date: moment(auctionDate).utc().format("YYYY-MM-DD"),
          ...(unflag && {
            removal_reason: removalReason
          })
        },
        ...(unflag && {
          params: {
            unflag
          }
        })
      })
    }),
    updateAuction: builder.mutation<
      Auction,
      { auctionId: number; facilityId: number; auctionDate: Moment; auctionTime: Moment }
    >({
      query: ({ auctionId, facilityId, auctionDate, auctionTime }) => ({
        url: `api/v1/facilities/${facilityId}/auctions/${auctionId}`,
        body: {
          auction_date: moment(auctionDate).utc().format("YYYY-MM-DD"),
          auction_time: moment(auctionTime).utc().format("HH:mmZ")
        },
        method: "POST"
      }),
      invalidatesTags: ["Auctions"]
    })
  })
});

export const {
  useGetAuctionsQuery,
  useLazyGetAuctionsQuery,
  useFlagForAuctionMutation,
  useGetAuctionQuery,
  useUpdateAuctionMutation
} = auctions;
