import { makeStyles } from "../../makeStyles";

const useStyles = makeStyles<{ isCalling: boolean }>({ label: "Deal Follow Up Modal" })((theme, { isCalling }) => ({
  occupantInformation: {
    padding: theme.spacing(2),
    width: "100%",
    height: "200px",
    backgroundColor: theme.palette.background.default
  },
  dealInformation: {
    padding: `${theme.spacing(0)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`,
    width: "100%",
    height: "200px"
  },
  dealInformationTitle: {
    color: theme.palette.primary.contrastText,
    letterSpacing: "1px",
    textTransform: "uppercase",
    whiteSpace: "nowrap"
  },
  occupantName: {
    color: theme.palette.primary.contrastText
  },
  icon: {
    color: theme.palette.common.white
  },
  dialog: {
    width: "1100px",
    height: "fit-content"
  },
  radioGroup: {
    width: "100%",
    gap: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "start"
  },
  text: {},
  divider: {
    background: theme.palette.text.disabled
  },
  callNowButton: {
    backgroundColor: isCalling ? theme.palette.error.main : theme.palette.primary.contrastText,
    color: theme.palette.common.white
  },
  saveButton: {
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.contrastText
  },
  bodyHeaderDivider: {
    background: theme.palette.primary.contrastText,
    width: "100%"
  },
  saveAndCompleteButton: {
    textTransform: "uppercase",
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main
    }
  },
  phoneIcon: {
    padding: 0,
    marginLeft: theme.spacing(1)
  },
  notesContainer: {
    overflowY: "auto",
    height: "150px"
  },
  navigateButton: {
    padding: theme.spacing(1),
    borderRadius: "50%",
    color: "white",
    backgroundColor: theme.palette.primary.contrastText
  },
  navigateButtonDisabled: {
    backgroundColor: theme.palette.text.disabled
  }
}));

export default useStyles;
