import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import clsx from "clsx";
import React, { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { InsurancePlanValues } from "src/models/formikInputValues/InsurancePlanValues";
import { selectDocumentTemplates } from "src/store/reducers/documentsSlice/documentsSlice";
import {
  resetInsurancePlanInformationSlice,
  selectPlanInformationLoading
} from "src/store/reducers/insurancePlanInformationSlice/insurancePlanInformationSlice";
import { selectRevenueClasses } from "src/store/reducers/revenueClassesSlice/revenueClassesSlice";
import {
  getAllPaginatedDocumentTemplates
} from "src/store/thunks/documentTemplates/getAll/getAllPaginatedDocumentTemplates";
import {
  getAllInsurancePlansForFacility
} from "src/store/thunks/insurancePlans/getAllForFacility/getAllInsurancePlansForFacility";
import { getAllRevenueClasses } from "src/store/thunks/revenueClass/getAll/getAllRevenueClasses";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import ItemManagementLayout from "../../../layouts/ItemManagement/ItemManagementLayout";
import { Breadcrumb } from "../../../models/Breadcrumb";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectSelectedAdminFacility
} from "../../../store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import InsurancePlanForm from "../InsurancePlanForm/InsurancePlanForm";
import useStyles from "./CreateInsurancePlan.styles";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";
import { TppPlanPayload, useCreateTppPlanMutation } from "src/api/endpoints/insurancePlan";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Fees & Charges"
  },
  {
    name: "Create Insurance Plan",
    bold: true
  }
];

const CreateInsurancePlan: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selectedAdminFacility = useAppSelector(selectSelectedAdminFacility);
  const revenueClasses = useAppSelector(selectRevenueClasses);
  const documentTemplates = useAppSelector(selectDocumentTemplates);
  const insurancePlanInformationLoading = useAppSelector(selectPlanInformationLoading);
  const permissions = useAppSelector(selectFacilityPermissions);
  const insurancePlansPermissions = createPermissions(permissions, "insurance_plans");

  const [createTppPlan] = useCreateTppPlanMutation();

  useEffect(() => {
    dispatch(getAllRevenueClasses(selectedAdminFacility?.id));
    dispatch(getAllPaginatedDocumentTemplates({ category: 5 }));

    /* istanbul ignore next */ // cannot test this w/ enzyme */
    return () => {
      dispatch(resetInsurancePlanInformationSlice());
    };
  }, []);

  const footerButtons = (
    <>
      <Button
        variant={"text"}
        className={clsx(classes.cancelButton, classes.buttonBase)}
        size={"large"}
        component={NavLink}
        to={"/insurance-plans"}
      >
        Cancel
      </Button>
      <LoadingButton
        form={"insurance-plan-form"}
        variant={"contained"}
        className={clsx(classes.saveButton, classes.buttonBase)}
        size={"large"}
        type={"submit"}
        loading={insurancePlanInformationLoading}
        disabled={insurancePlanInformationLoading}
      >
        Save & Continue
      </LoadingButton>
    </>
  );

  const handleSubmit = async(values: InsurancePlanValues) => {
    if (values.document_template_id === 0) {
      values.document_template_id = null;
    }

    const payloadCreateTppPlan: TppPlanPayload = {
      ...values,
      facility_id: selectedAdminFacility?.id!
    };

    createTppPlan(payloadCreateTppPlan).then((data) => {
      if ("error" in data) {
        return dispatch(showSnackbar({ message: "Error creating Insurance Plan", variant: "error" }));
      }

      if ("data" in data) {
        dispatch(showSnackbar({
          message: "Insurance Plan created successfully",
          variant: "success"
        }));

        dispatch(getAllInsurancePlansForFacility(selectedAdminFacility!.id));
        navigate("/insurance-plans");
      }
    });
  };

  const pageHeader = <PageHeader title={"Create Insurance Plan"} breadcrumbs={breadcrumbs}/>;

  return (
    <ViewWrapper pageHeader={pageHeader}>
      {
        insurancePlansPermissions.create
          ? (<ItemManagementLayout
              title={`Add New Insurance Plan for ${selectedAdminFacility?.name}`}
              footerButtons={footerButtons}
              loading={false}
            >
            <InsurancePlanForm
              revenueClasses={revenueClasses}
              documentTemplates={documentTemplates}
              submitHandler={handleSubmit}
            />
          </ItemManagementLayout>)
          : <h1>You do not have permission to view this page.</h1>
      }
    </ViewWrapper>
  );
};

export default CreateInsurancePlan;
