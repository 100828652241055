import { Divider, Grid } from "@mui/material";
import LineItem, { LineItemProps } from "../LineItem/LineItem";
import React, { FC, ReactNode } from "react";
import useStyles from "./PaymentSummary.styles";

interface SummaryProps {
  lineItems: LineItemProps[];
  totals: { name: string | ReactNode; amount: string, subLineItem?: boolean }[];
  footer?: ReactNode;
}

const PaymentSummary: FC<SummaryProps> = ({ lineItems, totals, footer }: SummaryProps) => {
  const { classes } = useStyles();

  const lineItemsDisplay = lineItems.map((lineItem, index) => {
    const key = `li-${index}`;

    return <LineItem key={key} data-testid={`line-item-${key}`} {...lineItem} />;
  });

  const totalItems = totals.map((total, index) => {
    const key = `to-${index}`;

    return (
      <LineItem key={key} data-testid={key} name={total.name}
        amount={total.amount} subLineItem={total.subLineItem}/>
    );
  });

  return (
    <Grid container direction={"column"} px={2}>
      {lineItemsDisplay}
      {totals.length > 0 ? <Divider data-testid={"summary-divider"} className={classes.divider} /> : null}
      {totalItems}
      {footer}
    </Grid>
  );
};

export default PaymentSummary;
