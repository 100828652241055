import { Button, Grid, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { AddCircle } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import useStyles from "./EmptyTable.styles";

export interface EmptyTableProps {
  collectionNotFound?: string;
  value?: string;
  addLink?: string;
  addAction?: () => void;
}

// eslint-disable-next-line max-len
const EmptyTable: React.FC<EmptyTableProps> = ({
  collectionNotFound,
  value,
  addLink,
  addAction
}: EmptyTableProps): ReactElement => {
  const { classes } = useStyles();

  return (
    <Grid
      data-testid={"no-existing-rows"}
      className={clsx(classes.root, addLink || addAction ? classes.noResultsOverlay : classes.noResultsOverlayNoLink)}
      container
      direction={"column"}
      alignContent={"center"}
      alignItems={"center"}
    >
      {collectionNotFound && (
        <Grid item xs={12} pt={1}>
          <Typography data-testid={"no-existing-rows-title"}>No {collectionNotFound} found</Typography>
        </Grid>
      )}
      {(addLink || addAction) && (
        <Grid item xs={12}>
          <Button
            data-testid={"no-existing-rows-add-button"}
            className={classes.addButton}
            variant={"outlined"}
            startIcon={<AddCircle />}
            component={addLink ? NavLink : Button}
            to={addLink && addLink}
            onClick={addAction && addAction}
          >
            Add {value}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default EmptyTable;
