import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from "@mui/material";
import { ChevronLeft, ChevronRight, Close, Edit, Phone, Remove } from "@mui/icons-material";
import { PostNotePayload, usePostNoteMutation } from "src/api/endpoints/notes";
import React, { useEffect } from "react";
import { SoftphoneDisplayMode, SoftphoneStatus } from "src/store/reducers/appSoftphoneSlice/appSoftphoneSliceState";
import dealFollowUpModalValidation, { DealFollowUpModalValidationType } from "./DealFollowUpModalValidation";
import {
  selectDealNotification,
  setLastInteractionTime,
  setSelectedDealNotification,
  setStartedFollowUp
} from "src/store/reducers/dealNotificationSlice/dealNotificationSlice";
import {
  selectIsDisplayed,
  selectIsInteractable,
  selectStatus,
  setIsDisplayed,
  setStatus
} from "src/store/reducers/appSoftphoneSlice/appSoftphoneSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  useGetDealNotificationsQuery,
  useMarkDealNotificationAddressedMutation,
  useSaveMetricsMutation
} from "src/api/endpoints/deals";
import AppNote from "../note/Note";
import { Deal } from "src/models/Deal";
import Draggable from "react-draggable";
import Form from "../form/Form";
import NoteType from "src/enums/NoteType";
import clsx from "clsx";
import formatPhone from "src/utils/formatPhone/formatPhone";
import { formattedAmount } from "src/utils/formattedAmount/formattedAmount";
import moment from "moment";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { useNavigate } from "react-router";
import { useSoftphone } from "src/hooks/useSoftphone";
import useStyles from "./DealFollowUpModal.styles";

const DealSelector = () => {
  const { classes } = useStyles({ isCalling: false });
  const selectedFacility = useAppSelector(selectSelectedFacility);

  const { data: deals } = useGetDealNotificationsQuery(
    { facilityId: selectedFacility?.id },
    { skip: !selectedFacility }
  );

  const { selectedDeal } = useAppSelector(selectDealNotification);
  const dispatch = useAppDispatch();

  const currentDealIndex = deals?.findIndex((deal: Deal) => deal.id === selectedDeal?.id);

  return (
    <Grid container item mt={10}
      alignItems={"center"} justifyContent={"center"} gap={2}>
      <IconButton
        onClick={() => deals && dispatch(setSelectedDealNotification(deals[currentDealIndex! - 1]))}
        disabled={currentDealIndex! - 1 < 0}
      >
        <ChevronLeft
          className={clsx(classes.navigateButton, {
            [classes.navigateButtonDisabled]: currentDealIndex! - 1 < 0
          })}
        />
      </IconButton>
      {currentDealIndex! + 1} of {deals?.length}
      <IconButton
        onClick={() => deals && dispatch(setSelectedDealNotification(deals[currentDealIndex! + 1]))}
        disabled={currentDealIndex! + 1 >= deals?.length!}
      >
        <ChevronRight
          className={clsx(classes.navigateButton, {
            [classes.navigateButtonDisabled]: currentDealIndex! + 1 === deals?.length
          })}
        />
      </IconButton>
    </Grid>
  );
};

const DraggableModal = () => {
  const displayMode = useAppSelector(selectIsDisplayed);
  const phoneIsInteractable = useAppSelector(selectIsInteractable);
  const dispatch = useAppDispatch();
  const status = useAppSelector(selectStatus);
  const { selectedDeal } = useAppSelector(selectDealNotification);
  const { call, endCall } = useSoftphone();
  const [saveMetrics] = useSaveMetricsMutation();
  const navigate = useNavigate();

  const isCalling = status === SoftphoneStatus.Calling || status === SoftphoneStatus.Connected;
  const { classes } = useStyles({ isCalling });

  const handleCallButtonClick = () => {
    dispatch(setLastInteractionTime(moment().unix()));
    dispatch(setStartedFollowUp(selectedDeal!.id));
    if (isCalling) {
      endCall();
      dispatch(setStatus(SoftphoneStatus.Ready));
      return;
    }

    dispatch(setStatus(SoftphoneStatus.Calling));
    call(selectedDeal?.phone!);

    if (selectedDeal && !selectedDeal?.deal_follow_up?.first_call_timestamp) {
      saveMetrics({
        deals: [{
          dealId: selectedDeal!.id,
          callTimestamp: moment().toISOString()
        }],
        facilityId: selectedDeal?.facility_id!
      });
    }
  };

  const formatOccupantName = () => {
    if (selectedDeal?.middle_name) {
      return `${selectedDeal?.first_name} ${selectedDeal?.middle_name} ${selectedDeal?.last_name}`;
    }
    return `${selectedDeal?.first_name} ${selectedDeal?.last_name}`;
  };

  return (
    <Draggable handle={"#draggable-dialog-title"} cancel={"[class*=\"MuiDialogContent-root\"]"}>
      <Dialog
        open={displayMode === SoftphoneDisplayMode.DisplayFollowUp}
        hideBackdrop
        disableEnforceFocus
        onClose={() => {}}
        maxWidth={"lg"}
        className={classes.dialog}
        aria-describedby={"modal-description"}
        aria-labelledby={"draggable-dialog-title"}
        disableScrollLock
      >
        <DialogTitle>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            id={"draggable-dialog-title"}
            style={{ cursor: "move" }}
          >
            <Typography variant={"h6"} component={"h2"}>
              New Lead Follow-Up
            </Typography>
            <Grid>
              <IconButton
                onClick={() => {
                  dispatch(setIsDisplayed(SoftphoneDisplayMode.MinimizeFollowUp));
                  dispatch(setLastInteractionTime(moment().unix()));
                }}
              >
                <Remove />
              </IconButton>
              {!isCalling && (
                <IconButton
                  onClick={() => {
                    dispatch(setIsDisplayed(SoftphoneDisplayMode.Hide));
                    dispatch(setLastInteractionTime(moment().unix()));
                  }}
                >
                  <Close />
                </IconButton>
              )}
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
        </DialogTitle>

        <DialogContent>
          <Grid container mt={2}>
            <Grid item container xs={12}
              gap={1} sm={6} className={classes.occupantInformation}>
              <Typography variant={"h6"} fontWeight={"bold"} className={classes.occupantName}>
                {formatOccupantName()}
              </Typography>

              <Grid container item>
                <Grid container item xs={6}
                  sm={6} gap={1} flexDirection={"column"}>
                  <Typography variant={"body1"} fontWeight={"bold"}>
                    Primary Phone:
                  </Typography>
                  <Typography variant={"body1"} fontWeight={"bold"}>
                    Email:
                  </Typography>
                </Grid>
                <Grid container item xs={6}>
                  <Typography variant={"body1"} alignItems={"center"}>
                    {formatPhone(selectedDeal?.phone)}
                  </Typography>

                  <Box display={"flex"} minWidth={0} width={"100%"}>
                    <Tooltip title={selectedDeal?.email || ""}>
                      <Typography variant={"body1"} textOverflow={"ellipsis"} overflow={"hidden"}
                        minWidth={0} noWrap>
                        {selectedDeal?.email}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>

              <Button
                startIcon={<Phone />}
                fullWidth
                className={classes.callNowButton}
                disabled={!phoneIsInteractable}
                variant={"contained"}
                onClick={handleCallButtonClick}
              >
                {isCalling ? "End Call" : "Call Now"}
              </Button>
            </Grid>

            <Grid item container xs={12}
              gap={1} sm={6} className={classes.dealInformation}>
              <Grid display={"flex"} alignItems={"center"} width={"100%"}>
                <Grid item mr={"5px"}>
                  <Typography variant={"body1"} className={classes.dealInformationTitle}>
                    Deal Information
                  </Typography>
                </Grid>
                <Grid container>
                  <Divider className={classes.bodyHeaderDivider} />
                </Grid>
                <Grid>
                  <Button
                    startIcon={<Edit />}
                    onClick={() => {
                      dispatch(setIsDisplayed(SoftphoneDisplayMode.MinimizeFollowUp));
                      navigate(`/deals/${selectedDeal?.id}/edit`);
                    }}
                  >
                    Edit
                  </Button>
                </Grid>
              </Grid>

              <Grid container item>
                <Grid container item xs={6}
                  sm={6} gap={1} flexDirection={"column"}>
                  <Typography variant={"body1"} fontWeight={"bold"}>
                    Selected Unit:
                  </Typography>
                  <Typography variant={"body1"} fontWeight={"bold"}>
                    Unit Size:
                  </Typography>
                  <Typography variant={"body1"} fontWeight={"bold"}>
                    Quoted Price:
                  </Typography>
                  <Typography variant={"body1"} fontWeight={"bold"}>
                    Promotion Offered:
                  </Typography>
                  <Typography variant={"body1"} fontWeight={"bold"}>
                    Product Type:
                  </Typography>
                </Grid>
                <Grid container item xs={6}
                  sm={6} gap={1} flexDirection={"column"}>
                  <Typography variant={"body1"}>{selectedDeal?.unit?.unit_number ?? "N/A"}</Typography>
                  <Typography variant={"body1"}>
                    {selectedDeal?.unit ? `${selectedDeal?.unit?.width}' x ${selectedDeal?.unit?.length}'` : "N/A"}
                  </Typography>
                  <Typography variant={"body1"}>
                    {(selectedDeal?.quoted_price && formattedAmount("Fixed Amount", selectedDeal?.quoted_price)) ??
                      "N/A"}
                  </Typography>
                  <Box display={"flex"} minWidth={0} width={"100%"}>
                    <Tooltip title={selectedDeal?.promotion?.name ?? "N/A"}>
                      <Typography variant={"body1"} textOverflow={"ellipsis"} overflow={"hidden"}
                        minWidth={0} noWrap>
                        {selectedDeal?.promotion?.name ?? "N/A"}
                      </Typography>
                    </Tooltip>
                  </Box>{" "}
                  <Box display={"flex"} minWidth={0} width={"100%"}>
                    <Tooltip title={selectedDeal?.product_type?.name ?? "N/A"}>
                      <Typography variant={"body1"} textOverflow={"ellipsis"} overflow={"hidden"}
                        minWidth={0} noWrap>
                        {selectedDeal?.product_type?.name ?? "N/A"}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid display={"flex"} alignItems={"center"} width={"100%"}
            mt={2}>
            <Grid item mr={"5px"}>
              <Typography variant={"body1"} className={classes.dealInformationTitle}>
                Notes
              </Typography>
            </Grid>
            <Grid container>
              <Divider className={classes.bodyHeaderDivider} />
            </Grid>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Form.Text id={"description"} name={"description"} multiline
              rows={4} />
          </Grid>
          <Grid container rowGap={2} mt={2}
            className={classes.notesContainer}>
            {selectedDeal?.notes?.map((note, index) => (
              <AppNote key={index} {...note} />
            ))}
          </Grid>

          <Box display={"flex"} justifyContent={"flex-end"} mt={2}
            mb={4} gap={2}>
            <Button
              onClick={() => {
                dispatch(setLastInteractionTime(moment().unix()));
                dispatch(setIsDisplayed(SoftphoneDisplayMode.Hide));
                dispatch(setSelectedDealNotification(undefined));
              }}
              color={"error"}
            >
              Cancel
            </Button>
            <Form.Submit
              variant={"outlined"}
              className={classes.saveButton}
              label={"Snooze"}
              value={"SAVE"}
            />
            <Form.Submit
              variant={"contained"}
              disabled={!selectedDeal}
              className={classes.saveAndCompleteButton}
              label={"Save & Complete"}
              value={"SAVE_AND_COMPLETE"}
            />
          </Box>
          <DealSelector />
        </DialogContent>
      </Dialog>
    </Draggable>
  );
};

const DealFollowUpModal = () => {
  const { selectedDeal } = useAppSelector(selectDealNotification);
  const dispatch = useAppDispatch();

  const [postNote] = usePostNoteMutation();
  const { data: deals } = useGetDealNotificationsQuery(
    { facilityId: selectedDeal?.facility_id! },
    { skip: !selectedDeal }
  );
  const [markAddressed] = useMarkDealNotificationAddressedMutation();

  useEffect(
    () => deals?.forEach((d: Deal) => d.id === selectedDeal?.id && dispatch(setSelectedDealNotification(d))),
    [deals]
  );

  const saveNote = async(description: string) => {
    const payload = {
      notable_id: selectedDeal?.id,
      notable_type: NoteType.deal,
      description
    } as PostNotePayload;

    await postNote(payload);
  };

  return (
    <Form
      onSubmit={async(data: DealFollowUpModalValidationType, event) => {
        dispatch(setLastInteractionTime(moment().unix()));

        // @ts-ignore property exists
        const source = event?.nativeEvent.submitter.value;

        if (source === "SAVE") {
          await saveNote(data.description);
          return;
        }

        if (source === "SAVE_AND_COMPLETE") {
          await saveNote(data.description);
          await markAddressed({ deal: selectedDeal! });
          dispatch(setIsDisplayed(SoftphoneDisplayMode.Hide));
          dispatch(setSelectedDealNotification(undefined));
        }
      }}
      defaultValues={{ description: "" }}
      schema={dealFollowUpModalValidation}
      resetOnSubmit
    >
      <DraggableModal />
    </Form>
  );
};

export default DealFollowUpModal;
