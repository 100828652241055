import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "TransferUnitConfirmation" })((theme) => ({
  table: {
    border: "1px solid",
    borderColor: theme.palette.divider,
    borderRadius: "10px",
    "&:last-child td": {
      border: 0
    }
  },
  ctaButton: {
    backgroundColor: theme.palette.primary.contrastText,
    textTransform: "capitalize",
    fontWeight: 300,
    color: theme.palette.common.white
  },
  button: {
    textTransform: "capitalize",
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.body2.fontSize
  },
  modal: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
    maxWidth: "35%",
    minHeight: "10rem",
    borderRadius: "1rem"
  },
  modalContainer: {
    borderRadius: "1rem",
    padding: theme.spacing(4)
  },
  textButton: { color: theme.palette.primary.contrastText }
}));

export default useStyles;
