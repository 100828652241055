import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Facility } from "src/models/Facility";
import FacilitySelector from "src/components/navigation/FacilitySelector/FacilitySelector";
import { Grid } from "@mui/material";
import { getAutomations } from "src/store/thunks/automations/getAll/getAutomations";
import {
  selectDelinquencyAutomations,
  selectDelinquencyAutomationsFilter
} from "src/store/reducers/delinquencyAutomationsSlice/delinquencyAutomationsSlice";
import {
  selectSelectedAdminFacility,
  setSelectedAdminFacility
} from "src/store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";

interface DelinquencyAutomationsTitleProps{}

const DelinquencyAutomationsTitle: FC<DelinquencyAutomationsTitleProps> = () => {
  const dispatch = useAppDispatch();
  const automationsFilter = useAppSelector(selectDelinquencyAutomationsFilter);
  const selectedAdminFacility = useAppSelector(selectSelectedAdminFacility);
  const automations = useAppSelector(selectDelinquencyAutomations);

  const handleOnFacilityChange = async(selectedFacility: Facility) => {
    await dispatch(setSelectedAdminFacility(selectedFacility));
    dispatch(getAutomations({ facilityId: selectedFacility.id, is_active: automationsFilter }));
  };

  useEffect(() => {
    if (selectedAdminFacility && automations.length === 0) {
      dispatch(getAutomations({ facilityId: selectedAdminFacility.id, is_active: automationsFilter }));
    }
  }, [selectedAdminFacility, automations]);

  return (
    <Grid item container alignItems={"center"}>
      <Grid item>Automations for:</Grid>
      <Grid item ml={2}>
        <FacilitySelector
          navBar={false}
          onFacilityChange={handleOnFacilityChange}
        />
      </Grid>
    </Grid>
  );
};

export default DelinquencyAutomationsTitle;
