import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const exportAskingRateChangesForOperator2 = createAsyncThunk(
  "exportAskingRateChangesForOperator2",
  (operatorId: number, { dispatch, rejectWithValue }) => {
    return axios.get(`/api/v1/operators/${operatorId}/asking-rate-changes/export2`)
      .then(response => {
        /*  Direct download was timing out, so the API call above will now queue it for background processing.
        const blob = new Blob([response.data], { type: "text/csv" });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = downloadUrl;
        link.setAttribute("download", `operator_${operatorId}_asking_rate_group_change_template.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        */
        dispatch(showSnackbar({
          message: "Asking Rate Group export has been queued for processing in the background. " +
            "It takes about 5 minutes.  The CSV export will be emailed to you.",
          variant: "success"
        }));
        return response.data;
      })
      .catch(() => {
        const errorMessage = "There was an error exporting the Asking Rate Group Changes for this Operator.";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
