import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getAllFacilitySettings = createAsyncThunk(
  "getAllFacilitySettings",
  (facilityId: string, { dispatch, rejectWithValue }) => {
    return axios.get(`/api/v1/facilities/${facilityId}/settings`)
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = "There was an error getting the Facility Settings";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
