import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import useStyles from "./AppSoftphone.styles";
import {
  selectCallDuration,
  selectInputNumber,
  selectIsDisplayed,
  selectStatus,
  setCallDuration,
  setInputNumber,
  setIsDisplayed,
  setStatus,
  updateCallDuration
} from "../../store/reducers/appSoftphoneSlice/appSoftphoneSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectSelectedFacility } from "../../store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import Draggable from "react-draggable";
import { Backspace, Close, Remove } from "@mui/icons-material";
import { formatCallDuration } from "src/utils/formatPhone/formatCallDuration";
import { useSoftphone } from "src/hooks/useSoftphone";
import { SoftphoneDisplayMode, SoftphoneStatus } from "src/store/reducers/appSoftphoneSlice/appSoftphoneSliceState";

interface AppSoftphoneProps {}

const AppSoftphone: React.FC<AppSoftphoneProps> = () => {
  const dispatch = useAppDispatch();
  const status = useAppSelector(selectStatus);
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const isDisplayed = useAppSelector(selectIsDisplayed);
  const [timer, setTimer] = useState<number>(0);
  const inputNumber = useAppSelector(selectInputNumber);
  const callDuration = useAppSelector(selectCallDuration);
  const { handleFacilityChange, call, endCall } = useSoftphone();

  useEffect(() => {
    handleFacilityChange(selectedFacility);
  }, [selectedFacility]);

  useEffect(() => {
    if (status === SoftphoneStatus.Connected) {
      setTimer(
        setInterval(() => {
          dispatch(updateCallDuration());
        }, 1000) as unknown as number
      );
    } else {
      dispatch(setCallDuration(0));
      clearInterval(timer);
    }
  }, [status]);

  const isCalling = useMemo(() => {
    return status === SoftphoneStatus.Calling || status === SoftphoneStatus.Connected;
  }, [status]);

  const handleClose = (event: Object, reason: string): void => {
    //disable backdrop click
    if (reason && reason === "backdropClick") {
      return;
    }
    dispatch(setIsDisplayed(SoftphoneDisplayMode.Hide));
  };

  const formatPhoneNumber = (phoneNumber: string) => {
    const cleanNumber = phoneNumber.replace(/[^\d*#]/g, "");
    const match = cleanNumber.match(/^(\d{3})(\d{3})(\d{4})([*#]?)$/);

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}${match[4]}`;
    }

    return phoneNumber;
  };

  const handleButtonClick = (number: any) => {
    if (inputNumber.length < 10 || ["#", "*"].includes(number)) {
      dispatch(setInputNumber(inputNumber + number));
    }
  };

  const handleCallButtonClick = () => {
    if (isCalling) {
      endCall();
      dispatch(setStatus(SoftphoneStatus.Ready));
      return;
    }

    dispatch(setStatus(SoftphoneStatus.Calling));
    call(inputNumber);
  };

  const { classes } = useStyles({ isCalling });

  return (
    <Draggable handle={"#draggable-dialog-title"} cancel={"[class*=\"MuiDialogContent-root\"]"}>
      <Dialog
        open={isDisplayed === "DISPLAY"}
        onClose={handleClose}
        hideBackdrop
        disableEnforceFocus
        className={classes.dialog}
        aria-labelledby={"draggable-dialog-title"}
      >
        <DialogTitle id={"draggable-dialog-title"} className={classes.header}>
          <Grid display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            <Typography variant={"h5"} component={"h1"}>
              Outbound Call
            </Typography>
            <Grid>
              <IconButton onClick={() => dispatch(setIsDisplayed(SoftphoneDisplayMode.Minimize))}>
                <Remove className={classes.icon} />
              </IconButton>
              {!isCalling && (
                <IconButton
                  onClick={() => {
                    dispatch(setIsDisplayed(SoftphoneDisplayMode.Hide));
                    dispatch(setInputNumber(""));
                  }}
                >
                  <Close className={classes.icon} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid height={"30px"} container mb={1}
            mt={2}>
            <Grid item xs={12}>
              <Typography variant={"h4"} textAlign={"center"}>
                {formatPhoneNumber(inputNumber)}
              </Typography>
            </Grid>

            {inputNumber && (
              <Grid position={"absolute"} right={10}>
                <IconButton disabled={isCalling} onClick={() => dispatch(setInputNumber(inputNumber.slice(0, -1)))}>
                  <Backspace />
                </IconButton>
              </Grid>
            )}
          </Grid>

          <Typography variant={"subtitle1"} gutterBottom textAlign={"center"}
            height={"10px"}>
            {status === SoftphoneStatus.Calling && "Calling"}
            {!!callDuration && formatCallDuration(callDuration)}
          </Typography>
          <Grid container spacing={4} rowSpacing={6}
            mt={2} height={"100%"}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, "*", 0, "#"].map((number) => (
              <Grid item xs={4} key={number}
                textAlign={"center"}>
                <Button onClick={() => handleButtonClick(number.toString())}>
                  <Typography variant={"h6"}>{number}</Typography>
                </Button>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button
                variant={"contained"}
                className={classes.button}
                fullWidth
                onClick={() => handleCallButtonClick()}
              >
                {isCalling ? "End Call" : "Call now"}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Draggable>
  );
};

export default AppSoftphone;
