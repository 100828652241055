import React, { ChangeEvent, useState } from "react";
import { Box, Button, Grid, IconButton, InputLabel, Modal, Paper, TextField, Typography } from "@mui/material";
import useStyles from "./SendEmailModal.styles";
import { Close } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  selectMoveInConfirmation,
  selectSendEmailModalOpened, setLeasePackageModalClosed,
  setSendEmailModalOpened
} from "src/store/reducers/moveInSlice/moveInSlice";
import { emailReceipt } from "src/store/thunks/receipt/emailReceipt/emailReceipt";
import PMSCheckbox from "src/components/ui/PMSCheckbox/PMSCheckbox";
import { selectMoveOutConfirmation } from "src/store/reducers/moveOutSlice/moveOutSlice";

const SendEmailModal = ({ setCompletedModalOpen, isFromMoveOut = false }:
  { setCompletedModalOpen?: React.Dispatch<React.SetStateAction<boolean>>, isFromMoveOut?: boolean }) => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const moveIn = useAppSelector(selectMoveInConfirmation);
  const moveOut = useAppSelector(selectMoveOutConfirmation);
  const [email, setEmail] = useState("");
  const modalOpened = useAppSelector(selectSendEmailModalOpened);
  const [emailSent, setEmailSent] = useState(false);
  const [check, setCheck] = useState(false);
  const currentEmail = isFromMoveOut ? moveOut?.occupant?.email : moveIn?.occupant?.email;

  const handleClose = () => {
    dispatch(setLeasePackageModalClosed());
    if (setCompletedModalOpen) {
      setCompletedModalOpen(false);
    }
    dispatch(setSendEmailModalOpened());
  };

  const handleSubmit = () => {
    setEmailSent(true);
    dispatch(emailReceipt(email)).then((resp) => {
      if (!resp.type.includes("rejected")) {
        handleClose();
        if (setCompletedModalOpen) {
          setCompletedModalOpen(true);
        }
      }
    });
  };

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheck(true);
      setEmail(currentEmail || "");
    } else {
      setCheck(false);
      setEmail("");
    }
  };

  return (
    <Modal
      open={modalOpened}
      onClose={handleClose}
      aria-labelledby={"Modal Component"}
      aria-describedby={"A Modal component."}
      className={classes.modal}
      data-testid={"modal-cancel-deal"}
  >
      <Grid
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        spacing={2}
        className={classes.container}
        pb={2}>

        <Box display={"flex"} justifyContent={"space-between"} className={classes.header}>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography variant={"h6"}>Send Email</Typography>
          </Box>
          <Box>
            <IconButton onClick={handleClose}><Close /></IconButton>
          </Box>
        </Box>

        <Box width={"100%"} mt={1}>
          {currentEmail && (
          <Grid item xs={12}>
            <PMSCheckbox
              smallText
              data-testid={"use-existing-email-address"}
              label={"Use Existing Email Addresss"}
              name={"useExistingEmailAddress"}
              isChecked={check}
              changeHandler={onCheckboxChange}
            />
            </Grid>
          )}
          <Box>
            <InputLabel htmlFor={"email"}>Email</InputLabel>
            <TextField
              fullWidth
              id={"email"}
              name={"email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
          />
          </Box>
          <Box width={"100%"} display={"flex"}
            justifyContent={"flex-end"}
            mt={3}>
            <Button
              color={"error"}
              variant={"text"}
              size={"large"}
              onClick={handleClose}
            >
              cancel
            </Button>
            <Box ml={2}>
              <Button
                className={emailSent ? classes.buttonPrimaryDisabled : classes.buttonPrimary}
                color={"primary"}
                variant={"contained"}
                size={"large"}
                onClick={handleSubmit}
                disabled={emailSent || !email}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>

      </Grid>
    </Modal>
  );
};

export default SendEmailModal;
