import { Button, FormControl, Grid, Modal, TextField, Typography } from "@mui/material";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import FacilitySelector from "src/components/navigation/FacilitySelector/FacilitySelector";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import ItemManagementLayout from "src/layouts/ItemManagement/ItemManagementLayout";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { Breadcrumb } from "src/models/Breadcrumb";
import { Facility } from "src/models/Facility";

import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  selectSelectedAdminFacility,
  setSelectedAdminFacility
} from "src/store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";

import {
  exportRateChangesHeadings
} from "src/store/thunks/rateChanges/exportForFacility/exportRateChangesHeadings";

import {
  exportRateChangesForFacility
} from "src/store/thunks/rateChanges/exportForFacility/exportRateChangesForFacility";
import {
  exportRateChangesForOperator
} from "src/store/thunks/rateChanges/exportForOperator/exportRateChangesForOperator";
import { batchUploadRates } from "src/store/thunks/rateChanges/batch/batchUploadRateChanges";
import useStyles from "./RateChanges.styles";
import { useFormik } from "formik";
import {
  selectBatchUploadLoading
} from "../../store/reducers/unitsSliceNew/unitsSliceNew";
import { showSnackbar } from "../../store/reducers/snackbarSlice/snackbarSlice";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import {
  selectMissingFieldsChangeOwnershipModalOpened, selectUndoChangeOwnershipModalOpened,
  setMissingFieldsChangeOwnershipModalOpened, setUndoChangeOwnershipModalOpened
} from "../../store/reducers/occupantSlice/occupantSlice";
import CustomModal from "../Occupants/EditOccupant/components/CustomModal/CustomModal";
import {
  createPermissions,
  hasPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Admin Settings"
  },
  {
    name: "Rate Changes",
    bold: true
  }
];

const fileValidationSchema = Yup.object().shape({
  file: Yup
    .mixed()
    .required("A file is required")
    .test(
      "fileType",
      "Uploaded file is not a CSV",
      value => value && value.type === "text/csv"
    )
});

const RateChanges: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedAdminFacility = useAppSelector(selectSelectedAdminFacility);
  const batchUploadLoading = useAppSelector(selectBatchUploadLoading);
  const successModalOpened = useAppSelector(selectMissingFieldsChangeOwnershipModalOpened);
  const errorModelOpened = useAppSelector(selectUndoChangeOwnershipModalOpened);
  const permissions = useAppSelector(selectFacilityPermissions);
  const occupantRateChangePermissions = createPermissions(permissions, "occupant_rate_changes");

  const formik = useFormik({
    initialValues: {
      file: null
    },
    //validationSchema: fileValidationSchema,
    onSubmit: (values) => {
      if (values.file) {
        dispatch(batchUploadRates({ file: values.file }))
          .then((resp) => {
            if (resp.type.includes("fulfilled")) {
              dispatch(setMissingFieldsChangeOwnershipModalOpened(true));
              return;
            }

            dispatch(showSnackbar({
              message: resp.payload,
              variant: "error"
            }));
            dispatch(setUndoChangeOwnershipModalOpened(true));
          });
      }
    }
  });
  const { handleSubmit, touched, errors, resetForm } = formik;
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const file = files[0];
      formik.setFieldValue("file", file);
      formik.setFieldTouched("file", true, false);
    }
  };

  const { classes } = useStyles({});

  const handleOnFacilityChange = (selectedFacility: Facility) => {
    dispatch(setSelectedAdminFacility(selectedFacility));
  };

  const ctaButton = () => {
    return (
      selectedAdminFacility && (
        <Grid spacing={2} item justifyContent={"space-between"}
          p={1} xs={12} lg={"auto"}>
          <Button
            data-testid={"fees-cta-button"}
            className={classes.buttonBase}
            color={"primary"}
            variant={"text"}
            onClick={() => dispatch(exportRateChangesForFacility(selectedAdminFacility.id))}
          >
            Export Selected Facility
          </Button>
        </Grid>
      )
    );
  };

  const ctaButton2 = (
    <Grid spacing={2} item justifyContent={"space-between"}
      p={4} xs={12} lg={"auto"}>
      <Button
        data-testid={"fees-cta-button"}
        className={classes.ctaButton}
        color={"primary"}
        variant={"text"}
        onClick={() => dispatch(exportRateChangesHeadings(0))}
      >
        Export Active Tenants
      </Button>
    </Grid>
  );

  const title = (
    <Grid container>
      <Grid item spacing={2} justifyContent={"space-between"}
        p={1}>
        <Typography variant={"h5"}>
          Rate Change Tools<br/>
        </Typography>
      </Grid>
    </Grid>
  );

  const exportHeadings = (
    <Grid container>
      <Grid item spacing={2} justifyContent={"space-between"}
        p={2}>
        <Typography component={"label"} variant={"h6"}>
          Export the rate change template headings only. <br/>
        </Typography>
      </Grid>
      <Grid spacing={10} item justifyContent={"space-between"}
        p={2}>
        <Button
          data-testid={"fees-cta-button"}
          className={classes.buttonBase}
          color={"primary"}
          variant={"text"}
          onClick={() => dispatch(exportRateChangesHeadings(0))}
        >
          Export Template Headings Only
        </Button>
      </Grid>
    </Grid>
  );
  const exportFacility = () => {
    return (
      <Grid item container alignItems={"center"}>
        <Grid item spacing={2} justifyContent={"space-between"}
          p={2}>
          <Typography variant={"h6"}>
            Export active tenants for 1 facility.   Select the facility:
          </Typography>
        </Grid>
        <Grid item spacing={2} justifyContent={"space-between"}
          p={1}>
          <FacilitySelector
            navBar={false}
            onFacilityChange={handleOnFacilityChange}
            elementId={"rc-facility-selector"}
            data-testid={"rate-change-facility-selector"}
          />
        </Grid>
        {ctaButton()}
      </Grid>
    );
  };
  const exportAllFacilities = (
    <Grid container>
      <Grid item spacing={2} justifyContent={"space-between"}
        p={2}>
        <Typography variant={"h6"}>
          Export active tenants for all facilities. <br/>
        </Typography>
      </Grid>
      <Grid spacing={10} item justifyContent={"space-between"}
        p={2}>
        <Button
          data-testid={"fees-cta-button"}
          className={classes.buttonBase}
          color={"primary"}
          variant={"text"}
          onClick={() => dispatch(exportRateChangesForOperator(1))}
        >
          Export All Facilities
        </Button>
      </Grid>
    </Grid>
  );

  const importForm = () => {
    return (
      <form
        id={"batch-upload-units-form"}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}>
        <Grid item container alignItems={"center"}>
          <Grid item spacing={2} justifyContent={"space-between"}
            p={2}>
            <Typography variant={"h6"}>
              Import Rate Changes:
            </Typography>
          </Grid>

          <Grid spacing={2} justifyContent={"space-between"}
            p={2}>
            <FormControl error={Boolean(touched.file && errors.file)} fullWidth>
              <TextField
                error={Boolean(touched.file && errors.file)}
                helperText={touched.file && errors.file ? errors.file : ""}
                id={"file"}
                type={"file"}
                onChange={handleFileChange}
                InputLabelProps={{
                  shrink: true
                }}
                variant={"outlined"}
                fullWidth
                margin={"normal"}
              />
            </FormControl>
          </Grid>
          <Grid item spacing={2} justifyContent={"space-between"}
            p={2}>
            <LoadingButton
              className={classes.buttonBase}
              type={"submit"}
              loading={batchUploadLoading}
              form={"batch-upload-units-form"}
              variant={"contained"}
            >
              Import
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item spacing={2} justifyContent={"space-between"}
            p={2}>
            <p>
              Import Rules:
              <ul>
                <li>New Rent <b>can not</b> be empty.  (The file should contain only rate changes.
                  No other records allowed.)
                </li>
                <li>There are 5 required column headings:
                  <strong> Location Code, Ledger ID, New Rent, Effective Date, Note </strong></li>
                <li>CSV file can include other columns.  Other columns will be ignored.</li>
                <li>CSV length is limited to 5000 rows. System will reject the file if it has more than 5000 rows. </li>
                <li>CSV can contain data for MULTIPLE facilities.  (You are not limited to 1 facility per CSV)</li>
                <li>Ledger ID <b>can not</b> be entered more than 1 time.  It must be unique.</li>
                <li>Ledger ID must be active.  (Upload will fail if any are inactive)</li>
                <li>Effective Date must be formatted: mm/dd/yyyy or yyyy-mm-dd</li>
                <li>Note field can be empty. (If supplied, it will be appended to the end of the system note.)</li>
              </ul>
              On the Effective Date, when the rates actually get changed, the system will automatically
              add a <strong>System Note</strong> on each updated ledger that looks like this below. <br/>
              <br/>
              Example System Note: <i>Scheduled rate change from $40 to $45 on 4/1/2024</i>
            </p>
          </Grid>
        </Grid>
      </form>
    );
  };

  const pageHeader = <PageHeader title={"Occupant Rate Changes"} breadcrumbs={breadcrumbs} />;

  return occupantRateChangePermissions.create
    ? (<ViewWrapper pageHeader={pageHeader}>
      <ItemManagementLayout title={title}>
        {exportFacility()}
        {exportAllFacilities}
        <hr/>
        {importForm()}
      </ItemManagementLayout>
      <CustomModal
        title={"Import Rate Changes"}
        open={successModalOpened}
        confirmButtonLabel={"Close"}
        hasCancel={false}
        handleSubmit={() => {
          dispatch(setMissingFieldsChangeOwnershipModalOpened(false));
          window.location.reload();
        }}
        handleClose={() => {
          dispatch(setMissingFieldsChangeOwnershipModalOpened(false));
          window.location.reload();
        }}
      >
        <Typography>
          The import was Successful.
        </Typography>
      </CustomModal>

      <CustomModal
        title={"Failure!"}
        open={errorModelOpened}
        confirmButtonLabel={"Close"}
        hasCancel={false}
        handleSubmit={() => {
          dispatch(setUndoChangeOwnershipModalOpened(false));
          window.location.reload();
        }}
        handleClose={() => {
          dispatch(setUndoChangeOwnershipModalOpened(false));
          window.location.reload();
        }}
      >
        <Typography>
          The import failed.  Review the error messages, update the file and try again.
        </Typography>
      </CustomModal>
    </ViewWrapper>)
    : <ViewWrapper pageHeader={pageHeader}>
      <h1>You do not have permission to view this page.</h1>
    </ViewWrapper>;
};

export default RateChanges;
