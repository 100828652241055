
import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getDocumentTemplateListForNewProductType = createAsyncThunk(
  "getDocumentTemplateListForNewProductType",
  (category: number | null, { dispatch, getState, rejectWithValue }) => {
    const store = getState() as RootState;
    const facilityId = store.selectedFacility.selectedFacility.id;

    const categoryParams = !category ? {} : { "filter[category]": category };

    const params = {
      params: {
        "filter[is_latest_version]": 1,
        ...categoryParams
      }
    };

    return axios.get(`/api/v1/facilities/${facilityId}/document-templates`, params)
      .then(resp => resp.data)
      .catch(() => {
        const errorMessage = "There was an error getting the Document Templates";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
