import { Grid, Paper, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import PMSAdvisory from "../../../../components/ui/PMSAdvisory/PMSAdvisory";
import SelectPromotion from "../../../../components/stepper/SelectPromotion/SelectPromotion";
import StepLayout from "../../../../layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "../../../../layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import { selectSelectedFacility } from "../../../../store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { selectSelectedPromotion } from "../../../../store/reducers/selectedPromotionSlice/selectedPromotionSlice";
import { setTargetPath } from "../../../../store/reducers/navigationSlice/navigationSlice";
import { useNavigate } from "react-router";

const SelectPromotionForMoveIn: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const selectedPromotion = useAppSelector(selectSelectedPromotion);

  const handleGoBack = () => navigate("/occupants/create-move-in/select-unit");

  const handleGoForward = () => {
    dispatch(setTargetPath("/occupants/create-move-in/select-promotion"));
    navigate("/occupants/create-move-in/process-move-in");
  };

  const advisoryText = (
    <>
      <p>Here you are able to select a promotion for a unit if applicable.</p>
      <p>
        You can view a list of available promotions at your facility. This list also displays promotion attributes
        that can be used to guide an occupant towards the one that best fits their needs.
      </p>
    </>
  );

  return (
    <>
      <PMSAdvisory
        title={"Select a Promotion"}
        data-testid={"promotion-advisory"}
        advisoryText={advisoryText}
        allowSkip
        skipHandler={handleGoForward}
      />
      <Paper elevation={1}>
        <StepLayout
          buttons={
            <StepperButtons
              data-testid={"select-move-in-promotion-buttons"}
              forwardAction={handleGoForward}
              backAction={handleGoBack}
              forwardActionText={selectedPromotion ? "Next" : "Skip"}
            />}
        >
          <Grid
            component={Typography}
            mb={2}
            variant={"h6"}
          >
            Available Promotions at {selectedFacility?.name}
          </Grid>
          <SelectPromotion />
        </StepLayout>
      </Paper>
    </>
  );
};

export default SelectPromotionForMoveIn;
