import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ReviewAndPayCashSlicState } from "./reviewAndPayCashSliceState";
import { RootState } from "../../rootStore";

export const getChangeOwed = (cashDue: string, cashReceived: string) => {
  if (cashDue && cashReceived) {
    const changeDue = (+cashReceived - +cashDue);

    if (changeDue >= 0.00) {
      // prevent displaying negative numbers in "change due"
      return changeDue.toFixed(2);
    }
  }

  return "0.00";
};

export const initialState: ReviewAndPayCashSlicState = {
  cashDue: "0",
  cashReceived: ""
};

export const reviewAndPayCashSlice = createSlice({
  name: "reviewAndPayCash",
  initialState,
  reducers: {
    setCashDue: (state, action: PayloadAction<string>) => {
      state.cashDue = action.payload;
    },
    setCashReceived: (state, action: PayloadAction<string>) => {
      state.cashReceived = action.payload;
    },
    resetReviewAndPayCashSlice: (state) => {
      Object.assign(state, initialState);
    }
  }
});

export const selectCashDue = (state: RootState) => state.reviewAndPayCash.cashDue;
export const selectCashReceived = (state: RootState) => state.reviewAndPayCash.cashReceived;
export const selectChangeOwed = (state: RootState) =>
  getChangeOwed(state.pointOfSales.finalBalance, state.reviewAndPayCash.cashReceived);

export const {
  setCashDue,
  setCashReceived,
  resetReviewAndPayCashSlice
} = reviewAndPayCashSlice.actions;

export default reviewAndPayCashSlice.reducer;
