import { AddCircle, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Modal,
  Paper,
  SelectChangeEvent,
  TextField,
  Typography
} from "@mui/material";
import React, { FC, useCallback, useEffect, useState } from "react";
import {
  resetChartOfAccountsSettings,
  selectChartOfAccountsSearchValue,
  selectChartOfAccountsSettings,
  selectChartOfAccountsSettingsLoading,
  selectRemoveModalOpened,
  selectSettingsModalOpen,
  setRemoveModalOpened,
  setSearchValue,
  setSettingModalOpen
} from "src/store/reducers/chartOfAccountsSlice/chartOfAccountsSlice";
import {
  resetSelectedAdminFacilitySlice,
  selectSelectedAdminFacility,
  setSelectedAdminFacility
} from "src/store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import { selectFacilities, selectFacilitiesLoading } from "src/store/reducers/facilitiesSlice/facilitiesSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Breadcrumb } from "src/models/Breadcrumb";
import ChartOfAccountsSettingForm from "./forms/ChartOfAccountsSettingForm/ChartOfAccountsSettingForm";
import ChartOfAccountsTable from "./ChartOfAccountsTable/ChartOfAccountsTable";
import ExportMenu from "./ExportMenu/ExportMenu";
import ItemManagementLayout from "src/layouts/ItemManagement/ItemManagementLayout";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { debounce } from "lodash";
import { getChartOfAccountSettings } from "src/store/thunks/chartOfAccounts/get/getChartOfAccountsSettings";
import { resetTablesSlice } from "src/store/reducers/tablesSlice/tablesSlice";
import { searchChartOfAccountSettings } from "src/store/thunks/chartOfAccounts/search/searchChartOfAccountsSettings";
import useStyles from "./ChartOfAccounts.styles";
import RemoveSettingModalForm from "./RemoveSettingModalForm/RemoveSettingModalForm";
import { SyntheticInputEvent } from "react-number-format/types/types";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

interface ChartOfAccountsProps {}

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Accounting"
  },
  {
    name: "Chart of Account Settings",
    bold: true
  }
];

const pageHeader = <PageHeader title={"Chart of Account Settings"} breadcrumbs={breadcrumbs} />;

const ChartOfAccounts: FC<ChartOfAccountsProps> = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const [value, setValue] = useState(0);
  const settings = useAppSelector(selectChartOfAccountsSettings);
  const settingsLoading = useAppSelector(selectChartOfAccountsSettingsLoading);
  const selectedFacility = useAppSelector(selectSelectedAdminFacility);
  const facilitiesLoading = useAppSelector(selectFacilitiesLoading);
  const facilities = useAppSelector(selectFacilities);
  const chartOfAccountsSettingFormOpened = useAppSelector(selectSettingsModalOpen);
  const searchInput = useAppSelector(selectChartOfAccountsSearchValue);
  const removeSettingModalOpened = useAppSelector(selectRemoveModalOpened);
  const permissions = useAppSelector(selectFacilityPermissions);
  const chartOfAccountsPermissions = createPermissions(permissions, "chart_of_accounts");

  const handleCloseModal = () => {
    if (chartOfAccountsSettingFormOpened) {
      dispatch(setSettingModalOpen(false));
      return;
    }

    if (removeSettingModalOpened) {
      dispatch(setRemoveModalOpened(false));
    }
  };

  const handleOpenModal = () => {
    dispatch(setSettingModalOpen(true));
  };

  const settingsDisplay = () => {
    if (!value || !selectedFacility) {
      return null;
    }

    if (!settings.length && chartOfAccountsPermissions.create) {
      return (
        <Grid
          item
          container
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          className={classes.displayContainer}
        >
          <Typography variant={"h6"} component={"span"}>
            No settings to display
          </Typography>
          <Button
            className={classes.newItemButton}
            color={"primary"}
            variant={"outlined"}
            startIcon={<AddCircle />}
            size={"large"}
            onClick={handleOpenModal}
          >
            New setting
          </Button>
        </Grid>
      );
    }

    return <ChartOfAccountsTable />;
  };

  const ctaButton = () => {
    return (
      selectedFacility &&
      !!value && (
        <Button
          className={classes.newSettingButton}
          data-testid={"new-setting-cta-button"}
          color={"primary"}
          variant={"contained"}
          onClick={handleOpenModal}
        >
          + New Setting
        </Button>
      )
    );
  };

  const handleOnFacilityChange = (event: SelectChangeEvent) => {
    const facilityIdSelected = parseInt(event.target.value);

    setValue(facilityIdSelected);

    const facility = facilities.find((facility) => facility.id === facilityIdSelected);

    dispatch(setSelectedAdminFacility(facility!));
    dispatch(resetTablesSlice());
    dispatch(setSearchValue(""));
    dispatch(getChartOfAccountSettings());
  };

  useEffect(() => {
    return () => {
      dispatch(resetSelectedAdminFacilitySlice());
      dispatch(resetChartOfAccountsSettings());
      dispatch(resetTablesSlice());
    };
  }, []);

  const title = (
    <Grid item container alignItems={"center"}>
      <Grid item>
        <Typography component={"label"} htmlFor={"chart-of-accounts-facility-selector"}>
          Chart of Account Settings for:
        </Typography>
      </Grid>
      <Grid item ml={2}>
        <PMSSelect
          className={classes.facilitySelector}
          id={"chart-of-accounts-facility-selector"}
          name={"chart-of-accounts-facility-selector"}
          value={value}
          changeHandler={handleOnFacilityChange}
          disabled={facilitiesLoading}
        >
          <option value={0} disabled>
            {facilitiesLoading ? "Facilities loading..." : "Select a Facility"}
          </option>

          {facilities.map((facility) => (
            <option data-testid={`facility-${facility.id}`} key={facility.id} value={facility.id}>
              {facility.facility_id}
            </option>
          ))}
        </PMSSelect>
      </Grid>
    </Grid>
  );

  const handleSearch = (searchValue: string) => {
    if (!searchValue.length) {
      dispatch(getChartOfAccountSettings());
      return;
    }

    dispatch(searchChartOfAccountSettings({ searchValue }));
  };

  const debounceSearch = useCallback(
    debounce((text) => handleSearch(text), 600),
    []
  );

  const handleChange = (e: SyntheticInputEvent) => {
    dispatch(setSearchValue(e.target.value));
    debounceSearch(e.target.value);
  };

  const searchBar = () => {
    return (
      selectedFacility &&
      !!value && (
        <TextField
          fullWidth
          placeholder={"Search"}
          size={"small"}
          hiddenLabel={true}
          inputProps={{ "aria-label": "Search Chart of Account Settings" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position={"end"}>
                <Search />
              </InputAdornment>
            )
          }}
          variant={"outlined"}
          onChange={handleChange}
          value={searchInput}
        />
      )
    );
  };

  if (chartOfAccountsPermissions.read) {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <Box pt={4} pb={selectedFacility ? 0 : 2} mr={20}>
          <Typography>
            Manage your Chart of Account settings by editing the default settings listed below.
            If you&apos;d like to add
            more, click the &apos;New Setting&apos; button where you can input a new account name,
            category, sub-category,
            and account code.
          </Typography>
        </Box>
        <ItemManagementLayout
          title={title}
          headerButton={chartOfAccountsPermissions.create ? ctaButton() : null}
          searchBar={searchBar()}
          exportExcel={<ExportMenu value={value} />}
          loading={settingsLoading}
        >
          {settingsDisplay()}
        </ItemManagementLayout>
        <Modal
          data-testid={"settings-modal"}
          open={chartOfAccountsSettingFormOpened || removeSettingModalOpened}
          onBackdropClick={handleCloseModal}
          onClose={handleCloseModal}
          aria-labelledby={"Chart of account settings modal"}
          aria-describedby={"A Modal component to add or edit chart of account settings."}
          className={chartOfAccountsSettingFormOpened ? classes.modal : classes.modalRemove}
        >
          <>
            {chartOfAccountsSettingFormOpened && <ChartOfAccountsSettingForm closeButtonAction={handleCloseModal} />}
            {removeSettingModalOpened && <RemoveSettingModalForm />}
          </>
        </Modal>
      </ViewWrapper>
    );
  } else {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <h1>You do not have permission to view this page.</h1>
      </ViewWrapper>
    );
  }
};

export default ChartOfAccounts;
