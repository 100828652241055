import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().required("E-mail Address is required").email("E-mail Address must be valid"),
  phone: Yup.string().length(10, "Primary Phone must be 10 digits").required("Primary Phone is required")
});

export default validationSchema;
