import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  unit_number: Yup.string().required("Unit number is required"),
  length: Yup.number().required("Length is required"),
  width: Yup.number().required("Width is required"),
  height: Yup.number().required("Height is required"),
  price: Yup.number().required("Price is required"),
  floor: Yup.string().required("Floor is required"),
  product_type: Yup.string().required("Product type is required").test({
    name: "product_type",
    message: "Product type is required",
    test: function(value) {
      if (value && value === "0") {
        return this.createError({ message: "Product type is required" });
      }

      return true;
    }
  }),
  unrentable_reason: Yup.string().nullable().when("rentable", {
    is: false,
    then: Yup.string().nullable().required("Unrentbale reason is required")
  }),
  unrentable_note: Yup.string().nullable().when("rentable", {
    is: false,
    then: Yup.string().nullable().required("Please enter a note")
  })
});

export default validationSchema;
