import { CircularProgress, Grid, Paper, Typography } from "@mui/material";
import React, { ReactElement, useEffect } from "react";
import {
  selectAdditionalPeriods,
  selectFees,
  selectInsurancePlan,
  selectMoveInCostLoading,
  selectMoveInCostMerchandise,
  selectMoveInNextPaymentFees,
  selectMoveInNextPaymentSubtotalAmount,
  selectMoveInNextPaymentTaxAmount,
  selectNextPayment,
  selectNextPaymentCredit,
  selectNextPaymentDate,
  selectNextPaymentInsurancePlan,
  selectNextPaymentPromoAdjustments,
  selectPreviousUnitBalance,
  selectPromoAdjustments,
  selectRemainingCredit,
  selectRent,
  selectSecurityDeposit,
  selectSubtotal,
  selectTax,
  selectTotalDueToday
} from "../../../../store/reducers/moveInCostSlice/moveInCostSlice";
import {
  selectIsTransfer,
  selectMonthlyRate,
  selectPeriods,
  selectWaiveFee
} from "../../../../store/reducers/moveInSlice/moveInSlice";
import {
  selectMoveOutAdditionalPeriods,
  selectMoveOutAdditionalPeriodsAmount,
  selectMoveOutCostBalance,
  selectMoveOutCostCredits,
  selectMoveOutCostFees,
  selectMoveOutCostFinalBalance,
  selectMoveOutCostLoading,
  selectMoveOutCostTax,
  selectMoveOutCostTotalCredits
} from "src/store/reducers/moveOutCostSlice/moveOutCostSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

import { LineItemProps } from "src/components/paymentSummary/LineItem/LineItem";
import Moment from "moment";
import PaymentSummary from "../../../../components/paymentSummary/PaymentSummary/PaymentSummary";
import { selectSelectedPromotion } from "src/store/reducers/selectedPromotionSlice/selectedPromotionSlice";
import { setTotalDueToday } from "../../../../store/reducers/paymentSlice/paymentSlice";
import { startCase } from "lodash";
import useStyles from "./MoveSummary.styles";

interface MoveSummaryProps {
  isMoveOut?: boolean;
}

const MoveSummary: React.FC<MoveSummaryProps> = ({ isMoveOut }): ReactElement => {
  const { classes } = useStyles();
  const moveCostLoading = useAppSelector(isMoveOut ? selectMoveOutCostLoading : selectMoveInCostLoading);
  let moveSummary;
  let nextPaymentSummary = null;
  let totals: Array<{ name: string; amount: string }> = [];
  const rent = useAppSelector(selectRent);
  const fees = useAppSelector(isMoveOut ? selectMoveOutCostFees : selectFees);
  const insurancePlan = useAppSelector(selectInsurancePlan);
  const merchandise = useAppSelector(selectMoveInCostMerchandise);
  const subtotal = useAppSelector(selectSubtotal);
  const tax = useAppSelector(selectTax);
  const totalDueToday = useAppSelector(selectTotalDueToday);
  const promoAdjustment = useAppSelector(selectPromoAdjustments);
  const additionalPeriodsAmount = useAppSelector(
    isMoveOut
      ? selectMoveOutAdditionalPeriodsAmount
      : selectAdditionalPeriods);
  const additionalPeriods = useAppSelector(
    isMoveOut
      ? selectMoveOutAdditionalPeriods
      : selectPeriods);
  const nextPaymentDate = useAppSelector(selectNextPaymentDate);
  const nextPaymentAmount = useAppSelector(selectNextPayment);
  const amountDue = parseFloat(totalDueToday) > 0 ? totalDueToday : "0";
  const moveOutCredits = useAppSelector(selectMoveOutCostCredits);
  const moveOutBalance = useAppSelector(selectMoveOutCostBalance);
  const moveOutFinalBalance = useAppSelector(selectMoveOutCostFinalBalance);
  const totalCredits = useAppSelector(selectMoveOutCostTotalCredits);
  const moveOutCostTax = useAppSelector(selectMoveOutCostTax);
  const waiveFee = useAppSelector(selectWaiveFee);
  const previousUnitBalance = useAppSelector(selectPreviousUnitBalance);
  const isTransfer = useAppSelector(selectIsTransfer);
  const selectedPromotion = useAppSelector(selectSelectedPromotion);
  const nextPaymentTaxAmount = useAppSelector(selectMoveInNextPaymentTaxAmount);
  const nextPaymentCredit = useAppSelector(selectNextPaymentCredit);
  const nextPaymentPromoAdjustment = useAppSelector(selectNextPaymentPromoAdjustments);
  const nextPaymentInsurancePlan = useAppSelector(selectNextPaymentInsurancePlan);
  const nextPaymentFees = useAppSelector(selectMoveInNextPaymentFees);
  const remainingCredit = useAppSelector(selectRemainingCredit);
  const securityDeposit = useAppSelector(selectSecurityDeposit);
  const monthlyRate = useAppSelector(selectMonthlyRate);
  const nextPaymentSubtotalAmount = useAppSelector(selectMoveInNextPaymentSubtotalAmount);

  const dispatch = useAppDispatch();
  /* istanbul ignore next */
  useEffect(() => {
    dispatch(setTotalDueToday(amountDue));
  }, []);

  if (moveCostLoading) {
    moveSummary = (
      <Grid container justifyContent={"center"}>
        <CircularProgress data-testid={"move-in-cost-loading-spinner"} />
      </Grid>
    );
  } else {
    const lineItems: LineItemProps[] = [
      {
        name: isMoveOut ? "Balance" : "Rent",
        amount: isMoveOut ? moveOutBalance : rent
      }
    ];

    const nextPaymentLineItems: LineItemProps[] = [
      {
        name: "Rent",
        amount: monthlyRate?.toString() || "0"
      }
    ];

    const nextPaymentTotalLineItems: LineItemProps[] = [
      {
        name: "Subtotal",
        amount: nextPaymentSubtotalAmount
      },
      {
        name: "Tax",
        amount: parseFloat(nextPaymentTaxAmount).toFixed(2)
      },
      {
        name: (
          <>
            Next Payment <br /> ({Moment(nextPaymentDate).format("MM/DD/YYYY")})
          </>
        ),
        amount: nextPaymentAmount
      }
    ];

    if (nextPaymentPromoAdjustment && parseFloat(nextPaymentPromoAdjustment) && selectedPromotion) {
      nextPaymentLineItems.push({
        name: "Promo",
        amount: (parseFloat(nextPaymentPromoAdjustment) * -1).toFixed(2)
      });
    }

    if (parseFloat(nextPaymentCredit) < 0) {
      nextPaymentLineItems.push({
        name: "Credit",
        amount: nextPaymentCredit?.toString() || "0"
      });
    }

    if (additionalPeriodsAmount && parseFloat(additionalPeriodsAmount) !== 0 && additionalPeriods! > 0) {
      lineItems.push({
        name: `Rent: Add. Periods (${additionalPeriods})`,
        amount: parseFloat(additionalPeriodsAmount).toFixed(2)
      });
    }

    // if (isMoveOut) {
    //   if (parseFloat(totalCredits) > 0) {
    //     lineItems.push({ name: "Total Credits", amount: `-${totalCredits}` });
    //   }

    //   moveOutCredits.forEach((moveOutCredit) => {
    //     if (parseFloat(moveOutCredit.amount) > 0 || parseFloat(moveOutCredit.amount) < 0) {
    //       lineItems.push({
    //         name: startCase(moveOutCredit.name),
    //         amount: `${parseFloat(moveOutCredit.amount) * -1}`,
    //         subLineItem: true
    //       });
    //     }
    //   });
    // }

    if (fees && fees.length) {
      fees.forEach((item) => {
        lineItems.push({
          name: `${item.name}${Boolean(item.is_required) === true ? " (Required)" : ""}`,
          amount: item.cost
        });
      });
    }

    if (nextPaymentFees && nextPaymentFees.length) {
      nextPaymentFees.forEach((item) => {
        nextPaymentLineItems.push({
          name: item.name,
          amount: item.cost
        });
      });
    }

    if (parseFloat(moveOutCostTax) > 0) {
      lineItems.push({
        name: "Tax",
        amount: moveOutCostTax
      });
    }

    if (promoAdjustment && parseFloat(promoAdjustment) > 0 && selectedPromotion) {
      lineItems.push({
        name: "Promo",
        amount: (parseFloat(promoAdjustment) * -1).toFixed(2)
      });
    }

    if (insurancePlan && insurancePlan.name) {
      lineItems.push({
        name: insurancePlan.name,
        amount: parseFloat(insurancePlan.cost).toFixed(2)
      });
    }

    if (nextPaymentInsurancePlan && nextPaymentInsurancePlan.name) {
      nextPaymentLineItems.push({
        name: nextPaymentInsurancePlan.name,
        amount: parseFloat(nextPaymentInsurancePlan.cost).toFixed(2)
      });
    }

    if (merchandise && merchandise.length) {
      merchandise.forEach((merchItem) => {
        if (parseFloat(merchItem.cost as string) > 0) {
          lineItems.push({
            name: `${merchItem.name} (x${merchItem.quantity})`,
            amount: String(merchItem.cost)
          });
        }
      });
    }

    if (parseFloat(securityDeposit) > 0) {
      lineItems.push({
        name: "Security Deposit",
        amount: parseFloat(securityDeposit).toFixed(2)
      });
    }

    totals = [
      {
        name: "Subtotal",
        amount: subtotal
      },
      {
        name: "Tax",
        amount: tax
      },
      {
        name: "Total Due Today",
        amount: totalDueToday
      }
    ];

    if (parseFloat(waiveFee.waiveFeeAmount) > 0) {
      totals.unshift({
        name: "Waive Fee",
        amount: waiveFee.waiveFeeAmount
      });
    }

    if (isMoveOut) {
      totals = [
        {
          name: "Final Balance",
          amount: moveOutFinalBalance
        }
      ];
    }

    if (isTransfer) {
      lineItems.push({
        name: "Previous Unit Balance",
        amount: previousUnitBalance!,
        transferItem: true
      });
    }

    moveSummary = (
      <>
        <Typography data-testid={"move-in-summary-title"} className={classes.title} mb={2}>
          {isMoveOut ? "Move-Out Summary" : "Move-In Summary"}
        </Typography>
        <PaymentSummary data-testid={"move-in-summary"} lineItems={lineItems} totals={totals} />
      </>
    );

    if (nextPaymentDate && nextPaymentAmount) {
      nextPaymentSummary = (
        <PaymentSummary
          data-testid={"next-payment-summary"}
          lineItems={nextPaymentLineItems}
          totals={nextPaymentTotalLineItems}
          footer={ parseFloat(remainingCredit) < 0
            ? <Grid container justifyContent={"space-between"} mt={3}>
              <Grid item><Typography variant={"caption"}>Remaining Credit:</Typography></Grid>
              <Grid item>
                <Typography variant={"caption"}>
                  -${(parseFloat(remainingCredit) * -1).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
            : null }
        />
      );
    }
  }

  return (
    <section className={classes.summaryContainer}>
      <Paper component={Grid} container direction={"column"}
        py={3} mb={1}>
        {moveSummary}
      </Paper>
      {!isMoveOut && (
        <Paper>
          <Grid container direction={"column"} py={3}>
            {nextPaymentSummary}
          </Grid>
        </Paper>
      )}
    </section>
  );
};

export default MoveSummary;
