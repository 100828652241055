import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "AppModal" })((theme) => ({
  modal: {
    margin: "auto",
    width: "30rem",
    height: "15rem",
    boxShadow: "0 .188rem .375rem rgba(0,0,0, .2)",
    borderRadius: "1rem",
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.up("lg")]: {
      width: "35%"
    }
  }
}));

export default useStyles;
