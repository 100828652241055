import * as Yup from "yup";

const urlRegex = /^(https?):\/\/[^\s$.?#].[^\s]*$/;

const headerSchema = Yup.object({
  key: Yup.string().nullable(true),
  value: Yup.string().nullable(true)
}).test("header-validation", "",
  function(header) {
    const { key, value } = header;

    const errors: { key?: string; value?: string } = {};

    if (this.parent.length === 1 && !key && !value) {
      return true;
    }

    if (!key && value) {
      errors.key = "Key is required if value is provided";
    }
    if (key && !value) {
      errors.value = "Value is required if key is provided";
    }

    if (Object.keys(errors).length > 0) {
      return this.createError({ path: this.path, message: errors });
    }

    return true;
  }
);

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
  receivingUrl: Yup.string()
    .matches(urlRegex, "Enter a valid URL that starts with http:// or https://")
    .required("Must be a valid URL with http:// or https://"),
  authType: Yup.number(),
  eventTrigger: Yup.string().required("Event trigger is required"),
  authUsername: Yup.string()
    .when("authType", {
      is: 1,
      then: Yup.string().required("Auth username is required")
    }),
  authPassword: Yup.string()
    .when("authType", {
      is: 1,
      then: Yup.string().required("Auth password is required")
    }),
  authToken: Yup.string()
    .when("authType", {
      is: 2,
      then: Yup.string().required("Auth token is required")
    }),
  headers: Yup.array().of(headerSchema)

});

export default validationSchema;
