import React, { ReactElement } from "react";
import GetStarted from "../../../../components/stepper/GetStarted/GetStarted";
import { TRANSFER_UNIT_PATHS } from "src/routes/TransferUnitRoutes.";

const GetTransferUnitStarted: React.FC = (): ReactElement => {
  const advisoryText = (
    <>
      <p>You can use the following workflow to transfer an unit.</p>
      <p>
        Bacon ipsum dolor amet sandbagger Alcatraz hamburger pork chop,
        cousin occaecat adipisicing prosciutto lorem liberal tempor tenderloin meatball voluptate laboris.

        Chuck esse jerky elit tempor liberal.
      </p>
    </>
  );

  return (
    <GetStarted
      title={"Transfer Unit"}
      advisoryText={advisoryText}
      nextStepPath={TRANSFER_UNIT_PATHS.selectOccupantForTransferUnit}
    />
  );
};

export default GetTransferUnitStarted;
