enum FacilitySettingEnum {
    RESERVATION_DAYS_LIMIT = 1,
    DEAL_DAYS_LIMIT = 2,
    RECEIPT_TEMPLATE = 3,
    INVOICE_TEMPLATE = 4,
    AUTOBILL_TEMPLATE = 5,
    ESIGN_EMAIL_TEMPLATE = 6,
    PAST_DUE_EMAIL_TEMPLATE = 7,
    QUICK_PAY_EMAIL_TEMPLATE = 8,
    SMS_TEMPLATE = 9,
    GATE_SYSTEM = 10,
    EMERGENCY_GATE_OVERRIDE = 11,
    MOVE_OUT_MARK_DEBT = 12,
    MOVE_IN_DAYS_LIMIT = 13,
  };

export default FacilitySettingEnum;
