import { CircularProgress, Grid } from "@mui/material";
import React, { FC } from "react";

interface LoadingSpinnerProps {
  testId?: string;
}

const LoadingSpinner: FC<LoadingSpinnerProps> = ({ testId = "item-loading" }) => {
  return (
    <Grid my={5} container alignItems={"center"}
      height={"100%"} justifyContent={"center"} data-testid={testId}>
      <CircularProgress size={50} />
    </Grid>
  );
};

export default LoadingSpinner;
