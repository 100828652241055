import { ButtonGroup, Grid, Typography } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";
import { FilterList as FilterListIcon } from "@mui/icons-material";
import useStyles from "./FilterButtonGroup.styles";

interface FilterButtonGroupProps {
  heading: string
  children: ReactNode
}

const FilterButtonGroup: React.FC<FilterButtonGroupProps> = ({ heading, children }: FilterButtonGroupProps):
  ReactElement => {
  const { classes } = useStyles();

  return (
    <Grid
      className={classes.root}
      container
      item
      justifyContent={"center"}
      alignItems={"center"}
    >
      <FilterListIcon />
      <Typography variant={"subtitle2"} className={classes.heading} data-testid={"button-group-heading"}>
        {heading}
      </Typography>
      <ButtonGroup
        aria-label={"button group for filtering"}
        disableElevation
        size={"small"}
      >
        {children}
      </ButtonGroup>
    </Grid>
  );
};

export default FilterButtonGroup;
