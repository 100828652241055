import React, { ReactElement } from "react";
import GetStarted from "../../../../components/stepper/GetStarted/GetStarted";

const GetAddProductTypeStarted: React.FC = (): ReactElement => {
  const advisoryText = (
    <>
      <p>
        Start by creating product types for your units. Here you can define unit attributes, including size,
        climate control, and other important features.
      </p>
      <p>
        You will also be able to assign revenue classes and group units into your created product type.
      </p>
    </>
  );

  return (
    <GetStarted
      title={"Create product types"}
      advisoryText={advisoryText}
      nextStepPath={"/product-types/add-product-type/define-product-type"}
    />
  );
};

export default GetAddProductTypeStarted;
