import { Button, Grid } from "@mui/material";
import React, { FormEvent, useEffect, useState } from "react";
import { useAppDispatch } from "src/store/hooks";
import { useLocation, useNavigate } from "react-router";
import { setPosModalOpen } from "src/store/reducers/pointOfSalesSlice/pointOfSalesSlice";
import useStyles from "./Buttons.styles";

interface ButtonsProps {
  formId: string;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  disabledNextButton?: boolean;
}

const Buttons = ({ formId, disabledNextButton }: ButtonsProps) => {
  const dispatch = useAppDispatch();
  const [nextBtnText, setNextBtnText] = useState("Next");
  const [nextPath, setNextPath] = useState("/merchandise/point-of-sales/review-and-pay");
  const navigate = useNavigate();
  const location = useLocation();
  const { classes } = useStyles();
  const last = location.pathname.split("/").pop();

  useEffect(() => {
    setNextBtnText("Next");
    if (last === "review-and-pay") {
      setNextBtnText("Place Order");
    }
  }, [location]);

  const handleSubmit = () => {
    navigate(nextPath);
  };

  return (
    <Grid
      container
      mt={2}
      justifyContent={"space-around"}
      component={"form"}
      id={"point-of-sales-payment-form"}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
      }}
    >
      <Grid item>
        <Button
          data-testid={"cancel-button-modal-pos"}
          color={"secondary"}
          onClick={() => dispatch(setPosModalOpen(true))}
          variant={"text"}
          className={classes.cancelButton}
        >
          Cancel
        </Button>
      </Grid>
      <Grid item>
        <Button
          form={formId}
          type={"submit"}
          data-testid={"next-button-modal-pos"}
          variant={"contained"}
          color={"primary"}
          className={classes.confirmButton}
          disabled={disabledNextButton}
        >
          { nextBtnText }
        </Button>
      </Grid>
    </Grid>
  );
};

export default Buttons;
