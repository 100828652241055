
import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Facility Form Rebuild - Facility Details" })((theme) => ({
  labelWeight: {
    fontWeight: 300,
    color: "black"
  }
}));

export default useStyles;
