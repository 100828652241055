import { APIFilter } from "../../../models/responses/APIFilter";
import { makeStyles } from "../../../makeStyles";

const hasFilter = (filter: APIFilter) => {
  return filter && Object.keys(filter).length && Object.keys(filter).includes("filter[is_signed]");
};

const useStyles = makeStyles<{ filter: APIFilter }>({ label: "ESign Status" })((theme, { filter }) => ({
  buttonText: {
    textTransform: "capitalize"
  },
  allFilter: {
    color:
      hasFilter(filter) && filter["filter[is_signed]"] === "0,1"
        ? theme.palette.text.primary
        : theme.palette.text.disabled
  },
  completedFilter: {
    color:
      hasFilter(filter) && filter["filter[is_signed]"] === "1"
        ? theme.palette.text.primary
        : theme.palette.text.disabled
  },
  pendingFilter: {
    color:
      hasFilter(filter) && filter["filter[is_signed]"] === "0"
        ? theme.palette.text.primary
        : theme.palette.text.disabled
  }
}));

export default useStyles;
