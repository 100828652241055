import React, { ReactElement, useEffect } from "react";
import {
  resetMerchandiseCategoriesSlice,
  selectMerchandiseCategoriesLoading
} from "../../../store/reducers/merchandiseCategoriesSlice/merchandiseCategoriesSlice";
import {
  resetRevenueClassesSlice,
  selectRevenueClassesLoading
} from "../../../store/reducers/revenueClassesSlice/revenueClassesSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Automation } from "src/models/Automation";
import { Breadcrumb } from "../../../models/Breadcrumb";
import CreateAutomationForm from "../components/AutomationForm/AutomationForm";
import FooterButtons from "../../../components/itemManagement/ItemManagementCard/FooterButtons/FooterButtons";
import ItemManagementLayout from "../../../layouts/ItemManagement/ItemManagementLayout";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { createAutomation } from "src/store/thunks/automations/create/createAutomation";
import { getAllMerchandiseCategories }
  from "../../../store/thunks/merchandiseCategory/getAll/getAllMerchandiseCategories";
import { getAllRevenueClasses } from "../../../store/thunks/revenueClass/getAll/getAllRevenueClasses";
import { getAutomations } from "src/store/thunks/automations/getAll/getAutomations";
import { resetMerchandiseItemsSlice } from "../../../store/reducers/merchandiseItemsSlice/merchandiseItemsSlice";
import { selectMerchandiseFormLoading } from "../../../store/reducers/merchandiseFormSlice/merchandiseFormSlice";
import { selectSelectedAdminFacility } from "src/store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import { useNavigate } from "react-router";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Automations"
  },
  {
    name: "System Automations"
  },
  {
    name: "Create New Automation",
    bold: true
  }
];

const CreateAutomation: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedAdminFacility = useAppSelector(selectSelectedAdminFacility);
  const revenueClassesLoading = useAppSelector(selectRevenueClassesLoading);
  const merchandiseCategoriesLoading = useAppSelector(selectMerchandiseCategoriesLoading);
  const viewLoading = revenueClassesLoading || merchandiseCategoriesLoading;
  const merchandiseFormLoading = useAppSelector(selectMerchandiseFormLoading);
  const permissions = useAppSelector(selectFacilityPermissions);
  const automationsPermissions = createPermissions(permissions, "automations");

  const handleSubmit = (automationValues: Automation) => {
    dispatch(createAutomation({
      values: automationValues,
      facilityId: String(selectedAdminFacility?.id)
    }))
      .then((resp: any) => {
        if (!resp.type.includes("rejected")) {
          if (selectedAdminFacility?.id) {
            dispatch(getAutomations({ facilityId: selectedAdminFacility.id, is_active: true }));
          }

          navigate("/delinquency-automations");
        }
      });
  };

  useEffect(() => {
    if (!selectedAdminFacility?.id) {
      return navigate("/delinquency-automations");
    }

    dispatch(getAllRevenueClasses(selectedAdminFacility?.id));
    dispatch(getAllMerchandiseCategories());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetRevenueClassesSlice());
      dispatch(resetMerchandiseCategoriesSlice());
      dispatch(resetMerchandiseItemsSlice());
    };
  }, []);

  const pageHeader = <PageHeader title={`Create New Automation`} breadcrumbs={breadcrumbs} />;

  return (
    <ViewWrapper pageHeader={pageHeader}>
      {
        automationsPermissions.create
          ? (<ItemManagementLayout
              title={`New Automation for ${selectedAdminFacility?.name} - ${selectedAdminFacility?.facility_id}`}
              loading={viewLoading}
              footerButtons={
                <FooterButtons
                  formId={"create-automation-form"}
                  cancelPath={"/delinquency-automations"}
                  submitLoading={merchandiseFormLoading}
                  submitButtonText={"Save & Continue"}
                />
              }
            >
            <CreateAutomationForm submitHandler={handleSubmit}/>
          </ItemManagementLayout>)
          : <h1>You do not have permission to view this page.</h1>
      }
    </ViewWrapper>
  );
};

export default CreateAutomation;
