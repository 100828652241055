import AddOccupant from "../pages/Occupants/AddOccupant/AddOccupant";
import CreateMoveIn from "src/pages/Deals/CreateMoveIn/CreateMoveIn";
import CreateMoveOut from "src/pages/Deals/CreateMoveOut/CreateMoveOut";
import EditOccupant from "../pages/Occupants/EditOccupant/EditOccupant";
import IssueCredit from "src/pages/IssueCredit/IssueCredit";
import IssueRefund from "src/pages/IssueRefund/IssueRefund";
import ManageOccupants from "../pages/Occupants/ManageOccupants/ManageOccupants";
import React from "react";
import { Route } from "react-router";

export const groupName = "occupants";

export const OccupantRoutes = (
  <>
    <Route path={`${groupName}/add-occupant`} element={<AddOccupant />} />
    <Route path={`${groupName}/:id/edit`} element={<EditOccupant />} />
    <Route path={`${groupName}/manage-occupants`} element={<ManageOccupants />} />
    <Route path={`${groupName}/create-move-in`} element={<CreateMoveIn />} />
    <Route path={`${groupName}/create-move-out`} element={<CreateMoveOut />} />
    <Route path={`${groupName}/:id/ledgers/:ledgerId/issue-credit`} element={<IssueCredit />} />
    <Route path={`${groupName}/:id/ledgers/:ledgerId/issue-refund`} element={<IssueRefund />} />
  </>
);

export default OccupantRoutes;
