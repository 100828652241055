import { Edit, FileDownload, RemoveCircleOutline, RemoveRedEye, SettingsBackupRestore } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import {
  setSelectedDocumentTemplate,
  setShowDeleteDocumentModal
}
  from "src/store/reducers/documentsSlice/documentsSlice";
import { DocumentTemplate } from "src/models/DocumentTemplate";
import { NavLink } from "react-router-dom";
import React from "react";
import {
  getDocumentTemplateChangelog
}
  from "src/store/thunks/documentTemplates/changelog/getDocumentTemplateChangelog";
import moment from "moment";
import {
  setDocumentModalOpened,
  setDocumentRollbackModalOpened
}
  from "src/store/reducers/documentSlice/documentSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { downloadDocumentTemplate } from "src/store/thunks/documentTemplates/download/downloadDocumentTemplate";
import { getByIdDocumentTemplate } from "src/store/thunks/documentTemplates/getById/getByIdDocumentTemplate";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const renderCategory: { [index: number]: string } = {
  1: "Auctions",
  2: "Billing",
  3: "Collections",
  4: "Commercial",
  5: "Insurance",
  6: "Lease",
  7: "Miscellaneous",
  8: "Occupant",
  10: "Receipts",
  11: "Invoices",
  9: "Uncategorized"
};

export const DOCUMENT_TYPE: { [index: number]: string } = {
  1: "E-Sign",
  2: "Email",
  3: "Printed Mail"
};

const getAttachedFacilitiesNamesFromDocument = (document: DocumentTemplate) => {
  const facilities = document.facilities;
  const attachedFacilitiesNames = facilities.map((facility) => facility.name);

  return attachedFacilitiesNames.join(", ");
};

interface Row extends DocumentTemplate {
  id: number;
  operator_id: number;
  name: string;
  category: number;
  type: number;
  content: string;
  version: string;
  version_control_id: number;
  is_latest_version: number;
  rollback_allowed: number;
  is_disabled: number;
  created_at: string;
  updated_at: string;
}

const RenderActionButtons = (documentTemplate: DocumentTemplate) => {
  const dispatch = useAppDispatch();

  const seeChangelog = () => {
    dispatch(setSelectedDocumentTemplate(documentTemplate));
    dispatch(getDocumentTemplateChangelog(documentTemplate.id)).then((resp) => {
      if (!resp.type.includes("rejected")) {
        dispatch(setDocumentModalOpened());
      }
    });
  };

  const download = () => {
    dispatch(downloadDocumentTemplate(documentTemplate.id)).then((resp) =>
      window.open(resp.payload as string, "_blank")
    );
  };

  const revertToPreviousVersion = () => {
    dispatch(getByIdDocumentTemplate(documentTemplate.version_control_id)).then((resp) => {
      if (!resp.type.includes("rejected")) {
        dispatch(setSelectedDocumentTemplate(documentTemplate));
        dispatch(setDocumentRollbackModalOpened());
      }
    });
  };

  const permissions = useAppSelector(selectFacilityPermissions);
  const documentLibraryPermissions = createPermissions(permissions, "document_library");

  return (
    <Grid
      data-testid={"render-details-button"}
      container
      direction={"row"}
      alignItems={"center"}
      justifyContent={"start"}
    >
      {documentLibraryPermissions.update && (
        <>
          <Tooltip title={"Edit Document"}>
            <IconButton size={"small"} to={`/documents/${documentTemplate.id}/edit`} component={NavLink}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Remove Document"}>
            <IconButton
              disabled={Boolean(documentTemplate.is_default)}
              size={"small"}
              data-testid={"disable-document-button"}
              onClick={() => {
                dispatch(setShowDeleteDocumentModal(true));
                dispatch(setSelectedDocumentTemplate(documentTemplate));
              }}
            >
              <RemoveCircleOutline />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Revert to Previous Change"}>
            <IconButton
              size={"small"}
              data-testid={"revert-previous-change"}
              disabled={!documentTemplate.version_control_id || !documentTemplate.rollback_allowed}
              onClick={() => revertToPreviousVersion()}
            >
              <SettingsBackupRestore />
            </IconButton>
          </Tooltip>
        </>
      )}
      {documentLibraryPermissions.read && (
        <>
          <Tooltip title={"Download Document"}>
            <IconButton size={"small"} data-testid={"download-document"} onClick={() => download()}>
              <FileDownload />
            </IconButton>
          </Tooltip>
          <Tooltip title={"View Changelog"}>
            <IconButton size={"small"} onClick={() => seeChangelog()}>
              <RemoveRedEye />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Grid>
  );
};

interface DocumentLibraryPermissions {
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
}

export const DocumentLibraryTableColumns = (documentLibraryPermissions: DocumentLibraryPermissions): GridColDef[] => {
  const columns: GridColDef[] = [
    {
      field: "name",
      renderHeader: () => (
        <Tooltip title={"Document Name"}>
          <Typography>Document Name</Typography>
        </Tooltip>
      ),
      flex: 1,
      minWidth: 200
    },
    {
      field: "category",
      renderHeader: () => (
        <Tooltip title={"Document Category"}>
          <Typography>Category</Typography>
        </Tooltip>
      ),
      flex: 1,
      minWidth: 150,
      renderCell: (cellValues: GridRenderCellParams<any, Row>) => {
        const category = cellValues.row.category;
        return <>{renderCategory[category]}</>;
      }
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 80,
      valueGetter: (params: GridValueGetterParams) => DOCUMENT_TYPE[params.row.type] || "N/A"
    },
    {
      field: "attachedFacilities",
      headerName: "Attached Facilities",
      minWidth: 200,
      sortable: false,
      renderCell: (cellValues: GridRenderCellParams<any, Row>) => (
        <Tooltip title={getAttachedFacilitiesNamesFromDocument(cellValues.row) || "N/A"}>
          <span>{getAttachedFacilitiesNamesFromDocument(cellValues.row) || "N/A"}</span>
        </Tooltip>
      )
    },
    {
      field: "created_at",
      headerName: "Created On",
      minWidth: 110,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.created_at ? moment(params.row.created_at).format("MM/DD/YYYY") : "N/A"
    },
    {
      field: "created_by",
      headerName: "Created By",
      minWidth: 90,
      sortable: false,
      renderCell: (cellValues: GridRenderCellParams<any, Row>) => (
        <Tooltip title={"Created By"}>
          <span></span>
        </Tooltip>
      )
    },
    {
      field: "updated_at",
      headerName: "Last Updated",
      minWidth: 90,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.updated_at ? moment(params.row.updated_at).format("MM/DD/YYYY") : "N/A"
    },
    {
      field: "updated_by",
      headerName: "Updated By",
      minWidth: 90,
      sortable: false,
      renderCell: (cellValues: GridRenderCellParams<any, Row>) => (
        <Tooltip title={"Updated By"}>
          <span></span>
        </Tooltip>
      )
    }
  ];

  if (documentLibraryPermissions.read || documentLibraryPermissions.update) {
    columns.push({
      field: "actions",
      renderHeader: () => (
        <Tooltip title={"Actions"}>
          <Typography>Actions</Typography>
        </Tooltip>
      ),
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams) => RenderActionButtons(row)
    });
  }

  return columns;
};
