import { RootState } from "src/store/rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSearchParams } from "../../../../utils/getSearchParams/getSearchParams";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getChartOfAccountSettings = createAsyncThunk(
  "getChartOfAccountSettings",
  (_, { dispatch, getState, rejectWithValue }) => {
    const store = getState() as RootState;

    const facilityId = store.selectedAdminFacility.selectedAdminFacility?.id;

    const searchParams = {
      params: {
        with: ["revenueClasses"],
        ...getSearchParams(store, true)
      }
    };

    return axios.get(`/api/v1/facilities/${facilityId}/chart-accounts`, searchParams)
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = "There was an error fetching the Chart of Accounts settings";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
