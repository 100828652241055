import React from "react";
import { LoadingButton } from "@mui/lab";
import { Button, Grid, Modal, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";

import useStyles from "./CustomModal.styles";

const CustomModal = ({
  handleSubmit,
  handleClose,
  open,
  hasCancel = true,
  title,
  confirmButtonLabel = "Confirm",
  children
}: any) => {
  const { classes } = useStyles();

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby={"Modal Component"}
      aria-describedby={"A Modal component."}
      className={classes.modal}
      data-testid={"reactivate-cancel-deal"}
  >
      <Grid
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        spacing={2}
        className={classes.container}
        pb={2}>
        <Box className={classes.content}>
          <Typography variant={"h5"} className={classes.title}>{title}</Typography>
          {children}
          <Grid
            pt={4}
            spacing={4}
            container
            justifyContent={"center"}
      >
            {hasCancel && (
            <Grid item>
              <Button
                data-testid={"cancel-button"}
                className={classes.cancelButton}
                onClick={() => handleClose()}
                variant={"outlined"}
                color={"secondary"}
          >
                Cancel
              </Button>
            </Grid>
            )}
            <Grid item>
              <LoadingButton
                data-testid={"confirm-button-modal"}
                color={"primary"}
                variant={"outlined"}
                className={classes.button}
                onClick={() => handleSubmit()}
          >
                {confirmButtonLabel}
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Modal>
  );
};

export default CustomModal;
