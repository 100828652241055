import * as Yup from "yup";

import GateSystems from "src/enums/GateSystems";
import { ID_Type_Enum } from "src/store/reducers/occupantInformationSlice/occupantInformationSlice";
import { passportRegex } from "src/components/stepper/OccupantContactInformation/occupantContactInformationValidation";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().required("E-mail Address is required").email("E-mail Address must be valid"),
  addressOne: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  postalCode: Yup.string()
    .required("Postal Code is required")
    .test("length", "Postal Code must have 5 or 9 digits", (postalCode) => {
      return postalCode?.length === 5 || postalCode?.length === 9;
    }),
  primaryPhone: Yup.string().length(10, "Primary Phone must be 10 digits").required("Primary Phone is required"),
  secondaryPhone: Yup.string()
    .nullable()
    .test({
      name: "secondaryPhone",
      message: "Secondary Phone must be 10 digits",
      test: (value) => {
        if (!value) {
          return true;
        }
        return value.length === 10;
      }
    }),
  idNumber: Yup.string().test({
    name: "idNumber",
    test: function(value) {
      if (!value) {
        return this.createError({ message: "Identification Number is required" });
      }

      if (Number(this.parent?.idType) === ID_Type_Enum.Passport) {
        if (!passportRegex.test(value)) {
          return this.createError({ message: "Passport ID must start with a capital letter followed by 8 digits" });
        }
      }

      return true;
    }
  }),
  idState: Yup.string().nullable(),
  dob: Yup.string().nullable(),
  gateCode: Yup.string().test({
    name: "gateCode",
    test: function(value) {
      if (!value) {
        return this.createError({ message: "Gate code is required" });
      }

      if (parseInt(this.parent?.gateCodeProvider) === GateSystems.PTI && value?.length! > 0) {
        if (!(value?.length! >= 4 && value?.length! <= 8)) {
          return this.createError({ message: "Gate code must be between 4 and 8 digits" });
        }
      }

      if (parseInt(this.parent?.gateCodeProvider) === GateSystems.DOOR_KING && value?.length! > 0) {
        if (!(value?.length! === 5)) {
          return this.createError({ message: "Gate code must have 5 digits" });
        }
      }

      if (parseInt(this.parent?.gateCodeProvider) === GateSystems.STORE_GUARD && value?.length! > 0) {
        if (!(value?.length! >= 5 && value?.length! <= 8)) {
          return this.createError({ message: "Gate code must be between 5 and 8 digits" });
        }
      }

      return true;
    }
  }),
  business: Yup.boolean(),
  businessName: Yup.mixed().when("business", {
    is: true,
    then: Yup.mixed().required("Business name required")
  }),
  militaryStatus: Yup.number().required("Military Status is required"),
  militaryBranch: Yup.number().test({
    name: "militaryBranch",
    test: function(value) {
      if (this.parent.militaryStatus === 4 || this.parent.militaryStatus === 5) {
        if (!value) {
          return this.createError({ message: "Military Branch is required" });
        }
      }
      return true;
    }
  }).nullable(),
  militaryId: Yup.string().test({
    name: "militaryId",
    test: function(value) {
      if (this.parent.militaryStatus === 4 || this.parent.militaryStatus === 5) {
        if (!value) {
          return this.createError({ message: "Military ID is required" });
        }
        if (value?.length! !== 10) {
          return this.createError({ message: "Military ID must be 10 digits" });
        }
      }

      return true;
    }
  }).nullable(),
  idType: Yup.number().required("Please select ID type").test({
    name: "idType",
    test: function(value) {
      if (this.parent.idType === 0) {
        if (!value) {
          return this.createError({ message: "Please select ID type" });
        }
      }
      return true;
    }
  }),
  taxExempt: Yup.boolean(),
  taxExemptDocument: Yup.mixed().test({
    name: "taxExemptDocument",
    message: "Tax Exempt Document is required when Tax Exempt is checked",
    test: function(value) {
      if (this.parent.taxExempt && !value?.name) {
        return this.createError({ message: "Tax Exempt Document is required when Tax Exempt is checked" });
      }
      return true;
    }
  })
});

export default validationSchema;
