import React from "react";
import { Route } from "react-router";
import AddEmergencyContact from "src/pages/Occupants/AddOccupant/AddEmergencyContact/AddEmergencyContact";
import AddOccupantContact
  from "src/pages/Occupants/AddOccupant/AddOccupantContact/AddOccupantContact";
import AddOccupantNameAndLocation
  from "src/pages/Occupants/AddOccupant/AddOccupantNameAndLocation/AddOccupantNameAndLocation";
import GetAddOccupantStarted from "src/pages/Occupants/AddOccupant/GetAddOccupantStarted/GetAddOccupantStarted";
import AddOccupant from "src/pages/Occupants/AddOccupant/AddOccupant";

export const groupName = "occupants/add-occupant";

export const AddOccupantRoutes = (
  <>
    <Route
      path={groupName}
      element={
        <AddOccupant>
          <GetAddOccupantStarted />
        </AddOccupant>
      }
    />
    <Route
      path={`${groupName}/occupant-name-and-location`}
      element={
        <AddOccupant>
          <AddOccupantNameAndLocation />
        </AddOccupant>
      }
    />
    <Route
      path={`${groupName}/occupant-contact`}
      element={
        <AddOccupant>
          <AddOccupantContact />
        </AddOccupant>
      }
    />
    <Route
      path={`${groupName}/emergency-contact`}
      element={
        <AddOccupant>
          <AddEmergencyContact />
        </AddOccupant>
      }
    />
  </>
);

export default AddOccupantRoutes;
