import * as Yup from "yup";

import moment from "moment";

const validationSchema = (minDate: moment.MomentInput) => Yup.object().shape({
  moveOutDate: Yup.string()
    .nullable()
    .required("Move out date is required")
    .test("isValidDate", "A valid date is required (MM/DD/YYYY)", (value) => {
      return moment(value, "MM/DD/YYYY").isValid();
    })
    .test("isBeforeMoveInOrMinDateToday",
      "Move-Out date cannot be before Move-In date, or today if move in date is in past.", (value) => {
        if (minDate) {
          const comparisonDate = moment(minDate).format("MM/DD/YYYY");
          return !moment(value, "MM/DD/YYYY").isBefore(moment(comparisonDate, "MM/DD/YYYY"));
        }
        return true;
      }),
  unrentableReason: Yup.string().when("unrentable", {
    is: true,
    then: Yup.string().required("Unrentable reason is required")
  }),
  noteDescription: Yup.string().when("unrentable", {
    is: true,
    then: Yup.string().max(500).required("Unrentable note is required")
  }),
  applyToUnit: Yup.string().when("showApplyToOtherUnitdDropdown", {
    is: true,
    then: Yup.string().required("Unit is required")
  }),
  refundType: Yup.string().test({
    name: "refundType",
    test: function(value) {
      if (parseFloat(this.parent?.finalBalance) < 0 && !value) {
        return this.createError({ message: "Refund type is required" });
      }

      return true;
    }
  })

});

export default validationSchema;
