import React, { ReactElement } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import EmptyTable from "../../../components/ui/PMSTable/EmptyTable/EmptyTable";
import { MerchandiseTableColumns } from "./MerchandiseTableColumns/MerchandiseTableColumns";
import PMSTable from "../../../components/ui/PMSTable/PMSTable";
import RemoveMerchandiseModal from "../RemoveMerchandiseModal/RemoveMerchandiseModal";
import { getAllMerchandise } from "../../../store/thunks/merchandise/getAll/getAllMerchandise";
import { selectMerchandiseCategories }
  from "../../../store/reducers/merchandiseCategoriesSlice/merchandiseCategoriesSlice";
import { selectMerchandiseItems } from "../../../store/reducers/merchandiseItemsSlice/merchandiseItemsSlice";
import { selectRevenueClasses } from "../../../store/reducers/revenueClassesSlice/revenueClassesSlice";

const MerchandiseTable: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const merchandise = useAppSelector(selectMerchandiseItems);
  const merchandiseCategories = useAppSelector(selectMerchandiseCategories);
  const revenueClasses = useAppSelector(selectRevenueClasses);

  const handlePaginationUpdate = () => {
    dispatch(getAllMerchandise());
  };

  const emptyTable = () => (
    <EmptyTable
      collectionNotFound={`Merchandise`}
      value={"new merchandise"}
      addLink={"/merchandise/create"}
    />
  );

  return (
    <>
      <PMSTable
        data-testid={"merchandise-table"}
        columns={MerchandiseTableColumns(merchandiseCategories, revenueClasses)}
        rows={merchandise}
        emptyTableDisplay={emptyTable}
        onPaginationUpdate={handlePaginationUpdate}
      />
      <RemoveMerchandiseModal />
    </>
  );
};

export default MerchandiseTable;
