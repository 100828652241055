import { makeStyles } from "../../../makeStyles";

// eslint-disable-next-line max-len
const useStyles = makeStyles({ label: "PMSMultiSelect" })((theme) => ({
  fixSelect: {
    maxHeight: "1rem",
    whiteSpace: "unset !important" as "unset"
  }
}));

export default useStyles;
