import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "EditProductTypes" })((theme) => ({
  goBackButton: {
    textTransform: "capitalize",
    color: theme.palette.primary.contrastText
  },
  importButton: {
    textTransform: "capitalize",
    color: theme.palette.primary.contrastText,
    marginLeft: theme.spacing(2)
  }
}));

export default useStyles;
