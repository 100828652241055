import React, { ReactElement } from "react";
import { Typography } from "@mui/material";
import useStyles from "./PaymentStatus.styles";
import { UnitPaymentStatusEnum } from "src/enums/UnitPaymentStatusEnum";

// eslint-disable-next-line max-len
const PaymentStatusText = ({ status } : {status: string}): ReactElement => {
  const { classes } = useStyles();
  return (
    <Typography
      className={`${classes.root}
        ${status === UnitPaymentStatusEnum.CURRENT && classes.current}
        ${status === UnitPaymentStatusEnum.DUE && classes.due}
        ${status === UnitPaymentStatusEnum.PAST_DUE && classes.pastDue}`}
    >
      {status}
    </Typography>
  );
};

export default PaymentStatusText;
