import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const enablePromotion = createAsyncThunk(
  "enablePromotion",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const promotionId = state.selectedPromotion.selectedPromotion?.id;

    return axios.post(`/api/v1/promotions/${promotionId}/enabled`, { is_active: true })
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Promotion successfully enabled.",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = `There was an error enabling this promotion.`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
