import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProductType } from "src/models/ProductType";
import { ProductTypeAttribute } from "src/models/ProductTypeAttribute";
import { ProductTypeSliceState } from "./ProductTypeSliceState";
import { RootState } from "../../rootStore";
import { ToggleArrayBoolean } from "src/models/ToggleArrayBoolean";
import { ValidPropertyType } from "../../../models/ValidPropertyType";
import { createProductType } from "src/store/thunks/productType/create/createProductType";
import { getAllProductTypeAttributes } from "src/store/thunks/productTypeAttributes/getAll/getAllProductTypeAttributes";
import { getProductType } from "../../thunks/productType/get/getProductType";
import { updateProductType } from "../../thunks/productType/update/updateProductType";

export const initialState: ProductTypeSliceState = {
  facilityId: +(sessionStorage.getItem("facilityId") as string) ?? 0,
  productTypeName: sessionStorage.getItem("productTypeName") ?? "",
  propertyType: sessionStorage.getItem("propertyType") as ValidPropertyType ?? "",
  height: sessionStorage.getItem("height") ?? "",
  length: sessionStorage.getItem("length") ?? "",
  width: sessionStorage.getItem("width") ?? "",
  attributes: [],
  price: sessionStorage.getItem("price") ?? "",
  isInsurable: false,
  isHidden: false,
  productTypeLoading: false,
  productTypeError: "",
  productTypeId: parseInt(sessionStorage.getItem("productTypeId") as string) || 0,
  productTypeSubmitted: false,
  productCategoryId: parseInt(sessionStorage.getItem("productCategoryId") as string) || 0,
  leaseDocumentId: 0,
  insuranceDocumentId: 0,
  productTypeProductCategory: JSON.parse(sessionStorage.getItem("productTypeProductCategory") as string) ?? null,
  productTypeProductSubCategory: JSON.parse(sessionStorage.getItem("productTypeProductSubCategory") as string) ?? null,
  revenueClassId: parseInt(sessionStorage.getItem("productCategoryRevenueClassId") as string) || 0,
  productTypeRevenueClass: JSON.parse(sessionStorage.getItem("productTypeRevenueClass") as string) ?? null
};

export const productTypeSlice = createSlice({
  name: "productType",
  initialState,
  reducers: {
    setProductTypeName: (state: ProductTypeSliceState, action: PayloadAction<string>) => {
      state.productTypeName = action.payload;
      sessionStorage.setItem("productTypeName", state.productTypeName);
    },
    setPropertyType: (state: ProductTypeSliceState, action: PayloadAction<ValidPropertyType>) => {
      state.propertyType = action.payload;
      sessionStorage.setItem("propertyType", state.propertyType);
    },
    setHeight: (state: ProductTypeSliceState, action: PayloadAction<string>) => {
      state.height = action.payload;
      sessionStorage.setItem("height", state.height);
    },
    setLength: (state: ProductTypeSliceState, action: PayloadAction<string>) => {
      state.length = action.payload;
      sessionStorage.setItem("length", state.length);
    },
    setWidth: (state: ProductTypeSliceState, action: PayloadAction<string>) => {
      state.width = action.payload;
      sessionStorage.setItem("width", state.width);
    },
    setAttribute: (state: ProductTypeSliceState, action: PayloadAction<ToggleArrayBoolean>) => {
      const index = state.attributes.findIndex(item => item.name === action.payload.key);
      state.attributes[index].value = action.payload.value;
      sessionStorage.setItem("attributes", JSON.stringify(state.attributes));
    },
    setPrice: (state: ProductTypeSliceState, action: PayloadAction<string>) => {
      state.price = action.payload;
      sessionStorage.setItem("price", state.price);
    },
    setRevenueClassId: (state: ProductTypeSliceState, action: PayloadAction<number>) => {
      state.revenueClassId = action.payload;
      sessionStorage.setItem("productCategoryRevenueClassId", JSON.stringify(state.revenueClassId));
    },
    resetProductTypeSlice: (state: ProductTypeSliceState) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("productTypeName");
      sessionStorage.removeItem("propertyType");
      sessionStorage.removeItem("height");
      sessionStorage.removeItem("length");
      sessionStorage.removeItem("width");
      sessionStorage.removeItem("attributes");
      sessionStorage.removeItem("price");
      sessionStorage.removeItem("productTypeId");
      sessionStorage.removeItem("productCategoryId");
      sessionStorage.removeItem("productCategoryRevenueClassId");
      sessionStorage.removeItem("productTypeRevenueClass");
      sessionStorage.removeItem("productTypeProductCategory");
      sessionStorage.removeItem("productTypeProductSubCategory");
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductType.pending, (state) => {
        state.productTypeLoading = true;
      })
      .addCase(getProductType.fulfilled, (state, action) => {
        state.productTypeLoading = false;
        // state.width = action.payload.width.toString();
        // state.length = action.payload.length.toString();
        // state.height = action.payload.height.toString();
        // state.price = action.payload.price.toString();
        state.productTypeName = action.payload.name;
        state.productTypeProductCategory = action.payload.product_category ?? null;
        state.productCategoryId = action.payload.product_category_id;
        state.productTypeProductSubCategory = action.payload.product_category?.sub_categories ?? null;
        state.propertyType = action.payload.type as ValidPropertyType;
        state.revenueClassId = action.payload.revenue_class_id;
        state.productTypeRevenueClass = action.payload.revenue_class ?? null;
        state.attributes = action.payload.attributes;
        state.isInsurable = action.payload.is_insurable;
        state.productTypeId = action.payload.id;
        state.facilityId = action.payload.facility_id;
        state.leaseDocumentId = action.payload.lease_document_id;
        state.insuranceDocumentId = action.payload.insurance_document_id;

        sessionStorage.setItem("productTypeId", state.productTypeId.toFixed());
        sessionStorage.setItem("width", state.width);
        sessionStorage.setItem("length", state.width);
        sessionStorage.setItem("height", state.width);
        sessionStorage.setItem("price", state.width);
        sessionStorage.setItem("productTypeName", state.productTypeName);
        sessionStorage.setItem("propertyType", state.propertyType);
        sessionStorage.setItem("productCategoryRevenueClassId", JSON.stringify(state.revenueClassId));
        sessionStorage.setItem("productTypeRevenueClass", JSON.stringify(state.revenueClassId));
        sessionStorage.setItem("productTypeProductCategory", JSON.stringify(state.productTypeProductCategory));
        sessionStorage.setItem("productCategoryId", JSON.stringify(state.productCategoryId));
        sessionStorage.setItem("productTypeProductSubCategory", JSON.stringify(state.productTypeProductSubCategory));
        sessionStorage.setItem("attributes", JSON.stringify(state.attributes));
      })
      .addCase(getProductType.rejected, (state, action) => {
        state.productTypeLoading = false;
        state.productTypeError = action.payload as string;
      })
      .addCase(createProductType.pending, (state) => {
        state.productTypeLoading = true;
        state.productTypeSubmitted = true;
      })
      .addCase(createProductType.fulfilled, (state, action: PayloadAction<ProductType>) => {
        state.productTypeLoading = false;
        state.productTypeId = action.payload.id;
        sessionStorage.setItem("productTypeId", state.productTypeId.toFixed());

        if (action.payload.product_category_id) {
          state.productCategoryId = action.payload.product_category_id;
          sessionStorage.setItem("productCategoryId", state.productCategoryId.toFixed());
        }
      })
      .addCase(createProductType.rejected, (state, action) => {
        state.productTypeLoading = false;
        state.productTypeError = action.payload as string;
      })
      .addCase(getAllProductTypeAttributes.pending, (state) => {
        state.productTypeLoading = true;
      })
      .addCase(getAllProductTypeAttributes.fulfilled, (state, action) => {
        state.productTypeLoading = false;

        state.attributes = action.payload.data.map((attribute: ProductTypeAttribute) => {
          return { ...attribute, value: false };
        });

        sessionStorage.setItem("attributes", JSON.stringify(state.attributes));
      })
      .addCase(getAllProductTypeAttributes.rejected, (state, action) => {
        state.productTypeLoading = false;
        state.productTypeError = action.payload as string;
      })
      .addCase(updateProductType.rejected, (state, action) => {
        state.productTypeLoading = false;
        state.productTypeError = action.payload as string;
      })
      .addCase(updateProductType.fulfilled, (state, action) => {
        state.productTypeLoading = false;
        state.productTypeName = action.payload.name;
        state.productCategoryId = action.payload.product_category_id;
        state.revenueClassId = action.payload.revenue_class_id;
        state.isInsurable = action.payload.is_insurable;
        state.leaseDocumentId = action.payload.lease_document_id;
        state.insuranceDocumentId = action.payload.insurance_document_id;
      })
      .addCase(updateProductType.pending, (state) => {
        state.productTypeLoading = true;
      });
  }
});

export const selectProductTypeName = (state: RootState) => state.productType.productTypeName;
export const selectPropertyType = (state: RootState) => state.productType.propertyType;
export const selectProductTypeLoading = (state: RootState) => state.productType.productTypeLoading;
export const selectProductTypeId = (state: RootState) => state.productType.productTypeId;
export const selectProductTypeSubmitted = (state: RootState) => state.productType.productTypeSubmitted;
export const selectHeight = (state: RootState) => state.productType.height;
export const selectLength = (state: RootState) => state.productType.length;
export const selectWidth = (state: RootState) => state.productType.width;
export const selectAttributes = (state: RootState) => state.productType.attributes;
export const selectPrice = (state: RootState) => state.productType.price;
export const selectRevenueClassId = (state: RootState) => state.productType.revenueClassId;
export const selectProductCategoryId = (state: RootState) => state.productType.productCategoryId;
export const selectIsInsurable = (state: RootState) => state.productType.isInsurable;
export const selectProductTypeRevenueClass = (state: RootState) => state.productType.productTypeRevenueClass;
export const selectProductType = (state: RootState) => {
  const {
    productTypeLoading,
    productTypeError,
    productTypeSubmitted,
    productTypeProductCategory,
    productTypeRevenueClass,
    productTypeProductSubCategory,
    propertyType,
    productTypeName: name,
    productCategoryId,
    attributes,
    ...productTypeData
  } = state.productType;

  return {
    ...productTypeData,
    name
  };
};

export const {
  setProductTypeName,
  setPropertyType,
  resetProductTypeSlice,
  setHeight,
  setLength,
  setWidth,
  setAttribute,
  setPrice,
  setRevenueClassId
} = productTypeSlice.actions;

export default productTypeSlice.reducer;
