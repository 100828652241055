import React, { FC } from "react";
import { Deal } from "src/models/Deal";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { IconButton, Tooltip } from "@mui/material";
import { getDeal } from "src/store/thunks/deals/get/getDeal";
import { getMoveInCost } from "src/store/thunks/moveIn/getMoveInCost/getMoveInCost";
import { selectIsTransfer, setMonthlyRate } from "src/store/reducers/moveInSlice/moveInSlice";
import {
  setOccupantDataFromDeal
} from "src/store/reducers/occupantInformationSlice/occupantInformationSlice";
import { setSelectedUnit } from "src/store/reducers/selectedUnitSlice/selectedUnitSlice";
import { setTargetPath } from "src/store/reducers/navigationSlice/navigationSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { useLocation, useNavigate } from "react-router";
import { getTransferUnitCost } from "src/store/thunks/transfer/transferCost/getTransferUnitCost";
import { DealTypeStringEnum } from "src/enums/DealType";

interface ReservationMoveInButtonProps {
  deal: Deal;
}

const ReservationMoveInButton: FC<ReservationMoveInButtonProps> = ({ deal }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isTransfer = useAppSelector(selectIsTransfer);

  const updateSummary = () => {
    if (isTransfer) {
      return dispatch(getTransferUnitCost());
    }

    dispatch(getMoveInCost());
  };

  const moveInReservation = async() => {
    await dispatch(getDeal({ facilityId: deal.facility_id, dealId: deal.id }));

    const dealType = typeof deal.deal_type !== "number" ? deal.deal_type.toLowerCase() : deal.deal_type;

    let nextPath = "";

    if (dealType === 2 || dealType === DealTypeStringEnum.RESERVATION) {
      if (deal.occupant_id) {
        nextPath = "/occupants/create-move-in/process-move-in";
      } else {
        nextPath = "/occupants/create-move-in/occupant-name-and-location";
      }

      if (deal.unit?.is_rented) {
        nextPath = "/occupants/create-move-in/select-unit";
        dispatch(setSelectedUnit(null));
      } else {
        dispatch(setSelectedUnit(deal.unit ?? null));
      }
    }

    dispatch(setMonthlyRate(String(deal.quoted_price)));
    dispatch(setOccupantDataFromDeal(deal));

    updateSummary();
    dispatch(setTargetPath(location.pathname));
    navigate(nextPath, {
      state: {
        isMoveInFromDeals: true
      }
    });
  };

  return (
    <Tooltip title={"Reservation Move In"}>
      <IconButton onClick={moveInReservation} data-testid={"reservation-move-in-button"} size={"small"}>
        <ExitToAppOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ReservationMoveInButton;
