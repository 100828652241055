import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const rollbackDocumentTemplate = createAsyncThunk(
  "rollbackDocumentTemplate",
  (id: number, { dispatch, getState, rejectWithValue }) => {
    return axios.delete(`/api/v1/document-templates/rollback/${id}`)
      .then(resp => {
        dispatch(showSnackbar({
          message: "Document Template rollbacked to the previous version",
          variant: "success"
        }));

        return resp.data;
      })
      .catch(() => {
        const errorMessage = "There was an error on Document Template Rollback";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
