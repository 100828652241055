import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { RootState } from "src/store/rootStore";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const updateMiscellaneousSettings = createAsyncThunk(
  "updateMiscellaneousSettings",
  (reservationDateLimit: number,
    { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const facilityId = state.facility.id;

    const payload = {
      reservation_date_limit: reservationDateLimit
    };

    return axios.post(`/api/v1/facilities/${facilityId}/miscellaneous-settings`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: `Settings updated successfully`,
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = `There was an error updating the settings`;

        const snackbarPayload = {
          message: errorMessage,
          variant: "error"
        };

        dispatch(showSnackbar(snackbarPayload));
        return rejectWithValue(snackbarPayload.message);
      });
  }
);
