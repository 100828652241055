import { FormikTouched, useFormik } from "formik";
import { Grid, InputLabel, SelectChangeEvent, TextField } from "@mui/material";
import React, { ChangeEvent, FormEvent, ReactElement, useEffect } from "react";
import {
  resetAchSlice,
  selectAccountName,
  selectAccountNumber,
  selectAccountNumberConfirm,
  selectAccountNumberConfirmMasked,
  selectAccountNumberMasked,
  selectAccountType,
  selectBankName,
  selectCheckNumber,
  selectRoutingNumber,
  setAccountName,
  setAccountNumber,
  setAccountNumberConfirm,
  setAccountNumberConfirmMasked,
  setAccountNumberMasked,
  setAccountType,
  setBankName,
  setCheckNumber,
  setRoutingNumber
} from "../../../store/reducers/achSlice/achSlice";
import {
  selectAchBackup,
  selectAchForm,
  setAchBackup,
  setAchForm
} from "src/store/reducers/changeOwnership/changeOwnership";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import { PaymentMethod as PaymentMethodEnum } from "src/enums/PaymentMethod";
import PaymentOptions from "../PaymentOptions/PaymentOptions";
import accountTypes from "./accountTypes";
import achFormValidation from "./achFormValidation";
import { inputError } from "../../../utils/showInputError/showInputError";
import { selectChangeOwnershipIsEnabled } from "src/store/reducers/occupantSlice/occupantSlice";
import { selectFirstName } from "src/store/reducers/occupantInformationSlice/occupantInformationSlice";
import useStyles from "./ACHForm.styles";

export const accountNumbersMatch = (
  touched: FormikTouched<{ accountNumberConfirm: boolean }>,
  accountNumberConfirm: string,
  accountNumber: string
) => {
  let error = false;
  let helperText = "";

  if (touched.accountNumberConfirm && accountNumberConfirm.length && accountNumberConfirm !== accountNumber) {
    error = true;
    helperText = "Account Numbers do not match";
  }

  return { error, helperText };
};

export const showAccountNumberHelperText = (
  touched: FormikTouched<{ accountNumberConfirm: boolean }>,
  accountNumberConfirm: string,
  accountNumber: string
) =>
  accountNumbersMatch(touched, accountNumberConfirm, accountNumber).error &&
  accountNumbersMatch(touched, accountNumberConfirm, accountNumber).helperText;

interface ACHFormProps {
  handleSubmit: () => void;
  formId: string;
  disableSave?: boolean;
  withPaymentOptions?: boolean;
};

export const maskAccountNumber = (accountNumber: string) => "*".repeat(accountNumber?.length);

const ACHForm: React.FC<ACHFormProps> = ({ handleSubmit, formId, disableSave, withPaymentOptions }): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const routingNumber = useAppSelector(selectRoutingNumber);
  const bankName = useAppSelector(selectBankName);
  const accountNumber = useAppSelector(selectAccountNumber);
  const accountNumberConfirm = useAppSelector(selectAccountNumberConfirm);
  const accountNumberMasked = useAppSelector(selectAccountNumberMasked);
  const accountNumberConfirmMasked = useAppSelector(selectAccountNumberConfirmMasked);
  const accountName = useAppSelector(selectAccountName);
  const checkNumber = useAppSelector(selectCheckNumber);
  const accountType = useAppSelector(selectAccountType);
  const changeOwnershipIsEnabled = useAppSelector(selectChangeOwnershipIsEnabled);
  const achChangeOwnershipForm = useAppSelector(selectAchForm);
  const achChangeOwnershipBackup = useAppSelector(selectAchBackup);

  // todo: update the selector to render accountType in occupant Billing Information for a better one in the future
  const isOccupantBillingInformation = useAppSelector(selectFirstName);

  const formik = useFormik({
    initialValues: {
      routingNumber: routingNumber,
      bankName: bankName,
      accountNumber: accountNumberMasked,
      accountNumberConfirm: accountNumberConfirmMasked,
      accountName: accountName,
      checkNumber: checkNumber,
      accountType: accountType,
      // to check if we're in occupant billing information or not
      isOccupantBillingInformation: isOccupantBillingInformation.length > 0
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: achFormValidation,
    /* istanbul ignore next */
    onSubmit: () => {
      handleSubmit();
    }
  });

  const { touched, values, errors, resetForm, initialValues } = formik;

  useEffect(() => {
    return () => {
      !isOccupantBillingInformation && dispatch(resetAchSlice());
    };
  }, []);

  const updateForm = (fieldName: string, fieldValue?: string | boolean | number): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleRoutingNumberOnChange = (fieldName: string, fieldValue: string) => {
    if (fieldValue.length <= 9) {
      updateForm(fieldName, fieldValue);
      if (changeOwnershipIsEnabled) {
        dispatch(setAchForm({ ...achChangeOwnershipForm, routingNumber: fieldValue }));
      } else {
        dispatch(setRoutingNumber(fieldValue));
      }
    }
  };

  const handleBankNameOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    if (changeOwnershipIsEnabled) {
      dispatch(setAchForm({ ...achChangeOwnershipForm, bankName: fieldValue }));
    } else {
      dispatch(setBankName(fieldValue));
    }
  };

  const handleAccountNameOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    if (changeOwnershipIsEnabled) {
      dispatch(setAchForm({ ...achChangeOwnershipForm, accountName: fieldValue }));
    } else {
      dispatch(setAccountName(fieldValue));
    }
  };

  const handleAccountNumberOnChange = (fieldName: string, fieldValue: string) => {
    if (fieldValue.match(/^[0-9]*$/)) {
      updateForm(fieldName, fieldValue);
      updateForm("accountNumberActual", fieldValue);
      if (changeOwnershipIsEnabled) {
        dispatch(setAchForm({ ...achChangeOwnershipForm, accountNumber: fieldValue }));
      } else {
        dispatch(setAccountNumber(fieldValue));
      }
    }
  };

  const handleAccountNumberOnFocus = () => {
    if (changeOwnershipIsEnabled) {
      updateForm("accountNumber", achChangeOwnershipForm.accountNumber);
      dispatch(setAchForm({ ...achChangeOwnershipForm, accountNumber: achChangeOwnershipForm.accountNumber }));
    } else {
      updateForm("accountNumber", accountNumber);
      dispatch(setAccountNumber(accountNumber));
    }
  };

  const handleAccountNumberOnBlur = () => {
    if (changeOwnershipIsEnabled) {
      updateForm("accountNumber", maskAccountNumber(achChangeOwnershipForm.accountNumber));
      dispatch(
        setAchForm({
          ...achChangeOwnershipForm,
          accountNumberMasked: maskAccountNumber(achChangeOwnershipForm.accountNumber)
        })
      );
    } else {
      updateForm("accountNumber", maskAccountNumber(accountNumber));

      dispatch(setAccountNumberMasked(maskAccountNumber(accountNumber)));
    }
  };

  const handleAccountNumberConfirmOnChange = (fieldName: string, fieldValue: string) => {
    if (fieldValue.match(/^[0-9]*$/)) {
      updateForm(fieldName, fieldValue);
      updateForm("accountNumberConfirmActual", fieldValue);
      if (changeOwnershipIsEnabled) {
        dispatch(setAchForm({ ...achChangeOwnershipForm, accountNumberConfirm: fieldValue }));
      } else {
        dispatch(setAccountNumberConfirm(fieldValue));
      }
    }
  };

  const handleAccountNumberConfirmOnFocus = () => {
    if (changeOwnershipIsEnabled) {
      updateForm("accountNumberConfirm", achChangeOwnershipForm.accountNumberConfirm);
      dispatch(
        setAchForm({
          ...achChangeOwnershipForm,
          accountNumberConfirm: achChangeOwnershipForm.accountNumberConfirm
        })
      );
    } else {
      updateForm("accountNumberConfirm", accountNumberConfirm);
      dispatch(setAccountNumberConfirm(accountNumberConfirm));
    }
  };

  const handleAccountNumberConfirmOnBlur = () => {
    if (changeOwnershipIsEnabled) {
      updateForm("accountNumberConfirm", maskAccountNumber(achChangeOwnershipForm.accountNumberConfirm));
      dispatch(
        setAchForm({
          ...achChangeOwnershipForm,
          accountNumberConfirmMasked: maskAccountNumber(achChangeOwnershipForm.accountNumberConfirm)
        })
      );
    } else {
      updateForm("accountNumberConfirm", maskAccountNumber(accountNumberConfirm));
      dispatch(setAccountNumberConfirmMasked(maskAccountNumber(accountNumberConfirm)));
    }
  };

  const handleCheckNumberOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    if (changeOwnershipIsEnabled) {
      dispatch(setAchForm({ ...achChangeOwnershipForm, checkNumber: fieldValue }));
    } else {
      dispatch(setCheckNumber(fieldValue));
    }
  };

  const handleAccountTypeOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    if (changeOwnershipIsEnabled) {
      dispatch(setAchForm({ ...achChangeOwnershipForm, accountType: fieldValue }));
    } else {
      dispatch(setAccountType(fieldValue));
    }
  };

  useEffect(() => {
    if (changeOwnershipIsEnabled) {
      if (initialValues.accountName && !achChangeOwnershipForm.accountName) {
        const values = {
          routingNumber: initialValues.routingNumber,
          bankName: initialValues.bankName,
          accountNumber: initialValues.accountNumber,
          accountNumberConfirm: initialValues.accountNumberConfirm,
          accountName: initialValues.accountName,
          checkNumber: initialValues.checkNumber,
          accountType: initialValues.accountType
        };

        dispatch(setAchBackup(values));
      }

      const form = {
        routingNumber: achChangeOwnershipForm.routingNumber,
        bankName: achChangeOwnershipForm.bankName,
        accountNumber: achChangeOwnershipForm.accountNumber,
        accountNumberConfirm: achChangeOwnershipForm.accountNumberConfirm,
        accountName: achChangeOwnershipForm.accountName,
        checkNumber: achChangeOwnershipForm.checkNumber,
        accountType: achChangeOwnershipForm.accountType,
        isOccupantBillingInformation: initialValues.isOccupantBillingInformation
      };

      resetForm({
        values: form
      });
    } else {
      if (achChangeOwnershipBackup.accountName) {
        const form = {
          routingNumber: achChangeOwnershipBackup.routingNumber,
          bankName: achChangeOwnershipBackup.bankName,
          accountNumber: achChangeOwnershipBackup.accountNumber,
          accountNumberConfirm: achChangeOwnershipBackup.accountNumberConfirm,
          accountName: achChangeOwnershipBackup.accountName,
          checkNumber: achChangeOwnershipBackup.checkNumber,
          accountType: achChangeOwnershipBackup.accountType,
          isOccupantBillingInformation: initialValues.isOccupantBillingInformation
        };

        resetForm({ values: form });
      }
    }
  }, [changeOwnershipIsEnabled]);

  return (
    <Grid
      container
      direction={"column"}
      spacing={2}
      component={"form"}
      id={formId}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        formik.handleSubmit();
      }}
    >
      <Grid item container spacing={2}>
        <Grid item xs={6} lg={3}>
          <PMSSelect
            id={"account-type"}
            data-testid={"account-type"}
            name={"accountType"}
            value={values.accountType}
            changeHandler={(e: SelectChangeEvent<any>) => handleAccountTypeOnChange("accountType", e.target.value)}
            label={"Account Type"}
          >
            <option disabled value={""}>
              Account Type
            </option>
            {accountTypes.map((accType) => (
              <option key={accType.value} value={accType.value}>
                {accType.label}
              </option>
            ))}
          </PMSSelect>
        </Grid>

        <Grid item xs={12} lg={4}
          order={{ xs: 1, lg: 1 }}>
          <InputLabel htmlFor={"routing-number"}>Routing Number</InputLabel>
          <TextField
            className={classes.noArrow}
            fullWidth
            placeholder={"Routing Number"}
            id={"routing-number"}
            type={"number"}
            inputProps={{
              "data-testid": "routing-number",
              maxLength: 9
            }}
            name={"routingNumber"}
            value={values.routingNumber}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleRoutingNumberOnChange("routingNumber", e.target.value)
            }
            error={inputError("routingNumber", touched, errors).error}
            helperText={inputError("routingNumber", touched, errors).helperText}
          />
        </Grid>
        <Grid item xs={12} lg={5}
          order={{ xs: 2, lg: 2 }}>
          <InputLabel htmlFor={"bank-name"}>Bank Name</InputLabel>
          <TextField
            className={classes.noArrow}
            fullWidth
            placeholder={"Bank Name"}
            id={"bank-name"}
            inputProps={{ "data-testid": "bank-name" }}
            name={"bankName"}
            value={values.bankName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleBankNameOnChange("bankName", e.target.value)}
            error={inputError("bankName", touched, errors).error}
            helperText={inputError("bankName", touched, errors).helperText}
          />
        </Grid>
        <Grid item xs={12} lg={7}
          order={{ xs: 3, lg: 3 }}>
          <InputLabel htmlFor={"account-number"}>Account Number</InputLabel>
          <TextField
            className={classes.noArrow}
            fullWidth
            placeholder={"Account Number"}
            id={"account-number"}
            inputProps={{ "data-testid": "account-number" }}
            name={"accountNumber"}
            value={values.accountNumber}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleAccountNumberOnChange("accountNumber", e.target.value)
            }
            onFocus={() => handleAccountNumberOnFocus()}
            onBlur={() => handleAccountNumberOnBlur()}
            error={
              accountNumbersMatch(touched, accountNumberConfirm, accountNumber).error ||
              inputError("accountNumber", touched, errors).error
            }
            helperText={
              showAccountNumberHelperText(touched, accountNumberConfirm, accountNumber) ||
              inputError("accountNumber", touched, errors).helperText
            }
          />
        </Grid>
        <Grid item xs={12} lg={5}
          order={{ xs: 5, lg: 4 }}>
          <InputLabel htmlFor={"account-name"}>Name on Account</InputLabel>
          <TextField
            className={classes.noArrow}
            fullWidth
            placeholder={"Name on Account"}
            id={"account-name"}
            inputProps={{ "data-testid": "account-name" }}
            name={"accountName"}
            value={values.accountName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleAccountNameOnChange("accountName", e.target.value)}
            error={inputError("accountName", touched, errors).error}
            helperText={inputError("accountName", touched, errors).helperText}
          />
        </Grid>
        <Grid item xs={12} lg={7}
          order={{ xs: 4, lg: 5 }}>
          <InputLabel htmlFor={"account-number-confirm"}>Confirm Account Number</InputLabel>
          <TextField
            className={classes.noArrow}
            fullWidth
            placeholder={"Confirm Account Number"}
            id={"account-number-confirm"}
            inputProps={{ "data-testid": "account-number-confirm" }}
            name={"accountNumber"}
            value={values.accountNumberConfirm}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleAccountNumberConfirmOnChange("accountNumberConfirm", e.target.value)
            }
            onFocus={() => handleAccountNumberConfirmOnFocus()}
            onBlur={() => handleAccountNumberConfirmOnBlur()}
            error={
              accountNumbersMatch(touched, accountNumberConfirm, accountNumber).error ||
              inputError("accountNumberConfirm", touched, errors).error
            }
            helperText={
              showAccountNumberHelperText(touched, accountNumberConfirm, accountNumber) ||
              inputError("accountNumberConfirm", touched, errors).helperText
            }
          />
        </Grid>
        <Grid item xs={12} lg={5}
          order={{ xs: 6, lg: 6 }}>
          <InputLabel htmlFor={"check-number"}>Check Number</InputLabel>
          <TextField
            className={classes.noArrow}
            fullWidth
            placeholder={"Check Number"}
            id={"check-number"}
            type={"number"}
            inputProps={{ "data-testid": "check-number" }}
            name={"checkNumber"}
            value={values.checkNumber}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleCheckNumberOnChange("checkNumber", e.target.value)}
            error={inputError("checkNumber", touched, errors).error}
            helperText={inputError("checkNumber", touched, errors).helperText}
          />
        </Grid>
      </Grid>
      {withPaymentOptions && (
        <Grid m={1}>
          <PaymentOptions disabled={disableSave} paymentMethod={PaymentMethodEnum.ach}/>
        </Grid>
      )}

    </Grid>
  );
};

export default ACHForm;
