import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "ReviewLayout" })((theme) => ({
  sideColumn: {
    top: "1rem",
    position: "sticky"
  }
}));

export default useStyles;
