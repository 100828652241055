import React from "react";
import { Tooltip, Typography } from "@mui/material";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import EmptyTable from "src/components/ui/PMSTable/EmptyTable/EmptyTable";
import PMSTable from "src/components/ui/PMSTable/PMSTable";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  selectOccupantHistory,
  selectOccupantHistoryLoading
} from "src/store/reducers/occupantHistorySlice/occupantHistorySlice";
import { selectOccupant } from "src/store/reducers/occupantSlice/occupantSlice";
import { getOccupantHistory } from "src/store/thunks/occupantHistory/getAll/getOccupantHistory";
import { getKeyByValue } from "src/utils/getKeyByValue/getKeyByValue";
import moment from "moment";

const OccupantHistoryTableColumns = (): GridColDef[] => [
  {
    field: "action",
    renderHeader: () => (
      <Tooltip title={"Action"}>
        <Typography>Action</Typography>
      </Tooltip>
    ),
    minWidth: 170,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.action
  },
  {
    field: "created_at",
    renderHeader: () => (
      <Tooltip title={"Date & Time"}>
        <Typography>Date & Time</Typography>
      </Tooltip>
    ),
    minWidth: 170,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => moment(params.row.created_at).format("MM/DD/YYYY hh:mm A")
  }
];

const OccupantHistoryTable = ({ newest, searchValue }: { newest: boolean; searchValue: string }) => {
  const dispatch = useAppDispatch();
  const selectedOccupant = useAppSelector(selectOccupant);
  const history = useAppSelector(selectOccupantHistory);
  const loading = useAppSelector(selectOccupantHistoryLoading);

  const handlePaginationUpdate = () => {
    dispatch(getOccupantHistory({ occupantId: selectedOccupant?.id!, newest, searchValue }));
  };

  const emptyTable = () => <EmptyTable collectionNotFound={`documents`} value={"document"} />;

  return (
    <PMSTable
      columns={OccupantHistoryTableColumns()}
      rows={history}
      loading={loading}
      emptyTableDisplay={emptyTable}
      onPaginationUpdate={handlePaginationUpdate}
    />
  );
};

export default OccupantHistoryTable;
