import { DeleteBillingInformationContactPayload } from "./DeleteBillingInformationContactPayload";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const deleteBillingContact = createAsyncThunk(
  "deleteBillingContact",
  (payload: DeleteBillingInformationContactPayload, { dispatch, rejectWithValue }) => {
    const { occupantId, contactId } = payload;

    return axios.delete(`/api/v1/occupants/${occupantId}/billing-contacts/${contactId}`)
      .then(() => {
        dispatch(showSnackbar({
          message: `Billing Contact successfully deleted`,
          variant: "success"
        }));
      })
      .catch(() => {
        const errorMessage = `There was an error deleting this Billing Contact`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
