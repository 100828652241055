import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "FooterButtons" })((theme) => ({
  buttonBase: {
    textTransform: "capitalize",
    marginLeft: theme.spacing(2)
  },
  cancelButton: {
    color: theme.palette.primary.contrastText
  },
  saveButton: {
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText
  }
}));

export default useStyles;
