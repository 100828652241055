import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { APIListResponse } from "../../../models/responses/APIListResponse";
import { HttpProblem } from "src/models/HttpProblem";
import { RootState } from "../../rootStore";
import { Unit } from "../../../models/Unit";
import { UnitsSliceState } from "./UnitsSliceState";
import { getAllUnits } from "../../thunks/unit/getAll/getAllUnits";
import {
  getAvailableUnitsByProductType
} from "../../thunks/unit/getAvailableUnitsByProductType/getAvailableUnitsByProductType";
import { getUnitsByProductType } from "src/store/thunks/unit/getUnitsByProductType/getUnitsByProductType";
import { getUnitSizeList } from "src/store/thunks/unit/getUnitSizeList/getUnitSizeList";

export const initialState: UnitsSliceState = {
  unitsLoading: false,
  unitsError: "",
  units: JSON.parse(sessionStorage.getItem("units") as string) ?? [],
  unitUnrentableModalOpen: false,
  unitRowEditableIndex: null,
  expansionTableUnits: {},
  unitSizeList: []
};

export const unitsSlice = createSlice({
  name: "units",
  initialState,
  reducers: {
    setUnitRowEditableIndex: (state, action) => {
      state.unitRowEditableIndex = action.payload;
    },
    resetUnitRowEditableIndex: (state, action) => {
      state.unitRowEditableIndex = initialState.unitRowEditableIndex;
    },
    setUnitUnrentableModalOpen: (state, action) => {
      state.unitUnrentableModalOpen = action.payload;
    },
    resetUnitsSlice: (state) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("units");
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUnits.pending, (state) => {
        state.unitsLoading = true;
      })
      .addCase(getAllUnits.fulfilled, (state, action: PayloadAction<APIListResponse<Unit[]>>) => {
        state.units = action.payload.data;
        sessionStorage.setItem("units", JSON.stringify(action.payload.data));
        state.unitsLoading = false;
      })
      .addCase(getAllUnits.rejected, (state, action) => {
        state.unitsError = action.payload as string;
        state.unitsLoading = false;
      })
      .addCase(getAvailableUnitsByProductType.pending, (state) => {
        state.unitsLoading = true;
      })
      .addCase(getAvailableUnitsByProductType.fulfilled, (state: UnitsSliceState, action: PayloadAction<Unit[]>) => {
        state.unitsLoading = false;

        if (!action.payload.length) {
          return;
        }

        state.expansionTableUnits[action.payload[0].product_type_id] = action.payload;
      })
      .addCase(getAvailableUnitsByProductType.rejected, (state: UnitsSliceState, action: PayloadAction<unknown>) => {
        state.unitsLoading = false;
        state.unitsError = action.payload as HttpProblem;
      })
      .addCase(getUnitsByProductType.pending, (state) => {
        state.unitsLoading = true;
      })
      .addCase(
        getUnitsByProductType.fulfilled,
        (state: UnitsSliceState, action: PayloadAction<APIListResponse<Unit[]>>) => {
          state.unitsLoading = false;
          state.units = action.payload.data;
          sessionStorage.setItem("units", JSON.stringify(action.payload));
        }
      )
      .addCase(getUnitsByProductType.rejected, (state: UnitsSliceState, action: PayloadAction<unknown>) => {
        state.unitsLoading = false;
        state.unitsError = action.payload as HttpProblem;
      })
      .addCase(getUnitSizeList.fulfilled, (state, action) => {
        state.unitSizeList = action.payload;
      });
  }
});

export const selectUnits = (state: RootState) => state.units.units;
export const selectExpansionTableUnits = (state: RootState) => state.units.expansionTableUnits;
export const selectUnitsLoading = (state: RootState) => state.units.unitsLoading;
export const selectUnitUnrentableModalOpen = (state: RootState) => state.units.unitUnrentableModalOpen;
export const selectUnitRowEditableIndex = (state: RootState) => state.units.unitRowEditableIndex;
export const selectUnitSizeList = (state: RootState) => state.units.unitSizeList;

export const { resetUnitsSlice, resetUnitRowEditableIndex, setUnitRowEditableIndex, setUnitUnrentableModalOpen } =
  unitsSlice.actions;

export default unitsSlice.reducer;
