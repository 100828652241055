
import { makeStyles } from "../../makeStyles";

const useStyles = makeStyles({ label: "Edit Occupant" })((theme) => ({
  tabContainer: { width: "100%", typography: "body1" },
  noPad: {
    padding: 0
  }
}));

export default useStyles;
