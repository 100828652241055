import { Edit, RemoveCircle } from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { NavLink } from "react-router-dom";
import React from "react";
import { ProductType } from "src/models/ProductType";
import {
  setDeleteProductTypeModalOpen,
  setProductToDelete
} from "src/store/reducers/productTypesSlice/productTypesSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  createPermissions,
  hasPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

interface ProductTypesRenderDetailsButtonProps {
  row: ProductType;
}

const RenderDetailsButton: React.FC<ProductTypesRenderDetailsButtonProps> = ({ row }) => {
  const dispatch = useAppDispatch();
  const permissions = useAppSelector(selectFacilityPermissions);
  const productTypesPermissions = createPermissions(permissions, "product_types");

  return (
    <Grid container direction={"row"} alignItems={"center"}
      justifyContent={"start"}>
      {productTypesPermissions.update && (
      <Tooltip title={"Edit Product Type"}>
        <IconButton
          data-testid={"edit-product-type-button"}
          size={"small"}
          to={`/product-types/${row.id}/edit`}
          component={NavLink}
        >
          <Edit />
        </IconButton>
      </Tooltip>
      )}
      {!row.units?.some((unit) => unit.is_rented) && productTypesPermissions.delete && (
        <Tooltip title={"Disable Product Type"}>
          <IconButton
            size={"small"}
            data-testid={"disable-product-type-button"}
            onClick={() => {
              dispatch(setDeleteProductTypeModalOpen(true));
              dispatch(setProductToDelete(row.id));
            }}
          >
            <RemoveCircle />
          </IconButton>
        </Tooltip>
      )}
    </Grid>
  );
};

const ManageProductTypeTableColumns = (actionPermissions: Boolean): GridColDef[] => [
  {
    field: "name",
    headerName: "Product Type",
    minWidth: 170,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params.row.name || "N/A"}>
        <span>{params.row.name || "N/A"}</span>
      </Tooltip>
    )
  },
  {
    field: "total_units",
    headerName: "Total Units",
    minWidth: 120,
    sortable: false,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "revenue_class",
    headerName: "Revenue Class",
    minWidth: 100,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params.row.revenue_class?.name || "N/A"}>
        <span>{params.row.revenue_class?.name || "N/A"}</span>
      </Tooltip>
    )
  },
  {
    field: "lease_attached",
    headerName: "Lease Attached",
    minWidth: 100,
    sortable: false,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params.row.lease_document?.name || "N/A"}>
        <span>{params.row.lease_document?.name || "N/A"}</span>
      </Tooltip>
    )
  },
  actionPermissions
    ? {
        field: "actionIcons",
        headerName: "Actions",
        sortable: false,
        minWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => RenderDetailsButton(params)
      }
    : null
].filter(Boolean) as GridColDef[];

export default ManageProductTypeTableColumns;
