import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";
import { RootState } from "src/store/rootStore";

export const editKeypadAccess = createAsyncThunk(
  "createUnitNew",
  (values: {zone: string, accessName: string},
    { dispatch, rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const facilityId = state.facility.facility!.id;
    const selectedAccessTable = state.facility.selectedAccessTable;

    const payload = {
      zone: values.zone,
      name: values.accessName
    };

    return axios.put(`/api/v1/facilities/${facilityId}/keypad-accesses/${selectedAccessTable?.id}`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Successfully edited Keypad Access",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = err.response.data?.detail;

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = err.response?.data?.detail || `There was an error editing the Keypad Access`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
