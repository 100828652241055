import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles<{ showDrawer: boolean }>({ label: "NavigationLink" })((theme, {
  showDrawer
}) => ({
  topLink: {
    minHeight: "3rem"
  },
  dropdownLink: {
    paddingLeft: theme.spacing(10)
  },
  selectedLink: {
    background: showDrawer ? theme.palette.primary.light : "inherit"
  },
  link: {
    textDecoration: "none",
    color: theme.palette.common.white,
    "& .MuiTypography-root": {
      fontSize: "0.875rem"
    }
  },
  listIcon: {
    color: theme.palette.common.white,
    minWidth: "2.8rem",
    paddingLeft: "1.5rem",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "1.2rem"
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0.75rem"
    }
  },
  arrowIcon: {
    color: theme.palette.common.white,
    justifyContent: "center"
  },
  listText: {
    display: showDrawer ? "block" : "none",
    color: theme.palette.common.white
  }
}));

export default useStyles;
