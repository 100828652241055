import { FormikTouched } from "formik";

export const accountNumbersMatch = (
  touched: FormikTouched<{ accountNumberConfirm: boolean }>,
  accountNumberConfirm: string,
  accountNumber: string
) => {
  let error = false;
  let helperText = "";

  if (touched.accountNumberConfirm && accountNumberConfirm.length && accountNumberConfirm !== accountNumber) {
    error = true;
    helperText = "Account Numbers do not match";
  }

  return { error, helperText };
};

export const showAccountNumberHelperText = (
  touched: FormikTouched<{ accountNumberConfirm: boolean }>,
  accountNumberConfirm: string,
  accountNumber: string
) =>
  accountNumbersMatch(touched, accountNumberConfirm, accountNumber).error &&
    accountNumbersMatch(touched, accountNumberConfirm, accountNumber).helperText;
