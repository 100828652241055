import React, { ReactElement } from "react";
import {
  selectDocumentCurrentTabView,
  selectDocumentTemplates,
  selectShowAllFacilitiesInDocumentLibrary
} from "src/store/reducers/documentsSlice/documentsSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { DocumentLibraryTableColumns } from "./DocumentLibraryTableColumns";
import EmptyTable from "../../../../components/ui/PMSTable/EmptyTable/EmptyTable";
import PMSTable from "../../../../components/ui/PMSTable/PMSTable";
import {
  getAllPaginatedDocumentTemplates
} from "src/store/thunks/documentTemplates/getAll/getAllPaginatedDocumentTemplates";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const ESignTemplatesTable: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const documentTemplates = useAppSelector(selectDocumentTemplates);
  const showAllFacilities = useAppSelector(selectShowAllFacilitiesInDocumentLibrary);
  const documentCurrentTabView = useAppSelector(selectDocumentCurrentTabView);
  const permissions = useAppSelector(selectFacilityPermissions);
  const documentLibraryPermissions = createPermissions(permissions, "document_library");

  const handlePaginationUpdate = () => {
    dispatch(getAllPaginatedDocumentTemplates({ showAllFacilities, type: documentCurrentTabView }));
  };

  const emptyTable = () => (
    <EmptyTable collectionNotFound={`documents`} value={"document"} addLink={"/documents/new-document/create"} />
  );

  return (
    <PMSTable
      columns={DocumentLibraryTableColumns(documentLibraryPermissions)}
      rows={documentTemplates}
      emptyTableDisplay={emptyTable}
      onPaginationUpdate={handlePaginationUpdate}
    />
  );
};

export default ESignTemplatesTable;
