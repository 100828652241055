import { CommonProductTypesPayload, productTypesApi } from "src/api/endpoints/productTypes";
import { CommonUnitSizesPayload, unitsApi } from "src/api/endpoints/units";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Promotion } from "../../../models/Promotion";
import { PromotionInformationSliceState } from "./PromotionInformationSliceState";
import { RootState } from "../../rootStore";
import { createPromotion } from "../../thunks/promotion/create/createPromotion";
import { getPromotion } from "../../thunks/promotion/get/getPromotion";
import { updatePromotion } from "../../thunks/promotion/update/updatePromotion";

export const initialState: PromotionInformationSliceState = {
  promotionId: sessionStorage.getItem("promotionId") ?? "",
  promotionName: sessionStorage.getItem("promotionName") ?? "",
  promotionDescription: sessionStorage.getItem("promotionDescription") ?? "",
  promotionAmount: sessionStorage.getItem("promotionAmount") ?? "",
  promotionType: parseInt(sessionStorage.getItem("promotionType") as string) || 0,
  appliedPromotionFacilities: JSON.parse(sessionStorage.getItem("appliedPromotionFacilities") as string) ?? [],
  appliedPromotionMonth: parseInt(sessionStorage.getItem("appliedPromotionMonth") as string) || 0,
  appliedPromotionDuration: parseInt(sessionStorage.getItem("appliedPromotionDuration") as string) || 0,
  promotionStart: sessionStorage.getItem("promotionStart") ?? "",
  promotionEnd: sessionStorage.getItem("promotionEnd") ?? "",
  promotionIndefiniteEnd: JSON.parse(sessionStorage.getItem("promotionIndefiniteEnd") as string) ?? false,
  promotionActive: JSON.parse(sessionStorage.getItem("promotionActive") as string) ?? false,
  promotionInformationLoading: false,
  promotionInformationUpdating: false,
  updatePromotionInformationModal: false,
  hasFetchedPromotionInformation: false,
  promotionInformationError: "",
  channel_restriction: [],
  occupancy_comparison: "greater than",
  occupancy_first_value: "",
  occupancy_second_value: null,
  total_unit_restriction_comparison: "greater than",
  total_unit_restriction_value: "",
  total_unit_restriction_second_value: "",
  unit_area_restriction_comparison: "greater than",
  unit_area_restriction_value: "",
  unit_area_restriction_second_value: "",
  unit_sizes: [],
  product_types: [],
  occupancyTarget: "",
  commonProductTypes: [],
  commonUnitSizes: [],
  switchs: {
    channel_restriction: false,
    occupancy: false,
    total_unit_restriction: false,
    unit_area_restriction: false,
    unit_size_restriction: false,
    product_type_restriction: false
  },
  is_unit_group_occupancy: false
};

export const promotionInformationSlice = createSlice({
  name: "promotionInformation",
  initialState,
  reducers: {
    setPromotionName: (state, action: PayloadAction<string>) => {
      state.promotionName = action.payload;
      sessionStorage.setItem("promotionName", action.payload);
    },
    setPromotionDescription: (state, action: PayloadAction<string>) => {
      state.promotionDescription = action.payload;
      sessionStorage.setItem("promotionDescription", action.payload);
    },
    setPromotionAmount: (state, action: PayloadAction<string>) => {
      state.promotionAmount = action.payload;
      sessionStorage.setItem("promotionAmount", action.payload);
    },
    setPromotionType: (state, action: PayloadAction<number>) => {
      state.promotionType = action.payload;
      sessionStorage.setItem("promotionType", action.payload.toFixed());
    },
    setAppliedPromotionFacilities: (state, action: PayloadAction<(number | "all")[]>) => {
      if (action.payload.includes("all")) {
        state.appliedPromotionFacilities = ["all"];
      } else {
        state.appliedPromotionFacilities = action.payload;
      }
      sessionStorage.setItem("appliedPromotionFacilities", JSON.stringify(action.payload));
    },
    setAppliedPromotionMonth: (state, action: PayloadAction<number>) => {
      state.appliedPromotionMonth = action.payload;
      sessionStorage.setItem("appliedPromotionMonth", state.appliedPromotionMonth.toFixed());
    },
    setAppliedPromotionDuration: (state, action: PayloadAction<number>) => {
      state.appliedPromotionDuration = action.payload;
      sessionStorage.setItem("appliedPromotionDuration", state.appliedPromotionDuration.toFixed());
    },
    setPromotionStart: (state, action: PayloadAction<string>) => {
      state.promotionStart = action.payload;
      sessionStorage.setItem("promotionStart", state.promotionStart);
    },
    setPromotionEnd: (state, action: PayloadAction<string>) => {
      state.promotionEnd = action.payload;
      sessionStorage.setItem("promotionEnd", state.promotionEnd);
    },
    setPromotionIndefiniteEnd: (state, action: PayloadAction<boolean>) => {
      state.promotionIndefiniteEnd = action.payload;
      sessionStorage.setItem("promotionIndefiniteEnd", JSON.stringify(state.promotionIndefiniteEnd));
    },
    setUpdatePromotionInformationModal: (state, action: PayloadAction<boolean>) => {
      state.updatePromotionInformationModal = action.payload;
    },
    setChannelRestriction: (state, action) => {
      state.channel_restriction = action.payload;
    },
    setOccupancyComparison: (state, action) => {
      state.occupancy_comparison = action.payload;
    },
    setOccupancyFirstValue: (state, action) => {
      state.occupancy_first_value = action.payload;
    },
    setOccupancySecondValue: (state, action) => {
      state.occupancy_second_value = action.payload;
    },
    setTotalUnitRestrictionComparison: (state, action) => {
      state.total_unit_restriction_comparison = action.payload;
    },
    setTotalUnitRestrictionValue: (state, action) => {
      state.total_unit_restriction_value = action.payload;
    },
    setTotalUnitRestrictionSecondValue: (state, action) => {
      state.total_unit_restriction_second_value = action.payload;
    },
    setUnitAreaRestrictionComparison: (state, action) => {
      state.unit_area_restriction_comparison = action.payload;
    },
    setUnitAreaRestrictionValue: (state, action) => {
      state.unit_area_restriction_value = action.payload;
    },
    setUnitAreaRestrictionSecondValue: (state, action) => {
      state.unit_area_restriction_second_value = action.payload;
    },
    setProductTypesRestriction: (state, action) => {
      state.product_types = action.payload;
    },
    setUnitSizes: (state, action) => {
      state.unit_sizes = action.payload;
    },
    setOccupancyTarget: (state, action: PayloadAction<string>) => {
      state.occupancyTarget = action.payload;
    },
    setPromotionSwitches: (state, action) => {
      state.switchs = action.payload;
    },
    setIsUnitGroupOccupancy: (state, action: PayloadAction<boolean>) => {
      state.is_unit_group_occupancy = action.payload;
    },
    resetPromotionInformationSlice: (state) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("promotionName");
      sessionStorage.removeItem("promotionDescription");
      sessionStorage.removeItem("promotionAmount");
      sessionStorage.removeItem("promotionType");
      sessionStorage.removeItem("appliedPromotionFacilities");
      sessionStorage.removeItem("appliedPromotionMonth");
      sessionStorage.removeItem("appliedPromotionDuration");
      sessionStorage.removeItem("promotionStart");
      sessionStorage.removeItem("promotionEnd");
      sessionStorage.removeItem("promotionIndefiniteEnd");
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPromotion.pending, (state) => {
        state.promotionInformationLoading = true;
      })
      .addCase(getPromotion.fulfilled, (state, action: PayloadAction<Promotion>) => {
        const appliedFacilities = action.payload.facilities?.map((facility) => facility.id) ?? [];

        const promotionEnd = action.payload.is_indefinite
          ? ""
          : action.payload.end
            ? action.payload.end.substring(0, 10)
            : "";
        state.promotionInformationLoading = false;
        state.hasFetchedPromotionInformation = true;
        state.promotionId = action.payload.id;
        state.promotionName = action.payload.name;
        state.promotionDescription = action.payload.description;
        state.promotionAmount = String(action.payload.amount);
        state.promotionType = action.payload.type;
        state.appliedPromotionFacilities = appliedFacilities;
        state.appliedPromotionMonth = action.payload.applied_month;
        state.appliedPromotionDuration = action.payload.duration;
        state.promotionStart = action.payload.start.substring(0, 10);
        state.promotionEnd = promotionEnd;
        state.promotionIndefiniteEnd = action.payload.is_indefinite;
        state.promotionActive = action.payload.is_active;

        if (action.payload.promotion_criteria) {
          const productTypesApplied =
          action.payload.promotion_criteria.product_types.map((productType: any) => productType.id) ?? [];
          state.product_types = productTypesApplied;
          state.channel_restriction = action.payload.promotion_criteria.channel_restriction
            ? action.payload.promotion_criteria.channel_restriction.split(",")
            : [];

          state.occupancy_comparison = action.payload.promotion_criteria.occupancy_comparison;
          state.occupancy_first_value = action.payload.promotion_criteria.occupancy_first_value;
          state.occupancy_second_value = action.payload.promotion_criteria.occupancy_second_value;
          state.total_unit_restriction_comparison = action.payload.promotion_criteria.total_unit_restriction_comparison;
          state.total_unit_restriction_value = action.payload.promotion_criteria.total_unit_restriction_value;
          state.total_unit_restriction_second_value =
          action.payload.promotion_criteria.total_unit_restriction_second_value;
          state.unit_area_restriction_comparison = action.payload.promotion_criteria.area_comparison;
          state.unit_area_restriction_value = action.payload.promotion_criteria.area_first_value;
          state.unit_area_restriction_second_value =
            action.payload.promotion_criteria.area_second_value;
          state.unit_sizes = action.payload.promotion_criteria?.unit_sizes?.split(";") ?? [];
          state.occupancyTarget = action.payload.promotion_criteria.occupancy_target;
          state.switchs = {
            channel_restriction: state.channel_restriction.length > 0,
            occupancy: !!(state.occupancy_comparison && state.occupancy_first_value),
            total_unit_restriction: !!(state.total_unit_restriction_comparison && state.total_unit_restriction_value),
            unit_area_restriction: !!(state.unit_area_restriction_comparison && state.unit_area_restriction_value),
            unit_size_restriction: state.unit_sizes.length > 0,
            product_type_restriction: state.product_types.length > 0
          };

          state.is_unit_group_occupancy = action.payload.promotion_criteria.occupancy_target === "unit_group";
        }

        sessionStorage.setItem("promotionId", String(action.payload.id));
        sessionStorage.setItem("promotionName", action.payload.name);
        sessionStorage.setItem("promotionDescription", action.payload.description);
        sessionStorage.setItem("promotionAmount", String(action.payload.amount));
        sessionStorage.setItem("promotionType", action.payload.type.toFixed());
        sessionStorage.setItem("appliedPromotionFacilities", JSON.stringify(appliedFacilities));
        sessionStorage.setItem("appliedPromotionMonth", action.payload.applied_month.toFixed());
        sessionStorage.setItem("appliedPromotionDuration", action.payload.duration.toFixed());
        sessionStorage.setItem("promotionStart", action.payload.start.substring(0, 10));
        sessionStorage.setItem("promotionEnd", promotionEnd);
        sessionStorage.setItem("promotionIndefiniteEnd", JSON.stringify(action.payload.is_indefinite));
        sessionStorage.setItem("promotionActive", JSON.stringify(action.payload.is_active));
      })
      .addCase(getPromotion.rejected, (state, action) => {
        state.promotionInformationLoading = false;
        state.hasFetchedPromotionInformation = false;
        state.promotionInformationError = action.payload as string;
      })
      .addCase(createPromotion.pending, (state) => {
        state.promotionInformationLoading = true;
      })
      .addCase(createPromotion.fulfilled, (state) => {
        state.promotionInformationLoading = false;
      })
      .addCase(createPromotion.rejected, (state, action) => {
        state.promotionInformationLoading = false;
        state.promotionInformationError = action.payload as string;
      })
      .addCase(updatePromotion.pending, (state) => {
        state.promotionInformationUpdating = true;
      })
      .addCase(updatePromotion.fulfilled, (state) => {
        state.promotionInformationUpdating = false;
      })
      .addCase(updatePromotion.rejected, (state, action) => {
        state.promotionInformationUpdating = false;
        state.promotionInformationError = action.payload as string;
      })
      .addMatcher(productTypesApi.endpoints.getCommonProductTypes.matchFulfilled,
        (state, action:PayloadAction<CommonProductTypesPayload[]>) => {
          state.commonProductTypes = action.payload;

          if (state.hasFetchedPromotionInformation) {
            const temp = state.product_types;
            const matched:string[] = [];
            state.commonProductTypes.forEach((productType) => {
              productType.unique.forEach((unique) => {
                if (temp.includes(unique.id)) {
                  matched.push(String(productType.temp_id));
                }
              });
            });

            state.product_types = Array.from(new Set(matched)) as unknown as number[];

            if (state.commonProductTypes.length === 0) {
              state.unit_sizes = [];
              state.switchs.unit_size_restriction = false;
            }
          }
        })
      .addMatcher(unitsApi.endpoints.getCommonUnitSizes.matchFulfilled,
        (state, action:PayloadAction<CommonUnitSizesPayload[]>) => {
          state.commonUnitSizes = action.payload;

          if (state.hasFetchedPromotionInformation) {
            const unitSizes = state.unit_sizes;

            const matched:string[] = [];

            state.commonUnitSizes.forEach((unitSize) => {
              const str = `${unitSize.width}x${unitSize.length}`;
              if (unitSizes?.includes(str)) {
                matched.push(str);
              }
            });

            state.unit_sizes = Array.from(new Set(matched));
            if (state.commonUnitSizes.length === 0) {
              state.unit_sizes = [];
              state.switchs.unit_size_restriction = false;
            }
          }
        });
  }
});

export const selectHasFetchedPromotionInformation = (state: RootState) =>
  state.promotionInformation.hasFetchedPromotionInformation;
export const selectPromotionSwitches = (state: RootState) => state.promotionInformation.switchs;
export const selectPromotionName = (state: RootState) => state.promotionInformation.promotionName;
export const selectCommonProductTypes = (state: RootState) => state.promotionInformation.commonProductTypes;
export const selectCommonUnitSizes = (state: RootState) => state.promotionInformation.commonUnitSizes;
export const selectPromotionDescription = (state: RootState) => state.promotionInformation.promotionDescription;
export const selectPromotionAmount = (state: RootState) => state.promotionInformation.promotionAmount;
export const selectPromotionType = (state: RootState) => state.promotionInformation.promotionType;
export const selectAppliedPromotionFacilities = (state: RootState) =>
  state.promotionInformation.appliedPromotionFacilities;
export const selectAppliedPromotionMonth = (state: RootState) => state.promotionInformation.appliedPromotionMonth;
export const selectAppliedPromotionDuration = (state: RootState) => state.promotionInformation.appliedPromotionDuration;
export const selectPromotionStart = (state: RootState) => state.promotionInformation.promotionStart;
export const selectPromotionEnd = (state: RootState) => state.promotionInformation.promotionEnd;
export const selectPromotionIndefiniteEnd = (state: RootState) => state.promotionInformation.promotionIndefiniteEnd;
export const selectPromotionInformationLoading = (state: RootState) =>
  state.promotionInformation.promotionInformationLoading;
export const selectPromotionInformationUpdating = (state: RootState) =>
  state.promotionInformation.promotionInformationUpdating;
export const selectUpdatePromotionInformationModal = (state: RootState) =>
  state.promotionInformation.updatePromotionInformationModal;
export const selectChannelRestriction = (state: RootState) => state.promotionInformation.channel_restriction;
export const selectOccupancyComparison = (state: RootState) => state.promotionInformation.occupancy_comparison;
export const selectOccupancyFirstValue = (state: RootState) => state.promotionInformation.occupancy_first_value;
export const selectOccupancySecondValue = (state: RootState) => state.promotionInformation.occupancy_second_value;
export const selectTotalUnitRestrictionComparison = (state: RootState) =>
  state.promotionInformation.total_unit_restriction_comparison;
export const selectTotalUnitRestrictionValue = (state: RootState) =>
  state.promotionInformation.total_unit_restriction_value;
export const selectTotalUnitRestrictionSecondValue = (state: RootState) =>
  state.promotionInformation.total_unit_restriction_second_value;
export const selectUnitAreaRestrictionComparison = (state: RootState) =>
  state.promotionInformation.unit_area_restriction_comparison;
export const selectUnitAreaRestrictionValue = (state: RootState) =>
  state.promotionInformation.unit_area_restriction_value;
export const selectUnitAreaRestrictionSecondValue = (state: RootState) =>
  state.promotionInformation.unit_area_restriction_second_value;
export const selectProductTypesRestriction = (state: RootState) => state.promotionInformation.product_types;
export const selectUnitSizes = (state: RootState) => state.promotionInformation.unit_sizes;
export const selectOccupancyTarget = (state: RootState) => state.promotionInformation.occupancyTarget;
export const selectIsUnitGroupOccupancy = (state: RootState) => state.promotionInformation.is_unit_group_occupancy;
export const selectCommonUnitSizesRequest = (state: RootState) =>
  state.promotionInformation.commonProductTypes.filter(item =>
    state.promotionInformation.product_types.includes(item.temp_id))
    .flatMap(item => item.unique.map(uniqueItem => uniqueItem.id));

export const {
  setPromotionName,
  setPromotionSwitches,
  setPromotionDescription,
  setPromotionAmount,
  setPromotionType,
  setAppliedPromotionFacilities,
  setAppliedPromotionMonth,
  setAppliedPromotionDuration,
  setPromotionStart,
  setPromotionEnd,
  setPromotionIndefiniteEnd,
  setUpdatePromotionInformationModal,
  setChannelRestriction,
  setOccupancyComparison,
  setOccupancySecondValue,
  setOccupancyFirstValue,
  setTotalUnitRestrictionComparison,
  setTotalUnitRestrictionValue,
  setTotalUnitRestrictionSecondValue,
  setUnitAreaRestrictionComparison,
  setUnitAreaRestrictionValue,
  setUnitAreaRestrictionSecondValue,
  setProductTypesRestriction,
  setUnitSizes,
  setOccupancyTarget,
  setIsUnitGroupOccupancy,
  resetPromotionInformationSlice
} = promotionInformationSlice.actions;

export default promotionInformationSlice.reducer;
