import { APIListMetaData } from "src/models/responses/APIListMetaData";
import { GridSortDirection } from "@mui/x-data-grid";
import { RootState } from "src/store/rootStore";
import api from "../api";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";

// Request Types
type CommonUnitSizesRequest = number[];

export type FlatUnit = {
  id: number;
  facility_id: number;
  product_type_id: number;
  deal_id: number | null;
  legacy_id: null;
  unit_number: string;
  floor: string;
  is_ada_compliant: boolean;
  is_rentable: boolean;
  unrentable_reason: string | number | null;
  unrentable_days: number;
  is_rented: boolean;
  is_reserved: boolean;
  is_insurable: boolean;
  is_hidden: boolean;
  door_type: string | number;
  walk_through_order: null;
  unrentable_at: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: null;
  is_active: boolean;
  width: number;
  length: number;
  height: number;
  price: number;
  security_deposit: null;
  Interior: boolean;
  "Drive Up": boolean;
  "Climate Controlled": boolean;
  "Air Cooled": boolean;
  Heated: boolean;
  Illuminated: boolean;
  Electric: boolean;
  Alarmed: boolean;
  "Elevator Access": boolean;
  "Stair Access": boolean;
  "Ada Compliant": boolean;
};

export type UpdateFlatUnitsRequest = {
  payload: FlatUnit[];
};

export interface GetFlatUnitsLocalStateRequest {
  tableParams: {
    page: number,
    pageSize: number,
    sortDirection: GridSortDirection,
    sortBy: string,
    searchValue: string
  };
  productTypeId?: string;
}

export interface GetFlatUnitsRequest {
  facilityId: number;
}

export type MarkUnitUnrentableRequest = {
  unit: FlatUnit;
  note: string;
};

// Response Types
export interface CommonUnitSizesPayload {
  width: number;
  length: number;
}

export type GetFlatUnitsResponse = { data: FlatUnit[], meta: APIListMetaData }
export type UpdateFlatUnitsResponse = any;

export const unitsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFlatUnitsLocalState: builder.query<GetFlatUnitsResponse, GetFlatUnitsLocalStateRequest>({
      queryFn: async({ tableParams, productTypeId }, { getState }, _extraOptions, baseQuery) => {
        try {
          const state = getState() as RootState;
          const facilityId = state.selectedFacility.selectedFacility?.id;

          if (!facilityId) {
            return { error: { status: "CUSTOM_ERROR", error: "Facility ID is missing" } };
          }

          const filter = {
            ...(tableParams.searchValue ? { "filter[unit_number]": tableParams.searchValue } : {}),
            ...(productTypeId ? { "filter[product_type_id]": productTypeId } : {})
          };

          const { searchValue, ...tableParamsWithoutSearchValue } = tableParams;

          // Merge the filter object into the new tableParams copy
          const params = {
            ...tableParamsWithoutSearchValue,
            ...filter,
            with: ["attributes", "notes"]
          };

          const result = await baseQuery({
            url: `/api/v1/facilities/${facilityId}/units/flattened-attributes`,
            params
          });

          if (result.error) {
            return { error: result.error };
          }

          return { data: result.data as GetFlatUnitsResponse };
        } catch (error) {
          return { error: { status: "CUSTOM_ERROR", error: "Failed to fetch flat units" } };
        }
      },
      providesTags: (result: GetFlatUnitsResponse | undefined) => {
        if (result) {
          return [
            { type: "FlatUnitsPage" as const, id: `${result.meta.current_page}-${result.meta.per_page}` },
            ...result.data.map(unit => ({ type: "FlatUnit" as const, id: unit.id.toString() }))
          ];
        } else {
          return [{ type: "FlatUnitsPage" as const, id: "NONE" }];
        }
      }
    }),
    getCommonUnitSizes: builder.query<CommonUnitSizesPayload[], CommonUnitSizesRequest>({
      query: (ids) => ({
        url: `api/v1/units/common-sizes?ids=${ids.join(",")}`
      }),
      providesTags: [{ type: "CommonUnitSizes" }]
    }),
    updateUnits: builder.mutation<UpdateFlatUnitsResponse, UpdateFlatUnitsRequest>({
      queryFn: async({ payload }, { getState }, _extraOptions, baseQuery) => {
        const state = getState() as RootState;
        const facilityId = state.selectedFacility.selectedFacility?.id;

        if (!facilityId) {
          return { error: { status: "CUSTOM_ERROR", error: "Facility ID is missing" } };
        }

        const result = await baseQuery({
          url: `/api/v1/facilities/${facilityId}/units/update-flat`,
          method: "PUT",
          body: { units: payload }
        });

        return result.error ? { error: result.error } : { data: result.data as UpdateFlatUnitsResponse };
      },
      invalidatesTags: (_, __, { payload }) => [
        ...payload.map(unit => ({ type: "FlatUnit" as const, id: unit.id.toString() }))
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(showSnackbar({ message: "Units updated successfully.", variant: "success" }));
        } catch (error) {
          dispatch(showSnackbar({ message: `Error: "Error updating units.`, variant: "error" }));
        }
      }
    }),
    markUnitUnrentable: builder.mutation<void, MarkUnitUnrentableRequest>({
      query: ({ unit, note }) => ({
        url: `/api/v1/units/unrentable`,
        method: "POST",
        body: {
          unit_id: unit.id,
          note: {
            title: null,
            description: note
          },
          unrentable_reason: unit.unrentable_reason || 4
        }
      }),
      invalidatesTags: (result, error, { unit }) => [
        { type: "FlatUnit", id: unit.id.toString() }
      ]
    })
  })
});

// Exporting hooks for both endpoints after the unitsApi has been initialized
export const {
  useMarkUnitUnrentableMutation,
  useGetCommonUnitSizesQuery,
  useGetFlatUnitsLocalStateQuery,
  useUpdateUnitsMutation
} = unitsApi;
