import { Add, ArrowBack, Remove } from "@mui/icons-material";
import { Button, Divider, Grid, Modal, Typography } from "@mui/material";
import { IssueCreditPayload, issueCredit } from "src/store/thunks/credits/issueCredit";
import React, { useEffect, useState } from "react";
import {
  hasPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";
import {
  resetCredits,
  selectChargesLoading,
  selectCreditInformation,
  selectCurrentCharges,
  selectFutureCharges
} from "src/store/reducers/creditsSlice/creditsSlice";
import {
  selectLedgersModalOpen,
  setLedgersModalOpen,
  setSelectedLedger
} from "src/store/reducers/ledgersSlice/ledgersSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { useNavigate, useParams } from "react-router";

import AppModal from "src/components/ui/AppModal/AppModal";
import { Breadcrumb } from "src/models/Breadcrumb";
import { Charge } from "src/store/reducers/creditsSlice/creditsSliceState";
import Form from "src/components/form/Form";
import IssueCreditChargesTable from "./IssueCreditTable/IssueCreditChargesTable";
import IssueCreditInformationTable from "./IssueCreditTable/IssueCreditInformationTable";
import IssueCreditModal from "./IssueCreditModal";
import ItemManagementLayout from "src/layouts/ItemManagement/ItemManagementLayout";
import { Ledger } from "src/models/Ledger";
import LedgerView from "../Occupants/EditOccupant/LedgerView/LedgerView";
import { LoadingButton } from "@mui/lab";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import TableWrapper from "src/layouts/TableWrapper/TableWrapper";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { blockNavigation } from "src/components/router/CustomRouter";
import clsx from "clsx";
import { getLedger } from "src/store/thunks/ledger/get/getLedger";
import { getOccupant } from "src/store/thunks/occupant/getOne/getOccupant";
import { getProjectedLedgerItems } from "src/store/thunks/ledger/get/getProjectedLedgerItems";
import { getUnitCharges } from "src/store/thunks/credits/getUnitCharges";
import moment from "moment";
import { useFormState } from "react-hook-form";
import useStyles from "./IssueCredit.styles";
import { z } from "zod";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Occupants"
  },
  {
    name: "Issue Credits",
    bold: true
  }
];

const createDefaultValuesStructure = (charges?: Charge[]) =>
  Object.fromEntries(
    charges?.filter((charge) => !!charge.applied_credit).map((charge) => [`${charge.id}`, charge.applied_credit])! || {}
  );

const createSchema = (charges?: Charge[]) =>
  z.object({
    reason: z.string().nonempty("Reason is required"),
    ...Object.fromEntries(charges?.map((charge) => [`${charge.id}`, z.string().optional()])!)
  });

const IssueCredit = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const facilityPermissions = useAppSelector(selectFacilityPermissions);
  const chargesLoading = useAppSelector(selectChargesLoading);
  const currentCharges = useAppSelector(selectCurrentCharges);
  const futureCharges = useAppSelector(selectFutureCharges);
  const creditInformation = useAppSelector(selectCreditInformation);
  const { classes } = useStyles();
  const { ledgerId, id: occupantId } = useParams<{ ledgerId: string; id: string }>();
  const [ledger, setLedger] = useState<Ledger | null>(null);
  const [prepaidPeriods, setPrepaidPeriods] = useState<number>(0);
  const [workflowIncompleteWarning, setWorkflowIncompleteWarning] = useState(false);
  const [localTargetPath, setLocalTargetPath] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [hasCredit, setHasCredit] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const ledgersModalOpen = useAppSelector(selectLedgersModalOpen);

  let unblock: Function;

  if (!occupantId || !ledgerId) {
    navigate(-1);
  }

  const handleAdvisory = () => {
    return blockNavigation(({ location }) => {
      const nextLocation = location.pathname;
      setLocalTargetPath(nextLocation);

      const hasCurrentChargesAndCreditApplied = currentCharges.length > 0 && hasCredit;
      const hasPrepaidPeriodsAndCreditApplied = prepaidPeriods > 0 && hasCredit;

      if ((hasPrepaidPeriodsAndCreditApplied || hasCurrentChargesAndCreditApplied) && !formSubmitted) {
        setWorkflowIncompleteWarning(true);

        return;
      }

      unblock();
      navigate(nextLocation, { state: { isFromIssueCredits: true } });
    });
  };

  const handleReset = () => {
    dispatch(setSelectedLedger(null));
  };

  const confirmNavigationAway = () => {
    if (unblock) {
      unblock();
    }

    handleReset();
    navigate(localTargetPath, { state: { isFromIssueCredits: true } });
  };

  useEffect(() => {
    unblock = handleAdvisory();

    return () => {
      if (unblock) {
        unblock();
      }
    };
  });

  useEffect(() => {
    if (occupantId && ledgerId) {
      dispatch(resetCredits());

      dispatch(getLedger({ occupantId, ledgerId })).then(async({ payload }) => {
        const ledger = payload as Ledger;

        await dispatch(setSelectedLedger(ledger));

        setLedger(ledger);
      });

      dispatch(getOccupant(occupantId));

      dispatch(getProjectedLedgerItems({ occupantId: Number(occupantId), ledgerId: Number(ledgerId) })).then((action) =>
        setPrepaidPeriods(action.payload.requested_prepaid_periods)
      );
    }
  }, []);

  const goBack = async() => {
    navigate(`/occupants/${occupantId}/edit`, {
      state: { isFromIssueCredits: true }
    });
  };

  const handleNextPeriods = () => {
    if (occupantId && ledgerId) {
      setFormSubmitted(false);
      const periodCount = prepaidPeriods + 1;
      setPrepaidPeriods(periodCount);

      dispatch(getUnitCharges({ occupantId, ledgerId, prepaidPeriods: periodCount }));
    }
  };

  const handleRemovePeriod = () => {
    if (occupantId && ledgerId) {
      if (prepaidPeriods > 0) {
        const periodCount = prepaidPeriods - 1;
        setPrepaidPeriods(periodCount);

        dispatch(getUnitCharges({ occupantId, ledgerId, prepaidPeriods: periodCount }));
      }
    }
  };

  const handleSubmit = async(data: { [key: string]: string }, event?: React.BaseSyntheticEvent) => {
    if (!occupantId || !ledgerId) {
      return;
    }

    const creditForCurrent = currentCharges
      .filter((charge: Charge) => !!data[charge.id])
      .filter((charge: Charge) => data[charge.id] !== currentCharges.find((c) => c.id === charge.id)?.applied_credit)
      .map((charge: Charge) => ({
        id: charge.id,
        credit_amount: Number(data[charge.id]),
        reason: data.reason
      }));

    const creditForFuture = futureCharges
      .filter((charge: Charge) => !!data[charge.id])
      .map((charge: Charge) => ({
        description: charge.description,
        credit_amount: Number(data[charge.id]),
        charge_date: charge.charge_date,
        reason: data.reason,
        parent_charge_id: charge.chargeable_id ?? null
      }));

    const credits: IssueCreditPayload = {};

    if (creditForCurrent.length > 0) {
      credits.current_charges = creditForCurrent;
    }

    if (creditForFuture.length > 0) {
      credits.future_charges = creditForFuture;
    }

    await dispatch(
      issueCredit({
        occupantId,
        ledgerId,
        data: credits
      })
    );
    dispatch(getUnitCharges({ occupantId, ledgerId, prepaidPeriods }));
    setModalOpen(false);
    setFormSubmitted(true);
  };

  const FooterButtons = ({ setHasCredit }: { setHasCredit: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const state = useFormState();

    useEffect(() => {
      if (state.isDirty) {
        setHasCredit(state.isDirty);
      }
    }, [state.isDirty]);

    const hasCreditOnLastPeriod = () => {
      const lastItem = futureCharges[futureCharges.length - 1];
      const allFromLastPeriod = futureCharges.filter((item) =>
        moment(item.charge_date).startOf("month").isSame(moment(lastItem.charge_date).startOf("month"))
      );

      return allFromLastPeriod.some((item) => Number(item.applied_credit ?? 0) > 0);
    };

    return (
      <Grid container display={"flex"} alignItems={"end"}
        justifyContent={"space-between"} mt={"1.25rem"}>
        <Grid item display={"flex"} flexDirection={"column"}
          alignItems={"start"} justifyContent={"space-evenly"}>
          <Typography gutterBottom fontWeight={"bold"}>
            Future Payment Periods:
          </Typography>
          <Grid display={"flex"}>
            {prepaidPeriods > 0
              ? (
                <LoadingButton
                  loading={chargesLoading}
                  onClick={handleRemovePeriod}
                  disabled={hasCreditOnLastPeriod()}
                  variant={"outlined"}
                  startIcon={<Remove />}
                  className={classes.removeButton}
                >
                  REMOVE PERIOD
                </LoadingButton>
                )
              : null}
            <LoadingButton
              loading={chargesLoading}
              onClick={handleNextPeriods}
              variant={"contained"}
              className={classes.addButton}
              startIcon={<Add />}
            >
              ADD PERIOD
            </LoadingButton>
          </Grid>
        </Grid>
        <Button
          onClick={() => setModalOpen(true)}
          variant={"contained"}
          className={classes.buttonBase}
          disabled={!state.isDirty || hasPermissions(
            facilityPermissions,
            "occ_issue_credits",
            ["create", "read", "update", "delete"],
            "all"
          )}
        >
          Apply
        </Button>
      </Grid>
    );
  };

  return (
    <>
      <Form
        defaultValues={{
          reason: "",
          ...createDefaultValuesStructure([...currentCharges, ...futureCharges])
        }}
        onSubmit={handleSubmit}
        schema={createSchema([...currentCharges, ...futureCharges])}
        resetOnSubmit
      >
        <ViewWrapper pageHeader={<PageHeader title={"Issue Credits"} breadcrumbs={breadcrumbs} />}>
          <Button
            data-testid={"back-button"}
            variant={"text"}
            className={clsx(classes.textButton, classes.baseButton)}
            startIcon={<ArrowBack />}
            onClick={() => goBack()}
          >
            Go Back to Rented Units
          </Button>

          <ItemManagementLayout
            title={"Issue Credits"}
            noMarginFooter
            footerButtons={<FooterButtons setHasCredit={setHasCredit} />}
          >
            <Grid display={"flex"} flexDirection={"column"} item
              xs={12} container>
              <Grid display={"flex"} alignItems={"center"} mt={"1.25rem"}>
                <Grid item mr={"5px"}>
                  <Typography fontSize={"16px"} className={classes.bodyHeaderText}>
                    Unit Information
                  </Typography>
                </Grid>
                <Grid container>
                  <Divider className={classes.bodyHeaderDivider} />
                </Grid>
              </Grid>
              <TableWrapper
                table={
                  <IssueCreditInformationTable
                    ledger={ledger}
                    balance={creditInformation?.current_balance_total_formatted!}
                  />
                }
                loading={!ledger}
              />
              <Grid item mt={2}>
                <Typography variant={"caption"}>
                  *includes all outstanding rent charges and fees associated with this unit.
                </Typography>
              </Grid>

              <Grid display={"flex"} alignItems={"center"} mt={"2.5rem"}>
                <Grid item mr={"5px"}>
                  <Typography className={classes.bodyHeaderText} fontSize={"16px"}>
                    Unit Charges
                  </Typography>
                </Grid>
                <Grid container>
                  <Divider className={classes.bodyHeaderDivider} />
                </Grid>
              </Grid>

              <TableWrapper
                table={<IssueCreditChargesTable charges={[...currentCharges, ...futureCharges]} ledger={ledger} />}
                loading={!ledger || chargesLoading}
              />
              <Grid item mt={2}>
                <Typography variant={"caption"}>
                  {`*Please note: any credit applied to a current or past due charge is final and will apply immediately
                  to the occupant's balance.`}
                </Typography>
              </Grid>
            </Grid>
          </ItemManagementLayout>
        </ViewWrapper>
        <AppModal
          open={workflowIncompleteWarning}
          cancelAction={() => setWorkflowIncompleteWarning(false)}
          confirmAction={confirmNavigationAway}
          data-testid={"app-layout-modal"}
        >
          <Typography gutterBottom variant={"h6"}>
            Warning
          </Typography>
          <Typography variant={"body1"}>
            You are about to leave an incomplete workflow. If you choose to continue, information you have provided
            during this and previous steps will be lost.
          </Typography>
        </AppModal>

        <IssueCreditModal open={modalOpen} onClose={() => setModalOpen(false)} />
      </Form>
      <Modal
        open={ledgersModalOpen}
        onClose={() => dispatch(setLedgersModalOpen(false))}
        aria-labelledby={"Modal Component"}
        aria-describedby={"A Modal component."}
        className={clsx(classes.modal, classes.ledgerModal)}
      >
        {/* this fragment here gets rid of a strange forwardRef console error locally. */}
        <>
          <LedgerView />
        </>
      </Modal>
    </>
  );
};

export default IssueCredit;
