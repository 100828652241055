import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "PageHeader" })((theme) => ({
  header: {
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
    }
  },
  subheading: {
    [theme.breakpoints.down("md")]: {
      marginBottom: "-1.5rem"
    }
  },
  date: {
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  }
}));

export default useStyles;
