import React, { FC, useEffect } from "react";
import {
  selectInventory,
  selectInventoryLoading,
  setInventoryModalOpen
} from "src/store/reducers/inventorySlice/inventorySlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import EmptyTable from "src/components/ui/PMSTable/EmptyTable/EmptyTable";
import PMSTable from "src/components/ui/PMSTable/PMSTable";
import { getMerchInventory } from "src/store/thunks/inventory/getMerchInventory/getMerchInventory";
import manageInventoryTableColumns from "./ManageInventoryTableColumns";
import { useParams } from "react-router";

interface ManageInventoryTableProps {

}

const ManageInventoryTable: FC<ManageInventoryTableProps> = () => {
  const inventoryLoading = useAppSelector(selectInventoryLoading);
  const inventory = useAppSelector(selectInventory);
  const dispatch = useAppDispatch();
  const params = useParams<{id: string}>();

  const handlePaginationUpdate = () => {
    dispatch(getMerchInventory(String(params.id)));
  };

  useEffect(() => {
    handlePaginationUpdate();
  }, []);

  const emptyTable = () => (
    <EmptyTable
      collectionNotFound={`Merchandise Inventory`}
      value={"New Inventory"}
      addAction={() => dispatch(setInventoryModalOpen(true))}
    />
  );

  return (
    <PMSTable
      identifier={"inventory"}
      data-testid={"manage-inventory-table"}
      columns={manageInventoryTableColumns()}
      loading={inventoryLoading}
      rows={inventory}
      emptyTableDisplay={emptyTable}
      onPaginationUpdate={handlePaginationUpdate}
    />
  );
};

export default ManageInventoryTable;
