import { Button, Grid, Modal, Paper, Typography } from "@mui/material";
import {
  selectUnableToDisableRevenueClassOpenModal,
  setUnableToDisableRevenueClassOpenModal
} from "src/store/reducers/revenueClassesSlice/revenueClassesSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Box } from "@mui/system";
import React from "react";
import useStyles from "./UnableToDisableRevenueClassModal.styles";

const UnableToDisableRevenueClassModal = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const modalOpened = useAppSelector(selectUnableToDisableRevenueClassOpenModal);

  const handleClose = () => {
    dispatch(setUnableToDisableRevenueClassOpenModal(false));
  };

  return (
    <Modal
      open={modalOpened}
      onClose={() => handleClose()}
      aria-labelledby={"Modal Component"}
      aria-describedby={"A Modal component."}
      className={classes.modal}
      data-testid={"modal-unable-disable"}
  >
      <Grid
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        spacing={2}
        className={classes.container}
        pb={2}>
        <Box className={classes.content}>
          <Typography variant={"h5"} className={classes.title}>
            Cannot Disable Revenue Class
          </Typography>
          <Typography className={classes.description}>
            This revenue class is currently in use within the system and cannot be disabled until all items
            tied to it have been assigned a different revenue class.
          </Typography>
          <Grid
            pt={4}
            spacing={4}
            container
            justifyContent={"center"}
      >
            <Grid item>
              <Button
                data-testid={"cancel-button"}
                className={classes.cancelButton}
                onClick={() => handleClose()}
                variant={"outlined"}
                color={"secondary"}
          >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Modal>
  );
};

export default UnableToDisableRevenueClassModal;
