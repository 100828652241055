import React, { useEffect } from "react";
import { Breadcrumb } from "../../../models/Breadcrumb";
import ManageFacilitiesTable from "./ManageFacilitiesTable/ManageFacilitiesTable";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import TableWrapper from "../../../layouts/TableWrapper/TableWrapper";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { getAllPaginatedFacilities } from "../../../store/thunks/facility/getAllPaginated/getAllPaginatedFacilities";
import { resetTablesSlice } from "../../../store/reducers/tablesSlice/tablesSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  createPermissions,
  hasPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Facility Management"
  },
  {
    name: "Manage Facilities",
    bold: true
  }
];

const ManageFacilities: React.FC = () => {
  const dispatch = useAppDispatch();

  const permissions = useAppSelector(selectFacilityPermissions);
  const facilityPermissions = createPermissions(permissions, "facility");
  const addFacilityPermissions = createPermissions(permissions, "add_facility");

  useEffect(() => {
    dispatch(getAllPaginatedFacilities());
  }, []);

  useEffect(() => {
    /* istanbul ignore next */ // cannot test this w/ enzyme
    return () => {
      dispatch(resetTablesSlice());
      dispatch(getAllPaginatedFacilities());
    };
  }, []);

  const pageHeader = <PageHeader title={"Manage Facilities"} breadcrumbs={breadcrumbs} />;
  return (
    facilityPermissions.read
      ? (<ViewWrapper pageHeader={pageHeader}>
        <TableWrapper
          title={"Facilities"}
          addButtonText={addFacilityPermissions.create ? ("Add new Facility") : null }
          addButtonLink={addFacilityPermissions.create ? ("/facility-management/add-facility") : null }
          table={<ManageFacilitiesTable />}
        />
      </ViewWrapper>)
      : <ViewWrapper pageHeader={pageHeader}>
        <h1>You do not have permission to view this page.</h1>
      </ViewWrapper>
  );
};

export default ManageFacilities;
