import * as Yup from "yup";
import { Button, FormControl, Grid, Modal, Paper, TextField, Typography } from "@mui/material";
import React, { FC } from "react";
import {
  selectBatchUnitUpdateModalOpen,
  selectBatchUpdateLoading,
  setBatchUnitUpdateModalOpen
} from "src/store/reducers/unitsSliceNew/unitsSliceNew";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { FileCopy } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { batchUpdateUnits } from "src/store/thunks/unit/batch/update/batchUpdateUnits";
import { exportFacilityUnitsCsv } from "src/store/thunks/unit/export/exportFacilityUnitsCsv";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { useFormik } from "formik";
import useStyles from "./BatchUpdateUnitsModal.styles";
import { getAllUnitsNew } from "src/store/thunks/unitNew/get/getAllUnitsNew";
import { selectProductTypeIdNew } from "src/store/reducers/productTypeSliceNew/productTypeSliceNew";

interface BatchUpdateUnitsModalProps { }

const fileValidationSchema = Yup.object().shape({
  file: Yup
    .mixed()
    .required("A file is required")
    .test(
      "fileType",
      "Uploaded file is not a CSV",
      value => value && value.type === "text/csv"
    )
});

const BatchUpdateUnitsModal: FC<BatchUpdateUnitsModalProps> = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const batchUpdateUnitsModalOpen = useAppSelector(selectBatchUnitUpdateModalOpen);
  const batchUpdateUnitsLoading = useAppSelector(selectBatchUpdateLoading);
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const formik = useFormik({
    initialValues: {
      file: null
    },
    validationSchema: fileValidationSchema,
    onSubmit: (values) => {
      if (values.file) {
        dispatch(batchUpdateUnits({ file: values.file }))
          .then((resp) => {
            if (resp.type.includes("fulfilled")) {
              formik.resetForm();
              dispatch(setBatchUnitUpdateModalOpen(false));
              dispatch(getAllUnitsNew({ id: String(selectedFacility.id) }));
              return;
            }

            dispatch(showSnackbar({
              message: resp.payload,
              variant: "error"
            }));
            dispatch(setBatchUnitUpdateModalOpen(false));
          });
      }
    }
  });

  const { handleSubmit, touched, errors } = formik;

  const handleClose = () => {
    dispatch(setBatchUnitUpdateModalOpen(false));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const file = files[0];
      formik.setFieldValue("file", file);
      formik.setFieldTouched("file", true, false);
    }
  };

  const handleGetUnitExport = () => {
    dispatch(exportFacilityUnitsCsv(selectedFacility.id));
  };

  return (
    <Modal
      onClose={handleClose}
      open={batchUpdateUnitsModalOpen}
      className={classes.root}
    >
      <form
        id={"batch-upload-units-form"}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}>
        <Grid
          container
          spacing={2}
          p={2}
          className={classes.root}
          component={Paper}
          elevation={0}
        >
          <Grid item xs={12}>
            <Typography variant={"h5"}>Batch Update Existing Units</Typography>
          </Grid>
          <Grid
            container
            spacing={2}
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Typography variant={"h6"}>Download Exported Units Template For This Facility</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button className={classes.buttonBase} onClick={handleGetUnitExport} startIcon={<FileCopy/>}
                variant={"contained"}>
                Download
              </Button>
            </Grid>
            <Grid item xs={12}>
              <FormControl error={Boolean(touched.file && errors.file)} fullWidth>
                <TextField
                  error={Boolean(touched.file && errors.file)}
                  helperText={touched.file && errors.file ? errors.file : ""}
                  id={"file"}
                  type={"file"}
                  onChange={handleFileChange}
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant={"outlined"}
                  fullWidth
                  margin={"normal"}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            p={2}
            spacing={2}
            item
            xs={12}
            justifyContent={"flex-end"}
          >
            <Grid item>
              <LoadingButton
                className={classes.buttonBase}
                type={"submit"}
                loading={batchUpdateUnitsLoading}
                form={"batch-upload-units-form"}
                variant={"contained"}
              >
                Submit
              </LoadingButton>
            </Grid>
            <Grid item>
              <Button
                onClick={handleClose}
                variant={"text"}
                color={"error"}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
};

export default BatchUpdateUnitsModal;
