import React, { ReactElement } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useLocation, useNavigate } from "react-router";

import { APIListResponse } from "src/models/responses/APIListResponse";
import PMSAdvisory from "../../../../components/ui/PMSAdvisory/PMSAdvisory";
import { Paper } from "@mui/material";
import { Promotion } from "src/models/Promotion";
import SelectUnitInStepper from "src/components/stepper/SelectUnit/SelectUnitInStepper/SelectUnitInStepper";
import StepLayout from "../../../../layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "../../../../layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import { getAllPromotionsForFacility } from "src/store/thunks/promotion/getAllForFacility/getAllPromotionsForFacility";
import { getMoveInCost } from "../../../../store/thunks/moveIn/getMoveInCost/getMoveInCost";
import { getTransferUnitCost } from "src/store/thunks/transfer/transferCost/getTransferUnitCost";
import { selectECFirstName }
  from "../../../../store/reducers/emergencyContactInformationSlice/emergencyContactInformationSlice";
import { selectIsTransfer } from "src/store/reducers/moveInSlice/moveInSlice";
import { selectSelectedOccupantReservations }
  from "../../../../store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { selectSelectedUnit } from "src/store/reducers/selectedUnitSlice/selectedUnitSlice";
import { setTargetPath } from "../../../../store/reducers/navigationSlice/navigationSlice";
import { updateDeal } from "src/store/thunks/deals/update/updateDeal";
import { selectDeal } from "src/store/reducers/dealSlice/dealSlice";
import UpdateDealPayload from "src/store/thunks/deals/update/UpdateDealPayload";
import { Deal } from "src/models/Deal";
import dealFollowUpModalValidation from "src/components/dealNotification/DealFollowUpModalValidation";
import { isNull } from "lodash";

const SelectUnitForMoveIn: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const reservations = useAppSelector(selectSelectedOccupantReservations);
  const emergencyContact = useAppSelector(selectECFirstName);
  const isTransfer = useAppSelector(selectIsTransfer);
  const selectedUnit = useAppSelector(selectSelectedUnit);
  const selectedDeal = useAppSelector(selectDeal);

  const advisoryText = (
    <>
      <p>Now, you are able to select the unit the occupant would like to rent.</p>
      <p>
        You can view a list of available units at your facility. This list also displays unit attributes (such
        as the current deal rate, floor number, etc.) that can be used to guide an occupant towards the unit
        that best fits their needs.
      </p>
      <p>
        After a unit has been selected, you may proceed to the next step.
      </p>
    </>
  );

  const updateSummary = () => {
    if (isTransfer) {
      return dispatch(getTransferUnitCost());
    }

    dispatch(getMoveInCost());
  };

  const handleGoBack = () => {
    if (emergencyContact) {
      return navigate("/occupants/create-move-in/emergency-contact");
    }

    if (reservations.length) {
      return navigate("/occupants/create-move-in/select-reservation");
    }

    navigate("/occupants/create-move-in/select-occupant");
  };

  const handleSubmit = async() => {
    const { payload } = await dispatch(getAllPromotionsForFacility(selectedUnit?.id));

    const facilityPromotions = payload as APIListResponse<Promotion[]>;

    const reservationDeal = selectedDeal as Deal;

    if (!isNull(reservationDeal)) {
      const payloadDeal: UpdateDealPayload = {
        ...reservationDeal,
        deal_type: reservationDeal.deal_type === "Reservation" ? 2 : 1,
        appointment_at: reservationDeal!.appointment_at!,
        cancelled_at: reservationDeal.cancelled_at!,
        contact_at: reservationDeal.contact_at!,
        last_contact_at: reservationDeal.last_contact_at!,
        quoted_price: parseFloat(reservationDeal.quoted_price!),
        middle_name: reservationDeal.middle_name!,
        promotion_id: reservationDeal.promotion_id !== null ? parseInt(reservationDeal.promotion_id) : null,
        product_type_id: selectedUnit!.product_type_id,
        unit_id: selectedUnit?.id
      };

      dispatch(updateDeal({ payload: payloadDeal, deal_id: selectedDeal!.id }));
    }

    if (facilityPromotions.data.length > 0) {
      return navigate("/occupants/create-move-in/select-promotion");
    }

    dispatch(setTargetPath(location.pathname));
    updateSummary();
    navigate("/occupants/create-move-in/process-move-in");
  };

  return (
    <>
      <PMSAdvisory title={"Select a Unit"} advisoryText={advisoryText} />
      <Paper elevation={1}>
        <StepLayout
          buttons={
            <StepperButtons
              data-testid={"select-move-in-unit-buttons"}
              stepperId={"select-unit"}
              backAction={handleGoBack}
              forwardActionText={"Next"}
            />}
        >
          <SelectUnitInStepper data-testid={"select-unit"} onSubmitHandler={handleSubmit} />
        </StepLayout>
      </Paper>
    </>
  );
};

export default SelectUnitForMoveIn;
