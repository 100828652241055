import CreateWebhook from "../pages/Webhooks/CreateWebhook/CreateWebhook";
import EditWebhook from "../pages/Webhooks/EditWebhook/EditWebhook";
import ManageWebhooks from "src/pages/Webhooks/ManageWebhooks";
import React from "react";
import { Route } from "react-router";

export const groupName = "webhooks";

export const WebhookRoutes = (
  <>
    <Route path={`${groupName}`} element={<ManageWebhooks />} />
    <Route path={`${groupName}/create`} element={<CreateWebhook />} />
    <Route path={`${groupName}/:id/edit`} element={<EditWebhook />} />
  </>
);

export default WebhookRoutes;
