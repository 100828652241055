import { Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { FormEvent, ReactElement } from "react";
import {
  selectAddressOne, selectAddressTwo, selectCity,
  selectFirstName, selectLastName,
  selectMiddleName, selectPostalCode, selectState
} from "../../../store/reducers/occupantInformationSlice/occupantInformationSlice";
import { OccupantNameAndLocationValues }
  from "../../../models/formikInputValues/occupant/OccupantNameAndLocationValues";
import PMSSelect from "../../ui/PMSSelect/PMSSelect";
import { inputError } from "../../../utils/showInputError/showInputError";
import occupantInformationOneValidation from "./occupantNameAndLocationValidation";
import states from "../../../utils/usStates";
import { useAppSelector } from "../../../store/hooks";
import { useFormik } from "formik";

interface OccupantNameAndLocationProps {
  stepperId: string
  onSubmitHandler: (values: OccupantNameAndLocationValues) => void
}

const OccupantNameAndLocation: React.FC<OccupantNameAndLocationProps> = ({
  stepperId,
  onSubmitHandler
}): ReactElement => {
  const firstName = useAppSelector(selectFirstName);
  const middleName = useAppSelector(selectMiddleName);
  const lastName = useAppSelector(selectLastName);
  const addressOne = useAppSelector(selectAddressOne);
  const addressTwo = useAppSelector(selectAddressTwo);
  const city = useAppSelector(selectCity);
  const state = useAppSelector(selectState);
  const postalCode = useAppSelector(selectPostalCode);

  const formik = useFormik({
    initialValues: {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      addressOne: addressOne,
      addressTwo: addressTwo,
      city: city,
      state: state,
      postalCode: postalCode
    },
    validationSchema: occupantInformationOneValidation,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values: OccupantNameAndLocationValues) => onSubmitHandler(values)
  });

  const { touched, errors, values, handleChange, setFieldTouched, setFieldValue } = formik;

  const maskZipCode = (fieldValue: string) => {
    const value = fieldValue.replace(/\D/g, "")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");

    return value;
  };

  const handleZipCodeChange = (fieldName: string, fieldValue: string) => {
    setFieldTouched(fieldName);
    setFieldValue(fieldName, maskZipCode(fieldValue));
  };

  return (
    <Grid
      component={"form"}
      p={1}
      id={stepperId}
      data-testid={stepperId}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        formik.handleSubmit();
      }}
    >
      <Typography variant={"h6"} component={"h3"}>Occupant Information</Typography>
      <Grid
        container
        justifyContent={"space-between"}
        mt={2}
        spacing={2}
      >
        <Grid xs={12} lg={5} item>
          <InputLabel htmlFor={"first-name"}>First Name</InputLabel>
          <TextField
            fullWidth
            placeholder={"- First Name -"}
            id={"first-name"}
            inputProps={{ "data-testid": "first-name" }}
            name={"firstName"}
            value={values.firstName}
            onChange={handleChange}
            error={inputError("firstName", touched, errors).error}
            helperText={inputError("firstName", touched, errors).helperText}
          />
        </Grid>
        <Grid xs={12} lg={2} item>
          <InputLabel htmlFor={"middle-name"}>Initials</InputLabel>
          <TextField
            fullWidth
            placeholder={"- Initials -"}
            id={"middle-name"}
            inputProps={{ "data-testid": "middle-name" }}
            name={"middleName"}
            value={values.middleName}
            onChange={handleChange}
            error={inputError("middleName", touched, errors).error}
            helperText={inputError("middleName", touched, errors).helperText}
          />
        </Grid>
        <Grid xs={12} lg={5} item>
          <InputLabel htmlFor={"last-name"}>Last Name</InputLabel>
          <TextField
            fullWidth
            placeholder={"- Last Name -"}
            id={"last-name"}
            inputProps={{ "data-testid": "last-name" }}
            name={"lastName"}
            value={values.lastName}
            onChange={handleChange}
            error={inputError("lastName", touched, errors).error}
            helperText={inputError("lastName", touched, errors).helperText}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <InputLabel htmlFor={"mailing-address-one"}>Mailing Address 1</InputLabel>
          <TextField
            fullWidth
            placeholder={"- Mailing Address 1 -"}
            id={"mailing-address-one"}
            inputProps={{ "data-testid": "mailing-address-one" }}
            name={"addressOne"}
            value={values.addressOne}
            onChange={handleChange}
            error={inputError("addressOne", touched, errors).error}
            helperText={inputError("addressOne", touched, errors).helperText}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <InputLabel htmlFor={"mailing-address-two"}>Mailing Address 2</InputLabel>
          <TextField
            fullWidth
            placeholder={"- Mailing Address 2 -"}
            id={"mailing-address-two"}
            inputProps={{ "data-testid": "mailing-address-two" }}
            name={"addressTwo"}
            value={values.addressTwo}
            onChange={handleChange}
            error={inputError("addressTwo", touched, errors).error}
            helperText={inputError("addressTwo", touched, errors).helperText}
          />
        </Grid>
        <Grid xs={12} lg={6} item>
          <InputLabel htmlFor={"city"}>City</InputLabel>
          <TextField
            fullWidth
            placeholder={"- City -"}
            id={"city"}
            inputProps={{ "data-testid": "city" }}
            name={"city"}
            value={values.city}
            onChange={handleChange}
            error={inputError("city", touched, errors).error}
            helperText={inputError("city", touched, errors).helperText}
          />
        </Grid>
        <Grid xs={12} lg={2} item>
          <PMSSelect
            id={"state"}
            name={"state"}
            label={"State"}
            value={values.state}
            changeHandler={handleChange}
            error={inputError("state", touched, errors).error}
            helperText={inputError("state", touched, errors).helperText}
          >
            <option value={""} disabled>{" - State -"}</option>
            {states.map(state => <option key={state.value} value={state.value}>{state.text}</option>)}
          </PMSSelect>
        </Grid>
        <Grid xs={12} lg={2} item>
          <InputLabel htmlFor={"postal-code"}>Zip/postal code</InputLabel>
          <TextField
            fullWidth
            placeholder={"- Zip/postal Code -"}
            id={"postal-code"}
            name={"postalCode"}
            value={values.postalCode}
            onChange={(e) => handleZipCodeChange("postalCode", e.target.value)}
            error={inputError("postalCode", touched, errors).error}
            helperText={inputError("postalCode", touched, errors).helperText}
          />
        </Grid>
        <Grid xs={12} lg={2} item>
          <InputLabel htmlFor={"country"}>Country</InputLabel>
          <Select
            fullWidth
            disabled
            defaultValue={""}
            id={"country"}
            displayEmpty
            variant={"outlined"}
            value={""}
            inputProps={{ "data-testid": "country" }}
          >
            <MenuItem value={""} disabled>United States</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OccupantNameAndLocation;
