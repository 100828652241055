import { makeStyles } from "../../../../../makeStyles";

const useStyles = makeStyles({ label: "DelinquentPaymentTable" })((theme) => ({
  table: {
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  },
  total: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    border: `1.5px solid ${theme.palette.info.main}`,
    borderRadius: "0.25rem"
  },
  partialPayment: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  partialPaymentInput: {
    width: "8rem"
  },
  pastDue: {
    border: `1.5px solid ${theme.palette.error.main}`,
    borderRadius: "0.25rem",
    color: theme.palette.error.main,
    fontStyle: "italic"
  }
}));

export default useStyles;
