import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "ManageFacilitiesTable" })((theme) => ({
  root: {
    border: 0,
    "& .MuiDataGrid-columnSeparator": {
      display: "none"
    },
    height: "auto"
  },
  modalContainer: {
    position: "relative",
    height: "100vh"
  },
  modal: {
    backgroundColor: theme.palette.background.paper,
    position: "absolute",
    width: "35rem",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    padding: "1rem 0"
  }
}));

export default useStyles;
