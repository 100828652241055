import { makeStyles } from "../../../../../../makeStyles";

const useStyles = makeStyles<{ active: boolean }>()((theme, { active }) => ({
  cardContent: {
    background: theme.palette.background.default,
    height: "auto",
    borderRadius: "1rem"
  },
  infoTitle: {
    color: theme.palette.primary.contrastText,
    fontStyle: "italic"
  },
  facilityChip: {
    background: theme.palette.primary.contrastText,
    color: theme.palette.common.white
  },
  rentedUnitStatus: {
    textTransform: "capitalize",
    // maxHeight: "1rem",
    borderColor: active ? theme.palette.success.main : theme.palette.error.main,
    fontSize: "0.8rem",
    color: active ? theme.palette.success.main : theme.palette.error.main
  },
  submitButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: "white"
  },
  overlocked: {
    textTransform: "capitalize",
    maxHeight: "1rem",
    borderColor: theme.palette.warning.main,
    fontSize: "0.5rem",
    color: theme.palette.warning.main
  },
  infoText: {
    fontStyle: "italic"
  },
  actionButton: {
    textTransform: "capitalize",
    height: "100%",
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  },
  disabledButton: {
    backgroundColor: active ? theme.palette.primary.contrastText : theme.palette.grey[400]
  }
}));

export default useStyles;
