import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "SelectedUnitsDisplay" })((theme) => ({
  chip: {
    padding: `${theme.spacing(3)} ${theme.spacing(1)}`,
    height: "3.45rem",
    fontSize: "1.5rem",
    background: theme.palette.primary.contrastText,
    color: theme.palette.common.white,
    "& .MuiChip-deleteIcon": {
      marginLeft: theme.spacing(1),
      color: theme.palette.primary.main
    }
  },
  label: {
    marginBottom: theme.spacing(1)
  },
  textField: {
    height: "4rem"
  }
}));

export default useStyles;
