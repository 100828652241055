import { Grid, InputAdornment, InputLabel, TextField, Typography } from "@mui/material";
import React, { FormEvent, ReactElement, useState } from "react";
import {
  selectCoverageAmount,
  selectPlanDescription,
  selectPlanDocumentTemplateId,
  selectPlanFreeTrail,
  selectPlanIsActive,
  selectPlanIsAutoEnroll,
  selectPlanName,
  selectPlanRevenueClassId,
  selectPremiumAmount,
  setCoverageAmount,
  setPlanDescription,
  setPlanDocumentTemplateId,
  setPlanFreeTrial,
  setPlanIsActive,
  setPlanIsAutoEnroll,
  setPlanName,
  setPlanRevenueClassId,
  setPremiumAmount
} from "../../../store/reducers/insurancePlanInformationSlice/insurancePlanInformationSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import PMSSelect from "../../../components/ui/PMSSelect/PMSSelect";
import PMSSwitch from "src/components/ui/PMSSwitch/PMSSwitch";
import { RevenueClass } from "../../../models/RevenueClass";
import { inputError } from "../../../utils/showInputError/showInputError";
import insurancePlanFormValidation from "./insurancePlanFormValidation";
import { useFormik } from "formik";
import useStyles from "./InsurancePlanForm.styles";
import AppModal from "src/components/ui/AppModal/AppModal";

interface InsurancePlanFormProps {
  documentTemplates: any;
  revenueClasses: RevenueClass[];
  submitHandler: Function;
}

const InsurancePlanForm: React.FC<InsurancePlanFormProps> = ({
  documentTemplates,
  revenueClasses,
  submitHandler
}): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const planName = useAppSelector(selectPlanName);
  const premiumAmount = useAppSelector(selectPremiumAmount);
  const coverageAmount = useAppSelector(selectCoverageAmount);
  const planDescription = useAppSelector(selectPlanDescription);
  const planRevenueClassId = useAppSelector(selectPlanRevenueClassId);
  const documentTemplateId = useAppSelector(selectPlanDocumentTemplateId) ?? 0;
  const planIsActive = useAppSelector(selectPlanIsActive);
  const planIsAutoEnroll = useAppSelector(selectPlanIsAutoEnroll);
  const freeTrail = useAppSelector(selectPlanFreeTrail);

  const [showModal, setShowModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: planName,
      premium_amount: premiumAmount,
      coverage_amount: coverageAmount,
      line_item_description: planDescription,
      revenue_class_id: planRevenueClassId,
      document_template_id: documentTemplateId,
      is_active: planIsActive,
      is_auto_enroll: planIsAutoEnroll,
      free_trial: freeTrail
    },
    enableReinitialize: true,
    validationSchema: insurancePlanFormValidation,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => submitHandler(values)
  });

  const { touched, errors, values, handleChange, setFieldTouched, setFieldValue } = formik;

  const updateForm = (fieldName: string, fieldValue?: string | number | boolean): void => {
    setFieldTouched(fieldName);
    setFieldValue(fieldName, fieldValue);
  };

  const handleNameOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setPlanName(fieldValue));
  };

  const handlePremiumAmountOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setPremiumAmount(fieldValue));
  };

  const handleCoverageAmountOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setCoverageAmount(fieldValue));
  };

  const handleLineItemDescriptionOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setPlanDescription(fieldValue));
  };

  const handleRevenueClassIdOnChange = (fieldName: string, fieldValue: number) => {
    updateForm(fieldName, fieldValue);
    dispatch(setPlanRevenueClassId(fieldValue));
  };

  const handleIsActiveOnChange = (fieldName: string, fieldValue: boolean) => {
    updateForm(fieldName, fieldValue);
    dispatch(setPlanIsActive(fieldValue));
  };

  const handleDocumentTemplateIdOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, parseInt(fieldValue));
    dispatch(setPlanDocumentTemplateId(parseInt(fieldValue)));
  };

  const handleFreeTrailOnChange = (fieldName: string, fieldValue: number) => {
    updateForm(fieldName, fieldValue);
    dispatch(setPlanFreeTrial(fieldValue));
  };

  const handleIsAutoEnrollOnChange = (fieldName: string, fieldValue: boolean) => {
    if (fieldValue) {
      setShowModal(true);
      return;
    }

    updateForm(fieldName, fieldValue);
    dispatch(setPlanIsAutoEnroll(fieldValue));
  };

  const updateAutoEnroll = (value: boolean) => {
    setShowModal(false);

    updateForm("is_auto_enroll", value);
    dispatch(setPlanIsAutoEnroll(value));
  };

  return (
    <Grid
      id={"insurance-plan-form"}
      data-testid={"insurance-plan-form"}
      container
      spacing={2}
      mt={1}
      component={"form"}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        formik.handleSubmit();
      }}
    >
      <Grid item xs={12}>
        <InputLabel htmlFor={"plan-name"}>Name</InputLabel>
        <TextField
          id={"plan-name"}
          inputProps={{ "data-testid": "plan-name" }}
          fullWidth
          variant={"outlined"}
          name={"name"}
          placeholder={"- Plan Name -"}
          value={values.name}
          onChange={(e) => handleNameOnChange("name", e.target.value)}
          error={inputError("name", touched, errors).error}
          helperText={inputError("name", touched, errors).helperText}
        />
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={6}>
          <InputLabel htmlFor={"premium-amount"}>Premium Amount</InputLabel>
          <TextField
            className={classes.noArrowInput}
            id={"premium-amount"}
            type={"number"}
            InputProps={{
              startAdornment: <InputAdornment position={"start"}>$</InputAdornment>
            }}
            fullWidth
            variant={"outlined"}
            name={"premium_amount"}
            placeholder={"- Premium Amount -"}
            inputProps={{
              "data-testid": "premium-amount",
              step: "any"
            }}
            value={values.premium_amount}
            onChange={(e) => handlePremiumAmountOnChange("premium_amount", e.target.value)}
            error={inputError("premium_amount", touched, errors).error}
            helperText={inputError("premium_amount", touched, errors).helperText}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel htmlFor={"coverage-amount"}>Coverage Amount</InputLabel>
          <TextField
            className={classes.noArrowInput}
            id={"coverage-amount"}
            fullWidth
            type={"number"}
            InputProps={{ startAdornment: <InputAdornment position={"start"}>$</InputAdornment> }}
            variant={"outlined"}
            name={"coverage_amount"}
            placeholder={"- Coverage Amount -"}
            inputProps={{
              "data-testid": "coverage-amount",
              step: "any"
            }}
            value={values.coverage_amount}
            onChange={(e) => handleCoverageAmountOnChange("coverage_amount", e.target.value)}
            error={inputError("coverage_amount", touched, errors).error}
            helperText={inputError("coverage_amount", touched, errors).helperText}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InputLabel htmlFor={"plan-description"}>Line Item Description</InputLabel>
        <TextField
          id={"plan-description"}
          inputProps={{
            "data-testid": "plan-description",
            maxLength: 255
          }}
          fullWidth
          variant={"outlined"}
          name={"line_item_description"}
          placeholder={"- Plan Description -"}
          value={values.line_item_description}
          onChange={(e) => handleLineItemDescriptionOnChange("line_item_description", e.target.value)}
          error={inputError("line_item_description", touched, errors).error}
          helperText={inputError("line_item_description", touched, errors).helperText}
        />
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={4}>
          <PMSSelect
            label={"Revenue Class"}
            id={"revenue-class"}
            name={"revenue_class_id"}
            value={values.revenue_class_id}
            changeHandler={(e) => handleRevenueClassIdOnChange("revenue_class_id", e.target.value)}
            error={inputError("revenue_class_id", touched, errors).error}
            helperText={inputError("revenue_class_id", touched, errors).helperText}
          >
            <option value={0} disabled>
              - Select Revenue Class -
            </option>
            {revenueClasses.map((revenueClass) => (
              <option key={revenueClass.id} value={revenueClass.id}>
                {revenueClass.name}
              </option>
            ))}
          </PMSSelect>
        </Grid>
        <Grid item xs={4}>
          <PMSSelect
            label={"Applicable Document"}
            id={"applicable-document"}
            name={"applicable-document-id"}
            value={values.document_template_id}
            changeHandler={(e) => handleDocumentTemplateIdOnChange("document_template_id", e.target.value)}
          >
            <option value={0}>- Select One -</option>
            {documentTemplates.map((documentTemplate: any) => (
              <option key={documentTemplate.id} value={documentTemplate.id}>
                {documentTemplate.name}
              </option>
            ))}
          </PMSSelect>
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={4}>
          <InputLabel htmlFor={"free_trial"}>
            Free Trial
          </InputLabel>
          <TextField
            fullWidth
            id={"free-trial"}
            inputProps={{
              "data-testid": "plan-free-trial",
              maxLength: 2
            }}
            name={"free_trial"}
            value={values.free_trial}
            onChange={(e) => handleFreeTrailOnChange("free_trial", +e.target.value)}
            error={inputError("free_trial", touched, errors).error}
            helperText={inputError("free_trial", touched, errors).helperText}
          />
        </Grid>
        <Grid item xs={2} alignContent={"center"}>
          <PMSSwitch
            name={"is_auto_enroll"}
            label={"Auto Enroll"}
            labelPlacement={"end"}
            isChecked={values.is_auto_enroll}
            changeHandler={(e) => handleIsAutoEnrollOnChange("is_auto_enroll", e.target.checked)}
          />

          <AppModal
            open={showModal}
            onCloseAction={() => updateAutoEnroll(false)}
            cancelAction={() => updateAutoEnroll(false)}
            confirmAction={() => updateAutoEnroll(true)}
            data-testid={"app-layout-modal"}
          >
            <Typography gutterBottom variant={"h6"}>
              Are you sure?
            </Typography>
            <Typography gutterBottom variant={"body1"}>
              Activating Auto-Enroll for this insurance plan will set it as the default for all new rentals.
            </Typography>
          </AppModal>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <PMSSwitch
          name={"is_active"}
          label={"Active"}
          labelPlacement={"end"}
          isChecked={values.is_active}
          changeHandler={(e) => handleIsActiveOnChange("is_active", e.target.checked)}
        />
      </Grid>
    </Grid>
  );
};

export default InsurancePlanForm;
