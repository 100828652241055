import React from "react";
import { Route } from "react-router";
import UserSettings from "../pages/Dashboard/UserSettings/UserSettings";

export const groupName = "user-settings";

export const UserSettingsRoutes = (
  <Route path={groupName} element={<UserSettings />} />
);

export default UserSettingsRoutes;
