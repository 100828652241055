import { Checkbox, FormControlLabel, FormGroup, SelectProps, Tooltip } from "@mui/material";
import React, { ChangeEvent, ReactElement, ReactNode } from "react";
import useStyles from "./PMSCheckbox.styles";

export interface PMSCheckboxProps {
  name?: string;
  changeHandler?: (event: ChangeEvent<any>, child: ReactNode) => void;
  isChecked: boolean;
  value?: string | number;
  smallText?: boolean;
  size?: SelectProps["size"];
  typographyNoWrap?: boolean;
  label?: string;
  disabled?: boolean;
  tooltipText?: string;
}

const PMSCheckbox: React.FC<PMSCheckboxProps> = ({
  name,
  changeHandler,
  size = "medium",
  isChecked,
  smallText = false,
  typographyNoWrap = false,
  label = "",
  disabled = false,
  value,
  tooltipText = ""
}: PMSCheckboxProps): ReactElement => {
  const { classes } = useStyles({ smallText: smallText });

  return (
    <FormGroup>
      <Tooltip placement={"bottom-start"} title={tooltipText} classes={{ tooltip: classes.tooltip }}>
        <FormControlLabel
          data-testid={"pms-checkbox-label"}
          className={classes.root}
          componentsProps={{ typography: { noWrap: typographyNoWrap } }}
          labelPlacement={"end"}
          control={
            <Checkbox
              data-testid={"pms-checkbox"}
              value={value}
              checked={isChecked}
              name={name}
              size={size}
              onChange={changeHandler}
              disabled={disabled}
            />
          }
          label={label}
        />
      </Tooltip>
    </FormGroup>
  );
};

export default PMSCheckbox;
