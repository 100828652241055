import { APIFilter } from "src/models/responses/APIFilter";
import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles<{filter: APIFilter | ""}>({ label: "ManageTasks" })((theme, { filter }) => ({
  createTaskButton: {
    textTransform: "capitalize",
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.common.white,
    width: "9rem"
  },
  activeFilter: {
    color: filter === "" ? theme.palette.text.primary : theme.palette.text.disabled
  },
  todayFilter: {
    color: filter !== "" && Object.values(filter)[0] && !(Object.values(filter)[0] as string).includes("gte")
      ? theme.palette.text.primary
      : theme.palette.text.disabled
  },
  upcomingFilter: {
    color: Object.values(filter)[0] && (Object.values(filter)[0] as string).includes("gte")
      ? theme.palette.text.primary
      : theme.palette.text.disabled
  },
  buttonText: {
    textTransform: "capitalize"
  }
}));

export default useStyles;
