function isValidCents(value: number) {
  return Number.isInteger(value);
}

function centsToDollars(cents?: number | null, adorn: boolean = false) {
  if (!cents) {
    return "N/A";
  }

  if (!isValidCents(cents)) {
    console.log("currency conversion error");
    return "N/A";
  }

  return adorn ? `$${(cents / 100).toFixed(2)}` : (cents / 100).toFixed(2).toString();
}

export default centsToDollars;
