import React from "react";
import { Route } from "react-router";
import CreatePromotion from "../pages/Promotions/CreatePromotion/CreatePromotion";
import EditPromotion from "../pages/Promotions/EditPromotion/EditPromotion";
import Promotions from "../pages/Promotions/Promotions";
import BulkPromotions from "../pages/Promotions/BulkPromotions/BulkPromotions";

export const groupName = "promotions";

export const PromotionsRoutes = (
  <>
    <Route path={groupName} element={<Promotions />} />
    <Route path={`${groupName}/create`} element={<CreatePromotion />} />
    <Route path={`${groupName}/:id/edit`} element={<EditPromotion />} />
    <Route path={`${groupName}/bulk`} element={<BulkPromotions />} />

  </>
);

export default PromotionsRoutes;
