import { makeStyles } from "../../../../../makeStyles";

const useStyles = makeStyles({ label: "AvailableUnitsTableHead" })({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
});

export default useStyles;
