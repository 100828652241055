import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  resetDealsSlice,
  selectDealsFilter,
  selectDealsLoading,
  setDealsFilter
} from "../../../store/reducers/dealsSlice/dealsSlice";
import { resetPage, resetRowsPerPage, resetTablesSlice } from "src/store/reducers/tablesSlice/tablesSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { APIFilter } from "../../../models/responses/APIFilter";
import { Breadcrumb } from "../../../models/Breadcrumb";
import { Button, InputAdornment, TextField } from "@mui/material";
import CancellationModal from "../CancellationModal/CancellationModal";
import FilterButtonGroup from "../../../components/ui/FilterButtonGroup/FilterButtonGroup";
import ManageDealsTable from "./ManageDealsTable/ManageDealsTable";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import ReactivationModal from "../ReactivationModal/ReactivationModal";
import TableWrapper from "../../../layouts/TableWrapper/TableWrapper";
import UnableToReactivateModal from "../UnableToReactivateModal/UnableToReactivateModal";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import clsx from "clsx";
import { getAllActiveDeals } from "../../../store/thunks/deals/getAllActive/getAllActiveDeals";
import { resetProductTypeSlice } from "src/store/reducers/productTypeSlice/productTypeSlice";
import {
  selectSearchValue, selectUnableToReactivateDealType,
  setSearchValue
} from "src/store/reducers/dealSlice/dealSlice";
import { today } from "../../../utils/__dateAndTimeUtils__/today";
import useStyles from "./ManageDeals.styles";
import { SyntheticInputEvent } from "react-number-format/types/types";
import { debounce } from "lodash";
import { Search } from "@mui/icons-material";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Deals"
  },
  {
    name: "Manage Deals",
    bold: true
  }
];

export const leadsFilter: APIFilter = {
  "filter[deal_type]": 1
};

export const reservationsFilter: APIFilter = {
  "filter[deal_type]": 2,
  "filter[reservation_expires_at]": `gte:${today}`
};

export const expiredFilter: APIFilter = {
  include_expired: "1",
  "filter[reservation_expires_at]": `lt:${today}`
};

export const onlyCancelledFilter: APIFilter = {
  only_cancelled: "1"
};

export async function handleResetAndGetDeals(dispatch: Function) {
  await dispatch(resetPage());
  await dispatch(resetRowsPerPage());
  dispatch(getAllActiveDeals(""));
}

const ManageDeals: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectDealsFilter);
  const { classes } = useStyles({ filter: filter });
  const loading = useAppSelector(selectDealsLoading);
  const dealType = useAppSelector(selectUnableToReactivateDealType);
  const searchValue = useAppSelector(selectSearchValue);
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(getAllActiveDeals(filter));
  }, []);

  useEffect(() => {
    /* istanbul ignore next */ // cannot test this w/ enzyme
    return () => {
      dispatch(resetProductTypeSlice());
      dispatch(resetTablesSlice());
    };
  }, []);

  const filterDeals = (filter: APIFilter | "") => {
    dispatch(setSearchValue(""));
    setSearch("");
    dispatch(setDealsFilter(filter));
    dispatch(getAllActiveDeals(filter));
  };

  const filterButtonGroup = (
    <FilterButtonGroup heading={"Display deals: "}>
      <Button
        className={clsx(classes.buttonText, classes.activeFilter)}
        onClick={() => filterDeals("")}
        data-testid={"active-button"}
      >
        Active
      </Button>
      <Button
        className={clsx(classes.buttonText, classes.leadsFilter)}
        onClick={() => filterDeals(leadsFilter)}
        data-testid={"leads-button"}
      >
        Leads
      </Button>
      <Button
        className={clsx(classes.buttonText, classes.reservationsFilter)}
        onClick={() => filterDeals(reservationsFilter)}
        data-testid={"reservations-button"}
      >
        Reservations
      </Button>
      <Button
        className={clsx(classes.buttonText, classes.onlyCancelledFilter)}
        onClick={() => filterDeals(onlyCancelledFilter)}
      >
        Cancelled
      </Button>
      <Button
        className={clsx(classes.buttonText, classes.expiredFilter)}
        onClick={() => filterDeals(expiredFilter)}
        data-testid={"expired-button"}
      >
        Expired
      </Button>
    </FilterButtonGroup>
  );

  useEffect(() => {
    if (!searchValue.length) {
      dispatch(getAllActiveDeals(filter));
      return;
    }

    dispatch(setSearchValue(searchValue));
    dispatch(getAllActiveDeals({
      filter_all_fields: searchValue,
      ...filter
    }));
  }, [searchValue]);

  const debounceSearch = useCallback(
    debounce((text) => dispatch(setSearchValue(text)), 600),
    []
  );

  const handleChange = (e: SyntheticInputEvent) => {
    debounceSearch(e.target.value);
    setSearch(e.target.value);
  };

  const searchBar = (
    <TextField
      fullWidth
      id={"search-occupants-table"}
      placeholder={"Search"}
      size={"small"}
      InputProps={{
        endAdornment: (
          <InputAdornment position={"end"}>
            <Search />
          </InputAdornment>
        )
      }}
      value={search}
      variant={"outlined"}
      name={"occupantsTableSearch"}
      onChange={handleChange}
    />
  );

  const pageHeader = <PageHeader title={"Manage Deals"} breadcrumbs={breadcrumbs}/>;

  return (
    <ViewWrapper pageHeader={pageHeader}>
      <TableWrapper
        title={"Deals"}
        addButtonText={"Add new Deal"}
        addButtonLink={"/deals/create-new-deal"}
        filterButtonGroup={filterButtonGroup}
        table={<ManageDealsTable />}
        searchBar={searchBar}
        loading={loading}
      />
      <CancellationModal />
      <ReactivationModal />
      <UnableToReactivateModal type={dealType} />
    </ViewWrapper>
  );
};

export default ManageDeals;
