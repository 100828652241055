import { Grid, Tooltip, Typography } from "@mui/material";
import React, { FC, useEffect } from "react";
import {
  resetDeliquencyAutomationsSlice,
  selectDelinquencyAutomations,
  selectDelinquencyAutomationsLoading,
  selectDelinquencyAutomationsTypeModal,
  selectSelectedDeliquencyAutomation
} from "src/store/reducers/delinquencyAutomationsSlice/delinquencyAutomationsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Breadcrumb } from "src/models/Breadcrumb";
import DelinquencyAutomation from "src/models/DelinquencyAutomations";
import DelinquencyAutomationsCTAButtons from "./components/CTAButton/DelinquencyAutomationsCTAButton";
import DelinquencyAutomationsFilterButtons from "./components/FilterButtons/DelinquencyAutomationsFilterButtons";
import DelinquencyAutomationsItemButtons from "./components/ItemButtons/DelinquencyAutomationsItemButtons";
import DelinquencyAutomationsTitle from "./components/Title/DelinquencyAutomationsTitle";
import DisableOrEnableModal from "./components/DisableOrEnableModal/DisableOrEnableModal";
import ItemManagementCard from "src/components/itemManagement/ItemManagementCard/ItemManagementCard";
import ItemManagementLayout from "src/layouts/ItemManagement/ItemManagementLayout";
import NoItemsDisplay from "src/components/itemManagement/NoItemsDisplay/NoItemsDisplay";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { resetTablesSlice } from "src/store/reducers/tablesSlice/tablesSlice";
import { selectSelectedAdminFacility } from "src/store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import useStyles from "./DelinquencyAutomations.styles";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Automations"
  },
  {
    name: "System Automations",
    bold: true
  }
];

interface DelinquencyAutomationProps {}

const pageHeader = <PageHeader title={"Automation"} breadcrumbs={breadcrumbs} />;

const DelinquencyAutomations: FC<DelinquencyAutomationProps> = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const automations = useAppSelector(selectDelinquencyAutomations);
  const automationsLoading = useAppSelector(selectDelinquencyAutomationsLoading);
  const selectedFacility = useAppSelector(selectSelectedAdminFacility);
  const typeModal = useAppSelector(selectDelinquencyAutomationsTypeModal);
  const automation = useAppSelector(selectSelectedDeliquencyAutomation);
  const permissions = useAppSelector(selectFacilityPermissions);
  const automationsPermissions = createPermissions(permissions, "automations");

  const bottomRow = (automation: DelinquencyAutomation) => (
    <>
      <Grid
        item
        xs={6}
        pr={2}
        lg={"auto"}
        order={{
          xs: 1,
          lg: 1
        }}
      >
        <span className={classes.cellTitle}>Fee Applied: </span>Fee Here
      </Grid>
      <Grid
        item
        xs={6}
        pr={2}
        lg={"auto"}
        order={{
          xs: 2,
          lg: 2
        }}
      >
        <span className={classes.cellTitle}>Document Applied: </span>
        Document Name here
      </Grid>
      <Grid
        item
        xs={6}
        pr={2}
        lg={"auto"}
        order={{
          xs: 5,
          lg: 3
        }}
      >
        <span className={classes.cellTitle}>Automation Category: </span>
        Category name here
      </Grid>
      <Grid
        item
        xs={6}
        pr={2}
        lg={"auto"}
        order={{
          xs: 3,
          lg: 4
        }}
      >
        <span className={classes.cellTitle}>Task Applied: </span>Task Name here
      </Grid>
    </>
  );

  const automationsList = (
    <Grid mt={1} data-testid={"automations-list"}>
      {automations.map((automation) => {
        const days = automation.days === 1 ? "day" : "days";
        return (
          <ItemManagementCard
            blurb={
              <Tooltip title={`Apply ${automation.name} upon ${automation.days} ${days} of being late.`}>
                <Typography noWrap variant={"subtitle1"} className={classes.description}>
                  Apply {automation.name} upon {automation.days} {days} of being late.
                </Typography>
              </Tooltip>
            }
            data-testid={"item-management-card-" + automation.id}
            buttonGroup={
              automationsPermissions.update ? <DelinquencyAutomationsItemButtons automation={automation} /> : null
            }
            bottomRow={bottomRow(automation)}
            key={automation.id}
            item={automation}
          />
        );
      })}
    </Grid>
  );

  const automationsDisplay = () => {
    if (!selectedFacility) {
      return null;
    }

    if (!automations.length) {
      return <NoItemsDisplay item={`automation`} link={"/delinquency-automations/create-automation"} />;
    }

    return automationsList;
  };

  useEffect(() => {
    return () => {
      dispatch(resetDeliquencyAutomationsSlice());
      dispatch(resetTablesSlice());
    };
  }, []);

  if (automationsPermissions.read) {
    return (
      <>
        <ViewWrapper pageHeader={pageHeader}>
          <ItemManagementLayout
            title={<DelinquencyAutomationsTitle />}
            filters={<DelinquencyAutomationsFilterButtons />}
            headerButton={automationsPermissions.create ? <DelinquencyAutomationsCTAButtons /> : null}
            loading={automationsLoading}
          >
            {automationsDisplay()}
          </ItemManagementLayout>
        </ViewWrapper>
        <DisableOrEnableModal type={typeModal} automationName={automation?.name || ""} />
      </>
    );
  } else {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <h1>You do not have permission to view this page.</h1>
      </ViewWrapper>
    );
  }
};

export default DelinquencyAutomations;
