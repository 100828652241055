import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import HelloSign from "hellosign-embedded";
import { getEsignDocumentKeys } from "src/store/thunks/esignDocument/sign/getKeys";
import { signDocument } from "src/store/thunks/esignDocument/sign/signDocument";
import { useAppDispatch } from "src/store/hooks";

const EsignDocument = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const esignDiv = document.getElementById("esign");
  const rootDiv = document.getElementById("root");
  const successDiv = document.getElementById("successMessage");
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    (async() => {
      if (params.eSignId) {
        const response = await dispatch(getEsignDocumentKeys({ id: +params.eSignId }));

        const { embed_url, client_id } = response.payload;

        if (esignDiv && !isOpened && rootDiv && successDiv) {
          esignDiv.style.height = "100vh";
          successDiv.style.display = "none";
          successDiv.innerHTML = "";
          rootDiv.style.display = "none";

          const client = new HelloSign({
            clientId: client_id,
            testMode: Boolean(process.env.REACT_APP_TEST_MODE_ESIGN),
            skipDomainVerification: true,
            container: esignDiv
          });

          client.on("sign", () => {
            if (params.eSignId) {
              dispatch(signDocument({ id: +params?.eSignId }));
            }
          });

          client.on("close", () => {
            window.close();
          });

          client.open(embed_url);
          setIsOpened(true);
        }
      }
    })();
  }, []);

  return null;
};

export default EsignDocument;
