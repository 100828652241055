import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EmergencyContact } from "../../../models/EmergencyContact";
import { EmergencyContactInformationSliceState } from "./EmergencyContactInformationSliceState";
import { EmergencyContactValues } from "../../../models/formikInputValues/occupant/EmergencyContactValues";
import { RootState } from "../../rootStore";
import { createEmergencyContact } from "../../thunks/emergencyContact/create/createEmergencyContact";
import { getOccupant } from "src/store/thunks/occupant/getOne/getOccupant";
import { updateEmergencyContact } from "../../thunks/emergencyContact/update/updateEmergencyContact";

export const initialState: EmergencyContactInformationSliceState = {
  id: 0,
  occupant_id: 0,
  first_name: "",
  middle_name: "",
  last_name: "",
  address: "",
  address_two: "",
  city: "",
  region: "",
  postal_code: "",
  phone_primary: "",
  relationship: "",
  email: "",
  country: "USA",
  created_at: "",
  updated_at: "",
  emergencyContactInformationLoading: false,
  emergencyContactAddressSameAsOccupant: false,
  emergencyContactInformationError: ""
};

export const emergencyContactInformationSlice = createSlice({
  name: "emergencyContactInformation",
  initialState,
  reducers: {
    setEmergencyContact: (state, action: PayloadAction<EmergencyContactValues>) => {
      state.first_name = action.payload.firstName;
      state.middle_name = action.payload.middleName;
      state.last_name = action.payload.lastName;
      state.address = action.payload.addressOne;
      state.address_two = action.payload.addressTwo;
      state.city = action.payload.city;
      state.region = action.payload.state;
      state.postal_code = action.payload.postalCode;
      state.phone_primary = action.payload.phone;
      state.relationship = action.payload.relationship;
      state.email = action.payload.email;
    },
    setECPrimaryPhone: (state, action) => {
      state.phone_primary = action.payload;
    },
    setECSecondaryPhone: (state, action) => {
      state.phone_alternate = action.payload;
    },
    setEmergencyContactAddressSameAsOccupant: (state, action) => {
      state.emergencyContactAddressSameAsOccupant = !state.emergencyContactAddressSameAsOccupant;

      if (state.emergencyContactAddressSameAsOccupant) {
        state.address = action.payload.address;
        state.address_two = action.payload.addressTwo;
        state.city = action.payload.city;
        state.region = action.payload.state;
        state.postal_code = action.payload.postalCode;
        state.country = action.payload.country;
      }

      if (!state.emergencyContactAddressSameAsOccupant) {
        state.address = initialState.address;
        state.address_two = initialState.address_two;
        state.city = initialState.city;
        state.region = initialState.region;
        state.postal_code = initialState.postal_code;
        state.country = initialState.country;
      }
    },
    resetEmergencyContactInformationSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createEmergencyContact.pending, (state) => {
        state.emergencyContactInformationLoading = true;
      })
      .addCase(createEmergencyContact.fulfilled, (state, action: PayloadAction<EmergencyContact>) => {
        state.id = action.payload.id;
        state.emergencyContactInformationLoading = false;
      })
      .addCase(createEmergencyContact.rejected, (state, action) => {
        state.emergencyContactInformationLoading = false;
        state.emergencyContactInformationError = action.payload as string;
      })
      .addCase(updateEmergencyContact.pending, (state) => {
        state.emergencyContactInformationLoading = true;
      })
      .addCase(updateEmergencyContact.fulfilled, (state) => {
        state.emergencyContactInformationLoading = false;
      })
      .addCase(updateEmergencyContact.rejected, (state, action) => {
        state.emergencyContactInformationLoading = false;
        state.emergencyContactInformationError = action.payload as string;
      })
      .addCase(getOccupant.fulfilled, (state, action) => {
        state.id = action.payload.contacts[0]?.id ?? "";
        state.first_name = action.payload.contacts[0]?.first_name ?? "";
        state.middle_name = action.payload.contacts[0]?.middle_name ?? "";
        state.last_name = action.payload.contacts[0]?.last_name ?? "";
        state.address = action.payload.contacts[0]?.address ?? "";
        state.address_two = action.payload.contacts[0]?.address_two ?? "";
        state.city = action.payload.contacts[0]?.city ?? "";
        state.region = action.payload.contacts[0]?.region ?? "";
        state.postal_code = action.payload.contacts[0]?.postal_code ?? "";
        state.phone_primary = action.payload.contacts[0]?.phone_primary ?? "";
        state.relationship = action.payload.contacts[0]?.relationship ?? "";
        state.email = action.payload.contacts[0]?.email ?? "";
      });
  }
});

export const selectECFirstName = (state: RootState) => state.emergencyContactInformation.first_name;
export const selectECMiddleName = (state: RootState) => state.emergencyContactInformation.middle_name;
export const selectECLastName = (state: RootState) => state.emergencyContactInformation.last_name;
export const selectECAddressOne = (state: RootState) => state.emergencyContactInformation.address;
export const selectECAddressTwo = (state: RootState) => state.emergencyContactInformation.address_two;
export const selectECCity = (state: RootState) => state.emergencyContactInformation.city;
export const selectECState = (state: RootState) => state.emergencyContactInformation.region;
export const selectECPostalCode = (state: RootState) => state.emergencyContactInformation.postal_code;
export const selectEcValidMailingAddress = (state: RootState) => {
  return !!(
    state.emergencyContactInformation.address &&
    state.emergencyContactInformation.city &&
    state.emergencyContactInformation.region &&
    (state.emergencyContactInformation.postal_code.length === 5)
  );
};
export const selectECAddressSameAsOccupant = (state: RootState) =>
  state.emergencyContactInformation.emergencyContactAddressSameAsOccupant;
export const selectECId = (state: RootState) => state.emergencyContactInformation.id;
export const selectECPhone = (state: RootState) => state.emergencyContactInformation.phone_primary;
export const selectECRelationship = (state: RootState) => state.emergencyContactInformation.relationship;
export const selectECEmail = (state: RootState) => state.emergencyContactInformation.email;
export const selectECInformationLoading = (state: RootState) =>
  state.emergencyContactInformation.emergencyContactInformationLoading;

export const {
  setEmergencyContact,
  setECPrimaryPhone,
  setECSecondaryPhone,
  setEmergencyContactAddressSameAsOccupant,
  resetEmergencyContactInformationSlice
} = emergencyContactInformationSlice.actions;

export default emergencyContactInformationSlice.reducer;
