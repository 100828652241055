import { Button, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { NavLink } from "react-router-dom";
import React from "react";
import { selectDealInformationLoading } from "src/store/reducers/dealInformationSlice/dealInformationSlice";
import {
  selectDeal
  , setCancelDealModalOpen
} from "src/store/reducers/dealSlice/dealSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import useStyles from "./EditDealFooterButtons.styles";
import { Deal } from "src/models/Deal";
interface EditDealFooterButtonsProp {
  formId: string
}
const EditDealFooterButtons: React.FC<EditDealFooterButtonsProp> = ({ formId }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const dealLoading = useAppSelector(selectDealInformationLoading);
  const deal = useAppSelector(selectDeal) as Deal;

  return (
    <Grid
      item
      xs={12}
      mt={8}
      mb={1}
      container
      justifyContent={"end"}
      data-testid={"item-footer-buttons"}
      className={classes.footerButtons}
        >
      <Grid container justifyContent={"space-between"}>
        <Button
          variant={"outlined"}
          color={"error"}
          data-testid={"cancel-deal-button"}
          onClick={() => dispatch(setCancelDealModalOpen(true))}
          disabled={!!deal?.cancelled_at}
        >
          Cancel Deal
        </Button>
        <Grid
          container
          spacing={3}
          justifyContent={"flex-end"}
          item
          xs={6}
        >
          <Grid item>
            <Button
              variant={"text"}
              color={"error"}
              component={NavLink}
              to={"/deals/manage-deals"}
                        >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              form={formId}
              variant={"contained"}
              className={classes.saveButton}
              type={"submit"}
              loading={dealLoading}
              disabled={dealLoading}
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid >
  );
};

export default EditDealFooterButtons;
