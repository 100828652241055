import { Edit, RemoveCircleOutline } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { Facility } from "src/models/Facility";
import { NavLink } from "react-router-dom";
import PMSTaskCategoryBadge from "src/components/ui/PMSTaskCategoryBadge/PMSTaskCategoryBadge";
import React from "react";
import { formatDate } from "../../../../utils/__dateAndTimeUtils__/formatDate/formatDate";
import { getTaskRecurrencePrettyName } from "src/utils/getTaskRecurrencePrettyName/getTaskRecurrencePrettyName";
import { useAppSelector } from "src/store/hooks";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

export const ManageTasksTableColumns = () => {
  const permissions = useAppSelector(selectFacilityPermissions);
  const manageTasksPermissions = createPermissions(permissions, "manage_tasks");

  const columns = [
    {
      field: "name",
      renderHeader: () => (
        <Tooltip title={"Task Name"}>
          <Typography>Task</Typography>
        </Tooltip>
      ),
      renderCell: ({ row }: GridRenderCellParams) => (
        <Tooltip title={row.name}>
          <Typography variant={"inherit"} noWrap>
            {row.name}
          </Typography>
        </Tooltip>
      ),
      minWidth: 200,
      flex: 1
    },
    {
      field: "assignee",
      headerName: "",
      renderHeader: () => (
        <Tooltip title={"Facilities Applied"}>
          <Typography>Facility</Typography>
        </Tooltip>
      ),
      minWidth: 220,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.assignees.map((assignee: Facility) => assignee.name)
    },
    {
      field: "task_category",
      renderHeader: () => (
        <Tooltip title={"Task Category"}>
          <Typography>Category</Typography>
        </Tooltip>
      ),
      minWidth: 170,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <PMSTaskCategoryBadge
          color={params.row.task_category.color}
          category={params.row.task_category.name}
        />
      )
    },
    {
      field: "recurrence",
      renderHeader: () => (
        <Tooltip title={"Task Recurrence"}>
          <Typography>Recurrence</Typography>
        </Tooltip>
      ),
      minWidth: 140,
      valueGetter: (params: GridValueGetterParams) =>
        getTaskRecurrencePrettyName(params.row.recurrence)
    },
    {
      field: "due_date",
      renderHeader: () => (
        <Tooltip title={"Due Date"}>
          <Typography>Due Date</Typography>
        </Tooltip>
      ),
      minWidth: 100,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        formatDate(params.row.due_date, "MM/DD/YYYY", "N/A")
    },
    {
      field: "due_time",
      renderHeader: () => (
        <Tooltip title={"Due Time"}>
          <Typography>Due Time</Typography>
        </Tooltip>
      ),
      minWidth: 100,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.is_all_day ? "N/A" : params.row.due_time
    }
  ];

  if (manageTasksPermissions.update) {
    columns.push({
      field: "actions",
      renderHeader: () => (
        <Tooltip title={"Actions"}>
          <Typography>Actions</Typography>
        </Tooltip>
      ),
      minWidth: 100,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Grid container direction={"row"} alignItems={"center"}
          justifyContent={"start"}>
          <Tooltip title={"View/Edit Task"}>
            <IconButton
              role={"button"}
              data-testid={"edit-task-button"}
              title={"Edit Task"}
              to={`/task-management/${params.row.id}/edit`}
              size={"small"}
              component={NavLink}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Disable Task"}>
            <IconButton size={"small"} data-testid={"disable-task-button"}>
              <RemoveCircleOutline />
            </IconButton>
          </Tooltip>
        </Grid>
      )
    });
  }

  return columns;
};

export default ManageTasksTableColumns;
