import { Button, Divider, Grid, IconButton, InputLabel, Paper, TextField, Typography } from "@mui/material";
import React, { FC, SyntheticEvent, useEffect } from "react";
import {
  selectChartOfAccountsSettingsLoading,
  selectIsEditSettingModal,
  selectSelectedSetting,
  setEditModal
} from "src/store/reducers/chartOfAccountsSlice/chartOfAccountsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import AddNewSettingValues from "src/models/AddNewSettingValues";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import { SyntheticInputEvent } from "react-number-format/types/types";
import chartOfAccountsSettingValidation from "./chartOfAccountsSettingValidation";
import clsx from "clsx";
import { createChartOfAccountsSetting } from "src/store/thunks/chartOfAccounts/create/createChartOfAccountsSetting";
import { getChartOfAccountSettings } from "src/store/thunks/chartOfAccounts/get/getChartOfAccountsSettings";
import { inputError } from "src/utils/showInputError/showInputError";
import { selectSelectedAdminFacility } from "src/store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import { updateCharOfAccountsSetting } from "src/store/thunks/chartOfAccounts/update/updateChartOfAccountsSetting";
import { useFormik } from "formik";
import useStyles from "./ChartOfAccountsSettingForm.styles";

interface ChartOfAccountsSettingFormProps {
  closeButtonAction: () => void
}

const initialValues = {
  category: 0,
  account_name: "",
  sub_category: "",
  gl_number: ""
} as AddNewSettingValues;

const ChartOfAccountsSettingForm: FC<ChartOfAccountsSettingFormProps> = ({ closeButtonAction }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const settingsLoading = useAppSelector(selectChartOfAccountsSettingsLoading);
  const isUpdate = useAppSelector(selectIsEditSettingModal);
  const selectedSetting = useAppSelector(selectSelectedSetting);
  const selectedFacility = useAppSelector(selectSelectedAdminFacility);

  const formik = useFormik({
    initialValues: selectedSetting && isUpdate ? selectedSetting as AddNewSettingValues : initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: chartOfAccountsSettingValidation,
    onSubmit: (values) => {
      if (isUpdate && selectedFacility && selectedSetting) {
        return dispatch(updateCharOfAccountsSetting({
          ...values,
          facility_id:
          selectedFacility.id,
          id: selectedSetting?.id
        }))
          .then((resp) => {
            if (!resp.type.includes("rejected")) {
              closeButtonAction();
              dispatch(getChartOfAccountSettings());
            }
          });
      }

      dispatch(createChartOfAccountsSetting(values))
        .then((resp) => {
          if (!resp.type.includes("rejected")) {
            closeButtonAction();
            dispatch(getChartOfAccountSettings());
          }
        });
    }
  });

  const { values, handleChange, handleSubmit, touched, errors, setFieldValue, setFieldTouched } = formik;

  const handleChangeGL = (e:SyntheticInputEvent) => {
    const onlyNumbers = e.target.value.replace(/[^0-9]/g, "");
    setFieldTouched("gl_number");
    setFieldValue("gl_number", onlyNumbers);
  };

  useEffect(() => {
    return () => {
      dispatch(setEditModal(false));
    };
  }, []);

  return (
    <Grid
      container
      className={classes.modalContainer}
      component={Paper}
      elevation={0}
    >
      <Grid
        pt={0}
        component={"form"}
        id={"new-chart-of-accounts-setting-form"}
        onSubmit={(e: SyntheticEvent) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}
        container
        spacing={2}
    >
        <Grid
          container
          alignContent={"center"}
          item
          xs={12}
          justifyContent={"space-between"}
      >
          <Typography variant={"h4"}>{!isUpdate ? "Add New Setting" : "Update Setting"}</Typography>
          <IconButton onClick={closeButtonAction}>
            <Close color={"primary"} fontSize={"large"} />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"h5"}>Setting Information</Typography>
        </Grid>
        <Grid item xs={6}>
          <InputLabel htmlFor={"account-name"}>
            Account Name
          </InputLabel>
          <TextField
            fullWidth
            disabled={isUpdate && !!selectedSetting && selectedSetting.is_original}
            id={"account-name"}
            inputProps={{ "data-testid": "account-name", maxLength: 25 }}
            name={"account_name"}
            value={values.account_name}
            onChange={handleChange}
            error={inputError("account_name", touched, errors).error}
            helperText={inputError("account_name", touched, errors).helperText}
        />
        </Grid>
        <Grid item xs={6}>
          <InputLabel htmlFor={"category"}>
            Account Category
          </InputLabel>
          <PMSSelect
            fullWidth
            disabled={isUpdate && !!selectedSetting && selectedSetting.is_original}
            id={"category"}
            name={"category"}
            value={values.category}
            changeHandler={handleChange}
            error={inputError("category", touched, errors).error}
            helperText={inputError("category", touched, errors).helperText}
        >
            <option value={0} disabled>Select a Category</option>
            <option value={1}>Asset</option>
            <option value={2}>Liabilities</option>
            <option value={3}>Revenue</option>
            <option value={4}>Expense</option>
            <option value={5}>Cost of Goods Sold</option>
          </PMSSelect>
        </Grid>
        <Grid item xs={6}>
          <InputLabel htmlFor={"subcategory"}>
            Account Subcategory
          </InputLabel>
          <TextField
            fullWidth
            disabled={isUpdate && !!selectedSetting && selectedSetting.is_original}
            id={"subcategory"}
            inputProps={{ "data-testid": "subcategory", maxLength: 25 }}
            name={"sub_category"}
            value={values.sub_category}
            onChange={handleChange}
            error={inputError("sub_category", touched, errors).error}
            helperText={inputError("sub_category", touched, errors).helperText}
        />
        </Grid>
        <Grid item xs={6}>
          <InputLabel htmlFor={"gl_number"}>
            G/L Number
          </InputLabel>
          <TextField
            fullWidth
            id={"gl_number"}
            inputProps={{ "data-testid": "gl_number", maxLength: 25 }}
            name={"gl_number"}
            value={values.gl_number}
          // eslint-disable-next-line no-return-assign
            onChange={handleChangeGL}
            error={inputError("gl_number", touched, errors).error}
            helperText={inputError("gl_number", touched, errors).helperText}
          />
        </Grid>
        <Grid
          container
          justifyContent={"flex-end"}
          item
          xs={12}
      >
          <Button
            onClick={closeButtonAction}
            variant={"text"}
            color={"error"}
            className={classes.buttonBase}
            size={"large"}
        >
            Cancel
          </Button>
          <LoadingButton
            variant={"contained"}
            className={clsx(classes.saveButton, classes.buttonBase)}
            size={"large"}
            type={"submit"}
            loading={settingsLoading}
            disabled={settingsLoading}
        >
            Save
          </LoadingButton>

        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChartOfAccountsSettingForm;
