import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React, { FormEvent, useEffect } from "react";
import {
  selectAddressOne,
  selectAddressTwo,
  selectCity,
  selectFirstName,
  selectLastName,
  selectMiddleName,
  selectOccupantAddressInformation,
  selectPostalCode,
  selectState
}
  from "src/store/reducers/occupantInformationSlice/occupantInformationSlice";
import {
  selectChangeOwnershipIsEnabled,
  setChangeOwnerShipStep
} from "src/store/reducers/occupantSlice/occupantSlice";
import {
  selectExistingOccupantBillingAddressContact,
  selectOccupantBillingAddressOption,
  setOccuppantBillingAddressOption
} from "src/store/reducers/occupantBillingInformationSlice/occupantBillingInformationSlice";
import { selectFormOccupantInformation, setBillingInformationForm }
  from "src/store/reducers/changeOwnership/changeOwnership";
import { selectPaymentType, setPaymentType } from "src/store/reducers/paymentSlice/paymentSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { useNavigate, useParams } from "react-router";

import AlertChangeOwnership from "../../components/AlertChangeOwnership/AlertChangeOwnership";
import BillingAddressForm from "./BillingAddressForm/BillingAddressForm";
import BillingAddressOptions from "./BillingAddressOptions/BillingAddressOptions";
import {
  BillingInformationContactPayload
} from "src/store/thunks/billingContact/create/BillingInformationContactPayload";
import { BillingInformationContactValues } from "src/models/formikInputValues/BillingInformationContactValues";
import BillingInformationFooterButtons
  from "src/pages/Deals/CreateMoveIn/ReviewMoveIn/PaymentEntry/BillingInformationFooterButtons";
import { OwnershipButtons } from "../../components/OwnershipButtons/OwnershipButtons";
import PaymentEntry from "src/pages/Deals/CreateMoveIn/ReviewMoveIn/PaymentEntry/PaymentEntry";
import PaymentMethod from "src/pages/Deals/CreateMoveIn/ReviewMoveIn/PaymentMethod/PaymentMethod";
import { PaymentMethod as PaymentMethodEnum } from "src/enums/PaymentMethod";
import billingInformationValidation from "./BillingAddressForm/billingAddressFormValidation";
import { createBillingContact } from "src/store/thunks/billingContact/create/createBillingContact";
import { savePaymentInstrument } from "src/store/thunks/paymentInstrument/savePaymentInstrument";
import { selectAch } from "src/store/reducers/achSlice/achSlice";
import { selectCreditCard } from "src/store/reducers/creditCardSlice/creditCardSlice";
import { updateBillingContact } from "src/store/thunks/billingContact/update/updateBillingContact";
import { useFormik } from "formik";
import useStyles from "./BillingInformation.styles";
import { getOccupant } from "src/store/thunks/occupant/getOne/getOccupant";

const BillingInformation = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const billingAddressOption = useAppSelector(selectOccupantBillingAddressOption);
  const paymentType = useAppSelector(selectPaymentType);
  const firstName = useAppSelector(selectFirstName);
  const middleName = useAppSelector(selectMiddleName);
  const lastName = useAppSelector(selectLastName);
  const addressOne = useAppSelector(selectAddressOne);
  const addressTwo = useAppSelector(selectAddressTwo);
  const city = useAppSelector(selectCity);
  const state = useAppSelector(selectState);
  const postalCode = useAppSelector(selectPostalCode);
  const formId = "edit-occupant-billing-form";
  const paymentInformationFormId = "edit-occupant-payment-information-form";
  const billingContact = useAppSelector(selectExistingOccupantBillingAddressContact);
  const occupantAddressInformation = useAppSelector(selectOccupantAddressInformation);
  const changeOwnershipIsEnabled = useAppSelector(selectChangeOwnershipIsEnabled);
  const formOccupantInformation = useAppSelector(selectFormOccupantInformation);
  const ach = useAppSelector(selectAch);
  const creditCard = useAppSelector(selectCreditCard);

  const handleOnSubmitPaymentInformation = () => {
    if (!changeOwnershipIsEnabled) {
      if (paymentType === PaymentMethodEnum.creditCard) {
        dispatch(
          savePaymentInstrument({
            occupantId: params.id!,
            creditCard: {
              cardHolderName: creditCard.name,
              cardNumber: creditCard.number.replaceAll(" ", ""),
              expirationMonth: creditCard.expiration.slice(0, 2),
              expirationYear: "20" + creditCard.expiration.slice(2, 4),
              cardSecurityCode: creditCard.cvv,
              cardProcessor: creditCard.type
            }
          })
        );
      }

      if (paymentType === PaymentMethodEnum.ach) {
        dispatch(
          savePaymentInstrument({
            occupantId: params.id!,
            ach: {
              accountHolderName: ach.accountName,
              accountNumber: ach.accountNumber,
              routingNumber: ach.routingNumber,
              accountType: ach.accountType!,
              bankName: ach.bankName
            }
          })
        );
      }

      dispatch(getOccupant(params.id!));
    } else {
      dispatch(setChangeOwnerShipStep(2));
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName,
      middleName,
      lastName,
      addressOne,
      addressTwo,
      city,
      state,
      postalCode
    },
    validationSchema: billingInformationValidation,
    validateOnBlur: false,
    validateOnChange: false,

    onSubmit: (values: BillingInformationContactValues) => {
      if (changeOwnershipIsEnabled) {
        const payload = {
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          address: values.addressOne,
          addressTwo: values.addressTwo,
          city: values.city,
          state: values.state,
          postalCode: values.postalCode
        };

        dispatch(setBillingInformationForm(payload));
      } else {
        const payload: BillingInformationContactPayload = {
          occupantId: params.id!,
          first_name: values.firstName,
          middle_name: values.middleName,
          last_name: values.lastName,
          address: values.addressOne,
          address_two: values.addressTwo,
          city: values.city,
          region: values.state,
          postal_code: values.postalCode
        };

        if (billingContact?.id) {
          dispatch(updateBillingContact({ ...payload, contactId: billingContact.id! }));
          return;
        }

        dispatch(createBillingContact(payload));
      }
    }
  });

  useEffect(() => {
    if (creditCard.number) {
      dispatch(setPaymentType(PaymentMethodEnum.creditCard));
    } else {
      dispatch(setPaymentType(PaymentMethodEnum.ach));
    }
  }, []);

  // we want this ONLY to run on mount
  useEffect(() => {
    if (billingContact !== undefined && !changeOwnershipIsEnabled) {
      // any non required fields will need to be cleared so what was in the
      // fields from the occupant doesnt contaminate the billing contact.
      formik.setFieldValue("middleName", "");
      formik.setFieldValue("addressTwo", "");
      dispatch(setOccuppantBillingAddressOption(2));
    }
    return () => {
      dispatch(setOccuppantBillingAddressOption(1));
    };
  }, []);

  useEffect(() => {
    // if option is 2, if no contact, reset form.
    if (changeOwnershipIsEnabled) {
      formik.setFieldValue("firstName", "");
      formik.setFieldValue("middleName", "");
      formik.setFieldValue("lastName", "");
      formik.setFieldValue("addressOne", "");
      formik.setFieldValue("addressTwo", "");
      formik.setFieldValue("city", "");
      formik.setFieldValue("state", "");
      formik.setFieldValue("postalCode", "");
    } else {
      if (billingAddressOption === 2 && !billingContact) {
        formik.setFieldValue("firstName", "");
        formik.setFieldValue("middleName", "");
        formik.setFieldValue("lastName", "");
        formik.setFieldValue("addressOne", "");
        formik.setFieldValue("addressTwo", "");
        formik.setFieldValue("city", "");
        formik.setFieldValue("state", "");
        formik.setFieldValue("postalCode", "");
      }

      if (billingAddressOption === 2 && billingContact) {
        //if option is 2, if there IS a contact, put that in there.
        formik.setFieldValue("firstName", billingContact?.firstName);
        formik.setFieldValue("middleName", billingContact?.middleName);
        formik.setFieldValue("lastName", billingContact?.lastName);
        formik.setFieldValue("addressOne", billingContact?.addressOne);
        formik.setFieldValue("addressTwo", billingContact?.addressTwo);
        formik.setFieldValue("city", billingContact?.city);
        formik.setFieldValue("state", billingContact?.state);
        formik.setFieldValue("postalCode", billingContact?.postalCode);
      }
    }
  }, [billingAddressOption]);

  useEffect(() => {
    if (changeOwnershipIsEnabled) {
      let form = {
        firstName: "",
        middleName: "",
        lastName: "",
        addressOne: "",
        addressTwo: "",
        city: "",
        state: "",
        postalCode: ""
      };

      if (billingAddressOption === 1) {
        form = {
          firstName: formOccupantInformation.firstName,
          middleName: formOccupantInformation.middleName as string,
          lastName: formOccupantInformation.lastName,
          addressOne: formOccupantInformation.address,
          addressTwo: formOccupantInformation.addressTwo as string,
          city: formOccupantInformation.city,
          state: formOccupantInformation.state,
          postalCode: formOccupantInformation.postalCode
        };
      }

      formik.resetForm({
        values: form
      });
    } else {
      if (billingAddressOption === 2 && billingContact !== undefined) {
        const form = {
          firstName: billingContact.firstName,
          middleName: billingContact?.middleName as string ?? "",
          lastName: billingContact.lastName,
          addressOne: billingContact.addressOne,
          addressTwo: billingContact?.addressTwo as string ?? "",
          city: billingContact.city,
          state: billingContact.state,
          postalCode: billingContact.postalCode
        };
        formik.resetForm({ values: form });
      }
      if (billingContact !== undefined) {
        dispatch(setOccuppantBillingAddressOption(2));
      }
    }
  }, [changeOwnershipIsEnabled]);

  const occupantTextDisplay = () => {
    return (
      <Grid maxHeight={"8rem"} container item
        xs={12}>
        <Grid item xs={12}>
          <Typography fontWeight={900}>{changeOwnershipIsEnabled
            ? `${formOccupantInformation.firstName}
              ${formOccupantInformation.middleName ?? ""}
              ${formOccupantInformation.lastName}`
            : `${firstName} ${middleName ?? ""} ${lastName}`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>{changeOwnershipIsEnabled
            ? formOccupantInformation.address
            : occupantAddressInformation.address}</Typography>
        </Grid>
        {changeOwnershipIsEnabled
          ? (
            <>
              {formOccupantInformation.addressTwo ??
              <Grid item xs={12}>
                <Typography>{formOccupantInformation.addressTwo}</Typography>
              </Grid>
          }
            </>
            )
          : (
            <>
              {occupantAddressInformation.addressTwo ??
              <Grid item xs={12}>
                <Typography>{occupantAddressInformation.addressTwo}</Typography>
              </Grid>
          }
            </>
            )}
        <Grid item xs={12}>
          <Typography>
            {changeOwnershipIsEnabled
              ? (
                <>
                  {formOccupantInformation.city &&
                  formOccupantInformation.state &&
                  formOccupantInformation.postalCode && (
                    <>
                      {/* eslint-disable-next-line max-len */}
                      {formOccupantInformation.city + ", " + formOccupantInformation.state + ", " + formOccupantInformation.postalCode}
                    </>
                  )}
                </>
                )
              : (
                <>
                  {/* eslint-disable-next-line max-len */}
                  {occupantAddressInformation.city + ", " + occupantAddressInformation.state + ", " + occupantAddressInformation.postalCode}
                </>
                )}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const updateBillingAddressButton = () => {
    return (
      <Grid item xs={12}>
        <Button
          type={"submit"}
          id={formId}
          variant={"contained"}
          className={classes.updateButton}
        >
          Update Billing Address
        </Button>
      </Grid>
    );
  };
  return (
    <Grid container>
      <Grid
        component={"form"}
        id={formId}
        onSubmit={(e: FormEvent) => {
          e.preventDefault();
          e.stopPropagation();
          formik.handleSubmit();
        }}
    >
        <Box className={classes.containerTitle}>
          <Typography gutterBottom variant={"h6"} component={"h3"}>Billing Information</Typography>
          <OwnershipButtons />
        </Box>
        <Divider />
        {changeOwnershipIsEnabled && (
          <AlertChangeOwnership />
        )}

        <Grid container>

          <Grid xs={12} item>
            <Typography
              gutterBottom
              className={classes.headerCell}
              sx={{ marginTop: "1rem" }}
            >
              Billing Address
            </Typography>
          </Grid>

          <Grid
            container
            justifyContent={"space-between"}
            mt={2}
          >

            <Grid
              container
              item
              xs={3.75}
            >
              <Grid item xs={12}>
                <BillingAddressOptions formik={formik} />
              </Grid>
              {billingAddressOption === 2 ? updateBillingAddressButton() : occupantTextDisplay()}
            </Grid>

            <Grid
              container
              item
              xs={8.25}
            >
              <BillingAddressForm formik={formik} />
            </Grid>

          </Grid>

        </Grid>

      </Grid>

      <Grid container>
        <Grid
          xs={3}
          item
          mt={"1rem"}
          mr={"3rem"}
        >
          <Grid item xs={12}>
            <Typography
              className={classes.headerCell}
            >
              Payment method
            </Typography>
          </Grid>

          <Grid item xs={12} lg={3}>
            <PaymentMethod paymentOptions={[PaymentMethodEnum.creditCard, PaymentMethodEnum.ach]} />
          </Grid>

        </Grid>

        <Grid
          item
          xs={7}
          mt={"1rem"}
          ml={"2rem"}
          spacing={2}>
          <PaymentEntry
            formId={paymentInformationFormId}
            handleSubmit={handleOnSubmitPaymentInformation}
            withPaymentOptions={false}
          />
          <BillingInformationFooterButtons
            onCancel={() => navigate("/occupants/manage-occupants")}
            formId={paymentInformationFormId}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BillingInformation;
