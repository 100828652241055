import React from "react";
import { Route } from "react-router";
import AddFacility from "../pages/FacilityManagement/AddFacility/AddFacility";
import EditFacility from "../pages/FacilityManagement/EditFacility/EditFacility";
import FacilityManagement from "../pages/FacilityManagement/FacilityManagement";
import ManageFacilities from "../pages/FacilityManagement/ManageFacilities/ManageFacilities";

export const groupName = "facility-management";

export const FacilityManagementRoutes = (
  <>
    <Route path={groupName} element={<FacilityManagement />} />
    <Route path={`${groupName}/edit-facility/:id`} element={<EditFacility />} />
    <Route path={`${groupName}/add-facility`} element={<AddFacility />} />
    <Route path={`${groupName}/manage-facilities`} element={<ManageFacilities />} />
  </>
);

export default FacilityManagementRoutes;
