import { APIFilter } from "src/models/responses/APIFilter";
import api from "../api";

interface TableParams {
  page: number;
  rowsPerPage: number;
  sortBy: string;
  sortDirection: "asc" | "desc";
  bucket: APIFilter | "";
  searchValue?: string;
}

type GetDelinquentOccupantsRequest = {
  id: number;
  params: TableParams;
}

export const delinquentOccupantsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDelinquentOccupants: builder.query<any, GetDelinquentOccupantsRequest>({
      queryFn: ({ id, params }, { getState }, _, baseQuery) => {
        const { searchValue, ...restParams } = params;
        const paramsObj = {
          ...(searchValue ? { filter: searchValue } : {}),
          ...restParams
        };
        const result = baseQuery({
          url: `/api/v1/facilities/${id}/collections`,
          method: "GET",
          params: paramsObj
        });

        return result;
      },
      providesTags: ["DelinquentOccupants"]
    })
  })
});

export const { useGetDelinquentOccupantsQuery, useLazyGetDelinquentOccupantsQuery } = delinquentOccupantsApi;
