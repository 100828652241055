
import { makeStyles } from "../../../../../makeStyles";

const useStyles = makeStyles({ label: "Edit Deal" })((theme) => ({
  footerButtons: {
    justifyContent: "flex-end"
  },
  saveButton: {
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText,
    width: "11rem"
  }
}));

export default useStyles;
