import { FormProvider, UseFormProps, useForm } from "react-hook-form";

import FormAutoComplete from "./FormAutocomplete";
import FormCancel from "./FormCancel";
import FormCheckbox from "./FormCheckbox";
import FormCurrency from "./FormCurrency";
import FormDatePicker from "./FormDatePicker";
import FormPhone from "./FormPhone";
import FormRadioGroup from "./FormRadioGroup";
import FormSelect from "./FormSelect";
import FormSubmit from "./FormSubmit";
import FormSwitch from "./FormSwitch";
import FormText from "./FormText";
import FormTimePicker from "./FormTimePicker";
import React from "react";
import { ZodType } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

type FormProps<T> = {
  schema?: ZodType;
  children: any;
  defaultValues?: Record<string, any>;
  onSubmit: (data: T, event?: React.FormEvent) => void;
  formId?: string;
  resetOnSubmit?: boolean;
  style?: {};
};

const Form = <T extends unknown>({
  schema,
  onSubmit,
  children,
  defaultValues,
  formId = "formId",
  resetOnSubmit,
  style = {}
}: FormProps<T>) => {
  const options = {
    values: defaultValues
  } as UseFormProps;

  if (schema) {
    options.resolver = zodResolver(schema);
  }

  const methods = useForm(options) as any;

  const { handleSubmit, trigger, reset } = methods;

  return (
    <FormProvider {...methods}>
      <form
        id={formId}
        onSubmit={async(e) => {
          e.preventDefault();
          const isValid = schema && (await trigger());
          await handleSubmit(onSubmit)(e);
          if (isValid) {
            resetOnSubmit && reset();
          }
        }}
        style={style}
      >
        {children}
      </form>
    </FormProvider>
  );
};

Form.Phone = FormPhone;
Form.Submit = FormSubmit;
Form.Text = FormText;
Form.Autocomplete = FormAutoComplete;
Form.Select = FormSelect;
Form.DatePicker = FormDatePicker;
Form.Checkbox = FormCheckbox;
Form.RadioGroup = FormRadioGroup;
Form.Currency = FormCurrency;
Form.Cancel = FormCancel;
Form.Switch = FormSwitch;
Form.TimePicker = FormTimePicker;

export default Form;
