import {
  LONG_DAMAGE,
  NOT_APPLICABLE,
  POST_AUCTION,
  SHORT_DAMAGE
} from "src/utils/getUnitUnrentableReason/unrentableReasons";

export const unrentableReasons = [
  { value: 1, label: SHORT_DAMAGE },
  { value: 2, label: LONG_DAMAGE },
  { value: 3, label: POST_AUCTION },
  { value: 4, label: NOT_APPLICABLE }
];
