// Define a type for the reports

export interface Report {
  id: number;
  category: string;
  name: string;
  path: string;
  catalog: string;
  inputs: ("MultiSelect" | "SingleSelect" | "DateRange" | "Date" | "Hours")[];
};

//to add a new report:
// 1. add to this array with required inputs for parameters.
// 2. if there is a new parameter input not supported, it must be added to FacilityMultiSelectAndDate.tsx
//  in similar fashion to the existing ones as an example
// 3. support the formik input and validation.
// 4. ???
// 5. Profit! Validation and formik input should be working for the new report.

const isProduction = window.config.appEnvironment === "production";

// Production will utilize production-data collections
// All lowers will utilize UAT-data collections
function getAdjustedUrl(originalValue: string): string {
  const rootDirectory = isProduction ? "/production" : "/UAT";
  return `${rootDirectory}${originalValue}`;
}

const reports: Report[] = [
  {
    id: 1,
    category: "Accounting",
    name: "Payment Activity",
    path: getAdjustedUrl("/CannedReportsCatalogs/PaymentActivity.cls"),
    catalog: getAdjustedUrl("/CannedReportsCatalogs/CannedReportsMA.cat"),
    inputs: ["MultiSelect", "DateRange"]
  },
  {
    id: 2,
    category: "Operations",
    name: "Occupied Units",
    path: getAdjustedUrl("/CannedReportsCatalogs/OccupiedUnits.cls"),
    catalog: getAdjustedUrl("/CannedReportsCatalogs/CannedReportsMA.cat"),
    inputs: ["MultiSelect"]
  },
  {
    id: 3,
    category: "Operations",
    name: "Occupancy Stats",
    path: getAdjustedUrl("/CannedReportsCatalogs/OccupancyStats.cls"),
    catalog: getAdjustedUrl("/CannedReportsCatalogs/CannedReportsMA.cat"),
    inputs: ["MultiSelect"]
  },
  {
    id: 4,
    category: "Accounting",
    name: "Rent Roll",
    path: getAdjustedUrl("/CannedReportsCatalogs/RentRoll.cls"),
    catalog: getAdjustedUrl("/CannedReportsCatalogs/CannedReportsMA.cat"),
    inputs: ["MultiSelect"]
  },
  {
    id: 5,
    category: "Operations",
    name: "Walk Thru",
    path: getAdjustedUrl("/CannedReportsCatalogs/WalkThru.cls"),
    catalog: getAdjustedUrl("/CannedReportsCatalogs/CannedReportsMA.cat"),
    inputs: ["MultiSelect"]
  },
  {
    id: 6,
    category: "Operations",
    name: "Vacant Units",
    path: getAdjustedUrl("/CannedReportsCatalogs/VacantUnits.cls"),
    catalog: getAdjustedUrl("/CannedReportsCatalogs/CannedReportsMA.cat"),
    inputs: ["MultiSelect"]
  },
  {
    id: 7,
    category: "Operations",
    name: "Overlocked Units",
    path: getAdjustedUrl("/CannedReportsCatalogs/Overlocked.cls"),
    catalog: getAdjustedUrl("/CannedReportsCatalogs/CannedReportsMA.cat"),
    inputs: ["MultiSelect"]
  },
  {
    id: 8,
    category: "Operations",
    name: "Remove Overlock",
    path: getAdjustedUrl("/CannedReportsCatalogs/RemoveOverlocks.cls"),
    catalog: getAdjustedUrl("/CannedReportsCatalogs/CannedReportsMA.cat"),
    inputs: ["MultiSelect", "Hours"]
  },
  {
    id: 9,
    category: "Accounting",
    name: "Price List",
    path: getAdjustedUrl("/CannedReportsCatalogs/PriceList.cls"),
    catalog: getAdjustedUrl("/CannedReportsCatalogs/CannedReportsMA.cat"),
    inputs: ["MultiSelect"]
  },
  {
    id: 10,
    category: "Operations",
    name: "Unit Status",
    path: getAdjustedUrl("/CannedReportsCatalogs/UnitStatus.cls"),
    catalog: getAdjustedUrl("/CannedReportsCatalogs/CannedReportsMA.cat"),
    inputs: ["MultiSelect"]
  },
  {
    id: 11,
    category: "Operations",
    name: "Move Ins/Move Outs",
    path: getAdjustedUrl("/CannedReportsCatalogs/MoveInsAndMoveOuts.cls"),
    catalog: getAdjustedUrl("/CannedReportsCatalogs/CannedReportsMA.cat"),
    inputs: ["MultiSelect", "DateRange"]
  },
  {
    id: 12,
    category: "Accounting",
    name: "Credits",
    path: getAdjustedUrl("/CannedReportsCatalogs/Credits_Report.cls"),
    catalog: getAdjustedUrl("/CannedReportsCatalogs/CannedReportsMA.cat"),
    inputs: ["MultiSelect", "DateRange"]
  },
  {
    id: 13,
    category: "Operations",
    name: "Unrentable Units",
    path: getAdjustedUrl("/CannedReportsCatalogs/UnrentableUnits.cls"),
    catalog: getAdjustedUrl("/CannedReportsCatalogs/CannedReportsMA.cat"),
    inputs: ["MultiSelect", "Date"]
  },
  {
    id: 14,
    category: "Accounting",
    name: "Payment Activity (Accounting)",
    path: getAdjustedUrl("/CannedReportsCatalogs/PaymentActivityAcct.cls"),
    catalog: getAdjustedUrl("/CannedReportsCatalogs/CannedReportsMA.cat"),
    inputs: ["MultiSelect", "DateRange"]
  },
  {
    id: 14,
    category: "Executive",
    name: "Management Summary",
    path: getAdjustedUrl("/CannedReportsCatalogs/ManagementSummary.cls"),
    catalog: getAdjustedUrl("/CannedReportsCatalogs/CannedReportsMA.cat"),
    inputs: ["MultiSelect", "Date"]
  },
  {
    id: 15,
    category: "Operations",
    name: "Daily Lock Check",
    path: getAdjustedUrl("/CannedReportsCatalogs/DailyLockCheck.cls"),
    catalog: getAdjustedUrl("/CannedReportsCatalogs/CannedReportsMA.cat"),
    inputs: ["SingleSelect"]
  },
  {
    id: 16,
    category: "Operations",
    name: "Occupant TPP Roll",
    path: getAdjustedUrl("/CannedReportsCatalogs/InsuredOccupantsRoll.cls"),
    catalog: getAdjustedUrl("/CannedReportsCatalogs/CannedReportsMA.cat"),
    inputs: ["MultiSelect", "Date"]
  },
  {
    id: 17,
    category: "Operations",
    name: "Rental Activity",
    path: getAdjustedUrl("/CannedReportsCatalogs/RentalActivity.cls"),
    catalog: getAdjustedUrl("/CannedReportsCatalogs/CannedReportsMA.cat"),
    inputs: ["MultiSelect", "Date"]
  },
  {
    id: 18,
    category: "Operations",
    name: "Deal Notifications Summary",
    path: getAdjustedUrl("/CannedReportsCatalogs/DealNotificationsSummary.cls"),
    catalog: getAdjustedUrl("/CannedReportsCatalogs/CannedReportsMA.cat"),
    inputs: ["MultiSelect", "DateRange"]
  },
  {
    id: 19,
    category: "Operations",
    name: "Deal Notifications Details",
    path: getAdjustedUrl("/CannedReportsCatalogs/DealNotificationsDetails.cls"),
    catalog: getAdjustedUrl("/CannedReportsCatalogs/CannedReportsMA.cat"),
    inputs: ["MultiSelect", "DateRange"]
  },
  {
    id: 20,
    category: "Operations",
    name: "Merchandise Summary",
    path: getAdjustedUrl("/PMS/MerchandiseSummary.cls"),
    catalog: getAdjustedUrl("/PMS/PMSReports.cat"),
    inputs: ["MultiSelect"]
  },
  {
    id: 21,
    category: "Operations",
    name: "TPP Policies and Claims Summary",
    path: getAdjustedUrl("/CannedReportsCatalogs/TPPPoliciesAndClaimsSummary.cls"),
    catalog: getAdjustedUrl("/CannedReportsCatalogs/CannedReportsMA.cat"),
    inputs: ["DateRange"]
  },
  {
    id: 22,
    category: "Operations",
    name: "Auction Eligible - Not Scheduled",
    path: getAdjustedUrl("/PMS/AuctionEligibleNotScheduled.cls"),
    catalog: getAdjustedUrl("/PMS/PMSReports.cat"),
    inputs: ["MultiSelect"]
  },
  {
    id: 23,
    category: "Operations",
    name: "Scheduled Auctions",
    path: getAdjustedUrl("/PMS/ScheduledAuctions.cls"),
    catalog: getAdjustedUrl("/PMS/PMSReports.cat"),
    inputs: ["MultiSelect", "Date"]
  },
  {
    id: 24,
    category: "Operations",
    name: "Merch Adjustments",
    path: getAdjustedUrl("/PMS/MerchandiseAdjustments.cls"),
    catalog: getAdjustedUrl("/PMS/PMSReports.cat"),
    inputs: ["MultiSelect", "DateRange"]
  },
  {
    id: 25,
    category: "Operations",
    name: "Autopay Summary",
    path: getAdjustedUrl("/CannedReportsCatalogs/AutopaySummary.cls"),
    catalog: getAdjustedUrl("/CannedReportsCatalogs/CannedReportsMA.cat"),
    inputs: ["MultiSelect", "DateRange"]
  },
  {
    id: 26,
    category: "Operations",
    name: "Autopay Details",
    path: getAdjustedUrl("/PMS/AutopayDetails.cls"),
    catalog: getAdjustedUrl("/PMS/PMSReports.cat"),
    inputs: ["SingleSelect", "DateRange"]
  },
  {
    id: 27,
    category: "Operations",
    name: "Scheduled Move Outs",
    path: getAdjustedUrl("/PMS/ScheduledMoveOuts.cls"),
    catalog: getAdjustedUrl("/PMS/PMSReports.cat"),
    inputs: ["MultiSelect", "Date"]
  },
  {
    id: 28,
    category: "Operations",
    name: "Deal Notes",
    path: getAdjustedUrl("/PMS/DealNotes.cls"),
    catalog: getAdjustedUrl("/PMS/PMSReports.cat"),
    inputs: ["MultiSelect", "DateRange"]
  },
  {
    id: 29,
    category: "Operations",
    name: "AR Notes",
    path: getAdjustedUrl("/PMS/ARNotes.cls"),
    catalog: getAdjustedUrl("/PMS/PMSReports.cat"),
    inputs: ["MultiSelect", "Date"]
  },
  {
    id: 30,
    category: "Operations",
    name: "Discounted Units",
    path: getAdjustedUrl("/PMS/DiscountedUnits.cls"),
    catalog: getAdjustedUrl("/PMS/PMSReports.cat"),
    inputs: ["MultiSelect"]
  },
  {
    id: 31,
    category: "Operations",
    name: "Unpaid Charges",
    path: getAdjustedUrl("/PMS/UnpaidCharges.cls"),
    catalog: getAdjustedUrl("/PMS/PMSReports.cat"),
    inputs: ["MultiSelect", "Date"]
  },
  {
    id: 32,
    category: "Operations",
    name: "Last Contact Notes",
    path: "/PMS/LastContactNotes.cls",
    catalog: "/PMS/PMSReports.cat",
    inputs: ["MultiSelect"]
  }
];

export default reports;
