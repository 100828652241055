import { AccountTypeEnum, AccountTypeStringEnum } from "../../../../enums/AccountType";

import { FormikValues } from "formik";
import { PaymentMethod } from "../../../../enums/PaymentMethod";
import { RootState } from "src/store/rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import creditCard from "credit-card-type";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

interface MakePaymentParams {
  values: FormikValues; // Specify a more detailed type if possible
  effectiveAmount: string|number; // Specify a more detailed type if possible
}

export const makeAPayment = createAsyncThunk(
  "makeAPayment", ({ values, effectiveAmount }: MakePaymentParams, { dispatch, getState, rejectWithValue }) => {
    const store = getState() as RootState;
    const paymentType = store.payment.paymentType;
    const cc = store.creditCard;
    const ach = store.ach;
    const accountType = store.ach.accountType;
    const ledgerPayments = store.makeAPayment.ledgerPayments;

    const paymentAmount = effectiveAmount;

    const facilityId = store.selectedFacility.selectedFacility.id;
    const prepaidPeriods = store.makeAPayment.periods || 0;

    const lp = ledgerPayments.map(ledgerPayment => {
      return {
        id: ledgerPayment.id,
        payment_amount: paymentAmount,
        ...(Number(prepaidPeriods) > 0 && { prepaid_periods: prepaidPeriods })
      };
    });

    const body: any = {
      ledgers: lp,
      payment_method: paymentType,
      billing: {}
    };

    if (paymentType === PaymentMethod.creditCard || paymentType === PaymentMethod.ach) {
      if (paymentType === PaymentMethod.creditCard) {
        const cardData = creditCard(cc.number);
        if (cardData.length) {
          body.billing.credit_card_type = cardData[0].type;
          body.billing.instrument_type = cardData[0].type;
        }

        body.billing.first_name = cc.name.split(" ")[0];
        body.billing.last_name = cc.name.split(" ")[1];
        body.billing.card_number = cc.number;
        body.billing.exp_month = cc.expiration.substring(0, 2);
        body.billing.exp_year = cc.expiration.substring(2, 4);
        body.billing.cvv = cc.cvv;
        body.billing.enable_autobill = cc.enableAutoPay;
        body.billing.use_saved_payment_method = values.usePaymentToken;
        body.billing.payment_instrument_id = parseInt(values.paymentInstrument);
      } else {
        body.billing.enable_autobill = ach.enableAutoPay;
        body.billing.first_name = ach.accountName.split(" ")[0];
        body.billing.last_name = ach.accountName.split(" ")[1];
        body.billing.bank_name = ach.bankName;
        body.billing.account_holder_name = ach.accountName;
        body.billing.account_number = ach.accountNumber;
        body.billing.routing_number = ach.routingNumber.toString();
        body.billing.instrument_type = (accountType === AccountTypeEnum.checking)
          ? AccountTypeStringEnum.CHECKING
          : AccountTypeStringEnum.SAVINGS;
        body.billing.check_number = values.checkNumber;
      }
    }

    if (paymentType === PaymentMethod.moneyOrder) {
      body.order_number = values.moneyOrderNumber;
    }

    let errorMessage = "";

    return axios.post(`/api/v1/facilities/${facilityId}/partial-payment`, body)
      .then((resp) => {
        dispatch(showSnackbar({
          message: `Payment successful`,
          variant: "success"
        }));
        return resp.data;
      })
      .catch((err) => {
        if (err.response.status === 400) {
          errorMessage = err.response.data.detail;
          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));
        }

        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);
          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));
        }

        return rejectWithValue(errorMessage);
      });
  }
);
