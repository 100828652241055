/* eslint-disable max-len */
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Tooltip, Typography } from "@mui/material";
import { MerchandiseForRefund } from "src/models/MerchandiseForRefund";
import QuantityIncrementor from "src/pages/Merchandise/RefundMerchandise/QuantityIncrementor/QuantityIncrementor";
import React from "react";
import SelectMerchandiseRefundReason from "../../SelectMerchandiseRefundReason/SelectMerchandiseRefundReason";

export const RefundMerchandiseTableColumns = (): GridColDef[] => [
  {
    field: "product_name",
    renderHeader: () => <Tooltip title={"Product Name"}><Typography>Product Name</Typography></Tooltip>,
    minWidth: 100,
    flex: 1,
    valueGetter: (params: { row: MerchandiseForRefund }) => params.row.product_name
  },
  {
    field: "price",
    renderHeader: () => <Tooltip title={"Price"}><Typography>Price</Typography></Tooltip>,
    minWidth: 100,
    valueGetter: (params: { row: MerchandiseForRefund }) => `$ ${params.row.price}`
  },
  {
    field: "refunded",
    renderHeader: () => <Tooltip title={"Refunded"}><Typography>Refunded</Typography></Tooltip>,
    minWidth: 100,
    valueGetter: (params: { row: MerchandiseForRefund }) => params.row.refunded_quantity ?? 0
  },
  {
    field: "ordered",
    renderHeader: () => <Tooltip title={"Quantity"}><Typography>Quantity</Typography></Tooltip>,
    minWidth: 100,
    valueGetter: (params: { row: MerchandiseForRefund }) => params.row.ordered
  },
  {
    field: "return",
    renderHeader: () => <Tooltip title={"Return"}><Typography>Return</Typography></Tooltip>,
    minWidth: 150,
    renderCell: ({ row, value }:GridRenderCellParams) => <QuantityIncrementor merchandiseId={row.id} maxValue={row.ordered - row.refunded_quantity}/>
  },
  {
    field: "reason",
    renderHeader: () => <Tooltip title={"Reason"}><Typography>Reason</Typography></Tooltip>,
    minWidth: 185,
    flex: 1,
    renderCell: ({ row, value }:GridRenderCellParams) => <SelectMerchandiseRefundReason merchandiseId={+row.id}/>
  }
];

export default RefundMerchandiseTableColumns;
