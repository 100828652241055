import React, { ReactElement } from "react";
import {
  selectProductTypes,
  selectProductTypesLoading
} from "src/store/reducers/productTypesSlice/productTypesSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import EmptyTable from "../../../../components/ui/PMSTable/EmptyTable/EmptyTable";
import ManageProductTypesTableColumns from "./ManageProductTypesTableColumns/ManageProductTypesTableColumns";
import PMSTable from "../../../../components/ui/PMSTable/PMSTable";
import { getAllPaginatedProductTypes } from "src/store/thunks/productType/getAllPaginated/getAllPaginatedProductTypes";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";

const ManageProductTypesTable: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const productTypes = useAppSelector(selectProductTypes);
  const productTypesLoading = useAppSelector(selectProductTypesLoading);
  const selectedFacility = useAppSelector(selectSelectedFacility);

  const handlePaginationUpdate = () => {
    dispatch(getAllPaginatedProductTypes(selectedFacility.id));
  };

  const emptyTable = () => (
    <EmptyTable
      collectionNotFound={"Product Types"}
      value={"Product Type"}
      addLink={"/product-types/add-product-type"}
    />
  );

  return (
    <PMSTable
      data-testid={"manage-product-types-table"}
      columns={ManageProductTypesTableColumns()}
      rows={productTypes}
      emptyTableDisplay={emptyTable}
      onPaginationUpdate={handlePaginationUpdate}
      loading={productTypesLoading}
    />
  );
};

export default ManageProductTypesTable;
