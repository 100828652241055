import { Grid, InputLabel, Typography } from "@mui/material";
import React, { FC, SyntheticEvent, useEffect } from "react";
import { selectDocumentList, selectDocumentLoading } from "src/store/reducers/documentSlice/documentSlice";
import {
  selectProductTypeLeaseDocument,
  setProductTypeLeaseDocument
} from "src/store/reducers/productTypeSliceNew/productTypeSliceNew";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import StepLayout from "src/layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "src/layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import assignLeaseDocumentValidation from "./assignLeaseDocumentValidation";
// eslint-disable-next-line max-len
import { getDocumentTemplateListForNewProductType } from "src/store/thunks/documentTemplates/getDocumentTemplateListForNewpProductType/getDocumentTemplateListForNewProductType";
import { inputError } from "src/utils/showInputError/showInputError";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { useFormik } from "formik";

const stepperId = "new-product-type-form";

interface AssignLeaseDocumentProps {
  handleClose: () => void
  setStep: React.Dispatch<React.SetStateAction<number>>
};

const AssignLeaseDocument: FC<AssignLeaseDocumentProps> = ({ setStep, handleClose }) => {
  const dispatch = useAppDispatch();

  const selectedFacility = useAppSelector(selectSelectedFacility);
  const docList = useAppSelector(selectDocumentList);
  const docListLoading = useAppSelector(selectDocumentLoading);
  const existingDoc = useAppSelector(selectProductTypeLeaseDocument);

  const formik = useFormik({
    initialValues: {
      lease_document: existingDoc?.id || ""
    },
    validationSchema: assignLeaseDocumentValidation,
    enableReinitialize: true,
    onSubmit: (values) => {
      const selectedDoc = docList.filter(doc => doc.id === +values.lease_document)[0];

      dispatch(setProductTypeLeaseDocument(selectedDoc));

      setStep((state) => state + 1);
    }
  });

  const handleGoBack = () => {
    setStep((state) => state - 1);
  };

  const { touched, values, errors, handleChange } = formik;

  useEffect(() => {
    // get your lease documents
    if (selectedFacility.id) {
      dispatch(getDocumentTemplateListForNewProductType(6));
    }
  }, []);

  return (
    <StepLayout
      padding={2}
      gridDirection={"row"}
      buttons={
        <StepperButtons
          showCancelButton
          onCancel={handleClose}
          stepperId={stepperId}
          forwardActionText={"Next"}
          backAction={handleGoBack}
        />
      }
    >
      <Grid
        component={"form"}
        container
        spacing={1}
        id={stepperId}
        onSubmit={(e: SyntheticEvent) => {
          e.preventDefault();
          e.stopPropagation();
          formik.handleSubmit();
        }}
      >
        <Grid item xs={12}>
          <Typography textAlign={"center"} variant={"h5"}>Assign Lease Document</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography textAlign={"center"} >
            Assign an available lease document to this product type.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor={"lease_document"}>
            Add Lease Document
          </InputLabel>
          <PMSSelect
            disabled={docListLoading}
            id={"lease_document"}
            name={"lease_document"}
            value={values.lease_document}
            changeHandler={handleChange}
            error={inputError("lease_document", touched, errors).error}
            helperText={inputError("lease_document", touched, errors).helperText}
          >
            <option value={""}>
              {" - Add Lease Document -"}
            </option>
            {docList.map(document =>
              <option key={document.id} value={document.id}>{document.name}</option>
            )}
          </PMSSelect>
        </Grid>
      </Grid>
    </StepLayout>
  );
};

export default AssignLeaseDocument;
