import { Box, Typography } from "@mui/material";
import { Deal } from "src/models/Deal";
import { ReactComponent as FabIcon } from "src/assets/icons/FAB.svg";
import React from "react";
import { SoftphoneDisplayMode } from "src/store/reducers/appSoftphoneSlice/appSoftphoneSliceState";
import formatPhone from "src/utils/formatPhone/formatPhone";
import { setIsDisplayed } from "src/store/reducers/appSoftphoneSlice/appSoftphoneSlice";
import { setSelectedDealNotification } from "src/store/reducers/dealNotificationSlice/dealNotificationSlice";
import { useAppDispatch } from "src/store/hooks";
import useStyles from "../FabPulsatingButton.styles";

const DealChips = ({ deals, pulse }: { deals: Deal[]; pulse: boolean }) => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles({ pulse });

  const dealLayout = ({ deal, index }: { deal: Deal; index: number }) => {
    return (
      <Box
        key={index}
        className={index === 0 ? classes.lead : classes.lead2}
        onClick={() => {
          dispatch(setSelectedDealNotification(deal));
          dispatch(setIsDisplayed(SoftphoneDisplayMode.DisplayFollowUp));
        }}
        >
        <FabIcon />
        <Typography className={classes.leadText}>
          New LEAD: {deal.first_name} {deal.last_name} {formatPhone(deal.phone)}
        </Typography>
      </Box>
    );
  };

  if (deals.length > 2) {
    const threeOldestDeals = deals.slice(0, 3);
    return (
      <>
        {threeOldestDeals.map((deal, index) => dealLayout({ deal, index }))}
        <Typography variant={"body2"} className={classes.plusDeals}>{`+${deals.length - 3}`}</Typography>
      </>
    );
  }

  return <>{deals?.map((deal: Deal, index: number) => dealLayout({ deal, index }))}</>;
};

export default DealChips;
