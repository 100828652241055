import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const exportFacilityUnitsCsv = createAsyncThunk(
  "exportFacilityUnitsCsv", async(facilityId: number, { dispatch, rejectWithValue }) => {
    return axios.get(`/api/v1/facilities/${facilityId}/units/export`, { responseType: "blob" })
      .then(response => {
        const blob = new Blob([response.data], { type: "text/csv" });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = downloadUrl;
        link.setAttribute("download", `facility_${facilityId}_units.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();

        return response.data;
      })
      .catch(() => {
        const errorMessage = `There was an error generating a CSV for Facility ${facilityId}`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
