import { LoadingButton } from "@mui/lab";
import { Button, Grid, Modal, Paper, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectApplyFee } from "src/store/reducers/applyFeeSlice/applyFeeSlice";
import { applyFees } from "src/store/thunks/applyFee/applyFees";
import { formattedAmount } from "src/utils/formattedAmount/formattedAmount";
import useStyles from "../ApplyFee.styles";
import { map } from "lodash";
import { setChangeOwnerShipStep } from "src/store/reducers/occupantSlice/occupantSlice";

interface ApplyFeeModalProps {
  onClose: () => void;
  open: boolean;
  occupantId: string;
  ledgerId: string;
  fees: number[];
}

const ApplyFeeModal = ({ open, onClose, occupantId, ledgerId, fees }: ApplyFeeModalProps) => {
  const { classes } = useStyles();
  const { applyFeeCost } = useAppSelector(selectApplyFee);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby={"Modal Component"}
      aria-describedby={"A Modal component."}
      className={classes.modal}
    >
      {/* this fragment here gets rid of a strange forwardRef console error locally. */}
      <>
        <Grid
          component={Paper}
          className={classes.container}
          container
          elevation={0}
          variant={"outlined"}
          spacing={2}
          pb={2}
        >
          <Typography variant={"h5"}>Confirm to Apply Fee</Typography>
          <Typography mt={2}>Are you sure you want to apply the following fee(s):</Typography>
          <Grid className={classes.feeSummary}>
            {applyFeeCost.fees?.map((fee) => (
              <Grid
                key={fee.id}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
                p={1}
              >
                <Typography>{fee.name}</Typography>
                <Typography>{formattedAmount("Fixed Amount", fee.amount)}</Typography>
              </Grid>
            ))}
          </Grid>
          <Grid className={classes.totalFee}>
            <Typography fontWeight={600}>Total Applied Fee(s):</Typography>
            <Typography fontWeight={600}>{formattedAmount("Fixed Amount", applyFeeCost.amount!)}</Typography>
          </Grid>
          <Typography marginX={"10%"} textAlign={"center"}>
            A total fee of {formattedAmount("Fixed Amount", applyFeeCost.amount!)} will be applied to the original
            balance.
          </Typography>
          <Grid pt={4} spacing={4} container
            justifyContent={"center"}>
            <Grid item>
              <Button
                data-testid={"cancel-button"}
                className={classes.cancelButton}
                onClick={() => onClose()}
                variant={"outlined"}
                color={"secondary"}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                data-testid={"confirm-button-modal"}
                color={"primary"}
                variant={"outlined"}
                className={classes.button}
                onClick={() => {
                  if (occupantId && ledgerId) {
                    setChangeOwnerShipStep(3);
                    dispatch(applyFees({ occupantId, ledgerId, fees })).then(() =>
                      navigate(`/occupants/${occupantId}/edit`, { state: { isFromApplyFee: true } })
                    );
                  }
                }}
              >
                Confirm
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </>
    </Modal>
  );
};

export default ApplyFeeModal;
