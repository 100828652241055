import api from "../api";

export interface TppPlanPayload {
  facility_id: number
  id?: number
  name: string
  premium_amount: string
  coverage_amount: string
  line_item_description: string
  revenue_class_id: number
  document_template_id: number | null
  is_active: boolean
  free_trial: number
  is_auto_enroll: boolean
}

export const insurancePlanApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createTppPlan: builder.mutation<void, TppPlanPayload>({
      query: (payload) => ({
        url: `/api/v1/facilities/${payload.facility_id}/tpp-plans`,
        method: "POST",
        body: payload
      }),
      invalidatesTags: ["TppPlans"]
    }),
    updateTppPlan: builder.mutation<void, TppPlanPayload>({
      query: (payload) => ({
        url: `/api/v1/facilities/${payload.facility_id}/tpp-plans/${payload.id!}`,
        method: "PUT",
        body: payload
      }),
      invalidatesTags: ["TppPlans"]
    })
  })
});

export const { useCreateTppPlanMutation, useUpdateTppPlanMutation } = insurancePlanApi;
