import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "Occupant Ledger Items Table" })((theme) => ({
  root: {
    border: 0,
    "& .MuiDataGrid-columnSeparator": {
      display: "none"
    },
    "& .MuiDataGrid-colCellTitle": {
      overflow: "unset"
    },
    "& .MuiDataGrid-virtualScroller": {
      overflowY: "auto"
    },
    marginTop: theme.spacing(1)
  }
}));

export default useStyles;
