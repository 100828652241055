import * as Yup from "yup";
import { dateTimeWarningShort } from "../../../../../utils/commonWarnings";
import validDateTime from "../../../../../utils/__dateAndTimeUtils__/validDateTime/validDateTime";
import isCurrentOrFutureDate
  from "../../../../../utils/__dateAndTimeUtils__/isCurrentOrFutureDate/isCurrentOrFutureDate";
import moment from "moment";

const validationSchema = Yup.object().shape({
  referralType: Yup.string().nullable(),
  dealType: Yup.string().required("Deal type is required"),
  neededOn: Yup.date()
    .nullable()
    .required("Rental Date is required")
    .test({
      name: "is past date",
      test: function(value) {
        const date = moment(value);
        return date.isSameOrAfter(date.subtract(1, "month"), "day");
      }
    }),
  followup: Yup.date()
    .nullable()
    .required("Follow-Up Date is required")
    .test({
      name: "is past date",
      test: function(value) {
        const date = moment(value);
        return date.isSameOrAfter(date.subtract(1, "month"), "day");
      }
    }),
  expiresOn: Yup.date().nullable().when("dealType", {
    is: "reservation",
    then: Yup.date().nullable().required("Expiration Date is required").test({
      name: "neededOn",
      message: "A valid date is required",
      test: (value) => isCurrentOrFutureDate(value)
    })
  }),
  appointmentFor: Yup.date().nullable().when("dealType", {
    is: "reservation",
    then: Yup.date().nullable().required("Appointment Date is required").test({
      name: "neededOn",
      message: "A valid date is required",
      test: (value) => isCurrentOrFutureDate(value)
    }).test(
      "appointmentFor",
      dateTimeWarningShort,
      (value) => validDateTime(value)
    )
  }),
  unit: Yup.mixed().required("Unit is required")
});

export default validationSchema;
