import { ChartOfAccountsSliceState } from "./ChartOfAccountsSliceState";
import { RootState } from "src/store/rootStore";
import { createChartOfAccountsSetting } from "src/store/thunks/chartOfAccounts/create/createChartOfAccountsSetting";
import { createSlice } from "@reduxjs/toolkit";
import { getChartOfAccountSettings } from "src/store/thunks/chartOfAccounts/get/getChartOfAccountsSettings";
import {
  getRevenueChartOfAccountSettings
} from "src/store/thunks/chartOfAccounts/get/getRevenueChartOfAccountsSettings";
import { searchChartOfAccountSettings } from "src/store/thunks/chartOfAccounts/search/searchChartOfAccountsSettings";

export const initialState: ChartOfAccountsSliceState = {
  settingsLoading: false,
  settings: [],
  settingsRevenue: [],
  settingsError: "",
  editModal: false,
  modalOpen: false,
  removeModalOpened: false,
  selectedSetting: undefined,
  searchValue: ""
};

export const chartOfAccounts = createSlice({
  name: "chartOfAccounts",
  initialState,
  reducers: {
    resetChartOfAccountsSettings: (state) => {
      Object.assign(state, initialState);
    },
    setSettingModalOpen: (state, action) => {
      state.modalOpen = action.payload;
    },
    setSelectedSetting: (state, action) => {
      state.selectedSetting = action.payload;
    },
    setEditModal: (state, action) => {
      state.editModal = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setRemoveModalOpened: (state, action) => {
      state.removeModalOpened = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChartOfAccountSettings.pending, (state) => {
        state.settingsLoading = true;
      })
      .addCase(getChartOfAccountSettings.fulfilled, (state, action) => {
        state.settingsLoading = false;
        state.settings = action.payload.data;
      })
      .addCase(getChartOfAccountSettings.rejected, (state, action) => {
        state.settingsLoading = false;
        state.settingsError = action.payload as string;
      })
      .addCase(getRevenueChartOfAccountSettings.pending, (state) => {
        state.settingsLoading = true;
      })
      .addCase(getRevenueChartOfAccountSettings.fulfilled, (state, action) => {
        state.settingsLoading = false;
        state.settingsRevenue = action.payload.data;
      })
      .addCase(getRevenueChartOfAccountSettings.rejected, (state, action) => {
        state.settingsLoading = false;
        state.settingsError = action.payload as string;
      })
      .addCase(createChartOfAccountsSetting.pending, (state) => {
        state.settingsLoading = true;
      })
      .addCase(createChartOfAccountsSetting.fulfilled, (state, action) => {
        state.settingsLoading = false;
      })
      .addCase(createChartOfAccountsSetting.rejected, (state, action) => {
        state.settingsLoading = false;
        state.settingsError = action.payload as string;
      })
      .addCase(searchChartOfAccountSettings.pending, (state) => {
        state.settingsLoading = true;
      })
      .addCase(searchChartOfAccountSettings.fulfilled, (state, action) => {
        state.settingsLoading = false;
        state.settings = action.payload.data;
      })
      .addCase(searchChartOfAccountSettings.rejected, (state, action) => {
        state.settingsLoading = false;
        state.settingsError = action.payload as string;
      });
  }
});
export const selectChartOfAccountsSettings = (state: RootState) => state.chartOfAccounts.settings;
export const selectChartOfAccountsSettingsRevenue = (state: RootState) => state.chartOfAccounts.settingsRevenue;
export const selectIsEditSettingModal = (state: RootState) => state.chartOfAccounts.editModal;
export const selectSettingsModalOpen = (state: RootState) => state.chartOfAccounts.modalOpen;
export const selectSelectedSetting = (state: RootState) => state.chartOfAccounts.selectedSetting;
export const selectChartOfAccountsSettingsLoading = (state: RootState) => state.chartOfAccounts.settingsLoading;
export const selectChartOfAccountsSearchValue = (state: RootState) => state.chartOfAccounts.searchValue;
export const selectRemoveModalOpened = (state: RootState) => state.chartOfAccounts.removeModalOpened;

export const {
  resetChartOfAccountsSettings,
  setEditModal,
  setSettingModalOpen,
  setSelectedSetting,
  setSearchValue,
  setRemoveModalOpened
} = chartOfAccounts.actions;

export default chartOfAccounts.reducer;
