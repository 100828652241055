import React, { useEffect } from "react";
import RptAPI, { rptSvrInfo, rptSvrInfoNoBar } from "src/utils/reportApiHelper/RptApi";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import FabPulsatingButton from "./FabPulsatingButton/FabPulsatingButton";
import { Grid } from "@mui/material";
import LogiReportStudio from "../Reporting/components/LogiReportStudio/LogiReportStudio";
import PageHeader from "../../components/ui/PageHeader/PageHeader";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { getAllFacilities } from "../../store/thunks/facility/getAll/getAllFacilities";
import moment from "moment";
import { selectFacilities } from "src/store/reducers/facilitiesSlice/facilitiesSlice";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { selectUserName } from "../../store/reducers/userInfoSlice/userInfoSlice";
import { getFacilityPermissions } from "src/store/thunks/permissions/getFacilityPermissions";

const Dashboard: React.FC = () => {
  const userName = useAppSelector(selectUserName);
  const dispatch = useAppDispatch();
  const facilitiesMaster = useAppSelector(selectFacilities);
  const selectedFacility = useAppSelector(selectSelectedFacility);

  const params = {
    operator_id: facilitiesMaster[0]?.operator_id,
    facility_id: [selectedFacility?.id],
    period: "Month-over-month",
    date: moment().format("MM/DD/YYYY")
  };

  const handleFaciltiyService = async() => {
    await dispatch(getAllFacilities());
    await dispatch(getFacilityPermissions());
  };

  useEffect(() => {
    const report = "/CannedReportsCatalogs/PMSDashboard.cls";
    const catalog = "/CannedReportsCatalogs/CannedReportsMA.cat";
    handleFaciltiyService();

    if (selectedFacility?.id && facilitiesMaster.length > 0) {
      RptAPI.runReport({ name: report }, { name: catalog }, params);
    }
  }, [selectedFacility]);

  const pageHeader = (
    <PageHeader
      title={"Your Property Management System Dashboard"}
      subtitle={`Welcome back, ${userName}! Here's your daily snapshot.`}
    />
  );

  return (
    <ViewWrapper pageHeader={pageHeader}>
      <FabPulsatingButton />
      <Grid container p={4} spacing={2}>
        <Grid minHeight={"45vh"} item xs={12}>
          <LogiReportStudio
            id={"lr-studio"}
            className={"lr-studio"}
            svrInfo={rptSvrInfoNoBar}
            params={params}
          />
        </Grid>
      </Grid>
    </ViewWrapper>
  );
};

export default Dashboard;
