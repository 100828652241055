import React, { ReactElement, useEffect } from "react";
import {
  resetMerchandiseCategoriesSlice,
  selectMerchandiseCategories,
  selectMerchandiseCategoriesLoading
} from "../../../store/reducers/merchandiseCategoriesSlice/merchandiseCategoriesSlice";
import {
  resetRevenueClassesSlice,
  selectRevenueClasses,
  selectRevenueClassesLoading
} from "../../../store/reducers/revenueClassesSlice/revenueClassesSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { AppDispatch } from "../../../store/rootStore";
import { Breadcrumb } from "../../../models/Breadcrumb";
import FooterButtons from "../../../components/itemManagement/ItemManagementCard/FooterButtons/FooterButtons";
import ItemManagementLayout from "../../../layouts/ItemManagement/ItemManagementLayout";
import MerchandiseForm from "../MerchandiseForm/MerchandiseForm";
import { MerchandiseValues } from "../../../models/formikInputValues/MerchandiseValues";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { createMerchandise } from "../../../store/thunks/merchandise/create/createMerchandise";
import { getAllMerchandiseCategories }
  from "../../../store/thunks/merchandiseCategory/getAll/getAllMerchandiseCategories";
import { getAllRevenueClasses } from "../../../store/thunks/revenueClass/getAll/getAllRevenueClasses";
import { useNavigate } from "react-router";
import { resetMerchandiseItemsSlice } from "../../../store/reducers/merchandiseItemsSlice/merchandiseItemsSlice";
import { selectMerchandiseFormLoading } from "../../../store/reducers/merchandiseFormSlice/merchandiseFormSlice";
import { selectSelectedFacility } from "../../../store/reducers/selectedFacilitySlice/selectedFacilitySlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Merchandise"
  },
  {
    name: "Create Merchandise",
    bold: true
  }
];

export const handleReset = (dispatch: AppDispatch) => {
  dispatch(resetRevenueClassesSlice());
  dispatch(resetMerchandiseCategoriesSlice());
  dispatch(resetMerchandiseItemsSlice());
};

const CreateMerchandise: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const revenueClasses = useAppSelector(selectRevenueClasses);
  const merchandiseCategories = useAppSelector(selectMerchandiseCategories);
  const revenueClassesLoading = useAppSelector(selectRevenueClassesLoading);
  const merchandiseCategoriesLoading = useAppSelector(selectMerchandiseCategoriesLoading);
  const viewLoading = revenueClassesLoading || merchandiseCategoriesLoading;
  const merchandiseFormLoading = useAppSelector(selectMerchandiseFormLoading);

  const handleSubmit = (merchFormData: MerchandiseValues) => {
    dispatch(createMerchandise(merchFormData))
      .then((resp) => {
        if (!resp.type.includes("rejected")) {
          navigate("/merchandise/manage-merchandise");
        }
      });
  };

  useEffect(() => {
    dispatch(getAllRevenueClasses(selectedFacility?.id));
    dispatch(getAllMerchandiseCategories());
  }, []);

  useEffect(() => {
    /* istanbul ignore next */ // cannot test this w/ enzyme */
    return () => {
      handleReset(dispatch);
    };
  }, []);

  const pageHeader = <PageHeader title={"Create Merchandise"} breadcrumbs={breadcrumbs} />;

  return (
    <ViewWrapper pageHeader={pageHeader}>
      <ItemManagementLayout
        title={"New Merchandise"}
        loading={viewLoading}
        footerButtons={
          <FooterButtons
            formId={"merchandise-form"}
            cancelPath={"/merchandise/manage-merchandise"}
            submitLoading={merchandiseFormLoading}
            submitButtonText={"Create Merchandise"}
             />
           }
         >
        <MerchandiseForm
          merchandiseCategories={merchandiseCategories}
          revenueClasses={revenueClasses}
          submitHandler={handleSubmit}
        />
      </ItemManagementLayout>
    </ViewWrapper>
  );
};

export default CreateMerchandise;
