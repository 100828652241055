import { PaymentMethod } from "../../../../enums/PaymentMethod";
import { RootState } from "src/store/rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import creditCard from "credit-card-type";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const partialPaymentDeliquentOccupants = createAsyncThunk("partialPaymentDeliquentOccupants",
  ({
    selectedPaymentMethod,
    paymentAmount,
    values
  }: { selectedPaymentMethod: number, paymentAmount: string, values: any },
  { dispatch, getState, rejectWithValue }) => {
    const store = getState() as RootState;

    const facilityId = store.selectedFacility.selectedFacility.id;
    const selectedDelinquent = store.collections.selectedDelinquent;
    const cc = store.creditCard;

    let amount = selectedDelinquent?.balance;

    if (paymentAmount) {
      amount = paymentAmount;
    } else if (selectedPaymentMethod === PaymentMethod.cash) {
      amount = values.amountDue;
    }

    const body: any = {
      ledgers: [
        {
          id: selectedDelinquent?.ledger_id,
          payment_amount: amount
        }
      ],
      payment_method: selectedPaymentMethod
    };

    if (selectedPaymentMethod === PaymentMethod.creditCard || selectedPaymentMethod === PaymentMethod.ach) {
      body.billing = {
        first_name: "",
        last_name: "",
        enable_autobill: values.isAutoBilling
      };

      if (selectedPaymentMethod === PaymentMethod.creditCard) {
        body.billing.use_saved_payment_method = values.usePaymentToken;

        const cardData = creditCard(values.creditCardNumber);
        if (cardData.length) {
          body.billing.credit_card_type = cardData[0].type;
        }

        if (values.usePaymentToken) {
          body.billing.first_name = cc.name.split(" ")[0];
          body.billing.last_name = cc.name.split(" ")[1];
          body.billing.payment_instrument_id = parseInt(values.paymentInstrument);
        } else {
          body.billing.first_name = values.cardholderName.split(" ")[0];
          body.billing.last_name = values.cardholderName.split(" ")[1];
          body.billing.card_number = values.creditCardNumber;
          body.billing.exp_month = values.expDate.substring(0, 2);
          body.billing.exp_year = values.expDate.substring(2, 4);
          body.billing.cvv = values.cvv;
        }
      } else {
        body.billing.first_name = values.accountName.split(" ")[0];
        body.billing.last_name = values.accountName.split(" ")[1];
        body.billing.account_number = values.accountNumber;
        body.billing.routing_number = values.routingNumber.toString();
        body.billing.check_number = values.checkNumber;
      }
    }

    if (selectedPaymentMethod === PaymentMethod.moneyOrder) {
      body.order_number = values.moneyOrderNumber;
    }

    return axios.post(`/api/v1/facilities/${facilityId}/partial-payment`, body)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = `There was an error with the partial payment`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
