import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "PaymentModal" })((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  root: {
    display: "flex",
    margin: "auto",
    maxHeight: "100%",
    maxWidth: "55rem",
    borderRadius: "1rem",
    justifyContent: "space-between",
    overflowY: "auto"
  },
  confirmButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.common.white,
    fontWeight: "normal"
  },
  cancelButton: {
    color: theme.palette.error.contrastText,
    fontWeight: "normal"
  },
  headerCell: {
    color: theme.palette.common.black,
    background: theme.palette.background.default,
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    width: "100%"
  },
  remainingBalance: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    border: `1.5px solid ${theme.palette.error.main}`,
    borderRadius: "0.25rem",
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2)
  }
}));

export default useStyles;
