import { Grid, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";

import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import BatchUpdateUnitsModal from "./ManageUnitsTable/components/BatchUpdateUnitsModal/BatchUpdateUnitsModal";
import BatchUploadUnitsModal from "./ManageUnitsTable/components/BatchUploadUnitsModal/BatchUploadUnitsModal";
import { Breadcrumb } from "src/models/Breadcrumb";
import { FlatUnit } from "src/api/endpoints/units";
import ManageUnitsTable from "./ManageUnitsTable/ManageUnitsTable";
import NewUnitModal from "./ManageUnitsTable/components/NewUnitModal/NewUnitModal";
import NotesModal from "./ManageUnitsTable/components/NotesModal/NotesModal";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { getAllProductTypes } from "src/store/thunks/productType/getAll/getAllProductTypes";
import { getAllUnitAttributes } from "src/store/thunks/unitAttributes/getAll/getAllUnitAttributes";
import {
  handleUnitsSliceReset
} from "src/store/reducers/unitsSliceNew/unitsSliceNew";
import { resetTablesSlice } from "src/store/reducers/tablesSlice/tablesSlice";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Units"
  },
  {
    name: "Manage Units",
    bold: true
  }
];

export type FlatUnitChanges = {
  [key: number]: Partial<FlatUnit>;
};

const ManageUnits: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const pageHeader = <PageHeader title={"Manage Units"} breadcrumbs={breadcrumbs} />;
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    if (selectedFacility?.id) {
      dispatch(getAllProductTypes(selectedFacility?.id));
      dispatch(getAllUnitAttributes());
    }

    return () => {
      dispatch(handleUnitsSliceReset());
      dispatch(resetTablesSlice());
    };
  }, []);

  return (
    <ViewWrapper pageHeader={pageHeader}>
      <Grid
        mt={2}
        component={Paper}
        container
        variant={"outlined"}
        justifyContent={"space-between"}
        alignItems={"center"}
        p={2}
        >
        <Grid item xs={12} lg={6}
          mb={2}>
          <Typography
            pb={!isLargeScreen ? 1 : 0}
            noWrap
            variant={"h5"}
            component={"h3"}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            }}
            >
            All Units at {selectedFacility.name}
          </Typography>
        </Grid>
        <ManageUnitsTable/>
      </Grid>
      <NewUnitModal />
      <BatchUploadUnitsModal />
      <BatchUpdateUnitsModal />
      <NotesModal />
    </ViewWrapper>
  );
};

export default ManageUnits;
