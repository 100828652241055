import { CreateNotePayload } from "./CreateNotePayload";
import NoteType from "src/enums/NoteType";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const createNote = createAsyncThunk("createNote", (payload: { id: number; type: NoteType, description: string },
  { dispatch, getState, rejectWithValue }) => {
  let errorMessage;

  const fullPayload: CreateNotePayload = {
    notable_id: payload.id,
    notable_type: payload.type,
    description: payload.description
  };

  if (!payload.id) {
    errorMessage = "No ID provided for the Note";

    dispatch(showSnackbar({
      message: errorMessage,
      variant: "error"
    }));

    return rejectWithValue(errorMessage);
  }

  return axios.post("/api/v1/notes", fullPayload)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      let errorMessage: string;
      if (err.response.status === 422) {
        errorMessage = format422(err.response.data.errors);

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      }

      errorMessage = `There was an error creating the Note`;

      dispatch(showSnackbar({
        message: errorMessage,
        variant: "error"
      }));

      return rejectWithValue(errorMessage);
    });
}
);
