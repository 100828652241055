import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getPromotion = createAsyncThunk(
  "getPromotion",
  (promotionId: string | number, { dispatch, rejectWithValue }) => {
    if (!promotionId) {
      return dispatch(showSnackbar({
        message: "No Promotion ID",
        variant: "error"
      }));
    }

    const params = {
      params: {
        with: ["facilities:id", "promotionCriteria.productTypes:id"]
      }
    };

    return axios.get(`/api/v1/promotions/${promotionId}`, params)
      .then((resp) => resp.data)
      .catch((e) => {
        const errorMessage = "There was an error getting the Promotion information";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
