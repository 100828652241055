
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CollectionsSliceState } from "./CollectionsSliceState";
import DelinquentCollection from "src/models/DelinquentCollection";
import { RootState } from "../../rootStore";

export const initialState: CollectionsSliceState = {
  collectionsLoading: false,
  collections: [],
  collectionsError: "",
  bucketFilter: "",
  followUpModalOpen: false,
  selectedDelinquentOccupant: <DelinquentCollection>{},
  paymentModalOpen: false,
  selectedDelinquent: null,
  paymentCompleteModalOpen: false,
  isPartialPayment: false,
  paymentAmount: "",
  createAuctionsModalOpen: false,
  confirmCreateAuctionsModalOpen: false
};

export const collectionsSlice = createSlice({
  name: "collections",
  initialState,
  reducers: {
    setBucketFilter: (state, action) => {
      state.bucketFilter = action.payload;
      sessionStorage.setItem("bucketFilter", JSON.stringify(action.payload));
    },
    setDelinquentOccupant: (state, action) => {
      state.selectedDelinquentOccupant = action.payload;
    },
    setFollowUpModalOpen: (state, action) => {
      state.followUpModalOpen = action.payload;
    },
    setCreateAuctionsModalOpen: (state, action) => {
      state.createAuctionsModalOpen = action.payload;
    },
    setPaymentModalOpen: (state, action) => {
      state.paymentModalOpen = action.payload;
    },
    setSelectedDelinquent: (state, action) => {
      state.selectedDelinquent = action.payload;
    },
    setPaymentCompleteModalOpen: (state, action) => {
      state.paymentCompleteModalOpen = action.payload;
    },
    setIsPartialPayment: (state, action) => {
      state.isPartialPayment = action.payload;
    },
    setCollections: (state, action) => {
      state.collections = action.payload;
    },
    setPaymentAmount: (state, action) => {
      state.paymentAmount = action.payload;
    },
    setAuction: (state, action: PayloadAction<{ auctionTime: string; auctionDate: string }>) => {
      state.auction = action.payload;
    },
    setConfirmCreateAuctionsModalOpen: (state, action) => {
      state.confirmCreateAuctionsModalOpen = action.payload;
    }
  }
});

export const selectBucketFilter = (state: RootState) => state.collections.bucketFilter;
export const selectFollowUpModalOpen = (state: RootState) => state.collections.followUpModalOpen;
export const selectCreateAuctionsModalOpen = (state: RootState) => state.collections.createAuctionsModalOpen;
export const selectConfirmCreateAuctionsModalOpen = (state: RootState) =>
  state.collections.confirmCreateAuctionsModalOpen;
export const selectCollectionsLoading = (state: RootState) => state.collections.collectionsLoading;
export const selectDelinquentOccupants = (state: RootState) => state.collections.collections;
export const selectDelinquentOccupant = (state: RootState) => state.collections.selectedDelinquentOccupant;
export const selectPaymentModalOpen = (state: RootState) => state.collections.paymentModalOpen;
export const selectSelectedDelinquent = (state: RootState) => state.collections.selectedDelinquent;
export const selectPaymentCompleteModalOpen = (state: RootState) => state.collections.paymentCompleteModalOpen;
export const selectIsPartialPayment = (state: RootState) => state.collections.isPartialPayment;
export const selectPaymentAmount = (state: RootState) => state.collections.paymentAmount;
export const selectCollections = (state: RootState) => state.collections;
export const selectCollectionsArray = (state: RootState) => state.collections.collections;

export const {
  setBucketFilter,
  setFollowUpModalOpen,
  setPaymentModalOpen,
  setConfirmCreateAuctionsModalOpen,
  setSelectedDelinquent,
  setDelinquentOccupant,
  setCollections,
  setPaymentCompleteModalOpen,
  setCreateAuctionsModalOpen,
  setIsPartialPayment,
  setPaymentAmount,
  setAuction
} = collectionsSlice.actions;

export default collectionsSlice.reducer;
