import { InsertDriveFile, UploadFile } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Modal, Paper, Typography } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import React, { useRef, useState } from "react";
import useStyles from "./UploadDocumentModal.style";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import { useAppSelector } from "src/store/hooks";
import { selectUnitLedgers } from "src/store/reducers/ledgersSlice/ledgersSlice";
import { Ledger } from "src/models/Ledger";
import { selectUploadedDocumentsLoading } from "src/store/reducers/uploadedDocumentsSlice/uploadedDocumentsSlice";
import LoadingSpinner from "src/components/ui/LoadingSpinner/LoadingSpinner";

const UploadDocumentModal = ({ open, onClose, onSubmit }: { open: boolean; onClose: Function; onSubmit: Function }) => {
  const { classes } = useStyles();
  const fileInput = useRef(null);
  const [selectedUnit, setSelectedUnit] = useState(0);
  const [file, setFile] = useState<File | null>(null);
  const [dragActive, setDragActive] = useState(false);
  const unitLedgers = useAppSelector(selectUnitLedgers);
  const loading = useAppSelector(selectUploadedDocumentsLoading);

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const clearState = () => {
    setFile(null);
    setSelectedUnit(0);
    setDragActive(false);
  };

  const modalContent = () => {
    if (loading) {
      return <LoadingSpinner />;
    }

    return (
      <>
        <Grid
          className={classes.fileUpload}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <input
            type={"file"}
            accept={".pdf"}
            ref={fileInput}
            hidden
            onDrop={(e) => {
              if (e.dataTransfer.files) {
                setFile(e.dataTransfer.files[0]);
              }
            }}
            onChange={(e) => {
              if (e.target.files) {
                setFile(e?.target?.files[0]);
              }
            }}
          />

          {file && (
            <Box className={classes.file}>
              <IconButton onClick={() => setFile(null)} className={classes.fileRemoveIcon}>
                <GridCloseIcon />
              </IconButton>

              <InsertDriveFile className={classes.fileIcon} />
              <Typography mt={1} variant={"caption"} className={classes.fileName}
                noWrap>
                {file.name}
              </Typography>
            </Box>
          )}

          {!file && dragActive && <UploadFile />}
          {!file && !dragActive && (
            <>
              <Typography mb={2}>Drag and drop your file or</Typography>
              <Button
                onClick={(e) => fileInput.current && (fileInput.current as HTMLInputElement).click()}
                className={classes.button}
                variant={"contained"}
              >
                Upload
              </Button>
            </>
          )}
        </Grid>
        <Grid item container alignItems={"center"}
          mt={2}>
          <Grid item pr={1}>
            <Typography component={"label"} htmlFor={"facility-selector"}>
              Document for Unit:
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <PMSSelect
              id={"selectedUnit"}
              name={"selectedUnit"}
              value={selectedUnit}
              changeHandler={(event) => setSelectedUnit(event.target.value)}
              data-testid={"unit-selector"}
              native
            >
              <option value={0} disabled>
                {" "}
                - Select a Unit -{" "}
              </option>
              {unitLedgers.map((ledger: Ledger) => (
                <option key={ledger.unit_id} value={ledger.unit_id}>
                  Unit {ledger?.unit?.unit_number}
                </option>
              ))}
            </PMSSelect>
          </Grid>
        </Grid>

        <Grid mt={2} alignSelf={"flex-end"}>
          <Button
            className={classes.cancelButton}
            variant={"text"}
            onClick={() => {
              onClose();
              clearState();
            }}
          >
            Cancel
          </Button>
          <Button
            className={classes.button}
            variant={"contained"}
            disabled={!selectedUnit || !file}
            data-testid={"button-confirm-modal"}
            onClick={async() => {
              onSubmit({ unitId: selectedUnit, file: file }).then(() => clearState());
            }}
          >
            Save
          </Button>
        </Grid>
      </>
    );
  };

  return (
    <Modal open={open} onClose={() => onClose()} className={classes.modal}>
      <Grid className={classes.container} component={Paper} elevation={0}>
        <Grid className={classes.content}>{modalContent()}</Grid>
      </Grid>
    </Modal>
  );
};

export default UploadDocumentModal;
