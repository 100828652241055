import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { ACHSliceState } from "./ACHSliceState";
import { RootState } from "../../rootStore";

export const initialState: ACHSliceState = {
  accountName: "",
  accountNumber: "",
  accountNumberMasked: "",
  accountNumberConfirm: "",
  accountNumberConfirmMasked: "",
  bankName: "",
  checkNumber: "",
  routingNumber: "",
  accountType: "",
  isPreferredPayment: false,
  enableAutoPay: false
};

export const achSlice = createSlice({
  name: "ach",
  initialState,
  reducers: {
    setAccountName: (state, action: PayloadAction<string>) => {
      state.accountName = action.payload;
    },
    setAccountNumber: (state, action: PayloadAction<string>) => {
      state.accountNumber = action.payload;
    },
    setAccountNumberConfirm: (state, action: PayloadAction<string>) => {
      state.accountNumberConfirm = action.payload;
    },
    setAccountNumberMasked: (state, action: PayloadAction<string>) => {
      state.accountNumberMasked = action.payload;
    },
    setAccountNumberConfirmMasked: (state, action: PayloadAction<string>) => {
      state.accountNumberConfirmMasked = action.payload;
    },
    setBankName: (state, action: PayloadAction<string>) => {
      state.bankName = action.payload;
    },
    setCheckNumber: (state, action: PayloadAction<string>) => {
      state.checkNumber = action.payload;
    },
    setRoutingNumber: (state, action: PayloadAction<string>) => {
      state.routingNumber = action.payload;
    },
    setAccountType: (state, action: PayloadAction<string>) => {
      state.accountType = action.payload;
    },
    setIsPreferredPayment: (state, action: PayloadAction<boolean>) => {
      state.isPreferredPayment = action.payload;
    },
    setEnableAutoPay: (state, action: PayloadAction<boolean>) => {
      state.enableAutoPay = action.payload;
    },
    resetAchSlice: (state) => {
      Object.assign(state, initialState);
    }
  }
});

export const selectAccountName = (state: RootState) => state.ach.accountName;
export const selectAccountNumber = (state: RootState) => state.ach.accountNumber;
export const selectAccountNumberConfirm = (state: RootState) => state.ach.accountNumberConfirm;
export const selectAccountNumberMasked = (state: RootState) => state.ach.accountNumberMasked;
export const selectAccountNumberConfirmMasked = (state: RootState) => state.ach.accountNumberConfirmMasked;
export const selectBankName = (state: RootState) => state.ach.bankName;
export const selectCheckNumber = (state: RootState) => state.ach.checkNumber;
export const selectRoutingNumber = (state: RootState) => state.ach.routingNumber;
export const selectAccountType = (state: RootState) => state.ach.accountType;
export const selectIsPreferredPayment = (state: RootState) => state.ach.isPreferredPayment;
export const selectEnableAutoPay = (state: RootState) => state.ach.enableAutoPay;
export const selectAch = (state: RootState) => state.ach;

export const {
  setAccountName,
  setAccountNumber,
  setAccountNumberConfirm,
  setAccountNumberMasked,
  setAccountNumberConfirmMasked,
  setBankName,
  setCheckNumber,
  setRoutingNumber,
  setAccountType,
  setIsPreferredPayment,
  setEnableAutoPay,
  resetAchSlice
} = achSlice.actions;

export default achSlice.reducer;
