import React, { PropsWithChildren } from "react";
import { useAppSelector } from "src/store/hooks";
import { Breadcrumb } from "src/models/Breadcrumb";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import WebhookForm from "../WebhookForm/WebhookForm";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Automations"
  },
  {
    name: "Webhooks"
  },
  {
    name: "Edit Webhook",
    bold: true
  }
];

const EditWebhook: React.FC<PropsWithChildren> = () => {
  const pageHeader = <PageHeader title={"Edit Webhook"} breadcrumbs={breadcrumbs} />;
  const permissions = useAppSelector(selectFacilityPermissions);
  const automationPermissions = createPermissions(permissions, "automations");

  if (automationPermissions.update) {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <WebhookForm isEdit={true} />
      </ViewWrapper>
    );
  } else {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <h1>You do not have permission to view this page.</h1>
      </ViewWrapper>
    );
  }
};
export default EditWebhook;
