import { PaletteOptions, createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {

  interface Palette {
    active: string;
    hover: string;
  }
  interface PaletteOptions {
    active: string;
    hover: string;
  }

}

export const palette: PaletteOptions = {
  primary: {
    light: "#3d526f",
    main: "#354760",
    dark: "#233043",
    contrastText: "#1976D2" // <--- this is the button color.
  },
  secondary: {
    light: "#4b5d86",
    main: "#1A2233",
    contrastText: "#354760" // <--- this is the button color.
  },
  active: "#61778D",
  hover: "#90A3C2",
  success: {
    main: "#4FC43A",
    contrastText: "#4FC43A" // <--- this is the button color.
  },
  error: {
    main: "#FF5252",
    contrastText: "#FF5252" // <--- this is the button color.
  },
  warning: {
    main: "#FF9800",
    contrastText: "#FF9800" // <--- this is the button color.
  },
  background: {
    default: "#F6F8FC",
    paper: "#FFFFFF"
  },
  text: {
    primary: "#354760",
    disabled: "#a2a2a2"
  }
};

const theme = createTheme(({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          paddingBottom: "0.33rem"
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none"
          },
          "input[type=number]": {
            MozAppearance: "textfield"
          }
        }
      }
    }
  },
  palette
}));

export default theme;
