import { FacilitySetting } from "src/models/FacilitySetting";
import { FacilitySettingsUpdateValues } from "../FacilitySettings";
import GlobalSettingEnum from "src/enums/GlobalSetting";
import {
  UpdateFacilitySettingsPayload
} from "src/store/thunks/facilitySetting/batchUpdate/batchUpdateFacilitySettings";

const buildFacilitySettingsPayload = (
  receiptTemplateSettingId: number,
  invoiceTemplateSettingId: number,
  autoBillTemplateSettingId: number,
  values: FacilitySettingsUpdateValues,
  gateCodeSystemSettingId: number,
  reservationDaysLimitSettingId?: number,
  dealDaysLimitSettingId?: number,
  emergencyGateOverrideId?: number,
  moveInDaysLimitSettingId?: number
): UpdateFacilitySettingsPayload => {
  const settingsArray: Partial<FacilitySetting>[] = [
    {
      id: reservationDaysLimitSettingId,
      identifier: GlobalSettingEnum.RESERVATION_DAYS_LIMIT,
      value: values.reservationDateLimit ? String(values.reservationDateLimit) : null
    },
    {
      id: dealDaysLimitSettingId,
      identifier: GlobalSettingEnum.DEAL_DAYS_LIMIT,
      value: values.dealDate ? String(values.dealDate) : null
    },
    {
      id: receiptTemplateSettingId,
      value: values.facilityReceiptTemplate ? String(values.facilityReceiptTemplate) : null
    },
    {
      id: invoiceTemplateSettingId,
      value: values.facilityInvoiceTemplate ? String(values.facilityInvoiceTemplate) : null
    },
    {
      id: autoBillTemplateSettingId,
      value: values.facilityAutobillTemplate ? String(values.facilityAutobillTemplate) : null
    },
    {
      id: gateCodeSystemSettingId,
      identifier: GlobalSettingEnum.GATE_SYSTEM,
      value: values.gateSystem ? String(values.gateSystem) : null
    },
    {
      id: emergencyGateOverrideId,
      identifier: GlobalSettingEnum.EMERGENCY_GATE_OVERRIDE,
      value: values.gateSystem ? String(values.emergencyGateOverride) : null
    },
    {
      id: moveInDaysLimitSettingId,
      identifier: GlobalSettingEnum.MOVE_IN_DAYS_LIMIT,
      value: values.moveInDate ? String(values.moveInDate) : null
    }
  ];

  return { facility_settings: settingsArray };
};

export default buildFacilitySettingsPayload;
