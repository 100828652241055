import { Button, Grid, Modal, Paper } from "@mui/material";
import React, { FC, ReactNode } from "react";
import { LoadingButton } from "@mui/lab";
import { toggleModal } from "../../../store/reducers/modalSlice/modalSlice";
import { useAppDispatch } from "../../../store/hooks";
import useStyles from "./AppModal.styles";

interface AppModalProps {
  formId?: string
  open: boolean
  useActions?: boolean
  confirmAction?: Function
  confirmActionLoading?: boolean
  cancelAction?: Function
  children: ReactNode
  onCloseAction?: Function
  cancelButtonText?: string
  confirmButtonText?: string
}

const AppModal: FC<AppModalProps> = ({
  formId,
  open,
  children,
  useActions = true,
  confirmAction = () => {},
  confirmActionLoading,
  cancelAction,
  onCloseAction,
  cancelButtonText,
  confirmButtonText
}: AppModalProps) => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();

  const specifyCloseAction = () => {
    onCloseAction ? onCloseAction() : dispatch(toggleModal(!open));
  };

  return (
    <Modal
      data-testid={"ui-modal"}
      open={open}
      onClose={() => specifyCloseAction()}
      aria-labelledby={"Modal Component"}
      aria-describedby={"A Modal component."}
      className={classes.modal}
    >
      <Grid
        container
        component={Paper}
        p={4}
        direction={"column"}
        justifyContent={"space-between"}
      >
        {children}

        {useActions &&
          <Grid
            pt={2}
            spacing={4}
            container
            justifyContent={"center"}
          >
            <Grid item>
              <Button
                data-testid={"cancel-button"}
                className={classes.cancelButton}
                onClick={() => cancelAction
                  ? cancelAction(dispatch)
                  : dispatch(toggleModal(false))
                }
                variant={"outlined"}
                color={"secondary"}
              >
                { cancelButtonText ?? "Cancel" }
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                form={formId}
                type={formId ? "submit" : undefined}
                data-testid={"confirm-button"}
                color={"primary"}
                variant={"outlined"}
                className={classes.button}
                loading={confirmActionLoading}
                disabled={confirmActionLoading}
                onClick={() => confirmAction()}
              >
                { confirmButtonText ?? "Confirm" }
              </LoadingButton>
            </Grid>
          </Grid>}
      </Grid>
    </Modal>
  );
};

export default AppModal;
