import { Button, Collapse, Divider, Grid, InputLabel, TextField } from "@mui/material";
import React, { FC, FormEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Add } from "@mui/icons-material";
import AppNote from "src/components/note/Note";
import { LoadingButton } from "@mui/lab";
import NoteType from "src/enums/NoteType";
import contactNotesValidation from "./contactNotesValidation";
import { createNote } from "src/store/thunks/note/createNote/createNote";
import { getAllNotes } from "src/store/thunks/note/getAll/getAllNotes";
import { inputError } from "src/utils/showInputError/showInputError";
import { selectDelinquentOccupant } from "src/store/reducers/collectionsSlice/collectionsSlice";
import { selectNotes } from "src/store/reducers/notesSlice/notesSlice";
import { useFormik } from "formik";
import useStyles from "./ContactNotes.styles";

interface ContactNotesProps {}

const ContactNotes: FC<ContactNotesProps> = () => {
  const [isAddNewNoteOpen, setIsAddNewNoteOpen] = useState(false);
  const notes = useAppSelector(selectNotes);
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const delinquentOccupant = useAppSelector(selectDelinquentOccupant);

  const formik = useFormik({
    initialValues: {
      description: ""
    },
    validationSchema: contactNotesValidation,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      dispatch(
        createNote({
          id: delinquentOccupant.ledger_id,
          type: NoteType.ledger,
          description: values.description
        })
      );

      formik.setFieldValue("description", "");
      setIsAddNewNoteOpen(false);
    }
  });

  const { touched, errors, values, handleChange, setFieldValue } = formik;

  const handleCancel = () => {
    setIsAddNewNoteOpen(false);
    setFieldValue("description", "");
  };

  const getOccupantNotes = () => {
    dispatch(
      getAllNotes({
        itemId: delinquentOccupant.ledger_id,
        noteType: NoteType.ledgers
      })
    );
  };

  useEffect(() => {
    getOccupantNotes();
  }, []);

  return (
    <Grid
      component={"form"}
      id={"occupant-notes"}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        formik.handleSubmit();
      }}
    >
      <Grid container mb={1}>
        <Grid item xs={12} pr={1}
          container alignItems={"center"} justifyContent={"flex-end"}>
          <Button
            className={classes.button}
            variant={"contained"}
            startIcon={<Add />}
            onClick={() => setIsAddNewNoteOpen(true)}
          >
            Add Note
          </Button>
        </Grid>
      </Grid>
      <Collapse in={isAddNewNoteOpen}>
        <Grid container item>
          <Grid item xs={12} mt={1}>
            <InputLabel htmlFor={"note-description"}>New note description</InputLabel>
            <TextField
              id={"note-description"}
              multiline
              rows={4}
              fullWidth
              name={"description"}
              onChange={handleChange}
              inputProps={{
                maxLength: 500
              }}
              error={inputError("description", touched, errors).error}
              helperText={inputError("description", touched, errors).helperText}
              value={values.description}
            />
          </Grid>
          <Grid item container xs={12}
            mt={2} justifyContent={"flex-end"}>
            <Button color={"error"} variant={"text"} onClick={() => handleCancel()}
              sx={{ marginRight: 5 }}>
              Cancel
            </Button>
            <LoadingButton form={"occupant-notes"} variant={"contained"} type={"submit"}
              className={classes.button}>
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </Collapse>

      <Grid container rowGap={2} mt={2}>
        {notes?.map((note, index) => (
          <AppNote key={index} {...note} />
        ))}
      </Grid>
    </Grid>
  );
};

export default ContactNotes;
