import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .when("picked", {
      is: "new",
      then: () => Yup.string()
        .required("A valid E-mail is required")
        .email("E-mail must be valid")
        .test(value => {
          if (!value) {
            return false;
          }
          return true;
        })
    })
});

export default validationSchema;
