import { makeStyles } from "../../makeStyles";

const useStyles = makeStyles<{ showDrawer: boolean }>({ label: "AppLayout" })((theme, { showDrawer }) => ({
  mainView: {
    width: "0rem",
    flexGrow: 1
  },
  modalTitle: {
    textTransform: "capitalize"
  }
  // navDrawer: {
  //   transition: theme.transitions.create("all", {
  //     easing: theme.transitions.easing.easeOut,
  //     duration: theme.transitions.duration.short
  //   }),
  //   [theme.breakpoints.up("xl")]: {
  //     width: showDrawer ? "20rem" : "6rem"
  //   },
  //   [theme.breakpoints.down("xl")]: {
  //     width: showDrawer ? "18rem" : "6rem"
  //   },
  //   [theme.breakpoints.down("lg")]: {
  //     width: showDrawer ? "10rem" : "6rem"
  //   }
  // }
}));

export default useStyles;
