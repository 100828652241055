import { identityInstance as axios } from "../../../../api/identityInstance/identityInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getUserInfo = createAsyncThunk(
  "getUserInfo",
  (_, { dispatch, rejectWithValue }) => {
    return axios.get("/api/v1/users/me")
      .then(resp => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = "There was an error getting the current user information";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
