import React, { ReactElement, ReactNode } from "react";
import { Grid } from "@mui/material";
import MoveSummary from "../../../pages/Deals/CreateMoveIn/MoveSummary/MoveSummary";
import { useLocation } from "react-router";
import useStyles from "./ReviewLayout.styles";
import { useAppSelector } from "src/store/hooks";
import { selectIsTransfer } from "src/store/reducers/transferUnitSlice/transferUnitSlice";
import TransferSummary from "src/pages/Occupants/TransferUnit/TransferSummary/TransferSummary";

interface ReviewLayoutProps {
  children: ReactNode
  header?: ReactNode | null
  actionButtons?: ReactNode | null
}

const ReviewLayout: React.FC<ReviewLayoutProps> = ({ actionButtons, children, header }): ReactElement => {
  const { classes } = useStyles();
  const location = useLocation();
  const isTransfer = useAppSelector(selectIsTransfer);

  return (
    <>
      {header && <Grid mt={3} mb={2} item>{header}</Grid>}
      <Grid container spacing={3}>
        <Grid
          item
          container
          direction={"column"}
          xs={9}
        >
          {children}
        </Grid>
        <Grid item xs={3}>
          <Grid
            className={classes.sideColumn}
            direction={"column"}
            container
          >
            {isTransfer}
            {!isTransfer
              ? <MoveSummary isMoveOut={location?.pathname.includes("/create-move-out")}/>
              : <TransferSummary/>}
            {actionButtons && actionButtons}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ReviewLayout;
