import { Button, Grid, Modal, Paper, Typography } from "@mui/material";
import { Close, Info } from "@mui/icons-material";
import React, { ReactElement } from "react";
import useStyles from "./AlertModal.styles";

interface AlertModalProps {
  open: boolean;
  title: string;
  buttonLabel: string;
  onButtonClick: Function;
  onCloseClick: Function;
  children: ReactElement;
  showButtons?: boolean;
}

const AlertModal: React.FC<AlertModalProps> = ({
  open,
  title,
  buttonLabel,
  onButtonClick,
  onCloseClick,
  children,
  showButtons = true
}) => {
  const { classes } = useStyles();

  return (
    <Modal open={open} onClose={() => onCloseClick()}
      className={classes.modal}>
      <Grid className={classes.container} component={Paper} elevation={0}>
        <Grid className={classes.header} pt={5} px={5}>
          <Typography variant={"h4"} className={classes.title}>
            {title}
          </Typography>
        </Grid>

        <Grid className={classes.content}>
          {children}
          {showButtons &&
            <Grid mt={2}>
              <Button
                className={classes.cancelButton}
                variant={"outlined"}
                onClick={() => onCloseClick()}
              >
                Cancel
              </Button>
              <Button
                className={classes.button}
                variant={"outlined"}
                data-testid={"button-confirm-modal"}
                onClick={() => onButtonClick()}
              >
                {buttonLabel}
              </Button>
            </Grid>
          }
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AlertModal;
