import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  buyerName: Yup.string()
    .max(256)
    .label("Buyer Name")
    .when("moveOutViaAuction", { is: true, then: Yup.string().required() })
});

export default validationSchema;
