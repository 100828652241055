/* eslint-disable max-len */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GetMerchandiseCostForRefundPayload } from "src/store/thunks/merchandise/getMerchandiseCostForRefund/getMetchandiseCostForRefundPayload";
import { MerchandiseForRefundSliceState } from "./MerchandiseForRefundSliceState";
import { RootState } from "../../rootStore";
import { getMerchandiseCostForRefund } from "../../thunks/merchandise/getMerchandiseCostForRefund/getMerchandiseCostForRefund";
import { getMerchandiseForRefund } from "src/store/thunks/transactions/getMerchandiseForRefund/getMerchandiseForRefund";
import { processRefundMerchandise } from "src/store/thunks/merchandise/processRefundMerchandise/processRefundMerchandise";
import { Transaction } from "src/models/Transaction";

export const initialState: MerchandiseForRefundSliceState = {
  merchandiseForRefund: [],
  merchandiseForRefundLoading: false,
  merchandiseForRefundError: "",
  merchandiseCostLoading: false,
  summaryLineItems: [],
  subTotal: "0.00",
  tax: "0.00",
  finalBalance: "0.00",
  refundMethod: 0,
  transactionId: 0,
  refundedTransactionId: 0
};

export const merchandiseForRefundSlice = createSlice({
  name: "merchandiseForRefund",
  initialState,
  reducers: {
    resetMerchandiseForRefundSlice: (state) => {
      Object.assign(state, initialState);
    },
    setMerchandiseQuantityToRefund: (state: MerchandiseForRefundSliceState, action) => {
      state.merchandiseForRefund = action.payload;
    },
    setUpdateMerchandiseForRefundQuantity: (state: MerchandiseForRefundSliceState, action: PayloadAction<{id:number, quantity:number}>) => {
      const collection = state.merchandiseForRefund;
      const merchIndex = collection.findIndex((merch) => merch.id === action.payload.id);
      state.merchandiseForRefund[merchIndex].return_quantity = action.payload.quantity;

      if (action.payload.quantity === 0) {
        state.merchandiseForRefund[merchIndex].reason = "";
      }
    },
    setUpdateMerchandiseForRefundReason: (state: MerchandiseForRefundSliceState, action: PayloadAction<{id:number, reason:string}>) => {
      const collection = state.merchandiseForRefund;
      const merchIndex = collection.findIndex((merch) => merch.id === action.payload.id);
      state.merchandiseForRefund[merchIndex].reason = action.payload.reason;
    },
    setRefundMethodSelected: (state: MerchandiseForRefundSliceState, action) => {
      state.refundMethod = action.payload;
    },
    setTransactionIdForMerchandiseRefund: (state: MerchandiseForRefundSliceState, action) => {
      state.transactionId = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMerchandiseForRefund.pending, (state) => {
        state.merchandiseForRefundLoading = true;
      })
      .addCase(getMerchandiseForRefund.fulfilled, (state, action) => {
        state.merchandiseForRefundLoading = false;
        state.merchandiseForRefund = action.payload;
      })
      .addCase(getMerchandiseForRefund.rejected, (state, action) => {
        state.merchandiseForRefundLoading = false;
        state.merchandiseForRefundError = action.payload as string;
      })
      .addCase(getMerchandiseCostForRefund.pending, (state) => {
        state.merchandiseCostLoading = true;
      })
      .addCase(getMerchandiseCostForRefund.fulfilled, (state, action: PayloadAction<GetMerchandiseCostForRefundPayload>) => {
        state.summaryLineItems = action.payload.merchandiseItems || [];
        state.finalBalance = action.payload.final_balance || "0.00";
        state.tax = action.payload.tax || "0.00";
        state.subTotal = action.payload.sub_total || "0.00";
        state.merchandiseCostLoading = false;
      })
      .addCase(getMerchandiseCostForRefund.rejected, (state) => {
        state.merchandiseCostLoading = false;
      })
      .addCase(processRefundMerchandise.pending, (state) => {
        state.merchandiseForRefundLoading = true;
      })
      .addCase(processRefundMerchandise.fulfilled, (state, action) => {
        state.merchandiseForRefundLoading = false;
        state.refundedTransactionId = action.payload.transaction.id;
      })
      .addCase(processRefundMerchandise.rejected, (state, action) => {
        state.merchandiseForRefundLoading = false;
      });
  }
});

export const selectMerchForRefundItems = (state: RootState) => state.merchandiseForRefunds;
export const selectSummaryLineItemsForRefund = (state: RootState) => state.merchandiseForRefunds.summaryLineItems;
export const selectMerchandiseForRefund = (state: RootState) => state.merchandiseForRefunds.merchandiseForRefund;
export const selectRefundedTransactionId = (state: RootState) => state.merchandiseForRefunds.refundedTransactionId;
export const selectMerchandiseForRefundLoading = (state: RootState) => state.merchandiseForRefunds.merchandiseForRefundLoading;
export const selectReturnMerchandiseValidation = (state: RootState) => {
  if (!state.merchandiseForRefunds.merchandiseForRefund.length) {
    return false;
  }

  if (state.merchandiseForRefunds.refundMethod === 0) {
    return false;
  }

  const merchandiseList = state.merchandiseForRefunds.merchandiseForRefund.filter(merch => merch.return_quantity > 0);

  if (!merchandiseList.length) {
    return false;
  }

  const validationItems = merchandiseList.map(item => {
    if (item.return_quantity > 0 && item.reason) {
      return true;
    }

    return false;
  });

  return validationItems.every(item => {
    return item === true;
  });
};

export const {
  resetMerchandiseForRefundSlice,
  setMerchandiseQuantityToRefund,
  setUpdateMerchandiseForRefundQuantity,
  setUpdateMerchandiseForRefundReason,
  setRefundMethodSelected,
  setTransactionIdForMerchandiseRefund
} = merchandiseForRefundSlice.actions;

export default merchandiseForRefundSlice.reducer;
