import { FormControlLabel, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";
import { Close } from "@mui/icons-material";
import { FormikValues } from "formik";
import PMSCheckbox from "src/components/ui/PMSCheckbox/PMSCheckbox";
import clsx from "clsx";
import { inputError } from "src/utils/showInputError/showInputError";
import useStyles from "./ItemSelector.styles";

interface ItemSelectorProps<T> {
  item: T;
  isChecked: boolean;
  itemLabel: string;
  itemPrice: string;
  changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  removeItem: (id: number) => void;
  incrementor?: ReactNode;
  handleWaiveFeeOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
  formik?: FormikValues;
}

interface GenericInterface {
  name: string;
  id: number;
  quantity_for_cart?: number;
}

const ItemSelector = <T extends GenericInterface>({
  item,
  isChecked,
  itemLabel,
  itemPrice,
  changeHandler,
  removeItem,
  incrementor,
  handleWaiveFeeOnChange,
  isDisabled = false,
  formik
}: ItemSelectorProps<T>): ReactElement => {
  const { classes } = useStyles();

  const renderCheckbox = (item: GenericInterface) => {
    return (
      <PMSCheckbox
        name={`${item.name}`}
        value={item.id}
        changeHandler={changeHandler}
        isChecked={isChecked}
        disabled={isDisabled}
      />
    );
  };

  return (
    <Grid container>
      <Grid
        py={0.5}
        key={item.id}
        container
        className={clsx(classes.checkboxRowWrapper, isChecked ? classes.checked : null)}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Tooltip title={itemLabel}>
          <Grid
            item
            ml={1}
            xs={incrementor ? 6 : 8}
            component={FormControlLabel}
            control={renderCheckbox(item)}
            label={itemLabel}
            componentsProps={{ typography: { noWrap: true, style: { whiteSpace: "pre", textOverflow: "ellipsis" } } }}
          />
        </Tooltip>
        {incrementor && (
          <Grid item container xs={incrementor ? 3 : 0.5}
            textAlign={"right"} py={"0.2rem"}>
            {isChecked && incrementor}
          </Grid>
        )}

        {item.id !== -1 && (
          <Grid item xs={incrementor ? 1.5 : 2}>
            <Typography textAlign={"right"}>{itemPrice}</Typography>
          </Grid>
        )}
        <Grid item xs={incrementor ? 0.65 : 1.25} mr={0.5}>
          {isChecked && (
            <IconButton data-testid={"close-button"} onClick={() => removeItem(item.id)} disabled={isDisabled}>
              <Close className={classes.closeIcon} />
            </IconButton>
          )}
        </Grid>
      </Grid>

      {item.id === -1 && isChecked && (
        <Grid item xs={4} justifyContent={"flex-end"}
          marginLeft={"auto"}>
          <TextField
            id={"waiveFeeAmount"}
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position={"start"}>$</InputAdornment>
            }}
            name={"waiveFeeAmount"}
            onChange={handleWaiveFeeOnChange}
            value={formik?.values.waiveFeeAmount}
            error={inputError("waiveFeeAmount", formik?.touched, formik?.errors).error}
            helperText={inputError("waiveFeeAmount", formik?.touched, formik?.errors).helperText}
            inputProps={{ "aria-label": "waiveFeeAmount" }}
            sx={{ textAlign: "right" }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ItemSelector;
