import {
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import {
  resetExternalTppPlanState,
  selectExternalTppPlanActive,
  selectInsurnaceChangeModalOpen,
  selectSelectedExternalTppPlan,
  setInsuranceChangeModalOpen
} from "src/store/reducers/externalTppPlanSlice/externalTppPlanSlice";
import {
  selectSelectedInsurancePlan,
  setSelectedInsurancePlan
} from "src/store/reducers/insurancePlansSlice/insurancePlansSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { changeCurrentTppPlan } from "src/store/thunks/ledger/updateTpp/changeCurrentTppPlan";
import { createExternalTppPlan } from "src/store/thunks/externalTppPlan/create/createExternalTppPlan";
import { disableLedgerInsurancePlan } from "src/store/thunks/insurancePlans/disable/disableLedgerInsurancePlan";
import { getOccupant } from "src/store/thunks/occupant/getOne/getOccupant";
import { removeExternalTppPlan } from "src/store/thunks/externalTppPlan/remove/removeExternalTppPlan";
import { selectSelectedLedger } from "src/store/reducers/ledgersSlice/ledgersSlice";
import { updateExternalTppPlan } from "src/store/thunks/externalTppPlan/update/updateExternalTppPlan";
import { useParams } from "react-router";
import useStyles from "./ConfirmInsuranceChangeModal.styles";

interface ConfirmInsuranceChangeModalProps { }

const ConfirmInsuranceChangeModal: FC<ConfirmInsuranceChangeModalProps> = () => {
  const { classes } = useStyles();
  const insuranceChangeModalOpen = useAppSelector(selectInsurnaceChangeModalOpen);
  const dispatch = useAppDispatch();
  const [isExternal, setIsExternal] = useState(false);
  const externalTppPlan = useAppSelector(selectSelectedExternalTppPlan);
  const params = useParams<{ id: string }>();
  const ledger = useAppSelector(selectSelectedLedger);
  const selectedInternalTppPlan = useAppSelector(selectSelectedInsurancePlan);
  const hasExternalTppPlan = useAppSelector(selectExternalTppPlanActive);

  const handleClose = () => {
    dispatch(setInsuranceChangeModalOpen(false));
    dispatch(setSelectedInsurancePlan(null));
    dispatch(resetExternalTppPlanState());
    dispatch(getOccupant(String(ledger?.occupant_id)));
  };

  useEffect(() => {
    if (hasExternalTppPlan) {
      setIsExternal(true);
    }
    return () => setIsExternal(false);
  }, [hasExternalTppPlan]);

  const handleSave = () => {
    //if you just want to change internal plans
    if (selectedInternalTppPlan?.id && ledger?.id) {
      //if the external one is present, soft delete it.
      if (ledger?.has_external_tpp && externalTppPlan?.id) {
        dispatch(removeExternalTppPlan({
          occupant_id: String(params.id),
          external_tpp_plan_id: String(externalTppPlan?.id)
        }));
      }
      return dispatch(changeCurrentTppPlan({
        occupant_id: String(ledger?.occupant_id),
        ledger_id: String(ledger?.id),
        payload: { tpp_plan_id: String(selectedInternalTppPlan.id) }
      }))
        .then((resp) => {
          if (!resp.type.includes("rejected")) {
            return handleClose();
          }
        });
    }

    //if you have an external plan loaded and you want to update it.
    if (hasExternalTppPlan && externalTppPlan && ledger?.has_external_tpp) {
      return dispatch(updateExternalTppPlan({
        occupant_id: String(ledger?.occupant_id),
        external_tpp_plan_id: String(externalTppPlan?.id),
        payload: externalTppPlan
      })).then(resp => {
        if (!resp.type.includes("rejected")) {
          return handleClose();
        }
      });
    }

    // if you're just creating an external plan
    if (ledger?.id && hasExternalTppPlan && !ledger?.has_external_tpp && !!externalTppPlan) {
      return dispatch(disableLedgerInsurancePlan({
        occupant_id: String(ledger.occupant_id),
        ledger_id: String(ledger?.id)
      }))
        .then((resp) => {
          dispatch(createExternalTppPlan({
            occupant_id: String(ledger?.occupant_id),
            payload: externalTppPlan,
            ledger_id: String(ledger?.id)
          })).then((resp) => {
            if (!resp.type.includes("rejected")) {
              dispatch(getOccupant(String(params.id)));
              dispatch(setInsuranceChangeModalOpen(false));
              dispatch(resetExternalTppPlanState());
            }
          });
        });
    }
  };

  return (
    <Modal
      onClose={handleClose}
      open={insuranceChangeModalOpen}
      className={classes.modal}
    >
      <Grid
        p={4}
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        justifyContent={"center"}
        className={classes.container}
      >
        <Grid item xs={11}>
          <Typography whiteSpace={"pre"} noWrap variant={"h5"} >
            Confirm to {isExternal ? "Add External Insurance" : `Select ${selectedInternalTppPlan?.name}`}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container my={4} spacing={2}>
          <Grid item xs={12}>
            <Typography textAlign={"center"} variant={"body1"}>
              This selection will add
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight={900} textAlign={"center"} variant={"body1"}>
              {isExternal ? "N/A" : `$ ${selectedInternalTppPlan?.premium_amount}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign={"center"} variant={"body1"}>
              to your next invoice.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign={"center"} variant={"body1"}>
              {isExternal
                ? `Are you sure you want to remove your internal TPP plan and add an external insurance?`
                : `Are you sure you want to add ${selectedInternalTppPlan?.name}?`}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          mt={2}
          justifyContent={"center"}
        >
          <Button
            onClick={handleClose}
            color={"error"}
            variant={"text"}
            sx={{ marginRight: 5 }}
          >
            Cancel
          </Button>
          <LoadingButton
            form={"external-tpp-form"}
            onClick={handleSave}
            variant={"outlined"}
            className={classes.button}
          >
            Confirm
          </LoadingButton>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ConfirmInsuranceChangeModal;
