import { Button, Grid, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { AddCircle } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import useStyles from "./NoItemsDisplay.styles";

interface NoItemsDisplayProps {
  item: string
  link: string
  message?: string | null
}

const NoItemsDisplay: React.FC<NoItemsDisplayProps> = ({ item, link, message }: NoItemsDisplayProps): ReactElement => {
  const { classes } = useStyles();

  return (
    <Grid
      item
      container
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      className={classes.displayContainer}
    >
      <Typography variant={"h6"} component={"span"}>
        {message ?? `No ${item}s found.`}
      </Typography>
      <Button
        className={classes.newItemButton}
        color={"primary"}
        variant={"outlined"}
        startIcon={<AddCircle />}
        size={"large"}
        component={NavLink}
        to={link}
      >
        New {item}
      </Button>
    </Grid>
  );
};

export default NoItemsDisplay;
