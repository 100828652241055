import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { Pageview, RemoveCircle } from "@mui/icons-material";
import { setFacility, updateFacilityId } from "src/store/reducers/facilitySlice/facilitySlice";
import { Facility } from "../../../../../models/Facility";
import React from "react";
import { useNavigate } from "react-router";
import { toggleFacilityDisableModal } from "src/store/reducers/modalSlice/modalSlice";
import { useAppSelector } from "src/store/hooks";
import {
  createPermissions,
  hasPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

export const RenderDetailsButton = (facility: Facility, dispatch: Function) => {
  const permissions = useAppSelector(selectFacilityPermissions);
  const facilityPermissions = createPermissions(permissions, "facility");

  const navigate = useNavigate();
  function selectFacility(id: number) {
    return () => {
      updateFacilityId(id);
      navigate(`/facility-management/edit-facility/${id}`);
    };
  }

  function openDisableModal(facility: Facility) {
    dispatch(setFacility(facility));
    dispatch(toggleFacilityDisableModal(true));
  }

  return (
    <Grid container direction={"row"} alignItems={"center"}
      justifyContent={"start"}>
      {facilityPermissions.update
        ? (<Tooltip title={"Edit Facility"}>
          <IconButton
            size={"small"}
            style={{ color: "grey", marginRight: ".5rem" }}
            onClick={selectFacility(facility.id as number)}
          >
            <Pageview />
          </IconButton>
        </Tooltip>)
        : null }
      {facilityPermissions.update
        ? (<Tooltip title={"Disable Facility"}>
          <IconButton
            size={"small"}
            style={{ color: "grey" }}
            data-testid={"disable-facility-button"}
            onClick={() => openDisableModal(facility)}
          >
            <RemoveCircle />
          </IconButton>
        </Tooltip>)
        : null }
    </Grid>
  );
};

const manageFacilitiesTableColumns = (dispatch: Function, actionPermissions: Boolean): GridColDef[] => [
  {
    field: "facility_id",
    renderHeader: () => <Tooltip title={"ID"}><Typography>ID</Typography></Tooltip>,
    minWidth: 75,
    flex: 1
  },
  {
    field: "name",
    renderHeader: () => <Tooltip title={"Marketing Name"}><Typography>Market. Name</Typography></Tooltip>,
    minWidth: 220,
    flex: 1
  },
  {
    field: "legal_name",
    renderHeader: () => <Tooltip title={"Legal Name"}><Typography>Legal Name</Typography></Tooltip>,
    minWidth: 260,
    flex: 1
  },
  {
    field: "address",
    renderHeader: () => <Tooltip title={"Address"}><Typography>Address</Typography></Tooltip>,
    minWidth: 200,
    flex: 1
  },
  {
    field: "city",
    renderHeader: () => <Tooltip title={"City"}><Typography>City</Typography></Tooltip>,
    minWidth: 120,
    flex: 1
  },
  {
    field: "region",
    renderHeader: () => <Tooltip title={"State"}><Typography>State</Typography></Tooltip>,
    minWidth: 75,
    flex: 1
  },
  {
    field: "postal_code",
    renderHeader: () => <Tooltip title={"Zip"}><Typography>Zip</Typography></Tooltip>,
    minWidth: 75,
    flex: 1
  },
  {
    field: "phone",
    renderHeader: () => <Tooltip title={"Phone"}><Typography>Phone</Typography></Tooltip>,
    minWidth: 125,
    flex: 1
  },
  // { field: "district", headerName: "District", width: 95 },
  {
    field: "tax_id",
    renderHeader: () => <Tooltip title={"Tax ID"}><Typography>Tax ID</Typography></Tooltip>,
    minWidth: 115,
    flex: 1
  },
  {
    field: "timezone",
    renderHeader: () => <Tooltip title={"Timezone"}><Typography>Timezone</Typography></Tooltip>,
    minWidth: 180,
    flex: 1
  },
  actionPermissions
    ? {
        field: "actionIcons",
        renderHeader: () => <Tooltip title={"Actions"}><Typography>Actions</Typography></Tooltip>,
        sortable: false,
        minWidth: 120,
        flex: 1,
        renderCell: ({ row }: GridRenderCellParams) => RenderDetailsButton(row, dispatch)
      }
    : null
].filter(Boolean) as GridColDef[];

export default manageFacilitiesTableColumns;
