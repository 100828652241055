import { MerchandiseValues } from "../../../../models/formikInputValues/MerchandiseValues";
import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const updateMerchandise = createAsyncThunk(
  "updateMerchandise",
  (payload: MerchandiseValues, { dispatch, getState, rejectWithValue }) => {
    const store = getState() as RootState;
    const facilityId = store.selectedFacility.selectedFacility.id;

    // // clean up the payload object of unnecessary fields
    // delete payload.quantity_change_reason_required;
    // if (!payload.quantity_change_reason || payload.quantity_change_reason === "") {
    //   delete payload.quantity_change_reason;
    // }

    return axios.put(`/api/v1/facilities/${facilityId}/merchandise/${payload.id}`, payload)
      .then(() => {
        dispatch(showSnackbar({
          message: "Merchandise updated successfully",
          variant: "success"
        }));
      })
      .catch((err) => {
        let errorMessage: string = "There was an error updating the Merchandise";

        if (err && err.response && err.response.status && err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);
        }

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
