import { makeStyles } from "../../../../../../../makeStyles";

const useStyles = makeStyles({ label: "StatusColumn" })((theme) => ({
  pastDue: {
    border: `1.5px solid ${theme.palette.error.main}`,
    borderRadius: "0.25rem",
    color: theme.palette.error.main,
    fontStyle: "italic"
  }
}));

export default useStyles;
