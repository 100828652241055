
const generateChartOfAccountsCategory = (input: number) => {
  switch (input) {
    case 1:
      return "Asset";
    case 2:
      return "Liabilities";
    case 3:
      return "Revenue";
    case 4:
      return "Expense";
    case 5:
      return "Cost of Goods Sold";
    default:
      return "N/A";
  }
};

export default generateChartOfAccountsCategory;
