import { CircularProgress, Grid, Paper } from "@mui/material";
import React, { ReactElement, useEffect } from "react";
import {
  addTransferUnitFee,
  addTransferUnitInsurancePlan,
  addWaivefee,
  removeTransferUnitFee,
  selectMonthlyRate,
  selectPromoDefermentStatus,
  selectTransferLeaseStartsOn,
  selectTransferPeriods,
  selectTransferUnitInsurancePlan,
  selectWaiveFee,
  setTransferLeaseStartsOn,
  setTransferWaiveFeeAmount
} from "src/store/reducers/transferUnitSlice/transferUnitSlice";
import { selectActiveTransferFees, selectFeesLoading } from "../../../../store/reducers/feesSlice/feesSlice";
import { selectFees, selectRent, selectTax } from "src/store/reducers/transferUnitCostSlice/transferUnitCostSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import DealDetails from "../../../../layouts/stepper/ReviewLayout/DealDetails/DealDetails";
import InsurancePlansList from "./InsurancePlansList/InsurancePlansList";
import MerchandiseList from "./MerchandiseList/MerchandiseList";
import RateAndAccessDetails from "./RateAndAccessDetails/RateAndAccessDetails";
import ReviewActionButtons from "../../../../layouts/stepper/ReviewLayout/ReviewActionButtons/ReviewActionButtons";
import ReviewHeader from "../../../../layouts/stepper/ReviewLayout/ReviewHeader/ReviewHeader";
import ReviewLayout from "../../../../layouts/stepper/ReviewLayout/ReviewLayout";
import { moneyValidator } from "src/utils/commonRegexes";
import rateAndAccessDetailsValidation from "./RateAndAccessDetails/rateAndAccessDetailsValidation";
import { selectDealLoading } from "../../../../store/reducers/dealSlice/dealSlice";
import { selectInsurancePlansLoading } from "../../../../store/reducers/insurancePlansSlice/insurancePlansSlice";
import { selectMerchandiseItemsLoading } from "../../../../store/reducers/merchandiseItemsSlice/merchandiseItemsSlice";
import { selectPromotionsForFacility } from "../../../../store/reducers/promotionsSlice/promotionsSlice";
import { selectTargetPath, setTargetPath } from "../../../../store/reducers/navigationSlice/navigationSlice";
import { useFormik } from "formik";
import { getTransferUnitCost } from "src/store/thunks/transfer/transferCost/getTransferUnitCost";
import { today } from "src/utils/__dateAndTimeUtils__/today";
import { useNavigate } from "react-router";
import useStyles from "./ProcessTransferUnit.styles";
import FeesList from "src/pages/Deals/FeesList/FeesList";
import { TRANSFER_UNIT_PATHS } from "src/routes/TransferUnitRoutes.";
import AddExternalTPPModal from "src/pages/Deals/AddExternalTPPModal/AddExternalTPPModal";
import {
  selectExternalTppPlanActive,
  setExternalTppPlanActive,
  setSelectedExternalTppPlan
} from "src/store/reducers/externalTppPlanSlice/externalTppPlanSlice";
import { getLedgerInsurancePlan } from "src/store/thunks/insurancePlans/get/getLedgerInsurancePlan";
import { selectSelectedLedgerToTransfer } from "src/store/reducers/ledgersSlice/ledgersSlice";
import { selectSelectedOccupant } from "src/store/reducers/selectedOccupantSlice/selectedOccupantSlice";

export const goBackText = (redirect: string) => {
  if (redirect.includes(TRANSFER_UNIT_PATHS.selectPromotionForTransferUnit)) {
    return "Select Promotion";
  }

  if (redirect.includes(TRANSFER_UNIT_PATHS.processTransferUnit)) {
    return "Select Promotion";
  }

  return "Select Unit";
};

const ProcessTransferUnit: React.FC = (): ReactElement => {
  const redirect = useAppSelector(selectTargetPath);
  const facilityPromotions = useAppSelector(selectPromotionsForFacility);
  const dealLoading = useAppSelector(selectDealLoading);
  const merchandiseLoading = useAppSelector(selectMerchandiseItemsLoading);
  const insurancePlansLoading = useAppSelector(selectInsurancePlansLoading);
  const feesLoading = useAppSelector(selectFeesLoading);
  const viewLoading = dealLoading || merchandiseLoading || insurancePlansLoading || feesLoading;
  const leaseStartsOn = useAppSelector(selectTransferLeaseStartsOn);
  const monthlyRate = useAppSelector(selectMonthlyRate);
  const periods = useAppSelector(selectTransferPeriods);
  const promoDefermentStatus = useAppSelector(selectPromoDefermentStatus);
  const transferCostFees = useAppSelector(selectFees);
  const fees = useAppSelector(selectActiveTransferFees);
  const dispatch = useAppDispatch();
  const waiveMoveInFee = useAppSelector(selectWaiveFee);
  const rent = useAppSelector(selectRent);
  const tax = useAppSelector(selectTax);
  const navigate = useNavigate();
  const { classes } = useStyles();
  const transferInsurancePlan = useAppSelector(selectTransferUnitInsurancePlan);
  const hasExternalTpp = useAppSelector(selectExternalTppPlanActive);
  const ledgerId = useAppSelector(selectSelectedLedgerToTransfer);
  const occupant = useAppSelector(selectSelectedOccupant);

  const handleGoBack = () => {
    if (redirect.includes(TRANSFER_UNIT_PATHS.processTransferUnit)) {
      return navigate(TRANSFER_UNIT_PATHS.selectPromotionForTransferUnit);
    }

    if (redirect) {
      return navigate(redirect);
    }

    if (facilityPromotions.length) {
      return navigate(TRANSFER_UNIT_PATHS.selectPromotionForTransferUnit);
    }

    navigate(TRANSFER_UNIT_PATHS.selectUnitForTransferUnit);
  };

  const onSubmit = () => {
    dispatch(setTargetPath(TRANSFER_UNIT_PATHS.processTransferUnit));
    navigate(TRANSFER_UNIT_PATHS.reviewTransferUnit);
  };

  const handleCancel = () => {
    navigate(TRANSFER_UNIT_PATHS.getTransferUnitStarted);
  };

  const formik = useFormik({
    initialValues: {
      leaseStartsOn: leaseStartsOn,
      monthlyRate: monthlyRate,
      periods: periods,
      promoDefermentStatus: promoDefermentStatus,
      waiveFee: waiveMoveInFee.waive,
      waiveFeeAmount: waiveMoveInFee.waiveFeeAmount,
      rent,
      tax
    },
    validationSchema: rateAndAccessDetailsValidation,
    validateOnBlur: true,
    /* istanbul ignore next */
    onSubmit
  });

  const updateSummary = () => {
    dispatch(getTransferUnitCost());
  };

  const addRequiredFees = async() => {
    await transferCostFees.forEach(async(fee) => {
      if (fee.is_required) {
        await dispatch(addTransferUnitFee(fee.id));
      }
    });

    await updateSummary();
  };

  const { handleSubmit } = formik;

  const isChecked = (id: number) => {
    return !!transferCostFees?.find((item) => item.id === id);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const feeId = +event.target.value;

    if (feeId === -1) {
      if (waiveMoveInFee.waive) {
        dispatch(addWaivefee({ waive: false, waiveFeeAmount: "0.00" }));
        formik.setFieldValue("waiveFee", false);
        return;
      }
      dispatch(addWaivefee({ waive: true, waiveFeeAmount: "0.00" }));
      formik.setFieldValue("waiveFee", true);
      return;
    }

    if (isChecked(feeId)) {
      dispatch(removeTransferUnitFee(feeId));
    } else {
      dispatch(addTransferUnitFee(feeId));
    }

    updateSummary();
  };

  const removeFee = (feeId: number) => {
    if (feeId === -1) {
      dispatch(addWaivefee({ waive: false, waiveFeeAmount: "0.00" }));
      formik.setFieldValue("waiveFeeAmount", "0.00");
      updateSummary();
      return;
    }
    dispatch(removeTransferUnitFee(feeId));
    updateSummary();
  };

  const handleWaiveFeeOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("waiveFeeAmount", event.target.value);
    dispatch(setTransferWaiveFeeAmount(event.target.value));

    if (moneyValidator.test(event.target.value)) {
      updateSummary();
    }
  };

  // this useEffect is only to add rent and tax to the formik state
  useEffect(() => {
    formik.setFieldValue("rent", rent);
    formik.setFieldValue("tax", tax);
  }, [rent, tax]);

  useEffect(() => {
    dispatch(setTransferLeaseStartsOn(today));
  }, []);

  useEffect(() => {
    const ledger = occupant?.ledgers?.find((ledger) => ledger.id === ledgerId);

    dispatch(getLedgerInsurancePlan({ ledgerId: String(ledgerId), occupantId: String(occupant?.id) })).then(
      ({ payload: data }) => {
        if (ledger?.external_tpp_plan_id) {
          dispatch(setSelectedExternalTppPlan(ledger?.external_tpp_plan));
          dispatch(setExternalTppPlanActive(true));
          return;
        }
        if (data) {
          dispatch(addTransferUnitInsurancePlan(data.id));
        }
      }
    );
  }, []);

  const shouldBeDisabled = () => (transferInsurancePlan ? false : !hasExternalTpp);

  useEffect(() => {
    addRequiredFees();
  }, []);

  return (
    <ReviewLayout
      header={
        <ReviewHeader
          headerText={"Process Transfer"}
          data-testid={"process-transfer-unit-header"}
          backMethod={handleGoBack}
          goBackText={goBackText(redirect)}
        />
      }
      actionButtons={
        <ReviewActionButtons
          formId={""}
          disabled={shouldBeDisabled()}
          ctaText={"Review Transfer"}
          handlerMethod={handleSubmit}
          cancelMethod={handleCancel}
        />
      }
    >
      <Paper elevation={1} className={classes.fullWidth}>
        {viewLoading
          ? (
            <Grid container justifyContent={"center"} py={8}>
              <CircularProgress size={60} />
            </Grid>
            )
          : (
            <>
              <DealDetails />
              <RateAndAccessDetails formik={formik} />
              <Grid container item spacing={2}
                py={2} px={2} xs={12}
                lg={"auto"}>
                <Grid item xs={12} lg={6}>
                  <FeesList
                    fees={fees}
                    title={"Transfer Charges & Fees"}
                    isFeeChecked={isChecked || ((id) => !!fees.find((item) => item.id === id && item.is_required))}
                    handleChange={handleChange}
                    removeFee={removeFee}
                    handleWaiveFeeOnChange={handleWaiveFeeOnChange}
                    formik={formik}
                    isDisabled={(id) => !!fees.find((item) => item.id === id && item.is_required)}
                />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <InsurancePlansList />
                </Grid>
                <Grid item xs={12}>
                  <MerchandiseList />
                </Grid>
              </Grid>
            </>
            )}
      </Paper>
      <AddExternalTPPModal />
    </ReviewLayout>
  );
};

export default ProcessTransferUnit;
