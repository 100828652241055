import { Grid, Modal } from "@mui/material";
import {
  selectFacilitiesLoading,
  selectPaginationFacilities
} from "src/store/reducers/facilitiesSlice/facilitiesSlice";
import {
  selectFacilityDisableModalOpen,
  toggleFacilityDisableModal
} from "src/store/reducers/modalSlice/modalSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import DisableModal from "./DisableModal/DisableModal";
import EmptyTable from "../../../../components/ui/PMSTable/EmptyTable/EmptyTable";
import { Facility } from "../../../../models/Facility";
import ManageFacilitiesTableColumns from "./ManageFacilitiesTableColumns/ManageFacilitiesTableColumns";
import PMSTable from "../../../../components/ui/PMSTable/PMSTable";
import React from "react";
import formatPhone from "src/utils/formatPhone/formatPhone";
import { getAllPaginatedFacilities } from "../../../../store/thunks/facility/getAllPaginated/getAllPaginatedFacilities";
import useStyles from "./ManageFacilitiesTable.styles";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

export const formatFacility = (facility: Facility) => {
  return {
    ...facility,
    address: facility.address_two ? `${facility.address} ${facility.address_two}` : facility.address,
    phone: formatPhone(facility.phone),
    fax: formatPhone(facility.fax)
  };
};

export const formattedFacilities = (facilities: Facility[]) => {
  return facilities.map((facility) => formatFacility(facility));
};

const ManageFacilitiesTable: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const paginationFacilities = useAppSelector(selectPaginationFacilities);
  const facilitiesLoading = useAppSelector(selectFacilitiesLoading);
  const facilityDisableModalOpen = useAppSelector(selectFacilityDisableModalOpen);
  const permissions = useAppSelector(selectFacilityPermissions);
  const facilityPermissions = createPermissions(permissions, "facility");

  const handlePaginationUpdate = () => {
    dispatch(getAllPaginatedFacilities());
  };

  const emptyTable = () => (
    <EmptyTable
      collectionNotFound={"facilities"}
      value={"facility"}
      addLink={"/facility-management/add-facility"}
    />
  );

  return (
    <>
      <PMSTable
        data-testid={"manage-facilities-table"}
        columns={ManageFacilitiesTableColumns(dispatch, facilityPermissions.update || facilityPermissions.delete)}
        rows={formattedFacilities(paginationFacilities)}
        emptyTableDisplay={emptyTable}
        onPaginationUpdate={handlePaginationUpdate}
        loading={facilitiesLoading}
      />
      <Modal
        data-testid={"facility-disable-modal"}
        open={facilityDisableModalOpen}
        onClose={() => dispatch(toggleFacilityDisableModal(false))}
        aria-labelledby={"simple-modal-title"}
        aria-describedby={"simple-modal-description"}
      >
        <Grid
          className={classes.modalContainer}
          container
          item
          justifyContent={"center"}
          alignItems={"center"}
        >
          <div className={classes.modal}>
            <DisableModal />
          </div>
        </Grid>
      </Modal>
    </>
  );
};

export default ManageFacilitiesTable;
