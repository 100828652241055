import React, { ReactElement } from "react";
import { selectDeals, selectDealsFilter, selectDealsLoading } from "src/store/reducers/dealsSlice/dealsSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import EmptyTable from "../../../../components/ui/PMSTable/EmptyTable/EmptyTable";
import PMSTable from "../../../../components/ui/PMSTable/PMSTable";
import { getAllActiveDeals } from "../../../../store/thunks/deals/getAllActive/getAllActiveDeals";
import manageDealsTableColumns from "./ManageDealsTableColumns/ManageDealsTableColumns";

const ManageDealsTable: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const deals = useAppSelector(selectDeals);
  const dealsLoading = useAppSelector(selectDealsLoading);
  const dealFilter = useAppSelector(selectDealsFilter);

  const handlePaginationUpdate = () => {
    dispatch(getAllActiveDeals(dealFilter));
  };

  const emptyTable = () => (
    <EmptyTable collectionNotFound={`deals`} value={"deal"} addLink={"/deals/create-new-deal"} />
  );

  return (
    <PMSTable
      identifier={"deals"}
      data-testid={"manage-deals-table"}
      columns={manageDealsTableColumns(dispatch)}
      rows={deals}
      emptyTableDisplay={emptyTable}
      onPaginationUpdate={handlePaginationUpdate}
      loading={dealsLoading}
    />
  );
};

export default ManageDealsTable;
