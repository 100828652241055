import {
  Button,
  Grid,
  Modal,
  Paper,
  Typography
} from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import {
  selectLeasePackageModalOpened,
  selectSendEmailModalOpened,
  setLeasePackageModalOpened
} from "src/store/reducers/moveInSlice/moveInSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import GenerateLeasePackageModal
  from "src/pages/Deals/CreateMoveIn/GenerateLeasePackageModal/GenerateLeasePackageModal";
import SendEmailForm from "src/pages/Merchandise/PointOfSales/MerchSummary/SendEmailReceiptForm/SendEmailReceiptForm";
import SendEmailModal from "src/pages/Deals/CreateMoveIn/GenerateLeasePackageModal/SendEmailModal/SendEmailModal";
import TransferUnitConfirmationTable from "./TransferUnitConfirmationTable/TransferUnitConfirmationTable";
import { handleReset } from "../TransferUnit";
import { printReceipt } from "src/store/thunks/moveIn/print/printReceipt";
import { selectMoveOutLedger } from "src/store/reducers/moveOutSlice/moveOutSlice";
import { selectSelectedPromotion } from "src/store/reducers/selectedPromotionSlice/selectedPromotionSlice";
import { selectSelectedUnit } from "src/store/reducers/selectedUnitSlice/selectedUnitSlice";
import { selectTransferUnitConfirmation } from "src/store/reducers/transferUnitSlice/transferUnitSlice";
import useStyles from "./TransferUnitConfirmation.styles";

const TransferUnitConfirmation: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const transferUnitConfirmation = useAppSelector(selectTransferUnitConfirmation);
  const promotion = useAppSelector(selectSelectedPromotion);
  const selectedUnitFromTransfer = useAppSelector(selectSelectedUnit);
  const leasePackageModalOpened = useAppSelector(selectLeasePackageModalOpened);
  const sendEmailModalOpened = useAppSelector(selectSendEmailModalOpened);
  const selectedMoveOutLedger = useAppSelector(selectMoveOutLedger);
  const [emailModalOpen, setEmailModalOpen] = useState(false);

  const handlePrintReceiptClick = () => {
    dispatch(printReceipt()).then((resp) => {
      if (!resp.type.includes("rejected")) {
        const link = document.createElement("a");
        link.href = resp.payload as string;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  useEffect(() => {
    return () => handleReset(dispatch);
  }, []);

  const openModal = () => {
    dispatch(setLeasePackageModalOpened());
  };

  const handleEmailModalToggle = () => {
    setEmailModalOpen(!emailModalOpen);
  };

  return (
    <>
      <Typography variant={"h4"} mt={2}>
        Transfer Unit Confirmation
      </Typography>
      <Paper elevation={1}>
        <Grid p={4}>
          <Typography fontWeight={"bold"}>
            Your transfer is confirmed. Confirmation #{transferUnitConfirmation?.transaction?.id}
          </Typography>
          <Typography mt={2}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Null am sit amet minibus enim, vitae ornare Maris. Nam NEC Massey Lucius diam interim accused.
            Nunc in fermentum mi, id consectetur Purus.
          </Typography>

          <Typography my={2} fontWeight={"bold"}>Transferred from: </Typography>

          <TransferUnitConfirmationTable
            ledger={selectedMoveOutLedger!}
            unit={selectedMoveOutLedger?.unit}
            promotion={selectedMoveOutLedger?.promotion}
          />

          <Typography my={2} fontWeight={"bold"}>Transferred to: </Typography>

          <TransferUnitConfirmationTable
            ledger={transferUnitConfirmation?.ledger}
            unit={selectedUnitFromTransfer!}
            promotion={promotion}
          />

          <Grid mt={2} gap={2} container>
            <Button color={"primary"} variant={"contained"} className={classes.ctaButton}
              onClick={openModal}>
              Generate Lease Package
            </Button>
            <Button variant={"outlined"} className={classes.button} onClick={handleEmailModalToggle}>
              Email Receipt
            </Button>
            <Button variant={"outlined"} className={classes.button} onClick={() => handlePrintReceiptClick()}>
              Print Receipt
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {leasePackageModalOpened
        ? (
          <GenerateLeasePackageModal />
          )
        : null}
      {sendEmailModalOpened
        ? (
          <SendEmailModal />
          )
        : null}

      <Modal onClose={handleEmailModalToggle} className={classes.modal} open={emailModalOpen}>
        <Grid
          container
          className={classes.modalContainer}
          component={Paper}
          elevation={0}
        >
          <SendEmailForm closeButtonAction={handleEmailModalToggle} />
        </Grid>
      </Modal>

    </>
  );
};

export default TransferUnitConfirmation;
