import React, { ReactElement } from "react";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import clsx from "clsx";
import useStyles from "./ReviewActionButtons.styles";

interface MoveInActionButtonsProps {
  loading?: boolean
  ctaText: string
  handlerMethod?: () => void
  cancelMethod: () => void
  formId?: string
  disabled?: boolean
}

const ReviewActionButtons: React.FC<MoveInActionButtonsProps> = ({
  loading,
  ctaText,
  cancelMethod,
  formId,
  disabled,
  handlerMethod
}: MoveInActionButtonsProps): ReactElement => {
  const { classes } = useStyles();

  return (
    <>
      <LoadingButton
        className={clsx(classes.baseButton, classes.filledButton)}
        data-testid={"handler-button"}
        variant={"contained"}
        size={"large"}
        form={formId ?? "review-form"}
        onClick={handlerMethod}
        type={"submit"}
        loading={loading}
        disabled={loading || disabled}
        role={"button"}
      >
        {ctaText}
      </LoadingButton>
      <Button
        data-testid={"cancel-button"}
        className={clsx(classes.baseButton, classes.textButton)}
        variant={"text"}
        size={"large"}
        onClick={cancelMethod}
      >
        Cancel
      </Button>
    </>
  );
};

export default ReviewActionButtons;
