import React, { ReactElement } from "react";
import GetStarted from "../../../../components/stepper/GetStarted/GetStarted";

const GetMoveInStarted: React.FC = (): ReactElement => {
  const advisoryText = (
    <>
      <p>You can use the following workflow to move an occupant into a unit.</p>
      <p>
        You will be able to select an existing occupant from the system, as well as their desired unit from the
        list of those currently available. Applicable promotions and fees will be available for selection, and
        you will be able to finalize the transaction via taking the occupant’s payment and generating their
        lease for signature.
      </p>
    </>
  );

  return (
    <GetStarted
      title={"Move In"}
      advisoryText={advisoryText}
      nextStepPath={"/occupants/create-move-in/select-occupant"}
    />
  );
};

export default GetMoveInStarted;
