import { DataGrid, GridRowId, GridRowParams, GridSelectionModel } from "@mui/x-data-grid";
import React, { ReactElement, useEffect } from "react";
import {
  selectLedgers,
  selectLedgersLoading, selectSelectedLedgers, selectSelectedLedgersBeforePayment, setLedgerPayments,
  setSelectedLedgers
} from "../../../../store/reducers/makeAPaymentSlice/makeAPaymentSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { Box } from "@mui/system";
import EmptyTable from "../../../../components/ui/PMSTable/EmptyTable/EmptyTable";
import LedgerTableColumns from "../SelectOccupantOrUnit/LedgerTableColumns/LedgerTableColumns";
import { Typography } from "@mui/material";
import { getAllLedgersForOccupant } from "../../../../store/thunks/ledger/getAllForOccupant/getAllLedgersForOccupant";
import {
  getPartialPaymentCost
} from "../../../../store/thunks/makeAPayment/getPartialPaymentCost/getPartialPaymentCost";
import {
  getPartialPaymentCostNew
} from "src/store/thunks/makeAPayment/getPartialPaymentCostNew/getPartialPaymentCostNew";
import { selectSelectedOccupant } from "../../../../store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import useStyles from "./OccupantLedgerTable.styles";
import { getMaxAmount } from "src/store/thunks/makeAPayment/getMaxAmount/getMaxAmount";

const OccupantLedgerTable: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const ledgersLoading = useAppSelector(selectLedgersLoading);
  const selectedOccupant = useAppSelector(selectSelectedOccupant);
  const selectedOccupantLedgers = useAppSelector(selectLedgers);
  const outStandingBalanceAllLedgers = selectedOccupantLedgers.reduce(
    (totalDue, ledger) => totalDue + Number(ledger.current_balance), 0
  );
  const selectedLedgers = useAppSelector(selectSelectedLedgers);
  const selectedRows = selectedLedgers.map(row => row.id as GridRowId);

  useEffect(() => {
    if (selectedOccupant) {
      dispatch(getAllLedgersForOccupant({
        occupantId: selectedOccupant.id,
        filter: { "filter[is_active]": "1" }
      }));
    }
  }, [selectedOccupant]);

  const emptyTable = () => (
    <EmptyTable
      collectionNotFound={"ledgers"}
      value={"ledgers"}
    />
  );

  const updateSelectedLedgers = (selectedIds: GridSelectionModel) => {
    const currentSelectedLedger = selectedOccupantLedgers.filter(l => selectedIds[selectedIds.length - 1] === l.id);

    const ledgerPayments = currentSelectedLedger.map((ledger) => {
      return { id: ledger.id, payment_amount: ledger.current_balance };
    });

    if (selectedOccupant?.id && currentSelectedLedger[0]?.id) {
      dispatch(setSelectedLedgers(currentSelectedLedger));
      dispatch(setLedgerPayments(ledgerPayments));
      dispatch(getMaxAmount({ occupantId: selectedOccupant?.id, ledgerId: currentSelectedLedger[0].id }));
      dispatch(getPartialPaymentCostNew({ occupantId: selectedOccupant?.id, ledgerId: currentSelectedLedger[0].id }));
    }
  };

  return (
    <DataGrid
      className={classes.table}
      autoHeight
      components={{
        Footer: () => (
          <Box className={classes.total}>
            <Typography fontWeight={"bold"}>OUTSTANDING BALANCE DUE</Typography>
            <Typography fontWeight={"bold"}>$ { outStandingBalanceAllLedgers.toFixed(2) }</Typography>
          </Box>
        ),
        NoResultsOverlay: emptyTable,
        NoRowsOverlay: emptyTable
      }}
      hideFooterPagination={true}
      hideFooterSelectedRowCount={true}
      loading={ledgersLoading}
      onSelectionModelChange={updateSelectedLedgers}
      checkboxSelection={true}
      selectionModel={selectedRows}
      columns={LedgerTableColumns(selectedOccupant)}
      rows={selectedOccupantLedgers}
      // isRowSelectable={({ row }: GridRowParams) => row.current_balance > 0}
    />
  );
};

export default OccupantLedgerTable;
