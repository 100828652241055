import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";

import achSlice from "./reducers/achSlice/achSlice";
import api from "src/api/api";
import appSoftphoneSlice from "./reducers/appSoftphoneSlice/appSoftphoneSlice";
import applyFeeSlice from "./reducers/applyFeeSlice/applyFeeSlice";
import askingRateChangesSlice from "./reducers/askingRateChangesSlice/askingRateChangesSlice";
import auctionSlice from "./reducers/auctionSlice/auctionSlice";
import automaticPaymentsSlice from "./reducers/automaticPaymentsSlice/automaticPaymentsSlice";
import automationSlice from "./reducers/automationSlice/automationSlice";
import cashSlice from "./reducers/cashSlice/cashSlice";
import changeOwnershipSlice from "./reducers/changeOwnership/changeOwnership";
import chartOfAccountsSlice from "./reducers/chartOfAccountsSlice/chartOfAccountsSlice";
import collectionsSlice from "./reducers/collectionsSlice/collectionsSlice";
import creditCardSlice from "./reducers/creditCardSlice/creditCardSlice";
import creditsSlice from "./reducers/creditsSlice/creditsSlice";
import dealInformationSlice from "./reducers/dealInformationSlice/dealInformationSlice";
import dealNotificationSlice from "./reducers/dealNotificationSlice/dealNotificationSlice";
import dealSlice from "./reducers/dealSlice/dealSlice";
import dealsSlice from "./reducers/dealsSlice/dealsSlice";
import delinquencyAutomationsSlice from "./reducers/delinquencyAutomationsSlice/delinquencyAutomationsSlice";
import documentSlice from "./reducers/documentSlice/documentSlice";
import documentsReducerSlice from "./reducers/documentsSlice/documentsSlice";
import eSignStatusSlice from "./reducers/eSignStatusSlice/eSignStatusSlice";
import eSignTemplatesSlice from "./reducers/eSignTemplatesSlice/eSignTemplatesSlice";
import emergencyContactInformationSlice
  from "./reducers/emergencyContactInformationSlice/emergencyContactInformationSlice";
import emergencyContactSlice from "./reducers/emergencyContactSlice/emergencyContactSlice";
import errorReducerSlice from "./reducers/errorSlice/errorSlice";
import esignDocumentsSlice from "./reducers/esignDocumentsSlice/esignDocumentsSlice";
import externalTppPlanSlice from "./reducers/externalTppPlanSlice/externalTppPlanSlice";
import facilitiesReducerSlice from "./reducers/facilitiesSlice/facilitiesSlice";
import facilityHoursSlice from "./reducers/facilityHoursSlice/facilityHoursSlice";
import facilityReducerSlice from "./reducers/facilitySlice/facilitySlice";
import facilitySettingInformationSlice
  from "./reducers/facilitySettingInformationSlice/facilitySettingInformationSlice";
import feeCategoriesSlice from "./reducers/feeCategoriesSlice/feeCategoriesSlice";
import feeInformationSlice from "./reducers/feeInformationSlice/feeInformationSlice";
import feeSlice from "./reducers/feeSlice/feeSlice";
import feesSlice from "./reducers/feesSlice/feesSlice";
import formsReducerSlice from "./reducers/formsSlice/formsSlice";
import froalaSlice from "./reducers/froala/froalaSlice";
import globalSettingInformationSlice from "./reducers/globalSettingInformationSlice/globalSettingInformationSlice";
import importProductTypesSlice from "./reducers/importProductTypesSlice/importProductTypesSlice";
import insurancePlanInformationSlice from "./reducers/insurancePlanInformationSlice/insurancePlanInformationSlice";
import insurancePlansSlice from "./reducers/insurancePlansSlice/insurancePlansSlice";
import inventorySlice from "./reducers/inventorySlice/inventorySlice";
import ledgersSlice from "./reducers/ledgersSlice/ledgersSlice";
import makeAPaymentSlice from "./reducers/makeAPaymentSlice/makeAPaymentSlice";
import merchandiseCategoriesSlice from "./reducers/merchandiseCategoriesSlice/merchandiseCategoriesSlice";
import merchandiseForRefundSlice from "./reducers/merchandiseForRefundSlice/merchandiseForRefundSlice";
import merchandiseFormSlice from "./reducers/merchandiseFormSlice/merchandiseFormSlice";
import merchandiseItemsSlice from "./reducers/merchandiseItemsSlice/merchandiseItemsSlice";
import modalSlice from "./reducers/modalSlice/modalSlice";
import moneyOrderSlice from "./reducers/moneyOrderSlice/moneyOrderSlice";
import moveInCostSlice from "./reducers/moveInCostSlice/moveInCostSlice";
import moveInSlice from "./reducers/moveInSlice/moveInSlice";
import moveOutCostSlice from "./reducers/moveOutCostSlice/moveOutCostSlice";
import moveOutSlice from "./reducers/moveOutSlice/moveOutSlice";
import navigationReducerSlice from "./reducers/navigationSlice/navigationSlice";
import noteSlice from "./reducers/noteSlice/noteSlice";
import notesSlice from "./reducers/notesSlice/notesSlice";
import notificationReducerSlice from "./reducers/notificationSlice/notificationSlice";
import occupantBillingInformationSlice
  from "./reducers/occupantBillingInformationSlice/occupantBillingInformationSlice";
import occupantHistorySlice from "./reducers/occupantHistorySlice/occupantHistorySlice";
import occupantInformationSlice from "./reducers/occupantInformationSlice/occupantInformationSlice";
import occupantSlice from "./reducers/occupantSlice/occupantSlice";
import occupantsSlice from "./reducers/occupantsSlice/occupantsSlice";
import paymentSlice from "./reducers/paymentSlice/paymentSlice";
import paymentSummarySlice from "./reducers/paymentSummarySlice/paymentSummarySlice";
import permissionsSlice from "./reducers/permissionsSlice/permissionsSlice";
import pointOfSalesSlice from "./reducers/pointOfSalesSlice/pointOfSalesSlice";
import productCategoriesSlice from "./reducers/productCategoriesSlice/productCategoriesSlice";
import productCategorySlice from "./reducers/productCategorySlice/productCategorySlice";
import productTypeSlice from "./reducers/productTypeSlice/productTypeSlice";
import productTypeSliceNew from "./reducers/productTypeSliceNew/productTypeSliceNew";
import productTypesSlice from "./reducers/productTypesSlice/productTypesSlice";
import promotionInformationSlice from "./reducers/promotionInformationSlice/promotionInformationSlice";
import promotionSlice from "./reducers/promotionSlice/promotionSlice";
import promotionsSlice from "./reducers/promotionsSlice/promotionsSlice";
import reportParamsSlice from "./reducers/reportParamsSlice/reportParamsSlice";
import revenueClassInformationSlice from "./reducers/revenueClassInformationSlice/revenueClassInformationSlice";
import revenueClassSlice from "./reducers/revenueClassSlice/revenueClassSlice";
import revenueClassesSlice from "./reducers/revenueClassesSlice/revenueClassesSlice";
import reviewAndPayBillingAddressSlice
  from "./reducers/reviewAndPayBillingAddressSlice/reviewAndPayBillingAddressSlice";
import reviewAndPayCCSlice from "./reducers/reviewAndPayCC/reviewAndPayCCSlice";
import reviewAndPayCashSlice from "./reducers/reviewAndPayCashSlice/reviewAndPayCashSlice";
import selectedAdminFacilitySlice from "./reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import selectedFacilitySlice from "./reducers/selectedFacilitySlice/selectedFacilitySlice";
import selectedOccupantSlice from "./reducers/selectedOccupantSlice/selectedOccupantSlice";
import selectedPromotionSlice from "./reducers/selectedPromotionSlice/selectedPromotionSlice";
import selectedUnitSlice from "./reducers/selectedUnitSlice/selectedUnitSlice";
import snackbarReducerSlice from "./reducers/snackbarSlice/snackbarSlice";
import speedeRentSlice from "./reducers/speedeRent/speedeRentSlice";
import tablesSlice from "./reducers/tablesSlice/tablesSlice";
import taskCategoriesSlice from "./reducers/taskCategoriesSlice/taskCategoriesSlice";
import taskFormSlice from "./reducers/taskFormSlice/taskFormSlice";
import tasksSlice from "./reducers/tasksSlice/tasksSlice";
import transactionSlice from "./reducers/transactionSlice/transactionSlice";
import transferUnitCostSlice from "./reducers/transferUnitCostSlice/transferUnitCostSlice";
import transferUnitSlice from "./reducers/transferUnitSlice/transferUnitSlice";
import unitSlice from "./reducers/unitSlice/unitSlice";
import unitsSlice from "./reducers/unitsSlice/unitsSlice";
import unitsSliceNew from "./reducers/unitsSliceNew/unitsSliceNew";
import uploadedDocumentsSlice from "./reducers/uploadedDocumentsSlice/uploadedDocumentsSlice";
import userInfoSlice from "./reducers/userInfoSlice/userInfoSlice";
import userReducerSlice from "./reducers/userSlice/userSlice";
import webhookSlice from "./reducers/webhooks/webhookSlice";

export const rootStoreReducer = {
  reducer: {
    ach: achSlice,
    askingRateChanges: askingRateChangesSlice,
    appSoftphone: appSoftphoneSlice,
    automation: automationSlice,
    cash: cashSlice,
    chartOfAccounts: chartOfAccountsSlice,
    credits: creditsSlice,
    creditCard: creditCardSlice,
    dealInformation: dealInformationSlice,
    deal: dealSlice,
    deals: dealsSlice,
    collections: collectionsSlice,
    document: documentSlice,
    delinquencyAutomations: delinquencyAutomationsSlice,
    externalTppPlan: externalTppPlanSlice,
    eSignStatus: eSignStatusSlice,
    eSignTemplates: eSignTemplatesSlice,
    eSignDocuments: esignDocumentsSlice,
    emergencyContact: emergencyContactSlice,
    emergencyContactInformation: emergencyContactInformationSlice,
    error: errorReducerSlice,
    facility: facilityReducerSlice,
    facilities: facilitiesReducerSlice,
    facilityHours: facilityHoursSlice,
    feeInformation: feeInformationSlice,
    fees: feesSlice,
    fee: feeSlice,
    feeCategories: feeCategoriesSlice,
    froala: froalaSlice,
    forms: formsReducerSlice,
    facilitySettings: facilitySettingInformationSlice,
    globalSettings: globalSettingInformationSlice,
    ledgers: ledgersSlice,
    importProductTypes: importProductTypesSlice,
    insurancePlanInformation: insurancePlanInformationSlice,
    insurancePlans: insurancePlansSlice,
    makeAPayment: makeAPaymentSlice,
    inventory: inventorySlice,
    merchandiseCategories: merchandiseCategoriesSlice,
    merchandiseForm: merchandiseFormSlice,
    merchandiseItems: merchandiseItemsSlice,
    modal: modalSlice,
    moneyOrder: moneyOrderSlice,
    moveInCost: moveInCostSlice,
    moveIn: moveInSlice,
    moveOut: moveOutSlice,
    moveOutCost: moveOutCostSlice,
    navigation: navigationReducerSlice,
    note: noteSlice,
    notes: notesSlice,
    notification: notificationReducerSlice,
    occupant: occupantSlice,
    occupants: occupantsSlice,
    occupantBillingInformation: occupantBillingInformationSlice,
    occupantInformation: occupantInformationSlice,
    payment: paymentSlice,
    permissions: permissionsSlice,
    paymentSummary: paymentSummarySlice,
    pointOfSales: pointOfSalesSlice,
    productCategories: productCategoriesSlice,
    productCategory: productCategorySlice,
    productType: productTypeSlice,
    productTypeNew: productTypeSliceNew,
    productTypes: productTypesSlice,
    promotionInformation: promotionInformationSlice,
    promotions: promotionsSlice,
    promotion: promotionSlice,
    reportParams: reportParamsSlice,
    revenueClassInformation: revenueClassInformationSlice,
    revenueClass: revenueClassSlice,
    revenueClasses: revenueClassesSlice,
    selectedFacility: selectedFacilitySlice,
    selectedPromotion: selectedPromotionSlice,
    selectedAdminFacility: selectedAdminFacilitySlice,
    selectedOccupant: selectedOccupantSlice,
    selectedUnit: selectedUnitSlice,
    snackbar: snackbarReducerSlice,
    speedeRent: speedeRentSlice,
    tables: tablesSlice,
    taskCategories: taskCategoriesSlice,
    taskForm: taskFormSlice,
    tasks: tasksSlice,
    unit: unitSlice,
    units: unitsSlice,
    unitsNew: unitsSliceNew,
    user: userReducerSlice,
    userInfo: userInfoSlice,
    changeOwnership: changeOwnershipSlice,
    reviewAndPayCC: reviewAndPayCCSlice,
    reviewAndPayBillingAddress: reviewAndPayBillingAddressSlice,
    reviewAndPayCash: reviewAndPayCashSlice,
    documents: documentsReducerSlice,
    transactions: transactionSlice,
    uploadedDocuments: uploadedDocumentsSlice,
    merchandiseForRefunds: merchandiseForRefundSlice,
    transferUnit: transferUnitSlice,
    transferUnitCost: transferUnitCostSlice,
    applyFee: applyFeeSlice,
    occupantHistory: occupantHistorySlice,
    automaticPayments: automaticPaymentsSlice,
    dealNotification: dealNotificationSlice,
    auction: auctionSlice,
    webhookInformation: webhookSlice,
    [api.reducerPath]: api.reducer
  }
};

export const rootStore = configureStore({
  reducer: rootStoreReducer.reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware)
});

export const generateStoreWithInitialState = (initialState: Partial<RootState>) =>
  configureStore({
    reducer: rootStoreReducer.reducer,
    preloadedState: initialState
  });

export type AppDispatch = typeof rootStore.dispatch;
export type RootState = ReturnType<typeof rootStore.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
