import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "RefundMerchandise" })((theme) => ({
  bold: {
    fontWeight: "bold"
  },
  confirmButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.common.white,
    minWidth: theme.spacing(18),
    minHeight: theme.spacing(5),
    fontWeight: "normal"
  },
  cancelButton: {
    minWidth: theme.spacing(18),
    minHeight: theme.spacing(5),
    color: theme.palette.error.contrastText,
    fontWeight: "normal"
  }
}));

export default useStyles;
