import { Button, CircularProgress, Divider, Grid, Paper, Typography } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";

import { NavLink } from "react-router-dom";
import clsx from "clsx";
import useStyles from "./TableWrapper.styles";

interface TableWrapperProps {
  table: ReactNode;
  title?: string | ReactNode;
  filterButtonGroup?: ReactNode;
  searchBar?: ReactNode;
  addButtonText?: ReactNode; // you will need *both* of these properties to display the add button
  addButtonLink?: ReactNode; // you will need *both* of these properties to display the add button
  addButtonClick?: () => void;
  buttonStartIcon?: ReactNode;
  functionButton?: ReactNode;
  loading?: boolean;
  divider?: boolean;
  filterDropdown?: ReactNode;
}

const TableWrapper: React.FC<TableWrapperProps> = ({
  table,
  title,
  filterButtonGroup,
  searchBar,
  addButtonText,
  addButtonLink,
  addButtonClick,
  buttonStartIcon,
  functionButton,
  loading,
  divider,
  filterDropdown
}): ReactElement => {
  const { classes } = useStyles();

  const loadingSpinner = (
    <Grid py={5} container alignItems={"center"}
      justifyContent={"center"} data-testid={"item-loading"}>
      <CircularProgress size={50} />
    </Grid>
  );

  return (
    <Paper data-testid={"table-wrapper"} className={clsx(classes.root)}>
      <Grid container px={2} pt={2}
        flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}
        mb={1}>
        <Grid container item xs={"auto"}
          alignItems={"center"}>
          {title && (
            <Typography variant={"h5"} component={"h3"}>
              {title}
            </Typography>
          )}
          <Grid item ml={2}>
            {filterDropdown && filterDropdown}
          </Grid>

          {addButtonText && addButtonLink && (
            <Button
              data-testid={"table-add-button"}
              className={classes.addButton}
              component={NavLink}
              to={addButtonLink as string}
              startIcon={buttonStartIcon}
            >
              {addButtonText}
            </Button>
          )}
          {addButtonText && addButtonClick && (
            <Button className={classes.addButton} onClick={addButtonClick} startIcon={buttonStartIcon}>
              {addButtonText}
            </Button>
          )}
          {functionButton}
        </Grid>
        {filterButtonGroup && (
          <Grid data-testid={"table-button-group"} item xs={"auto"}>
            {filterButtonGroup}
          </Grid>
        )}
        {searchBar && (
          <Grid data-testid={"table-search-group"} item xs={3.25}>
            {searchBar}
          </Grid>
        )}
        {divider && (
          <Grid container>
            <Divider className={classes.bodyHeaderDivider} />
          </Grid>
        )}
      </Grid>
      {divider && <Divider />}

      {loading ? loadingSpinner : table}
    </Paper>
  );
};

export default TableWrapper;
