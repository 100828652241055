import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../rootStore";
import SnackbarSliceState from "./snackbarSliceState";

export const initialState: SnackbarSliceState = {
  message: "",
  variant: "success"
};

export const snackbarReducerSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    showSnackbar: (state: SnackbarSliceState, action: PayloadAction<any>) => {
      state.message = action.payload!.message;
      state.variant = action.payload!.variant;
    },
    hideSnackbar: (state: SnackbarSliceState) => {
      state.message = "";
      state.variant = "success";
    }
  }
});

export const selectSnackbarMessage = (state: RootState) => state.snackbar.message;
export const selectSnackbarVariant = (state: RootState) => state.snackbar.variant;

export const {
  showSnackbar,
  hideSnackbar
} = snackbarReducerSlice.actions;

export default snackbarReducerSlice.reducer;
