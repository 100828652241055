import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export interface FacilitySetting {
    id: number | null
    identifier: number | null
    value: string | null
    description: string
}

export interface UpdateFacilitySettingsPayload {
    facility_settings: Partial<FacilitySetting>[]
}

export const updateFacilitySettings = createAsyncThunk(
  "updateFacilitySettings", (
    { facilityId, data }: {facilityId: string, data: UpdateFacilitySettingsPayload},
    { dispatch, rejectWithValue }) => {
    return axios.put(`/api/v1/facilities/${facilityId}/settings`, data)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Facility settings updated",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an error updating the Facility settings";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
