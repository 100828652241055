import React, { ReactElement } from "react";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import useStyles from "./FooterButtons.styles";

interface FooterButtonsProps {
  formId: string;
  cancelPath: string;
  submitButtonText?: string;
  submitLoading?: boolean;
  disabled?: boolean;
}

const FooterButtons: React.FC<FooterButtonsProps> = ({
  formId,
  cancelPath,
  submitButtonText = "Save & Continue",
  submitLoading,
  disabled
}): ReactElement => {
  const { classes } = useStyles();

  return (
    <>
      <Button
        variant={"text"}
        className={clsx(classes.cancelButton, classes.buttonBase)}
        size={"large"}
        component={NavLink}
        to={cancelPath}
      >
        Cancel
      </Button>
      <LoadingButton
        form={formId}
        variant={"contained"}
        className={clsx(classes.saveButton, classes.buttonBase)}
        size={"large"}
        type={"submit"}
        loading={submitLoading}
        disabled={submitLoading || disabled}
      >
        {submitButtonText}
      </LoadingButton>
    </>
  );
};

export default FooterButtons;
