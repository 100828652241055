import { Button, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { ReactElement, useEffect } from "react";
import {
  resetSelectedOccupantSlice,
  setSelectedOccupant
}
  from "src/store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import {
  resetSelectedPromotionSlice,
  selectSelectedPromotion,
  setSelectedPromotion
}
  from "src/store/reducers/selectedPromotionSlice/selectedPromotionSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { formatDate } from "src/utils/__dateAndTimeUtils__/formatDate/formatDate";
import { formattedAmount } from "src/utils/formattedAmount/formattedAmount";
import { getUnitSizeFromUnit } from "src/utils/getUnitSize/getUnitSize";
import { printReceipt } from "src/store/thunks/moveIn/print/printReceipt";
import { resetAchSlice } from "src/store/reducers/achSlice/achSlice";
import { resetCashSlice } from "src/store/reducers/cashSlice/cashSlice";
import { resetCreditCardSlice } from "src/store/reducers/creditCardSlice/creditCardSlice";
import { resetDealSlice } from "src/store/reducers/dealSlice/dealSlice";
import { resetEmergencyContactInformationSlice }
  from "src/store/reducers/emergencyContactInformationSlice/emergencyContactInformationSlice";
import { resetMoneyOrderSlice } from "src/store/reducers/moneyOrderSlice/moneyOrderSlice";
import { resetMoveInCostSlice } from "src/store/reducers/moveInCostSlice/moveInCostSlice";
import { resetOccupantInformationSlice } from "src/store/reducers/occupantInformationSlice/occupantInformationSlice";
import { resetOpenExpansionRows } from "src/store/reducers/tablesSlice/tablesSlice";
import {
  selectLeasePackageModalOpened,
  selectMoveInConfirmation, selectSendEmailModalOpened, setLeasePackageModalOpened, setSendEmailModalOpened
} from "src/store/reducers/moveInSlice/moveInSlice";
import useStyles from "./MoveInConfirmation.styles";
import GenerateLeasePackageModal from "../GenerateLeasePackageModal/GenerateLeasePackageModal";
import SendEmailModal from "../GenerateLeasePackageModal/SendEmailModal/SendEmailModal";

const MoveInConfirmation: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const moveInConfirmation = useAppSelector(selectMoveInConfirmation);
  const promotion = useAppSelector(selectSelectedPromotion);
  const unit = moveInConfirmation?.ledger?.unit || moveInConfirmation?.unit;
  const leasePackageModalOpened = useAppSelector(selectLeasePackageModalOpened);
  const sendEmailModalOpened = useAppSelector(selectSendEmailModalOpened);

  const handlePrintReceiptClick = () => {
    dispatch(printReceipt()).then((resp) => {
      if (!resp.type.includes("rejected")) {
        const link = document.createElement("a");
        link.href = resp.payload as string;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  const handleEmailReceipt = () => {
    dispatch(setSendEmailModalOpened());
  };

  useEffect(() => {
    return () => {
      dispatch(setSelectedOccupant(null));
      dispatch(setSelectedPromotion(null));
      dispatch(resetDealSlice());
      dispatch(resetMoveInCostSlice());
      dispatch(resetOpenExpansionRows());
      dispatch(resetSelectedOccupantSlice());
      dispatch(resetOccupantInformationSlice());
      dispatch(resetEmergencyContactInformationSlice());
      dispatch(resetSelectedPromotionSlice());
      dispatch(resetCashSlice());
      dispatch(resetMoneyOrderSlice());
      dispatch(resetCreditCardSlice());
      dispatch(resetAchSlice());
    };
  }, []);

  const openModal = () => {
    dispatch(setLeasePackageModalOpened());
  };

  return (
    <>
      <Typography variant={"h4"} mt={2}>
        Move In Confirmation
      </Typography>
      <Paper elevation={1}>
        <Grid p={4}>
          <Typography fontWeight={"bold"}>Your move in is confirmed. Confirmation #XXXXXXX</Typography>
          <Grid xs={8} mt={2} className={classes.table}
            item>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align={"left"}>Unit Number</TableCell>
                  <TableCell align={"center"}>Unit Size</TableCell>
                  <TableCell align={"center"}>Floor</TableCell>
                  <TableCell align={"center"}>Deal Rate</TableCell>
                  <TableCell align={"center"}>Move In Date</TableCell>
                  <TableCell align={"center"}>Applied Discount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align={"left"}>{unit?.unit_number}</TableCell>
                  <TableCell align={"center"}>
                    {getUnitSizeFromUnit(unit)}
                  </TableCell>
                  <TableCell align={"center"}>{unit?.floor}</TableCell>
                  <TableCell align={"center"}>
                    {formattedAmount("Fixed Amount", moveInConfirmation?.ledger?.monthly_rate.toString()!)}
                  </TableCell>
                  <TableCell align={"center"}>{formatDate(moveInConfirmation?.ledger?.moved_in_at || "")}</TableCell>
                  <TableCell align={"center"}>{promotion?.name ?? "None"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid mt={2} gap={2} container>
            <Button color={"primary"} variant={"contained"} className={classes.ctaButton}
              onClick={openModal}>
              Generate Lease Package
            </Button>
            <Button variant={"outlined"} className={classes.button} onClick={() => handleEmailReceipt()}>
              Email Receipt
            </Button>
            <Button variant={"outlined"} className={classes.button} onClick={() => handlePrintReceiptClick()}>
              Print Receipt
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {leasePackageModalOpened ? (<GenerateLeasePackageModal />) : null}
      {sendEmailModalOpened ? (<SendEmailModal />) : null}
    </>
  );
};

export default MoveInConfirmation;
