import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  Paper,
  TextField,
  TextFieldProps,
  Typography
} from "@mui/material";
import React, { ChangeEvent, FC, useEffect } from "react";
import {
  selectExternalTPPModalOpen,
  selectExternalTppEditMode,
  selectSelectedExternalTppPlan,
  setExternalTPPModalOpen,
  setExternalTppPlanActive,
  setSelectedExternalTppPlan
} from "src/store/reducers/externalTppPlanSlice/externalTppPlanSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Close } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import ExternalTppPlan from "src/models/ExternalTppPlan";
import { LoadingButton } from "@mui/lab";
import moment, { Moment as MomentType } from "moment";
import { formatDate } from "src/utils/__dateAndTimeUtils__/formatDate/formatDate";
import { inputError } from "src/utils/showInputError/showInputError";
import { useFormik } from "formik";
import useStyles from "./AddExternalTPPModal.styles";
import validationSchema from "./externalTppPlanValidation";
import { setNextPaymentTppPlan, setTppPlan } from "src/store/reducers/moveInCostSlice/moveInCostSlice";
import { getMoveInCost } from "src/store/thunks/moveIn/getMoveInCost/getMoveInCost";

interface AddExternalTPPModalProps {
  leaseStartDate?: string;
}

const AddExternalTPPModal: FC<AddExternalTPPModalProps> = ({ leaseStartDate }) => {
  const { classes } = useStyles();
  const externalTPPModalOpen = useAppSelector(selectExternalTPPModalOpen);
  const externalTppPlan = useAppSelector(selectSelectedExternalTppPlan);
  const isEdit = useAppSelector(selectExternalTppEditMode);
  const dispatch = useAppDispatch();

  const initialValues: ExternalTppPlan & { leaseStartDate: string } = {
    policy_start_date: externalTppPlan?.policy_start_date ?? "",
    policy_end_date: externalTppPlan?.policy_end_date ?? "",
    coverage_amount: externalTppPlan?.coverage_amount ?? "",
    policy_number: externalTppPlan?.policy_number ?? "",
    description: externalTppPlan?.description ?? "",
    leaseStartDate: leaseStartDate ?? ""
  };

  useEffect(() => {
    initialValues.leaseStartDate = leaseStartDate ?? "";
  }, [leaseStartDate]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: ({ leaseStartDate, ...rest }) => {
      dispatch(setExternalTPPModalOpen(false));
      dispatch(setSelectedExternalTppPlan(rest));
      dispatch(setExternalTppPlanActive(true));
      dispatch(setTppPlan(null));
      dispatch(setNextPaymentTppPlan(null));
      dispatch(getMoveInCost());
    }
  });

  const { values, errors, touched, handleChange, resetForm, setFieldValue, handleSubmit } = formik;

  const handleCloseModal = () => {
    if (isEdit) {
      return dispatch(setExternalTPPModalOpen(false));
    }

    dispatch(setExternalTPPModalOpen(false));
    resetForm({ values: initialValues });
  };

  return (
    <Modal onClose={handleCloseModal} open={externalTPPModalOpen} className={classes.modal}>
      <Grid
        p={4}
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        justifyContent={"center"}
        className={classes.container}
      >
        <Grid container component={"form"} id={"external-tpp-form"}
          onSubmit={handleSubmit} spacing={2}>
          <Grid item xs={11}>
            <Typography variant={"h5"}>{isEdit ? "Edit External Insurance" : "Add External Insurance"}</Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={handleCloseModal}>
              <Close />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <InputLabel htmlFor={"policy_start_date"}>
              <Typography noWrap>Policy Start Date:</Typography>
            </InputLabel>
            <DatePicker
              renderInput={(props: TextFieldProps) => (
                <TextField
                  {...props}
                  fullWidth
                  name={"policy_start_date"}
                  error={inputError("policy_start_date", touched, errors).error}
                  helperText={inputError("policy_start_date", touched, errors).helperText}
                />
              )}
              OpenPickerButtonProps={{ "aria-label": "Select Start Date" }}
              componentsProps={{
                leftArrowButton: { "aria-label": "Move Backward" },
                rightArrowButton: { "aria-label": "Move Backward" }
              }}
              inputFormat={"MM/DD/yyyy"}
              maxDate={moment(leaseStartDate || "")}
              value={values.policy_start_date}
              onChange={(value: MomentType | null) => setFieldValue("policy_start_date", formatDate(value))}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel htmlFor={"policy_end_date"}>
              <Typography noWrap>Policy End Date:</Typography>
            </InputLabel>
            <DatePicker
              renderInput={(props: TextFieldProps) => (
                <TextField
                  {...props}
                  fullWidth
                  name={"policy_end_date"}
                  error={inputError("policy_end_date", touched, errors).error}
                  helperText={inputError("policy_end_date", touched, errors).helperText}
                />
              )}
              OpenPickerButtonProps={{ "aria-label": "Select Start Date" }}
              componentsProps={{
                leftArrowButton: { "aria-label": "Move Backward" },
                rightArrowButton: { "aria-label": "Move Backward" }
              }}
              inputFormat={"MM/DD/yyyy"}
              value={values.policy_end_date}
              minDate={moment(values.policy_start_date)}
              onChange={(value: MomentType | null, keyboardInputValue?: string | undefined) =>
                setFieldValue("policy_end_date", formatDate(value))
              }
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor={"policy_number"}>
              <Typography noWrap>Policy Number:</Typography>
            </InputLabel>
            <TextField
              name={"policy_number"}
              value={values.policy_number}
              onChange={handleChange}
              fullWidth
              error={inputError("policy_number", touched, errors).error}
              helperText={inputError("policy_number", touched, errors).helperText}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor={"description"}>
              <Typography noWrap>Coverage amount:</Typography>
            </InputLabel>
            <TextField
              fullWidth
              inputProps={{ "data-testid": "monthly-rate" }}
              type={"number"}
              name={"coverage_amount"}
              value={values.coverage_amount}
              InputProps={{ startAdornment: <InputAdornment position={"start"}>$</InputAdornment> }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("coverage_amount", e.target.value)}
              error={inputError("coverage_amount", touched, errors).error}
              helperText={inputError("coverage_amount", touched, errors).helperText}
            />
            <Grid item xs={12}>
              <InputLabel htmlFor={"description"}>
                <Typography noWrap>Policy description:</Typography>
              </InputLabel>
              <TextField
                fullWidth
                name={"description"}
                onChange={handleChange}
                value={values.description}
                rows={5}
                multiline
                error={inputError("description", touched, errors).error}
                helperText={inputError("description", touched, errors).helperText}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12}
            mt={2} justifyContent={"flex-end"}>
            <Button onClick={handleCloseModal} color={"error"} variant={"text"}
              sx={{ marginRight: 5 }}>
              Cancel
            </Button>
            <LoadingButton form={"external-tpp-form"} variant={"contained"} type={"submit"}
              className={classes.button}>
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AddExternalTPPModal;
