import { PaymentMethod } from "../../../../enums/PaymentMethod";
import { RootState } from "../../../rootStore";
import { TransferUnitPayload } from "./TransferUnitPayload";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { getChangeDue } from "src/store/reducers/cashSlice/cashSlice";
import moment from "moment";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const createTransferUnit = createAsyncThunk(
  "createTransferUnit",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;

    const changeOwed = parseFloat(getChangeDue(state.cash.amountDue, state.cash.amountReceived)) * -1;

    const amountsReceived = [state.cash.amountReceived, state.moneyOrder.amountReceived];

    let paymentAmount = amountsReceived[state.payment.paymentType - 1] ?? state.transferUnitCost.total_due_today;

    if (parseFloat(state.transferUnitCost.total_due_today) <= 0 && (!paymentAmount || parseFloat(paymentAmount) <= 0)) {
      paymentAmount = changeOwed.toFixed(2);
    }

    const payload: TransferUnitPayload = {
      lease_start_date: moment(state.transferUnit?.leaseStartsOn).format("yyyy-MM-DD"),
      new_unit_id: state.selectedUnit?.selectedUnit?.id!,
      payment_amount: paymentAmount,
      promotion_id: state.selectedPromotion?.selectedPromotion?.id,
      fees: state.transferUnit?.fees ?? null,
      tpp_plan_id: state.transferUnit?.insurancePlanId,
      merchandise: state.transferUnit?.merchandise,
      payment_method: state.payment?.paymentType,
      periods: state.transferUnit?.periods,
      waive_fee: state.transferUnit?.waiveFee?.waiveFeeAmount,
      what_are_you_storing: state.transferUnit?.storingReason,
      is_promo_deferred: state.transferUnit?.promoDefermentStatus,
      monthly_rate: parseFloat(state.transferUnit?.monthlyRate),
      external_tpp_plan: state.externalTppPlan.selectedExternalTppPlan,
      refund_type: state.transferUnitCost.refundType ? state.transferUnitCost.refundType : null,
      refund_ledger_id: state.transferUnitCost?.applyToUnit?.length > 0 ? +state.transferUnitCost.applyToUnit : null
    };

    if (payload.payment_method === PaymentMethod.creditCard || payload.payment_method === PaymentMethod.ach) {
      payload.billing = {
        first_name: "",
        last_name: "",
        enable_autobill: false
      };

      if (payload.payment_method === PaymentMethod.creditCard) {
        payload.billing.first_name = state.creditCard.name.split(" ")[0];
        payload.billing.last_name = state.creditCard.name.split(" ")[1];
        payload.billing.card_number = state.creditCard.number;
        payload.billing.exp_month = state.creditCard.expiration.substring(0, 2);
        payload.billing.exp_year = "20" + state.creditCard.expiration.substring(2, 4);
        payload.billing.cvv = state.creditCard.cvv;
        payload.billing.credit_card_type = state.creditCard.type;
        payload.billing.instrument_type =
          state.creditCard.type === "american-express" ? "american express" : state.creditCard.type;
        payload.billing.enable_autobill = state.creditCard.enableAutoPay;
      } else {
        payload.billing.first_name = state.ach.accountName.split(" ")[0];
        payload.billing.last_name = state.ach.accountName.split(" ")[1];
        payload.billing.account_number = state.ach.accountNumber;
        payload.billing.routing_number = state.ach.routingNumber;
        payload.billing.enable_autobill = state.ach.enableAutoPay;
        payload.billing.check_number = state.ach.checkNumber;
      }
    }

    if (payload.payment_method === PaymentMethod.moneyOrder) {
      payload.billing = {
        first_name: state.selectedOccupant.selectedOccupant?.first_name ?? "",
        last_name: state.selectedOccupant.selectedOccupant?.last_name ?? "",
        money_order_number: state.moneyOrder.moneyOrderNumber
      };
    }

    const occupantId = state.selectedOccupant?.selectedOccupant?.id;
    const ledger = state.ledgers.selectedLedgerToTransfer;

    return axios
      .post(`/api/v1/occupants/${occupantId}/ledgers/${ledger}/transfer`, payload)
      .then((resp) => {
        dispatch(
          showSnackbar({
            message: "Unit Transferred successfully",
            variant: "success"
          })
        );

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(
            showSnackbar({
              message: errorMessage,
              variant: "error"
            })
          );

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an error transferring the unit";

        if (err && err.response && err.response.status && err.response.status === 400) {
          errorMessage += `: ${err.response.data.detail}`;
        }

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  }
);
