import * as Yup from "yup";
import { moneyValidator } from "../../../utils/commonRegexes";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Merchandise Name is required"),
  price: Yup.string().required("Price is required").matches(moneyValidator, "Incorrect Format"),
  revenue_class_id: Yup.number().moreThan(0, "Revenue Class is required"),
  merchandise_category_id: Yup.number().moreThan(0, "Merchandise Category is required")
  // sku: Yup.string().required("SKU is required"),
  // quantity: Yup.number().required("Quantity is required")
  // .moreThan(-1, "Quantity is required")
  // .integer("Incorrect Format"),
  // cost_of_goods_sold: Yup.string().required("Cost of Goods Sold is required")
  // .matches(moneyValidator, "Incorrect Format"),
  // quantity_change_reason_required: Yup.boolean(),
  // quantity_change_reason: Yup.string().when("quantity_change_reason_required", {
  // is: true,
  // then: (schema) => schema.required("Quantity change reason is required"),
  // otherwise: (schema) => schema.notRequired()
  // })
});

export default validationSchema;
