import {
  Alert,
  AlertTitle,
  FormControlLabel,
  Grid,
  InputLabel,
  Modal,
  Paper,
  Radio,
  SelectChangeEvent,
  TextField,
  TextFieldProps,
  Typography
} from "@mui/material";
import React, { ReactElement, SyntheticEvent, useEffect, useRef, useState } from "react";
import {
  resetMoveOutCostSlice,
  selectMoveOutCostCreditProratedValue,
  selectMoveOutCostEnableIssueCredit,
  selectMoveOutCostFees,
  selectMoveOutCostFinalBalance,
  selectMoveOutCostNoteDescription,
  selectMoveOutCostShowApplyToOtherUnitDropdown,
  selectMoveOutCostUnrentable,
  selectMoveOutCostUnrentableReason,
  selectMoveOutCostWaiveFee,
  selectMoveOutcostApplyToUnit,
  selectRefundType,
  setApplyToUnit,
  setCreditProratedValue,
  setMoveOutCostEnableIssueCredit,
  setMoveOutCostUnrentable,
  setMoveOutCostUnrentableReason,
  setMoveOutCostWaiveFee,
  setMoveOutNoteDescription,
  setShowApplyToOtherUnitDropdown,
  setrefundType
} from "src/store/reducers/moveOutCostSlice/moveOutCostSlice";
import {
  resetMoveOutSlice,
  selectIsMoveOutFromManageOccupants,
  selectMoveOutDate,
  selectMoveOutLedger,
  setMoveOut
} from "src/store/reducers/moveOutSlice/moveOutSlice";
import {
  selectLedgersModalOpen,
  setLedgersModalOpen,
  setSelectedLedger
} from "src/store/reducers/ledgersSlice/ledgersSlice";
import {
  selectSelectedOccupant,
  selectSelectedOccupantLedgers
} from "src/store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

import { CheckCircleOutline } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import DealDetails from "src/layouts/stepper/ReviewLayout/DealDetails/DealDetails";
import FeesList from "../../FeesList/FeesList";
import IssueCredit from "./IssueCredit/IssueCredit";
import LedgerView from "src/pages/Occupants/EditOccupant/LedgerView/LedgerView";
import PMSCheckbox from "src/components/ui/PMSCheckbox/PMSCheckbox";
import PMSRadio from "src/components/ui/PMSRadio/PMSRadio";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import ProcessMoveOutValidationSchema from "./ProcessMoveOutValidation";
import ReviewActionButtons from "src/layouts/stepper/ReviewLayout/ReviewActionButtons/ReviewActionButtons";
import ReviewHeader from "src/layouts/stepper/ReviewLayout/ReviewHeader/ReviewHeader";
import ReviewLayout from "src/layouts/stepper/ReviewLayout/ReviewLayout";
import { getActiveLedgers } from "src/utils/getActiveLedgers/getActiveLedgers";
import { getAllGlobalSettings } from "src/store/thunks/globalSetting/getAll/getAllGlobalSettings";
import { getMoveOutCost } from "src/store/thunks/moveOut/getMoveOutCost/getMoveOutCost";
import { handleReset } from "../CreateMoveOut";
import { inputError } from "src/utils/showInputError/showInputError";
import moment from "moment";
import { selectFees } from "src/store/reducers/feesSlice/feesSlice";
import { selectMoveOutMarkDebtSetting, selectEnableProratedMoveOutSetting }
  from "src/store/reducers/globalSettingInformationSlice/globalSettingInformationSlice";
import { selectTargetPath } from "src/store/reducers/navigationSlice/navigationSlice";
import { setOccupantInformation } from "src/store/reducers/occupantInformationSlice/occupantInformationSlice";
import { today } from "src/utils/__dateAndTimeUtils__/today";
import { unrentableReasons } from "src/utils/constants";
import { useFormik } from "formik";
import useMoveOutStyles from "../styles/MoveOut.styles";
import { useNavigate } from "react-router";
import useStyles from "./ProcessMoveOut.styles";
import { without } from "lodash";

export const refundOptions = [
  { value: "1", label: "No refund" }
  // Leave refund on account will only be enabled for post MVP.
  //{ value: "2", label: "Leave refund on Account" },
  // { value: "3", label: "Apply to other unit" }
  // { value: "4", label: "Return to occupant" } // CK-254 Hide option while full process isn't implemented
];

const ProcessMoveOut: React.FC = (): ReactElement => {
  const redirect = useAppSelector(selectTargetPath);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const occupant = useAppSelector(selectSelectedOccupant);
  const moveOutFees = useAppSelector(selectFees);
  const moveOutLedger = useAppSelector(selectMoveOutLedger);
  const finalBalance = useAppSelector(selectMoveOutCostFinalBalance);
  const ledgersModalOpen = useAppSelector(selectLedgersModalOpen);
  const moveOutDate = useAppSelector(selectMoveOutDate);
  const refundType = useAppSelector(selectRefundType);
  const unrentable = useAppSelector(selectMoveOutCostUnrentable);
  const creditProratedValue: boolean = useAppSelector(selectMoveOutCostCreditProratedValue);
  const waiveFees = useAppSelector(selectMoveOutCostWaiveFee);
  const noteDescription = useAppSelector(selectMoveOutCostNoteDescription);
  const fees = useAppSelector(selectMoveOutCostFees);
  const issueCredit = useAppSelector(selectMoveOutCostEnableIssueCredit);
  const showApplyToOtherUnitdDropdown = useAppSelector(selectMoveOutCostShowApplyToOtherUnitDropdown);
  const selectedOccupantLedgers = useAppSelector(selectSelectedOccupantLedgers);
  const applyToUnit = useAppSelector(selectMoveOutcostApplyToUnit);
  const unrentableReason = useAppSelector(selectMoveOutCostUnrentableReason);
  const { classes } = useStyles();
  const { classes: moveOutClasses } = useMoveOutStyles();
  const activeLedgers = getActiveLedgers(occupant?.ledgers!);
  const sameDayMoveIn = moment(today).isSame(moveOutLedger?.moved_in_at);
  const isMoveOutFromManageOccupants = useAppSelector(selectIsMoveOutFromManageOccupants);
  const moveOutFinalBalance = useAppSelector(selectMoveOutCostFinalBalance);
  const moveOutMinDate = moment().isAfter(moment(moveOutLedger?.moved_in_at))
    ? moment().format("MM/DD/YYYY")
    : moment(moveOutLedger?.moved_in_at).format("MM/DD/YYYY");
  const lastValidValues = useRef(null);

  const [isMoveOutFuture, setIsMoveOutFuture] = useState(false);
  const moveOutMarkDebtAsBadSetting = useAppSelector(selectMoveOutMarkDebtSetting);
  const isMarkAsBadDebt = (parseFloat(moveOutFinalBalance) > 0 &&
      Number(moveOutMarkDebtAsBadSetting?.value) === 1);
  const enableProratedMoveOutSetting = useAppSelector(selectEnableProratedMoveOutSetting);
  const enableProratedMoveOut = Number(enableProratedMoveOutSetting?.value) === 1;

  const initialValues = {
    moveOutDate: moveOutDate || moveOutMinDate,
    creditProratedValue,
    waiveFees,
    issueCreditValue: "",
    fees: fees.map((fee) => fee.id) || [],
    refundType: refundType,
    unrentable: unrentable,
    noteDescription: noteDescription || "",
    takePaymentForRemainder: false,
    markRemainderAsBadDebt: isMarkAsBadDebt,
    moveOutViaAuction: false,
    buyerName: null,
    issueCredit,
    showApplyToOtherUnitdDropdown,
    applyToUnit,
    unrentableReason,
    finalBalance
  };

  const {
    values,
    errors,
    touched,
    validateField,
    validateForm,
    handleSubmit,
    setFieldValue,
    setFieldTouched
  } = useFormik({
    initialValues: initialValues,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: ProcessMoveOutValidationSchema(moveOutMinDate),
    onSubmit: () => {
      dispatch(setMoveOut(values));
      navigate("/occupants/create-move-out/review-move-out");
    }
  });

  const handleFutureMoveOut = () => {
    setIsMoveOutFuture(false);
    if (moment(values?.moveOutDate).isAfter(moment())) {
      setIsMoveOutFuture(true);
    }
  };

  const getBackButtonText = () => {
    if (isMoveOutFromManageOccupants) {
      return "Manage Occupants";
    }
    return activeLedgers!.length > 1 ? "Select Unit" : "Select Occupant";
  };

  const handleGoBack = async() => {
    if (isMoveOutFromManageOccupants) {
      await navigate("/occupants/manage-occupants");
      return;
    }

    activeLedgers?.length > 1
      ? navigate("/occupants/create-move-out/select-unit")
      : navigate("/occupants/create-move-out/select-occupant");

    dispatch(resetMoveOutSlice());
    dispatch(resetMoveOutCostSlice());
  };

  const handleCancel = () => {
    if (redirect && redirect.includes("manage-deals")) {
      return navigate(redirect);
    }

    navigate("/occupants/create-move-out");
    handleReset(dispatch);
  };

  const isFeeChecked = (id: number) => {
    return !!fees.find((item) => item.id === id);
  };

  const toggleViewIssueCredit = () => {
    setFieldValue("issueCredit", !values.issueCredit);
    dispatch(setMoveOutCostEnableIssueCredit(!values.issueCredit));
    setFieldValue("issueCreditValue", 0);
    setFieldTouched("issueCreditValue");
  };

  const submitIssueCredit = () => {
    const invalidIssueCredit =
      isNaN(+values.issueCreditValue) ||
      +values.issueCreditValue <= 0 ||
      inputError("issueCreditValue", touched, errors).error;

    if (invalidIssueCredit) {
      return toggleViewIssueCredit();
    }

    dispatch(getMoveOutCost(values));
  };

  const handleIssueCreditInputChange = (e: SyntheticEvent) => {
    const input = e.target as HTMLInputElement;
    const numericalValue: string = input.value.replace(/,/g, "");
    const parsedValue = parseFloat(numericalValue) || 0;

    if (parsedValue <= parseFloat(finalBalance)) {
      setFieldValue("issueCreditValue", parsedValue);
    }
  };

  const getUpdatedFeeList = (feeId: number) => {
    if (isFeeChecked(feeId)) {
      return without(values.fees, feeId);
    } else {
      return values.fees.concat(feeId);
    }
  };

  const handleProratedValueChange = () => {
    setFieldValue("creditProratedValue", !values.creditProratedValue);
    dispatch(setCreditProratedValue(!values.creditProratedValue));
  };

  const handleWaiveFeeChange = () => {
    dispatch(setMoveOutCostWaiveFee(!values.waiveFees));
    setFieldValue("waiveFees", !values.waiveFees);
  };

  const handleMarkAsUnrentable = () => {
    dispatch(setMoveOutCostUnrentable(!values.unrentable));
    setFieldValue("unrentable", !values.unrentable);
  };

  const handleNoteDescription = (value: string) => {
    dispatch(setMoveOutNoteDescription(value));
    setFieldValue("noteDescription", value);
  };

  const handleRefundOnChange = (e: SyntheticEvent) => {
    const input = e.target as HTMLInputElement;

    setFieldValue("refundType", input.value);
    dispatch(setrefundType(input.value));

    // hide apply to unit dropdown
    dispatch(setShowApplyToOtherUnitDropdown(false));
    setFieldValue("showApplyToOtherUnitdDropdown", false);

    // reset value of apply to unit
    dispatch(setApplyToUnit(""));
    setFieldValue("applyToUnit", "");

    if (input.value === "3") {
      // show apply to unit dropdown
      dispatch(setShowApplyToOtherUnitDropdown(true));
      setFieldValue("showApplyToOtherUnitdDropdown", true);
    }
  };

  const handleApplyToUnitOnChange = (e: SelectChangeEvent<any>) => {
    setFieldValue("applyToUnit", e.target.value);
    dispatch(setApplyToUnit(e.target.value));
  };

  const handleMarkAsUnrentableReason = (e: SelectChangeEvent<any>) => {
    setFieldValue("unrentableReason", e.target.value);
    dispatch(setMoveOutCostUnrentableReason(e.target.value));
  };

  const isApplyToOtherUnitDisabled = () => {
    if (!occupant || !occupant?.ledgers) {
      return true;
    }

    return !occupant.ledgers.some((ledger) => ledger.is_active && ledger.id !== moveOutLedger?.id);
  };
  const setMoveOutDateValue = (value: string | null) => {
    const date = moment(value).format("MM/DD/YYYY");

    setFieldValue("moveOutDate", date);
    // Manually trigger validation for moveOutDate field
    validateField("moveOutDate");
  };

  const getGlobalSettings = async() => {
    await dispatch(getAllGlobalSettings());
  };

  useEffect(() => {
    dispatch(setMoveOutCostEnableIssueCredit(false));

    setFieldValue("issueCredit", false);

    if (!occupant || !occupant.ledgers) {
      navigate("/occupants/create-move-out");
    }

    if (occupant) {
      dispatch(setOccupantInformation(occupant));
    }
    getGlobalSettings();
    dispatch(setSelectedLedger(moveOutLedger));
  }, []);

  useEffect(() => {
    async function runEffect() {
      const errors = await validateForm();

      // move forward if there are no errors or if the error is related to refundType
      // because initially refund type shouldn't have a defautl value but we should be calculating the move out cost
      // even if the user hasn't selected a refund type yet.
      if (Object.keys(errors).length === 0 || errors.refundType) {
        // @ts-ignore
        lastValidValues.current = values;

        // if (values.issueCreditValue) {
        //   setFieldValue("issueCreditValue", 0);
        // }

        if (!Object.is(values, initialValues)) {
          dispatch(
            getMoveOutCost({
              ...values,
              issueCreditValue: undefined
            })
          );
        }
      }
    }

    if (!Object.is(values, lastValidValues.current)) {
      runEffect();
      handleFutureMoveOut();
    }
  }, [values]);

  useEffect(() => {
    if (!Object.is(values, initialValues) && !values.issueCredit) {
      setFieldValue("issueCreditValue", 0);
      dispatch(getMoveOutCost(values));
    }
  }, [values.issueCredit]);

  useEffect(() => {
    // onMoveOutCost fetch reset this fields if final balance is greater or equal than 0
    if (parseFloat(finalBalance) >= 0 && refundType !== "1") {
      setFieldValue("applyToUnit", "");
      setFieldValue("showApplyToOtherUnitdDropdown", false);

      dispatch(setApplyToUnit(""));
      dispatch(setShowApplyToOtherUnitDropdown(false));
    }

    setFieldValue("finalBalance", finalBalance);
  }, [finalBalance]);

  useEffect(() => {
    dispatch(getMoveOutCost(values));
  }, []);

  return (
    <ReviewLayout
      header={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ReviewHeader
              headerText={"Process Move-Out"}
              data-testid={"process-move-out-header"}
              backMethod={handleGoBack}
              goBackText={getBackButtonText()}
            />
          </Grid>
          {isMoveOutFuture
            ? (
              <Grid item xs={9} alignSelf={"center"}>
                <Alert severity={"error"}>
                  <AlertTitle>Important</AlertTitle>
                  <Typography variant={"body2"}>
                    This move out is scheduled for a future date.
                    Actual amount due will be calculated on move out date.
                  </Typography>
                </Alert>
              </Grid>
              )
            : null}
        </Grid>
      }
      actionButtons={
        <ReviewActionButtons
          ctaText={"Review Move-Out"}
          handlerMethod={() => handleSubmit()}
          cancelMethod={handleCancel}
        />
      }
    >
      <Paper className={classes.fullWidth} elevation={1}>
        <DealDetails isMoveOut />
        <Grid display={"flex"} container item
          px={2} spacing={2}>
          <Grid item lg={3} md={6}
            xs={12}>
            <InputLabel htmlFor={"move-out-date"} className={moveOutClasses.headerCell}>
              Move Out Date
            </InputLabel>
            <DatePicker
              minDate={moment().format("MM/DD/YYYY")}
              onChange={(value: string | null) => setMoveOutDateValue(value)}
              value={values.moveOutDate}
              renderInput={(props: TextFieldProps) => (
                <TextField
                  name={"move-out-date"}
                  id={"move-out-date"}
                  fullWidth
                  placeholder={"- Move out date -"}
                  {...props}
                  onKeyDown={(e) => e.preventDefault()}
                  helperText={inputError("moveOutDate", touched, errors).helperText || errors.moveOutDate}
                  error={inputError("moveOutDate", touched, errors).error || Boolean(errors.moveOutDate)}
                />
              )}
            />
          </Grid>

          <Grid lg={3} md={6} xs={12}
            item>
            <InputLabel htmlFor={"refunds"} className={moveOutClasses.headerCell}>
              Refunds
            </InputLabel>
            <PMSRadio
              changeHandler={handleRefundOnChange}
              name={"Refunds"}
              value={values.refundType}
              helperText={inputError("refundType", touched, errors).helperText}
              helperTextStyles={classes.errorText}
            >
              {refundOptions.map((item) => (
                <>
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    disabled={
                      (item.value === "3" && isApplyToOtherUnitDisabled()) ||
                      (parseFloat(finalBalance) >= 0 && refundType !== "1")
                    }
                    control={<Radio checkedIcon={<CheckCircleOutline />} />}
                    label={item.label}
                  />

                  {showApplyToOtherUnitdDropdown && item.value === "3" && (
                    <Grid item xs={12} my={1}>
                      <PMSSelect
                        id={"move-out-cost-apply-to-unit"}
                        data-testid={"move-out-cost-apply-to-unit"}
                        name={"applyToUnit"}
                        value={values.applyToUnit}
                        changeHandler={handleApplyToUnitOnChange}
                        error={inputError("applyToUnit", touched, errors).error || Boolean(errors.applyToUnit)}
                        helperText={inputError("applyToUnit", touched, errors).helperText || errors.applyToUnit}
                      >
                        <option value={""}>Select Unit...</option>
                        {selectedOccupantLedgers
                          .filter((ledger) => ledger.is_active && ledger.id !== moveOutLedger?.id)
                          .map((ledger, i) => {
                            return (
                              <option key={i} value={ledger.id}>
                                Unit {ledger.unit?.unit_number}
                              </option>
                            );
                          })}
                      </PMSSelect>
                    </Grid>
                  )}
                </>
              ))}
            </PMSRadio>
          </Grid>

          <Grid lg={3} md={6} xs={12}
            item>
            <InputLabel className={moveOutClasses.headerCell}>Credit Options</InputLabel>
            <PMSCheckbox
              name={"prorate"}
              label={"Prorate remaining rent & charges"}
              value={"Prorate remaining rent & charges"}
              isChecked={values.creditProratedValue}
              changeHandler={handleProratedValueChange}
              disabled={sameDayMoveIn || !enableProratedMoveOut}
            />
            <PMSCheckbox
              name={"waiveFees"}
              label={"Waive fees after move out"}
              value={"Waive fees after move out"}
              isChecked={values.waiveFees}
              changeHandler={handleWaiveFeeChange}
            />
            {/* <IssueCredit
              isChecked={values.issueCredit}
              onToggleCheckbox={toggleViewIssueCredit}
              onInputChange={handleIssueCreditInputChange}
              issueCredit={values.issueCreditValue}
              onSubmit={submitIssueCredit}
              error={inputError("issueCreditValue", touched, errors).error}
              helperText={inputError("issueCreditValue", touched, errors).helperText}
            /> */}
          </Grid>
          <Grid item lg={3} md={6}
            xs={12}>
            <InputLabel className={moveOutClasses.headerCell}>Unit Notes</InputLabel>
            <PMSCheckbox
              name={"unrentable"}
              label={"Mark as Unrentable"}
              value={"Mark as Unrentable"}
              isChecked={values.unrentable}
              changeHandler={handleMarkAsUnrentable}
            />
            {unrentable && (
              <Grid item xs={12} my={1}>
                <PMSSelect
                  id={"mark-as-unrentable-reason"}
                  data-testid={"mark-as-unrentable-reason"}
                  name={"markAsUnrentableReason"}
                  value={values.unrentableReason}
                  changeHandler={handleMarkAsUnrentableReason}
                  label={"Unrentable Reason"}
                  error={inputError("unrentableReason", touched, errors).error}
                  helperText={inputError("unrentableReason", touched, errors).helperText}
                >
                  <option value={""} disabled>
                    Select a Reason
                  </option>
                  {unrentableReasons.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </PMSSelect>
              </Grid>
            )}
            <TextField
              fullWidth
              rows={5}
              multiline
              name={"noteDescription"}
              value={values.noteDescription}
              placeholder={"Add Note"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNoteDescription(e.target.value)}
              helperText={inputError("noteDescription", touched, errors).helperText}
              error={inputError("noteDescription", touched, errors).error}
            ></TextField>
          </Grid>

          <Grid xs={12} item mb={2}>
            <FeesList
              title={"Rental Adjustments & Fees"}
              fees={moveOutFees}
              isFeeChecked={isFeeChecked}
              handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue("fees", getUpdatedFeeList(+event.target.value));
              }}
              removeFee={(id: number) => setFieldValue("fees", getUpdatedFeeList(id)) as Promise<void>}
              isDisabled={(id) => !!fees.find((item) => item.id === id && item.is_required)}
            />
          </Grid>
        </Grid>
      </Paper>

      <Modal
        open={ledgersModalOpen}
        onClose={() => dispatch(setLedgersModalOpen(false))}
        aria-labelledby={"Modal Component"}
        aria-describedby={"A Modal component."}
        className={classes.modal}
      >
        {/* this fragment here gets rid of a strange forwardRef console error locally. */}
        <>
          <LedgerView showLedgerPrintAndSelectUnit={false} />
        </>
      </Modal>
    </ReviewLayout>
  );
};

export default ProcessMoveOut;
