import * as Yup from "yup";
import { moneyValidator } from "../../../../utils/commonRegexes";

const validationSchema = Yup.object().shape({
  unitLength: Yup.string().required("Unit Length required"),
  unitWidth: Yup.string().required("Unit Width required"),
  unitHeight: Yup.string().required("Unit Height required. If unknown or not applicable, enter \"0\""),
  streetRate: Yup.string().required("Unit Price required").matches(moneyValidator, "Incorrect format")
});

export default validationSchema;
