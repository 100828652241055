import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { ArrowBack } from "@mui/icons-material";
import { Link } from "react-router-dom";
import useStyles from "./Redirect404.styles";
interface Redirect404Props{}

const Redirect404: FC<Redirect404Props> = () => {
  const { classes } = useStyles();
  return (
    <Grid container >
      <Grid
        item
        xs={12}
        alignItems={"center"}
        justifyContent={"center"}
        display={"flex"}
        flexDirection={"column"}
        height={"90vh"}
      >
        <Grid
          item
          xs={6}
        >
          <Typography className={classes.statusText}>
            404
          </Typography>
          <Typography className={classes.titleText}>
            That page must be in a storage unit somewhere.
          </Typography>
          <p className={classes.subtitleText}>
            Sorry but the page you are looking for does not exist, may have been removed,
            or is currently temporarily unavailable.
          </p>
          <Link to={"/"} style={{ textDecoration: "none" }}>
            <Grid
              display={"flex"}
              flexDirection={"row"}
              gap={1}
              alignItems={"center"}
            >
              <ArrowBack/>
              <Typography className={classes.returnText}>
                Return to dashboard
              </Typography>
            </Grid>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Redirect404;
