import React, { ReactElement, useEffect } from "react";
import {
  resetRevenueClassInformationSlice,
  selectRevenueClassInformationLoading,
  selectUpdateRevenueClassModalOpen,
  setSelectedRevenueClassId,
  toggleUpdateRevenueClassModal
} from "../../../../store/reducers/revenueClassInformationSlice/revenueClassInformationSlice";
import {
  resetRevenueClassSlice,
  selectRevenueClassLoading
} from "../../../../store/reducers/revenueClassSlice/revenueClassSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useNavigate, useParams } from "react-router";
import { AppDispatch } from "../../../../store/rootStore";
import AppModal from "src/components/ui/AppModal/AppModal";
import { Breadcrumb } from "../../../../models/Breadcrumb";
import FooterButtons from "../../../../components/itemManagement/ItemManagementCard/FooterButtons/FooterButtons";
import ItemManagementLayout from "../../../../layouts/ItemManagement/ItemManagementLayout";
import PageHeader from "../../../../components/ui/PageHeader/PageHeader";
import RevenueClassForm from "../RevenueClassForm/RevenueClassForm";
import { Typography } from "@mui/material";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import {
  getAllPaginatedRevenueClasses
} from "src/store/thunks/revenueClass/getAllPaginated/getAllPaginatedRevenueClasses";
import { getRevenueClass } from "../../../../store/thunks/revenueClass/get/getRevenueClass";
import { resetTablesSlice } from "../../../../store/reducers/tablesSlice/tablesSlice";
import { selectSelectedAdminFacility } from "src/store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import { updateRevenueClass } from "src/store/thunks/revenueClass/update/updateRevenueClass";
import {
  setFromRevenueClassPage,
  setRevenueClassesFilter
} from "src/store/reducers/revenueClassesSlice/revenueClassesSlice";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Accounting"
  },
  {
    name: "Revenue Classes",
    bold: true
  }
];

export const handleResets = (dispatch: AppDispatch) => {
  dispatch(resetRevenueClassSlice());
  dispatch(resetRevenueClassInformationSlice());
  dispatch(resetTablesSlice());
};

const EditRevenueClass: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectRevenueClassLoading);
  const params = useParams<{ id: string }>();
  const revenueClassInformationLoading = useAppSelector(selectRevenueClassInformationLoading);
  const updateRevenueClassModalOpen = useAppSelector(selectUpdateRevenueClassModalOpen);
  const navigate = useNavigate();
  const selectedFacility = useAppSelector(selectSelectedAdminFacility);
  const permissions = useAppSelector(selectFacilityPermissions);
  const revenueClassesPermissions = createPermissions(permissions, "revenue_classes");

  const handleUpdateRevenueClass = () => {
    dispatch(updateRevenueClass()).then((resp) => {
      if (!resp.type.includes("rejected")) {
        dispatch(toggleUpdateRevenueClassModal(false));
        dispatch(setRevenueClassesFilter({ "filter[is_active]": "1" }));
        dispatch(getAllPaginatedRevenueClasses({
          facilityId: selectedFacility?.id,
          filter: { "filter[is_active]": "1" }
        }));
        navigate("/accounting/revenue-classes");
      }
    });
  };

  useEffect(() => {
    dispatch(setFromRevenueClassPage(true));
    dispatch(setSelectedRevenueClassId(params.id));
    dispatch(getRevenueClass(params.id!));

    /* istanbul ignore next */ // cannot test this w/ enzyme
    return () => {
      handleResets(dispatch);
    };
  }, []);

  const pageHeader = <PageHeader title={"Edit Revenue Class"} breadcrumbs={breadcrumbs} />;

  if (revenueClassesPermissions.update) {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <ItemManagementLayout
          loading={revenueClassInformationLoading}
          title={"Edit Revenue Class"}
          footerButtons={
            <FooterButtons
              formId={"revenue-class-form"}
              cancelPath={"/accounting/revenue-classes"}
              submitLoading={loading}
              submitButtonText={"Update Revenue Class"}
            />
          }
        >
          <RevenueClassForm isUpdate={true} />
        </ItemManagementLayout>
        <AppModal
          open={updateRevenueClassModalOpen}
          confirmAction={() => handleUpdateRevenueClass()}
          confirmActionLoading={revenueClassInformationLoading}
          cancelAction={() => dispatch(toggleUpdateRevenueClassModal(false))}
        >
          <Typography variant={"h6"} gutterBottom>Are you sure?</Typography>
          <Typography gutterBottom>
            Adjusting this Revenue Class will affect the final pricing of all assets tied to it in the system.
          </Typography>
        </AppModal>
      </ViewWrapper>
    );
  } else {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <h1>You do not have permission to view this page.</h1>
      </ViewWrapper>
    );
  }
};

export default EditRevenueClass;
