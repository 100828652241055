import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Button,
  Grid,
  GridProps,
  Typography
} from "@mui/material";
import React, { FC, MouseEventHandler, SyntheticEvent, useState } from "react";
import {
  selectCurrentReport,
  selectReportApplyParametersDisabled,
  selectReportDate,
  selectReportEndDate,
  selectReportFacilities,
  selectReportFacility,
  selectReportHours,
  selectReportStartDate
} from "src/store/reducers/reportParamsSlice/reportParamsSlice";
import BackButton from "../../../../components/navigation/BackButton/BackButton";
import FacilityMultiSelectAndDateRange from "../ParamInputs/FacilityMultiSelectAndDate/FacilityMultiSelectAndDate";
import { FormikValues } from "formik";
import RptAPI from "src/utils/reportApiHelper/RptApi";
import { Tune } from "@mui/icons-material";
import clsx from "clsx";
import moment from "moment";
import reports from "../../reports";
import { selectFacilities } from "src/store/reducers/facilitiesSlice/facilitiesSlice";
import { useAppSelector } from "src/store/hooks";
import useStyles from "./ReportParamsBuilder.styles.";

interface ReportParamsBuilderProps { }

const ReportParamsBuilder: FC<ReportParamsBuilderProps> = () => {
  const { classes } = useStyles();

  const [expanded, setExpanded] = useState<boolean>(false);
  const reportName = useAppSelector(selectCurrentReport);
  const attachedFacilities = useAppSelector(selectReportFacilities);
  const attachedFacility = useAppSelector(selectReportFacility);
  const startDate = useAppSelector(selectReportStartDate);
  const date = useAppSelector(selectReportDate);
  const endDate = useAppSelector(selectReportEndDate);
  const hours = useAppSelector(selectReportHours);
  const facilitiesMaster = useAppSelector(selectFacilities);
  const operator_id = facilitiesMaster.find((facility) => facility.id === attachedFacilities[0])?.operator_id;

  const handleChange = (e:SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setExpanded(!expanded);
  };

  const handleSubmit = (e: SyntheticEvent, values: FormikValues) => {
    e.stopPropagation();
    e.preventDefault();
    const selectedReport = reports.find((report) => report.name === reportName);

    if (!reportName || !selectedReport || (!attachedFacilities.length && !attachedFacility.length)) {
      return;
    }

    const catalogName = selectedReport?.catalog;
    const rptDef = { name: selectedReport?.path };
    const catDef = { name: catalogName };

    let effectiveOperatorId = operator_id;
    if (typeof effectiveOperatorId === "undefined" && facilitiesMaster.length > 0) {
      effectiveOperatorId = facilitiesMaster[0].operator_id;
    }
    const params = {
      ...effectiveOperatorId && { operator_id: effectiveOperatorId },
      ...attachedFacilities.length && { facility_id: attachedFacilities },
      ...attachedFacility.length && { single_facility_id: +attachedFacility[0] },
      ...startDate && { start_date: moment(startDate).format("MMMM D, YYYY") },
      ...endDate && { end_date: moment(endDate).format("MMMM D, YYYY") },
      ...date && { date: moment(date).format("MMMM D, YYYY") },
      ...hours && { hours: hours }
    };

    RptAPI.runReport(rptDef, catDef, params);
  };

  const accordionProps: Partial<AccordionProps> = {
    variant: "outlined",
    className: clsx(
      classes.headerBackground,
      classes.noRotate,
      classes.borderRadius
    ),
    disableGutters: true,
    elevation: 0,
    square: true,
    expanded: expanded
  };

  const summaryInnerGridProps: GridProps = {
    container: true,
    alignItems: "center",
    justifyContent: "space-between",
    spacing: 2,
    item: true,
    xs: 12,
    px: 2
  };

  const ParamsButton: FC = () => {
    const startDate = useAppSelector(selectReportStartDate);
    const endDate = useAppSelector(selectReportEndDate);
    const date = useAppSelector(selectReportDate);
    const reportname = useAppSelector(selectCurrentReport);
    const hours = useAppSelector(selectReportHours);
    const attachedFacilities = useAppSelector(selectReportFacilities);
    const isDisabled = useAppSelector(selectReportApplyParametersDisabled);

    const values = {
      startDate,
      endDate,
      date,
      reportname,
      hours,
      attachedFacilities,
      attachedFacility
    };

    return (
      <Button
        disableElevation
        onClick={(e) => expanded ? handleSubmit(e, values) : handleChange(e)}
        startIcon={<Tune />}
        className={classes.paramsButton}
        variant={"contained"}
        disabled={isDisabled}
      >
        <Grid component={Typography} variant={"subtitle2"} alignSelf={"end"}>
          {expanded ? "Update" : "Edit"}
        </Grid>
      </Button>
    );
  };

  return (
    <Accordion style={{ margin: 0 }}
      {...accordionProps} >
      <AccordionSummary
        onClick={handleChange}
        className={classes.noMarginSummaryContent}
        expandIcon={<ParamsButton />}
        id={"panel-header"}
      >
        <Grid {...summaryInnerGridProps}>
          <Grid item xs={8}>
            <Typography variant={"h6"}>{reportName}</Typography>
          </Grid>
          <Grid alignSelf={"flex-end"} item >
            <BackButton path={"/reporting/view-reports"} pathName={"Reporting"} />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={clsx(
        classes.detailsBorderRadius,
        classes.detailsInnerPadding,
        classes.detailsBackground
      )}>
        <FacilityMultiSelectAndDateRange isParamEdit={true} />
      </AccordionDetails>
    </Accordion >
  );
};

export default ReportParamsBuilder;
