import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "UserSettings" })((theme) => ({
  contentContainer: {
    marginTop: "1.5rem"
  },
  paper: {
    padding: ".75rem",
    marginRight: "1rem"
  },
  smallMargin: {
    marginTop: ".5rem"
  },
  userIcon: {
    fontSize: "9rem",
    color: "grey"
  }
}));

export default useStyles;
