import {
  Box,
  Button
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import React, { ReactElement, useEffect } from "react";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";
import {
  resetPromotionInformationSlice,
  selectPromotionInformationLoading,
  selectPromotionSwitches
} from "../../../store/reducers/promotionInformationSlice/promotionInformationSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Breadcrumb } from "../../../models/Breadcrumb";
import { LoadingButton } from "@mui/lab";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import PromotionForm from "../PromotionForm/PromotionForm";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import clsx from "clsx";
import { createPromotion } from "../../../store/thunks/promotion/create/createPromotion";
import useStyles from "./CreatePromotion.styles";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Promotions"
  },
  {
    name: "Create Promotion",
    bold: true
  }
];

const CreatePromotion: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const loading = useAppSelector(selectPromotionInformationLoading);
  const promotionSwitches = useAppSelector(selectPromotionSwitches);
  const footerButtons = (
    <>
      <Button
        variant={"text"}
        className={clsx(classes.cancelButton, classes.buttonBase)}
        size={"large"}
        component={NavLink}
        to={"/promotions"}
      >
        Cancel
      </Button>
      <LoadingButton
        form={"promotion-form"}
        variant={"contained"}
        className={clsx(classes.saveButton, classes.buttonBase)}
        size={"large"}
        type={"submit"}
        loading={loading}
        disabled={loading}
      >
        Save & Continue
      </LoadingButton>
    </>
  );

  const handleSubmit = () => {
    dispatch(createPromotion(promotionSwitches))
      .then(resp => {
        if (!resp.type.includes("rejected")) {
          navigate("/promotions");
        }
      });
  };

  useEffect(() => {
    dispatch(resetPromotionInformationSlice());
    return () => {
      dispatch(resetPromotionInformationSlice());
    };
  }, []);

  const pageHeader = <PageHeader title={"Create Promotion"} breadcrumbs={breadcrumbs} />;
  const permissions = useAppSelector(selectFacilityPermissions);
  const promotionsPermissions = createPermissions(permissions, "promotions");

  return (
    promotionsPermissions.create
      ? (<ViewWrapper pageHeader={pageHeader}>
        <PromotionForm data-testid={"create-promotion-form"} submitHandler={handleSubmit}/>
        <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
          {footerButtons}
        </Box>
      </ViewWrapper>)
      : <ViewWrapper pageHeader={pageHeader}>
        <h1>You do not have permission to view this page.</h1>
      </ViewWrapper>
  );
};
export default CreatePromotion;
