import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSearchParams } from "../../../../utils/getSearchParams/getSearchParams";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getByIdDocumentTemplate = createAsyncThunk(
  "getByIdDocumentTemplate",
  (documentId: any, { dispatch, getState, rejectWithValue }) => {
    const store = getState() as RootState;

    const params = {
      params: {
        ...getSearchParams(store, true, "name"),
        "with[]": "facilities"
      }
    };

    return axios.get(`/api/v1/document-templates/${documentId}`, params)
      .then(resp => resp.data)
      .catch(() => {
        const errorMessage = "There was an error getting the Document Template";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
