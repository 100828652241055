import { Grid, InputLabel, SwitchProps, TextField, Typography } from "@mui/material";

import PMSSwitch from "../ui/PMSSwitch/PMSSwitch";
import React from "react";
import { useController } from "react-hook-form";

type FormSwitchProps = {
  name: string;
  label: string;
  size?: SwitchProps["size"]
  isDisabled?: boolean
  withLabel?: boolean
};

const FormSwitch = ({ name, label, size, isDisabled = false, withLabel = true }: FormSwitchProps) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    name
  });

  return (
    <Grid>
      {withLabel && (
        <InputLabel htmlFor={name}>
          <Typography noWrap>{label}</Typography>
        </InputLabel>
      )}

      <PMSSwitch
        {...field}
        name={name}
        size={size}
        smallText
        isChecked={field.value}
        label={label}
        changeHandler={field.onChange}
        isDisabled={isDisabled}
        />
    </Grid>
  );
};

export default FormSwitch;
