import { Box, Button, Grid, Modal, Paper, Typography } from "@mui/material";
import React from "react";
import {
  selectDeleteDocumentLoading,
  selectDocumentCurrentTabView,
  selectDocumentTemplateSearchValue,
  selectSelectedDocumentTemplate,
  selectShowAllFacilitiesInDocumentLibrary,
  selectShowDeleteDocumentModal,
  setShowDeleteDocumentModal
}
  from "src/store/reducers/documentsSlice/documentsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Automation } from "src/models/Automation";
import { InsurancePlan } from "src/models/InsurancePlan";
import { LoadingButton } from "@mui/lab";
import { ProductType } from "src/models/ProductType";
import { deleteDocumentTemplate } from "src/store/thunks/documentTemplates/delete/deleteDocumentTemplate";
import useStyles from "./RemoveDocumentModal.styles";
import {
  getAllPaginatedDocumentTemplates
} from "src/store/thunks/documentTemplates/getAll/getAllPaginatedDocumentTemplates";

export const getNamesFromAttribute = (attribute?: InsurancePlan[] | Automation[] | ProductType[]): string => {
  const usedBy: string[] = [];
  if (attribute) {
    attribute?.map((item) => {
      return usedBy.push(item.name);
    });
  }
  return usedBy.length > 0 ? usedBy.join(", ") : "";
};

const RemoveDocumentModal = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const showDeleteDocumentModal = useAppSelector(selectShowDeleteDocumentModal);
  const deleteDocumentLoading = useAppSelector(selectDeleteDocumentLoading);
  const selectedDocumentTemplate = useAppSelector(selectSelectedDocumentTemplate);
  const searchValue = useAppSelector(selectDocumentTemplateSearchValue);
  const documentCurrentTabView = useAppSelector(selectDocumentCurrentTabView);
  const showAllFacilities = useAppSelector(selectShowAllFacilitiesInDocumentLibrary);
  const productTypes = getNamesFromAttribute(selectedDocumentTemplate?.product_types);
  const systemAutomations = getNamesFromAttribute(selectedDocumentTemplate?.system_automations);
  const insurancePlans = getNamesFromAttribute(selectedDocumentTemplate?.tpp_plans);
  const removable =
    (productTypes.length === 0 && systemAutomations.length === 0 && insurancePlans.length === 0);

  const onModalClose = () => {
    dispatch(setShowDeleteDocumentModal(false));
  };

  const onSubmit = () => {
    dispatch(deleteDocumentTemplate(selectedDocumentTemplate?.id!)).then((resp) => {
      if (!resp.type.includes("rejected")) {
        onModalClose();
        dispatch(getAllPaginatedDocumentTemplates({
          showAllFacilities,
          searchValue,
          type: documentCurrentTabView
        }));
      }
    });
  };

  return (
    <Modal
      open={showDeleteDocumentModal}
      onClose={onModalClose}
      aria-labelledby={"Modal Component"}
      aria-describedby={"A Modal component."}
      className={classes.modal}
      data-testid={"modal-move-in-confirmation"}
    >
      <Grid
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        spacing={2}
        className={classes.container}
        pb={2}
      >
        <Box>
          <Typography variant={"h5"} className={classes.title}>
            Confirm Document Removal
          </Typography>

          {productTypes &&
            (
              <Typography className={classes.text} mt={2}>
                This document is currently being used by the following product types:
                <br />
                <span className={classes.bold}>
                  {productTypes}
                </span>
              </Typography>
            )}

          {systemAutomations && (
            <Typography className={classes.text} mt={2}>
              This document is currently being used by the following system automations:
              <br />
              <span className={classes.bold}>
                {systemAutomations}
              </span>
            </Typography>
          )}

          {insurancePlans && (
            <Typography className={classes.text} mt={2}>
              This document is currently being used by the following insurance plans:
              <br />
              <span className={classes.bold}>
                {insurancePlans}
              </span>
            </Typography>
          )}

          {removable && (
            <Typography className={classes.text} pt={1}>
              Are you sure you want to remove this document?
            </Typography>
          )}

          {!removable && (
            <Typography className={classes.text} pt={1}>
              This document can not be deleted
            </Typography>
          )}

          <Grid pt={4} spacing={4} container
            justifyContent={"center"}>
            <Grid item>
              <Button
                data-testid={"cancel-button"}
                className={classes.cancelButton}
                onClick={onModalClose}
                variant={"outlined"}
                color={"secondary"}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                data-testid={"confirm-button-modal"}
                variant={"outlined"}
                className={classes.button}
                loading={deleteDocumentLoading}
                onClick={onSubmit}
                disabled={!removable}
              >
                Confirm
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Modal>
  );
};

export default RemoveDocumentModal;
