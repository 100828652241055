import { makeStyles } from "../../../../../makeStyles";

const useStyles = makeStyles({ label: "RateAndAccessDetails" })((theme) => ({
  calculatorButtonWrapper: {
    alignSelf: "end",
    flexGrow: 1
  },
  calculatorButton: {
    textTransform: "capitalize",
    background: theme.palette.primary.contrastText,
    color: theme.palette.common.white
  },
  headerCell: {
    color: theme.palette.common.black,
    background: theme.palette.background.default,
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1)
  },
  noArrow: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    },
    "input[type=number]": {
      MozAppearance: "textfield"
    }
  },
  cellWrapper: {
    flexGrow: 1
  },
  containerCheckbox: {
    height: "56px"
  }
}));

export default useStyles;
