import { Button, Grid, Typography, TextField } from "@mui/material";
import React, { ReactElement, useState, ChangeEvent, KeyboardEvent } from "react";
import useStyles from "./Heading.styles";
import { useAppSelector } from "src/store/hooks";
import { selectProductTypeName } from "src/store/reducers/productTypeSlice/productTypeSlice";
import clsx from "clsx";

interface HeadingDisplayProps {
  formikProductTypeName: string;
  onChange: Function,
  onSubmit: Function,
  error: boolean,
  helperText: string
};

const HeadingDisplay: React.FC<HeadingDisplayProps> = ({
  formikProductTypeName,
  onChange,
  onSubmit,
  error,
  helperText
}): ReactElement => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const { classes } = useStyles();
  const productTypeName = useAppSelector(selectProductTypeName);

  const subtitle = <Typography variant={"h4"} component={"h2"}>{productTypeName}</Typography>;

  const handleClick = () => {
    setEditMode(!editMode);
    if (editMode) {
      if (error) return;
      onSubmit(formikProductTypeName);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e?.target?.value);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    const key = e.key.toLowerCase();
    const escape = key === "escape";
    const enter = key === "enter";

    if (enter) {
      if (error) return;
      onSubmit(formikProductTypeName);
    }
    if (escape || enter) {
      onChange(productTypeName);
      setEditMode(false);
    }
  };

  const editSubtitle = (
    <TextField
      id={"edit-product-type-name"}
      variant={"outlined"}
      name={"editProductTypeName"}
      // eslint-disable-next-line
      autoFocus
      value={formikProductTypeName}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      error={error}
      helperText={helperText}
    />
  );

  return (
    <Grid
      item
      container
      alignItems={editMode ? "start" : "center"}
      mb={2}
    >
      { editMode ? editSubtitle : subtitle }
      <Grid ml={2}>
        <Button
          className={clsx(classes.button, editMode && classes.saveButton)}
          type={"button"}
          onClick={handleClick}
          variant={"contained"}
        >
          { editMode ? "Save" : "Edit name" }
        </Button>
      </Grid>
    </Grid>
  );
};

export default HeadingDisplay;
