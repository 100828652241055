import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridFeatureMode,
  GridRowId,
  GridRowParams,
  GridSelectionModel
} from "@mui/x-data-grid";
import React, { JSXElementConstructor, ReactElement, ReactNode } from "react";
import useStyles from "./PMSTable.styles";
import { PMSPagination } from "src/hooks/usePagination";
import clsx from "clsx";

const rowsPerPageOptions = [5, 10, 25, 50];

interface PMSTableProps {
  identifier?: string;
  checkboxSelection?: boolean;
  stopPropagation?: boolean;
  selectionModel?: GridRowId[];
  // eslint-disable-next-line max-len
  onSelectionModelChange?: ((selectionModel: GridSelectionModel, details: GridCallbackDetails) => void) | undefined;
  columns: GridColDef[];
  rows: any[];
  emptyTableDisplay: JSXElementConstructor<any>;
  onPaginationUpdate?: () => void;
  loading?: boolean;
  onRowClick?: (rowData: GridRowParams) => void;
  sortingMode?: GridFeatureMode;
  hideSelectAllCheckbox?: boolean;
  additionalRowsPerPageOptions?: number[];
  hideFooter?: boolean;
  hidePagination?: boolean;
  total?: number;
  pagination?: PMSPagination;
  noMargin?: boolean;
}

const NewPMSTable: React.FC<PMSTableProps> = ({
  columns,
  checkboxSelection = false,
  stopPropagation = false,
  selectionModel,
  onSelectionModelChange = () => {},
  identifier,
  rows,
  emptyTableDisplay,
  loading,
  onRowClick,
  sortingMode,
  hideSelectAllCheckbox,
  additionalRowsPerPageOptions,
  hideFooter,
  hidePagination = false,
  pagination,
  total,
  noMargin
}): ReactElement => {
  const { classes } = useStyles();

  const handleRowClick = (rowData: GridRowParams) => {
    if (onRowClick) {
      onRowClick(rowData);
    }
  };

  const idString = identifier ? `${identifier}` : "pms-table";

  const handleRowsPerPageOptions = (rowsPerPageOptions: number[], additionalRowsPerPage?: number[]) => {
    if (!additionalRowsPerPage) {
      return rowsPerPageOptions;
    }

    return [...rowsPerPageOptions, ...additionalRowsPerPage];
  };

  return (
    <DataGrid
      checkboxSelection={checkboxSelection}
      selectionModel={selectionModel}
      columnBuffer={columns.length}
      className={clsx(classes.root, {
        [classes.hideSelectAllCheckbox]: hideSelectAllCheckbox,
        [classes.marginTop]: !noMargin
      })}
      columns={columns}
      rows={rows}
      onCellKeyDown={stopPropagation ? (params, events) => events.stopPropagation() : undefined}
      autoHeight
      disableSelectionOnClick
      data-testid={"pms-table"}
      paginationMode={"server"}
      sortingMode={sortingMode ?? "server"}
      loading={loading}
      page={pagination?.values.page}
      rowCount={total}
      hideFooter={hideFooter}
      onSelectionModelChange={onSelectionModelChange}
      rowsPerPageOptions={handleRowsPerPageOptions(rowsPerPageOptions, additionalRowsPerPageOptions)}
      sortModel={pagination?.values.sortModel}
      pageSize={pagination?.values.pageSize}
      onPageChange={pagination?.handlePageChange}
      onPageSizeChange={pagination?.handlePageSizeChange}
      onRowClick={handleRowClick}
      onSortModelChange={pagination?.handleSortModelChange}
      componentsProps={{ header: { "data-testid": `${idString}-header` }, row: { "data-testid": `${idString}-row` } }}
      components={{
        NoResultsOverlay: emptyTableDisplay,
        NoRowsOverlay: emptyTableDisplay
      }}
      hideFooterPagination={hidePagination}
    />
  );
};

export default NewPMSTable;
