import moment from "moment/moment";
import { UnitPaymentStatusEnum } from "../enums/UnitPaymentStatusEnum";

// eslint-disable-next-line max-len
export const getUnitPaymentStatus = (startDate: string | undefined, currentBalance: number|null, delinquentDays: number|null): string => {
  if (startDate && moment(startDate).isAfter(moment())) {
    return UnitPaymentStatusEnum.FUTURE;
  }

  if (currentBalance && currentBalance > 0) {
    return delinquentDays === 1 ? UnitPaymentStatusEnum.DUE : UnitPaymentStatusEnum.PAST_DUE;
  }
  return UnitPaymentStatusEnum.CURRENT;
};
