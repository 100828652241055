import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton, InputAdornment,
  InputLabel,
  Paper,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import React, { ChangeEvent, FormEvent, ReactElement, useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  useCreateWebhookMutation,
  useGetWebhookQuery,
  useGetWebhookTriggerEventsQuery,
  useUpdateWebhookMutation
} from "src/api/endpoints/webhooks";
import PMSCheckbox from "src/components/ui/PMSCheckbox/PMSCheckbox";
import HeadersFormSection from "./components/AddHeaders/AddHeaders";
import { LoadingButton } from "@mui/lab";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import clsx from "clsx";
import createWebhookValidation from "./webhookFormValidation";
import { inputError } from "src/utils/showInputError/showInputError";
import {
  selectWebhookLoading
} from "src/store/reducers/webhooks/webhookSlice";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { useFormik } from "formik";
import useStyles from "./WebhookForm.styles";

interface WebhookFormProps {
  isEdit?: boolean;
}

export interface Header {
  key: string;
  value: string;
}

export interface FormValues {
  name: string;
  description: string;
  isEnabled: boolean;
  receivingUrl: string;
  eventTrigger: string;
  authType: number;
  authUsername: string;
  authPassword: string;
  headers: Header[];
  authToken: string;
}

const WebhookForm: React.FC<WebhookFormProps> = ({ isEdit = false }): ReactElement => {
  const theme = useTheme();
  const loading = useAppSelector(selectWebhookLoading);
  const params = useParams<{ id?: string }>();
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();
  const { data: triggers, isLoading, isFetching } = useGetWebhookTriggerEventsQuery();
  const { data: webhook, isLoading: webhookLoading, isFetching: webhookFetching } = useGetWebhookQuery(params?.id ?? "",
    { skip: !params.id && !isEdit });
  const [createWebhook] = useCreateWebhookMutation();
  const [updateWebhook] = useUpdateWebhookMutation();
  const { classes } = useStyles();
  const formik = useFormik<FormValues>({
    initialValues: {
      name: webhook?.name || "",
      description: webhook?.description || "",
      isEnabled: webhook?.is_enabled || false,
      receivingUrl: webhook?.url || "",
      eventTrigger: webhook?.trigger || "",
      authType: webhook?.auth_type || 0,
      authUsername: webhook?.auth_username || "",
      authPassword: webhook?.auth_password || "",
      headers: (webhook && webhook.headers.length > 0) ? webhook.headers : [{ key: "", value: "" }],
      authToken: webhook?.auth_token || ""
    },
    validationSchema: createWebhookValidation,
    validateOnChange: false,
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: (values) => {
      if (isEdit && params.id) {
        updateWebhook({ id: params.id, payload: values }).then((data) => {
          if ("error" in data) {
            return dispatch(showSnackbar({ message: "Error editing Webhook", variant: "error" }));
          }

          if ("data" in data) {
            dispatch(showSnackbar({ message: "Webhook edited successfully", variant: "success" }));
            navigate("/webhooks");
            formik.resetForm();
          }
        });
        return;
      }

      createWebhook(values).then((data) => {
        // @ts-ignore
        if ("error" in data) {
          return dispatch(showSnackbar({ message: "Error creating Webhook", variant: "error" }));
        }

        if ("data" in data) {
          dispatch(showSnackbar({ message: "Webhook created successfully", variant: "success" }));
          navigate("/webhooks");
          formik.resetForm();
        }
      });
    }
  });

  const { values, errors, touched, setValues } = formik;

  const handleShowPasswordOnchange = () => setShowPassword(!showPassword);

  const toggleVisibilityButton = (
    <InputAdornment position={"end"}>
      <IconButton
        aria-label={"toggle password"}
        data-testid={"toggle-password-button"}
        onClick={() => handleShowPasswordOnchange()}
      >
        {!showPassword
          ? <Visibility style={{ color: theme.palette.primary.main }} data-testid={"on-icon"} />
          : <VisibilityOff style={{ color: theme.palette.primary.main }} data-testid={"off-icon"} />
        }
      </IconButton>
    </InputAdornment>
  );

  const updateForm = (fieldName: string, fieldValue?: string | number | boolean | null | []): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleInputOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
  };

  const handleEventTriggerOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
  };

  const handleAuthTypeOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
  };

  const addCustomizeKey = () => {
    const headers = [...values.headers];
    headers.push({
      key: "",
      value: ""
    });

    setValues({
      ...values,
      headers
    });
  };

  const removeCustomizeKey = (index: number) => {
    const headers = [...values.headers];
    headers.splice(index, 1);
    setValues({
      ...values,
      headers
    });
  };

  useEffect(() => {
    switch (values.authType) {
      // @ts-ignore
      case "0":
        setValues({
          ...values,
          authUsername: "",
          authPassword: "",
          authToken: ""
        });
        break;
        // @ts-ignore
      case "1":
        setValues({
          ...values,
          authToken: ""
        });
        break;
        // @ts-ignore
      case "2":
        setValues({
          ...values,
          authUsername: "",
          authPassword: ""
        });
        break;
    }
  }, [values.authType]);

  return (
    <Grid
      container
      xs={12}
      flexDirection={"column"}
      id={"webhook-form"}
      data-testid={"webhook-form"}
      component={"form"}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        formik.handleSubmit();
      }}
      mt={3}
    >
      <Grid
        container
        component={Paper}
        xs={12}
        p={3}
        justifyContent={"space-between"}
      >
        <Grid
          container
          item
          direction={"column"}
          spacing={2}
          lg={6}
          xs={12}
        >
          <Grid item container p={2}>
            <Box className={classes.header}>
              <Typography variant={"h6"}>Create Webhook</Typography>
            </Box>
            <Grid item xs={12}>
              <InputLabel htmlFor={"webhook-name"}>Name</InputLabel>
              <TextField
                id={"name"}
                fullWidth
                placeholder={"- Name -"}
                inputProps={{ "data-testid": "webhook-name" }}
                name={"name"}
                value={values.name}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleInputOnChange("name", e.target.value)
                }
                error={inputError("name", touched, errors).error}
                helperText={inputError("name", touched, errors).helperText}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor={"webhook-description"}>Description</InputLabel>
              <TextField
                id={"description"}
                fullWidth
                multiline
                rows={5}
                inputProps={{
                  "data-testid": "webhook-description",
                  maxLength: 500
                }}
                name={"description"}
                value={values.description}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleInputOnChange("description", e.target.value)
                }
                error={inputError("description", touched, errors).error}
                helperText={inputError("description", touched, errors).helperText}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor={"webhook-url"}>Receiving URL</InputLabel>
              <TextField
                id={"receivingUrl"}
                fullWidth
                placeholder={"- url -"}
                inputProps={{ "data-testid": "webhook-url" }}
                name={"receivingUrl"}
                value={values.receivingUrl}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleInputOnChange("receivingUrl", e.target.value)
                }
                error={inputError("receivingUrl", touched, errors).error}
                helperText={inputError("receivingUrl", touched, errors).helperText}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12} md={6}
              mb={1}
            >
              <PMSSelect
                id={"eventTrigger"}
                label={"Event Trigger"}
                name={"eventTrigger"}
                value={values.eventTrigger}
                defaultValue={""}
                changeHandler={(e) => handleEventTriggerOnChange("eventTrigger", e.target.value as string)}
                error={inputError("eventTrigger", touched, errors).error}
                helperText={inputError("eventTrigger", touched, errors).helperText}
              >
                <option value={""} disabled>
                  - Select Event Trigger -
                </option>
                {triggers?.map((trigger, index) => <option key={index + 1} value={trigger}>{trigger}</option>)}

              </PMSSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <PMSSelect
                id={"authType"}
                label={"Authentication"}
                name={"authType"}
                value={values.authType}
                changeHandler={(e) => handleAuthTypeOnChange("authType", e.target.value as string)}
                error={inputError("authType", touched, errors).error}
                helperText={inputError("authType", touched, errors).helperText}
              >
                <option value={0}>None</option>
                <option value={1}>Basic Auth</option>
                <option value={2}>API Key</option>
              </PMSSelect>
            </Grid>
            {/* eslint-disable-next-line eqeqeq */}
            {values.authType == 1 &&
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <InputLabel htmlFor={"authentication-name"}>Authentication Username</InputLabel>
                <TextField
                  id={"authentication-name"}
                  fullWidth
                  placeholder={"- Username -"}
                  inputProps={{ "data-testid": "authentication-name" }}
                  name={"authUsername"}
                  value={values.authUsername}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInputOnChange("authUsername", e.target.value)
                    }
                  error={inputError("authUsername", touched, errors).error}
                  helperText={inputError("authUsername", touched, errors).helperText}
                  />
              </Grid>
              <Grid item xs={6}>
                <InputLabel htmlFor={"authentication-password"}>Authentication Password</InputLabel>
                <TextField
                  type={showPassword ? "text" : "password"}
                  id={"authentication-password"}
                  fullWidth
                  placeholder={"- Password -"}
                  inputProps={{ "data-testid": "authentication-password" }}
                  name={"authPassword"}
                  value={values.authPassword}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInputOnChange("authPassword", e.target.value)
                    }
                  error={inputError("authPassword", touched, errors).error}
                  helperText={inputError("authPassword", touched, errors).helperText}
                  InputProps={{ endAdornment: toggleVisibilityButton }}
                  />
              </Grid>
            </Grid>
            }
            {/* eslint-disable-next-line eqeqeq */}
            {values.authType == 2 &&
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <InputLabel htmlFor={"authentication-name"}>API Key</InputLabel>
                <TextField
                  id={"authentication-token"}
                  fullWidth
                  placeholder={"- API Key -"}
                  inputProps={{ "data-testid": "authentication-token" }}
                  name={"authToken"}
                  value={values.authToken}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInputOnChange("authToken", e.target.value)
                    }
                  error={inputError("authToken", touched, errors).error}
                  helperText={inputError("authToken", touched, errors).helperText}
                  />
              </Grid>
            </Grid>
                }
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <PMSCheckbox
                  smallText
                  data-testid={"enable-webhook"}
                  name={"isEnabled"}
                  changeHandler={(e) => handleInputOnChange("isEnabled", e.target.checked)}
                  isChecked={values.isEnabled}
                  label={"Enable Webhook"}
                  tooltipText={
                    isEdit
                      ? "If checked, the webhook payload will be sent to the Receiving URL."
                      : "If checked, the webhook payload will start being sent to the Receiving URL."
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item alignItems={"center"} lg={6}
          xs={12}>
          <Grid item>
            <Typography variant={"h6"} pb={1}>
              Custom Headers
            </Typography>
          </Grid>
          <Grid item style={{ flexGrow: 1 }}>
            <Divider style={{ marginBottom: "15px" }} />
          </Grid>
          <HeadersFormSection
            values={values}
            handleInputOnChange={handleInputOnChange}
            addCustomizeKey={addCustomizeKey}
            removeCustomizeKey={removeCustomizeKey}
            inputError={inputError}
            formik={formik}
          />
        </Grid>
      </Grid>
      {/* // Footer */}
      <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
        <Button
          variant={"text"}
          className={clsx(classes.cancelButton, classes.buttonBase)}
          size={"large"}
          component={NavLink}
          to={"/webhooks"}
        >
          Cancel
        </Button>
        <LoadingButton
          form={"webhook-form"}
          variant={"contained"}
          className={clsx(classes.saveButton, classes.buttonBase)}
          size={"large"}
          type={"submit"}
          loading={loading || webhookLoading || webhookFetching || isLoading || isFetching}
          disabled={loading}
        >
          Save
        </LoadingButton>
      </Box>
    </Grid>
  );
};
export default WebhookForm;
