import { Facility } from "../models/Facility";

export const allFacilitiesValue = 9999;

export const allFacilitiesFakeFacility: Facility = {
  id: allFacilitiesValue,
  operator_id: 0,
  legacy_id: "",
  facility_id: "All",
  name: "All",
  manager: "",
  district: "",
  access_timezone: "",
  end_of_days_timezone: "",
  district_region: "",
  area: "",
  legal_name: "",
  email: "",
  phone: "",
  fax: "",
  timezone: "",
  address: "",
  address_two: "",
  city: "",
  region: "",
  postal_code: "",
  country: "",
  tax_id: "",
  latitude: 0,
  longitude: 0,
  is_enabled: false,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  disposition_date: "",
  reservation_date_limit: 0,
  keypad_accesses: [],
  time_accesses: [],
  sip_username: null,
  sip_password: null,
  sip_host: null,
  sip_port: null
};
