import { FormControl, FormGroup, Grid, InputLabel, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import {
  addTransferUnitMerchandise,
  removeTransferUnitMerchandise,
  selectIsTransfer,
  selectTransferUnitMerchandise,
  setMerchandiseQuantityForTransferUnit
}
  from "src/store/reducers/transferUnitSlice/transferUnitSlice";
import {
  selectMerchandiseItems,
  setMerchandiseQuantity
} from "../../../../../store/reducers/merchandiseItemsSlice/merchandiseItemsSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import ItemSelector from "../ItemSelector/ItemSelector";
import ItemSelectorIncrementor from "../ItemSelector/ItemSelectorIncrementor/ItemSelectorIncrementor";
import { Merchandise } from "../../../../../models/Merchandise";
import { MoveInMerchandise } from "../../../../../models/MoveInMerchandise";
import { getMoveInCost } from "../../../../../store/thunks/moveIn/getMoveInCost/getMoveInCost";
import { showSnackbar } from "../../../../../store/reducers/snackbarSlice/snackbarSlice";
import useStyles from "./MerchandiseList.styles";
import { getTransferUnitCost } from "src/store/thunks/transfer/transferCost/getTransferUnitCost";

const MerchandiseList: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const availableMerchandise = useAppSelector(selectMerchandiseItems);
  const merchandiseInMoveIn = useAppSelector(selectTransferUnitMerchandise);
  const isTransfer = useAppSelector(selectIsTransfer);

  const updateSummary = () => {
    if (isTransfer) {
      return dispatch(getTransferUnitCost());
    }

    dispatch(getMoveInCost());
  };

  const isChecked = (id: number) => !!merchandiseInMoveIn.find((merchandiseItem) => merchandiseItem.id === id);

  const removeMerchandise = (merchandiseId: number) => {
    if (isTransfer) {
      dispatch(setMerchandiseQuantityForTransferUnit({ merchId: merchandiseId, merchQuantity: 1 }));
      dispatch(removeTransferUnitMerchandise(merchandiseId));
      return;
    }
    dispatch(setMerchandiseQuantity({ merchId: merchandiseId, merchQuantity: 1 }));
    dispatch(removeTransferUnitMerchandise(merchandiseId));
    updateSummary();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const merchId = parseInt(event.target.value);

    if (isChecked(merchId)) {
      removeMerchandise(merchId);
    } else {
      const merchandiseInfo = availableMerchandise.find((merchandiseItem) => merchandiseItem.id === merchId)!;
      const updatedMerch: MoveInMerchandise = {
        id: merchId,
        name: merchandiseInfo.name,
        cost: merchandiseInfo.price,
        is_required: false,
        quantity: 1
      };

      if (isTransfer) {
        dispatch(addTransferUnitMerchandise(updatedMerch));
      } else {
        dispatch(addTransferUnitMerchandise(updatedMerch));
      }
    }

    updateSummary();
  };

  const handleQuantityChange = (selectedQuantity: number, item: Merchandise) => {
    if (selectedQuantity < 1) {
      return;
    }

    if (selectedQuantity > item.quantity) {
      dispatch(
        showSnackbar({
          message: `Maximum available quantity: ${item.quantity}`,
          variant: "error"
        })
      );

      return;
    }

    const merchandiseInfo = availableMerchandise.find((merchandiseItem) => merchandiseItem.id === item.id)!;

    dispatch(setMerchandiseQuantity({ merchId: merchandiseInfo.id, merchQuantity: selectedQuantity }));
    dispatch(setMerchandiseQuantityForTransferUnit({ merchId: merchandiseInfo.id, merchQuantity: selectedQuantity }));

    updateSummary();
  };

  const merchandiseList = availableMerchandise.map((merchandiseItem) => (
    <ItemSelector
      key={merchandiseItem.id}
      item={merchandiseItem}
      isChecked={isChecked(merchandiseItem.id)}
      itemLabel={merchandiseItem.name}
      itemPrice={`$${merchandiseItem.price}`}
      changeHandler={handleChange}
      removeItem={removeMerchandise}
      incrementor={
        <ItemSelectorIncrementor
          item={merchandiseItem}
          onManualQuantityChange={handleQuantityChange}
          onIncrement={() => handleQuantityChange(merchandiseItem.quantity_for_cart! + 1, merchandiseItem)}
          onDecrement={() => handleQuantityChange(merchandiseItem.quantity_for_cart! - 1, merchandiseItem)}
        />
      }
    />
  ));

  return (
    <>
      <InputLabel className={classes.headerCell} htmlFor={"retail-merchandise"}>
        <Typography noWrap>Retail Merchandise</Typography>
      </InputLabel>
      <FormControl className={classes.merchandiseListWrapper} component={"fieldset"} variant={"standard"}>
        <FormGroup data-testid={"merchandise-display"}>
          {availableMerchandise.length
            ? (
                merchandiseList
              )
            : (
              <Typography>No merchandise available at this facility</Typography>
              )}
        </FormGroup>
      </FormControl>
    </>
  );
};

export default MerchandiseList;
