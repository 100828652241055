import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  cashReceivedAmount: Yup.string().test({
    name: "cashReceivedAmount",
    test: function(value) {
      if (this.parent?.takePartialPayment && parseFloat(this.parent?.amountDue) > 0 && !value) {
        return true;
      }

      if (parseFloat(this.parent?.amountDue) > 0 && !value) {
        return this.createError({ message: "Cash received is required" });
      }

      return true;
    }
  })

});

export default validationSchema;
