import { GetMoveInCostPayload } from "./GetMoveInCostPayload";
import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getMoveInCost = createAsyncThunk(
  "getMoveInCost",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const facilityId = state.selectedFacility.selectedFacility?.id;
    const unitId = state.selectedUnit.selectedUnit!.id;
    const leaseStartDate = state.moveIn.leaseStartsOn;
    const monthlyRate = state.moveIn.monthlyRate;
    const periods = state.moveIn.periods;
    const promotionId = state.selectedPromotion.selectedPromotion?.id;
    const moveInFees = state.moveIn.fees;
    const moveInPlan = state.moveIn.insurancePlanId;
    const merchandise = state.moveIn.merchandise;
    const dealId = state.deal.deal?.id ?? null;
    const promoDefermentStatus = state.moveIn.promoDefermentStatus;
    const waiveFee = state.moveIn.waiveFee.waiveFeeAmount;

    const payload: GetMoveInCostPayload = {
      unit_id: unitId,
      lease_start_date: leaseStartDate,
      monthly_rate: +monthlyRate,
      periods: periods as number,
      fees: moveInFees,
      merchandise: merchandise,
      tpp_plan_id: moveInPlan ?? 0,
      promotion_id: promotionId as number,
      deal_id: dealId,
      is_promo_deferred: promoDefermentStatus,
      waive_fee: waiveFee,
      occupant_is_tax_exempt: state.selectedOccupant?.selectedOccupant?.is_tax_exempt
    };

    return axios.post(`/api/v1/facilities/${facilityId}/move-in-cost`, payload)
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        let errorMessage = "There was an error getting the Move-In Cost";

        if (err &&
          err.response &&
          err.response.status &&
          err.response.status === 422 &&
          err.response.data.errors &&
          Object.keys(err.response.data.errors).length
        ) {
          errorMessage = format422(err.response.data.errors);
        } else if (err && err.response && err.response.data && err.response.data.detail) {
          errorMessage += `: ${err.response.data.detail}`;
        }

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
