import { axiosInstance as axios } from "../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";

export const downloadOccupantDocument = createAsyncThunk("downloadOccupantDocument", (
  { occupantId, documentId }: { occupantId: number; documentId?: number }, { dispatch, getState, rejectWithValue }
) => {
  return axios
    .get(`/api/v1/occupants/${occupantId}/uploaded-documents/${documentId}/download`, {
      responseType: "blob",
      headers: {
        Accept: "application/pdf"
      }
    })
    .then((resp) => {
      return resp.data;
    })
    .catch(() => {
      const errorMessage = "There was an error fetching occupant documents";

      dispatch(
        showSnackbar({
          message: errorMessage,
          variant: "error"
        })
      );

      return rejectWithValue(errorMessage);
    });
}
);
