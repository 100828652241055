import { useCallback } from "react";
import { Facility } from "src/models/Facility";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectStatus, setStatus } from "src/store/reducers/appSoftphoneSlice/appSoftphoneSlice";
import { SoftphoneStatus } from "src/store/reducers/appSoftphoneSlice/appSoftphoneSliceState";
import { AppDispatch } from "src/store/rootStore";

const softphone = window.webphone_api;

export const handleFacilityChange = (selectedFacility: Facility | null) => {
  if (!selectedFacility) {
    return;
  }

  if (!selectedFacility.sip_host || !selectedFacility.sip_username || !selectedFacility.sip_password) {
    softphone.stop();
  }

  softphone.stop();
  softphone.setparameter("serveraddress", selectedFacility.sip_host);
  softphone.setparameter("username", selectedFacility.sip_username);
  softphone.setparameter("password", selectedFacility.sip_password);
  softphone.start();
};

const handleRegisterListeners = (dispatch: AppDispatch, selectedFacility: Facility | null) => {
  softphone.onAppStateChange(function(state: string) {
    switch (state) {
      case "loaded":
        handleFacilityChange(selectedFacility);
        break;
      case "stopped":
        dispatch(setStatus(SoftphoneStatus.Stopped));
        break;
    }
  });

  softphone.onRegStateChange(function(state: string) {
    switch (state) {
      case "registered":
        dispatch(setStatus(SoftphoneStatus.Ready));
        break;
      case "unregistered":
        dispatch(setStatus(SoftphoneStatus.Stopped));
        break;
      case "failed":
        dispatch(setStatus(SoftphoneStatus.Stopped));
        break;
    }
  });

  softphone.onCallStateChange(function(state: string) {
    switch (state) {
      case "setup":
      case "ringing":
        dispatch(setStatus(SoftphoneStatus.Calling));
        break;
      case "connected":
        dispatch(setStatus(SoftphoneStatus.Connected));
        break;
      case "disconnected":
        dispatch(setStatus(SoftphoneStatus.Ready));
        break;
    }
  });
};

export const useSoftphone = () => {
  const state = useAppSelector(selectStatus);
  const call = useCallback(
    (phoneNumber: string) => {
      if (state === SoftphoneStatus.Ready) {
        softphone.call(phoneNumber);
      }
    },
    [softphone, state]
  );

  const endCall = useCallback(() => {
    softphone.hangup();
    softphone.reject();
  }, [softphone]);

  return {
    call,
    endCall,
    handleRegisterListeners,
    handleFacilityChange
  };
};
