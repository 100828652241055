import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { NavLink } from "react-router-dom";
import React from "react";

type RenderAddAndRemoveButtonProps = {
    addCustomizeKey: () => void;
    removeCustomizeKey: (index: number) => void;
    index: number;
    disableRemoveButton: boolean;
};

const RenderAddAndRemoveButton = (
  { addCustomizeKey, removeCustomizeKey, index, disableRemoveButton }: RenderAddAndRemoveButtonProps
) => {
  const removeButton = () => removeCustomizeKey(index);
  return (
    <Grid
      container
      item
      pt={3}
      direction={"row"}
      alignItems={"center"}
      justifyContent={"center"}
      style={{ height: "100%" }}
        >
      <Grid item xs={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        {/* No Tooltip for simplicity, add if necessary */}
        <Tooltip title={"Remove Custom Key"} placement={"top"}>
          <IconButton
            size={"small"}
            data-testid={"cancel-deal-button"}
            disabled={disableRemoveButton}
            onClick={removeButton}
                    >
            <RemoveCircle />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Tooltip title={"Add Custom Key"} placement={"top"}>
          <IconButton
            component={NavLink}
            to={"#"}
            size={"small"}
            onClick={addCustomizeKey}
        >
            <AddCircle />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>

  );
};

export default RenderAddAndRemoveButton;
