import React, { ReactElement } from "react";
import GetStarted from "../../../../components/stepper/GetStarted/GetStarted";

const GetAddOccupantStarted: React.FC = (): ReactElement => {
  const advisoryText = (
    <>
      <p>
        Add your occupants to your system. You will be able to mark them as personal or business accounts, as well as
        convert one to the other.
      </p>
      <p>
        Occupants are able to be added to units across facilities with a single account.
      </p>
    </>
  );

  return (
    <GetStarted
      title={"Add a New Occupant"}
      advisoryText={advisoryText}
      nextStepPath={"/occupants/add-occupant/occupant-name-and-location"}
    />
  );
};

export default GetAddOccupantStarted;
