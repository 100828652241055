import GateSystems from "src/enums/GateSystems";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  facilityReceiptTemplate: Yup.string().nullable(),
  facilityInvoiceTemplate: Yup.string().nullable(),
  facilityAutobillTemplate: Yup.string().nullable(),
  emergencyGateOverride: Yup.string().test({
    name: "emergencyGateOverride",
    test: function(value) {
      if (parseInt(this.parent?.gateSystem) === GateSystems.PTI && value?.length! > 0) {
        if (!(value?.length! >= 4 && value?.length! <= 8)) {
          return this.createError({ message: "Emergency Gate Override must be between 4 and 8 digits" });
        }
      }

      if (parseInt(this.parent?.gateSystem) === GateSystems.DOOR_KING && value?.length! > 0) {
        if (!(value?.length! === 5)) {
          return this.createError({ message: "Emergency Gate Override must have 5 digits" });
        }
      }

      if (parseInt(this.parent?.gateSystem) === GateSystems.STORE_GUARD && value?.length! > 0) {
        if (!(value?.length! >= 5 && value?.length! <= 8)) {
          return this.createError({ message: "Emergency Gate Override must be between 4 and 8 digits" });
        }
      }

      return true;
    }
  })
});

export default validationSchema;
