import { Box, Grid, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import React, { useMemo } from "react";
import { Charge } from "src/store/reducers/creditsSlice/creditsSliceState";
import EmptyTable from "src/components/ui/PMSTable/EmptyTable/EmptyTable";
import Form from "src/components/form/Form";
import { Ledger } from "src/models/Ledger";
import PMSTable from "src/components/ui/PMSTable/PMSTable";
import PaymentStatusText from "../../../components/ui/PaymentStatus/PaymentStatusText";
import { UnitPaymentStatusEnum } from "src/enums/UnitPaymentStatusEnum";
import { formatDate } from "src/utils/__dateAndTimeUtils__/formatDate/formatDate";
import { formattedAmount } from "src/utils/formattedAmount/formattedAmount";
import { getUnitPaymentStatus } from "src/utils/getUnitPaymentStatus";
import moment from "moment";

interface IssueCreditChargesTableProps {
  charges: Charge[];
  ledger: Ledger | null
}

const emptyTable = () => <EmptyTable />;

const columns = (xs: boolean): GridColDef[] => [
  {
    field: "start_date",
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => (
      <Tooltip title={"Start Date"}>
        <Typography>Start Date</Typography>
      </Tooltip>
    ),
    valueGetter: ({ row }: GridRenderCellParams) => formatDate(moment.utc(row?.charge_date)),
    flex: 1,
    align: "center",
    headerAlign: "center"
  },
  {
    field: "end_date",
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => (
      <Tooltip title={"End Date"}>
        <Typography>End Date</Typography>
      </Tooltip>
    ),
    valueGetter: ({ row }: GridRenderCellParams) => formatDate(moment.utc(row?.charge_date).endOf("month")),
    flex: 1,
    align: "center",
    headerAlign: "center"
  },
  {
    field: "description",
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => (
      <Tooltip title={"Description"}>
        <Typography>Description</Typography>
      </Tooltip>
    ),
    flex: 2,
    align: "left",
    headerAlign: "left"
  },
  {
    field: "status",
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => (
      <Tooltip title={"Status"}>
        <Typography>Status</Typography>
      </Tooltip>
    ),
    renderCell: ({ row }: GridRenderCellParams) => {
      return (
        <PaymentStatusText status={row.status} />
      );
    },
    flex: 1,
    align: "center",
    headerAlign: "center"
  },
  {
    field: "applied_credit",
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => (
      <Tooltip title={`Input field to add dollar amount to apply credit/waive fee. • Cap amount at ledger item total`}>
        <Typography>Applied Credit*</Typography>
      </Tooltip>
    ),
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }: GridRenderCellParams) => {
      return (
        <Grid p={4}>
          <Form.Currency
            id={`${row?.id}`}
            name={`${row?.id}`}
            disabled={
              row.applied_credit && moment(row.charge_date).startOf("month").isSameOrBefore(moment().startOf("month"))
            }
            max={Number(row.pending_amount_before_tax_formatted)}
            placeholder={"00.00"}
          />
        </Grid>
      );
    },
    width: 190
  },
  {
    field: "charge_amount",
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => (
      <Tooltip title={"Charge"}>
        <Typography>Charge</Typography>
      </Tooltip>
    ),
    valueGetter: ({ row }: GridRenderCellParams) => formattedAmount("Fixed Amount", row?.charge_amount_formatted),
    flex: 1,
    align: "right",
    headerAlign: "right"
  },
  {
    field: "issued_credit_amount_formatted",
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => (
      <Tooltip title={"Applied Promotion Amount"}>
        <Typography>Applied Promotion</Typography>
      </Tooltip>
    ),
    valueGetter: ({ row }: GridRenderCellParams) =>
      formattedAmount("Fixed Amount", row?.issued_credit_amount_formatted),
    align: "right",
    headerAlign: "right",
    minWidth: xs ? 180 : 93,
    flex: xs ? 0 : 1
  },
  {
    field: "pending_amount",
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => (
      <Tooltip title={"Pending Amount"}>
        <Typography>Pending Total</Typography>
      </Tooltip>
    ),
    valueGetter: ({ row }: GridRenderCellParams) =>
      formattedAmount("Fixed Amount", row?.pending_amount_before_tax_formatted),
    align: "right",
    headerAlign: "right",
    flex: 1
  }
];

const IssueCreditChargesTable: React.FC<IssueCreditChargesTableProps> = ({ charges, ledger }) => {
  const xs = useMediaQuery("(min-width: 1300px)");
  const filteredCharges = useMemo(() => {
    return charges.map(charge => {
      const status = getUnitPaymentStatus(charge.charge_date, charge.pending_amount, ledger?.delinquent_days ?? null);
      return {
        ...charge,
        status
      };
    }).filter(ch => ch.status !== UnitPaymentStatusEnum.CURRENT);
  }, [charges]);
  return (
    <Box m={2}>
      <PMSTable columns={columns(xs)} rows={filteredCharges} emptyTableDisplay={emptyTable}
        hideFooter />
    </Box>
  );
};

export default IssueCreditChargesTable;
