import React from "react";
import { Route } from "react-router";
import DelinquentOccupants from "../pages/Collections/DelinquentOccupants/DelinquentOccupants";
import MakeAPayment from "../pages/Collections/MakeAPayment/MakeAPayment";
import SelectOccupantOrUnit from "../pages/Collections/MakeAPayment/SelectOccupantOrUnit/SelectOccupantOrUnit";
import ReviewAndApplyPayment from "../pages/Collections/MakeAPayment/ReviewAndApplyPayment/ReviewAndApplyPayment";
import PaymentConfirmation from "../pages/Collections/MakeAPayment/PaymentConfirmation/PaymentConfirmation";
import GetStarted from "src/components/stepper/GetStarted/GetStarted";
import MakeAPaymentGetStarted from "src/pages/Collections/MakeAPayment/GetStarted/MakeAPaymentGetStarted";
import ApplyFee from "src/pages/Collections/DelinquentOccupants/ApplyFee/ApplyFee";
import ManageAuctions from "src/pages/Collections/ManageAuctions/ManageAuctions";
import EditAuction from "src/pages/Collections/ManageAuctions/EditAuction";

export const groupName = "collections";

export const CollectionsRoutes = (
  <>
    <Route
      path={`${groupName}/make-a-payment`}
      element={
        <MakeAPayment>
          <MakeAPaymentGetStarted />
        </MakeAPayment>
      }
    />
    <Route
      path={`${groupName}/make-a-payment/select-occupant-or-unit`}
      element={
        <MakeAPayment>
          <SelectOccupantOrUnit />
        </MakeAPayment>
      }
    />
    <Route
      path={`${groupName}/make-a-payment/review-and-apply-payment`}
      element={
        <MakeAPayment>
          <ReviewAndApplyPayment />
        </MakeAPayment>
      }
    />
    <Route
      path={`${groupName}/make-a-payment/payment-confirmation`}
      element={
        <MakeAPayment>
          <PaymentConfirmation />
        </MakeAPayment>
      }
    />
    <Route path={`${groupName}/delinquent-occupants`} element={<DelinquentOccupants />} />
    <Route path={`${groupName}/delinquent-occupants`} element={<DelinquentOccupants />} />
    <Route path={`${groupName}/occupants/:occupantId/ledgers/:ledgerId/apply-fee`} element={<ApplyFee />} />
    <Route path={`${groupName}/manage-auctions`} element={<ManageAuctions />} />
    <Route path={`${groupName}/auctions/:auctionId`} element={<EditAuction />} />
  </>
);

export default CollectionsRoutes;
