import { RootState } from "src/store/rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSearchParams } from "src/utils/getSearchParams/getSearchParams";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getAllUnitsNew = createAsyncThunk(
  "getAllUnitsNew", ({ id, productTypeId }: {id: string, productTypeId?: string},
    { dispatch, getState, rejectWithValue }) => {
    const store = getState() as RootState;
    const searchValue = store.unitsNew.unitsSearchValue;

    const options = {
      params: {
        with: ["attributes", "notes"],
        ...getSearchParams(store, true)
      }
    };

    const filter = {
      "filter[product_type_id]": productTypeId,
      "filter[unit_number]": searchValue
    };

    if (productTypeId || searchValue) {
      options.params = { ...options.params, ...filter };
    }

    return axios.get(`/api/v1/facilities/${id}/units`, options)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = `There was an error getting the units for Facility ${id}`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
