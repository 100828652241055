import FacilityFormValues from "src/models/formikInputValues/FacilityFormValues";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const createFacility = createAsyncThunk("createFacility",
  (payload:FacilityFormValues, { dispatch, getState, rejectWithValue }) => {
    return axios.post("/api/v1/facilities", { ...payload, is_enabled: true })
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Facility created",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an error creating the Facility";

        const snackbarPayload = {
          message: errorMessage,
          variant: "error"
        };

        dispatch(showSnackbar(snackbarPayload));
        return rejectWithValue(snackbarPayload.message);
      });
  }
);
