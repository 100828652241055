import { Divider, Grid, Paper, Typography } from "@mui/material";
import React, { FC } from "react";
import { selectMerchandiseForRefund } from "src/store/reducers/merchandiseForRefundSlice/merchandiseForRefundSlice";
import { useAppSelector } from "src/store/hooks";

const MerchandiseTransactionInfo: FC = () => {
  const refundMerch = useAppSelector(selectMerchandiseForRefund)[0];

  return (
    <Paper
      component={Grid}
      container
      variant={"outlined"}
      direction={"column"}
      py={3}
      p={2}
      mt={2}
    >
      <Grid px={1} item xs={12}>
        <Typography gutterBottom variant={"h6"}>
          Original Transaction Information
        </Typography>

        <Grid px={1} pb={1} item
          xs={12}>
          <Divider />
          <Grid item xs={12}>
            <Typography>
              {refundMerch?.payment_method === 3 ? "Cardholder Name" : "Name"}:&nbsp;
              {refundMerch?.is_walk_in ? "Walk-In Purchase" : refundMerch?.customer_name}
            </Typography>

            <Typography>
              Payment Method: {refundMerch?.payment_method_name}
            </Typography>

            {refundMerch?.payment_method === 3 && (
              <Typography>
                {refundMerch?.payment_method_name} : {refundMerch?.credit_card}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MerchandiseTransactionInfo;
