import { RootState } from "src/store/rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSearchParams } from "src/utils/getSearchParams/getSearchParams";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getTransactions = createAsyncThunk(
  "getTransactions",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const facilityId = state.selectedFacility.selectedFacility.id;

    const searchParams = {
      params: {
        ...getSearchParams(state, true, "id", "desc")
      }
    };

    return axios.get(`/api/v1/facilities/${facilityId}/transactions?filter[originated_from]=4`, searchParams)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = `There was an error getting the transactions`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
