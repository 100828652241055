import CreateDocument from "src/pages/DocumentManagement/CreateDocument/CreateDocument";
import DocumentLibrary from "../pages/DocumentManagement/DocumentLibrary/DocumentLibrary";
import ESignStatus from "src/pages/DocumentManagement/ESignStatus/ESignStatus";
import EditDocument from "../pages/DocumentManagement/EditDocument/EditDocument";
import EsignDocument from "src/pages/DocumentManagement/ESign/EsignDocument";
import GetCreateNewDocumentStarted
  from "src/pages/DocumentManagement/GetCreateNewDocumentStarted/GetCreateNewDocumentStarted";
import React from "react";
import { Route } from "react-router";

export const groupName = "documents";

export const DocumentManagementRoutes = (
  <>
    <Route path={`${groupName}/document-library`} element={<DocumentLibrary />} />
    <Route path={`${groupName}/:id/edit`} element={<EditDocument />} />
    <Route path={`${groupName}/new-document`} element={<GetCreateNewDocumentStarted />} />
    <Route path={`${groupName}/new-document/create`} element={<CreateDocument />} />
    <Route path={`${groupName}/eSign-sign/:eSignId`} element={<EsignDocument />} />
    <Route path={`${groupName}/status`} element={<ESignStatus />} />
  </>
);

export default DocumentManagementRoutes;
