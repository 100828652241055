import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { APIListResponse } from "../../../models/responses/APIListResponse";
import { FeeCategoriesSliceState } from "./FeeCategoriesSliceState"; // every slice should have a state typing
import { FeeCategory } from "../../../models/FeeCategory";
import { RootState } from "../../rootStore";
import { getAllFeeCategories } from "../../thunks/feeCategory/getAll/getAllFeeCategories";

export const initialState: FeeCategoriesSliceState = {
  feeCategories: JSON.parse(sessionStorage.getItem("feeCategories") as string) ?? [],
  feeCategoriesLoading: false,
  feeCategoriesError: ""
};

export const feeCategoriesSlice = createSlice({
  name: "feeCategories",
  initialState,
  reducers: {
    resetFeeCategoriesSlice: (state) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("feeCategories");
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllFeeCategories.pending, (state) => {
        state.feeCategoriesLoading = true;
      })
      .addCase(getAllFeeCategories.fulfilled, (state, action: PayloadAction<APIListResponse<FeeCategory[]>>) => {
        state.feeCategories = action.payload.data;
        state.feeCategoriesLoading = false;
        sessionStorage.setItem("feeCategories", JSON.stringify(action.payload.data));
      })
      .addCase(getAllFeeCategories.rejected, (state, action) => {
        state.feeCategoriesError = action.payload as string;
        state.feeCategoriesLoading = false;
      });
  }
});

export const selectFeeCategories = (state: RootState) => state.feeCategories.feeCategories;
export const selectFeeCategoriesLoading = (state: RootState) => state.feeCategories.feeCategoriesLoading;

export const { resetFeeCategoriesSlice } = feeCategoriesSlice.actions;

export default feeCategoriesSlice.reducer;
