import { useEffect, useRef, useState } from "react";

type UseCardSwipe = {
  full_name: string;
  cc_number: string;
  exp_date: string;
}
function formatCreditCardNumber(ccNumber: string) {
  return ccNumber.replace(/(\d{4})(?=\d)/g, "$1 ");
}

function parseCreditCardSwipe(swipe: string) {
  const track1Pattern = /%B(\d{16})\^([^^]+)\^(\d{2})(\d{2})/;
  const match = swipe.match(track1Pattern);

  if (!match) {
    throw new Error("Invalid swipe data");
  }

  const [_, cc_number, fullName, expYear, expMonth] = match;
  const [lastName, firstName] = fullName.split("/");

  return {
    full_name: `${firstName.trim()} ${lastName.trim()}`,
    cc_number: formatCreditCardNumber(cc_number),
    exp_date: `${expMonth}/${expYear}`
  };
}

const useCardSwipe = (): [UseCardSwipe | undefined, () => void] => {
  const [swipeData, setSwipeData] = useState<string | undefined>(undefined);
  const bufferRef = useRef<string>("");
  const lastKeyTime = useRef<number>(0);
  const resetSwipeData = () => {
    bufferRef.current = "";
    setSwipeData(undefined);
  };

  // Adjust this value as necessary
  const SWIPE_TIMEOUT = 100;

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const { key } = event;
      const currentTime = new Date().getTime();

      // Filter out the 'Shift' key inputs
      if (key === "Shift") {
        return;
      }

      // Check if the key press is within the allowed time frame
      if (currentTime - lastKeyTime.current > SWIPE_TIMEOUT) {
        bufferRef.current = "";
      }
      lastKeyTime.current = currentTime;

      // Start new input sequence if key is '%'
      if (key === "%" && bufferRef.current === "") {
        bufferRef.current = key;
        return;
      }

      // Append the key to the buffer
      bufferRef.current += key;

      // Check if the sequence ends correctly
      if (key === "Enter" && bufferRef.current.includes("?")) {
        // Capture the complete sequence
        setSwipeData(bufferRef.current);
        // Reset buffer for the next sequence
        bufferRef.current = "";
      }
    };

    const handleKeyPress = (event: KeyboardEvent) => {
      const { key } = event;

      // Check if an input element is focused
      const activeElement = document.activeElement;
      if (activeElement && (activeElement.tagName === "INPUT" || activeElement.tagName === "TEXTAREA")) {
        // Allow regular typing in input fields
        return;
      }

      // Prevent the default action to avoid the swipe data being input into fields
      event.preventDefault();
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keypress", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  const parsedData = swipeData ? parseCreditCardSwipe(swipeData) : undefined;

  return [parsedData, resetSwipeData];
};

export default useCardSwipe;
