import { Button, Grid, Modal, Paper, Typography } from "@mui/material";
import React, { FC } from "react";
import {
  selectCurrentWebhookId,
  selectDeleteWebhookModalOpen,
  setConfirmDeleteWebhookModalOpen,
  setCurrentWebhookId
} from "src/store/reducers/webhooks/webhookSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { useDeleteWebhookMutation } from "src/api/endpoints/webhooks";
import useStyles from "./ConfirmDeleteWebhookModal.styles";

type ConfirmDeleteModalProps = {
};

const ConfirmDeleteModal: FC<ConfirmDeleteModalProps> = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const currentWebhookId = useAppSelector(selectCurrentWebhookId);
  const open = useAppSelector(selectDeleteWebhookModalOpen);
  const [deleteWebhook] = useDeleteWebhookMutation();

  const handleClose = () => {
    dispatch(setCurrentWebhookId(null));
    dispatch(setConfirmDeleteWebhookModalOpen(false));
  };

  const handleSubmit = () => {
    if (currentWebhookId) {
      deleteWebhook(+currentWebhookId).then(data => {
        if ("error" in data) {
          dispatch(showSnackbar({ message: "Error deleting webhook", variant: "error" }));
          dispatch(setCurrentWebhookId(null));
          dispatch(setConfirmDeleteWebhookModalOpen(false));
        }

        if ("data" in data) {
          dispatch(showSnackbar({ message: "Webhook deleted", variant: "success" }));
          dispatch(setCurrentWebhookId(null));
          dispatch(setConfirmDeleteWebhookModalOpen(false));
        }
      });
    }
  };

  return (
    <Modal
      open={currentWebhookId !== null && open}
      onClose={handleClose}
      className={classes.root}
    >
      <Grid
        container
        p={2}
        className={classes.root}
        component={Paper}
        elevation={0}
      >
        <Grid
          container
          p={2}
          spacing={2}
          item
          xs={12}
          justifyContent={"flex-end"}
        >
          <Grid item xs={12}>
            <Typography textAlign={"center"} variant={"h4"}>
              Delete Webhook
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign={"center"} variant={"h6"}>
              Are you sure you want to delete this webhook?
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={handleSubmit}
              className={classes.buttonBase}
              type={"submit"}
              form={"unrentable-reason-form"}
              variant={"contained"}
            >
              Submit
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={"text"}
              color={"error"}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};
export default ConfirmDeleteModal;
