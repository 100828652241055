import { Deal } from "src/models/Deal";
import api from "../api";
import moment from "moment";

const dealNotificationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDealNotifications: builder.query<Deal[], { facilityId: number }>({
      query: ({ facilityId }) => {
        const params = new URLSearchParams();
        params.append("with[]", "notes");
        params.append("with[]", "unit");
        params.append("with[]", "productType");
        params.append("with[]", "promotion");
        params.append("external_deals", "1");
        params.append("filter[is_addressed]", "0");
        params.append("filter[is_converted]", "0");
        params.append("with[]", "dealFollowUp");
        params.append("sort", "asc");
        return { url: `api/v1/facilities/${facilityId}/deals`, params: params };
      },
      providesTags: ["DealNotifications"],
      transformResponse: (response: { data: Deal[] }) => response.data,
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          const { data: deals } = await queryFulfilled;
          const facilityId = deals[0]?.facility_id;
          const dealsToSave = deals?.filter(deal => !deal.deal_follow_up?.first_appearance_timestamp)
            .map(deal => ({
              dealId: deal.id,
              appearanceTimestamp: moment().toISOString()
            }));

          if (dealsToSave && dealsToSave.length > 0) {
            dispatch(dealNotificationsApi.endpoints.saveMetrics.initiate({ deals: dealsToSave, facilityId }));
          }
        } catch (error) {
          console.error("Failed to fetch deals or dispatch saveMetrics:", error);
        }
      }
    }),
    markDealNotificationAddressed: builder.mutation<void, { deal: Deal }>({
      query: ({ deal }) => ({
        url: `api/v1/facilities/${deal.facility_id}/deals/${deal.id}/mark-addressed`,
        method: "PATCH"
      }),
      invalidatesTags: ["DealNotifications"]
    }),
    saveMetrics: builder.mutation<void,
      { deals: { dealId: number, callTimestamp?: string, appearanceTimestamp?: string }[], facilityId: number }>({
        query: ({ deals, facilityId }) => {
          return {
            url: `api/v1/facilities/${facilityId}/deals/save-metrics`,
            method: "PATCH",
            body: { metrics: deals }
          };
        }
      }),
    reservationsForOccupant: builder.query<
      Deal[],
      { firstName: string; lastName: string; middleName?: string; email: string; facilityId: string }
    >({
      query: ({ firstName, lastName, middleName, email, facilityId }) => {
        const params = new URLSearchParams();
        params.append("filter[first_name]", firstName);
        params.append("filter[last_name]", lastName);
        middleName && params.append("filter[middle_name]", middleName);
        params.append("filter[email]", email);
        params.append("filter[deal_type]", "2");
        params.append("with[]", "unit");
        params.append("with[]", "unit.productType");

        return {
          url: `/api/v1/facilities/${facilityId}/deals`,
          params: params
        };
      },
      transformResponse: (response: { data: Deal[] }) => response.data
    })
  })
});

export const {
  useGetDealNotificationsQuery,
  useMarkDealNotificationAddressedMutation,
  useSaveMetricsMutation,
  useReservationsForOccupantQuery,
  useLazyReservationsForOccupantQuery
} = dealNotificationsApi;
