
import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Edit Document" })((theme) => ({
  goBackButton: {
    textTransform: "capitalize",
    color: theme.palette.primary.contrastText
  }
}));

export default useStyles;
