import React, { ReactElement, useEffect } from "react";
import {
  resetMerchandiseItemsSlice,
  selectMerchandiseItemsLoading
} from "../../store/reducers/merchandiseItemsSlice/merchandiseItemsSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { AppDispatch } from "../../store/rootStore";
import { Breadcrumb } from "../../models/Breadcrumb";
import MerchandiseTable from "./MerchandiseTable/MerchandiseTable";
import PageHeader from "../../components/ui/PageHeader/PageHeader";
import TableWrapper from "../../layouts/TableWrapper/TableWrapper";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { getAllMerchandise } from "../../store/thunks/merchandise/getAll/getAllMerchandise";
import { getAllMerchandiseCategories }
  from "../../store/thunks/merchandiseCategory/getAll/getAllMerchandiseCategories";
import { getAllRevenueClasses } from "../../store/thunks/revenueClass/getAll/getAllRevenueClasses";
import { resetTablesSlice } from "../../store/reducers/tablesSlice/tablesSlice";
import { selectMerchandiseCategoriesLoading }
  from "../../store/reducers/merchandiseCategoriesSlice/merchandiseCategoriesSlice";
import { selectRevenueClassesLoading } from "../../store/reducers/revenueClassesSlice/revenueClassesSlice";
import { selectSelectedFacility } from "../../store/reducers/selectedFacilitySlice/selectedFacilitySlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Merchandise"
  },
  {
    name: "Manage Merchandise",
    bold: true
  }
];

export const handleReset = (dispatch: AppDispatch) => {
  dispatch(resetMerchandiseItemsSlice());
  dispatch(resetTablesSlice());
};

const Merchandise: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const merchandiseCategoriesLoading = useAppSelector(selectMerchandiseCategoriesLoading);
  const merchandiseItemsLoading = useAppSelector(selectMerchandiseItemsLoading);
  const revenueClassesLoading = useAppSelector(selectRevenueClassesLoading);
  const loading = merchandiseCategoriesLoading || merchandiseItemsLoading || revenueClassesLoading;
  const selectedFacility = useAppSelector(selectSelectedFacility);

  useEffect(() => {
    dispatch(getAllMerchandise());
    dispatch(getAllMerchandiseCategories());
    dispatch(getAllRevenueClasses(selectedFacility?.id));
  }, []);

  useEffect(() => {
    /* istanbul ignore next */ // cannot test w/ enzyme
    return () => {
      handleReset(dispatch);
    };
  }, []);

  const pageHeader = <PageHeader title={"Manage Merchandise"} breadcrumbs={breadcrumbs} />;

  return (
    <ViewWrapper pageHeader={pageHeader}>
      <TableWrapper
        title={"Available Merchandise"}
        addButtonText={"Add New Merchandise"}
        addButtonLink={"/merchandise/create"}
        table={<MerchandiseTable />}
        loading={loading}
      />
    </ViewWrapper>
  );
};

export default Merchandise;
