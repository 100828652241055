import React from "react";
import { Route } from "react-router";
import AddProductType from "../pages/ProductTypes/AddProductType/AddProductType";
import EditProductTypes from "../pages/ProductTypes/EditProductTypes/EditProductTypes";
import ManageProductTypes from "../pages/ProductTypes/ManageProductTypes/ManageProductTypes";
import NewManageProductTypes from "src/pages/Units/ManageProductTypes/ManageProductTypes";

export const groupName = "product-types";

export const manageProductTypesPath = `/${groupName}/manage-product-types-new`;

export const ProductTypeRoutes = (
  <>
    <Route path={manageProductTypesPath} element={<NewManageProductTypes />} />
    <Route path={`${groupName}/add-product-type`} element={<AddProductType />} />
    <Route path={`${groupName}/manage-product-types`} element={<ManageProductTypes />} />
    <Route path={`${groupName}/:id/edit`} element={<EditProductTypes />} />
  </>
);

export default ProductTypeRoutes;
