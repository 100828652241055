import React, { ReactElement, useEffect, PropsWithChildren } from "react";
import {
  selectNewFacilityAdvisoryModal,
  selectSelectedFacility
} from "../../../store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { selectTargetPath, setTargetPath } from "../../../store/reducers/navigationSlice/navigationSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { AppDispatch } from "../../../store/rootStore";
import AppModal from "../../../components/ui/AppModal/AppModal";
import { Breadcrumb } from "../../../models/Breadcrumb";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import { Typography } from "@mui/material";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { blockNavigation } from "src/components/router/CustomRouter";
import { getAllFacilities } from "../../../store/thunks/facility/getAll/getAllFacilities";
import { getAllProductCategories } from "../../../store/thunks/productCategory/getAll/getAllProductCategories";
import { getAllProductTypeAttributes } from "src/store/thunks/productTypeAttributes/getAll/getAllProductTypeAttributes";
import { getAllRevenueClasses } from "../../../store/thunks/revenueClass/getAll/getAllRevenueClasses";
import { resetNoteSlice } from "src/store/reducers/noteSlice/noteSlice";
import { resetPaymentSlice } from "src/store/reducers/paymentSlice/paymentSlice";
import { resetProductCategoriesSlice } from "../../../store/reducers/productCategoriesSlice/productCategoriesSlice";
import { resetProductCategorySlice } from "../../../store/reducers/productCategorySlice/productCategorySlice";
import { resetProductTypeSlice } from "../../../store/reducers/productTypeSlice/productTypeSlice";
import { resetRevenueClassesSlice } from "../../../store/reducers/revenueClassesSlice/revenueClassesSlice";
import { setFileName } from "../../../store/reducers/importProductTypesSlice/importProductTypesSlice";
import { useLocation, useNavigate } from "react-router";

const basePath = "/product-types/add-product-type";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Units"
  },
  {
    name: "Product Types",
    bold: true
  }
];

export const resetAddProductType = (dispatch: AppDispatch) => {
  dispatch(setTargetPath(""));
  dispatch(resetProductTypeSlice());
  dispatch(resetPaymentSlice());
  dispatch(resetProductCategorySlice());
  dispatch(resetProductCategoriesSlice());
  dispatch(resetNoteSlice());
  dispatch(resetRevenueClassesSlice());
  dispatch(setFileName(""));
};

const AddProductType: React.FC<PropsWithChildren> = ({ children, ...props }): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const pageLocation = useLocation();
  const targetPath = useAppSelector(selectTargetPath);
  const redirect = pageLocation.state ?? null;
  const newFacilityAdvisoryModal = useAppSelector(selectNewFacilityAdvisoryModal);
  const [workflowIncompleteWarning, setWorkflowIncompleteWarning] = React.useState(false);
  const [localTargetPath, setLocalTargetPath] = React.useState<string>("");
  let unblock: Function;

  const selectedFacility = useAppSelector(selectSelectedFacility);

  const handleAdvisory = () => {
    return blockNavigation(({ location }) => {
      const nextLocation = location.pathname;
      const currentLocation = pageLocation.pathname;

      setLocalTargetPath(nextLocation);

      const workflowComplete = (
        currentLocation === `${basePath}/import-units` &&
        nextLocation === "/product-types/manage-product-types"
      );

      if (
        // you're not on the first step
        currentLocation !== basePath &&
        // and you're not navigating b/w workflow steps
        !nextLocation.includes(basePath) &&
        // and you haven't finished adding the occupant
        !workflowComplete &&
        // and you're not performing an allowed redirect
        nextLocation !== targetPath &&
        // and you're not changing facilities (which should trigger a different modal)
        !newFacilityAdvisoryModal
      ) {
        setWorkflowIncompleteWarning(true);
        return false;
      }

      unblock();
      navigate(nextLocation);
    });
  };

  useEffect(() => {
    dispatch(getAllFacilities());
    dispatch(getAllProductTypeAttributes());
    dispatch(getAllProductCategories());
    dispatch(getAllRevenueClasses(selectedFacility?.id));
  }, []);

  useEffect(() => {
    if (redirect) {
      dispatch(setTargetPath(redirect as string));
      navigate(`${basePath}/define-product-type`);
    }
  }, []);

  useEffect(() => {
    unblock = handleAdvisory();

    return () => {
      if (unblock) {
        unblock();
      }
    };
  });

  useEffect(() => {
    return () => {
      resetAddProductType(dispatch);
    };
  }, []);

  const confirmNavigationAway = () => {
    if (unblock) {
      unblock();
    }

    resetAddProductType(dispatch);
    navigate(localTargetPath);
  };

  const pageHeader = <PageHeader title={"Product Types"} breadcrumbs={breadcrumbs} />;

  return (
    <ViewWrapper pageHeader={pageHeader} {...props}>
      {children}
      <AppModal
        open={workflowIncompleteWarning}
        cancelAction={() => setWorkflowIncompleteWarning(false)}
        confirmAction={confirmNavigationAway}
        data-testid={"app-layout-modal"}
      >
        <Typography gutterBottom variant={"h6"}>Warning</Typography>
        <Typography variant={"body1"}>
          You are about to leave an incomplete workflow. If you choose to continue, information you have provided
          during this and previous steps will be lost.
        </Typography>
      </AppModal>
    </ViewWrapper>
  );
};

export default AddProductType;
