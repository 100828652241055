import { RootState } from "src/store/rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSearchParams } from "../../../../utils/getSearchParams/getSearchParams";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const searchOccupants = createAsyncThunk(
  "searchOccupants",
  (
    {
      searchValue,
      disablePagination,
      onlyWithActiveLedgers,
      pageSize
    }: { searchValue: string; disablePagination?: boolean; onlyWithActiveLedgers?: boolean; pageSize?: number },
    { dispatch, getState, rejectWithValue }
  ) => {
    const store = getState() as RootState;
    const facilityId = store.selectedFacility.selectedFacility.id;
    const occupantsFilter = store.occupants.occupantsFilter;

    let pagination;

    if (!disablePagination) {
      pagination = getSearchParams(store, true);
    }

    let filter = {};
    if (onlyWithActiveLedgers) {
      filter = { only_with_active_ledgers: 1 };
    }

    const payload = {
      params: {
        ...pagination,
        ...filter,
        ...(pageSize && { pageSize: pageSize }),
        with: [
          "deals",
          "deals.unit.productType",
          "deals.promotion:id,name",
          // eslint-disable-next-line
          "ledgers:id,occupant_id,monthly_rate,current_balance,unit_id,is_active,date_paid_thru,ledger_number,facility_id,billing_cycle,moved_in_at,delinquent_days,promotion_id,moved_out_at,external_tpp_plan_id",
          "ledgers.ledgerItems",
          "ledgers.facility:id,address,phone",
          "ledgers.unit",
          "ledgers.unit.productType",
          "ledgers.promotion",
          "ledgers.tppPlans",
          "ledgers.externalTppPlan"
          // "ledgers.unit.productType:id,facility_id,width,length,height,name"
        ],
        remove_expired_deals: 1
      }
    };

    if (occupantsFilter) {
      payload.params = { ...payload.params, ...occupantsFilter };
    }

    return axios
      .get(`/api/v1/facilities/${facilityId}/occupants?filter=${searchValue}`, payload)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = "There was an error searching for the Occupant";

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  }
);
