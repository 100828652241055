import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "ProductCategories" })((theme) => ({
  root: {
    height: "100%"
  },
  label: {
    color: theme.palette.common.black,
    paddingBottom: theme.spacing(1)
  },
  backButton: {
    display: "flex",
    flexWrap: "nowrap",
    minWidth: "6rem",
    justifyContent: "center"
  },
  boldLabel: {
    fontWeight: 500
  },
  addToListButton: {
    background: theme.palette.primary.contrastText,
    color: theme.palette.common.white,
    textTransform: "capitalize"
  },
  spinner: {
    color: theme.palette.common.white
  }
}));

export default useStyles;
