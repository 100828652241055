import moment from "moment";
import { BillingCycle } from "../../enums/BillingCycle";

export const getBillingCycle = (cycle: BillingCycle) => {
  switch (cycle) {
    case BillingCycle.anniversary:
      return "Anniversary";
    case BillingCycle.first_of_month:
      return "First of Month";
    default:
      return "";
  }
};

export const getBillingDay = (day: string, billingCycle: BillingCycle) => {
  if (billingCycle === BillingCycle.first_of_month) {
    return moment().add(1, "M").startOf("month").format("MM/DD/yyyy"); ;
  }
  if (billingCycle === BillingCycle.anniversary) {
    return moment(day).add(1, "M").format("MM/DD/yyyy");
  }
};
