import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles()((theme) => ({
  link: {
    textDecoration: "none",
    color: "black"
  },
  menuButton: {
    background: "inherit",
    boxShadow: "none",
    color: theme.palette.primary.dark
  },
  menuButtonIcon: {
    background: "inherit",
    boxShadow: "none"
  },
  badge: {
    color: "#FFF",
    background: "red"
  },
  container: {
    padding: 0
  },
  content: {
    width: "350px",
    height: "500px",
    position: "relative",
    margin: 0
  },
  header: {
    padding: theme.spacing(2)
  },
  list: {
    height: "310px",
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: "5px"
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
      borderRadius: "5px",
      border: "2px solid #ddd",
      boxShadow: "inset 1px 1px 5px #ddd"
    }
  },
  item: {
    borderTop: "1px solid #ddd",
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    height: "70px",
    display: "flex"
  },
  circle: {
    borderRadius: "50%",
    width: "25px",
    height: "25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFF"
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  align: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  boxPast: {
    background: "rgb(255,0,0, 0.3)",
    padding: "2px 4px",
    borderRadius: "16px"
  },
  itemTitle: {
    color: "#444",
    fontSize: "13px",
    fontWeight: "bold"
  },
  description: {
    fontSize: "11px",
    fontWeight: "bold"
  },
  footer: {
    position: "absolute",
    width: "100%",
    borderTop: "2px solid #ddd",
    bottom: 0,
    height: "65px",
    display: "flex",
    textTransform: "capitalize"
  }
}));

export default useStyles;
