import { Grid, Paper, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import {
  selectOccupantReservation,
  setOccupantReservation
} from "src/store/reducers/moveInSlice/moveInSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import DisplayUnitsTable from "./DisplayUnitsTable/DisplayUnitsTable";
import PMSAdvisory from "../../../../components/ui/PMSAdvisory/PMSAdvisory";
import SelectedItemDisplay from "src/components/ui/SelectedItemDisplay/SelectedItemDisplay";
import StepLayout from "src/layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "src/layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import { selectECId }
  from "../../../../store/reducers/emergencyContactInformationSlice/emergencyContactInformationSlice";
import { setTargetPath } from "../../../../store/reducers/navigationSlice/navigationSlice";
import { useLocation, useNavigate } from "react-router";
import useStyles from "./SelectReservation.styles";
import { resetDealSlice } from "../../../../store/reducers/dealSlice/dealSlice";
import { resetSelectedUnitSlice } from "../../../../store/reducers/selectedUnitSlice/selectedUnitSlice";

const stepperId = "select-reservation";

const SelectReservation: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedReservation = useAppSelector(selectOccupantReservation);
  const location = useLocation();
  const emergencyContactId = useAppSelector(selectECId);

  const handleRemoveReservation = () => {
    dispatch(setOccupantReservation(null));
    dispatch(resetDealSlice());
    dispatch(resetSelectedUnitSlice());
  };

  const handleGoBack = () => {
    if (emergencyContactId) {
      return navigate("/occupants/create-move-in/occupant-name-and-location");
    }

    navigate("/occupants/create-move-in/select-occupant");
  };

  const handleGoForward = () => {
    if (selectedReservation) {
      dispatch(setTargetPath(location.pathname));
      return navigate("/occupants/create-move-in/process-move-in");
    }

    navigate("/occupants/create-move-in/select-unit");
  };

  return (
    <>
      <PMSAdvisory
        title={"Select A Reservation"}
        advisoryText={"The current occupant has one or more reservations in the system."}
      />
      <Paper elevation={1}>
        <StepLayout stepperId={stepperId} buttons={
          <StepperButtons
            data-testid={"select-reservation-buttons"}
            stepperId={stepperId}
            forwardActionText={selectedReservation ? "Next" : "Skip"}
            forwardAction={handleGoForward}
            backAction={handleGoBack}
          />
        }>
          <Grid item xs={12}>
            <Typography gutterBottom>
              Below is a list of active reservations for this occupant. Please select the desired reservation for
              move in.
            </Typography>
            <Typography gutterBottom>
              If the occupant’s needs have changed or they would prefer a unit they have not reserved, you can skip
              this step to select an available unit at this facility.
            </Typography>
            <Typography className={classes.boldHeading}>Occupant reservations:</Typography>
            <DisplayUnitsTable />
            {selectedReservation &&
            <SelectedItemDisplay
              data-testid={"selected-unit-display"}
              selectedItem={"reservation"}
              displayName={`Unit ${selectedReservation.unit?.unit_number}`}
              price={selectedReservation.quoted_price.toString()}
              removeAction={handleRemoveReservation}
            />}
          </Grid>
        </StepLayout>
      </Paper>
    </>
  );
};

export default SelectReservation;
