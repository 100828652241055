import { DataGrid, GridRowParams } from "@mui/x-data-grid";
import React, { ReactElement } from "react";
import { setMonthlyRate, setOccupantReservation } from "src/store/reducers/moveInSlice/moveInSlice";
import {
  setQuotedRate,
  setSelectedProductType,
  setSelectedUnit
} from "src/store/reducers/selectedUnitSlice/selectedUnitSlice";
import { ProductType } from "src/models/ProductType";
import { Reservation } from "src/models/Reservation";
import { Unit } from "src/models/Unit";
import displayUnitsTableColumns from "./DisplayUnitsTableColumns/DisplayUnitsTableColumns";
import {
  selectSelectedOccupant,
  selectSelectedOccupantReservations
} from "../../../../../store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { setDeal } from "src/store/reducers/dealSlice/dealSlice";
import { setSelectedPromotion } from "src/store/reducers/selectedPromotionSlice/selectedPromotionSlice";
import { useAppDispatch } from "../../../../../store/hooks";
import { useAppSelector } from "src/store/hooks";
import useStyles from "./DisplayUnitsTable.styles";
import { useReservationsForOccupantQuery } from "src/api/endpoints/deals";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { isEqual, unionWith } from "lodash";

export function selectReservation(row: Reservation, dispatch: Function) {
  dispatch(setDeal(row));
  dispatch(setOccupantReservation(row));
  dispatch(setSelectedUnit(row.unit as Unit));
  dispatch(setQuotedRate(String(row.quoted_price)));
  dispatch(setMonthlyRate(String(row.quoted_price)));
  dispatch(setSelectedProductType((row.unit as Unit).product_type as ProductType));
  dispatch(setSelectedPromotion(row.promotion ?? null));
}

const DisplayUnitsTable: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const reservations = useAppSelector(selectSelectedOccupantReservations);
  const selectedOccupant = useAppSelector(selectSelectedOccupant);
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const { data: reservationsWithSameOccupantInfo } = useReservationsForOccupantQuery(
    {
      email: selectedOccupant?.email!,
      firstName: selectedOccupant?.first_name!,
      lastName: selectedOccupant?.last_name!,
      middleName: selectedOccupant?.middle_name,
      facilityId: String(selectedFacility.id)
    },
    { skip: !selectedOccupant }
  );

  return (
    <DataGrid
      data-testid={"display-units-table"}
      className={classes.root}
      loading={false}
      rows={unionWith(reservations, reservationsWithSameOccupantInfo, (val1, val2) => val1.id === val2.id)}
      disableSelectionOnClick
      autoHeight
      onRowClick={({ row }: GridRowParams) => selectReservation(row as Reservation, dispatch)}
      columns={displayUnitsTableColumns()}
      pageSize={5}
      rowsPerPageOptions={[5, 10, 25, 50]}
    />
  );
};

export default DisplayUnitsTable;
