import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSearchParams } from "../../../../utils/getSearchParams/getSearchParams";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getAllMerchandiseNoPagination = createAsyncThunk(
  "getAllMerchandiseNoPagination",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const facilityId = state.selectedFacility.selectedFacility.id;

    return axios.get(`/api/v1/facilities/${facilityId}/merchandise`)
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = `There was an error getting the Merchandise`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
