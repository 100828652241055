import React, { FC } from "react";
import { Button, Grid, InputLabel, Modal, Paper, Typography } from "@mui/material";
import useStyles from "./IssueCredit.styles";
import Form from "src/components/form/Form";
import { LoadingButton } from "@mui/lab";
import { useFormContext } from "react-hook-form";

interface IssueCreditModalProps {
  onClose: () => void;
  open: boolean;
}

const IssueCreditModal: FC<IssueCreditModalProps> = ({ onClose, open }) => {
  const { classes } = useStyles();
  const { setValue } = useFormContext();

  return (
    <Modal onClose={onClose} open={open} className={classes.root}>
      <Grid container p={2} className={classes.root}
        component={Paper} elevation={0}>
        <Grid id={"reason-form"} data-testid={"reason-form"} container
          spacing={1} p={3}>
          <Grid item xs={12}>
            <Typography textAlign={"center"} variant={"h4"}>
              Confirm Issuing of Credit(s)
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Form.Text
              id={"reason"}
              label={"Please provide a reason and confirm you would like to apply your change(s)."}
              rows={5}
              name={"reason"}
              multiline
            />
          </Grid>
        </Grid>
        <Grid container p={2} spacing={2}
          item xs={12} justifyContent={"flex-end"}>
          <Grid item>
            <LoadingButton className={classes.buttonBase} type={"submit"} variant={"contained"}
              form={"formId"}>
              Apply
            </LoadingButton>
          </Grid>
          <Grid item>
            <Button
              variant={"text"}
              color={"error"}
              onClick={() => {
                onClose();
                setValue("reason", "");
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default IssueCreditModal;
