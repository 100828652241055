import { makeStyles } from "../../../../../../../makeStyles";

const useStyles = makeStyles({ label: "PartialPaymentColumn" })((theme) => ({
  partialPayment: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  partialPaymentInput: {
    width: "8rem"
  }
}));

export default useStyles;
