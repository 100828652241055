import ExternalTppPlan from "src/models/ExternalTppPlan";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

interface CreateExternalTppPlanPayload {
occupant_id: string;
payload: ExternalTppPlan;
ledger_id?: string;
}

export const createExternalTppPlan = createAsyncThunk("createExternalTppPlan",
  ({ occupant_id, payload, ledger_id }: CreateExternalTppPlanPayload,
    { dispatch, rejectWithValue }) => {
    return axios.post(`/api/v1/occupants/${occupant_id}/external-tpp-plans`, { ...payload, occupant_id, ledger_id })
      .then((resp) => {
        dispatch(showSnackbar({
          message: "External TPP Plan created",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an error creating the External TPP plan";

        const snackbarPayload = {
          message: errorMessage,
          variant: "error"
        };

        dispatch(showSnackbar(snackbarPayload));
        return rejectWithValue(snackbarPayload.message);
      });
  }
);
