import * as Yup from "yup";
import { Box, Button, Grid, InputLabel, Modal, Paper, Typography } from "@mui/material";
import React, { FormEvent } from "react";
import { LoadingButton } from "@mui/lab";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import { inputError } from "src/utils/showInputError/showInputError";
import { useFormik } from "formik";
import useStyles from "./MoveInConfirmationModal.styles";

const storing = ["Home Goods", "Automotive", "Other"];

const MoveInConfirmationModal = ({
  open,
  onModalClose,
  onModalSubmit,
  loading
}: {
  open: boolean;
  onModalClose: Function;
  onModalSubmit: Function;
  loading: boolean;
}) => {
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      storingReason: ""
    },
    validationSchema: Yup.object().shape({
      storingReason: Yup.string().required().label("Reason")
    }),
    onSubmit: ({ storingReason }) => {
      onModalSubmit(storingReason);
    }
  });

  const { classes } = useStyles();

  const storingOptions = storing.map((storing) => (
    <>
      <option key={storing} value={storing}>
        {storing}
      </option>
    </>
  ));

  return (
    <Modal
      open={open}
      onClose={() => onModalClose()}
      aria-labelledby={"Modal Component"}
      aria-describedby={"A Modal component."}
      className={classes.modal}
      data-testid={"modal-move-in-confirmation"}
    >
      <Grid
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        spacing={2}
        className={classes.container}
        pb={2}
      >
        <Box
          component={"form"}
          className={classes.form}
          onSubmit={(e: FormEvent) => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit();
          }}
        >
          <Typography variant={"h5"} className={classes.title}>
            Confirm Move In
          </Typography>
          <Box className={classes.containerSelect}>
            <InputLabel htmlFor={"storingReason"}>What are you storing?</InputLabel>
            <PMSSelect
              id={"storingReason"}
              name={"storingReason"}
              value={values.storingReason}
              changeHandler={handleChange}
              error={inputError("storingReason", touched, errors).error}
              helperText={inputError("storingReason", touched, errors).helperText}
            >
              <option value={""} disabled>
                - Select Reason -
              </option>
              {storingOptions}
            </PMSSelect>
          </Box>
          <Grid pt={4} spacing={4} container
            justifyContent={"center"}>
            <Grid item>
              <Button
                data-testid={"cancel-button"}
                className={classes.cancelButton}
                onClick={() => onModalClose()}
                variant={"outlined"}
                color={"secondary"}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                data-testid={"confirm-button-modal"}
                variant={"outlined"}
                className={classes.button}
                type={"submit"}
                loading={loading}
              >
                Confirm
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Modal>
  );
};

export default MoveInConfirmationModal;
