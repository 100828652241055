import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Occupant } from "src/models/Occupant";
import { OccupantContactValues } from "../../../models/formikInputValues/occupant/OccupantContactValues";
import { OccupantInformationSliceState } from "./OccupantInformationSliceState";
import {
  OccupantNameAndLocationValues
} from "../../../models/formikInputValues/occupant/OccupantNameAndLocationValues";
import { RootState } from "src/store/rootStore";
import { createOccupant } from "../../thunks/occupant/create/createOccupant";
import { getDeal } from "src/store/thunks/deals/get/getDeal";
import { getGateCode } from "src/store/thunks/occupant/getGateCode/getGateCode";
import { getOccupant } from "src/store/thunks/occupant/getOne/getOccupant";
import { getOccupantTaxExemptDocument } from "src/store/thunks/occupantDocuments/getOccupantTaxExemptDocument";
import moment from "moment";
import { updateOccupant } from "src/store/thunks/occupant/update/updateOccupant";
import { updateOccupantInStepper } from "../../thunks/occupant/update/stepper/updateOccupantInStepper";
import { validateGateCode } from "src/store/thunks/occupant/validateGateCode/validateGateCode";

export const ID_Type_Enum = {
  DriversLicense: 1,
  StateId: 2,
  Passport: 3
};

const setOccupant = (state: OccupantInformationSliceState, action: PayloadAction<Occupant>) => {
  state.firstName = action.payload.first_name;
  state.middleName = action.payload.middle_name;
  state.lastName = action.payload.last_name;
  state.address = action.payload.address;
  state.addressTwo = action.payload.address_two || "";
  state.city = action.payload.city;
  state.state = action.payload.region;
  state.postalCode = action.payload.postal_code;
  state.country = action.payload.country;
  state.email = action.payload.email;
  state.taxExemptNumber = action.payload.tax_id;
  state.military = action.payload.military_status;
  state.primaryPhone = action.payload.phone_primary;
  state.alternatePhone = action.payload.phone_alternate ?? "";
  state.dob = moment.utc(action.payload.date_of_birth).format("MM/DD/YYYY");
  state.gateCode = action.payload?.gate_codes?.[0] ?? "";
  state.idNumber = action.payload.id_number;
  state.idState = action.payload.id_state;
  state.business = action.payload.is_business;
  state.taxExempt = action.payload.is_tax_exempt;
  state.businessName = action.payload.business_name ?? "";
  state.idType = Number(action.payload.id_type) ?? ID_Type_Enum.DriversLicense;
  state.militaryBranch = action.payload.military_branch;
  state.militaryId = action.payload.military_id;
};

export const initialState: OccupantInformationSliceState = {
  id: "",
  firstName: "",
  middleName: "",
  lastName: "",
  email: "",
  gateCode: "",
  idNumber: "",
  idState: "",
  military: 0,
  dob: null,
  taxExempt: false,
  taxExemptDocument: "",
  taxExemptNumber: "",
  business: false,
  businessName: "",
  primaryPhone: "",
  alternatePhone: "",
  address: "",
  addressTwo: "",
  city: "",
  state: "",
  postalCode: "",
  country: "USA",
  occupantDetailsLoading: false,
  occupantDetailsError: "",
  occupantSubmitted: false,
  occupantTaxExemptDocumentBase64: "",
  gateCodeLoading: false,
  validGateCode: false,
  idType: ID_Type_Enum.DriversLicense,
  militaryBranch: 0,
  militaryId: ""
};

export const occupantInformationSlice = createSlice({
  name: "occupantInformation",
  initialState,
  reducers: {
    setOccupantNameAndLocation: (state, action: PayloadAction<OccupantNameAndLocationValues>) => {
      state.firstName = action.payload.firstName;
      state.middleName = action.payload.middleName;
      state.lastName = action.payload.lastName;
      state.address = action.payload.addressOne;
      state.addressTwo = action.payload.addressTwo;
      state.city = action.payload.city;
      state.state = action.payload.state;
      state.postalCode = action.payload.postalCode.replace(/\D/g, "");
    },
    setOccupantContactInformation: (state, action: PayloadAction<OccupantContactValues>) => {
      state.email = action.payload.email;
      state.primaryPhone = action.payload.primaryPhone;
      state.alternatePhone = action.payload.secondaryPhone;
      state.idNumber = action.payload.idNumber;
      state.idState = action.payload.idState;
      state.military = parseInt(action.payload.militaryStatus);
      state.dob = action.payload.dob;
      state.gateCode = action.payload.gateCode;
      state.taxExempt = action.payload.taxExempt;
      state.business = action.payload.business;
      state.businessName = action.payload.businessName;
      state.taxExempt = action.payload.taxExempt;
      state.taxExemptDocument = action.payload.taxExemptDocument;
      state.idType = action.payload.idType;
      state.militaryBranch = action.payload.militaryBranch;
      state.militaryId = action.payload.militaryId;
    },
    setOccupantDataFromDeal: (state, action: PayloadAction<any>) => {
      state.firstName = action.payload.first_name;
      state.middleName = action.payload.middle_name;
      state.lastName = action.payload.last_name;
      state.email = action.payload.email;
      state.primaryPhone = action.payload.phone;
    },
    setOccupantInformation: (state, action: PayloadAction<Occupant>) => {
      setOccupant(state, action);
    },
    setSelectIdType: (state, action: PayloadAction<any>) => {
      state.idType = action.payload;
    },
    toggleIsBusiness: (state, action: PayloadAction<boolean>) => {
      state.business = action.payload;
    },
    setOccupantTaxExemptDocumentBase64: (state, action: PayloadAction<string>) => {
      state.occupantTaxExemptDocumentBase64 = action.payload;
    },
    resetOccupantInformationSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOccupant.pending, (state) => {
        state.occupantSubmitted = true;
        state.occupantDetailsLoading = true;
      })
      .addCase(createOccupant.fulfilled, (state, action) => {
        state.occupantDetailsLoading = false;
        state.id = action.payload.id;
        sessionStorage.setItem("occupantId", state.id);
      })
      .addCase(createOccupant.rejected, (state, action) => {
        state.occupantDetailsLoading = false;
        state.occupantDetailsError = action.payload as string;
      })
      .addCase(updateOccupantInStepper.pending, (state) => {
        state.occupantDetailsLoading = true;
      })
      .addCase(updateOccupantInStepper.fulfilled, (state) => {
        state.occupantDetailsLoading = false;
      })
      .addCase(updateOccupantInStepper.rejected, (state, action) => {
        state.occupantDetailsLoading = false;
        state.occupantDetailsError = action.payload as string;
      })
      .addCase(getDeal.fulfilled, (state, action) => {
        state.id = action.payload.occupant_id;
        state.firstName = action.payload.first_name;
        state.middleName = action.payload.middle_name;
        state.lastName = action.payload.last_name;
        state.address = action.payload.address;
        state.city = action.payload.city;
        state.state = action.payload.region || "";
        state.postalCode = action.payload.postal_code;
        state.email = action.payload.email;
        state.primaryPhone = action.payload.phone;
      })
      .addCase(getOccupant.pending, (state) => {
        state.occupantDetailsLoading = true;
      })
      .addCase(getOccupant.fulfilled, (state, action) => {
        state.occupantDetailsLoading = false;
        setOccupant(state, action);
      })
      .addCase(getOccupant.rejected, (state, action) => {
        state.occupantDetailsLoading = false;
        state.occupantDetailsError = action.payload as string;
      })
      .addCase(updateOccupant.pending, (state) => {
        state.occupantDetailsLoading = true;
      })
      .addCase(updateOccupant.fulfilled, (state, action) => {
        state.occupantDetailsLoading = false;
        setOccupant(state, action);
      })
      .addCase(updateOccupant.rejected, (state, action) => {
        state.occupantDetailsLoading = false;
        state.occupantDetailsError = action.payload as string;
      })
      .addCase(getOccupantTaxExemptDocument.fulfilled, (state, action) => {
        state.taxExemptDocument = action.payload;
      })
      .addCase(getGateCode.pending, (state) => {
        state.gateCodeLoading = true;
      })
      .addCase(getGateCode.fulfilled, (state, action) => {
        state.gateCodeLoading = false;
        state.gateCode = action.payload.code;
      })
      .addCase(getGateCode.rejected, (state, action) => {
        state.gateCodeLoading = false;
      })
      .addCase(validateGateCode.fulfilled, (state, action) => {
        state.validGateCode = action.payload?.valid_gate_code;
      });
  }
});

export const selectId = (state: RootState) => state.occupantInformation.id;
export const selectFirstName = (state: RootState) => state.occupantInformation.firstName;
export const selectMiddleName = (state: RootState) => state.occupantInformation.middleName;
export const selectLastName = (state: RootState) => state.occupantInformation.lastName;
export const selectAddressOne = (state: RootState) => state.occupantInformation.address;
export const selectAddressTwo = (state: RootState) => state.occupantInformation.addressTwo;
export const selectCity = (state: RootState) => state.occupantInformation.city;
export const selectState = (state: RootState) => state.occupantInformation.state;
export const selectPostalCode = (state: RootState) => state.occupantInformation.postalCode;
export const selectValidMailingAddress = (state: RootState) => {
  return !!(
    state.occupantInformation.address &&
    state.occupantInformation.city &&
    state.occupantInformation.state &&
    (state.occupantInformation.postalCode.length === 5 || state.occupantInformation.postalCode.length === 9)
  );
};
export const selectEmail = (state: RootState) => state.occupantInformation.email;
export const selectPrimaryPhone = (state: RootState) => state.occupantInformation.primaryPhone;
export const selectSecondaryPhone = (state: RootState) => state.occupantInformation.alternatePhone;
export const selectDriversLicenseNumber = (state: RootState) => state.occupantInformation.idNumber;
export const selectDriversLicenseState = (state: RootState) => state.occupantInformation.idState;
export const selectIdType = (state: RootState) => state.occupantInformation.idType;
export const selectTaxId = (state: RootState) => state.occupantInformation.taxExemptNumber;
export const selectMilitaryStatus = (state: RootState) => state.occupantInformation.military;
export const selectDob = (state: RootState) => state.occupantInformation.dob;
export const selectGateCode = (state: RootState) => state.occupantInformation.gateCode;
export const selectIsBusiness = (state: RootState) => state.occupantInformation.business;
export const selectIsTaxExempt = (state: RootState) => state.occupantInformation.taxExempt;
export const selectBusinessName = (state: RootState) => state.occupantInformation.businessName;
export const selectOccupantDetailsLoading = (state: RootState) => state.occupantInformation.occupantDetailsLoading;
export const selectOccupantSubmitted = (state: RootState) => state.occupantInformation.occupantSubmitted;
export const selectOccupantAddressInformation = (state: RootState) => {
  return {
    address: state.occupantInformation.address,
    addressTwo: state.occupantInformation.addressTwo,
    city: state.occupantInformation.city,
    state: state.occupantInformation.state,
    postalCode: state.occupantInformation.postalCode,
    country: state.occupantInformation.country
  };
};

export const selectMilitaryBranch = (state: RootState) => state.occupantInformation.militaryBranch;
export const selectMilitaryId = (state: RootState) => state.occupantInformation.militaryId;
export const selectTaxExemptDocument = (state: RootState) => state.occupantInformation.taxExemptDocument;
export const selectGateCodeLoading = (state: RootState) => state.occupantInformation.gateCodeLoading;
export const selectValidGateCode = (state: RootState) => state.occupantInformation.validGateCode;
export const {
  setOccupantNameAndLocation,
  setOccupantContactInformation,
  setOccupantDataFromDeal,
  setOccupantInformation,
  toggleIsBusiness,
  setOccupantTaxExemptDocumentBase64,
  resetOccupantInformationSlice,
  setSelectIdType
} = occupantInformationSlice.actions;

export default occupantInformationSlice.reducer;
