import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Auction } from "src/models/Auction";
import { RootState } from "../../rootStore";

interface AuctionSLiceState {
  openRemoveUnitFromAuctionModal:boolean
  selectedAuction: Auction | null
}

export const initialState: AuctionSLiceState = {
  openRemoveUnitFromAuctionModal: false,
  selectedAuction: null
};

export const auctionSlice = createSlice({
  name: "auction",
  initialState,
  reducers: {
    resetAuctionSlice: (state: AuctionSLiceState) => {
      Object.assign(state, initialState);
    },
    setOpenRemoveUnitFromAuctionModal: (state, action) => {
      state.openRemoveUnitFromAuctionModal = action.payload;
    },
    setSelectedAuction: (state, action: PayloadAction<Auction | null>) => {
      state.selectedAuction = action.payload;
    }
  }

});

export const selectOpenRemoveUnitFromAuctionModal = (state: RootState) =>
  state.auction.openRemoveUnitFromAuctionModal;

export const selectSelectedAuction = (state: RootState) =>
  state.auction.selectedAuction;

export const {
  setOpenRemoveUnitFromAuctionModal,
  setSelectedAuction
} = auctionSlice.actions;

export default auctionSlice.reducer;
