/* eslint-disable max-len */
import { MerchandiseRefundItem } from "src/models/MerchandiseRefundItem";
import { RefundMerchandisePayload } from "./RefundMerchandisePayload";
import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const processRefundMerchandise = createAsyncThunk(
  "processRefundMerchandise",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const facilityId = state.selectedFacility.selectedFacility.id;

    const merchandiseForRefund = state.merchandiseForRefunds.merchandiseForRefund.filter(merch => merch.return_quantity > 0);

    const refundMerchItemsArray: MerchandiseRefundItem[] = merchandiseForRefund.map((merchItem) => ({
      id: merchItem.id,
      ledger_item_id: merchItem.ledger_item_id,
      quantity: merchItem.return_quantity,
      reason: merchItem.reason
    }));

    const payload: RefundMerchandisePayload = {
      merchandise: refundMerchItemsArray,
      refund_amount: state.merchandiseForRefunds.finalBalance,
      refund_method: state.merchandiseForRefunds.refundMethod,
      transaction_id: state.merchandiseForRefunds.transactionId
    };

    return axios.post(`/api/v1/facilities/${facilityId}/merchandise/refund`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Refund Merchandise successfully",
          variant: "success"
        }));
        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = `There was an error creating refunding the merchandise`;

        if (err?.response?.status === 400) {
          errorMessage += `: ${err.response.data.detail}`;
        }

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
