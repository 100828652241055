import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DelinquencyAutomationsSliceState } from "./DelinquencyAutomationsSliceState";
import { RootState } from "src/store/rootStore";
import { getAutomations } from "src/store/thunks/automations/getAll/getAutomations";
import sortAutomationsList from "src/utils/sortAutomationsList/sortAutomationsList";

export const initialState: DelinquencyAutomationsSliceState = {
  delinquencyAutomations: [],
  filter: true,
  delinquencyAutomationsLoading: false,
  delinquencyAutomationsError: "",
  openModal: false,
  typeModal: "disable",
  selectedDeliquencyAutomation: null
};

export const delinquencyAutomations = createSlice({
  name: "delinquencyAutomations",
  initialState,
  reducers: {
    setOpenModal: (state, action: PayloadAction<boolean>) => {
      state.openModal = action.payload;
    },
    setAutomationsFilter: (state, action) => {
      state.filter = action.payload;
    },
    setTypeModal: (state, action: PayloadAction<"disable" | "reenable">) => {
      state.typeModal = action.payload;
    },
    setSelectedDeliquencyAutomation: (state, action) => {
      state.selectedDeliquencyAutomation = action.payload;
    },
    resetDeliquencyAutomationsSlice: (state) => {
      state = initialState;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAutomations.pending, (state) => {
        state.delinquencyAutomationsLoading = true;
      })
      .addCase(getAutomations.fulfilled, (state, action) => {
        state.delinquencyAutomationsLoading = false;
        state.delinquencyAutomations = sortAutomationsList(action.payload.data);
      })
      .addCase(getAutomations.rejected, (state, action) => {
        state.delinquencyAutomationsLoading = false;
        state.delinquencyAutomationsError = action.payload as string;
      });
  }
});

export const selectDelinquencyAutomations = (state: RootState) => state.delinquencyAutomations.delinquencyAutomations;
export const selectDelinquencyAutomationsLoading = (state: RootState) =>
  state.delinquencyAutomations.delinquencyAutomationsLoading;
export const selectDelinquencyAutomationsError = (state: RootState) =>
  state.delinquencyAutomations.delinquencyAutomationsError;
export const selectDelinquencyAutomationsOpenModal = (state: RootState) =>
  state.delinquencyAutomations.openModal;
export const selectDelinquencyAutomationsTypeModal = (state: RootState) =>
  state.delinquencyAutomations.typeModal;
export const selectSelectedDeliquencyAutomation = (state: RootState) =>
  state.delinquencyAutomations.selectedDeliquencyAutomation;
export const selectDelinquencyAutomationsFilter = (state: RootState) =>
  state.delinquencyAutomations.filter;

export const {
  setOpenModal,
  setAutomationsFilter,
  setTypeModal,
  setSelectedDeliquencyAutomation,
  resetDeliquencyAutomationsSlice
} = delinquencyAutomations.actions;

export default delinquencyAutomations.reducer;
