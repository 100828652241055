import React, { ReactElement } from "react";
import { Box } from "@mui/material";
import { Breadcrumb } from "../../../models/Breadcrumb";
import DocumentForm from "../DocumentForm/DocumentForm";
import PMSAdvisory from "src/components/ui/PMSAdvisory/PMSAdvisory";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import ViewWrapper from "../../../layouts/ViewWrapper/ViewWrapper";
import { useAppSelector } from "../../../store/hooks";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const CreateDocument: React.FC = (): ReactElement => {
  const breadcrumbs: Breadcrumb[] = [
    {
      name: "Document Management"
    },
    {
      name: "Create Document",
      bold: true
    }
  ];

  const pageHeader = <PageHeader title={"Create Document"} breadcrumbs={breadcrumbs} />;
  const permissions = useAppSelector(selectFacilityPermissions);
  const documentLibraryPermissions = createPermissions(permissions, "document_library");

  const CollapseContent = () => {
    return (
      <Box mb={1}>
        Turducken culpa pancetta chicken tongue labore pork loin sint
        burgdoggen picanha ex minim ea tempor aliquip. Ea sint cupidatat buffalo.
        Minim lorem boudin ham hock ex tempor do. Mollit frankfurter eiusmod pork magna.
        Tongue kielbasa aliquip, beef ribs meatloaf cupim aute sirloin porchetta hamburger.
        Exercitation sed est ut, shank spare ribs aliquip ham consequat.
      </Box>
    );
  };

  if (documentLibraryPermissions.create) {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <PMSAdvisory
          title={"Create Document Details"}
          advisoryText={<CollapseContent />}
        />
        <DocumentForm />
      </ViewWrapper>
    );
  } else {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <h1>You do not have permission to view this page.</h1>
      </ViewWrapper>
    );
  }
};

export default CreateDocument;
