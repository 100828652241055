import { CircularProgress, Divider, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import React, { FC } from "react";
import {
  selectMerchandiseCostLoading,
  selectPosState
} from "src/store/reducers/pointOfSalesSlice/pointOfSalesSlice";
import { LineItemProps } from "src/components/paymentSummary/LineItem/LineItem";
import PaymentSummary from "src/components/paymentSummary/PaymentSummary/PaymentSummary";
import { useAppSelector } from "src/store/hooks";

interface IMerchandiseSummary {}

const MerchandiseSummary: FC<IMerchandiseSummary> = () => {
  const posState = useAppSelector(selectPosState);
  const merchandiseCostLoading = useAppSelector(selectMerchandiseCostLoading);

  const breakpoint = useMediaQuery("(max-width: 1300px)");

  const lineItems: LineItemProps[] = posState.summaryLineItems.map(merch => {
    return {
      name: merch.name,
      amount: merch.cost
    };
  });

  let totals: Array<{ name: string; amount: string }> = [];

  totals = [
    {
      name: "Subtotal",
      amount: posState.subTotal
    },
    {
      name: "Tax",
      amount: posState.tax
    },
    {
      name: "Total Due Today",
      amount: posState.finalBalance
    }
  ];

  return (
    <Paper
      component={Grid}
      container
      p={breakpoint ? 0 : 2}
      variant={"outlined"}
      direction={"column"}
      py={3}
      >

      {merchandiseCostLoading
        ? (
          <>
            <Grid container justifyContent={"center"}>
              <CircularProgress data-testid={"move-in-cost-loading-spinner"} />
            </Grid>
          </>
          )
        : (
          <>
            <Grid px={1} item xs={12}>
              <Typography gutterBottom variant={"h5"}>
                Total
              </Typography>
              <Grid px={1} pb={1} item
                xs={12}>
                <Divider />
              </Grid>
            </Grid>
            <PaymentSummary
        // parsing line item cost into amount
              lineItems={lineItems}
              totals={totals}
      />
          </>
          )}

    </Paper>
  );
};

export default MerchandiseSummary;
