import { Button, InputAdornment, TextField } from "@mui/material";
import React, { ReactElement, useCallback, useEffect } from "react";
import {
  resetOccupantsSlice,
  selectOccupantsFilter,
  selectOccupantsSearchValue,
  setOccupantsFilter,
  setOccupantsSearchValue
} from "../../../store/reducers/occupantsSlice/occupantsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { APIFilter } from "src/models/responses/APIFilter";
import { Breadcrumb } from "../../../models/Breadcrumb";
import FilterButtonGroup from "src/components/ui/FilterButtonGroup/FilterButtonGroup";
import ManageOccupantsTable from "./ManageOccupantsTable/ManageOccupantsTable";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import { Search } from "@mui/icons-material";
import { SyntheticInputEvent } from "react-number-format/types/types";
import TableWrapper from "../../../layouts/TableWrapper/TableWrapper";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import clsx from "clsx";
import { debounce } from "lodash";
import { getAllOccupants } from "../../../store/thunks/occupant/getAll/getAllOccupants";
import { resetTablesSlice } from "../../../store/reducers/tablesSlice/tablesSlice";
import { searchOccupants } from "src/store/thunks/occupant/search/searchOccupants";
import useStyles from "./ManageOccupants.styles";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Occupants"
  },
  {
    name: "Manage Occupants",
    bold: true
  }
];

export const currentFilter: APIFilter = {
  only_with_active_ledgers: 1
};

export const pastFilter: APIFilter = {
  only_with_inactive_ledgers: 1
};

const ManageOccupants: React.FC = (): ReactElement => {
  const searchValue = useAppSelector(selectOccupantsSearchValue);
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectOccupantsFilter);
  const { classes } = useStyles({ filter: filter });

  const filterDeals = (filter: APIFilter | "") => {
    dispatch(setOccupantsFilter(filter));
    dispatch(getAllOccupants());
  };

  const filterButtonGroup = (
    <FilterButtonGroup heading={"Display occupants: "}>
      <Button
        className={clsx(classes.buttonText, classes.allFilter)}
        onClick={() => filterDeals("")}
        data-testid={"active-button"}
      >
        All
      </Button>
      <Button
        className={clsx(classes.buttonText, classes.currentFilter)}
        onClick={() => filterDeals(currentFilter)}
        data-testid={"leads-button"}
      >
        Current
      </Button>
      <Button
        className={clsx(classes.buttonText, classes.pastFilter)}
        onClick={() => filterDeals(pastFilter)}
        data-testid={"reservations-button"}
      >
        Past
      </Button>
    </FilterButtonGroup>
  );

  useEffect(() => {
    /* istanbul ignore next */ // cannot test this w/ enzyme */
    return () => {
      dispatch(resetTablesSlice());
      dispatch(resetOccupantsSlice());
    };
  }, []);

  useEffect(() => {
    if (!searchValue.length) {
      dispatch(setOccupantsFilter(currentFilter));
      dispatch(getAllOccupants());
      return;
    }

    dispatch(setOccupantsSearchValue(searchValue));
    dispatch(searchOccupants({ searchValue }));
  }, [searchValue]);

  const debounceSearch = useCallback(
    debounce((text) => dispatch(setOccupantsSearchValue(text)), 600),
    []
  );

  const handleChange = (e: SyntheticInputEvent) => debounceSearch(e.target.value);

  const searchBar = (
    <TextField
      fullWidth
      id={"search-occupants-table"}
      placeholder={"- Search by Name/Email -"}
      size={"small"}
      InputProps={{
        endAdornment: (
          <InputAdornment position={"end"}>
            <Search />
          </InputAdornment>
        )
      }}
      variant={"outlined"}
      name={"occupantsTableSearch"}
      onChange={handleChange}
    />
  );

  const pageHeader = <PageHeader title={"Manage Occupants"} breadcrumbs={breadcrumbs} />;

  return (
    <ViewWrapper pageHeader={pageHeader}>
      <TableWrapper
        title={"Occupants"}
        addButtonText={"Add Occupant"}
        addButtonLink={"/occupants/add-occupant"}
        filterButtonGroup={filterButtonGroup}
        searchBar={searchBar}
        table={<ManageOccupantsTable />}
      />
    </ViewWrapper>
  );
};

export default ManageOccupants;
