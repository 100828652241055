import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getOccupantHistory } from "src/store/thunks/occupantHistory/getAll/getOccupantHistory";
import { RootState } from "../../rootStore";
import { OccupantHistorySliceState } from "./OccupantHistorySliceState";

export const initialState: OccupantHistorySliceState = {
  history: [],
  loading: false
};

export const occupantSlice = createSlice({
  name: "occupant",
  initialState,
  reducers: {
    setOccupantHistoryLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    resetOccupantHistorySlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOccupantHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOccupantHistory.fulfilled, (state, action) => {
        state.history = action.payload.data;
        state.loading = false;
      })
      .addCase(getOccupantHistory.rejected, (state, action) => {
        state.loading = false;
      });
  }
});

export const selectOccupantHistory = (state: RootState) => state.occupantHistory.history;
export const selectOccupantHistoryLoading = (state: RootState) => state.occupantHistory.loading;

export const { resetOccupantHistorySlice, setOccupantHistoryLoading } = occupantSlice.actions;

export default occupantSlice.reducer;
