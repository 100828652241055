import { MoneyOrderSliceState } from "./MoneyOrderSliceState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../rootStore";

export const initialState: MoneyOrderSliceState = {
  amountReceived: "",
  moneyOrderNumber: ""
};

export const moneyOrderSlice = createSlice({
  name: "moneyOrder",
  initialState,
  reducers: {
    setAmountReceived: (state, action: PayloadAction<string>) => {
      state.amountReceived = action.payload;
    },
    setMoneyOrderNumber: (state, action: PayloadAction<string>) => {
      state.moneyOrderNumber = action.payload;
    },
    resetMoneyOrderSlice: (state) => {
      Object.assign(state, initialState);
    }
  }
});

export const selectAmountReceived = (state: RootState) => state.moneyOrder.amountReceived;
export const selectMoneyOrderNumber = (state: RootState) => state.moneyOrder.moneyOrderNumber;

export const {
  setAmountReceived,
  setMoneyOrderNumber,
  resetMoneyOrderSlice
} = moneyOrderSlice.actions;

export default moneyOrderSlice.reducer;
