import React, { ReactElement } from "react";
import { selectSelectedOccupant, selectSelectedOccupantReservations }
  from "../../../../store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useLocation, useNavigate } from "react-router";

import PMSAdvisory from "../../../../components/ui/PMSAdvisory/PMSAdvisory";
import { Paper } from "@mui/material";
import SelectUnitInStepper from "src/components/stepper/SelectUnit/SelectUnitInStepper/SelectUnitInStepper";
import StepLayout from "../../../../layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "../../../../layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import { TRANSFER_UNIT_PATHS } from "src/routes/TransferUnitRoutes.";
import { getActiveLedgers } from "src/utils/getActiveLedgers/getActiveLedgers";
import { getTransferUnitCost } from "src/store/thunks/transfer/transferCost/getTransferUnitCost";
import { selectECFirstName }
  from "../../../../store/reducers/emergencyContactInformationSlice/emergencyContactInformationSlice";
import { selectPromotionsForFacility } from "../../../../store/reducers/promotionsSlice/promotionsSlice";
import { setTargetPath } from "../../../../store/reducers/navigationSlice/navigationSlice";

const SelectNewUnitForTransferUnit: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const reservations = useAppSelector(selectSelectedOccupantReservations);
  const facilityPromotions = useAppSelector(selectPromotionsForFacility);
  const emergencyContact = useAppSelector(selectECFirstName);
  const occupant = useAppSelector(selectSelectedOccupant);

  const advisoryText = (
    <>
      <p>Now, you are able to select the unit the occupant would like to rent.</p>
      <p>
        You can view a list of available units at your facility. This list also displays unit attributes (such
        as the current deal rate, floor number, etc.) that can be used to guide an occupant towards the unit
        that best fits their needs.
      </p>
      <p>
        After a unit has been selected, you may proceed to the next step.
      </p>
    </>
  );

  const updateSummary = () => {
    dispatch(getTransferUnitCost());
  };

  const handleGoBack = () => {
    navigate(TRANSFER_UNIT_PATHS.selectUnitForMoveOutTransferUnit);
  };

  const handleSubmit = () => {
    if (facilityPromotions.length) {
      return navigate(TRANSFER_UNIT_PATHS.selectPromotionForTransferUnit);
    }

    dispatch(setTargetPath(location.pathname));
    updateSummary();
    navigate(TRANSFER_UNIT_PATHS.processTransferUnit);
  };

  return (
    <>
      <PMSAdvisory title={"Select a Unit"} advisoryText={advisoryText} />
      <Paper elevation={1}>
        <StepLayout
          buttons={
            <StepperButtons
              data-testid={"select-move-in-unit-buttons"}
              stepperId={"select-unit"}
              backAction={handleGoBack}
              forwardActionText={"Next"}
            />}
        >
          <SelectUnitInStepper data-testid={"select-unit"} onSubmitHandler={handleSubmit} />
        </StepLayout>
      </Paper>
    </>
  );
};

export default SelectNewUnitForTransferUnit;
