import { Grid } from "@mui/material";
import React, { FC } from "react";
import FacilitySelector from "src/components/navigation/FacilitySelector/FacilitySelector";
import { Facility } from "src/models/Facility";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  selectDocumentCurrentTabView,
  selectDocumentTemplateSearchValue,
  setShowAllFacilities
} from "src/store/reducers/documentsSlice/documentsSlice";
import {
  resetSelectedAdminFacilitySlice,
  setSelectedAdminFacility
} from "src/store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import {
  getAllPaginatedDocumentTemplates
} from "src/store/thunks/documentTemplates/getAll/getAllPaginatedDocumentTemplates";

interface DocumentLibraryTitleProps {
}

const DocumentLibraryTitle: FC<DocumentLibraryTitleProps> = () => {
  const dispatch = useAppDispatch();
  const documentCurrentTabView = useAppSelector(selectDocumentCurrentTabView);
  const documentSearchValue = useAppSelector(selectDocumentTemplateSearchValue);

  const handleOnFacilityChange = async(selectedFacility: Facility) => {
    dispatch(setShowAllFacilities(false));
    await dispatch(setSelectedAdminFacility(selectedFacility));
    dispatch(
      getAllPaginatedDocumentTemplates({
        facilityId: selectedFacility.id,
        type: documentCurrentTabView,
        searchValue: documentSearchValue
      })
    );
  };

  const handleOnAllFacilitiiesChange = async() => {
    dispatch(setShowAllFacilities(true));
    dispatch(
      getAllPaginatedDocumentTemplates({
        type: documentCurrentTabView,
        showAllFacilities: true
      })
    );
    dispatch(resetSelectedAdminFacilitySlice());
  };

  return (
    <Grid item container alignItems={"center"}>
      <Grid item>Document Templates for:</Grid>
      <Grid item ml={2}>
        <FacilitySelector
          navBar={false}
          onFacilityChange={handleOnFacilityChange}
          handleOnAllFacilitiiesChange={handleOnAllFacilitiiesChange}
        />
      </Grid>
    </Grid>
  );
};

export default DocumentLibraryTitle;
