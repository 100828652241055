import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "SelectReservation" })((theme) => ({
  boldHeading: {
    fontWeight: "bold",
    margin: `${theme.spacing(2)} 0`
  }
}));

export default useStyles;
