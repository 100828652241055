import { Button, Divider, Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import {
  TRANSACTION_PAYMENT_METHOD,
  selectTransactionForRefund
} from "src/store/reducers/transactionSlice/transactionSlice";
import refundFormValidation, { RefundFormType } from "./RefundFormValidation";
import {
  selectRefundMerchId,
  selectRefundModalOpen,
  selectReturnType,
  setRefundModalOpen
} from "src/store/reducers/pointOfSalesSlice/pointOfSalesSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";

import AlertModal from "src/components/modal/AlertModal";
import Form from "src/components/form/Form";
import moment from "moment";
import {
  processRefundTransaction
} from "src/store/thunks/transactions/processRefundTransaction/processRefundTransaction";
import { useNavigate } from "react-router";
import useStyles from "./RefundModal.styles";

interface RefundModalProps {}

const RefundModal: FC<RefundModalProps> = () => {
  const { classes } = useStyles();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const refundModalOpen = useAppSelector(selectRefundModalOpen);

  const refundId = useAppSelector(selectRefundMerchId);
  const refundTransactionInfo = useAppSelector(selectTransactionForRefund);

  const returnType = useAppSelector(selectReturnType);

  const formDefaultValues = {
    refundType: returnType === "Refund" ? 0 : 69,
    refundDescription: "",
    refundAmount: returnType === "Refund" ? `${refundTransactionInfo?.amount}` : "1"
  };

  const handleCancel = () => {
    dispatch(setRefundModalOpen(false));
  };
  const handleConfirm = async(data: { refundType: number; refundDescription: string; refundAmount: string }) => {
    const requestData = {
      transactionId: refundId!,
      amount: Number(data.refundAmount) ?? refundTransactionInfo!.amount,
      reason: data.refundDescription,
      method: data.refundType ?? 69
    };

    await dispatch(processRefundTransaction(requestData)).then((resp) => {
      dispatch(setRefundModalOpen(false));
      if (!resp.type.includes("rejected")) {
        location.reload();
      }
    });
  };

  return (
    <Form onSubmit={handleConfirm} schema={refundFormValidation} defaultValues={formDefaultValues}>
      <AlertModal
        open={refundModalOpen}
        buttonLabel={"Confirm"}
        onCloseClick={() => handleCancel()}
        onButtonClick={() => {}}
        title={"Confirm to " + returnType + " Transaction"}
        showButtons={false}
      >
        <>
          {typeof refundTransactionInfo !== "undefined" && refundTransactionInfo !== null && (
            <>
              <Grid display={"flex"} alignItems={"center"} mt={"1.25rem"}
                width={"100%"}>
                <Typography className={classes.blueText} noWrap>
                  PAYMENT DETAILS
                </Typography>
                <Divider className={classes.bodyHeaderDivider} />
              </Grid>
              <Grid display={"flex"} flexDirection={"column"} xs={12}
                mt={2} container>
                <Grid container item display={"flex"}
                  alignItems={"center"}>
                  <Grid item sm={6} display={"flex"}>
                    <Typography className={classes.title}>Payment ID:</Typography>
                    <Typography>{refundId}</Typography>
                  </Grid>
                  <Grid item sm={6} display={"flex"}>
                    <Typography className={classes.title}>Amount:</Typography>
                    <Typography>$ {refundTransactionInfo!.amount}</Typography>
                  </Grid>
                </Grid>
                <Grid container item display={"flex"}
                  alignItems={"center"}>
                  <Grid item sm={6} display={"flex"}>
                    <Typography className={classes.title}>Date:</Typography>
                    <Typography>{moment(refundTransactionInfo!.created_at).format("MM/DD/YYYY")}</Typography>
                  </Grid>
                  <Grid item sm={6} display={"flex"}>
                    <Typography className={classes.title}>Type:</Typography>
                    <Typography>{TRANSACTION_PAYMENT_METHOD[refundTransactionInfo!.payment_method ?? 0]}</Typography>
                  </Grid>
                </Grid>
                {Number(refundTransactionInfo!.refunded_amount) > 0 && returnType === "Refund" && (
                  <Grid container item display={"flex"}
                    alignItems={"center"}>
                    <Grid item sm={6} display={"flex"}>
                      <Typography className={classes.title}>Refunded Amount:</Typography>
                      <Typography>$ {refundTransactionInfo!.refunded_amount}</Typography>
                    </Grid>
                    <Grid item sm={6} display={"flex"}>
                      <Typography className={classes.title}>Amount Left:</Typography>
                      <Typography>
                        ${" "}
                        {(
                          Number(refundTransactionInfo?.amount) - Number(refundTransactionInfo?.refunded_amount)
                        ).toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid display={"flex"} alignItems={"center"} mt={"1.25rem"}
                style={{ width: "100%" }}>
                <Typography className={classes.blueText} sx={{ textTransform: "capitalize" }} noWrap>
                  {returnType} DETAILS
                </Typography>
                <Divider className={classes.bodyHeaderDivider} />
              </Grid>
              <Grid
                sm={12}
                container
                display={returnType === "Refund" ? "flex" : "none"}
                mt={2}
                flexDirection={"column"}
                alignItems={"left"}
              >
                <Typography align={"left"} style={{ paddingLeft: "8px" }} className={classes.title}>
                  {returnType} to
                </Typography>
                <Grid px={1} pb={1} item
                  xs={12}>
                  <Divider />
                  <Form.Select id={"refundType"} name={"refundType"} valuesAsNumber>
                    <Form.Select.Option value={0} disabled>
                      - Select Option -
                    </Form.Select.Option>
                    <Form.Select.Option
                      value={1}
                      disabled={
                        refundTransactionInfo?.payment_method !== 1 ||
                        moment().diff(moment(refundTransactionInfo!.created_at!.substring(0, 10)), "days") >= 1
                      }
                    >
                      Cash
                    </Form.Select.Option>
                    {(refundTransactionInfo?.payment_method === 1 || refundTransactionInfo?.payment_method === 4) && (
                      <Form.Select.Option value={4}>ACH / Check</Form.Select.Option>
                    )}
                    {refundTransactionInfo?.payment_method === 3 && (
                      <Form.Select.Option value={3}>
                        Credit Card (**** {refundTransactionInfo!.method_last_4})
                      </Form.Select.Option>
                    )}
                  </Form.Select>
                </Grid>
              </Grid>
              <Grid
                sm={12}
                container
                display={returnType === "Refund" ? "flex" : "none"}
                mt={2}
                flexDirection={"column"}
                alignItems={"left"}
                style={{ paddingLeft: "8px" }}
              >
                <Typography align={"left"} className={classes.title}>
                  Amount to Refund
                </Typography>
                <Form.Currency
                  disabled={true}
                  id={"refundAmount"}
                  name={"refundAmount"}
                  placeholder={"00.00"}
                  max={Number(refundTransactionInfo!.amount) - Number(refundTransactionInfo!.refunded_amount)}
                />
              </Grid>
              <Grid
                sm={12}
                container
                display={"flex"}
                mt={2}
                flexDirection={"column"}
                alignItems={"left"}
                style={{ paddingLeft: "8px" }}
              >
                <Typography align={"left"} className={classes.title}>
                  Reason
                </Typography>
                <Form.Text id={"refundDescription"} name={"refundDescription"} label={""}
                  rows={5} multiline />
              </Grid>
              <Grid mt={2}>
                <Button className={classes.cancelButton} variant={"outlined"} onClick={() => handleCancel()}>
                  Cancel
                </Button>
                <Form.Submit label={"Confirm"} variant={"outlined"} />
              </Grid>
            </>
          )}
        </>
      </AlertModal>
    </Form>
  );
};

export default RefundModal;
