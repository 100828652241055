const errorMessageFormatter = (message: string) => {
  switch (message) {
    case "unique":
      return "unique value required";
    case "required":
      return "value required";
    default:
      return "";
  }
};

const errorMessageParser = (errors: string[]) => {
  const errorMessages: string[] = [];

  errors.forEach((error) => {
    const parsedError = error.substring(error.indexOf(".") + 1, error.length);
    errorMessages.push(errorMessageFormatter(parsedError));
  });

  return errorMessages.join(", ");
};

export const format422 = (errors: { [key: string]: string[] }) => {
  const allErrors = Object.entries(errors)
    .reduce((acc: any, err: [string, string[]]) => {
      const errorName = err[0].replaceAll("_", " ");
      const errorMessages = errorMessageParser(err[1]) ? `(${errorMessageParser(err[1])})` : "";
      acc.push(`${errorName} ${errorMessages}`);
      return acc;
    }, [])
    .join(", ")
    .trim();

  return `Errors were detected in the following field(s): ${allErrors}`;
};
