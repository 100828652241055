import { Grid, InputLabel } from "@mui/material";
import React, { ReactElement } from "react";
import { RevenueClass } from "../../../../../models/RevenueClass";
import useStyles from "./Taxes.styles";
import TaxInputLabel from "./components/TaxInputLabel";

interface TaxesProps {
  revenueClass: RevenueClass
}

const Taxes: React.FC<TaxesProps> = ({ revenueClass }: TaxesProps): ReactElement => {
  const { classes } = useStyles();

  return (
    <>
      <Grid>
        <TaxInputLabel
          id={"total-tax"}
          label={"Total Tax Rate"}
          value={revenueClass?.tax_total}
        />
      </Grid>
      <Grid
        container
        flexWrap={"wrap"}
        className={classes.taxRates}
      >
        <InputLabel>Tax Rates</InputLabel>
        <Grid
          container
          className={classes.taxesContainer}
        >
          <TaxInputLabel
            id={"state-tax"}
            label={"State"}
            value={revenueClass?.tax_state}
          />
          <TaxInputLabel
            id={"city-tax"}
            label={"City"}
            value={revenueClass?.tax_city}
          />
          <TaxInputLabel
            id={"county-tax"}
            label={"County"}
            value={revenueClass?.tax_county}
          />
          <TaxInputLabel
            id={"local-tax"}
            label={"Local"}
            value={revenueClass?.tax_local}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Taxes;
