import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "StepLayout" })((theme) => ({
  root: {
    width: "100%"
  },
  buttons: {
    justifyContent: "flex-end" // NECESSARY FOR PROPER ALIGNMENT IN SAFARI - Conor
  }
}));

export default useStyles;
