import { Grid, Typography } from "@mui/material";
import {
  ManageLedgerItemsTableColumns,
  parseNumber
} from "./ManageLedgerItemsTableColumns/ManageLedgerItemsTableColumns";
import React, { useContext } from "react";
import { selectLedgerItemsTotalAmount, selectSelectedLedger } from "src/store/reducers/ledgersSlice/ledgersSlice";

import { DataGrid } from "@mui/x-data-grid";
import EmptyTable from "../../../../../components/ui/PMSTable/EmptyTable/EmptyTable";
import { useAppSelector } from "src/store/hooks";
import useStyles from "./LedgerItemsTable.styles";
import { LedgerViewContext } from "../LedgerViewContext";

const emptyTable = () => {
  return (
    <Grid container alignItems={"center"}>
      <EmptyTable collectionNotFound={"ledgers"} value={"ledger"} addLink={"/"} />
    </Grid>
  );
};

const CustomFooterTotalComponent = () => {
  const { ledgerView } = useContext(LedgerViewContext);

  return (
    <Grid container>
      <Typography variant={"h5"} my={1}>
        Totals
      </Typography>

      <Grid container display={"flex"} flexDirection={"row"}
        flexWrap={"nowrap"}>
        <Grid item xs={2}>
          <Typography variant={"inherit"} fontSize={"1rem"}>
            Applied Refund: {`${parseNumber(Number(ledgerView?.totals.applied_refund ?? 0))}`}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant={"inherit"} fontSize={"1rem"}>
            Issue Credit: {`${parseNumber(Number(ledgerView?.totals.issued_credit ?? 0))}`}
          </Typography>
        </Grid>
        <Grid item xs={1.9}>
          <Typography variant={"inherit"} fontSize={"1rem"}>
            Payment: {`${parseNumber(Number(ledgerView?.totals.payment_amount ?? 0))}`}
          </Typography>
        </Grid>
        <Grid item xs={1.9}>
          <Typography variant={"inherit"} fontSize={"1rem"}>
            Charge: {`${parseNumber(Number(ledgerView?.totals.charge_amount ?? 0))}`}
          </Typography>
        </Grid>
        <Grid item xs={1.2}>
          <Typography variant={"inherit"} fontSize={"1rem"}>
            Tax: {`${parseNumber(Number(ledgerView?.totals.tax_amount ?? 0))}`}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant={"inherit"} fontSize={"1rem"}>
            Amount Due: {`${parseNumber(Number(ledgerView?.totals.amount_due ?? 0))}`}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant={"inherit"} fontSize={"1rem"}>
            Prepaid Balance: {`${parseNumber(Number(ledgerView?.totals.prepaid_balance ?? 0))}`}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const LedgerItemsTable = () => {
  const { classes } = useStyles();
  const { loading, ledgerView } = useContext(LedgerViewContext);

  const allItems = ledgerView?.current_items?.concat(ledgerView.future_items) || [];

  return (
    <Grid container item xs={12}>
      <DataGrid
        loading={loading}
        columnBuffer={ManageLedgerItemsTableColumns.length}
        className={classes.root}
        columns={ManageLedgerItemsTableColumns}
        rows={allItems || []}
        autoHeight
        data-testid={"ledger-items-table"}
        componentsProps={{
          header: { "data-testid": `ledger-items-header` },
          row: { "data-testid": `ledger-items-row` }
        }}
        components={{
          NoResultsOverlay: emptyTable,
          NoRowsOverlay: emptyTable,
          Footer: CustomFooterTotalComponent
        }}
      />
    </Grid>
  );
};

export default LedgerItemsTable;
