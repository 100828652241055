export enum TaskRecurrence {
  oneTime = 0,
  daily = 1,
  weekly = 2,
  biweekly = 3,
  monthly = 4,
  endOfMonth = 5,
  quarterly = 6,
  annually = 7
}
