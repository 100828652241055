import { getUnitSizeFromUnit } from "src/utils/getUnitSize/getUnitSize";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProductType } from "../../../models/ProductType";
import { RootState } from "../../rootStore";
import { SelectedUnitSliceState } from "./SelectedUnitSliceState";
import { Unit } from "../../../models/Unit";
import { getAllDeals } from "src/store/thunks/deals/getAll/getAllDeals";
import { getDeal } from "src/store/thunks/deals/get/getDeal";

export const initialState: SelectedUnitSliceState = {
  quotedRate: "",
  selectedUnit: null,
  selectedProductType: null
};

export const selectedUnitSlice = createSlice({
  name: "selectedUnit",
  initialState,
  reducers: {
    setQuotedRate: (state, action: PayloadAction<string>) => {
      state.quotedRate = action.payload;
    },
    setSelectedUnit: (state, action: PayloadAction<Unit | null>) => {
      state.selectedUnit = action.payload;
    },
    setSelectedProductType: (state, action: PayloadAction<ProductType | null>) => {
      state.selectedProductType = action.payload;
    },
    resetSelectedUnitSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      // I don't believe this is doing anything.
      .addCase(getAllDeals.fulfilled, (state, action) => {
        state.quotedRate = action.payload.quoted_price;
      })
      .addCase(getDeal.fulfilled, (state, action) => {
        state.quotedRate = action.payload.quoted_price;
        state.selectedUnit = action.payload.unit;
        state.selectedProductType = action.payload.product_type;
      });
  }
});

export const selectQuotedRate = (state: RootState) => state.selectedUnit.quotedRate;
export const selectSelectedProductType = (state: RootState) => state.selectedUnit.selectedProductType;
export const selectProductTypeRate = (state: RootState) => state.selectedUnit.selectedProductType?.price;
export const selectSelectedUnit = (state: RootState) => state.selectedUnit.selectedUnit;
export const selectSelectedUnitNumber = (state: RootState) => state.selectedUnit.selectedUnit?.unit_number;
export const selectSelectedUnitId = (state: RootState) => state.selectedUnit.selectedUnit?.id;
export const selectSelectedUnitSize = (state: RootState) => {
  const selectedUnit = state.selectedUnit.selectedUnit;

  if (!selectedUnit) {
    return "";
  }

  return getUnitSizeFromUnit(selectedUnit);
};
export const selectSelectedProductTypeName = (state: RootState) => state.selectedUnit.selectedProductType?.name;

export const {
  setQuotedRate,
  setSelectedUnit,
  setSelectedProductType,
  resetSelectedUnitSlice
} = selectedUnitSlice.actions;

export default selectedUnitSlice.reducer;
