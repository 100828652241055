import { Box, Grid, Modal, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import removeAuctionModalValidation, { RemoveAuctionModalFormType } from "./RemoveAuctionModalValidation";
import {
  selectOpenRemoveUnitFromAuctionModal,
  selectSelectedAuction,
  setOpenRemoveUnitFromAuctionModal
} from "src/store/reducers/auctionSlice/auctionSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";

import Form from "src/components/form/Form";
import moment from "moment";
import { resetSelectedLedgers } from "src/store/reducers/makeAPaymentSlice/makeAPaymentSlice";
import { selectSelectedLedger } from "src/store/reducers/ledgersSlice/ledgersSlice";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { useFlagForAuctionMutation } from "src/api/endpoints/auctions";
import { useNavigate } from "react-router";
import useStyles from "./RemoveAuctionModal.styles";

interface CancellationReason {
  value: number;
  text: string;
}

const cancellationReasons: CancellationReason[] = [
  {
    value: 1,
    text: "Added in Error"
  }
];

const RemoveAuctionModal = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const openRemoveUnitFromAuctionModal = useAppSelector(selectOpenRemoveUnitFromAuctionModal);
  const [flagUnitForAuction, { isError }] = useFlagForAuctionMutation();
  const auction = useAppSelector(selectSelectedAuction);
  const selectedLedger = useAppSelector(selectSelectedLedger);
  const navigate = useNavigate();

  const defaultValues = {
    cancellationReason: undefined
  };

  const onSubmitHandler = async(data: RemoveAuctionModalFormType) => {
    if (!auction || !selectedLedger) {
      return;
    }

    await flagUnitForAuction({
      facilityId: selectedLedger.facility_id,
      payload: [selectedLedger?.id],
      auctionDate: moment(auction?.auction_date),
      auctionTime: moment(auction?.auction_time),
      unflag: true,
      removalReason: Number(data.cancellationReason)
    });

    if (!isError) {
      dispatch(
        showSnackbar({
          message: "Unit successfully removed from auction.",
          variant: "success"
        })
      );
    } else {
      dispatch(
        showSnackbar({
          message: "There was an error removing the unit from auction.",
          variant: "error"
        })
      );
    }

    dispatch(setOpenRemoveUnitFromAuctionModal(false));
    navigate("/collections/manage-auctions");
  };

  return (
    <Modal
      open={openRemoveUnitFromAuctionModal}
      onClose={() => dispatch(setOpenRemoveUnitFromAuctionModal(false))}
      className={classes.modal}
      data-testid={"modal-cancel-deal"}
    >
      <Grid
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        spacing={2}
        className={classes.container}
        pb={2}
      >
        <Box className={classes.content}>
          <Typography variant={"h5"} className={classes.title}>
            Confirm to Remove Auction Status
          </Typography>
          <Typography mb={2}>Are you sure you want to remove the Auction status from this Occupant/Unit?</Typography>

          <Form
            onSubmit={onSubmitHandler}
            formId={"removeAuctionForm"}
            defaultValues={defaultValues}
            schema={removeAuctionModalValidation}
          >
            <Form.Select id={"cancellationReason"} name={"cancellationReason"} label={"Reason for Removal"}>
              <Form.Select.Option value={""}>{" - Reason -"}</Form.Select.Option>
              {cancellationReasons.map((reason: CancellationReason) => (
                <Form.Select.Option key={reason.value} value={reason.value}>
                  {reason.text}
                </Form.Select.Option>
              ))}
            </Form.Select>
            <Grid pt={4} spacing={4} container
              justifyContent={"center"}>
              <Grid item>
                <Form.Cancel
                  onClick={() => {
                    dispatch(setOpenRemoveUnitFromAuctionModal(false));
                  }}
                />
                <Form.Submit label={"Confirm"} formId={"removeAuctionForm"} />
              </Grid>
            </Grid>
          </Form>
        </Box>
      </Grid>
    </Modal>
  );
};

export default RemoveAuctionModal;
