import { InputAdornment, TextField } from "@mui/material";

import { NumericFormat } from "react-number-format";
import React from "react";
import { useController } from "react-hook-form";

type FormCurrencyProps = {
  id: string;
  name: string;
  placeholder?: string;
  max?: number;
  className?: string;
  disabled?: boolean;
};

const FormCurrency = ({ id, name, max = 0, placeholder, disabled }: FormCurrencyProps) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    name
  });

  return (
    <NumericFormat
      id={id}
      {...field}
      decimalScale={2}
      isAllowed={({ floatValue }) => {
        if (!max) {
          return true;
        }
        if (!floatValue) {
          return true;
        }
        return floatValue <= max;
      }}
      placeholder={placeholder}
      variant={"outlined"}
      size={"small"}
      customInput={TextField}
      error={!!error?.message}
      helperText={error?.message}
      disabled={disabled}
      InputProps={{
        startAdornment: <InputAdornment position={"start"}>$</InputAdornment>
      }}
    />
  );
};

export default FormCurrency;
