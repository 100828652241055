import { Box, Grid, InputAdornment, InputLabel, TextField, Typography } from "@mui/material";
import React, { FormEvent, ReactElement, useEffect, useState } from "react";
import {
  selectChartAccountId,
  selectCityTax,
  selectCountyTax,
  selectLocalTax,
  selectRevenueClassName,
  selectStateTax,
  selectTotalTax,
  setChartAccountId,
  setCityTax,
  setCountyTax,
  setLocalTax,
  setRevenueClassName,
  setStateTax,
  toggleUpdateRevenueClassModal
} from "../../../../store/reducers/revenueClassInformationSlice/revenueClassInformationSlice";
import {
  selectChartOfAccountsSettingsLoading,
  selectChartOfAccountsSettingsRevenue
} from "src/store/reducers/chartOfAccountsSlice/chartOfAccountsSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { createRevenueClass } from "../../../../store/thunks/revenueClass/create/createRevenueClass";
import {
  getAllPaginatedRevenueClasses
} from "../../../../store/thunks/revenueClass/getAllPaginated/getAllPaginatedRevenueClasses";
import {
  getRevenueChartOfAccountSettings
} from "src/store/thunks/chartOfAccounts/get/getRevenueChartOfAccountsSettings";
import { inputError } from "../../../../utils/showInputError/showInputError";
import revenueClassFormValidation from "./revenueClassFormValidation";
import {
  selectSelectedAdminFacility
} from "../../../../store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import { useFormik } from "formik";
import useStyles from "./RevenueClassForm.styles";
import { useNavigate } from "react-router";
import ChartOfAccountsSetting from "src/models/ChartOfAccountsSetting";
import PMSAutocomplete from "src/components/ui/PMSAutocomplete/PMSAutocomplete";

interface RevenueClassFormProps {
  isUpdate?: boolean;
}

const RevenueClassForm: React.FC<RevenueClassFormProps> = ({ isUpdate }): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedFacility = useAppSelector(selectSelectedAdminFacility);
  const revenueClassName = useAppSelector(selectRevenueClassName);
  const chartAccountId = useAppSelector(selectChartAccountId);
  const stateTax = useAppSelector(selectStateTax);
  const countyTax = useAppSelector(selectCountyTax);
  const cityTax = useAppSelector(selectCityTax);
  const localTax = useAppSelector(selectLocalTax);
  const totalTax = useAppSelector(selectTotalTax);
  const settingsRevenue = useAppSelector(selectChartOfAccountsSettingsRevenue);
  const settingsLoading = useAppSelector(selectChartOfAccountsSettingsLoading);
  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    (async() => {
      await dispatch(getRevenueChartOfAccountSettings());
      setRerender(true);
    })();
  }, []);

  const formik = useFormik({
    initialValues: {
      revenueClassName: revenueClassName,
      chartAccountId: chartAccountId ?? "",
      stateTax: stateTax,
      countyTax: countyTax,
      cityTax: cityTax,
      localTax: localTax
    },
    validationSchema: revenueClassFormValidation,
    validateOnChange: false,
    validateOnBlur: false,
    // enableReinitialize: true,
    onSubmit: () => {
      if (isUpdate) {
        dispatch(toggleUpdateRevenueClassModal(true));
        return;
      }

      dispatch(createRevenueClass()).then((resp) => {
        if (!resp.type.includes("rejected")) {
          dispatch(
            getAllPaginatedRevenueClasses({
              facilityId: selectedFacility?.id,
              filter: ""
            })
          );
          navigate("/accounting/revenue-classes");
        }
      });
    }
  });

  const { touched, errors, values } = formik;

  const updateForm = (fieldName: string, fieldValue?: string | number): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleRevenueClassNameOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setRevenueClassName(fieldValue));
  };

  const handleAssociatedAccountOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setChartAccountId(fieldValue));
  };

  const handleStateTaxOnChange = (fieldName: string, fieldValue: string) => {
    if (fieldValue.length > 7) {
      return;
    }

    updateForm(fieldName, fieldValue);
    dispatch(setStateTax(parseFloat(fieldValue)));
  };

  const handleCountyTaxOnChange = (fieldName: string, fieldValue: string) => {
    if (fieldValue.length > 7) {
      return;
    }

    updateForm(fieldName, fieldValue);
    dispatch(setCountyTax(parseFloat(fieldValue)));
  };

  const handleCityTaxOnChange = (fieldName: string, fieldValue: string) => {
    if (fieldValue.length > 7) {
      return;
    }

    updateForm(fieldName, fieldValue);
    dispatch(setCityTax(parseFloat(fieldValue)));
  };

  const handleLocalTaxOnChange = (fieldName: string, fieldValue: string) => {
    if (fieldValue.length > 7) {
      return;
    }

    updateForm(fieldName, fieldValue);
    dispatch(setLocalTax(parseFloat(fieldValue)));
  };

  const getFormattedSettingName = (setting: ChartOfAccountsSetting) => {
    if (setting.sub_category) {
      return `(${setting.gl_number}) - ${setting.account_name} - ${setting.sub_category}`;
    }
    return `(${setting.gl_number}) - ${setting.account_name}`;
  };

  return (
    <Grid
      id={"revenue-class-form"}
      data-testid={"revenue-class-form"}
      container
      spacing={2}
      mt={1}
      component={"form"}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        formik.handleSubmit();
      }}
    >
      <Grid item xs={6}>
        <InputLabel htmlFor={"revenue-class-name"}>Revenue Class Name</InputLabel>
        <TextField
          id={"revenue-class-name"}
          inputProps={{ "data-testid": "revenue-class-name" }}
          fullWidth
          variant={"outlined"}
          name={"revenueClassName"}
          placeholder={"- Revenue Class Name -"}
          value={values.revenueClassName}
          onChange={(e) => handleRevenueClassNameOnChange("revenueClassName", e.target.value)}
          error={inputError("revenueClassName", touched, errors).error}
          helperText={inputError("revenueClassName", touched, errors).helperText}
        />
      </Grid>
      <Grid item xs={6}>
        {rerender && (
          <PMSAutocomplete
            id={"chartAccountId"}
            label={"Associated Account"}
            placeholder={"Select a Setting"}
            options={settingsRevenue}
            value={settingsRevenue?.find((chartOfAccounts) => chartOfAccounts.id === Number(values.chartAccountId))}
            getOptionLabel={(option: ChartOfAccountsSetting) => getFormattedSettingName(option)}
            changeHandler={(event, value: ChartOfAccountsSetting) =>
              handleAssociatedAccountOnChange("chartAccountId", String(value?.id ?? ""))
            }
            error={inputError("chartAccountId", touched, errors).error}
            helperText={inputError("chartAccountId", touched, errors).helperText}
            renderOption={(props, option: ChartOfAccountsSetting) => (
              <Box component={"li"} {...props} key={option.id}>
                {getFormattedSettingName(option)}
              </Box>
            )}
          ></PMSAutocomplete>

        )}
      </Grid>
      <Grid item xs={12} mt={2}>
        <Typography>Revenue Class Tax Breakdown</Typography>
      </Grid>
      <Grid item container xs={12}
        spacing={3} alignItems={"start"}>
        <Grid item xs={1.5}>
          <InputLabel htmlFor={"state-tax"}>State</InputLabel>
          <TextField
            id={"state-tax"}
            inputProps={{
              step: "any",
              "data-testid": "state-tax"
            }}
            fullWidth
            variant={"outlined"}
            name={"stateTax"}
            type={"number"}
            InputProps={{
              endAdornment: <InputAdornment position={"end"}>%</InputAdornment>
            }}
            placeholder={"- State Tax -"}
            className={classes.noArrowInput}
            value={values.stateTax}
            onChange={(e) => handleStateTaxOnChange("stateTax", e.target.value)}
            error={inputError("stateTax", touched, errors).error}
            helperText={inputError("stateTax", touched, errors).helperText}
          />
        </Grid>
        <Grid item xs={1.5}>
          <InputLabel htmlFor={"city-tax"}>City</InputLabel>
          <TextField
            id={"city-tax"}
            inputProps={{
              step: "any",
              "data-testid": "city-tax"
            }}
            fullWidth
            variant={"outlined"}
            name={"cityTax"}
            type={"number"}
            InputProps={{
              endAdornment: <InputAdornment position={"end"}>%</InputAdornment>
            }}
            placeholder={"- City Tax -"}
            className={classes.noArrowInput}
            value={values.cityTax}
            onChange={(e) => handleCityTaxOnChange("cityTax", e.target.value)}
            error={inputError("cityTax", touched, errors).error}
            helperText={inputError("cityTax", touched, errors).helperText}
          />
        </Grid>
        <Grid item xs={1.5}>
          <InputLabel htmlFor={"county-tax"}>County</InputLabel>
          <TextField
            id={"county-tax"}
            inputProps={{
              step: "any",
              "data-testid": "county-tax"
            }}
            fullWidth
            variant={"outlined"}
            name={"countyTax"}
            type={"number"}
            InputProps={{
              endAdornment: <InputAdornment position={"end"}>%</InputAdornment>
            }}
            placeholder={"- County Tax -"}
            className={classes.noArrowInput}
            value={values.countyTax}
            onChange={(e) => handleCountyTaxOnChange("countyTax", e.target.value)}
            error={inputError("countyTax", touched, errors).error}
            helperText={inputError("countyTax", touched, errors).helperText}
          />
        </Grid>
        <Grid item xs={1.5}>
          <InputLabel htmlFor={"local-tax"}>Local</InputLabel>
          <TextField
            id={"local-tax"}
            inputProps={{
              step: "any",
              "data-testid": "local-tax"
            }}
            fullWidth
            variant={"outlined"}
            name={"localTax"}
            type={"number"}
            InputProps={{
              endAdornment: <InputAdornment position={"end"}>%</InputAdornment>
            }}
            placeholder={"- Local Tax -"}
            className={classes.noArrowInput}
            value={values.localTax}
            onChange={(e) => handleLocalTaxOnChange("localTax", e.target.value)}
            error={inputError("localTax", touched, errors).error}
            helperText={inputError("localTax", touched, errors).helperText}
          />
        </Grid>
        <Grid item container alignItems={"center"}
          xs={6} mt={4}>
          <Grid item>
            <Typography variant={"subtitle1"} component={"span"}>
              Revenue Class Tax Rate
            </Typography>
          </Grid>
          <Grid item xs={3} ml={2}>
            <TextField
              disabled
              id={"total-tax-rate"}
              inputProps={{ "data-testid": "total-tax-rate" }}
              fullWidth
              variant={"outlined"}
              InputProps={{
                endAdornment: <InputAdornment position={"end"}>%</InputAdornment>
              }}
              placeholder={"- Tax Rate -"}
              value={totalTax}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid >
  );
};

export default RevenueClassForm;
