import { Box, Button, Grid, IconButton, Tooltip } from "@mui/material";
import React, { FC } from "react";
import { setOpenModal, setSelectedDeliquencyAutomation, setTypeModal } from
  "src/store/reducers/delinquencyAutomationsSlice/delinquencyAutomationsSlice";
import { Delete } from "@mui/icons-material";
import DelinquencyAutomation from "src/models/DelinquencyAutomations";
import clsx from "clsx";
import { useAppDispatch } from "src/store/hooks";
import useStyles from "../DelinquencyAutomationsComponents.styles";
import { useNavigate } from "react-router";

interface DelinquencyAutomationsItemButtonsProps {
  automation: DelinquencyAutomation
}

const DelinquencyAutomationsItemButtons: FC<DelinquencyAutomationsItemButtonsProps> = ({ automation }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleDisable = () => {
    dispatch(setOpenModal(true));
    dispatch(setSelectedDeliquencyAutomation(automation));
    dispatch(setTypeModal("disable"));
  };

  const handleReenable = () => {
    dispatch(setOpenModal(true));
    dispatch(setSelectedDeliquencyAutomation(automation));
    dispatch(setTypeModal("reenable"));
  };

  return (
    <Box>
      {automation.is_active
        ? (
          <Grid item>
            {!automation.is_original
              ? (
                <Tooltip title={"Remove Automation"}>
                  <IconButton
                    color={"error"}
                    aria-label={"delete-button"}
                    onClick={() => handleDisable()}
          >
                    <Delete />
                  </IconButton>
                </Tooltip>
                )
              : null}
            <Button
              className={clsx(classes.button, classes.buttonText)}
              variant={"contained"}
              onClick={() => navigate(`/delinquency-automations/${automation.id}/edit-automation`)}>
              Edit
            </Button>
          </Grid>
          )
        : (
          <Grid item>
            <Button
              className={clsx(classes.button, classes.buttonText)}
              variant={"contained"}
              onClick={() => handleReenable()}>
              Re-enable
            </Button>
          </Grid>
          )}
    </Box>
  );
};

export default DelinquencyAutomationsItemButtons;
