import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FeeInformationSliceState } from "./FeeInformationSliceState";
import { RootState } from "../../rootStore";
import { createFee } from "../../thunks/fee/create/createFee";
import { getFee } from "src/store/thunks/fee/get/getFee";
import { Fee } from "src/models/Fee";

export const initialState: FeeInformationSliceState = {
  feeName: "",
  feeCategoryId: 0,
  feeAmount: 0,
  feeDescription: "",
  feeType: 0,
  feeRecurrence: 0,
  revenueClassId: 0,
  applyFeeAt: 0,
  feeInformationLoading: false,
  feeInformationError: "",
  isRequired: false,
  fee: {} as Fee
};

export const feeInformationSlice = createSlice({
  name: "feeInformation",
  initialState,
  reducers: {
    setFeeName: (state, action: PayloadAction<string>) => {
      state.feeName = action.payload;
    },
    setFeeCategoryId: (state, action: PayloadAction<number>) => {
      state.feeCategoryId = action.payload;
    },
    setFeeAmount: (state, action: PayloadAction<number>) => {
      state.feeAmount = action.payload;
    },
    setFeeDescription: (state, action: PayloadAction<string>) => {
      state.feeDescription = action.payload;
    },
    setFeeType: (state, action: PayloadAction<number>) => {
      state.feeType = action.payload;
    },
    setFeeRecurrence: (state, action: PayloadAction<number>) => {
      state.feeRecurrence = action.payload;
    },
    setFeeRevenueClassId: (state, action: PayloadAction<number>) => {
      state.revenueClassId = action.payload;
    },
    setApplyFeeAt: (state, action: PayloadAction<number>) => {
      state.applyFeeAt = action.payload;
    },
    setFeeIsRequired: (state, action: PayloadAction<boolean>) => {
      state.isRequired = action.payload;
    },
    resetFeeInformationSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createFee.pending, (state) => {
        state.feeInformationLoading = true;
      })
      .addCase(createFee.fulfilled, (state) => {
        state.feeInformationLoading = false;
      })
      .addCase(createFee.rejected, (state, action) => {
        state.feeInformationLoading = false;
        state.feeInformationError = action.payload as string;
      })
      .addCase(getFee.pending, (state, action) => {
        state.feeInformationLoading = true;
      })
      .addCase(getFee.fulfilled, (state, action) => {
        state.feeInformationLoading = false;
        state.feeName = action.payload.name;
        state.feeCategoryId = action.payload.fee_category_id;
        state.feeAmount = action.payload.amount;
        state.feeDescription = action.payload.description;
        state.feeRecurrence = action.payload.recurrence;
        state.revenueClassId = action.payload.revenue_class_id;
        state.applyFeeAt = action.payload.apply_at;
        state.feeType = action.payload.type;
        state.isRequired = action.payload.is_required;
        state.fee = action.payload;
      })
      .addCase(getFee.rejected, (state, action) => {
        state.feeInformationLoading = false;
        state.feeInformationError = action.payload as string;
      });
  }
});

export const selectFeeName = (state: RootState) => state.feeInformation.feeName;
export const selectFeeCategoryId = (state: RootState) => state.feeInformation.feeCategoryId;
export const selectFeeAmount = (state: RootState) => state.feeInformation.feeAmount;
export const selectFeeDescription = (state: RootState) => state.feeInformation.feeDescription;
export const selectFeeType = (state: RootState) => state.feeInformation.feeType;
export const selectFeeRecurrence = (state: RootState) => state.feeInformation.feeRecurrence;
export const selectFeeRevenueClassId = (state: RootState) => state.feeInformation.revenueClassId;
export const selectApplyFeeAt = (state: RootState) => state.feeInformation.applyFeeAt;
export const selectFeeInformationLoading = (state: RootState) => state.feeInformation.feeInformationLoading;
export const selectFeeIsRequired = (state: RootState) => state.feeInformation.isRequired;
export const selectFee = (state: RootState) => state.feeInformation.fee;

export const {
  setFeeName,
  setFeeCategoryId,
  setFeeAmount,
  setFeeDescription,
  setFeeType,
  setFeeRecurrence,
  setFeeRevenueClassId,
  setApplyFeeAt,
  resetFeeInformationSlice,
  setFeeIsRequired
} = feeInformationSlice.actions;

export default feeInformationSlice.reducer;
