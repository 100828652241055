import React, { ReactElement, useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector
} from "../../../store/hooks";
import { Breadcrumb } from "src/models/Breadcrumb";
import ManageProductTypesTable from "./ManageProductTypesTable/ManageProductTypesTable";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import TableWrapper from "../../../layouts/TableWrapper/TableWrapper";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { getAllPaginatedProductTypes } from "src/store/thunks/productType/getAllPaginated/getAllPaginatedProductTypes";
import { resetProductTypeSlice } from "../../../store/reducers/productTypeSlice/productTypeSlice";
import { resetProductTypesSlice } from "../../../store/reducers/productTypesSlice/productTypesSlice";
import { resetTablesSlice } from "../../../store/reducers/tablesSlice/tablesSlice";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Units"
  },
  {
    name: "Manage Units & Product Types",
    bold: true
  }
];

const ManageProductTypes: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const selectedFacility = useAppSelector(selectSelectedFacility);

  useEffect(() => {
    dispatch(getAllPaginatedProductTypes(selectedFacility?.id));

    /* istanbul ignore next */ // cannot test this w/ enzyme
    return () => {
      dispatch(resetTablesSlice());
      dispatch(resetProductTypesSlice());
      dispatch(resetProductTypeSlice());
    };
  }, []);

  const pageHeader = <PageHeader title={"Manage Units & Product Types"} breadcrumbs={breadcrumbs} />;

  return (
    <ViewWrapper pageHeader={pageHeader}>
      <TableWrapper
        title={"Product Types"}
        addButtonText={"Add Product Type"}
        addButtonLink={"/product-types/add-product-type"}
        table={<ManageProductTypesTable />}
      />
    </ViewWrapper>
  );
};

export default ManageProductTypes;
