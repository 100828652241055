import { RootState } from "../../store/rootStore";

export const getSearchParams = (state: RootState, paginate?: boolean,
  initialSortByValue = "",
  initialSortDirection = "") => ({
  page: state.tables.page + 1, // b/c MUI tables are 0-indexed, we need to increment w/e goes out by 1
  pageSize: paginate ? state.tables.rowsPerPage : 999,
  sortDirection: state.tables.sortModel[0]?.sort || initialSortDirection,
  sortBy: state.tables.sortModel[0]?.field || initialSortByValue
});
