const amexRegex = /^(\d{0,4})(\d{0,6})(\d{0,5})$/g;
export const standardCCRegex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
export const parsedCCNumbers = (ccNumbers: string) => ccNumbers.replace(/[^\d]/g, "");

export const formatStandard = (ccNumbers: string) => {
  return parsedCCNumbers(ccNumbers).replace(standardCCRegex, (standardCCRegex, $1, $2, $3, $4) => {
    return [$1, $2, $3, $4].filter(group => !!group).join(" ");
  });
};

export const formatAmex = (ccNumbers: string) => {
  return parsedCCNumbers(ccNumbers).replace(amexRegex, (amexRegex, $1, $2, $3) => {
    return [$1, $2, $3].filter(group => !!group).join(" ");
  });
};

export const maskStandard = (ccNumbers: string) => {
  return parsedCCNumbers(ccNumbers).replace(standardCCRegex, (standardCCRegex, $1, $2, $3, $4) => {
    return [$1.replace(/./g, "*"), $2.replace(/./g, "*"), $3.replace(/./g, "*"), $4]
      .filter(group => !!group).join(" ");
  });
};

export const maskAmex = (ccNumbers: string) => {
  return parsedCCNumbers(ccNumbers).replace(amexRegex, (amexRegex, $1, $2, $3) => {
    return [$1.replace(/./g, "*"), $2.replace(/./g, "*"), $3.replace(/./, "*")].filter(group => !!group).join(" ");
  });
};

export const maskCVV = (cvv: string) => "*".repeat(cvv?.length);
