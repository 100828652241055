import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { ReactElement, useEffect } from "react";
import {
  resetLedgers,
  resetMakeAPaymentSlice,
  resetSelectedLedgers,
  selectSelectedLedgers
} from "../../../../store/reducers/makeAPaymentSlice/makeAPaymentSlice";
import {
  resetSelectedOccupantSlice,
  selectSelectedOccupant
} from "../../../../store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import OccupantLedgerTable from "../OccupantLedgerTable/OccupantLedgerTable";
import PMSAdvisory from "../../../../components/ui/PMSAdvisory/PMSAdvisory";
import SelectOccupant from "../../../../components/stepper/SelectOccupant/SelectOccupant";
import StepLayout from "../../../../layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "../../../../layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import { resetAchSlice } from "src/store/reducers/achSlice/achSlice";
import { resetCashSlice } from "src/store/reducers/cashSlice/cashSlice";
import { resetCreditCardSlice } from "src/store/reducers/creditCardSlice/creditCardSlice";
import { resetPaymentSlice } from "src/store/reducers/paymentSlice/paymentSlice";
import { useNavigate } from "react-router";

const SelectOccupantOrUnit: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedOccupant = useAppSelector(selectSelectedOccupant);
  const selectedLedgers = useAppSelector(selectSelectedLedgers);

  const handleClearLedgers = () => {
    dispatch(resetLedgers());
    dispatch(resetSelectedLedgers());
  };

  const CollapseContent = () => {
    return (
      <Box mb={1}>
        Search for an existing occupant or unit to begin payment for. Bacon ipsum dolor amet landjaegar alcatra
        hamburguer pork chop. Chuck esse jerky elit tempor lerberkas.
      </Box>
    );
  };

  useEffect(() => {
    dispatch(resetMakeAPaymentSlice());
    dispatch(resetSelectedOccupantSlice());
    dispatch(resetPaymentSlice());
    dispatch(resetCashSlice());
    dispatch(resetCreditCardSlice());
    dispatch(resetAchSlice());
  }, []);

  return (
    <>
      <Grid item>
        <PMSAdvisory title={"Search by Occupant or Unit"} advisoryText={<CollapseContent />} />
      </Grid>

      <Grid item>
        <Paper elevation={1}>
          <Grid item container p={4}>
            <Grid item xs={12}>
              <Typography variant={"h5"}>Search by Occupant or Unit</Typography>
            </Grid>
            <Grid item xs={12}>
              <SelectOccupant
                disableAddOccupant
                disablePagination
                onlyWithActiveLedgers
                showWarning={false}
                data-testid={"select-make-a-payment-occupant-form"}
                callBackReset={handleClearLedgers}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {selectedOccupant && (
        <Grid item>
          <Paper elevation={1}>
            <StepLayout
              buttons={
                <StepperButtons
                  data-testid={"select-unit-buttons"}
                  stepperId={"select-unit"}
                  forwardActionText={"Next"}
                  forwardAction={() => navigate("/collections/make-a-payment/review-and-apply-payment")}
                  disabled={selectedLedgers.length === 0}
                  showBackButton={false}
                  showCancelButton
                  onCancel={() => navigate("/collections/make-a-payment")}
                />
              }
            >
              <Grid container rowSpacing={2}>
                <Grid item xs={8}>
                  <Typography variant={"h5"}>Listed Occupant Units:</Typography>
                  <Typography>
                    We&apos;ve found these reservations for this occupant. Select the unit(s) to make payment for.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <OccupantLedgerTable />
                </Grid>
              </Grid>
            </StepLayout>
          </Paper>
        </Grid>
      )}
    </>
  );
};

export default SelectOccupantOrUnit;
