import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getFacility = createAsyncThunk(
  "getFacility", (id: number, { dispatch, getState, rejectWithValue }) => {
    const params = {
      params: {
        with: [
          "keypadAccesses",
          "timeAccesses"
        ]
      }
    };

    return axios.get(`/api/v1/facilities/${id}`, params)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = `There was an error getting the data for facility ${id}`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
