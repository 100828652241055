import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Box } from "@mui/system";
import ChartOfAccountsTableColumns from "./ChartOfAccountsTableColumns/ChartOfAccountsTableColumns";
import EmptyTable from "src/components/ui/PMSTable/EmptyTable/EmptyTable";
import PMSTable from "src/components/ui/PMSTable/PMSTable";
import { getChartOfAccountSettings } from "src/store/thunks/chartOfAccounts/get/getChartOfAccountsSettings";
import {
  selectChartOfAccountsSearchValue,
  selectChartOfAccountsSettings
} from "src/store/reducers/chartOfAccountsSlice/chartOfAccountsSlice";
import useStyles from "./ChartOfAccountsTable.styles";
import { searchChartOfAccountSettings }
  from "src/store/thunks/chartOfAccounts/search/searchChartOfAccountsSettings";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

interface ChartOfAccountsTableProps { }

const ChartOfAccountsTable: FC<ChartOfAccountsTableProps> = () => {
  const settings = useAppSelector(selectChartOfAccountsSettings);
  const searchValue = useAppSelector(selectChartOfAccountsSearchValue);
  const dispatch = useAppDispatch();
  const permissions = useAppSelector(selectFacilityPermissions);
  const chartOfAccountsPermissions = createPermissions(permissions, "chart_of_accounts");

  const handlePaginationUpdate = () => {
    if (!searchValue.length) {
      dispatch(getChartOfAccountSettings());
      return;
    }

    dispatch(searchChartOfAccountSettings({ searchValue }));
  };

  const emptyTable = () => (
    <EmptyTable
      collectionNotFound={`chart of accounts settings`}
      value={"setting"}
      addLink={"/accounting/chart-of-accounts/create"}
    />
  );

  return (
    <Box mt={2} pt={2}>
      <PMSTable
        columns={ChartOfAccountsTableColumns(chartOfAccountsPermissions.update)}
        rows={settings}
        emptyTableDisplay={emptyTable}
        onPaginationUpdate={handlePaginationUpdate}
        additionalRowsPerPageOptions={[100]}
      />
    </Box>
  );
};

export default ChartOfAccountsTable;
