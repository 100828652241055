import React, { FC, useEffect } from "react";
import {
  resetFacilitySlice,
  selectFacility,
  selectFacilityLoading
} from "src/store/reducers/facilitySlice/facilitySlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Breadcrumb } from "../../../models/Breadcrumb";
import FacilityForm from "../FacilityForm/FacilityForm";
import FacilityFormValues from "src/models/formikInputValues/FacilityFormValues";
import FacilitySettings from "../FacilitySettings/FacilitySettings";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import TabView from "src/layouts/TabView/TabView";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { getFacility } from "../../../store/thunks/facility/get/getFacility";
import { resetFacilityHoursSlice } from "src/store/reducers/facilityHoursSlice/facilityHoursSlice";
import { toggleFacilityDisableModal } from "src/store/reducers/modalSlice/modalSlice";
import { useParams } from "react-router";

interface EditFacilityProps {}

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Facility Management"
  },
  {
    name: "Edit Facility",
    bold: true
  }
];

const EditFacility: FC<EditFacilityProps> = () => {
  const params: { id?: string } = useParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!params.id) {
      return;
    }

    const id = parseInt(params.id, 10);

    dispatch(getFacility(id));

    return () => {
      dispatch(toggleFacilityDisableModal(false));
      dispatch(resetFacilitySlice());
      dispatch(resetFacilityHoursSlice());
    };
  }, []);

  const facilityLoading = useAppSelector(selectFacilityLoading);

  const selectedFacility = useAppSelector(selectFacility) as unknown as FacilityFormValues;

  const pageHeader = <PageHeader title={"Edit Facility"} breadcrumbs={breadcrumbs} />;

  const componentList = [
    {
      name: "Facility Information",
      component: <FacilityForm initialValues={selectedFacility} isUpdate={true} />
    },
    {
      name: "Facility Settings",
      component: <FacilitySettings facilityId={+params.id!}/>
    }
  ];

  return (
    <ViewWrapper pageHeader={pageHeader}>
      <TabView
        showPaper={false}
        innerPadding={false}
        loading={facilityLoading}
        components={componentList}
        onChangeAction={() => dispatch(getFacility(+params.id!))}
      />
    </ViewWrapper>
  );
};

export default EditFacility;
