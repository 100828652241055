import { APIFilter } from "../../../../models/responses/APIFilter";
import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSearchParams } from "../../../../utils/getSearchParams/getSearchParams";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getAllPaginatedRevenueClasses = createAsyncThunk(
  "getAllPaginatedRevenueClasses",
  (
    { facilityId, filter }: { facilityId: number | unknown, filter: APIFilter | "" },
    { dispatch, getState, rejectWithValue }) => {
    if (!facilityId) {
      const errorMessage = "Facility ID not found";

      dispatch(showSnackbar({
        message: errorMessage,
        variant: "error"
      }));

      rejectWithValue(errorMessage);
      return;
    }

    const store = getState() as RootState;
    const searchParams = {
      params: {
        ...getSearchParams(store, true),
        with: ["chartAccount"]
      }
    };

    if (filter) {
      searchParams.params = { ...searchParams.params, ...filter };
    }

    return axios.get(`/api/v1/facilities/${facilityId}/revenue-classes`, searchParams)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = "There was an error getting the Revenue Classes";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
