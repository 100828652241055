import { Grid, InputLabel, Paper, TextField, Typography } from "@mui/material";
import React, { CSSProperties, ChangeEvent, FC, useEffect } from "react";
import { FormValues, Header } from "../../WebhookForm";
import { FormikErrors, FormikProps } from "formik";
import RenderAddAndRemoveButton from "./AddAndRemoveButtons";

  interface HeadersFormSectionProps {
    values: FormValues;
    handleInputOnChange: (fieldName: string, fieldValue: string) => void;
    inputError: (fieldName: string, touched: any, errors: any) => { error: boolean; helperText: string | undefined };
    addCustomizeKey: () => void;
    removeCustomizeKey: (index: number) => void;
    formik: FormikProps<FormValues>;
  }

const gridStyles: CSSProperties = {
  maxHeight: "45vh",
  overflowY: "auto"
};

const HeadersFormSection: FC<HeadersFormSectionProps> = ({
  values,
  handleInputOnChange,
  inputError,
  addCustomizeKey,
  removeCustomizeKey,
  formik
}) => {
  const headers = Array.isArray(values.headers) ? values.headers : [];

  return (
    <Grid container style={gridStyles} >
      {headers.map((header, index) => (
        <Grid
          pb={1}
          container
          justifyContent={"center"}
          key={index}
          spacing={2}
        >
          <Grid item xs={5}>
            <InputLabel htmlFor={`headers-key-${index}`}>Key</InputLabel>
            <TextField
              id={`headers-key-${index}`}
              fullWidth
              placeholder={"- Key -"}
              inputProps={{ "data-testid": `headers-key-${index}` }}
              name={`headers.${index}.key`}
              value={header.key}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputOnChange(`headers.${index}.key`, e.target.value)
              }
              error={Boolean(formik.errors.headers && (formik.errors.headers[index] as FormikErrors<Header>)?.key)}
              helperText={(formik.errors.headers && (formik.errors.headers[index] as FormikErrors<Header>)?.key) ?? ""}
              />
          </Grid>
          <Grid item xs={5}>
            <InputLabel htmlFor={`headers-value-${index}`}>Value</InputLabel>
            <TextField
              id={`headers-value-${index}`}
              fullWidth
              placeholder={"- Value -"}
              inputProps={{ "data-testid": `headers-value-${index}` }}
              name={`headers.${index}.value`}
              value={header.value}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputOnChange(`headers.${index}.value`, e.target.value)
              }
              error={Boolean(formik.errors.headers && (formik.errors.headers[index] as FormikErrors<Header>)?.value)}
              helperText={(formik.errors.headers && (formik.errors.headers[index] as FormikErrors<Header>)?.value) ??
              ""}
              />
          </Grid>
          <Grid item xs={2}>
            <RenderAddAndRemoveButton
              addCustomizeKey={addCustomizeKey}
              removeCustomizeKey={removeCustomizeKey}
              index={index}
              disableRemoveButton={values.headers.length === 1}
              />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
export default HeadersFormSection;
