import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({
  label: "Delinquency Automation components"
})((theme) => ({
  filterButtonActive: {
    color: theme.palette.text.primary
  },
  filterButtonDisabled: {
    color: theme.palette.text.disabled
  }
}));

export default useStyles;
