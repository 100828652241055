import {
  Box,
  Grid,
  InputAdornment,
  InputLabel,
  TextField
} from "@mui/material";
import React, { FormEvent, ReactElement } from "react";
import {
  selectMerchandiseCategoryId,
  selectMerchandiseDescription,
  selectMerchandiseId,
  selectMerchandiseName,
  selectMerchandisePrice,
  selectMerchandiseRevenueClassId
} from "../../../store/reducers/merchandiseFormSlice/merchandiseFormSlice";
import { MerchandiseCategory } from "../../../models/MerchandiseCategory";
import { MerchandiseValues } from "../../../models/formikInputValues/MerchandiseValues";
import PMSAutocomplete from "src/components/ui/PMSAutocomplete/PMSAutocomplete";
import PMSSelect from "../../../components/ui/PMSSelect/PMSSelect";
import { RevenueClass } from "../../../models/RevenueClass";
import { inputError } from "../../../utils/showInputError/showInputError";
import merchandiseFormValidation from "./merchandiseFormValidation";
import { useAppSelector } from "../../../store/hooks";
import { useFormik } from "formik";

interface MerchandiseFormProps {
  merchandiseCategories: MerchandiseCategory[];
  revenueClasses: RevenueClass[];
  submitHandler: (values: MerchandiseValues) => void;
}

const MerchandiseForm: React.FC<MerchandiseFormProps> = ({
  merchandiseCategories,
  revenueClasses,
  submitHandler
}): ReactElement => {
  const id = useAppSelector(selectMerchandiseId);
  const name = useAppSelector(selectMerchandiseName);
  const price = useAppSelector(selectMerchandisePrice);
  const description = useAppSelector(selectMerchandiseDescription);
  const revenueClassId = useAppSelector(selectMerchandiseRevenueClassId);
  const merchandiseCategoryId = useAppSelector(selectMerchandiseCategoryId);

  const formik = useFormik({
    initialValues: {
      id: id,
      name: name,
      price: price,
      description: description,
      revenue_class_id: revenueClassId,
      merchandise_category_id: merchandiseCategoryId
    },
    validationSchema: merchandiseFormValidation,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values: MerchandiseValues) => submitHandler(values)
  });

  const { touched, values, errors, handleChange, setFieldTouched, setFieldValue, handleSubmit } = formik;
  const handleCurrencyChange = (fieldName: string, amount: number | string) => {
    setFieldTouched(fieldName);
    setFieldValue(fieldName, String(amount));
  };

  return (
    <Grid
      id={"merchandise-form"}
      container
      spacing={2}
      mt={1}
      component={"form"}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
      }}
    >
      {/* //left col */}
      <Grid item xs={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel htmlFor={"merchandise-name"}>Merchandise Name</InputLabel>
            <TextField
              id={"merchandise-name"}
              fullWidth
              variant={"outlined"}
              name={"name"}
              placeholder={"- Merchandise Name -"}
              value={values.name}
              onChange={handleChange}
              error={inputError("name", touched, errors).error}
              helperText={inputError("name", touched, errors).helperText}
        />
          </Grid>
          <Grid item xs={4}>
            <InputLabel htmlFor={"merchandise-price"}>Price</InputLabel>
            <TextField
              id={"merchandise-price"}
              fullWidth
              type={"number"}
              inputProps={{ step: "any" }}
              variant={"outlined"}
              name={"price"}
              placeholder={"- Price -"}
              InputProps={{ startAdornment: <InputAdornment position={"start"}>$</InputAdornment> }}
              value={values.price}
              onChange={(e) => handleCurrencyChange("price", e.target.value)}
              error={inputError("price", touched, errors).error}
              helperText={inputError("price", touched, errors).helperText}
        />
          </Grid>
          <Grid item xs={4}>
            <PMSSelect
              id={"merchandise-category"}
              label={"Merchandise Category"}
              name={"merchandise_category_id"}
              value={values.merchandise_category_id}
              changeHandler={handleChange}
              error={inputError("merchandise_category_id", touched, errors).error}
              helperText={inputError("merchandise_category_id", touched, errors).helperText}
        >
              <option value={0}>- Select Category -</option>
              {merchandiseCategories.map((merchandiseCategory) => (
                <option key={merchandiseCategory.id} value={merchandiseCategory.id}>
                  {merchandiseCategory.name}
                </option>
              ))}
            </PMSSelect>
          </Grid>
          <Grid item xs={4}>
            <PMSAutocomplete
              id={"revenue-classes"}
              label={"Revenue Classes"}
              options={revenueClasses}
              placeholder={"- Select Revenue Class -"}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option: RevenueClass) => (
                <Box component={"li"} {...props} key={option.id}>
                  {option.name}
                </Box>
              )}
              changeHandler={(event, option) => setFieldValue("revenue_class_id", option?.id || 0)}
              value={revenueClasses.find((revenueClass) => values.revenue_class_id === revenueClass.id)}
              error={inputError("revenue_class_id", touched, errors).error}
              helperText={inputError("revenue_class_id", touched, errors).helperText}
        />
          </Grid>
        </Grid>
      </Grid>
      {/* // right col */}
      <Grid item xs={3}>
        <InputLabel htmlFor={"merchandise-description"}>Description</InputLabel>
        <TextField
          id={"merchandise-description"}
          fullWidth
          multiline
          rows={5.35}
          inputProps={{ maxLength: 255 }}
          name={"description"}
          value={values.description}
          onChange={handleChange}
          error={inputError("description", touched, errors).error}
          helperText={inputError("description", touched, errors).helperText}
        />
      </Grid>
    </Grid>
  );
};

export default MerchandiseForm;
