import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { ExitToApp, Pageview, RemoveCircle } from "@mui/icons-material";
import { Occupant } from "../../../../../models/Occupant";
import React from "react";
import {
  getAllUnitsForOccupant
} from "../../../../../utils/__occupantUtils__/getAllUnitsForOccupant/getAllUnitsForOccupant";
import { getOccupantBalance } from "../../../../../utils/__occupantUtils__/getOccupantBalance/getOccupantBalance";
import { nameParser } from "src/utils/nameParser/nameParser";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { setSelectedOccupant } from "src/store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { useNavigate } from "react-router";
import { getActiveLedgers } from "src/utils/getActiveLedgers/getActiveLedgers";
import { setMoveOutLedger } from "src/store/reducers/moveOutSlice/moveOutSlice";

interface OccupantsRenderDetailsButtonProps {
  row: Occupant;
}

export const RenderDetailsButton: React.FC<OccupantsRenderDetailsButtonProps> = ({ row }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const activeLedgers = getActiveLedgers(row.ledgers);

  return (
    <Grid container direction={"row"} alignItems={"center"}
      justifyContent={"start"}>
      <Tooltip title={"View/Edit Occupant"}>
        <IconButton
          data-testid={"edit-occupant-button"}
          size={"small"}
          onClick={async() => {
            await dispatch(setSelectedOccupant(row));
            navigate(`/occupants/${row?.id}/edit`);
          }}
        >
          <Pageview />
        </IconButton>
      </Tooltip>
      <Tooltip title={"Move Out"}>
        <IconButton
          disabled={activeLedgers?.length === 0}
          size={"small"}
          data-testid={"move-out-occupant-button"}
          onClick={async() => {
            if (!activeLedgers || activeLedgers.length === 0) {
              return;
            }
            await dispatch(setSelectedOccupant(row));
            if (activeLedgers && activeLedgers.length > 1) {
              return navigate("/occupants/create-move-out/select-unit", { state: { isMoveOutFromOccupant: true } });
            }
            await dispatch(setMoveOutLedger(activeLedgers[0]));
            navigate("/occupants/create-move-out/process-move-out", { state: { isMoveOutFromOccupant: true } });
          }}
        >
          <ExitToApp />
        </IconButton>
      </Tooltip>
      <Tooltip title={"Remove Occupant"}>
        <IconButton size={"small"} data-testid={"disable-occupant-button"}>
          <RemoveCircle />
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

export const ManageOccupantsTableColumns = (): GridColDef[] => [
  {
    field: "last_name",
    renderHeader: () => (
      <Tooltip title={"Name"}>
        <Typography variant={"body2"}>Name</Typography>
      </Tooltip>
    ),
    minWidth: 180,
    flex: 1,
    valueGetter: (params: { row: Occupant }) =>
      nameParser(params.row.first_name!, params.row.last_name!, params.row.middle_name)
  },
  {
    field: "unit",
    renderHeader: () => (
      <Tooltip title={"Unit(s)"}>
        <Typography variant={"body2"}>Unit(s)</Typography>
      </Tooltip>
    ),
    sortable: false,
    minWidth: 75,
    flex: 1,
    valueGetter: (params: { row: Occupant }) => getAllUnitsForOccupant(params.row)
  },
  {
    field: "is_business",
    renderHeader: () => (
      <Tooltip title={"Business Account"}>
        <Typography variant={"body2"}>Bus. Acct</Typography>
      </Tooltip>
    ),
    minWidth: 150,
    flex: 1,
    valueGetter: (params: { row: Occupant }) => (params.row.is_business ? "Yes" : "No")
  },
  {
    field: "email",
    renderHeader: () => (
      <Tooltip title={"Email"}>
        <Typography variant={"body2"}>Email</Typography>
      </Tooltip>
    ),
    minWidth: 220,
    flex: 1
  },
  {
    field: "delinquent_bucket",
    renderHeader: () => (
      <Tooltip title={"Delinquent"}>
        <Typography variant={"body2"}>Delinquent</Typography>
      </Tooltip>
    ),
    minWidth: 100,
    flex: 1,
    sortable: false
  },
  {
    field: "actionIcons",
    renderHeader: () => (
      <Tooltip title={"Actions"}>
        <Typography variant={"body2"}>Actions</Typography>
      </Tooltip>
    ),
    sortable: false,
    minWidth: 120,
    flex: 1,
    renderCell: (row) => RenderDetailsButton(row)
  }
];

export default ManageOccupantsTableColumns;
