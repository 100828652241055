import React from "react";
import { Tooltip, Typography } from "@mui/material";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { getUnitSizeFromUnit } from "../../../../../utils/getUnitSize/getUnitSize";

export const MoveOutUnitTableColumns = (): GridColDef[] => [
  {
    field: "unit_id",
    renderHeader: () => (
      <Tooltip title={"Unit Number"}>
        <Typography>Unit Num</Typography>
      </Tooltip>
    ),
    minWidth: 170,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => `Unit ${params.row.unit.unit_number}`
  },
  {
    field: "unit_size",
    renderHeader: () => (
      <Tooltip title={"Unit Size"}>
        <Typography>Unit S</Typography>
      </Tooltip>
    ),
    minWidth: 170,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => getUnitSizeFromUnit(params.row.unit)
  },
  {
    field: "monthly_rate",
    renderHeader: () => (
      <Tooltip title={"Monthly Rate"}>
        <Typography>Monthly R</Typography>
      </Tooltip>
    ),
    minWidth: 170,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => `$${params.row.monthly_rate.toFixed(2)}`
  },
  {
    field: "current_balance",
    renderHeader: () => (
      <Tooltip title={"Balance"}>
        <Typography>Balance</Typography>
      </Tooltip>
    ),
    minWidth: 170,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => `$${parseFloat(params.row.current_balance).toFixed(2)}`
  },
  /* istanbul ignore next */ // at least until we get a paid through date
  {
    field: "tax_city",
    headerName: "Paid Thru Date",
    renderHeader: () => (
      <Tooltip title={"Paid Thru Date"}>
        <Typography>Paid T Date</Typography>
      </Tooltip>
    ),
    minWidth: 170,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.date_paid_thru
  }
];

export default MoveOutUnitTableColumns;
