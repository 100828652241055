import { axiosInstance } from "src/api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "src/utils/format422/format422";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";

interface ChangeLedgerTppPlanPayload {
    occupant_id: string
    ledger_id: string
    payload: { tpp_plan_id: string }
}

export const changeCurrentTppPlan = createAsyncThunk(
  "changeCurrentTppPlan",
  async({ occupant_id, ledger_id, payload }: ChangeLedgerTppPlanPayload, { dispatch, getState, rejectWithValue }) => {
    return axiosInstance.post(`/api/v1/occupants/${occupant_id}/ledgers/${ledger_id}/change-current-tpp-plan`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: `Current TPP Plan successfully changed.`,
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an changing this ledger's current tpp plan. Please try again later.";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
