import { Add, Search } from "@mui/icons-material";
import { Button, Grid, InputAdornment, Paper, TextField, Typography, useMediaQuery } from "@mui/material";
import React, { ReactElement, useEffect } from "react";
import {
  resetTablesSlice,
  selectSelectedRows,
  setSearchValue,
  setSortModel
} from "src/store/reducers/tablesSlice/tablesSlice";
import {
  selectBucketFilter,
  selectCollectionsLoading,
  selectPaymentCompleteModalOpen,
  selectPaymentModalOpen,
  setBucketFilter,
  setCreateAuctionsModalOpen,
  setPaymentCompleteModalOpen,
  setPaymentModalOpen
} from "src/store/reducers/collectionsSlice/collectionsSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Breadcrumb } from "../../../models/Breadcrumb";
import CreateAuctionsConfirmationModal from "./CreateAuctionsModal/CreateAuctionsConfirmationModal";
import CreateAuctionsModal from "./CreateAuctionsModal/CreateAuctionsModal";
import DelinquentOccupantsTable from "./DelinquentOccupantsTable/DelinquentOccupantsTable";
import FilterButtonGroup from "../../../components/ui/FilterButtonGroup/FilterButtonGroup";
import FollowUpModal from "./FollowUpModal/FollowUpModal";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import PaymentCompleteModal from "./PaymentModal/PaymentCompleteModal/PaymentCompleteModal";
import PaymentModal from "./PaymentModal/PaymentModal";
import TableWrapper from "src/layouts/TableWrapper/TableWrapper";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import clsx from "clsx";
import { getAllGlobalSettings } from "src/store/thunks/globalSetting/getAll/getAllGlobalSettings";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import useStyles from "./DelinquentOccupants.styles";
import theme from "src/theme";

type BucketCode = "" | 0 | 1 | 2 | 3;

type BucketView = "All" | "0-30" | "31-60" | "61-90" | "90+";

type BucketFilter = {
  [bucketView in BucketView]: BucketCode;
};

const bucketFilter: BucketFilter = {
  All: "",
  "0-30": 0,
  "31-60": 1,
  "61-90": 2,
  "90+": 3
};

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Collections"
  },
  {
    name: "Delinquent Occupants",
    bold: true
  }
];

const DelinquentOccupants: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectBucketFilter);
  const facility = useAppSelector(selectSelectedFacility);
  const paymentModalOpen = useAppSelector(selectPaymentModalOpen);
  const paymentCompleteModalOpen = useAppSelector(selectPaymentCompleteModalOpen);
  const selectedRows = useAppSelector(selectSelectedRows);
  const { facility_id } = facility;
  const { classes } = useStyles();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const pageHeader = <PageHeader title={"Delinquent Occupants"} breadcrumbs={breadcrumbs} />;
  const handleFilterClick = (bucketCode: BucketCode) => {
    dispatch(setBucketFilter(bucketCode));
  };

  const handleSearch = (searchValue: string) => {
    dispatch(setSearchValue(searchValue));
  };
  const paymentModal = paymentModalOpen
    ? <PaymentModal open={paymentModalOpen} onClose={() => dispatch(setPaymentModalOpen(false))} />
    : null;

  const paymentCompleteModal = paymentCompleteModalOpen
    ? (
      <PaymentCompleteModal
        open={paymentCompleteModalOpen}
        onModalClose={() => dispatch(setPaymentCompleteModalOpen(false))}
      />
      )
    : null;
  const title = (
    <Grid item container alignItems={"center"}>
      <Grid item>
        <Typography component={"label"} htmlFor={"deliquent-occupants-facility-selector"} variant={"h5"}>
          Delinquent Occupants at {facility_id}
        </Typography>
      </Grid>
    </Grid>
  );

  const searchBar = (
    <TextField
      fullWidth
      id={"search-delinquent-occupants-table"}
      placeholder={"- Search -"}
      size={"small"}
      InputProps={{
        endAdornment: (
          <InputAdornment position={"end"}>
            <Search />
          </InputAdornment>
        )
      }}
      variant={"outlined"}
      name={"delinquentOccupantsSearch"}
      onChange={(e) => handleSearch(e.target.value)}
    />
  );

  const filterButtonGroup = (
    <FilterButtonGroup heading={"Filter by: "}>
      {Object.entries(bucketFilter).map(([bucket, bucketCode]) => {
        return (
          <Button
            className={clsx(classes.inactiveFilter, { [classes.activeFilter]: filter === bucketCode })}
            onClick={() => handleFilterClick(bucketCode)}
            key={bucket}
            data-testid={`delinquent-occupants-filter-${bucket.toLocaleLowerCase()}`}
          >
            {bucket}
          </Button>
        );
      })}
    </FilterButtonGroup>
  );

  useEffect(() => {
    dispatch(getAllGlobalSettings());
    return () => {
      dispatch(resetTablesSlice());
      dispatch(setBucketFilter(""));
      dispatch(setSortModel([]));
    };
  }, []);

  const showButton = selectedRows.length > 0

    ? (
      <Button
        fullWidth
        className={classes.buttonBase}
        disableElevation
        variant={"contained"}
        color={"primary"}
        key={"create-auctions"}
        startIcon={<Add />}
        onClick={() => dispatch(setCreateAuctionsModalOpen(true))}
      >
        Create Auctions
      </Button>
      )
    : null;

  return (
    <ViewWrapper pageHeader={pageHeader}>
      <Grid
        mt={2}
        component={Paper}
        variant={"outlined"}
        container
        p={2}
        spacing={2}
        justifyContent={"space-between"}
        alignItems={"center"}

      >
        <Grid style={{ whiteSpace: "pre", textOverflow: "ellipsis", overflow: "hidden" }}
          item xs={isLargeScreen ? 4 : 12} >
          {title}
        </Grid>
        <Grid container justifyContent={"flex-end"} spacing={2}
          item
          xs={isLargeScreen ? 8 : 12} >
          <Grid item xs={7}>
            {filterButtonGroup}
          </Grid>
          <Grid item xs={5}>
            {searchBar}
          </Grid>
        </Grid>
        {showButton && (
          <Grid justifySelf={"flex-end"} item xs={"auto"}>
            {showButton}
          </Grid>
        )}
        <Grid item xs={12}>
          <DelinquentOccupantsTable />
        </Grid>
      </Grid>
      {paymentModal}
      {paymentCompleteModal}
      <CreateAuctionsModal />
      <CreateAuctionsConfirmationModal />
      <FollowUpModal />
    </ViewWrapper>
  );
};

export default DelinquentOccupants;
