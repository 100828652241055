import React, { ReactElement } from "react";
import { setOccupantContactInformation, setOccupantTaxExemptDocumentBase64 }
  from "../../../../store/reducers/occupantInformationSlice/occupantInformationSlice";
import OccupantContactInformation from
  "../../../../components/stepper/OccupantContactInformation/OccupantContactInformation";
import { OccupantContactValues } from "../../../../models/formikInputValues/occupant/OccupantContactValues";
import OccupantInfoIcons from "../../../../components/stepper/OccupantInfoIcons/OccupantInfoIcons";
import PMSAdvisory from "../../../../components/ui/PMSAdvisory/PMSAdvisory";
import { Paper } from "@mui/material";
import StepLayout from "../../../../layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "../../../../layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { toBase64 } from "src/pages/Occupants/EditOccupant/EditOccupantTabs/OccupantDocuments/OccupantDocuments";
import { useAppDispatch } from "../../../../store/hooks";
import { useNavigate } from "react-router";
import { validateGateCode } from "src/store/thunks/occupant/validateGateCode/validateGateCode";

const AddMoveInOccupantContact: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGoBack = () => navigate("/occupants/create-move-in/occupant-name-and-location");

  const handleSubmit = async(values: OccupantContactValues) => {
    if (values.taxExempt && values.taxExemptDocument?.name) {
      const taxExemptDocumentBase64 = await toBase64(values.taxExemptDocument);
      await dispatch(setOccupantTaxExemptDocumentBase64(taxExemptDocumentBase64 as string));
    }
    await dispatch(setOccupantContactInformation(values));
    const { payload } = await dispatch(validateGateCode());

    if (!payload?.valid_gate_code) {
      dispatch(showSnackbar({
        message: "Gate Code is in use",
        variant: "error"
      }));
      return;
    }

    dispatch(setOccupantContactInformation(values));
    navigate("/occupants/create-move-in/emergency-contact");
  };

  const stepperId = "move-in-occupant-contact";

  return (
    <>
      <PMSAdvisory
        title={"Occupant Information"}
        advisoryText={"First, please fill out the occupant's basic information below."}
        icons={<OccupantInfoIcons />}
      />
      <Paper elevation={1}>
        <StepLayout stepperId={stepperId} buttons={
          <StepperButtons
            data-testid={"move-in-occupant-contact-buttons"}
            stepperId={stepperId}
            backAction={handleGoBack}
          />
        }>
          <OccupantContactInformation
            data-testid={"move-in-occupant-contact-form"}
            stepperId={stepperId}
            onSubmitHandler={handleSubmit}
          />
        </StepLayout>
      </Paper>
    </>
  );
};

export default AddMoveInOccupantContact;
