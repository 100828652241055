import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "Occupant Ledger View" })((theme) => ({
  container: {
    position: "relative",
    maxHeight: "85vh",
    overflowY: "auto"
  },
  closeButton: {
    marginRight: "1rem"
  },
  closeIcon: {
    position: "absolute",
    top: 15,
    right: 5
  },
  buttonText: {
    textTransform: "none"
  }
}));

export default useStyles;
