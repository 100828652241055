import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const deleteDocumentTemplate = createAsyncThunk(
  "deleteDocumentTemplate",
  (documentTemplateId: number, { dispatch, getState, rejectWithValue }) => {
    return axios.delete(`/api/v1/document-templates/${documentTemplateId}`)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Document Template successfully deleted",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an error deleting the Document Template";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
