import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { formatStandard, maskCVV, maskStandard } from "src/components/payment/CreditCardForm/CreditCardForm";

import { CreditCardProvider } from "../../../models/CreditCardProvider";
import { CreditCardSliceState } from "./CreditCardSliceState";
import { Occupant } from "src/models/Occupant";
import { RootState } from "src/store/rootStore";
import creditCard from "credit-card-type";
import { getOccupant } from "src/store/thunks/occupant/getOne/getOccupant";

export const initialState: CreditCardSliceState = {
  name: "",
  number: "",
  numberMasked: "",
  cvv: "",
  cvvMasked: "",
  expiration: "",
  type: "",
  savePaymentMethod: false,
  enableAutoPay: false,
  showCCNumber: false,
  isPreferredPayment: false
};

export const creditCardSlice = createSlice({
  name: "creditCard",
  initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setNumber: (state, action) => {
      state.number = action.payload;

      const cardData = creditCard(action.payload);
      if (cardData.length) {
        state.type = cardData[0].type as CreditCardProvider;
      }
    },
    setNumberMasked: (state, action) => {
      state.numberMasked = action.payload;
    },
    setCVV: (state, action) => {
      state.cvv = action.payload;
    },
    setCVVMasked: (state, action) => {
      state.cvvMasked = action.payload;
    },
    setExpiration: (state, action) => {
      state.expiration = action.payload;
    },
    setSavePaymentMethod: (state, action) => {
      state.savePaymentMethod = action.payload;
    },
    setIsPreferredPayment: (state, action) => {
      state.isPreferredPayment = action.payload;
    },
    setEnableAutoPay: (state, action) => {
      state.enableAutoPay = action.payload;
    },
    resetCreditCardSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getOccupant.fulfilled, (state, action: PayloadAction<Occupant>) => {
      const latestCreditCard =
        action.payload.payment_instruments?.length! > 0 &&
         action.payload.payment_instruments?.[action.payload.payment_instruments.length - 1];
      if (latestCreditCard) {
        state.name = latestCreditCard.name;
        state.number = `xxxx xxxx xxxx ${latestCreditCard.last_4_digits}`;
        state.cvv = "xxx";
        state.numberMasked = `xxxx xxxx xxxx ${latestCreditCard.last_4_digits}`;
        state.isPreferredPayment = latestCreditCard.is_preferred_payment;
        state.enableAutoPay = latestCreditCard.is_enable_autobilling;
        state.expiration = `${latestCreditCard.exp_month}${latestCreditCard.exp_year?.substring(2, 4)}`;
      }
    });
  }
});

export const {
  setName,
  setNumber,
  setNumberMasked,
  setExpiration,
  setCVV,
  setCVVMasked,
  setEnableAutoPay,
  setSavePaymentMethod,
  setIsPreferredPayment,
  resetCreditCardSlice
} = creditCardSlice.actions;

export const selectCCName = (state: RootState) => state.creditCard.name;
export const selectCCNumber = (state: RootState) => state.creditCard.number;
export const selectCCNumberMasked = (state: RootState) => state.creditCard.numberMasked;
export const selectCCCVV = (state: RootState) => state.creditCard.cvv;
export const selectCCCVVMasked = (state: RootState) => state.creditCard.cvvMasked;
export const selectCCExpiration = (state: RootState) => state.creditCard.expiration;
export const selectCCType = (state: RootState) => state.creditCard.type;
export const selectEnableAutoPay = (state: RootState) => state.creditCard.enableAutoPay;
export const selectSavePaymentMethod = (state: RootState) => state.creditCard.savePaymentMethod;
export const selectIsPreferredPayment = (state: RootState) => state.creditCard.isPreferredPayment;
export const selectCreditCard = (state: RootState) => state.creditCard;

export default creditCardSlice.reducer;
