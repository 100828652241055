import { Alert, Grid, IconButton, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { Close } from "@mui/icons-material";
import useStyles from "./FacilitySettingsAlert.styles";
interface FacilitySettingsAlertProps { }

const FacilitySettingsAlert: FC<FacilitySettingsAlertProps> = () => {
  const [show, setShow] = useState(true);
  const { classes } = useStyles();
  return (
    show
      ? <Alert className={classes.root} hidden={!show}
          severity={"error"}>
        <Grid id={"foobar"} container alignItems={"center"}>
          <Grid item xs={11}>
            <Typography>
              By changing these settings, any template settings set previously in Global Settings will be overridden.
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => setShow(false)} >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </Alert>
      : null
  );
};
export default FacilitySettingsAlert;
