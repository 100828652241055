import { Edit, RemoveCircle } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { NavLink } from "react-router-dom";
import React from "react";

interface ProductTypesRenderDetailsButtonProps {
  row: GridValueGetterParams
}

export const RenderDetailsButton: React.FC<ProductTypesRenderDetailsButtonProps> = ({ row }) => {
  return (
    <Grid
      container
      direction={"row"}
      alignItems={"center"}
      justifyContent={"start"}
    >
      <IconButton
        data-testid={"edit-product-type-button"}
        size={"small"}
        to={`/product-types/${row.id}/edit`}
        component={NavLink}
      >
        <Edit />
      </IconButton>
      <IconButton size={"small"} data-testid={"disable-product-type-button"}>
        <RemoveCircle />
      </IconButton>
    </Grid>
  );
};

export const ManageOccupantsTableColumns = (): GridColDef[] => [
  {
    field: "name",
    headerName: "Product Type",
    minWidth: 170,
    flex: 1
  },
  {
    field: "type",
    headerName: "Property Type",
    minWidth: 140,
    flex: 1,
    sortable: false
  },
  {
    field: "product_class",
    headerName: "Product Class",
    minWidth: 140,
    flex: 1,
    sortable: false,
    valueGetter:
      (params: GridValueGetterParams) => params.row.product_category?.name ?? "N/A"
  },
  {
    field: "product_sub_class",
    headerName: "Product Sub-Class",
    sortable: false,
    minWidth: 160,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => "N/A" // there's no more subcategorie
  },
  {
    field: "price",
    headerName: "Unit Price",
    minWidth: 100,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => `$${params.row.price}`
  },
  {
    field: "unit_size",
    headerName: "Unit Size",
    minWidth: 110,
    flex: 1,
    valueGetter:
      (params: GridValueGetterParams) =>
        `${params.row.width}' x ${params.row.length}'`
  },
  {
    field: "revenue_class",
    headerName: "Revenue Class",
    minWidth: 220,
    flex: 1,
    sortable: false,
    valueGetter:
      (params: GridValueGetterParams) => params.row.revenue_class.name
  },
  {
    field: "actionIcons",
    headerName: "Actions",
    sortable: false,
    minWidth: 100,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => RenderDetailsButton(params)
  }
];

export default ManageOccupantsTableColumns;
