import { TaskRecurrence } from "src/enums/TaskRecurrence";

export const getTaskRecurrencePrettyName = (recurrenceId:number) => {
  switch (recurrenceId) {
    case TaskRecurrence.annually:
      return "Annually";
    case TaskRecurrence.biweekly:
      return "Biweekly";
    case TaskRecurrence.daily:
      return "Daily";
    case TaskRecurrence.endOfMonth:
      return "End of Month";
    case TaskRecurrence.monthly:
      return "Monthly";
    case TaskRecurrence.oneTime:
      return "One Time";
    case TaskRecurrence.quarterly:
      return "Quarterly";
    case TaskRecurrence.weekly:
      return "Weekly";
    default:
      return "Unknown";
  }
};
