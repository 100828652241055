import { Chip, Grid, Tooltip, Typography } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";
import DelinquencyAutomation from "src/models/DelinquencyAutomations";
import { Fee } from "../../../models/Fee";
import { Promotion } from "../../../models/Promotion";
import useStyles from "./ItemManagementCard.styles";

interface ItemManagementCardProps {
  item: Promotion | Fee | DelinquencyAutomation
  bottomRow?: ReactNode
  blurb?: ReactNode
  buttonGroup?: ReactNode
}

export const extraFacilitiesDisplay = (facilityCount: { id: number; name: string; facility_id: string; }[]) => {
  if (facilityCount.length === 2) {
    return "1 facility";
  }

  return `${facilityCount.length - 1} facilities`;
};

const ItemManagementCard: React.FC<ItemManagementCardProps> = ({
  item,
  bottomRow,
  buttonGroup,
  blurb
}): ReactElement => {
  const { classes } = useStyles({ active: item.is_active });

  const facilitiesDisplay = (item: Promotion) => {
    if (item.facilities) {
      return (
        <Grid
          lg={4}
          item
          container
          alignItems={"center"}
        >
          <Typography className={classes.cellTitle} noWrap>Facilities Applied: </Typography>
          <Chip
            data-testid={"item-chip"}
            key={item.facilities[0]?.id}
            label={`${item.facilities[0]?.facility_id} - Facility ${item.facilities[0]?.id}`}
            clickable={false}
            size={"small"}
            className={classes.facilityChip}
          />
          {item.facilities.length > 1 &&
          <Typography data-testid={"item-extra-facilities"} variant={"caption"}>
            + {extraFacilitiesDisplay(item.facilities)}
          </Typography>
          }
        </Grid>
      );
    }
  };

  return (
    <Grid container>
      <Grid
        item
        container
        xs={9}
        lg={10}
        direction={"column"}
        width={"1px"}
        className={classes.cardContent}
        p={2}
        my={1}
      >
        <Grid
          item
          container
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid
            item
            container
            xs={11}
            alignItems={"center"}
          >
            <Grid item xs={3}>
              <Tooltip title={item.name}>
                <Typography variant={"h6"} noWrap component={"div"}>{item.name}</Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={6} lg={5}>
              {blurb}
            </Grid>
            {facilitiesDisplay(item as Promotion)}
          </Grid>
          <Grid
            item
            container
            xs={1}
            pr={1}
            className={classes.itemStatus}
            justifyContent={"end"}
            data-testid={"item-status"}
          >
            <Typography className={classes.statusText}>{item.is_active ? "Active" : "Disabled"}</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={1}
          mt={1}
        >
          {bottomRow}
        </Grid>
      </Grid>
      {buttonGroup && <Grid
        container
        item
        lg={2}
        xs={3}
        pl={1}
        direction={"row"}
        justifyContent={"start"}
        alignContent={"center"}
      >
        {buttonGroup}
      </Grid>}
    </Grid>
  );
};

export default ItemManagementCard;
