import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { APIListResponse } from "../../../models/responses/APIListResponse";
import { RevenueClass } from "../../../models/RevenueClass";
import { RevenueClassesSliceState } from "./RevenueClassesSliceState";
import { RootState } from "../../rootStore";
import { getAllPaginatedRevenueClasses } from "../../thunks/revenueClass/getAllPaginated/getAllPaginatedRevenueClasses";
import { getAllRevenueClasses } from "../../thunks/revenueClass/getAll/getAllRevenueClasses";

import { APIFilter } from "../../../models/responses/APIFilter";

export const initialState: RevenueClassesSliceState = {
  revenueClasses: JSON.parse(sessionStorage.getItem("revenueClasses") as string) ?? [],
  paginationRevenueClasses: JSON.parse(sessionStorage.getItem("paginationRevenueClasses") as string) ?? [],
  revenueClassesLoading: false,
  revenueClassesError: "",
  revenueClassesFilter: {
    "filter[is_active]": "0,1"
  },
  disableRevenueClassOpenModal: false,
  unableToDisableRevenueClassOpenModal: false,
  fromRevenueClassPage: false
};

export const revenueClassesSlice = createSlice({
  name: "revenueClasses",
  initialState,
  reducers: {
    resetRevenueClassesSlice: (state) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("revenueClasses");
      sessionStorage.removeItem("revenueClassesFacility");
    },
    setRevenueClassesFilter: (state, action: PayloadAction<APIFilter>) => {
      state.revenueClassesFilter = action.payload;
    },
    setDisableRevenueClassesOpenModal: (state, action: PayloadAction<boolean>) => {
      state.disableRevenueClassOpenModal = action.payload;
    },
    setUnableToDisableRevenueClassOpenModal: (state, action: PayloadAction<boolean>) => {
      state.unableToDisableRevenueClassOpenModal = action.payload;
    },
    setFromRevenueClassPage: (state, action: PayloadAction<boolean>) => {
      state.fromRevenueClassPage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRevenueClasses.pending, (state) => {
        state.revenueClassesLoading = true;
      })
      .addCase(getAllRevenueClasses.fulfilled, (state, action: PayloadAction<APIListResponse<RevenueClass[]>>) => {
        state.revenueClasses = action.payload.data;
        state.revenueClassesLoading = false;
        sessionStorage.setItem("revenueClasses", JSON.stringify(state.revenueClasses));
      })
      .addCase(getAllRevenueClasses.rejected, (state, action) => {
        state.revenueClassesError = action.payload as string;
        state.revenueClassesLoading = false;
      })
      .addCase(getAllPaginatedRevenueClasses.pending, (state) => {
        state.revenueClassesLoading = true;
      })
      .addCase(
        getAllPaginatedRevenueClasses.fulfilled,
        (state, action: PayloadAction<APIListResponse<RevenueClass[]>>) => {
          state.paginationRevenueClasses = action.payload.data;
          state.revenueClassesLoading = false;
          sessionStorage.setItem("paginationRevenueClasses", JSON.stringify(action.payload.data));
        }
      )
      .addCase(getAllPaginatedRevenueClasses.rejected, (state, action) => {
        state.revenueClassesError = action.payload as string;
        state.revenueClassesLoading = false;
      });
  }
});

export const selectRevenueClasses = (state: RootState) => state.revenueClasses.revenueClasses;
export const selectPaginationRevenueClasses = (state: RootState) => state.revenueClasses.paginationRevenueClasses;
export const selectRevenueClassesLoading = (state: RootState) => state.revenueClasses.revenueClassesLoading;
export const selectRevenueClassesFilter = (state: RootState) => state.revenueClasses.revenueClassesFilter;
export const selectDisableRevenueClassOpenModal = (state: RootState) =>
  state.revenueClasses.disableRevenueClassOpenModal;
export const selectUnableToDisableRevenueClassOpenModal = (state: RootState) =>
  state.revenueClasses.unableToDisableRevenueClassOpenModal;
export const selectFromRevenueClassPage = (state: RootState) =>
  state.revenueClasses.fromRevenueClassPage;

export const {
  resetRevenueClassesSlice,
  setRevenueClassesFilter,
  setDisableRevenueClassesOpenModal,
  setUnableToDisableRevenueClassOpenModal,
  setFromRevenueClassPage
} = revenueClassesSlice.actions;

export default revenueClassesSlice.reducer;
