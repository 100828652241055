import { Button, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { NavLink } from "react-router-dom";
import React from "react";
import { selectOccupantDetailsLoading } from "src/store/reducers/occupantInformationSlice/occupantInformationSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import useStyles from "./EditOccupantEmergencyContactInformation.styles";
import {
  selectChangeOwnershipIsEnabled,
  setChangeOwnerShipStep
} from "src/store/reducers/occupantSlice/occupantSlice";

const EditOccupantEmergencyContactInformationFooterButtons = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const occupantInformationLoading = useAppSelector(selectOccupantDetailsLoading);
  const changeOwnershipIsEnabled = useAppSelector(selectChangeOwnershipIsEnabled);

  return (
    <Grid
      mt={1}
      container
      spacing={3}
      justifyContent={"flex-end"}
      item
      xs={12}
        >
      <Grid item>
        <Button
          variant={"text"}
          color={"error"}
          component={NavLink}
          to={"/occupants/manage-occupants"}
        >
          Cancel
        </Button>
      </Grid>
      <Grid item>
        {changeOwnershipIsEnabled &&
          (
            (
            <>
              <Button
                form={"next-occupant-step"}
                variant={"outlined"}
                className={classes.backButton}
                onClick={() => dispatch(setChangeOwnerShipStep(1))}
 >
                Back
              </Button>
            </>
            )
          )}
        <LoadingButton
          form={"edit-occupant-ec-form"}
          variant={"contained"}
          className={classes.saveButton}
          type={"submit"}
          data-testid={"edit-occupant-ec-button-submit"}
          loading={occupantInformationLoading}
          disabled={occupantInformationLoading}
            >
          {changeOwnershipIsEnabled ? "Submit" : "Save"}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default EditOccupantEmergencyContactInformationFooterButtons;
