import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "PDFViewer" })((theme) => ({
  iframe: {
    border: "1px solid black",
    width: "auto",
    boxShadow: "none",
    minHeight: "40rem",
    minWidth: "30rem"
  }
}));

export default useStyles;
