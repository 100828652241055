import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSearchParams } from "../../../../utils/getSearchParams/getSearchParams";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getAllProductTypes = createAsyncThunk(
  "getAllProductTypes",
  (facilityId: number | string | void, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const params = {
      params: {
        ...getSearchParams(state, false),
        "with[]": "units"
      }
    };

    return axios.get(`/api/v1/facilities/${facilityId}/product-types`, params)
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = "There was an error getting the Product Types";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
