import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "ProcessMoveOut" })((theme) => ({
  calculatorButtonWrapper: {
    alignSelf: "end",
    flexGrow: 1
  },
  fullWidth: {
    width: "100%"
  },
  calculatorButton: {
    textTransform: "capitalize",
    background: theme.palette.primary.contrastText,
    color: theme.palette.common.white
  },
  noArrow: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    },
    "input[type=number]": {
      MozAppearance: "textfield"
    }
  },
  cellWrapper: {
    flexGrow: 1
  },
  containerCheckbox: {
    height: "56px"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    margin: "10%"
  },
  title: {
    fontWeight: "bold",
    textAlign: "center"
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.8rem"
  }
}));

export default useStyles;
