import { InputAdornment, TextField } from "@mui/material";
import React, { ReactElement, useEffect } from "react";
import { Breadcrumb } from "../../../models/Breadcrumb";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import RefundModal from "./RefundModal/RefundModal";
import { Search } from "@mui/icons-material";
import SummaryOfSalesTable from "./SummaryOfSalesTable/SummaryOfSalesTable";
import { SyntheticInputEvent } from "react-number-format/types/types";
import TableWrapper from "../../../layouts/TableWrapper/TableWrapper";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { debounce } from "lodash";
import { getTransactions } from "src/store/thunks/transactions/getTransaction/getTransactions";
import { resetTablesSlice } from "../../../store/reducers/tablesSlice/tablesSlice";
import { searchTransaction } from "src/store/thunks/transactions/searchTransaction/searchTransaction";
import { useAppDispatch } from "src/store/hooks";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Merchandise"
  },
  {
    name: "Summary of Sales",
    bold: true
  }
];

const SummaryOfSales: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getTransactions());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetTablesSlice());
    };
  }, []);

  const debounceSearch = debounce((text) => {
    if (text.length) {
      return dispatch(searchTransaction({ searchValue: text }));
    }
    return dispatch(getTransactions());
  }, 600);

  const handleChange = (e: SyntheticInputEvent) => debounceSearch(e.target.value);

  const searchBar = (
    <TextField
      fullWidth
      id={"search-transactions-table"}
      placeholder={"Search"}
      size={"small"}
      InputProps={{
        endAdornment: (
          <InputAdornment position={"end"}>
            <Search />
          </InputAdornment>
        )
      }}
      variant={"outlined"}
      name={"transactionsTableSearch"}
      onChange={handleChange}
    />
  );

  const pageHeader = <PageHeader title={"Summary of Sales"} breadcrumbs={breadcrumbs} />;

  return (
    <ViewWrapper pageHeader={pageHeader}>
      <TableWrapper title={"Transactions"} searchBar={searchBar} table={<SummaryOfSalesTable />} />
      <RefundModal />
    </ViewWrapper>
  );
};

export default SummaryOfSales;
