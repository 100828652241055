import React, { ReactElement } from "react";
import {
  selectTransactions,
  selectTransactionsLoading,
  selectTransactionsSearchValue
} from "src/store/reducers/transactionSlice/transactionSlice";
import {
  selectSelectedLedger
} from "src/store/reducers/ledgersSlice/ledgersSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import EmptyTable from "../../../components/ui/PMSTable/EmptyTable/EmptyTable";
import PMSTable from "../../../components/ui/PMSTable/PMSTable";
import IssueRefundTableColumns from "./IssueRefundTableColumns/IssueRefundTableColumns";
import { getUnitTransactions } from "src/store/thunks/transactions/getUnitTransaction/getUnitTransactions";
import { searchUnitTransactions } from "src/store/thunks/transactions/searchUnitTransactions/searchUnitTransactions";

const SummaryOfSalesTable: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();

  const selectedLedger = useAppSelector(selectSelectedLedger);
  const transactions = useAppSelector(selectTransactions);
  const transactionsLoading = useAppSelector(selectTransactionsLoading);
  const searchValue = useAppSelector(selectTransactionsSearchValue);

  const handlePaginationUpdate = () => {
    if (!searchValue.length) {
      dispatch(getUnitTransactions({ ledgerId: String(selectedLedger!.id) }));
      return;
    }

    dispatch(searchUnitTransactions({ ledgerId: String(selectedLedger!.id), searchValue: searchValue }));
  };

  const emptyTable = () => (
    <EmptyTable
      collectionNotFound={"transactions"}
      value={"transaction"}
    />
  );

  return (
    <PMSTable
      data-testid={"refund-transactions-table"}
      columns={IssueRefundTableColumns()}
      rows={transactions}
      emptyTableDisplay={emptyTable}
      loading={transactionsLoading}
      onPaginationUpdate={handlePaginationUpdate}
      sortingMode={"client"}
    />
  );
};

export default SummaryOfSalesTable;
