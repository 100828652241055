import React from "react";
import { useAppSelector } from "src/store/hooks";
import { selectPaymentAmount, selectSelectedDelinquent } from "src/store/reducers/collectionsSlice/collectionsSlice";
import {
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import useStyles from "./DelinquentPaymentTable.styles";
import PMSTable from "src/components/ui/PMSTable/PMSTable";
import DelinquentPaymentTableColumns from "./DelinquentPaymentTableColumns";
import EmptyTable from "src/components/ui/PMSTable/EmptyTable/EmptyTable";

const DelinquentPaymentTable = () => {
  const { classes } = useStyles();
  const selectedDelinquent = useAppSelector(selectSelectedDelinquent);
  const paymentAmount = useAppSelector(selectPaymentAmount);

  const emptyTable = () => (
    <EmptyTable
      collectionNotFound={`data`}
      value={"data"}
    />
  );

  const rows = [{
    id: 1,
    unit_name: selectedDelinquent?.unit_name,
    occupant_name: selectedDelinquent?.occupant_name,
    deal_rate: selectedDelinquent?.ledger.monthly_rate?.toFixed(2),
    due_date: selectedDelinquent?.ledger.date_paid_thru,
    total: selectedDelinquent?.balance
  }];

  return (
    <Box className={classes.table}>
      <PMSTable
        data-testid={"quick-payment-table"}
        columns={DelinquentPaymentTableColumns()}
        rows={rows}
        emptyTableDisplay={emptyTable}
        hideFooter
    />
      <Box
        className={classes.total}>
        <Typography variant={"h6"} fontWeight={"bold"}>
          Total
        </Typography>
        <Typography fontWeight={"bold"}>$ {paymentAmount || selectedDelinquent?.balance}</Typography>
      </Box>
    </Box>
  );
};

export default DelinquentPaymentTable;
