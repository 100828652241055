import { Box, Button } from "@mui/material";
import {
  NavLink,
  useParams
} from "react-router-dom";
import React, { ReactElement, useEffect } from "react";
import { ArrowBack } from "@mui/icons-material";
import { Breadcrumb } from "../../../models/Breadcrumb";
import DocumentForm from "../DocumentForm/DocumentForm";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import ViewWrapper from "../../../layouts/ViewWrapper/ViewWrapper";
import { getByIdDocumentTemplate } from "src/store/thunks/documentTemplates/getById/getByIdDocumentTemplate";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import useStyles from "./EditDocument.styles";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const EditDocument: React.FC = (): ReactElement => {
  const breadcrumbs: Breadcrumb[] = [
    {
      name: "Document Management"
    },
    {
      name: "Edit Document",
      bold: true
    }
  ];

  const pageHeader = <PageHeader title={"Edit Document"} breadcrumbs={breadcrumbs} />;
  const { classes } = useStyles();
  const params = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const permissions = useAppSelector(selectFacilityPermissions);
  const documentLibraryPermissions = createPermissions(permissions, "document_library");

  useEffect(() => {
    if (params.id) {
      dispatch(getByIdDocumentTemplate(params.id));
    }
  }, [params]);

  if (documentLibraryPermissions.update) {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <Box mt={3}>
          <Button
            variant={"text"}
            className={classes.goBackButton}
            startIcon={<ArrowBack />}
            component={NavLink}
            to={"/documents/document-library"}
        >
            Go Back To Document Library
          </Button>
        </Box>
        <DocumentForm editId={+params.id!} />
      </ViewWrapper>
    );
  } else {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <h1>You do not have permission to view this page.</h1>
      </ViewWrapper>
    );
  }
};

export default EditDocument;
