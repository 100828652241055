import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";
import { RootState } from "src/store/rootStore";
import { getSearchParams } from "src/utils/getSearchParams/getSearchParams";

export const searchUnitTransactions = createAsyncThunk(
  "searchUnitTransactions",
  (
    {
      ledgerId, searchValue
    }: { ledgerId: string, searchValue?: string }, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const facilityId = state.selectedFacility.selectedFacility.id;
    const transactionsFilter = state.transactions.transactionsFilter;

    const filter = {
      ledger_id: ledgerId,
      not_originated_from: 4,
      payment_method: [1, 3, 4]
    };

    const searchParams = {
      params: {
        ...getSearchParams(state, true),
        ...filter
      }
    };

    if (transactionsFilter) {
      searchParams.params = { ...searchParams.params, ...transactionsFilter };
    }

    return axios.get(
      `/api/v1/facilities/${facilityId}/transactions?filter=${searchValue}`,
      searchParams
    )
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = `There was an error getting the transactions`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
