import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { APIListResponse } from "../../../models/responses/APIListResponse";
import { MerchandiseCategoriesSliceState } from "./MerchandiseCategoriesSliceState";
import { MerchandiseCategory } from "../../../models/MerchandiseCategory";
import { RootState } from "../../rootStore";
import { getAllMerchandiseCategories } from "../../thunks/merchandiseCategory/getAll/getAllMerchandiseCategories";
import { getAllRetailMerch } from "src/store/thunks/merchandise/getAllRetailMerch/getAllRetailMerch";

export const initialState: MerchandiseCategoriesSliceState = {
  merchandiseCategories: [],
  merchandiseCategoriesLoading: false,
  merchandiseCategoriesError: ""
};

export const merchandiseCategoriesSlice = createSlice({
  name: "merchandiseCategories",
  initialState,
  reducers: {
    resetMerchandiseCategoriesSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllMerchandiseCategories.pending, (state) => {
        state.merchandiseCategoriesLoading = true;
      })
      .addCase(getAllMerchandiseCategories.fulfilled, (
        state, action: PayloadAction<APIListResponse<MerchandiseCategory[]>>
      ) => {
        state.merchandiseCategoriesLoading = false;
        state.merchandiseCategories = action.payload.data;
      })
      .addCase(getAllMerchandiseCategories.rejected, (state, action) => {
        state.merchandiseCategoriesLoading = false;
        state.merchandiseCategoriesError = action.payload as string;
      });
  }
});

export const selectMerchandiseCategories = (state: RootState) => state.merchandiseCategories.merchandiseCategories;
export const selectMerchandiseCategoriesLoading = (state: RootState) =>
  state.merchandiseCategories.merchandiseCategoriesLoading;

export const {
  resetMerchandiseCategoriesSlice
} = merchandiseCategoriesSlice.actions;

export default merchandiseCategoriesSlice.reducer;
