import React from "react";
import { Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import ForkLeftIcon from "@mui/icons-material/ForkLeft";
import {
  selectChangeOwnershipIsEnabled,
  selectOccupant,
  setChangeOwnershipIsEnabled,
  setChangeOwnershipModalOpened,
  setUndoChangeOwnershipModalOpened
} from "src/store/reducers/occupantSlice/occupantSlice";
import ReplayIcon from "@mui/icons-material/Replay";
import useStyles from "./OwnershipButtons.styles";
import { resetChangeOwnership } from "src/store/reducers/changeOwnership/changeOwnership";

export const OwnershipButtons = ({ isOccupantInformation = false }: { isOccupantInformation?: boolean }) => {
  const dispatch = useAppDispatch();
  const changeOwnershipIsEnabled = useAppSelector(selectChangeOwnershipIsEnabled);
  const occupant = useAppSelector(selectOccupant);
  const { classes } = useStyles();

  return (
    <>
      {!changeOwnershipIsEnabled
        ? (
          <>
            {isOccupantInformation && (
            <Button
              variant={"contained"}
              color={"error"}
              disabled={!occupant?.ledgers?.some((ledger) => ledger.is_active)}
              data-testid={"change-ownership-button"}
              className={classes.changeOfOwnershipButton}
              onClick={() => {
                dispatch(resetChangeOwnership());
                dispatch(setChangeOwnershipModalOpened(true));
              }}
          >
              <ForkLeftIcon fontSize={"small"} className={classes.icon} /> Change of ownership
            </Button>

            )}
          </>

          )
        : (
          <Button
            variant={"contained"}
            color={"error"}
            className={classes.changeOfOwnershipButton}
            onClick={() => dispatch(setUndoChangeOwnershipModalOpened(true))}
          >
            <ReplayIcon
              fontSize={"small"}
              className={classes.iconRotated} /> Undo change
          </Button>
          )}
    </>
  );
};
