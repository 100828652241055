import { makeStyles } from "../../../../../makeStyles";

const useStyles = makeStyles<{isSelected: boolean}>({ label: "ProductTypesRow" })((theme, { isSelected }) => ({
  productTypeRow: {
    outline: `.1rem solid ${theme.palette.background.default}`
  },
  pointerActive: {
    cursor: "pointer"
  },
  noProductTypesRow: {
    backgroundColor: theme.palette.background.default
  },
  activeProductTypeRow: {
    "& > *": {
      backgroundColor: isSelected ? theme.palette.active : theme.palette.background.default,
      color: isSelected ? theme.palette.background.paper : theme.palette.text.primary
    },
    "&:hover > *": {
      backgroundColor: theme.palette.hover,
      color: theme.palette.background.paper
    }
  }
}));

export default useStyles;
