import {
  Autocomplete,
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  Paper,
  TextField,
  Typography
} from "@mui/material";
import { NavLink, useParams } from "react-router-dom";
import React, { FormEvent, ReactElement, useEffect, useState } from "react";
import {
  selectAutoBillTemplateSetting,
  selectInvoiceTemplateSetting,
  selectReceiptTemplateSetting
}
  from "src/store/reducers/globalSettingInformationSlice/globalSettingInformationSlice";
import {
  selectDocumentTemplateLoading,
  selectDocumentTemplates
} from "src/store/reducers/documentsSlice/documentsSlice";
import {
  selectFacilityAutoBillTemplateSetting,
  selectFacilityDealDaysLimitSetting,
  selectFacilityEmergencyGateOverrideSetting,
  selectFacilityGateSystemSetting,
  selectFacilityInvoiceTemplateSetting,
  selectFacilityMoveInDaysLimitSetting,
  selectFacilityReceiptTemplateSetting,
  selectFacilityReservationDaysLimitSetting
} from "src/store/reducers/facilitySettingInformationSlice/facilitySettingInformationSlice";
import {
  selectFacilityKeypadAccesses,
  selectFacilityTimeAccesses,
  setFacilityAccessTableModal,
  setSelectedAccessTable
}
  from "src/store/reducers/facilitySlice/facilitySlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";

import AccessTable from "./AccessTable/AccessTable";
import { Add } from "@mui/icons-material";
import { Box } from "@mui/system";
import { DocumentTemplate } from "src/models/DocumentTemplate";
import FacilitySettingsAlert from "./helpers/FacilitySettingsAlert/FacilitySettingsAlert";
import { LoadingButton } from "@mui/lab";
import NewAccessModal from "./NewAccessModal/NewAccessModal";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import RemoveAccessModal from "./RemoveAccessModal/RemoveAccessModal";
import { SyntheticInputEvent } from "react-number-format/types/types";
import buildFacilitySettingsPayload from "./helpers/buildFacilitySettingsPayload";
import clsx from "clsx";
import {
  getAllDocumentTemplatesAllFacilities
} from "src/store/thunks/documentTemplates/getAll/getAllDocumentTemplatesAllFacilities";
import { getAllFacilitySettings } from "src/store/thunks/facilitySetting/getAll/getAllFacilitySettings";
import { getAllGlobalSettings } from "src/store/thunks/globalSetting/getAll/getAllGlobalSettings";
import { inputError } from "src/utils/showInputError/showInputError";
import { updateFacilitySettings } from "src/store/thunks/facilitySetting/batchUpdate/batchUpdateFacilitySettings";
import { useFormik } from "formik";
import useStyles from "./FacilitySettings.styles";
import validationSchema from "./helpers/facilitySettingsFormValidation";

export interface FacilitySettingsUpdateValues {
  reservationDateLimit: string
  dealDate: string
  moveInDate: string
  facilityReceiptTemplate: string
  facilityInvoiceTemplate: string
  facilityAutobillTemplate: string
  gateSystem: number
  emergencyGateOverride?: number
  timeAccess: number
  keypadAccess: number
}

const FacilitySettings = ({ facilityId }: {facilityId: number}): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();

  const documentTemplates = useAppSelector(selectDocumentTemplates);

  const documentTemplatesLoading = useAppSelector(selectDocumentTemplateLoading);
  //id:1
  const reservationDaysLimitSettingFacility = useAppSelector(selectFacilityReservationDaysLimitSetting);
  //id:2
  const dealDaysLimitSettingFacility = useAppSelector(selectFacilityDealDaysLimitSetting);
  //id:3
  const receiptTemplateSetting = useAppSelector(selectReceiptTemplateSetting);
  const receiptTemplateSettingFacility = useAppSelector(selectFacilityReceiptTemplateSetting);
  //id:4
  const invoiceTemplateSetting = useAppSelector(selectInvoiceTemplateSetting);
  const invoiceTemplateSettingFacility = useAppSelector(selectFacilityInvoiceTemplateSetting);
  //id:5
  const autoBillTemplateSetting = useAppSelector(selectAutoBillTemplateSetting);
  const autoBillTemplateSettingFacility = useAppSelector(selectFacilityAutoBillTemplateSetting);

  const gateSystemSettingFacility = useAppSelector(selectFacilityGateSystemSetting);

  const emergencyGateOverrideSettingFacility = useAppSelector(selectFacilityEmergencyGateOverrideSetting);

  const moveInDaysLimitSettingFacility = useAppSelector(selectFacilityMoveInDaysLimitSetting);

  const facilityTimeAccesses = useAppSelector(selectFacilityTimeAccesses);

  const facilityKeypadAccesses = useAppSelector(selectFacilityKeypadAccesses);

  const getFacilitySettingsData = async() => {
    await dispatch(getAllFacilitySettings(String(params.id)));
    await dispatch(getAllGlobalSettings());
    await dispatch(getAllDocumentTemplatesAllFacilities());
  };

  useEffect(() => {
    getFacilitySettingsData();
  }, []);

  const initialValues: FacilitySettingsUpdateValues = {
    reservationDateLimit: reservationDaysLimitSettingFacility?.value ?? "",
    dealDate: dealDaysLimitSettingFacility?.value ?? "",
    moveInDate: moveInDaysLimitSettingFacility?.value ?? "",
    facilityReceiptTemplate: receiptTemplateSettingFacility?.value ?? "",
    facilityInvoiceTemplate: invoiceTemplateSettingFacility?.value ?? "",
    facilityAutobillTemplate: autoBillTemplateSettingFacility?.value ?? "",
    gateSystem: gateSystemSettingFacility?.value ? Number(gateSystemSettingFacility?.value) : 0,
    emergencyGateOverride: emergencyGateOverrideSettingFacility?.value
      ? Number(emergencyGateOverrideSettingFacility?.value)
      : undefined,
    timeAccess: 0,
    keypadAccess: 0
  };

  const [autocompleteOpen, setAutocompleteOpen] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (!documentTemplates.length) {
        // we should probably not allow submission if the settings if the templates fail to fetch!
      }

      const payload = buildFacilitySettingsPayload(
        receiptTemplateSettingFacility?.id ?? receiptTemplateSetting?.id!,
        invoiceTemplateSettingFacility?.id ?? invoiceTemplateSetting?.id!,
        autoBillTemplateSettingFacility?.id ?? autoBillTemplateSetting?.id!,
        values,
        gateSystemSettingFacility?.id!,
        reservationDaysLimitSettingFacility?.id!,
        dealDaysLimitSettingFacility?.id!,
        emergencyGateOverrideSettingFacility?.id!,
        moveInDaysLimitSettingFacility?.id!
      );

      dispatch(updateFacilitySettings({ facilityId: String(params.id), data: payload }));
    }
  });

  const LoadingContainer = () => {
    return (
      <Grid
        container
        justifyContent={"center"} alignContent={"center"}
        height={500} >
        <CircularProgress size={100} />
      </Grid>
    );
  };

  const { handleChange, touched, errors, values, handleSubmit, setFieldValue } = formik;

  const findInvoiceTemplate = documentTemplates.find(template => template.id === +values.facilityInvoiceTemplate);
  const findReceiptTemplate = documentTemplates.find(template => template.id === +values.facilityReceiptTemplate);
  const findAutobillTemplate = documentTemplates.find(template => template.id === +values.facilityAutobillTemplate);

  const handleChangeTemplate = (option: DocumentTemplate | null, templateName: string) => {
    if (option?.id) {
      return setFieldValue(templateName, String(option?.id));
    };

    setFieldValue(templateName, undefined);
  };

  return (
    <Box>
      <FacilitySettingsAlert />
      <Box mt={1} component={Paper} p={4}>
        {!documentTemplatesLoading
          ? <form
              id={"facility-settings-form"}
              onSubmit={(e: FormEvent) => {
                e.preventDefault();
                e.stopPropagation();
                handleSubmit();
              }}
        >
            <Grid container >
              <Grid item xs={12}>
                <Typography gutterBottom variant={"h5"}>
                  Facility Settings
                </Typography>
              </Grid>
              <Grid mb={1} item xs={12}>
                <Divider />
              </Grid>
              <Typography py={2} className={classes.blueText}>
                Date Allowance Settings
              </Typography>
              <Grid mb={1} item xs={12}>
                <InputLabel className={classes.labelWeight} htmlFor={"reservationDateLimit"}>
                  Reservation Date Limit
                </InputLabel>
                <Typography >
                  When the number of days is entered into this facility setting feature,
                  no reservation date can be set beyond this total number of days in the future.
                </Typography>
                <TextField
                  id={"reservationDateLimit"}
                  name={"reservationDateLimit"}
                  type={"number"}
                  value={values.reservationDateLimit}
                  onChange={handleChange}
                  error={(Boolean(errors.reservationDateLimit))}
                  onInput={(e: SyntheticInputEvent) => {
                    const val = (e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3));
                    return val;
                  }}
                />
                <FormHelperText error>
                  {errors.reservationDateLimit}
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.labelWeight} htmlFor={"dealDate"}>Lead Date Limit</InputLabel>
                <Typography >
                  When the number of days is entered into this facility setting feature,
                  no lead date can be set beyond this total number of days in the future.
                </Typography>
                <TextField
                  id={"dealDate"}
                  name={"dealDate"}
                  type={"number"}
                  value={values.dealDate}
                  onChange={handleChange}
                  error={(Boolean(errors.dealDate))}
                  onInput={(e: SyntheticInputEvent) => {
                    const val = (e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3));
                    return val;
                  }}
                />
                <FormHelperText error>
                  {errors.dealDate}
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.labelWeight} htmlFor={"moveInDate"}>Move-In Date Limit</InputLabel>
                <Typography >
                  When the number of days is entered into this facility setting feature,
                  no move-in date can be set beyond this total number of days in the future.
                </Typography>
                <TextField
                  id={"moveInDate"}
                  name={"moveInDate"}
                  type={"number"}
                  value={values.moveInDate}
                  onChange={handleChange}
                  error={(Boolean(errors.moveInDate))}
                  onInput={(e: SyntheticInputEvent) => {
                    const val = (e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3));
                    return val;
                  }}
                />
                <FormHelperText error>
                  {errors.moveInDate}
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid mt={2}>
              <Typography pb={2} className={classes.blueText}>
                Document Template Settings
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  <Typography className={classes.labelWeight}>
                    Facility Templates
                  </Typography>
                </Grid>
                <Grid container item xs={6}>
                  <Grid item xs={12} pb={2}>
                    <InputLabel
                      className={classes.labelWeight}
                      htmlFor={"facilityReceiptTemplate"}
                    >
                      Facility Receipt Template
                    </InputLabel>
                    <Autocomplete
                      onOpen={() => setAutocompleteOpen(true)}
                      onClose={() => setAutocompleteOpen(false)}
                      id={"facilityReceiptTemplate"}
                      defaultValue={findReceiptTemplate}
                      value={findReceiptTemplate}
                      options={documentTemplates}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option) => (
                        <Box component={"li"} {...props} key={option.id}>
                          {option.name}
                        </Box>
                      )}
                      onChange={(event, option) => handleChangeTemplate(option, "facilityReceiptTemplate")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={clsx({ [classes.autocompleteError]: errors.facilityReceiptTemplate })}
                      />
                      )}
                      />
                    <FormHelperText error>
                      {errors.facilityReceiptTemplate}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} pb={2}>
                    <InputLabel className={classes.labelWeight} htmlFor={"facilityInvoiceTemplate"}>
                      Facility Invoice Template
                    </InputLabel>
                    <Autocomplete
                      onOpen={() => setAutocompleteOpen(true)}
                      onClose={() => setAutocompleteOpen(false)}
                      id={"facilityInvoiceTemplate"}
                      defaultValue={findInvoiceTemplate}
                      value={findInvoiceTemplate}
                      options={documentTemplates}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option) => (
                        <Box component={"li"} {...props} key={option.id}>
                          {option.name}
                        </Box>
                      )}
                      onChange={(event, option) => handleChangeTemplate(option, "facilityInvoiceTemplate")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={clsx({ [classes.autocompleteError]: errors.facilityInvoiceTemplate })}
                      />
                      )}
                      />
                    <FormHelperText error>
                      {errors.facilityInvoiceTemplate}
                    </FormHelperText>

                  </Grid>
                  <Grid item xs={12} pb={2}>
                    <InputLabel className={classes.labelWeight} htmlFor={"facilityAutobillTemplate"}>
                      Facility Auto-Bill Template
                    </InputLabel>
                    <Autocomplete
                      onOpen={() => setAutocompleteOpen(true)}
                      onClose={() => setAutocompleteOpen(false)}
                      id={"facilityAutobillTemplate"}
                      defaultValue={findAutobillTemplate}
                      value={findAutobillTemplate}
                      options={documentTemplates}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option) => (
                        <Box component={"li"} {...props} key={option.id}>
                          {option.name}
                        </Box>
                      )}
                      onChange={(event, option) => handleChangeTemplate(option, "facilityAutobillTemplate")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={clsx({ [classes.autocompleteError]: errors.facilityAutobillTemplate })}
                      />
                      )}
                      />
                    <FormHelperText error>
                      {errors.facilityAutobillTemplate}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Grid>
              <Grid my={1} container>
                <Grid item xs={6}>
                  <Typography className={classes.labelWeight}>
                    Communication Templates
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Grid item xs={12} pb={2}>
                    <InputLabel
                      className={classes.labelWeight}
                      htmlFor={"eSignEmailTemplate"}
                  >
                      E-Sign Email Template
                    </InputLabel>
                    <Autocomplete
                      disableClearable={true}
                      disabled={true}
                      onOpen={() => setAutocompleteOpen(true)}
                      onClose={() => setAutocompleteOpen(false)}
                      id={"eSignEmailTemplate"}
                      options={[]}
                      renderInput={(params) => (
                        <TextField {...params} placeholder={"- Default -"} />
                      )}
                      />
                  </Grid>
                  <Grid item xs={12} pb={2}>
                    <InputLabel
                      className={classes.labelWeight}
                      htmlFor={"pastDueEmailTemplate"}
                    >
                      Past Due Email Template
                    </InputLabel>
                    <Autocomplete
                      disableClearable={true}
                      disabled={true}
                      id={"pastDueEmailTemplate"}
                      noOptionsText={"- Default -"}
                      options={[]}
                      placeholder={"- Default -"}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={"- Default -"}
                      />
                      )}
                      />

                  </Grid>
                  <Grid item xs={12} pb={2}>
                    <InputLabel className={classes.labelWeight} htmlFor={"quickPayEmailTemplate"}>
                      QuickPay Email Template
                    </InputLabel>
                    <Autocomplete
                      disableClearable={true}
                      disabled={true}
                      onOpen={() => setAutocompleteOpen(true)}
                      onClose={() => setAutocompleteOpen(false)}
                      id={"quickPayEmailTemplate"}
                      options={[]}
                      renderInput={(params) => (
                        <TextField {...params} placeholder={"- Default -"} />
                      )}
                      />
                  </Grid>
                  <Grid item xs={12} pb={2}>
                    <InputLabel className={classes.labelWeight} htmlFor={"smsTemplate"}>
                      SMS Template
                    </InputLabel>
                    <Autocomplete
                      disableClearable={true}
                      disabled={true}
                      onOpen={() => setAutocompleteOpen(true)}
                      onClose={() => setAutocompleteOpen(false)}
                      id={"smsTemplate"}
                      options={[]}
                      renderInput={(params) => (
                        <TextField {...params} placeholder={"- Default -"} />
                      )}
                      />
                  </Grid>

                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                item
                direction={"column"}
                xs={4}
              >
                <Grid item xs={12}>
                  <Typography py={2} className={classes.blueText}>
                    Gate Settings
                  </Typography>
                </Grid>
                <Grid container item xs={4}>
                  <InputLabel
                    className={classes.labelWeight}
                    htmlFor={"gateSystem"}
                  >
                    Gate System
                  </InputLabel>
                  <PMSSelect
                    id={"gateSystem"}
                    value={values.gateSystem}
                    changeHandler={handleChange}
                    name={"gateSystem"}
                  >
                    <option value={0} disabled={true}> - Default -</option>
                    <option value={1}>PTI</option>
                    <option value={2}>Door King</option>
                    <option value={3}>Store Guard</option>
                  </PMSSelect>
                </Grid>
                <Grid item xs={4}>
                  <InputLabel
                    className={classes.labelWeight}
                    htmlFor={"emergencyGateOverride"}
                  >
                    Emergency Gate Override
                  </InputLabel>
                  <TextField
                    // disabled={true}
                    id={"emergencyGateOverride"}
                    value={values.emergencyGateOverride}
                    name={"emergencyGateOverride"}
                    onChange={handleChange}
                    type={"text"}
                    helperText={inputError("emergencyGateOverride", touched, errors).helperText}
                    error={inputError("emergencyGateOverride", touched, errors).error}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                item
                direction={"column"}
                xs={6}
              >
                <Grid item xs={6} mt={2}>
                  <Grid
                    item
                    container
                    flexDirection={"row"}
                    alignItems={"center"}
                    mb={1}
                    >
                    <InputLabel
                      className={classes.labelWeight}
                      htmlFor={"timeAccess"}
                  >
                      Time Access
                    </InputLabel>
                    <Button
                      className={classes.addButton}
                      startIcon={<Add/>}
                      onClick={async() => {
                        await dispatch(setSelectedAccessTable(null));
                        await dispatch(setFacilityAccessTableModal({ open: true, type: "timeAccess" }));
                      }}
                    >
                      New Time Access
                    </Button>
                  </Grid>
                  <AccessTable
                    items={facilityTimeAccesses}
                    value={"timeAccesses"}
                    collection={"Time Accesses"}
                  />
                </Grid>
                <Grid item xs={6} mt={2}>
                  <Grid
                    item
                    container
                    flexDirection={"row"}
                    alignItems={"center"}
                    mb={1}
                    >
                    <InputLabel
                      className={classes.labelWeight}
                      htmlFor={"timeAccess"}
                  >
                      Keypad Access
                    </InputLabel>
                    <Button
                      className={classes.addButton}
                      startIcon={<Add/>}
                      onClick={async() => {
                        await dispatch(setSelectedAccessTable(null));
                        await dispatch(setFacilityAccessTableModal({ open: true, type: "keypadAccess" }));
                      }}
                    >
                      New Keypad Access
                    </Button>
                  </Grid>
                  <AccessTable
                    items={facilityKeypadAccesses}
                    value={"keypadAccesses"}
                    collection={"Keypad Accesses"}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              justifyContent={"flex-end"}
              display={"flex"}
              alignItems={"center"}
              >
              <Grid gap={2} display={"flex"} justifyContent={"flex-end"}>
                <Button
                  variant={"text"}
                  color={"error"}
                  component={NavLink}
                  to={"/facility-management/manage-facilities"}
                  >
                  Cancel
                </Button>
                <LoadingButton
                  disabled={Boolean(Object.keys(errors).length) || documentTemplatesLoading || autocompleteOpen}
                  variant={"contained"}
                  className={classes.actionButton}
                  id={"facility-settings-form"}
                  type={"submit"}
                  >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
            <NewAccessModal facilityId={facilityId}/>
            <RemoveAccessModal facilityId={facilityId}/>
          </form>
          : <LoadingContainer />
      }
      </Box>
    </Box>
  );
};

export default FacilitySettings;
