import { CalendarRecurrence } from "../../models/CalendarRecurrence";
import { Task } from "../../models/Task";
import { TaskRecurrence } from "../../enums/TaskRecurrence";
import { generateDateAndTime } from "./generateDateAndTime";

export const generateRecurrence = (task: Task): CalendarRecurrence | undefined => {
  // https://github.com/jakubroztocil/rrule <--- documentation for rrule.

  if (task.recurrence === TaskRecurrence.oneTime) {
    // does not need a recurrence schema; do nothing
    return;
  }

  if (task.recurrence === TaskRecurrence.daily) {
    return {
      freq: "daily",
      dtstart: generateDateAndTime(task)
    };
  }

  if (task.recurrence === TaskRecurrence.weekly) {
    return {
      freq: "weekly",
      dtstart: generateDateAndTime(task)
    };
  }

  if (task.recurrence === TaskRecurrence.biweekly) {
    return {
      freq: "weekly",
      interval: 2,
      dtstart: generateDateAndTime(task)
    };
  }

  if (task.recurrence === TaskRecurrence.monthly) {
    return {
      freq: "monthly",
      interval: 1,
      dtstart: generateDateAndTime(task)
    };
  }

  if (task.recurrence === TaskRecurrence.endOfMonth) {
    return {
      freq: "monthly",
      bymonthday: -1,
      dtstart: generateDateAndTime(task)
    };
  }

  if (task.recurrence === TaskRecurrence.quarterly) {
    return {
      freq: "monthly",
      interval: 3,
      dtstart: generateDateAndTime(task)
    };
  }

  if (task.recurrence === TaskRecurrence.annually) {
    return {
      freq: "yearly",
      interval: 1,
      dtstart: generateDateAndTime(task)
    };
  }
};
