import * as Yup from "yup";
import { AnyObject } from "yup/lib/types";
import moment from "moment";

const testValidStoreTimes = (item: string | undefined, ctx: Yup.TestContext<AnyObject>) => {
  //im pretty sure we want validation off if these are selected.
  if (ctx.parent.is_closed_for_the_day || ctx.parent.is_open_24_hours) {
    return true;
  }

  if (moment(item).isBefore(moment(ctx.parent.open))) {
    return ctx.createError({ message: "Closing time must be after opening time" });
  }

  return true;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Marketing Name is required."),
  legal_name: Yup.string().required("Legal Name is required."),
  district: Yup.string().nullable().required("District is required."),
  district_region: Yup.string().nullable().required("Region is required."),
  area: Yup.string().nullable().required("Area is required."),
  facility_id: Yup.string().required("Location code is required."),
  address: Yup.string().required("Address Line 1 is required."),
  city: Yup.string().required("City is required."),
  region: Yup.string().required("State is required."),
  latitude: Yup
    .string()
    .nullable()
    .notRequired()
    .when("latitude", {
      is: (value: string) => value?.length,
      then: (rule) => rule.test("Lat is valid",
        (item: string | null | undefined, ctx: Yup.TestContext<AnyObject>) => {
          const input = parseFloat(item as string);

          const isLatitude = (num: number) => isFinite(num) && Math.abs(num) <= 90;

          if (isLatitude(input)) {
            return true;
          }

          return ctx.createError({ message: "Latitude is invalid." });
        })
    }),
  longitude: Yup.string().nullable().notRequired()
    .when("longitude", {
      is: (value: string) => value?.length,
      then: (rule) => rule.test("Long is valid",
        (item: string | null | undefined, ctx: Yup.TestContext<AnyObject>) => {
          const input = parseFloat(item as string);
          const isLongitude = (num: number) => isFinite(num) && Math.abs(num) <= 180;

          if (isLongitude(input)) {
            return true;
          }

          return ctx.createError({ message: "Longitude is invalid." });
        })
    }),
  operational_hours: Yup.object().shape({
    monday: Yup.object().shape({
      close: Yup.string().test("Closing time is before opening time.", testValidStoreTimes),
      is_closed_for_the_day: Yup.boolean(),
      is_open_24_hours: Yup.boolean(),
      open: Yup.string()
    }),
    tuesday: Yup.object().shape({
      close: Yup.string().test("Closing time is before opening time.", testValidStoreTimes),
      is_closed_for_the_day: Yup.boolean(),
      is_open_24_hours: Yup.boolean(),
      open: Yup.string()
    }),
    wednesday: Yup.object().shape({
      close: Yup.string().test("Closing time is before opening time.", testValidStoreTimes),
      is_closed_for_the_day: Yup.boolean(),
      is_open_24_hours: Yup.boolean(),
      open: Yup.string()
    }),
    thursday: Yup.object().shape({
      close: Yup.string().test("Closing time is before opening time.", testValidStoreTimes),
      is_closed_for_the_day: Yup.boolean(),
      is_open_24_hours: Yup.boolean(),
      open: Yup.string()
    }),
    friday: Yup.object().shape({
      close: Yup.string().test("Closing time is before opening time.", testValidStoreTimes),
      is_closed_for_the_day: Yup.boolean(),
      is_open_24_hours: Yup.boolean(),
      open: Yup.string()
    }),
    saturday: Yup.object().shape({
      close: Yup.string().test("Closing time is before opening time.", testValidStoreTimes),
      is_closed_for_the_day: Yup.boolean(),
      is_open_24_hours: Yup.boolean(),
      open: Yup.string()
    }),
    sunday: Yup.object().shape({
      close: Yup.string().test("Closing time is before opening time.", testValidStoreTimes),
      is_closed_for_the_day: Yup.boolean(),
      is_open_24_hours: Yup.boolean(),
      open: Yup.string()
    })
  }),
  access_hours: Yup.object().shape({
    monday: Yup.object().shape({
      close: Yup.string().test("Closing time is before opening time.", testValidStoreTimes),
      is_closed_for_the_day: Yup.boolean(),
      is_open_24_hours: Yup.boolean(),
      open: Yup.string()
    }),
    tuesday: Yup.object().shape({
      close: Yup.string().test("Closing time is before opening time.", testValidStoreTimes),
      is_closed_for_the_day: Yup.boolean(),
      is_open_24_hours: Yup.boolean(),
      open: Yup.string()
    }),
    wednesday: Yup.object().shape({
      close: Yup.string().test("Closing time is before opening time.", testValidStoreTimes),
      is_closed_for_the_day: Yup.boolean(),
      is_open_24_hours: Yup.boolean(),
      open: Yup.string()
    }),
    thursday: Yup.object().shape({
      close: Yup.string().test("Closing time is before opening time.", testValidStoreTimes),
      is_closed_for_the_day: Yup.boolean(),
      is_open_24_hours: Yup.boolean(),
      open: Yup.string()
    }),
    friday: Yup.object().shape({
      close: Yup.string().test("Closing time is before opening time.", testValidStoreTimes),
      is_closed_for_the_day: Yup.boolean(),
      is_open_24_hours: Yup.boolean(),
      open: Yup.string()
    }),
    saturday: Yup.object().shape({
      close: Yup.string().test("Closing time is before opening time.", testValidStoreTimes),
      is_closed_for_the_day: Yup.boolean(),
      is_open_24_hours: Yup.boolean(),
      open: Yup.string()
    }),
    sunday: Yup.object().shape({
      close: Yup.string().test("Closing time is before opening time.", testValidStoreTimes),
      is_closed_for_the_day: Yup.boolean(),
      is_open_24_hours: Yup.boolean(),
      open: Yup.string()
    })
  }),
  postal_code: Yup.string()
    .required("Postal code is required.").matches(/^\d{5}([-\s]?\d{4})?$/, "Must be a valid zipcode"),
  tax_id: Yup.string().required("Tax ID # is required."),
  timezone: Yup.string().required("Timezone is required."),
  access_timezone: Yup.string().required("Timezone is required.").nullable(),
  end_of_days_timezone: Yup.string().required("Accounting Timezone is required.")
},
[
  // cyclic dependency array
  ["latitude", "latitude"],
  ["longitude", "longitude"]
]);

export default validationSchema;
