import { CircularProgress, Grid, Paper } from "@mui/material";
import { CreateSpeedeRentPayload, useCreateSpeedeRentMutation } from "src/api/endpoints/speederent";
import React, { ReactElement, useEffect, useState } from "react";
import {
  addMoveInFee,
  addWaivefee,
  removeMoveInFee,
  selectIsTransfer,
  selectKeypadAccess,
  selectLeaseStartsOn,
  selectMonthlyRate,
  selectMoveInInsurancePlan,
  selectMoveInLoading,
  selectPeriods,
  selectPromoDefermentStatus,
  selectTimeAccess,
  selectWaiveFee,
  setWaiveFeeAmount
} from "src/store/reducers/moveInSlice/moveInSlice";
import {
  resetExternalTppPlanState,
  selectExternalTppPlanActive
} from "src/store/reducers/externalTppPlanSlice/externalTppPlanSlice";
import { resetSpeedeRentState, setIsSpeedeRentProcess } from "src/store/reducers/speedeRent/speedeRentSlice";
import { selectActiveMoveInFees, selectFeesLoading } from "../../../../store/reducers/feesSlice/feesSlice";
import {
  selectAlternativeRentalFlowSetting,
  selectGlobalSettingInformationLoading
} from "src/store/reducers/globalSettingInformationSlice/globalSettingInformationSlice";
import { selectDeal, selectDealLoading } from "../../../../store/reducers/dealSlice/dealSlice";
import { selectFees, selectRent, selectTax } from "src/store/reducers/moveInCostSlice/moveInCostSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import AddExternalTPPModal from "../../AddExternalTPPModal/AddExternalTPPModal";
import DealDetails from "../../../../layouts/stepper/ReviewLayout/DealDetails/DealDetails";
import FeeList from "../../FeesList/FeesList";
import InsurancePlansList from "./InsurancePlansList/InsurancePlansList";
import MerchandiseList from "./MerchandiseList/MerchandiseList";
import RateAndAccessDetails from "./RateAndAccessDetails/RateAndAccessDetails";
import ReviewActionButtons from "../../../../layouts/stepper/ReviewLayout/ReviewActionButtons/ReviewActionButtons";
import ReviewHeader from "../../../../layouts/stepper/ReviewLayout/ReviewHeader/ReviewHeader";
import ReviewLayout from "../../../../layouts/stepper/ReviewLayout/ReviewLayout";
import SpeedeRentActionButtons from "src/layouts/stepper/ReviewLayout/SpeedeRentActionButtons/SpeedeRentActionButtons";
import SpeedeRentConfirmationModal from "./SpeedeRentModal/SpeedeRentConfirmationModal";
import { getAllGlobalSettings } from "src/store/thunks/globalSetting/getAll/getAllGlobalSettings";
import { getMoveInCost } from "src/store/thunks/moveIn/getMoveInCost/getMoveInCost";
import { getTransferUnitCost } from "src/store/thunks/transfer/transferCost/getTransferUnitCost";
import moment from "moment";
import { moneyValidator } from "src/utils/commonRegexes";
import rateAndAccessDetailsValidation from "./RateAndAccessDetails/rateAndAccessDetailsValidation";
import { selectFacilities } from "src/store/reducers/facilitiesSlice/facilitiesSlice";
import { selectInsurancePlansLoading } from "../../../../store/reducers/insurancePlansSlice/insurancePlansSlice";
import { selectMerchandiseItemsLoading } from "../../../../store/reducers/merchandiseItemsSlice/merchandiseItemsSlice";
import { selectPromotionsForFacility } from "../../../../store/reducers/promotionsSlice/promotionsSlice";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import { selectSelectedOccupant } from "src/store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { selectSelectedPromotion } from "src/store/reducers/selectedPromotionSlice/selectedPromotionSlice";
import { selectSelectedUnit } from "src/store/reducers/selectedUnitSlice/selectedUnitSlice";
import { selectTargetPath } from "../../../../store/reducers/navigationSlice/navigationSlice";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import useStyles from "./ProcessMoveIn.styles";

export const goBackText = (redirect: string) => {
  if (redirect.includes("emergency-contact")) {
    return "Add Occupant";
  }

  if (redirect.includes("manage-deals")) {
    return "Manage Deals";
  }

  if (redirect.includes("reservation")) {
    return "Select Reservation";
  }

  if (redirect.includes("promotion")) {
    return "Select Promotion";
  }

  return "Select Unit";
};

const ProcessMoveIn: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const redirect = useAppSelector(selectTargetPath);
  const facilityPromotions = useAppSelector(selectPromotionsForFacility);
  const dealLoading = useAppSelector(selectDealLoading);
  const merchandiseLoading = useAppSelector(selectMerchandiseItemsLoading);
  const insurancePlansLoading = useAppSelector(selectInsurancePlansLoading);
  const feesLoading = useAppSelector(selectFeesLoading);
  const viewLoading = dealLoading || merchandiseLoading || insurancePlansLoading || feesLoading;
  const leaseStartsOn = useAppSelector(selectLeaseStartsOn);
  const monthlyRate = useAppSelector(selectMonthlyRate);
  const periods = useAppSelector(selectPeriods);
  const promoDefermentStatus = useAppSelector(selectPromoDefermentStatus);
  const moveInCostFees = useAppSelector(selectFees);
  const fees = useAppSelector(selectActiveMoveInFees);
  const waiveMoveInFee = useAppSelector(selectWaiveFee);
  const rent = useAppSelector(selectRent);
  const tax = useAppSelector(selectTax);
  const isTransfer = useAppSelector(selectIsTransfer);
  const timeAccess = useAppSelector(selectTimeAccess);
  const keypadAccess = useAppSelector(selectKeypadAccess);
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const facilities = useAppSelector(selectFacilities);
  const [speedeRentModalOpen, setSpeedeRentModalOpen] = useState(false);
  const selectedUnit = useAppSelector(selectSelectedUnit);
  const selectedPromotion = useAppSelector(selectSelectedPromotion);
  const leaseStartDate = useAppSelector(selectLeaseStartsOn);
  const selectedDeal = useAppSelector(selectDeal);
  const selectedOccupant = useAppSelector(selectSelectedOccupant);
  const updatedFacility = facilities.find((facility) => facility.id === selectedFacility.id);
  const moveInInsurancePlan = useAppSelector(selectMoveInInsurancePlan);
  const hasExternalTpp = useAppSelector(selectExternalTppPlanActive);
  const [createSpeedeRent] = useCreateSpeedeRentMutation();
  const alternativeRentalFlowSetting = useAppSelector(selectAlternativeRentalFlowSetting);
  const loading = useAppSelector(selectMoveInLoading);
  const isSpeedeRentFlowEnabled = !isTransfer && Number(alternativeRentalFlowSetting?.value) === 1;
  const globalSettingsLoading = useAppSelector(selectGlobalSettingInformationLoading);

  useEffect(() => {
    dispatch(getAllGlobalSettings());
    dispatch(resetSpeedeRentState());
  }, []);

  const payloadSpeedeRent: CreateSpeedeRentPayload = {
    facility_id: selectedUnit?.facility_id!,
    occupant_id: selectedOccupant?.id,
    unit_id: selectedUnit?.id!,
    deal_id: selectedDeal?.id ?? null,
    promotion_id: selectedPromotion?.id,
    lease_start_date: moment(leaseStartDate).format("yyyy-MM-DD")
  };

  const handleSpeedeRentModalSubmit = async() => {
    dispatch(setIsSpeedeRentProcess(true));

    const response = await createSpeedeRent(payloadSpeedeRent);

    if ("error" in response) {
      setSpeedeRentModalOpen(false);
      return dispatch(showSnackbar({
        message: "Error processing SpeedeRent",
        variant: "error"
      }));
    }

    if ("data" in response) {
      dispatch(resetExternalTppPlanState());

      dispatch(showSnackbar({
        message: "SpeedeRent Completed",
        variant: "success"
      }));

      navigate("/deals/manage-deals");
    }

    dispatch(resetSpeedeRentState());
    setSpeedeRentModalOpen(false);
  };

  const handleGoBack = () => {
    if (redirect.includes("emergency-contact")) {
      return navigate("/occupants/create-move-in/occupant-name-and-location");
    }

    if (redirect) {
      return navigate(redirect);
    }

    if (facilityPromotions.length) {
      return navigate("/occupants/create-move-in/select-promotions");
    }

    navigate("/occupants/create-move-in/select-unit");
  };

  const handleCancel = () => {
    if (redirect && redirect.includes("manage-deals")) {
      return navigate(redirect);
    }

    navigate("/occupants/create-move-in");
  };

  const onSubmit = () => {
    navigate("/occupants/create-move-in/review-move-in");
  };

  // set default time access and keypad access
  const defaultTimeAccess = Math.min(...updatedFacility!.time_accesses.map(timeAccess => timeAccess.id));

  const defaultKeypadAccess = Math.min(...updatedFacility!.keypad_accesses.map(keypadAccess => keypadAccess.id));

  const formik = useFormik({
    initialValues: {
      leaseStartsOn: leaseStartsOn,
      monthlyRate: monthlyRate,
      periods: periods,
      promoDefermentStatus: promoDefermentStatus,
      waiveFee: waiveMoveInFee.waive,
      waiveFeeAmount: waiveMoveInFee.waiveFeeAmount,
      rent,
      tax,
      timeAccess: timeAccess || defaultTimeAccess,
      keypadAccess: keypadAccess || defaultKeypadAccess
    },
    validationSchema: rateAndAccessDetailsValidation,
    validateOnBlur: true,
    /* istanbul ignore next */
    onSubmit
  });

  const updateSummary = () => {
    if (isTransfer) {
      return dispatch(getTransferUnitCost());
    }

    dispatch(getMoveInCost());
  };

  useEffect(() => {
    updateSummary();
  }, []);

  const { handleSubmit } = formik;

  const isChecked = (id: number) => {
    return !!moveInCostFees?.find((item) => item.id === id);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const feeId = +event.target.value;

    if (feeId === -1) {
      if (waiveMoveInFee.waive) {
        dispatch(addWaivefee({ waive: false, waiveFeeAmount: "0.00" }));
        formik.setFieldValue("waiveFee", false);
        return;
      }
      dispatch(addWaivefee({ waive: true, waiveFeeAmount: "0.00" }));
      formik.setFieldValue("waiveFee", true);
      return;
    }

    if (isChecked(feeId)) {
      dispatch(removeMoveInFee(feeId));
    } else {
      dispatch(addMoveInFee(feeId));
    }

    updateSummary();
  };

  const handleSpeedeRent = () => {
    setSpeedeRentModalOpen(true);
  };

  const removeFee = (feeId: number) => {
    if (feeId === -1) {
      dispatch(addWaivefee({ waive: false, waiveFeeAmount: "0.00" }));
      formik.setFieldValue("waiveFeeAmount", "0.00");
      updateSummary();
      return;
    }
    dispatch(removeMoveInFee(feeId));
    updateSummary();
  };

  const handleWaiveFeeOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("waiveFeeAmount", event.target.value);
    dispatch(setWaiveFeeAmount(event.target.value));

    if (moneyValidator.test(event.target.value)) {
      updateSummary();
    }
  };

  // this useEffect is only to add rent and tax to the formik state
  useEffect(() => {
    formik.setFieldValue("rent", rent);
    formik.setFieldValue("tax", tax);
  }, [rent, tax]);

  const getCtaText = () => {
    if (isTransfer) {
      return "Review Transfer & Move-In";
    }

    return isSpeedeRentFlowEnabled ? "Cash Move-In" : "Review Move-In";
  };

  const shouldBeDisabled = () => (moveInInsurancePlan ? false : !hasExternalTpp);

  const getCTAButtons = () => {
    const isLoading = loading || globalSettingsLoading;
    const formId = "move-in-payment-form";

    const speedeRentButtons = isSpeedeRentFlowEnabled
      ? (
        <SpeedeRentActionButtons
          disabled={isLoading}
          loading={isLoading}
          formId={formId}
          handlerMethod={handleSpeedeRent}
      />
        )
      : null;

    return (
      <>
        {speedeRentButtons}
        <ReviewActionButtons
          formId={""}
          disabled={shouldBeDisabled()}
          ctaText={getCtaText()}
          handlerMethod={handleSubmit}
          cancelMethod={handleCancel}
        />
      </>
    );
  };

  return (
    <ReviewLayout
      header={
        <ReviewHeader
          headerText={"Process Move-In"}
          data-testid={"process-move-in-header"}
          backMethod={handleGoBack}
          goBackText={goBackText(redirect)}
        />
      }
      actionButtons={getCTAButtons()}
    >
      <Paper elevation={1} className={classes.fullWidth}>
        {viewLoading
          ? (
            <Grid container justifyContent={"center"} py={8}>
              <CircularProgress size={60} />
            </Grid>
            )
          : (
            <>
              <DealDetails />
              <RateAndAccessDetails formik={formik} />
              <Grid container item spacing={2}
                py={2} px={2} xs={12}
                lg={"auto"}>
                <Grid item xs={12} lg={6}>
                  <FeeList
                    fees={fees}
                    title={"Move-In Charges & Fees"}
                    isFeeChecked={isChecked}
                    handleChange={handleChange}
                    removeFee={removeFee}
                    handleWaiveFeeOnChange={handleWaiveFeeOnChange}
                    formik={formik}
                    isDisabled={(id) => !!fees.find((item) => item.id === id && item.is_required)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <InsurancePlansList />
                </Grid>
                <Grid item xs={12}>
                  <MerchandiseList />
                </Grid>
              </Grid>
            </>
            )}
      </Paper>

      <SpeedeRentConfirmationModal
        open={speedeRentModalOpen}
        onModalClose={() => setSpeedeRentModalOpen(false)}
        onModalSubmit={handleSpeedeRentModalSubmit}
        loading={loading}
      />
      <AddExternalTPPModal leaseStartDate={formik.values.leaseStartsOn} />
    </ReviewLayout>
  );
};

export default ProcessMoveIn;
