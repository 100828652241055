import { Button, Divider, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { FileUpload, Search } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import OccupantDocumentsTable from "./OccupantDocumentsTable";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import UploadDocumentModal from "./Modal/UploadDocumentModal";
import ViewDocumentModal from "./ViewDocumentModal/ViewDocumentModal";
import { debounce } from "lodash";
import { getAllPaginatedOccupantDocuments } from "src/store/thunks/occupantDocuments/getAllPaginatedOccupantDocuments";
import { selectOccupant } from "src/store/reducers/occupantSlice/occupantSlice";
import { selectUnitLedgers } from "src/store/reducers/ledgersSlice/ledgersSlice";
import { uploadOccupantDocument } from "src/store/thunks/occupantDocuments/uploadDocument/uploadDocument";
import useStyles from "./OccupantDocuments.style";

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const UPLOADED_DOCUMENT_TYPES = {
  "Tax Exempt": 1,
  Other: 2,
  "E-Sign": 3
};

const OccupantDocuments = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const unitLedgers = useAppSelector(selectUnitLedgers);
  const [isUploadDocumentModalOpen, setIsUploadDocumentModalOpen] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const selectedOccupant = useAppSelector(selectOccupant);

  const debouncedSearch = debounce((value) => {
    dispatch(
      getAllPaginatedOccupantDocuments({ occupantId: selectedOccupant?.id!, unitId: selectedUnit, searchValue: value })
    );
  }, 500);

  const onSubmit = async({ unitId, file }: { unitId: number; file: File | null }) => {
    const base64 = (await toBase64(file!)) as string;
    const content = base64.replace("data:application/pdf;base64,", "");
    return dispatch(
      uploadOccupantDocument({
        unitId: unitId!,
        file: content,
        occupantId: selectedOccupant?.id!,
        name: file?.name!,
        type: UPLOADED_DOCUMENT_TYPES.Other
      })
    ).then(() => {
      setIsUploadDocumentModalOpen(false);
      dispatch(
        getAllPaginatedOccupantDocuments({
          occupantId: selectedOccupant?.id!,
          unitId: selectedUnit,
          searchValue
        })
      );
    });
  };

  useEffect(() => {
    dispatch(
      getAllPaginatedOccupantDocuments({ occupantId: selectedOccupant?.id!, unitId: selectedUnit, searchValue })
    );
  }, []);

  return (
    <>
      <Grid component={"form"}>
        <Grid container mb={1} alignItems={"center"}
          justifyContent={"space-between"}>
          <Grid item xs={8} pr={1}
            container alignItems={"center"}>
            <Grid item pr={1}>
              <Typography component={"label"} htmlFor={"facility-selector"}>
                Documents for:
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <PMSSelect
                id={"selectedUnit"}
                name={"selectedUnit"}
                value={selectedUnit}
                changeHandler={(event) => {
                  setSelectedUnit(event.target.value);
                  if (event.target.value) {
                    return dispatch(
                      getAllPaginatedOccupantDocuments({
                        occupantId: selectedOccupant?.id!,
                        unitId: event.target.value,
                        searchValue
                      })
                    );
                  }
                  dispatch(
                    getAllPaginatedOccupantDocuments({
                      occupantId: selectedOccupant?.id!,
                      unitId: event.target.value,
                      searchValue
                    })
                  );
                }}
                data-testid={"unit-selector"}
                native
              >
                <option value={0}>All Units</option>
                {unitLedgers.map((ledger) => (
                  <option key={ledger.unit_id} value={ledger.unit_id}>
                    Unit {ledger?.unit?.unit_number}
                  </option>
                ))}
              </PMSSelect>
            </Grid>

            <Grid item ml={3}>
              <Button
                className={classes.button}
                variant={"contained"}
                startIcon={<FileUpload />}
                onClick={() => setIsUploadDocumentModalOpen(true)}
              >
                Upload Document
              </Button>
            </Grid>
          </Grid>
          <Grid>
            <TextField
              id={"search-occupants-table"}
              placeholder={"- Search -"}
              size={"small"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={"end"}>
                    <Search />
                  </InputAdornment>
                )
              }}
              variant={"outlined"}
              name={"occupantsTableSearch"}
              onChange={({ target: { value } }) => {
                setSearchValue(value);
                debouncedSearch(value);
              }}
            />
          </Grid>
        </Grid>
        <Divider />
        <OccupantDocumentsTable />
      </Grid>

      <ViewDocumentModal />
      <UploadDocumentModal
        onClose={() => setIsUploadDocumentModalOpen(false)}
        onSubmit={onSubmit}
        open={isUploadDocumentModalOpen}
      />
    </>
  );
};

export default OccupantDocuments;
