import { Button, Grid, Paper, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { setCurrentStep } from "../../../store/reducers/formsSlice/formsSlice";
import { useAppDispatch } from "../../../store/hooks";
import { ButtonItem, ButtonItemProps } from "./ButtonItem/ButtonItem";
import useStyles from "./GetStarted.styles";

interface GetStartedProps {
  title: string
  advisoryText: string | ReactElement
  nextStepPath?: string
  buttons?: ButtonItemProps[]
}

const GetStarted: React.FC<GetStartedProps> = ({
  title,
  advisoryText,
  nextStepPath,
  buttons
}: GetStartedProps): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const handleGetStarted = () => {
    dispatch(setCurrentStep(2));
  };

  const getStartedButton = (
    <ButtonItem
      onClick={() => handleGetStarted()}
      text={"Get Started"}
      path={nextStepPath}
    />
  );

  return (
    <Paper elevation={1} data-testid={"get-started"}>
      <Grid
        container
        item
        my={3}
        xs={12}
        component={"div"}
        justifyContent={"space-between"}
      >
        <Grid item xs={7} p={4}>
          <Typography
            gutterBottom
            variant={"h4"}
            component={"h3"}
            data-testid={"get-started-title"}
          >
            {title}
          </Typography>
          <Typography component={"div"} data-testid={"get-started-advisory-text"}>{advisoryText}</Typography>
        </Grid>
        <Grid
          item
          container
          my={3}
          xs={5}
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {
            buttons?.length
              ? buttons.map((props, i) => <ButtonItem {...props} key={i}/>)
              : getStartedButton
          }
        </Grid>
      </Grid>
    </Paper>
  );
};

export default GetStarted;
