import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography
} from "@mui/material";

import React, { FormEvent, ReactElement } from "react";
import { inputError } from "../../../../utils/showInputError/showInputError";
import { validationSchema as unrentableReasonValidation } from "./UnrentableReasonFormValidation";
import { unrentableReasons } from "src/utils/constants";
import { useFormik } from "formik";

interface UnrentableReasonFormProps {
  submitHandler: Function
}

const UnrentableReasonForm: React.FC<UnrentableReasonFormProps> = ({ submitHandler }): ReactElement => {
  const formik = useFormik({
    initialValues: {
      unrentableReason: 0,
      unrentableNotes: ""
    },
    validationSchema: unrentableReasonValidation,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: values => submitHandler(values)
  });

  const { touched, errors, values, handleChange } = formik;

  return (
    <Grid
      id={"unrentable-reason-form"}
      data-testid={"unrentable-reason-form"}
      container
      spacing={1}
      p={3}
      component={"form"}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        formik.handleSubmit();
      }}
    >
      <Grid item xs={12}>
        <Typography textAlign={"center"} variant={"h4"}>
          Confirm Unrentable Reason
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <InputLabel htmlFor={"unrentable-reason"}>
          Please select the reason why this unit is no longer rentable.</InputLabel>
        <FormControl fullWidth>
          <Select
            displayEmpty
            native
            id={"unrentable-reason"}
            inputProps={{ "data-testid": "unrentable-reason" }}
            name={"unrentableReason"}
            value={values.unrentableReason}
            onChange={handleChange}
            error={inputError("unrentableReason", touched, errors).error}
          >
            <option value={0} disabled data-testid={"select-unrentable-reasons-prompt"}>
              - Select Unrentable Reason -
            </option>
            {unrentableReasons.map(({ value, label }) => (
              <option key={value} value={value} >{label}</option>
            ))}
          </Select>
          {inputError("unrentableReason", touched, errors).display}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <InputLabel htmlFor={"unrentable-reason"}>Notes:</InputLabel>
        <FormControl fullWidth>
          <TextField
            id={"unrentable-notes"}
            fullWidth
            multiline
            rows={5}
            inputProps={{ maxLength: 500 }}
            variant={"outlined"}
            name={"unrentableNotes"}
            value={values.unrentableNotes}
            onChange={handleChange}
            error={inputError("unrentableNotes", touched, errors).error}
            helperText={inputError("unrentableNotes", touched, errors).helperText}
          >
          </TextField>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default UnrentableReasonForm;
