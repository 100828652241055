import {
  Divider,
  Grid,
  InputLabel, MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import React, { FC, FormEvent } from "react";
import {
  selectDealAddress, selectDealAddressTwo, selectDealCity, selectDealCountry,
  selectDealEmail,
  selectDealFirstName, selectDealLastName,
  selectDealMiddleName, selectDealPhone, selectDealPostalCode, selectDealRegion
} from "../../../../../store/reducers/dealInformationSlice/dealInformationSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Deal } from "../../../../../models/Deal";
import EditDealFooterButtons from "../../EditDealHelpers/EditDealFooterButtons/EditDealFooterButtons";
import MaskedPhone from "../../../../../components/masking/MaskedPhone/MaskedPhone";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import UpdateDealPayload from "src/store/thunks/deals/update/UpdateDealPayload";
import editDealOccupantInformationValidation from "./editDealOccupantInformationValidation";
import { inputError } from "src/utils/showInputError/showInputError";
import { selectDeal } from "../../../../../store/reducers/dealSlice/dealSlice";
import { updateDeal } from "../../../../../store/thunks/deals/update/updateDeal";
import usStates from "../../../../../utils/usStates";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import MaskedPostalCode from "src/components/masking/MaskedPostalCode/MaskedPostalCode";

interface EditDealProps { }

const EditDealOccupantInformation: FC<EditDealProps> = () => {
  const navigate = useNavigate();
  const formId = "edit-deal-occupant-information-form";
  const dispatch = useAppDispatch();
  const deal = useAppSelector(selectDeal) as Deal;
  const firstName = useAppSelector(selectDealFirstName);
  const middleName = useAppSelector(selectDealMiddleName);
  const lastName = useAppSelector(selectDealLastName);

  const email = useAppSelector(selectDealEmail);
  const phone = useAppSelector(selectDealPhone);

  const addressOne = useAppSelector(selectDealAddress);
  const addressTwo = useAppSelector(selectDealAddressTwo);
  const city = useAppSelector(selectDealCity);
  const region = useAppSelector(selectDealRegion);
  const postalCode = useAppSelector(selectDealPostalCode);
  const country = useAppSelector(selectDealCountry);

  type DealType = 1 | 2 | "Reservation" | "Lead";

  const parseDealType = (dealType: DealType): number => {
    if (typeof dealType === "string") {
      if (dealType === "Reservation") {
        return 2;
      } else {
        return 1;
      }
    } else {
      return dealType as number;
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: firstName ?? "",
      middleName: middleName ?? "",
      lastName: lastName ?? "",
      email: email ?? "",
      phone: phone ?? "",
      addressOne: addressOne ?? "",
      addressTwo: addressTwo ?? "",
      city: city ?? "",
      region: region ?? "",
      postalCode: postalCode ?? "",
      country: country ?? ""
    },
    validationSchema: editDealOccupantInformationValidation,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      const payload: UpdateDealPayload = {
        facility_id: deal?.facility_id,
        occupant_id: deal.occupant_id,
        product_type_id: deal?.product_type_id,
        ...(deal?.promotion_id && { promotion_id: parseInt(deal?.promotion_id) }),
        ...(deal?.unit_id && { unit_id: parseInt(deal?.unit_id) }),
        reservation_expires_at: deal?.reservation_expires_at,
        deal_type: parseDealType(deal?.deal_type),
        appointment_at: deal?.appointment_at ?? "",
        needed_at: deal?.needed_at,
        cancelled_at: deal?.cancelled_at ?? "",
        contact_at: deal?.contact_at ?? "",
        last_contact_at: deal?.last_contact_at ?? "",
        quoted_price: parseFloat(deal?.quoted_price),
        first_name: values.firstName,
        middle_name: values.middleName!,
        last_name: values.lastName,
        email: values.email,
        phone: values.phone,
        address: values.addressOne,
        address_two: values.addressTwo!,
        city: values.city,
        region: values.region,
        postal_code: values.postalCode,
        country: values.country,
        referral_type: deal?.referral_type,
        source: deal?.source,
        cancel_note: deal?.cancel_note!
      };

      dispatch(updateDeal({ payload, deal_id: deal?.id })).then((resp) => {
        if (!resp.type.includes("rejected")) {
          navigate("/deals/manage-deals");
        }
      });
    }
  });

  const { values, errors, handleChange, touched, setFieldValue, setFieldTouched } = formik;

  const handleZipCodeChange = (fieldName: string, fieldValue: string) => {
    if (!(/^([0-9]{0,5})$/.test(fieldValue))) {
      return false;
    }
    setFieldTouched(fieldName);
    setFieldValue(fieldName, fieldValue);
  };

  const handleChangeState = (fieldValue: string) => {
    setFieldValue("region", fieldValue);
  };

  return (
    <Grid
      component={"form"}
      id={formId}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        formik.handleSubmit();
      }}
    >
      <Typography variant={"h6"} gutterBottom>Occupant Information</Typography>
      <Divider/>
      <Grid
        container
        justifyContent={"space-between"}
        mt={2}
        spacing={2}
      >
        <Grid xs={8} lg={5} item>
          <InputLabel htmlFor={"first-name"}>First Name</InputLabel>
          <TextField
            fullWidth
            placeholder={" - First Name -"}
            id={"first-name"}
            inputProps={{ "data-testid": "first-name" }}
            name={"firstName"}
            value={values.firstName}
            onChange={handleChange}
            error={inputError("firstName", touched, errors).error}
            helperText={inputError("firstName", touched, errors).helperText}
          />
        </Grid>
        <Grid xs={4} lg={2} item>
          <InputLabel htmlFor={"middle-name"}>Middle Initial</InputLabel>
          <TextField
            fullWidth
            placeholder={"- Middle Initial -"}
            id={"middle-name"}
            inputProps={{ "data-testid": "middle-name" }}
            name={"middleName"}
            value={values.middleName}
            onChange={handleChange}
            error={inputError("middleName", touched, errors).error}
            helperText={inputError("middleName", touched, errors).helperText}
          />
        </Grid>
        <Grid xs={12} lg={5} item>
          <InputLabel htmlFor={"last-name"}>Last Name</InputLabel>
          <TextField
            fullWidth
            placeholder={"- Last Name -"}
            id={"last-name"}
            inputProps={{ "data-testid": "last-name" }}
            name={"lastName"}
            value={values.lastName}
            onChange={handleChange}
            error={inputError("lastName", touched, errors).error}
            helperText={inputError("lastName", touched, errors).helperText}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <InputLabel htmlFor={"mailing-address-one"}>Mailing Address 1</InputLabel>
          <TextField
            fullWidth
            placeholder={"- Mailing Address 1 -"}
            id={"mailing-address-one"}
            inputProps={{ "data-testid": "mailing-address-one" }}
            name={"addressOne"}
            value={values.addressOne}
            onChange={handleChange}
            error={inputError("addressOne", touched, errors).error}
            helperText={inputError("addressOne", touched, errors).helperText}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <InputLabel htmlFor={"mailing-address-two"}>Mailing Address 2</InputLabel>
          <TextField
            fullWidth
            placeholder={"- Mailing Address 2 -"}
            id={"mailing-address-two"}
            inputProps={{ "data-testid": "mailing-address-two" }}
            name={"addressTwo"}
            value={values.addressTwo}
            onChange={handleChange}
            error={inputError("addressTwo", touched, errors).error}
            helperText={inputError("addressTwo", touched, errors).helperText}
          />
        </Grid>
        <Grid xs={6} lg={3} item>
          <InputLabel htmlFor={"city"}>City</InputLabel>
          <TextField
            fullWidth
            placeholder={"- City -"}
            id={"city"}
            inputProps={{ "data-testid": "city" }}
            name={"city"}
            value={values.city}
            onChange={handleChange}
            error={inputError("city", touched, errors).error}
            helperText={inputError("city", touched, errors).helperText}
          />
        </Grid>
        <Grid xs={6} lg={3} item>
          <PMSSelect
            id={"state"}
            name={"state"}
            label={"State"}
            value={values.region}
            changeHandler={(e) => handleChangeState(e.target.value)}
            error={inputError("state", touched, errors).error}
            helperText={inputError("state", touched, errors).helperText}
          >
            <option value={""} disabled>{" - State -"}</option>
            {usStates.map(state => <option key={state.text} value={state.value}>{state.text}</option>)}
          </PMSSelect>
        </Grid>
        <Grid xs={6} lg={3} item>
          <InputLabel htmlFor={"postal-code"}>Postal Code</InputLabel>
          <MaskedPostalCode
            fullWidth
            placeholder={"- Postal Code -"}
            id={"postal-code"}
            name={"postalCode"}
            value={values.postalCode}
            onChange={(e) => handleZipCodeChange("postalCode", e.target.value)}
            error={inputError("postalCode", touched, errors).error}
            helperText={inputError("postalCode", touched, errors).helperText}
          />
        </Grid>
        <Grid xs={6} lg={3} item>
          <InputLabel htmlFor={"country"}>Country</InputLabel>
          <Select
            fullWidth
            disabled
            defaultValue={""}
            id={"country"}
            displayEmpty
            variant={"outlined"}
            value={""}
            inputProps={{ "data-testid": "country" }}
          >
            <MenuItem value={""} disabled>United States</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={"space-between"}
        mt={2}
        spacing={2}
      >
        <Grid item xs={12} lg={6} >
          <InputLabel htmlFor={"email"}>
            Email
          </InputLabel>
          <TextField
            fullWidth
            placeholder={"- Email Address -"}
            id={"email"}
            inputProps={{ "data-testid": "email" }}
            name={"email"}
            value={values.email}
            onChange={handleChange}
            error={inputError("email", touched, errors).error}
            helperText={inputError("email", touched, errors).helperText}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <InputLabel htmlFor={"phone"}>Primary Phone</InputLabel>
          <MaskedPhone
            id={"phone"}
            dataTestId={"phone"}
            fullWidth
            variant={"outlined"}
            name={"phone"}
            type={"tel"}
            placeholder={"- Primary Phone -"}
            onChange={handleChange}
            value={values.phone}
            error={inputError("phone", touched, errors).error}
            helperText={inputError("phone", touched, errors).helperText}
          />
        </Grid>
      </Grid>
      <EditDealFooterButtons formId={formId}/>
    </Grid>
  );
};

export default EditDealOccupantInformation;
