import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Cancellation Modal" })((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  container: {
    width: "30rem",
    borderRadius: "1rem",
    padding: theme.spacing(4)
  },
  content: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center"
  },
  title: {
    marginBottom: theme.spacing(3),
    fontWeight: "bold"
  },
  button: {
    color: theme.palette.primary.contrastText,
    minWidth: theme.spacing(15),
    minHeight: theme.spacing(5),
    borderColor: theme.palette.grey[400]
  },
  cancelButton: {
    minWidth: theme.spacing(15),
    minHeight: theme.spacing(5),
    color: theme.palette.error.contrastText,
    borderColor: theme.palette.grey[400]
  }
}));

export default useStyles;
