import * as Yup from "yup";

const validationSchema = (militaryStatus: number) => {
  if (militaryStatus !== 4) {
    return Yup.object().shape({
      firstName: Yup.string(),
      lastName: Yup.string(),
      addressOne: Yup.string(),
      city: Yup.string(),
      state: Yup.string(),
      postalCode: Yup.string().test("length", "Postal Code must have 5 or 9 digits", (postalCode) => {
        if (postalCode === undefined) {
          return true;
        }
        return postalCode?.length === 5 || postalCode?.length === 9;
      }),
      phone: Yup.string().length(10, "Phone Number must be 10 digits"),
      relationship: Yup.string(),
      email: Yup.string().email("E-mail Address must be valid")
    });
  }
  return Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    addressOne: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    postalCode: Yup.string()
      .required("Postal Code is required")
      .test("length", "Postal Code must have 5 or 9 digits", (postalCode) => {
        return postalCode?.length === 5 || postalCode?.length === 9;
      }),
    phone: Yup.string().length(10, "Phone Number must be 10 digits").required("Phone Number is required."),
    relationship: Yup.string().required("Relationship is required"),
    email: Yup.string().required("E-mail Address is required").email("E-mail Address must be valid")
  });
};

export default validationSchema;
