import React, { ReactElement, useEffect, useState } from "react";
import {
  resetTaskCategoriesSlice,
  selectTaskCategories,
  selectTaskCategoriesLoading
} from "../../../store/reducers/taskCategoriesSlice/taskCategoriesSlice";
import {
  selectTaskCategory,
  selectTaskDescription,
  selectTaskDueDate,
  selectTaskDueTime,
  selectTaskFacilities,
  selectTaskFormLoading,
  selectTaskIsAllDay,
  selectTaskIsCompleted,
  selectTaskName,
  selectTaskRecurrence,
  setTaskUpdateValues
} from "../../../store/reducers/taskFormSlice/taskFormSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useNavigate, useParams } from "react-router";
import AppModal from "src/components/ui/AppModal/AppModal";
import { Breadcrumb } from "../../../models/Breadcrumb";
import ItemManagementFooterButtons
  from "../../../components/itemManagement/ItemManagementFooterButtons/ItemManagementFooterButtons";
import ItemManagementLayout from "../../../layouts/ItemManagement/ItemManagementLayout";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import TaskForm from "../TaskForm/TaskForm";
import { TaskValues } from "src/models/formikInputValues/TaskValues";
import { Typography } from "@mui/material";
import ViewWrapper from "../../../layouts/ViewWrapper/ViewWrapper";
import { getAllTaskCategories } from "src/store/thunks/taskCategory/getAll/getAllTaskCategories";
import { getTask } from "src/store/thunks/task/getOne/getTask";
import moment from "moment";
import taskFormValidation from "../TaskForm/taskFormValidation";
import { updateTask } from "src/store/thunks/task/update/updateTask";
import { useFormik } from "formik";
import { resetTasksSlice } from "src/store/reducers/tasksSlice/tasksSlice";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Task Management"
  },
  {
    name: "Edit Task",
    bold: true
  }
];

const EditTask: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const taskCategories = useAppSelector(selectTaskCategories);
  const viewLoading = useAppSelector(selectTaskCategoriesLoading);
  const formLoading = useAppSelector(selectTaskFormLoading);
  const navigate = useNavigate();
  const taskId = useParams<{ id: string }>();
  const taskFormLoading = useAppSelector(selectTaskFormLoading);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const taskName = useAppSelector(selectTaskName);
  const taskCategoryId = useAppSelector(selectTaskCategory);
  const taskRecurrence = useAppSelector(selectTaskRecurrence);
  const taskDueDate = useAppSelector(selectTaskDueDate);
  const taskDueTime = useAppSelector(selectTaskDueTime);
  const taskIsAllDay = useAppSelector(selectTaskIsAllDay);
  const taskIsCompleted = useAppSelector(selectTaskIsCompleted);
  const taskDescription = useAppSelector(selectTaskDescription);
  const taskFacilities = useAppSelector(selectTaskFacilities);
  const permissions = useAppSelector(selectFacilityPermissions);
  const manageTasksPermissions = createPermissions(permissions, "manage_tasks");

  const handleSubmit = (input: TaskValues) => {
    const values = { ...input, taskDueTime: moment(input.taskDueTime).format("hh:mm A") };

    dispatch(setTaskUpdateValues(values));
    setModalOpen(true);
  };

  const formik = useFormik<TaskValues>({
    initialValues: {
      taskName: taskName,
      taskCategoryId: taskCategoryId,
      taskRecurrence: taskRecurrence,
      taskDueDate: taskDueDate,
      taskDueTime: taskDueTime,
      taskIsAllDay: taskIsAllDay,
      taskIsCompleted: taskIsCompleted,
      taskDescription: taskDescription,
      taskFacilities: taskFacilities
    },
    validationSchema: taskFormValidation,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: (values: TaskValues) => handleSubmit(values)
  });

  const handleCancel = () => {
    setModalOpen(false);
  };

  const handleUpdate = () => {
    dispatch(updateTask(taskId.id!))
      .then((resp) => {
        if (!resp.type.includes("rejected")) {
          navigate("/task-management");
        }
      });
  };

  const pageHeader = <PageHeader title={"Edit Task"} breadcrumbs={breadcrumbs} />;

  const footerButtons = (
    <ItemManagementFooterButtons
      cancelReturnPath={"/task-management"}
      formId={"task-form"}
      loading={formLoading}
    />
  );

  useEffect(() => {
    dispatch(getTask(taskId.id!));

    return () => {
      dispatch(resetTaskCategoriesSlice());
      dispatch(resetTasksSlice());
      dispatch(getAllTaskCategories());
    };
  }, []);

  if (manageTasksPermissions.update) {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <ItemManagementLayout
          title={"Edit Task"}
          loading={viewLoading}
          footerButtons={footerButtons}
        >
          <TaskForm formik={formik} taskCategories={taskCategories} />
        </ItemManagementLayout>
        <AppModal
          data-testid={"update-task-modal"}
          formId={"task-form"}
          open={modalOpen}
          confirmAction={handleUpdate}
          onCloseAction={handleCancel}
          cancelAction={handleCancel}
          confirmActionLoading={taskFormLoading}
        >
          <Typography variant={"h6"}>
            Are you sure?
          </Typography>
          <Typography >
            Saving these edits will apply them to the current task.
            All active facilities with this task will be affected accordingly.
          </Typography>
        </AppModal>
      </ViewWrapper>
    );
  } else {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <h1>You do not have permission to view this page.</h1>
      </ViewWrapper>
    );
  }
};

export default EditTask;
