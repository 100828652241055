import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { InsurancePlanInformationSliceState } from "./InsurancePlanInformationSliceState";
import { InsurancePlanValues } from "../../../models/formikInputValues/InsurancePlanValues";
import { RootState } from "../../rootStore";
import { getInsurancePlan } from "src/store/thunks/insurancePlans/get/getInsurancePlan";
import { insurancePlanApi } from "src/api/endpoints/insurancePlan";

export const initialState: InsurancePlanInformationSliceState = {
  name: "",
  premium_amount: "",
  coverage_amount: "",
  line_item_description: "",
  revenue_class_id: 0,
  document_template_id: 0,
  is_active: true,
  free_trial: 0,
  is_auto_enroll: false,
  insurancePlanInformationLoading: false,
  insurancePlanInformationError: "",
  insurancePlanInformationModalOpen: false
};

export const insurancePlanInformationSlice = createSlice({
  name: "insurancePlanInformation",
  initialState,
  reducers: {
    setInsurancePlanInformation: (
      state,
      action: PayloadAction<InsurancePlanValues>
    ) => {
      state.name = action.payload.name;
      state.premium_amount = action.payload.premium_amount;
      state.coverage_amount = action.payload.coverage_amount;
      state.line_item_description = action.payload.line_item_description;
      state.revenue_class_id = action.payload.revenue_class_id;
      state.document_template_id = action.payload.document_template_id;
      state.is_active = action.payload.is_active;
      state.free_trial = action.payload.free_trial;
      state.is_auto_enroll = action.payload.is_auto_enroll;
    },
    setPlanName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setPremiumAmount: (state, action: PayloadAction<string>) => {
      state.premium_amount = action.payload;
    },
    setCoverageAmount: (state, action: PayloadAction<string>) => {
      state.coverage_amount = action.payload;
    },
    setPlanDescription: (state, action: PayloadAction<string>) => {
      state.line_item_description = action.payload;
    },
    setPlanRevenueClassId: (state, action: PayloadAction<number>) => {
      state.revenue_class_id = action.payload;
    },
    setPlanDocumentTemplateId: (state, action: PayloadAction<number>) => {
      state.document_template_id = action.payload;
    },
    setPlanIsActive: (state, action: PayloadAction<boolean>) => {
      state.is_active = action.payload;
    },
    setPlanFreeTrial: (state, action: PayloadAction<number>) => {
      state.free_trial = action.payload;
    },
    setPlanIsAutoEnroll: (state, action: PayloadAction<boolean>) => {
      state.is_auto_enroll = action.payload;
    },
    resetInsurancePlanInformationSlice: (state) => {
      Object.assign(state, initialState);
    },
    setInsurancePlanInformationModalOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.insurancePlanInformationModalOpen = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInsurancePlan.pending, (state) => {
        state.insurancePlanInformationLoading = true;
      })
      .addCase(getInsurancePlan.fulfilled, (state, action) => {
        state.insurancePlanInformationLoading = false;
        state.name = action.payload.name;
        state.premium_amount = action.payload.premium_amount;
        state.coverage_amount = action.payload.coverage_amount;
        state.line_item_description = action.payload.line_item_description;
        state.revenue_class_id = action.payload.revenue_class_id;
        state.document_template_id = action.payload.document_template_id;
        state.is_active = action.payload.is_active;
        state.free_trial = action.payload.free_trial;
        state.is_auto_enroll = action.payload.is_auto_enroll;
      })
      .addCase(getInsurancePlan.rejected, (state, action) => {
        state.insurancePlanInformationLoading = false;
        state.insurancePlanInformationError = action.payload as string;
      });

    builder
      .addMatcher(insurancePlanApi.endpoints.createTppPlan.matchPending, (state) => {
        state.insurancePlanInformationLoading = true;
      })
      .addMatcher(insurancePlanApi.endpoints.createTppPlan.matchFulfilled, (state) => {
        state.insurancePlanInformationLoading = false;
      })
      .addMatcher(insurancePlanApi.endpoints.createTppPlan.matchRejected, (state) => {
        state.insurancePlanInformationLoading = false;
      });
  }
});

export const selectPlanName = (state: RootState) =>
  state.insurancePlanInformation.name;
export const selectPremiumAmount = (state: RootState) =>
  state.insurancePlanInformation.premium_amount;
export const selectCoverageAmount = (state: RootState) =>
  state.insurancePlanInformation.coverage_amount;
export const selectPlanDescription = (state: RootState) =>
  state.insurancePlanInformation.line_item_description;
export const selectPlanRevenueClassId = (state: RootState) =>
  state.insurancePlanInformation.revenue_class_id;
export const selectPlanIsActive = (state: RootState) =>
  state.insurancePlanInformation.is_active;
export const selectPlanInformationLoading = (state: RootState) =>
  state.insurancePlanInformation.insurancePlanInformationLoading;
export const selectPlanInformationModal = (state: RootState) =>
  state.insurancePlanInformation.insurancePlanInformationModalOpen;
export const selectPlanDocumentTemplateId = (state: RootState) =>
  state.insurancePlanInformation.document_template_id;
export const selectPlanFreeTrail = (state: RootState) =>
  state.insurancePlanInformation.free_trial;
export const selectPlanIsAutoEnroll = (state: RootState) =>
  state.insurancePlanInformation.is_auto_enroll;

export const {
  setInsurancePlanInformation,
  resetInsurancePlanInformationSlice,
  setInsurancePlanInformationModalOpen,
  setPlanName,
  setPremiumAmount,
  setCoverageAmount,
  setPlanDescription,
  setPlanRevenueClassId,
  setPlanIsActive,
  setPlanDocumentTemplateId,
  setPlanFreeTrial,
  setPlanIsAutoEnroll
} = insurancePlanInformationSlice.actions;

export default insurancePlanInformationSlice.reducer;
