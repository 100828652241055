/* eslint-disable no-unused-vars */
enum NoteType {
  //this needs to be here for the getAllNotes endpoint. it is pluralized.
  deals = "deals",
  deal = "deal",
  merch = "merchandise",
  occupant = "occupant",
  occupants = "occupants",
  ledger = "ledger",
  ledgers = "ledgers",
  unit = "unit",
  units = "units"
}

export default NoteType;
