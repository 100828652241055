import { Button, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { NavLink } from "react-router-dom";
import React from "react";
import { selectOccupantDetailsLoading } from "src/store/reducers/occupantInformationSlice/occupantInformationSlice";
import { useAppSelector } from "src/store/hooks";
import useStyles from "./EditOccupantInformation.styles";
import {
  selectChangeOwnershipIsEnabled
} from "src/store/reducers/occupantSlice/occupantSlice";

interface EditOccupantInformationFooterButtonsProps {
  disabled?: boolean
}

const EditOccupantInformationFooterButtons = ({ disabled }: EditOccupantInformationFooterButtonsProps) => {
  const { classes } = useStyles();
  const occupantInformationLoading = useAppSelector(selectOccupantDetailsLoading);
  const changeOwnershipIsEnabled = useAppSelector(selectChangeOwnershipIsEnabled);

  return (
    <Grid
      mt={1}
      container
      spacing={3}
      justifyContent={"flex-end"}
      item
      xs={12}
        >
      <Grid item>
        <Button
          variant={"text"}
          color={"error"}
          component={NavLink}
          to={"/occupants/manage-occupants"}
                >
          Cancel
        </Button>
      </Grid>
      <Grid item>
        <LoadingButton
          form={"edit-occupant-form"}
          variant={"contained"}
          className={classes.saveButton}
          type={"submit"}
          loading={occupantInformationLoading}
          disabled={disabled}
          data-testid={"edit-occupant-button"}
        >
          {changeOwnershipIsEnabled ? "Next" : "Save"}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default EditOccupantInformationFooterButtons;
