import React, { FC } from "react";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import moment from "moment";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useAppDispatch } from "src/store/hooks";
import {
  setFacilityAccessTableModal,
  setRemoveAccessTableModal,
  setSelectedAccessTable
} from "src/store/reducers/facilitySlice/facilitySlice";
import { TimeAccess } from "src/models/TimeAccess";
import { KeypadAccess } from "src/models/KeypadAccess";

interface ActionButtonsProps {
  row: TimeAccess | KeypadAccess
}

const ActionButtons: FC<ActionButtonsProps> = ({ row }) => {
  const dispatch = useAppDispatch();

  return (
    <Grid container>
      <Grid item xs={3}>
        <Tooltip title={"Edit"}>
          <IconButton onClick={() => {
            dispatch(setSelectedAccessTable(row));
            dispatch(setFacilityAccessTableModal({ open: true, type: "keypadAccess" }));
          }}>
            <Edit />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid
        item xs={3}
        onClick={() => {
          dispatch(setSelectedAccessTable(row));
          dispatch(setRemoveAccessTableModal({
            open: true,
            type: "keypadAccess"
          }));
        }}
      >
        <Tooltip title={"Delete"}>
          <IconButton >
            <Delete fontSize={"small"} />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export const KeypadAccessTableColumns: GridColDef[] = [
  {
    field: "zone",
    renderHeader: () => <Tooltip title={"Zone"}><Typography>Zone</Typography></Tooltip>,
    minWidth: 150,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.zone
  },
  {
    field: "name",
    renderHeader: () => <Tooltip title={"Access Name"}><Typography>Access Name</Typography></Tooltip>,
    minWidth: 150,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.name
  },
  {
    field: "actionIcons",
    headerName: "Actions",
    sortable: false,
    minWidth: 150,
    renderCell: ({ row }: GridRenderCellParams) => <ActionButtons row={row}/>
  }
];
