import { AutocompleteRenderInputParams, TextField } from "@mui/material";
import React, { ChangeEventHandler, ReactElement } from "react";
import CircularProgress from "@mui/material/CircularProgress";

interface SelectOccupantSearchFieldProps {
  params: AutocompleteRenderInputParams
  searchValue: string
  changeHandler: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  loading?: boolean
}

const SelectOccupantSearchField: React.FC<SelectOccupantSearchFieldProps> = ({
  params,
  searchValue,
  changeHandler,
  loading
}): ReactElement => {
  return (
    <TextField
      label={"Search"}
      {...params}
      id={"search-input"}
      data-testid={"search-input"}
      variant={"outlined"}
      value={searchValue}
      onChange={changeHandler}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {loading ? <CircularProgress data-testid={"search-loading"} color={"inherit"} size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        )
      }}
    />
  );
};

export default SelectOccupantSearchField;
