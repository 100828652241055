import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles<{ smallText?: boolean }>({ label: "PMSSwitch" })((theme, { smallText }) => ({
  root: {
    color: "black",
    marginLeft: `0 ${theme.spacing(1.25)}`,
    justifyContent: "start",
    "& .MuiTypography-root": {
      fontSize: smallText ? ".9rem" : "1.15rem"
    }
  }
}));

export default useStyles;
