import {
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
  GridValueGetterParams
} from "@mui/x-data-grid";
import { MerchandiseCategory } from "src/models/MerchandiseCategory";
import ProcessMerchQuantityIncrementor from "../ProcessMerchQuantityIncrementor/ProcessMerchQuantityIncrementor";
import React from "react";
import { Tooltip, Typography } from "@mui/material";
import theme from "src/theme";

const categoryText = (val: number) => {
  const inStock = val > 0 ? theme.palette.success.main : theme.palette.error.main;

  const textShown = val > 0 ? "In Stock" : "Out of Stock";

  const style = {
    border: `2px solid ${inStock}`,
    color: `${inStock}`,
    padding: `0 ${theme.spacing(1)} 0 ${theme.spacing(1)}`
  };

  return <Typography style={style} fontSize={".75rem"} variant={"subtitle2"}>{textShown}</Typography>;
};

const ChooseRetailMerchTableColumns = (
  merchandiseCategories: MerchandiseCategory[],
  itemsSelected: GridSelectionModel
): GridColDef[] => {
  const showMerchandiseCategory = (id: number) => merchandiseCategories.length
    ? merchandiseCategories.find(merchCategory => merchCategory.id === id)?.name
    : "N/A";

  const baseSet = [
    {
      field: "name",
      renderHeader: () => <Tooltip title={"Product"}><Typography>Prod</Typography></Tooltip>,
      flex: 1
    },
    {
      getApplyQuickFilterFn: undefined,
      field: "sku",
      renderHeader: () => <Tooltip title={"SKU"}><Typography>SKU</Typography></Tooltip>,
      maxWidth: 60,
      valueGetter: () => "0001"
    },
    {
      field: "merchandise_category_id",
      renderHeader: () => <Tooltip title={"Category"}><Typography>Category</Typography></Tooltip>,
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => showMerchandiseCategory(row.merchandise_category_id)
    },
    {
      getApplyQuickFilterFn: undefined,
      field: "quantity",
      renderHeader: () => <Tooltip title={"Stock Category"}><Typography>Stock</Typography></Tooltip>,
      flex: 1,
      type: "number",
      sortComparator: (a: string, b: string) =>
        Number(a) - Number(b),
      renderCell: ({ row }: GridRenderCellParams) => categoryText(row.quantity)
    },
    {
      getApplyQuickFilterFn: undefined,
      field: "price",
      renderHeader: () => <Tooltip title={"Price"}><Typography>Price</Typography></Tooltip>,
      type: "number",
      flex: 1,
      sortComparator: (a: string, b: string) =>
        Number(a.slice(1)) - Number(b.slice(1)),
      valueGetter: ({ row }: GridValueGetterParams) => `$${parseFloat(row.price).toFixed(2)}`
    }
  ];

  const enhancedSet = [
    {
      field: "name",
      renderHeader: () => <Tooltip title={"Product"}><Typography>Product</Typography></Tooltip>,
      flex: 1
    },
    {
      getApplyQuickFilterFn: undefined,
      field: "sku",
      renderHeader: () => <Tooltip title={"SKU"}><Typography>SKU</Typography></Tooltip>,
      maxWidth: 60,
      valueGetter: () => "0001"
    },
    {
      field: "merchandise_category_id",
      renderHeader: () => <Tooltip title={"Category"}><Typography>Category</Typography></Tooltip>,
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => showMerchandiseCategory(row.merchandise_category_id)
    },
    {
      getApplyQuickFilterFn: undefined,
      field: "quantity",
      renderHeader: () => <Tooltip title={"Stock Category"}><Typography>Stock Categ</Typography></Tooltip>,
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => categoryText(row.quantity)
    },
    {
      getApplyQuickFilterFn: undefined,
      field: "quantity_for_cart",
      renderHeader: () => <Tooltip title={"Quantity"}><Typography>Qty</Typography></Tooltip>,
      flex: 1,
      minWidth: 150,
      renderCell: ({ row, value }:GridRenderCellParams) =>
        itemsSelected.includes(row.id)
          ? <ProcessMerchQuantityIncrementor row={row} value={value}/>
          : <Typography>{value}</Typography>
    },
    {
      getApplyQuickFilterFn: undefined,
      field: "price",
      renderHeader: () => <Tooltip title={"Price"}><Typography>Price</Typography></Tooltip>,
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => `$${parseFloat(row.price).toFixed(2)}`
    }
  ];

  return itemsSelected.length ? enhancedSet : baseSet;
};

export default ChooseRetailMerchTableColumns;
