import {
  Assessment,
  AttachMoney,
  CalendarToday,
  CollectionsBookmark,
  Home,
  Looks,
  People,
  Task
} from "@mui/icons-material";
import React from "react";
import { selectHasAnyPermissions } from "src/store/reducers/permissionsSlice/permissionsSlice";
import { useAppSelector } from "src/store/hooks";

export const useStandardLinks = () => {
  const hasAnyPermissions = useAppSelector(selectHasAnyPermissions);

  if (!hasAnyPermissions) {
    return [];
  }

  const standardLinks = [
    { name: "Dashboard", path: "/", icon: <Home /> },
    { name: "My Schedule", path: "/my-schedule", icon: <CalendarToday /> },
    {
      name: "Occupants",
      path: "",
      icon: <People />,
      isOpen: true,
      childLinks: [
        { name: "Add Occupant", path: "/occupants/add-occupant" },
        { name: "Manage Occupants", path: "/occupants/manage-occupants" },
        { name: "Move In", path: "/occupants/create-move-in" },
        { name: "Move Out", path: "/occupants/create-move-out" }
        // { name: "Transfer Unit", path: TRANSFER_UNIT_PATHS.getTransferUnitStarted }
      ]
    },
    {
      name: "Deals",
      path: "",
      icon: <AttachMoney />,
      isOpen: true,
      childLinks: [
        { name: "Create New Deal", path: "/deals/create-new-deal" },
        { name: "Manage Deals", path: "/deals/manage-deals" }
      ]
    },
    {
      name: "Merchandise",
      path: "",
      icon: <Task />,
      isOpen: true,
      childLinks: [
        { name: "Create Merchandise", path: "/merchandise/create" },
        { name: "Manage Merchandise", path: "/merchandise/manage-merchandise" },
        { name: "Point of Sales", path: "/merchandise/point-of-sales" },
        { name: "Summary of Sales", path: "/merchandise/summary-of-sales" }
      ]
    },
    {
      name: "Collections & Billing",
      path: "",
      icon: <CollectionsBookmark />,
      isOpen: true,
      childLinks: [
        {
          name: "Make A Payment",
          path: "/collections/make-a-payment"
        },
        {
          name: "Delinquent Occupants",
          path: "/collections/delinquent-occupants"
        },
        {
          name: "Manage Auctions",
          path: "/collections/manage-auctions"
        }
      ]
    },
    {
      name: "Reporting",
      path: "",
      icon: <Assessment />,
      isOpen: true,
      childLinks: [
        {
          icon: <Looks />,
          name: "View Reports",
          path: "/reporting/view-reports"
        }
      ]
    }
  ];

  return standardLinks.filter((link) => link !== null);
};
