import React from "react";
import { Route } from "react-router";
import AskingRateChanges from "../pages/AskingRateChanges/AskingRateChanges";

export const groupName = "asking-rate-changes";

export const AskingRateChangesRoutes = (
  <>
    <Route path={groupName} element={<AskingRateChanges />} />

  </>
);

export default AskingRateChangesRoutes;
