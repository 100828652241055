import React from "react";
import EmptyTable from "src/components/ui/PMSTable/EmptyTable/EmptyTable";
import PMSTable from "src/components/ui/PMSTable/PMSTable";
import { useAppSelector } from "src/store/hooks";
import { selectProductTypes, selectProductTypesLoading } from "src/store/reducers/productTypesSlice/productTypesSlice";
import ManageProductTypeTableColumns from "./ManageProductTypesTableColumns";
import {
  createPermissions,
  hasPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

export interface ManageProductTypesTableProps {
  onPaginationUpdate: () => void
}

const ManageProductTypesTable = ({ onPaginationUpdate }: ManageProductTypesTableProps) => {
  const productTypes = useAppSelector(selectProductTypes);
  const productTypesLoading = useAppSelector(selectProductTypesLoading);
  const permissions = useAppSelector(selectFacilityPermissions);
  const productTypesPermissions = createPermissions(permissions, "product_types");

  const emptyTable = () => (
    <EmptyTable
      collectionNotFound={"Product Types"}
      value={"Product Type"}
      addLink={"/"}
    />
  );

  return (
    <PMSTable
      data-testid={"manage-product-types-table"}
      columns={ManageProductTypeTableColumns(productTypesPermissions.update || productTypesPermissions.delete)}
      rows={productTypes}
      emptyTableDisplay={emptyTable}
      onPaginationUpdate={onPaginationUpdate}
      loading={productTypesLoading}
    />
  );
};

export default ManageProductTypesTable;
