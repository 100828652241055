import { FormHelperText, Grid, TextField, TextFieldProps, Typography } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import React, { ReactElement } from "react";
import moment, { Moment, MomentInput } from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { FormikProps } from "formik";
import PMSSwitch from "../../../../../components/ui/PMSSwitch/PMSSwitch";
import useStyles from "./AccessHoursRow.styles";

interface Day {
  close: string
  is_closed_for_the_day: boolean,
  is_open_24_hours: boolean,
  open: string
}

interface FormValues {
    name: string,
    legal_name: string
    facility_id: string
    address: string
    city: string
    region: string,
    access_hours: {
      monday: Day,
      tuesday: Day,
      wednesday: Day,
      thursday: Day,
      friday: Day,
      saturday: Day,
      sunday: Day,
    }
    postal_code: string,
    tax_id: string
    timezone: string
}

interface AccessHoursRowProps {
  formik: Pick<FormikProps<FormValues>,
  "handleChange" | "errors" | "values" | "touched" | "setFieldValue" | "setFieldError" | "setFieldTouched">,
  day: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday"
  gateClosed: boolean
  gateTwentyFourHours: boolean
  valueOpen: string | null
  valueClosed: string | null
}

const AccessHoursRow: React.FC<AccessHoursRowProps> = ({
  day,
  formik,
  gateClosed,
  gateTwentyFourHours,
  valueOpen,
  valueClosed
}: AccessHoursRowProps): ReactElement => {
  const { classes } = useStyles();

  const updateForm = (fieldName: string, fieldValue?: string | boolean| MomentInput): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleOpenTimeChange = (fieldName: string, fieldValue: Moment | null) => {
    updateForm(fieldName, moment(fieldValue).format());
  };

  const handleClosedTimeChange = (fieldName: string, fieldValue: Moment | null) => {
    updateForm(fieldName, moment(fieldValue).format());
  };

  const handleSetGateTwentyFourHours = () => {
    updateForm(`access_hours.${day}.is_open_24_hours`, !gateTwentyFourHours);
  };

  const handleSetGateClosed = () => {
    updateForm(`access_hours.${day}.is_closed_for_the_day`, !gateClosed);
  };

  return (
    <Grid
      container
      spacing={2}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Grid item xs={1}>
        <Typography color={"black"} className={classes.storeHoursDay}>{day.substr(0, 3)}</Typography>
      </Grid>
      <Grid item xs={3} >
        <LocalizationProvider dateAdapter={AdapterMoment} >
          <TimePicker
            ampm
            data-testid={"open-time-picker"}
            renderInput={(params: TextFieldProps) => <TextField name={`access_hours.${day}.open`} {...params} />}
            value={valueOpen}
            disabled={(gateClosed || gateTwentyFourHours)}
            onChange={(date: Moment | null) => handleOpenTimeChange(`access_hours.${day}.open`, date)}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={1}>
        <Typography color={"black"} >to</Typography>
      </Grid>
      <Grid item xs={3}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <TimePicker
            ampm
            data-testid={"close-time-picker"}
            renderInput={(params: TextFieldProps) => (
              <TextField
                {...params}
                name={`access_hours.${day}.close`}
                error={Boolean(formik.errors.access_hours?.[day]?.close)}
            />
            )}
            disabled={(gateClosed || gateTwentyFourHours)}
            value={valueClosed}
            onChange={(date:Moment |null) => handleClosedTimeChange(`access_hours.${day}.close`, date)}
            />
          <FormHelperText className={classes.errorText}>
            {formik.errors.access_hours && formik.errors.access_hours?.[day] &&
            formik.errors.access_hours?.[day]?.close}
          </FormHelperText>
        </LocalizationProvider>
      </Grid>
      <Grid pl={2} item xs={2}>
        <PMSSwitch
          name={`${day}-24-hours-access`}
          label={"24 Hours"}
          typographyNoWrap
          smallText
          isChecked={gateTwentyFourHours}
          value={gateTwentyFourHours}
          changeHandler={() => handleSetGateTwentyFourHours()}
        />
      </Grid>
      <Grid item xs={2}>
        <PMSSwitch
          name={`${day}-closed-access`}
          label={"Closed"}
          smallText
          isChecked={gateClosed}
          value={gateClosed}
          changeHandler={() => handleSetGateClosed()}
        />
      </Grid>
    </Grid>
  );
};

export default AccessHoursRow;
