import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Contact Notes Modal" })((theme) => ({
  button: {
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText
  }
}));

export default useStyles;
