import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Add External TPP Modal" })((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  container: {
    width: "30rem",
    borderRadius: "1rem"
  },
  innerFormScroll: {
    width: "30rem",
    maxHeight: "35rem",
    overflowY: "auto",
    borderRadius: "1rem"
  },
  button: {
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText
  },
  cancelButton: {
    minWidth: theme.spacing(15),
    minHeight: theme.spacing(7),
    borderColor: theme.palette.error.contrastText,
    color: theme.palette.common.black,
    textTransform: "none",
    fontWeight: "normal"
  }
}));

export default useStyles;
