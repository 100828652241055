import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export interface GlobalSetting {
    id:number
    identifier: number
    value: string
    description: string
}

export interface UpdateGlobalSettingsPayload {
    global_settings: Partial<GlobalSetting>[]
}

export const updateGlobalSettings = createAsyncThunk(
  "updateGlobalSettings", (data: UpdateGlobalSettingsPayload, { dispatch, getState, rejectWithValue }) => {
    return axios.put(`/api/v1/global-settings`, data)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Global settings updated",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an error updating the Global settings";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
