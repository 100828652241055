import { Grid, InputAdornment, InputLabel, TextField } from "@mui/material";
import React, { ChangeEvent, FormEvent, ReactElement, useEffect } from "react";
import {
  selectAmountReceived,
  selectChangeDue,
  setAmountDue,
  setAmountReceived
} from "../../../store/reducers/cashSlice/cashSlice";
import { selectMoveOut, selectMoveOutLedger } from "src/store/reducers/moveOutSlice/moveOutSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import cashFormValidation from "./cashFormValidation";
import { inputError } from "../../../utils/showInputError/showInputError";
import { isEmpty } from "lodash";
import { selectIsTransfer } from "src/store/reducers/transferUnitSlice/transferUnitSlice";
import { selectMoveOutCostFinalBalance } from "src/store/reducers/moveOutCostSlice/moveOutCostSlice";
import { selectTotalDueToday } from "../../../store/reducers/moveInCostSlice/moveInCostSlice";
import { selectTransferUnitTotalDueToday } from "src/store/reducers/transferUnitCostSlice/transferUnitCostSlice";
import { useFormik } from "formik";
import { useLocation } from "react-router";
import useStyles from "./CashForm.styles";

interface CashFormProps {
  handleSubmit: () => void;
  formId: string;
  disabled?: boolean;
}

const CashForm: React.FC<CashFormProps> = ({ handleSubmit, formId, disabled }): ReactElement => {
  const { classes } = useStyles({ disabled });
  const location = useLocation();
  const cashReceivedAmount = useAppSelector(selectAmountReceived);
  const changeDue = useAppSelector(selectChangeDue);
  const isTransfer = useAppSelector(selectIsTransfer);
  const amountDue = useAppSelector(
    location?.pathname.includes("/create-move-out")
      ? selectMoveOutCostFinalBalance
      : isTransfer
        ? selectTransferUnitTotalDueToday
        : selectTotalDueToday

  );
  const takePartialPayment = useAppSelector(selectMoveOut)?.takePaymentForRemainder;
  const moveOutLedger = useAppSelector(selectMoveOutLedger);
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      cashReceivedAmount: cashReceivedAmount,
      amountDue: parseFloat(amountDue) >= 0 ? amountDue : "0.00",
      takePartialPayment: moveOutLedger?.id ? takePartialPayment : false
    },
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: cashFormValidation,
    /* istanbul ignore next */
    onSubmit: () => {
      handleSubmit();
    }
  });

  const updateForm = (fieldName: string, fieldValue?: string | boolean): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleCashReceivedOnchange = (amountReceived: string) => {
    updateForm("cashReceivedAmount", amountReceived);
    dispatch(setAmountReceived(amountReceived));
  };

  const { values, errors, touched, setFieldValue } = formik;

  useEffect(() => {
    if (moveOutLedger?.id) {
      setFieldValue("takePartialPayment", takePartialPayment);

      if (takePartialPayment) {
        setFieldValue("cashReceivedAmount", "0.00");
      } else {
        setFieldValue("cashReceivedAmount", "");
      }
    }
  }, [takePartialPayment]);

  useEffect(() => {
    dispatch(setAmountDue(parseFloat(amountDue) >= 0 ? amountDue : "0.00"));
    setFieldValue("amountDue", parseFloat(amountDue) >= 0 ? amountDue : "0.00");
  }, [amountDue]);

  return (
    <Grid
      container
      direction={"column"}
      spacing={2}
      justifyContent={"center"}
      alignItems={"center"}
      component={"form"}
      id={formId}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        formik.handleSubmit();
      }}
    >
      <Grid item container direction={"column"}
        alignItems={"center"}>
        <LocalAtmIcon className={classes.moneyIcon} />
      </Grid>
      <Grid container item spacing={2}>
        <Grid item container xs={6}
          justifyContent={"center"}>
          <Grid item>
            <InputLabel htmlFor={"amount-due"}>Cash Due</InputLabel>
            <TextField
              id={"amount-due"}
              disabled
              value={values.amountDue}
              InputProps={{ startAdornment: <InputAdornment position={"start"}>$</InputAdornment> }}
            />
          </Grid>
        </Grid>
        <Grid item container xs={6}
          justifyContent={"center"}>
          <Grid item>
            <InputLabel htmlFor={"amount-received"}>Cash Received</InputLabel>
            <TextField
              id={"amount-received"}
              type={"number"}
              placeholder={"0.00"}
              className={classes.noArrow}
              value={values.cashReceivedAmount}
              inputProps={{ "data-testid": "amount-received" }}
              InputProps={{ startAdornment: <InputAdornment position={"start"}>$</InputAdornment> }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleCashReceivedOnchange(e.target.value)}
              error={inputError("cashReceivedAmount", touched, errors).error}
              helperText={inputError("cashReceivedAmount", touched, errors).helperText}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid item container xs={6}
          justifyContent={"center"}>
          <Grid item>
            <InputLabel htmlFor={"change-owed"}>Change Owed</InputLabel>
            <TextField
              id={"change-owed"}
              disabled
              value={changeDue}
              InputProps={{ startAdornment: <InputAdornment position={"start"}>$</InputAdornment> }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CashForm;
