import { RootState } from "src/store/rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getUnitSizeList = createAsyncThunk(
  "getUnitSizeList",
  (_, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as RootState;

    const facilityId = state.selectedFacility.selectedFacility!.id;

    return axios.get(`/api/v1/facilities/${facilityId}/unit-size-list`)
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = "There was an error getting the Unit Size List";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
