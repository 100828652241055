import React, { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { Button, Grid, Modal, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import useStyles from "./DisableRevenueClassModal.styles";
import {
  selectDisableRevenueClassOpenModal,
  setDisableRevenueClassesOpenModal,
  setRevenueClassesFilter,
  setUnableToDisableRevenueClassOpenModal
} from "src/store/reducers/revenueClassesSlice/revenueClassesSlice";
import { resetRevenueClassInformationSlice, selectSelectedRevenueClass }
  from "src/store/reducers/revenueClassInformationSlice/revenueClassInformationSlice";
import { enableOrDisableRevenueClass } from "src/store/thunks/revenueClass/enableOrDisable/enableOrDisableRevenueClass";
import {
  selectSelectedAdminFacility,
  setSelectedAdminFacility
} from "src/store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import {
  getAllPaginatedRevenueClasses
} from "src/store/thunks/revenueClass/getAllPaginated/getAllPaginatedRevenueClasses";
import { Facility } from "src/models/Facility";

const DisableRevenueClassModal = () => {
  const { classes } = useStyles();
  const selectedRevenueClass = useAppSelector(selectSelectedRevenueClass);
  const dispatch = useAppDispatch();
  const modalOpened = useAppSelector(selectDisableRevenueClassOpenModal);
  const selectedFacility = useAppSelector(selectSelectedAdminFacility);

  const handleClose = () => {
    dispatch(setDisableRevenueClassesOpenModal(false));
  };

  const handleSubmit = () => {
    dispatch(enableOrDisableRevenueClass(selectedRevenueClass)).then(async(resp) => {
      handleClose();
      if (!resp.type.includes("rejected")) {
        await dispatch(setSelectedAdminFacility(selectedFacility as Facility));
        dispatch(resetRevenueClassInformationSlice());
        dispatch(setRevenueClassesFilter({ "filter[is_active]": "0" }));
        dispatch(getAllPaginatedRevenueClasses({
          facilityId: selectedFacility?.id,
          filter: { "filter[is_active]": "0" }
        }));

        return;
      }
      if (resp.payload === "This revenue class is currently in use within the system and cannot be disabled") {
        dispatch(setUnableToDisableRevenueClassOpenModal(true));
      }
    });
  };
  return (
    <Modal
      open={modalOpened}
      onClose={() => handleClose()}
      aria-labelledby={"Modal Component"}
      aria-describedby={"A Modal component."}
      className={classes.modal}
      data-testid={"modal-disable-revenue-class"}
  >
      <Grid
        container
        component={Paper}
        elevation={0}
        variant={"outlined"}
        spacing={2}
        className={classes.container}
        pb={2}>
        <Box className={classes.content}>
          <Typography variant={"h5"} className={classes.title}>
            Disable Revenue Class
          </Typography>
          <Typography className={classes.description}>
            Are you sure you want to disabled this revenue class?
          </Typography>
          <Grid
            pt={4}
            spacing={4}
            container
            justifyContent={"center"}
      >
            <Grid item>
              <Button
                data-testid={"cancel-button"}
                className={classes.cancelButton}
                onClick={() => handleClose()}
                variant={"outlined"}
                color={"secondary"}
          >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                data-testid={"confirm-button-modal"}
                color={"primary"}
                variant={"outlined"}
                className={classes.button}
                onClick={() => handleSubmit()}
          >
                Confirm
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Modal>
  );
};

export default DisableRevenueClassModal;
