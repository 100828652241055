import GlobalSettingEnum from "../../../enums/GlobalSetting";
import { GlobalSettingInformationSliceState } from "./globalSettingInformationSliceState";
import { HttpProblem } from "../../../models/HttpProblem";
import { RootState } from "../../rootStore";
import { createSlice } from "@reduxjs/toolkit";
import { getAllGlobalSettings } from "../../thunks/globalSetting/getAll/getAllGlobalSettings";

const initialState: GlobalSettingInformationSliceState = {
  globalSettingInformationLoading: false,
  globalSettingInformationError: "",
  globalSettings: []
};

export const globalSettingInformationSlice = createSlice({
  name: "globalSettingInformation",
  initialState,
  reducers: {
    resetglobalInformationSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllGlobalSettings.pending, (state) => {
        state.globalSettingInformationLoading = true;
      })
      .addCase(getAllGlobalSettings.fulfilled, (state, action) => {
        state.globalSettingInformationLoading = false;
        state.globalSettings = action.payload.data;
      })
      .addCase(getAllGlobalSettings.rejected, (state, action) => {
        state.globalSettingInformationLoading = false;
        state.globalSettingInformationError = action.payload as HttpProblem;
      });
  }
});

export const selectReservationDaysLimitSetting = (state: RootState) =>
  state.globalSettings.globalSettings.find(item => item.identifier === GlobalSettingEnum.RESERVATION_DAYS_LIMIT);
export const selectDealDaysLimitSetting = (state: RootState) =>
  state.globalSettings.globalSettings.find(item => item.identifier === GlobalSettingEnum.DEAL_DAYS_LIMIT);
export const selectReceiptTemplateSetting = (state: RootState) =>
  state.globalSettings.globalSettings.find(item => item.identifier === GlobalSettingEnum.RECEIPT_TEMPLATE);
export const selectInvoiceTemplateSetting = (state: RootState) =>
  state.globalSettings.globalSettings.find(item => item.identifier === GlobalSettingEnum.INVOICE_TEMPLATE);
export const selectAutoBillTemplateSetting = (state: RootState) =>
  state.globalSettings.globalSettings.find(item => item.identifier === GlobalSettingEnum.AUTOBILL_TEMPLATE);
export const selectESignTemplateSetting = (state: RootState) =>
  state.globalSettings.globalSettings.find(item => item.identifier === GlobalSettingEnum.ESIGN_EMAIL_TEMPLATE);
export const selectPastDueEmailTemplateSetting = (state: RootState) =>
  state.globalSettings.globalSettings.find(item => item.identifier === GlobalSettingEnum.PAST_DUE_EMAIL_TEMPLATE);
export const selectQuickPayEmailTemplateSetting = (state: RootState) =>
  state.globalSettings.globalSettings.find(item => item.identifier === GlobalSettingEnum.QUICK_PAY_EMAIL_TEMPLATE);
export const selectSmsTemplateSetting = (state: RootState) =>
  state.globalSettings.globalSettings.find(item => item.identifier === GlobalSettingEnum.SMS_TEMPLATE);
export const selectMoveInDaysLimitSetting = (state: RootState) =>
  state.globalSettings.globalSettings.find(item => item.identifier === GlobalSettingEnum.MOVE_IN_DAYS_LIMIT);
export const selectGlobalSettingInformationLoading = (state: RootState) =>
  state.globalSettings.globalSettingInformationLoading;
export const selectAllSettings = (state: RootState) => state.globalSettings.globalSettings;
export const selectMoveOutMarkDebtSetting = (state: RootState) =>
  state.globalSettings.globalSettings.find(item => item.identifier === GlobalSettingEnum.MOVE_OUT_MARK_DEBT);

export const selectEnableProratedMoveOutSetting = (state: RootState) =>
  state.globalSettings.globalSettings.find(item => item.identifier === GlobalSettingEnum.ENABLE_PRORATION_ON_MOVE_OUT);

export const selectGlobalFlagIndividualUnitForAuctionSetting = (state: RootState) =>
  state.globalSettings.globalSettings.find(item =>
    item.identifier === GlobalSettingEnum.FLAG_INDIVIDUAL_UNIT_FOR_AUCTION);

export const selectAlternativeRentalFlowSetting = (state: RootState) =>
  state.globalSettings.globalSettings.find(item => item.identifier === GlobalSettingEnum.ALTERNATIVE_RENTAL_FLOW);

export const selectReservationRemoveUnitFromInventorySetting = (state: RootState) =>
  state.globalSettings.globalSettings.find(
    (item) => item.identifier === GlobalSettingEnum.RESERVATION_REMOVE_UNIT_FROM_INVENTORY
  );
export const selectEnableLeadDealTypeSetting = (state: RootState) =>
  state.globalSettings.globalSettings.find(item => item.identifier === GlobalSettingEnum.ENABLE_LEAD_DEAL_TYPE);

export const selectDefaultEmailSetting = (state: RootState) =>
  state.globalSettings.globalSettings.find(item => item.identifier === GlobalSettingEnum.DEFAULT_EMAIL);

export const selectDefaultSenderSetting = (state: RootState) =>
  state.globalSettings.globalSettings.find(item => item.identifier === GlobalSettingEnum.DEFAULT_SENDER);

export const {
  resetglobalInformationSlice
} = globalSettingInformationSlice.actions;

export default globalSettingInformationSlice.reducer;
