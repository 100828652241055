import React, { ReactElement } from "react";
import GetStarted from "../../../../components/stepper/GetStarted/GetStarted";

const GetMoveOutStarted: React.FC = (): ReactElement => {
  const advisoryText = (
    <>
      <p>
        You can use the following workflow to move an occupant out of a unit.
      </p>
      <p>
        You will be able to select an existing occupant from the system, as well as their desired unit from a list
        of those currently rented (if applicable). Applicable fees will be available for selection, payment options
        for a remaining balance can be taken, and you will be able to finalize the move-out.
      </p>
    </>
  );

  return (
    <GetStarted
      title={"Move Out"}
      advisoryText={advisoryText}
      nextStepPath={"/occupants/create-move-out/select-occupant"}
    />
  );
};

export default GetMoveOutStarted;
