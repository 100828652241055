import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Manage Product Types" })((theme) => ({
  buttonBase: {
    textTransform: "uppercase",
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    overflowY: "auto"
  },
  container: {
    width: "40em",
    borderRadius: "1rem",
    padding: theme.spacing(2)
  }
}));

export default useStyles;
