import { Button, Grid, Modal, Paper, Typography } from "@mui/material";
import { EmailOutlined, ReceiptOutlined } from "@mui/icons-material";
import React from "react";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { LoadingButton } from "@mui/lab";
import {
  selectMoveOutConfirmation,
  selectSelectedLedgerUnitNumber
} from "src/store/reducers/moveOutSlice/moveOutSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import useStyles from "./CompletedMoveOutModal.styles";
import { printReceipt } from "src/store/thunks/moveIn/print/printReceipt";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { type } from "os";
import { setSendEmailModalOpened } from "src/store/reducers/moveInSlice/moveInSlice";

interface CompletedMoveOutModalProps {
  open: boolean;
  onModalClose: Function;
  onModalSubmit: Function;
  setCompletedModalOpen: Function;
  formId?: string;
}
const CompletedMoveOutModal = ({ open, onModalClose, setCompletedModalOpen }: CompletedMoveOutModalProps) => {
  const { classes } = useStyles();
  const unitNumber = useAppSelector(selectSelectedLedgerUnitNumber);
  const moveOutConfirmation = useAppSelector(selectMoveOutConfirmation);
  const dispatch = useAppDispatch();

  const handlePrintReceiptClick = () => {
    if (!moveOutConfirmation?.transaction?.id) {
      return dispatch(showSnackbar({
        message: "Transaction ID is missing",
        variant: "error"
      }));
    }

    dispatch(printReceipt(moveOutConfirmation?.transaction?.id)).then((resp) => {
      if (!resp.type.includes("rejected")) {
        const link = document.createElement("a");
        link.href = resp.payload as string;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  const handleEmailReceipt = () => {
    setCompletedModalOpen(false);
    dispatch(setSendEmailModalOpened());
  };

  return (
    <Modal
      open={open}
      onClose={() => onModalClose()}
      aria-labelledby={"Modal Component"}
      aria-describedby={"A Modal component."}
      className={classes.modal}
      data-testid={"modal-move-in-confirmation"}
    >
      <Grid container className={classes.modal}>
        <Grid
          container
          component={Paper}
          elevation={0}
          variant={"outlined"}
          spacing={2}
          className={classes.container}
          pb={2}
          bgcolor={"green"}
        >
          <Grid container>
            <Grid item xs={12} justifyContent={"center"}
              textAlign={"center"} fontSize={"5rem"}>
              <CheckCircle className={classes.icon} fontSize={"large"} />
            </Grid>
            <Grid item xs={12} justifyContent={"center"}
              textAlign={"center"}>
              <Typography variant={"h5"} textAlign={"center"} color={"white"}>
                Move Out Complete
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          component={Paper}
          elevation={0}
          variant={"outlined"}
          spacing={2}
          className={classes.actionButtonContainer}
          pb={2}
        >
          <Grid container justifyContent={"center"}>
            <Grid item className={classes.description}>
              <Typography variant={"body1"} textAlign={"center"}>
                Payment is complete. Occupant has been succesfully moved out of Unit {unitNumber}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            // pt={4}
            container
            justifyContent={"center"}
            className={classes.buttonContainer}
          >
            <Grid item className={classes.iconContainer}>
              <ReceiptOutlined />
              <Button
                data-testid={"cancel-button"}
                className={classes.actionButton}
                onClick={handlePrintReceiptClick}
                color={"secondary"}
              >
                Print Receipt
              </Button>
            </Grid>
            <Grid item className={classes.iconContainer}>
              <EmailOutlined />
              <LoadingButton
                data-testid={"confirm-button-modal"}
                className={classes.actionButton}
                type={"submit"}
                onClick={handleEmailReceipt}
              >
                Email Receipt
              </LoadingButton>
            </Grid>
          </Grid>

          <Grid container xs={12} justifyContent={"center"}
            py={2}>
            <Button
              data-testid={"cancel-button"}
              className={classes.cancelButton}
              onClick={() => onModalClose()}
              variant={"outlined"}
              color={"secondary"}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CompletedMoveOutModal;
