import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Resend Document Modal" })(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  container: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
    width: "35rem",
    boxShadow: "0 .188rem .375rem rgba(0,0,0, .2)",
    borderRadius: "1rem"
  },
  confirmationButton: {
    color: theme.palette.primary.contrastText
  },
  cancelButton: {
    marginRight: "2rem",
    color: theme.palette.error.contrastText
  }
}));

export default useStyles;
