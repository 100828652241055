import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "UIModal" })((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    flexDirection: "column"
  },
  container: {
    width: "35rem",
    borderRadius: "1rem",
    padding: theme.spacing(8),
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  actionButton: {
    color: theme.palette.primary.contrastText,
    minWidth: theme.spacing(10),
    minHeight: theme.spacing(5),
    fontSize: theme.typography.body2.fontSize
  },
  cancelButton: {
    minWidth: theme.spacing(10),
    minHeight: theme.spacing(5),
    marginTop: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.body2.fontSize
  },
  icon: {
    color: theme.palette.background.default,
    fontSize: "8rem"
  },
  actionButtonContainer: {
    width: "35rem",
    borderRadius: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  description: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10)
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly"
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: theme.palette.primary.contrastText
  }
}));

export default useStyles;
