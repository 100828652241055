import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getAvailableUnitsByProductType = createAsyncThunk(
  "getAvailableUnitsByProductType",
  (id: number, { dispatch, getState, rejectWithValue }) => {
    const store = getState() as RootState;
    const facilityId = store.dealInformation.dealFacility!.id;

    return axios.get(`/api/v1/facilities/${facilityId}/product-types/${id}/units/available`)
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = `There was an error getting the Available Units for Product Type ${id}`;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
