import { Button, Grid, Typography } from "@mui/material";
import React, { FC, useEffect } from "react";
import {
  selectMerchandiseItems,
  setMerchandiseQuantity
} from "src/store/reducers/merchandiseItemsSlice/merchandiseItemsSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Edit } from "@mui/icons-material";
import EmptyTable from "src/components/ui/PMSTable/EmptyTable/EmptyTable";
import { GridCellEditCommitParams } from "@mui/x-data-grid";
import ItemManagementLayout from "src/layouts/ItemManagement/ItemManagementLayout";
import { Merchandise } from "src/models/Merchandise";
import PMSTable from "src/components/ui/PMSTable/PMSTable";
import ReviewAndPayQuantityTableColumns from "./ReviewAndPayQuantityTableColumns/ReviewAndPayQuantityTableColumns";
import { getAllMerchandiseCategories } from "src/store/thunks/merchandiseCategory/getAll/getAllMerchandiseCategories";
import {
  selectMerchLineItemIds, selectPosState
} from "src/store/reducers/pointOfSalesSlice/pointOfSalesSlice";
import { selectMerchandiseCategories } from "src/store/reducers/merchandiseCategoriesSlice/merchandiseCategoriesSlice";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { useNavigate } from "react-router";
import useStyles from "./ReviewAndPayQuanitityTable.styles";

interface ReviewAndPayQuantityTableProps { }

const emptyTable = () => (
  <EmptyTable
    collectionNotFound={"merchandise items"}
    value={"merchandise item"}
  />
);

const ReviewAndPayQuantityTable: FC<ReviewAndPayQuantityTableProps> = () => {
  const allMerchItems = useAppSelector(selectMerchandiseItems);
  const selectedMerchItemIds = useAppSelector(selectMerchLineItemIds);
  const selectedMerchItems = allMerchItems.filter(merchItems => selectedMerchItemIds.includes(merchItems.id));
  const merchandiseCategories = useAppSelector(selectMerchandiseCategories);
  const posState = useAppSelector(selectPosState);

  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const navigate = useNavigate();

  const handleEditOrAdd = () => {
    navigate("/merchandise/point-of-sales/choose-merch");
  };

  const headerButton = (
    <Button
      onClick={handleEditOrAdd}
      variant={"contained"}
      className={classes.addEditButton}
      startIcon={<Edit />}
    >
      Edit/Add Items
    </Button>
  );

  useEffect(() => {
    !merchandiseCategories.length && dispatch(getAllMerchandiseCategories());
  }, []);

  return (
    <ItemManagementLayout noMarginTop title={"Review Order"} headerButton={headerButton}>
      <Grid
        className={classes.root}
        container
      >
        <Grid item xs={12}>
          <PMSTable
            rows={selectedMerchItems}
            emptyTableDisplay={emptyTable}
            columns={ReviewAndPayQuantityTableColumns(merchandiseCategories)}
            sortingMode={"client"}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            className={classes.subtotalBar}
            variant={"h5"
          }
          >
            Subtotal: ${posState.subTotal}
          </Typography>
        </Grid>
      </Grid>
    </ItemManagementLayout>
  );
};

export default ReviewAndPayQuantityTable;
