import { Grid, Paper } from "@mui/material";
import React, { ReactElement } from "react";
import {
  selectTransferUnitLoading,
  setTransferStoringReason,
  setTransferUnitModalOpen
} from "../../../../store/reducers/transferUnitSlice/transferUnitSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import ConfirmTransferUnitModal from "./ConfirmTransferUnitModal/ConfirmTransferUnitModal";
import DealDetails from "../../../../layouts/stepper/ReviewLayout/DealDetails/DealDetails";
import PaymentEntry from "src/pages/Deals/CreateMoveIn/ReviewMoveIn/PaymentEntry/PaymentEntry";
import PaymentMethod from "src/pages/Deals/CreateMoveIn/ReviewMoveIn/PaymentMethod/PaymentMethod";
import { PaymentMethod as PaymentMethodEnum } from "src/enums/PaymentMethod";
import ReviewActionButtons from "../../../../layouts/stepper/ReviewLayout/ReviewActionButtons/ReviewActionButtons";
import ReviewHeader from "../../../../layouts/stepper/ReviewLayout/ReviewHeader/ReviewHeader";
import ReviewLayout from "../../../../layouts/stepper/ReviewLayout/ReviewLayout";
import { TRANSFER_UNIT_PATHS } from "src/routes/TransferUnitRoutes.";
import { createTransferUnit } from "src/store/thunks/transfer/create/createTransferUnit";
import { resetExternalTppPlanState } from "src/store/reducers/externalTppPlanSlice/externalTppPlanSlice";
import { savePaymentInstrument } from "src/store/thunks/paymentInstrument/savePaymentInstrument";
import { selectCreditCard } from "src/store/reducers/creditCardSlice/creditCardSlice";
import { selectPaymentType } from "src/store/reducers/paymentSlice/paymentSlice";
import { selectSelectedOccupant } from "src/store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { selectTargetPath } from "../../../../store/reducers/navigationSlice/navigationSlice";
import { useNavigate } from "react-router";

const ReviewTransferUnit: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector(selectTransferUnitLoading);
  const redirect = useAppSelector(selectTargetPath);
  const creditCard = useAppSelector(selectCreditCard);
  const paymentType = useAppSelector(selectPaymentType);
  const selectedOccupant = useAppSelector(selectSelectedOccupant);
  const formId = "transfer-unit-payment-form";

  const handleOpenTransferUnitModal = () => {
    dispatch(setTransferUnitModalOpen(true));
  };

  const handleGoBack = () => {
    if (redirect) {
      return navigate(redirect);
    }

    navigate(TRANSFER_UNIT_PATHS.processTransferUnit);
  };

  const handleCancel = () => {
    navigate(TRANSFER_UNIT_PATHS.getTransferUnitStarted);
  };

  const handleSubmit = async(storingReason: string) => {
    await dispatch(setTransferStoringReason(storingReason));

    await dispatch(createTransferUnit()).then((resp) => {
      if (!resp.type.includes("rejected")) {
        //if it's autobill the backend will create the payment instrument automatically
        if (
          paymentType === PaymentMethodEnum.creditCard &&
          creditCard.isPreferredPayment &&
          !creditCard.enableAutoPay
        ) {
          dispatch(
            savePaymentInstrument({
              occupantId: selectedOccupant?.id!,
              creditCard: {
                cardHolderName: creditCard.name,
                cardNumber: creditCard.number.replaceAll(" ", ""),
                expirationMonth: creditCard.expiration.slice(0, 2),
                expirationYear: "20" + creditCard.expiration.slice(2, 4),
                cardSecurityCode: creditCard.cvv,
                cardProcessor: creditCard.type
              }
            })
          );
        }

        navigate(TRANSFER_UNIT_PATHS.confirmTransferUnit);
      }
    });

    setTransferUnitModalOpen(false);
    dispatch(resetExternalTppPlanState());
  };

  return (
    <ReviewLayout
      header={<ReviewHeader headerText={"Review Transfer"} backMethod={handleGoBack} goBackText={"Process Transfer"} />}
      actionButtons={
        <ReviewActionButtons ctaText={"Transfer"} loading={loading} formId={formId}
          cancelMethod={handleCancel} />
      }
    >
      <Paper elevation={1}>
        <DealDetails />
        <Grid container spacing={1} item
          justifyContent={"space-between"} p={4} xs={12}
          lg={"auto"}>
          <Grid item xs={12} lg={3}>
            <PaymentMethod label={"Payment Method"} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <PaymentEntry formId={formId} handleSubmit={handleOpenTransferUnitModal} />
          </Grid>
        </Grid>
      </Paper>
      <ConfirmTransferUnitModal handleSubmit={handleSubmit} />
    </ReviewLayout>
  );
};

export default ReviewTransferUnit;
