import * as Yup from "yup";
import { Grid, TextField, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import {
  resetMerchandiseFormSlice,
  selectMerchandiseId,
  selectMerchandiseName
} from "src/store/reducers/merchandiseFormSlice/merchandiseFormSlice";
import {
  selectMerchandiseDeleteLoading,
  selectMerchandiseDeleteOpen,
  toggleMerchandiseDeleteModal
} from "src/store/reducers/modalSlice/modalSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import AppModal from "src/components/ui/AppModal/AppModal";
import { deleteMerchandise } from "src/store/thunks/merchandise/delete/deleteMerchandise";
import { getAllMerchandise } from "src/store/thunks/merchandise/getAll/getAllMerchandise";
import { useNavigate } from "react-router";

interface RemoveMerchandiseModalProps{

}

const RemoveMerchandiseModal:FC<RemoveMerchandiseModalProps> = () => {
  const merchandiseDeleteModalOpen = useAppSelector(selectMerchandiseDeleteOpen);
  const merchandiseDeleteLoading = useAppSelector(selectMerchandiseDeleteLoading);
  const navigate = useNavigate();
  const [confirmedMerchandiseId, setConfirmedMerchandiseId] = useState("");
  const dispatch = useAppDispatch();
  const [confirmError, setConfirmError] = useState(false);
  const [confirmErrorMessage, setConfirmErrorMessage] = useState("");

  const merchandiseId = useAppSelector(selectMerchandiseId);
  const merchandiseName = useAppSelector(selectMerchandiseName);
  const cancelAction = () => {
    setConfirmError(false);
    setConfirmErrorMessage("");
    dispatch(toggleMerchandiseDeleteModal(false));
    dispatch(resetMerchandiseFormSlice());
  };

  const handleDeleteMerchandise = async() => {
    const re = new RegExp(`^${String(merchandiseId)}$`);

    const confirmSchema = Yup.string()
      .required("This field is required")
      .matches(re, "Incorrect Merchandise ID");

    await confirmSchema.validate(confirmedMerchandiseId).then(() => {
      setConfirmError(false);
      setConfirmErrorMessage("");
      dispatch(deleteMerchandise(confirmedMerchandiseId)).then(() => {
        dispatch(toggleMerchandiseDeleteModal(false));
        dispatch(getAllMerchandise());
        dispatch(resetMerchandiseFormSlice());
        navigate("/merchandise/manage-merchandise");
      });
    }).catch((err) => {
      let errorMessage = "";
      setConfirmError(true);

      if (err && err.errors && err.errors[0]) {
        errorMessage = err.errors[0];
      }

      setConfirmErrorMessage(errorMessage);
    });
  };

  return (
    <AppModal
      data-testid={"merchandise-delete-modal"}
      open={merchandiseDeleteModalOpen}
      onCloseAction={cancelAction}
      aria-labelledby={"delete-merchandise-modal-title"}
      aria-describedby={"delete-merchandise-modal-description"}
      confirmActionLoading={merchandiseDeleteLoading}
      confirmAction={() => handleDeleteMerchandise()}
      cancelAction={cancelAction}
      >
      <Typography
        id={"delete-merchandise-modal-title"}
        align={"center"}
        variant={"h5"}
        >
        Confirm Merchandise Removal
      </Typography>
      <Typography mb={2} id={"delete-merchandise-modal-description"} align={"center"}>
        To confirm removal of this merchandise from this facility, please enter the Merchandise ID of the selected
        item.
      </Typography>
      <Grid container item justifyContent={"center"}>
        <Typography mb={2}>
          Merchandise Name: {merchandiseName}<br />
          Merchandise ID: {merchandiseId}
        </Typography>
      </Grid>
      <TextField
        error={confirmError}
        helperText={confirmErrorMessage}
        id={"merchandise-delete-id-confirmation"}
        label={"Merchandise ID"}
        variant={"outlined"}
        onChange={(event) => setConfirmedMerchandiseId(event.target.value)}
        />
    </AppModal>
  );
};

export default RemoveMerchandiseModal;
