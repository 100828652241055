import { Badge, Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import useStyles from "./PMSTaskCategoryBadge.styles";

interface TaskCategoryBadge {
  category: string
  color: string
}

const PMSTaskCategoryBadge: FC<TaskCategoryBadge> = ({ category, color = "blue" }) => {
  const { classes } = useStyles({ color });
  return (
    <>
      <Badge
        color={"primary"}
        classes={{ badge: classes.color }}
        className={classes.dot}
        variant={"dot"}
      />
      <Typography>{category}</Typography>
    </>
  );
};

export default PMSTaskCategoryBadge;
