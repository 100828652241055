import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles<{ active: boolean }>({ label: "InsurancePlanCard" })((theme, { active }) => ({
  boldTitle: {
    fontWeight: "1000"
  },
  statusText: {
    background: active ? theme.palette.success.main : theme.palette.error.main,
    textAlign: "center",
    fontStyle: "italic",
    color: theme.palette.common.white
  },
  cardContent: {
    maxWidth: "50vw",
    background: theme.palette.background.default,
    borderRadius: "1rem"
  },
  itemStatus: {
    color: theme.palette.success.main,
    textTransform: "capitalize"
  }

}));

export default useStyles;
