import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ItemManagementLayout from "src/layouts/ItemManagement/ItemManagementLayout";
import PaymentForm from "./components/PaymentForm/PaymentForm";
import PaymentMethod from "src/pages/Deals/CreateMoveIn/ReviewMoveIn/PaymentMethod/PaymentMethod";
import { PaymentMethod as PaymentMethodEnum } from "src/enums/PaymentMethod";
import { setPaymentType } from "src/store/reducers/paymentSlice/paymentSlice";
import { useAppDispatch } from "src/store/hooks";
import useStyles from "./PaymentMethodSection.styles";

const PaymentMethodSection = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPaymentType(PaymentMethodEnum.creditCard));
  }, []);

  return (
    <ItemManagementLayout title={"Payment Methods"}>
      <Grid container justifyContent={"space-between"} gridTemplateColumns={""}>
        <Grid
          xs={2}
          item
          mt={"1rem"}
          pr={4}
       >
          <Grid item xs={12}>
            <Typography className={classes.headerCell}>
              Payment Method
            </Typography>
          </Grid>
          <Grid item xs={12} lg={3}>
            <PaymentMethod paymentOptions={[PaymentMethodEnum.cash, PaymentMethodEnum.creditCard]} />
          </Grid>
        </Grid>
        <PaymentForm />
      </Grid>
    </ItemManagementLayout>
  );
};

export default PaymentMethodSection;
