import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

interface GetLedgerInsurancePlan {
    occupantId: string
    ledgerId: string
}

export const getLedgerInsurancePlan = createAsyncThunk(
  "getLedgerInsurancePlan",
  ({ occupantId, ledgerId }: GetLedgerInsurancePlan, { dispatch, getState, rejectWithValue }) => {
    return axios.get(`/api/v1/occupants/${occupantId}/ledgers/${ledgerId}/current-enrolled-tpp-plan`)
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = "There was an error getting the Insurance Plan for this Ledger";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
