import { Divider, Grid, Typography } from "@mui/material";

import Form from "src/components/form/Form";
import PMSSwitch from "src/components/ui/PMSSwitch/PMSSwitch";
import React from "react";

interface AuctionSettingsProps {
  flagIndividualUnitForAuction: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const AuctionSettings = ({ flagIndividualUnitForAuction, setFieldValue }: AuctionSettingsProps) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography gutterBottom variant={"h5"}>
          Auction Settings
        </Typography>
      </Grid>
      <Grid mb={1} item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} mt={1}>
        <PMSSwitch
          smallText
          name={"moveOutMarkBadDebt"}
          label={"Allow User to Flag Individual Unit for Auction"}
          labelPlacement={"end"}
          isChecked={flagIndividualUnitForAuction}
          changeHandler={(e) => setFieldValue("flagIndividualUnitForAuction", e.target.checked)}
        />

      </Grid>
    </Grid>
  );
};

export default AuctionSettings;
