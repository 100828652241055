import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getAllRevenueClasses = createAsyncThunk(
  "getAllRevenueClasses",
  (facilityId: number | unknown, { dispatch, rejectWithValue }) => {
    if (!facilityId) {
      const errorMessage = "Facility ID not found";

      dispatch(showSnackbar({
        message: errorMessage,
        variant: "error"
      }));

      rejectWithValue(errorMessage);
      return;
    }

    const searchParams = {
      pageSize: 999
    };

    return axios.get(`/api/v1/facilities/${facilityId}/revenue-classes`, { params: { ...searchParams } })
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = "There was an error getting the Revenue Classes";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
