import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const signDocument = createAsyncThunk(
  "signDocument",
  ({ id }: { id: number }, { dispatch, rejectWithValue }) => {
    return axios.post(`/api/v1/esign/${id}/sign`)
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = "There was an error updating the E-sign document status";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
