import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp, OpenInNew } from "@mui/icons-material";
import React, { FC, SyntheticEvent } from "react";
import {
  selectDropdownOpen,
  selectShowDrawer,
  toggleOpenDropdown
} from "src/store/reducers/navigationSlice/navigationSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { useLocation, useNavigate } from "react-router";
import { NavLink } from "../../../../models/NavLink";
import clsx from "clsx";
import useStyles from "./NavigationLink.styles";

interface NavLinkProps {
  link: NavLink,
  openInNewTab?: boolean
}

const NavigationLink: FC<NavLinkProps> = ({ link, openInNewTab }: NavLinkProps) => {
  const drawerIsShown = useAppSelector(selectShowDrawer);
  const dropdownOpen = useAppSelector(selectDropdownOpen);
  const location = useLocation();
  const navigate = useNavigate();
  const { classes } = useStyles({ showDrawer: drawerIsShown });
  const dispatch = useAppDispatch();

  const childLinks = () => !!(link.childLinks && link.childLinks.length);

  const linkText = (text: string) => (
    <ListItemText data-testid={`${link.name}-link-text`} primary={text} className={classes.listText} />
  );

  const dropdownArrow = () => (dropdownOpen === link.name) ? <KeyboardArrowUp /> : <KeyboardArrowDown />;

  const handleLinkClick = (e: SyntheticEvent, link: NavLink) => {
    e.preventDefault();
    if (link.childLinks && link.childLinks.length) {
      dispatch(toggleOpenDropdown(link.name));
    } else {
      if (openInNewTab) {
        window.open(link.path, "_blank");
      } else {
        navigate(link.path);
      }
    }
  };

  const handleChildLinkClick = (e: SyntheticEvent, childLink: NavLink) => {
    e.preventDefault();
    if (childLink.openInNewTab) {
      window.open(childLink.path, "_blank");
    } else {
      navigate(childLink.path);
    }
  };

  return (
    <>
      <ListItem
        disabled={!drawerIsShown}
        data-testid={`${link.name}-list-item-dropdown`}
        aria-label={`${link.name} dropdown toggle`}
        className={clsx(
          classes.link,
          classes.topLink,
          (location.pathname === link.path) && classes.selectedLink
        )}
        onClick={(e: SyntheticEvent) => handleLinkClick(e, link)}
        button
      >
        <ListItemIcon className={classes.listIcon}>{link.icon}</ListItemIcon>
        {linkText(link.name)}
        <ListItemIcon className={classes.arrowIcon}>
          {(childLinks() && drawerIsShown) ? dropdownArrow() : null}
          {openInNewTab && <OpenInNew />}
        </ListItemIcon>
      </ListItem>

      {drawerIsShown && link.childLinks && (
      <Collapse
        in={link.name === dropdownOpen}
        timeout={"auto"}
        unmountOnExit
  >
        <List
          dense
          component={"nav"}
          aria-label={`${link.name} child links`}
          disablePadding
    >
          {link.childLinks
            .filter((childLink): childLink is NavLink => childLink !== null)
            .map((childLink) => (
              <ListItem
                data-testid={`${childLink.name}-dropdown-link-item`}
                key={childLink.path + "-child"}
                className={clsx(
                  classes.link,
                  classes.dropdownLink,
                  childLink.path && location.pathname.includes(childLink.path) && classes.selectedLink
                )}
                onClick={(e: SyntheticEvent) => handleChildLinkClick(e, childLink)}
                button
          >
                {linkText(childLink.name)}
              </ListItem>
            ))}
        </List>
      </Collapse>
      )}

    </>
  );
};

export default NavigationLink;
