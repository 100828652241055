import { FormControl, FormHelperText, InputLabel, Select, SelectChangeEvent, SelectProps } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";
import useStyles from "./PMSMultiSelect.styles";

interface PMSMultiSelectProps {
  id: string
  children: ReactNode
  value: unknown
  changeHandler: (event: SelectChangeEvent<any>, child: ReactNode) => void
  renderValue?: ((value: any) => ReactNode) | undefined
  name?: string
  label?: string
  fullWidth?: boolean
  variant?: SelectProps["variant"]
  disabled?: boolean
  error?: boolean
  helperText?: string
  className?: any
  size?: SelectProps["size"]
}

const PMSMultiSelect: React.FC<PMSMultiSelectProps> = ({
  id,
  value,
  children,
  changeHandler,
  label,
  name,
  renderValue,
  fullWidth = true,
  variant = "outlined",
  disabled = false,
  error = false,
  helperText = "",
  className,
  size = "medium"
}): ReactElement => {
  const { classes } = useStyles();
  return (
    <>
      {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
      <FormControl fullWidth={fullWidth} variant={variant}>
        <Select
          displayEmpty
          multiple
          id={id}
          value={value}
          name={name}
          fullWidth={fullWidth}
          variant={variant}
          size={size}
          renderValue={renderValue}
          onChange={changeHandler}
          error={error}
          disabled={disabled}
          SelectDisplayProps={{ className: classes.fixSelect }}
        >
          {children}
        </Select>
      </FormControl>
      {error ? <FormHelperText style={{ marginLeft: "14px" }} error>{helperText}</FormHelperText> : null}
    </>
  );
};

export default PMSMultiSelect;
