import { Divider, Grid, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import {
  selectIsTransferUnitModalOpen,
  selectUnitLedgers,
  setIsTransferUnitModalOpen
} from "../../../../../store/reducers/ledgersSlice/ledgersSlice";

import AutobillingConfirmationModal from "./AutobillingConfirmationModal/AutobillingConfirmationModal";
import AutomaticPaymentsModal from "./AutomaticPaymentsModal/AutomaticPaymentsModal";
import AwaitingSignatureSubmissionModal from "./AwaitingSignatureSubmissionModal/AwaitingSignatureSubmissionModal";
import ConfirmAutomaticPaymentsModal from "./ConfirmAutomaticPaymentsModal/ConfirmAutomaticPaymentsModal";
import ConfirmInsuranceChangeModal from "./ConfirmInsuranceChangeModal/ConfirmInsuranceChangeModal";
import DisableAutoBillModal from "./DisableAutoBillModal/DisableAutoBillModal";
import EmailAutoBillModal from "./EmailAutoBillModal/EmailAutoBillModal";
import GenerateDocumentModal from "./GenerateDocumentModal/GenerateDocumentModal";
import ReRunAutoBillModal from "./ReRunAutoBillModal/ReRunAutoBillModal";
import RentedUnitCard from "./RentedUnitsCard/RentedUnitCard";
import SignatureAcceptedModal from "./SignatureAcceptedModal/SignatureAcceptedModal";
import { TRANSFER_UNIT_PATHS } from "src/routes/TransferUnitRoutes.";
import UpdateInsurancePolicyModal from "./UpdateInsurancePolicyModal/UpdateInsurancePolicyModal";
import WarningModal from "src/components/modal/WarningModal";
import { setIsTransfer } from "src/store/reducers/transferUnitSlice/transferUnitSlice";
import { useAppSelector } from "../../../../../store/hooks";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

const RentedUnits: React.FC = (): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const unitLedgers = useAppSelector(selectUnitLedgers);
  const isModalOpen = useAppSelector(selectIsTransferUnitModalOpen);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant={"h6"} gutterBottom>
            Rented Units
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {unitLedgers.map((ledger, index) => (
            <RentedUnitCard data-testid={`rented-unit-card-${index}`} key={ledger.id} ledger={ledger} />
          ))}
        </Grid>
      </Grid>
      <WarningModal
        open={isModalOpen}
        buttonLabel={"Transfer unit"}
        onButtonClick={() => {
          dispatch(setIsTransfer(true));
          dispatch(setIsTransferUnitModalOpen(false));
          navigate(TRANSFER_UNIT_PATHS.selectUnitForMoveOutTransferUnit, { state: { isTransfer: true } });
        }}
        onCloseClick={() => dispatch(setIsTransferUnitModalOpen(false))}
        title={"Transferring Unit"}
      >
        <>
          <Typography>You are in the process of transfering to a different unit.</Typography>
          <Typography mt={2}>Are you sure you want to transfer the unit?</Typography>
        </>
      </WarningModal>
      <UpdateInsurancePolicyModal />
      <ConfirmInsuranceChangeModal />
      <AutomaticPaymentsModal />
      <ConfirmAutomaticPaymentsModal />
      <AutobillingConfirmationModal />
      <EmailAutoBillModal />
      <AwaitingSignatureSubmissionModal />
      <SignatureAcceptedModal />
      <DisableAutoBillModal />
      <GenerateDocumentModal />
      <ReRunAutoBillModal />
    </>
  );
};

export default RentedUnits;
