import { Divider, Grid, IconButton, Modal, Paper, Typography, useMediaQuery } from "@mui/material";
import React, { FC } from "react";
import {
  selectOccupantDocumentModalOpened,
  selectPDF,
  setOccupantDocumentModalOpened
} from "src/store/reducers/occupantSlice/occupantSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Close } from "@mui/icons-material";
import useStyles from "./ViewDocumentModal.styles";
import PDFViewer from "src/components/PdfViewer/PDFViewer";

interface ViewDocumentModalProps {}

const ViewDocumentModal: FC<ViewDocumentModalProps> = () => {
  const { classes } = useStyles();
  const viewDocumentModalOpen = useAppSelector(selectOccupantDocumentModalOpened);
  const dispatch = useAppDispatch();
  const blobUrl = useAppSelector(selectPDF);

  const handleCloseModal = () => {
    dispatch(setOccupantDocumentModalOpened(false));
  };

  return (
    <Modal className={classes.root} open={viewDocumentModalOpen} onClose={() => handleCloseModal()}>
      <Grid className={classes.inner} spacing={2} component={Paper}
        container p={2}>
        <Grid item xs={11}>
          <Typography variant={"h5"}>VIEW DOC</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={() => handleCloseModal()}>
            <Close />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container justifyContent={"center"} item
          xs={12}>
          <PDFViewer pdfUrl={blobUrl} />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ViewDocumentModal;
