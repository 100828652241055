import { Button, InputAdornment, TextField } from "@mui/material";
import React, {
  ReactElement,
  useCallback,
  useEffect
} from "react";
import {
  resetTasksSlice,
  selectTasksFilter,
  selectTasksLoading,
  selectTasksSearchValue,
  setTasksFilter,
  setTasksSearchValue
} from "src/store/reducers/tasksSlice/tasksSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { APIFilter } from "src/models/responses/APIFilter";
import { Breadcrumb } from "src/models/Breadcrumb";
import FilterButtonGroup from "src/components/ui/FilterButtonGroup/FilterButtonGroup";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import { Search } from "@mui/icons-material";
import { SyntheticInputEvent } from "react-number-format/types/types";
import TableWrapper from "src/layouts/TableWrapper/TableWrapper";
import TaskTable from "../TaskTable/TaskTable";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import clsx from "clsx";
import { debounce } from "lodash";
import { getTasks } from "src/store/thunks/task/getAll/getTasks";
import { today } from "src/utils/__dateAndTimeUtils__/today";
import useStyles from "./ManageTasks.styles";
import { resetTablesSlice } from "src/store/reducers/tablesSlice/tablesSlice";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Task Management"
  },
  {
    name: "Manage Tasks",
    bold: true
  }
];

export const todayFilter: APIFilter = {
  "filter[due_date]": `${today}`
};

export const upcomingFilter: APIFilter = {
  "filter[due_date]": `gte:${today}`
};

const ManageTasks: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectTasksFilter);
  const { classes } = useStyles({ filter: filter });
  const tasksLoading = useAppSelector(selectTasksLoading);
  const searchValue = useAppSelector(selectTasksSearchValue);
  const debounceSearch = useCallback(debounce(() => dispatch(getTasks({ getNotifications: false })), 600), []);
  const permissions = useAppSelector(selectFacilityPermissions);
  const manageTasksPermissions = createPermissions(permissions, "manage_tasks");

  useEffect(() => {
    dispatch(getTasks({ getNotifications: false }));

    return () => {
      dispatch(resetTasksSlice());
      dispatch(resetTablesSlice());
    };
  }, []);

  const handleSearchChange = (e: SyntheticInputEvent) => {
    dispatch(setTasksSearchValue(e.target.value));
    debounceSearch();
  };

  const filterTasks = (filter: APIFilter | "") => {
    dispatch(setTasksFilter(filter));
    dispatch(getTasks({ getNotifications: false }));
  };

  const searchBar = (
    <TextField
      id={"search-tasks-table"}
      placeholder={"- Search by Task -"}
      size={"small"}
      InputProps={{ endAdornment: <InputAdornment position={"end"}><Search /></InputAdornment> }}
      value={searchValue}
      variant={"outlined"}
      name={"tasksTableSearch"}
      onChange={handleSearchChange}
    />
  );

  const filterButtonGroup = (
    <FilterButtonGroup heading={"Display Tasks: "}>
      <Button
        className={clsx(classes.buttonText, classes.activeFilter)}
        onClick={() => filterTasks("")}
        data-testid={"all-button"}
      >
        All
      </Button>
      <Button
        className={clsx(classes.buttonText, classes.todayFilter)}
        onClick={() => filterTasks(todayFilter)}
        data-testid={"today-button"}
      >
        Today
      </Button>
      <Button
        className={clsx(classes.buttonText, classes.upcomingFilter)}
        onClick={() => filterTasks(upcomingFilter)}
        data-testid={"upcoming-button"}
      >
        Upcoming
      </Button>
    </FilterButtonGroup>
  );

  const pageHeader = <PageHeader title={"Manage Tasks"} breadcrumbs={breadcrumbs} />;

  if (manageTasksPermissions.read) {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <TableWrapper
          title={"Tasks"}
          {...manageTasksPermissions.create && {
            addButtonText: "Create Task",
            addButtonLink: "/task-management/create"
          }}
          filterButtonGroup={filterButtonGroup}
          searchBar={searchBar}
          loading={tasksLoading}
          table={<TaskTable />}
        />
      </ViewWrapper>
    );
  } else {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <h1>You do not have permission to view this page.</h1>
      </ViewWrapper>
    );
  }
};

export default ManageTasks;
