import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AutomationSliceState } from "./AutomationSliceState";
import { GetAutomationPayload } from "src/store/thunks/automations/get/GetAutomationPayload";
import { RootState } from "../../rootStore";
import { createAutomation } from "src/store/thunks/automations/create/createAutomation";
import { getAutomation } from "src/store/thunks/automations/get/getAutomation";

export const initialState: AutomationSliceState = {
  automation: {
    name: "",
    description: "",
    amount: null,
    recurrence: null,
    revenueClassId: null,
    feeId: null,
    isActive: false,
    isOriginal: false,
    appliedAt: "",
    type: null,
    days: "",
    is_due_before: 1,
    applicableDocumentForAutomation: 0,
    taskId: null,
    isDeliveryByEmail: false,
    isDeliveryByPostalMail: false,
    isDeliveryByCertifiedEmail: false,
    automation_category: 0
  },
  isLoading: false
};

export const automationSlice = createSlice({
  name: "automation",
  initialState,
  reducers: {
    setAutomation: (state, action: PayloadAction<AutomationSliceState>) => {
      state = action.payload;
      return state;
    },
    setIsDeliveryByEmail: (state, action: PayloadAction<{ value?: boolean }>) => {
      state.automation.isDeliveryByEmail = action?.payload?.value || !state.automation.isDeliveryByEmail;
    },
    setIsDeliveryByCertifiedEmail: (state, action: PayloadAction<{ value?: boolean }>) => {
      state.automation.isDeliveryByCertifiedEmail =
        action?.payload?.value || !state.automation.isDeliveryByCertifiedEmail;
    },
    setIsDeliveryByPostalMail: (state, action: PayloadAction<{ value?: boolean }>) => {
      state.automation.isDeliveryByPostalMail = action?.payload?.value || !state.automation.isDeliveryByPostalMail;
    },
    resetAutomationSliceState: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAutomation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAutomation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAutomation.fulfilled, (state, action: PayloadAction<GetAutomationPayload>) => {
        state.isLoading = false;
        state.automation.name = action.payload.name;
        state.automation.description = action.payload.description;
        state.automation.amount = action.payload.amount;
        state.automation.days = action.payload.days;
        state.automation.is_due_before = action.payload.is_due_before;
        state.automation.recurrence = action.payload.recurrence;
        state.automation.automation_category = action.payload.automation_category;
        state.automation.revenueClassId = action.payload.revenue_class_id;
        state.automation.feeId = action.payload.fee_id;
        state.automation.isOriginal = action.payload.is_original;
        state.automation.isActive = action.payload.is_active;
        state.automation.taskId = action.payload.task_id;
        state.automation.applicableDocumentForAutomation = action.payload.document_template_id;
        state.automation.isDeliveryByCertifiedEmail = action.payload.is_delivery_by_certified_email;
        state.automation.isDeliveryByEmail = action.payload.is_delivery_by_email;
        state.automation.isDeliveryByPostalMail = action.payload.is_delivery_by_postal_mail;
      })
      .addCase(getAutomation.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const selectAutomationLoading = (state: RootState) => state.automation.isLoading;
export const selectAutomationState = (state: RootState) => state.automation.automation;

export const {
  setAutomation,
  resetAutomationSliceState,
  setIsDeliveryByCertifiedEmail,
  setIsDeliveryByEmail,
  setIsDeliveryByPostalMail
} = automationSlice.actions;

export default automationSlice.reducer;
