import { Button, Grid, InputLabel, Typography } from "@mui/material";
import React, { RefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import {
  selectFileName,
  selectImportLoading,
  setFileName
} from "src/store/reducers/importProductTypesSlice/importProductTypesSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { AddCircle } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import DescriptionIcon from "@mui/icons-material/Description";
import GetAppIcon from "@mui/icons-material/GetApp";
import StepLayout from "../../../../layouts/stepper/StepLayout/StepLayout";
import StepperButtons from "../../../../layouts/stepper/StepLayout/StepperButtons/StepperButtons";
import { createProductType } from "../../../../store/thunks/productType/create/createProductType";
import { importUnits } from "../../../../store/thunks/unit/import/importUnits";
import { selectProductTypeLoading }
  from "../../../../store/reducers/productTypeSlice/productTypeSlice";
import useStyles from "./ImportProductTypes.styles";
import { useLocation, useNavigate } from "react-router";
import PMSAdvisory from "../../../../components/ui/PMSAdvisory/PMSAdvisory";
import Paper from "@mui/material/Paper";
import { showSnackbar } from "../../../../store/reducers/snackbarSlice/snackbarSlice";
import { resetAddProductType } from "../AddProductType";

export function clearUpload(ref: RefObject<HTMLInputElement>, dispatch: Function) {
  if (ref.current) {
    ref.current.value = "";
  }
  dispatch(setFileName(""));
}

export function setFileUpload(e: SyntheticEvent, ref: RefObject<HTMLInputElement>) {
  e.preventDefault();
  e.stopPropagation();
  if (ref.current) {
    ref.current.click();
  }
}

const ImportProductTypes: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const fileName = useAppSelector(selectFileName);
  const importLoading = useAppSelector(selectImportLoading);
  const productAttributesLoading = useAppSelector(selectProductTypeLoading);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const loading = importLoading || productAttributesLoading;

  const navigate = useNavigate();
  const location = useLocation();
  const redirect = location.state ?? null;

  const [uploadedFile, setUploadedFile] = useState<Blob|null>(null);

  const handleFileUploadOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    const file = event.target.files[0];
    dispatch(setFileName(file.name));
    setUploadedFile(file);
  };

  const handleCreateProductType = async() => {
    await dispatch(createProductType())
      .then(async(resp) => {
        if (resp.type.includes("rejected")) {
          dispatch(showSnackbar({
            message: "There was an error creating the product type",
            variant: "error"
          }));
        }

        if (uploadedFile) {
          await dispatch(importUnits(uploadedFile))
            .then(resp => {
              if (resp.type.includes("rejected")) {
                dispatch(showSnackbar({
                  message: "Product type created, but there was an error importing the uploaded units",
                  variant: "error"
                }));
              }
            });
        }

        resetAddProductType(dispatch);

        if (redirect) {
          navigate(redirect);
        } else {
          navigate("/product-types/manage-product-types");
        }
      });
  };

  const stepperId = "import-product-types-form";

  return (
    <>
      <PMSAdvisory
        title={"Import Unit CSV File"}
        advisoryText={"Finally, you can import a .csv list of units for your Product Type in this step. A link to a " +
        "template .csv is available for download below that will aid you in uploading your units."}
        allowSkip={true}
        skipHandler={handleCreateProductType}
      />
      <Paper elevation={1}>
        <StepLayout
          stepperId={stepperId}
          buttons={
            <StepperButtons
              data-testid={"import-units-buttons"}
              stepperId={stepperId}
              loading={loading}
              forwardActionText={"Finish"}
              backAction={() => navigate("/product-types/add-product-type/attributes")}
            />
          }
        >
          <form
            id={stepperId}
            data-testid={stepperId}
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleCreateProductType();
            }}
          >
            <Grid container justifyContent={"center"} alignItems={"center"}>
              <Grid item xs={12}>
                <InputLabel className={classes.label} htmlFor={"test"} >
                  Import Unit CSV File
                </InputLabel>
              </Grid>
              <Grid container justifyContent={"center"} >
                <input
                  ref={fileInputRef}
                  onChange={(e) => handleFileUploadOnChange(e)}
                  type={"file"}
                  name={"csv"}
                  id={"csv"}
                  data-testid={"csv"}
                  className={classes.hideIt}
                />
                {fileName
                  ? <Grid className={classes.dropzone} container justifyContent={"center"}>
                    <DescriptionIcon />
                    <Typography >{fileName}</Typography>
                    <CancelIcon
                      onClick={() => clearUpload(fileInputRef, dispatch)}
                      className={classes.exButton}
                    />
                  </Grid>
                  : <Grid
                      container
                      onClick={(e: SyntheticEvent) => setFileUpload(e, fileInputRef)}
                      className={classes.dropzone}
                  >
                    <AddCircle className={classes.plusButton} />
                    <Typography>Upload CSV</Typography>
                  </Grid>
                }
              </Grid>
              <Grid py={2} item xs={12}>
                <InputLabel className={classes.subText} htmlFor={"test"} >
                  If attributes aren&apos;t listed, you can always define those later.
                </InputLabel>
              </Grid>
              <Button component={"a"} href={"/unit-template.csv"} className={classes.downloadButton}>
                <GetAppIcon className={classes.downloadIcon} />
                Download CSV Template
              </Button>
            </Grid>
          </form>
        </StepLayout>
      </Paper>
    </>
  );
};

export default ImportProductTypes;
