import ErrorSliceState from "./errorSliceState";
import { RootState } from "../../rootStore";
import { createSlice } from "@reduxjs/toolkit";

export const initialState: ErrorSliceState = {
  fatalError: false
};

export const errorReducerSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    logoutError: (state: ErrorSliceState) => {
      sessionStorage.clear();
      localStorage.clear();
      state.fatalError = true;
    }
  }
});

export const selectFatalError = (state: RootState) => state.error.fatalError;

export const {
  logoutError
} = errorReducerSlice.actions;

export default errorReducerSlice.reducer;
