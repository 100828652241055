import React, { FC } from "react";
import { Box } from "@mui/system";
import useStyles from "./PDFViewer.styles";

interface PdfViewerProps {
  pdfUrl: string;
  editable?: boolean;
}

const PDFViewer: FC<PdfViewerProps> = ({ pdfUrl, editable }) => {
  const { classes } = useStyles();

  return (
    <Box>
      <iframe className={classes.iframe} contentEditable={editable} title={"pdf-embed"}
        src={pdfUrl} />
    </Box>
  );
};

export default PDFViewer;
