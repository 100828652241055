import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSearchParams } from "../../../../utils/getSearchParams/getSearchParams";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getAllFacilities = createAsyncThunk(
  "facility/getAllFacilities",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;

    const searchParams = {
      params: {
        ...getSearchParams(state, false, "facility_id"),
        only_enabled: 1,
        with: [
          "keypadAccesses",
          "timeAccesses"
        ]
      }
    };

    return axios.get("/api/v1/facilities", searchParams)
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = "There was an error getting the Facilities";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
