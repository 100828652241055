import * as Yup from "yup";
import moment from "moment";

const validationSchema = Yup.object().shape({
  usePaymentToken: Yup.boolean(),
  paymentInstrument: Yup.number()
    .when("usePaymentToken", {
      is: true,
      then: Yup.number()
        .required("Select saved Credit Card is required"),
      otherwise: Yup.number().nullable().notRequired()
    }),
  cardholderName: Yup.string()
    .when("usePaymentToken", {
      is: false,
      then: Yup.string()
        .required("Cardholder name is required")
        .min(3, "Cardholder name must be at least 3 letters")
        .matches(/^(\w+)\s(\w+)/, "Cardholder name should be in the format of \"First Last\""),
      otherwise: Yup.string().nullable().notRequired()
    }),
  cvv: Yup.string()
    .when("usePaymentToken", {
      is: false,
      then: Yup.string()
        .required("CVV is required")
        .min(3, "CVV must be at least 3 digits"),
      otherwise: Yup.string().nullable().notRequired()
    }),
  creditCardNumber: Yup.string()
    .when("usePaymentToken", {
      is: false,
      then: Yup.string()
        .required("Credit Card Number is required")
        .min(15, "Credit Card Number must be at least 15 digits"),
      otherwise: Yup.string().nullable().notRequired()
    }),
  expDate: Yup.string()
    .when("usePaymentToken", {
      is: false,
      then: Yup.string()
        .test({
          name: "is past date",
          test: function(value) {
            const valueAsDate = `${value?.slice(0, 2)}-01-${value?.slice(2, 4)}`;
            if (moment(valueAsDate).isBefore(moment(), "day")) {
              return this.createError({ message: "A valid date is required" });
            }

            return true;
          }
        })
        .required("Exp Date is required")
        .length(4, "Exp Date format should be MM/YY"),
      otherwise: Yup.string().nullable().notRequired()
    })
});

export default validationSchema;
