import {
  DataGrid,
  DataGridProps,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp
} from "@mui/x-data-grid";
import { IconButton, Tooltip, Typography } from "@mui/material";
import React, { CSSProperties, FC } from "react";
import {
  setCurrentReport,
  setParamsBuilderModalOpen
} from "src/store/reducers/reportParamsSlice/reportParamsSlice";
import { FileOpen } from "@mui/icons-material";
import { useAppDispatch } from "src/store/hooks";

interface ReportsTableProps{
  rows: GridRowsProp
}

interface ActionButtonProps{
  name: string
}

const ActionButton:FC<ActionButtonProps> = ({ name = "N/A" }) => {
  const dispatch = useAppDispatch();

  const handleNavigation = () => {
    dispatch(setCurrentReport(name));
    dispatch(setParamsBuilderModalOpen(true));
  };

  return (
    <Tooltip title={"Build Report"} placement={"bottom-end"}>
      <IconButton onClick={handleNavigation}>
        <FileOpen />
      </IconButton>
    </Tooltip>
  );
};

const reportsTableStyles: CSSProperties = {
  borderRadius: ".75rem",
  minHeight: "35rem"
};
const columns: GridColDef[] = [
  {
    headerName: "Report",
    field: "name",
    flex: 1,
    align: "left",
    renderHeader: () => <Typography pl={2} variant={"subtitle2"}>Report</Typography>,
    renderCell: ({ row }) => <Typography pl={2} variant={"subtitle2"}>{row.name}</Typography>
  },
  {
    headerName: "Actions",
    headerAlign: "center",
    minWidth: 150,
    field: "actions",
    align: "center",
    renderHeader: () => <Typography variant={"subtitle2"}>Actions</Typography>,
    renderCell: ({ row }:GridRenderCellParams) => <ActionButton name={row.name} />
  }
];

const ReportsTable:FC<ReportsTableProps> = ({ rows }) => {
  const reportsTableProps:DataGridProps = {
    style: reportsTableStyles,
    autoHeight: true,
    columns: columns,
    disableSelectionOnClick: true,
    rows: rows
  };

  return (
    <DataGrid hideFooter {...reportsTableProps} />
  );
};

export default ReportsTable;
