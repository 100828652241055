import BuildReport from "src/pages/Reporting/BuildReport/BuildReport";
import React from "react";
import { Route } from "react-router";
import ViewReporting from "src/pages/Reporting/Reporting";

export const groupName = "reporting";

const ReportingRoutes = (
  <>
    <Route path={`${groupName}/view-reports`} element={<ViewReporting/>} />
    <Route path={`${groupName}/view-reports/build`} element={<BuildReport/>} />
  </>
);

export default ReportingRoutes;
