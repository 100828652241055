import { Button, Collapse, Divider, Grid, InputLabel, SelectChangeEvent, TextField, Typography } from "@mui/material";
import React, { FormEvent, useEffect, useState } from "react";
import { selectNotes } from "src/store/reducers/notesSlice/notesSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import Add from "@mui/icons-material/Add";
import AppNote from "src/components/note/Note";
import { LoadingButton } from "@mui/lab";
import NoteType from "src/enums/NoteType";
import OccupantNoteValidation from "./OccupantNoteValidation";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import { createNote } from "src/store/thunks/note/createNote/createNote";
import { getAllNotes } from "src/store/thunks/note/getAll/getAllNotes";
import { inputError } from "src/utils/showInputError/showInputError";
import { selectUnitLedgers } from "src/store/reducers/ledgersSlice/ledgersSlice";
import { useFormik } from "formik";
import useStyles from "./OccupantNotes.styles";
import { useParams } from "react-router";

const formId = "edit-deal-edit-notes-information-form";

const OccupantNotes = () => {
  const [isAddNewNoteOpen, setIsAddNewNoteOpen] = useState(false);
  const notes = useAppSelector(selectNotes);
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const unitLedgers = useAppSelector(selectUnitLedgers);
  const params = useParams<{ id: string }>();

  const formik = useFormik({
    initialValues: {
      selectedUnit: "occupant",
      description: ""
    },
    validationSchema: OccupantNoteValidation,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      dispatch(
        createNote({
          id: values.selectedUnit === "occupant" ? +params?.id! : +values.selectedUnit,
          type: values.selectedUnit === "occupant" ? NoteType.occupant : NoteType.ledger,
          description: values.description
        })
      );

      formik.setFieldValue("description", "");
      setIsAddNewNoteOpen(false);
    }
  });

  const { touched, errors, values, handleChange, setFieldValue } = formik;

  const handleCancel = () => {
    setIsAddNewNoteOpen(false);
    setFieldValue("description", "");
  };

  const getLedgerNotes = (ledgerId: number) => {
    dispatch(getAllNotes({ itemId: ledgerId, noteType: NoteType.ledgers }));
  };

  const getOccupantNotes = () => {
    dispatch(getAllNotes({ itemId: +params?.id!, noteType: NoteType.occupants }));
  };

  const handleUnitChange = (event: SelectChangeEvent) => {
    setFieldValue("selectedUnit", event.target.value);
    if (event.target.value === "occupant") {
      return getOccupantNotes();
    }
    getLedgerNotes(+event.target.value);
  };

  useEffect(() => {
    getOccupantNotes();
  }, []);

  return (
    <Grid
      component={"form"}
      id={formId}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        formik.handleSubmit();
      }}
    >
      <Grid container mb={1}>
        <Grid item xs={9.5} pr={1}
          container alignItems={"center"}>
          <Grid item xs={"auto"} pr={1}
            container>
            <Typography component={"label"} htmlFor={"facility-selector"}>
              Notes for:
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <PMSSelect
              id={"selectedUnit"}
              name={"selectedUnit"}
              value={values.selectedUnit}
              changeHandler={handleUnitChange}
              error={inputError("selectedUnit", touched, errors).error}
              helperText={inputError("selectedUnit", touched, errors).helperText}
              data-testid={"unit-selector"}
              native
            >
              <option value={"occupant"}>Occupant</option>
              {unitLedgers.map((ledger) => (
                <option key={ledger.id} value={ledger.id}>
                  Unit {ledger?.unit?.unit_number}
                </option>
              ))}
            </PMSSelect>
          </Grid>

          <Grid item ml={3}>
            <Button
              className={classes.button}
              variant={"contained"}
              startIcon={<Add />}
              onClick={() => setIsAddNewNoteOpen(true)}
            >
              Add Note
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Collapse in={isAddNewNoteOpen}>
        <Grid container item>
          <Grid item xs={12} mt={1}>
            <InputLabel htmlFor={"note-description"}>New note description</InputLabel>
            <TextField
              id={"note-description"}
              multiline
              rows={4}
              fullWidth
              name={"description"}
              onChange={handleChange}
              inputProps={{
                maxLength: 500
              }}
              error={inputError("description", touched, errors).error}
              helperText={inputError("description", touched, errors).helperText}
              value={values.description}
            />
          </Grid>
          <Grid item container xs={12}
            mt={1} justifyContent={"flex-end"}>
            <Button color={"error"} variant={"text"} onClick={() => handleCancel()}
              sx={{ marginRight: 5 }}>
              Cancel
            </Button>
            <LoadingButton form={formId} variant={"contained"} type={"submit"}
              className={classes.button}>
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </Collapse>

      <Grid container rowGap={2} mt={2}>
        {notes?.map((note, index) => (
          <AppNote key={index} {...note} />
        ))}
      </Grid>
    </Grid>
  );
};

export default OccupantNotes;
