export interface AppSoftphoneSliceState {
  status: SoftphoneStatus;
  isDisplayed: SoftphoneDisplayMode;
  isInteractable: boolean;
  callDuration: number;
  inputNumber: string;
}

export enum SoftphoneDisplayMode {
  Display = "DISPLAY",
  Minimize = "MINIMIZE",
  Hide = "HIDE",
  DisplayFollowUp = "DISPLAY_FOLLOW_UP",
  MinimizeFollowUp = "MINIMIZE_FOLLOW_UP"
}

export enum SoftphoneStatus {
  Initializing = "INITIALIZING",
  Ready = "READY",
  Calling = "CALLING",
  Connected = "CONNECTED",
  Stopped = "STOPPED"
}
