import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSearchParams } from "../../../../utils/getSearchParams/getSearchParams";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getAllPaginatedProductTypes = createAsyncThunk(
  "getAllPaginatedProductTypes",
  (facilityId: number | unknown, { dispatch, getState, rejectWithValue }) => {
    if (!facilityId) {
      const errorMessage = "Facility ID not found";

      dispatch(
        showSnackbar({
          message: errorMessage,
          variant: "error"
        })
      );

      rejectWithValue(errorMessage);
      return;
    }

    const store = getState() as RootState;

    const searchParams = {
      params: {
        with: ["productCategory", "leaseDocument", "revenueClass:id,name", "facility", "units"],
        ...getSearchParams(store, true)
      }
    };

    return axios
      .get(`/api/v1/facilities/${facilityId}/product-types`, searchParams)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = "There was an error getting the Product Types";

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  }
);
