import { Box, FormHelperText, Grid, Typography } from "@mui/material";
import React, { ReactElement, useEffect } from "react";
import { selectDealType, setDealFacility } from "src/store/reducers/dealInformationSlice/dealInformationSlice";
import {
  selectProductTypes,
  selectProductTypesLoading
} from "../../../store/reducers/productTypesSlice/productTypesSlice";
import {
  selectQuotedRate,
  selectSelectedProductType,
  selectSelectedUnit,
  setSelectedProductType,
  setSelectedUnit
} from "src/store/reducers/selectedUnitSlice/selectedUnitSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import AvailableUnitsTable from "./AvailableUnitsTable/AvailableUnitsTable";
import LoadingSpinner from "src/components/ui/LoadingSpinner/LoadingSpinner";
import SelectedItemDisplay from "../../ui/SelectedItemDisplay/SelectedItemDisplay";
import { selectSelectedFacility } from "src/store/reducers/selectedFacilitySlice/selectedFacilitySlice";

interface SelectUnitProps {
  validationError?: string
  chipLocation?: "top" | "bottom"
}

const SelectUnit: React.FC<SelectUnitProps> = ({ validationError, chipLocation = "bottom" }): ReactElement => {
  const dispatch = useAppDispatch();
  const dealType = useAppSelector(selectDealType);
  const selectedProductType = useAppSelector(selectSelectedProductType);
  const selectedUnit = useAppSelector(selectSelectedUnit);
  const productTypes = useAppSelector(selectProductTypes);
  const quotedRate = useAppSelector(selectQuotedRate);
  const productTypesLoading = useAppSelector(selectProductTypesLoading);
  const selectedFacility = useAppSelector(selectSelectedFacility);

  const handleRemoveDeal = () => {
    dispatch(setSelectedUnit(null));
    dispatch(setSelectedProductType(null));
  };

  const canDisplayDeal = (): boolean => {
    return !!selectedUnit;
  };

  const dealDisplayName = (): string => {
    let returnedName = "";
    if (typeof selectedProductType === "number") {
      returnedName = productTypes[selectedProductType]?.name;
      return returnedName;
    }

    if (selectedProductType) {
      returnedName = selectedProductType?.name;
    }

    if (selectedUnit) {
      returnedName = selectedUnit.unit_number;
    }

    return returnedName;
  };

  const tableDisplay = () => {
    let returnDisplay = <></>;

    if (productTypes.length) {
      returnDisplay = (
        <>
          <Grid container item>
            {canDisplayDeal() && chipLocation === "top" &&
            <SelectedItemDisplay
              selectedItem={"Unit"}
              displayName={dealDisplayName()}
              price={quotedRate}
              removeAction={() => handleRemoveDeal()}
            />
            }
            {validationError &&
            <Grid
              component={FormHelperText}
              data-testid={"select-unit-validation"}
              mt={2}
              error>{validationError}
            </Grid>
            }
            <Grid
              container
              justifyContent={"space-between"}
              spacing={1}
              mt={3}
            >
              <AvailableUnitsTable />
            </Grid>
            {canDisplayDeal() && chipLocation === "bottom" &&
              <SelectedItemDisplay
                selectedItem={"Unit"}
                data-testid={"selected-unit-display"}
                displayName={dealDisplayName()}
                price={quotedRate}
                removeAction={() => handleRemoveDeal()}
              />
            }
            {validationError &&
              <Grid
                component={FormHelperText}
                data-testid={"select-unit-validation"}
                mt={2}
                error>{validationError}
              </Grid>
            }
          </Grid>
        </>
      );

      return returnDisplay;
    }

    if (!productTypes.length) {
      returnDisplay = (
        <Box mt={5}>
          <Typography>No available product types were found at this facility</Typography>
        </Box>
      );

      return returnDisplay;
    }

    return returnDisplay;
  };

  useEffect(() => {
    dispatch(setDealFacility(selectedFacility));
  }, []);

  return productTypesLoading ? <LoadingSpinner /> : tableDisplay();
};

export default SelectUnit;
