
import { RootState } from "src/store/rootStore";
import { createChartOfAccountsSetting } from "src/store/thunks/chartOfAccounts/create/createChartOfAccountsSetting";
import { createSlice } from "@reduxjs/toolkit";
import { AskingRateChangesSliceState } from "./AskingRateChangesSliceState";
import {
  exportAskingRateChangesForOperator
} from "src/store/thunks/askingRateChanges/exportForOperator/exportAskingRateChangesForOperator";
import {
  exportAskingRateChangesForOperator2
} from "src/store/thunks/askingRateChanges/exportForOperator/exportAskingRateChangesForOperator2";

export const initialState: AskingRateChangesSliceState = {
  settingsLoading: false,
  documentCurrentTabView: 0
};

export const askingRateChanges = createSlice({
  name: "askingRateChanges",
  initialState,
  reducers: {
    resetAskingRateChangesSettings: (state) => {
      Object.assign(state, initialState);
    },
    setDocumentCurrentTabView: (state, action) => {
      state.documentCurrentTabView = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(exportAskingRateChangesForOperator.pending, (state) => {
      state.settingsLoading = true;
    }).addCase(exportAskingRateChangesForOperator.fulfilled, (state) => {
      state.settingsLoading = false;
    }).addCase(exportAskingRateChangesForOperator.rejected, (state) => {
      state.settingsLoading = false;
    }).addCase(exportAskingRateChangesForOperator2.pending, (state) => {
      state.settingsLoading = true;
    }).addCase(exportAskingRateChangesForOperator2.fulfilled, (state) => {
      state.settingsLoading = false;
    }).addCase(exportAskingRateChangesForOperator2.rejected, (state) => {
      state.settingsLoading = false;
    });
  }
});
export const selectAskingRateChangesSettingsLoading = (state: RootState) => state.askingRateChanges.settingsLoading;
export const selectDocumentCurrentTabView = (state: RootState) => state.askingRateChanges.documentCurrentTabView;

export const {
  setDocumentCurrentTabView,
  resetAskingRateChangesSettings
} = askingRateChanges.actions;

export default askingRateChanges.reducer;
