import React from "react";
import { Route } from "react-router";
import AssignRevenueClass from "src/pages/ProductTypes/AddProductType/AssignRevenueClass/AssignRevenueClass";
import GetAddProductTypeStarted
  from "src/pages/ProductTypes/AddProductType/GetAddProductTypeStarted/GetAddProductTypeStarted";
import ImportProductTypes from "src/pages/ProductTypes/AddProductType/ImportProductTypes/ImportProductTypes";
import ProductCategories from "src/pages/ProductTypes/AddProductType/ProductCategories/ProductCategories";
import ProductTypeAttributes from "src/pages/ProductTypes/AddProductType/ProductTypeAttributes/ProductTypeAttributes";
import SelectProductType from "src/pages/ProductTypes/AddProductType/SelectProductType/SelectProductType";
import AddProductType from "src/pages/ProductTypes/AddProductType/AddProductType";

export const groupName = "product-types/add-product-type";

export const AddProductTypeRoutes = (
  <>
    <Route
      path={groupName}
      element={
        <AddProductType>
          <GetAddProductTypeStarted />
        </AddProductType>
      }
    />
    <Route
      path={`${groupName}/define-product-type`}
      element={
        <AddProductType>
          <SelectProductType />
        </AddProductType>
      }
    />
    <Route
      path={`${groupName}/select-category`}
      element={
        <AddProductType>
          <ProductCategories />
        </AddProductType>
      }
    />
    <Route
      path={`${groupName}/revenue-class`}
      element={
        <AddProductType>
          <AssignRevenueClass />
        </AddProductType>
      }
    />
    <Route
      path={`${groupName}/attributes`}
      element={
        <AddProductType>
          <ProductTypeAttributes />
        </AddProductType>
      }
    />
    <Route
      path={`${groupName}/import-units`}
      element={
        <AddProductType>
          <ImportProductTypes />
        </AddProductType>
      }
    />
  </>
);

export default AddProductTypeRoutes;
