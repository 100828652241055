import { InputAdornment, InputLabel, TextField } from "@mui/material";
import React, { ReactElement } from "react";

interface TaxInputLabelProps {
  value?: string | number;
  id: string;
  label: string;
}

const TaxInputLabel: React.FC<TaxInputLabelProps> = ({ value, id, label }): ReactElement => {
  return (
    <>
      <InputLabel htmlFor={id}>{ label }</InputLabel>
      <TextField
        disabled
        value={value}
        id={id}
        variant={"outlined"}
        InputProps={{ endAdornment: <InputAdornment position={"end"}>%</InputAdornment> }}
        sx={{
          width: "100%"
        }}
      />
    </>
  );
};

export default TaxInputLabel;
