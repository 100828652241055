import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { Grid, IconButton, TextField } from "@mui/material";
import React, { FC, ReactNode, useEffect } from "react";
import { selectMerchLineItemIds, setPosMerchItemLineIds } from "src/store/reducers/pointOfSalesSlice/pointOfSalesSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Merchandise } from "src/models/Merchandise";
import { setMerchandiseQuantity } from "src/store/reducers/merchandiseItemsSlice/merchandiseItemsSlice";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";
import { useNavigate } from "react-router";
import useStyles from "./ProcessMerchQuantityIncrementor.styles";

interface ProcessMerchQuantityIncrementorProps {
  row: Merchandise
  value: number
}

const ProcessMerchQuantityIncrementor: FC<ProcessMerchQuantityIncrementorProps> = ({ row, value }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const allItemsSelected = useAppSelector(selectMerchLineItemIds);
  const dispatch = useAppDispatch();

  useEffect(() => {
    !allItemsSelected.length && navigate("/merchandise/point-of-sales/choose-merch");
  }, [allItemsSelected]);

  const handleSetQuantity = (val: number) => {
    if (val > row.quantity) {
      dispatch(
        showSnackbar({
          message: `Maximum available quantity: ${row.quantity}`,
          variant: "error"
        })
      );

      dispatch(setMerchandiseQuantity({
        merchId: row.id,
        merchQuantity: row.quantity
      }));

      return;
    }

    if (val <= 0) {
      const filteredItems = allItemsSelected.filter(item => item !== row.id);

      return dispatch(setPosMerchItemLineIds(filteredItems));
    }

    dispatch(setMerchandiseQuantity({ merchId: row.id, merchQuantity: val }));
  };

  const quantityFab = (iconButton: ReactNode) => (
    <Grid
      item
      container
      justifyItems={"center"}
      xs={"auto"}
    >
      {iconButton}
    </Grid>
  );

  return (
    <>
      {quantityFab(
        <IconButton
          aria-label={"decrement button"}
          onClick={() => handleSetQuantity((value - 1))}

          className={classes.quantityButton}>
          <RemoveCircle />
        </IconButton>
      )}
      <Grid item xs={5}>
        <TextField
          size={"small"}
          variant={"outlined"}
          value={value}
          inputProps={{ "data-testid": "quantity-input" }}
          type={"number"}
          onChange={(e) => handleSetQuantity(+e.target.value)}
        />
      </Grid>
      {quantityFab(
        <IconButton
          aria-label={"increment button"}
          onClick={() => handleSetQuantity((value + 1))}
          className={classes.quantityButton}
        >
          <AddCircle />
        </IconButton>
      )}
    </>
  );
};

export default ProcessMerchQuantityIncrementor;
