import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import renderSummaryLineItem from "src/utils/RenderSummaryLineItem/RenderSummaryLineItem";
import { selectPaymentSummarySlice } from "src/store/reducers/paymentSummarySlice/paymentSummarySlice";
import { useAppSelector } from "src/store/hooks";

interface AdditionalInformationDisplayProps{}

const AdditionalInformationDisplay: FC<AdditionalInformationDisplayProps> = () => {
  const {
    customPaymentCoversCurrentBalance,
    requestedCustomPayment,
    amountCarriedForward
  } = useAppSelector(selectPaymentSummarySlice);

  const hasData = [
    customPaymentCoversCurrentBalance,
    requestedCustomPayment,
    amountCarriedForward
  ].some(value => value !== 0 && value !== null && value !== undefined);

  return hasData
    ? (
      <Grid container p={1}>
        {/* Custom Payment */}
        {requestedCustomPayment
          ? renderSummaryLineItem("Custom Payment Covers Current Balance", Boolean(customPaymentCoversCurrentBalance))
          : null}
        {renderSummaryLineItem("Prepaid Balance", amountCarriedForward)}

      </Grid>
      )
    : (
      <Grid container item xs={12}
        justifyContent={"center"} p={1}>
        <Typography align={"center"}>No information to show</Typography>
      </Grid>
      );
};

export default AdditionalInformationDisplay;
