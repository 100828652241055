import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { APIListResponse } from "../../../models/responses/APIListResponse";
import { Fee } from "../../../models/Fee";
import { FeesSliceState } from "./FeesSliceState";
import { RootState } from "../../rootStore";
import { getAllFeesForFacility } from "../../thunks/fee/getAllForFacility/getAllFeesForFacility";
import { FeeApplyAt } from "../../../enums/FeeApplyAt";

export const initialState: FeesSliceState = {
  fees: [],
  feesLoading: false,
  feesError: "",
  showFeesType: "all"
};

export const feesSlice = createSlice({
  name: "fees",
  initialState,
  reducers: {
    setShowFeesType: (state, action: PayloadAction<"all" | "active" | "disabled">) => {
      state.showFeesType = action.payload;
    },
    resetFeesSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllFeesForFacility.pending, (state) => {
        state.feesLoading = true;
      })
      .addCase(getAllFeesForFacility.fulfilled, (state, action: PayloadAction<APIListResponse<Fee[]>>) => {
        state.feesLoading = false;
        state.fees = action.payload.data;
      })
      .addCase(getAllFeesForFacility.rejected, (state, action) => { // Example extarReducer action
        state.feesLoading = false;
        state.feesError = action.payload as string;
      });
  }
});

export const selectFees = (state: RootState) => state.fees.fees;
export const selectActiveFees = (state: RootState) => state.fees.fees.filter(fee => fee.is_active);
export const selectInactiveFees = (state: RootState) => state.fees.fees.filter(fee => !fee.is_active);
export const selectFeesLoading = (state: RootState) => state.fees.feesLoading;
export const selectShowFeesTypes = (state: RootState) => state.fees.showFeesType;
export const selectActiveMoveInFees = (state: RootState) => state.fees.fees.filter(
  fee => (fee.is_active && (fee.apply_at === FeeApplyAt.moveIn || fee.apply_at === FeeApplyAt.all))
);
export const selectActiveMoveOutFees = (state: RootState) => state.fees.fees.filter(
  fee => (fee.is_active && fee.apply_at === FeeApplyAt.moveOut)
);
export const selectActivePaymentFees = (state: RootState) => state.fees.fees.filter(
  fee => (fee.is_active && fee.apply_at === FeeApplyAt.payment)
);
export const selectActiveTransferFees = (state: RootState) => state.fees.fees.filter(
  fee => (fee.is_active && (fee.apply_at === FeeApplyAt.transfer || fee.apply_at === FeeApplyAt.all))
);

export const {
  setShowFeesType,
  resetFeesSlice
} = feesSlice.actions;

export default feesSlice.reducer;
