import React, { PropsWithChildren, createContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectSelectedLedger } from "src/store/reducers/ledgersSlice/ledgersSlice";
import { getLedgerView } from "src/store/thunks/ledger/ledgerView/getLedgerView";

export interface LedgerViewItem {
  id: string;
  start_date: string;
  end_date: string;
  description: string;
  method_last_4: string;
  receipt_id: string;
  invoice: string;
  refund_amount: number;
  credit_amount: number;
  payment_amount: number;
  charge_amount: number;
  tax_amount: number;
}

export interface LedgerView {
  totals: {
    applied_refund: number;
    issued_credit: number;
    payment_amount: number;
    charge_amount: number;
    tax_amount: number;
    amount_due: number;
    prepaid_balance: number;
  };
  current_items: LedgerViewItem[];
  future_items: LedgerViewItem[];
}

export const LedgerViewContext = createContext({
  ledgerView: {} as LedgerView | undefined,
  setLedgerView: (() => {}) as React.Dispatch<React.SetStateAction<LedgerView | undefined>>,
  loading: false,
  changeLedger: (occupantId: string | number, ledgerId: string | number) => {}
});

export const LedgerViewProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [ledgerView, setLedgerView] = useState<LedgerView>();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const selectedLedger = useAppSelector(selectSelectedLedger);

  const changeLedger = (occupantId: string | number, ledgerId: string | number) => {
    setLoading(true);
    dispatch(getLedgerView({ occupantId, ledgerId })).then((action) => {
      setLedgerView(action.payload);
      setLoading(false);
    });
  };

  useEffect(() => {
    changeLedger(selectedLedger?.occupant_id!, selectedLedger?.id!);
  }, []);

  return (
    <LedgerViewContext.Provider value={{ ledgerView, setLedgerView, changeLedger, loading }}>
      {children}
    </LedgerViewContext.Provider>
  );
};
