import { Facility } from "src/models/Facility";

export const selectedFacilitiesDisplay = (facilityIds: (number | "all")[], facilities: Facility[]) => {
  if (!facilityIds || !facilityIds.length) {
    return "- Select Facility -";
  }

  return facilityIds
    .map((facilityId) => {
      return facilities.find((currentFacility) => currentFacility.id === facilityId)?.facility_id;
    })
    .join(", ");
};

export default selectedFacilitiesDisplay;
