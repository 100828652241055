import {
  Alert,
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
  FormHelperText,
  Grid,
  Typography
} from "@mui/material";
import React, { FormEvent, ReactElement, SyntheticEvent, useCallback, useEffect, useState } from "react";
import {
  resetSelectedOccupantSlice,
  selectAddOccupantFormOpen,
  selectAvailableOccupants,
  selectOccupantsSearchOpen,
  selectSelectedOccupant,
  selectSelectedOccupantLoading,
  selectSelectedOccupantName,
  setSelectedOccupant,
  toggleAddOccupantFormOpen,
  toggleOccupantsSearchOpen
} from "../../../store/reducers/selectedOccupantSlice/selectedOccupantSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddOccupantForm from "./AddOccupantForm/AddOccupantForm";
import { AddOccupantModalFormValues } from "../../../models/AddOccupantModalFormValues";
import { LoadingButton } from "@mui/lab";
import { Occupant } from "../../../models/Occupant";
import SelectOccupantSearchField from "./SelectOccupantSearchField/SelectOccupantSearchField";
import SelectedOccupantDisplay from "./SelectedOccupantDisplay/SelectedOccupantDisplay";
import formatPhone from "src/utils/formatPhone/formatPhone";
import { isEmpty } from "lodash";
import { resetEmergencyContactSlice } from "../../../store/reducers/emergencyContactSlice/emergencyContactSlice";
import { resetOccupantInformationSlice } from "src/store/reducers/occupantInformationSlice/occupantInformationSlice";
import { searchOccupants } from "../../../store/thunks/occupant/search/searchOccupants";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import useStyles from "./SelectOccupant.styles";

export function getOptionLabel(option: Partial<Occupant> | string): string {
  if (!option) {
    return "";
  }
  if (typeof option === "string") {
    return option;
  }

  return `${option.first_name} ${option.last_name} - ${formatPhone(option.phone_primary)}`;
}

export function getOptionSelected(option: Partial<Occupant>, value: Occupant) {
  return option.first_name === value.first_name;
}

export const getOccupantAddress = (occupant: Partial<Occupant>) => {
  if (!occupant.address) {
    return "";
  }

  return occupant.address;
};

export const getOccupantPhone = (occupant: Partial<Occupant>) => {
  if (!occupant.phone_primary) {
    return "";
  }

  return occupant.phone_primary;
};

interface SelectOccupantProps {
  onSubmitHandler?: Function | void;
  disableAddOccupant?: boolean;
  isMoveIn?: boolean;
  disablePagination?: boolean;
  onlyWithActiveLedgers?: boolean;
  showWarning?: boolean;
  pageSize?: number;
  callBackReset?: Function | void;
}

const SelectOccupant: React.FC<SelectOccupantProps> = ({
  onSubmitHandler,
  disableAddOccupant,
  isMoveIn,
  disablePagination,
  onlyWithActiveLedgers,
  pageSize = undefined,
  showWarning = true,
  callBackReset
}): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const occupantSearchResults = useAppSelector(selectAvailableOccupants);
  const occupantSearchOpen = useAppSelector(selectOccupantsSearchOpen);
  const addOccupantFormOpen = useAppSelector(selectAddOccupantFormOpen);
  const searchLoading = useAppSelector(selectSelectedOccupantLoading);
  const selectedOccupant = useAppSelector(selectSelectedOccupant);
  const selectedOccupantName = useAppSelector(selectSelectedOccupantName);
  const [noOccupantsFoundMessage, setNoOccupantsFoundMessage] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const formik = useFormik({
    initialValues: {
      occupantsSearchValue: ""
    },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {
      if (!selectedOccupantName) {
        return;
      }

      if (onSubmitHandler) {
        return onSubmitHandler();
      }
    }
  });

  const stepperId = "select-occupant";

  const handleOccupantSearch = () => {
    const searchCriteria = {
      searchValue,
      onlyWithActiveLedgers: onlyWithActiveLedgers,
      disablePagination: disablePagination,
      pageSize
    };

    dispatch(searchOccupants(searchCriteria)).then((resp) => {
      if (!resp.type.includes("rejected")) {
        if (resp.payload.data.length) {
          return dispatch(toggleOccupantsSearchOpen(true));
        }
        return setNoOccupantsFoundMessage("No Occupants found");
      }
    });
  };

  const handleOnChange = (event: SyntheticEvent, occupant: Occupant | string | null) => {
    if (typeof occupant === "object" && occupant?.first_name) {
      setNoOccupantsFoundMessage("");
      return dispatch(setSelectedOccupant(occupant));
    }

    // @ts-ignore
    if (event.key?.toLowerCase() === "enter" && !!occupant) {
      setSearchValue(occupant as string);
      handleOccupantSearch();
      return;
    }
    setSearchValue("");
    formik.setFieldValue("occupantsSearchValue", "");
  };

  const handleClear = (event?: SyntheticEvent) => {
    // this check is the only way i can find that lets us listen *exclusively* to an event on the clearIcon, as there
    // does not appear to be a component method available for this (thanks as always MUI) - Conor
    // @ts-ignore
    if (event?.target.tagName === "svg" || event?.target.tagName === "path") {
      dispatch(resetSelectedOccupantSlice());
      setSearchValue("");
      formik.setFieldValue("occupantsSearchValue", "");
      setNoOccupantsFoundMessage("");
    }
  };

  const handleAddNewOccupant = () => {
    if (isMoveIn) {
      return navigate("/occupants/create-move-in/occupant-name-and-location");
    }

    dispatch(toggleAddOccupantFormOpen(true));
  };

  const handleNewOccupantSubmitted = (newOccupantData: AddOccupantModalFormValues) => {
    const newOccupant = {
      first_name: newOccupantData.firstName,
      last_name: newOccupantData.lastName,
      middle_name: newOccupantData.middleName,
      phone_primary: newOccupantData.phone,
      email: newOccupantData.email
    };

    dispatch(setSelectedOccupant(newOccupant as Occupant));
    dispatch(toggleAddOccupantFormOpen(false));
  };

  const handleRemoveOccupant = () => {
    dispatch(resetOccupantInformationSlice());
    dispatch(resetEmergencyContactSlice());
    dispatch(resetSelectedOccupantSlice());
    setSearchValue("");
    formik.setFieldValue("occupantsSearchValue", "");
    setNoOccupantsFoundMessage("");
    if (callBackReset) {
      callBackReset();
    }
  };

  const handleOnChangeAutoCompleteInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.value === "") {
      dispatch(resetSelectedOccupantSlice());
      setNoOccupantsFoundMessage("");
    }
    setSearchValue(event.target.value);
  };

  const autoCompleteInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <SelectOccupantSearchField
        params={params}
        searchValue={searchValue}
        changeHandler={(e) => handleOnChangeAutoCompleteInput(e)}
      />
    ),
    [searchValue]
  );

  const handlePreventSearch = () => {
    let result = false;

    if (searchLoading) {
      result = true;
    }

    if (!searchValue.length && !selectedOccupant) {
      result = true;
    }

    if (!searchValue.length && selectedOccupant && !selectedOccupant.id) {
      result = true;
    }

    return result;
  };

  useEffect(() => {
    if (isEmpty(selectedOccupant)) {
      setSearchValue("");
    }
  }, [selectedOccupant]);

  return (
    <Grid
      id={stepperId}
      data-testid={stepperId}
      container
      spacing={5}
      component={"form"}
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        formik.handleSubmit();
      }}
    >
      <Grid item container xs={12}
        lg={5} direction={"column"}>
        {!selectedOccupantName && showWarning && (
          <Alert severity={"info"}>
            <Typography data-testid={"FacilityForm-ErrorPage-copy"} variant={"subtitle1"} component={"span"}>
              Please select an occupant to proceed
            </Typography>
          </Alert>
        )}
        <Grid item container justifyContent={"space-between"}
          alignItems={"center"} mt={2}>
          <Grid item xs={9}>
            <Autocomplete
              id={"occupant-search"}
              aria-label={"autocomplete"}
              data-testid={"occupant-search-autocomplete"}
              freeSolo
              open={occupantSearchOpen}
              value={searchValue}
              onOpen={() => dispatch(toggleOccupantsSearchOpen(true))}
              onClose={() => dispatch(toggleOccupantsSearchOpen(false))}
              isOptionEqualToValue={getOptionSelected}
              onInputChange={(event: SyntheticEvent) => handleClear(event)}
              onChange={(event: SyntheticEvent, newValue: Occupant | string | null) => handleOnChange(event, newValue)}
              getOptionLabel={getOptionLabel}
              options={occupantSearchResults}
              loading={searchLoading}
              filterOptions={(x) => x}
              renderInput={autoCompleteInput}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {getOptionLabel(option)}
                  </li>
                );
              }}
            />
          </Grid>
          <Grid item xs={3} pl={2}>
            <LoadingButton
              aria-label={"search occupants"}
              data-testid={"search-button"}
              className={classes.searchButton}
              variant={"contained"}
              fullWidth
              loading={searchLoading}
              disabled={handlePreventSearch()}
              onClick={handleOccupantSearch}
              role={"button"}
            >
              Search
            </LoadingButton>
          </Grid>
        </Grid>
        {noOccupantsFoundMessage && (
          <Grid data-testid={"error-message"} component={FormHelperText} px={1}
            error>
            {noOccupantsFoundMessage}
          </Grid>
        )}
        {!disableAddOccupant && (
          <Grid item mt={2}>
            <Button
              data-testid={"add-new-occupant-button"}
              className={classes.plusButton}
              startIcon={<AddCircleIcon />}
              onClick={handleAddNewOccupant}
            >
              Add new Occupant
            </Button>
          </Grid>
        )}
      </Grid>
      {selectedOccupant && (
        <SelectedOccupantDisplay
          data-testid={"selected-occupant-for-deal"}
          name={selectedOccupant.first_name + " " + selectedOccupant.last_name}
          id={selectedOccupant.id}
          address={getOccupantAddress(selectedOccupant)}
          email={selectedOccupant?.email ?? ""}
          phone={getOccupantPhone(selectedOccupant)}
          onClose={handleRemoveOccupant}
        />
      )}
      <AddOccupantForm
        data-testid={"add-occupant-form"}
        open={addOccupantFormOpen}
        cancelHandler={() => dispatch(toggleAddOccupantFormOpen(false))}
        submitHandler={(values) => handleNewOccupantSubmitted(values)}
      />
    </Grid>
  );
};

export default SelectOccupant;
