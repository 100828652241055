import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { Receipt, RotateLeft } from "@mui/icons-material";
import { setOccupantId, setRefundMerchId, setRefundModalOpen }
  from "src/store/reducers/pointOfSalesSlice/pointOfSalesSlice";
import { GridColDef } from "@mui/x-data-grid";
import React from "react";
import { Transaction } from "src/models/Transaction";
import { TransactionStatus } from "src/enums/TransactionStatus";
import moment from "moment";
import { printReceipt } from "src/store/thunks/moveIn/print/printReceipt";
import { useAppDispatch } from "src/store/hooks";
import useStyles from "./SummaryOfSalesTableColumns.style";

interface TransactionsRenderDetailsButtonProps {
  row: Transaction;
}

export const RenderDetailsButton: React.FC<TransactionsRenderDetailsButtonProps> = ({ row }) => {
  const dispatch = useAppDispatch();

  const refundDisabled = row.status === TransactionStatus.refunded || row.is_all_items_refunded;

  return (
    <Grid container direction={"row"} alignItems={"center"}
      justifyContent={"start"}>
      <Tooltip title={"Print Transaction"}>
        <IconButton
          data-testid={"print-transaction-button"}
          size={"small"}
          title={"Print Receipt"}
          onClick={() => {
            dispatch(printReceipt(row.id)).then((resp) => {
              if (!resp.type.includes("rejected")) {
                const link = document.createElement("a");
                link.href = resp.payload as string;
                link.target = "_blank";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            });
          }}
        >
          <Receipt />
        </IconButton>
      </Tooltip>

      <Tooltip title={"Refund Transaction"}>
        <IconButton
          size={"small"}
          data-testid={"refund-transaction-button"}
          title={"Refund Transaction"}
          disabled={refundDisabled}
          onClick={(() => {
            dispatch(setRefundMerchId(row.id));
            dispatch(setOccupantId(row.occupant_id));
            dispatch(setRefundModalOpen(true));
          })}
        >
          <RotateLeft />
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

export const RenderTransactionStatus = (status?: string) => {
  const { classes } = useStyles();

  return (
    <div
      className={`${classes.completedStatus} ${status === TransactionStatus.refunded && classes.refundedTransaction}`}>
      <Typography
      // className={classes.}
        variant={"subtitle2"}
        component={"span"}
      >
        {status}
      </Typography>
    </div>
  );
};

export const SummaryOfSalesTableColumns = (): GridColDef[] => [
  {
    field: "id",
    renderHeader: () => <Tooltip title={"Transaction ID"}><Typography>Trans. ID</Typography></Tooltip>,
    minWidth: 100,
    flex: 1,
    valueGetter: (params: { row: Transaction }) =>
      params.row.id
  },
  {
    field: "created_at",
    renderHeader: () => <Tooltip title={"Transaction Date/Time"}><Typography>Trans. DateTime</Typography></Tooltip>,
    minWidth: 175,
    flex: 1,
    valueGetter: (params: { row: Transaction }) => moment(params.row.created_at).format("MM/DD/YYYY h:mm A")
  },
  {
    field: "subtotal",
    renderHeader: () => <Tooltip title={"Subtotal"}><Typography>Subtotal</Typography></Tooltip>,
    minWidth: 120,
    flex: 1,
    valueGetter: (params: { row: Transaction }) => `$ ${params.row.subtotal.toFixed(2)}`
  },
  {
    field: "state_tax",
    renderHeader: () => <Tooltip title={"State Tax"}><Typography>State Tax</Typography></Tooltip>,
    minWidth: 120,
    flex: 1,
    valueGetter: (params: { row: Transaction }) => `$ ${params.row.state_tax}`
  },
  {
    field: "city_tax",
    renderHeader: () => <Tooltip title={"City Tax"}><Typography>City Tax</Typography></Tooltip>,
    minWidth: 110,
    flex: 1,
    valueGetter: (params: { row: Transaction }) => `$ ${params.row.city_tax}`
  },
  {
    field: "county_tax",
    renderHeader: () => <Tooltip title={"County Tax"}><Typography>County Tax</Typography></Tooltip>,
    minWidth: 110,
    flex: 1,
    valueGetter: (params: { row: Transaction }) => `$ ${params.row.county_tax}`
  },
  {
    field: "local_tax",
    renderHeader: () => <Tooltip title={"Local Tax"}><Typography>Local Tax</Typography></Tooltip>,
    minWidth: 110,
    flex: 1,
    valueGetter: (params: { row: Transaction }) => `$ ${params.row.local_tax}`
  },
  {
    field: "total_tax",
    renderHeader: () => <Tooltip title={"Total Tax"}><Typography>Total Tax</Typography></Tooltip>,
    minWidth: 110,
    flex: 1,
    valueGetter: (params: { row: Transaction }) => `$ ${params.row.total_tax}`
  },
  {
    field: "amount",
    renderHeader: () => <Tooltip title={"Total Amount"}><Typography>Total Amount</Typography></Tooltip>,
    minWidth: 120,
    flex: 1,
    valueGetter: (params: { row: Transaction }) => params.row.amount
      ? `$ ${(+params.row.amount).toFixed(2)}`
      : params.row.amount
  },
  {
    field: "is_refunded",
    renderHeader: () => <Tooltip title={"Status"}><Typography>Status</Typography></Tooltip>,
    minWidth: 150,
    flex: 1,
    renderCell: (params: { row: Transaction }) => RenderTransactionStatus(params.row.status)
  },
  {
    field: "actionIcons",
    renderHeader: () => <Tooltip title={"Actions"}><Typography>Actions</Typography></Tooltip>,
    sortable: false,
    minWidth: 120,
    flex: 1,
    renderCell: (row) => RenderDetailsButton(row)
  }
];

export default SummaryOfSalesTableColumns;
