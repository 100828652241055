import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../rootStore";
import { UploadedDocument } from "src/models/UploadedDocument";
import { UploadedDocumentsSliceState } from "./uploadedDocumentsSliceState";
import { downloadOccupantDocument } from "src/store/thunks/occupantDocuments/downloadOccupantDocument";
import { getAllPaginatedOccupantDocuments } from "src/store/thunks/occupantDocuments/getAllPaginatedOccupantDocuments";
import { uploadOccupantDocument } from "src/store/thunks/occupantDocuments/uploadDocument/uploadDocument";

export const initialState: UploadedDocumentsSliceState = {
  documents: [],
  loading: false
};

export const uploadedDocumentsSlice = createSlice({
  name: "uploadedDocuments",
  initialState,
  reducers: {
    setUploadedDocuments: (state, action: PayloadAction<UploadedDocument[]>) => {
      state.documents = action.payload;
    },
    resetUploadedDocumentsSlice: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadOccupantDocument.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(uploadOccupantDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadOccupantDocument.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllPaginatedOccupantDocuments.fulfilled, (state, action) => {
        state.loading = false;
        state.documents = action.payload.data;
      })
      .addCase(getAllPaginatedOccupantDocuments.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPaginatedOccupantDocuments.rejected, (state) => {
        state.loading = false;
      })
      .addCase(downloadOccupantDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(downloadOccupantDocument.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(downloadOccupantDocument.rejected, (state) => {
        state.loading = false;
      });
  }
});

export const selectUploadedDocuments = (state: RootState) => state.uploadedDocuments.documents;
export const selectUploadedDocumentsLoading = (state: RootState) => state.uploadedDocuments.loading;

export const { resetUploadedDocumentsSlice, setUploadedDocuments } = uploadedDocumentsSlice.actions;

export default uploadedDocumentsSlice.reducer;
