import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  amountReceived: Yup.string().test(
    "amountReceived",
    "Amount Received is required",
    function(value) {
      if (!value) {
        return this.createError({ message: "Amount Received is required" });
      }
      if (parseFloat(value) !== parseFloat(this.parent?.amountDue)) {
        return this.createError({ message: "Amount must be equal to Due Today" });
      }
      return true;
    }
  ),
  moneyOrderNumber: Yup.string().required("Check/Money Order is required")
});

export default validationSchema;
