import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Billing Information" })((theme) => ({
  headerCell: {
    color: theme.palette.common.black,
    background: theme.palette.grey[200],
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius
  }
}));

export default useStyles;
