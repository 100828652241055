import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "DocumentLibrary" })((theme) => ({
  newDocumentButton: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.primary.contrastText,
    textTransform: "capitalize",
    fontWeight: 300,
    color: theme.palette.common.white,
    minWidth: "8rem",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main
    }
  }
}));

export default useStyles;
