import React, { ReactElement, useEffect } from "react";
import { resetAutomationSliceState, selectAutomationLoading } from "src/store/reducers/automationSlice/automationSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  useNavigate,
  useParams
} from "react-router";
import { Automation } from "src/models/Automation";
import AutomationForm from "../components/AutomationForm/AutomationForm";
import { Breadcrumb } from "../../../models/Breadcrumb";
import FooterButtons from "../../../components/itemManagement/ItemManagementCard/FooterButtons/FooterButtons";
import ItemManagementLayout from "../../../layouts/ItemManagement/ItemManagementLayout";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import { editAutomation } from "src/store/thunks/automations/edit/editAutomation";
import { getAutomation } from "src/store/thunks/automations/get/getAutomation";
import { getAutomations } from "src/store/thunks/automations/getAll/getAutomations";
import { selectMerchandiseFormLoading } from "../../../store/reducers/merchandiseFormSlice/merchandiseFormSlice";
import { selectSelectedAdminFacility } from "src/store/reducers/selectedAdminFacilitySlice/selectedAdminFacilitySlice";
import {
  createPermissions,
  selectFacilityPermissions
} from "src/store/reducers/permissionsSlice/permissionsSlice";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Automations"
  },
  {
    name: "System Automations"
  },
  {
    name: "Edit System Automation",
    bold: true
  }
];

const EditAutomation: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedAdminFacility = useAppSelector(selectSelectedAdminFacility);
  const automationLoading = useAppSelector(selectAutomationLoading);
  const merchandiseFormLoading = useAppSelector(selectMerchandiseFormLoading);
  const params = useParams<{ id: string }>();
  const permissions = useAppSelector(selectFacilityPermissions);
  const automationsPermissions = createPermissions(permissions, "automations");

  const handleSubmit = (automationValues: Automation) => {
    dispatch(editAutomation({
      values: automationValues,
      automationId: params.id!,
      facilityId: String(selectedAdminFacility?.id)
    })).then((resp: any) => {
      if (!resp.type.includes("rejected")) {
        if (selectedAdminFacility?.id) {
          dispatch(getAutomations({ facilityId: selectedAdminFacility.id, is_active: true }));
        }

        navigate("/delinquency-automations");
      }
    });
  };

  useEffect(() => {
    if (!selectedAdminFacility?.id) {
      return navigate("/delinquency-automations");
    }

    dispatch(getAutomation({ id: params.id!, facilityId: String(selectedAdminFacility?.id) }));

    return () => {
      dispatch(resetAutomationSliceState());
    };
  }, []);

  const pageHeader = <PageHeader title={`Edit System Automation`} breadcrumbs={breadcrumbs} />;

  if (automationsPermissions.update) {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <ItemManagementLayout
          title={`Edit System Automation for ${selectedAdminFacility?.name} - ${selectedAdminFacility?.facility_id}`}
          loading={automationLoading}
          footerButtons={
            <FooterButtons
              formId={"create-automation-form"}
              cancelPath={"/delinquency-automations"}
              submitLoading={merchandiseFormLoading}
              submitButtonText={"Save & Continue"}
            />
          }
        >
          <AutomationForm submitHandler={handleSubmit}/>
        </ItemManagementLayout>
      </ViewWrapper>
    );
  } else {
    return (
      <ViewWrapper pageHeader={pageHeader}>
        <h1>You do not have permission to view this page.</h1>
      </ViewWrapper>
    );
  }
};

export default EditAutomation;
