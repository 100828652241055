import { AxiosRequestConfig } from "axios";
import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getAllDocumentTemplatesAllFacilities = createAsyncThunk(
  "getAllDocumentTemplatesAllFacilities",
  (_, { dispatch, getState, rejectWithValue }) => {
    const store = getState() as RootState;
    const facilityId = store.selectedFacility.selectedFacility.id;
    const selectedFacilityId = store.selectedAdminFacility.selectedAdminFacility?.id;

    const params: AxiosRequestConfig = {
      params: {
        showAllFacilities: true
      }
    };

    let facility = facilityId;

    if (selectedFacilityId) {
      facility = selectedFacilityId;
    }

    return axios
      .get(`/api/v1/facilities/${facility}/document-templates?pageSize=999`, params)
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = "There was an error getting the Document Templates";

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  }
);
