import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Payment Complete Modal" })((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  container: {
    width: "32rem",
    borderRadius: "1rem",
    padding: theme.spacing(4)
  },
  content: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center"
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  button: {
    borderColor: theme.palette.grey[400],
    color: theme.palette.primary.contrastText,
    fontWeight: "normal",
    textTransform: "uppercase"
  },
  close: {
    color: theme.palette.error.contrastText,
    fontWeight: "normal"
  }
}));

export default useStyles;
