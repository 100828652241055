import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "PMSTable" })((theme) => ({
  root: {
    border: 0,
    "& .MuiDataGrid-columnSeparator": {
      display: "none"
    },
    "& .MuiDataGrid-colCellTitle": {
      overflow: "unset"
    }
  },
  marginTop: { marginTop: theme.spacing(3) },
  hideSelectAllCheckbox: {
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
      display: "none"
    }
  }
}));

export default useStyles;
