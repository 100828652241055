import * as Yup from "yup";
import { moneyValidator } from "../../../utils/commonRegexes";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required"),
  premium_amount: Yup.string()
    .required("Premium Amount is required")
    .matches(moneyValidator, "Incorrect Format")
    .test("is-correct-money-length", "The maximum amount is $999,999.99", (value) => {
      if (!value) {
        return false;
      }

      return Number(value) <= 999999.99;
    }),
  coverage_amount: Yup.string()
    .required("Coverage Amount is required")
    .matches(moneyValidator, "Incorrect Format")
    .test("is-correct-money-length", "The maximum amount is $999,999.99", (value) => {
      if (!value) {
        return false;
      }

      return Number(value) <= 999999.99;
    }),
  line_item_description: Yup.string()
    .required("Line Item Description is required")
    .max(255, "Description too long."),
  revenue_class_id: Yup.number()
    .moreThan(0, "Revenue Class is Required"),
  free_trial: Yup.number()
    .integer("Must be an integer")
    .min(0, "Free Trial must be at least 0")
    .max(90, "The max of Free Trial days is 90")
});

export default validationSchema;
