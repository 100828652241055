import { ProductSubCategoryPayload } from "./ProductSubCategoryPayload";
import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { getAllProductCategories } from "../../productCategory/getAll/getAllProductCategories";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const createProductSubCategory = createAsyncThunk(
  "createProductSubCategory",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const facilityId = state.selectedFacility.selectedFacility!.id;
    const name = state.productCategory.newProductSubCategoryName;
    const parentId = state.productCategory.productCategory!.id;

    const payload: ProductSubCategoryPayload = {
      facility_id: facilityId,
      name: name,
      parent_id: parentId
    };

    return axios.post(`/api/v1/facilities/${facilityId}/product-categories`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Product Sub-Category created",
          variant: "success"
        }));
        dispatch(getAllProductCategories());

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        errorMessage = "There was an error creating the Product Sub-Category";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
