import { APIFilter } from "../../../models/responses/APIFilter";
import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles<{ filter: APIFilter | "" }>({ label: "ManageOccupants" })((theme, { filter }) => ({
  buttonText: {
    textTransform: "capitalize"
  },
  allFilter: {
    color: filter === "" ? theme.palette.text.primary : theme.palette.text.disabled
  },
  currentFilter: {
    color: Object.keys(filter).includes("only_with_active_ledgers")
      ? theme.palette.text.primary
      : theme.palette.text.disabled
  },
  pastFilter: {
    color: Object.keys(filter).includes("only_with_inactive_ledgers")
      ? theme.palette.text.primary
      : theme.palette.text.disabled
  }
}));

export default useStyles;
