import { RootState } from "src/store/rootStore";
import { axiosInstance as axios } from "../../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "src/store/reducers/snackbarSlice/snackbarSlice";

interface BatchUploadUnitsPayload {
    file: File;
}

export const batchUpdateUnits = createAsyncThunk(
  "units/batchUpdate",
  ({ file }: BatchUploadUnitsPayload, { dispatch, getState, rejectWithValue }) => {
    const formData = new FormData();
    const store = getState() as RootState;
    const facilityLocationCode = store.selectedFacility.selectedFacility.facility_id;
    const facilityId = store.selectedFacility.selectedFacility.id;
    formData.append("units_file", file);
    formData.append("facility_id", facilityLocationCode);

    return axios.post(`/api/v1/facilities/${facilityId}/units/update`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Units updated successfully.",
          variant: "success"
        }));
        return resp.data;
      })
      .catch((err) => {
        const errorMessage = err.response.data.detail || "An error occurred while updating these units.";

        return rejectWithValue(errorMessage);
      });
  }
);
