import React, { FC } from "react";
import { Auction } from "src/models/Auction";
import EmptyTable from "src/components/ui/PMSTable/EmptyTable/EmptyTable";
import NewPMSTable from "src/components/ui/PMSTable/NewPMSTable";
import { PMSPagination } from "src/hooks/usePagination";
import { manageAuctionsColumns } from "./ManageAuctionsTableColumns";

type ManageAuctionsTableProps = {

  totalCount: number;
  loading: boolean;
  pagination: PMSPagination;
  auctions: Auction[];
}

const ManageAuctionsTable:FC<ManageAuctionsTableProps> = ({ auctions, loading, pagination, totalCount }) => {
  const emptyTable = () => <EmptyTable collectionNotFound={"auctions"} value={"auction"} />;

  return (
    <NewPMSTable
      data-testid={"manage-auctions-table"}
      columns={manageAuctionsColumns}
      rows={auctions}
      emptyTableDisplay={emptyTable}
      loading={loading}
      pagination={pagination}
      total={totalCount}
    />
  );
};

export default ManageAuctionsTable;
