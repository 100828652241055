import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Moment from "moment-timezone";
import { MoveInMerchandise } from "../../../models/MoveInMerchandise";
import { MoveInSliceState } from "./MoveInSliceState";
import { Reservation } from "src/models/Reservation";
import { RootState } from "src/store/rootStore";
import { WaiveFee } from "src/models/WaiveFee";
import { createMoveIn } from "../../thunks/moveIn/create/createMoveIn";
import moment from "moment";
import { createTransferUnit } from "src/store/thunks/transfer/create/createTransferUnit";

// @ts-ignore
// eslint-disable-next-line new-cap
const today = new Moment.utc().format("MM/DD/yyyy");

export const initialState: MoveInSliceState = {
  leaseStartsOn: today,
  monthlyRate: "",
  periods: 0,
  moveInLoading: false,
  occupantReservation: null,
  moveInError: "",
  isDealConversion: false,
  promotionId: null,
  fees: [],
  merchandise: [],
  insurancePlanId: null,
  promoDefermentStatus: false,
  moveInConfirmation: null,
  waiveFee: { waive: false, waiveFeeAmount: "0.00" },
  storingReason: "",
  isTransfer: false,
  leasePackageModalOpened: false,
  sendEmailModalOpened: false,
  selectedDocuments: [],
  defaultDocuments: [],
  timeAccess: "",
  keypadAccess: ""
};

export const moveInSlice = createSlice({
  name: "moveIn",
  initialState,
  reducers: {
    setOccupantReservation: (state: MoveInSliceState, action: PayloadAction<Reservation | null>) => {
      state.occupantReservation = action.payload;
    },
    setLeaseStartsOn: (state, action: PayloadAction<string>) => {
      state.leaseStartsOn = moment(action.payload).isValid() ? action.payload : today;
    },
    setMonthlyRate: (state, action: PayloadAction<string>) => {
      state.monthlyRate = action.payload;
    },
    setPeriods: (state, action: PayloadAction<number | number[]>) => {
      state.periods = action.payload;
    },
    addMoveInFee: (state, action: PayloadAction<number>) => {
      state.fees.push(action.payload);
    },
    addWaivefee: (state, action: PayloadAction<WaiveFee>) => {
      state.waiveFee = { ...state.waiveFee, ...action.payload };
    },
    setWaiveFeeAmount: (state, action: PayloadAction<string>) => {
      state.waiveFee.waiveFeeAmount = action.payload;
    },
    removeMoveInFee: (state, action: PayloadAction<number>) => {
      state.fees = state.fees.filter((feeId) => feeId !== action.payload);
    },
    addMoveInMerchandise: (state, action: PayloadAction<MoveInMerchandise>) => {
      state.merchandise.push(action.payload);
    },
    removeMoveInMerchandise: (state, action: PayloadAction<number>) => {
      state.merchandise = state.merchandise.filter((selectedMerch) => selectedMerch.id !== action.payload);
    },
    setMerchandiseQuantityForMoveIn: (state, action: PayloadAction<{ merchId: number; merchQuantity: number }>) => {
      const merchToUpdate = state.merchandise.find((merchItem) => merchItem.id === action.payload.merchId);

      if (merchToUpdate) {
        merchToUpdate.quantity = action.payload.merchQuantity;
      }
    },
    addMoveInInsurancePlan: (state, action: PayloadAction<number>) => {
      state.insurancePlanId = action.payload;
    },
    clearMoveInInsurancePlan: (state) => {
      state.insurancePlanId = initialState.insurancePlanId;
    },
    setPromoDefermentStatus: (state, action: PayloadAction<boolean>) => {
      state.promoDefermentStatus = action.payload;
    },
    setMoveInStoringReason: (state, action: PayloadAction<string>) => {
      state.storingReason = action.payload;
    },
    setLeasePackageModalOpened: (state) => {
      state.leasePackageModalOpened = !state.leasePackageModalOpened;
    },
    setLeasePackageModalClosed: (state) => {
      state.leasePackageModalOpened = false;
    },
    setSendEmailModalOpened: (state) => {
      state.sendEmailModalOpened = !state.sendEmailModalOpened;
    },
    setSelectedDocuments: (state, action) => {
      state.selectedDocuments = action.payload;
    },
    setDefaultDocuments: (state, action) => {
      state.defaultDocuments = action.payload;
    },
    setKeyPadAccess: (state, action) => {
      state.keypadAccess = action.payload;
    },
    setTimeAccess: (state, action) => {
      state.timeAccess = action.payload;
    },
    resetMoveInSlice: (state: MoveInSliceState) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createMoveIn.pending, (state) => {
        state.moveInLoading = true;
      })
      .addCase(createMoveIn.fulfilled, (state, action) => {
        state.moveInLoading = false;
        state.moveInConfirmation = action.payload;
      })
      .addCase(createMoveIn.rejected, (state, action) => {
        state.moveInLoading = false;
        state.moveInError = action.payload as string;
      })
      .addCase(createTransferUnit.pending, (state) => {
        state.moveInLoading = true;
      })
      .addCase(createTransferUnit.fulfilled, (state, action) => {
        state.moveInLoading = false;
        state.moveInConfirmation = action.payload;
      })
      .addCase(createTransferUnit.rejected, (state, action) => {
        state.moveInLoading = false;
        state.moveInError = action.payload as string;
      });
  }
});

export const selectMoveInSlice = (state: RootState) => state.moveIn;
export const selectOccupantReservation = (state: RootState) => state.moveIn?.occupantReservation;
export const selectLeaseStartsOn = (state: RootState) => state.moveIn?.leaseStartsOn;
export const selectMonthlyRate = (state: RootState) => state.moveIn?.monthlyRate;
export const selectMoveInLoading = (state: RootState) => state.moveIn?.moveInLoading;
export const selectPeriods = (state: RootState) => state.moveIn?.periods;
export const selectPromotionId = (state: RootState) => state.moveIn?.promotionId;
export const selectMoveInFees = (state: RootState) => state.moveIn?.fees;
export const selectMoveInMerchandise = (state: RootState) => state.moveIn?.merchandise;
export const selectMoveInInsurancePlan = (state: RootState) => state.moveIn?.insurancePlanId;
export const selectPromoDefermentStatus = (state: RootState) => state.moveIn?.promoDefermentStatus;
export const selectMoveInConfirmation = (state: RootState) => state.moveIn?.moveInConfirmation;
export const selectWaiveFee = (state: RootState) => state.moveIn?.waiveFee;
export const selectIsTransfer = (state: RootState) => state.moveIn?.isTransfer;
export const selectLeasePackageModalOpened = (state: RootState) => state.moveIn?.leasePackageModalOpened;
export const selectSendEmailModalOpened = (state: RootState) => state.moveIn?.sendEmailModalOpened;
export const selectSelectedDocuments = (state: RootState) => state.moveIn?.selectedDocuments;
export const selectDefaultDocuments = (state: RootState) => state.moveIn?.defaultDocuments;
export const selectTimeAccess = (state: RootState) => state.moveIn?.timeAccess;
export const selectKeypadAccess = (state: RootState) => state.moveIn?.keypadAccess;

export const {
  setOccupantReservation,
  setLeaseStartsOn,
  setMonthlyRate,
  setPeriods,
  addMoveInFee,
  addWaivefee,
  setWaiveFeeAmount,
  removeMoveInFee,
  addMoveInMerchandise,
  removeMoveInMerchandise,
  setMerchandiseQuantityForMoveIn,
  addMoveInInsurancePlan,
  clearMoveInInsurancePlan,
  setPromoDefermentStatus,
  setMoveInStoringReason,
  setLeasePackageModalOpened,
  setSendEmailModalOpened,
  setLeasePackageModalClosed,
  setSelectedDocuments,
  setDefaultDocuments,
  setKeyPadAccess,
  setTimeAccess,
  resetMoveInSlice
} = moveInSlice.actions;

export default moveInSlice.reducer;
