import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getProjectedLedgerItems = createAsyncThunk(
  "getProjectedLedgerItems",
  ({ occupantId, ledgerId }: { occupantId: number; ledgerId: number }, { dispatch, getState, rejectWithValue }) => {
    return axios
      .get(`/api/v1/occupants/${occupantId}/ledgers/${ledgerId}/projected-ledger-items`)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = `There was an error getting ledger ${ledgerId} projected ledger items`;

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  }
);
