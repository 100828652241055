import ExternalTppPlan from "src/models/ExternalTppPlan";
import { HttpProblem } from "src/models/HttpProblem";
import { RootState } from "src/store/rootStore";
import { createSlice } from "@reduxjs/toolkit";
import getExternalTppPlan from "src/store/thunks/externalTppPlan/get/getExternalTppPlan";

interface ExternalTppPlanSliceState {
    externalTppPlanActive: boolean,
    isEditExternalPlan: boolean,
    externalTPPModalOpen: boolean
    insuranceChangeModalOpen: boolean
    externalTppLoading: boolean,
    externalTppError: HttpProblem | string,
    selectedExternalTppPlan: ExternalTppPlan | null
}

export const initialState: ExternalTppPlanSliceState = {
  externalTppPlanActive: false,
  externalTPPModalOpen: false,
  insuranceChangeModalOpen: false,
  externalTppLoading: false,
  externalTppError: "",
  isEditExternalPlan: false,
  selectedExternalTppPlan: null
};

export const externalTppPlanSlice = createSlice({
  name: "externalTppPlans",
  initialState,
  reducers: {
    setSelectedExternalTppPlan: (state, action) => {
      state.selectedExternalTppPlan = action.payload;
    },
    setIsEditExternalPlan: (state, action) => {
      state.isEditExternalPlan = action.payload;
    },
    setExternalTppPlanActive: (state, action) => {
      state.externalTppPlanActive = action.payload;
    },
    setExternalTPPModalOpen: (state, action) => {
      state.externalTPPModalOpen = action.payload;
    },
    setInsuranceChangeModalOpen: (state, action) => {
      state.insuranceChangeModalOpen = action.payload;
    },
    resetExternalTppPlanState: (state) => {
      state.externalTppPlanActive = false;
      state.externalTPPModalOpen = false;
      state.isEditExternalPlan = false;
      state.selectedExternalTppPlan = null;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getExternalTppPlan.pending, (state) => {
        state.externalTppLoading = true;
      })
      .addCase(getExternalTppPlan.fulfilled, (state, action) => {
        state.selectedExternalTppPlan = action.payload;
        state.externalTppLoading = false;
      })
      .addCase(getExternalTppPlan.rejected, (state, action) => {
        state.externalTppError = action.payload as string;
        state.externalTppLoading = false;
      });
  }
});

export const selectExternalTPPModalOpen = (state: RootState) => state.externalTppPlan.externalTPPModalOpen;
export const selectInsurnaceChangeModalOpen = (state: RootState) => state.externalTppPlan.insuranceChangeModalOpen;
export const selectExternalTppPlanActive = (state: RootState) => state.externalTppPlan.externalTppPlanActive;
export const selectExternalTppEditMode = (state: RootState) => state.externalTppPlan.isEditExternalPlan;
export const selectExternalTppLoading = (state: RootState) => state.externalTppPlan.externalTppLoading;
export const selectSelectedExternalTppPlan = (state: RootState) => state.externalTppPlan.selectedExternalTppPlan;

export const {
  setExternalTppPlanActive,
  setSelectedExternalTppPlan,
  setExternalTPPModalOpen,
  setInsuranceChangeModalOpen,
  setIsEditExternalPlan,
  resetExternalTppPlanState
} = externalTppPlanSlice.actions;

export default externalTppPlanSlice.reducer;
