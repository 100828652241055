import GetTransferUnitStarted from "src/pages/Occupants/TransferUnit/GetTransferUnitStarted/GetTransferUnitStarted";
import React from "react";
import { Route } from "react-router";
import SelectOccupantForTransferUnit
  from "src/pages/Occupants/TransferUnit/SelectOccupantForTransferUnit/SelectOccupantForTransferUnit";
import TransferUnit from "src/pages/Occupants/TransferUnit/TransferUnit";
import SelectPromotionForTransferUnit
  from "src/pages/Occupants/TransferUnit/SelectPromotionForTransferUnit/SelectPromotionForTransferUnit";
import SelectNewUnitForTransferUnit
  from "src/pages/Occupants/TransferUnit/SelectUnitForTransferUnit/SelectUnitForTransferUnit";
import SelectUnitForMoveOutTransferUnit
  from "src/pages/Occupants/TransferUnit/SelectMoveOutUnitForTransferUnit/SelectMoveOutUnitForTransferUnit";
import ProcessTransferUnit from "src/pages/Occupants/TransferUnit/ProcessTransferUnit/ProcessTransferUnit";
import ReviewTransferUnit from "src/pages/Occupants/TransferUnit/ReviewTransferUnit/ReviewTransferUnit";
import TransferUnitConfirmation
  from "src/pages/Occupants/TransferUnit/TransferUnitConfirmation/TransferUnitConfirmation";

export const groupName = "occupants/transfer-unit";

export const TRANSFER_UNIT_PATHS = {
  getTransferUnitStarted: `/${groupName}/get-transfer-unit-started`,
  selectOccupantForTransferUnit: `/${groupName}/select-occupant`,
  selectUnitForMoveOutTransferUnit: `/${groupName}/select-unit-for-move-out`,
  selectUnitForTransferUnit: `/${groupName}/select-new-unit`,
  selectPromotionForTransferUnit: `/${groupName}/select-promotion`,
  processTransferUnit: `/${groupName}/process-transfer-unit`,
  reviewTransferUnit: `/${groupName}/review-transfer-unit`,
  confirmTransferUnit: `/${groupName}/confirm-transfer-unit`
};

export const TransferUnitRoutes = (
  <>
    <Route
      path={TRANSFER_UNIT_PATHS.getTransferUnitStarted}
      element={
        <TransferUnit>
          <GetTransferUnitStarted />
        </TransferUnit>
      }
    />
    <Route
      path={TRANSFER_UNIT_PATHS.selectOccupantForTransferUnit}
      element={
        <TransferUnit>
          <SelectOccupantForTransferUnit />
        </TransferUnit>
      }
    />
    <Route
      path={TRANSFER_UNIT_PATHS.selectUnitForMoveOutTransferUnit}
      element={
        <TransferUnit>
          <SelectUnitForMoveOutTransferUnit />
        </TransferUnit>
      }
    />
    <Route
      path={TRANSFER_UNIT_PATHS.selectUnitForTransferUnit}
      element={
        <TransferUnit>
          <SelectNewUnitForTransferUnit />
        </TransferUnit>
      }
    />
    <Route
      path={TRANSFER_UNIT_PATHS.selectPromotionForTransferUnit}
      element={
        <TransferUnit>
          <SelectPromotionForTransferUnit />
        </TransferUnit>
      }
    />
    <Route
      path={TRANSFER_UNIT_PATHS.processTransferUnit}
      element={
        <TransferUnit>
          <ProcessTransferUnit/>
        </TransferUnit>
      }
    />
    <Route
      path={TRANSFER_UNIT_PATHS.reviewTransferUnit}
      element={
        <TransferUnit>
          <ReviewTransferUnit/>
        </TransferUnit>
      }
    />
    <Route
      path={TRANSFER_UNIT_PATHS.confirmTransferUnit}
      element={
        <TransferUnit>
          <TransferUnitConfirmation/>
        </TransferUnit>
      }
    />
  </>
);

export default TransferUnitRoutes;
