import { GridAlignment, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Tooltip, Typography } from "@mui/material";
import { Ledger } from "src/models/Ledger";
import { Occupant } from "../../../../../../models/Occupant";
import PartialPaymentColumn from "../components/PartialPaymentColumn/PartialPaymentColumn";
import React from "react";
import { formatDate } from "../../../../../../utils/__dateAndTimeUtils__/formatDate/formatDate";
import useStyles from "./SelectedLedgerTableColumns.style";
import PaymentStatus from "src/components/ui/PaymentStatus/PaymentStatus";

export const RenderLedgerStatus = (status?: string) => {
  const { classes } = useStyles();

  return (
    <div className={`${classes.dueStatus} ${status === "Past Due" && classes.pastDueStatus}`}>
      <Typography variant={"subtitle2"} component={"span"}>
        {status}
      </Typography>
    </div>
  );
};

const SelectedLedgerTableColumns = (occupant: Occupant | null): GridColDef[] => {
  const rightAlignment: GridAlignment = "right";
  const baseSet = [
    {
      field: "row.unit.unit_number",
      renderHeader: () => (
        <Tooltip title={"Unit"}>
          <Typography>Unit</Typography>
        </Tooltip>
      ),
      flex: 1,
      renderCell: ({ value }: GridRenderCellParams) => (
        <div data-testid={"table-unit-number"}>{value}</div>
      ),
      valueGetter: ({ row }: GridRenderCellParams) => row?.unit?.unit_number
    },
    {
      field: "occupant",
      renderHeader: () => (
        <Tooltip title={"Occupant"}>
          <Typography>Occupant</Typography>
        </Tooltip>
      ),
      flex: 1,
      renderCell: ({ value }: GridRenderCellParams) => (
        <div data-testid={"table-occupant-full-name"}>{value}</div>
      ),
      valueGetter: () => `${occupant?.first_name} ${occupant?.last_name}`
    },
    {
      field: "monthly_rate",
      renderHeader: () => (
        <Tooltip title={"Deal Rate"}>
          <Typography>Deal Rate</Typography>
        </Tooltip>
      ),
      flex: 1,
      renderCell: ({ value }: GridRenderCellParams) => (
        <div data-testid={"table-deal-rate"}>{value}</div>
      )
    },
    {
      field: "due_date",
      renderHeader: () => (
        <Tooltip title={"Due Date"}>
          <Typography>Due Date</Typography>
        </Tooltip>
      ),
      valueGetter: ({ row }: GridRenderCellParams) => formatDate(row.due_date),
      flex: 1,
      renderCell: ({ value }: GridRenderCellParams) => (
        <div data-testid={"table-due-date"}>{value}</div>
      )
    },
    {
      field: "delinquent_days",
      renderHeader: () => (
        <Tooltip title={"Status"}>
          <Typography>Status</Typography>
        </Tooltip>
      ),
      renderCell: ({ row }: GridRenderCellParams) => (
        <div data-testid={"table-ledger-status"}>
          <PaymentStatus
            currentBalance={parseFloat(row.current_balance)}
            delinquentDays={parseFloat(row.delinquent_days ?? 0)}
          />
        </div>
      )
    },
    {
      field: "partial_payment",
      width: 200,
      renderHeader: () => (
        <Tooltip title={"Custom Payment"}>
          <Typography>Custom Payment</Typography>
        </Tooltip>
      ),
      renderCell: ({ row }: GridRenderCellParams<Ledger>) => (
        <div data-testid={"table-partial-payment"}>
          <PartialPaymentColumn ledger={row} />
        </div>
      )
    },
    {
      field: "current_balance",
      renderHeader: () => (
        <Tooltip title={"Outstanding Balance"}>
          <Typography>Outstanding Balance</Typography>
        </Tooltip>
      ),
      valueGetter: ({ row }: GridRenderCellParams) => `$ ${row.current_balance}`,
      align: rightAlignment,
      renderCell: ({ value }: GridRenderCellParams) => (
        <div data-testid={"table-outstanding-balance"}>{value}</div>
      )
    }
  ];

  return baseSet;
};

export default SelectedLedgerTableColumns;
