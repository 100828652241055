import { Button, Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { FC, useState } from "react";
import { selectAttributesLoading, selectUnitAttributes } from "src/store/reducers/unitsSliceNew/unitsSliceNew";
import { Add } from "@mui/icons-material";
import { useAppSelector } from "src/store/hooks";
import useStyles from "./SelectUnitUploadModal.styles";

type SelectUploadUnitModalProps = {
    onAddNew: () => void;
    onBatchAddNew: () => void;
    onUpdateExisting: () => void;
};

const SelectUploadUnitModal: FC<SelectUploadUnitModalProps> = ({ onAddNew, onBatchAddNew, onUpdateExisting }) => {
  const [selectedOption, setSelectedOption] = useState(0);
  const attributes = useAppSelector(selectUnitAttributes);
  const attributesLoading = useAppSelector(selectAttributesLoading);
  const newDisabled = !attributes.length || attributesLoading;
  const { classes } = useStyles();
  const handleSelectChange = (event: SelectChangeEvent<number>) => {
    setSelectedOption(Number(event.target.value));
  };

  const handleButtonClick = () => {
    switch (selectedOption) {
      case 0:
        return onAddNew();
      case 1:
        return onBatchAddNew();
      case 2:
        return onUpdateExisting();
    }
  };

  return (
    <Grid container alignItems={"center"} spacing={2}>
      <Grid item xs={7}>
        <Select
          fullWidth
          size={"small"}
          defaultValue={0}
          value={selectedOption}
          onChange={handleSelectChange}
          displayEmpty
        >
          <MenuItem disabled={newDisabled} value={0}>New Unit</MenuItem>
          <MenuItem value={1}>Batch New Units</MenuItem>
          <MenuItem value={2}>Batch Update Units</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={5}>
        <Button
          fullWidth
          variant={"contained"}
          color={"primary"}
          className={classes.buttonBase}
          onClick={handleButtonClick}
          startIcon={<Add />}
        >
          Open
        </Button>
      </Grid>
    </Grid>
  );
};

export default SelectUploadUnitModal;
