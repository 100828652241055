import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "OccupantDocuments" })((theme) => ({
  button: {
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "30rem",
    minHeight: "15rem",
    borderRadius: ".625rem .625rem .625rem .625rem",
    overflow: "hidden"
  },
  content: {
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
    textAlign: "center"
  },
  confirmButton: {
    color: theme.palette.primary.contrastText,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`
  },
  cancelButton: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    color: theme.palette.error.contrastText,
    marginRight: theme.spacing(2)
  },
  fileUpload: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    borderWidth: "2px",
    borderRadius: "1rem",
    borderStyle: "dashed",
    borderColor: "#cbd5e1",
    backgroundColor: "#f8fafc",
    minHeight: "20rem",
    minWidth: "25rem"
  },
  file: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(2),
    border: `2px solid ${theme.palette.grey[300]}`,
    maxWidth: "10rem",
    borderRadius: "1rem",
    minHeight: "8rem"
  },
  fileRemoveIcon: {
    alignSelf: "flex-end",
    justifySelf: "flex-end",
    color: theme.palette.error.main
  },
  fileIcon: {
    fontSize: "52px"
  },
  fileName: {
    textOverflow: "ellipsis",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden"
  }
}));

export default useStyles;
