import { APIFilter } from "../../../models/responses/APIFilter";
import { makeStyles } from "../../../makeStyles";

const hasFilter = (filter: APIFilter) => {
  return filter && Object.keys(filter).length && Object.keys(filter).includes("filter[is_active]");
};

const useStyles = makeStyles<{ filter: APIFilter }>({ label: "RevenueClasses" })((theme, { filter }) => ({
  newRevenueClassButton: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.primary.contrastText,
    textTransform: "capitalize",
    fontWeight: 300,
    color: theme.palette.common.white,
    minWidth: "8rem",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main
    }
  },
  buttonText: {
    textTransform: "capitalize"
  },
  allFilter: {
    color:
      hasFilter(filter) && filter["filter[is_active]"] === "0,1"
        ? theme.palette.text.primary
        : theme.palette.text.disabled
  },
  activeFilter: {
    color:
      hasFilter(filter) && filter["filter[is_active]"] === "1"
        ? theme.palette.text.primary
        : theme.palette.text.disabled
  },
  disableFilter: {
    color:
      hasFilter(filter) && filter["filter[is_active]"] === "0"
        ? theme.palette.text.primary
        : theme.palette.text.disabled
  }
}));

export default useStyles;
