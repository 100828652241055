import { makeStyles } from "../../../../../../makeStyles";

const useStyles = makeStyles({ label: "CashForm" })((theme) => ({
  moneyIcon: {
    fontSize: 75,
    marginBottom: theme.spacing(1)
  }
}));

export default useStyles;
