import React from "react";
import { Route } from "react-router";
import CreateNewTask from "../pages/TaskManagement/CreateNewTask/CreateNewTask";
import EditTask from "../pages/TaskManagement/EditTask/EditTask";
import ManageTasks from "../pages/TaskManagement/ManageTasks/ManageTasks";

export const groupName = "task-management";

export const TaskManagementRoutes = (
  <>
    <Route path={groupName} element={<ManageTasks />} />
    <Route path={`${groupName}/create`} element={<CreateNewTask />} />
    <Route path={`${groupName}/:id/edit`} element={<EditTask />} />
  </>
);

export default TaskManagementRoutes;
