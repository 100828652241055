import { makeStyles } from "../../../makeStyles";

const useStyles = makeStyles({ label: "SelectOccupant" })((theme) => ({
  plusButton: {
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(1),
    cursor: "pointer",
    textTransform: "capitalize"
  },
  searchButton: {
    background: theme.palette.primary.contrastText,
    color: theme.palette.common.white,
    textTransform: "capitalize"
  },
  spinner: {
    color: theme.palette.common.white
  }
}));

export default useStyles;
