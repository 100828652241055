import { CircularProgress, Divider, Grid, Paper } from "@mui/material";
import React, { ReactElement, useEffect } from "react";
import {
  resetProductTypeSlice,
  selectIsInsurable,
  selectProductCategoryId,
  selectProductType,
  selectProductTypeLoading,
  selectProductTypeName,
  selectProductTypeRevenueClass,
  selectRevenueClassId
} from "src/store/reducers/productTypeSlice/productTypeSlice";
import Heading from "./Heading/Heading";
import { RevenueClass } from "src/models/RevenueClass";
import Taxes from "./Taxes/Taxes";
import EditableAttributes from "./EditableAttributes/EditableAttributes";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import useStyles from "./EditAttributesForm.styles";
import { useFormik } from "formik";
import { updateProductType } from "src/store/thunks/productType/update/updateProductType";
import validationSchema from "./editAttributesFormValidation";
import { inputError } from "src/utils/showInputError/showInputError";
import { useParams } from "react-router";
import { getProductType } from "src/store/thunks/productType/get/getProductType";

interface ProductTypeUpdates {
  name?: string
  productCategoryId?: number
  revenueClassId?: number
  isInsurable?: boolean
  leaseDocumentId?: number
}

const EditAttributes: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const params = useParams<{id: string}>();
  const loading = useAppSelector(selectProductTypeLoading);
  const productTypeRevenueClass = useAppSelector(selectProductTypeRevenueClass) as RevenueClass;
  const productTypeName = useAppSelector(selectProductTypeName);
  const productType = useAppSelector(selectProductType);
  const productCategoryId = useAppSelector(selectProductCategoryId);
  const revenueClassId = useAppSelector(selectRevenueClassId);
  const isInsurable = useAppSelector(selectIsInsurable);

  const {
    setFieldValue,
    setFieldTouched,
    values,
    touched,
    errors
  } = useFormik({
    initialValues: {
      productTypeName,
      productCategoryId,
      revenueClassId,
      isInsurable,
      leaseDocumentId: productType.leaseDocumentId
    },
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: () => {}
  });

  const dispatchUpdateProductType = (productTypeUpdates: ProductTypeUpdates) => {
    return dispatch(updateProductType({
      ...productType,
      ...productTypeUpdates
    }));
  };

  useEffect(() => () => {
    dispatch(resetProductTypeSlice());
  }, []);

  const handleSelectChange = async(productTypeUpdates: ProductTypeUpdates) => {
    await dispatchUpdateProductType(productTypeUpdates);
    const [fieldAndValue] = Object.entries(productTypeUpdates);
    setFieldValue(...fieldAndValue);
    dispatch(getProductType(params.id!));
  };

  return (
    <Paper variant={"outlined"} className={classes.root}>
      <Grid direction={"column"} container item>
        {loading
          ? <Grid
              item
              container
              justifyContent={"center"}
              p={5}
          >
            <CircularProgress size={75} aria-label={"product type attributes loading"}/>
          </Grid>
          : <Grid
              item
              container
              direction={"column"}
              xs={12}
              p={1}
            >
            <Heading
              formikProductTypeName={values.productTypeName}
              onChange={(value: string) => {
                setFieldTouched("productTypeName");
                setFieldValue("productTypeName", value);
              }}
              onSubmit={(name: string) => dispatchUpdateProductType({ name })}
              error={inputError("productTypeName", touched, errors).error}
              helperText={inputError("productTypeName", touched, errors).helperText}
            />
            <Divider />
            <Grid
              item
              py={2}
              className={classes.editAttributesForm}
            >
              <EditableAttributes
                onChange={handleSelectChange}
                productCategoryId={values.productCategoryId}
                revenueClassId={values.revenueClassId}
                leaseDocumentId={values.leaseDocumentId}
                isInsurable={values.isInsurable}
              />
              <Taxes revenueClass={productTypeRevenueClass}/>
            </Grid>
          </Grid>
        }
      </Grid>
    </Paper>
  );
};

export default EditAttributes;
