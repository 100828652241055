import { Button, Grid, Modal, Paper } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import {
  resetMerchandiseCategoriesSlice,
  selectMerchandiseCategoriesLoading
} from "../../../store/reducers/merchandiseCategoriesSlice/merchandiseCategoriesSlice";
import {
  resetMerchandiseFormSlice,
  selectMerchandiseFormLoading
} from "../../../store/reducers/merchandiseFormSlice/merchandiseFormSlice";
import {
  resetRevenueClassesSlice,
  selectRevenueClassesLoading
} from "../../../store/reducers/revenueClassesSlice/revenueClassesSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useNavigate, useParams } from "react-router";
import { AppDispatch } from "../../../store/rootStore";
import { ArrowBack } from "@mui/icons-material";
import { Breadcrumb } from "../../../models/Breadcrumb";
import EditMerchandiseInformation from "./EditMerchandiseTabs/EditMerchandiseInformation/EditMerchandiseInformation";
import EditMerchandiseNotes from "./EditMerchandiseTabs/EditMerchandiseNotes/EditMerchandiseNotes";
import NewInventoryModal from "./NewInventoryModal/NewInventoryModal";
import NoteType from "src/enums/NoteType";
import PageHeader from "../../../components/ui/PageHeader/PageHeader";
import TabView from "src/layouts/TabView/TabView";
import ViewWrapper from "src/layouts/ViewWrapper/ViewWrapper";
import {
  getAllMerchandiseCategories
} from "../../../store/thunks/merchandiseCategory/getAll/getAllMerchandiseCategories";
import { getAllNotes } from "src/store/thunks/note/getAll/getAllNotes";
import { getAllRevenueClasses } from "../../../store/thunks/revenueClass/getAll/getAllRevenueClasses";
import { getMerchandise } from "../../../store/thunks/merchandise/get/getMerchandise";
import { resetMerchandiseItemsSlice } from "../../../store/reducers/merchandiseItemsSlice/merchandiseItemsSlice";
import { resetNoteSlice } from "src/store/reducers/noteSlice/noteSlice";
import { resetNotesSlice } from "src/store/reducers/notesSlice/notesSlice";
import { selectSelectedFacility } from "../../../store/reducers/selectedFacilitySlice/selectedFacilitySlice";
import useStyles from "./EditMerchandise.styles";
import { getMerchInventory } from "src/store/thunks/inventory/getMerchInventory/getMerchInventory";

const breadcrumbs: Breadcrumb[] = [
  {
    name: "Merchandise"
  },
  {
    name: "Edit Merchandise",
    bold: true
  }
];

export const handleReset = (dispatch: AppDispatch) => {
  dispatch(resetMerchandiseFormSlice());
  dispatch(resetRevenueClassesSlice());
  dispatch(resetMerchandiseCategoriesSlice());
  dispatch(resetMerchandiseItemsSlice());
  dispatch(resetNotesSlice());
  dispatch(resetNoteSlice());
};

const componentList = [
  {
    name: "Merchandise Information", component: <EditMerchandiseInformation/>
  },
  {
    name: "Merchandise Notes", component: <EditMerchandiseNotes/>
  }
];

const EditMerchandise: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const selectedFacility = useAppSelector(selectSelectedFacility);
  const revenueClassesLoading = useAppSelector(selectRevenueClassesLoading);
  const merchandiseCategoriesLoading = useAppSelector(selectMerchandiseCategoriesLoading);
  const merchandiseFormLoading = useAppSelector(selectMerchandiseFormLoading);
  const viewLoading = revenueClassesLoading || merchandiseCategoriesLoading || merchandiseFormLoading;
  const navigate = useNavigate();
  const { classes } = useStyles();

  useEffect(() => {
    dispatch(getMerchandise(params.id!));
    dispatch(getAllRevenueClasses(selectedFacility?.id));
    dispatch(getAllMerchandiseCategories());
    dispatch(getMerchInventory(params.id!));
    dispatch(getAllNotes({ itemId: +params.id!, noteType: NoteType.merch }));

    return () => {
      handleReset(dispatch);
    };
  }, []);

  const pageHeader = <PageHeader title={"Edit Merchandise"} breadcrumbs={breadcrumbs} />;

  return (
    <ViewWrapper pageHeader={pageHeader}>
      <Grid mt={2} item xs={12}>
        <Button
          onClick={() => navigate("/merchandise/manage-merchandise")}
          startIcon={<ArrowBack />}
          variant={"text"}
        >
          Go back to Manage Merchandise
        </Button>
      </Grid>
      <Grid mt={2} item xs={12}>
        <TabView
          innerPadding={false}
          showPaper={false}
          loading={viewLoading}
          components={componentList}
        />
      </Grid>
    </ViewWrapper>
  );
};

export default EditMerchandise;
