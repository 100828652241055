import { makeStyles } from "../../../../../makeStyles";

const useStyles = makeStyles({ label: "ItemSelector" })((theme) => ({
  checkboxRowWrapper: {
    border: "1px solid transparent",
    borderRadius: theme.spacing(0.4),
    marginBottom: theme.spacing(0.75),
    "> span": {
      padding: theme.spacing(0.4)
    },
    height: "3.75rem"
  },
  checked: {
    borderColor: theme.palette.text.disabled,
    paddingTop: "10px",
    paddingBottom: "10px"

  },
  closeIcon: {
    color: theme.palette.text.disabled,
    cursor: "pointer"
  },
  input: {
    padding: 0
  }
}));

export default useStyles;
