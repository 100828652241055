import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Manage Unit Notes Modal" })((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  buttonBase: {
    textTransform: "uppercase",
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main
    }
  },
  root: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
    maxWidth: "65%",
    minHeight: "10rem",
    borderRadius: "1rem"
  }
}));

export default useStyles;
