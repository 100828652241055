import * as Yup from "yup";

const validationSchema = (someValue:string) => Yup.object().shape({
  name: Yup.string().required("Merchandise Name is required"),
  sku: Yup.string().required("Sku is required"),
  remaining_quantity: Yup.string().required("Quantity is required"),
  quantity_change_reason: Yup.string().when("remaining_quantity", {
    is: (value: string) => value !== someValue,
    then: Yup.string().required("Reason is required when quantity is changed")
  }),
  shipping_cost: Yup.string().required("Shipping Cost is required"),
  cost_of_goods_sold: Yup.string().required("Cost of Goods Sold is required")
});

export default validationSchema;
