import { GetMerchandiseCostForRefundPayload } from "./getMetchandiseCostForRefundPayload";
import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getMerchandiseCostForRefund = createAsyncThunk(
  "getMerchandiseCostForRefund",
  (
    _,
    { dispatch, getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const facilityId = state.selectedFacility.selectedFacility?.id;
    const lineItems = state.merchandiseForRefunds.merchandiseForRefund.filter(merch => merch.return_quantity > 0);
    const occupantId = state.pointOfSales.occupant_id!;

    const payload: GetMerchandiseCostForRefundPayload = {
      is_refund: true,
      merchandise: {
        ...lineItems.map(item => ({
          id: item.id,
          quantity: item.return_quantity
        }))
      },
      ...(occupantId && { occupant_id: occupantId })
    };

    return axios
      .post(`/api/v1/facilities/${facilityId}/merchandise/cost`, payload)
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        let errorMessage = "There was an error getting the Merchandise Cost";

        if (
          err?.response?.status === 422 &&
          err.response.data.errors &&
          Object.keys(err.response.data.errors).length
        ) {
          errorMessage = format422(err.response.data.errors);
        } else if (err?.response?.data && err.response.data.detail) {
          errorMessage += `: ${err.response.data.detail}`;
        }

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  }
);
