import { Button, Grid } from "@mui/material";
import {
  selectChangeOwnershipIsEnabled,
  setChangeOwnerShipStep
} from "src/store/reducers/occupantSlice/occupantSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { LoadingButton } from "@mui/lab";
import React from "react";
import { selectOccupantDetailsLoading } from "src/store/reducers/occupantInformationSlice/occupantInformationSlice";
import useStyles from "./BillingInformationFooterButtons.styles";

interface BillingInformationFooterButtonsProps {
  formId: string
  onCancel?: () => void
}

const BillingInformationFooterButtons = ({ formId, onCancel }: BillingInformationFooterButtonsProps) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const occupantInformationLoading = useAppSelector(selectOccupantDetailsLoading);
  const changeOwnershipIsEnabled = useAppSelector(selectChangeOwnershipIsEnabled);

  return (
    <Grid
      mt={1}
      container
      spacing={3}
      justifyContent={"flex-end"}
      item
      xs={12}
        >
      {onCancel && (
        <Grid item>
          <Button
            variant={"text"}
            color={"error"}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Grid>
      )}
      <Grid item>
        {changeOwnershipIsEnabled && (
        <Button
          form={"next-occupant-step"}
          variant={"outlined"}
          className={classes.backButton}
          onClick={() => dispatch(setChangeOwnerShipStep(0))}
 >
          Back
        </Button>
        )}
        <LoadingButton
          form={formId}
          variant={"contained"}
          className={classes.saveButton}
          type={"submit"}
          data-testid={"billing-information-confirm-button"}
          loading={occupantInformationLoading}
          disabled={occupantInformationLoading}
            >
          { changeOwnershipIsEnabled ? "Next" : "Save"}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default BillingInformationFooterButtons;
