import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { NoteSliceState } from "./NoteSliceState";
import { RootState } from "../../rootStore";
import { createNote } from "../../thunks/note/createNote/createNote";

export const initialState: NoteSliceState = {
  note: sessionStorage.getItem("note") ?? "",
  noteLoading: false,
  noteError: ""
};

export const noteSlice = createSlice({
  name: "note",
  initialState,
  reducers: {
    setNote: (state, action: PayloadAction<string>) => {
      state.note = action.payload;
      sessionStorage.setItem("note", state.note);
    },
    resetNoteSlice: (state) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("note");
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNote.pending, (state) => {
        state.noteLoading = true;
      })
      .addCase(createNote.fulfilled, (state) => {
        state.noteLoading = false;
      })
      .addCase(createNote.rejected, (state, action) => {
        state.noteLoading = false;
        state.noteError = action.payload as string;
      });
  }
});

export const selectNote = (state: RootState) => state.note.note;
export const selectNoteLoading = (state: RootState) => state.note.noteLoading;

export const {
  resetNoteSlice,
  setNote
} = noteSlice.actions;

export default noteSlice.reducer;
