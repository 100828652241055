import React, { ReactElement } from "react";
import FacilityFormValues from "src/models/formikInputValues/FacilityFormValues";
import { FormikProps } from "formik";
import { Grid } from "@mui/material";
import HoursRow from "./AccessHoursRow/AccessHoursRow";

interface AccessHoursFormProps {
  formik: Pick<FormikProps<FacilityFormValues>,
    "handleChange" | "errors" | "values" | "touched" | "setFieldValue" | "setFieldError" | "setFieldTouched">
}

const AccessHoursForm: React.FC<AccessHoursFormProps> = ({ formik }): ReactElement => {
  return (
    <Grid container>
      <HoursRow
        formik={formik}
        day={"monday"}
        gateClosed={formik.values.access_hours.monday.is_closed_for_the_day}
        gateTwentyFourHours={formik.values.access_hours.monday.is_open_24_hours}
        valueOpen={formik.values.access_hours.monday.open}
        valueClosed={formik.values.access_hours.monday.close}
        />
      <HoursRow
        formik={formik}
        day={"tuesday"}
        gateClosed={formik.values.access_hours.tuesday.is_closed_for_the_day}
        gateTwentyFourHours={formik.values.access_hours.tuesday.is_open_24_hours}
        valueOpen={formik.values.access_hours.tuesday.open}
        valueClosed={formik.values.access_hours.tuesday.close}
        />
      <HoursRow
        formik={formik}
        day={"wednesday"}
        gateClosed={formik.values.access_hours.wednesday.is_closed_for_the_day}
        gateTwentyFourHours={formik.values.access_hours.wednesday.is_open_24_hours}
        valueOpen={formik.values.access_hours.wednesday.open}
        valueClosed={formik.values.access_hours.wednesday.close}
          />
      <HoursRow
        formik={formik}
        day={"thursday"}
        gateClosed={formik.values.access_hours.thursday.is_closed_for_the_day}
        gateTwentyFourHours={formik.values.access_hours.thursday.is_open_24_hours}
        valueOpen={formik.values.access_hours.thursday.open}
        valueClosed={formik.values.access_hours.thursday.close}
        />
      <HoursRow
        formik={formik}
        day={"friday"}
        gateClosed={formik.values.access_hours.friday.is_closed_for_the_day}
        gateTwentyFourHours={formik.values.access_hours.friday.is_open_24_hours}
        valueOpen={formik.values.access_hours.friday.open}
        valueClosed={formik.values.access_hours.friday.close}
      />
      <HoursRow
        formik={formik}
        day={"saturday"}
        gateClosed={formik.values.access_hours.saturday.is_closed_for_the_day}
        gateTwentyFourHours={formik.values.access_hours.saturday.is_open_24_hours}
        valueOpen={formik.values.access_hours.saturday.open}
        valueClosed={formik.values.access_hours.saturday.close}
      />
      <HoursRow
        formik={formik}
        day={"sunday"}
        gateClosed={formik.values.access_hours.sunday.is_closed_for_the_day}
        gateTwentyFourHours={formik.values.access_hours.sunday.is_open_24_hours}
        valueOpen={formik.values.access_hours.sunday.open}
        valueClosed={formik.values.access_hours.sunday.close}
      />
    </Grid>
  );
};

export default AccessHoursForm;
