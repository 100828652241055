import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getAllPromotions = createAsyncThunk(
  "getAllPromotions",
  (promoStatus: number | void, { dispatch, rejectWithValue }) => {
    const params = {
      params: {
        include_usage_counts: 1,
        pageSize: 999999,
        with: ["facilities:id,name,facility_id"],
        sortBy: "name"
      }
    };

    return axios
      .get("api/v1/promotions", params)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = "There was an error getting the Promotions";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
