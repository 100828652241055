import React, { forwardRef } from "react";
import { useController } from "react-hook-form";
import { Grid, InputLabel, TextField, Typography } from "@mui/material";
import { PatternFormat, PatternFormatProps } from "react-number-format";

type FormPhoneProps = {
  id: string;
  name: string;
  label: string;
};

interface NumberFormatCustomProps {
  inputRef: (instance: PatternFormatProps | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom = forwardRef(function NumberFormatCustom(props: NumberFormatCustomProps, ref) {
  const { inputRef, onChange, ...other } = props;

  return (
    <PatternFormat {...other} getInputRef={ref} onChange={onChange}
      format={"(###) ###-####"} mask={"_"} />
  );
});

const FormPhone = ({ id, name, label }: FormPhoneProps) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    name
  });

  return (
    <Grid item>
      <InputLabel htmlFor={name}>
        <Typography noWrap>{label}</Typography>
      </InputLabel>
      <TextField
        id={id}
        type={"tel"}
        inputProps={{ ...field }}
        InputProps={{
          inputComponent: NumberFormatCustom as any
        }}
        error={!!error?.message}
        helperText={error?.message}
      />
    </Grid>
  );
};

export default FormPhone;
