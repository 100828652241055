import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Follow Up Modal" })((theme) => ({
  buttonBase: {
    textTransform: "uppercase",
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main
    }
  },
  root: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
    minHeight: "10rem",
    borderRadius: "1rem"
  },
  xs: {
    maxWidth: "55%"
  },
  lg: {
    maxWidth: "85%"
  },
  inner: {
    height: "auto",
    maxHeight: "45rem",
    borderRadius: "1rem",
    overflow: "scroll"
  }
}));

export default useStyles;
