import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  addressOne: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  postalCode: Yup.string()
    .required("Postal Code is required")
    .test("length", "Postal Code must have 5 or 9 digits", (postalCode) => {
      return postalCode?.length === 5 || postalCode?.length === 9;
    })
});

export default validationSchema;
