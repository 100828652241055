import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const disableFacility = createAsyncThunk(
  "disableFacility",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const facilityId = state.facility.facility!.id;

    return axios.post(`/api/v1/facilities/${facilityId}/enabled`, { is_enabled: false })
      .then((resp) => {
        dispatch(showSnackbar({
          message: `Facility disabled`,
          variant: "success"
        }));

        return resp.data;
      })
      .catch(() => {
        const snackbarPayload = {
          message: `There was an error disabling the Facility`,
          variant: "error"
        };

        dispatch(showSnackbar(snackbarPayload));
        return rejectWithValue(snackbarPayload.message);
      });
  }
);
