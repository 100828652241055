import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ label: "Reports Params Builder" })((theme) => ({
  borderRadius: {
    borderRadius: "10px"
  },
  detailsBorderRadius: {
    borderRadius: "0px 0px 10px 10px"
  },
  detailsInnerPadding: {
    padding: "2rem"
  },
  headerBackground: {
    background: theme.palette.grey[300]
  },
  detailsBackground: {
    background: theme.palette.common.white
  },
  paramsButton: {
    background: theme.palette.primary.contrastText,
    color: "white",
    minWidth: "7rem"
  },
  noRotate: {
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "none"
    }
  },
  noMarginSummaryContent: {
    "& .MuiAccordionSummary-content": {
      margin: 0
    }
  }

}
));

export default useStyles;
