import { makeStyles } from "../../../../makeStyles";

const useStyles = makeStyles({ label: "ImportProductTypes" })((theme) => ({
  cancelButton: {
    color: theme.palette.primary.contrastText,
    textTransform: "capitalize",
    height: "3rem"
  },
  exButton: {
    position: "absolute",
    bottom: 10,
    right: 10,
    color: theme.palette.error.main
  },
  downloadButton: {
    color: theme.palette.primary.contrastText,
    textTransform: "capitalize"
  },
  downloadIcon: {
    paddingRight: theme.spacing(1)
  },
  arrowBack: {
    marginRight: theme.spacing(1)
  },
  nextButtonContainer: {
    justifySelf: "flex-end"
  },
  nextButton: {
    background: theme.palette.primary.contrastText,
    color: theme.palette.common.white,
    textTransform: "capitalize",
    width: "10rem",
    height: "3rem"
  },
  spinner: {
    color: theme.palette.common.white
  },
  boldLabel: {
    fontWeight: 500
  },
  backButton: {
    display: "flex",
    flexWrap: "nowrap",
    minWidth: "6rem",
    justifyContent: "center"
  },
  hideIt: {
    display: "none"
  },
  dropzone: {
    position: "relative",
    cursor: "pointer",
    border: "1px dashed",
    borderRadius: "1vw",
    height: "5rem",
    minWidth: "15rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  plusButton: {
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(1)
  },
  label: {
    color: theme.palette.common.black,
    paddingBottom: theme.spacing(1),
    textAlign: "center"
  },
  subText: {
    textAlign: "center"
  },
  fullHeight: {
    height: "100%"
  }
}));

export default useStyles;
