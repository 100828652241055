import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const getEsignDocumentKeys = createAsyncThunk(
  "getEsignDocumentKeys",
  ({ id }: { id: number }, { dispatch, rejectWithValue }) => {
    return axios.get(`/api/v1/esign/${id}/get-keys`)
      .then((resp) => resp.data)
      .catch(() => {
        const errorMessage = "There was an error getting the necessary credentials for this document";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
