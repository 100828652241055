import { Grid, GridDirection } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";
import { ResponsiveStyleValue } from "@mui/system";
import StepperButtons from "./StepperButtons/StepperButtons";
import useStyles from "./StepLayout.styles";

interface StepLayoutProps {
  stepperId?: string,
  children: ReactNode,
  buttons?: ReactNode
  gridDirection?: ResponsiveStyleValue<GridDirection>
  padding?: number
}

const StepLayout: React.FC<StepLayoutProps> = ({
  stepperId,
  children,
  gridDirection = "column",
  padding = 5,
  buttons = <StepperButtons stepperId={stepperId} />
}: StepLayoutProps): ReactElement => {
  const { classes } = useStyles();

  return (
    <>
      <Grid
        direction={gridDirection}
        className={classes.root}
        container
        p={padding}
      >
        <Grid
          item
          container
          direction={"column"}
          xs={12}
        >
          <Grid
            item
            container
            direction={"column"}
            justifyContent={"space-between"}
          >
            {children}
          </Grid>
        </Grid>
        <Grid
          pt={3}
          item
          container
          className={classes.buttons}
        >
          {buttons}
        </Grid>
      </Grid>
    </>
  );
};

export default StepLayout;
