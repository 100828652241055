import {
  Button,
  Chip,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  SelectChangeEvent,
  Typography
} from "@mui/material";
import React, { ReactElement, useState } from "react";
import {
  setAutomaticPaymentsModalOpened,
  setDisableAutoBillModalOpened,
  setReRunAutoBillModalOpen
} from "src/store/reducers/automaticPaymentsSlice/automaticPaymentsSlice";
import { setChangeOwnerShipStep, setGenerateDocumentModalOpened } from "src/store/reducers/occupantSlice/occupantSlice";
import {
  setExternalTPPModalOpen,
  setExternalTppPlanActive
} from "src/store/reducers/externalTppPlanSlice/externalTppPlanSlice";
import {
  setIsFromPayNow,
  setLedgerPayments,
  setMakeAPaymentPeriods,
  setOutStandingBalance,
  setSelectedLedgers,
  setTotalPaymentDue
} from "src/store/reducers/makeAPaymentSlice/makeAPaymentSlice";
import { setIsFromRentedUnits, setMoveOutLedger } from "src/store/reducers/moveOutSlice/moveOutSlice";
import {
  setIsTransferUnitModalOpen,
  setLedgersModalOpen,
  setSelectedLedger,
  setSelectedLedgerToTransfer
} from "src/store/reducers/ledgersSlice/ledgersSlice";
import { useNavigate, useParams } from "react-router";
import { Ledger } from "../../../../../../models/Ledger";
import PMSSelect from "src/components/ui/PMSSelect/PMSSelect";
import PMSSwitch from "src/components/ui/PMSSwitch/PMSSwitch";
import { ReplayCircleFilledRounded } from "@mui/icons-material";
import {
  getAllInsurancePlansForFacility
} from "src/store/thunks/insurancePlans/getAllForFacility/getAllInsurancePlansForFacility";
import getExternalTppPlan from "src/store/thunks/externalTppPlan/get/getExternalTppPlan";
import { getLedgerInsurancePlan } from "src/store/thunks/insurancePlans/get/getLedgerInsurancePlan";
import { getMaxAmount } from "src/store/thunks/makeAPayment/getMaxAmount/getMaxAmount";
import { getOneLedger } from "src/store/thunks/ledger/getOneLedger/getOneLedger";
import moment from "moment";
import { useAppDispatch } from "src/store/hooks";
import useStyles from "./RentedUnitCard.styles";

interface RentedUnitCardProps {
  ledger: Ledger;
}

const RentedUnitCard: React.FC<RentedUnitCardProps> = ({ ledger }): ReactElement => {
  const { classes } = useStyles({ active: ledger.is_active });
  const dispatch = useAppDispatch();
  const activeStatusText = ledger.is_active ? "Active" : "Disabled";
  const [actionValue, setActionValue] = useState("");
  const navigate = useNavigate();

  const paymentInstrument = ledger.payment_instruments?.[0] ?? null;

  const handleSelectLedger = async() => {
    await dispatch(setSelectedLedger(ledger));
    dispatch(setLedgersModalOpen(true));
  };

  const params = useParams<{ id: string }>();

  const prepModalWithExternal = () => {
    dispatch(getAllInsurancePlansForFacility(ledger?.facility_id));
    dispatch(setExternalTPPModalOpen(true));
    dispatch(setSelectedLedger(ledger));
    dispatch(setExternalTppPlanActive(true));

    // if the ledger item has a balance transfer in the array of balances, we need
    // to get the external tpp plan from a different table.
    dispatch(
      getExternalTppPlan({
        occupant_id: String(params?.id),
        external_tpp_plan_id: String(ledger?.external_tpp_plan_id)
      })
    );
  };

  const prepModalWithoutExternal = () => {
    dispatch(getAllInsurancePlansForFacility(ledger?.facility_id));
    dispatch(getLedgerInsurancePlan({ occupantId: String(ledger.occupant_id), ledgerId: String(ledger?.id) }));
    dispatch(setExternalTPPModalOpen(true));
    dispatch(setSelectedLedger(ledger));
    dispatch(setExternalTppPlanActive(false));
  };

  const prepareToMakeAPayment = async() => {
    await dispatch(setSelectedLedgers([ledger]));
    const currentBalance = ledger.current_balance ?? 0;
    const ledgerPayment = {
      id: ledger.id as number,
      payment_amount: String(currentBalance.toFixed(2))
    };

    dispatch(setOutStandingBalance(ledger.current_balance));
    dispatch(setTotalPaymentDue(ledger.current_balance));
    dispatch(setLedgerPayments([ledgerPayment]));
  };

  const handleAutomaticPaymentsToggle = (value: boolean) => {
    dispatch(setSelectedLedger(ledger));
    if (value === true && ledger.is_autobill_enabled === false) {
      dispatch(setAutomaticPaymentsModalOpened(value));
      return;
    }

    dispatch(setDisableAutoBillModalOpened(true));
  };

  const handleReRunAutoPay = () => {
    dispatch(setSelectedLedger(ledger));
    dispatch(setReRunAutoBillModalOpen(true));
  };

  const handleSubmit = async() => {
    switch (actionValue) {
      case "1":
        dispatch(setIsFromPayNow(true));
        prepareToMakeAPayment();
        dispatch(setMakeAPaymentPeriods(0));
        await dispatch(getOneLedger({ occupantId: ledger.occupant_id, ledgerId: ledger.id }));
        await dispatch(getMaxAmount({ occupantId: ledger.occupant_id, ledgerId: ledger.id }));
        navigate("/collections/make-a-payment/review-and-apply-payment");
        setActionValue("");
        break;
      case "2":
        navigate(`/collections/occupants/${ledger.occupant_id}/ledgers/${ledger.id}/apply-fee`);
        setActionValue("");
        break;
      case "3":
        dispatch(setIsFromRentedUnits(true));
        dispatch(setMoveOutLedger(ledger));
        navigate("/occupants/create-move-out/process-move-out");
        setActionValue("");
        break;
      case "4":
        dispatch(setMoveOutLedger(ledger));
        dispatch(setSelectedLedger(ledger));
        dispatch(setSelectedLedgerToTransfer(ledger?.id));
        dispatch(setIsTransferUnitModalOpen(true));
        setActionValue("");
        break;
      case "5":
        handleSelectLedger();
        setActionValue("");
        break;
      case "6":
        dispatch(setChangeOwnerShipStep(5));
        setActionValue("");
        break;
      case "7":
        break;
      case "8":
        break;
      case "9":
        ledger.has_external_tpp ? prepModalWithExternal() : prepModalWithoutExternal();
        setActionValue("");
        break;
      case "10":
        dispatch(setGenerateDocumentModalOpened(true));
        dispatch(setSelectedLedger(ledger));
        break;
      case "11":
        navigate(`/occupants/${ledger.occupant_id}/ledgers/${ledger.id}/issue-credit`);
        setActionValue("");
        break;
      case "12":
        navigate(`/occupants/${ledger.occupant_id}/ledgers/${ledger.id}/issue-refund`);
        setActionValue("");
        break;
      default:
        setActionValue("");
        break;
    }
  };

  return (
    <Grid data-testid={`rented-unit-card`} container alignItems={"center"}
      mt={2}
      spacing={1}>
      <Grid className={classes.cardContent} component={Paper} variant={"outlined"}
        container item xs={9}
        p={2}>
        <Grid container direction={"column"} justifyContent={"space-around"}
          item xs={3}>
          <Grid item>
            <Typography variant={"h5"}>Unit {ledger.unit?.unit_number}</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.infoTitle}>Rental Rate:</Typography>
            <Typography className={classes.infoText}>${ledger.monthly_rate}</Typography>
          </Grid>
        </Grid>
        <Grid container item xs={3.5}
          spacing={1} direction={"column"} justifyContent={"space-around"}>
          <Grid item>
            <Typography className={classes.infoTitle}>Facilites:&nbsp;</Typography>
            <Chip
              label={`${ledger.facility?.facility_id} - Facility ${ledger.facility_id}`}
              clickable={false}
              size={"small"}
              className={classes.facilityChip}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.infoTitle}>Promotion Applied:</Typography>
            <Typography className={classes.infoText}>{ledger?.promotion?.name || "None"}</Typography>
          </Grid>
        </Grid>
        <Grid container item xs={3}
          direction={"column"} spacing={1}>
          <Grid item>
            <Typography className={classes.infoTitle}>Revenue Class: </Typography>
            <Typography className={classes.infoText}>{ledger.unit?.product_type?.revenue_class?.name}</Typography>
          </Grid>
          <Grid item >
            <PMSSwitch
              name={`automaticPayments-${ledger.id}`}
              size={"small"}
              smallText
              isDisabled={!ledger.is_active}
              isChecked={ledger.is_autobill_enabled}
              label={"Auto Pay"}
              changeHandler={(e) => handleAutomaticPaymentsToggle(e.target.checked)}
              />
            {
                ledger.is_autobill_enabled &&
                paymentInstrument && (
                  <Grid item>
                    <Typography className={classes.infoText}>
                      {`**** ${paymentInstrument.last_4_digits}`} -
                      {` Exp. ${paymentInstrument.exp_month}/${paymentInstrument.exp_year}`}
                    </Typography>
                  </Grid>
                )
              }
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={2.5}
          className={ledger.overlocked ? classes.overlocked : classes.rentedUnitStatus}
          direction={"row"}
        >
          <Grid item ml={"auto"}>
            <Typography align={"center"} border={1} p={0.5}>
              {ledger.overlocked ? "Overlocked" : activeStatusText}
            </Typography>
          </Grid>

          <Grid container item mt={"auto"}
            direction={"row"} alignItems={"center"}>
            <Grid item xs={2}>
              {ledger?.current_balance > 0 &&
                ledger.is_autobill_enabled && (
                  <IconButton onClick={() => handleReRunAutoPay()}>
                    <ReplayCircleFilledRounded color={"action"} />
                  </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container alignItems={"center"} item
        xs={2}>
        <InputLabel>Actions</InputLabel>
        <PMSSelect
          name={`rented-unit-card-actions`}
          id={"action"}
          fullWidth
          value={actionValue}
          defaultValue={actionValue}
          changeHandler={(e: SelectChangeEvent<string>) => setActionValue(e.target.value)}
        >
          <option value={""} disabled>
            - Select Option -
          </option>
          <option
            disabled={!ledger.is_active} value={"1"}>
            Pay Now
          </option>
          <option disabled={!ledger.is_active} value={"2"}>
            Apply Fee
          </option>
          <option disabled={!ledger.is_active} value={"3"}>
            Move Out
          </option>
          <option disabled={ledger.transfer_from_id !== null &&
            moment().diff(moment(ledger.moved_in_at), "days") < 30
          } value={"4"}>
            Transfer Unit
          </option>
          <option value={"5"}>View Ledger</option>
          <option disabled={!ledger.is_active} value={"11"}>
            Issue Credits
          </option>
          <option disabled={!ledger.is_active} value={"12"}>
            Issue Refund/Void
          </option>
          <option value={"6"}>View Notes</option>
          <option disabled={true} value={"7"}>
            Change Ownership
          </option>
          <option disabled={true} value={"8"}>
            Change Rental Amount
          </option>
          <option disabled={!ledger.is_active} value={"9"}>
            Update Insurance Policy
          </option>
          <option disabled={!ledger.is_active} value={"10"}>
            Generate Document
          </option>
        </PMSSelect>
      </Grid>
      <Grid mt={3.5} item xs={1}>
        <Button
          data-testid={`rented-unit-card-submit-button`}
          className={classes.submitButton}
          onClick={handleSubmit}
          disableElevation
          disabled={!actionValue}
          variant={"contained"}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default RentedUnitCard;
