import { Button, Grid, Paper, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { deleteChartOfAccoutnsSetting } from "src/store/thunks/chartOfAccounts/delete/deleteChartOfAccountsSetting";
import {
  selectSelectedSetting,
  setRemoveModalOpened
} from "src/store/reducers/chartOfAccountsSlice/chartOfAccountsSlice";
import React from "react";
import { getChartOfAccountSettings } from "src/store/thunks/chartOfAccounts/get/getChartOfAccountsSettings";
import useStyles from "./RemoveSettingModalForm.styles";
import { isEmpty } from "lodash";

const RemoveSettingModalForm = () => {
  const { classes } = useStyles();
  const selectedSetting = useAppSelector(selectSelectedSetting);
  const dispatch = useAppDispatch();
  const setting = useAppSelector(selectSelectedSetting);
  const hasActiveRevenueClass = setting?.revenue_classes.some((revenueClass) => revenueClass.is_active);

  const handleClose = () => {
    dispatch(setRemoveModalOpened(false));
  };

  const handleSubmit = () => {
    dispatch(deleteChartOfAccoutnsSetting(selectedSetting?.id as number)).then((resp) => {
      if (!resp.type.includes("rejected")) {
        dispatch(getChartOfAccountSettings());
        handleClose();
      }
    });
  };

  const ModalContent = () => {
    if (hasActiveRevenueClass) {
      return (
        <Grid textAlign={"center"}>
          <Typography>This setting is attached to an existing revenue class:</Typography>
          {setting?.revenue_classes.map((revenueClass) => (
            <Typography fontWeight={"bold"} key={revenueClass.id}>
              {revenueClass.name}
            </Typography>
          ))}
          <Typography>You can not remove this setting</Typography>
        </Grid>
      );
    }
    return (
      <Typography textAlign={"center"}>
        Are you sure you want to remove this account setting and all attached data?
      </Typography>
    );
  };

  return (
    <Grid
      container
      component={Paper}
      elevation={0}
      variant={"outlined"}
      spacing={2}
      className={classes.container}
      pb={2}
    >
      <Box className={classes.content}>
        <Typography variant={"h5"} className={classes.title}>
          Confirm Setting Removal
        </Typography>
        <ModalContent />
        <Grid pt={4} spacing={4} container
          justifyContent={"center"}>
          <Grid item>
            <Button
              data-testid={"cancel-button"}
              className={classes.cancelButton}
              onClick={() => handleClose()}
              variant={"outlined"}
              color={"secondary"}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              disabled={hasActiveRevenueClass}
              data-testid={"confirm-button-modal"}
              color={"primary"}
              variant={"outlined"}
              className={classes.button}
              onClick={() => handleSubmit()}
            >
              Confirm
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default RemoveSettingModalForm;
