import { CreatePromotionPayload } from "./CreatePromotionPayload";
import { PromotionSwitches } from "src/store/reducers/promotionInformationSlice/PromotionInformationSliceState";
import { RootState } from "../../../rootStore";
import { axiosInstance as axios } from "../../../../api/axiosInstance/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { format422 } from "../../../../utils/format422/format422";
import {
  prepareSelectedFacilitiesForAPI
} from "../../../../utils/prepareSelectedFacilitiesForAPI/prepareSelectedFacilitiesForAPI";
import { showSnackbar } from "../../../reducers/snackbarSlice/snackbarSlice";

export const createPromotion = createAsyncThunk(
  "createPromotion",
  (switchs: PromotionSwitches, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const facilities = state.facilities.facilities;
    const promotionName = state.promotionInformation.promotionName;
    const promotionDescription = state.promotionInformation.promotionDescription;
    const promotionAmount = parseFloat(state.promotionInformation.promotionAmount);
    const promotionType = state.promotionInformation.promotionType;
    const appliedPromotionFacilities = state.promotionInformation.appliedPromotionFacilities;
    const appliedPromotionMonth = state.promotionInformation.appliedPromotionMonth;
    const appliedPromotionDuration = state.promotionInformation.appliedPromotionDuration;
    const promotionStart = state.promotionInformation.promotionStart;
    const promotionIndefiniteEnd = state.promotionInformation.promotionIndefiniteEnd;
    const promotionEnd = state.promotionInformation.promotionEnd;
    const channel_restriction = state.promotionInformation.channel_restriction;
    const occupancy_comparison = state.promotionInformation.occupancy_comparison;
    const occupancy_first_value = state.promotionInformation.occupancy_first_value;
    const occupancy_second_value = state.promotionInformation.occupancy_second_value;
    const total_unit_restriction_comparison = state.promotionInformation.total_unit_restriction_comparison;
    const total_unit_restriction_value = state.promotionInformation.total_unit_restriction_value;
    const total_unit_restriction_second_value = state.promotionInformation.total_unit_restriction_second_value;
    const unit_area_restriction_comparison = state.promotionInformation.unit_area_restriction_comparison;
    const unit_area_restriction_value = state.promotionInformation.unit_area_restriction_value;
    const unit_area_restriction_second_value = state.promotionInformation.unit_area_restriction_second_value;
    const occupancy_target = state.promotionInformation.occupancyTarget;
    const unit_sizes = state.promotionInformation.unit_sizes.join(";");
    const finalProductTypeIds = state.promotionInformation.commonProductTypes.filter(entry =>
      state.promotionInformation.product_types.includes(entry.temp_id))
      .flatMap(entry => entry.unique.map(item => item.id));

    const payload: CreatePromotionPayload = {
      facilities: prepareSelectedFacilitiesForAPI(facilities, appliedPromotionFacilities),
      name: promotionName,
      description: promotionDescription,
      amount: promotionAmount,
      type: promotionType,
      applied_month: appliedPromotionMonth,
      duration: appliedPromotionDuration,
      start: promotionStart,
      end: promotionIndefiniteEnd ? null : promotionEnd,
      is_indefinite: promotionIndefiniteEnd,
      is_active: true,
      ...(switchs.channel_restriction &&
        {
          channel_restriction: channel_restriction.includes("all")
            ? "all"
            : channel_restriction.join(",")
        }),
      ...(switchs.occupancy && { occupancy_comparison }),
      ...(switchs.occupancy && { occupancy_target }),
      ...(switchs.occupancy && { occupancy_first_value: occupancy_first_value ? +occupancy_first_value : null }),
      ...(switchs.occupancy && { occupancy_second_value: occupancy_second_value ? +occupancy_second_value : null }),
      ...(switchs.total_unit_restriction && { total_unit_restriction_comparison }),
      ...(switchs.total_unit_restriction && {
        total_unit_restriction_value: total_unit_restriction_value ? +total_unit_restriction_value : null
      }),
      ...(switchs.total_unit_restriction && total_unit_restriction_second_value
        ? {
            total_unit_restriction_second_value: total_unit_restriction_second_value
              ? +total_unit_restriction_second_value
              : null
          }
        : null),
      ...(switchs.unit_area_restriction && { area_comparison: unit_area_restriction_comparison }),
      ...(switchs.unit_area_restriction && {
        area_first_value: unit_area_restriction_value ? +unit_area_restriction_value : null
      }),
      ...(switchs.unit_area_restriction && unit_area_restriction_second_value
        ? {
            area_second_value: unit_area_restriction_second_value
              ? +unit_area_restriction_second_value
              : null
          }
        : null),
      ...(switchs.unit_size_restriction && { unit_sizes }),
      ...(switchs.product_type_restriction && { product_types: finalProductTypeIds })
    };

    return axios
      .post("/api/v1/promotions", payload)
      .then((resp) => {
        dispatch(
          showSnackbar({
            message: "Promotion created successfully",
            variant: "success"
          })
        );

        return resp.data;
      })
      .catch((err) => {
        let errorMessage: string;
        if (err.response.status === 422) {
          errorMessage = format422(err.response.data.errors);

          dispatch(
            showSnackbar({
              message: errorMessage,
              variant: "error"
            })
          );

          return rejectWithValue(errorMessage);
        }

        errorMessage = `There was an error creating the Promotion`;

        dispatch(
          showSnackbar({
            message: errorMessage,
            variant: "error"
          })
        );

        return rejectWithValue(errorMessage);
      });
  }
);
